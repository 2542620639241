import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const NoDMsWidget = ({ username, displayName, groupName }) => (
  <NoDMsWidgetWrapper>
    <NoDMsWidgetIcon src="/images/misc/empty-dms-dude.svg" alt="empty dms dude" />
    <NoDMsWidgetText>
      This is the beginning of your chat history with <strong>{groupName || username || displayName}</strong>. Say hi!
    </NoDMsWidgetText>
  </NoDMsWidgetWrapper>
);

NoDMsWidget.propTypes = {
  username: PropTypes.string,
  displayName: PropTypes.string,
  groupName: PropTypes.string,
};

NoDMsWidget.defaultProps = {
  username: '',
  displayName: '',
  groupName: '',
};

const NoDMsWidgetWrapper = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NoDMsWidgetIcon = styled.img`
  width: 61px;
  display: inline-block;
`;

const NoDMsWidgetText = styled.p`
  margin: 10px 0 0 0;
  color: #ffffff;
  font-size: 12px;
  line-height: 21px;
  font-family: Inter;
  text-align: left;
`;

export default NoDMsWidget;
