import * as annyang from 'annyang';
import party from 'party-js';

import { isPageMuted } from '../util';
import { playSoundEffect as playSound } from '../util/sound-fx-util';
import { track } from '../util/analytics-util';

import '../../styles/voice-control-effects.less';

let voiceControlEnabled = true;

const failHornSound = 'audio/fail-horn.mp3';
const applauseSound = 'audio/applause.mp3';
const saxophoneSound = 'audio/saxophone.mp3';
const christmasSound = 'audio/christmas.mp3';
const hanukkahSound = 'audio/hanukkah.mp3';
const newYearSound = 'audio/new-year.mp4';
const hauntedSound = 'audio/zombies.mp3';
const trickOrTreatSound = 'audio/trick-or-treat.mp3';
const happyHalloweenSound = 'audio/happy-halloween.mp3';
const halloweenSound = 'audio/this-is-halloween.m4a';
const monsterSound = 'audio/calling-all-the-monsters.m4a';

const snowflakeImage = 'images/fx/snowflake.png';
const dreidelGif = 'images/fx/dreidel.gif';
const reindeerGif = 'images/fx/reindeer.gif';
const explosionGif = 'images/fx/explosion.gif';
const hauntedGif = 'images/fx/zombies.gif';
const trickOrTreatGif = 'images/fx/trick-or-treat.gif';
const halloweenGif = 'images/fx/happy-halloween.gif';

party.settings.gravity = 200;

const playSoundEffect = (type) => {
  let soundEffect;
  switch (type) {
    case 'fail':
      soundEffect = failHornSound;
      break;
    case 'applause':
      soundEffect = applauseSound;
      break;
    case 'saxophone':
      soundEffect = saxophoneSound;
      break;
    case 'christmas':
      soundEffect = christmasSound;
      break;
    case 'hanukkah':
      soundEffect = hanukkahSound;
      break;
    case 'new year':
      soundEffect = newYearSound;
      break;
    default:
  }
  const audio = new Audio(soundEffect);
  audio.volume = 0.2;
  audio.play();
};

const clearEffectImage = () => {
  const mainImageElement = document.getElementById('main-effect-image');
  mainImageElement.src = 'none';
  mainImageElement.style.display = 'none';
};

const releaseConfetti = (color, sound) => {
  const origin = new party.Rect(0, 0, window.innerWidth, 100);
  let shapeArray = ['square', 'circle'];
  if (sound === 'christmas') {
    party.resolvableShapes.snowflake = `<img style="height: 20px" src=${snowflakeImage} />`;
    shapeArray = ['snowflake'];
  }
  if (sound === 'new year') {
    // explode particle effects here
    const explosionEls = document.querySelectorAll('.explosion-effect');
    explosionEls.forEach((element) => {
      element.src = explosionGif;
      element.style.display = 'block';
    });
    setTimeout(() => {
      explosionEls.forEach((element) => {
        element.style.display = 'none';
      });
    }, 1500);
  } else if (sound === 'hanukkah') {
    const mainImageElement = document.getElementById('main-effect-image');
    mainImageElement.src = dreidelGif;
    mainImageElement.style.display = 'block';
    mainImageElement.classList.add('dreidel-effect');
    setTimeout(() => {
      mainImageElement.classList.remove('dreidel-effect');
      clearEffectImage();
    }, 4500);
  } else if (sound === 'christmas') {
    const mainImageElement = document.getElementById('main-effect-image');
    mainImageElement.src = reindeerGif;
    mainImageElement.style.display = 'block';
    setTimeout(() => {
      clearEffectImage();
    }, 5000);
  }
  party.confetti(origin, {
    color,
    size: party.variation.range(20, 25),
    count: party.variation.skew(400 * (window.innerWidth / 1980), 0.4),
    shapes: shapeArray,
    spread: 40,
    speed: party.variation.range(0, 400),
  });
  playSoundEffect(sound);
};

const releaseMetsConfetti = () => {
  releaseConfetti([party.Color.fromHex('#002D72'), party.Color.fromHex('#FF5910')], 'applause');
};

const releaseHauntedEffect = async () => {
  const mainImageElement = document.getElementById('main-effect-image');
  mainImageElement.src = hauntedGif;
  mainImageElement.style.display = 'block';
  mainImageElement.classList.add('haunted-effect');
  const stopSound = await playSound(hauntedSound);
  setTimeout(() => {
    mainImageElement.classList.remove('haunted-effect');
    clearEffectImage();
    stopSound();
  }, 6900);
};

const releaseTrickOrTreatEffect = async () => {
  const mainImageElement = document.getElementById('main-effect-image');
  mainImageElement.src = trickOrTreatGif;
  mainImageElement.style.display = 'block';
  mainImageElement.classList.add('trick-or-treat-effect');
  const stopSound = await playSound(trickOrTreatSound);
  setTimeout(() => {
    mainImageElement.classList.remove('trick-or-treat-effect');
    clearEffectImage();
    stopSound();
  }, 5500);
};

const releaseHappyHalloweenEffect = async () => {
  const mainImageElement = document.getElementById('main-effect-image');
  mainImageElement.src = halloweenGif;
  mainImageElement.style.display = 'block';
  mainImageElement.classList.add('halloween-effect');
  const stopSound = await playSound(happyHalloweenSound);
  setTimeout(() => {
    mainImageElement.classList.remove('halloween-effect');
    clearEffectImage();
    stopSound();
  }, 5000);
};

const releaseHalloweenEffect = async () => {
  const stopSound = await playSound(halloweenSound);
  setTimeout(() => {
    clearEffectImage();
    stopSound();
  }, 3200);
};

const releaseMonsterEffect = async () => {
  const stopSound = await playSound(monsterSound);
  setTimeout(() => {
    clearEffectImage();
    stopSound();
  }, 5000);
};

const releaseLoveConfetti = () => {
  releaseConfetti(
    [party.Color.fromHex('#FF0000'), party.Color.fromHex('#CC0000'), party.Color.fromHex('#e06666')],
    'saxophone'
  );
};

const releaseHanukkahConfetti = () => {
  releaseConfetti([party.Color.fromHex('#3A56A0'), party.Color.fromHex('#F2CD3C')], 'hanukkah');
};

const releaseChristmasConfetti = () => {
  releaseConfetti([party.Color.fromHex('#F6335D'), party.Color.fromHex('#39C360')], 'christmas');
};

const releaseNewYearConfetti = () => {
  releaseConfetti(
    [party.Color.fromHex('#FFF7B6'), party.Color.fromHex('#EFD466'), party.Color.fromHex('#D7B030')],
    'new year'
  );
};

const releaseApplauseConfetti = () => {
  releaseConfetti([party.Color.fromHex('#612cda'), party.Color.fromHex('#ffffff')], 'applause');
};

const sendConfetti = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseConfetti');
  releaseApplauseConfetti();
  track('Voice Control', { effect: 'SendConfetti' });
};

const playFailHorn = () => {
  playSoundEffect('fail');
};

const sendFailHorn = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('PlayFailHorn');
  playFailHorn();
  track('Voice Control', { effect: 'SendFailHorn' });
};

const sendHanukkahEffect = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseHanukkahConfetti');
  releaseHanukkahConfetti();
  track('Voice Control', { effect: 'SendHanukkahEffect' });
};

const sendChristmasEffect = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseChristmasConfetti');
  releaseChristmasConfetti();
  track('Voice Control', { effect: 'SendChristmasEffect' });
};

const sendNewYearEffect = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseNewYearConfetti');
  releaseNewYearConfetti();
  track('Voice Control', { effect: 'SendNewYearEffect' });
};

const sendLoveConfetti = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseLoveConfetti');
  releaseLoveConfetti();
  track('Voice Control', { effect: 'SendLoveConfetti' });
};

const sendMetsConfetti = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseMetsConfetti');
  releaseMetsConfetti();
  track('Voice Control', { effect: 'SendMetsConfetti' });
};

const sendHauntedEffect = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseHauntedEffect');
  releaseHauntedEffect();
  track('Voice Control', { effect: 'SendHauntedEffect' });
};

const sendTrickOrTreatEffect = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseTrickOrTreatEffect');
  releaseTrickOrTreatEffect();
  track('Voice Control', { effect: 'SendTrickOrTreatEffect' });
};

const sendHappyHalloweenEffect = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseHappyHalloweenEffect');
  releaseHappyHalloweenEffect();
  track('Voice Control', { effect: 'SendHappyHaloweenEffect' });
};

const sendHalloweenEffect = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseHalloweenEffect');
  releaseHalloweenEffect();
  track('Voice Control', { effect: 'SendHalloweenEffect' });
};

const sendMonsterEffect = () => {
  if (isPageMuted() || !isVoiceControlEnabled()) return;
  window.rtc.sendVoiceControl('ReleaseMonsterEffect');
  releaseMonsterEffect();
  track('Voice Control', { effect: 'SendMonsterEffect' });
};

const TestForCommand = (splat) => {
  if (!window.rtc.isAudioOn) {
    return;
  }

  splat = splat.toLowerCase();

  if (
    splat.includes('congratulations') ||
    splat.includes('congrats') ||
    splat.includes('happy monday') ||
    splat.includes('happy tuesday') ||
    splat.includes('happy wednesday') ||
    splat.includes('happy thursday') ||
    splat.includes('happy friday') ||
    splat.includes('happy saturday') ||
    splat.includes('happy sunday') ||
    splat.includes('happy birthday')
  ) {
    sendConfetti();
  } else if (splat.includes('happy hanukkah')) {
    sendHanukkahEffect();
  } else if (splat.includes('merry christmas')) {
    sendChristmasEffect();
  } else if (splat.includes('happy new year')) {
    sendNewYearEffect();
  } else if (splat.includes('failure') || splat.includes('fail')) {
    sendFailHorn();
  } else if (splat.includes('i love you')) {
    sendLoveConfetti();
  } else if (splat.includes("let's go mets")) {
    sendMetsConfetti();
  }
};

const commands = {
  '*splat': TestForCommand,
};

export function loadVoiceCommands() {
  if (annyang) {
    // Add our commands to annyang
    annyang.addCommands(commands);

    // Start listening
    annyang.start({ autoRestart: true, continuous: false });
  }
}

export function unloadVoiceCommands() {
  if (annyang) {
    annyang.pause();
  }
}

export function areVoiceCommandsListening() {
  return annyang && annyang.isListening();
}

export function isVoiceControlEnabled() {
  return voiceControlEnabled;
}

export function setVoiceControlEnabled(newState) {
  if (newState) loadVoiceCommands();
  else unloadVoiceCommands();
  voiceControlEnabled = newState;
}

export function handleMessageReceived(message) {
  switch (message) {
    case 'ReleaseConfetti':
      releaseApplauseConfetti();
      break;
    case 'ReleaseMetsConfetti':
      releaseMetsConfetti();
      break;
    case 'ReleaseLoveConfetti':
      releaseLoveConfetti();
      break;
    case 'PlayFailHorn':
      playFailHorn();
      break;
    case 'ReleaseHanukkahConfetti':
      releaseHanukkahConfetti();
      break;
    case 'ReleaseChristmasConfetti':
      releaseChristmasConfetti();
      break;
    case 'ReleaseNewYearConfetti':
      releaseNewYearConfetti();
      break;

    default:
  }
}
