import React, { useEffect, useMemo, useRef } from 'react';
import { ReactNodeLike } from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { UserProfile } from '../../../definitions/user-profile';
import { getAvatarsStyles } from '../sidebar/style-constants';
import UserAvatar from '../../components/UserAvatar';
import {
  selectFullChatMembersByChatId,
  selectChatByChatId,
  selectIsAPublicChat,
} from '../../store/messaging/selectors';
import BadgeIcon from '../../../../assets/icons/hereos/sidebar/chat-icon-badge.svg';
import ChatIconTooltip from '../../chats/ChatIconTooltip';
import { selectCurrentUser } from '../../store/users/selectors';
import { applyTheme } from '../../../util/theming-util';
import { selectCurrentTheme } from '../../store/room/selectors';
import { ConditionalWrapper } from '../../components/ConditionalWrapper';
import { svgColorMixin } from '../../mixins';
import GlobeIcon from '../../../../assets/icons/discover-globe-small.svg';

const MAX_NUM_AVATARS = 5;

interface Props {
  chatId: string;
  isActive?: boolean;
  withBadging?: boolean;
  withTooltip?: boolean;
}

const ChatIcon: React.FC<Props> = ({ chatId, isActive = false, withBadging = true, withTooltip = true }) => {
  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const members: UserProfile[] = useSelector((state) => selectFullChatMembersByChatId(state, chatId));
  const { avatars, containerSize } = useMemo(() => getAvatarsStyles(members.length), [members.length]);

  const desktopTheme = useSelector((state) => selectCurrentTheme(state));

  const currentUser = useSelector(selectCurrentUser);

  const themeRef = useRef(null);
  useEffect(() => {
    if (chat?.theme?.colors) {
      applyTheme(chat.theme.colors, null, themeRef.current);
    }
  }, [chat?.theme?.colors]);

  const isPublicChat = useSelector((state) => selectIsAPublicChat(state, chatId));
  const publicIconRef = useRef(null);
  useEffect(() => {
    if (desktopTheme?.colors && publicIconRef.current) {
      applyTheme(desktopTheme?.colors, null, publicIconRef.current);
    }
  }, [desktopTheme?.colors]);

  return (
    <ConditionalWrapper
      condition={withTooltip}
      wrapper={(
        children: NonNullable<ReactNodeLike> // ReactNodeLike because ConditionalWrapper is written as a js func
      ) => <ChatIconTooltip title={chat?.groupName || members[0]?.displayName}>{children}</ChatIconTooltip>}
    >
      <Background
        ref={themeRef}
        isActive={isActive}
        borderColor={desktopTheme?.colors?.secondaryBackground}
        backgroundImg={chat?.background?.thumbnail || chat?.background?.original}
      >
        {isPublicChat && (
          <PublicChatIconContainer ref={publicIconRef}>
            <GlobeIcon />
          </PublicChatIconContainer>
        )}
        <Container>
          {members.length > 0 ? (
            <AvatarsContainer size={containerSize}>
              {avatars.map((avatarStyle, index) => {
                if (index > MAX_NUM_AVATARS) {
                  return (
                    <Avatar key={`overflow-${chatId}`} borderColor="#ffffff" avatarStyle={avatarStyle}>
                      <OverflowCount>+{members.length - MAX_NUM_AVATARS}</OverflowCount>
                    </Avatar>
                  );
                }
                return (
                  <Avatar
                    // nothing else to use as a key
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}-${chatId}`}
                    borderColor={members[index]?.mood?.theme?.colors?.primaryBackground || '#ffffff'}
                    avatarStyle={avatarStyle}
                  >
                    <UserAvatar userProfile={members[index]} />
                  </Avatar>
                );
              })}
            </AvatarsContainer>
          ) : (
            // if there are no members, show the current user's avatar
            <AvatarsContainer size="28">
              <UserAvatar userProfile={currentUser} />
            </AvatarsContainer>
          )}
        </Container>
        {chat?.unreadCount > 0 && withBadging && <StyledBadgeIcon />}
      </Background>
    </ConditionalWrapper>
  );
};

export default ChatIcon;

const Background = styled.div<{ isActive: boolean; borderColor?: string; backgroundImg?: string }>`
  width: 45px;
  height: 45px;

  ${({ backgroundImg }) => {
    if (backgroundImg) {
      return `
        background: url('${backgroundImg}');
        background-size: cover;
        background-position: center;
      `;
    }
    return 'background: var(--primary-background);';
  }}

  border-radius: 13px;

  margin: 0 auto;

  ${({ isActive, borderColor }) => isActive && `border: 2px solid ${borderColor || 'var(--secondary-background)'};`}

  position: relative;
`;

const PublicChatIconContainer = styled.div`
  position: absolute;
  top: -6px;
  left: -6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--primary-background);
  ${svgColorMixin('var(--primary-foreground)')};

  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBadgeIcon = styled(BadgeIcon)`
  position: absolute;
  top: -3px;
  right: -5px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarsContainer = styled.div<{ size: string }>`
  position: relative;
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
`;

const Avatar = styled.div<{
  borderColor: string;
  avatarStyle: { size: string; top?: string; bottom?: string; left?: string; right?: string };
}>`
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  position: absolute;

  ${({ avatarStyle }) => `
    top: ${avatarStyle.top ? `${avatarStyle.top}px` : 'auto'};
    bottom: ${avatarStyle.bottom ? `${avatarStyle.bottom}px` : 'auto'};
    left: ${avatarStyle.left ? `${avatarStyle.left}px` : 'auto'};
    right: ${avatarStyle.right ? `${avatarStyle.right}px` : 'auto'};
    
    width: ${avatarStyle.size}px;
    height: ${avatarStyle.size}px;
  `}
`;

const OverflowCount = styled.span`
  color: #fff;
  font-weight: 700;
  font-size: 8px;
`;
