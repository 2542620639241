import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'lodash/fp/isEqual';

// components
import UserAvatar from '../../components/UserAvatar';
import { NewDayDivider } from './NewDayDivider';
import ReactionsLabelsList from './reactions/ReactionsLabelsList.tsx';
import {
  AvatarContainer,
  ChatAvatarStub,
  ChatMessageContainer,
  ChatMessageMain,
  MessageContainer,
  MessageContainerHeader,
  MessageContent,
  MessageCreator,
  MessageDate,
  MessageGutter,
} from './chat-message-shared-styles.ts';

// store code
import { addDMEmoteReaction, addDMEmojiReaction, resendFailedMessage } from '../../store/messaging/reducer';
import { selectIsDmByChatId } from '../../store/messaging/selectors';
import { selectCurrentUserId, selectUserFirstName } from '../../store/users/selectors';

// utils
import { openUserProfile } from '../../user-profile/utils';
import { getMessageTimestampData, getDividerData } from './msgTimeStampUtil.ts';

// icons
import MessageSendingFailedIcon from '../../../../assets/icons/red-triangle-alert.svg';

const ChatMessage = ({
  messageId,
  chatId,
  createdAt,
  creator,
  previousCreator,
  previousMsgCreatedAt,
  nextCreator,
  nextMsgCreatedAt,
  queue,
  sendingFailed,
  messageContent,
  emojiReactions,
}) => {
  const dispatch = useDispatch();

  const [userProfileOpen, setUserProfileOpen] = useState(false);

  const messageGutter = useRef(null);
  const feedItem = useRef(null);
  const avatar = useRef(null);

  const isDm = useSelector((state) => selectIsDmByChatId(state, chatId));
  const creatorName = useSelector((state) => selectUserFirstName(state, creator));

  const currentUserId = useSelector(selectCurrentUserId);
  const isSelf = creator === currentUserId;

  const onEmojiPick = useCallback(
    (reactionData) => {
      if (reactionData.emoteUrl) {
        const { emoteUrl, emoteType, pack } = reactionData;
        dispatch(addDMEmoteReaction({ messageId, chatId, emoteUrl, emoteType, pack, isDm }));
      } else {
        dispatch(addDMEmojiReaction({ messageId, chatId, reaction: reactionData.emoji, isDm }));
      }
    },
    [dispatch, messageId, chatId, isDm]
  );

  const onReactionLabelClick = useCallback(
    (reactionData) => {
      if (reactionData.customReaction) {
        dispatch(addDMEmoteReaction({ messageId, chatId, emoteUrl: reactionData.customReaction, isDm }));
      } else {
        dispatch(addDMEmojiReaction({ messageId, chatId, reaction: reactionData.reaction, isDm }));
      }
    },
    [dispatch, messageId, chatId, isDm]
  );

  const { showTimestamp, msgTimestamp } = useMemo(
    () =>
      getMessageTimestampData({
        currMsgDate: createdAt,
        prevMsgDate: previousMsgCreatedAt,
        creator,
        previousCreator,
      }),
    [createdAt, creator, previousCreator, previousMsgCreatedAt]
  );
  const { showTimestamp: showTimestampNextMsg } = useMemo(
    () =>
      getMessageTimestampData({
        currMsgDate: nextMsgCreatedAt,
        prevMsgDate: createdAt,
        creator: nextCreator,
        previousCreator: creator,
      }),
    [createdAt, creator, nextCreator, nextMsgCreatedAt]
  );

  const { showNewDayDivider, dividerTimestamp } = getDividerData(createdAt, previousMsgCreatedAt);

  const onAvatarClick = () => {
    if (userProfileOpen) {
      setUserProfileOpen(false);
    } else {
      setUserProfileOpen(true);

      const feedItemCoordinates = feedItem.current.getBoundingClientRect();

      const topPosition = feedItemCoordinates.y;
      const leftPosition = feedItemCoordinates.x + messageGutter.current.offsetWidth + 10;

      openUserProfile({ userId: creator, topPosition, leftPosition, source: 'chat pfp' });

      const onDocumentClick = (e) => {
        const isClickedOnAvatar = avatar.current?.contains(e.target);

        if (!isClickedOnAvatar) {
          setUserProfileOpen(false);
          document.removeEventListener('click', onDocumentClick);
        }
      };

      document.addEventListener('click', onDocumentClick);
    }
  };

  const onTryAgainClick = () => {
    if (sendingFailed) {
      dispatch(resendFailedMessage({ messageId }));
    }
  };

  return (
    <>
      {showNewDayDivider && <NewDayDivider dividerTimestamp={dividerTimestamp} />}
      <ChatMessageContainer hasReactions={emojiReactions.length}>
        <ChatMessageMain>
          {!isDm && !isSelf && (
            <MessageGutter ref={messageGutter}>
              {(showTimestampNextMsg || !nextCreator) && (
                <AvatarContainer ref={avatar} onClick={onAvatarClick}>
                  <UserAvatar userId={creator} />
                </AvatarContainer>
              )}
            </MessageGutter>
          )}

          <MessageContainer isSelf={isSelf} isLocal={queue} isFailed={sendingFailed}>
            {showTimestamp ? (
              <MessageContainerHeader className="dont-drag-me">
                <MessageCreator>
                  {creatorName}
                  <MessageDate>{msgTimestamp}</MessageDate>
                </MessageCreator>
              </MessageContainerHeader>
            ) : null}

            <MessageContent ref={feedItem} className={`${queue || sendingFailed ? 'local-message' : ''}`}>
              {messageContent}
              <ReactionsLabelsList
                emojiReactions={emojiReactions}
                onEmojiPick={onEmojiPick}
                handleUnreact={onReactionLabelClick}
                addSameReaction={onReactionLabelClick}
              />
            </MessageContent>
          </MessageContainer>
        </ChatMessageMain>

        {sendingFailed ? (
          <ChatMessageError>
            <ChatAvatarStub />

            <MessageFailedContainer>
              <MessageSendingFailedIcon />
              <MessageFailedText>Message Failed. </MessageFailedText>
              <ResendButton onClick={onTryAgainClick}>Try Again</ResendButton>
            </MessageFailedContainer>
          </ChatMessageError>
        ) : null}
      </ChatMessageContainer>
    </>
  );
};

ChatMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
  createdAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      createdAt: PropTypes.func,
    }),
    PropTypes.number, // Date.now() for local queue messages
  ]).isRequired,
  creator: PropTypes.string.isRequired,
  previousCreator: PropTypes.string,
  previousMsgCreatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      previousMsgCreatedAt: PropTypes.func,
    }),
    PropTypes.number, // Date.now() for local queue messages
  ]),
  nextCreator: PropTypes.string,
  nextMsgCreatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      nextMsgCreatedAt: PropTypes.func,
    }),
    PropTypes.number,
  ]),
  queue: PropTypes.bool,
  sendingFailed: PropTypes.bool,
  messageContent: PropTypes.node.isRequired,
  emojiReactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

ChatMessage.defaultProps = {
  previousCreator: null,
  previousMsgCreatedAt: null,
  nextCreator: null,
  nextMsgCreatedAt: null,
  queue: false,
  sendingFailed: false,
  emojiReactions: [],
};

const ChatMessageError = styled.div`
  margin-top: 5px;
  display: flex;
`;

const MessageFailedContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 25px;
  }
`;

const MessageFailedText = styled.div`
  color: #f6335dcc;
  font-size: 14px;
`;

const ResendButton = styled.button`
  display: flex;
  color: #f6335dcc;
  font-size: 14px;
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    color: #f6335d;
  }
`;

const propsAreEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(ChatMessage, propsAreEqual);
