import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  current: [],
};

export const addAnimation = createAction('animations/add');
export const addComplexAnimation = createAction('animations/addComplex');
export const animationCompleted = createAction('animations/completed');

export const animationsReducer = createReducer(initialState, {
  [addAnimation]: (state, { payload: { id, name, data } }) => {
    state.current.push({
      id,
      name,
      data: data || {},
    });
  },
  [animationCompleted]: (state, { payload: { id } }) => {
    state.current = state.current.filter((animation) => animation.id !== id);
  },
});
