/* eslint-disable @typescript-eslint/lines-between-class-members */
import {
  SessionsRemoteStreamMetricNames,
  DistributionField,
  SessionRemoteStreamStat,
  DistributionFieldsEnum,
  SubscriberDistributionFields,
  PublisherDistributionFields,
  MediaSource,
  SessionPublisherStat,
  SessionLogDefinition,
} from './definitions/lib.sessions';
import firebase, { db } from './firebase';
import log from './log';
import { boardUsers } from './presence/db';
import { UserId } from './sfu/definitions/index.definitions';
import { RollingAverage } from './sfu/helpers/rolling-average';
import {
  IceRestartStatsType,
  RTCInboundRtpStreamStatsComplete,
  RTCOutboundRtpStreamStatsComplete,
} from './sfu/stats/definitions';
import { track as trackMetric } from './util/analytics-util';

const UPDATE_INTERVAL = 10 * 1000;

const DistributionMetricsConfig = {
  camera: {
    interval: 25000,
    max: 1200000,
    round: 0,
  },
  mic: {
    interval: 2000,
    max: 150000,
    round: 0,
  },
  screenshare: {
    interval: 25000,
    max: 1200000,
    round: 0,
  },
  screenshareMic: {
    interval: 2000,
    max: 150000,
    round: 0,
  },
  [DistributionFieldsEnum.RoundTrip]: {
    interval: 100,
    max: 5000,
    round: 3,
  },
  [DistributionFieldsEnum.AvDrift]: {
    interval: 100,
    max: 5000,
    round: 3,
  },
  [DistributionFieldsEnum.FrameDecodeTime]: {
    interval: 300,
    max: 40000,
    round: 3,
  },
  [DistributionFieldsEnum.Jitter]: {
    interval: 100,
    max: 5000,
    round: 4,
  },
};
class SessionLog {
  private static instance: SessionLog;
  sessionStart: Date;
  activityId: string;
  sessionElapsed = 0;
  lastUpdateTimestamp: number;
  // FIXME stallCount move into streams
  stallCount = 0;
  streams: { [key: UserId]: SessionRemoteStreamStat };

  publishingElapsed: { [key in MediaSource]?: { timestamp: number; value: number } } = {};

  publisherStats: { [key in MediaSource]?: SessionPublisherStat } = {};

  roundTripTimes: DistributionField = {};

  bitrateMeasures: {
    [key: string]: {
      timestamp: number;
      bytes: number;
    };
  } = {};

  stallsCount: { [key: string]: number } = {};

  dataChanged = false;

  lastFlushDurations = new RollingAverage(10);

  public static getInstance() {
    if (!SessionLog.instance) {
      SessionLog.instance = new SessionLog();
      (() => {
        window.setInterval(SessionLog.instance.flushSessionLogs.bind(SessionLog.instance), UPDATE_INTERVAL);
      })();
    }
    return SessionLog.instance;
  }

  constructor() {
    (['camera', 'mic', 'screenshare', 'screenshareMic'] as MediaSource[]).forEach((mediaSource) => {
      this.publisherStats[mediaSource] = {
        source: mediaSource,
        elapsed: 0,
        nackCount: 0,
        firCount: 0,
        pliCount: 0,
        iceRestartsStats: {},
        bitrate: { 0: 0 },
        qualityLimitationDurations: { none: 0, cpu: 0, bandwidth: 0, other: 0 },
      };
    });
  }

  get currentActivityId() {
    return this.activityId;
  }

  async updateActivityId(newActivityId: string) {
    if (newActivityId === this.activityId) {
      return;
    }
    log.debug(`Logging with activity ID ${newActivityId}`);

    // If we have an old ID, flush logs
    if (this.activityId) {
      this.flushSessionLogs();
    }

    this.streams = {};
    this.sessionStart = new Date();
    this.activityId = newActivityId;

    const user = firebase.auth().currentUser;
    const existingStats = await db
      .collection('globalActivity')
      .doc(this.activityId)
      .collection('webrtcLogs')
      .doc(user.uid)
      .get();
    // TODO: Add a reload counter here?
    const data = existingStats.data();
    this.lastUpdateTimestamp = data?.timestamp || Date.now();
    this.sessionElapsed = data?.elapsed || 0;
    this.stallCount = data?.stallCount || 0;
    this.streams = data?.streams || {};
  }

  recordPublisherStats(
    source: MediaSource,
    stat: RTCOutboundRtpStreamStatsComplete,
    iceRestartsStats: IceRestartStatsType = {}
  ) {
    const dataFromStats = (({ nackCount, firCount, pliCount, qualityLimitationDurations }) => ({
      nackCount,
      firCount: firCount || null,
      pliCount: pliCount || null,
      qualityLimitationDurations: qualityLimitationDurations || null,
    }))(stat);

    const publisherStat = Object.assign(dataFromStats, {
      source,
      iceRestartsStats,
      elapsed: this.publishingElapsed[source]?.value || 0,
    }) as Exclude<SessionPublisherStat, 'bitrate'>;

    this.publisherStats[source] = Object.assign(this.publisherStats[source] || publisherStat, publisherStat);
  }

  recordRemoteStreamStats(
    userId: string,
    audioRTP: RTCInboundRtpStreamStatsComplete,
    videoRTP: RTCInboundRtpStreamStatsComplete
  ) {
    if (!userId) {
      log.error('Trying to recordRemoteStreamStats for an undefined user.');
      trackMetric('WebRTC Stats undefined remote user');
      return;
    }
    if (!this.streams) {
      log.warn('SessionLog: Cannot record remote streams yet, activity session is not initialized apparently.');
      return;
    }
    const currentAvDrift =
      this.streams[userId] && this.streams[userId][SessionsRemoteStreamMetricNames.AvDrift]
        ? this.streams[userId][SessionsRemoteStreamMetricNames.AvDrift]
        : { 0: 0 };

    const currentFrameDecodeTime =
      this.streams[userId] && this.streams[userId][SessionsRemoteStreamMetricNames.FrameDecodeTime]
        ? this.streams[userId][SessionsRemoteStreamMetricNames.FrameDecodeTime]
        : { 0: 0 };

    const currentJitter =
      this.streams[userId] && this.streams[userId][SessionsRemoteStreamMetricNames.Jitter]
        ? this.streams[userId][SessionsRemoteStreamMetricNames.Jitter]
        : { 0: 0 };

    const t = Date.now();
    if (this.bitrateMeasures[videoRTP.ssrc]?.bytes - videoRTP.bytesReceived === 0) {
      if (this.stallsCount[videoRTP.ssrc] === undefined) this.stallsCount[videoRTP.ssrc] = 0;
      this.stallsCount[videoRTP.ssrc] += 1;
    }
    this.bitrateMeasures[videoRTP.ssrc] = { bytes: videoRTP.bytesReceived, timestamp: t };

    this.streams[userId] = {
      avDrift: currentAvDrift,
      frameDecodeTime: currentFrameDecodeTime,
      jitter: currentJitter,
      stallCount: this.stallsCount[videoRTP.ssrc] || 0,
      audio: !!audioRTP,
      video: !!videoRTP,
      audioNack: audioRTP?.nackCount || this.streams[userId]?.audioNack || 0,
      videoNack: videoRTP?.nackCount || this.streams[userId]?.videoNack || 0,
      fir: videoRTP.firCount || this.streams[userId]?.fir || 0,
      pli: videoRTP.pliCount || this.streams[userId]?.pli || 0,
    };
  }

  recordDistributionBitrateField(source: MediaSource, ssrc: string, timestamp: number, bytesSent: number) {
    const lastMeasure = this.bitrateMeasures[ssrc];

    if (lastMeasure) {
      const bytesInInterval = bytesSent - lastMeasure.bytes || 0;
      const bitrate = ((bytesInInterval * 8) / (timestamp - lastMeasure.timestamp || Date.now())) * 1000;
      if (bitrate >= 0) {
        this.recordDistributionField(source, DistributionFieldsEnum.Bitrate, bitrate);
      } else {
        log.warn(`Invalid bitrate computed from interval ${bytesInInterval}`);
      }
    }

    this.bitrateMeasures[ssrc] = {
      bytes: bytesSent,
      timestamp,
    };
  }

  /**
   * Record a new distribution field entry
   * @param source Source of this measuring, like session, publisher or subscriber.
   * @param fieldName Field in which we want to record.
   * @param level The measure level
   * @param fromUserId Optional, from which user this metric come from (In a Subscribing context).
   * You should omit this param if the measuring corresponds to a Publishing metric.
   * @returns
   */
  recordDistributionField(
    source: MediaSource | 'session' | 'streams',
    fieldName: DistributionFieldsEnum,
    level: number,
    fromUserId?: string
  ) {
    const configKey = fieldName === DistributionFieldsEnum.Bitrate ? source : fieldName;
    const { interval, max, round } = DistributionMetricsConfig[configKey as keyof typeof DistributionMetricsConfig];
    if (!interval) {
      log.error(`SessionLog: No distribution metric named ${fieldName}`);
      return;
    }

    if (!this.publisherStats || typeof level === 'undefined' || Number.isNaN(level)) {
      return;
    }

    const quantizedLevel = Math.min(max, parseFloat((level / interval).toFixed(round || 0)) * interval);

    let distribution;
    if (fieldName === DistributionFieldsEnum.RoundTrip) {
      distribution = this.roundTripTimes;
    } else if (source === 'streams') {
      distribution = this.getUserStreamSession(fromUserId)[fieldName as SubscriberDistributionFields];
    } else {
      distribution = this.publisherStats[source as MediaSource][fieldName as PublisherDistributionFields];
    }

    if (!distribution[quantizedLevel]) {
      distribution[quantizedLevel] = 0;
    }
    distribution[quantizedLevel] += 1;

    this.dataChanged = true;
  }

  recordStall() {
    this.dataChanged = true;
    this.stallCount += 1;
  }

  updateElapsed(source: MediaSource, increment: boolean): void {
    const currentTime = Date.now();
    const elapsed = this.publishingElapsed[source]?.value || 0;
    const lastTime = this.publishingElapsed[source]?.timestamp || currentTime;
    let newElapsed;
    if (increment) {
      const timediff = currentTime - lastTime;
      newElapsed = Math.round(timediff / 1000) + elapsed;
    }
    this.publishingElapsed[source] = {
      timestamp: currentTime,
      value: newElapsed || elapsed,
    };
  }

  flushSessionLogs() {
    if (!window.currentBoardId) {
      return;
    }

    const user = firebase.auth().currentUser;

    const t0 = performance.now();

    try {
      const roomUsers = (boardUsers as { [key: string]: { length: number } })[window.currentBoardId];
      if (
        !this.activityId ||
        this.activityId.length === 0 ||
        !user ||
        !this.dataChanged ||
        !roomUsers ||
        roomUsers.length === 1
      ) {
        return;
      }

      const elapsed = this.sessionElapsed + Math.round((Date.now() - this.lastUpdateTimestamp) / 1000);
      this.sessionElapsed = elapsed;
      this.lastUpdateTimestamp = Date.now();

      const sessionLog: SessionLogDefinition = {
        elapsed,
        timestamp: this.lastUpdateTimestamp as unknown as number,
        camera: this.publisherStats.camera || null,
        mic: this.publisherStats.mic || null,
        screenshare: this.publisherStats.screenshare || null,
        screenshareMic: this.publisherStats.screenshareMic || null,
        roundTripTimes: this.roundTripTimes,
        stallCount: this.stallCount,
        streams: this.streams,
      };

      log.debug(`Writing logs for user ${user.uid} in session ${this.activityId}`);
      db.collection('globalActivity').doc(this.activityId).collection('webrtcLogs').doc(user.uid).set(sessionLog);
    } catch (e) {
      log.error(`Cannot write session logs for user ${user.uid} in session ${this.activityId}, error: `, e);
      trackMetric('Session Log Write Error');
    }

    const t1 = performance.now();
    this.lastFlushDurations.push(t1 - t0);
    this.dataChanged = false;
  }

  private getUserStreamSession(userId: string) {
    if (!this.streams) this.streams = {};
    if (!this.streams[userId]) {
      this.streams[userId] = {
        audio: false,
        video: false,
        audioNack: 0,
        videoNack: 0,
        fir: 0,
        pli: 0,
        avDrift: { 0: 0 },
        frameDecodeTime: { 0: 0 },
        jitter: { 0: 0 },
        stallCount: 0,
      };
    }
    return this.streams[userId];
  }
}

export default SessionLog.getInstance();
