import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import TaggingPicker from './TaggingPicker';
import { useElementData } from '../hooks/useElementData';
import { selectRoomId } from '../store/room/selectors';
import eventBus, { taggingWindowRequested } from '../../event-bus';
import TagsBubble from './TagsBubble';

const BoardElementTagging = ({ elementId }) => {
  const [isTaggingOpened, setIsTaggingOpened] = useState(false);
  const roomId = useSelector(selectRoomId);
  const [elementData] = useElementData(roomId, elementId);

  const tags = useMemo(
    () => Object.entries(elementData?.tags || {}).map(([id, tag]) => ({ id, ...tag })),
    [elementData]
  );
  const unreadTags = useMemo(() => tags.filter((tag) => !tag.seenAt), [tags]);

  useEffect(() => {
    const listener = (requestedElementId) => requestedElementId === elementId && setIsTaggingOpened(true);
    eventBus.on(taggingWindowRequested, listener);
    return () => eventBus.off(taggingWindowRequested, listener);
  }, [elementId]);

  const onPickerOpen = useCallback(() => setIsTaggingOpened(true), []);
  const onPickerClose = useCallback(() => setIsTaggingOpened(false), []);

  return (
    <>
      <TagsBubble tags={unreadTags} onTaggingPickerOpen={onPickerOpen} />
      <PopoverPrimitive.Root open={isTaggingOpened} onOpenChange={setIsTaggingOpened}>
        <PopoverPrimitive.Anchor />
        <PopoverPrimitive.Content side="right">
          <TaggingPicker existingTags={tags} elementId={elementId} onClose={onPickerClose} />
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Root>
    </>
  );
};

export default BoardElementTagging;

BoardElementTagging.propTypes = {
  elementId: PropTypes.string.isRequired,
};
