import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  banMember,
  fetchPublicChat,
  fetchPublicChatSuccess,
  fetchPublicChats,
  fetchPublicChatsError,
  fetchPublicChatsSuccess,
  joinPublicChat,
  receiveGroupSettingsUpdated,
  removeMember,
  removeMemberSocketEvent,
  setChatMemberBanned,
  setGroupSettings,
  setMemberRole,
  setRemovedMember,
  unbanMember,
} from './actions';
import { requestAsync } from '../helpers';
import { changeMemberRole, removeMemberFromGroup } from '../../../api/groups-api';
import { selectChatIdByGroupId, selectGroupIdFromChatId } from './selectors';
import log from '../../../log';
import { selectCurrentUserId } from '../users/selectors';
import { removeChat } from './reducer';
import { selectOpenChats } from '../os/selectors';
import { selectCurrentOsDesktopId } from '../room/selectors';
import { db } from '../../../firebase';
import { SocketUpdateGroupSettingsMessage } from '../../../definitions/groups';
import { ChatMessage, PublicChat, ChatMemberRoles } from '../../../definitions/message';
import { getPublicChat, getPublicChats } from './api';
import { joinGroup, joinGroupSuccess } from '../groups/actions';
import { setIsDiscoverModalOpened } from '../os/actions';
import { formatMessageFromResponse } from './normalizers';
import { CHAT_DEMOTE_MEMBER, CHAT_PROMOTE_MEMBER } from '../../os/analytics';
import { track } from '../../../util/analytics-util';
import { banUser, unbanUser } from '../../../api/reports-api';

function* handleRemoveMember({ payload: { chatId, userId } }: { payload: { chatId: string; userId: string } }) {
  try {
    const groupId: string = yield select(selectGroupIdFromChatId, chatId);
    const response: { success: boolean } = yield call(requestAsync, () => removeMemberFromGroup({ groupId, userId }));

    if (response.success) {
      yield put(setRemovedMember({ chatId, userId }));
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleRemoveMemberSocketEvent({
  payload: { chatId, userId },
}: {
  payload: { chatId: string; userId: string };
}) {
  try {
    const currentUserId: string = yield select(selectCurrentUserId);

    if (userId === currentUserId) {
      yield put(removeChat({ chatId }));
    } else {
      yield put(setRemovedMember({ chatId, userId }));
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleRemoveChat({ payload: { chatId } }: { payload: { chatId: string } }) {
  try {
    const openChats: { [key: string]: { elementId: string } } = yield select(selectOpenChats);
    const osDesktopId: string = yield select(selectCurrentOsDesktopId);

    // close chat if open
    if (chatId && openChats[chatId] && osDesktopId) {
      yield call([db.doc(`boards/${osDesktopId}/elements/${openChats[chatId].elementId}`), 'delete']);
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleReceiveGroupSettingsUpdated({
  payload: { socketMessage },
}: {
  payload: { socketMessage: SocketUpdateGroupSettingsMessage };
}) {
  try {
    const chatId: string = yield select(selectChatIdByGroupId, socketMessage.groupId);

    const updates = {
      ...(socketMessage.name && { groupName: socketMessage.name }),
      ...(socketMessage.background && {
        background: {
          original: socketMessage.background.original,
          thumbnail: socketMessage.background.original,
        },
      }),
      ...(socketMessage.theme && { theme: socketMessage.theme }),
    };

    if (chatId) {
      yield put(setGroupSettings({ chatId, updates }));
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleFetchPublicChats() {
  try {
    const response: { success: boolean; chats: PublicChat[] } = yield call(requestAsync, () => getPublicChats());

    const publicChats: { [key: string]: PublicChat } = response.chats.reduce(
      (acc, chat) => ({ ...acc, [chat.id]: chat }),
      {}
    );

    const publicChatIds = response.chats.map((chat) => chat.id);

    yield put(fetchPublicChatsSuccess({ publicChats, publicChatIds }));
  } catch (e) {
    log.error(e);
    yield put(fetchPublicChatsError({ error: 'There was an error. Please try again!' }));
  }
}

function* handleFetchPublicChat({ payload: { chatId } }: { payload: { chatId: string } }) {
  try {
    const response: { success: boolean; chat: PublicChat } = yield call(requestAsync, () => getPublicChat(chatId));

    const formattedMessages = response.chat.messages.map((message: ChatMessage) => formatMessageFromResponse(message));
    const formattedChat = {
      ...response.chat,
      messages: formattedMessages,
    };

    yield put(fetchPublicChatSuccess({ publicChat: formattedChat }));
  } catch (e) {
    log.error(e);
    yield put(fetchPublicChatsError({ error: 'There was an error. Please try again!' }));
  }
}

function* handleJoinPublicChat({ payload: { publicChat } }: { payload: { publicChat: PublicChat } }) {
  try {
    yield put(joinGroup({ groupId: publicChat.groupId }));
    yield take(joinGroupSuccess);
    yield put(setIsDiscoverModalOpened({ isOpened: false }));
  } catch (e) {
    log.error(e);
    yield put(fetchPublicChatsError({ error: 'There was an error. Please try again!' }));
  }
}

function* handleSetMemberRole({
  payload: { chatId, memberId, role },
}: {
  payload: { chatId: string; memberId: string; role: ChatMemberRoles };
}) {
  try {
    const groupId: string = yield select((state) => selectGroupIdFromChatId(state, chatId));
    const response: { success: boolean } = yield call(requestAsync, () =>
      changeMemberRole({ groupId, memberId, role })
    );

    if (response.success) {
      // For now promotion can only mean member->admin and demotion can only mean admin->member
      const eventName = role === ChatMemberRoles.Admin ? CHAT_PROMOTE_MEMBER : CHAT_DEMOTE_MEMBER;
      yield call(track, eventName, { role });
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleBanMember({ payload: { chatId, memberId } }: { payload: { chatId: string; memberId: string } }) {
  try {
    yield call(requestAsync, () => banUser(chatId, memberId));
    yield put(setChatMemberBanned({ chatId, memberId, isBanned: true }));
  } catch (err) {
    log.error(err);
  }
}

function* handleUnbanMember({ payload: { chatId, memberId } }: { payload: { chatId: string; memberId: string } }) {
  try {
    yield call(requestAsync, () => unbanUser(chatId, memberId));
    yield put(setChatMemberBanned({ chatId, memberId, isBanned: false }));
  } catch (err) {
    log.error(err);
  }
}

export default function* messagesTsSaga() {
  yield all([
    takeEvery(removeMember, handleRemoveMember),
    takeEvery(removeMemberSocketEvent, handleRemoveMemberSocketEvent),
    takeEvery(removeChat, handleRemoveChat),
    takeEvery(receiveGroupSettingsUpdated, handleReceiveGroupSettingsUpdated),
    takeEvery(fetchPublicChats, handleFetchPublicChats),
    takeEvery(fetchPublicChat, handleFetchPublicChat),
    takeEvery(joinPublicChat, handleJoinPublicChat),
    takeEvery(setMemberRole, handleSetMemberRole),
    takeEvery(banMember, handleBanMember),
    takeEvery(unbanMember, handleUnbanMember),
  ]);
}
