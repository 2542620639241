import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useThrottledCallback } from 'use-debounce';
import styled from 'styled-components';

import { createUploadHandler } from '../chats/chat-input/upload-to-chat';
import { deleteMessage, sendImageMessage, sendMessage } from '../../api/feed-api';

// store
import { selectCurrentUserId } from '../store/users/selectors';

// components
import ChatWindow from '../chats/ChatWindow';
import RoomChatInput from '../chats/room-chat-input/index.tsx';
import { RoomChatInputContext } from '../common/contexts.ts';

// hooks
import { useMessagesList } from '../hooks/useMessagesList';
import { useElementData } from '../hooks/useElementData';
import { useTypingUsersList } from '../hooks/feed/useTypingUsersList';
import { useLocalMessagesList } from '../hooks/useLocalMessagesList';

// utils
import { handleURLSummary } from '../../message-util';
import { track } from '../../util/analytics-util';

// constants
import { messageTypes, typingStatuses } from '../../constants/chat-constants';
import { CHANGE_MESSAGE_FONT_STYLE_SOURCES, SEND_CHAT_MESSAGE } from '../../constants/analytics-events/chat-events.ts';
import { gifPickerCallers } from '../../constants/image-constants';

// styles
import '../../../styles/chat.less';

function TextChannel(props) {
  const { elementId } = props;

  const currentBoardId = useSelector((state) => state.room.id);
  const { typingUsersList } = useTypingUsersList(elementId);

  const [messageWithOpenedChatReactionsPickerPrevReactions, setMessageWithOpenedChatReactionsPickerPrevReactions] =
    React.useState([]);

  const [{ textChannelBackgroundImg, textChannelBackgroundColor }] = useElementData(currentBoardId, elementId);

  const messagesCollectionPath = React.useMemo(
    () => (currentBoardId ? `boards/${currentBoardId}/elements/${elementId}/messages` : null),
    [currentBoardId, elementId]
  );

  const imagesStorageFolder = React.useMemo(
    () => (currentBoardId ? `boards/${currentBoardId}/elements/${elementId}/textChannelImages` : null),
    [currentBoardId, elementId]
  );

  const { messagesList, loadMoreMessages, hasMore } = useMessagesList(
    messagesCollectionPath,
    useCallback(() => false, [])
  );

  const { resultMessagesList, addMessageLocally, deleteLocalMessage } = useLocalMessagesList({
    messagesList,
  });

  const deleteMessageHandler = React.useCallback(
    (messageId, storagePath) => {
      if (messagesCollectionPath) {
        track(`Deleted Message`);
        deleteMessage(messageId, messagesCollectionPath, storagePath);
      }
    },
    [messagesCollectionPath]
  );

  const chatItemMenuContextValue = React.useMemo(
    () => ({
      messagesCollectionPath,

      messageWithOpenedChatReactionsPickerPrevReactions,
      setMessageWithOpenedChatReactionsPickerPrevReactions,
    }),
    [
      messagesCollectionPath,

      messageWithOpenedChatReactionsPickerPrevReactions,
      setMessageWithOpenedChatReactionsPickerPrevReactions,
    ]
  );

  const userId = useSelector(selectCurrentUserId);

  const sendTypingEventThrottled = useThrottledCallback((params) => {
    window.rtc.sendTyping(params);
  }, 800);

  const typingParams = useMemo(
    () => ({
      userId,
      typingStatus: typingStatuses.ACTIVE,
      target: elementId,
    }),
    [userId, elementId]
  );

  const send = useCallback(
    async (messageToSendText) => {
      const ref = await sendMessage(messageToSendText, messagesCollectionPath);
      if (ref) {
        handleURLSummary(messageToSendText, ref);
        track(SEND_CHAT_MESSAGE);

        // hide typing indicator after message sent
        sendTypingEventThrottled.cancel();
        window.rtc.sendTyping({ ...typingParams, typingStatus: typingStatuses.FINISHED });
      }
    },
    [messagesCollectionPath, sendTypingEventThrottled, typingParams]
  );

  const onMessageUploadSuccess = React.useCallback(
    async ({ imageUrl, storagePath }) => {
      await sendImageMessage(imageUrl, storagePath, messagesCollectionPath);
    },
    [messagesCollectionPath]
  );

  const onMessageUploadFail = React.useCallback(
    (errorData) => {
      const id = `text-channel-upload-failed-${Math.random()}`;
      addMessageLocally({
        id,
        type: messageTypes.UPLOAD_ERROR,
        errorType: errorData.type,
        creator: userId,
        text: errorData.message,
        createdAt: new Date(),
        deleteSelf: () => deleteLocalMessage(id),
      });
    },
    [addMessageLocally, deleteLocalMessage, userId]
  );

  const handleFileUpload = useMemo(
    () => createUploadHandler(imagesStorageFolder, onMessageUploadSuccess, onMessageUploadFail),
    [imagesStorageFolder, onMessageUploadSuccess, onMessageUploadFail]
  );

  const roomChatInputContextValue = React.useMemo(
    () => ({
      elementId,
      caller: gifPickerCallers.TEXT_CHANNEL,
      fontStyleSource: CHANGE_MESSAGE_FONT_STYLE_SOURCES.TEXT_CHANNEL,
    }),
    [elementId]
  );

  const chatInput = useMemo(
    () => (
      <RoomChatInputContext.Provider value={roomChatInputContextValue}>
        <RoomChatInputContainer>
          <RoomChatInput
            handleFileUpload={handleFileUpload}
            sendTextMessage={send}
            sendTyping={() => {
              sendTypingEventThrottled.callback({
                typingStatus: typingStatuses.ACTIVE,
                target: elementId,
              });
            }}
          />
        </RoomChatInputContainer>
      </RoomChatInputContext.Provider>
    ),
    [elementId, handleFileUpload, send, sendTypingEventThrottled, roomChatInputContextValue]
  );

  return (
    <ChatWindow
      chatItemMenuContextValue={chatItemMenuContextValue}
      textChannelBackgroundImg={textChannelBackgroundImg}
      textChannelBackgroundColor={textChannelBackgroundColor}
      onDeleteMessage={deleteMessageHandler}
      messagesList={resultMessagesList}
      loadMoreMessages={loadMoreMessages}
      hasMore={hasMore}
      typingUsersList={typingUsersList}
      inputEntry={chatInput}
    />
  );
}

TextChannel.propTypes = {
  elementId: PropTypes.string.isRequired,
};

const RoomChatInputContainer = styled.div`
  padding: 10px;
  margin-bottom: 25px;
`;

export default TextChannel;
