import React from 'react';
import PropTypes from 'prop-types';
import UIkit from 'uikit';
import { useSelector } from 'react-redux';

import ChatReactionLabelsList from './reactions/ChatReactionLabelsList';
import MessageContextMenu from './context-menu/MessageContextMenu.tsx';

// utils
import { selectCurrentUserId } from '../../store/users/selectors';

// TODO: A lot of repeating code with TextMessage

// UPD: now there are a bit less duplicated code with TextMessage ;)
// duplicated things that left
// 1) toggle emoji picker;
// 2) duplicated styles in the feed.less, chat.less, message.less

function ImageMessage(props) {
  const { id, imageUrl, storagePath, url, nextCreator, creator, emojiReactions, deleteMessage, dmChatId, style } =
    props;

  const currentUserId = useSelector(selectCurrentUserId);

  const finalImageUrl = imageUrl !== '' ? imageUrl : url;

  function onImageClick() {
    const modal = document.getElementById('chat-image-modal');
    const img = modal.querySelector('#chat-image-modal-img');
    img.src = finalImageUrl;

    const modalObj = UIkit.modal(modal);
    UIkit.util.on(modalObj, 'hidden', () => {
      img.src = '';
    });

    img.onclick = (e) => e.stopPropagation();
    modal.onclick = () => modalObj.hide();

    UIkit.modal(modal).show();
  }

  const bottomMsgClass = currentUserId === creator ? 'bottom-message-bubble-self' : 'bottom-message-bubble';

  return (
    <div className="feed-image-message" style={style}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="message-container" onClick={onImageClick}>
        <div className={`image-message-bubble ${nextCreator !== creator ? bottomMsgClass : 'message-bubble'}`}>
          <MessageContextMenu
            chatId={dmChatId}
            text={finalImageUrl}
            creator={creator}
            deleteSelf={() => deleteMessage(id, storagePath)}
          />

          <img
            className="feed-item-image-content"
            src={finalImageUrl}
            alt="feed-message"
            onDragStart={(e) => e.preventDefault()}
            loading="lazy"
          />
        </div>
      </div>

      {!dmChatId && <ChatReactionLabelsList emojiReactions={emojiReactions} messageId={id} />}
    </div>
  );
}

ImageMessage.propTypes = {
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  url: PropTypes.string,
  storagePath: PropTypes.string,
  nextCreator: PropTypes.string,
  creator: PropTypes.string.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  emojiReactions: PropTypes.arrayOf(PropTypes.object),
  dmChatId: PropTypes.string,
  style: PropTypes.shape({
    marginBottom: PropTypes.string,
  }),
};

ImageMessage.defaultProps = {
  nextCreator: '',
  imageUrl: '',
  url: '',
  storagePath: '',
  emojiReactions: [],
  dmChatId: '',
  style: null,
};

export default ImageMessage;
