import UIkit from 'uikit';
import Hammer from 'hammerjs';
import firebase, { db } from './firebase';
import {
  isLockAllowed,
  addUserCanAddContentSubscriber,
  canDeleteContent,
  onRoleChange,
  canMove,
  canInteract,
} from './roles-management';
import { applyElementTransform } from './element-transform';
import log from './log';
import { dragElement } from './drag';

// utils
import { htmlToElement, getQueryStringValue } from './util';
import { checkIsMobile } from './util/platform-util';
import { deleteElement, prepareToPin, unpinFromAll, mirror, duplicate } from './util/element-util';
import { hasPinnedElements, unpinElement } from './util/pinned-util';
import { getCurrentBoardIsUserCard } from './util/room-util';

import '../styles/element-wrapper.less';
import eventBus, { taggingWindowRequested } from './event-bus';
import { track } from './util/analytics-util';
import { DISABLE_DECK, ENABLE_DECK } from './constants/analytics-events/element-events';
import { isHereEmployee } from './util/user-util';

// icons
const disableAddContentLockIcon = 'images/icons/disable-add-content-lock-black.svg';
const caretIcon = 'images/icons/chevron-left.svg';

const LONG_PRESS_DELAY = 500; // ms before recognizing a move gesture on mobile

const zIndexCenter = 500;
let zIndexPlaceholder;
let idPlaceholder;

let isHereEmployeeValue = false;
firebase.auth().onAuthStateChanged(async (user) => {
  isHereEmployeeValue = await isHereEmployee(user);
});

async function updateElement(elementId, update) {
  const element = document.getElementById(`element-${elementId}`);
  // May incidentally call this after object deleted
  if (element) {
    await db.doc(element.getAttribute('docPath')).update(update);
  }
}

function lockElement(elementId) {
  return updateElement(elementId, { locked: true });
}

function unlockElement(elementId) {
  unpinElement(elementId);
  return updateElement(elementId, { locked: false });
}

function lockElementInteraction(elementId) {
  return updateElement(elementId, { lockedInteraction: true });
}

function unlockElementInteraction(elementId) {
  return updateElement(elementId, { lockedInteraction: false });
}

function moveToIndex(elementId, index) {
  return updateElement(elementId, { zIndex: index });
}

function moveToFront(elementId) {
  zIndexPlaceholder = window.getFrontZIndex();
  const el = document.getElementById(`element-${elementId}`);
  el.style.zIndex = zIndexPlaceholder;
  return updateElement(elementId, { zIndex: zIndexPlaceholder });
}

function moveToBack(elementId) {
  const allElements = document.querySelectorAll('#elements [id^="element"]');
  let minZ = 10000;
  allElements.forEach((el) => {
    const newZ = parseInt(el.style.zIndex, 10);
    if (el.id !== `element-${elementId}` && newZ < minZ) {
      minZ = newZ;
    }
  });
  zIndexPlaceholder = minZ === 1 ? -1 : minZ - 1;
  const el = document.getElementById(`element-${elementId}`);
  el.style.zIndex = zIndexPlaceholder;
  return updateElement(elementId, { zIndex: zIndexPlaceholder });
}

function elementOptions(doc) {
  const data = doc.data();

  const moveToFrontOption = htmlToElement(
    '<button class="uk-dropdown-close options-menu-option">Move to front</button>'
  );
  moveToFrontOption.addEventListener('click', () => moveToFront(doc.id));

  const moveToBackOption = htmlToElement('<button class="options-menu-option">Move to back</button>');
  moveToBackOption.addEventListener('click', () => moveToBack(doc.id));

  const pinOption = htmlToElement('<button class="options-menu-option">Pin</button>');
  pinOption.addEventListener('click', () => prepareToPin(doc.id));

  const unpinOption = htmlToElement('<button class="options-menu-option">Unpin from All</button>');
  unpinOption.addEventListener('click', () => unpinFromAll(doc.id));

  const lockStyle = isLockAllowed() ? '' : 'display: none';
  const lockFunction = data.locked ? unlockElement : lockElement;
  const interactionFunction = data.lockedInteraction ? unlockElementInteraction : lockElementInteraction;
  const isInteractionLockAllowed =
    data.class !== 'ImageElement' &&
    data.class !== 'BarrierElement' &&
    data.class !== 'SpawnElement' &&
    data.class !== 'RoomActiveTimeWidget' &&
    data.class !== 'RoomTopMembersWidget' &&
    data.class !== 'RoomStreaksElement';

  let lockOption;
  if (isInteractionLockAllowed) {
    lockOption = htmlToElement(
      `<li class="uk-parent" style="${lockStyle}">
      <button class="options-menu-option">Lock<span class="right"><img src="${caretIcon}"></span></button>
      <div class="option-menu-extension" uk-dropdown="mode: hover; pos: right-top">
          <ul class="uk-nav-sub uk-dropdown-nav">
          ${
            !hasPinnedElements(doc.id)
              ? `
            <li>
              ${
                data.locked
                  ? `<button class="lock-control options-menu-option" uk-tooltip="title: Allow everyone to move this element">Unlock Position</button>`
                  : `<button class="lock-control options-menu-option" uk-tooltip="title: Prevent everyone from moving this element">Lock Position</button>`
              }
            </li>
          `
              : ``
          }
            <li>
              ${
                data.lockedInteraction
                  ? `<button class="interaction-control options-menu-option" uk-tooltip="title: Allow everyone to interact with this element">Unlock Interaction</button>`
                  : `<button class="interaction-control options-menu-option" uk-tooltip="title: Prevent regular members from interacting with this element">Lock Interaction</button>`
              }
            </li>
          </ul>
      </div>
      </li>
    `
    );

    if (!hasPinnedElements(doc.id)) {
      lockOption.querySelector('.lock-control').addEventListener('click', () => lockFunction(doc.id));
    }

    lockOption.querySelector('.interaction-control').addEventListener('click', () => interactionFunction(doc.id));
  } else {
    lockOption = htmlToElement(
      `${
        data.locked
          ? `<button style="${lockStyle}" class="lock-control options-menu-option">Unlock</button>`
          : `<button style="${lockStyle}" class="lock-control options-menu-option">Lock</button>`
      }`
    );
    lockOption.addEventListener('click', () => lockFunction(doc.id));
  }

  const options = [
    moveToFrontOption,
    moveToBackOption,
    !data.locked ? pinOption : null,
    window.elementHandlers[doc.id] &&
    window.elementHandlers[doc.id].pinnedElements &&
    window.elementHandlers[doc.id].pinnedElements.length
      ? unpinOption
      : null,
    !hasPinnedElements(doc.id) || isInteractionLockAllowed ? lockOption : null,
  ].filter((item) => !!item);

  // TODO: let handlers define if its rotatable/mirroring element themselves?
  if (data.class !== 'WhiteboardElement') {
    const mirrorOption = htmlToElement('<button class="options-menu-option">Mirror</button>');
    mirrorOption.addEventListener('click', () => mirror(doc.id));
    options.push(mirrorOption);
  }

  if (data.class !== 'CameraElement' && data.class !== 'ScreenshareElement') {
    const duplicateOption = htmlToElement(`
    <div class="options-menu-option duplicate-option">
      <button>Duplicate</button>
      <span class="locked-text">( <img src="${disableAddContentLockIcon}" /> Locked by Admin )</span>
    </div>
    `);

    duplicateOption.addEventListener('click', () => {
      if (!duplicateOption.classList.contains('disabled')) {
        duplicate(doc.id, { source: 'Duplication' });
      }
    });
    options.push(duplicateOption);

    addUserCanAddContentSubscriber((userCanAddContentUpdated) => {
      duplicateOption.querySelector('button').disabled = !userCanAddContentUpdated;
      if (userCanAddContentUpdated) {
        duplicateOption.style.opacity = '1';
        duplicateOption.classList.remove('disabled');
        duplicateOption.classList.add('uk-dropdown-close');
      } else {
        duplicateOption.style.opacity = '0.6';
        duplicateOption.classList.add('disabled');
        duplicateOption.classList.remove('uk-dropdown-close');
      }
    });
  }

  // Add other elements as well? Or otherwise move it to the Image handler
  if (data.class === 'ImageElement' && isHereEmployeeValue) {
    const deckOption = htmlToElement(`
    <div class="options-menu-option deck-option">
      <button>Make Deck</button>
      <span class="locked-text">( <img src="${disableAddContentLockIcon}" /> Locked by Admin )</span>
    </div>
    `);
    const undeckOption = htmlToElement(`
    <div class="options-menu-option undeck-option">
      <button>Undo Deck</button>
      <span class="locked-text">( <img src="${disableAddContentLockIcon}" /> Locked by Admin )</span>
    </div>
    `);
    deckOption.addEventListener('click', () => {
      track(ENABLE_DECK, { elementType: data.class });
      updateElement(doc.id, { isDeck: true });
    });
    undeckOption.addEventListener('click', () => {
      track(DISABLE_DECK, { elementType: data.class });
      updateElement(doc.id, { isDeck: false });
    });
    options.push(undeckOption, deckOption);
  }

  return options;
}

export function updateElementAttributes(element, elementDoc, interactionsOnly = false) {
  const data = elementDoc.data();
  if (!interactionsOnly) {
    element.style.width = `${Math.round(data.size[0])}px`;
    element.style.height = `${Math.round(data.size[1])}px`;
    applyElementTransform(element, data, false);
    const id = element.id.substring(element.id.lastIndexOf('-') + 1, element.id.length);
    if (id === idPlaceholder) element.style.zIndex = window.getFrontZIndex();
    else element.style.zIndex = data.zIndex ? data.zIndex : zIndexCenter;

    const optEl = element.querySelector(`options-${elementDoc.id} ul`);
    if (optEl) {
      optEl.innerHTML = '';
      optEl.append(...elementOptions(elementDoc));
    }

    const unlockButton = element.querySelector('.element-option-button.unlock');
    if (data.locked || data.lockedInteraction) {
      unlockButton.style.display = 'block';
      if (data.locked && !data.lockedInteraction) {
        unlockButton.setAttribute('uk-tooltip', 'title: Locked Position');
      } else if (!data.locked && data.lockedInteraction) {
        unlockButton.setAttribute('uk-tooltip', 'title: Locked Interaction');
      } else if (data.locked && data.lockedInteraction) {
        unlockButton.setAttribute('uk-tooltip', 'title: Locked');
      }
    } else if (unlockButton) {
      unlockButton.style.display = 'none';
    }
  }

  const elContainer = element.querySelector('.element-container');
  if (elContainer) {
    if (data.lockedInteraction && !isLockAllowed() && data.class !== 'MediaPlayerElement') {
      elContainer.classList.add('ignore-pointer');
    } else {
      elContainer.classList.remove('ignore-pointer');
    }
  }
  const overflowButton = document.querySelector(`.element-option-button.element-overflow`);
  if ((data.locked && isLockAllowed()) || !data.locked) {
    if (overflowButton) {
      overflowButton.classList.remove('hidden');
    }
  }

  const elementMenuBar = element.querySelector('.element-menu-bar');
  if (elementMenuBar) {
    if (data.lockedInteraction !== true) {
      elementMenuBar.classList.remove('hidden');
    }
  }

  element?.closest('.boardElement')?.classList[data.isDeck ? 'add' : 'remove']('deck-element');
}

function moveToFrontTemporarily(id) {
  if (idPlaceholder === id) {
    return;
  }

  idPlaceholder = id;
  const elementId = `element-${id}`;
  const el = document.getElementById(elementId);
  zIndexPlaceholder = parseInt(el.style.zIndex, 10);
  el.style.zIndex = window.getFrontZIndex();
  UIkit.util.on(`#options-${id}`, 'hide', checkForElementClickAway);
  UIkit.util.on(`#add-friend-${id}`, 'hide', checkForElementClickAway);

  function checkForElementClickAway() {
    el.style.zIndex = zIndexPlaceholder;
    moveToIndex(idPlaceholder, zIndexPlaceholder);
    UIkit.util.off(`#options-${idPlaceholder}`, 'hide', checkForElementClickAway);
    UIkit.util.off(`#add-friend-${idPlaceholder}`, 'hide', checkForElementClickAway);
    zIndexPlaceholder = '';
    idPlaceholder = '';
  }
}

export default function wrapElement(
  element,
  doc,
  { classes = [], styles = '', hasViewerControls = false, preserveAspectRatio = false, additionalOptions = [] } = {}
) {
  const data = { id: doc.id, ...doc.data() };

  let pointerControls = canMove({ isLocked: data.locked, hasViewerControls }) ? 'can-move' : '';
  if (hasViewerControls) {
    pointerControls += ' viewer-controls';
  }

  const interactionControls = canInteract({ isLockedInteraction: data.lockedInteraction, hasViewerControls })
    ? ''
    : 'ignore-pointer';

  const user = firebase.auth().currentUser;

  let lockTooltip = '';
  if (data.locked && !data.lockedInteraction) {
    lockTooltip = 'Locked Position';
  } else if (!data.locked && data.lockedInteraction) {
    lockTooltip = 'Locked Interaction';
  } else if (data.locked && data.lockedInteraction) {
    lockTooltip = 'Locked';
  }
  const deckClass = data.isDeck ? ' deck-element' : '';
  const shouldShowLock = user === null ? false : data.locked || data.lockedInteraction;
  const wrapper = htmlToElement(`
    <div
      id="element-${doc.id}"
      docPath="${doc.ref.path}"
      style="width: ${Math.round(data.size[0])}px;
              height: ${Math.round(data.size[1])}px;
              z-index: ${data.zIndex ? data.zIndex : zIndexCenter};
              ${styles}"
      class="boardElement ${data.locked ? ' locked' : ''} ${
    data.lockedInteraction ? ' lockedInteraction' : ''
  } ${pointerControls} ${deckClass} ${classes.join(' ')}">
      <div class="element-container ${interactionControls}"></div>
      <div class="containing-rectangle"></div>
      ${data.class === 'CameraElement' ? '' : '<div class="tagging-container"></div>'}
      <div class="element-footer-options-translate">
        <div
          class="element-footer-options"
          style="transform: scale(${1 / window.canvasScale})"
          id="footer-menu-${doc.id}"
        >
          <div uk-tooltip="title: ${lockTooltip}" style="display: ${
    shouldShowLock ? 'block' : 'none'
  }" class="element-option-button unlock"></div>
          ${
            data.class === 'FileElement'
              ? `<a href="${data.fileURL}" target="_blank" uk-tooltip="title: Download" class="element-option-button download"></a>`
              : ''
          }
          <div id="element-overflow-menu-${doc.id}" class="element-option-button element-overflow ${
    data.locked ? 'hidden' : ''
  }"></div>
          <div
            uk-dropdown="mode: click"
            class="overflow-menu element-submenu element-options-menu"
            id="options-${doc.id}"
          >
            <ul class="uk-nav uk-dropdown-nav"></ul>
          </div>
          ${
            data.class === 'CameraElement'
              ? `<div class="element-option-button camera-add-friend-menu-button uk-open" style="display: none">
                  <img src="/images/icons/add-friend-purple.svg" class="add-icon" alt="Add friend icon" />
                  <img src="/images/icons/check-purple.svg" class="request-sent-icon" alt="Friend request is already sent" />
                </div>
                <div
                  id="add-friend-${doc.id}"
                  uk-dropdown="mode: click"
                  class="camera-add-friend-menu element-options-menu element-submenu"
                >
                  <ul class="uk-nav uk-dropdown-nav">
                    <li>
                      <button class="options-menu-option uk-dropdown-close add-friend-button">
                        Add Friend
                      </button>
                    </li>
                  </ul>
                </div>`
              : ''
          }
          ${
            data.class === 'CameraElement'
              ? `<div class="element-option-button camera-send-dm" style="display: none;">
                  <img src="/images/icons/send-dm-paperplane.svg" class="send-message-icon" alt="Send DM" />
                </div>`
              : ``
          }
          ${
            data.class === 'CameraElement' ||
            data.class === 'ScreenshareElement' ||
            getQueryStringValue('is-user-page') ||
            user === null
              ? ''
              : `<div class="element-option-button tag-user-button">@</div>`
          }
          ${
            data.class === 'WhiteboardElement' || data.class === 'FileElement' || data.class === 'BarrierElement'
              ? ''
              : `<div class="element-option-button rotate ${data.locked ? 'hidden' : ''}"></div>`
          }
          <div class="element-option-button resize${preserveAspectRatio ? ' aspect-ratio' : ''} ${
    data.locked ? 'hidden' : ''
  }"></div>
        </div>
      </div>
    </div>
  `);

  const isEditableUserCard = getCurrentBoardIsUserCard() ? window.currentBoardData.creator === user?.uid : true;
  if (!data.locked && isEditableUserCard) {
    wrapper.addEventListener('mousedown', dragElement);
  }

  // Add touch events specifically for mobile
  let hammerElementManager = { on: () => {} };
  let hammerResizeManager = { on: () => {} };
  let hammerRotateManager = { on: () => {} };
  if (checkIsMobile()) {
    hammerElementManager = new Hammer.Manager(wrapper);
    const longTouchRecognizer = new Hammer.Press({ time: LONG_PRESS_DELAY });
    hammerElementManager.add(longTouchRecognizer);

    const resizeControl = wrapper.querySelector('.resize');
    if (resizeControl) {
      hammerResizeManager = new Hammer.Manager(resizeControl, {
        recognizers: [[Hammer.Press]],
      });
      hammerResizeManager.on('press', (e) => {
        if (e.pointerType === 'touch') {
          dragElement(e);
        }
      });
    }

    const rotateControl = wrapper.querySelector('.rotate');
    if (rotateControl) {
      hammerRotateManager = new Hammer.Manager(rotateControl, {
        recognizers: [[Hammer.Press]],
      });
      hammerRotateManager.on('press', (e) => {
        if (e.pointerType === 'touch') {
          dragElement(e);
        }
      });
    }
  }

  hammerElementManager.on('press', (e) => {
    if (e.pointerType === 'touch') {
      window.navigator.vibrate(100);
      dragElement(e);
    }
  });

  const handleRoleChange = async () => {
    updateElementAttributes(element, doc, true);
  };

  onRoleChange(handleRoleChange);

  wrapper
    .querySelectorAll('.element-option-button.element-overflow, .element-option-button.camera-add-friend-menu-button')
    .forEach((el) => el.addEventListener('click', moveToFrontTemporarily.bind(null, doc.id)));

  const unlockOption = wrapper.querySelector('.element-option-button.unlock');
  if (unlockOption) {
    unlockOption.addEventListener('click', () => {
      if (isLockAllowed()) {
        unlockElement(doc.id);
        unlockElementInteraction(doc.id);
      }
    });
  }

  // Dynamically add menu options right before the ... menu is opened.
  const overflowMenu = wrapper.querySelector('.overflow-menu');
  overflowMenu.addEventListener('beforeshow', (e) => {
    if (e.target !== overflowMenu) {
      return;
    }
    const rawOptions =
      additionalOptions instanceof Function
        ? [...elementOptions(doc), ...additionalOptions()]
        : [...elementOptions(doc), ...additionalOptions];

    if (data.class !== 'CameraElement' && data.class !== 'ScreenshareElement' && canDeleteContent()) {
      const deleteOption = htmlToElement('<button class="options-menu-option" style="color: #f6335d;">Delete</button>');
      deleteOption.addEventListener('click', () => {
        deleteElement(doc.id).catch((error) => {
          // The document probably doesn't exist.
          log.error('Error removing document: ', error);
        });
      });

      rawOptions.push(deleteOption);
    }

    const options = rawOptions.map((o) => {
      if (o.nodeName.toLowerCase() !== 'a' && !o.classList.contains('disabled')) {
        // uk-dropdown-close blocks links from opening
        o.classList.add('uk-dropdown-close');
      }

      const li = document.createElement('li');
      li.append(o);
      return li;
    });
    wrapper.querySelector('.overflow-menu .uk-dropdown-nav').append(...options);
  });

  overflowMenu.addEventListener('hidden', (e) => {
    if (e.target !== overflowMenu) {
      return;
    }
    wrapper.querySelector('.overflow-menu .uk-dropdown-nav').innerHTML = '';
  });

  wrapper.querySelector('.element-container').append(element);

  if (!element.querySelector('.rotatable-container')) {
    // If an element doesn't specify it, we gonna rotate itself!
    element.classList.add('rotatable-container');
  }
  applyElementTransform(wrapper, data);

  wrapper
    .querySelector('.tag-user-button')
    ?.addEventListener('click', () => eventBus.dispatch(taggingWindowRequested, doc.id));

  return wrapper;
}
