import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setUsernameWithValidation, submitUsernameScreen } from '../../store/signing-in/actions.ts';

// store
import { selectUsername, selectUsernameValidationResult } from '../../store/signing-in/selectors.ts';

// components
import FlowInput from '../FlowInput.tsx';
import FlowInputsContainer from '../FlowInputsContainer.tsx';
import {
  ContainerDefault,
  InputContainer,
  NavButtonsContainer,
  NextNavButton,
  ScreenSubTitle,
  ScreenTitle,
  TitleContainer,
} from '../shared-styled.ts';
import { applyTheme } from '../../../util/theming-util';
import { track } from '../../../util/analytics-util';
import { ONBOARDING_STEP_VIEW } from '../../../constants/analytics-events/onboarding-events.ts';

const CreateUsernameScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    track(ONBOARDING_STEP_VIEW, { step: 'Create Username' });
  }, []);

  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef?.current) {
      // clearing any previous theme set in a parent div
      applyTheme({}, 'Inter', containerRef.current);
    }
  }, []);

  const username = useSelector(selectUsername);
  const validationResult = useSelector(selectUsernameValidationResult);
  const handleUsernameChange = useCallback(
    async (e) => {
      dispatch(setUsernameWithValidation({ username: e.target.value }));
    },
    [dispatch]
  );

  const onNextClick = () => {
    dispatch(submitUsernameScreen());
  };

  const errorMessage = !validationResult.isValid ? validationResult.message : '';

  return (
    <ContainerDefault ref={containerRef}>
      <TitleContainer>
        <ScreenTitle>Claim Your Username</ScreenTitle>
        <ScreenSubTitle textColor="#101010b2">
          Having a unique username makes it easier for friends to find you. You can always change this later.
        </ScreenSubTitle>
      </TitleContainer>

      <FlowInputsContainer isValid={validationResult.isValid} onSubmit={onNextClick}>
        <InputContainer>
          <FlowInput
            label="Username"
            errorMessage={errorMessage}
            id="set-username-input"
            checkResult={validationResult.isChecking || validationResult.isGenerating ? null : validationResult.isValid}
            prefix="@"
            value={username}
            onChange={handleUsernameChange}
            autoFocus
            testId="create-account-username-input-field"
          />
        </InputContainer>
      </FlowInputsContainer>

      <NavButtonsContainer>
        <NextNavButton
          onClick={onNextClick}
          disabled={!validationResult.isValid || validationResult.isChecking}
          data-testid="next-button"
        >
          Next
        </NextNavButton>
      </NavButtonsContainer>
    </ContainerDefault>
  );
};

export default CreateUsernameScreen;
