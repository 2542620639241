import { useEffect, useState, useRef } from 'react';

import eventBus, { userTyping } from '../../../event-bus';

import { typingStatuses, TYPING_USER_IN_LIST_VISIBILITY_MS } from '../../../constants/chat-constants';

export function useTypingUsersList(target) {
  const timeoutIdsMap = useRef({});

  const [typingUsersList, setTypingUsersList] = useState([]);

  useEffect(() => {
    function onUserTyping(userTypingUpdate) {
      if (target === userTypingUpdate.target) {
        switch (userTypingUpdate.typingStatus) {
          case typingStatuses.ACTIVE: {
            clearTimeout(timeoutIdsMap.current[userTypingUpdate.userId]);

            if (!typingUsersList.find((typingUser) => typingUser.id === userTypingUpdate.userId)) {
              setTypingUsersList([...typingUsersList, { id: userTypingUpdate.userId }]);
            }

            timeoutIdsMap.current[userTypingUpdate.userId] = setTimeout(() => {
              setTypingUsersList((prevTypingUsersList) =>
                prevTypingUsersList.filter((typingUser) => typingUser.id !== userTypingUpdate.userId)
              );
            }, TYPING_USER_IN_LIST_VISIBILITY_MS);

            break;
          }

          case typingStatuses.FINISHED: {
            setTypingUsersList(typingUsersList.filter((typingUser) => typingUser.id !== userTypingUpdate.userId));
            break;
          }

          default: {
            throw new Error('Wrong user typing state');
          }
        }
      }
    }

    eventBus.on(userTyping, onUserTyping);

    return () => {
      eventBus.off(userTyping, onUserTyping);
    };
  }, [typingUsersList, target]);

  return { typingUsersList };
}
