import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { friendsAsArraySelector } from '../../../../store/friends/selectors';
import { noScrollbarMixin, svgColorMixin } from '../../../../mixins';
import FriendSearchIcon from '../../../../../../assets/icons/friends-v3/search-friends.svg';
import FriendItem from './FriendItem';

interface Props {
  selectedFriends: string[];
  onFriendClick: (userId: string) => void;
}

const AddFriendsSettings: React.FC<Props> = ({ selectedFriends = [], onFriendClick }) => {
  const friends = useSelector(friendsAsArraySelector);

  const [searchFocused, setSearchFocused] = useState(false);
  const [friendSearch, setFriendSearch] = useState('');

  const filteredUsersList = useMemo(
    () =>
      friends.filter((userData) => {
        let nameMatch = false;
        if (userData.displayName) {
          nameMatch = userData.displayName.toLowerCase().includes(friendSearch.toLowerCase());
        }

        let usernameMatch = false;
        if (userData.username) {
          usernameMatch = userData.username.toLowerCase().includes(friendSearch.toLowerCase());
        }

        return nameMatch || usernameMatch;
      }),
    [friends, friendSearch]
  );

  return (
    <>
      <FriendSearchContainer isFocused={searchFocused}>
        <StyledFriendSearchIcon />
        <TextInput
          placeholder="Search for friends..."
          value={friendSearch}
          onChange={(e) => setFriendSearch(e.target.value)}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
        />
      </FriendSearchContainer>
      <FriendsContainer>
        {filteredUsersList.map((f) => (
          <FriendItemContainer key={f.userId}>
            <FriendItem
              friendId={f.userId}
              isSelected={selectedFriends.includes(f.userId)}
              onSelect={() => onFriendClick(f.userId)}
            />
          </FriendItemContainer>
        ))}
      </FriendsContainer>
    </>
  );
};

export default AddFriendsSettings;

const FriendSearchContainer = styled.div<{ isFocused: boolean }>`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  outline: 1px solid var(--primary-foreground-alpha-20);
  box-shadow: 0 4px 10px 0 var(--primary-foreground-alpha-10);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;

  margin-bottom: 3px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isFocused }) => isFocused && 'outline: 2px solid #12002d33;'}
`;

const StyledFriendSearchIcon = styled(FriendSearchIcon)`
  ${svgColorMixin('var(--primary-foreground)')}
`;

const TextInput = styled.input`
  font-size: 12px;
  font-weight: bold;
  color: var(--primary-foreground);
  outline: none;
  background: none;
  border: none;
  flex-grow: 1;
  margin-left: 10px;

  &::placeholder {
    font-weight: normal;
    color: var(--primary-foreground-alpha-50);
  }

  &:focus {
    outline: none;
  }
`;

const FriendsContainer = styled.div`
  overflow-y: scroll;
  flex-grow: 1;

  ${noScrollbarMixin()}
`;

const FriendItemContainer = styled.div`
  margin-top: 6.5px;
`;
