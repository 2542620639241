import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/fp/isEqual';
import styled from 'styled-components';
import { flattenDeep } from 'lodash/fp';
import { useSelector } from 'react-redux';

import { ConditionalWrapper } from '../../components/ConditionalWrapper';
import ChatReactionLabelsList from './reactions/ChatReactionLabelsList';
import RoomCardMessage from './RoomCardMessage';
import SkinningDecoratorWrapper from '../../os/chat/skinning/decorator/SkinningDecoratorWrapper.tsx';

import getAddElementFunctionForUrl from '../../../widgets/embedded';
import { isElementsInteractionAllowed } from '../../../roles-management';
import { sanitize, getEmojiCount, containsText } from '../../../util';
import { isEmoji } from '../../../util/string-util';
import { embedMessageTypes, EMOJI_COUNT_THRESHOLD } from '../../../constants/chat-constants';
import { isHereRoomURL } from '../../../util/room-util';
import { selectCurrentUserId } from '../../store/users/selectors';
import MessageContextMenu from './context-menu/MessageContextMenu.tsx';

function TextMessage(props) {
  const {
    id,
    nextCreator,
    creator,
    url,
    text,
    emojiReactions,
    roomChatMessageOutsideOfRoom,
    linkSummary,
    deleteMessage,
    userCanAddContent,
    messageColor,
    fontColor,
    fontSize,
    fontFamily,
    dmChatId,
    style,
    decorator,
  } = props;

  const currentUserId = useSelector(selectCurrentUserId);

  const [embedButtonClicked, setEmbedButtonClicked] = React.useState(false);

  const canAddToRoom =
    userCanAddContent && !embedButtonClicked && !roomChatMessageOutsideOfRoom && linkSummary?.youtubeVideoId;
  // For now all embeds can be added to the mural, but in the future we might want to check its type
  const canAddToHub = !embedButtonClicked && linkSummary?.embed;

  const addEmbeddedResource = url && getAddElementFunctionForUrl(url);
  const interactiveElementsVisibilityClassName = isElementsInteractionAllowed() ? '' : 'hidden';

  const messageText = text || '';
  const isAnEmoji =
    isEmoji(messageText) && getEmojiCount(messageText) < EMOJI_COUNT_THRESHOLD && !containsText(messageText);

  let finalFontSize = fontSize;
  if (isAnEmoji) finalFontSize = 'auto';

  async function onEmbedVideoButtonClick() {
    if (isElementsInteractionAllowed()) {
      const { default: MediaPlayerElement } = await import('../../../media-player/media-player');
      MediaPlayerElement.addElement(url);
    }

    setEmbedButtonClicked(true);
  }

  function onEmbeddedButtonClick() {
    addEmbeddedResource();
    setEmbedButtonClicked(true);
  }

  const checkMessageForURLs = useCallback((message) => {
    const urlWithProtocolRegex = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    const parts = message.split(urlWithProtocolRegex);

    const partsWithProtocol = parts.map((part, i) => {
      if (urlWithProtocolRegex.test(part)) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <URL key={i} href={part} target="_blank" style={{ textDecoration: 'underline' }} rel="noreferrer">
            {part}
          </URL>
        );
      }
      if ((part === 'https' || part === 'http') && urlWithProtocolRegex.test(parts[i - 1])) return '';
      return part;
    });

    const partsWithoutProtocol = partsWithProtocol.map((part) => {
      if (typeof part !== 'string') return part;

      const noProtocolRegex = /(^|[^/])(www\.[-A-Z0-9_]+\.[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]+(\b|$))/gim;
      const subParts = part.split(noProtocolRegex);

      return subParts.map((subPart, i) => {
        if (noProtocolRegex.test(subPart)) {
          return (
            <URL
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              href={`https://${subPart}`}
              target="_blank"
              style={{ textDecoration: 'underline' }}
              rel="noreferrer"
            >
              {subPart}
            </URL>
          );
        }
        return subPart;
      });
    });

    return flattenDeep(partsWithoutProtocol);
  }, []);
  const [hereRoomId, setHereRoomId] = React.useState(false);
  const [renderRoomCardMessageOnly, setRenderRoomCardMessageOnly] = React.useState(false);

  useEffect(() => {
    (async () => {
      const { roomId = false, messageIsUrl = false } = (await isHereRoomURL(text)) || {};
      setHereRoomId(roomId);
      setRenderRoomCardMessageOnly(messageIsUrl && roomId);
    })();
  }, [text]);

  if (renderRoomCardMessageOnly) {
    return (
      <RoomCardMessage
        messageId={id}
        dmChatId={dmChatId}
        creator={creator}
        roomId={hereRoomId}
        isInvitorBlockVisible={false}
        deleteMessage={deleteMessage}
      />
    );
  }

  const bottomMsgClass = currentUserId === creator ? 'bottom-message-bubble-self' : 'bottom-message-bubble';

  return (
    <div className="feed-text-message" style={style}>
      <div className="message-container">
        <MessageContextMenu chatId={dmChatId} text={text} creator={creator} deleteSelf={() => deleteMessage(id)} />

        <div
          className={`dont-drag-me ${nextCreator !== creator && !hereRoomId ? bottomMsgClass : 'message-bubble'} ${
            isAnEmoji ? 'image-message-bubble' : ''
          } ${decorator?.contentType === 'inside' ? 'inside-decorator' : ''}`}
          style={{ backgroundColor: `${messageColor}`, lineHeight: finalFontSize }}
        >
          <SkinningDecoratorWrapper decorator={decorator}>
            {messageText.split('\n').map((messageLine, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ color: fontColor, fontSize: finalFontSize, fontFamily }}
                className={`message-text ${isAnEmoji ? `message-emoji` : ``}`}
              >
                {checkMessageForURLs(messageLine)}
              </div>
            ))}
            <ConditionalWrapper
              condition={!canAddToHub && !canAddToRoom && !addEmbeddedResource}
              wrapper={(children) => (
                <a href={sanitize(url)} target="_blank" rel="noreferrer">
                  {children}
                </a>
              )}
            >
              <>
                {linkSummary &&
                (linkSummary.image || linkSummary.title || linkSummary.description) &&
                // we don't want to show the link summary outside of the own feed
                !(roomChatMessageOutsideOfRoom && addEmbeddedResource) &&
                !hereRoomId ? (
                  <div className="message-link-summary">
                    {linkSummary.image && !linkSummary.embed ? (
                      <img className="message-link-image" src={sanitize(linkSummary.image)} alt="message link" />
                    ) : null}

                    {linkSummary.title && !linkSummary.embed ? (
                      <h2 className="message-link-title">{linkSummary.title}</h2>
                    ) : null}

                    {linkSummary.description && !linkSummary.embed ? (
                      <p className="message-link-description">{linkSummary.description}</p>
                    ) : null}
                    {linkSummary.embed ? (
                      <Iframe scrolling="no" src={sanitize(linkSummary.embed.url)} type={linkSummary.embed.type} />
                    ) : null}

                    {canAddToHub || canAddToRoom ? (
                      <div className="message-add-element-prompt">
                        <button
                          type="button"
                          className={`embed-element-button add-youtube-button ${interactiveElementsVisibilityClassName}`}
                          message-url={url}
                          onClick={onEmbedVideoButtonClick}
                        >
                          Add to Room
                        </button>
                      </div>
                    ) : null}

                    {userCanAddContent &&
                    addEmbeddedResource &&
                    !embedButtonClicked &&
                    !roomChatMessageOutsideOfRoom ? (
                      <div className="message-add-element-prompt ">
                        <button
                          type="button"
                          className={`embed-element-button add-embedded-button ${interactiveElementsVisibilityClassName}`}
                          message-url={url}
                          onClick={onEmbeddedButtonClick}
                        >
                          Add to room
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            </ConditionalWrapper>
          </SkinningDecoratorWrapper>
        </div>
      </div>

      {!dmChatId && <ChatReactionLabelsList emojiReactions={emojiReactions} messageId={id} />}

      {hereRoomId ? (
        <div className="message-container room-card-message">
          <RoomCardMessage
            messageId={id}
            dmChatId={dmChatId}
            creator={creator}
            roomId={hereRoomId}
            isInvitorBlockVisible={false}
            deleteMessage={deleteMessage}
          />
        </div>
      ) : null}
    </div>
  );
}

TextMessage.propTypes = {
  id: PropTypes.string.isRequired,
  nextCreator: PropTypes.string,
  creator: PropTypes.string.isRequired,
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  messageColor: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  userCanAddContent: PropTypes.bool.isRequired,
  emojiReactions: PropTypes.arrayOf(PropTypes.shape({})),
  roomChatMessageOutsideOfRoom: PropTypes.bool,
  linkSummary: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    youtubeVideoId: PropTypes.string,
    tiktokVideoId: PropTypes.string,
    embed: PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  dmChatId: PropTypes.string,
  style: PropTypes.shape({
    marginBottom: PropTypes.string,
  }),
  decorator: PropTypes.shape({
    contentType: PropTypes.string,
  }),
};

TextMessage.defaultProps = {
  nextCreator: null,
  url: '',
  linkSummary: null,
  messageColor: '#612cda',
  fontColor: 'white',
  fontSize: '16px',
  fontFamily: 'Inter',
  emojiReactions: [],
  roomChatMessageOutsideOfRoom: false,
  dmChatId: '',
  style: null,
  decorator: null,
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

export default React.memo(TextMessage, areEqual);

const URL = styled.a`
  text-decoration: underline;
`;

const iframeHeight = {
  [embedMessageTypes.TIKTOK]: 570,
  [embedMessageTypes.YOUTUBE_SHORTS]: 400,
};
const Iframe = styled.iframe`
  grid-area: message-link--embed;
  height: ${({ type }) => iframeHeight[type] || 200}px;
  width: 100%;
`;
