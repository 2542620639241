import React, { useRef } from 'react';

import { useSettingsTabsAdapter } from '../useSettingsTabsAdapter';

const HelpSettings = () => {
  const webComponentRef = useRef();
  useSettingsTabsAdapter(webComponentRef);
  return <here-feedback ref={webComponentRef} />;
};

export default HelpSettings;
