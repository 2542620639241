import { authedApiInstance } from '../firebase';

export const getRoomActiveTime = async (roomId: string) =>
  authedApiInstance.get<{ success: boolean; total: number }>(`/room/${roomId}/stats/total`);

export interface RoomTopMember {
  displayName: string;
  time: number;
  userId: string;
  username: string;
  avatar: {
    isProcessed: boolean;
    original: string;
    thumbnail: string;
  };
}

export const getRoomTopMembers = async (roomId: string) =>
  authedApiInstance.get<{ success: boolean; boardId: string; members: RoomTopMember[] }>(
    `/room/${roomId}/stats/topMembers`
  );
