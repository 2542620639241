import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectOrderedOwnerAndAdmins, selectBannedChatMembers } from '../../../../store/messaging/selectors';
import AdminListItem from './AdminListItem';
import BannedListItem from './BannedListItem';
import { noScrollbarMixin } from '../../../../mixins';
import { ChatPermissionsContext } from '../../../../common/contexts';

interface Props {
  chatId: string;
}

const AdminSettings = ({ chatId }: Props) => {
  const admins = useSelector((state) => selectOrderedOwnerAndAdmins(state, chatId));
  const bannedUsers = useSelector((state) => selectBannedChatMembers(state, chatId));
  const permissions = useContext(ChatPermissionsContext);

  return (
    <Container>
      <Section>
        <SectionTitle>Roles</SectionTitle>
        {admins.map((u) => (
          <AdminListItem user={u} key={u.userId} chatId={chatId} />
        ))}
      </Section>
      {bannedUsers.length && permissions.canRemoveMembers ? (
        <Section>
          <SectionTitle>Banned Users</SectionTitle>
          {bannedUsers.map((u) => (
            <BannedListItem memberId={u.userId} key={u.userId} chatId={chatId} />
          ))}
        </Section>
      ) : null}
    </Container>
  );
};

export default AdminSettings;

const Container = styled.div`
  background: var(--primary-background);
  height: 100%;
  padding: 16px;
  overflow: auto;
  ${noScrollbarMixin()}
`;

const Section = styled.div`
  margin-bottom: 16px;
`;

const SectionTitle = styled.div`
  color: var(--primary-foreground);
  font-size: 12px;
  font-weight: bold;
`;
