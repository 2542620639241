import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useSelector } from 'react-redux';

import { useUserProfileData } from '../hooks/useUserProfileData';
import UserAvatar from '../components/UserAvatar';
import { markTagAsSeen } from './api';
import { selectCurrentUserId } from '../store/users/selectors';

const TagAvatar = ({ tag, borderColor }) => {
  const currentUserId = useSelector(selectCurrentUserId);
  const { userProfile: senderProfile } = useUserProfileData(tag.senderId);
  const { userProfile: receiverProfile } = useUserProfileData(tag.receiverId);

  const onOpenChange = useCallback(
    (isOpened) => {
      if (isOpened && !tag.seenAt && tag.receiverId === currentUserId) {
        markTagAsSeen(tag.id);
      }
    },
    [tag, currentUserId]
  );

  return (
    <Tooltip.Root onOpenChange={onOpenChange}>
      <Tooltip.Trigger asChild>
        <AvatarContainer>
          <UserAvatar userProfile={receiverProfile} customBorderColor={borderColor} borderWidth={2} />
        </AvatarContainer>
      </Tooltip.Trigger>
      <TooltipContent side="top">
        {senderProfile.displayName} tagged @{receiverProfile.username}
      </TooltipContent>
    </Tooltip.Root>
  );
};

export default TagAvatar;

TagAvatar.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    senderId: PropTypes.string,
    receiverId: PropTypes.string,
    seenAt: PropTypes.instanceOf(Date),
  }).isRequired,
  borderColor: PropTypes.string.isRequired,
};

const AvatarContainer = styled.div`
  width: 42px;
  height: 42px;
`;

const TooltipContent = styled(Tooltip.Content)`
  background: #12002d;
  color: white;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: bold;
`;
