// icons
import DuplicateRoomIcon from '../../assets/icons/room-options/duplicate-room.svg';
import CopyRoomIcon from '../../assets/icons/room-options/copy-room-link.svg';
import DeleteRoomIcon from '../../assets/icons/room-options/delete-room.svg';
import LeaveRoomIcon from '../../assets/icons/dismiss.svg';
import OpenInDesktopIcon from '../../assets/icons/room-options/open-in-desktop.svg';

export const COPY_ROOM_LINK_OPTION = {
  id: 'copy-link',
  order: 1,
  Icon: CopyRoomIcon,
  title: 'Copy Link',
};

export const OPEN_IN_DESKTOP_OPTION = {
  id: 'open-in-desktop',
  order: 2,
  Icon: OpenInDesktopIcon,
  title: 'Open in Desktop',
};

export const DUPLICATE_ROOM_OPTION = {
  id: 'duplicate-room',
  order: 3,
  Icon: DuplicateRoomIcon,
  title: 'Duplicate Room',
};

export const DELETE_ROOM_OPTION = {
  id: 'delete-room',
  order: 4,
  Icon: DeleteRoomIcon,
  title: 'Delete Room',
};

export const LEAVE_ROOM_OPTION = {
  id: 'leave-room',
  order: 5,
  Icon: LeaveRoomIcon,
  title: 'Leave Room',
};

export const PREVIEW_ITEMS_NUMBER = 3;

// modals
export const BANNED_BY_MODAL = 'BANNED_BY_MODAL';
export const KICKED_BY_MODAL = 'KICKED_BY_MODAL';
export const VIEWERS_MODE_FINISHED_MODAL = 'VIEWERS_MODE_FINISHED_MODAL';
export const REMOVED_ROOM_MODAL = 'REMOVED_ROOM_MODAL';
export const QUALITY_SURVEY_MODAL = 'QUALITY_SURVEY_MODAL';
export const MOBILE_UPSELL_MODAL = 'MOBILE_UPSELL_MODAL';
export const INVITE_FRIENDS_TO_GROUP = 'INVITE_FRIENDS_TO_GROUP';
export const LINK_SCHOOL_MODAL = 'LINK_SCHOOL_MODAL';

// quality survey screens
export const QUALITY_SURVEY_RATINGS_SCREEN = 'QUALITY_SURVEY_RATINGS_SCREEN';
export const QUALITY_SURVEY_DETAILS_SCREEN = 'QUALITY_SURVEY_DETAILS_SCREEN';

// local storage constants
export const HIDE_VIDEO_QUALITY_SURVEY = 'hideVideoQualitySurvey';
export const HIDE_ONE_TIME_MOBILE_UPSELL = 'hideOneTimeMobileUpsellLobbyModal';

// query param constants
export const BANNED_BY_QUERY_PARAM = 'bannedBy';
export const KICKED_BY_QUERY_PARAM = 'kickedBy';
export const REMOVED_ROOM_QUERY_PARAM = 'removedRoom';
export const QUALITY_SURVEY_QUERY_PARAM = 'lastActivity';
export const MOBILE_UPSELL_QUERY_PARAM = 'showMobileCTA';
