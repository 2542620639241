import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import UnreadsBadge, { UnreadsBadgeContainer } from './UnreadsBadge';
import UserAvatarWithStatus from '../../components/UserAvatarWithStatus';
import LottieAnimation from '../../animations/LottieAnimation';

import { useUserProfileData } from '../../hooks/useUserProfileData';

// utils
import { isOfficialHereUser } from '../../../util/user-util';

// assets
import animationData from '../../../../assets/lottie/avatar_sparkles.json';

// Avatar with unread count badge and online status indicator
const MessageSidebarAvatar = ({ user, unreadCount, onClick, isActiveChat }) => {
  const { userProfile } = useUserProfileData(user.userId);

  const onAvatarContainerClick = useCallback(() => {
    onClick(user);
  }, [onClick, user]);

  return (
    <AvatarContainer className="message-sidebar-avatar-container" onClick={onAvatarContainerClick}>
      {unreadCount > 0 && <UnreadsBadge unreadsCount={unreadCount} />}
      <UserAvatarWithStatus
        userProfile={userProfile}
        status={user?.onlineStatus?.status}
        isHereOfficialAccount={isOfficialHereUser(user)}
        customAvatarBorderColor={isActiveChat && !isOfficialHereUser(user) ? '#ffffff' : null}
        borderWidth={userProfile?.mood || isActiveChat ? 3 : null}
        borderRadius="100%"
        showNameTooltip
      />
      {isOfficialHereUser(user) && (
        <AnimationContainer>
          <LottieAnimation animationData={animationData} loop />
        </AnimationContainer>
      )}
    </AvatarContainer>
  );
};

export default MessageSidebarAvatar;

MessageSidebarAvatar.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.string,
    displayName: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    onlineStatus: PropTypes.shape({
      status: PropTypes.string,
    }),
    email: PropTypes.string,
  }).isRequired,
  unreadCount: PropTypes.number,
  onClick: PropTypes.func,
  isActiveChat: PropTypes.bool,
};

MessageSidebarAvatar.defaultProps = {
  unreadCount: 0,
  onClick: () => {},
  isActiveChat: false,
};

export const AvatarContainer = styled.div`
  position: relative;
  width: 42px;
  height: 42px;
  margin: 6px 0px;

  border-radius: 50%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);

  transition: transform 0.1s ease-in;

  &:hover {
    transform: scale(1.1);
  }

  ${({ onClick }) => onClick && `cursor: pointer;`}

  ${UnreadsBadgeContainer} {
    position: absolute;
    margin-left: 27px;
    margin-top: -7px;
  }
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: -12px;
  left: -12px;
  pointer-events: none;

  width: 68px;
  height: 68px;
`;
