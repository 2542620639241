import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { chatCallState } from '../../../../constants/chat-constants';
import LottieAnimation from '../../../animations/LottieAnimation';

import callingIcon from '../../../../../assets/lottie/calling-phone.json';
import CallIcon from '../../../../../assets/icons/call.svg';
import DisabledCallIcon from '../../../../../assets/icons/disabled-call.svg';

const CallButton = ({ callState, onCall }) => {
  if (callState === chatCallState.ENABLE) {
    return (
      <CallButtonContainer>
        <CallIconWrapper onClick={onCall}>
          <CallIcon />
        </CallIconWrapper>
      </CallButtonContainer>
    );
  }
  if (callState === chatCallState.IS_CALLING) {
    return (
      <CallButtonContainer>
        <LottieAnimation animationData={callingIcon} loop />;
      </CallButtonContainer>
    );
  }
  if (callState === chatCallState.DISABLE) {
    return (
      <CallButtonContainer>
        <DisabledCallIcon />
      </CallButtonContainer>
    );
  }
  return null;
};

export default CallButton;

CallButton.propTypes = {
  callState: PropTypes.oneOf(Object.values(chatCallState)).isRequired,
  onCall: PropTypes.func.isRequired,
};

const CallButtonContainer = styled.div`
  height: 28px;
  width: 28px;
`;

const CallIconWrapper = styled.div`
  cursor: pointer;
`;
