import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { messagesReducer } from './messaging/reducer';
import messagesSaga from './messaging/sagas';
import messagesTsSaga from './messaging/sagasTs.ts';

import roomSaga from './room/sagas';
import { roomReducer } from './room/store';

import newRoomSaga from './new-room/sagas.ts';
import { newRoomReducer } from './new-room/reducer.ts';

import templateSaga from './template/sagas';
import { templateReducer } from './template/store';

import tipsAndTricksSaga from './tips-and-tricks/sagas.ts';
import { tipsAndTricksReducer } from './tips-and-tricks/reducer.ts';

// eslint-disable-next-line import/no-cycle
import imageSearchSaga from './image-search/sagas';
import { imageSearchReducer } from './image-search/store';

// eslint-disable-next-line import/no-cycle
import gifSearchSaga from './gif-search/sagas';
import { gifSearchReducer } from './gif-search/store';

import membersSaga from './room-members/sagas';
import { membersReducer } from './room-members/store';

import { friendsReducer } from './friends/store';
import friendsSaga from './friends/sagas';

import { usersReducer } from './users/store';
import usersSaga from './users/sagas';

import { animationsReducer } from './animations/store';
import animationsSaga from './animations/sagas';

import { webLobbyReducer } from './web-lobby/reducer.ts';
import webLobbySaga from './web-lobby/sagas.ts';

import { signingInReducer } from './signing-in/reducer.ts';
import signingInSaga from './signing-in/sagas';

import { visitedRoomsReducer } from './visited-rooms/reducer.ts';
import visitedRoomsSaga from './visited-rooms/sagas.ts';

import groupsSaga from './groups/sagas.ts';
import { groupsReducer } from './groups/reducer.ts';

import emotesSaga from './emotes/sagas.ts';
import { emotesReducer } from './emotes/reducer.ts';

import kaomojiSaga from './kaomoji/sagas.ts';
import { kaomojiReducer } from './kaomoji/reducer.ts';

import osSaga from './os/sagas.ts';
import { osReducer } from './os/reducer.ts';

import chatSkinningSaga from './chat-skinning/sagas.ts';
import { chatSkinningReducer } from './chat-skinning/reducer.ts';

import serializeMiddleware from './serialize-middleware';

import reduxAdapter from './redux-adapter';
import { isProductionEnv } from '../../firebase';

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    gifSearchSaga(),
    imageSearchSaga(),
    membersSaga(),
    templateSaga(),
    tipsAndTricksSaga(),
    roomSaga(),
    newRoomSaga(),
    friendsSaga(),
    messagesSaga(),
    messagesTsSaga(),
    usersSaga(),
    animationsSaga(),
    visitedRoomsSaga(),
    webLobbySaga(),
    signingInSaga(),
    groupsSaga(),
    emotesSaga(),
    kaomojiSaga(),
    osSaga(),
    chatSkinningSaga(),
  ]);
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer(); // Note: no optional configs for now

const store = configureStore({
  reducer: {
    gifSearch: gifSearchReducer,
    imageSearch: imageSearchReducer,
    members: membersReducer,
    template: templateReducer,
    tipsAndTricks: tipsAndTricksReducer,
    room: roomReducer,
    newRoom: newRoomReducer,
    friends: friendsReducer,
    messages: messagesReducer,
    users: usersReducer,
    animations: animationsReducer,
    webLobby: webLobbyReducer,
    visitedRooms: visitedRoomsReducer,
    signingIn: signingInReducer,
    groups: groupsReducer,
    emotes: emotesReducer,
    kaomoji: kaomojiReducer,
    os: osReducer,
    chatSkinning: chatSkinningReducer,
  },
  middleware: (getDefaultMiddleware) => [serializeMiddleware, ...getDefaultMiddleware(), sagaMiddleware],
  ...(isProductionEnv() && { enhancers: [sentryReduxEnhancer] }),
});

sagaMiddleware.run(rootSaga);

store.subscribe(() => reduxAdapter.dispatchStateChange(store.getState()));
reduxAdapter.setDispatchingHandler(store.dispatch.bind(store));

export default store;
