/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-explicit-any */
import JanusJS from './janus.definitions.original';
import Janus from '../../lib/janus';
import VideoroomWrapper from './videoroom';
import promisify from './promisify';
import log from '../../log';

export type JanusWrapperType = Partial<Omit<InstanceType<typeof Janus>, 'attach' | 'destroy' | 'reconnect'>> & {
  attach: (options: JanusJS.PluginOptions) => Promise<any>;
  destroy: (options: JanusJS.DestroyOptions) => Promise<any>;
  reconnect: (options?: JanusJS.ReconnectOptions) => Promise<any>;
};

export class JanusWrapper implements JanusWrapperType {
  janus: Janus;

  janusOptions: JanusJS.ConstructorOptions;

  // FIXME: The following static methods has been added to avoid
  // method missing errors. Need to be expressed in some other way since
  // they should be already proxied.
  static isWebrtcSupported() {
    return Janus.isWebrtcSupported();
  }

  static attachMediaStream(element: HTMLMediaElement, stream: MediaStream | MediaSource) {
    // FIXME: Had to implement here because method wasn't found in Janus? WTF?
    try {
      element.srcObject = stream;
    } catch (error1) {
      try {
        element.src = URL.createObjectURL(stream as MediaSource);
      } catch (error2) {
        log.error('Error attaching stream to element', error2);
      }
    }
  }

  static randomString(len: number) {
    return Janus.randomString(len);
  }

  constructor(options: JanusJS.ConstructorOptions) {
    this.janusOptions = options;
    this.janus = new Janus(options);

    // instance methods
    return new Proxy(this, {
      get: (obj, prop) =>
        prop in this ? this[prop as keyof typeof this] : this.janus[prop as keyof typeof this.janus],
    });
  }

  attach(options: JanusJS.PluginOptions) {
    const successHandler = (
      resolve: (arg: JanusJS.PluginHandle | VideoroomWrapper) => void,
      pluginHandle: JanusJS.PluginHandle
    ) => {
      const handler = options.plugin === 'janus.plugin.videoroom' ? new VideoroomWrapper(pluginHandle) : pluginHandle;
      resolve(handler);
    };

    return promisify((...args: any[]) => this.janus.attach.apply(this.janus, args), options, successHandler);
  }

  destroy(options: JanusJS.DestroyOptions) {
    return promisify((...args: any[]) => this.janus.destroy.apply(this.janus, args), options);
  }

  reconnect(options?: JanusJS.ReconnectOptions) {
    return promisify((...args: any[]) => this.janus.reconnect.apply(this.janus, args), options);
  }

  static init(options: JanusJS.InitOptions) {
    return new Promise((resolve) =>
      Janus.init({
        ...options,
        callback: resolve,
      })
    );
  }
}

// class (static) methods
const StaticJanusWrapper = new Proxy(JanusWrapper, {
  get: (_obj, prop) =>
    prop in JanusWrapper ? JanusWrapper[prop as keyof typeof JanusWrapper] : Janus[prop as keyof typeof Janus],
});

export default StaticJanusWrapper;
