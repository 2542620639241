import { authedApiInstance, db } from '../firebase';

export const createUserReport = async (reportData: object) => {
  await db.collection('userReports').add(reportData);
};

export const banUser = (chatId: string, userId: string) =>
  authedApiInstance.post(`/chat/${chatId}/${userId}/ban`).then((result) => result.data);

export const unbanUser = (chatId: string, userId: string) =>
  authedApiInstance.delete(`/chat/${chatId}/${userId}/ban`).then((result) => result.data);
