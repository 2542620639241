import $ from 'jquery';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { initParticlesEngine } from '@tsparticles/react';
import { loadFull } from 'tsparticles';

import '../auth';
import '../api/websocket-client.ts';
import '../drag';
import '../photobooth';
import '../upload';
import * as Sentry from '@sentry/browser';
import HereSFU from '../sfu';
import { collectPageLoadData, sendAnalyticsCallback } from '../collect-page-load-data';

import firebase, { isProductionEnv } from '../firebase';
import { disableViewersMode } from '../room';
import log from '../log';
import { checkIsMobile } from '../util/platform-util';
import bootstrap from '../bootstrap';
import { goToLobby } from '../util/navigation-util';
import { growthbook, growthBookRequestPromise, GROWTHBOOK_FEATURES } from '../api/growthbook-handler';
import { renderNewHomePage } from '../react/home-page/renderNewHomePage';
import { VIEWED_HOMEPAGE_CONCEPT_0, VIEWED_HOMEPAGE_CONCEPT_2 } from '../constants/analytics-events/homepage-events';

import '../components/avatar';
import '../components/invite';
import '../components/loader';
import '../components/user-name';
import '../components/users-bar';
import '../components/color-select';
import '../components/spaces-select';
import '../components/on-air';
import '../components/broadcast-camera-invite';
import '../components/broadcast-camera-requests';
import '../components/toolbar';
import '../components/feedback';
import '../components/camera-lite-mode';
import '../components/whats-new';
import '../components/here-audio-control';
import '../components/electron-windows-titlebar';
import '../components/room-loading-animation';
import '../components/electron-upsell';
import '../components/inline-svg';
import '../components/room-settings';
import '../components/chat-image-preview-modal';

import 'uikit/dist/css/uikit.css';
import '../../styles/main.less';
import { findCameraHandler } from '../util/camera-util';
import { track } from '../util/analytics-util';
import { HomepageConcepts, homepageConceptLocalStorageItem } from '../constants/homepage-constants';

collectPageLoadData()
  .then((analyticsResult) => sendAnalyticsCallback('Page Load', analyticsResult))
  .catch((err) => log.error(err));

if (isProductionEnv()) {
  Sentry.init({
    dsn: 'https://d22b5fa541404660a56ccc2888021fa9@o593841.ingest.sentry.io/5741817',
    integrations: [new Sentry.BrowserTracing()],
    // % of traces sent, from 0-1
    tracesSampleRate: 0.01,
  });
}
UIkit.use(Icons);

initParticlesEngine(async (engine) => {
  await loadFull(engine);
});

window.rtc = HereSFU;
window.currentBoardId = null;
window.currentBoardTitle = null;
window.currentBoardDefaultViewport = null;
window.currentBoardData = {};

window.userCameraId = null;
window.elementHandlers = {};

// Set up PWA service-worker

// This is a temporary change, once we switch over to Next.js then we should remove this altogether
const isNextApp = window.location.href.includes('app.sandbox.here.fm') || window.location.href.includes('new.here.fm');
if ('serviceWorker' in navigator && !isNextApp) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/pwa-service-worker.js');
  });
}

document.querySelector('#leave-broadcast-dialog .leave-and-continue-streaming').addEventListener('click', goToLobby);
document.querySelector('#leave-broadcast-dialog .leave-and-end-streaming').addEventListener('click', async () => {
  await disableViewersMode({ showConfirmation: false });
  goToLobby();
});

window.cameraPlaying = async (streamId) => {
  const element = $(`#video-${streamId}`).closest('.boardElement');
  element.removeClass('inactive-camera-element');
  element.addClass('active-camera-element');

  if (streamId === firebase.auth().currentUser.uid) {
    window.onAudioDeviceChanged();
    log.debug(`Camera started playing my own camera`);
  } else {
    log.debug(`Camera started playing user: ${streamId}`);
  }
  const handler = findCameraHandler(streamId);
  if (handler) {
    handler.videoStartedPlaying();
  } else {
    log.debug(`No handler found for stream ${streamId}`);
  }
  // FIXME: Do we need this here?
  // window.rtc.updateLocalStreamConstraints();
};

window.getFrontZIndex = () => {
  const allElements = $('#elements').find('*[id^="element"]');
  let maxZ = 0;
  allElements.each((i, e) => {
    const newZ = parseInt($(e).css('z-index'), 10);
    if (newZ > maxZ) {
      maxZ = newZ;
    }
  });
  return parseInt(maxZ, 10) + 1;
};

const isHomepage = window.location.pathname === '/';

// On startup
$(() => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user || !isHomepage) {
      bootstrap();
      return;
    }

    growthBookRequestPromise.then(() => {
      if (growthbook.isOn(GROWTHBOOK_FEATURES.homepage)) {
        // hide original homepage for now (we display #root again after sign up):
        document.getElementById('root').style.display = 'none';
        renderNewHomePage();

        localStorage.setItem(homepageConceptLocalStorageItem, HomepageConcepts.CONCEPT_2);
        track(VIEWED_HOMEPAGE_CONCEPT_2);
      } else {
        localStorage.setItem(homepageConceptLocalStorageItem, HomepageConcepts.CONCEPT_0);
        track(VIEWED_HOMEPAGE_CONCEPT_0);
      }
    });
  });
});

document.oncontextmenu = (event) => {
  if (!event) {
    return false;
  }

  const { target } = event;
  const boardElement = target.closest('.boardElement');
  const isMobile = checkIsMobile();

  if (isMobile) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  // show context menu only if unlocked board element was clicked
  if (boardElement && !boardElement.closest('.locked') && !isMobile) {
    const contextMenuButton = boardElement.querySelector('.element-overflow');
    contextMenuButton.click();
    return false;
  }

  return true;
};
