import { createReducer } from '@reduxjs/toolkit';

// store
import { initialState } from './init-state';
import {
  updateNewRoomData,
  setIsNewRoomCreating,
  setCreateRoomFlowStep,
  resetToInitState,
  setIsOnboarding,
  setNewRoomTheme,
  setNewRoomHasCustomBackground,
} from './actions';

export const newRoomReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetToInitState, () => initialState)
    .addCase(setCreateRoomFlowStep, (state, { payload }) => {
      state.step = payload.step;
    })
    .addCase(updateNewRoomData, (state, { payload }) => {
      const { data } = payload;
      Object.assign(state.newRoom, data);
    })
    .addCase(setIsNewRoomCreating, (state, { payload }) => {
      state.isNewRoomCreating = payload.isNewRoomCreating;
    })
    .addCase(setIsOnboarding, (state, { payload }) => {
      state.isOnboarding = payload.isOnboarding;
    })
    .addCase(setNewRoomTheme, (state, { payload }) => {
      state.newRoomTheme = payload.theme;
    })
    .addCase(setNewRoomHasCustomBackground, (state, { payload }) => {
      state.newRoomHasCustomBackground = payload.hasCustomBackground;
    });
});
