import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { track } from '../../util/analytics-util';
import { VIEW_USER_PAGE_CLICK } from '../../constants/analytics-events/user-page-events';
import { moreMenuItemMixin } from '../mixins';
import CardsFanIcon from '../../../assets/icons/user-cards/cards-outline-only.svg';
import { checkIsElectron } from '../../util/platform-util';
import { EventSourceContext } from '../common/contexts.ts';

const ViewUserPageMenuItem = ({ username, themeStyling }) => {
  const source = useContext(EventSourceContext);
  // if user has no username, don't add user page option as they'll be directed to /u/undefined
  // if user is on electron, don't show option as user pages are not currently on electron
  if (username && !checkIsElectron()) {
    const onViewProfilePageClick = () => {
      track(VIEW_USER_PAGE_CLICK, { source });
      window.open(`/u/${username}`);
    };

    return (
      <MenuItem
        className="uk-dropdown-close"
        role="button"
        onClick={onViewProfilePageClick}
        themeStyling={themeStyling}
      >
        <IconContainer>
          <CardsFanIcon />
        </IconContainer>
        View Page
      </MenuItem>
    );
  }

  return null;
};

export default ViewUserPageMenuItem;

ViewUserPageMenuItem.propTypes = {
  username: PropTypes.string,
  themeStyling: PropTypes.bool,
};

ViewUserPageMenuItem.defaultProps = {
  username: '',
  themeStyling: false,
};

const MenuItem = styled.div`
  ${({ themeStyling }) => moreMenuItemMixin({ themed: themeStyling })}
`;

const IconContainer = styled.div`
  margin-right: 10px;
  width: 20px;

  display: flex;
`;
