import React, { useEffect, useState } from 'react';

import { FurnitureType, furnitureCategoryFetcherMap } from './room-decoration-data';

// components
import { RoomDecorationTabContainer } from './shared-styles';
import RoomDecorationGif from './RoomDecorationGif';

interface RoomDecorationTabResolverProps {
  type: FurnitureType;
  isActive: boolean;
}

const RoomDecorationTab = ({ type, isActive }: RoomDecorationTabResolverProps) => {
  const [gifs, setGifs] = useState([]);

  useEffect(() => {
    (async () => {
      const roomDecorationCategoryData = furnitureCategoryFetcherMap[type];
      if (roomDecorationCategoryData && isActive) {
        const data = await roomDecorationCategoryData.getImages();
        setGifs(data);
      }
    })();
  }, [type, isActive]);

  return (
    <RoomDecorationTabContainer>
      {gifs.map((gif) => (
        <RoomDecorationGif key={gif.id} id={gif.id} url={gif.url} category={type} />
      ))}
    </RoomDecorationTabContainer>
  );
};

export default RoomDecorationTab;
