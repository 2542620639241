import log from '../log';
import { htmlToElement, sanitize } from '../util';
import { addSystemMessage } from '../message-util';
import { track } from '../util/analytics-util';

export default class SdkWorker {
  constructor(code, initialData, initialContext, rootElement, objId, callbacks) {
    this.objId = objId;
    this.worker = new Worker('sdk-wrapper-service-worker.js');
    this.worker.addEventListener('message', (e) => {
      const { data } = e;
      switch (data.event) {
        case 'updateData': {
          callbacks.handleDataUpdate(data.data);
          break;
        }
        case 'addElements': {
          const elements = this.markupToElements(data.elements);
          rootElement.innerHTML = '';
          elements.forEach((el) => rootElement.appendChild(el));
          break;
        }
        case 'error': {
          if (callbacks.onError) {
            callbacks.onError(data.message);
          }
          break;
        }
        case 'bootstrap': {
          if (callbacks.onLoaded) {
            callbacks.onLoaded();
          }
          break;
        }
        case 'focus': {
          if (!data.id) {
            log.warn('id required to focus element');
            return;
          }
          const element = rootElement.querySelector(`#${this.elementId(data.id)}`);
          if (element) {
            element.focus();
          } else {
            log.warn(`No element found with id ${data.id}`);
          }
          break;
        }
        case 'setValue': {
          if (!data.id) {
            log.warn('id required to set value');
            return;
          }
          const element = rootElement.querySelector(`#${this.elementId(data.id)}`);
          if (element) {
            element.value = data.value || '';
          } else {
            log.warn(`No element found with id ${data.id}`);
          }
          break;
        }
        case 'hideElement': {
          if (!data.id) {
            log.warn('id required to hide element');
            return;
          }
          const element = rootElement.querySelector(`#${this.elementId(data.id)}`);
          if (element) {
            element.style.display = 'none';
          } else {
            log.warn(`No element found with id ${data.id}`);
          }
          break;
        }
        case 'showElement': {
          if (!data.id) {
            log.warn('id required to show element');
            return;
          }
          const element = rootElement.querySelector(`#${this.elementId(data.id)}`);
          if (element) {
            element.style.display = null;
          } else {
            log.warn(`No element found with id ${data.id}`);
          }
          break;
        }
        case 'openLink': {
          window.open(data.url, '_blank');
          break;
        }
        case 'track': {
          track(data.trackingEvent, data.metadata);
          break;
        }
        case 'systemMessage': {
          addSystemMessage(data.message, null);
          break;
        }
        case 'emitParticles': {
          callbacks.emitParticles(data.particleData);
          break;
        }
        case 'removeElements':
          // TODO
          break;
        case 'replaceElements': {
          const elements = this.markupToElements(data.elements);
          elements.forEach((el) => {
            const oldEl = rootElement.querySelector(`#${el.id}`);
            if (oldEl) {
              oldEl.parentNode.replaceChild(el, oldEl);
            } else {
              log.warn(`Trying to replace unknown element ${el.id}`);
            }
          });
          break;
        }
        default: {
          if (data.status === 'done') {
            log.debug('Ack for event', data.event);
          } else {
            log.error('Unrecognized event sent from app: ', data);
          }
          break;
        }
      }
    });

    this.worker.postMessage({
      event: 'bootstrap',
      code,
      appData: initialData,
      context: initialContext,
    });
  }

  onDataUpdated(appData) {
    this.handlePostMessage('dataUpdated', { appData });
  }

  terminate() {
    this.worker.terminate();
  }

  handlePostMessage(eventName, message) {
    // message.handle = // some random string;
    this.worker.postMessage({ event: eventName, ...message });
    // TODO set a timer and if we don't get a callback
  }

  elementId(localId) {
    return `el-${this.objId}-${sanitize(localId)}`;
  }

  markupToElements(elements) {
    const result = [];
    elements.forEach((elData) => {
      let element = null;
      if (!elData) {
        return;
      }
      switch (elData.type) {
        case 'button':
          element = this.button(elData);
          break;
        case 'text':
          element = this.text(elData);
          break;
        case 'textField':
          element = this.textField(elData);
          break;
        case 'checkbox':
          element = this.checkbox(elData);
          break;
        case 'container':
          element = this.container(elData);
          break;
        case 'avatar':
          element = this.avatar(elData);
          break;
        case 'image':
          element = this.image(elData);
          break;
        default:
          log.warn(`Element not recognized: ${elData}`);
          break;
      }

      if (element) {
        element.id = elData.id ? this.elementId(elData.id) : '';
        this.applyStyleToElement(element, elData);
        result.push(element);
      }
    });
    return result;
  }

  // Internal methods

  /* Options:
   * direction: row, row-reverse, column, or column-reverse
   */
  container(opts) {
    let style = '';
    if (['row', 'row-reverse', 'column', 'column-reverse'].includes(opts.direction)) {
      style += `flex-direction: ${opts.direction};`;
    }
    if (['center', 'stretch', 'flex-start', 'flex-end', 'baseline', 'start', 'end'].includes(opts.align)) {
      style += `align-items: ${opts.align};`;
    }
    if (opts.wrap) {
      style += 'flex-wrap: wrap;';
    }
    if (['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly'].includes(opts.justify)) {
      style += `justify-content: ${opts.justify};`;
    }
    const container = htmlToElement(`<div class="container" style="${style}"></div>`);
    if (opts.elements) {
      const elements = this.markupToElements(opts.elements);
      elements.forEach((element) => {
        container.appendChild(element);
      });
    }
    return container;
  }

  /* Options:
   * userId: user id
   */
  avatar(opts) {
    return htmlToElement(`<here-avatar userId="${opts.userId}"/>`);
  }

  checkbox(opts) {
    // TODO figure out a good way to style the <span>, or ditch the label attr here
    const el = htmlToElement(
      `<label><input type="checkbox" ${opts.checked ? 'checked' : ''}/><span>${opts.label || ''}</span></label>`
    );
    el.addEventListener('change', (e) => {
      this.handlePostMessage('valueChange', { id: opts.id, value: e.target.checked });
    });
    return el;
  }

  /* Options:
   * title: The button title
   */
  button(opts) {
    const innerContent = opts.title ? `<div class='button-title'>${opts.title}</div>` : '';

    const el = htmlToElement(`<button>${innerContent}</button>`);
    if (opts.content) {
      el.appendChild(this.markupToElements([opts.content])[0]);
    }
    el.addEventListener('click', () => {
      this.handlePostMessage('callback', { id: opts.id, type: 'click', args: [opts.id] });
    });
    el.addEventListener('mousedown', (e) => e.preventDefault());
    if (opts.style && opts.style === 'secondary') {
      el.classList.add(opts.style);
    }
    return el;
  }

  /* Options:
   * string: the string of text
   */
  text(opts) {
    const el = htmlToElement(`<div>${sanitize(opts.string || '')}</div>`);
    el.addEventListener('click', () => {
      this.handlePostMessage('callback', { id: opts.id, type: 'click', args: [opts.id] });
    });
    return el;
  }

  textField(opts) {
    const el = htmlToElement(
      `<input placeholder="${sanitize(opts.placeholder || '')}" value="${sanitize(opts.value || '')}" />`
    );
    el.addEventListener('mousedown', (e) => {
      e.stopPropagation();
    });

    el.addEventListener('keyup', (e) => {
      this.handlePostMessage('valueChange', { id: opts.id, value: e.target.value });
    });

    el.addEventListener('blur', () => {
      this.handlePostMessage('callback', { id: opts.id, type: 'unfocus', args: [opts.id] });
    });
    return el;
  }

  image({ alt, src, width, height }) {
    return htmlToElement(`
      <${src.endsWith('.svg') ? 'here-inline-svg' : 'img'}
        alt="${sanitize(alt)}"
        src="${sanitize(src)}"
        width="${sanitize(width)}"
        height="${sanitize(height)}"
      />`);
  }

  applyStyleToElement(el, opts) {
    if (['s', 'm', 'l', 'xl'].includes(opts.fontSize)) {
      el.classList.add(`font-size-${opts.fontSize}`);
    }
    if (opts.width) {
      el.style.width = `${sanitize(opts.width)}px`;
    }
    if (opts.height) {
      el.style.height = `${sanitize(opts.height)}px`;
    }
    if (opts.hidden) {
      el.style.display = 'none';
    }
    if (opts._clickable) {
      el.classList.add('clickable');
    }
    if (opts.visibleOnHover) {
      el.classList.add('visible-on-hover');
    }
    if (opts.color) {
      el.style.color = opts.color;
    }
    if (opts.backgroundColor) {
      el.style.backgroundColor = opts.backgroundColor;
    }
    if (opts.grow) {
      el.style.flexGrow = Number.parseInt(opts.grow, 10);
    }
    if (opts.shrink) {
      el.style.flexShrink = Number.parseInt(opts.shrink, 10);
    }
    return el;
  }
}
