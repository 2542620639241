import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000aa;
`;
