export const MoreThanFiveAvatars = [
  { size: '17', left: '0', top: '0' },
  { size: '12', right: '6', top: '0.5' },
  { size: '11', left: '15', bottom: '12.5' },
  { size: '10', right: '0', bottom: '16.5' },
  { size: '14', left: '2.3', bottom: '0' },
  { size: '16', right: '0', bottom: '0' }, // position and size for circle with overflow +
];

export const FiveAvatars = [
  { size: '10', left: '2.3', top: '0.5' },
  { size: '14', right: '1', top: '0' },
  { size: '18', left: '9', top: '10' },
  { size: '12', left: '0', bottom: '0' },
  { size: '18', right: '0', bottom: '3' },
];

export const FourAvatars = [
  { size: '18', left: '0', top: '1.5' },
  { size: '14', right: '0', top: '0' },
  { size: '13', left: '3', bottom: '0' },
  { size: '15', right: '1.5', bottom: '5' },
];

export const ThreeAvatars = [
  { size: '16', left: '1', top: '0' },
  { size: '17', right: '0', bottom: '1' },
  { size: '13', left: '0', bottom: '0' },
];

export const TwoAvatars = [
  { size: '20', left: '0', top: '0' },
  { size: '18.5', right: '0', bottom: '0' },
];

export const OneAvatar = [{ size: '30', left: '0', top: '0' }];

interface AvatarStyle {
  size: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}

export const getAvatarsStyles = (memberCount: number): { avatars: AvatarStyle[]; containerSize: string } => {
  if (memberCount > 5) {
    return { avatars: MoreThanFiveAvatars, containerSize: '39' };
  }
  if (memberCount === 5) {
    return { avatars: FiveAvatars, containerSize: '40' };
  }
  if (memberCount === 4) {
    return { avatars: FourAvatars, containerSize: '37' };
  }
  if (memberCount === 3) {
    return { avatars: ThreeAvatars, containerSize: '32' };
  }
  if (memberCount === 2) {
    return { avatars: TwoAvatars, containerSize: '36' };
  }
  if (memberCount === 1) {
    return { avatars: OneAvatar, containerSize: '30' };
  }
  return { avatars: [], containerSize: '0' };
};
