import React from 'react';
import { useDispatch } from 'react-redux';

import SchoolSelectionFlow from '../../school-selection';
import { schoolSelectionComplete } from '../../store/signing-in/actions';
import { EventSourceContext } from '../../common/contexts';
import { LINK_SCHOOL_SOURCES } from '../../../constants/analytics-events/user-events';

const SchoolSelectionScreen: React.FC = () => {
  const dispatch = useDispatch();

  const onComplete = () => dispatch(schoolSelectionComplete());

  return (
    <EventSourceContext.Provider value={LINK_SCHOOL_SOURCES.SIGNUP}>
      <SchoolSelectionFlow onComplete={onComplete} />
    </EventSourceContext.Provider>
  );
};

export default SchoolSelectionScreen;
