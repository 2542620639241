import $ from 'jquery';
import domtoimage from 'dom-to-image';

import firebase from './firebase';
import { uploadFile } from './upload';
import log from './log';
import { track } from './util/analytics-util';
import { playSoundEffect, soundTypes } from './util/sound-fx-util';
import { placeholderImg } from './media-player/media-player-youtube';
import { checkIsElectron } from './util/platform-util';
import { getRoomWindowMediaSource, supportsElectronScreenshot } from './electron-support/electron-support';
import { hideInterface, showInterface } from './util';

let photoboothButton;
const INSTANT_MODE = true;

export function startPhotoCountdown(button) {
  const { uid } = firebase.auth().currentUser;
  const message = `<here-user-name userId=${uid}></here-user-name> is taking a photo. Smile! 📸`;
  if (INSTANT_MODE) {
    captureScreenshot();
    playSoundEffect(soundTypes.PHOTOCAPTURE);
  } else {
    const duration = 3; // in seconds
    window.rtc.sendPhotoBoothCountdown(message, duration);
    track('Photobooth Countdown Started');
    photoTimerShouldStart(duration, message, true);
  }

  photoboothButton = button;

  photoboothButton.setAttribute('disabled', true);
  photoboothButton.style.opacity = 0.6;

  return false;
}

export function photoTimerShouldStart(duration, message, takeSnapshot = false) {
  let countdown = duration;
  $('#booth-countdown').show();
  $('.booth-message').html(message);
  $('.booth-timer').html(countdown);
  playSoundEffect(soundTypes.PHOTOCOUNTDOWN);
  const countdownTimer = window.setInterval(() => {
    countdown -= 1;
    $('.booth-timer').html(countdown);

    if (countdown <= 0) {
      $('#booth-countdown').hide();
      window.clearInterval(countdownTimer);
      if (takeSnapshot) {
        captureScreenshot();
      }
      playSoundEffect(soundTypes.PHOTOCAPTURE);
    } else {
      playSoundEffect(soundTypes.PHOTOCOUNTDOWN);
    }
  }, 1000);
}

async function captureElectronScreenshot() {
  hideInterface(false);

  let stream;
  let constraints;
  try {
    const src = await getRoomWindowMediaSource();
    constraints = {
      audio: false,

      video: {
        mandatory: {
          chromeMediaSource: 'desktop',
          chromeMediaSourceId: src.id,
          maxWidth: 1280,
          maxHeight: 720,
        },
      },
    };
    track('Get User Media', { constraints });
    stream = await navigator.mediaDevices.getUserMedia(constraints);
  } catch (err) {
    track('Get User Media Error', { constraints, error: err.name, action: 'captureElectronScreenshot' });
    log.error(err);
    showInterface();
  }

  /* Record video */
  const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
  const blobs = [];

  // event: new recorded video blob available
  mediaRecorder.addEventListener('dataavailable', (e) => {
    blobs.push(e.data);
  });

  return new Promise((resolve) => {
    // event : recording stopped & all blobs sent
    mediaRecorder.addEventListener('stop', () => {
      // create local object URL from the recorded video blobs
      const dataUrl = URL.createObjectURL(new Blob(blobs, { type: 'video/webm' }));
      const media = dataUrl.replace('video/webm', 'video/octet-stream');
      resolve({ media, dataUrl, filetype: 'webm' });
    });

    // start recording with each recorded blob having 1 second video
    mediaRecorder.start(1000);

    setTimeout(() => {
      mediaRecorder.stop();
      showInterface();
    }, 3000);
  });
}

async function captureWebScreenshot() {
  const cameraUids = [...document.querySelectorAll('[id^="video-container"]')].map((camera) =>
    camera.id.replace('video-container-', '')
  );

  // Freeze all cameras
  cameraUids.forEach((uid) => {
    window.rtc.saveSnapshot(uid);
    window.rtc.replaceWithSnapshot(uid);
  });

  const imageHandlers = Object.values(window.elementHandlers).filter(
    (h) => h.constructor.elementType === 'ImageElement'
  );

  try {
    imageHandlers.forEach((h) => h.showSingleImage());

    const filter = (node) => {
      // filter out images that didn't load properly
      if (node.naturalWidth === 0) return false;

      // filter out thumbnails from media player because we'll get CORS errors
      // also filter out camera-name-icon image whose source is a blob
      if (
        node.classList &&
        (node.classList.contains('video-thumbnail') ||
          node.classList.contains('suggested-video-thumbnail') ||
          node.classList.contains('camera-name-icon'))
      )
        return false;

      // filter out placeholder youtube image
      if (node.src?.includes(placeholderImg)) return false;

      if (node.classList?.contains('link-quality-icon')) {
        return false;
      }

      return true;
    };

    const screenshotOptions = { filter, height: window.innerHeight, width: window.innerWidth };
    const dataUrl = await domtoimage.toPng(document.querySelector('#main'), screenshotOptions);
    const media = dataUrl.replace('image/png', 'image/octet-stream');

    return { media, dataUrl, filetype: 'png' };
  } finally {
    cameraUids.forEach((uid) => window.rtc.hideSnapshot(uid));
    imageHandlers.forEach((h) => h.hideSingleImage());
  }
}

async function captureScreenshot() {
  try {
    const useElectronScreenshot = checkIsElectron() && (await supportsElectronScreenshot());
    const screenshotData = useElectronScreenshot ? await captureElectronScreenshot() : await captureWebScreenshot();

    const { media, dataUrl, filetype } = screenshotData;

    if (!useElectronScreenshot) {
      const download = document.createElement('a');
      download.download = `photobooth.${filetype}`;
      download.href = media;
      download.click();
    }

    // Upload it to the room
    const blob = await (await fetch(dataUrl)).blob();
    blob.name = `photobooth.${filetype}`;
    await uploadFile(blob, null, {
      height: window.innerHeight / 2,
      width: window.innerWidth / 2,
      rotationAngle: Math.floor(Math.random() * 30 - 15),
      captureSource: 'photobooth',
    });
    track('Photobooth Capture Succeeded');

    // re-enable button
    photoboothButton.removeAttribute('disabled');
    photoboothButton.style.opacity = 1.0;
  } catch (err) {
    log.error(err);
    track('Photobooth Capture Failed', { error: err });

    // re-enable button
    photoboothButton.removeAttribute('disabled');
    photoboothButton.style.opacity = 1.0;
  }
}
