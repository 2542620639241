import firebase, { authedApiInstance, db } from '../firebase';
import log from '../log';

/**
 *
 * Fetches a board from the firestore by boardId or urlAlias
 *
 * @param {object} param
 * @param {string} param.boardId
 * @param {string} [param.urlAlias]
 * @returns {Promise<object | null>}
 */
export async function fetchBoard({ boardId, urlAlias }) {
  if (boardId) {
    const data = await db.doc(`boards/${boardId}`).get();
    if (data.exists) {
      return { ...data.data(), id: data.id };
    }

    return null;
  }

  if (urlAlias) {
    const data = await db.collection('boards').where('urlAlias', '==', urlAlias).limit(1).get();
    if (data.size > 0) {
      return { ...data.docs[0].data(), id: data.docs[0].id };
    }

    return null;
  }

  throw new Error('boardId or urlAlias is required');
}

export const updateBoardRoomOnboardingStepId = async (boardId, roomOnboardingStepId) => {
  await db.doc(`boards/${boardId}`).update({ roomOnboardingStepId });
};

export const deleteBoardRoomOnboardingStepId = async (boardId) => {
  await db.doc(`boards/${boardId}`).update({ roomOnboardingStepId: firebase.firestore.FieldValue.delete() });
};

export const addCustomChatSound = async (boardId, data) =>
  authedApiInstance.post(`/room/${boardId}/chatSounds`, data).catch((error) => error?.response?.data);

export const setChatSoundEnabled = async (boardId, chatSoundId, data) =>
  authedApiInstance
    .put(`/room/${boardId}/chatSounds/${chatSoundId}/isEnabled`, data)
    .catch((error) => error?.response?.data);

export const updateChatSound = async (boardId, chatSoundId, data) =>
  authedApiInstance.patch(`/room/${boardId}/chatSounds/${chatSoundId}`, data).catch((error) => error?.response?.data);

export const deleteChatSound = async (boardId, chatSoundId) =>
  authedApiInstance.delete(`/room/${boardId}/chatSounds/${chatSoundId}`).catch((error) => error?.response?.data);
