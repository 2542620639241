import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import TypingIndicatorItem from './TypingIndicatorItem';

// utils
import { getAppearingAnimation } from './utils';

// constants
import {
  MAX_VISIBLE_TYPING_USERS,
  typingIndicatorAnimations,
  typingIndicatorShapes,
} from '../../../constants/chat-constants';
import UserAvatar from '../../components/UserAvatar';

function TypingIndicator(props) {
  const { appearingAnimationType, typingUsers, addGlow } = props;
  const visibleTypingUsers = React.useMemo(() => typingUsers.slice(0, MAX_VISIBLE_TYPING_USERS), [typingUsers]);

  const hiddenTypingUsersCount = React.useMemo(
    () => typingUsers.length - MAX_VISIBLE_TYPING_USERS,
    [typingUsers.length]
  );

  const usersAreTyping = React.useMemo(() => Boolean(typingUsers.length), [typingUsers.length]);

  return (
    <TypingIndicatorWrapper
      className="typing-indicator"
      appearingAnimationType={appearingAnimationType}
      usersAreTyping={usersAreTyping}
    >
      <TypingUserAvatarsContainer>
        {visibleTypingUsers.map((typingUser, index) => (
          <TypingUserAvatarWrapper key={typingUser.id} index={index}>
            <UserAvatar userId={typingUser.id} />
          </TypingUserAvatarWrapper>
        ))}

        {hiddenTypingUsersCount > 0 ? (
          <TypingUserAvatarWrapper key="hidden-typing-users-count">
            <HiddenTypingUsersCountWidget>{`+${hiddenTypingUsersCount}`}</HiddenTypingUsersCountWidget>
          </TypingUserAvatarWrapper>
        ) : null}
      </TypingUserAvatarsContainer>

      <TypingIndicatorItemsList>
        <TypingIndicatorItem
          shape={typingIndicatorShapes.HEXAGON}
          animations={[typingIndicatorAnimations.SPINNING]}
          addGlow={addGlow && !!typingUsers.length}
        />
        <TypingIndicatorItem
          shape={typingIndicatorShapes.RECT}
          animations={[typingIndicatorAnimations.SPINNING, typingIndicatorAnimations.VERTICAL_JUMPS]}
          addGlow={addGlow && !!typingUsers.length}
        />
        <TypingIndicatorItem
          shape={typingIndicatorShapes.TRIANGLE}
          animations={[typingIndicatorAnimations.HORIZONTAL_JUMPS]}
          addGlow={addGlow && !!typingUsers.length}
        />
      </TypingIndicatorItemsList>
    </TypingIndicatorWrapper>
  );
}

TypingIndicator.propTypes = {
  appearingAnimationType: PropTypes.string.isRequired,
  addGlow: PropTypes.bool,
  typingUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
};

TypingIndicator.defaultProps = {
  addGlow: false,
};

export const TypingIndicatorWrapper = styled.div`
  padding: 1px 20px 10px 7px;
  z-index: 1;
  width: inherit;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: ${({ appearingAnimationType, usersAreTyping }) =>
    getAppearingAnimation({ appearingAnimationType, usersAreTyping })};
  transition: transform 0.15s ease-in-out;
`;

const TypingUserAvatarWrapper = styled.div`
  height: 37px;
  width: 37px;
  display: flex;
  overflow: hidden;
  transition: transform 0.25s ease-in-out;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 0px #000000bf;

  & + & {
    margin-left: -15px;
  }
`;

const TypingUserAvatarsContainer = styled.div`
  position: relative;
  margin-right: 7.5px;
  height: 37px;
  min-width: 37px;
  display: flex;
`;

const TypingIndicatorItemsList = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
`;

const HiddenTypingUsersCountWidget = styled.p`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6b1be3;
  font-weight: bolder;
  background-color: #ffffff;
`;

export default TypingIndicator;
