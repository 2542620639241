import { useMemo } from 'react';

const S_IN_MIN = 60;
const MS_IN_S = 1000;

export function useDurationValue(timeMs) {
  const duration = useMemo(() => {
    if (!timeMs && timeMs !== 0) {
      return '--';
    }

    const secondsTotal = Math.floor(timeMs / MS_IN_S);
    const minutes = Math.floor(secondsTotal / S_IN_MIN);
    const seconds = secondsTotal % S_IN_MIN;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, [timeMs]);

  return duration;
}
