import React, { useContext, useRef } from 'react';
import styled from 'styled-components';

import ControlButton from './ControlButton';
import { BoardElementControllerContext } from '../../common/contexts.ts';
import { useHandleDragging } from '../../hooks/useHandleDragging';

import RotateIcon from '../../../../assets/icons/rotate.svg';

const rotationAnchorStep = 45;
const rotationAnchors = Array(...Array(360 / rotationAnchorStep)).map((_, i) => i * rotationAnchorStep);
const rotationAnchorRange = 8;

const RotateButton = () => {
  const { elementData, patchLiveData, patchDbData, containerRef, setAreControlButtonsAlwaysVisible } =
    useContext(BoardElementControllerContext);
  const containerCenterRef = useRef({});
  const getAngle = (x, y) =>
    (Math.atan2(containerCenterRef.current.x - x, containerCenterRef.current.y - y) * 180) / Math.PI;
  const startAngleRef = useRef(null);
  const startRelativeAngleRef = useRef(null);

  const onStartDragging = ({ x, y }) => {
    setAreControlButtonsAlwaysVisible(true);
    const rect = containerRef.current.getBoundingClientRect();
    containerCenterRef.current.x = rect.x + rect.width / 2;
    containerCenterRef.current.y = rect.y + rect.height / 2;
    startAngleRef.current = elementData.rotationAngle || 0;
    startRelativeAngleRef.current = getAngle(x, y);
  };
  const onDragging = ({ x, y }) => {
    const deltaAngle = startRelativeAngleRef.current - getAngle(x, y);
    let rotationAngle = (startAngleRef.current + deltaAngle + 360) % 360;

    const anchorNearby = rotationAnchors.find((anchor) => Math.abs(anchor - rotationAngle) < rotationAnchorRange);
    rotationAngle = anchorNearby === undefined ? rotationAngle : anchorNearby;

    patchLiveData({ rotationAngle });
  };
  const onStopDragging = () => {
    setAreControlButtonsAlwaysVisible(false);
    patchDbData({ rotationAngle: elementData.rotationAngle });
  };
  const draggingHandlers = useHandleDragging({
    onStartDragging,
    onDragging,
    onStopDragging,
    cursor: 'url(/images/icons/rotate.svg) 10 10, alias',
  });

  return (
    <Container {...draggingHandlers}>
      <RotateIcon />
    </Container>
  );
};

export default RotateButton;

const Container = styled(ControlButton)`
  cursor: url(/images/icons/rotate.svg) 10 10, alias;
`;
