import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { customScrollbarMixin } from '../../mixins';
import UsersSelectListItem from './UsersSelectListItem';

import SearchIcon from '../../../../assets/icons/search.svg';

const UsersSelect = ({ usersList, selectedIds, disabledIds, onToggle }) => {
  const [usersFilter, setUsersFilter] = useState('');

  const filteredUsersList = useMemo(
    () =>
      usersList.filter((userData) => {
        let nameMatch = false;
        if (userData.displayName) {
          nameMatch = userData.displayName.toLowerCase().includes(usersFilter.toLowerCase());
        }

        let usernameMatch = false;
        if (userData.username) {
          usernameMatch = userData.username.toLowerCase().includes(usersFilter.toLowerCase());
        }

        return nameMatch || usernameMatch;
      }),
    [usersList, usersFilter]
  );

  return (
    <>
      <InputContainer>
        <SearchIcon />
        <Input value={usersFilter} placeholder="Search..." onChange={(e) => setUsersFilter(e.target.value)} />
      </InputContainer>

      <FriendsList>
        {filteredUsersList.map((userData) => (
          <UsersSelectListItem
            key={userData.userId}
            userId={userData.userId}
            isActive={!!selectedIds[userData.userId]}
            isDisabled={!!disabledIds[userData.userId]}
            onlineStatus={userData.onlineStatus?.status}
            onClick={() => onToggle(userData.userId)}
          />
        ))}
      </FriendsList>
    </>
  );
};

export default UsersSelect;

UsersSelect.propTypes = {
  usersList: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      username: PropTypes.string,
      userId: PropTypes.string,
      onlineStatus: PropTypes.shape({ status: PropTypes.string }),
    })
  ).isRequired,
  selectedIds: PropTypes.shape({}).isRequired,
  onToggle: PropTypes.func.isRequired,
  disabledIds: PropTypes.shape({}),
};

UsersSelect.defaultProps = {
  disabledIds: {},
};

const InputContainer = styled.div`
  position: relative;
  margin-top: 23px;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 13px;
    margin: auto;
  }
`;

const Input = styled.input`
  padding: 11px 11px 11px 40px;
  width: 100%;
  box-sizing: border-box;
  background-color: #00000019;
  border: none;
  border-radius: 10px;
`;

const FriendsList = styled.div`
  margin-top: 11px;
  overflow: auto;
  flex-grow: 1;

  ${customScrollbarMixin()}

  ${({ theme }) => theme?.friendsListHeight && `height: ${theme?.friendsListHeight}px`}
`;
