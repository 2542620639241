import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// icons
import AppStoreBadge from '../../../../assets/icons/external/app-store-badge.svg';
import GooglePlayBadge from '../../../../assets/icons/external/google-play-badge.svg';
import DownloadMobileAppQRCode from '../../../../assets/icons/here/download-mobile-app-qr-code.svg';
import { track } from '../../../util/analytics-util';

const MobileUpsellModal = ({ hideModal }) => {
  const onAppStoreButtonClick = () => {
    track('Android Upsell Clicked');
    hideModal();
  };

  const onGooglePlayButtonClick = () => {
    track('iOS Upsell Clicked');
    hideModal();
  };

  return (
    <Container>
      <Title>Wherever You Are, Be Here!</Title>

      <Description>
        Scan the QR below or visit <DownloadLink href="https://here.fm/app">here.fm/app</DownloadLink> on your phone’s
        browser to stay close to your friends from anywhere.
      </Description>

      <IconContainer>
        <DownloadMobileAppQRCode width="182" />
      </IconContainer>

      <Buttons>
        <DownloadFromStoreLink
          target="_blank"
          href="https://apps.apple.com/us/app/here-talk-text-watch/id1541854332"
          onClick={onAppStoreButtonClick}
        >
          <AppStoreBadge height="60" />
        </DownloadFromStoreLink>

        <DownloadFromStoreLink
          target="_blank"
          href="https://play.google.com/store/apps/details?id=fm.here.android"
          onClick={onGooglePlayButtonClick}
        >
          <GooglePlayBadge height="60" />
        </DownloadFromStoreLink>
      </Buttons>
    </Container>
  );
};

MobileUpsellModal.propTypes = {
  hideModal: PropTypes.func,
};

MobileUpsellModal.defaultProps = {
  hideModal: () => {},
};

const Container = styled.div`
  padding: 29px 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 20px;
`;

const Title = styled.h2`
  color: #12002d;
  font-weight: bolder;
  font-size: 30px;
`;

const Description = styled.p`
  margin-top: 20px;
  width: 460px;
  color: #12002d;
  font-size: 20px;
  text-align: center;
`;

const DownloadLink = styled.a`
  font-weight: bolder;
  color: #6b1be3;
  cursor: pointer;

  &:hover {
    color: #6b1be3;
    text-decoration: underline;
  }
`;

const IconContainer = styled.div`
  margin-top: 20px;
`;

const Buttons = styled.div`
  margin-top: 43px;
  display: flex;
  gap: 30px;
`;

const DownloadFromStoreLink = styled.a``;

export default MobileUpsellModal;
