export enum EmoteType {
  CUSTOM = 'custom',
  LIBRARY = 'library',
  STICKER = 'sticker',
}

export interface EmoteFromResponse {
  url: string;
  type: EmoteType;
  stickerPack?: string;
}

export enum StickerPacks {
  HERE = 'hereStickerPack',
  SANRIO = 'sanrioPack',
  MEME = 'memePack',
}

export interface StickerPack {
  id: StickerPacks | string;
  label: string;
  urls: string[];
}

export interface EmotesForEmojiPicker {
  id: string;
  imageUrl: string;
  name: string;
  short_names: string[]; // emoji-mart requires this field to be snakecase lol
}
