import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import HereModalWithPortal from '../../../../components/HereModalWithPortal';
import {
  selectChatByChatId,
  selectChatMembersByChatId,
  selectGroupIdFromChatId,
} from '../../../../store/messaging/selectors';
import { getGroupChatParams, track } from '../../../../../util/analytics-util';
import { removeMember } from '../../../../store/messaging/actions';
import { CHAT_COPY_LINK, CHAT_MEMBERS_ADD, CHAT_MEMBERS_REMOVE } from '../../../analytics';
import { addMembersToGroup } from '../../../../store/groups/actions';
import { ellipsisTextMixin, noScrollbarMixin, svgColorMixin } from '../../../../mixins';
import ShareLinkIcon from '../../../../../../assets/icons/hereos/chat/share-link.svg';
import ChatIcon from '../../ChatIcon';
import AddFriendsSettings from '../invite-friends/AddFriendsSettings';
import { ChatPermissionsContext } from '../../../../common/contexts';

const ADD_MEMBERS_MODAL_ID = 'group-chat-add-members';

interface Props {
  chatId: string;
  setModalOpen: (isOpen: boolean) => void;
}

const InviteFriendsModal: React.FC<Props> = ({ chatId, setModalOpen }) => {
  const dispatch = useDispatch();

  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const groupId = useSelector((state) => selectGroupIdFromChatId(state, chatId));
  const members = useSelector((state) => selectChatMembersByChatId(state, chatId));
  const memberIds = useMemo(() => Object.keys(members), [members]);

  const onFriendClick = (userId: string) => {
    if (members[userId]) {
      dispatch(removeMember({ userId, chatId }));
      track(
        CHAT_MEMBERS_REMOVE,
        getGroupChatParams({
          chatId,
        })
      );
    } else {
      dispatch(addMembersToGroup({ groupId: chat?.groupId, userIds: [userId] }));
      track(
        CHAT_MEMBERS_ADD,
        getGroupChatParams({
          chatId,
        })
      );
    }
  };

  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const onCopyLinkClick = () => {
    window.navigator.clipboard.writeText(`${window.location.origin}/join/${groupId}`);

    track(CHAT_COPY_LINK, getGroupChatParams({ chatId, source: 'invite friends modal' }));

    setIsUrlCopied(true);

    setTimeout(() => {
      setIsUrlCopied(false);
    }, 1000);
  };

  const permissions = useContext(ChatPermissionsContext);

  return (
    <HereModalWithPortal
      modalRootId={ADD_MEMBERS_MODAL_ID}
      modalWidth="520px"
      onOverlayClick={() => setModalOpen(false)}
    >
      <div className="prevent-on-click-outside">
        <InviteFriendsModalContainer>
          <InviteLinkContainer>
            <InviteFriendsLabel>Invite Your Friends To:</InviteFriendsLabel>
            {permissions.canAddMembers ? (
              <InviteFriendsButton onClick={onCopyLinkClick}>
                {isUrlCopied ? (
                  'Copied!'
                ) : (
                  <>
                    <ShareLinkIcon />
                    Invite Link
                  </>
                )}
              </InviteFriendsButton>
            ) : null}
          </InviteLinkContainer>
          <InviteFriendsTitleContainer>
            <ChatIconContainer>
              <ChatIcon chatId={chatId} withBadging={false} withTooltip={false} />
            </ChatIconContainer>
            <InviteFriendsChatTitle>&quot;{chat?.groupName}&quot;</InviteFriendsChatTitle>
          </InviteFriendsTitleContainer>
          <FriendsContainer>
            <AddFriendsSettings selectedFriends={memberIds} onFriendClick={onFriendClick} />
          </FriendsContainer>
        </InviteFriendsModalContainer>
      </div>
    </HereModalWithPortal>
  );
};

export default InviteFriendsModal;

const chatIconSize = 45;

const FriendsContainer = styled.div`
  height: 374px;
  padding: 3px;
  overflow-y: auto;
  ${noScrollbarMixin()}
`;

const InviteFriendsModalContainer = styled.div`
  padding: 20px 20px 0 20px;
  background: var(--primary-background);
  border-radius: 15px;
`;

const InviteLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InviteFriendsLabel = styled.div`
  font-size: 13px;
  color: var(--primary-foreground);
  margin-bottom: 5px;
`;

const InviteFriendsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  border-radius: 30px;
  height: 30px;
  width: 120px;
  cursor: pointer;

  background-color: var(--secondary-background);
  color: var(--secondary-foreground);
  transition: background-color 0.2s;

  svg {
    margin-right: 3px;
    ${svgColorMixin('var(--secondary-foreground)')}
  }

  &:hover {
    background-color: var(--secondary-background-alpha-50);
    color: var(--primary-foreground);

    ${svgColorMixin('var(--primary-foreground)')}
  }
`;

const InviteFriendsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ChatIconContainer = styled.div`
  width: ${chatIconSize}px;
  height: ${chatIconSize}px;

  margin-right: 5px;
`;

const InviteFriendsChatTitle = styled.div`
  color: var(--primary-foreground);
  font-size: 20px;
  font-weight: bold;
  width: calc(100% - ${chatIconSize}px);
  ${ellipsisTextMixin()}
`;
