import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const SlideIn = ({ time, children }) => <SlideInContainer time={time}>{children}</SlideInContainer>;

export default SlideIn;

SlideIn.propTypes = {
  time: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SlideIn.defaultProps = {
  time: '0.2s',
};

const slideInAnimation = css`
  @keyframes slide-in {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }
`;

const SlideInContainer = styled.div`
  ${slideInAnimation}

  animation: ${({ time }) => `slide-in ${time}`}
`;
