import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { renderMobileUpsellModal } from '../../web-lobby/modals/render';
import { svgColorMixin } from '../../mixins';

// utils
import { attemptLoadInElectron } from '../../../util/electron-util';
import { checkIsElectron } from '../../../util/platform-util';

// icons
import MobileIcon from '../../icons/MobileIcon';
import DesktopIcon from '../../../../assets/icons/desktop.svg';

const RoomSettingsFooter = () => {
  const [showDesktopLink, setShowDesktopLink] = useState(false);

  useEffect(() => {
    setShowDesktopLink(!checkIsElectron());
  }, []);

  const onMobileButtonClick = () => {
    renderMobileUpsellModal();
  };

  const onDesktopButtonClick = () => {
    attemptLoadInElectron(
      window.currentBoardId,
      () => {},
      () => {
        window.open('https://here.fm/desktop', '_blank');
      }
    );
  };

  return (
    <footer>
      <FooterSeparator />

      <FooterContent>
        <FooterTitle>Get Here on</FooterTitle>
        <FooterButtons>
          <FooterButton onClick={onMobileButtonClick}>
            <MobileIcon />
            <FooterLinkText>Mobile</FooterLinkText>
          </FooterButton>

          {showDesktopLink ? (
            <FooterButton onClick={onDesktopButtonClick}>
              <DesktopIcon />
              <FooterLinkText>Desktop</FooterLinkText>
            </FooterButton>
          ) : null}
        </FooterButtons>
      </FooterContent>
    </footer>
  );
};

const FooterSeparator = styled.div`
  margin: auto;
  height: 1px;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const FooterContent = styled.div`
  padding: 23px 45px;
`;

const FooterTitle = styled.h6`
  font-size: 12px;
  font-weight: bolder;
  color: var(--primary-foreground, #12002d7f);
`;

const FooterButtons = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 30px;
`;

const FooterLinkText = styled.span`
  font-size: 16px;
  font-weight: bolder;
  color: var(--primary-foreground, #12002d7f);
`;

const FooterButton = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  cursor: pointer;

  ${svgColorMixin('var(--primary-foreground, #12002d7f)')}

  &:hover {
    ${FooterLinkText} {
      text-decoration: underline;
    }
  }
`;

export default RoomSettingsFooter;
