import { FriendsSubTabs } from '../../web-lobby/tabs/friends-tab/Navigation';

export interface OsState {
  isFriendsSidebarOpened: FriendsSubTabs;
  isFriendsOnlineOpened: boolean;
  isAppearanceMenuOpened: boolean;
  isFeedbackMenuOpened: boolean;
  isRoomsMenuOpened: boolean;
  isMyStuffMenuOpened: boolean;
  isAvatarProfileOpened: boolean;
  isCreateChatOpened: boolean;
  isWhatsNewOpened: boolean;
  isDiscoverModalOpened: boolean;
  openChats: { [key: string]: { elementId: string } };
  maxZIndex: number;
}

export const initialState: OsState = {
  isFriendsSidebarOpened: null,
  isFriendsOnlineOpened: false,
  isAppearanceMenuOpened: false,
  isFeedbackMenuOpened: false,
  isRoomsMenuOpened: false,
  isMyStuffMenuOpened: false,
  isAvatarProfileOpened: false,
  isCreateChatOpened: false,
  isWhatsNewOpened: false,
  isDiscoverModalOpened: false,
  openChats: {},
  maxZIndex: 0,
};
