import React from 'react';
import ReactDOM from 'react-dom';

import RoomSettings from './settings';
import { WithRoomStore } from '../store/Wrapper';
import InviteRecentVisitorsModal from './invite-recent-visitors/InviteRecentVisitorsModal.tsx';

export function renderRoomSettings() {
  ReactDOM.render(
    <WithRoomStore>
      <RoomSettings />
    </WithRoomStore>,
    document.getElementById('room-settings-react')
  );
}

export function renderInviteRecentVisitorsModal(recentVisitors) {
  ReactDOM.render(
    <WithRoomStore>
      <InviteRecentVisitorsModal recentVisitors={recentVisitors} />
    </WithRoomStore>,
    document.getElementById('recent-visitors-invite-modal')
  );
}
