import { getRandomNumberInRange } from '../../util';

import { tips } from './constants';

import template from './room-loading-animation.html';

import './room-loading-animation.less';

export default class RoomLoading extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    // binds
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    // elements
    this.roomLoadingContainerEl = this.querySelector('#room-loading-container');
    this.tipAnimationWrapperEl = this.querySelector('#tip-animation-wrapper');
  }

  show() {
    const randomNonViewedTipIndex = Math.abs(Math.round(getRandomNumberInRange(0, tips.length - 1)));
    const randomNonViewedTip = tips[randomNonViewedTipIndex];
    this.tipAnimationWrapperEl.textContent = randomNonViewedTip;

    this.roomLoadingContainerEl.classList.remove('hidden');
  }

  hide() {
    this.roomLoadingContainerEl.classList.add('hidden');
  }
}

window.customElements.define('here-room-loading-animation', RoomLoading);
