import { BoardElementEnum } from './BoardElementEnum';

const getElementTypeText = (elementType: BoardElementEnum) => {
  const text = BoardElementEnum[elementType];

  if (text === BoardElementEnum.GIF) {
    return ` in a ${text}`;
  }

  if (text === BoardElementEnum.IMAGE) {
    return ` in an ${text.toLowerCase()}`;
  }

  if (text === BoardElementEnum.OTHER) {
    return ' in something';
  }

  return ` in a ${text.toLowerCase()}`;
};

export default getElementTypeText;
