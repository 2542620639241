import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// store
import { itemChosen } from '../../store/template/store';
import { NewRoomFlowSteps } from '../../store/new-room/init-state';
import { resetToInitState } from '../../store/new-room/actions';
import {
  selectNewRoomTemplateBackground,
  selectRoomCreationStep,
  selectNewRoomTemplate,
  selectNewRoomName,
} from '../../store/new-room/selectors';

// components
import { fadeIn } from '../../components/animate/keyframes';
import RoomCreationNaming from './steps/RoomCreationNaming';
import RoomCreationInviteFriends from './steps/RoomCreationInviteFriends';

const stepsTitlesMap = {
  [NewRoomFlowSteps.CREATE_NAME]: 'Create Your Room',
  [NewRoomFlowSteps.INVITE_FRIENDS]: 'Here is better with Friends',
};

const RoomCreation = () => {
  const dispatch = useDispatch();

  const roomCreationStep = useSelector(selectRoomCreationStep);
  const pickedTemplate = useSelector(selectNewRoomTemplate);
  const newRoomBackground = useSelector(selectNewRoomTemplateBackground);
  const newRoomName = useSelector(selectNewRoomName);

  const computedBackground = Number.isInteger(Number(newRoomBackground))
    ? `images/bg/${newRoomBackground}.jpg`
    : newRoomBackground;

  const templateNameValue = roomCreationStep === NewRoomFlowSteps.INVITE_FRIENDS ? newRoomName : pickedTemplate.name;

  useEffect(
    () => () => {
      dispatch(resetToInitState());
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const renderStep = useMemo(() => {
    switch (roomCreationStep) {
      case NewRoomFlowSteps.CREATE_NAME: {
        return <RoomCreationNaming />;
      }

      case NewRoomFlowSteps.INVITE_FRIENDS: {
        return <RoomCreationInviteFriends />;
      }

      default: {
        throw new Error('Wrong step!');
      }
    }
  }, [roomCreationStep]);

  const onBackClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(itemChosen({ item: null }));
  };

  return (
    <Container bgImage={computedBackground}>
      <BackButton type="button" visible={roomCreationStep === NewRoomFlowSteps.CREATE_NAME} onClick={onBackClick}>
        Back
      </BackButton>

      <FlowContainer>
        <div>
          <TemplateName>{templateNameValue}</TemplateName>
          <Title>{stepsTitlesMap[roomCreationStep]}</Title>
        </div>

        <StepContainer>{renderStep}</StepContainer>
      </FlowContainer>
    </Container>
  );
};

const Container = styled.div<{ bgImage: string }>`
  padding: 30px 50px;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  animation: ${fadeIn} ease 1s;
  background: radial-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 1) 100%), ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const BackButton = styled.button<{ visible: boolean }>`
  width: fit-content;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  font-size: 18px;
  color: #bbbbbb;
  opacity: 0.9;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`;

const FlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 140px;
  flex-grow: 1;

  @media (max-height: 700px) {
    gap: 25px;
  }
`;

const TemplateName = styled.h3`
  color: white;
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Title = styled.h2`
  color: white;
  text-align: center;
`;

const StepContainer = styled.div`
  width: 38%;
  display: flex;

  @media (max-width: 1300px) {
    width: 50%;
  }

  @media (min-width: 1200px) and (max-width: 1299px) {
    width: 55%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 65%;
  }

  @media (max-width: 991px) {
    width: 95%;
  }
`;

export default RoomCreation;
