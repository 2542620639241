import firebase, { api, authedApiInstance, db } from '../../../firebase';
import { NewRoomData } from './init-state';

export const createRoomRequest = async (
  data: NewRoomData & { uid: string; background: string; roomOnboardingStepId?: string; isCustomBackground?: boolean }
) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accepts', 'application/json');

  const body = {
    name: data.name,
    joinModeOpen: data.joinModeOpen,
    templateBoardId: data.template.id,
    creator: data.uid,
    uid: data.uid,

    ...(data.background && data.roomOnboardingStepId && data.isCustomBackground ? { background: data.background } : {}),

    ...(data.roomOnboardingStepId ? { roomOnboardingStepId: data.roomOnboardingStepId } : {}),

    ...(data.hasCustomUrl ? { url: data.customUrl } : {}),
  };

  const resp = await fetch(`${api}/room/create`, {
    headers,
    method: 'POST',
    body: JSON.stringify(body),
  });
  return resp.json();
};

export const createRoomForGroupRequest = async (
  data: NewRoomData & {
    groupId: string;
    background: string;
    roomOnboardingStepId?: string;
    isCustomBackground?: boolean;
    joinMode: string;
  }
) => {
  const body = {
    name: data.name,
    templateBoardId: data.template.id,
    joinMode: data.joinMode,

    ...(data.background && data.roomOnboardingStepId && data.isCustomBackground ? { background: data.background } : {}),

    ...(data.roomOnboardingStepId ? { roomOnboardingStepId: data.roomOnboardingStepId } : {}),

    ...(data.hasCustomUrl ? { url: data.customUrl } : {}),
  };

  const resp = await authedApiInstance.post(`/groups/${data.groupId}/rooms`, body);
  return resp.data;
};

export const makeSendInvitesRequest = (
  roomId: string,
  userIds: string[],
  room: { title: string; background?: string | number; creator: string }
) => authedApiInstance.post(`/room/${roomId}/invite`, { ids: userIds, room });

export const setGroupsOnboardingStep = (userId: string, stepId: string) =>
  db.doc(`userProfiles/${userId}`).update({ groupsOnboardingStepId: stepId });

export const setUserProfileIsOnboarding = (userId: string, isOnboarding: boolean) =>
  db.doc(`userProfiles/${userId}`).update({ isOnboarding });

export const deleteGroupsOnboardingStep = (userId: string) =>
  db.doc(`userProfiles/${userId}`).update({ groupsOnboardingStepId: firebase.firestore.FieldValue.delete() });
