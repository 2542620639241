import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { chatSkinningDataFetched, fetchChatSkinningData, setIsChatSkinningDataLoading } from './actions';
import { getChatSkinningData } from '../../../api/chat-skinning-api';
import { UnfoldedSkinsData } from './init-state';
import log from '../../../log';
import { selectIsChatSkinningLoaded, selectIsChatSkinningLoading } from './selectors';
import { requestAsync } from '../helpers';

function* handleFetchChatSkinningData() {
  try {
    const isLoading: boolean = yield select(selectIsChatSkinningLoading);
    const isLoaded: boolean = yield select(selectIsChatSkinningLoaded);
    if (isLoading || isLoaded) return;

    yield put(setIsChatSkinningDataLoading({ isLoading: true }));
    const skinsData: UnfoldedSkinsData = yield call(requestAsync, getChatSkinningData);

    yield put(chatSkinningDataFetched({ data: skinsData }));
    yield put(setIsChatSkinningDataLoading({ isLoading: false }));
  } catch (error) {
    log.error(error);
  }
}

export default function* chatSkinningSaga() {
  yield all([takeLatest(fetchChatSkinningData, handleFetchChatSkinningData)]);
}
