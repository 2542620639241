import { Lens, CameraKitSession, bootstrapCameraKit, CameraKit } from '@snap/camera-kit';

import { TrackProvider } from '../sfu/track-provider';
import log from '../log';
import CameraElement from '../camera';

class SnapchatVideoTrackProvider implements TrackProvider {
  type = 'snapchat';

  private cameraKit: CameraKit;

  private session: CameraKitSession;

  private async init() {
    if (!this.cameraKit) {
      this.cameraKit = await bootstrapCameraKit({
        apiToken: process.env.SNAPCHAT_API_TOKEN,
      });
    }
    if (!this.session) {
      this.session = await this.cameraKit.createSession();
      this.session.events.addEventListener('error', ({ detail }) => {
        log.error(detail);
      });
    }
  }

  private _currentLens: Lens;

  public get currentLens() {
    return this._currentLens;
  }

  private set currentLens(value) {
    this._currentLens = value;
    if (CameraElement.ownCamera.isBackgroundRemovalEnabled()) {
      CameraElement.ownCamera.disableBackgroundRemoval();
    }
  }

  async getTrack(videoMediaConfig: object) {
    const inputStream = await navigator.mediaDevices.getUserMedia({ video: videoMediaConfig || true, audio: false });
    await this.session.setSource(inputStream);
    return this.session.output.capture.captureStream().getVideoTracks()[0];
  }

  async suspend() {
    if (!this.currentLens) return;

    this.currentLens = null;
    this.session.pause('capture');
  }

  async applyLens(lens: Lens) {
    if (!this.session) {
      await this.init();
    }

    this.session.applyLens(lens);
    this.session.play('capture');
    this.session.mute();

    const previousLens = this.currentLens;
    this.currentLens = lens;
    if (!previousLens) {
      await this.restartRtc();
    }
  }

  removeLens() {
    this.suspend();
    this.restartRtc();
  }

  async getLenses() {
    if (!this.cameraKit) await this.init();
    const { lenses } = await this.cameraKit.lensRepository.loadLensGroups([process.env.SNAPCHAT_GROUP_ID]);
    return lenses;
  }

  private async restartRtc() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await window.rtc.room.cameraPublisher.restartVideoDevice();
  }
}

export default new SnapchatVideoTrackProvider();
