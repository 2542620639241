import bus, { boardChanged } from '../../event-bus';
import firebase, { db } from '../../firebase';
import { htmlToElement } from '../../util';

import template from './broadcast-camera-requests.html';
import './broadcast-camera-requests.less';

export default class BroadcastCameraRequests extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    this.onSwitchingBoards = this.onSwitchingBoards.bind(this);
    this.onRequestsListUpdate = this.onRequestsListUpdate.bind(this);

    this.currentBoardId = window.currentBoardId;
    this.onSwitchingBoards(this.currentBoardId);
    bus.on(boardChanged, this.onSwitchingBoards);
  }

  disconnectedCallback() {
    bus.off(boardChanged, this.onSwitchingBoards);
    this.removeRequestsListener();
  }

  getRef() {
    return firebase.database().ref(`/memberRequests/${this.currentBoardId}`);
  }

  addRequestsListener() {
    this.getRef().on('value', this.onRequestsListUpdate);
  }

  removeRequestsListener() {
    this.getRef().off('value', this.onRequestsListUpdate);
  }

  onSwitchingBoards(newBoardId) {
    this.removeRequestsListener();
    this.currentBoardId = newBoardId;
    this.addRequestsListener();
  }

  acceptRequest(userId) {
    db.doc(`boards/${this.currentBoardId}/members/${userId}`).update({ role: null });
    this.removeRequest(userId);
  }

  removeRequest(userId) {
    firebase.database().ref(`/memberRequests/${this.currentBoardId}/${userId}`).remove();
  }

  addRequestElement(userId) {
    const request = htmlToElement(`
      <div class="notification broadcast-request" data-user-id="${userId}">
        <div class="left-area">
          <div class="avatar-container">
            <here-avatar userId="${userId}"></here-avatar>
          </div>
        </div>
        <div class="message">
          <strong>
            <here-user-name userId="${userId}"></here-user-name> wants to go on camera
          </strong>
          <button class="accept-button">Allow</button>
          <button class="ignore-button">Ignore</button>
        </div>
      </div>
    `);

    request.querySelector('.accept-button').addEventListener('click', () => this.acceptRequest(userId));
    request.querySelector('.ignore-button').addEventListener('click', () => this.removeRequest(userId));

    this.querySelector('.requests-container').append(request);
  }

  removeRequestElement(userId) {
    this.querySelector(`[data-user-id="${userId}"]`).remove();
  }

  onRequestsListUpdate(requestsList) {
    const requestIds = requestsList.val() ? Object.keys(requestsList.val()) : [];

    const shownRequests = [...this.querySelectorAll('.broadcast-request')];
    const shownRequestsIds = shownRequests.map((el) => el.dataset.userId);

    shownRequestsIds.forEach((id) => {
      if (!requestIds.includes(id)) {
        this.removeRequestElement(id);
      }
    });

    requestIds.forEach((id) => {
      if (!shownRequestsIds.includes(id)) {
        this.addRequestElement(id);
      }
    });
  }
}
window.customElements.define('here-broadcast-camera-requests', BroadcastCameraRequests);
