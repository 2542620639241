import { createAction, createReducer } from '@reduxjs/toolkit';
import { FRIEND_REQUEST_STATUS } from '../../user-profile/constants';
import { roomSwitched } from '../room/store';
import { friendRequestNormalizer } from './normalizers';

const initialState = {
  friendsIds: {},
  loadingFriends: false,
  friendsError: '',
  incomingRequests: {},
  outgoingRequests: {},
  // Ids of receivers who accepted outgoing friendship (removed when notification is closed)
  acceptedRequestNotifications: [],

  // Suggestion to add to friends first time room member. Can be only one.
  firstTimeMemberFriendSuggestion: null,

  // Suggestion to add to friends the user who sent an outside reaction. Can be only one.
  outsideReactionFriendSuggestion: null,
};

// Fetching friends action:
export const fetchFriends = createAction('friends/friendsFetch');

// Unfriending action:
export const unfriend = createAction('friends/unfriend');

// Success/Error actions that kick off after the above:
export const friendsSuccess = createAction('friends/friendsSuccess');
export const friendsError = createAction('friends/friendsError');

export const incomingFriendRequestsUpdated = createAction('friends/incomingRequestsUpdated');
// Outgoing requests list is updated
export const outgoingFriendRequestsUpdated = createAction('friends/outgoingRequestsUpdated');
export const outgoingFriendRequestsNotificationClosed = createAction('friends/outgoingRequestsNotificationClosed');

export const setFirstTimeMemberFriendSuggestion = createAction('setFirstTimeMemberFriendSuggestion');

export const setOutsideReactionFriendSuggestion = createAction('setOutsideReactionFriendSuggestion');

// Friends reducer:
export const friendsReducer = createReducer(initialState, {
  [fetchFriends]: (state) => {
    state.loadingFriends = true;
    state.friendsError = '';
  },
  [unfriend]: (state) => {
    state.loadingFriends = true;
    state.friendsError = '';
  },
  [friendsSuccess]: (state, { payload: { friendsIds } }) => {
    state.loadingFriends = false;
    state.friendsIds = friendsIds;
  },
  [friendsError]: (state, { payload: { error } }) => {
    state.loadingFriends = false;
    state.friendsError = error;
  },
  [outgoingFriendRequestsUpdated]: (state, { payload: { requests } }) => {
    requests.forEach((current) => {
      const previous = state.outgoingRequests[current.id];
      if (
        previous &&
        previous.status === FRIEND_REQUEST_STATUS.pending &&
        current.status === FRIEND_REQUEST_STATUS.accepted
      ) {
        state.acceptedRequestNotifications.push(current.receiver);
        state.friendsIds[current.receiver] = true;
      }
    });

    state.outgoingRequests = {};
    requests.forEach((r) => {
      state.outgoingRequests[r.id] = friendRequestNormalizer(r);
    });
  },
  [incomingFriendRequestsUpdated]: (state, { payload: { requests } }) => {
    state.incomingRequests = {};
    requests.forEach((r) => {
      state.incomingRequests[r.id] = friendRequestNormalizer(r);
    });
  },
  [outgoingFriendRequestsNotificationClosed]: (state, { payload: { userId } }) => {
    state.acceptedRequestNotifications = state.acceptedRequestNotifications.filter((id) => id !== userId);
  },
  [setFirstTimeMemberFriendSuggestion]: (state, { payload: { suggestion } }) => {
    state.firstTimeMemberFriendSuggestion = suggestion;
  },
  [setOutsideReactionFriendSuggestion]: (state, { payload: { suggestion } }) => {
    state.outsideReactionFriendSuggestion = suggestion;
  },
  [roomSwitched]: (state) => {
    state.firstTimeMemberFriendSuggestion = null;
  },
});
