import React, { ChangeEvent, KeyboardEvent, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import log from '../../../log';
import { Theme } from '../../../definitions/theme';

// store
import {
  selectCurrentUserFontColor,
  selectCurrentUserFontFamily,
  selectCurrentUserFontSize,
  selectCurrentUserMessageColor,
} from '../../store/users/selectors';
import { currentUserProfileUpdateRequest } from '../../store/users/store';

// components
import MessageInput from '../chat-input/MessageInput';
import MessageColorButton from '../chat-input/buttons/MessageColorButton';
import EmojiPickerButton from '../chat-input/buttons/EmojiPickerButton';
import KaomojiPickerButton from '../chat-input/buttons/KaomojiPickerButton';
import GifPickerButton from '../chat-input/buttons/GifPickerButton';
import UploadButton from '../chat-input/buttons/UploadButton';
import FontStyleButton from '../chat-input/buttons/FontStyleButton';
import { svgColorMixin } from '../../mixins';
import { RoomChatInputContext } from '../../common/contexts';

// utils
import { track } from '../../../util/analytics-util';

// constants
import { DEFAULT_PRIMARY_FOREGROUND } from '../../groups-lobby/style-constants';
import {
  CHANGE_MESSAGE_BACKGROUND,
  CHANGE_MESSAGE_FONT_COLOR,
  CHANGE_MESSAGE_FONT_SIZE,
  CHANGE_MESSAGE_FONT_STYLE,
} from '../../../constants/analytics-events/chat-events';

const onMessageInputUnmount = () => {};

const onBlur = () => {};

const onPaste = () => {};

const onDrop = () => {};

type RoomChatInputActiveProps = {
  message: string;
  theme: Theme;
  inputRef?: React.RefObject<HTMLInputElement>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  onMessageChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  sendTextMessage: (messageToSend: string) => Promise<void>;
  onEmojiSelected: (emoji: object) => void;
  onKaomojiSelected: (kaomoji: string, category: string) => void;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RoomChatInputActive = ({
  message,
  theme,
  inputRef,
  setMessage,
  onMessageChange,
  sendTextMessage,
  onEmojiSelected,
  onKaomojiSelected,
  handleFileUpload,
}: RoomChatInputActiveProps) => {
  const dispatch = useDispatch();

  const messageColor = useSelector(selectCurrentUserMessageColor);
  const fontSize = useSelector(selectCurrentUserFontSize);
  const fontColor = useSelector(selectCurrentUserFontColor);
  const fontFamily = useSelector(selectCurrentUserFontFamily);

  const { elementId, caller, fontStyleSource } = useContext(RoomChatInputContext);

  const onMessageColorPick = useCallback(
    (color) => {
      track(CHANGE_MESSAGE_BACKGROUND, { color });
      dispatch(currentUserProfileUpdateRequest({ patch: { messageColor: color.detail.color } }));
    },
    [dispatch]
  );

  const onFontChange = useCallback(
    (font) => {
      if (font.family !== fontFamily) {
        track(CHANGE_MESSAGE_FONT_STYLE, { source: fontStyleSource, fontName: font.family });
        dispatch(currentUserProfileUpdateRequest({ patch: { fontFamily: font.family } }));
      }
    },
    [dispatch, fontStyleSource, fontFamily]
  );

  const onFontSizeChange = useCallback(
    (updatedFontSize) => {
      track(CHANGE_MESSAGE_FONT_SIZE, { fontSize: updatedFontSize });
      dispatch(currentUserProfileUpdateRequest({ patch: { fontSize: updatedFontSize } }));
    },
    [dispatch]
  );

  const onFontColorPick = useCallback(
    (color) => {
      track(CHANGE_MESSAGE_FONT_COLOR, { color: color.detail.color });
      dispatch(currentUserProfileUpdateRequest({ patch: { fontColor: color.detail.color } }));
    },
    [dispatch]
  );

  const onKeyDown = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    try {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();

        const trimmedMessage = message.trim();
        if (trimmedMessage) {
          setMessage('');
          await sendTextMessage(trimmedMessage);
        }
      }
    } catch (error) {
      log.error(error);
    }
  };

  return (
    <Container>
      <ButtonsContainer>
        <ButtonsGroupContainer iconsColor={theme?.colors?.primaryForeground || DEFAULT_PRIMARY_FOREGROUND}>
          <KaomojiPickerButton onSelectKaomoji={onKaomojiSelected} />
          <EmojiPickerButton onEmojiSelected={onEmojiSelected} />
          <GifPickerButton elementId={elementId} caller={caller} />
          <UploadButton elementId={elementId} handleFileUpload={handleFileUpload} />
        </ButtonsGroupContainer>

        <ButtonsGroupContainer iconsColor={theme?.colors?.primaryForeground || DEFAULT_PRIMARY_FOREGROUND}>
          <FontStyleButton
            id="dmWindow"
            currentFontFamily={fontFamily}
            currentFontSize={fontSize}
            currentFontColor={fontColor}
            fontStyleDropdownDirection="up"
            onFontChange={onFontChange}
            onFontSizeChange={onFontSizeChange}
            onFontColorPick={onFontColorPick}
          />
          <MessageColorButton messageColor={messageColor} onColorPick={onMessageColorPick} />
        </ButtonsGroupContainer>
      </ButtonsContainer>

      <MessageInput
        key={caller}
        ref={inputRef}
        // input props
        autoFocus
        // message
        message={message}
        maxMessageLength={512}
        // message settings
        messageColor={messageColor}
        fontColor={fontColor}
        fontSize={fontSize}
        fontFamily={fontFamily}
        // event handlers
        onChange={onMessageChange}
        onPaste={onPaste}
        onDrop={onDrop}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        //
        onUnmount={onMessageInputUnmount}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 6px;
  display: flex;
  flex-direction: column;
  background: var(--primary-background, #12002d);
  gap: 9px;
  border-radius: 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonsGroupContainer = styled.div<{ iconsColor: string }>`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 5px;

  ${({ iconsColor }) => svgColorMixin(iconsColor)};
`;

export default RoomChatInputActive;
