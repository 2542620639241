import React from 'react';
import PropTypes from 'prop-types';

// components
import DirectMessagePreview from './DirectMessagePreview';

// Generic dumb messages preview
// (no popover wrapper, if needed use MessagesPreviewPopover)
const MessagesPreview = ({ messagesList }) => (
  <>
    {messagesList.map((m) => (
      <DirectMessagePreview
        key={m.id}
        id={m.id}
        dmChatId={m.chatId}
        type={m.type}
        creator={m.creator || ''}
        text={m.text}
        roomId={m.roomId}
        fontFamily={m.fontFamily}
        fontColor={m.fontColor}
        messageColor={m.messageColor}
        url={m.url}
        imageUrl={m.imageUrl}
      />
    ))}
  </>
);

export default MessagesPreview;

MessagesPreview.propTypes = {
  messagesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      creator: PropTypes.string,
      text: PropTypes.string,
      fontFamily: PropTypes.string,
      fontColor: PropTypes.string,
      fontSize: PropTypes.string,
      messageColor: PropTypes.string,
      url: PropTypes.string,
      imageUrl: PropTypes.string,
      storagePath: PropTypes.string,
      chatId: PropTypes.string,
    })
  ),
};

MessagesPreview.defaultProps = {
  messagesList: [],
};
