/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable no-underscore-dangle */
/* eslint-disable lines-between-class-members */
import { delay } from 'lodash';
import log from '../log';
import { trackInForeground as TrackMetric } from '../util/analytics-util';
import { asyncDebounce } from './util';

interface ConstraintDefinition {
  high: Constraint;
  low: Constraint;
}

interface PublisherEntity {
  isAudioOn: boolean;
  isVideoOn: boolean;
  peerConnection: RTCPeerConnection;
  setTargetBitrate: (target: number) => void;
}

interface Constraint {
  bitrate: number;
  width: number;
  height: number;
  framerate: number;
}

type ConstraintType = 'camerasOnly' | 'screensharing';

interface ConstraintsGroup {
  [key: number]: { [key in ConstraintType]: ConstraintDefinition };
}

export enum BitrateBoundary {
  High = 'high',
  Low = 'low',
}

/* key: the minimum number of users before receiving these settings
   values: {
     camerasOnly: all stream quality properties when no screenshares active,
     screensharing: all stream quality properties when anyone is screensharing
   }
 */

/** Mock how many streams we are consuming, use false to skip. */
const FIXED_STREAM_COUNT: number | boolean = false; // 5;

/** By default use constraints for N streams no matter how much are publishing */
export const DEFAULT_STREAM_COUNT = 0;

export const CONSTRAINTS_BY_STREAM_COUNT: ConstraintsGroup = {
  0: {
    camerasOnly: {
      high: {
        bitrate: 500000,
        width: 640,
        height: 480,
        framerate: 24,
      },
      low: {
        bitrate: 262144,
        width: 320,
        height: 240,
        framerate: 16,
      },
    },
    screensharing: {
      high: {
        bitrate: 262144,
        width: 320,
        height: 240,
        framerate: 13,
      },
      low: {
        bitrate: 220000,
        width: 320,
        height: 240,
        framerate: 13,
      },
    },
  },
  4: {
    camerasOnly: {
      high: {
        bitrate: 300000,
        width: 320,
        height: 240,
        framerate: 24,
      },
      low: {
        bitrate: 180000,
        width: 320,
        height: 240,
        framerate: 24,
      },
    },
    screensharing: {
      high: {
        bitrate: 256000,
        width: 320,
        height: 240,
        framerate: 13,
      },
      low: {
        bitrate: 180000,
        width: 320,
        height: 240,
        framerate: 13,
      },
    },
  },
  6: {
    camerasOnly: {
      high: {
        bitrate: 262144,
        width: 320,
        height: 240,
        framerate: 24,
      },
      low: {
        bitrate: 180000,
        width: 320,
        height: 240,
        framerate: 24,
      },
    },
    screensharing: {
      high: {
        bitrate: 262144,
        width: 320,
        height: 240,
        framerate: 10,
      },
      low: {
        bitrate: 180000,
        width: 320,
        height: 240,
        framerate: 10,
      },
    },
  },
  10: {
    camerasOnly: {
      high: {
        bitrate: 262144,
        width: 320,
        height: 240,
        framerate: 16,
      },
      low: {
        bitrate: 180000,
        width: 320,
        height: 240,
        framerate: 16,
      },
    },
    screensharing: {
      high: {
        bitrate: 262144,
        width: 320,
        height: 240,
        framerate: 10,
      },
      low: {
        bitrate: 180000,
        width: 320,
        height: 240,
        framerate: 10,
      },
    },
  },
};

const SET_CONSTRAINTS_DEBOUNCE_TIME = 2000;

export default class StreamConstraints {
  externalMediaPlaying = false;
  publisherEntity: PublisherEntity;

  private _bitrateBoundary: BitrateBoundary = BitrateBoundary.High;
  private _localStreamConstraints: ConstraintDefinition;

  /**
   * Lock constraints from subsequent modifications
   */
  public lock = false;

  constructor() {
    log.debug(`Constraints: Default Contraints are set for ${DEFAULT_STREAM_COUNT} streams.`);
    if (FIXED_STREAM_COUNT) {
      log.warn(`Constraints: Fixed stream count is set to ${FIXED_STREAM_COUNT}`);
    }
    this.resetLocalStreamConstraints();
  }

  get bitrateBoundary() {
    return this._bitrateBoundary;
  }

  set bitrateBoundary(newBoundary) {
    if (newBoundary !== this._bitrateBoundary) {
      this._bitrateBoundary = newBoundary;
      this.setConstraints(this.localStreamConstraints, true);
    } else {
      this._bitrateBoundary = newBoundary;
    }
  }

  resetLocalStreamConstraints() {
    this._localStreamConstraints = { ...CONSTRAINTS_BY_STREAM_COUNT[DEFAULT_STREAM_COUNT].camerasOnly };
  }

  get localStreamConstraints() {
    if (!this._localStreamConstraints) this.resetLocalStreamConstraints();
    return this._localStreamConstraints;
  }

  set localStreamConstraints(constraints) {
    this._localStreamConstraints = constraints;
  }

  get defaultBitrate() {
    return this.localStreamConstraints[this.bitrateBoundary].bitrate;
  }

  get videoSender() {
    const senders = this.publisherEntity.peerConnection.getSenders();
    return senders.find((s) => s.track && s.track.kind === 'video');
  }

  get senderEncodingParams() {
    return this.videoSender.getParameters().encodings;
  }

  async updateLocalStreamConstraints(activeStreams: { [key: string]: MediaStream }, isScreenSharing = false) {
    try {
      if (!activeStreams && !isScreenSharing && !this.externalMediaPlaying) return;
      let numVideoStreams = FIXED_STREAM_COUNT;
      if (!FIXED_STREAM_COUNT) {
        numVideoStreams = Object.values(activeStreams).reduce(
          (acc, stream) => (stream?.active ? acc + (stream?.getVideoTracks().length || 0) : acc),
          0
        );
      }
      let key = 0;
      Object.keys(CONSTRAINTS_BY_STREAM_COUNT).forEach((item) => {
        const numItem = parseInt(item, 10);
        if (numItem <= numVideoStreams && numItem > key) {
          key = numItem;
        }
      });
      log.log(
        `Constraints: Active streams: ${numVideoStreams}, using constraints for min ${key}, with screenshare: ${isScreenSharing}, with external player: ${this.externalMediaPlaying}`
      );

      const constraints =
        CONSTRAINTS_BY_STREAM_COUNT[key][
          this.externalMediaPlaying || isScreenSharing ? 'screensharing' : 'camerasOnly'
        ];
      await this.setConstraints(constraints);
    } catch (error) {
      log.error(`StreamConstraints: Error updating local constraints: ${error}`);
      TrackMetric('SFU Error Updating Local Constraints', { error: error.message });
    }
  }

  setConstraints = asyncDebounce(
    async (newConstraints: ConstraintDefinition, force = false, attempts = 0) => {
      if (this.lock) {
        log.warn(`StreamConstraints: constraints locked not applying ${newConstraints}`);
        return;
      }

      if (!this.publisherEntity?.isVideoOn || !this.publisherEntity?.peerConnection) {
        this.localStreamConstraints = newConstraints;
        return;
      }

      if (
        !force &&
        newConstraints[this.bitrateBoundary].height === this.localStreamConstraints[this.bitrateBoundary].height &&
        newConstraints[this.bitrateBoundary].bitrate === this.localStreamConstraints[this.bitrateBoundary].bitrate &&
        newConstraints[this.bitrateBoundary].framerate === this.localStreamConstraints[this.bitrateBoundary].framerate
      ) {
        return;
      }

      try {
        if (this.publisherEntity?.peerConnection?.iceConnectionState !== 'connected') {
          throw new Error('Constraints: Will not apply constraints beacuse client is not publishing');
        }

        if (!this.videoSender) {
          throw new Error('Constraints: No video sender found in my PeerConnection');
        }

        const senderParams = this.videoSender.getParameters();

        if (!senderParams?.encodings || senderParams.encodings.length === 0) {
          throw new Error(`Constraints: No encodings on sender params: ${JSON.stringify(senderParams)}`);
        }

        log.debug(
          `Constraints: oldConstraints\n: ${JSON.stringify(
            this.localStreamConstraints
          )}\nsetConstraints: ${JSON.stringify(newConstraints)} with boundary: ${this.bitrateBoundary}`
        );

        if (!senderParams.encodings) {
          senderParams.encodings = [{}];
        }

        const newTargetBitrate = newConstraints[this.bitrateBoundary].bitrate;
        this.publisherEntity.setTargetBitrate(newTargetBitrate);

        const encoding = senderParams.encodings[0] as RTCRtpEncodingParameters & { maxFramerate: number };
        encoding.maxBitrate = newConstraints[this.bitrateBoundary].bitrate;
        encoding.maxFramerate = newConstraints[this.bitrateBoundary].framerate;

        await this.videoSender.track.applyConstraints({
          height: { ideal: newConstraints[this.bitrateBoundary].height },
        });
        await this.videoSender.setParameters(senderParams);

        this.localStreamConstraints = newConstraints;
      } catch (err) {
        if (attempts < 10) {
          log.warn(
            `Constraints: Something went wrong updating constraints: ${err},
            videoSenderTrack: {enabled: ${this.videoSender?.track?.enabled},
            readyState: ${this.videoSender?.track?.readyState}}, retrying...`
          );
          delay(this.setConstraints.bind(this), 1000, newConstraints, force, attempts + 1);
        } else {
          log.warn(`Constraints: Update canceled after ${attempts} attempts, error: ${err.message}`);
          TrackMetric('SFU Error Setting Constraints', { error: err.message, name: err.name });
        }
      }
    },
    SET_CONSTRAINTS_DEBOUNCE_TIME,
    { trailing: true }
  );

  videoMediaConfig(videoDeviceId: string, definition: ConstraintDefinition) {
    const config: MediaTrackConstraints = {
      width: { ideal: definition[this.bitrateBoundary].width },
      height: { ideal: definition[this.bitrateBoundary].height },
      frameRate: { ideal: definition[this.bitrateBoundary].framerate, max: 30 },
    };

    if (videoDeviceId) {
      config.deviceId = { exact: videoDeviceId };
    }

    return config;
  }

  audioMediaConfig(audioDeviceId: string, audioMediaType: 'voice' | 'screen') {
    const config: MediaTrackConstraints = {
      echoCancellation: true,
    };

    if (
      audioMediaType === 'voice' &&
      Object.keys(navigator.mediaDevices.getSupportedConstraints()).includes('channelCount')
    ) {
      config.channelCount = 1;
    }

    if (audioDeviceId) {
      config.deviceId = { exact: audioDeviceId };
    }
    return config;
  }
}
