import React, { useContext } from 'react';

import { renderBanUserModal, renderReportUserModal } from '../../../web-lobby/modals/chat-management/render';
import MessageContextButton from './MessageContextButton';
import ContextMenu from '../../../components/ContextMenu';
import DeleteButton from './DeleteButton';
import { ChatPermissionsContext } from '../../../common/contexts';
import { isCurrentUser } from '../../../../util/user-util';
import { useCanDeleteMessage } from './useCanDeleteMessage';

interface MessageContextMenuProps {
  chatId?: string;
  text: string;
  creator: string;
  options?: React.ReactNode[];
  deleteSelf: () => void;
}

const MessageContextMenu = ({ chatId, text, creator, options = [], deleteSelf }: MessageContextMenuProps) => {
  const permissions = useContext(ChatPermissionsContext);
  const canDelete = useCanDeleteMessage(creator);

  const onReportClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    renderReportUserModal(creator, chatId, text);
  };

  const onBanClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    renderBanUserModal(creator, chatId);
  };

  const onDeleteMessage = (e: MouseEvent) => {
    e.stopPropagation();
    deleteSelf();
  };

  return (
    <ContextMenu>
      {chatId && !isCurrentUser(creator) ? (
        <MessageContextButton onClick={onReportClick}>Report</MessageContextButton>
      ) : null}

      {chatId && permissions.canRemoveMembers && !isCurrentUser(creator) ? (
        <MessageContextButton onClick={onBanClick}>Ban</MessageContextButton>
      ) : null}

      {...options.map((option) => option)}

      {canDelete ? <DeleteButton onClick={onDeleteMessage} /> : null}
    </ContextMenu>
  );
};

export default MessageContextMenu;
