import { createAction } from '@reduxjs/toolkit';
import { EmoteFromResponse, StickerPack } from '../../../definitions/emotes';

export const fetchEmotesRequest = createAction('emotes/fetchEmotes');
export const fetchEmotesSuccess = createAction<{ hereEmotePack: EmoteFromResponse[]; emotes: EmoteFromResponse[] }>(
  'emotes/fetchEmotesSuccess'
);
export const fetchEmotesError = createAction<{ error: string }>('emotes/fetchEmotesError');

export const fetchStickerPacksRequest = createAction('emotes/fetchStickerPacks');
export const fetchStickerPacksSuccess = createAction<{ stickerPacks: StickerPack[] }>(
  'emotes/fetchStickerPacksSuccess'
);
export const fetchStickerPacksError = createAction<{ error: string }>('emotes/fetchStickerPacksError');

export const addFromEmotePackRequest = createAction<{ url: string; pack: string }>('emotes/addEmote');
export const addFromEmotePackSuccess = createAction<{ emote: EmoteFromResponse }>('emotes/addEmoteSuccess');
export const addFromEmotePackError = createAction<{ error: string }>('emotes/addEmoteError');

export const removeEmoteRequest = createAction<{ url: string }>('emotes/removeEmote');
export const removeEmoteSuccess = createAction<{ url: string }>('emotes/removeEmoteSuccess');
export const removeEmoteError = createAction<{ error: string }>('emotes/removeEmoteError');
