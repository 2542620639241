import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createFriendRequest } from '../store/friends/api';

// store
import { getMemberIsOnline, getMemberProfile } from '../store/room-members/selectors';
import {
  acceptedNotificationsSelector,
  selectCanAddToFriend,
  selectFirstTimeMemberFriendSuggestion,
  selectOutsideReactionFriendSuggestion,
} from '../store/friends/selectors';
import { setOutsideReactionFriendSuggestion } from '../store/friends/store';

// components
import FriendSuggestionPopover from './FriendSuggestionPopover';

// utils
import { track } from '../../util/analytics-util';

// constants
import { FRIEND_REQUEST_SOURCES, REQUEST_FRIEND } from '../../constants/analytics-events/friend-events';

const OutsideReactionFriendSuggestion = () => {
  const dispatch = useDispatch();

  const firstTimeMemberSuggestionId = useSelector(selectFirstTimeMemberFriendSuggestion)?.memberId;
  const outsideReactionFriendSuggestion = useSelector(selectOutsideReactionFriendSuggestion);
  const acceptedNotifications = useSelector(acceptedNotificationsSelector);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const canAddToFriend = useSelector((state) => selectCanAddToFriend(state, outsideReactionFriendSuggestion?.senderId));
  const member = useSelector((state) =>
    outsideReactionFriendSuggestion ? getMemberProfile(state, { id: outsideReactionFriendSuggestion?.senderId }) : null
  );
  const isOnline = useSelector((state) =>
    outsideReactionFriendSuggestion?.senderId
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getMemberIsOnline(state, { id: outsideReactionFriendSuggestion?.senderId })
      : false
  );

  const close = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(setOutsideReactionFriendSuggestion({ suggestion: null }));
  };

  const addFriend = async () => {
    close();
    const response = await createFriendRequest(outsideReactionFriendSuggestion?.senderId);
    if (response && response.success) {
      track(REQUEST_FRIEND, {
        source: FRIEND_REQUEST_SOURCES.OUTSIDE_REACTION_SUGGESTION,
        receiverId: outsideReactionFriendSuggestion?.senderId,
      });
    }
  };

  return outsideReactionFriendSuggestion && !firstTimeMemberSuggestionId && !acceptedNotifications.length ? (
    <FriendSuggestionPopover
      isOnline={isOnline}
      text={`${member.name} has sent ${outsideReactionFriendSuggestion.emoji}!`}
      member={member}
      showAddFriendButton={canAddToFriend}
      onClose={close}
      onAddFriend={addFriend}
    />
  ) : null;
};

export default OutsideReactionFriendSuggestion;
