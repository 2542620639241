import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { TipsAndTricks } from './init-state';
import { addTip, hideTip, removeTip, showTip } from './actions';

import { track } from '../../../util/analytics-util';
import log from '../../../log';

function* handleShowTip({ payload: { tip, source } }: PayloadAction<{ tip: TipsAndTricks; source: string }>) {
  try {
    yield put(addTip({ tip }));
    yield call(track, 'show tip', { tip, source });
  } catch (error) {
    log.error(error);
  }
}

function* handleHideTip({ payload: { tip, source } }: PayloadAction<{ tip: TipsAndTricks; source: string }>) {
  try {
    yield put(removeTip({ tip }));
    yield call(track, 'hide tip', { tip, source });
  } catch (error) {
    log.error(error);
  }
}

export default function* tipsAndTricksSaga() {
  yield all([takeEvery(showTip, handleShowTip), takeEvery(hideTip, handleHideTip)]);
}
