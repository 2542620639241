export const ADD_PHONE_NUMBER_CLICKED = 'Add Phone Number Clicked';
export const SEND_CODE_CLICKED = 'Send Code Clicked';
export const RESEND_CODE_CLICKED = 'Resend Code Clicked';
export const VERIFY_CODE_CLICKED = 'Verify Code Clicked';
export const PHONE_NUMBER_SUCCESSFULLY_VERIFIED = 'Phone Number Successfully Verified';
// params: error (string)
export const SEND_CODE_ERROR = 'Send Code Error';
// params: error (string)
export const RESEND_CODE_ERROR = 'Resend Code Error';
// params: error (string)
export const VERIFY_CODE_ERROR = 'Verify Code Error';
export const STILL_DIDNT_GET_CODE_LINK_CLICKED = "Still Didn't Get Code Link Clicked";
export const RECAPTCHA_CALLBACK = 'Recaptcha Callback';
export const RECAPTCHA_ERROR_CALLBACK = 'Recaptcha Error Callback';
export const RECAPTCHA_EXPIRED_CALLBACK = 'Recaptcha Expired Callback';
