import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../definitions/store';
import { EmoteFromResponse } from '../../../definitions/emotes';

export const selectEmotes = (state: RootState) => state.emotes.emotes;

export const selectHereEmotePack = (state: RootState) => state.emotes.hereEmotePack;

export const selectHereEmotePackForEmojiPicker = createSelector([selectHereEmotePack], (emotes) =>
  emotes.map((emote: EmoteFromResponse, i: number) => ({
    id: i,
    imageUrl: emote.url,
    name: `here-pack-${i}`,
    short_names: [`here-pack-${i}`], // emoji-mart expects this as snakecase ¯\_(ツ)_/¯
  }))
);

export const selectStickerPacks = (state: RootState) => state.emotes.stickerPacks;

export const selectIsAdded = createSelector(
  [selectEmotes, (state: RootState, url: string) => url],
  (emotes: EmoteFromResponse[], url: string) => emotes.some((emote) => emote.url === url)
);
