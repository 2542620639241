export const getCreateElementById = (id, type = 'div') => {
  let el = document.getElementById(id);

  if (!el) {
    el = document.createElement(type);
    el.setAttribute('id', id);
    document.body.append(el);
  }

  return el;
};
