import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// store
import { selectUserById } from '../../../store/users/selectors';
import { removePopupMessage } from '../../../store/messaging/reducer';

// components
import UserAvatar from '../../../components/UserAvatar';
import RoomCardMessage, { RoomCardMessageWrapper, RoomCardDismissButton } from '../../messages/RoomCardMessage';
import RotateFadeIn from '../../../components/animate/RotatedFadeIn';
import Wiggle from '../../../components/animate/Wiggle';
import MessagePreview from '../MessagePreview.tsx';
import { ImageMargin, MessageBubble, MessageImg, MessageText, UserDisplayName } from './shared-styles.ts';

// utils
import { isEmoji } from '../../../../util/string-util';
import { containsText, getEmojiCount } from '../../../../util';

// constants
import {
  DELAY_BEFORE_FADEOUT_MS,
  EMOJI_COUNT_THRESHOLD,
  FADEOUT_DURATION_MS,
  INVITE_MESSAGE_DELAY_MS,
  messageTypes,
} from '../../../../constants/chat-constants';

// hooks
import { useUserProfileData } from '../../../hooks/useUserProfileData';
import { selectChatByChatId } from '../../../store/messaging/selectors';

const roomAppearAnimationDuration = 0.4;

const DirectMessagePreview = ({
  id,
  dmChatId,
  type,
  creator,
  text,
  roomId,
  fontFamily,
  fontColor,
  url,
  imageUrl,
  messageColor,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => selectUserById(state, creator));
  const chatObj = useSelector((state) => selectChatByChatId(state, dmChatId));
  const { userProfile } = useUserProfileData(creator);
  const textIsEmoji = text && isEmoji(text) && getEmojiCount(text) < EMOJI_COUNT_THRESHOLD && !containsText(text);

  const startFadingOut = useCallback(() => {
    setTimeout(
      () => {
        dispatch(removePopupMessage({ messageId: id }));
      },
      type === messageTypes.INVITE ? INVITE_MESSAGE_DELAY_MS : FADEOUT_DURATION_MS
    );
  }, [dispatch, id, type]);

  const avatarNode = useMemo(
    () =>
      type !== messageTypes.INVITE && userProfile ? (
        <UserAvatarContainer>
          <UserAvatar
            userProfile={userProfile}
            customBorderColor={userProfile.mood ? null : '#ffffff'}
            borderWidth={3}
            borderRadius="100%"
          />
        </UserAvatarContainer>
      ) : null,
    [type, userProfile]
  );

  const displayNameNode = useMemo(
    () =>
      type !== messageTypes.INVITE && user ? (
        <UserDisplayName>
          {user.username ? `@${user.username}` : user.displayName}
          {chatObj.groupName ? ` to ${chatObj.groupName}` : ''}
        </UserDisplayName>
      ) : null,
    [type, user, chatObj]
  );

  const messageNode = useMemo(() => {
    switch (type) {
      case messageTypes.TEXT: {
        // Emoji message
        if (textIsEmoji) {
          return (
            <ImageMargin>
              <MessageText fontFamily="auto" fontSize="50px" fontColor="auto">
                {text}
              </MessageText>
            </ImageMargin>
          );
        }

        // Text message
        return (
          <MessageBubble key={id} bgColor={messageColor || '#6b1be3'}>
            <MessageText lineHeight="16px" fontFamily={fontFamily} fontColor={fontColor || 'white'} fontSize="14px">
              {text}
            </MessageText>
          </MessageBubble>
        );
      }

      case messageTypes.IMAGE: {
        return (
          <ImageMargin>
            <MessageImg src={imageUrl} alt="img message" />
          </ImageMargin>
        );
      }

      case messageTypes.GIF: {
        return <MessageImg src={url} alt="gif message" />;
      }

      case messageTypes.INVITE: {
        if (roomId) {
          return (
            <RotateFadeIn time={roomAppearAnimationDuration}>
              <RoomCardWiggleAnimationWrapper time={1} delay={roomAppearAnimationDuration}>
                <RoomCardMessage messageId={id} dmChatId={dmChatId} creator={creator} roomId={roomId} />
              </RoomCardWiggleAnimationWrapper>
            </RotateFadeIn>
          );
        }

        return null;
      }

      default: {
        return null;
      }
    }
  }, [type, id, roomId, creator, dmChatId, text, textIsEmoji, messageColor, fontFamily, fontColor, imageUrl, url]);

  if (!user) {
    return null;
  }

  return (
    <MessagePreviewContainer>
      <MessagePreview
        imageNode={avatarNode}
        titleNode={displayNameNode}
        messageNode={messageNode}
        delayBeforeFadeoutMs={type === messageTypes.INVITE ? INVITE_MESSAGE_DELAY_MS : DELAY_BEFORE_FADEOUT_MS}
        onFadeOut={startFadingOut}
      />
    </MessagePreviewContainer>
  );
};

export default DirectMessagePreview;

DirectMessagePreview.propTypes = {
  id: PropTypes.string.isRequired,
  dmChatId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
  text: PropTypes.string,
  roomId: PropTypes.string,
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  messageColor: PropTypes.string,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
};

DirectMessagePreview.defaultProps = {
  text: '',
  roomId: null,
  fontFamily: '',
  fontColor: '',
  messageColor: '',
  url: '',
  imageUrl: '',
};

const MessagePreviewContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  ${RoomCardMessageWrapper} {
    width: 274px;
  }

  &:hover {
    ${RoomCardDismissButton} {
      display: block;
    }
  }
`;

const RoomCardWiggleAnimationWrapper = styled(Wiggle)`
  &:hover {
    animation: none;
  }
`;

const UserAvatarContainer = styled.div`
  width: 34px;
  height: 34px;
  margin-right: 8px;
  margin-top: 21px;
`;
