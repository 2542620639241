import { UserId } from '../definitions/index.definitions';
import JanusJS from './janus.definitions.original.d';

export { default } from './janus.definitions.original.d';

/**
 * These are the definitions imported from Janus, usually far behind
 * Javascript Client implementation.
 *
 * We forward the definitions that we care from them and define
 * and export the rest by our own.
 */

/**
 * These are our definitions and updates to Janus definitions.
 */

export type StreamId = string;
export type MidId = string;
export type FeedId = string;
export type MidDict = { [key in MidId]: JanusStream };

export enum MediaType {
  Audio = 'audio',
  Video = 'video',
}

export enum IceState {
  New = 'new',
  Checking = 'checking',
  Connected = 'connected',
  Completed = 'completed',
  Disconnected = 'disconnected',
  Failed = 'failed',
  Closed = 'closed',
}

export enum PeerConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export interface PublisherFeed {
  id: string;
  display: string;
  streams?: PublisherStreamParams[];
  dummy?: boolean;
  video_codec?: string;
  audio_codec?: string;
}

export interface PublisherStreamParams {
  type: MediaType & 'data';
  mid: string; // "0"
  mindex: number;
  disabled: boolean;
  codec?: string; // codec used for published stream #1>
  description?: string; // <text description of published stream #1, if any>
  moderated?: boolean; // <true if this stream audio has been moderated for this participant>
  simulcast?: boolean; // "<true if published stream #1 uses simulcast (VP8 and H.264 only)>
  svc?: boolean; // <true if published stream #1 uses SVC (VP9 only)>
  talking?: boolean; // <true|false, whether the publisher stream has audio activity or not (only if audio leve
  source_ids?: UserId[]; // An Array of User Ids subscribed to this stream if it is a data stream
}

export interface PublisherStreamParamsWithFeedId extends PublisherStreamParams {
  feed_id?: FeedId;
  source_ids?: FeedId[];
  active?: boolean;
}

/** StreamEvent Represents a part of an 'attached' or 'updated' videoroom response. */
export interface StreamEventParams {
  'type': MediaType & 'data';
  'mid': string; // "0"
  'mindex': number;
  'feed_display': string; // "idJGblNoq7Q94qeYc7VuyVupQ6w2"
  'feed_id'?: string; // "72461fee-a4bf-46cd-9bae-d175d1f207a3"
  'feed_mid': string; // "0"
  'ready': boolean;
  'send': boolean;
  'active'?: boolean;
  'codec': string; // only for audio or video, "h264"
  'h264-profile': string; // WTF, yes, they use dash. Only for video
  'source_ids'?: []; // only for data
  'disabled': boolean;
}

export interface JanusAttachedEvent {
  videoroom: 'attached';
  room: string;
  streams: StreamEventParams[];
}

export interface JanusMessageRequest {
  request: string;
}
export interface UnsubscribePayload extends JanusMessageRequest {
  streams: JoinAndSubscribeFeedPayload[];
}

export interface JoinAndSubscribeFeedPayload {
  feed: string;
  mid?: string;
}

export type OfferType = 'audio' | 'video' | 'screen' | 'data';
export type OfferParams = JanusJS.OfferParams;
export type TrackOption = JanusJS.TrackOption;
export interface ConfiguredTrack {
  type: 'audio' | 'video' | 'data';
  disabled?: boolean;
  mindex: number;
  mid: string;
  codec?: string;
  h264_profile?: string;
}

export interface JanusStream {
  // https://janus.conf.meetecho.com/docs/videoroom.html

  mid: string; // number or string ? <unique mid of this stream>
  type: MediaType & 'data'; // <type of this stream's media (audio|video|data)>
  mindex: number;

  //
  // "videoroom" : "attached",
  //
  feed_id?: string; // <unique ID of the publisher originating this stream>
  feed_mid?: string; // number or string? <unique mid of this publisher's stream>
  feed_display?: string; // display name of this publisher, if any>
  send?: boolean; // <true|false; whether we configured the stream to relay media>
  ready?: boolean; // <true|false; whether this stream is ready to start sending media (will be false at the beginning)>

  //
  // "videoroom" : "joined",
  // "publishers" : [{ ... }]
  //
  codec?: string; // codec used for published stream #1>
  description?: string; // <text description of published stream #1, if any>
  moderated?: boolean; // <true if this stream audio has been moderated for this participant>
  simulcast?: boolean; // "<true if published stream #1 uses simulcast (VP8 and H.264 only)>
  svc?: boolean; // <true if published stream #1 uses SVC (VP9 only)>
  talking?: boolean; // <true|false, whether the publisher stream has audio activity or not (only if audio leve

  //
  // "request" : "configure",
  // "streams" : [
  //
  substream?: number; // <substream to receive (0-2), in case simulcasting is enabled; optional>
  temporal?: number; // <temporal layers to receive (0-2), in case simulcasting is enabled; optional>
  fallback?: number; // <How much time (in us, default 250000) without receiving packets will make us drop to the substream below; optional
  spatial_layer?: number; // <spatial layer to receive (0-2), in case VP9-SVC is enabled; optional>
  temporal_layer?: number; // <temporal layers to receive (0-2), in case VP9-SVC is enabled; optional>
  audio_level_average?: number; // <if provided, overrides the room audio_level_average for this user; optional>
  audio_active_packets?: number; // <if provided, overrides the room audio_active_packets for this user; optional>
  min_delay?: number | null; // <minimum delay to enforce via the playout-delay RTP extension, in blocks of 10ms; optional>
  max_delay?: number | null; // <maximum delay to enforce via the playout-delay RTP extension, in blocks of 10ms; optional>
}

/* Error codes */
export enum JanusVideoRoomError {
  ERROR_UNKNOWN_ERROR = 499,
  ERROR_NO_MESSAGE = 421,
  INVALID_JSON = 422,
  INVALID_REQUEST = 423,
  JOIN_FIRST = 424,
  ALREADY_JOINED = 425,
  NO_SUCH_ROOM = 426,
  ROOM_EXISTS = 427,
  NO_SUCH_FEED = 428,
  MISSING_ELEMENT = 429,
  INVALID_ELEMENT = 430,
  INVALID_SDP_TYPE = 431,
  PUBLISHERS_FULL = 432,
  UNAUTHORIZED = 433,
  ALREADY_PUBLISHED = 434,
  NOT_PUBLISHED = 435,
  ID_EXISTS = 436,
  INVALID_SDP = 437,
}
