import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { addReaction, removeReaction } from '../../../../api/feed-api';

// components
import MessageReactionsContext from './MessageReactionsContext';
import ReactionsLabelsList from './ReactionsLabelsList.tsx';

const ChatReactionLabelsList = ({ emojiReactions, messageId }) => {
  const { messagesCollectionPath } = useContext(MessageReactionsContext);

  const handleUnreact = useCallback(
    async (reactionData) => {
      await removeReaction({
        messageId,
        messagesCollectionPath,
        reaction: reactionData.reaction,
        customReaction: reactionData.customReaction,
        prevEmojiReactions: emojiReactions,
      });
    },
    [messageId, messagesCollectionPath, emojiReactions]
  );

  const handleReact = useCallback(
    async (reactionData) => {
      if (reactionData.emoteUrl) {
        await addReaction({
          messageId,
          messagesCollectionPath,
          customReaction: reactionData.emoteUrl,
          emoteType: reactionData.emoteType,
          pack: reactionData.pack,
          prevEmojiReactions: emojiReactions,
        });
      } else {
        await addReaction({
          messageId,
          messagesCollectionPath,
          reaction: reactionData.emoji,
          prevEmojiReactions: emojiReactions,
        });
      }
    },
    [emojiReactions, messageId, messagesCollectionPath]
  );

  const addSameReaction = useCallback(
    async (reactionData) => {
      await handleReact({ emoji: reactionData.reaction, emoteUrl: reactionData.customReaction });
    },
    [handleReact]
  );

  return (
    <ReactionsLabelsList
      emojiReactions={emojiReactions}
      onEmojiPick={handleReact}
      handleUnreact={handleUnreact}
      addSameReaction={addSameReaction}
    />
  );
};

ChatReactionLabelsList.propTypes = {
  emojiReactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  messageId: PropTypes.string.isRequired,
};

export default ChatReactionLabelsList;
