import React, { useRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRandomPrompt } from '../../util/user-card-util';
import DiceIcon from '../../../assets/icons/dice.svg';
import { AFFIRMATIVE_RESPONSES, USER_CARD_WIDTH_PX } from '../../constants/user-card-constants';
import LottieAnimation from '../animations/LottieAnimation';
import animationData from '../../../assets/lottie/sparkle-confetti.json';
import FirstTimeEditModal from '../user-page/FirstTimeEditModal';
import Portal from '../components/Portal';

const CardEditTitle = ({ title, onTitleUpdate, isFirstCard }) => {
  const cardTitleInputRef = useRef(null);

  const [inspirationSparklesCoords, setInspirationSparklesCoords] = useState(null);
  const showInspireMeSparkles = () => {
    const titleInputRect = cardTitleInputRef.current?.getBoundingClientRect();
    const heightToCenterOfSparklesAnimation = 45;
    setInspirationSparklesCoords({
      top: titleInputRect?.y - titleInputRect?.height - heightToCenterOfSparklesAnimation,
      left: titleInputRect?.x,
    });

    const timeout = setTimeout(() => {
      setInspirationSparklesCoords(null);
      clearTimeout(timeout);
    }, 1300);
  };

  const [inspirationConfirmation, setInspirationConfirmation] = useState('');
  useEffect(() => {
    if (inspirationConfirmation) {
      const timeout = setTimeout(() => {
        setInspirationConfirmation('');
        clearTimeout(timeout);
      }, 800);
    }
  }, [inspirationConfirmation]);

  const getRandomAffirmativeResponse = () => {
    const random = Math.floor(Math.random() * AFFIRMATIVE_RESPONSES.length);
    return AFFIRMATIVE_RESPONSES[random];
  };

  const onInspireMeClick = () => {
    showInspireMeSparkles();
    onTitleUpdate(getRandomPrompt({ isFirstCard }));
    setInspirationConfirmation(getRandomAffirmativeResponse());
  };

  // Keeping track of input cursor
  const [cursor, setCursor] = useState(null);

  useEffect(() => {
    const input = cardTitleInputRef.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [cardTitleInputRef, cursor, title]);

  const handleChange = useCallback(
    (e) => {
      setCursor(e.target.selectionStart);
      onTitleUpdate(e.target.value);
    },
    [onTitleUpdate]
  );

  return (
    <>
      {isFirstCard && <FirstTimeEditModal onTitleUpdate={onTitleUpdate} onPromptGiven={showInspireMeSparkles} />}
      <CardTitleInputContainer isFirstCard={isFirstCard}>
        {inspirationSparklesCoords && (
          <Portal root={document.body}>
            <SparklesAnimationContainer position={inspirationSparklesCoords}>
              <LottieAnimation animationData={animationData} loop />
            </SparklesAnimationContainer>
          </Portal>
        )}
        <CardTitleInput ref={cardTitleInputRef} value={title} onChange={handleChange} placeholder="my card" />
        {!isFirstCard && (
          // we don't show this on the user's first card, instead we show FirstTimeEditModal
          <InspireMeContainer onClick={inspirationConfirmation ? () => {} : onInspireMeClick}>
            {inspirationConfirmation ? (
              <>{inspirationConfirmation}</>
            ) : (
              <>
                <StyledDiceIcon />
                Inspire Me
              </>
            )}
          </InspireMeContainer>
        )}
      </CardTitleInputContainer>
    </>
  );
};

export default CardEditTitle;

CardEditTitle.propTypes = {
  onTitleUpdate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isFirstCard: PropTypes.bool.isRequired,
};

const CardTitleInputContainer = styled.div`
  position: absolute;
  bottom: ${({ isFirstCard }) => (isFirstCard ? '-48px' : '-75px')};

  width: 100%;
`;

const SparklesAnimationContainer = styled.div`
  position: fixed;
  top: ${({ position }) => `${position.top}px`};
  left: ${({ position }) => `${position.left}px`};
  width: ${USER_CARD_WIDTH_PX}px;
  z-index: 1;
`;

const CardTitleInput = styled.input`
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  background: none;
  border: none;
  border-bottom: 2px solid white;
  padding-bottom: 6px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const InspireMeContainer = styled.div`
  cursor: pointer;

  font-size: 12px;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  margin-top: 10px;
`;

const StyledDiceIcon = styled(DiceIcon)`
  margin-right: 5px;
`;
