import React, { useCallback, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import OsChatWindow from '../../os/chat/OsChatWindow';
import { BoardControllerContext, BoardElementControllerContext } from '../../common/contexts';
import { selectActiveChatId } from '../../store/messaging/selectors';

const ChatWindowElement: React.FC = () => {
  const { elementData, patchDbData, removeElement } = useContext(BoardElementControllerContext);
  const { maxZIndex } = useContext(BoardControllerContext);
  const activeChatId = useSelector(selectActiveChatId);

  const containerRef = useRef(null);
  const onContainerWheel = (e: React.WheelEvent) => {
    e.stopPropagation();
  };

  const moveToFront = () => {
    if (activeChatId !== elementData.chatId) {
      patchDbData({ zIndex: maxZIndex + 1 });
    }
  };

  const closeWindow = useCallback(() => {
    removeElement(elementData.id);
  }, [elementData.id, removeElement]);

  return (
    <Container onClick={moveToFront} ref={containerRef} onWheel={onContainerWheel}>
      <OsChatWindow chatId={elementData.chatId} onClose={closeWindow} />
    </Container>
  );
};

export default ChatWindowElement;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
