import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useBoardData } from '../hooks/useBoardData';
import { useBoardElementsData } from '../hooks/useBoardElementsData';
import { getBackgroundStyle } from '../../util';
import BoardElement from '../board-elements/BoardElement';
import Viewport from './Viewport';
import { hideableMixin, svgColorMixin } from '../mixins';
import { db } from '../../firebase';
import log from '../../log';
import DropArea from './DropArea';
import { useBoardDnd } from '../hooks/useBoardDnd';
import { useBoardPaste } from '../hooks/useBoardPaste';
import { BoardControllerContext, ViewportControllerContext } from '../common/contexts.ts';
import Portal from '../components/Portal';

import TrashcanIcon from '../../../assets/icons/trashcan.svg';
import { elementDefinitions } from '../board-elements/element-definitions';

const minElementsForOptimization = 50;
const renderingHorizon = 500;
const optimizationRecalculatingStep = renderingHorizon / 2;

const Board = ({ boardId, isEditable }) => {
  const [boardData] = useBoardData(boardId);
  const [elementsData] = useBoardElementsData(boardId);
  const [renderedElements, setRenderedElements] = useState([]);
  const shouldOptimize = elementsData.length >= minElementsForOptimization;

  const viewportController = useContext(ViewportControllerContext);
  const viewportRect = viewportController?.getViewportRect();

  const boardController = useContext(BoardControllerContext);

  // TODO: Move all optimization to a separate hook (or component?)
  const lastViewportOptimizationPositionRef = useRef({ x: 0, y: 0 });
  useEffect(() => {
    if (!shouldOptimize || !viewportRect) {
      return () => {};
    }

    const recalculateRenderedElements = ({ x, y, scale }) => {
      const renderingRect = {
        x1: x - renderingHorizon,
        x2: x + viewportRect.width / scale + renderingHorizon,
        y1: y - renderingHorizon,
        y2: y + viewportRect.height / scale + renderingHorizon,
      };
      setRenderedElements(
        elementsData.filter(
          (el) =>
            !(el.center[0] > renderingRect.x2 || el.center[0] + el.size[0] < renderingRect.x1) &&
            !(el.center[1] > renderingRect.y2 || el.center[1] + el.size[1] < renderingRect.y1)
        )
      );
    };

    recalculateRenderedElements(viewportController.getCoordinates());
    return viewportController.subscribeToViewportChanged(({ x, y, scale }) => {
      if (
        Math.abs(lastViewportOptimizationPositionRef.current.x - x) < optimizationRecalculatingStep &&
        Math.abs(lastViewportOptimizationPositionRef.current.y - y) < optimizationRecalculatingStep
      ) {
        return;
      }

      lastViewportOptimizationPositionRef.current = { x, y };
      recalculateRenderedElements({ x, y, scale });
    });
  }, [viewportController, elementsData, viewportRect, shouldOptimize]);

  const [isTrashcanVisible, setIsTrashcanVisible] = useState(false);
  const [isTrashcanHovered, setIsTrashcanHovered] = useState(false);
  const background = useMemo(
    () => getBackgroundStyle({ background: boardData?.background, backgroundColor: boardData?.backgroundColor }),
    [boardData?.background, boardData?.backgroundColor]
  );

  const onElementStartDragging = useCallback((elementData) => {
    const definition = elementDefinitions[elementData.class];
    if (!definition.hideTrashcan) {
      setIsTrashcanVisible(true);
    }
  }, []);
  const onElementStopDragging = useCallback(
    async (elementData) => {
      setIsTrashcanVisible(false);
      if (!isTrashcanHovered) return false;

      try {
        await db.doc(`boards/${boardId}/elements/${elementData.id}`).delete();
        if (boardController?.onElementDelete && elementData) {
          boardController.onElementDelete(elementData);
        }
        return true;
      } catch (err) {
        log.error(`Error removing element ${elementData.id}`, err);
        return false;
      }
    },
    [boardController, boardId, isTrashcanHovered]
  );

  const dnd = useBoardDnd();
  const paste = useBoardPaste();

  return (
    <Container background={background} {...dnd.listeners} {...paste.listeners}>
      <Viewport>
        <ElementsContainer>
          {(shouldOptimize ? renderedElements : elementsData).map((el) => (
            <BoardElement
              key={el.id}
              boardId={boardId}
              elementData={el}
              isEditable={isEditable}
              onStartDragging={onElementStartDragging}
              onStopDragging={onElementStopDragging}
            />
          ))}
        </ElementsContainer>
      </Viewport>
      <Portal root={document.body}>
        <TrashcanContainer
          onMouseEnter={useCallback(() => setIsTrashcanHovered(true), [])}
          onMouseLeave={useCallback(() => setIsTrashcanHovered(false), [])}
          isHiddenDisplay={!isTrashcanVisible}
          viewportCenterLeft={viewportRect?.left + viewportRect?.width / 2}
          viewportBottom={viewportRect?.bottom}
        >
          <TrashcanIcon />
        </TrashcanContainer>
      </Portal>
      {dnd.isDropAreaVisible ? <DropArea /> : null}
    </Container>
  );
};

export default Board;

Board.propTypes = {
  boardId: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
};

Board.defaultProps = {
  isEditable: false,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${({ background }) => background}
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  overflow: hidden;
`;

const ElementsContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
`;

const trashcanSize = 60;
const TrashcanContainer = styled.div.attrs(({ viewportBottom, viewportCenterLeft }) => ({
  style: {
    top: `${viewportBottom - trashcanSize - 50}px`,
    left: `${viewportCenterLeft}px`,
  },
}))`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  position: fixed;
  transform: translateX(-50%);
  height: ${trashcanSize}px;
  width: ${trashcanSize}px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  ${svgColorMixin('black')}
  ${hideableMixin()}

  &:hover {
    background: rgba(255, 50, 10, 0.4);
  }

  svg {
    width: ${trashcanSize * 0.36}px;
  }
`;
