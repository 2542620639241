import React, { useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';

import firebase from '../../../../firebase';
import { BoardControllerContext, BoardElementControllerContext } from '../../../common/contexts.ts';
import { track } from '../../../../util/analytics-util';
import { YOUTUBE_VIDEO_ADDED, YOUTUBE_VIDEO_PLAYED } from './analytics';
import { getStandardizedYouTubeUrl } from '../../../../util/media-util';
import ActivatableElement from '../../ActivatableElement';

const YoutubeEmbedElement = () => {
  const { elementData, patchDbData } = useContext(BoardElementControllerContext);
  const { groupId } = useContext(BoardControllerContext);

  const currentUserId = firebase.auth().currentUser.uid;
  const { videoId } = elementData;

  const playerRef = useRef(null);
  const hasPlayingStartedRef = useRef(false);
  const onIframeRender = useCallback(
    (element) => {
      if (!element || playerRef.current) return;
      playerRef.current = new window.YT.Player(elementData.id, {
        events: {
          onReady: (e) => {
            // TODO: We can also detect video size here and change element size
            // in case it's different from standard 16:9 (e.g. vertical videos)
            if (currentUserId === elementData.creator && !elementData.isAnalyticsSent) {
              patchDbData({ isAnalyticsSent: true });
              track(YOUTUBE_VIDEO_ADDED, {
                groupId,
                name: e.target?.getVideoData()?.title,
                URL: getStandardizedYouTubeUrl(videoId),
                duration: e.target?.getDuration(),
              });
            }
          },
          onStateChange: (e) => {
            if (e.data === window.YT.PlayerState.PLAYING && !hasPlayingStartedRef.current) {
              track(YOUTUBE_VIDEO_PLAYED, {
                groupId,
                name: e.target?.getVideoData()?.title,
                URL: getStandardizedYouTubeUrl(videoId),
              });
              hasPlayingStartedRef.current = true;
            }
            if (e.data === window.YT.PlayerState.ENDED) {
              hasPlayingStartedRef.current = false;
            }
          },
        },
      });
    },
    [currentUserId, elementData.creator, elementData.id, elementData.isAnalyticsSent, groupId, patchDbData, videoId]
  );

  return (
    <ActivatableElement>
      <Iframe id={elementData.id} ref={onIframeRender} src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`} />
    </ActivatableElement>
  );
};

export default YoutubeEmbedElement;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;
