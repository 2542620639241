const MAX_IMAGE_UPLOAD_SIZE_BYTES = 1024 * 1024 * 8; // 8 mb
const allowedFileTypes = ['jpg', 'jpeg', 'png', 'gif'];

export const validateImageToUpload = (file: File): boolean => {
  if (file.size > MAX_IMAGE_UPLOAD_SIZE_BYTES) {
    return false;
  }

  const fileType = file.name.split('.').pop().toLowerCase();
  return allowedFileTypes.includes(fileType);
};
