import React from 'react';
import styled from 'styled-components';

// components
import { moreMenuItemMixin } from '../../../mixins';

type DeleteButtonProps = {
  onClick: (event: object) => void;
};

const DeleteButton = ({ onClick }: DeleteButtonProps) => <MenuItem onClick={onClick}>Delete</MenuItem>;

export default DeleteButton;

const MenuItem = styled.div`
  ${moreMenuItemMixin({ themed: false })}

  color: red;
`;
