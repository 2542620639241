import React from 'react';
import ReactDOM from 'react-dom';

import UserProfile from '.';
import ProfileHeaderForSignup from './ProfileHeaderForSignup';
import eventBus, { renderUserProfileRequested } from '../../event-bus';
import { WithRoomStore } from '../store/Wrapper';
import { EventSourceContext } from '../common/contexts.ts';

export function renderUserProfile({ userId, openToUserCards, editUserMood, source }) {
  let userProfileNode = (
    <WithRoomStore>
      <UserProfile userId={userId} openToUserCards={openToUserCards} editUserMood={editUserMood} id="renderMethod" />
    </WithRoomStore>
  );

  if (source) {
    userProfileNode = (
      <WithRoomStore>
        <EventSourceContext.Provider value={source}>
          <UserProfile
            userId={userId}
            openToUserCards={openToUserCards}
            editUserMood={editUserMood}
            id="renderMethod"
          />
        </EventSourceContext.Provider>
      </WithRoomStore>
    );
  }

  ReactDOM.render(userProfileNode, document.getElementById('user-profile'));
}

export function renderProfileHeaderForSignUp() {
  ReactDOM.render(
    <WithRoomStore>
      <ProfileHeaderForSignup />
    </WithRoomStore>,
    document.getElementById('profile-header-at-signup')
  );
}

// Doing indirect calls to avoid dependency cycles. This is not good, but there're a lot
// of cycles between ProfileCTAs.jsx and utils.js, so it's the only way to remove them
// without spending days on rewriting. The problem will disappear once there won't be calls
// to it from non-react parts.
let isListeningToRenderUserProfile = false;
export function listenToRenderUserProfile() {
  if (!isListeningToRenderUserProfile) {
    eventBus.on(renderUserProfileRequested, renderUserProfile);
    isListeningToRenderUserProfile = true;
  }
}
