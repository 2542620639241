import UIkit from 'uikit';
import log from './log';
import firebase from './firebase';

import { updateVideoVisibilityThreshold } from './video-visibility-manager';
import GSuiteElement from './widgets/embedded/gsuite';
import AppElement from './app-element';
import AppAdminElement from './app-admin';
import CobrowserElement from './cobrowser';
import BeaconElement from './beacon';
import EmbeddedElement from './embedded';
import { isElementsInteractionAllowed } from './roles-management';
import HereSFU from './sfu';
import { updateCaptionText } from './util/camera-util';
import { ELEMENT_TYPES } from './constants/analytics-events/element-events';

/**
 *
 * @param {string} text
 * @returns true if a recognized system command
 */
export async function handleSystemCommand(text) {
  if (text.startsWith('//')) {
    const userId = firebase.auth().currentUser.uid;
    const captionText = text.replace('//', '');

    updateCaptionText(userId, captionText);
    HereSFU.sendCaption(userId, captionText);
    return true;
  }
  if (text.startsWith('/stats')) {
    if (document.getElementById('webrtc-stats-box').style.display === 'block') {
      document.getElementById('webrtc-stats-box').style.display = 'none';
      window.rtc.setMonitorInterval(30000);
    } else {
      document.getElementById('webrtc-stats-box').style.display = 'block';
      window.rtc.setMonitorInterval(2000);
    }
    return true;
  }

  if (text.startsWith('/recover')) {
    window.rtc.sfuWatchDog.setCountersEdge();
    window.rtc.sfuWatchDog.dryRun = !window.rtc.sfuWatchDog.dryRun;
    if (window.rtc.sfuWatchDog.dryRun) log.log('SFU-Watchdog Disabled');
    else log.log('SFU-Watchdog Enabled');
    return true;
  }

  if (text.startsWith('/add ') && isElementsInteractionAllowed()) {
    const parts = text.split(' ');
    if (parts.length < 2) {
      log.error('Expected format: /add <element> <options>');
      return true;
    }
    if (parts[1] === 'iframe') {
      if (parts.length === 3) {
        CobrowserElement.addElement(parts[2]);
      } else {
        log.error('Expected format: /add iframe <url>');
      }
    } else if (parts[1] === 'embed') {
      if (parts.length === 3) {
        EmbeddedElement.addElement(parts[2], {
          name: ELEMENT_TYPES.EMBEDDED_WEB_OBJECT,
          size: [700, 440],
          preserveAspectRatio: true,
        });
      } else {
        log.error('Expected format: /add embed <url>');
      }
    } else if (parts[1] === 'app') {
      if (parts.length === 3) {
        AppElement.addElement({ appId: parts[2] });
      } else {
        log.error('Expected format: /add app <id>');
      }
    } else if (parts[1] === 'code') {
      await AppAdminElement.addElement(parts.length > 2 ? parts[2] : null);
    } else if (parts[1] === 'codedocs') {
      await GSuiteElement.addElement(
        'https://docs.google.com/document/d/e/2PACX-1vTB92ZpymfMflv7vunuHmZrCa8HPxWpNHGBK6UMBA8Gdrbl8pwX7x-rGjW91oCJdoGLzvsUPxARXV97/pub',
        { size: [800, 800] }
      );
    } else if (parts[1] === 'beacon') {
      await BeaconElement.addElement();
    } else if (parts[1] === 'synth') {
      const { default: SynthesizerElement } = await import('./synthesizer');
      await SynthesizerElement.addElement();
    } else if (parts[1] === 'spotify') {
      const { default: SpotifyElement } = await import('./widgets/spotify');
      if (!Object.values(window.elementHandlers).find((h) => h instanceof SpotifyElement)) {
        SpotifyElement.addElement();
      }
    }

    return true;
  }

  if (text.startsWith('/ssbitrate')) {
    const parts = text.split(' ');
    if (parts.length !== 2 && parts.length !== 3) {
      log.error('Expected format: /ssbitrate X');
      return true;
    }
    const bitrate = parseInt(parts[1], 10);
    window.rtc.screenshareBitrate = bitrate;
    return true;
  }

  if (text.startsWith('/ssframerate')) {
    const parts = text.split(' ');
    if (parts.length !== 2 && parts.length !== 3) {
      log.error('Expected format: /ssframerate X');
      return true;
    }
    const framerate = parseInt(parts[1], 10);
    window.rtc.screenshareFramerate = framerate;
    return true;
  }

  if (text.startsWith('/cameras')) {
    const parts = text.split(' ');
    if (parts.length !== 2 && parts.length !== 3) {
      log.error('Expected format: /cameras nearest X');
      return true;
    }
    if (parts[1] === 'nearest') {
      updateVideoVisibilityThreshold(parseInt(parts[2], 10));
    }
    return true;
  }

  if (text.startsWith('/host')) {
    const makeHost = firebase.functions().httpsCallable('makeHost');
    const result = await makeHost({ boardId: window.currentBoardId });
    log.debug('Make host result: ', result);
    return true;
  }

  if (text.startsWith('/owner')) {
    const parts = text.split(' ');
    if (parts.length < 2) {
      log.error('Expected format: /owner [name]');
      return true;
    }
    const transfer = firebase.functions().httpsCallable('transferRoomOwner');
    const newOwnerName = parts.slice(1).join(' ');
    const result = await transfer({ boardId: window.currentBoardId, newOwner: newOwnerName });
    log.debug('Owner transfer result: ', result);
    return true;
  }

  if (text.startsWith('/transferlink')) {
    const transfer = firebase.functions().httpsCallable('generateTransferLink');
    const result = await transfer({ boardId: window.currentBoardId });
    UIkit.modal.alert(
      `Transfer URL created: <input id="transferURL" value="https://here.fm/?trid=${result.data.transferId}#${window.currentBoardId}"></input><button onclick="document.getElementById('transferURL').select();document.execCommand('copy');">Copy</button>`
    );
    log.debug('Transfer link result: ', result);
    return true;
  }

  return false;
}
