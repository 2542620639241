import SessionLog from '../session-log.ts';
import {
  closeRoom,
  openLobby,
  loadRoom,
  hideElectronTemplatePicker,
  getCurrentElectronWindow,
  ELECTRON_WINDOWS,
} from '../electron-support/electron-support';
import { leaveBoard } from '../presence';
import { checkIsElectron } from './platform-util';
import { track } from './analytics-util';
import {
  HIDE_ONE_TIME_MOBILE_UPSELL,
  MOBILE_UPSELL_QUERY_PARAM,
  QUALITY_SURVEY_QUERY_PARAM,
} from '../constants/lobby-constants';
import { GROUP_PATH, HERE_OS_PATH } from '../definitions/groups.ts';

export const goToLobby = async (showMobileCTA = false) => {
  track('Exit Room');
  await leaveBoard(window.currentBoardId);
  if (checkIsElectron()) {
    openLobby(SessionLog.currentActivityId, window.currentBoardData.groupId);
    closeRoom();
  } else {
    let activityParam = '';
    if (SessionLog.currentActivityId) {
      activityParam = `?${QUALITY_SURVEY_QUERY_PARAM}=${SessionLog.currentActivityId}`;
    } else if (showMobileCTA && !localStorage.getItem(HIDE_ONE_TIME_MOBILE_UPSELL)) {
      activityParam = `?${MOBILE_UPSELL_QUERY_PARAM}=true`;
    }
    window.currentBoardId = null; // Prevent stuff from continuing to happen in the room while we're leaving

    if (window.currentBoardData?.groupId) {
      window.location.href = `/${GROUP_PATH}/${window.currentBoardData.groupId}${activityParam}`;
    } else {
      window.location.href = `/${GROUP_PATH}${activityParam}`;
    }
  }
};

export const goToLobbyAfterOnboarding = (groupId) => {
  if (checkIsElectron()) {
    openLobby(SessionLog.currentActivityId, groupId);
  }

  if (groupId) {
    window.location.href = `/${GROUP_PATH}/${groupId}`;
  } else {
    window.location.href = `/${GROUP_PATH}`;
  }
};

// Note: goToRoom should only be used if user is not currently already in a room
// for warping to a different room, use jumpIntoRoom in room-util
export const goToRoom = (boardId, { customUrl, isNewRoom, isOnboarding = false }) => {
  if (checkIsElectron()) {
    loadRoom(boardId);

    // if user is navigating to room from template picker window, close template picker window
    if (getCurrentElectronWindow() === ELECTRON_WINDOWS.TEMPLATE_PICKER) {
      hideElectronTemplatePicker();
    }
  } else {
    const snippetUrl = customUrl || boardId;

    const query = [];
    if (isNewRoom) query.push('newroom=true');
    if (isOnboarding) query.push('skip-preview=true');

    window.history.replaceState('string', 'Title', `/${snippetUrl}${query.length ? `?${query.join('&')}` : ''}`);
    if (window.location.search.includes('onboarding')) {
      window.location.search = '';
    } else {
      window.location.reload();
    }
  }
};
