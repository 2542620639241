import React, { useMemo, useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useGesture } from '@use-gesture/react';
import { useSpring, to, animated } from '@react-spring/web';

import { ViewportControllerContext } from '../common/contexts.ts';
import { clamp } from '../../util';

const Viewport = ({ children }) => {
  const containerRef = useRef(null);
  const viewportController = useContext(ViewportControllerContext);

  const initialViewportCoordinates = useMemo(() => {
    if (!viewportController) return { viewportPosition: [0, 0], viewportScale: 1 };

    const coordinates = viewportController.getCoordinates();
    return { viewportPosition: [coordinates.x, coordinates.y], viewportScale: coordinates.scale };
  }, [viewportController]);

  const updateViewportCoordinates = useCallback(
    (position, scale) => {
      const viewportRect = viewportController.getViewportRect();
      viewportController.onViewportChanged({
        x: position[0] - viewportRect.width / 2 / scale,
        y: position[1] - viewportRect.height / 2 / scale,
        scale,
      });
    },
    [viewportController]
  );

  const [{ viewportPosition, viewportScale }, api] = useSpring(() => ({
    ...initialViewportCoordinates,
    onChange: (e) => {
      if (!viewportController) return;
      updateViewportCoordinates(e.value.viewportPosition, e.value.viewportScale);
    },
  }));

  const bordersRef = useRef({ top: -Infinity, bottom: Infinity, left: -Infinity, right: Infinity });

  useEffect(() => {
    const listener = ({ coordinates, borders }) => {
      if (borders !== undefined) {
        const scale = viewportScale.get();
        const viewportSize = viewportController.getViewportRect();
        const halfHeight = viewportSize.height / 2 / scale;
        const halfWidth = viewportSize.width / 2 / scale;

        const isXFinite = Number.isFinite(borders[0]) && Number.isFinite(borders[2]);
        const isYFinite = Number.isFinite(borders[1]) && Number.isFinite(borders[3]);
        bordersRef.current = {
          left: isXFinite ? borders[0] + halfWidth : -Infinity,
          right: isXFinite ? borders[0] + borders[2] - halfWidth : Infinity,
          top: isYFinite ? borders[1] + halfHeight : -Infinity,
          bottom: isYFinite ? borders[1] + borders[3] - halfHeight : Infinity,
        };
      }

      if (coordinates !== undefined) {
        const params = {
          immediate: true,
          config: { duration: 700 },
        };
        if (!Number.isNaN(+coordinates.x) || !Number.isNaN(+coordinates.y)) {
          const viewportRect = viewportController.getViewportRect();
          const scale = coordinates.scale || viewportScale.get();
          params.viewportPosition = [
            Number.isNaN(+coordinates.x) ? viewportPosition.get()[0] : coordinates.x + viewportRect.width / 2 / scale,
            Number.isNaN(+coordinates.y) ? viewportPosition.get()[1] : coordinates.y + viewportRect.height / 2 / scale,
          ];
        }
        if (coordinates.scale) {
          params.viewportScale = coordinates.scale;
        }
        api.start(params);
        if (coordinates.forceRecalculate) {
          updateViewportCoordinates(params.viewportPosition, params.viewportScale);
        }
      }
    };

    return viewportController?.subscribeToViewportChangeRequest(listener);
  }, [api, updateViewportCoordinates, viewportController, viewportPosition, viewportScale]);

  const gestureConfig = {
    from: () => viewportPosition.get(),
    axis: viewportController.allowedAxis,
    enabled: viewportController.allowedAxis !== '',
    bounds: () => bordersRef.current,
  };
  useGesture(
    {
      onDrag: ({ down, offset, velocity, direction }) => {
        if (!down) {
          const staminaFactor = 200;
          offset = [
            clamp(
              offset[0] + velocity[0] * direction[0] * staminaFactor,
              bordersRef.current.left,
              bordersRef.current.right
            ),
            clamp(
              offset[1] + velocity[1] * direction[1] * staminaFactor,
              bordersRef.current.top,
              bordersRef.current.bottom
            ),
          ];
        }
        api.start({
          viewportPosition: offset,
          immediate: down,
          config: { velocity: [direction[0] * velocity[0], direction[1] * velocity[1]] },
        });
      },
      onWheel: ({ offset, target }) => {
        if (target.closest('.prevent-viewport-scrolling')) return;
        api.start({
          viewportPosition: offset,
          immediate: true,
        });
      },
    },
    {
      target: containerRef,
      wheel: {
        ...gestureConfig,
        preventDefault: true,
        eventOptions: { passive: false },
      },
      drag: {
        ...gestureConfig,
        pointer: { capture: false },
        transform: ([x, y]) => [-x, -y],
      },
    }
  );

  return (
    <Container ref={containerRef}>
      <ChildrenContainer position={viewportPosition} scale={viewportScale}>
        {children}
      </ChildrenContainer>
    </Container>
  );
};

export default Viewport;

Viewport.propTypes = {
  children: PropTypes.node.isRequired,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  touch-action: none;
`;

const ChildrenContainer = styled(animated.div).attrs(({ position, scale }) => ({
  style: {
    transform: to(
      [position, scale],
      ([x, y], scaleValue) => `scale3d(${scaleValue}, ${scaleValue}, 1) translate3d(${-x}px,${-y}px,0)`
    ),
  },
}))`
  width: 100%;
  height: 100%;
  will-change: transform;
`;
