import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { unreadCountMixin } from '../../mixins';

const UnreadsBadge = ({ unreadsCount }) => (
  <UnreadsBadgeContainer unreadsCount={unreadsCount}>{unreadsCount}</UnreadsBadgeContainer>
);

UnreadsBadge.propTypes = {
  unreadsCount: PropTypes.number.isRequired,
};

export const UnreadsBadgeContainer = styled.span`
  ${({ unreadsCount }) => unreadCountMixin({ unreadsCount })}
  z-index: 2;
`;

export default UnreadsBadge;
