export interface GroupsState {
  isLoading: boolean;
  error: string;
  // create group flow:
  isLoadingCreateGroup: boolean;
  createGroupError: string;
  createGroupFlowOpen: boolean;
  createdGroupId: string;
  // join group flow:
  isLoadingJoinGroup: boolean;
  joinedGroupId: string;
  joinGroupError: string;
  // delete group flow:
  isLoadingDeleteGroup: boolean;
  deleteGroupError: string;
  // transfer room to group flow:
  isLoadingTransferRoomToGroup: boolean;
  transferRoomToGroupError: string;
  // delete room from group flow:
  isLoadingDeleteRoomFromGroup: boolean;
  deleteRoomFromGroupError: string;
}

export const initialState: GroupsState = {
  isLoading: false,
  error: '',
  // create group flow:
  isLoadingCreateGroup: false,
  createGroupError: '',
  createGroupFlowOpen: false,
  createdGroupId: '',
  // join group flow:
  isLoadingJoinGroup: false,
  joinedGroupId: '',
  joinGroupError: '',
  // delete group flow:
  isLoadingDeleteGroup: false,
  deleteGroupError: '',
  // transfer room to group flow:
  isLoadingTransferRoomToGroup: false,
  transferRoomToGroupError: '',
  // delete room from group flow:
  isLoadingDeleteRoomFromGroup: false,
  deleteRoomFromGroupError: '',
};
