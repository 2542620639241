import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentScreenId } from '../../store/signing-in/selectors';
import VibePickerScreen from './VibePickerScreen';
import { CreateOsDesktopFlowSteps } from '../constants';
import DesktopCreationScreen from './DesktopCreationScreen';

const screens: { [k in CreateOsDesktopFlowSteps]?: React.FC } = {
  [CreateOsDesktopFlowSteps.VIBE_PICKER]: () => <VibePickerScreen />,
  [CreateOsDesktopFlowSteps.DESKTOP_CREATION]: () => <DesktopCreationScreen />,
};

export const CreateOsDesktop = () => {
  const screenId = useSelector(selectCurrentScreenId) as CreateOsDesktopFlowSteps;

  const Screen = screens[screenId];

  return <div>{Screen ? <Screen /> : null}</div>;
};

export default CreateOsDesktop;
