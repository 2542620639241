import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AddFriendIcon from '../../../assets/icons/add-friend.svg';

import FadeIn from '../components/animate/FadeIn';

import { renderFriendsModal } from '../friends/render';
import FriendRequestAlert from './FriendRequestAlert';
import { incomingRequestsAfterTimeSelector } from '../store/friends/selectors';
import { FRIENDS_MODAL_TABS } from '../../constants/friends-modal-constants.ts';
import { svgColorMixin } from '../mixins';

const AppAlerts = () => {
  const [initialTime] = useState(() => new Date().getTime());
  const [friendRequestAlerts, setFriendRequestAlerts] = useState({});

  // NOTE: eventually we should move this friend request specific logic into a separate component so that
  // index can be all app alerts (more general)

  // friend requests that come in after initial render time:
  const friendRequests = useSelector((store) => incomingRequestsAfterTimeSelector(store, initialTime));

  // turn friend requests into alerts:
  useEffect(() => {
    const alerts = {};

    friendRequests.forEach((friendRequest) => {
      const currAlert = friendRequestAlerts[friendRequest.requester];

      if (!currAlert || currAlert.updatedAt < friendRequest.updatedAt) {
        const alert = { ...friendRequest, dismissed: false };
        alerts[friendRequest.requester] = alert;
      }
    });

    setFriendRequestAlerts({ ...friendRequestAlerts, ...alerts });
    // Using friendRequestAlerts in deps array will cause infinite loop since
    // their previous value is used to update the value.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendRequests]);

  // only show alerts that are pending and are not dismissed:
  const alertsToShow = useMemo(
    () => Object.values(friendRequestAlerts).filter((alert) => !alert.dismissed && alert.status === 'pending'),
    [friendRequestAlerts]
  );

  // dismiss an alert:
  const dismissFriendRequestAlert = useCallback(
    (requester) => {
      const newAlert = {
        ...friendRequestAlerts[requester],
        dismissed: true,
      };

      const newAlerts = {
        ...friendRequestAlerts,
        [requester]: newAlert,
      };
      setFriendRequestAlerts(newAlerts);
    },
    [friendRequestAlerts]
  );

  // For friends modal:

  const dismissCurrentFriendRequestAlerts = () => {
    const newAlerts = {};
    Object.values(friendRequestAlerts).forEach((alert) => {
      newAlerts[alert.requester] = { ...alert, dismissed: true };
    });
    setFriendRequestAlerts(newAlerts);
  };

  const openFriendsModal = () => {
    dismissCurrentFriendRequestAlerts();
    renderFriendsModal(FRIENDS_MODAL_TABS.requests);
  };

  return (
    <>
      {alertsToShow.length > 0 && (
        <FadeIn>
          <AlertsContainer>
            <AlertHeader>
              <AlertTitle>
                <IconContainer>
                  <AddFriendIcon />
                </IconContainer>
                New Friend Request!
              </AlertTitle>
              <AlertLink onClick={openFriendsModal}>See All</AlertLink>
            </AlertHeader>
            {alertsToShow.map((alert) => (
              <FriendRequestAlert
                key={alert.requester}
                alert={alert}
                dismissAlert={() => dismissFriendRequestAlert(alert.requester)}
              />
            ))}
          </AlertsContainer>
        </FadeIn>
      )}
    </>
  );
};

export default AppAlerts;

const ALERTS_WIDTH = 460;

const AlertsContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 15px;
  z-index: 10000;

  background: #ffffff;
  width: ${ALERTS_WIDTH}px;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  @media (max-width: 615px) {
    width: 93vw;
    left: 50%;
    transform: translate(-50%);
  }
`;

const AlertHeader = styled.header`
  display: flex;
  justify-content: space-between;

  margin: 0 4px 7px 4px;
`;

const AlertTitle = styled.h4`
  color: #6b1be3;
  font-size: 12px;
  font-weight: 600;

  display: flex;
  align-items: center;
  margin: 0px;
`;

const AlertLink = styled.button`
  font-family: 'Inter';
  font-size: 12px;

  cursor: pointer;

  background: none;
  border: none;
  padding: 0px;

  color: #12002d;
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }
`;

const IconContainer = styled.div`
  margin-right: 5px;
  margin-top: -3px;

  display: flex;
  align-items: center;

  ${svgColorMixin('#6b1be3')}

  svg {
    width: 15px;
    g {
      stroke: #6b1be3;
    }
  }
`;
