import { createAction, createReducer } from '@reduxjs/toolkit';

import { isMemberRole } from '../../../util/user-util';

const initialState = {
  hasMoreOfflineMembers: true,
  current: [],
  online: {},
  profiles: {},
};

export const setHasMoreOfflineUsers = createAction('room/setHasMoreOfflineUsers');
export const membersListUpdated = createAction('room/membersListUpdated');
export const offlineMembersPageRequested = createAction('room/offlineMembersPageRequested');
export const bannedMembersRequested = createAction('room/bannedMembersRequested');
export const setBannedMembers = createAction('room/setBannedMembers');
export const setCurrentMembers = createAction('room/setCurrentMembers');
export const currentMemberUpdated = createAction('room/currentMemberUpdated');
export const onlineMembersListUpdated = createAction('room/onlineMembersListUpdated');
export const memberProfileUpdated = createAction('room/memberProfileUpdated');
export const memberInfoUpdateRequested = createAction('room/memberInfoUpdateRequested');
export const memberKickRequested = createAction('room/memberKickRequested');
export const memberBanRequested = createAction('room/memberBanRequested');
export const memberUnbanRequested = createAction('room/memberUnbanRequested');
export const promotionToMemberRequested = createAction('room/promotionToMemberRequested');
export const removeMember = createAction('room/removeMember');

export const membersReducer = createReducer(initialState, {
  [setHasMoreOfflineUsers]: (state, { payload: { hasMoreOfflineMembers } }) => {
    state.hasMoreOfflineMembers = hasMoreOfflineMembers;
  },
  [membersListUpdated]: (state, { payload: { members } }) => {
    state.current = [
      ...members.filter((m) => m.role === 'owner'),
      ...members.filter((m) => m.role === 'host'),
      ...members.filter((m) => !m.role),
      ...members.filter((m) => m.role === 'viewer'),
    ];
  },
  [setCurrentMembers]: (state, { payload: { members } }) => {
    state.current = members.filter(
      (m) => m.role === 'owner' || m.role === 'host' || m.role === 'viewer' || isMemberRole(m.role)
    );
  },
  [onlineMembersListUpdated]: (state, { payload: { members } }) => {
    state.online = members;
  },
  [memberProfileUpdated]: (state, { payload: { id, data } }) => {
    const existingProfile = state.profiles[id];
    if (existingProfile) {
      Object.assign(existingProfile, data);
    } else {
      state.profiles[id] = data;
    }
  },
  [currentMemberUpdated]: (state, { payload: { id, data } }) => {
    state.current = state.current.map((member) => (member.id === id ? { ...member, ...data } : member));
  },
  [setBannedMembers]: (state, { payload: { bannedMembers } }) => {
    const newBannedMembers = bannedMembers.filter(
      (bannedMember) => !state.current.find((m) => m.id === bannedMember.id)
    );

    state.current = [...state.current, ...newBannedMembers];
  },
  [removeMember]: (state, { payload: { memberId } }) => {
    // remove from members.current
    state.current = [...state.current.filter((m) => m.id !== memberId)];

    // remove from members.profiles
    const currentProfiles = { ...state.profiles };
    delete currentProfiles[memberId];
    state.profiles = currentProfiles;
  },
});
