import { monitorAudio, stopMonitoringAudio } from './audio';

let micMonitorNode = null;

export async function startMonitorCurrentMic() {
  micMonitorNode = await monitorAudio(window.rtc.localStream, 'mic-levels');
}

export function stopMonitorCurrentMic() {
  if (micMonitorNode) {
    stopMonitoringAudio(micMonitorNode);
    micMonitorNode = null;
  }
}

export function startMonitoringOwnAudioDevicesChange() {
  window.onAudioDeviceChanged = async () => {
    if (micMonitorNode) {
      stopMonitoringAudio(micMonitorNode);
    }

    micMonitorNode = await monitorAudio(window.rtc.localStream, 'mic-levels');
  };
}
