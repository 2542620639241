import React, { useState } from 'react';
import styled from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { moreMenuMixin } from '../mixins';
import { HorizontalSeparator } from '../chats/messages/context-menu/shared-styles';

const chatDotsButton = '/images/icons/chat-dots-button.svg';

type ContextMenuProps = {
  children?: React.ReactNode;
};

const ContextMenu = ({ children }: ContextMenuProps) => {
  const childrenArray = React.Children.toArray(children);

  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  function onContextMenuButtonClick(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
    e.stopPropagation();
    setTimeout(() => {
      setContextMenuOpen(true);
    }, 10);
  }

  if (!childrenArray.length) return null;

  return (
    <Popover open={contextMenuOpen}>
      <div className="chat-item-context-buttons" style={{ display: contextMenuOpen ? 'flex' : '' }}>
        <PopoverAnchor asChild>
          <img
            style={{ display: contextMenuOpen ? 'flex' : '' }}
            className="chat-item-context-button"
            src={chatDotsButton}
            alt="reactions"
            onClick={onContextMenuButtonClick}
          />
        </PopoverAnchor>
        <PopoverContent side="right" align="start" onPointerDownOutside={() => setContextMenuOpen(false)}>
          {childrenArray.map((child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {child}
              {index < childrenArray.length - 1 ? <HorizontalSeparator /> : null}
            </React.Fragment>
          ))}
        </PopoverContent>
      </div>
    </Popover>
  );
};

export default ContextMenu;

const Popover = PopoverPrimitive.Root;
const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = styled(PopoverPrimitive.Content)`
  ${moreMenuMixin()}

  margin-left: 10px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  outline: none;
`;
