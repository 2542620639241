import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

function Tooltip({ children, content, asChild }) {
  return (
    <TooltipRoot delayDuration={100}>
      <StyledTooltipTrigger asChild={asChild}>{children}</StyledTooltipTrigger>
      {content && content}
    </TooltipRoot>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node,
  asChild: PropTypes.bool,
};

Tooltip.defaultProps = {
  asChild: true,
  content: null,
};

const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;

const StyledTooltipTrigger = styled(TooltipTrigger)`
  outline: none;
  border: none;
`;

export default Tooltip;
