import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';

import SuggestedFriendsList from '../../friends/suggested-friends/SuggestedFriendsList';
import { submitFriendSuggestions } from '../../store/signing-in/actions.ts';
import { selectFriendsSuggestions, selectNextPage } from '../../store/signing-in/selectors.ts';
import {
  ContainerDefault,
  NavButtonsContainer,
  NextNavButton,
  ScreenSubTitle,
  ScreenTitle,
  TitleContainer,
} from '../shared-styled.ts';
import { EventSourceContext } from '../../common/contexts.ts';
import { FRIEND_REQUEST_SOURCES } from '../../../constants/analytics-events/friend-events';
import { track } from '../../../util/analytics-util';
import { ONBOARDING_STEP_VIEW } from '../../../constants/analytics-events/onboarding-events.ts';

const FriendsSuggestionsScreen = () => {
  const dispatch = useDispatch();

  const suggestions = useSelector(selectFriendsSuggestions);
  const nextPage = useSelector(selectNextPage);
  const onNextClick = useCallback(() => dispatch(submitFriendSuggestions()), [dispatch]);

  useEffect(() => {
    track(ONBOARDING_STEP_VIEW, { step: 'Friends Suggestions' });
  }, []);

  return (
    <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.ONBOARDING_FRIEND_SUGGESTION}>
      <ThemeProvider theme={{ userItemHeight: 86 }}>
        <ContainerDefault>
          <TitleContainer>
            <ScreenTitle>People you may know!</ScreenTitle>
            <ScreenSubTitle textColor="#101010b2">
              You might already have friends on Here. Add them so you can chat and invite them to your room.
            </ScreenSubTitle>
          </TitleContainer>

          <SuggestedFriendsListContainer isValid onSubmit={onNextClick}>
            <SuggestedFriendsList initialSuggestedFriends={suggestions} initialNextPage={nextPage} />
          </SuggestedFriendsListContainer>

          <NavButtonsContainer>
            <NextNavButton onClick={onNextClick} data-testid="next-button">
              Next
            </NextNavButton>
          </NavButtonsContainer>
        </ContainerDefault>
      </ThemeProvider>
    </EventSourceContext.Provider>
  );
};

const SuggestedFriendsListContainer = styled.div`
  margin-top: 20px;
  max-height: 345px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
`;

export default FriendsSuggestionsScreen;
