/* eslint-disable no-underscore-dangle */
import $ from 'jquery';
import UIkit from 'uikit';

import { debounce, delay } from 'lodash';
import firebase, { db } from './firebase';
import { updateWayfinders } from './wayfinders';
import { boardUsers } from './presence';
import SpawnElement from './spawn';
import { isObjectInBorders, moveCameraToScreen, scrollCanvasToRect } from './viewport';
import { canvasToScreenCoords } from './util/canvas-util';
import minimap from './minimap';
import { checkBarriers } from './util/barrier-util';
import { htmlToElement, isArrowKey, isOnScreen, getDocIdFromElementId, objectFromURL } from './util';

import { stopCameraInitTimer, startCameraInitTimer } from './util/device-error-util';
import { checkIsMobile } from './util/platform-util';
import { isCurrentUser, isHereEmployee, isNewUser } from './util/user-util';
import { applyElementTransformFromPartialData, getElementPosition } from './element-transform';
import { startMonitorCurrentMic, stopMonitorCurrentMic } from './mic-monitor';
import log from './log';
import { setVideoVolume } from './spatial-audio';
import sessionLog from './session-log.ts';
import wrapElement from './element-wrapper';
import { elementMoved } from './drag';
import { isHostGranted } from './roles-management';
import BoardElement from './board-element';
import eventBus, { boardChanged, userUpdated } from './event-bus';
import {
  stopKeyboardMoveLoop,
  isKeyboardMoveLoopActive,
  startKeyboardMoveLoop,
  CameraState,
  findCameraHandler,
} from './util/camera-util';
import { browserName, isSafari } from './util/browser-util';

import '../styles/camera.less';
import { showReportModal } from './util/report-util';
import { monitorUserAudio, stopMonitoringUserAudio } from './audio';
import { openUserProfile } from './react/user-profile/utils';
import { track } from './util/analytics-util';
import { createFriendRequest } from './react/store/friends/api';
import reduxAdapter from './react/store/redux-adapter';
import {
  selectCanAddToFriend,
  selectPendingOrIgnoredSentRequest,
  selectFriendIds,
} from './react/store/friends/selectors';
import { CHANGE_CAMERA_SHAPE, CANNOT_FIND_OWN_CAMERA } from './constants/analytics-events/camera-events';
import { rendererForCamera } from './camera/video-renderer-util.ts';
import { setActiveReceiverId, setMessagesSidebarOpen } from './react/store/messaging/reducer';
import { FRIEND_REQUEST_SOURCES, REQUEST_FRIEND } from './constants/analytics-events/friend-events';
import { selectCurrentUserIsAdmin } from './react/store/users/selectors';
import { shouldUseCustomAudioMixer } from './electron-support/electron-support';
import { renderChatHeadMood } from './react/user-profile/mood/mood-chat-head/render.tsx';
import { calculateAbsoluteTopValue } from './util/chat-head-label-util';
import { isSnapchatLensEnabled } from './camera/track-provider-util';

const ARROW_KEY_MOVE_SPEED = 24;
const OPEN_PROFILE_ON_CLICK_DELAY = 300;

const BACKGROUND_REMOVAL = 'background-removal';

const updateLinkQualityTimers = {};

const SHOW_HAVING_ISSUES = false;

const signalQualityImages = {
  1: null,
  2: null,
  3: null,
  4: null,
};

Object.keys(signalQualityImages).forEach(async (index) => {
  signalQualityImages[index] = await objectFromURL(`/images/icons/signal-${index}.svg`);
});

const roomImageElements = {
  owner: null,
  host: null,
};

Object.keys(roomImageElements).forEach(async (index) => {
  const img = await objectFromURL(`/images/icons/room-${index}.svg`);
  roomImageElements[index] = htmlToElement(`<img class="camera-name-icon" src="${img}" />`);
});

export default class CameraElement extends BoardElement {
  isCurrentUserAdmin = false;

  isHereEmployee = false;

  curentRenderer = null;

  updateCameraNameTimer = null;

  _userRole = null;

  constructor(elementId) {
    super(elementId);

    this.getAdditionalOptions = this.getAdditionalOptions.bind(this);
    this.onReduxStateChange = this.onReduxStateChange.bind(this);
    this.openProfile = this.openProfile.bind(this);

    this.state = CameraState.normal;
    this.visible = true;
    reduxAdapter.subscribe(this.onReduxStateChange);

    if (updateLinkQualityTimers[elementId]) {
      clearInterval(updateLinkQualityTimers[elementId]);
    }
    updateLinkQualityTimers[elementId] = window.setInterval(this.updateCameraName.bind(this), 3000);
  }

  set userRole(role) {
    this._userRole = role;
    this.updateCameraName();
  }

  get userRole() {
    return this._userRole;
  }

  get userCamera() {
    return document.querySelector(`.camera-${this.userId}`);
  }

  get userCameraName() {
    return this.userCamera?.querySelector('.camera-name-container')?.querySelector('.camera-name');
  }

  get linkQualityElement() {
    const linkQuality = window.rtc.getUserIdLinkQuality(this.userId);
    const qualityImage = signalQualityImages[linkQuality];
    if (linkQuality && qualityImage) {
      return htmlToElement(`<img width="15px;" class="link-quality-icon" src="${qualityImage}">`);
    }
    return undefined;
  }

  get noSignalElement() {
    return this.userCamera?.querySelector('.no-signal');
  }

  get cameraNameTemplate() {
    return `<span class="camera-name">${this.cameraNameTemplateInner}</span>`;
  }

  get cameraNameTemplateInner() {
    const havingIssues = SHOW_HAVING_ISSUES ? window.rtc.getUserIdLinkQuality(this.userId) === 1 : false;

    return `
      <div flex-direction='row'>
        ${havingIssues ? `<div class='place-avatar-here'></div>` : ''}
        <div flex-direction='colum' text-align='left'>
          <div>
            ${roomImageElements[this.userRole]?.outerHTML || ''}
            ${this.linkQualityElement?.outerHTML || ''}
            <here-user-name userId=${this.userId} />
          </div>
          ${havingIssues ? `<div><span>Having issues...</span></div>` : ''}
        </div>
      </div>
    `;
  }

  updateVideoVisibility() {
    const container = document.getElementById(`video-container-${this.userId}`);
    if (!container) {
      return false; // Our camera object doesn't have a stream yet
    }

    const video = container.querySelector('video');
    const avatar = container.querySelector('here-avatar');

    if (this.isVideoOn === false || !this.visible) {
      this.currentRenderer.hideVideo(this);

      avatar.style.display = 'block';
    } else if (video) {
      this.currentRenderer.showVideo(this);

      avatar.style.display = 'none';
    }
    return true;
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  async handleUpdate(element, elementDoc) {
    this.elementData = elementDoc.data();
    this.isVideoOn = this.elementData.isVideoOn;
    this.isAudioOn = this.elementData.isAudioOn;
    const formerRenderer = this.currentRenderer;
    if (this.isVideoOn) {
      this.currentRenderer = rendererForCamera(this);
    }

    this.setCaptionSize(element);

    let myColor = null;
    if (
      boardUsers[window.currentBoardId] &&
      boardUsers[window.currentBoardId][this.userId] &&
      boardUsers[window.currentBoardId][this.userId].color
    ) {
      myColor = boardUsers[window.currentBoardId][this.userId].color;
    }
    this.color = myColor;

    if (this.elementData.creator === firebase.auth().currentUser.uid && this.elementData.activityId) {
      sessionLog.updateActivityId(this.elementData.activityId);
    }

    if (this.isVideoOn && formerRenderer !== this.currentRenderer) {
      if (formerRenderer) {
        formerRenderer.teardownForCamera(this);
        if (CameraElement.ownCamera === this) {
          await window.rtc.room.cameraPublisher.restartVideoDevice();
        }
      }
    }

    if (!this.updateVideoVisibility()) {
      return false;
    }

    const container = document.getElementById(`video-container-${this.userId}`);
    const avatarBg = container.querySelector('.avatar');
    avatarBg.className = `avatar ${this.color}`;
    // Cancel any pending updates, this takes priority
    if (window.userLocationUpdateTimer && element.id === window.userCameraId) {
      clearTimeout(window.userLocationUpdateTimer);
      window.userLocationUpdateTimer = null;
    }

    const updateCameraClip = (selector) => {
      const cameraElement = element.querySelector(selector);
      if (cameraElement) {
        cameraElement.classList.remove(...[...cameraElement.classList].filter((c) => /(^|\s)camera-\S+/.test(c)));
        let { clip } = this.elementData;
        if (clip === 'heart' && isSafari()) {
          clip = 'circle'; // SVGs unsupported in Safari
        }
        cameraElement.classList.add(`camera-${clip}`);
      }
    };

    updateCameraClip(`#video-${this.elementData.creator}`, this.elementData.clip);
    updateCameraClip(`#camera-avatar-${this.elementData.creator}`, this.elementData.clip);
    updateCameraClip(`#video-container-${this.elementData.creator} .video-snapshot`, this.elementData.clip);
    updateCameraClip(`#video-container-${this.elementData.creator} .no-signal`, this.elementData.clip);
    this.setupAudioLevels(
      this.elementData.isVideoOn && this.visible,
      this.elementData.isAudioOn,
      container.closest('.camera-container')
    ).finally(() => {
      if (this.isAudioOn) {
        setVideoVolume(element);
      }

      updateWayfinders();
    });

    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    this.elementId = elementDoc.id; // just in case
    this.elementData = elementDoc.data();

    this.unsubscribeFromArrowKeysHandling = this.unsubscribeFromArrowKeysHandling.bind(this);

    eventBus.on(boardChanged, this.unsubscribeFromArrowKeysHandling);

    log.debug('Camera setup', elementId);
    const data = elementDoc.data();
    const el = document.getElementById(`element-${this.elementId}`);
    const container = el.querySelector('.video-container');

    this.userId = data.user;
    this.isVideoOn = data.isVideoOn;
    this.isAudioOn = data.isAudioOn;

    isHereEmployee(firebase.auth().currentUser).then((value) => {
      this.isHereEmployee = value;
    });

    this.setCaptionSize(el);

    this.currentRenderer = rendererForCamera(this);

    let myColor = null;
    if (
      boardUsers[window.currentBoardId] &&
      boardUsers[window.currentBoardId][this.userId] &&
      boardUsers[window.currentBoardId][this.userId].color
    ) {
      myColor = boardUsers[window.currentBoardId][this.userId].color;
    }
    this.color = myColor;

    const avatarBg = container.querySelector('.avatar');
    avatarBg.classList = `avatar ${this.color}`;
    const avatar = container.querySelector('here-avatar');
    avatar.style.display = 'block'; // this.isVideoOn ? 'none' : 'block';

    if (this === CameraElement.ownCamera) {
      log.debug('Found my own camera, adding...');
      window.userCameraId = elementId;
    }

    if (data.activityId) {
      sessionLog.updateActivityId(data.activityId);
    }
    window.rtc.onCameraElementReady(data.creator);

    this.setupAudioLevels(data.isVideoOn && this.visible, data.isAudioOn, container.closest('.camera-container')).then(
      () => {
        updateWayfinders();

        // arrow keys should move only own camera
        if (data.user === firebase.auth().currentUser.uid) {
          this.setUpArrowKeysMoving(data);
        }

        if (this !== CameraElement.ownCamera) {
          el.querySelector('.add-friend-button').addEventListener('click', async () => {
            const response = await createFriendRequest(this.userId);
            if (response?.success) {
              track(REQUEST_FRIEND, {
                source: FRIEND_REQUEST_SOURCES.CHAT_HEAD_MENU,
                receiverId: this.userId,
              });
            }
          });

          el.querySelector('.camera-send-dm').addEventListener('click', () => {
            // dispatch active receiver id
            reduxAdapter.dispatchAction(setMessagesSidebarOpen({ messagesSidebarOpen: true }));
            reduxAdapter.dispatchAction(setActiveReceiverId({ activeReceiverId: this.userId }));
          });
        }
      }
    );
  }

  onReduxStateChange(state) {
    this.updateAddFriendButtonOnReduxStateChange(state);
    this.updateStartDMsButtonOnReduxStateChange(state);
    this.isCurrentUserAdmin = selectCurrentUserIsAdmin(state);
  }

  updateAddFriendButtonOnReduxStateChange(state) {
    const menuButton = document.querySelector(`#element-${this.elementId} .camera-add-friend-menu-button`);
    if (!menuButton) return;

    const alreadySentFriendRequest = selectPendingOrIgnoredSentRequest(state, this.userId);
    const canAddToFriend = selectCanAddToFriend(state, this.userId);

    if (alreadySentFriendRequest) {
      menuButton.style.display = null;
      menuButton.classList.add('request-sent');
    } else if (canAddToFriend) {
      menuButton.style.display = null;
      menuButton.classList.remove('request-sent');
    } else {
      menuButton.style.display = 'none';
      UIkit.dropdown(`#add-friend-${this.elementId}`).hide();
    }
  }

  updateStartDMsButtonOnReduxStateChange(state) {
    const friendsWithCurrentUser = selectFriendIds(state)[this.userId];
    const openDMsButton = document.querySelector(`#element-${this.elementId} .camera-send-dm`);
    if (openDMsButton) {
      if (friendsWithCurrentUser && this !== CameraElement.ownCamera) {
        openDMsButton.style.display = null;
      } else {
        openDMsButton.style.display = 'none';
      }
    }
  }

  unsubscribeFromArrowKeysHandling() {
    document.removeEventListener('keydown', this.onArrowKeyDown);
    document.removeEventListener('keyup', this.onArrowKeyUp);
    eventBus.off(boardChanged, this.unsubscribeFromArrowKeysHandling);
  }

  setUpArrowKeysMoving(data) {
    const cameraElement = document.getElementById(`element-${this.elementId}`);

    let startX;
    let startY;

    const pressedArrowKeysMap = {};

    const movingLoop = () => {
      const focused = document.activeElement;
      if (focused.nodeName === 'INPUT' || focused.nodeName === 'TEXTAREA') {
        return;
      }

      let newX = startX;
      let newY = startY;

      if (pressedArrowKeysMap.ArrowUp) {
        newY -= ARROW_KEY_MOVE_SPEED;
      }

      if (pressedArrowKeysMap.ArrowDown) {
        newY += ARROW_KEY_MOVE_SPEED;
      }

      if (pressedArrowKeysMap.ArrowLeft) {
        newX -= ARROW_KEY_MOVE_SPEED;
      }

      if (pressedArrowKeysMap.ArrowRight) {
        newX += ARROW_KEY_MOVE_SPEED;
      }

      const w = parseFloat(cameraElement.style.width.replace('px', ''));
      const h = parseFloat(cameraElement.style.height.replace('px', ''));
      const newPos = checkBarriers({ x: startX, y: startY, w, h }, { x: newX, y: newY, w, h });
      newX = newPos.x;
      newY = newPos.y;

      const bordersCheckResult = isObjectInBorders({ x: newX, y: newY, w, h });
      newX = bordersCheckResult.xAllowedValue;
      newY = bordersCheckResult.yAllowedValue;

      window.rtc.sendElementResized(`element-${this.elementId}`, newX, newY, w, h, null);

      applyElementTransformFromPartialData(cameraElement, { center: [newX, newY] });
      minimap.setNeedsUpdate();

      startX = newX;
      startY = newY;

      if (!isOnScreen(cameraElement)) {
        scrollCanvasToRect(newX, newY, cameraElement.offsetWidth, cameraElement.offsetHeight);
      }
    };

    if (data.user === firebase.auth().currentUser.uid) {
      this.onArrowKeyDown = (event) => {
        if (isArrowKey(event.key)) {
          pressedArrowKeysMap[event.key] = true;

          if (!isKeyboardMoveLoopActive()) {
            [startX, startY] = getElementPosition(cameraElement).map((item) => item || 0);
            startKeyboardMoveLoop(movingLoop);
          }
        }
      };

      this.onArrowKeyUp = (event) => {
        function saveMovedElement(element, initiatorElementIds = []) {
          const newWidth = Number(element.style.width.replace('px', ''));
          const newHeight = Number(element.style.height.replace('px', ''));
          const center = getElementPosition(element);

          db.doc(element.getAttribute('docPath'))
            .update({
              size: [newWidth, newHeight],
              center,
            })
            .catch((error) => {
              // The document probably doesn't exist.
              log.error('Error updating document with new position: ', error);
            });

          const elementHandler = window.elementHandlers[getDocIdFromElementId(element.id)];
          if (elementHandler) {
            elementHandler.pinnedElements
              .map((doc) => ({ pinnedElement: document.getElementById(`element-${doc.docId}`) }))
              .forEach(({ pinnedElement }) => {
                if (pinnedElement && !initiatorElementIds.includes(pinnedElement.id)) {
                  saveMovedElement(pinnedElement, [...initiatorElementIds, element.id]);
                }
              });
          }
        }

        if (isArrowKey(event.key)) {
          delete pressedArrowKeysMap[event.key];

          if (!Object.keys(pressedArrowKeysMap).length) {
            stopKeyboardMoveLoop();

            // save to the db
            saveMovedElement(cameraElement);
          }
        }
      };

      document.addEventListener('keydown', this.onArrowKeyDown);
      document.addEventListener('keyup', this.onArrowKeyUp);
    }
  }

  isBackgroundRemovalEnabled() {
    return this.elementData?.clip === BACKGROUND_REMOVAL;
  }

  disableBackgroundRemoval() {
    const cameraDoc = db.doc(`boards/${window.currentBoardId}/elements/${this.elementId}`);
    cameraDoc.update({ clip: 'circle' });
  }

  isOwnCamera() {
    return this === CameraElement.ownCamera;
  }

  async changeVideoShape() {
    const docRef = db.doc(`boards/${window.currentBoardId}/elements/${this.elementId}`);
    const doc = await docRef.get();

    const currentShape = doc.data().clip || 'hexagon';
    const shapes = ['star', 'hexagon', 'circle', 'rect', 'triangle'];
    if (!isSafari()) {
      shapes.push('heart');
      if (!isSnapchatLensEnabled()) {
        shapes.push(BACKGROUND_REMOVAL);
      }
    }

    const index = shapes.indexOf(currentShape);
    const newShape = shapes[(index + 1) % shapes.length];

    await doc.ref.update({ clip: newShape });

    track(CHANGE_CAMERA_SHAPE, { oldShape: currentShape, newShape });
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();
    this.clipShape = data.clip != null ? data.clip : 'circle';

    if (this.clipShape === 'heart' && isSafari()) {
      this.clipShape = 'circle'; // SVGs unsupported in Safari
    }

    const streamPlaceholder = data.creator
      ? `
      <div class="camera-container rotatable-container">
        <div class="video-container" id="video-container-${data.creator}">
          ${
            SHOW_HAVING_ISSUES
              ? `<img src="images/misc/no-signal.gif" class="no-signal camera-${this.clipShape}" />`
              : ''
          }
          <here-avatar id="camera-avatar-${data.user}" class="camera-${this.clipShape}" userId="${
          data.user
        }"></here-avatar>
          <canvas class="video-snapshot mirror camera-${this.clipShape}" style="display: none"></canvas>
          ${
            isCurrentUser(data.user)
              ? `<button class="camera-muted-icon camera-muted-button ${data.isAudioOn ? 'hidden' : ''}"></button>`
              : `<div class="camera-muted-icon ${data.isAudioOn ? 'hidden' : ''}"></div>`
          }
        </div>
        <canvas class="camera-audio-level" width="300" height="150" id="audio-level-${data.user}"></canvas>
        <div class="caption-container" id="caption-container-${data.creator}"><div></div></div>
      </div>
    `
      : '';

    const videoDiv = htmlToElement(`
      <div class="animation">
        <div class="camera-name-container" 
          style="transform: scale(${1 / window.canvasScale}); 
          top: ${calculateAbsoluteTopValue(1 / window.canvasScale)}"
        >
          ${this.cameraNameTemplate}
          <div class="user-mood-container"></div>
        </div>
        ${streamPlaceholder}
      </div>
    `);
    const moodContainer = videoDiv.querySelector('.user-mood-container');
    renderChatHeadMood(moodContainer, data.user);

    elementDoc.ref.parent.parent
      .collection('members')
      .doc(data.user)
      .get()
      .then((ref) => ref.data() || {})
      .then(({ role }) => {
        this.userRole = role;
      })
      .catch((err) => {
        log.error('Failed to update camera icon to fetch', err);
      });

    const cameraNameContainer = videoDiv.querySelector('.camera-name-container');

    videoDiv.addEventListener('mouseenter', () => {
      // lazy size recalculation to improve perfomance
      cameraNameContainer.style.transform = `scale(${1 / window.canvasScale})`;
      cameraNameContainer.style.top = calculateAbsoluteTopValue(1 / window.canvasScale);
      cameraNameContainer.classList.add('maintain-size-in-viewport');
    });

    videoDiv.addEventListener('mouseleave', () => {
      cameraNameContainer.classList.remove('maintain-size-in-viewport');
    });

    const element = wrapElement(videoDiv, elementDoc, {
      classes: ['videoElement', 'inactive-camera-element', `camera-${data.creator}`],
      preserveAspectRatio: true,
      additionalOptions: () => this.getAdditionalOptions(data),
    });

    element.querySelector('.element-container').addEventListener('dblclick', (e) => {
      this.changeVideoShape(e);
      clearTimeout(this.doubleClickTimeout);
    });
    element.querySelector('.element-container').addEventListener('click', (e) => {
      const isFirstClick = e.detail === 1;
      if (
        isFirstClick &&
        !elementMoved() &&
        !element.classList.contains('pinnable') &&
        !e.target.classList.contains('camera-muted-button')
      ) {
        this.doubleClickTimeout = setTimeout(this.openProfile, OPEN_PROFILE_ON_CLICK_DELAY);
      }
    });

    return element;
  }

  openProfile() {
    const chatHead = document.getElementById(`element-${this.elementId}`);
    const chatHeadCoordinates = chatHead.getBoundingClientRect();

    const topPosition = chatHeadCoordinates.y;
    const leftPosition = chatHeadCoordinates.x + chatHead.offsetWidth + 20;

    openUserProfile({ userId: this.userId, topPosition, leftPosition });
  }

  getAdditionalOptions(data) {
    const additionalOptions = [];

    const profileOption = htmlToElement(`<button class="options-menu-option">View Profile</button>`);
    profileOption.addEventListener('click', this.openProfile);
    additionalOptions.push(profileOption);

    const userData = boardUsers[window.currentBoardId] ? boardUsers[window.currentBoardId][data.user] : null;
    if (isHostGranted() && !isCurrentUser(data.user)) {
      if (userData) {
        const makeHostOption = htmlToElement(`<button class="options-menu-option">Make Host</button>`);
        makeHostOption.addEventListener('click', () => {
          eventBus.dispatch(userUpdated, { option: 'make-host', userId: data.user });
        });

        const makeMemberOption = htmlToElement(`<button class="options-menu-option">Make Member</button>`);
        makeMemberOption.addEventListener('click', () => {
          eventBus.dispatch(userUpdated, { option: 'make-member', userId: data.user });
        });

        const kickOption = htmlToElement(`<button class="options-menu-option">Kick</button>`);
        kickOption.addEventListener('click', () => {
          UIkit.modal
            .confirm(`Are you sure you want to kick ${userData.name}?`)
            .then(() => {
              eventBus.dispatch(userUpdated, { option: 'kick', userId: data.user });
            })
            .catch(() => {});
        });

        const banOption = htmlToElement(`<button class="options-menu-option">Ban</button>`);
        banOption.addEventListener('click', () => {
          UIkit.modal
            .confirm(`Are you sure you want to ban ${userData.name}?`)
            .then(() => {
              eventBus.dispatch(userUpdated, { option: 'ban', userId: data.user });
            })
            .catch(() => {});
        });

        if (userData.role === 'member' || userData.role === undefined) {
          additionalOptions.push(makeHostOption);
          additionalOptions.push(kickOption);
          additionalOptions.push(banOption);
        } else if (userData.role === 'host') {
          additionalOptions.push(makeMemberOption);
          additionalOptions.push(kickOption);
          additionalOptions.push(banOption);
        }
      }
    }

    if (data.user !== firebase.auth().currentUser.uid) {
      const reportOption = htmlToElement(`<button class="options-menu-option options-report-option">Report</button>`);
      reportOption.addEventListener('click', () => {
        showReportModal({ name: userData.name, id: data.user });
      });
      additionalOptions.push(reportOption);
    }

    if (this.isHereEmployee) {
      const rtcDebugOption = htmlToElement('<button class="options-menu-option">RTC Debug</button>');
      rtcDebugOption.addEventListener('click', () => window.rtc.toggleUserStats(this.userId, 'CameraElement'));
      additionalOptions.push(rtcDebugOption);
    }

    if (additionalOptions[0]) {
      additionalOptions[0].classList.add('separated-option');
    }

    return additionalOptions;
  }

  async setupAudioLevelsWithDefaults() {
    const container = document.getElementById(`video-container-${this.userId}`);
    await this.setupAudioLevels(this.isVideoOn, this.isAudioOn, container.closest('.camera-container'));
  }

  async setupAudioLevels(isVideoOn, isAudioOn, container) {
    try {
      const audioLevels = container.querySelector('.camera-audio-level');
      if (isAudioOn && !isVideoOn) {
        const video = container.querySelector('video');
        if (video) {
          await monitorUserAudio(this.userId, window.rtc.activeStreams[this.userId]);
          audioLevels.style.display = 'block';
        }
      } else {
        stopMonitoringUserAudio(this.userId);
        audioLevels.style.display = 'none';
      }

      const mutedIcon = container.querySelector('.camera-muted-icon');
      const videoTag = container.querySelector('video');
      const useCustomAudioMixer = shouldUseCustomAudioMixer();
      if (isAudioOn) {
        mutedIcon.classList.add('hidden');
        if (videoTag && this !== CameraElement.ownCamera && !useCustomAudioMixer) {
          videoTag.removeAttribute('muted');
        }
      } else {
        mutedIcon.classList.remove('hidden');
        if (videoTag && this !== CameraElement.ownCamera) {
          videoTag.setAttribute('muted', '');
        }
      }

      // TODO remove this once we have no audio stream at all sent to the video tag
      if (videoTag && useCustomAudioMixer) {
        videoTag.setAttribute('muted', '');
        videoTag.volume = 0;
      }

      const mutedButton = container.querySelector('.camera-muted-button');
      if (mutedButton) {
        mutedIcon.addEventListener('click', () => {
          if (window.currentBoardData.membersCanUseMic === false && !isHostGranted()) {
            return;
          }
          CameraElement.enableOwnAudio();
        });
      }
    } catch (e) {
      track('Camera Error Setting Up Audio Levels', { error: e.name, message: e.message });
      log.error(`Camera: Has been an error in setupAudioLevels: ${e.message}`);
    }
  }

  onStartDeleting() {
    this.state = CameraState.deleting;
    reduxAdapter.unsubscribe(this.onReduxStateChange);
  }

  videoStartedPlaying() {
    const el = document.getElementById(`element-${this.elementId}`);
    if (this.isVideoOn && this.visible) {
      try {
        stopMonitoringUserAudio(this.userId);
      } catch (e) {
        log.error(`Camera: Error stopping user audio monitor ${e}`);
      } finally {
        const avatar = el.querySelector('here-avatar');
        avatar.style.display = 'none';
        if (this.isAudioOn) {
          setVideoVolume(el);
        }
      }
    } else {
      monitorUserAudio(this.userId, window.rtc.activeStreams[this.userId]).finally(() => {
        if (this.isAudioOn) {
          setVideoVolume(el);
        }
      });
    }
    this.updateVideoVisibility();
  }

  onStreamReady(streamId) {
    log.debug(`Camera: onStreamReady ${streamId}`);
    const el = document.getElementById(`element-${this.elementId}`);
    if (!el) {
      log.debug('No Camera element yet, deferred');
      return;
    }
    this.currentRenderer.setupForCamera(this);
  }

  isVisible() {
    return this.visible;
  }

  setVisible(visible) {
    if (this.isVideoOn && this.visible !== visible) {
      const container = document.getElementById(`video-container-${this.userId}`);

      if (visible) {
        // FIXME: Wait for this correctly
        window.rtc.subscribeToVideo(this.userId);
        if (container) {
          delay(
            this.setupAudioLevels.bind(this),
            1000,
            this.isVideoOn,
            this.isAudioOn,
            container.closest('.camera-container')
          );
        }
      } else {
        // FIXME: Wait for this correctly
        window.rtc.unsubscribeFromVideo(this.userId);
        if (container) {
          delay(this.setupAudioLevels.bind(this), 1000, false, this.isAudioOn, container.closest('.camera-container'));
        }
      }
      this.visible = visible;
      this.updateVideoVisibility();
    }
  }

  /**
   * Whether this object is on screen or not
   *
   * @param {int} overflowPx size in pixels of overflow - i.e. still returns true if object is this many pixels outside of the screen bounds
   * @returns true if within screen area plus overflow range
   */
  isOnScreen(overflowPx = 0) {
    const el = document.getElementById(`element-${this.elementId}`);
    if (!el) {
      return false;
    }

    const [x, y] = getElementPosition(el);
    const ow = parseFloat(el.style.width);
    const oh = parseFloat(el.style.height);
    const w = ow * window.canvasScale;
    const h = oh * window.canvasScale;

    const sxy = canvasToScreenCoords(x, y);

    // Look for any out-of-bounds cameras
    return (
      sxy[0] + w >= -overflowPx &&
      sxy[1] + h >= -overflowPx &&
      sxy[0] <= window.innerWidth + overflowPx &&
      sxy[1] <= window.innerHeight + overflowPx
    );
  }

  setCaptionSize(element) {
    const captionContainerEl = document.getElementById(`caption-container-${this.userId}`);
    captionContainerEl.style.fontSize = `${parseFloat(element.style.width) / 10}px`;
  }

  updateCameraName() {
    if (this.userCameraName) {
      this.userCameraName.innerHTML = this.cameraNameTemplateInner;
    }
    if (!this.noSignalElement) return;
    if (window.rtc.getUserIdLinkQuality(this.userId) === 1) {
      this.noSignalElement.style.display = 'block';
    } else {
      this.noSignalElement.style.display = 'none';
    }
  }

  // Statics

  static async remove() {
    return false;
  }

  // Determine the best camera position, either from a spawn point or from
  // a saved location
  static async findCameraPosition(user) {
    const rect = SpawnElement.nextAvailableForType(this.elementType);
    if (rect) {
      return rect;
    }

    // Fall back on saved position
    const savedPosition = await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('cameraPositions')
      .doc(user.uid)
      .get();
    if (savedPosition.exists) {
      return savedPosition.data();
    }

    return null;
  }

  static updateAudioState = debounce(
    (on) => {
      CameraElement.onCameraUpdate({ isAudioOn: on, isVideoOn: window.rtc.isVideoOn }, false);

      if (on) {
        window.rtc.unmuteLocalAudio.bind(window.rtc)();
        startMonitorCurrentMic();
      } else {
        stopMonitorCurrentMic();
        window.rtc.muteLocalAudio.bind(window.rtc)();
      }
    },
    1300,
    { leading: true }
  );

  static updateVideoState = debounce(
    (on) => {
      CameraElement.onCameraUpdate({ isAudioOn: window.rtc.isAudioOn, isVideoOn: on }, false);

      if (on) {
        const cameraOffButton = document.getElementById('camera-off-button');
        cameraOffButton.style.display = 'block';
        document.getElementById('camera-on-button').style.display = 'none';
        cameraOffButton.disabled = true;
        window.rtc.unmuteLocalVideo();
        cameraOffButton.disabled = false;
      } else {
        window.rtc.muteLocalVideo();
      }
    },
    1200,
    { leading: true }
  );

  static enableOwnAudio = () => {
    this.updateAudioState(true);
  };

  static disableOwnAudio = () => {
    this.updateAudioState(false);
  };

  static enableOwnCamera = () => {
    this.updateVideoState(true);
  };

  static disableOwnCamera = () => {
    this.updateVideoState(false);
  };

  static async updateCameraPosition(camera, cameraId) {
    // Check to see if the camera needs to be moved.
    const newCoords = moveCameraToScreen(camera.center[0], camera.center[1], camera.size[0], camera.size[1]);

    if (newCoords) {
      const cameraDoc = db.doc(`boards/${window.currentBoardId}/elements/${cameraId}`);
      await cameraDoc.update({
        center: [newCoords.x, newCoords.y],
      });
    }
  }

  static async initOwnCamera({ isAudioOn, isVideoOn, position }) {
    log.debug(`Camera: Init Own Camera audio=${isAudioOn} video=${isVideoOn}`);
    track('Init Own Camera', { isAudioOn, isVideoOn });

    window.rtc.isAudioOn = isAudioOn;
    window.rtc.isVideoOn = isVideoOn;

    CameraElement.isOwnCameraInitialized = true;

    // New users don't enable cameras until opening dialog is complete
    const user = firebase.auth().currentUser;
    if (await isNewUser(user)) {
      CameraElement.isOwnCameraInitialized = false;
      return;
    }

    document
      .querySelectorAll('.mic-buttons, .camera-buttons, .camera-settings-divider, .camera-loading')
      .forEach((el) => {
        el.style.display = null;
      });

    if (!checkIsMobile()) {
      Array.from(document.getElementsByClassName('screenshare-buttons')).forEach((item) => {
        item.style.display = null;
      });
    }

    startCameraInitTimer();

    document.querySelector('.camera-loading').style.display = null;
    document
      .querySelectorAll('#camera-on-button, #camera-off-button, #mic-on-button, #mic-off-button, #mic-levels')
      .forEach((el) => {
        el.style.display = 'none';
      });

    const cameraPosition = position || (await this.findCameraPosition(user));
    let cameraLocation = [Math.floor(Math.random() * 200) - 100, Math.floor(Math.random() * 200) - 100];

    // Move the saved camera pos on screen if we're far away
    if (cameraPosition) {
      const newCoords = moveCameraToScreen(
        cameraPosition.center[0],
        cameraPosition.center[1],
        cameraPosition.size[0],
        cameraPosition.size[1]
      );

      cameraLocation = newCoords ? [newCoords.x, newCoords.y] : cameraPosition.center;
    }

    // Fix old camera positions that were extra thicc
    const fixCameraSize = (size) => [Math.min(size[0], size[1]), Math.min(size[0], size[1])];

    if (!CameraElement.ownCamera) {
      CameraElement.ownCamera = findCameraHandler(user.uid);

      if (!CameraElement.ownCamera) {
        log.warn("Can't find my own camera, creating a new one");
        const elementsRef = db.collection('boards').doc(window.currentBoardId).collection('elements');
        await elementsRef.add({
          class: 'CameraElement',
          center: cameraLocation,
          creator: user.uid,
          size: cameraPosition ? fixCameraSize(cameraPosition.size) : [240, 240],
          clip: cameraPosition && cameraPosition.clip ? cameraPosition.clip : 'hexagon',
          isAudioOn,
          isVideoOn,
          zIndex: window.getFrontZIndex(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          user: user.uid, // TODO: remove this field and use creator
          platform: navigator.platform,
          client: browserName(),
        });
      } else {
        log.debug("Found existing camera with user's ID, rettached");
      }
    } else {
      log.debug(`Camera already exists for ${user.uid}`);
    }

    if (isVideoOn) {
      track('Camera On');
    }

    stopCameraInitTimer();
    this.onCameraUpdate({ isAudioOn, isVideoOn }, false);
    document.querySelector('.camera-loading').style.display = 'none';

    log.debug('Camera: local stream is ready');

    window.rtc.cameraIsReadyToPublish = true;
  }

  static onCameraUpdate = debounce(
    ({ isAudioOn, isVideoOn }, updateDbState = true) => {
      if (isAudioOn) {
        $('#mic-off-button').show();
        $('#mic-levels').show();
        $('#mic-on-button').hide();
      } else {
        $('#mic-off-button').hide();
        $('#mic-levels').hide();
        $('#mic-on-button').show();
      }

      if (isVideoOn) {
        $('#camera-off-button').show();
        $('#camera-on-button').hide();
        document
          .querySelectorAll('#lens-menu-button, .lenses-popover-link')
          .forEach((el) => el.classList.add('camera-enabled'));
      } else {
        $('#camera-off-button').hide();
        $('#camera-on-button').show();
        document
          .querySelectorAll('#lens-menu-button, .lenses-popover-link')
          .forEach((el) => el.classList.remove('camera-enabled'));
      }

      if (updateDbState) this.updateCameraDbState({ isAudioOn: !!isAudioOn, isVideoOn: !!isVideoOn });
    },
    300,
    { trailing: true }
  );

  static async shutDownOwnCamera() {
    CameraElement.isOwnCameraInitialized = false;
    document
      .querySelectorAll('.mic-buttons, .camera-buttons, .screenshare-buttons, .camera-settings-divider')
      .forEach((el) => {
        el.style.display = 'none';
      });
    await window.rtc.stopCamera();
    await CameraElement.getCameraDoc().delete();
    CameraElement.ownCamera = null;
  }

  static getCameraDoc() {
    const cameraElement = document.getElementById(`element-${CameraElement?.ownCamera?.elementId}`);
    if (!cameraElement) {
      log.error("Can't find my own camera");
      track(CANNOT_FIND_OWN_CAMERA);
      return null;
    }

    const docPath = cameraElement.getAttribute('docpath');
    return db.doc(docPath);
  }

  static async updateCameraDbState(change) {
    const doc = CameraElement.getCameraDoc();
    if (doc) {
      await doc.update(change);
    } else {
      log.warn("No doc found for user's own camera");
    }
  }
}

CameraElement.elementType = 'CameraElement';
CameraElement.ownCamera = null; // Reference to own camera element for convenience.
