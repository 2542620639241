import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';

import firebase from '../../../firebase';
import {
  BoardControllerContext,
  BoardElementControllerContext,
  ViewportControllerContext,
} from '../../common/contexts.ts';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { convertToHtml, parseHtml, textNotesFormat } from './htmlConverters';
import FontPicker from './FontPicker';
import BackgroundPicker from './BackgroundPicker';
import FontColorPicker from './FontColorPicker';
import FontSizePicker from './FontSizePicker';
import { svgColorMixin } from '../../mixins';

import LockIcon from '../../../../assets/icons/lock.svg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { invertHexColor } from '../../../util/color-util';

const toolbarOptions = {
  options: ['fontSize', 'fontFamily', 'colorPicker'],
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    component: FontSizePicker,
  },
  fontFamily: { component: FontPicker },
  colorPicker: { component: FontColorPicker },
};

const autoSyncIntervalMs = 3000;

const NoteElement = ({ isEditable }) => {
  const { elementData, patchDbData, setIsTemporaryOnTop } = useContext(BoardElementControllerContext);
  const lastAddedElementId = useContext(BoardControllerContext)?.lastAddedElementId;
  const viewportController = useContext(ViewportControllerContext);

  const [editorState, setEditorState] = useState(null);
  const editorStateRef = useRef(editorState);
  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);

  // When editing, locking editing for others and saving current content to realtime db with interval
  const realtimeDbDoc = useMemo(() => {
    const doc = firebase.database().ref(`notesDraftjs/${elementData.id}`);
    doc.onDisconnect().remove();
    return doc;
  }, [elementData.id]);
  const syncIntervalRef = useRef(null);
  const lastSyncedContentRef = useRef(null);
  const [realtimeDbValue, setRealtimeDbValue] = useState(null);
  const isSomebodyElseEditing = realtimeDbValue && realtimeDbValue.editedBy !== firebase.auth().currentUser.uid;

  // Always listen if somebody else starts editing
  useEffect(() => {
    const listener = (doc) => {
      setRealtimeDbValue(doc.val());
    };
    realtimeDbDoc.on('value', listener);
    return () => realtimeDbDoc.off('value', listener);
  }, [realtimeDbDoc]);
  const startSyncingInterval = useCallback(() => {
    syncIntervalRef.current = setInterval(() => {
      const content = convertToHtml(editorStateRef.current.getCurrentContent());
      if (lastSyncedContentRef.current !== content) {
        realtimeDbDoc.update({ content });
      }
      lastSyncedContentRef.current = content;
    }, autoSyncIntervalMs);
  }, [realtimeDbDoc]);

  const startEditing = useCallback(() => {
    const content = elementData.content || '';
    const editorStateWithContent = EditorState.createWithContent(parseHtml(content, elementData.format));
    setEditorState(EditorState.moveSelectionToEnd(editorStateWithContent));
    setIsTemporaryOnTop(true);
    realtimeDbDoc.set({
      editedBy: firebase.auth().currentUser.uid,
      content,
    });
    startSyncingInterval();
  }, [elementData.content, elementData.format, setIsTemporaryOnTop, realtimeDbDoc, startSyncingInterval]);

  const stopEditing = useCallback(async () => {
    if (!editorState) return;
    clearInterval(syncIntervalRef.current);
    await realtimeDbDoc.set(null);

    const contentState = editorState.getCurrentContent();
    patchDbData({
      content: convertToHtml(contentState),
      rawContent: contentState.getPlainText(),
      format: textNotesFormat.DRAFTJS,
      lastEditedBy: firebase.auth().currentUser.uid,
    });
    setEditorState(null);
    setIsTemporaryOnTop(false);
  }, [editorState, patchDbData, setIsTemporaryOnTop, realtimeDbDoc]);

  // Entering edit mode if you were the one who has just added this element
  const isAddingHandledRef = useRef(false);
  useEffect(() => {
    if (
      lastAddedElementId === elementData.id &&
      !isAddingHandledRef.current &&
      firebase.auth().currentUser.uid === elementData.creator
    ) {
      startEditing();
      isAddingHandledRef.current = true;
    }
  }, [lastAddedElementId, elementData.id, elementData.creator, startEditing]);

  const containerRef = useRef(null);
  useOnClickOutside(containerRef, stopEditing);

  // Focus editor each time the element is created (i.e. each time entering edit mode)
  const editorRef = useRef(null);
  const setEditorRef = useCallback((ref) => {
    ref?.focusEditor();
    editorRef.current = ref;
  }, []);

  // Stopping pointer down propagation to prevent dragging handling from starting
  const onContainerPointerDown = (e) => {
    if (!editorState) return;
    e.stopPropagation();
    editorRef.current?.focusEditor();
  };

  // Prevent scrolling event bubbling of the content of the note is scrollable
  const onContainerWheel = (e) => {
    if (containerRef.current.clientHeight !== containerRef.current.scrollHeight) {
      e.stopPropagation();
    }
  };

  const scale = viewportController?.getCoordinates().scale || 1;

  // In draftjs notes default font color is inverted background color
  const defaultFontColor = useMemo(
    () =>
      elementData.format === textNotesFormat.DRAFTJS
        ? invertHexColor(elementData.backgroundColor || '#000000', { preserveOpacity: false })
        : 'white',
    [elementData.backgroundColor, elementData.format]
  );

  return (
    <Container
      backgroundColor={elementData.backgroundColor}
      isEditing={!!editorState}
      ref={containerRef}
      scale={scale}
      defaultFontColor={defaultFontColor}
      onPointerDown={onContainerPointerDown}
      onWheel={onContainerWheel}
      className="unthemed"
    >
      {editorState ? (
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={toolbarOptions}
          toolbarCustomButtons={[<BackgroundPicker />]}
          placeholder="type something"
          ref={setEditorRef}
          stripPastedStyles
        />
      ) : (
        <TextView
          dangerouslySetInnerHTML={{ __html: isSomebodyElseEditing ? realtimeDbValue.content : elementData.content }}
          onClick={startEditing}
          isEditable={isEditable}
        />
      )}

      {isSomebodyElseEditing ? (
        <LockedState>
          <LockedStateText bgColor={elementData.color} textColor={elementData.backgroundColor}>
            <LockIcon />
            Someone else is editing...
          </LockedStateText>
        </LockedState>
      ) : null}
    </Container>
  );
};

export default NoteElement;

NoteElement.propTypes = {
  isEditable: PropTypes.bool.isRequired,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: ${({ backgroundColor }) => backgroundColor || 'rgba(51, 51, 51, 0.733)'};
  color: ${({ defaultFontColor }) => defaultFontColor};
  padding: 15px;
  line-height: 1.1;
  overflow: auto;
  box-sizing: border-box;
  word-break: break-word;
  ${({ isEditing }) => isEditing && `outline: 2px solid white;`}

  * {
    font-family: inherit;
  }

  .public-DraftStyleDefault-block {
    margin: 0;
  }

  .public-DraftEditorPlaceholder-root {
    color: ${({ defaultFontColor }) => defaultFontColor};
    opacity: 0.5;
  }

  .rdw-editor-main {
    cursor: text;
    overflow: visible;
  }

  .rdw-editor-toolbar {
    position: absolute;
    top: -54px;
    left: 50%;
    transform: translateX(-50%) scale(${({ scale }) => 1 / scale});
    transform-origin: bottom;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 12px;
    width: auto;
    z-index: 2;
    padding: 4px;

    &,
    .rdw-dropdown-selectedtext:hover {
      color: black;
    }

    .rdw-option-wrapper {
      box-sizing: content-box;
    }
  }
`;

const LockedState = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
`;

const LockedStateText = styled.p`
  position: absolute;
  right: 10px;
  bottom: 10px;
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
  color: ${({ textColor }) => textColor || 'white'};
  background-color: ${({ bgColor }) => bgColor || 'rgba(51, 51, 51, 0.733)'};
  pointer-events: none;

  svg {
    width: 11px;
    margin-right: 4px;
    position: relative;
    bottom: 2px;

    ${({ textColor }) => svgColorMixin(textColor)}
  }
`;

const TextView = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;

  ${({ isEditable }) => !isEditable && 'pointer-events: none;'}

  p {
    margin: 0;

    &::after {
      // Fixing empty paragraphs are not being shown
      content: '';
      display: inline-block;
    }
  }
`;
