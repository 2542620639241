import styled from 'styled-components';

export const UploadAreaContainer = styled.div`
  padding: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
  border: 1px dashed #12002d33;
  border-radius: 10px;
`;

const iconContainerSizePx = 53;
export const UploadAreaIconContainer = styled.div`
  height: ${iconContainerSizePx}px;
  width: ${iconContainerSizePx}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100%;

  svg {
    width: 32px;
  }
`;
