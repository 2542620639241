import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FadeIn from '../components/animate/FadeIn';

import FriendRequestListItem from './FriendRequestListItem';

const FriendRequestsList = ({ friendRequests }) => {
  const hasFriendRequests = friendRequests.length > 0;
  const [hasFriendRequestsTimedOut, setHasFriendRequestsTimedOut] = useState(hasFriendRequests);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasFriendRequestsTimedOut(hasFriendRequests);
    }, 1000);

    return () => clearTimeout(timer);
  }, [hasFriendRequests]);

  return (
    <>
      {hasFriendRequestsTimedOut ? (
        friendRequests.map((request) => <FriendRequestListItem key={request.requester} friendRequest={request} />)
      ) : (
        <FadeIn>
          <RelativeWrapper>
            <Background url="/images/profile-backgrounds/grey.svg" />
            <NoRequestsContent>
              <SmileyImageContainer>
                <img src="/images/misc/no-friend-requests-tear.svg" alt="smiley" />
              </SmileyImageContainer>
              No Requests
            </NoRequestsContent>
          </RelativeWrapper>
        </FadeIn>
      )}
    </>
  );
};

export default FriendRequestsList;

FriendRequestsList.propTypes = {
  friendRequests: PropTypes.arrayOf(
    PropTypes.shape({
      requester: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const RelativeWrapper = styled.div`
  position: relative;
  height: 248px;
`;

const Background = styled.div`
  background: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const NoRequestsContent = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;

  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  color: #12002d;
`;

const SmileyImageContainer = styled.div`
  margin-bottom: 15px;
`;
