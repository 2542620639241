import styled from 'styled-components';
import { svgColorMixin } from '../../mixins';

export const ToolbarButton = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: column;
  height: 30px;
  padding: 0 5px;

  svg {
    width: 15px;
    margin-bottom: 4px;
    ${svgColorMixin('black')}
  }
`;

export const ColorBlock = styled.div.attrs(({ background }) => ({
  style: { background },
}))`
  height: 4px;
  width: 80%;
  outline: 1px solid black;
`;
