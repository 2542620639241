import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomThemeColorSelect from './CustomThemeColorSelect';

const CustomThemeSettings = ({ colors, onColorUpdate }) => (
  <Container>
    <Title>Custom Theme Colors</Title>
    <ColorButtons>
      <CustomThemeColorSelect
        label="Background"
        value={colors.primaryBackground}
        onChange={useCallback((color) => onColorUpdate('primaryBackground', color), [onColorUpdate])}
      />
      <CustomThemeColorSelect
        label="Text"
        previewText="Tt"
        value={colors.primaryForeground}
        onChange={useCallback((color) => onColorUpdate('primaryForeground', color), [onColorUpdate])}
      />
      <CustomThemeColorSelect
        label="Button"
        isSecondary
        value={colors.secondaryBackground}
        onChange={useCallback((color) => onColorUpdate('secondaryBackground', color), [onColorUpdate])}
      />
      <CustomThemeColorSelect
        label="Button Text"
        isSecondary
        previewText="Tt"
        value={colors.secondaryForeground}
        onChange={useCallback((color) => onColorUpdate('secondaryForeground', color), [onColorUpdate])}
      />
    </ColorButtons>
  </Container>
);

export default CustomThemeSettings;

CustomThemeSettings.propTypes = {
  colors: PropTypes.shape({
    primaryBackground: PropTypes.string,
    primaryForeground: PropTypes.string,
    secondaryBackground: PropTypes.string,
    secondaryForeground: PropTypes.string,
  }).isRequired,
  onColorUpdate: PropTypes.func.isRequired,
};

const ARROW_SIZE = 20;
const Container = styled.div`
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  color: var(--primary-foreground);
  text-align: center;
  margin-top: 14px;
  position: relative;

  // Arrow that points to the Custom theme item.
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: ${ARROW_SIZE}px solid transparent;
    border-right: ${ARROW_SIZE}px solid transparent;
    border-bottom: ${ARROW_SIZE}px solid rgba(255, 255, 255, 0.1);
    position: absolute;
    top: -${ARROW_SIZE}px;
    right: 55px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const ColorButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 23px;
`;
