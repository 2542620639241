import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import { createNewFilename, getFileTypeByExtension, htmlToElement } from './util';
import '../styles/file.less';
import { elementMoved } from './drag';
import log from './log';
import BoardElement from './board-element';
import {
  ADD_ELEMENT,
  ADD_ELEMENT_DESTINATION_TYPES,
  ADD_ELEMENT_SOURCE_TYPES,
  ELEMENT_TYPES,
} from './constants/analytics-events/element-events';
import { track } from './util/analytics-util';
import { USER_CARD_BOARD_TYPE } from './constants/board-constants';

const labels = {
  txt: 'a text',
  pdf: 'a PDF',
  wav: 'a WAV audio',
  mp3: 'a MP3 audio',
  mp4: 'a MP4 video',
  mov: 'a MOV video',
  doc: 'a text',
  m4a: 'a M4A video',
  csv: 'a CSV',
  xls: 'a spreadsheet',
  ppt: 'a presentation',
  key: 'a presentation',
  rtf: 'a text',
  xlsx: 'a spreadsheet',
  pptx: 'a presentation',
  ogg: 'an OGG audio',
};

const starburstOutlinePath = '/images/icons/starburst-outline.svg';
const textIconPath = '/images/icons/document.svg';
const videoIconPath = '/images/icons/video.svg';
const audioIconPath = '/images/icons/audio.svg';
const spreadsheetIconPath = '/images/icons/spreadsheet.svg';
const presentationIconPath = '/images/icons/presentation.svg';
const unknownIconPath = '/images/icons/unknown.svg';

const storage = firebase.storage();

export default class FileElement extends BoardElement {
  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    this.setIconImage(element);
    const data = elementDoc.data();
    if (this.fileName !== data.fileName) {
      this.fileName = data.fileName;
      const el = document.getElementById(`element-${this.elementId}`);
      const elFilename = el.querySelector('.filename');
      const elFilenameArea = el.querySelector('.filename-area');
      elFilename.innerHTML = this.fileName;
      elFilenameArea.setAttribute('uk-tooltip', `title: ${this.fileName}`);
    }
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  async setup(elementId, elementDoc) {
    const el = document.getElementById(elementId);
    const data = elementDoc.data();
    const { type } = data;
    this.extension = type.trim();
    this.fileName = data.fileName;
    const iconData = this.getFileIcon(this.extension);
    this.fileIcon = iconData.icon;
    this.iconClass = iconData.class;
    this.backgroundClass = iconData.backgroundClass;
    this.setIconImage(document.getElementById(elementId));
    const elFilenameArea = el.querySelector('.filename-area');
    elFilenameArea.addEventListener('mouseup', () => {
      this.startRenamingFile();
    });

    const elFilenameInput = el.querySelector('.filename-input');
    elFilenameInput.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
        this.renameFile(e.target.value);
        this.stopRenamingFile();
      }
    });

    el.addEventListener('mouseover', () => {
      el.querySelector('.icon-background').classList.remove(`half-opacity`);
    });

    el.addEventListener('mouseleave', () => {
      el.querySelector('.icon-background').classList.add(`half-opacity`);
    });

    $(document).on('mouseup', (e) => {
      const element = document.getElementById(`${elementId}`);
      if (!element) return;
      const elFilenameInputArea = element.querySelector('.input-area');
      const elFilenameDiv = element.querySelector('.filename-area');
      if (!element.contains(e.target)) {
        elFilenameDiv.style.display = 'block';
        elFilenameInputArea.style.display = 'none';
      }
    });

    const rename = el.querySelector('.rename-option');
    if (rename) {
      rename.onclick = () => {
        if (!elementMoved()) this.startRenamingFile();
      };
    }
  }

  async renameFile(newFilename) {
    const el = document.getElementById(`element-${this.elementId}`);
    let fileName = `${newFilename}.${this.extension}`;
    let availableFilename = false;
    let storagePath;
    while (!availableFilename) {
      storagePath = `boards/${window.currentBoardId}/files/${fileName}`;
      const ref = storage.ref(storagePath);
      let urlTest;
      try {
        urlTest = await ref.getDownloadURL();
      } catch (err) {
        log.debug(err);
      }
      if (urlTest !== undefined) {
        fileName = createNewFilename(fileName, this.extension);
      } else {
        availableFilename = true;
      }
    }
    db.collection('boards').doc(window.currentBoardId).collection('elements').doc(this.elementId).update({
      fileName,
    });
    this.fileName = fileName;
    const elFilename = el.querySelector('.filename');
    const elFilenameArea = el.querySelector('.filename-area');
    elFilename.innerHTML = this.fileName;
    elFilenameArea.setAttribute('uk-tooltip', `title: ${this.fileName}`);
    window.analytics.track('Renamed file');
  }

  startRenamingFile() {
    const el = document.getElementById(`element-${this.elementId}`);
    const elFilename = el.querySelector('.filename');
    const elFilenameArea = el.querySelector('.filename-area');
    const elFilenameInputArea = el.querySelector('.input-area');
    const elFilenameInput = el.querySelector('.filename-input');
    elFilenameArea.style.display = 'none';
    elFilenameInputArea.style.display = 'block';
    const fullFilename = elFilename.innerHTML;
    const filenameStub = fullFilename.substring(0, fullFilename.lastIndexOf('.'));
    elFilenameInput.value = filenameStub;
    elFilenameInput.select();
  }

  stopRenamingFile() {
    const el = document.getElementById(`element-${this.elementId}`);
    const elFilenameArea = el.querySelector('.filename-area');
    const elFilenameInputArea = el.querySelector('.input-area');
    elFilenameArea.style.display = 'block';
    elFilenameInputArea.style.display = 'none';
  }

  setIconImage(element) {
    const elIcon = element.querySelector('.file-icon');
    const elBackground = element.querySelector('.icon-background');

    if (elIcon.src !== this.fileIcon) {
      elIcon.src = this.fileIcon;
      elIcon.classList.add(this.iconClass);
      elBackground.classList.add(this.backgroundClass);
    }
  }

  getFileIcon(extension) {
    const res = { icon: textIconPath, class: '', backgroundClass: '' };
    switch (extension ? extension.toLowerCase() : null) {
      case 'txt':
      case 'rtf':
      case 'doc':
      case 'docx':
      case 'pdf':
        res.icon = textIconPath;
        res.class = 'text-file';
        res.backgroundClass = 'text-file-background';
        break;
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ogg':
      case 'flac':
        res.icon = audioIconPath;
        res.class = 'audio-file';
        res.backgroundClass = 'audio-file-background';
        break;
      case 'mp4':
      case 'mov':
        res.icon = videoIconPath;
        res.class = 'video-file';
        res.backgroundClass = 'video-file-background';
        break;
      case 'xls':
      case 'xlsx':
      case 'csv':
        res.icon = spreadsheetIconPath;
        res.class = 'spreadsheet-file';
        res.backgroundClass = 'spreadsheet-file-background';
        break;
      case 'ppt':
      case 'pptx':
      case 'key':
        res.icon = presentationIconPath;
        res.class = 'presentation-file';
        res.backgroundClass = 'presentation-file-background';
        break;
      default:
        res.icon = unknownIconPath;
        res.class = 'unknown-file';
        res.backgroundClass = 'unknown-file-background';
    }
    return res;
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();
    const fullFileName = data.fileName !== undefined ? data.fileName : `Untitled.${data.type}`;
    this.fileName = fullFileName;
    const picture = htmlToElement(`<picture></picture>`);
    if (data.webp) {
      const source = htmlToElement(`<source type="image/webp" srcset="${data.webp}" />`);
      picture.appendChild(source);
    }

    this.fileType = getFileTypeByExtension(data.type);
    const starburstIconPath = `/images/icons/starburst-${this.fileType}.svg`;
    const img = htmlToElement(
      `<div class="object-container">
        <div>
          <img class="file-icon" crossOrigin="Anonymous" />
          <img class="icon-background-outline" src=${starburstOutlinePath} />
          <img class="icon-background half-opacity" src=${starburstIconPath} />
        </div>
        <div class="filename-area dont-drag-me" uk-tooltip="${this.fileName}">
          <h5 class="filename">${this.fileName}</h5>
        </div>
        <div class="input-area">
          <input class="filename-input" value=${this.fileName} />
        </div>
      </div>`
    );

    picture.appendChild(img);

    const downloadUrl = data.original || data.webp || data.fileURL;

    return wrapElement(picture, elementDoc, {
      classes: ['fileCard'],
      preserveAspectRatio: true,
      additionalOptions: [
        htmlToElement(`<button class="options-menu-option rename-option uk-dropdown-close">Rename</button>`),
        htmlToElement(`<a href="${downloadUrl}" target="__blank" download>Download File</a>`),
      ],
    });
  }

  // Statics
  static async addElement({ fileURL, type, ...additionalData }) {
    const props = {
      class: 'FileElement',
      creator: firebase.auth().currentUser.uid,
      size: [350, 350],
      zIndex: window.getFrontZIndex(),
      fileURL,
      type,
      ...additionalData,
    };

    if (!props.center) {
      props.center = screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2);
    }

    const boardRef = db.collection('boards').doc(window.currentBoardId);
    const boardData = await boardRef.get();

    await boardRef.collection('elements').add(props);

    const fileType = labels[type] !== undefined ? labels[type] : 'an unknown';
    addSystemMessage(`added ${fileType} file`);
    track(ADD_ELEMENT, {
      element: ELEMENT_TYPES.FILE,
      destination:
        boardData.data().type === USER_CARD_BOARD_TYPE
          ? ADD_ELEMENT_DESTINATION_TYPES.CARD
          : ADD_ELEMENT_DESTINATION_TYPES.ROOM,
      source: ADD_ELEMENT_SOURCE_TYPES.UPLOAD_FROM_COMPUTER,
    });
  }
}
