const colorValues = {
  red: '#f00',
  yellow: '#FEB445',
  purple: '#6B1BE3',
  orange: '#F7675C',
  magenta: '#A21D81',
  green: '#22936A',
  seafoam: '#2293AA',
  blue: '#1B77E4',
  violet: '#9668F8',
  lime: '#A9CE58',
  default: '#6B1BE3',
};

export default colorValues;
