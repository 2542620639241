import { useState, useEffect, useCallback } from 'react';

import firebase, { db } from '../../firebase';

import { formatFeedItemFromDocChanges, formatFeedItemFromDocs, loadMessagesPage } from '../chats/feed/feed-util';

import { MAX_MESSAGES_IN_PAGE } from '../../constants/chat-constants';
import { playSoundEffect, soundTypes } from '../../util/sound-fx-util';

export function useMessagesList(messagesCollectionPath, shouldPlaySound) {
  const [messagesList, setMessagesList] = useState([]);
  const [popupMessages, setPopupMessages] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const currentUserId = firebase.auth().currentUser.uid;

  useEffect(() => {
    if (!messagesCollectionPath) {
      return () => {};
    }

    const unsubscribe = db
      .collection(messagesCollectionPath)
      .orderBy('createdAt', 'desc')
      .limit(MAX_MESSAGES_IN_PAGE)
      .onSnapshot((snapshot) => {
        const docChanges = snapshot.docChanges();

        const newMessages = docChanges
          .filter((docChange) => docChange.type === 'added')
          .map(formatFeedItemFromDocChanges)
          .reverse();

        const modifiedMessages = docChanges
          .filter((docChange) => docChange.type === 'modified')
          .map(formatFeedItemFromDocChanges);

        const removedMessages = docChanges
          .filter((docChange) => docChange.type === 'removed')
          .map(formatFeedItemFromDocChanges);

        // play sound on new message in the chat
        if (newMessages.length === 1 && shouldPlaySound()) {
          const message = newMessages[0];
          if (message.type !== 'system' && message.creator !== currentUserId) {
            playSoundEffect(soundTypes.MESSAGE);
          }
        }

        setMessagesList((prevMessages) => {
          if (newMessages.length === 1 && !modifiedMessages.length && !removedMessages.length) {
            const newOldMessageIndex = prevMessages.findIndex((prevMessage) => prevMessage.id === newMessages[0].id);
            if (newOldMessageIndex !== -1) {
              return prevMessages;
            }
          }

          const oldAndNewMessages = [...prevMessages, ...newMessages]
            .filter((message) => !removedMessages.find((removedMessage) => removedMessage.id === message.id))
            .sort((a, b) => a.ts - b.ts); // this sorting may look useless but it's not

          // apply updates to messages
          modifiedMessages.forEach((modifiedMessage) => {
            const modifiedMessageIndex = oldAndNewMessages.findIndex((message) => message.id === modifiedMessage.id);
            if (modifiedMessageIndex !== -1) {
              // find added reaction
              const justAddedReaction = (modifiedMessage.emojiReactions || []).find(
                (reaction) =>
                  !(oldAndNewMessages[modifiedMessageIndex].emojiReactions || []).find(
                    (prevReaction) =>
                      prevReaction.creator === reaction.creator && prevReaction.reaction === reaction.reaction
                  )
              );

              // play sound on modified (reaction added) message in the chat
              if (
                justAddedReaction &&
                oldAndNewMessages[modifiedMessageIndex] &&
                oldAndNewMessages[modifiedMessageIndex].type !== 'system' && // it's a bit extra but just in case
                justAddedReaction.creator !== currentUserId &&
                oldAndNewMessages[modifiedMessageIndex].creator === currentUserId
              ) {
                playSoundEffect(soundTypes.MESSAGE);
              }

              oldAndNewMessages[modifiedMessageIndex] = {
                ...oldAndNewMessages[modifiedMessageIndex],
                ...modifiedMessage,
              };
            }
          });

          return oldAndNewMessages;
        });

        setPopupMessages((prevPopupMessages) => {
          const updatedPopupMessages = prevPopupMessages.filter(
            (message) => !removedMessages.find((removedMessage) => removedMessage.id === message.id)
          );

          if (newMessages.length === 1) {
            updatedPopupMessages.push(newMessages[0]);
          }

          if (modifiedMessages.length === 1) {
            const modifiedPopupMessageIndex = updatedPopupMessages.findIndex(
              (popup) => popup.id === modifiedMessages[0].id
            );

            updatedPopupMessages[modifiedPopupMessageIndex] = {
              ...updatedPopupMessages[modifiedPopupMessageIndex],
              ...modifiedMessages[0],
            };
          }

          return updatedPopupMessages;
        });
      });

    return () => {
      unsubscribe();
    };
  }, [messagesCollectionPath, currentUserId, shouldPlaySound]);

  useEffect(() => {
    if (!messagesCollectionPath) {
      return;
    }

    // reset messages list on board change (messagesCollectionPath depends on board)
    setMessagesList([]);

    loadMessagesPage(null, messagesCollectionPath, MAX_MESSAGES_IN_PAGE)
      .then((collections) => collections.docs.map(formatFeedItemFromDocs).reverse())
      .then((messagesPage) => {
        setMessagesList(messagesPage);
      });
  }, [messagesCollectionPath]);

  useEffect(() => {
    setMessagesList(messagesList.sort((a, b) => a.ts - b.ts));
  }, [messagesList]);

  const deleteMessageLocally = useCallback((id) => {
    setMessagesList((prevMessages) => prevMessages.filter((item) => item.id !== id));
  }, []);

  const deletePopupMessage = useCallback(
    (id) => {
      setPopupMessages((prevPopupMessages) => prevPopupMessages.filter((item) => item.id !== id));
    },
    [setPopupMessages]
  );

  const loadMoreMessages = useCallback(async () => {
    if (messagesList[0] && messagesCollectionPath && messagesList.length >= MAX_MESSAGES_IN_PAGE) {
      const collections = await loadMessagesPage(messagesList[0].id, messagesCollectionPath, MAX_MESSAGES_IN_PAGE);
      const fetchedMessages = collections.docs.map(formatFeedItemFromDocs).reverse();
      setMessagesList([...fetchedMessages, ...messagesList]);
      setHasMore(fetchedMessages.length === MAX_MESSAGES_IN_PAGE);
    }
  }, [messagesCollectionPath, messagesList]);

  return {
    messagesList,
    popupMessages,
    hasMore,
    setMessagesList,
    setPopupMessages,
    setHasMore,
    deleteMessageLocally,
    deletePopupMessage,
    loadMoreMessages,
  };
}
