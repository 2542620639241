export const SEND_FEED_MESSAGE = 'Send Feed Message';
export const SEND_CHAT_MESSAGE = 'Send Chat Message';
export const CHANGE_MESSAGE_FONT_STYLE = 'Change Message Font Style'; // params { fontName: string }
export const CHANGE_MESSAGE_FONT_SIZE = 'Change Message Font Size'; // params { fontSize: string }
export const CHANGE_MESSAGE_BACKGROUND = 'Change Message Background'; // params { color: string }
export const CHANGE_MESSAGE_FONT_COLOR = 'Change Message Font Color'; // params { color: string }
export const SEND_OUTSIDE_REACTION = 'Send Outside Reaction';

export const SEND_FEED_MESSAGE_SOURCES = {
  WEB_LOBBY: 'Web Lobby',
  MESSAGES_SIDEBAR: 'Messages Sidebar',
};

export const CHANGE_MESSAGE_FONT_STYLE_SOURCES = {
  DM: 'DM',
  ROOM_FEED: 'Room Feed',
  TEXT_CHANNEL: 'Text Channel',
};
