import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import UIkit from 'uikit';

// store
import { addGifRequested } from '../store/gif-search/store';

// components
import Draggable from '../components/Draggable.tsx';

// constants
import { gifPickerCallers } from '../../constants/image-constants';

const RoomDecorationGif = ({ id, url, category }) => {
  const dispatch = useDispatch();

  const data = {
    url,
    type: 'image',
  };

  const onClick = () => {
    UIkit.dropdown('#room-decoration-select').hide(0);
    dispatch(
      addGifRequested({ id, url, caller: gifPickerCallers.ROOM_DECORATION_PICKER, roomDecorationCategory: category })
    );
  };

  return (
    <Container onClick={onClick}>
      <Draggable contentSelector="img" data={data}>
        <Image src={url} alt="furniture item" />
      </Draggable>
    </Container>
  );
};

RoomDecorationGif.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

const Container = styled.div`
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Image = styled.img`
  max-height: 100%;
  width: 100%;
`;

export default RoomDecorationGif;
