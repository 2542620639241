import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateRoomButton = styled.button`
  height: 50px;
  width: 214px;
  box-sizing: border-box;
  color: white;
  font-size: 1.2em;
  line-height: 2.4em;
  text-transform: capitalize;
  font-weight: bold;
  background: linear-gradient(84.94deg, #6b1be3 0%, #4c13ee 100.44%);
  border: 1px solid white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  transition: transform 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: #ffffffcc;
    background: #636363cc;
    border: 0px;
    box-shadow: 0px;
    cursor: auto;
  `}
`;
