import axios from 'axios';
import { api } from '../firebase';

let fetchVideoServerTimer = 0;
let videoServer = null;
let waitPromiseInstance = null;

export const getVideoServer = () => videoServer;

const adjustFetchVideoServerTimeout = () => {
  if (fetchVideoServerTimer === 0) {
    fetchVideoServerTimer = 1000;
  }
  if (fetchVideoServerTimer < 30 * 1000) {
    fetchVideoServerTimer = Math.min(30 * 1000, fetchVideoServerTimer * 2);
  }
};

let isLoadingVideoServer = false;
const fetchVideoServer = (docId) => {
  setTimeout(async () => {
    try {
      isLoadingVideoServer = true;
      const resp = await axios({
        url: `${api}/room/${docId}/videoServer`,
        method: 'GET',
      });

      if (resp.data.server) {
        videoServer = resp.data.server;
        fetchVideoServerTimer = 0;
      } else {
        adjustFetchVideoServerTimeout();
        fetchVideoServer(docId);
      }

      isLoadingVideoServer = false;
    } catch (e) {
      console.error(e);
      adjustFetchVideoServerTimeout();
      fetchVideoServer(docId);
    }
  }, fetchVideoServerTimer);
};

const waitForVideoServer = () =>
  new Promise((resolve) => {
    const checkVideoServer = () => {
      if (!videoServer) {
        setTimeout(checkVideoServer, 1000);
      } else {
        resolve(videoServer);
      }
    };

    checkVideoServer();
  });

export const fetchAndWaitForVideoServer = async (docId, reset = false) => {
  if (reset) {
    videoServer = null;
  }

  if (isLoadingVideoServer && waitPromiseInstance) {
    await waitPromiseInstance;
    return getVideoServer();
  }

  if (videoServer) {
    return videoServer;
  }

  fetchVideoServer(docId);

  waitPromiseInstance = waitForVideoServer();

  await waitPromiseInstance;
  return getVideoServer();
};
