/* eslint-disable @typescript-eslint/lines-between-class-members */
import { defer, delay } from 'lodash';
import firebase from '../firebase';
import Janus, { JanusWrapperType } from './janus/wrapper';
import ScreenshareElement from '../screenshare';
import log from '../log';
import { setVideoVolume } from '../spatial-audio';
import './connection-watchdog';
import { findCameraHandler } from '../util/camera-util';
import CameraPublisher from './camera_publisher';
import Room, { RoomDelegateProtocol } from './room';
import { getQueryStringValue } from './helpers';
import Session, { SessionDelegateProtocol } from './session';
import JanusJS, { PublisherFeed } from './janus/janus.definitions';
import {
  SFU as SFUBase,
  ConnectionStatus,
  PublisherStreamSource,
  UserId,
  OnCameraUpdateFnPrototype,
  SubscribeStatus,
  StreamType,
  ScreenShareQuality,
  PlayerState,
  ElementWithPlayerState,
  ScreensharingState,
  OnlineStatus,
  PublisherType,
} from './definitions/index.definitions';
import { Snapshot } from './snapshot';
import { Devices, PublisherDeviceProtocol } from './devices';
import Events from './events';
import SFUWatchDog from './sfu-watchdog';
import Subscriber, { SubscriberDelegateProtocol } from './subscriber';
import { Stats } from './stats';
import { asyncDebounce } from './util';
import { trackInForeground as __OriginalTrackMetric } from '../util/analytics-util';
import { clearStallTimers } from '../camera/default-video-renderer';
import { rendererForCamera } from '../camera/video-renderer-util';
import CameraElement from '../camera';
import { Adaptability } from './adaptability';
import StreamConstraints from './stream-constraints';
import { shouldUseCustomAudioMixer } from '../electron-support/electron-support';
import { addAudioTrack } from '../audio-mixer';
import { BasePublisher, DeferReason } from './base_publisher';
import CameraLiteMode from './camera-lite-mode';

const SFU_OPTIONS = {
  SFU_WATCHDOG_ENABLED: true,
  SFU_STATS_ENABLED: true,
};

const SFU_WATCHDOG_DRY_RUN = false;
const ICE_RESTART_CANCEL_TOLERANCE_MS = 10000;

// FIXME: Keep an eye on this, NetworkInformation has been added but props not yet.
export class SFU implements SFUBase, SessionDelegateProtocol, RoomDelegateProtocol, SubscriberDelegateProtocol {
  onReconnecting: () => void;
  onReconnectComplete: () => void;
  _onCameraUpdate: OnCameraUpdateFnPrototype;

  private pauseListeners: { [key: string]: (e: Event) => void } = {};

  // FIXME
  /** HTML Element where Screensharing will be attached */
  localScreenshareElementDoc: HTMLElement;

  private initialized: boolean;
  private session: Session;

  private static instance: SFU;
  private opaqueId: string;
  private boardId: string;
  private videoServer: string;
  private janus: JanusWrapperType;
  private room: Room;
  private myusername: string;

  private _isAudioOn: boolean;
  private _isVideoOn: boolean;

  private gotSubscriber = false;
  private gotPublisher = false;

  private isHereEmployee = false;

  private screensharingLastState: ScreensharingState;

  private cameraLiteMode: CameraLiteMode;

  cameraIsReadyToPublish = false;
  screenshareFramerate: number = null;

  _online: OnlineStatus = OnlineStatus.Unknown;

  /** Plugin Instances */
  eventsPlugin: Events;
  readonly devices: Devices;
  sfuWatchDog: SFUWatchDog;
  stats: Stats;
  adaptability: Adaptability;
  constraints: StreamConstraints;

  constructor() {
    this.opaqueId = `here-${Janus.randomString(12)}`;
    this.devices = Devices.getInstance();
    this.sfuWatchDog = new SFUWatchDog();
    this.stats = new Stats();
    this.constraints = new StreamConstraints();
    this.adaptability = new Adaptability();
    this.adaptability.streamConstraints = this.constraints;
  }

  public static getInstance(): SFUBase {
    if (!SFU.instance) {
      SFU.instance = new SFU();
    }
    return SFU.instance;
  }

  get sessionId() {
    return this.janus?.getSessionId();
  }

  set screenshareBitrate(rate: number) {
    if (this.room?.screenPublisher) {
      this.room.screenPublisher.bitrate = rate;
    }
  }

  set onCameraUpdate(func: OnCameraUpdateFnPrototype) {
    this._onCameraUpdate = (options: { isAudioOn: boolean; isVideoOn: boolean }) => {
      this.isAudioOn = options.isAudioOn;
      this.isVideoOn = options.isVideoOn;
      func(options);
    };
  }

  get onCameraUpdate() {
    return this._onCameraUpdate;
  }

  get isAudioOn() {
    if (this.room?.cameraPublisher?.status === ConnectionStatus.Connected) {
      return this.room.cameraPublisher.isAudioOn;
    }
    return this._isAudioOn;
  }

  set isAudioOn(value: boolean) {
    this._isAudioOn = value;
  }

  get isVideoOn() {
    if (this.room?.cameraPublisher?.status === ConnectionStatus.Connected) {
      return this.room.cameraPublisher.isVideoOn;
    }
    return this._isVideoOn;
  }

  set isVideoOn(value: boolean) {
    this._isVideoOn = value;
  }

  get isScreenVideoOn() {
    return this.room?.screenPublisher?.status === ConnectionStatus.Connected;
  }

  get localStream() {
    return this.room?.cameraPublisher?.localStream;
  }

  get serverPrefix() {
    return this.session?.server;
  }

  set online(status) {
    this._online = status;
    if (this.sfuWatchDog) this.sfuWatchDog.online = status;
  }

  get online() {
    return this._online;
  }

  logInfo() {
    const ppc = this.room.cameraPublisher.publisherHandle.webrtcStuff.pc;
    const spc = this.room.subscriber.subscriberHandle.webrtcStuff.pc;
    const fc = this.room.subscriber.feedCollection;

    const subscribedMediaFeedTracks = fc.feedTracks.filter(
      (ft) => ft.status === SubscribeStatus.Subscribed && ft.type !== StreamType.Data
    );

    const streamVideoTracks = fc.feeds.map((f) => f.stream.getVideoTracks()).flat();
    const streamAudioTracks = fc.feeds.map((f) => f.stream.getAudioTracks()).flat();
    const unmutedPCVideoTracks = spc.getReceivers().filter((r) => r.track.kind === 'video' && !r.track.muted);
    const unmutedPCAudioTracks = spc.getReceivers().filter((r) => r.track.kind === 'audio' && !r.track.muted);

    log.log('--- SFU INFO ---\n');
    log.log(`Feeds: ${fc.feeds.filter((f) => f.subscribed).length}/${fc.feeds.length} subscribed\n`, fc.feeds, '\n');
    log.log(`FeedTracks: ${fc.feeds.length * 2}/${subscribedMediaFeedTracks.length} subscribed\n`, fc.feedTracks, '\n');
    log.log(
      `Video Tracks: ${streamVideoTracks.length}/${fc.feeds.length} present in MediaStream, PC: ${unmutedPCVideoTracks.length} unmuted\n`
    );
    log.log(
      `Audio Tracks: ${streamAudioTracks.length}/${fc.feeds.length} present in MediaStream, PC: ${unmutedPCAudioTracks.length} unmuted\n`
    );
    log.log('----------------\n');

    window.ppc = ppc;
    window.spc = spc;
    window.fc = fc;
  }

  private trackMetric(name: string, props?: { [key: string]: unknown }) {
    __OriginalTrackMetric(name, Object.assign(props || {}, this.commonMetricProps || {}));
  }

  get commonMetricProps() {
    return {
      isAudioOn: this.isAudioOn,
      isVideoOn: this.isVideoOn,
      sessionStatus: this.session?.status,
      publisherStatus: this.room?.cameraPublisher?.status,
      publisherPc: this.room?.cameraPublisher?.peerConnectionStatus,
      publisherIce: this.room?.cameraPublisher?.iceState,
      subscriberStatus: this.room?.subscriber?.status,
      subscriberPc: this.room?.subscriber?.peerConnectionStatus,
      subscriberIce: this.room?.subscriber?.iceState,
    };
  }

  updateAudioOutput() {
    this.devices.updateAudioOutput();
  }

  restartCapture() {
    try {
      this.devices.restartCapture();
    } catch (e) {
      log.error('SFU: restartCapture throw an Error: ', e);
      this.trackMetric('SFU Restart Capture Failed');
      // FIXME: If something went wrong we should recover state here
    }
  }

  reconnect = asyncDebounce(
    async () => {
      try {
        if (this.online === OnlineStatus.Offline) {
          return;
        }
        if (this.session) {
          this.session.reconnect(true);
          return;
        }
        this.trackMetric('SFU Reconnect');
        await this.room?.dispose();
        await this.session?.dispose();
        if (!this.boardId) throw new Error('Cannot reconnect SFU without a boardId!');
        if (!this.videoServer) throw new Error('Cannot reconnect SFU without a videoServer!');
        await this.init();
        await this.joinRTCRoom(this.boardId, this.videoServer, this.isAudioOn, this.isVideoOn);
      } catch (error) {
        log.error('SFU: Reconnect Error', { error: error.message });
        this.trackMetric('SFU Reconnect Error', { error: error.message });
      }
    },
    2000,
    { leading: true }
  );

  async init() {
    if (this.initialized) {
      log.warn('SFU: Already initialized... ignoring.');
      return;
    }
    log.log('SFU: init with options: \n', JSON.stringify(SFU_OPTIONS, null, 2));

    const debugOpts = getQueryStringValue('rtc-debug') ? 'all' : ['error', 'warn'];

    // FIXME: Correct type here please
    await Janus.init({ debug: debugOpts as JanusJS.DebugLevel[] | 'all' | boolean });

    if (!Janus.isWebrtcSupported()) {
      log.error("No WebRTC support in your browser - video and other features won't work :(");
    }

    await this.devices.monitorDeviceChanges();

    this.initialized = true;

    this.trackMetric('SFU Initialized');
  }

  async joinRTCRoom(boardId: string, videoServer: string, isAudioOn: boolean, isVideoOn: boolean) {
    this.trackMetric('SFU Attempt to Join Room');
    this.isAudioOn = isAudioOn;
    this.isVideoOn = isVideoOn;
    this.boardId = boardId;
    this.videoServer = videoServer;
    this.myusername = firebase.auth().currentUser.uid;
    if (!this.session || this.session.server !== videoServer || this.session.status !== ConnectionStatus.Connected) {
      await this.session?.dispose(true);
      this.session = new Session(this);
      this.session.createSession(videoServer);
      this.sfuWatchDog.session = this.session;
    } else if (this.session.status === ConnectionStatus.Connected) {
      this.room = new Room(this.boardId, this.opaqueId, this.janus, this.constraints, this);
      this.room.join(this.myusername);
    }
  }

  onCameraElementReady(streamId: string) {
    const stream = this.room?.activeStreams ? this.room.activeStreams[streamId] : null;
    if (stream) {
      log.debug(`SFU : onCameraElementReady: Adding HTML for stream id ${streamId}`);
      this.addHTMLForStream(stream, streamId);
    } else {
      log.debug(`SFU: onCameraElementReady: No pending stream found with id ${streamId}`);
    }
  }

  onExternalMediaPlayerStateChange(element: ElementWithPlayerState) {
    // TODO this strategy implies that there is only one active media player in the room
    // What we should probably do here for completeness is check all media players present
    // in the room and set externalMediaPlaying state based on ANY of them being active
    if (!element || !this.room?.constraints) return;

    if (element.playerState === PlayerState.ENDED) {
      this.room.constraints.externalMediaPlaying = false;
      this.updateLocalStreamConstraints();
    } else if (element.playerState === PlayerState.PAUSED) {
      delay(
        (el) => {
          if (el && this.room?.constraints && el.playerState === PlayerState.PAUSED) {
            this.room.constraints.externalMediaPlaying = false;
            this.updateLocalStreamConstraints();
          }
        },
        5000,
        element
      );
    } else {
      this.room.constraints.externalMediaPlaying = true;
      this.updateLocalStreamConstraints();
    }
  }

  getUserIdLinkQuality(userId: string, screensharing = false) {
    if (this.session?.status === ConnectionStatus.Connecting || this.room?.status === ConnectionStatus.Connecting) {
      return 4;
    }
    const feed = this.room?.subscriber?.feedCollection?.getFeedByUserId(userId);
    if (feed) {
      if (
        this.room?.subscriber?.peerConnection?.connectionState === 'failed' ||
        (this.gotSubscriber && !this.room?.subscriber?.peerConnection) ||
        (this.room?.cameraPublisher?.deferStatus?.attempts > 20 &&
          this.room?.cameraPublisher?.deferStatus.reason === DeferReason.SignalNotStable)
      ) {
        return 1;
      }
      return feed.linkQuality;
    }
    if (
      this.room?.cameraPublisher?.peerConnection?.connectionState === 'failed' ||
      (this.gotPublisher && !this.room?.cameraPublisher?.peerConnection)
    ) {
      return 1;
    }
    if (screensharing && userId === this.room?.screenPublisher?.userId) {
      return this.room?.screenPublisher?.linkQuality;
    }
    if (userId === this.room?.cameraPublisher?.userId) {
      return this.room?.cameraPublisher?.linkQuality;
    }
    return undefined;
  }

  // FIXME: Simplify this method if possible.
  async attachStreams(
    handler: CameraElement,
    videoElement: HTMLVideoElement,
    videoContainer: HTMLElement,
    streamId: string,
    stream: MediaStream
  ) {
    if ((await shouldUseCustomAudioMixer()) && !handler.isOwnCamera()) {
      const audioTracks = stream.getAudioTracks();
      if (audioTracks?.length > 0) {
        try {
          addAudioTrack(audioTracks[0], streamId);
          audioTracks[0].enabled = false;
        } catch (err) {
          log.error('Failed to add audio track to mixer', err);
        }
      }
    }
    Janus.attachMediaStream(videoElement, stream);
    if (!streamId.startsWith('screen-')) {
      handler.setupAudioLevelsWithDefaults();
    }

    const element = videoContainer.closest('.videoElement');
    setVideoVolume(element);
  }

  addHTMLForStream(stream: MediaStream, streamId: string) {
    log.debug(`SFU: Adding HTML for user ${streamId}`);

    if (!this.cameraIsReadyToPublish) {
      setTimeout(this.addHTMLForStream.bind(this), 1000, stream, streamId);
      log.debug('Defer addHTMLForStream');
      return;
    }

    this.updateLocalStreamConstraints();
    let handler;
    if (streamId.startsWith('screen-')) {
      const userId = streamId.replace('screen-', '');
      handler = ScreenshareElement.findScreenshareHandler(userId);
    } else {
      handler = findCameraHandler(streamId);
    }

    if (handler) {
      handler.onStreamReady(streamId);
      if (handler.updateCameraNameIcon) {
        handler.updateCameraNameIcon();
      }
    } else {
      log.debug(`SFU: addHTMLForStream: video container doesn't exist yet for ${streamId}`);
      return;
    }

    this.pauseListeners[streamId] = (e) => {
      if (this.room.teardown) return;

      if (this.room.activeStreams[streamId] && this.room.activeStreams[streamId].active) {
        log.debug('SFU: Camera paused, but stream still active. Restarting');
        this.trackMetric('SFU Camera Paused', { myself: this.myusername === streamId });
        this.addHTMLForStream(this.room.activeStreams[streamId], streamId);
        setTimeout(() => {
          // Apparently the target can set itself to null within the duration of this delay
          if (e.target) {
            (e.target as HTMLMediaElement).play();
          }
        }, 100);
      } else {
        log.debug('sfu: Camera paused, stream is not active');
      }
    };

    rendererForCamera(handler).attachStream(handler, streamId, stream, this.pauseListeners[streamId]);
  }

  removeStream(__streamId: string) {
    // FIXME: Here we should be handling and stream that went off.
  }

  //
  // FIXME: handle slow link in bitrate! connect it!
  //

  //
  // -- Implements SessionDelegate -- //
  //

  afterSessionDestroyed(): void {
    if (this.session.status === ConnectionStatus.Disconnected) {
      this.session.reconnect();
    }
  }

  async beforeSessionCreate() {
    clearStallTimers();
    // this.room?.dispose();
  }

  beforeSessionReconnect() {
    if (this.onReconnecting) this.onReconnecting();
  }

  afterSessionReconnect() {
    if (this.onReconnectComplete) this.onReconnectComplete();
    // FIXME: can this be somewhere else?
    document.getElementById('error-message').style.display = 'none';
  }

  afterSessionReconnectFailed() {
    // FIXME: Something to do here?
  }

  afterSessionConnected(janus: JanusWrapperType) {
    this.trackMetric('SFU Session Connected');
    this.janus = janus;
    this.room = new Room(this.boardId, this.opaqueId, janus, this.constraints, this);
    this.room.join(this.myusername);
  }

  //
  // -- Implements RoomDelegate -- //
  //

  roomDestroyed(_room: Room) {
    delay(this.reconnect.bind(this), 1000, true);
  }

  roomPCChanged(publisher: BasePublisher, room: Room, isPeerConnected: boolean) {
    if (room?.teardown) return;

    if (!room.destroyed && !isPeerConnected && room.status === ConnectionStatus.Connected && publisher) {
      if (publisher?.hangedUp) return;

      this.trackMetric('SFU Publish After Room PC Changed', {
        roomStatus: room.status,
        peerConnected: isPeerConnected,
        publisher: !!publisher,
        publisherType: publisher?.publisherType,
      });

      delay(
        publisher.publishOwnFeed.bind(publisher),
        2000,
        publisher.isAudioOn,
        publisher.isVideoOn,
        publisher.publisherType === PublisherType.Camera,
        0,
        publisher.iceState === 'disconnected' || publisher.iceState === 'failed'
      );
    }
  }

  roomPublishers(__room: Room, __publishers: PublisherFeed[]) {
    // Don't need to do nothing here since Room is already
    // subscribing automatically, set room.autoSubscribe to false
    // if need to change that behavior.
  }

  async roomJoined(_room: Room, publisher: CameraPublisher, publisherCount: number) {
    this.trackMetric('SFU Room Joined', { publisherCount });
    try {
      this.room = _room;
      publisher.setOnCameraUpdate(this.onCameraUpdate);
      this.room.updateLocalStreamConstraints();
      this.adaptability.streamConstraints = this.room.constraints;
      publisher.audioDeviceConstraints = this.room.constraints.audioMediaConfig(this.devices.audioDeviceId, 'voice');
      publisher.videoDeviceConstraints = this.room.constraints.videoMediaConfig(
        this.devices.videoDeviceId,
        this.room.constraints.localStreamConstraints
      );

      this.cameraLiteMode = new CameraLiteMode(publisher, this.room.constraints);
      publisher.onUpdatedRates = (rateInSeconds) => {
        this.cameraLiteMode.rateInSeconds = rateInSeconds;
      };

      const { isAudioOn, isVideoOn } = this;

      const publishCycle = async () => {
        try {
          await publisher.startCamera({ isAudioOn, isVideoOn });

          // Initialize plugins or provision them.
          this.eventsPlugin = new Events(publisher.publisherHandle, this.myusername);
          this.devices.publisher = publisher as PublisherDeviceProtocol;

          // Stats
          if (SFU_OPTIONS.SFU_STATS_ENABLED) this.stats.startMonitor();

          // Recreate Screenshare
          if (this.screensharingLastState) {
            log.warn('Last Screensharing to use:', this.screensharingLastState);
            delay(() => {
              // A hangup could occur during the delay, leaving this null
              if (this.screensharingLastState) {
                const stream = this.screensharingLastState.stream.clone();
                this.screensharingLastState.restart(stream);
              }
            }, 5000);
          }
        } catch (e) {
          log.error('SFU: Has been an error publishing the stream!', e);
        }
      };

      defer(delay, publishCycle.bind(this), this.cameraIsReadyToPublish ? 0 : 500);
    } catch (e) {
      log.error('SFU: Has been an error after room has been joined: ', e);
    }
  }

  publisherStreamReadyForHTML(stream: MediaStream, username: string, type: PublisherStreamSource) {
    this.gotPublisher = true;
    this.addHTMLForStream(stream, username);
    if (type === PublisherStreamSource.Camera) {
      this.stats.publisher = this.room.cameraPublisher;
    } else if (type === PublisherStreamSource.ScreenShare) {
      this.stats.screenPublisher = this.room.screenPublisher;
    }
  }

  roomLeave(room: Room) {
    log.debug('SFU: roomLeave ', room);
    this.stats.stopMonitor();
  }

  iceRestartCancelled(state: RTCIceConnectionState) {
    let willReconnect = false;
    // Handles PC isn't there
    if ([undefined, null].includes(this.room?.cameraPublisher?.iceState)) {
      willReconnect = true;
    }
    this.trackMetric('SFU Publisher ICE Restart Cancelled', {
      iceState: this.room?.cameraPublisher?.iceState,
      reconnect: willReconnect,
    });
    if (!state || state === 'disconnected' || state === 'failed') {
      delay(async () => {
        if (willReconnect) {
          await this.reconnect();
        }
      }, ICE_RESTART_CANCEL_TOLERANCE_MS);
    }
  }

  noICE(publisher: BasePublisher): void {
    log.warn('SFU: no ICE');
    if (
      this.room &&
      !this.room.cameraPublisher.iceState &&
      publisher.publisherType === PublisherType.Camera &&
      this.session.status === ConnectionStatus.Connected
    ) {
      this.room.join(this.myusername);
    }
  }

  //
  // -- AV Actions -- //
  //

  startCamera(...args: unknown[]) {
    log.error('SFU: Starting camera directly from SFU?');
    this.trackMetric('SFU Start Camera');
    this.room.cameraPublisher.startCamera.apply(this.room, ...args);
  }

  stopCamera() {
    log.debug('SFU: Stop Camera');
    this.trackMetric('SFU Stop Camera');
    this.room.cameraPublisher.stopCamera();
  }

  muteLocalVideo() {
    this.trackMetric('SFU Mute Local Video');
    const publisher = this.room?.cameraPublisher;
    if (publisher) publisher.muteLocalVideo();
    else {
      log.warn('SFU: Cannot mute local video without a Publisher!');
      this.trackMetric('SFU Cannot Mute Video');
    }
  }

  async unmuteLocalVideo() {
    this.trackMetric('SFU Unmute Local Video');
    const publisher = this.room?.cameraPublisher;
    if (publisher) publisher.unmuteLocalVideo();
    else {
      log.warn('SFU: Cannot unmute local video without a Publisher!');
      this.trackMetric('SFU Cannot Unmute Video');
    }
  }

  muteLocalAudio() {
    this.trackMetric('SFU Mute Local Audio');
    const publisher = this.room?.cameraPublisher;
    if (publisher) publisher.muteLocalAudio();
    else log.warn('SFU: Cannot mute local audio without a Publisher!');
  }

  unmuteLocalAudio() {
    this.trackMetric('SFU Unmute Local Audio');
    const publisher = this.room?.cameraPublisher;
    if (publisher) publisher.unmuteLocalAudio();
    else log.warn('SFU: Cannot unmute local audio without a Publisher!');
  }

  updateLocalStreamConstraints() {
    if (this.room?.subscriber?.status !== ConnectionStatus.Connected) return;
    this.room?.updateLocalStreamConstraints();
  }

  async subscribeToVideo(userId: string) {
    if (this.room?.subscriber?.status !== ConnectionStatus.Connected) return;
    await this.room.subscriber.subscribeToVideo(userId);
  }

  async unsubscribeFromVideo(streamId: string) {
    if (this.room?.subscriber?.status !== ConnectionStatus.Connected) return;
    this.replaceWithSnapshot(streamId as UserId);
    await this.room?.subscriber?.unsubscribeFromVideo(streamId);
  }

  hangupScreenshare() {
    this.screensharingLastState = null;
    this.room.screenPublisher.hangupScreenshare();
  }

  async startScreenshare(ready: () => void, restart: () => void, stream: MediaStream) {
    this.trackMetric('SFU Start Screenshare');

    this.room.startScreenshare(ready, restart, stream);
    this.screensharingLastState = { ready, restart, stream };
  }

  updateScreensharingQuality(quality: ScreenShareQuality) {
    if (this.isScreenVideoOn) {
      this.room.screenPublisher.updateQuality(quality);
    }
  }

  get screensharingQuality() {
    return this.room?.screenPublisher?.currentQuality;
  }

  get screensharingConstraints() {
    return this.room?.screenPublisher?.currentConstraints;
  }

  get screenshareOriginalStream() {
    return this.room?.screenPublisher?.screenshareOriginalStream;
  }

  get activeStreams(): { [key in UserId]: MediaStream } {
    return this.room.activeStreams;
  }

  get remoteTracks() {
    return Object.values(this.room.subscriber.activeStreams)
      .filter((st) => st)
      .flatMap((stream) => stream.getTracks());
  }

  //
  // -> Implements SubscriberDelegate
  //
  subscriberUp(subscriber: Subscriber) {
    this.trackMetric('SFU Got Subscriber PeerConnection', { feed_count: subscriber.feedCollection?.feeds?.length });
    this.sfuWatchDog.stop();
    if (SFU_OPTIONS.SFU_WATCHDOG_ENABLED) this.sfuWatchDog.start(this.room, SFU_WATCHDOG_DRY_RUN);
    this.gotSubscriber = true;
  }

  subscriberDown(subscriber: Subscriber) {
    this.trackMetric('SFU Lost Subscriber PeerConnection', { feedCount: subscriber?.feedCollection?.feeds?.length });
  }

  subscriberStreamReadyForHTML(stream: MediaStream, userId: string) {
    this.addHTMLForStream(stream, userId);
    this.stats.subscriber = this.room.subscriber;
    setVideoVolume();
  }

  subscriberTrackRemoved(track: MediaStreamTrack, stream: MediaStream, userId: string) {
    log.debug(`SFU: Subscriber track removed for user id: ${userId}, MediaStream id: ${stream.id}`);
    if (track.kind === 'video') {
      // Replace the video with a snapshot if we must
      const videoEl = document.getElementById(`video-${userId}`);

      let isVideoOn = false;
      if (videoEl) {
        const element = videoEl.closest('.boardElement');
        const elementId = element.id.replace('element-', '');
        // FIXME: What is this? How this should be?
        const videoHandler = window.elementHandlers[elementId];
        if (videoHandler) {
          isVideoOn = videoHandler.isVideoOn;
        }
        if (isVideoOn && videoEl.parentNode) {
          Snapshot.replaceWithSnapshot(userId);
        }
      }
    }

    stream.removeTrack(track);
  }

  subscriberNotRecoverableException() {
    this.trackMetric('SFU Subscriber Not Recoverable Exception');
    this.session.reconnect();
  }

  //
  // -> Forward Events Plugin
  //
  handleDataMessage(...args: unknown[]) {
    this.eventsPlugin?.handleDataMessage(...args);
  }
  sendCameraHand(...args: unknown[]) {
    this.eventsPlugin?.sendCameraHand(...args);
  }
  sendCursorLocation(...args: unknown[]) {
    this.eventsPlugin?.sendCursorLocation(...args);
  }
  sendElementMoved(...args: unknown[]) {
    this.eventsPlugin?.sendElementMoved(...args);
  }
  sendDrawPoint(...args: unknown[]) {
    this.eventsPlugin?.sendDrawPoint(...args);
  }
  sendElementRotated(...args: unknown[]) {
    this.eventsPlugin?.sendElementRotated(...args);
  }
  sendParticleEffect(...args: unknown[]) {
    this.eventsPlugin?.sendParticleEffect(...args);
  }
  sendReaction(...args: unknown[]) {
    this.eventsPlugin?.sendReaction(...args);
  }
  sendReactionDirected(...args: unknown[]) {
    this.eventsPlugin?.sendReactionDirected(...args);
  }
  sendElementResized(...args: unknown[]) {
    this.eventsPlugin?.sendElementResized(...args);
  }
  sendVoiceControl(...args: unknown[]) {
    this.eventsPlugin?.sendVoiceControl(...args);
  }
  sendMarkupDiff(...args: unknown[]) {
    this.eventsPlugin?.sendMarkupDiff(...args);
  }
  playSynthesizerSound(...args: unknown[]) {
    this.eventsPlugin?.playSynthesizerSound(...args);
  }
  sendPhotoBoothCountdown(...args: unknown[]) {
    this.eventsPlugin?.sendPhotoBoothCountdown(...args);
  }
  sendTyping(params: { typingStatus: string; target: string }) {
    this.eventsPlugin?.sendTyping(params);
  }

  //
  // -> Forward Devices
  //
  async getDeviceList() {
    return this.devices.getDeviceList();
  }

  async updateDevices() {
    await this.devices.updateDevices();
  }

  get audioDeviceLabel() {
    return this.devices.audioDeviceLabel;
  }

  set audioDeviceLabel(value: string) {
    if (this.devices) this.devices.audioDeviceLabel = value;
  }

  get videoDeviceLabel() {
    return this.devices?.videoDeviceLabel;
  }

  set videoDeviceLabel(value: string) {
    if (this.devices) this.devices.videoDeviceLabel = value;
  }

  get audioDeviceId() {
    return this.devices?.audioDeviceLabel;
  }

  set audioDeviceId(value: string) {
    if (this.devices) this.devices.audioDeviceId = value;
  }

  get videoDeviceId() {
    return this.devices?.videoDeviceId;
  }

  set videoDeviceId(value: string) {
    if (this.devices) this.devices.videoDeviceId = value;
  }

  //
  // -> Forward Snapshot
  //
  saveSnapshot(userId: string) {
    Snapshot.saveSnapshot(userId);
  }

  hideSnapshot(userId: string) {
    Snapshot.hideSnapshot(userId);
  }

  replaceWithSnapshot(userId: string) {
    Snapshot.saveSnapshot(userId);
    Snapshot.replaceWithSnapshot(userId);
  }

  //
  // -> Forward Stats
  //
  setMonitorInterval(value: number) {
    this.stats.setMonitorInterval(value);
  }

  toggleUserStats(userId: string, elementClass: 'CameraElement' | 'ScreenshareElement') {
    this.stats.toggleDOMRtcStats(userId, elementClass === 'ScreenshareElement');
  }
}

export default SFU.getInstance();
