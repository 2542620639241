// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import UIKit from 'uikit';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { FurnitureType } from './room-decoration-data';

// components
import Tabs from '../components/Tabs';
import RoomDecorationTab from './RoomDecorationTab';

import { buttonMixin, svgColorMixin } from '../mixins';
import BackgroundIcon from '../../../assets/icons/background.svg';
import { openRoomSettings } from '../store/room/store';
import { ROOM_SETTINGS_TABS } from '../store/room/constants';

const RoomDecorationMenu = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Seating');

  const tabs = [
    {
      name: 'Seating',
      node: <RoomDecorationTab type={FurnitureType.Seating} isActive={activeTab === 'Seating'} />,
    },

    {
      name: 'Decorations',
      node: <RoomDecorationTab type={FurnitureType.Decor} isActive={activeTab === 'Decorations'} />,
    },

    {
      name: 'Accessories',
      node: <RoomDecorationTab type={FurnitureType.Accessories} isActive={activeTab === 'Accessories'} />,
    },

    {
      name: 'Snacks',
      node: <RoomDecorationTab type={FurnitureType.Snacks} isActive={activeTab === 'Snacks'} />,
    },

    {
      name: 'Pets',
      node: <RoomDecorationTab type={FurnitureType.Pets} isActive={activeTab === 'Pets'} />,
    },

    {
      name: 'Spaces',
      node: <RoomDecorationTab type={FurnitureType.Spaces} isActive={activeTab === 'Spaces'} />,
    },
  ];

  const onTabChange = (tabName: string) => {
    setActiveTab(tabName);
  };

  const openBackgroundEdit = useCallback(() => {
    UIKit.dropdown('#room-decoration-select').hide(0);
    dispatch(openRoomSettings({ initialTab: ROOM_SETTINGS_TABS.background }));
  }, [dispatch]);

  return (
    <Container>
      <Tabs uniqueId="room-decoration" tabs={tabs} onTabChange={onTabChange} />
      <BackgroundButton className="here-button-regular" onClick={openBackgroundEdit}>
        <StyledBackgroundIcon />
        Background
      </BackgroundButton>
    </Container>
  );
};

export default RoomDecorationMenu;

const Container = styled.div`
  position: relative;
`;

const BackgroundButton = styled.div`
  ${buttonMixin()}

  position: absolute;
  padding: 8px 12px;
  top: 4px;
  right: 0;
  gap: 4px;

  @media (max-width: 829px) {
    font-size: 12px;
    padding: 6px;
  }

  @media (max-width: 764px) {
    font-size: 8px;
    padding: 4px;
  }
`;

const StyledBackgroundIcon = styled(BackgroundIcon)`
  ${svgColorMixin('white')}

  @media (max-width: 764px) {
    height: 8px;
    width: 8px;
  }
`;
