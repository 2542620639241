import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PauseIcon from '../../icons/PauseIcon';

const PauseButton = ({ onClick }) => (
  <Button onClick={onClick} className="here-button-regular">
    <PauseIcon />
  </Button>
);

export default PauseButton;

PauseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const Button = styled.button`
  width: 60px;
  padding: 15px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
