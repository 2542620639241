import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { getSuggestedFriendsV2 } from '../store/friends/api';

type SuggestedFriend = {
  contactBookData?: {
    firstName: string;
    lastName: string;
  };
  profile: {
    userId: string;
    displayName: string;
    username: string;
    backgroundPhoto: {
      original: string;
      thumbnail: string;
    };
    onlineStatus: {
      online: string;
    };
  };
  suggestionReasons: string[];
};

type SuggestedFriends = {
  success: boolean;
  suggestedFriends: SuggestedFriend[];
  metadata: {
    base: string;
    prev: string;
    next: string;
  };
};

export enum SuggestionReasons {
  MUTUAL_FRIEND = 'MUTUAL_FRIEND',
  DOMAIN_SUGGESTION = 'DOMAIN_SUGGESTION',
  COMMON_ROOM_MEMBERSHIP = 'COMMON_ROOM_MEMBERSHIP',
  CONTACT_BOOK = 'CONTACT_BOOK',
  SAME_SCHOOL = 'SAME_SCHOOL',
}

export function useFriendsSuggestions() {
  const [suggestedFriends, setSuggestedFriends] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [isLoadingSuggestedFriends, setLoadingSuggestedFriends] = useState(false);

  useEffect(() => {
    (async function getFriends() {
      setLoadingSuggestedFriends(true);
      const { data } = (await getSuggestedFriendsV2()) as AxiosResponse<SuggestedFriends>;
      if (data.success && data.suggestedFriends.length) {
        setSuggestedFriends(data.suggestedFriends);
        if (data.metadata.next) {
          setNextPage(data.metadata.next);
        }
      }
      setLoadingSuggestedFriends(false);
    })();
  }, []);

  return { suggestedFriends, nextPage, isLoadingSuggestedFriends };
}
