import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectChatByChatId, selectChatMemberIdsByChatId } from '../../../store/messaging/selectors';
import ShareLinkIcon from '../../../../../assets/icons/hereos/chat/share-link.svg';
import { ellipsisTextMixin, svgColorMixin } from '../../../mixins';
import MembersAvatarsList from '../../../groups-lobby/main-area/MembersAvatarsList';
import { getGroupChatParams, track } from '../../../../util/analytics-util';
import { CHAT_COPY_LINK } from '../../../os/analytics';
import StarIcon from '../../../../../assets/icons/rounded-star.svg';

const MAX_VISIBLE_AVATARS = 7;

interface Props {
  creatorId: string;
  creatorName: string;
  chatId: string;
}

const NewChatSystemMessage: React.FC<Props> = ({ creatorId, creatorName, chatId }) => {
  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const memberIds = useSelector((state) => selectChatMemberIdsByChatId(state, chatId));

  const [urlCopied, setUrlCopied] = useState(false);
  const onCopyLinkClick = () => {
    window.navigator.clipboard.writeText(`${window.location.origin}/join/${chat.groupId}`);

    track(CHAT_COPY_LINK, getGroupChatParams({ chatId, source: 'chat created message' }));

    setUrlCopied(true);

    setTimeout(() => {
      setUrlCopied(false);
    }, 1000);
  };

  return (
    <MessageContainer>
      <IconContainer1>
        <StarIcon />
      </IconContainer1>
      <IconContainer2>
        <StarIcon />
      </IconContainer2>
      <CreatedText>{creatorName} Created</CreatedText>
      <ChatName>{chat?.groupName}</ChatName>
      <MembersText>Members</MembersText>
      <AvatarsListContainer>
        <MembersAvatarsList
          memberIds={[creatorId, ...memberIds]}
          avatarSize={33}
          maxVisibleAvatars={MAX_VISIBLE_AVATARS}
        />
      </AvatarsListContainer>
      <InviteFriendsText>Invite friends to join the fun:</InviteFriendsText>
      <InviteButton onClick={onCopyLinkClick}>
        {urlCopied ? (
          'Copied!'
        ) : (
          <>
            <ShareLinkIcon width={20} height={15.5} />
            Copy Link to Chat
          </>
        )}
      </InviteButton>
    </MessageContainer>
  );
};

export default NewChatSystemMessage;

const MessageContainer = styled.div`
  color: var(--primary-foreground);
  background: rgba(255, 255, 255, 0.2);
  text-align: center;

  width: 259px;
  border-radius: 20px;

  padding: 26.5px;
  margin: 15px auto 40px auto;

  position: relative;

  svg {
    ${svgColorMixin('var(--primary-foreground)')}
  }
`;

const IconContainer1 = styled.div`
  position: absolute;
  top: 70px;
  left: 17px;
  transform: rotate(45deg);
`;

const IconContainer2 = styled.div`
  position: absolute;
  right: 17px;
  top: 40px;
`;

const CreatedText = styled.div`
  font-size: 12px;
`;

const ChatName = styled.div`
  font-weight: bold;
  font-size: 20px;
  font-family: var(--primary-font, Inter);
  line-height: 24px;
  margin: 0 auto 18px auto;
  ${ellipsisTextMixin()}
`;

const MembersText = styled.div`
  font-size: 12px;
  opacity: 0.7;
`;

const AvatarsListContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 7px 0;
`;

const InviteFriendsText = styled.div`
  font-size: 14px;
  margin-top: 18px;
`;

const InviteButton = styled.div`
  background: var(--secondary-background);
  color: var(--secondary-foreground);

  transition: background 0.2s;

  svg {
    margin-left: 3px;
    ${svgColorMixin('var(--secondary-foreground)')}
  }

  &:hover {
    background-color: var(--secondary-background-alpha-50);
    color: var(--primary-foreground);

    ${svgColorMixin('var(--primary-foreground)')}
  }

  font-size: 16px;
  font-weight: bold;

  padding: 6.5px 17.5px;
  margin: 5px auto 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  cursor: pointer;

  svg {
    margin-right: 5px;
    ${svgColorMixin('var(--secondary-foreground)')}
  }
`;
