import React from 'react';
import ReactDOM from 'react-dom';

import ItemContainer from '../components/ItemContainer';
import roomRootStore from '../store/room-root-store';
import { WithRoomStore } from '../store/Wrapper';
import { resetVisibility, setForKits } from '../store/template/store';
import eventBus, { renderOnboardingTemplateSelectionScreenRequested } from '../../event-bus';

export async function renderLobbyTemplateSelectionScreen({ groupId }) {
  // TODO: we probably shouldn't be dispatching actions in render functions like below
  roomRootStore.dispatch(resetVisibility());
  ReactDOM.render(
    <WithRoomStore>
      <ItemContainer groupId={groupId} />
    </WithRoomStore>,
    document.getElementById('render-template-select')
  );
}

export function renderOnboardingTemplateSelectionScreen() {
  document.querySelector('.minimap-container').style.display = 'none';
  ReactDOM.render(
    <WithRoomStore>
      <ItemContainer isFirstTimeUser onItemSelected={null} />
    </WithRoomStore>,
    document.getElementById('template-screen')
  );
}

export function renderRoomKitSelectionScreen() {
  document.getElementById('sticker-select').classList.remove('uk-open');
  roomRootStore.dispatch(resetVisibility());
  roomRootStore.dispatch(setForKits());
  const addRoomKitContainerId = 'render-room-kit-select';
  ReactDOM.unmountComponentAtNode(document.getElementById(addRoomKitContainerId));
  ReactDOM.render(
    <WithRoomStore>
      <ItemContainer />
    </WithRoomStore>,
    document.getElementById(addRoomKitContainerId)
  );
}

eventBus.on(renderOnboardingTemplateSelectionScreenRequested, renderOnboardingTemplateSelectionScreen);
