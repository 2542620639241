import React from 'react';
import emojiData from '@emoji-mart/data';
import { EmojiMartData, categories, categoryToTitle } from '../constants';
import { SectionTitle } from '../shared-styles';
import EmojiButton from './EmojiButton';

interface Props {
  onSelect: (emoji: string) => void;
  setCategoryRef: (element: HTMLDivElement | null, index: number) => void;
}

const EmojiSections: React.FC<Props> = ({ onSelect, setCategoryRef }) => {
  // Need to ignore here because new emoji-mart/data requires updated types lib.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { categories: emojiCategories, emojis }: EmojiMartData = emojiData;

  return (
    <>
      {emojiCategories.map((category) => (
        <div key={category.id} id={category.id} ref={(el) => setCategoryRef(el, categories.indexOf(category.id))}>
          <SectionTitle>{categoryToTitle[category.id]}</SectionTitle>

          {category.emojis.map((emojiId) => {
            // only supports emoji versions 1-13
            if (emojis[emojiId].version < 14) {
              const emoji = emojis[emojiId].skins[0].native;
              return <EmojiButton key={emoji} emoji={emoji} onSelect={onSelect} />;
            }
            return null;
          })}
        </div>
      ))}
    </>
  );
};

export default EmojiSections;
