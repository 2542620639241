import React from 'react';
import PropTypes from 'prop-types';

import LottieAnimation from './LottieAnimation';
import animationData from '../../../assets/lottie/confetti-burst.json';

const ConfettiBurst = ({ onComplete }) => <LottieAnimation animationData={animationData} onComplete={onComplete} />;

ConfettiBurst.propTypes = {
  onComplete: PropTypes.func,
};

ConfettiBurst.defaultProps = {
  onComplete: () => {},
};

export default ConfettiBurst;
