import React from 'react';
import PropTypes from 'prop-types';

const ReportIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.78 0.981221C12.64 0.869718 12.46 0.869718 12.3 0.95892C12.28 0.95892 10.72 1.80634 9.52 1.80634C8.38 1.80634 7.6 1.40493 6.74 0.95892C5.82 0.49061 4.88 0 3.5 0C1.9 0 0.32 0.914319 0.26 0.95892C0.0999998 1.07042 0 1.24883 0 1.47183V4.41549V9.4331L0 18C0 18.5523 0.447715 19 1 19V19C1.55228 19 2 18.5523 2 18C2 15.1203 2 12.2336 2 9.348C2.45264 9.19734 2.97903 9.07629 3.5 9.07629C4.76 9.07629 5.58 9.5 6.42 9.94601C7.28 10.392 8.18 10.8603 9.5 10.8603C11.06 10.8603 12.66 9.94601 12.74 9.90141C12.9 9.81221 13 9.6115 13 9.4108V1.47183C13 1.27113 12.92 1.09272 12.78 0.981221Z" fill={color} />
  </svg>
);

export default ReportIcon;

ReportIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

ReportIcon.defaultProps = {
  width: '13',
  height: '19',
  color: 'black',
};
