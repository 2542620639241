import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import Loader from './Loader';
import { svgColorMixin } from '../mixins';

const SearchableContent = ({
  results,
  isLoading,
  entityName,
  attribution,
  searchTerm,
  height,
  inputDebounceInterval,
  columnsCount,
  classNames,
  onLoadNext,
  onSearchTermChanged,
  onResultsRequest,
}) => {
  const debouncedOnResultsRequest = useDebouncedCallback(onResultsRequest, inputDebounceInterval);
  const onKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter') {
        onResultsRequest();
        debouncedOnResultsRequest.cancel();
      }
    },
    [debouncedOnResultsRequest, onResultsRequest]
  );

  const onInputChange = useCallback(
    ({ target }) => {
      if (target.value) {
        debouncedOnResultsRequest.callback();
      } else {
        debouncedOnResultsRequest.cancel();
      }

      onSearchTermChanged(target.value);
    },
    [debouncedOnResultsRequest, onSearchTermChanged]
  );

  const onContainerScroll = useCallback(
    ({ target }) => {
      // There might be rounding errors, so decreasing by 1.
      const maxScrollHeight = target.scrollHeight - 1;
      if (!isLoading && target.offsetHeight + target.scrollTop >= maxScrollHeight) {
        onLoadNext();
      }
    },
    [onLoadNext, isLoading]
  );

  const noResultsPlaceholder = React.useMemo(() => {
    if (!isLoading && !results.length && !debouncedOnResultsRequest.pending()) {
      if (searchTerm) {
        return <NoResultsPlaceholderText>Sorry, no results! Try a different search term.</NoResultsPlaceholderText>;
      }

      return (
        <NoResultsPlaceholderText>
          Type anything in the input above to find images, or drag your own image in to upload one.
        </NoResultsPlaceholderText>
      );
    }

    return null;
  }, [results, isLoading, searchTerm, debouncedOnResultsRequest]);

  return (
    <div className={`${classNames.join(' ')} full-height`}>
      <InputContainer className="uk-inline">
        <span className="uk-form-icon uk-preserve" data-uk-icon="icon: search" />
        <Input
          type="text"
          className="uk-input themed-input"
          aria-label={`${entityName} search term`}
          value={searchTerm}
          onKeyDown={onKeyDown}
          onChange={onInputChange}
        />
        <AttributionContainer>{attribution}</AttributionContainer>
      </InputContainer>
      <ListContainer height={height} onScroll={onContainerScroll} className="dont-drag-me full-height-list">
        <List>
          {results.map((result) => (
            <ListItem key={result.key} columnsCount={columnsCount}>
              {result}
            </ListItem>
          ))}
        </List>
        {isLoading ? <Loader>Loading...</Loader> : null}
        {!results.length ? noResultsPlaceholder : null}
      </ListContainer>
    </div>
  );
};

SearchableContent.propTypes = {
  entityName: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.node).isRequired,
  attribution: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,

  columnsCount: PropTypes.number,
  inputDebounceInterval: PropTypes.number,
  height: PropTypes.string.isRequired,

  onLoadNext: PropTypes.func.isRequired,
  onResultsRequest: PropTypes.func,
  onSearchTermChanged: PropTypes.func.isRequired,

  classNames: PropTypes.arrayOf(PropTypes.string),
};

SearchableContent.defaultProps = {
  columnsCount: 2,
  inputDebounceInterval: 1000,
  attribution: null,
  onResultsRequest: () => {},
  classNames: [],
};

export default SearchableContent;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  ${svgColorMixin('var(--primary-foreground, black)')}
`;

const Input = styled.input`
  width: 100%;
`;

const AttributionContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 25%;
  pointer-events: none;
`;

const ListContainer = styled.div`
  overflow-y: scroll;
  height: ${({ height }) => height};
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 10px;
`;

const ListItem = styled.div`
  break-inside: avoid;
  width: ${({ columnsCount }) => 100 / columnsCount}%;
`;

const NoResultsPlaceholderText = styled.p`
  font-size: 16px;
  text-align: center;
  color: var(--primary-foreground, inherit);
`;
