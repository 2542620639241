import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useUserProfileData } from '../hooks/useUserProfileData';
import { whiteShimmerAnimationMixin, grayShimmerAnimationMixin } from '../mixins';
import UserListItemLayout from './UserListItemLayout.tsx';

const UserListItem = ({
  userId,
  userCTAsSection,
  ctaSectionHoverChange,
  onlineStatus,
  isLoading,
  withDarkBackground,
}) => {
  const { userProfile } = useUserProfileData(userId);
  const { displayName, username, avatar } = userProfile;

  return (
    <UserListItemLayout
      displayName={displayName}
      username={username}
      onlineStatus={onlineStatus}
      avatar={avatar}
      isLoading={isLoading}
      ctaSectionHoverChange={ctaSectionHoverChange}
      userCTAsSection={userCTAsSection}
      withDarkBackground={withDarkBackground}
    />
  );
};

export default UserListItem;

UserListItem.propTypes = {
  userId: PropTypes.string,
  userCTAsSection: PropTypes.node,
  ctaSectionHoverChange: PropTypes.bool,
  onlineStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  withDarkBackground: PropTypes.bool,
};

UserListItem.defaultProps = {
  userId: '',
  userCTAsSection: null,
  ctaSectionHoverChange: false,
  onlineStatus: '',
  isLoading: false,
  withDarkBackground: false,
};

export const ListItemContainer = styled.div`
  position: relative;
  border-radius: 10px;
  background: ${({ theme }) => theme?.userItemBackground || 'rgba(0, 0, 0, 0.05)'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme?.userItemInterval && `margin: ${theme.userItemInterval}px 0;`}

  height: ${({ theme }) => theme?.userItemHeight || 70}px;

  &:hover {
    background: ${({ theme }) => theme?.userItemBackgroundHover || 'rgba(0, 0, 0, 0.1)'};
  }

  @media (max-width: 615px) {
    padding-right: 0px;
  }

  ${({ isLoading, withDarkBackground }) =>
    isLoading && (withDarkBackground ? whiteShimmerAnimationMixin() : grayShimmerAnimationMixin())}
`;
