import UIkit from 'uikit';

import bus, { boardChanged } from '../../event-bus';
import twitter from '../../lib/twitter';
import log from '../../log';

import { getCurrentRoomURL } from '../../util';
import { checkUrlAlias, maxRoomNameLength } from '../../util/url-alias-validator';
import { onDiscordIntegrationLinkClick } from '../../util/external-apps-util';
import firebase, { api, db } from '../../firebase';
import { offSnapshot, onSnapshot } from '../../firestore-watcher';
import { onRoleChange, isRoomManagementAllowed } from '../../roles-management';
import store from '../../react/store/room-root-store';

import template from './invite.html';
import './invite.less';
import { isRoomPublic } from '../../util/public-rooms-util';
import { lockEveryElement } from '../../util/room-util';
import { setReportCategory, sendReport, showReportDetails, showReportOptions } from '../../util/report-util';

import { BUTTON_CLICK_FEEDBACK_DURATION } from '../../react/admin-templates/constants';
import { CopyInviteLinkContexts, COPY_INVITE_LINK } from '../../constants/analytics-events/rooms-events';
import { containsBadWords } from '../../util/bad-words-util';
import { renderFriendsInviteEmptyState, renderFriendsInviteList } from '../../react/friends/friends-invite/render';
import { selectHasFriends } from '../../react/store/friends/selectors';
import reduxAdapter from '../../react/store/redux-adapter';
import { track } from '../../util/analytics-util';
import {
  CLICKED_ADD_DISCORD_INTEGRATION_LINK,
  CLICKED_DISCORD_INVITE_LINK,
  DISCORD_INTEGRATION_LINK_SOURCES,
} from '../../constants/analytics-events/discord-events.ts';
import { checkIsElectron } from '../../util/platform-util';

let checkUrlInputDelayTimer;
let initialUrl;
let isListenersActivated = false;

const invitePrivateTextIconPath = 'images/icons/planet-gray.svg';
const invitePublicTextIconPath = 'images/icons/planet-purple.svg';

export default class Invite extends HTMLElement {
  constructor() {
    super();

    this.onBoardChanged = this.onBoardChanged.bind(this);
    this.onBoardUpdate = this.onBoardUpdate.bind(this);
    this.isUrlError = false;
    this.isTheSameUrl = true;
    this.currentBoardId = window.currentBoardId;
  }

  addSnapshotListeners(boardId) {
    onSnapshot(`/boards/${boardId}`, this.onBoardUpdate);
  }

  removeSnapshotListeners(boardId) {
    offSnapshot(`/boards/${boardId}`, this.onBoardUpdate);
  }

  onBoardUpdate(boardData) {
    this.accountForUsersFriends(boardData);

    this.tryShowDiscordSlackButtons(boardData, isRoomManagementAllowed());
    const inviteTextIcons = document.querySelectorAll('.invite-text-icon');
    inviteTextIcons.forEach((el) => {
      el.src = isRoomPublic(boardData) ? invitePublicTextIconPath : invitePrivateTextIconPath;
    });
    document.getElementById('board-publicize-checkbox-add-content').checked = boardData.membersCanAddContent !== false;
    document.getElementById('board-publicize-checkbox-use-cam').checked = boardData.membersCanUseCam !== false;
    document.getElementById('board-publicize-checkbox-use-mic').checked = boardData.membersCanUseMic !== false;
    document.getElementById('room-name-input-publicize').value = boardData.title;
    if (isRoomManagementAllowed() && boardData.joinMode !== 'waitlist') {
      this.integrations.forEach((el) => {
        el.style.display = 'block';
      });
    } else {
      this.integrations.forEach((el) => {
        el.style.display = 'none';
      });
    }
  }

  connectedCallback() {
    this.innerHTML = template;

    bus.on(boardChanged, this.onBoardChanged);
    if (typeof currentBoardId !== 'undefined' && this.currentBoardId !== null) {
      this.onBoardChanged(window.currentBoardId);
    }
    // Twitter widget might not work if the container is hidden,
    // so handling cases when we're inside a UIKit dropdown.
    // this.parentNode.addEventListener('show', () => this.updateTwitterButton());

    this.querySelector('.copy-share-link').addEventListener('click', this.copyShareLink.bind(this));

    const elUpdateUrlInput = this.querySelector('.invite-container .board-share-url');
    const elCopyUrlButton = this.querySelector('.invite-container .copy-share-link');
    const elUpdateUrlButton = this.querySelector('.invite-container .update-share-link');
    const elInutErrorText = this.querySelector('.invite-container .input-err');
    const elChangeUrlButton = this.querySelector('.invite-container .change-url-button-new');
    const elChangeUrlText = this.querySelector('.invite-container .change-url-wrapper');

    this.inviteFriendsSection = this.querySelector('.invite-friends-section');
    this.inviteFriendsEmptyState = this.querySelector('.invite-friends-empty-state');
    this.integrations = this.querySelectorAll('.integration');
    reduxAdapter.subscribe(() => this.accountForUsersFriends(window.currentBoardData));

    onRoleChange(() => {
      this.accountForUsersFriends(window.currentBoardData);

      const areIntegrationsAllowed = isRoomManagementAllowed() && window.currentBoardData.joinMode !== 'waitlist';
      this.integrations.forEach((el) => {
        el.style.display = areIntegrationsAllowed ? 'block' : 'none';
      });

      if (isRoomManagementAllowed()) {
        this.tryShowDiscordSlackButtons(window.currentBoardData, isRoomManagementAllowed());

        if (!window.currentBoardData.urlAlias) {
          elChangeUrlText.style.display = 'inline';
          if (!isListenersActivated) {
            elChangeUrlButton.addEventListener(
              'click',
              this.changeUrl.bind(this, elUpdateUrlInput, elCopyUrlButton, elUpdateUrlButton, elChangeUrlText)
            );

            elUpdateUrlInput.addEventListener(
              'keyup',
              this.inputKeyUp.bind(
                this,
                elUpdateUrlInput,
                elUpdateUrlButton,
                elInutErrorText,
                elCopyUrlButton,
                elChangeUrlText
              )
            );

            elUpdateUrlButton.addEventListener(
              'click',
              this.updateRoomUrl.bind(
                this,
                elUpdateUrlButton,
                elUpdateUrlInput,
                elInutErrorText,
                elCopyUrlButton,
                elChangeUrlText
              )
            );

            document.addEventListener(
              'mouseup',
              this.resetUrlUpdating.bind(
                this,
                elUpdateUrlInput,
                elUpdateUrlButton,
                elCopyUrlButton,
                elInutErrorText,
                elChangeUrlText
              )
            );

            isListenersActivated = true;
            elUpdateUrlInput.addEventListener('mouseup', (e) => {
              const s = initialUrl.match(/.*\.fm\//)[0];
              if (e.target.selectionStart < s.length) e.target.selectionStart = s.length;
            });
            elUpdateUrlInput.addEventListener('focus', (e) => {
              e.target.selectionStart = 'https://here.fm/'.length;
            });
          }
        }
      } else {
        elChangeUrlText.style.display = 'none';
      }
    });

    this.shareOnDiscordButton = this.querySelector('.share-on-discord-button');
    this.connectToDiscordButton = this.querySelector('.connect-to-discord-button');
    this.connectToDiscordSection = this.querySelector('.connect-to-discord');
    UIkit.util.on(document, 'show', '#publicize-share-room-details', () => {
      document.getElementById('publicize-room-description').focus();
    });
    document.getElementById('publicize-room-cancel-button-details').onclick = () => {
      UIkit.modal('#publicize-share-room-details').hide();
    };
    document.getElementById('publicize-room-cancel-button-notice').onclick = () => {
      UIkit.modal('#publicize-share-room-notice').hide();
    };
    document.getElementById('publicize-room-notice-confirm-button').onclick = () => {
      UIkit.modal('#publicize-share-room-notice').hide();
      const textareaEl = document.getElementById('publicize-room-description');
      textareaEl.classList.remove('error-field');
      const errorText = document.getElementById('publicize-error');
      errorText.style.display = 'none';
      const roomNameTextareaEl = document.getElementById('room-name-input-publicize');
      roomNameTextareaEl.classList.remove('error-field');
      const usernameErrorText = document.getElementById('room-name-publicize-error');
      usernameErrorText.style.display = 'none';
      UIkit.modal('#publicize-share-room-details').show();
    };

    const publicizeCheckboxMicSlider = document.getElementById('board-publicize-checkbox-use-mic');
    publicizeCheckboxMicSlider.addEventListener('change', (e) => {
      const { checked } = e.target;
      const el = document.getElementById('publicize-room-permission-microphone');
      if (checked) {
        el.querySelector('.option-section-image-slash').style.display = 'none';
        el.setAttribute('uk-tooltip', `title: Visitors can turn on their microphone`);
        el.querySelector('h6').classList.add('activated');
      } else {
        el.querySelector('.option-section-image-slash').style.display = 'block';
        el.setAttribute('uk-tooltip', `title: Visitors can't turn on their microphone`);
        el.querySelector('h6').classList.remove('activated');
      }
    });

    const publicizeCheckboxCamSlider = document.getElementById('board-publicize-checkbox-use-cam');
    publicizeCheckboxCamSlider.addEventListener('change', (e) => {
      const { checked } = e.target;
      const el = document.getElementById('publicize-room-permission-camera');
      if (checked) {
        el.querySelector('.option-section-image-slash').style.display = 'none';
        el.setAttribute('uk-tooltip', `title: Visitors can turn on their camera`);
        el.querySelector('h6').classList.add('activated');
      } else {
        el.querySelector('.option-section-image-slash').style.display = 'block';
        el.setAttribute('uk-tooltip', `title: Visitors can't turn on their camera`);
        el.querySelector('h6').classList.remove('activated');
      }
    });

    const publicizeCheckboxContentSlider = document.getElementById('board-publicize-checkbox-add-content');
    publicizeCheckboxContentSlider.addEventListener('change', (e) => {
      const { checked } = e.target;
      const el = document.getElementById('publicize-room-permission-add-content');
      if (checked) {
        el.querySelector('.option-section-image-slash').style.display = 'none';
        el.setAttribute('uk-tooltip', `title: Visitors can add / remove content`);
        el.querySelector('h6').classList.add('activated');
      } else {
        el.querySelector('.option-section-image-slash').style.display = 'block';
        el.setAttribute('uk-tooltip', `title: Visitors can't add / remove content`);
        el.querySelector('h6').classList.remove('activated');
      }
    });

    const publicizeCheckboxLockSlider = document.getElementById('board-publicize-checkbox-lock-content');
    publicizeCheckboxLockSlider.addEventListener('change', (e) => {
      const { checked } = e.target;
      const el = document.getElementById('publicize-room-permission-move-stuff');
      if (checked) {
        el.querySelector('.option-section-image-slash').style.display = 'none';
        el.setAttribute('uk-tooltip', `title: Lock position of content in the room`);
        el.querySelector('h6').classList.add('activated');
      } else {
        el.querySelector('.option-section-image-slash').style.display = 'block';
        el.setAttribute('uk-tooltip', `title: Don't lock position of content in the room`);
        el.querySelector('h6').classList.remove('activated');
      }
    });

    document.getElementById('publicize-room-details-confirm-button').onclick = () => {
      const textareaEl = document.getElementById('publicize-room-description');
      const publicShareMessage = textareaEl.value.trim();
      textareaEl.classList.remove('error-field');
      const errorText = document.getElementById('publicize-error');
      errorText.style.display = 'none';
      const roomNameTextareaEl = document.getElementById('room-name-input-publicize');
      const title = roomNameTextareaEl.value.trim();
      roomNameTextareaEl.classList.remove('error-field');
      const usernameErrorText = document.getElementById('room-name-publicize-error');
      usernameErrorText.style.display = 'none';

      let allFieldsValid = true;
      if (title === '') {
        this.highlightTextField(roomNameTextareaEl);
        usernameErrorText.textContent = 'Your room name cannot be empty';
        usernameErrorText.style.display = 'block';
        track('Share Public Room Failed', { reason: 'Room name is blank' });
        allFieldsValid = false;
      }
      if (title.length > maxRoomNameLength) {
        this.highlightTextField(roomNameTextareaEl);
        usernameErrorText.textContent = 'Your room name is too long - please shorten it and try again';
        usernameErrorText.style.display = 'block';
        track('Share Public Room Failed', { reason: 'Room name is too long' });
        allFieldsValid = false;
      }
      if (containsBadWords(title)) {
        this.highlightTextField(roomNameTextareaEl);
        usernameErrorText.textContent =
          'Your room name contains inappropriate language - Please keep public rooms friendly for all ages!';
        usernameErrorText.style.display = 'block';
        track('Share Public Room Failed', { reason: 'Room name contains bad words' });
        allFieldsValid = false;
      }
      if (publicShareMessage === '') {
        this.highlightTextField(textareaEl);
        errorText.style.display = 'block';
        errorText.textContent = 'Your invite message cannot be empty';
        track('Share Public Room Failed', { reason: 'Invite message is blank' });
        allFieldsValid = false;
      }
      if (containsBadWords(publicShareMessage)) {
        this.highlightTextField(textareaEl);
        errorText.textContent =
          'Your invite message contains inappropriate language - Please keep public rooms friendly for all ages!';
        errorText.style.display = 'block';
        track('Share Public Room Failed', { reason: 'Invite message contains bad words' });
        allFieldsValid = false;
      }

      if (!allFieldsValid) {
        return;
      }

      UIkit.modal('#publicize-share-room-details').hide();
      document.querySelector('#invite-share-button').click();
      const membersCanAddContent = document.getElementById('board-publicize-checkbox-add-content').checked;
      const membersCanUseCam = document.getElementById('board-publicize-checkbox-use-cam').checked;
      const membersCanUseMic = document.getElementById('board-publicize-checkbox-use-mic').checked;
      const lockContent = document.getElementById('board-publicize-checkbox-lock-content').checked;
      if (lockContent) lockEveryElement();
      db.collection('boards').doc(window.currentBoardId).update({
        title,
        membersCanAddContent,
        membersCanUseCam,
        membersCanUseMic,
        publicShareMessage,
        joinMode: 'open',
      });
      textareaEl.value = '';
      track('Make Room Public', {
        cameraEnabled: membersCanUseCam,
        micEnabled: membersCanUseMic,
        addContentEnabled: membersCanAddContent,
        lockContent,
      });
    };
    document.getElementById('publicize-room-ended-confirm-button').onclick = () => {
      UIkit.modal('#publicize-share-room-ended').hide();
    };
    document.querySelector('.publicize-twitter-button').onclick = () => {
      const url = document.querySelector('.board-share-url').value;
      const text = `Join me in ${window.currentBoardTitle} on @hereHQ!`;
      window.open(
        `http://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
        '',
        'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
      );
      track('Open Twitter Share');
    };
    document.getElementById('report-user-notice-cancel-button').onclick = () => {
      UIkit.modal('#report-user-notice').hide();
    };
    const reportDescriptionEl = document.getElementById('report-user-description');
    const reportErrorText = document.getElementById('report-user-error');
    document.getElementById('report-user-send-report').onclick = async () => {
      if (reportDescriptionEl.value !== '') await sendReport();
      else {
        reportDescriptionEl.classList.add('error-field');
        reportDescriptionEl.scrollIntoView(true);
        reportDescriptionEl.focus();
        reportErrorText.style.display = 'block';
      }
    };
    document.getElementById('report-user-back-button').onclick = () => {
      showReportOptions();
    };
    const reportOptions = document.querySelectorAll('.report-user-type');
    reportOptions.forEach((option) => {
      option.onclick = (e) => {
        setReportCategory(e.target.getAttribute('option'));
        showReportDetails();
        if (reportDescriptionEl.classList.contains('error-field')) reportDescriptionEl.classList.remove('error-field');
        reportErrorText.style.display = 'none';
      };
    });

    this.querySelector('.connect-to-discord .close').addEventListener('click', async () => {
      await db
        .doc(`memberships/${firebase.auth().currentUser.uid}/boards/${window.currentBoardId}`)
        .update({ hasDismissedDiscordIntegration: true });
      this.querySelector('.dismiss-discord-container').style.display = 'none';
    });
  }

  highlightTextField(textField) {
    textField.classList.add('error-field');
    textField.scrollIntoView(true);
    textField.focus();
  }

  changeUrl(elUpdateUrlInput, elCopyUrlButton, elUpdateUrlButton, elChangeUrlText, e) {
    e.preventDefault();
    elUpdateUrlInput.removeAttribute('readonly');
    elUpdateUrlInput.readOnly = false;
    elUpdateUrlInput.focus();
    elCopyUrlButton.style.display = 'none';
    elUpdateUrlButton.style.display = 'flex';
    elChangeUrlText.style.display = 'none';
  }

  async inputKeyUp(elUpdateUrlInput, elUpdateUrlButton, elInutErrorText, elCopyUrlButton, elChangeUrlText, e) {
    const s = initialUrl.match(/.*\.fm\//)[0];
    if (e.target.selectionStart < s.length) e.target.selectionStart = s.length;
    const valid = e.target.value.substring(0, s.length) === s; // check if text starts with 'here.fm/'
    if (!valid) e.target.value = s;

    const newUrl = e.target.value;

    if (e.key !== 'Enter') {
      const newName = newUrl.replace(/.*\.fm\//, '').toLowerCase();

      const currentUrl = window.location.href;
      const urlLocation = currentUrl.substr(currentUrl.lastIndexOf('/') + 1, currentUrl.length);

      elUpdateUrlButton.disabled = true;

      clearTimeout(checkUrlInputDelayTimer);

      if (urlLocation.toLowerCase() === newName) {
        elUpdateUrlInput.classList.remove('url-invalid');
        elUpdateUrlInput.classList.remove('url-valid');
        elUpdateUrlButton.disabled = false;
        elInutErrorText.innerText = '';
        this.isTheSameUrl = true;
        return;
      }

      this.isTheSameUrl = false;

      checkUrlInputDelayTimer = setTimeout(async () => {
        const urlAliasErrorMessage = await checkUrlAlias(newName);
        if (!newName || urlAliasErrorMessage) {
          elUpdateUrlButton.disabled = true;
          elUpdateUrlInput.classList.remove('url-valid');
          elUpdateUrlInput.classList.add('url-invalid');
          elInutErrorText.innerText = urlAliasErrorMessage;
          this.isUrlError = true;
        } else {
          elUpdateUrlButton.disabled = false;
          elUpdateUrlInput.classList.remove('url-invalid');
          elUpdateUrlInput.classList.add('url-valid');
          elInutErrorText.innerText = '';
          this.isUrlError = false;
        }
      }, 350);
    } else {
      this.updateRoomUrl(elUpdateUrlButton, elUpdateUrlInput, elInutErrorText, elCopyUrlButton, elChangeUrlText);
    }
  }

  async updateRoomUrl(elUpdateUrlButton, elUpdateUrlInput, elInutErrorText, elCopyUrlButton, elChangeUrlText) {
    const newUrl = elUpdateUrlInput.value;
    const newName = newUrl.replace(/.*\.fm\//, '').toLowerCase();

    if (this.isUrlError) {
      return;
    }

    if (this.isTheSameUrl) {
      this.closeEditMode(elUpdateUrlInput, elUpdateUrlButton, elCopyUrlButton, elInutErrorText, elChangeUrlText);
      return;
    }

    elUpdateUrlButton.disabled = true;

    const urlAliasErrorMessage = await checkUrlAlias(newName);
    if (!newName || urlAliasErrorMessage) {
      elUpdateUrlInput.classList.remove('url-valid');
      elUpdateUrlInput.classList.add('url-invalid');
      elInutErrorText.innerText = urlAliasErrorMessage;
      this.isUrlError = true;
      return;
    }
    elUpdateUrlInput.classList.remove('url-invalid');
    elUpdateUrlInput.classList.add('url-valid');
    elInutErrorText.innerText = '';
    this.isUrlError = false;

    try {
      await db.collection('boards').doc(window.currentBoardId).set(
        {
          urlAlias: newName,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );

      window.history.replaceState('string', 'Title', `/${newName}`);
      window.analytics.track('Room URL updated successfully');
      initialUrl = newUrl;

      this.closeEditMode(elUpdateUrlInput, elUpdateUrlButton, elCopyUrlButton, elInutErrorText, elChangeUrlText);
    } catch (e) {
      elUpdateUrlButton.disabled = false;
      elInutErrorText.innerText = e;
    }
  }

  closeEditMode(elUpdateUrlInput, elUpdateUrlButton, elCopyUrlButton, elInutErrorText, elChangeUrlText) {
    elUpdateUrlButton.disabled = false;
    elCopyUrlButton.style.display = 'flex';
    elUpdateUrlButton.style.display = 'none';
    elUpdateUrlInput.classList.remove('url-valid');
    elUpdateUrlInput.classList.remove('url-invalid');
    elInutErrorText.innerText = '';
    elUpdateUrlInput.readOnly = true;
    this.isUrlError = false;
    this.isTheSameUrl = true;
    elChangeUrlText.style.display = 'inline';
    window.getSelection().removeAllRanges();
  }

  resetUrlUpdating(elUpdateUrlInput, elUpdateUrlButton, elCopyUrlButton, elInutErrorText, elChangeUrlText, e) {
    if (!elUpdateUrlInput.readOnly && e.target !== elUpdateUrlButton && e.target !== elUpdateUrlInput) {
      this.closeEditMode(elUpdateUrlInput, elUpdateUrlButton, elCopyUrlButton, elInutErrorText, elChangeUrlText);
      elUpdateUrlInput.value = initialUrl;
    }
  }

  accountForUsersFriends(boardData) {
    const currentState = store.getState();
    const hasFriends = selectHasFriends(currentState);
    if (hasFriends) {
      this.querySelector('.invite-tab-no-friends-label-section').style.display = 'none';
      this.querySelector('.invite-title').style.display = 'block';
      this.inviteFriendsEmptyState.style.display = 'none';

      if (boardData.joinMode !== 'waitlist' || isRoomManagementAllowed()) {
        this.inviteFriendsSection.style.display = 'block';
        const friendsListContainer = this.querySelector('.invite-container .invite-friends-container');

        if (!this.isFriendsListRendered) {
          this.isFriendsListRendered = true;
          renderFriendsInviteList(friendsListContainer);
        }
      } else {
        this.inviteFriendsSection.style.display = 'none';
      }
    } else {
      this.inviteFriendsEmptyState.style.display = 'block';
      this.querySelector('.invite-tab-no-friends-label-section').style.display = 'block';
      this.querySelector('.invite-title').style.display = 'none';
      this.inviteFriendsSection.style.display = 'none';

      if (!this.isFriendsEmptyStateRendered) {
        this.isFriendsEmptyStateRendered = true;
        renderFriendsInviteEmptyState(this.inviteFriendsEmptyState);
      }
    }
  }

  disconnectedCallback() {
    bus.off(boardChanged, this.onBoardChanged);
  }

  async onBoardChanged(boardId) {
    this.removeSnapshotListeners(this.currentBoardId);
    this.currentBoardId = boardId;
    this.addSnapshotListeners(boardId);

    const boardDoc = await db.doc(`boards/${boardId}`).get();
    const boardData = { id: boardDoc.id, ...boardDoc.data() };

    const newBoardURL = getCurrentRoomURL(boardId, boardData.urlAlias);
    const boardShareElement = this.querySelector('.board-share-url');
    boardShareElement.readOnly = false;
    boardShareElement.value = newBoardURL;
    initialUrl = newBoardURL;
    boardShareElement.readOnly = true;

    this.tryShowDiscordSlackButtons(boardData, isRoomManagementAllowed());
    document.getElementById('board-publicize-checkbox-add-content').checked = boardData.membersCanAddContent !== false;
    document.getElementById('board-publicize-checkbox-use-cam').checked = boardData.membersCanUseCam !== false;
    document.getElementById('board-publicize-checkbox-use-mic').checked = boardData.membersCanUseMic !== false;

    if (firebase.auth().currentUser) {
      const membership = await db.doc(`memberships/${firebase.auth().currentUser.uid}/boards/${boardId}`).get();
      this.querySelector('.dismiss-discord-container').style.display =
        membership?.data()?.hasDismissedDiscordIntegration || checkIsElectron() ? 'none' : 'block';
    }
  }

  async tryShowDiscordSlackButtons(boardData, isRoomManagementAllowedNow) {
    let shareOnDiscordButtonText = '';

    if (boardData.discordConfig && boardData.discordConfig.serverName && boardData.discordConfig.channelName) {
      const { serverName, channelName } = boardData.discordConfig;
      shareOnDiscordButtonText = `Share on #${channelName} in ${serverName}`;
    }

    const onShareDiscordButtonClick = () => {
      track(CLICKED_DISCORD_INVITE_LINK);

      const { currentUser } = firebase.auth();
      const currentUserName = currentUser ? currentUser.displayName : 'Someone';
      const boardUrl = boardData.urlAlias ? `https://here.fm/${boardData.urlAlias}` : `https://here.fm/${boardData.id}`;
      const backgroundLink =
        typeof boardData.background === 'number'
          ? `https://here.fm/images/bg/${boardData.background}.jpg`
          : boardData.background;

      this.shareOnDiscordButton.querySelector('.discord-button-icon').style.display = 'none';
      this.shareOnDiscordButton.querySelector('span').textContent = 'Message sent!';
      this.shareOnDiscordButton.onclick = () => {}; // do nothing on click for BUTTON_CLICK_FEEDBACK_DURATION ms

      setTimeout(() => {
        this.shareOnDiscordButton.querySelector('.discord-button-icon').style.display = '';
        this.shareOnDiscordButton.querySelector('span').textContent = shareOnDiscordButtonText;
        this.shareOnDiscordButton.onclick = onShareDiscordButtonClick;
      }, BUTTON_CLICK_FEEDBACK_DURATION);

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accepts', 'application/json');
      fetch(`${api}/discord/message`, {
        headers,
        method: 'POST',
        body: JSON.stringify({
          boardId: boardData.id,
          messageContent: {
            tts: false,
            embed: {
              title: `${boardData.title} — Click to Join`,
              description: `${currentUserName} has invited you to join them in ${boardData.title}`,
              url: `${boardUrl}?utm_source=discord`,
              color: 7019491,
              thumbnail: {
                url: backgroundLink,
              },
              fields: [
                {
                  name: 'Room Link',
                  value: `${boardUrl}?utm_source=discord`,
                },
              ],
            },
          },
        }),
      });
    };

    switch (true) {
      // show 'Share on Discord' button, hide rest
      case boardData.discordConfig && boardData.discordConfig.channelName && !boardData.slackConfig: {
        this.shareOnDiscordButton.querySelector('span').textContent = shareOnDiscordButtonText;
        this.shareOnDiscordButton.style.display = 'flex';
        this.shareOnDiscordButton.onclick = onShareDiscordButtonClick;
        this.connectToDiscordSection.style.display = 'none';
        break;
      }

      // show 'Connect to ...' buttons
      case !boardData.discordConfig && !boardData.slackConfig && isRoomManagementAllowedNow: {
        this.shareOnDiscordButton.style.display = 'none';
        this.connectToDiscordSection.style.display = null;
        this.connectToDiscordButton.onclick = () => {
          onDiscordIntegrationLinkClick(boardData.id, {
            event: CLICKED_ADD_DISCORD_INTEGRATION_LINK,
            params: { source: DISCORD_INTEGRATION_LINK_SOURCES.ADD_FROM_INVITE_MODAL },
          });
        };

        break;
      }

      // hide all buttons
      default: {
        this.connectToDiscordSection.style.display = 'none';
        this.shareOnDiscordButton.style.display = 'none';
      }
    }
  }

  async updateTwitterButton() {
    const currentUrl = window.location.href;
    const urlBoardName = currentUrl.substr(currentUrl.lastIndexOf('/') + 1, currentUrl.length);
    const shareURL = `https://here.fm/${urlBoardName}`;
    const tweetContainer = this.querySelector('.tweet-container');

    if (tweetContainer.offsetParent === null) {
      // not visible
      return;
    }

    if (this.previousShareURL === shareURL) {
      return;
    }

    this.previousShareURL = shareURL;

    tweetContainer.innerHTML = '';
    const options = {
      size: 'large',
      text: 'Hanging out in Here',
    };

    try {
      const addButton = async () => {
        await twitter.widgets.createShareButton(shareURL, tweetContainer, options);
      };
      if (twitter.widgets) {
        await addButton();
      } else {
        await twitter.ready(addButton);
      }
    } catch (err) {
      log.error(`Error creating Twitter share button: ${err}`);
    }
  }

  copyShareLink(e) {
    const shareLinkButton = e.currentTarget;
    const boardShareUrl = this.querySelector('.board-share-url');
    window.navigator.clipboard.writeText(boardShareUrl.value).then(() => {
      shareLinkButton.innerHTML = 'Copied!';
      track(COPY_INVITE_LINK, { source: CopyInviteLinkContexts.INVITE_MODAL });
      setTimeout(() => {
        shareLinkButton.innerHTML =
          '<here-inline-svg src="/images/icons/copy-link.svg"></here-inline-svg> Copy Invite Link';
      }, BUTTON_CLICK_FEEDBACK_DURATION);
    });
  }
}
window.customElements.define('here-invite', Invite);
