export const oppositeSides = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
};

export const directionToSide = {
  up: 'top',
  down: 'bottom',
  left: 'left',
  right: 'right',
};
