import React, { useState } from 'react';
import styled from 'styled-components';

import { SettingsTabs, tabs } from './constants';
import RoomReactionsSettings from './reaction-settings';
import EmotesSettings from './emotes-settings';
// eslint-disable-next-line import/no-cycle
import ProfileSettings from './profile-settings';
import { MENU_TAB_CLICK, OsMenus } from '../../os/analytics';
import { track } from '../../../util/analytics-util';

const MyStuffMenu: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(SettingsTabs.PROFILE);

  const onTabClick = (tab: SettingsTabs) => {
    setCurrentTab(tab);
    track(MENU_TAB_CLICK, { menu: OsMenus.MY_STUFF, tab });
  };

  return (
    <Container>
      <Tabs>
        {tabs.map((tab) => (
          <Tab key={tab} isSelected={currentTab === tab} onClick={() => onTabClick(tab)}>
            {tab}
          </Tab>
        ))}
      </Tabs>
      {currentTab === SettingsTabs.PROFILE && <ProfileSettings />}
      {currentTab === SettingsTabs.ROOM_REACTIONS && <RoomReactionsSettings />}
      {currentTab === SettingsTabs.EMOTES && <EmotesSettings />}
    </Container>
  );
};

export default MyStuffMenu;

const Container = styled.div`
  background: var(--primary-background-alpha-90, rgba(18, 0, 45, 0.9));
  border-radius: 20px;
  padding: 0 30px 20px 30px;
  width: 650px;
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  font-size: 16px;
  color: white;
  font-weight: 500;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid white;
  text-align: center;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    `
    border-width: 2px;
    font-weight: 700;
  `}

  flex: 1;
  text-align: center;
`;
