import React, { useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/fp/isEqual';

// utils
import { openUserProfile } from '../../user-profile/utils';
import { getMessageTimestampData, getDividerData } from './msgTimeStampUtil.ts';

// components
import UserAvatar from '../../components/UserAvatar';
import { NewDayDivider } from './NewDayDivider';
import {
  AvatarContainer,
  ChatMessageContainer,
  ChatMessageMain,
  MessageContainer,
  MessageContainerHeader,
  MessageContent,
  MessageCreator,
  MessageDate,
  MessageGutter,
} from './chat-message-shared-styles.ts';
import { selectCurrentUserId } from '../../store/users/selectors';

const FeedMessage = ({
  createdAt,
  creator,
  previousCreator,
  previousMsgCreatedAt,
  nextCreator,
  nextMsgCreatedAt,
  messageContent,
}) => {
  const [userProfileOpen, setUserProfileOpen] = useState(false);

  const currentUserId = useSelector(selectCurrentUserId);
  const isSelf = creator === currentUserId;

  const messageGutter = useRef(null);
  const feedItem = useRef(null);
  const avatar = useRef(null);

  const { showTimestamp, msgTimestamp } = useMemo(
    () =>
      getMessageTimestampData({
        currMsgDate: createdAt,
        prevMsgDate: previousMsgCreatedAt,
        creator,
        previousCreator,
      }),
    [createdAt, creator, previousCreator, previousMsgCreatedAt]
  );
  const { showTimestamp: showTimestampNextMsg } = useMemo(
    () =>
      getMessageTimestampData({
        currMsgDate: nextMsgCreatedAt,
        prevMsgDate: createdAt,
        creator: nextCreator,
        previousCreator: creator,
      }),
    [createdAt, creator, nextCreator, nextMsgCreatedAt]
  );
  const { showNewDayDivider, dividerTimestamp } = getDividerData(createdAt, previousMsgCreatedAt);

  const onAvatarClick = () => {
    if (userProfileOpen) {
      setUserProfileOpen(false);
    } else {
      setUserProfileOpen(true);

      const feedItemCoordinates = feedItem.current.getBoundingClientRect();

      const topPosition = feedItemCoordinates.y;
      const leftPosition = feedItemCoordinates.x + messageGutter.current.offsetWidth + 10;

      openUserProfile({ userId: creator, topPosition, leftPosition });

      const onDocumentClick = (e) => {
        const isClickedOnAvatar = avatar.current?.contains(e.target);

        if (!isClickedOnAvatar) {
          setUserProfileOpen(false);
          document.removeEventListener('click', onDocumentClick);
        }
      };

      document.addEventListener('click', onDocumentClick);
    }
  };

  return (
    <>
      {showNewDayDivider && <NewDayDivider dividerTimestamp={dividerTimestamp} />}
      <ChatMessageContainer>
        <ChatMessageMain>
          {!isSelf && (
            <MessageGutter ref={messageGutter}>
              {(showTimestampNextMsg || !nextCreator) && (
                <AvatarContainer ref={avatar} onClick={onAvatarClick}>
                  <UserAvatar userId={creator} />
                </AvatarContainer>
              )}
            </MessageGutter>
          )}

          <MessageContainer isSelf={isSelf} showHeader={showTimestamp}>
            {showTimestamp ? (
              <MessageContainerHeader className="dont-drag-me">
                <MessageCreator>
                  <here-user-name userId={creator} />
                  <MessageDate>{msgTimestamp}</MessageDate>
                </MessageCreator>
              </MessageContainerHeader>
            ) : null}

            <MessageContent ref={feedItem}>{messageContent}</MessageContent>
          </MessageContainer>
        </ChatMessageMain>
      </ChatMessageContainer>
    </>
  );
};

FeedMessage.propTypes = {
  createdAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      createdAt: PropTypes.func,
    }),
    PropTypes.number, // Date.now() for local queue messages
  ]).isRequired,
  creator: PropTypes.string.isRequired,
  previousCreator: PropTypes.string,
  previousMsgCreatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      previousMsgCreatedAt: PropTypes.func,
    }),
    PropTypes.number, // Date.now() for local queue messages
  ]),
  nextCreator: PropTypes.string,
  nextMsgCreatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      nextMsgCreatedAt: PropTypes.func,
    }),
    PropTypes.number,
  ]),
  messageContent: PropTypes.node.isRequired,
};

FeedMessage.defaultProps = {
  previousCreator: null,
  previousMsgCreatedAt: null,
  nextCreator: null,
  nextMsgCreatedAt: null,
};

const propsAreEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(FeedMessage, propsAreEqual);
