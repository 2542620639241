import { createReducer } from '@reduxjs/toolkit';
import { initialState } from './init-state';

import {
  addFromEmotePackError,
  addFromEmotePackRequest,
  addFromEmotePackSuccess,
  fetchEmotesError,
  fetchEmotesRequest,
  fetchEmotesSuccess,
  fetchStickerPacksError,
  fetchStickerPacksRequest,
  fetchStickerPacksSuccess,
  removeEmoteError,
  removeEmoteRequest,
  removeEmoteSuccess,
} from './actions';

export const emotesReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchEmotesRequest, (state) => {
    state.isLoadingFetchEmotes = true;
    state.fetchEmotesError = '';
  });
  builder.addCase(fetchEmotesSuccess, (state, { payload }) => {
    state.isLoadingFetchEmotes = false;
    state.hereEmotePack = payload.hereEmotePack;
    state.emotes = payload.emotes;
  });
  builder.addCase(fetchEmotesError, (state, { payload }) => {
    state.isLoadingFetchEmotes = false;
    state.fetchEmotesError = payload.error;
  });
  builder.addCase(fetchStickerPacksRequest, (state) => {
    state.isLoadingFetchEmotes = true;
    state.fetchEmotesError = '';
  });
  builder.addCase(fetchStickerPacksSuccess, (state, { payload }) => {
    state.isLoadingFetchEmotes = false;
    state.stickerPacks = payload.stickerPacks;
  });
  builder.addCase(fetchStickerPacksError, (state, { payload }) => {
    state.isLoadingFetchEmotes = false;
    state.fetchEmotesError = payload.error;
  });
  builder.addCase(addFromEmotePackRequest, (state) => {
    state.isLoadingAddEmote = true;
    state.addEmoteError = '';
  });
  builder.addCase(addFromEmotePackSuccess, (state, { payload }) => {
    state.isLoadingAddEmote = false;
    state.emotes.push(payload.emote);
  });
  builder.addCase(addFromEmotePackError, (state, { payload }) => {
    state.isLoadingAddEmote = false;
    state.addEmoteError = payload.error;
  });
  builder.addCase(removeEmoteRequest, (state) => {
    state.isLoadingRemoveEmote = true;
    state.removeEmoteError = '';
  });
  builder.addCase(removeEmoteSuccess, (state, { payload }) => {
    state.isLoadingRemoveEmote = false;
    state.emotes = state.emotes.filter((emote) => emote.url !== payload.url);
  });
  builder.addCase(removeEmoteError, (state, { payload }) => {
    state.isLoadingRemoveEmote = false;
    state.removeEmoteError = payload.error;
  });
});
