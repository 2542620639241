import axios from 'axios';

import firebase, { db } from '../../../firebase';

import template from './help-tab.html';

import './help-tab.less';
import { track } from '../../../util/analytics-util';

class HelpTab extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    this.helpTabNotificationsCheckbox = this.querySelector('#help-tab-notifications-checkbox');
    this.helpTabNotificationsCheckbox.onclick = () => {
      this.setNotificationsEnabled(this.helpTabNotificationsCheckbox.checked);
    };
  }

  async onBoardUpdate() {
    const membershipSnapshot = await db
      .doc(`boards/${window.currentBoardId}/members/${firebase.auth().currentUser.uid}`)
      .get();

    const membership = membershipSnapshot.data();
    if (membership && membership.push && membership.push.userJoined === false) {
      this.helpTabNotificationsCheckbox.checked = false;
    }
  }

  setNotificationsEnabled(newState) {
    if (newState) {
      track('Enable Room Notifications');
      axios.post('/api/room/notifications/subscribe', {
        boardId: window.currentBoardId,
        uid: firebase.auth().currentUser.uid,
      });
    } else {
      track('Disable Room Notifications');
      axios.post('/api/room/notifications/unsubscribe', {
        boardId: window.currentBoardId,
        uid: firebase.auth().currentUser.uid,
      });
    }
  }
}

window.customElements.define('here-help-tab', HelpTab);
