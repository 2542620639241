import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRandomPrompt } from '../../util/user-card-util';

const FirstTimeEditModal = ({ onTitleUpdate, onPromptGiven }) => {
  const onInspireMeClick = useCallback(() => {
    onTitleUpdate(getRandomPrompt({ isFirstCard: true }));
    onPromptGiven();
  }, [onTitleUpdate, onPromptGiven]);

  return (
    <FirstTimeUserModal>
      <StyledTigerImg src="/images/misc/thinking-cat.gif" />
      <FirstTimeUserModalText>Don't know what to make? Get a prompt!</FirstTimeUserModalText>
      <FirstTimePromptButton onClick={onInspireMeClick}>Inspire Me</FirstTimePromptButton>
    </FirstTimeUserModal>
  );
};

export default FirstTimeEditModal;

FirstTimeEditModal.propTypes = {
  onTitleUpdate: PropTypes.func.isRequired,
  onPromptGiven: PropTypes.func,
};

FirstTimeEditModal.defaultProps = {
  onPromptGiven: () => {},
};

const FirstTimeUserModal = styled.div`
  margin-left: -360px;
  width: 300px;
  height: 245px;
  background: white;
  border-radius: 20px;
  padding: 40px;
`;

const StyledTigerImg = styled.img`
  width: 120px;
  display: block;
  margin: -100px auto 0 auto;
`;

const FirstTimeUserModalText = styled.div`
  color: #12002d;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  margin-top: 15px;
`;

const FirstTimePromptButton = styled.div`
  width: 100%;
  background: #6b1be3;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  padding: 18px;
  margin-top: 25px;
  cursor: pointer;
`;
