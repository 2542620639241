/**
 * Converts an { hour, min, sec } object into the total number of seconds
 *
 * @param {Object} { hour, min, sec }
 * @return {Number}
 */
export const getDisplayTimeInSecs = ({ hour, min, sec }) => {
  const secs = Number.parseInt(sec, 10);
  const minSecs = Number.parseInt(min, 10) * 60;
  const hourSecs = Number.parseInt(hour, 10) * 60 * 60;

  return secs + minSecs + hourSecs;
};

/**
 * Formats to double digit time
 * Pads time digit with leading '0' if necessary
 *
 * @param {Number} timeDigit
 * @return {String}
 */
export const formatTimeDigit = (timeDigit) => {
  if (timeDigit < 10) {
    timeDigit = `0${timeDigit}`;
  } else {
    timeDigit = `${timeDigit}`;
  }

  return timeDigit;
};

/**
 * Converts total seconds into breakdown of { hour, min, sec }
 *
 * @param {Number} totalSeconds
 * @return {Object} as { hour, min, sec }
 */
export const getDisplayTimeFromSecs = (totalSeconds) => {
  let seconds = Math.floor(totalSeconds);
  let minutes = Math.floor(seconds / 60).toFixed(0);
  const hours = Math.floor(minutes / 60).toFixed(0);
  minutes %= 60;
  seconds %= 60;

  return {
    hour: formatTimeDigit(hours),
    min: formatTimeDigit(minutes),
    sec: formatTimeDigit(seconds),
  };
};

export const getDisplayTimeFromSecsWithDays = (totalSeconds) => {
  const { hour, min, sec } = getDisplayTimeFromSecs(totalSeconds);
  return {
    day: formatTimeDigit(Math.floor(hour / 24)),
    hour: formatTimeDigit(hour % 24),
    min,
    sec,
  };
};

/**
 *
 * @param {number} totalSeconds
 * @returns {{ day: number, hour: number, min: number }}
 */
export const getRoomActiveDisplayTimeFromSecs = (totalSeconds) => {
  let seconds = Math.floor(totalSeconds);
  let min = Math.floor(seconds / 60).toFixed(0);
  let hour = Math.floor(min / 60).toFixed(0);
  const day = Math.floor(hour / 24).toFixed(0);
  hour %= 24;
  min %= 60;
  seconds %= 60;

  return {
    day,
    hour,
    min,
  };
};

/**
 * Converts total seconds to minutes
 *
 * @param {number} totalSeconds
 * @returns {String}
 */
export const getDisplayMinutesFromSecs = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);

  return minutes.toLocaleString();
};
