import { isNumber, mean } from 'lodash';

export class RollingAverage {
  size: number | undefined;

  meanRound: number;

  elements: number[] = [];

  constructor(size: number | undefined, meanRound = 2) {
    this.size = size;
    this.meanRound = meanRound;
  }

  length() {
    return this.elements.length;
  }

  push(element: number) {
    if (this.size && this.elements.length === this.size) {
      this.elements.shift();
    }
    this.elements.push(element);
  }

  get() {
    return this.elements.shift();
  }

  get mean() {
    const elements = this.elements.filter((e) => isNumber(e));
    return Number(mean(elements as number[]).toFixed(this.meanRound));
  }
}
