import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import {
  selectActiveReceiverId,
  selectAllChatMessages,
  selectDMChatByReceiverId,
  selectActiveGroupChatId,
} from '../../../store/messaging/selectors';
import { useLocalMessagesList } from '../../../hooks/useLocalMessagesList';
import { setActiveChatId, setActiveReceiverId } from '../../../store/messaging/reducer';
import DMWindow from './DMWindow';

const ChatWindowForMessageSidebar = ({ triggerContent }) => {
  const dispatch = useDispatch();
  const isLobby = window.location.pathname === '/l';

  const activeReceiverId = useSelector(selectActiveReceiverId);
  const activeGroupChatId = useSelector(selectActiveGroupChatId);
  const chatWindowPopoverOpen = !!(activeReceiverId || activeGroupChatId);

  // For DM:
  const DMWithYouAndReceiver = useSelector((state) => selectDMChatByReceiverId(state, activeReceiverId));

  const chatId = activeGroupChatId || DMWithYouAndReceiver[0]?.id;

  const messagesList = useSelector((state) => selectAllChatMessages(state, chatId));
  const { deleteAllLocalMessages } = useLocalMessagesList({ messagesList });

  const onClickOutside = (event) => {
    // don't close DMWindow on click outside if
    // 1) another chat was clicked;
    // 2) image/sticker preview was dismissed (clicked on image preview overlay);
    // close DMWindow on click outside othervise
    const { chatIconClicked, imagePreviewClicked } = event.composedPath().reduce(
      (acc, el) => {
        if (el?.classList?.contains('message-sidebar-avatar-container')) {
          return { ...acc, chatIconClicked: true };
        }

        if (el?.classList?.contains('chat-image-preview-modal')) {
          return { ...acc, imagePreviewClicked: true };
        }

        return acc;
      },
      { chatIconClicked: false, imagePreviewClicked: false }
    );

    if (chatIconClicked) {
      // delete local messages when the user switches to another chat, so they won't see them there
      deleteAllLocalMessages();
    }

    if (!chatIconClicked && !imagePreviewClicked && chatWindowPopoverOpen) {
      deleteAllLocalMessages();
      dispatch(setActiveChatId({ activeChatId: null }));
      dispatch(setActiveReceiverId({ activeReceiverId: null }));
    }
  };

  return (
    <Popover open={chatWindowPopoverOpen}>
      <PopoverAnchor asChild>{triggerContent}</PopoverAnchor>
      <PopoverContent
        side="right"
        align="start"
        sideOffset={10}
        // we need the offset to be higher in the lobby:
        alignOffset={isLobby ? -190 : -105}
        onPointerDownOutside={onClickOutside}
        portalled={false}
        // this is so that the DM window doesn't jump up and get cut off by
        // top of the screen in small screen sizes:
        avoidCollisions={false}
      >
        <PopoverArrow offset={103} />
        <DMWindow />
      </PopoverContent>
    </Popover>
  );
};

export default ChatWindowForMessageSidebar;

ChatWindowForMessageSidebar.propTypes = {
  triggerContent: PropTypes.node,
};

ChatWindowForMessageSidebar.defaultProps = {
  triggerContent: null,
};

const Popover = PopoverPrimitive.Root;
const PopoverAnchor = PopoverPrimitive.Anchor;
const PopoverContent = styled(PopoverPrimitive.Content)`
  width: 310px;
  outline: none;
`;

const PopoverArrow = styled(PopoverPrimitive.Arrow)`
  margin-left: 28px;
  fill: #12002e;
`;
