import React from 'react';
import PropTypes from 'prop-types';

const MobileIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V16C0 17.1046 0.895431 18 2 18H10C11.1046 18 12 17.1046 12 16V2C12 0.895431 11.1046 0 10 0H2ZM10 3H2V14H10V3ZM7 16C7 16.5523 6.55228 17 6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16ZM4.5 1C4.22386 1 4 1.22386 4 1.5C4 1.77614 4.22386 2 4.5 2H7.5C7.77614 2 8 1.77614 8 1.5C8 1.22386 7.77614 1 7.5 1H4.5Z"
      fill="#12002D"
    />
  </svg>
);

export default MobileIcon;

MobileIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

MobileIcon.defaultProps = {
  width: '12',
  height: '18',
};
