import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../../../store/Wrapper';
import ReportUserModal from './report-user/ReportUserModal';
import { getCreateElementById } from '../../../../util/dom-utils';
import BanUserModal from './ban-user/BanUserModal';

export const renderReportUserModal = (reportedUserId: string, chatId: string, reportedMessage?: string) => {
  const reportUserModalContainer = getCreateElementById('report-user-modal-container');
  reportUserModalContainer.classList.add('modal-root');

  const close = () => {
    ReactDOM.unmountComponentAtNode(reportUserModalContainer);
    reportUserModalContainer.remove();
  };

  ReactDOM.render(
    <WithRoomStore>
      <ReportUserModal userId={reportedUserId} chatId={chatId} reportedMessage={reportedMessage} close={close} />
    </WithRoomStore>,
    reportUserModalContainer
  );
};

export const renderBanUserModal = (bannedUserId: string, chatId: string) => {
  const banUserModalContainer = getCreateElementById('ban-user-modal-container');
  banUserModalContainer.classList.add('modal-root');

  const close = () => {
    ReactDOM.unmountComponentAtNode(banUserModalContainer);
    banUserModalContainer.remove();
  };

  ReactDOM.render(
    <WithRoomStore>
      <BanUserModal userId={bannedUserId} chatId={chatId} close={close} />
    </WithRoomStore>,
    banUserModalContainer
  );
};
