// hide types
export const HIDE_LOBBY_EXTENSION_PROMO = 'hideLobbyExtensionPromo';
export const HIDE_DISCORD_INTEGRATION_PROMO = 'hideDiscordIntegrationPromo';
export const HIDE_HIRING_PROMO = 'hideHiringPromo';
export const HIDE_DESKTOP_UPSELLS = 'hideDesktopUpsell';
export const HIDE_MOBILE_UPSELL = 'hideMobileUpsell';

export const HERE_CHROME_EXTENSION_ID = 'nngnogkbdoeejopcdkclmmkcbefggmed';
export const LOBBY_EXTENSION_PROMO_EXPIRATION = 1000 * 60 * 60 * 24 * 14;

// cta types
export const GOOGLE_CALENDAR_EXTENSION = 'GOOGLE_CALENDAR_EXTENSION';
export const DISCORD_INTEGRATION = 'DISCORD_INTEGRATION';
export const HIRING_PROMO = 'HIRING_PROMO';
export const DESKTOP_UPSELL = 'DESKTOP_UPSELL';
export const MOBILE_UPSELL = 'MOBILE_UPSELL';

// links
export const HERE_CHROME_EXTENSION_INSTALL_PAGE_LINK =
  'https://chrome.google.com/webstore/detail/here-calendar/nngnogkbdoeejopcdkclmmkcbefggmed';
