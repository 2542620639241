import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// store
import { addMessage, deleteMessage, fetchChat } from '../store/messaging/reducer';
import {
  selectCurrentUserFontColor,
  selectCurrentUserFontSize,
  selectCurrentUserFontFamily,
  selectCurrentUserMessageColor,
} from '../store/users/selectors';

// utils
import { track } from '../../util/analytics-util';

// constants
import { messageTypes } from '../../constants/chat-constants';
import { DELETE_DM } from '../../constants/analytics-events/dm-events';

export const useDMs = ({ chatId, activeReceiverId, setNewChatReceiverId = (__) => {} }) => {
  const dispatch = useDispatch();

  const fontColor = useSelector(selectCurrentUserFontColor);
  const fontSize = useSelector(selectCurrentUserFontSize);
  const fontFamily = useSelector(selectCurrentUserFontFamily);
  const messageColor = useSelector(selectCurrentUserMessageColor);

  const onSendMessage = useCallback(
    (message) => {
      dispatch(
        addMessage({
          chatId,
          type: messageTypes.TEXT,
          text: message,
          fontColor,
          messageColor,
          fontSize,
          fontFamily,
        })
      );
    },
    [dispatch, chatId, fontColor, messageColor, fontSize, fontFamily]
  );

  const onCreateChat = useCallback(
    (message) => {
      setNewChatReceiverId(null);
      dispatch(
        addMessage({
          type: messageTypes.TEXT,
          receiverId: activeReceiverId,
          text: message,
          fontColor,
          messageColor,
          fontSize,
          fontFamily,
          createChat: true,
        })
      );
    },
    [dispatch, activeReceiverId, fontColor, messageColor, fontSize, fontFamily, setNewChatReceiverId]
  );

  const onMessageUploadSuccess = useMemo(
    () =>
      chatId
        ? ({ imageUrl, storagePath }) =>
            dispatch(addMessage({ chatId, imageUrl, storagePath, type: messageTypes.IMAGE }))
        : ({ imageUrl, storagePath }) =>
            dispatch(
              addMessage({
                receiverId: activeReceiverId,
                imageUrl,
                storagePath,
                type: messageTypes.IMAGE,
                createChat: true,
              })
            ),
    [dispatch, chatId, activeReceiverId]
  );

  const onDeleteMessage = useCallback(
    (messageId) => {
      dispatch(deleteMessage({ messageId, chatId }));
      track(DELETE_DM);
    },
    [dispatch, chatId]
  );

  const loadMoreMessages = useCallback(() => {
    dispatch(fetchChat({ chatId, isPagination: true }));
  }, [dispatch, chatId]);

  return {
    onSendMessage,
    onCreateChat,
    onMessageUploadSuccess,
    onDeleteMessage,
    loadMoreMessages,
  };
};
