import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const scaleIn = keyframes`
  from {
    transform: scale(0.75);
  }
  
  to {
    transform: scale(1);
  }
  `;

export const createSlideDownAnimation = (startPx, finishPx = 0) => keyframes`
  0% {
    opacity: 0;
    transform: translateY(${startPx}px);

    button {
      display: none;
    }
  }
  
  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
    transform: translateY(${finishPx}px);

    button {
      display: block;
    }
  }
`;
