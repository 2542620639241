import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import BackgroundIcon from '../../../../../assets/icons/another-picture.svg';
import BubbleIcon from '../../../../../assets/icons/bubble.svg';
import EffectIcon from '../../../../../assets/icons/airy-fairy.svg';
import FrameIcon from '../../../../../assets/icons/frame.svg';
import TextIcon from '../../../../../assets/icons/text-two-letters.svg';
import SkinningBackgroundPicker from './background/SkinningBackgroundPicker';
import { noScrollbarMixin, svgColorMixin } from '../../../mixins';
import SkinningThemePicker from './theme/SkinningThemePicker';
import SkinningDecoratorPicker from './decorator/SkinningDecoratorPicker';
import SkinningFramePicker from './frame/SkinningFramePicker';
import SkinningParticlesPicker from './particles/SkinningParticlesPicker';

interface Props {
  chatId: string;
}

const ChatSkinningCustomization = ({ chatId }: Props) => {
  const tabs = useMemo(
    () => [
      { name: 'BG', icon: <BackgroundIcon />, content: <SkinningBackgroundPicker chatId={chatId} /> },
      { name: 'Bubble Style', icon: <BubbleIcon />, content: <SkinningDecoratorPicker chatId={chatId} /> },
      { name: 'Effects', icon: <EffectIcon />, content: <SkinningParticlesPicker chatId={chatId} /> },
      { name: 'Frames', icon: <FrameIcon />, content: <SkinningFramePicker chatId={chatId} /> },
      { name: 'Fonts', icon: <TextIcon />, content: <SkinningThemePicker chatId={chatId} /> },
    ],
    [chatId]
  );
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <Container>
      <TabsHeader>
        {tabs.map((tab, index) => (
          <TabIconContainer key={tab.name} onClick={() => setActiveTabIndex(index)} isActive={index === activeTabIndex}>
            {tab.icon}
            <TabName isActive={index === activeTabIndex}>{tab.name}</TabName>
          </TabIconContainer>
        ))}
      </TabsHeader>
      <TabsContent>{tabs[activeTabIndex].content}</TabsContent>
    </Container>
  );
};

export default ChatSkinningCustomization;

const Container = styled.div`
  background: var(--primary-background);
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0 16px 16px 16px;
`;

const TabsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 0;
`;

const TabIconContainer = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:hover svg {
    opacity: 0.75;
  }

  svg {
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    height: 100%;
    ${svgColorMixin('var(--primary-foreground)')}
  }
`;

const TabName = styled.div<{ isActive: boolean }>`
  color: var(--primary-foreground);
  font-size: 10px;
  font-weight: bold;
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
`;

const TabsContent = styled.div`
  padding: 16px;
  flex: 0 1 auto;
  overflow: auto;
  ${noScrollbarMixin()}
`;
