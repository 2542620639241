const badWords = [
  '$u!c!d3',
  '$u!c!de',
  '$uicide',
  'anal',
  'anus',
  'arse',
  'ass',
  'asshole',
  'ballsack',
  'blow job',
  'blowjob',
  'bollok',
  'boner',
  'boob',
  'butthole',
  'buttplug',
  'clit',
  'clitoris',
  'cock',
  'coon',
  'crap',
  'cum',
  'cunt',
  'dick',
  'dicks',
  'dildo',
  'dyke',
  'f u c k',
  'fag',
  'faggot',
  'feck',
  'felching',
  'fellate',
  'fellatio',
  'flange',
  'fuc',
  'fuck',
  'fucking',
  'fuk',
  'fudge packer',
  'fudgepacker',
  'horny',
  'h0rny',
  'incel',
  'jerkoff',
  'jizz',
  'kms',
  'kys',
  'knob end',
  'knobend',
  'labia',
  'masturbate',
  'moan',
  'moaning',
  'motherfucker',
  'muff',
  'naked',
  'nigga',
  'nigger',
  'niggers',
  'nigguh',
  'nipple',
  'niqqa',
  'niqqas',
  'niqqer',
  'nude',
  'nudes',
  'n00dz',
  'p3ni$',
  'p3nis',
  'penis',
  'piss',
  'p0rn',
  'porn',
  'porno',
  'pube',
  'puss',
  'pussy',
  'r@p3',
  'r@pe',
  'rap3',
  'rape',
  'scrotum',
  'sex',
  'sexx',
  'smegma',
  'suicide',
  'tiddies',
  'tit',
  'tits',
  'titties',
  'twat',
  'unalive',
  'vagina',
  'wank',
];

export default badWords;

export function containsBadWords(str) {
  return badWords.some((badWord) => new RegExp(`\\b${badWord.toLowerCase()}\\b`, 'i').test(str.toLowerCase()));
}
