export const gifPickerCallers = {
  FEED: 'FEED',
  TEXT_CHANNEL: 'TEXT_CHANNEL',
  DM: 'DM',
  TOOLBAR: 'TOOLBAR',
  ROOM_DECORATION_PICKER: 'ROOM_DECORATION_PICKER',
  USER_PAGE: 'USER_PAGE',
  ROOM_CHAT: 'ROOM_CHAT',
  WEB_LOBBY_ROOM_CHAT: 'WEB_LOBBY_ROOM_CHAT',
  MURAL: 'MURAL',
  REMIX: 'REMIX',
  OS: 'OS',
};
