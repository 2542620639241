import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import log from './log';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import BoardElement from './board-element';

import '../styles/timer.less';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

const timerStates = {
  Stopped: 'Stopped',
  Active: 'Active',
  Paused: 'Paused',
};

// Previously called TimerElement
export default class StopwatchElement extends BoardElement {
  constructor(elementId) {
    super(elementId);

    this.timerState = timerStates.Stopped;
    this.isCountingDown = false;
    this.actionTimestamp = -1;
    this.stoppedTime = '00:00:00';
    this.pausedTime = 0;
    this.intervalId = null;
    this.clockIntervalId = null;
  }

  elementDescription() {
    return 'stopwatch';
  }

  minSize() {
    return [180, 240];
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    this.updateVariables(elementDoc.data());
    this.updateObjectVisuals();
    this.addControls(elementDoc);
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    this.updateVariables(elementDoc.data());
    this.updateObjectVisuals();
    this.addControls(elementDoc);
    this.setClock();
  }

  // Required method
  getElement(elementDoc) {
    const timer = htmlToElement('<div class="timer-content"></div>');
    timer.appendChild(this.getUI());

    return wrapElement(timer, elementDoc);
  }

  teardown() {
    clearInterval(this.clockIntervalId);
    clearInterval(this.intervalId);
  }

  // Statics
  static async addElement() {
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'TimerElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 110) + window.innerHeight / 2
        ),
        size: [432, 345],
        zIndex: window.getFrontZIndex(),
        creator: firebase.auth().currentUser.uid,
        timerState: timerStates.Stopped,
        isCountingDown: false,
        actionTimestamp: -1,
        stoppedTime: '00:00:00',
        intervalId: null,
        clockIntervalId: null,
      })
      .then(() => {
        addSystemMessage('added a stopwatch');
        track(ADD_ELEMENT, { element: ELEMENT_TYPES.TIMER, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
      })
      .catch((error) => {
        log.error(`Something bad happened creating StopwatchElement: ${error}`);
      });
  }

  /* ////////////////////////////////////////////////////////////////////////////// */

  getNowInSeconds() {
    return new Date().getTime() / 1000;
  }

  startTimer() {
    this.actionTimestamp = this.getNowInSeconds();
    this.pausedTime = 0;
    this.timerState = timerStates.Active;
    this.emitUpdate();
  }

  resumeTimer() {
    this.timerState = timerStates.Active;
    this.actionTimestamp = this.getNowInSeconds();
    this.updateTimer();
    this.emitUpdate();
  }

  pauseTimer() {
    this.stoppedTime = this.getTimerTime();
    this.pausedTime = this.getTimerTimeInSeconds();
    this.timerState = timerStates.Paused;
    this.emitUpdate();
    this.setTimer(this.stoppedTime);
  }

  stopTimer() {
    this.stoppedTime = this.getTimerTime();
    this.timerState = timerStates.Stopped;
    this.actionTimestamp = -1;
    this.pausedTime = 0;
    this.emitUpdate();
    this.setTimer('00:00:00');
  }

  getTime() {
    const currentDatetime = new Date();
    let hours = currentDatetime.getHours();
    let minutes = currentDatetime.getMinutes();
    let seconds = currentDatetime.getSeconds();
    let partOfDay = '';
    if (hours >= 12 && hours !== 24) {
      partOfDay = 'PM';
    } else {
      partOfDay = 'AM';
    }
    if (hours > 12 && hours !== 24) {
      hours -= 12;
    } else if (hours === 24) {
      hours = 1;
    }
    if (minutes < 10) {
      minutes = this.formatTime(minutes);
    }
    if (seconds < 10) {
      seconds = this.formatTime(seconds);
    }
    const formattedTime = `${hours}:${minutes} ${partOfDay}`;
    return formattedTime;
  }

  formatTime(timeDigit) {
    if (timeDigit < 10) {
      timeDigit = `0${timeDigit}`;
    }
    return timeDigit;
  }

  // Set the clock time
  setTime(newTime) {
    if (!document.getElementById(`element-${this.elementId}`)) {
      log.debug('clearing clockinterval');
      clearInterval(this.clockIntervalId);
      return;
    }
    const el = document.getElementById(`element-${this.elementId}`);
    if (el.querySelector(`${this.getFullElementId('#clock-time')}`) !== undefined) {
      el.querySelector(`${this.getFullElementId('#clock-time')}`).innerHTML = newTime;
    }
  }

  // Set the stopwatch timer
  setTimer(newTime) {
    if (!document.getElementById(`element-${this.elementId}`)) {
      log.debug('clearing interval');
      clearInterval(this.intervalId);
      return;
    }
    const el = document.getElementById(`element-${this.elementId}`);
    if (el.querySelector(`${this.getFullElementId('#timer')}`) !== undefined) {
      el.querySelector(`${this.getFullElementId('#timer')}`).html = newTime;
    }
  }

  getUI() {
    return htmlToElement(`
      <div class="timer-wrapper">
        <div class="timer-header">
          <here-inline-svg class="header-icon" src="images/icons/stopwatch.svg"></here-inline-svg>
          Stopwatch
        </div>
        <div class="timer-cell">
          <small class="timer-clock" id="${this.getFullElementId('clock-time')}">
            ${this.getTime()}
          </small>
          <h3 class="timer-stopwatch-time" id="${this.getFullElementId('timer')}">
            ${this.getTimerTime()}
          </h3>
          <button
            uk-tooltip="title: Start"
            class="timer-ui-button"
            id="${this.getFullElementId('timer-play')}"
          >
            <here-inline-svg src="images/icons/play.svg"></here-inline-svg>
          </button>
          <button
            uk-tooltip="title: Reset"
            class="timer-ui-button"
            id="${this.getFullElementId('timer-stop')}"
          >
            <here-inline-svg src="images/icons/refresh.svg"></here-inline-svg>
          </button>
        </div>
      </div>
     `);
  }

  getFullElementId(id) {
    return `${id}-${this.elementId}`;
  }

  // Handler-specific:
  updateVariables(elementData) {
    this.timerState = elementData.timerState;
    this.isCountingDown = elementData.isCountingDown;
    this.actionTimestamp = elementData.actionTimestamp;
    this.stoppedTime = elementData.stoppedTime;
    this.pausedTime = elementData.pausedTime;
  }

  setClock() {
    if (this.clockIntervalId !== undefined) clearInterval(this.clockIntervalId);
    log.debug(this.clockIntervalId);
    this.clockIntervalId = setInterval(() => {
      this.setTime(this.getTime());
    }, 30000);
  }

  updateObjectVisuals() {
    const el = document.getElementById(`element-${this.elementId}`);
    const content = el.querySelector('.timer-content');
    content.innerHTML = '';
    content.appendChild(this.getUI());

    switch (this.timerState) {
      case timerStates.Stopped:
        this.stopTimer();
        this.setTimer('00:00:00');
        if (el.querySelector('#play-button') !== undefined) {
          const button = el.querySelector(`${this.getFullElementId('#timer-play')}`);
          button.querySelector('here-inline-svg').src = 'images/icons/play.svg';
          button.setAttribute('uk-tooltip', 'title: Play');
          el.querySelector(`${this.getFullElementId('#timer-play')}`).addEventListener('click', (__e) => {
            this.startTimer();
          });
        }
        break;

      case timerStates.Paused:
        this.setTimer(this.stoppedTime);
        if (el.querySelector(`${this.getFullElementId('#timer-play')}`) !== undefined) {
          const button = el.querySelector(`${this.getFullElementId('#timer-play')}`);
          button.querySelector('here-inline-svg').src = 'images/icons/play.svg';
          button.setAttribute('uk-tooltip', 'title: Resume');
          el.querySelector(`${this.getFullElementId('#timer-play')}`).addEventListener('click', (__e) => {
            this.resumeTimer();
          });
        }
        break;

      case timerStates.Active:
        if (this.intervalId !== undefined) clearInterval(this.intervalId);
        this.intervalId = setInterval(() => {
          this.updateTimer();
        }, 1000);
        if (el.querySelector(`${this.getFullElementId('#timer-play')}`) !== undefined) {
          const button = el.querySelector(`${this.getFullElementId('#timer-play')}`);
          button.querySelector('here-inline-svg').src = 'images/icons/pause.svg';
          button.setAttribute('uk-tooltip', 'title: Pause');
          el.querySelector(`${this.getFullElementId('#timer-play')}`).addEventListener('click', (__e) => {
            this.pauseTimer();
          });
        }
        break;

      default:
        log.debug('No state found');
    }
  }

  addControls(elementDoc) {
    const el = document.getElementById(`element-${elementDoc.id}`);
    if (el.querySelector(`${this.getFullElementId('#timer-stop')}`) !== undefined) {
      el.querySelector(`${this.getFullElementId('#timer-stop')}`).addEventListener('click', (__e) => {
        this.stopTimer();
      });
    }
  }

  updateTimer() {
    const el = document.getElementById(`element-${this.elementId}`);
    if (el) {
      const content = el.querySelector(`${this.getFullElementId('#timer')}`);
      if (this.timerState === timerStates.Active) {
        content.innerHTML = this.getTimerTime();
      }
    }
  }

  getTimerTimeInSeconds() {
    const time = Math.floor(this.getNowInSeconds() - this.actionTimestamp).toFixed(0);
    return parseInt(time, 10) + parseInt(this.pausedTime, 10);
  }

  getTimerTime() {
    if (this.timerState === timerStates.Stopped) {
      return '00:00:00';
    }
    if (this.timerState === timerStates.Paused) {
      return this.stoppedTime;
    }
    let seconds = this.getTimerTimeInSeconds();
    let minutes = Math.floor(seconds / 60).toFixed(0);
    let hours = Math.floor(minutes / 60).toFixed(0);
    minutes %= 60;
    minutes = this.formatTime(minutes);
    hours = this.formatTime(hours);
    seconds %= 60;
    seconds = this.formatTime(seconds);
    return `${hours}:${minutes}:${seconds}`;
  }

  emitUpdate() {
    // Trying to update before the room is loaded, possibly in a preload state.
    // TODO really, we probably should not be refreshing the data in this element
    // every time it is loaded. Should fix this.
    if (!window.currentBoardId) {
      return;
    }
    db.collection('boards').doc(window.currentBoardId).collection('elements').doc(this.elementId).update({
      timerState: this.timerState,
      isCountingDown: this.isCountingDown,
      actionTimestamp: this.actionTimestamp,
      stoppedTime: this.stoppedTime,
      pausedTime: this.pausedTime,
    });
  }
}
