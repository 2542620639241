import { useCallback, useEffect, useRef, useState } from 'react';
import { StickerPacks } from '../../definitions/emotes';

export const useStickerPacksNav = () => {
  const [current, setCurrent] = useState<string>(StickerPacks.HERE);
  const packs = Object.values(StickerPacks);

  const [refsReady, setRefsReady] = useState(false);
  const packRefs = useRef<HTMLDivElement[]>([]);
  const observer = useRef(null);

  const setPackRef = (element: HTMLDivElement | null, index: number) => {
    if (element) {
      packRefs.current[index] = element;
      if (packRefs.current?.length === packs.length) {
        setRefsReady(true);
      }
    }
  };

  // When refs are all set, set observers
  useEffect(() => {
    if (refsReady) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setCurrent(entry.target.id);
            }
          });
        },
        { threshold: 0.4 }
      );

      packRefs.current.forEach((div) => {
        if (observer.current) {
          observer.current?.observe(div);
        }
      });

      return () => {
        observer.current?.disconnect();
      };
    }
    return () => {};
  }, [refsReady]);

  // Scrolling to section functionality for nav item clicks:

  const scrollToSection = useCallback((index: number) => {
    const ref = packRefs.current[index];
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return { current, setPackRef, scrollToSection };
};
