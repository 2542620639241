import { htmlToElement } from '../util';
import { track } from './analytics-util';

export function isFullscreenAvailable() {
  return (
    document.fullscreenEnabled ||
    document.mozFullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled
  );
}

/**
 *
 * Makes passed element fullscreen
 *
 * @param {Element} element
 */
export function makeElementFullscreen(element) {
  // Supports most browsers and their versions.
  const requestMethod =
    element.requestFullScreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    element.msRequestFullScreen;

  if (requestMethod) {
    // Native full screen.
    requestMethod.call(element);
  } else if (typeof window.ActiveXObject !== 'undefined') {
    // Older IE.
    const wscript = new window.ActiveXObject('WScript.Shell');
    if (wscript !== null) {
      wscript.SendKeys('{F11}');
    }
  }
}

export function createFullscreenMenuOption(element, analyticsText = 'Fullscreen') {
  if (isFullscreenAvailable()) {
    const goFullscreenOption = htmlToElement(
      `<button class="options-menu-option separated-option">Fullscreen</button>`
    );

    goFullscreenOption.addEventListener('click', () => {
      track(analyticsText);
      makeElementFullscreen(element);
    });

    return goFullscreenOption;
  }

  return null;
}
