import { EmoteType } from '../../definitions/emotes';

export const FREQ_USED_STORAGE_KEY = 'here-emoji-picker-freq-used';
export const DEFAULT_FREQ_USED = ['👍', '😀', '😘', '😍', '😆', '😜', '😅', '😂', '🥰'];

// categories here must be in order as they appear in EmojiPicker:
export enum Categories {
  FREQ_USED = 'freq_used',
  MY_STUFF = 'my_stuff',
  PEOPLE = 'people',
  NATURE = 'nature',
  FOODS = 'foods',
  ACTIVITY = 'activity',
  PLACES = 'places',
  OBJECTS = 'objects',
  SYMBOLS = 'symbols',
  FLAGS = 'flags',
}

export const categories = Object.values(Categories);

export const categoryToTitle = {
  [Categories.FREQ_USED]: 'Frequently Used',
  [Categories.MY_STUFF]: 'My Stuff',
  [Categories.PEOPLE]: 'People & Smileys',
  [Categories.NATURE]: 'Animals & Nature',
  [Categories.FOODS]: 'Food & Drink',
  [Categories.ACTIVITY]: 'Activity',
  [Categories.PLACES]: 'Travel & Places',
  [Categories.OBJECTS]: 'Objects',
  [Categories.SYMBOLS]: 'Symbols',
  [Categories.FLAGS]: 'Flags',
};

export interface Emoji {
  emoji?: string;
  emoteUrl?: string;
  emoteType?: EmoteType;
  pack?: string;
}

interface EmojiMartCategory {
  id: Categories;
  emojis: string[];
}

interface EmojiMartEmoji {
  id: string;
  version: number;
  skins: { native: string }[];
}

export interface EmojiMartData {
  categories: EmojiMartCategory[];
  emojis: { [key: string]: EmojiMartEmoji };
}
