import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db } from '../../firebase';
import { selectCurrentUserId } from '../store/users/selectors';
import { markUserPageAsEdited } from './api';

export function useMarkUserPageAsEdited(cardId) {
  const currentUserId = useSelector(selectCurrentUserId);

  // Subscribe to changes to board elements:
  useEffect(() => {
    let isFirstRender = true;

    const unsubscribeToElements = db
      .collection(`/boards/${cardId}/elements`)
      .onSnapshot({ includeMetadataChanges: true }, () => {
        // we only want to trigger updating lastEditedUserPage for changes made after render
        if (!isFirstRender) {
          markUserPageAsEdited(currentUserId);
        } else {
          isFirstRender = false;
        }
      });

    return () => unsubscribeToElements();
  }, [cardId, currentUserId]);

  // Subscribe to changes to board:
  useEffect(() => {
    let isFirstRender = true;

    const unsubscribeToBoard = db.doc(`/boards/${cardId}`).onSnapshot(() => {
      // we only want to trigger updating lastEditedUserPage for changes made after render
      if (!isFirstRender) {
        markUserPageAsEdited(currentUserId);
      } else {
        isFirstRender = false;
      }
    });

    return () => unsubscribeToBoard();
  }, [cardId, currentUserId]);
}
