import { getRandomNumberInRange } from '../util';

export function hexToRgb(hex) {
  return hex
    .substring(1)
    .match(/.{1,2}/g)
    .map((x) => parseInt(x, 16))
    .join(', ');
}

export function hexToRgbArray(hex) {
  const rgbArray = hex
    .substring(1)
    .match(/.{1,2}/g)
    .map((x) => parseInt(x, 16));
  return rgbArray;
}

export function invertHexColor(hex, { preserveOpacity } = { preserveOpacity: true }) {
  if (!hex.startsWith('#') || (hex.length !== 7 && hex.length !== 9)) {
    throw new Error(`Wrong hex color format: ${hex}`);
  }

  const colorBase = hex.substring(1, 7);
  // eslint-disable-next-line no-bitwise
  const invertedColor = `#${(Number(`0x1${colorBase}`) ^ 0xffffff).toString(16).substring(1).toUpperCase()}`;
  const opacityPart = preserveOpacity ? hex.substring(7, 9) : '';
  return invertedColor + opacityPart;
}

export function getRandomHexColor() {
  return `#${[...Array(3)]
    .map(() => Math.floor(getRandomNumberInRange(0, 0xff)).toString(16).padStart(2, '0'))
    .join('')}`;
}
