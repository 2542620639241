import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SectionWrapper = ({ children, title, description }) => (
  <div>
    <Title>{title}</Title>
    <Description>{description}</Description>
    <Content>{children}</Content>
  </div>
);

export default SectionWrapper;

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-foreground, black);
  margin-bottom: 6px;
`;

const Description = styled.p`
  font-size: 14px;
  margin: 0;
  color: var(--primary-foreground, black);
`;

const Content = styled.div`
  margin-top: 28px;
`;
