export const STARTED_PLAYER = 'Media Player Started'; // params: target ('YouTube' or 'Twitch')

export const YOUTUBE_SUGGESTION_VIDEO_CLICKED = 'Youtube suggestion video clicked';

export const SCREENSHARE_INSTRUCTION_SHOWN = 'Media Player Screenshare Instruction Shown';
export const SCREENSHARE_INSTRUCTION_CLOSE_BUTTON_CLICKED = 'Media Player Screenshare Instruction Close Button Clicked';
export const SCREENSHARE_INSTRUCTION_QUESTION_BUTTON_CLICKED =
  'Media Player Screenshare Instruction Question Button Clicked';
export const SCREENSHARE_INSTRUCTION_START_BUTTON_CLICKED =
  'Media Player Screenshare Instrcuction Start Button Clicked';
