import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

// store
import { sendInvites } from '../../../store/new-room/actions';
import { selectFriendsInOrderOfOnlineStatus } from '../../../store/friends/selectors';
import { selectNewRoomCustomUrl, selectNewRoomHasCustomUrl, selectNewRoomId } from '../../../store/new-room/selectors';

// components
import { ellipsisTextMixin, svgColorMixin } from '../../../mixins';
import FriendsInviteButton, { FriendContainer, FriendName } from '../../../friends/friends-invite/FriendsInviteButton';
import AddFriendsToRoomModal from '../AddFriendsToRoomModal';
import FriendsListEmptyState from '../FriendsListEmptyState';
import { ButtonContainer, CreateRoomButton } from '../shared-styles';

// utils
import { track } from '../../../../util/analytics-util';

// constants
import { COPY_INVITE_LINK, CopyInviteLinkContexts } from '../../../../constants/analytics-events/rooms-events';

// icons
import SmileyIcon from '../../../../../assets/icons/smiley.svg';
import SearchIcon from '../../../../../assets/icons/search.svg';
import ChevronLeftIcon from '../../../../../assets/icons/arrows/pointed-chevron-left.svg';
import CopyRoomLinkIcon from '../../../../../assets/icons/room-options/copy-room-link.svg';

const BUTTON_CLICK_FEEDBACK_DURATION = 1500;

const RoomCreationInviteFriends = () => {
  const dispatch = useDispatch();

  const friendsToInviteListRef = useRef<HTMLDivElement>(null);

  const newRoomId = useSelector(selectNewRoomId);
  const newRoomHasCustomUrl = useSelector(selectNewRoomHasCustomUrl);
  const newRoomCustomUrl = useSelector(selectNewRoomCustomUrl);
  const friendsList = useSelector(selectFriendsInOrderOfOnlineStatus);

  const [copyLinkButtonText, setCopyLinkButtonText] = useState('Copy Link');
  const [inviteFriendsModalOpen, setInviteFriendsModalOpen] = useState(false);
  const [invitesSent, setInvitesSent] = useState<{ [key: string]: boolean }>({});

  const boardShareUrl = `https://here.fm/${newRoomHasCustomUrl ? newRoomCustomUrl : newRoomId}`;

  const onCopyLinkButtonClick = async () => {
    await window.navigator.clipboard.writeText(boardShareUrl);
    setCopyLinkButtonText('Copied!');
    track(COPY_INVITE_LINK, { source: CopyInviteLinkContexts.CREATE_ROOM } );
    setTimeout(() => {
      setCopyLinkButtonText('Copy Link');
    }, BUTTON_CLICK_FEEDBACK_DURATION);
  };

  const onFriendInviteClick = (userId: string) => {
    setInvitesSent((prev) => ({ ...prev, [userId]: true }));
  };

  const toggleFriendInvite = (userId: string) => {
    setInvitesSent((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };

  const onMouseScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY) {
      friendsToInviteListRef.current.scrollLeft += e.deltaY;
    }
  };

  const onContinueToRoomClick = useCallback(() => {
    dispatch(sendInvites({ userIds: Object.keys(invitesSent).filter((userId) => invitesSent[userId]) }));
  }, [dispatch, invitesSent]);

  const showAddFriendsModal = () => {
    let modalContainer = document.getElementById('invite-friends-modal');
    if (!modalContainer) {
      modalContainer = document.createElement('div');
      modalContainer.setAttribute('id', 'invite-friends-modal');
      document.body.appendChild(modalContainer);
    }

    setInviteFriendsModalOpen(true);
  };

  return (
    <Container>
      <NewRoomLinkContainer>
        <CopyRoomLinkIcon height="12" width="12" />
        <NewRoomLinkText>{boardShareUrl}</NewRoomLinkText>
      </NewRoomLinkContainer>

      <CopyLinkButton type="button" onClick={onCopyLinkButtonClick}>
        {copyLinkButtonText}
      </CopyLinkButton>

      <HorizontalSeparator />

      {friendsList.length ? (
        <>
          <FriendsInviteListHeader>
            <FriendsInviteListHeaderTitle>
              <SmileyIcon />
              Add Friends to Room
            </FriendsInviteListHeaderTitle>

            <FriendsInviteListHeaderViewAllButton onClick={showAddFriendsModal}>
              <span>View All</span>
              <ChevronLeftIcon />
            </FriendsInviteListHeaderViewAllButton>
          </FriendsInviteListHeader>

          <FriendsToInviteList ref={friendsToInviteListRef} onWheel={onMouseScroll}>
            <SearchFriendsButton type="button" onClick={showAddFriendsModal}>
              <SearchFriendsButtonCircle>
                <SearchIcon width="20" />
              </SearchFriendsButtonCircle>

              <SearchFriendsButtonText>Search</SearchFriendsButtonText>
            </SearchFriendsButton>

            {friendsList.map((friend) => (
              <FriendsInviteButton
                key={friend.userId}
                user={friend}
                inviteAlreadySent={invitesSent[friend.userId]}
                alreadyInRoom={false}
                onFriendInviteClick={() => onFriendInviteClick(friend.userId)}
              />
            ))}
          </FriendsToInviteList>
        </>
      ) : (
        <FriendsListEmptyState />
      )}

      <ButtonContainer>
        <CreateRoomButton type="button" onClick={onContinueToRoomClick}>
          Continue to Room
        </CreateRoomButton>
      </ButtonContainer>

      {inviteFriendsModalOpen ? (
        <AddFriendsToRoomModal
          setOpen={setInviteFriendsModalOpen}
          continueToRoom={onContinueToRoomClick}
          invitesSent={invitesSent}
          onFriendInviteClick={toggleFriendInvite}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const NewRoomLinkContainer = styled.div`
  padding: 13px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 9px;
  overflow: hidden;
  background-color: #ffffff33;
  border-radius: 10px;

  ${svgColorMixin('white')}

  svg {
    opacity: 0.5;
  }
`;

const NewRoomLinkText = styled.span`
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
`;

const CopyLinkButton = styled.button`
  margin-top: 8px;
  padding: 13px;
  width: 100%;
  justify-content: center;
  color: white;
  font-weight: bolder;
  font-size: 16px;
  background: none;
  border: 2px solid white;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    color: black;
    background-color: white;
  }
`;

const HorizontalSeparator = styled.div`
  margin-top: 29px;
  height: 2px;
  width: 100%;
  background-color: #ffffff33;
`;

const FriendsInviteListHeader = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`;

const friendsInviteListHeaderTextMixin = () => css`
  color: white;
  font-size: 12px;
  font-weight: bold;

  ${svgColorMixin('white')}
`;

const FriendsInviteListHeaderTitle = styled.span`
  ${friendsInviteListHeaderTextMixin()}

  display: flex;
  gap: 9px;
`;

const FriendsInviteListHeaderViewAllButton = styled.button`
  ${friendsInviteListHeaderTextMixin()}

  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.95;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    opacity: 1;
  }

  ${svgColorMixin('white')}

  svg {
    transform: rotate(180deg);
    height: 11px;
  }
`;

const FriendsToInviteList = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${FriendContainer} {
    &:hover {
      box-shadow: none;
    }
  }

  ${FriendName} {
    color: white;
  }
`;

const SearchFriendsButtonCircle = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #ffffff22;

  ${svgColorMixin('white')}
`;

const SearchFriendsButton = styled.button`
  padding: 12px;
  margin: 6px;
  width: 84px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    ${SearchFriendsButtonCircle} {
      background-color: #ffffff3f;
    }
  }
`;

const SearchFriendsButtonText = styled.span`
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
  color: white;

  ${ellipsisTextMixin()}
`;

export default RoomCreationInviteFriends;
