import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { db } from '../../../firebase';
import ServerDate from '../../../util/server-date';

const SECS_IN_MIN = 60;
const SECS_IN_HOUR = SECS_IN_MIN * 60;
const SECS_IN_DAY = SECS_IN_HOUR * 24;
const SECS_IN_WEEK = SECS_IN_DAY * 7;

const NUM_OF_PROFILES_TO_DISPLAY = 3;

export type RecentVisitor = {
  id: string,
  name: string,
  lastAccess: Date,
  timeSince: string,
};

const getRelativeTimeSince = async (timestamp: Date) => {
  const secondsPast = Math.abs((await ServerDate.getTimeInSecs()) - (timestamp.getTime() / 1000));
  if (secondsPast < SECS_IN_MIN) {
    const sec = Math.floor(secondsPast);
    return `${sec} ${sec === 1 ? 'second' : 'seconds'} ago`;
  }
  if (secondsPast < SECS_IN_HOUR) {
    const min = Math.floor(secondsPast / SECS_IN_MIN);
    return `${min} ${min === 1 ? 'minute' : 'minutes'} ago`;
  }
  if (secondsPast < SECS_IN_DAY) {
    const hour = Math.floor(secondsPast / SECS_IN_HOUR);
    return `${hour} ${hour === 1 ? 'hour' : 'hours'} ago`;
  }
  if (secondsPast < SECS_IN_WEEK) {
    const day = Math.floor(secondsPast / SECS_IN_DAY);
    return `${day} ${day === 1 ? 'day' : 'days'} ago`;
  }
  // no label for past a week:
  return null;
};

export const getRecentVisitors = async (boardId: string, currentUserId: string): Promise<RecentVisitor[]> => {
  const q = query(
    collection(db, `boards/${boardId}/members`),
    orderBy('lastAccess', 'desc'),
    limit(NUM_OF_PROFILES_TO_DISPLAY + 1)  // limit needs to be +1 because it may include current user
  );
  const membershipsQuery = await getDocs(q);

  const recentVisitorsArr: RecentVisitor[] = await Promise.all(membershipsQuery.docs.map(async (doc) => {
    if (doc.id !== currentUserId) {
      return { 
        id: doc.id, 
        name: doc.data().name, 
        lastAccess: doc.data().lastAccess.toDate(),
        timeSince: await getRelativeTimeSince(doc.data().lastAccess.toDate()), 
      };
    }
    return undefined;
  }));

  // remove possible undefined from current user and restrict to NUM_OF_PROFILES_TO_DISPLAY in the case where query 
  // didn't include current user since we had to +1 in the query limit
  const filteredVisitors = recentVisitorsArr.filter(visitor => visitor !== undefined).slice(0, NUM_OF_PROFILES_TO_DISPLAY);
  
  return filteredVisitors;
};
