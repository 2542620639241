import shuffle from 'lodash/fp/shuffle';

import firebase from '../../../firebase';

const storage = firebase.storage();
const cache = {};

// Key is the name of storage folder
export const categories = {
  accessories: 'Accessories',
  blinkies: 'Blinkies',
  frames: 'Frames',
  reactions: 'Reactions',
};

// Each time shuffle identifier changes the items are re-shuffled.
// e.g. for groups it is group id
export async function getRemixItems(categoryId, shuffleId) {
  if (cache[categoryId]) {
    if (cache[categoryId].shuffleId !== shuffleId) {
      cache[categoryId] = {
        items: shuffle(cache[categoryId].items),
        shuffleId,
      };
    }

    return cache[categoryId].items;
  }

  const list = (await storage.ref(`mural-remix/${categoryId}`).listAll()).items;
  const items = await Promise.all(
    list.map(async (item) => ({
      id: item.name,
      url: await item.getDownloadURL(),
    }))
  );
  cache[categoryId] = { items: shuffle(items), shuffleId };
  return cache[categoryId].items;
}
