import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';
import { isEqual } from 'lodash/fp';

import { db } from '../../firebase';
import {
  BoardControllerContext,
  BoardElementControllerContext,
  ViewportControllerContext,
} from '../common/contexts.ts';
import ResizeButton from './controls/ResizeButton';
import RotateButton from './controls/RotateButton';
import ContextMenuButton from './controls/ContextMenuButton';
import { getRotationTransforms } from '../../util/element-util';
import { useHandleDragging } from '../hooks/useHandleDragging';
import { elementDefinitions } from './element-definitions';
import Portal from '../components/Portal';
import { MURAL_BOARD_TYPE } from '../../constants/board-constants';
import RemixButton from './remixing/RemixButton';
import RemixAIButton from './remixing-ai/RemixAIButton.tsx';
import { elementClasses } from './elements.ts';
import { isHereEmployeeCheck } from '../../util/user-util';
import log from '../../log';

const BoardElement = ({ boardId, elementData, isEditable, onStartDragging, onStopDragging }) => {
  // Currently it's local state, but it should have webrtc support in the future
  const [liveElementData, setLiveElementData] = useState(elementData);
  const previousElementDataRef = useRef(elementData);
  useEffect(() => {
    if (!isEqual(elementData, previousElementDataRef.current)) {
      // Update live data only when db data was actually changed (to prevent weird glitches on
      // re-renders because of other props changes)
      setLiveElementData(elementData);
    }
    previousElementDataRef.current = elementData;
  }, [elementData]);

  const containerRef = useRef(null);
  const viewportController = useContext(ViewportControllerContext);
  const viewportRect = viewportController?.getViewportRect();
  const [viewportCoordinates, setViewportCoordinates] = useState(() => viewportController?.getCoordinates());

  const rotationTransforms = useMemo(
    () =>
      getRotationTransforms({
        width: liveElementData.size[0],
        height: liveElementData.size[1],
        rotationAngle: liveElementData.rotationAngle || 0,
      }),
    // eslint wants us to check the whole size array, but we need to check number values
    // to avoid redundant re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [liveElementData.size[0], liveElementData.size[1], liveElementData.rotationAngle]
  );

  const [areControlButtonsAlwaysVisible, setAreControlButtonsAlwaysVisible] = useState(false);
  const [isTemporaryOnTop, setIsTemporaryOnTop] = useState(false);
  const onContextMenuOpened = useCallback(() => {
    setAreControlButtonsAlwaysVisible(true);
    setIsTemporaryOnTop(true);
  }, []);
  const onContextMenuClosed = useCallback(() => {
    setAreControlButtonsAlwaysVisible(false);
    setIsTemporaryOnTop(false);
  }, []);

  // Listen to viewport changes only if we need fresh coordinates, otherwise all board elements re-rendering
  // on viewport movement is a heavy performance hit.
  useEffect(() => {
    setViewportCoordinates(viewportController?.getCoordinates());
    return isTemporaryOnTop ? viewportController?.subscribeToViewportChanged(setViewportCoordinates) : () => {};
  }, [viewportController, isTemporaryOnTop]);

  const removeElement = useCallback(
    async (elementId) => {
      try {
        const docRef = db.doc(`boards/${boardId}/elements/${elementId}`);
        await docRef.delete();
      } catch (err) {
        log.error('Error removing element', err);
      }
    },
    [boardId]
  );

  const contextValue = useMemo(
    () => ({
      elementData: liveElementData,
      isEditable,
      patchLiveData: (patch) => setLiveElementData({ ...liveElementData, ...patch }),
      patchDbData: (patch) => db.doc(`boards/${boardId}/elements/${liveElementData.id}`).update(patch),
      containerRef,
      setIsTemporaryOnTop,
      setAreControlButtonsAlwaysVisible,
      removeElement,
    }),
    [boardId, liveElementData, isEditable, removeElement]
  );

  const draggingHandlers = useHandleDragging({
    onStartDragging: () => onStartDragging(elementData),
    onDragging: ({ deltaX, deltaY }) => {
      contextValue.patchLiveData({
        center: [
          liveElementData.center[0] + deltaX / viewportCoordinates.scale,
          liveElementData.center[1] + deltaY / viewportCoordinates.scale,
        ],
      });
    },
    onStopDragging: async () => {
      const isHandled = await onStopDragging(elementData);
      if (isHandled) return;

      contextValue.patchDbData({
        center: liveElementData.center,
        x: liveElementData.center[0],
        y: liveElementData.center[1],
      });
    },
    cursor: 'grabbing',
  });

  const ElementComponent = elementDefinitions[elementData.class]?.Component;
  const isPortalRestricted = elementDefinitions[elementData.class]?.restrictPortals;
  const portalNode = React.useMemo(() => createHtmlPortalNode(), []);
  const containerPositionProps =
    isTemporaryOnTop && !isPortalRestricted
      ? {
          top: viewportRect.x + (liveElementData.center[0] - viewportCoordinates.x) * viewportCoordinates.scale,
          left: viewportRect.y + (liveElementData.center[1] - viewportCoordinates.y) * viewportCoordinates.scale,
          scale: viewportCoordinates.scale,
          zIndex: 999999,
        }
      : {
          top: liveElementData.center[0],
          left: liveElementData.center[1],
          scale: 1,
          zIndex: liveElementData.zIndex,
        };

  const boardType = useContext(BoardControllerContext)?.type;

  const [isEmployee, setIsEmployee] = useState(false);
  useEffect(() => {
    (async () => {
      setIsEmployee(await isHereEmployeeCheck());
    })();
  }, []);

  if (!ElementComponent) {
    return null;
  }

  const content = (
    <BoardElementControllerContext.Provider value={contextValue}>
      <Container
        ref={containerRef}
        width={liveElementData.size[0]}
        height={liveElementData.size[1]}
        {...containerPositionProps}
        {...(isEditable ? draggingHandlers : {})}
        isEditable={isEditable}
      >
        <RotationContainer angle={liveElementData.rotationAngle || 0} isMirrored={liveElementData.isMirrored}>
          <ElementComponent isEditable={isEditable} />
        </RotationContainer>
        {isEditable && (
          <ContainingRectangle
            scaleX={rotationTransforms.containingRectangleScaleX}
            scaleY={rotationTransforms.containingRectangleScaleY}
            isAlwaysVisible={areControlButtonsAlwaysVisible}
          />
        )}
        {boardType === MURAL_BOARD_TYPE && (
          <>
            <RemixButtonContainer
              isClearView={elementDefinitions[elementData.class].hasClearView}
              offsetX={rotationTransforms.leftOffset}
              offsetY={rotationTransforms.topOffset}
              scale={1 / viewportCoordinates.scale}
              isAlwaysVisible={areControlButtonsAlwaysVisible}
            >
              <RemixButton />
            </RemixButtonContainer>
            {elementData.class === elementClasses.IMAGE && isEmployee && (
              <RemixingAIButtonContainer
                offsetX={rotationTransforms.rightOffset}
                offsetY={rotationTransforms.topOffset}
                scale={1 / viewportCoordinates.scale}
                isAlwaysVisible={areControlButtonsAlwaysVisible}
              >
                <RemixAIButton />
              </RemixingAIButtonContainer>
            )}
          </>
        )}
        {isEditable && (
          <FooterControlButtons
            isClearView={elementDefinitions[elementData.class].hasClearView}
            offsetX={rotationTransforms.rightOffset}
            offsetY={rotationTransforms.bottomOffset}
            scale={1 / viewportCoordinates.scale}
            isAlwaysVisible={areControlButtonsAlwaysVisible}
          >
            <ContextMenuButton onMenuOpened={onContextMenuOpened} onMenuClosed={onContextMenuClosed} />
            <RotateButton />
            <ResizeButton />
          </FooterControlButtons>
        )}
      </Container>
    </BoardElementControllerContext.Provider>
  );

  if (isPortalRestricted) {
    return content;
  }

  // Using 3rd party portalling library, otherwise react portals reset element internal state when re-parenting
  // https://github.com/facebook/react/issues/3965
  return (
    <>
      <InPortal node={portalNode}>{content}</InPortal>
      {
        ' ' /* This empty text node is actually needed here 🤷‍♂️ https://github.com/httptoolkit/react-reverse-portal/issues/22 */
      }
      {isTemporaryOnTop ? (
        <Portal root={document.body}>
          <OutPortal node={portalNode} />
        </Portal>
      ) : (
        <OutPortal node={portalNode} />
      )}
    </>
  );
};

export default memo(BoardElement, isEqual);

BoardElement.propTypes = {
  elementData: PropTypes.shape({
    class: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    center: PropTypes.arrayOf(PropTypes.number).isRequired,
    size: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    zIndex: PropTypes.number.isRequired,
    rotationAngle: PropTypes.number,
    isMirrored: PropTypes.bool,
  }).isRequired,
  boardId: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  onStartDragging: PropTypes.func,
  onStopDragging: PropTypes.func,
};

BoardElement.defaultProps = {
  isEditable: false,
  onStartDragging: () => {},
  onStopDragging: () => {},
};

const Container = styled.div.attrs(({ top, left, width, height, zIndex, scale }) => ({
  style: {
    width: `${width}px`,
    height: `${height}px`,
    zIndex,
    transform: `translate3d(${Math.round(top)}px, ${Math.round(left)}px, 0) scale3d(${scale}, ${scale}, 1)`,
  },
}))`
  position: absolute;
  left: 0;
  top: 0;
  cursor: ${({ isEditable }) => (isEditable ? 'grab' : 'default')};
  transform-origin: top left;
`;

const ControlButtonsBase = styled.div.attrs(({ offsetX, offsetY, scale }) => ({
  style: {
    transform: `translate3d(${Math.round(offsetX)}px, ${Math.round(offsetY)}px, 0) scale3d(${scale}, ${scale}, 1)`,
  },
}))`
  display: ${({ isAlwaysVisible }) => (isAlwaysVisible ? 'flex' : 'none')};
  position: absolute;

  ${Container}:hover & {
    display: flex;
  }
`;

const FooterControlButtons = styled(ControlButtonsBase)`
  bottom: ${({ isClearView }) => (isClearView ? '-8px' : '0')};
  right: 0;
  transform-origin: ${({ isClearView }) => (isClearView ? 'center right' : 'bottom right')};
`;

const RemixButtonContainer = styled(ControlButtonsBase)`
  transform-origin: ${({ isClearView }) => (isClearView ? 'bottom center' : 'top left')};
  top: ${({ isClearView }) => (isClearView ? '-20px' : '-8px')};
  left: ${({ isClearView }) => (isClearView ? '-27px' : '-8px')};
`;

const RemixingAIButtonContainer = styled(ControlButtonsBase)`
  transform-origin: ${({ isClearView }) => (isClearView ? 'bottom center' : 'top right')};
  top: -8px;
  right: -8px;
`;

// Applying rotation and mirroring separately so controls are not rotated and mirrored
const RotationContainer = styled.div.attrs(({ angle, isMirrored }) => ({
  style: {
    transform: `rotate(${angle}deg) scale3d(${isMirrored ? -1 : 1}, 1, 1)`,
  },
}))`
  width: 100%;
  height: 100%;
`;

// Container that is getting bigger when the element is rotated, so controls are always shown on hover.
const ContainingRectangle = styled.div.attrs(({ scaleX, scaleY }) => ({
  style: {
    transform: `scale3d(${scaleX}, ${scaleY}, 1)`,
  },
}))`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 20px;
  ${({ isAlwaysVisible }) => isAlwaysVisible && 'background: rgba(255, 255, 255, 0.1);'}

  ${Container}:hover & {
    background: rgba(255, 255, 255, 0.1);
  }
`;
