import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HereInput = ({
  id,
  type,
  // Pass in value for controlled input
  value,
  controlled,
  prefix,
  onChange,
  onKeyUp,
  onKeyDown,
  elementClass,
  toggleClass,
  availabilityIconClass,
  checkResult,
  // node you can add at end of input
  addOnAfter,
  errorMessage,
  placeholder,
  // We mark hidden on the element instead of not rendering entire component to let UIKit control visibility
  isHiddenOnDOM,
  darkStyle,
  smallStyle,
  onClick,
  disabled,
  customInputStyles,
  name,
  autoFocus,
  maxLength,
  testId,
}) => {
  // Value in input needs null check & to be passed in conditionally
  // as there are some places where we're using this component in an
  // uncontrolled way. However, this may break if your initial value
  // is null. TODO: move towards only using this component in a
  // controlled react form.
  const conditionalValue = {
    ...(controlled && { value }),
  };

  return (
    <>
      <Container
        className={`uk-input ${elementClass} ${toggleClass}`}
        style={{ border: `${checkResult !== false ? '' : '2px solid #F6335D'}` }}
        hidden={isHiddenOnDOM}
        darkStyle={darkStyle}
        smallStyle={smallStyle}
        onClick={onClick}
        disabled={disabled}
        hasError={errorMessage}
      >
        {prefix && <Prefix smallStyle={smallStyle}>{prefix}</Prefix>}
        <InputContainer checkResult={checkResult}>
          <Input
            id={id}
            // Spreading here is required to pass in value conditionally
            // to account for uncontrolled uses of input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...conditionalValue}
            type={type}
            name={name}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onChange={onChange}
            placeholder={placeholder}
            darkStyle={darkStyle}
            smallStyle={smallStyle}
            prefix={prefix}
            disabled={disabled}
            autoFocus={autoFocus}
            maxLength={maxLength}
            style={{ ...customInputStyles }}
            data-testid={testId}
          />
        </InputContainer>
        {addOnAfter && <AddOnAfter>{addOnAfter}</AddOnAfter>}
        {/* TODO: eventually we should pull these out of component as they are only to a specific use case */}
        {checkResult !== null && checkResult && (
          <IconContainer>
            <AvailabilityIcon
              className={availabilityIconClass}
              src="/images/icons/check-green.svg"
              uk-tooltip="title: This URL is available"
              alt="URL Available"
            />
          </IconContainer>
        )}
        {checkResult !== null && !checkResult && (
          <IconContainer>
            <AvailabilityIcon
              className={availabilityIconClass}
              src="/images/icons/x-red.svg"
              uk-tooltip="title: This URL is NOT available"
              alt="URL Unavailable"
            />
          </IconContainer>
        )}
      </Container>
      {errorMessage && <ErrorMessage smallStyle={smallStyle}>{errorMessage}</ErrorMessage>}
    </>
  );
};

HereInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  controlled: PropTypes.bool,
  prefix: PropTypes.node,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  elementClass: PropTypes.string,
  placeholder: PropTypes.string,
  checkResult: PropTypes.bool,
  addOnAfter: PropTypes.node,
  errorMessage: PropTypes.string,
  toggleClass: PropTypes.string,
  availabilityIconClass: PropTypes.string,
  isHiddenOnDOM: PropTypes.bool,
  darkStyle: PropTypes.bool,
  smallStyle: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  customInputStyles: PropTypes.shape(),
  name: PropTypes.string,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  testId: PropTypes.string,
};

HereInput.defaultProps = {
  id: undefined,
  type: 'text',
  value: '',
  controlled: false,
  prefix: null,
  onChange: null,
  onKeyUp: null,
  onKeyDown: () => {},
  elementClass: '',
  placeholder: '',
  checkResult: null,
  addOnAfter: null,
  errorMessage: '',
  toggleClass: '',
  availabilityIconClass: 'valid-username-icon',
  isHiddenOnDOM: false,
  darkStyle: false,
  smallStyle: false,
  onClick: () => {}, // no-op
  disabled: false,
  customInputStyles: null,
  name: '',
  autoFocus: false,
  maxLength: undefined,
  testId: null,
};

const Container = styled.div`
  padding: 0 10px;
  border-radius: 5px;
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
  border: none;
  outline: 1px solid var(--primary-foreground-alpha-20, rgba(0, 0, 0, 0.2));

  ${(props) =>
    props.smallStyle &&
    `
    height: auto;
  `}

  ${(props) =>
    props.darkStyle &&
    `
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;

    &:hover {
      outline: 1px solid var(--primary-foreground-alpha-50, rgba(255, 255, 255, 0.5));
    }
  `}

  &:focus-within {
    outline: 2px solid var(--secondary-background, #6b1be3);
  }

  ${(props) =>
    props.disabled &&
    `
    background: rgba(196, 196, 196, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.2);
  `}

  ${(props) =>
    props.hasError &&
    `
    border: 1px solid rgba(246, 51, 93, 1);

    &:hover {
      outline: 1px solid rgba(246, 51, 93, 1);
    }

    &:focus-within {
      border: 1px solid rgba(246, 51, 93, 1);
    }
  `}
`;

const Prefix = styled.div`
  color: var(--primary-foreground, #999);
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1em;

  ${(props) =>
    props.smallStyle &&
    `
    font-size: 0.95em;
  `}
`;

const AddOnAfter = styled.div`
  position: absolute;
  right: 3px;
`;

const InputContainer = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  ${({ checkResult, addOnAfter }) =>
    checkResult !== null &&
    !addOnAfter &&
    `
    padding-right: 30px;
  `}
`;

const Input = styled.input`
  width: 100%;
  font-size: 1em;
  text-decoration: none;
  border: 0px;
  outline: 0px;
  height: 100%;
  background: top;

  ${(props) =>
    props.smallStyle &&
    `
    font-size: 0.95em;
  `}

  ${(props) =>
    props.darkStyle &&
    `
    color: var(--primary-foreground, white);
  `}

  ${(props) =>
    props.prefix &&
    `
    margin-left: 3px;
  `}

  ${(props) =>
    props.disabled &&
    `
    color: #010101;
    opacity: 0.6;
  `}
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
`;

const AvailabilityIcon = styled.img`
  margin-top: 12px;
  margin-left: 12px;
  float: right;
  display: block;
  height: unset !important;
`;

const ErrorMessage = styled.div`
  color: #f6335d;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  margin-top: 5px;

  ${(props) =>
    props.smallStyle &&
    `
    font-size: 12px;
    line-height: 18px;
  `}
`;

export default HereInput;
