import isEqual from 'lodash/fp/isEqual';

import { hexToRgb } from './color-util';
import { readCssVariable, removeCssVariable, setClass, setCssVariable } from './styles-util';

const colorVariables = ['primaryBackground', 'primaryForeground', 'secondaryBackground', 'secondaryForeground'];
const defaultInverseMap = {
  primaryBackground: 'secondaryBackground',
  primaryForeground: 'secondaryForeground',
  secondaryBackground: 'primaryBackground',
  secondaryForeground: 'primaryForeground',
};

const opacityVariants = [...Array(9)].map((_, index) => (index + 1) / 10);
const getOpacityVariableName = (name, opacity) => `${name}Alpha${opacity * 100}`;

export const replaceOpacityString = (value, opacity) => value.replace(/<opacity>/g, opacity);

export const applyOpacityToVariable = (value, opacity) => {
  if (value.startsWith('#')) {
    return `rgba(${hexToRgb(value)}, ${opacity})`;
  }
  return replaceOpacityString(value, opacity);
};

function setVariableWithOpacityVariants(name, value, element) {
  opacityVariants.forEach((opacity) =>
    setCssVariable(getOpacityVariableName(name, opacity), applyOpacityToVariable(value, opacity), element)
  );
  setCssVariable(name, applyOpacityToVariable(value, 1), element);
}

function removeVariableWithOpacityVariants(name, element) {
  removeCssVariable(name, element);
  opacityVariants.forEach((opacity) => removeCssVariable(getOpacityVariableName(name, opacity), element));
}

function setFontVariable(name, value, element) {
  if (value) {
    // Setting a fallback in case the font is missing.
    setCssVariable(name, `${value}, Inter, sans-serif`, element);
  } else {
    removeCssVariable(name, element);
  }
}

function applyColors(colors, element) {
  colors = colors || {};

  setCssVariable('backgroundAlpha', 1, element);
  colorVariables.forEach((varName) => {
    if (colors[varName]) {
      setVariableWithOpacityVariants(varName, colors[varName], element);
    } else {
      removeVariableWithOpacityVariants(varName, element);
    }
  });

  const isThemed = colorVariables.every((varName) => colors[varName]);
  setClass('themed', isThemed, element);
}

export function applyTheme(colors, font, element = document.body) {
  applyColors(colors, element);
  setFontVariable('primaryFont', font, element);
  setClass('has-themed-font', !!font, element);
}

export function applyChatTheme(theme, element) {
  applyColors(theme?.colors, element);
  setFontVariable('primaryFont', theme?.skin?.fonts?.title || theme?.primaryFont, element);
  setFontVariable('chatSectionFont', theme?.skin?.fonts?.section, element);
  setFontVariable('chatSystemFont', theme?.skin?.fonts?.system, element);
  setClass('has-themed-font', !!theme?.skin?.fonts, element);
}

export function inverseTheme(element, inverseMap = defaultInverseMap) {
  // Reading values from parent to correctly handle being called twice on the same element
  Object.keys(inverseMap).forEach((originVarName) => {
    const destinationVarName = inverseMap[originVarName];
    opacityVariants.forEach((opacity) =>
      setCssVariable(
        getOpacityVariableName(destinationVarName, opacity),
        readCssVariable(getOpacityVariableName(originVarName, opacity), element.parentElement),
        element
      )
    );
    setCssVariable(destinationVarName, readCssVariable(originVarName, element.parentElement), element);
  });
}

export const isThemeCustom =
  // Custom room is either marked as custom, or has unique color scheme (this case is to handle
  // existing rooms if we change a preset theme colors).
  (currentTheme, themesList) =>
    currentTheme?.colors && (currentTheme.isCustom || !themesList.find((t) => isEqual(currentTheme.colors, t.colors)));

export const getThemeName = (isCustom, colors, themesList) =>
  (isCustom
    ? themesList.find((t) => t.isCustom)
    : themesList.find((t) => (colors ? isEqual(colors, t.colors) : t.isDefault))
  )?.name;
