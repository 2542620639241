import React from 'react';
import PropTypes from 'prop-types';

const PauseIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.5 0H21.75C20.9216 0 20.25 0.700726 20.25 1.56512V32.8674C20.25 33.7318 20.9216 34.4326 21.75 34.4326H28.5C29.3284 34.4326 30 33.7318 30 32.8674V1.56512C30 0.700726 29.3284 0 28.5 0Z"
      fill={color}
    />
    <path
      d="M8.24998 0H1.5C0.671571 0 0 0.700726 0 1.56512V32.8674C0 33.7318 0.671571 34.4326 1.5 34.4326H8.24998C9.0784 34.4326 9.74997 33.7318 9.74997 32.8674V1.56512C9.74997 0.700726 9.0784 0 8.24998 0Z"
      fill={color}
    />
  </svg>
);

export default PauseIcon;

PauseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

PauseIcon.defaultProps = {
  color: 'white',
  width: '25',
  height: '30',
};
