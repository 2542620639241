import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useElementData } from '../hooks/useElementData';

import InactiveTimer from '../components/timer/InactiveTimer';
import ActiveTimer from '../components/timer/ActiveTimer';
import { timerStates } from '../components/timer/constants';
import SpeakerIcon from '../icons/SpeakerIcon';
import TimerIcon from '../../../assets/icons/timer.svg';
import { svgColorMixin } from '../mixins';

const Timer = ({ elementId }) => {
  const currentBoardId = useSelector((state) => state.room.id);

  // subscribe to changes in document
  const [
    {
      originalStartTime, // { hour, min, sec }
      timerState, // inactive/active/paused
      currTimeLeft, // { hour, min, sec }
      timeStarted, // in seconds
      timerDone,
    },
    updateDocument,
  ] = useElementData(currentBoardId, elementId);

  return (
    <div id={`timer-${elementId}`} className="timer-content">
      <div className="timer-wrapper new-timer-wrapper">
        <div className="timer-header">
          <div className="timer-header-content">
            <div>
              <TimerIcon className="header-icon" />
              Timer
            </div>
            {timerDone && (
              <TimesUpBlock>
                <SpeakerIconContainer>
                  <SpeakerIcon />
                </SpeakerIconContainer>
                <div>Time&apos;s up!</div>
              </TimesUpBlock>
            )}
          </div>
        </div>
        <div className="timer-cell">
          {!timerState || timerState === timerStates.inactive ? (
            <InactiveTimer elementId={elementId} currTimeLeft={currTimeLeft} updateDocument={updateDocument} />
          ) : (
            <ActiveTimer
              currentBoardId={currentBoardId}
              elementId={elementId}
              originalStartTime={originalStartTime}
              timerState={timerState}
              currTimeLeft={currTimeLeft}
              timeStarted={timeStarted}
              updateDocument={updateDocument}
              timerDone={timerDone}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Timer;

Timer.propTypes = {
  elementId: PropTypes.string.isRequired,
};

const TimesUpBlock = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  background: var(--secondary-background, #f6335d);
  color: var(--secondary-foreground, white);
  padding: 5px 9px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-right: 21px;

  ${svgColorMixin('var(--secondary-foreground, white)')}
`;

const SpeakerIconContainer = styled.div`
  margin-right: 6px;
`;
