import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserListItem from '../user-profile/UserListItem';
import FriendRequestButtons from './FriendRequestButtons';
import { addAnimation } from '../store/animations/store';
import { ANIMATION_NAMES } from '../store/animations/constants';
import { errorMessageMixin } from '../mixins';

const FriendRequestListItem = ({ friendRequest }) => {
  const dispatch = useDispatch();

  const userId = friendRequest.requester;

  const [errorMessage, setErrorMessage] = useState('');
  const onAccept = () => {
    setErrorMessage('');
    dispatch(addAnimation({ name: ANIMATION_NAMES.confettiBurst, id: `${userId}-friendship-confetti` }));
  };

  const ButtonsSection = (
    <ButtonsContainer>
      <FriendRequestButtons
        requesterId={userId}
        onAccept={onAccept}
        onIgnore={() => setErrorMessage('')}
        onError={() => setErrorMessage('There was an error. Please try again.')}
      />
    </ButtonsContainer>
  );

  return (
    <FriendRequestContainer>
      <UserListItem userId={userId} userCTAsSection={ButtonsSection} />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </FriendRequestContainer>
  );
};

export default FriendRequestListItem;

FriendRequestListItem.propTypes = {
  friendRequest: PropTypes.shape({
    requester: PropTypes.string.isRequired,
  }).isRequired,
};

const FriendRequestContainer = styled.div`
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const ButtonsContainer = styled.div`
  padding-right: 12px;
`;

const ErrorMessage = styled.div`
  ${errorMessageMixin()}
`;
