import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/fp/isEmpty';

import { FRIEND_REQUEST_STATUS } from '../../user-profile/constants';
import { selectCurrentUserId, selectUserSearchResultProfiles } from '../users/selectors';
import { getPresentMemberIds, getRawPresentMembers } from '../room-members/selectors';

export const friendsSelector = (state) => {
  const { users, friends } = state;
  const ids = Object.keys(friends.friendsIds);
  const usersInStore = users.users;

  const friendsKeyedById = {};

  ids.forEach((id) => {
    if (usersInStore[id]) {
      // only add to friendsKeyedById if user exists in store:
      friendsKeyedById[id] = usersInStore[id];
    }
  });

  return friendsKeyedById;
};

export const friendsAsArraySelector = (state) => Object.values(friendsSelector(state));

export const friendByIdSelector = (state, userId) => {
  if (state.friends.friendsIds[userId]) {
    return state.users.users[userId];
  }
  return null;
};

export const selectFriendIds = (state) => state.friends.friendsIds;

export const selectFriendIdsAsArray = (state) => Object.keys(state.friends.friendsIds || {});

export const selectIsFriendsByIdCurried = (userId) => (state) => Boolean(state.friends.friendsIds[userId]);

export const loadingFriendsSelector = (state) => state.friends.loadingFriends;
export const friendsErrorSelector = (state) => state.friends.friendsError;
export const acceptedNotificationsSelector = (state) => state.friends.acceptedRequestNotifications;
export const outgoingRequestsSelector = createSelector([(state) => state.friends.outgoingRequests], Object.values);
export const incomingRequestsSelector = createSelector([(state) => state.friends.incomingRequests], Object.values);
export const incomingPendingRequestsSelector = createSelector([incomingRequestsSelector], (requests) =>
  requests.filter((r) => r.status === FRIEND_REQUEST_STATUS.pending)
);
export const incomingIgnoredRequestsSelector = createSelector([incomingRequestsSelector], (requests) =>
  requests.filter((r) => r.status === FRIEND_REQUEST_STATUS.ignored)
);
export const incomingRequestsAfterTimeSelector = createSelector(
  [incomingRequestsSelector, (state, timestamp) => timestamp],
  (requests, timestamp) => requests.filter((r) => r.updatedAt > timestamp)
);

export const incomingPendingRequestsByRequesterSelector = createSelector(
  [incomingPendingRequestsSelector, (state, requester) => requester],
  (requests, requester) => requests.find((r) => r.requester === requester)
);

export const incomingIgnoredRequestsByRequesterSelector = createSelector(
  [incomingIgnoredRequestsSelector, (state, requester) => requester],
  (requests, requester) => requests.find((r) => r.requester === requester)
);

export const selectOutgoingPendingRequests = createSelector([outgoingRequestsSelector], (requests) =>
  requests.filter((r) => r.status === FRIEND_REQUEST_STATUS.pending)
);
export const selectUserIdsOfOutgoingPendingRequests = createSelector([selectOutgoingPendingRequests], (requests) =>
  requests.map((r) => r.receiver)
);

export const selectUserIdsOfIncomingPendingRequests = createSelector([incomingPendingRequestsSelector], (requests) =>
  requests.map((r) => r.requester)
);

export const selectHasFriends = (state) => !isEmpty(state.friends.friendsIds);

export const selectFriendsInCurrentRoom = createSelector(
  [friendsAsArraySelector, getPresentMemberIds],
  (friends, presentMembers) => friends.filter((f) => presentMembers.includes(f.userId))
);

// Returns friends NOT in room first
export const selectFriendsInOrderOfPresence = createSelector(
  [friendsAsArraySelector, getRawPresentMembers],
  (friends, presentMembers) => [
    ...friends.filter((f) => !presentMembers[f.userId]),
    ...friends.filter((f) => !!presentMembers[f.userId]),
  ]
);

const onlineStatusesPriority = {
  offline: 0,
  online: 1,
};

// returns online friends first
export const selectFriendsInOrderOfOnlineStatus = createSelector([friendsAsArraySelector], (friends) =>
  friends.sort(
    (friendA, friendB) =>
      onlineStatusesPriority[friendB.onlineStatus.status] - onlineStatusesPriority[friendA.onlineStatus.status]
  )
);

export const selectFirstTimeMemberFriendSuggestion = (state) => state.friends.firstTimeMemberFriendSuggestion;

export const selectOutsideReactionFriendSuggestion = (state) => state.friends.outsideReactionFriendSuggestion;

export const selectOnlineFriendsCount = createSelector(
  [friendsAsArraySelector],
  (friends) => friends.filter((f) => f.onlineStatus.status === 'online').length
);

export const selectOnlineFriends = createSelector([friendsAsArraySelector], (friends) =>
  friends.filter((f) => f.onlineStatus?.status === 'online')
);

export const selectPendingOrIgnoredSentRequest = createSelector(
  [outgoingRequestsSelector, (state, id) => id],
  (outgoingRequests, id) =>
    outgoingRequests.find(
      (r) =>
        r.receiver === id && (r.status === FRIEND_REQUEST_STATUS.pending || r.status === FRIEND_REQUEST_STATUS.ignored)
    )
);
export const selectRequestSentPending = createSelector(
  [outgoingRequestsSelector, (state, id) => id],
  (outgoingRequests, id) =>
    outgoingRequests.find((r) => r.receiver === id && r.status === FRIEND_REQUEST_STATUS.pending)
);
export const selectPendingIncomingRequests = createSelector([incomingRequestsSelector], (incomingRequests) =>
  incomingRequests.filter((r) => r.status === FRIEND_REQUEST_STATUS.pending)
);

export const selectPendingIncomingRequestsCount = createSelector(
  [selectPendingIncomingRequests],
  (incomingRequests) => incomingRequests.length
);

export const selectPendingIncomingRequest = createSelector(
  [incomingRequestsSelector, (state, id) => id],
  (incomingRequests, id) =>
    incomingRequests.find((r) => r.requester === id && r.status === FRIEND_REQUEST_STATUS.pending)
);

export const selectCanAddToFriend = createSelector(
  [
    selectCurrentUserId,
    selectPendingOrIgnoredSentRequest,
    selectPendingIncomingRequest,
    (state, id) => !!state.friends.friendsIds[id],
    (state, id) => id,
  ],
  (currentUserId, sentRequest, incomingRequest, isFriend, userId) =>
    currentUserId !== userId && !isFriend && !incomingRequest && !sentRequest
);

export const selectCanAddToFriendCurried = (userId) => (state) => selectCanAddToFriend(state, { userId });

export const selectSearchResultProfilesThatAreNotFriends = createSelector(
  [selectUserSearchResultProfiles, selectFriendIds, selectCurrentUserId],
  (searchResults, friends, currentUserId) =>
    searchResults && [...searchResults.filter((u) => !friends[u.userId] && u.userId !== currentUserId)]
);
