import { useEffect, useState } from 'react';

import { getUserFriends } from '../store/users/api';

const cache: { [key: string]: object[] } = {};

export const useUserFriendsData = (userId: string) => {
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (cache[userId]) {
          setFriends(cache[userId]);
          return;
        }

        setError(null);
        setLoading(true);
        const data = await getUserFriends(userId);
        const profiles = data.friends.map((friend: { profile: object }) => friend.profile);
        cache[userId] = profiles;
        setFriends(profiles);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [userId]);

  return {
    friends,
    loading,
    error,
  };
};
