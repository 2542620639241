import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import HereModal from './HereModal';

interface Props {
  children: React.ReactNode;
  modalRootId: string;
  modalWidth?: string;
  onOverlayClick?: () => void;
}

const HereModalWithPortal: React.FC<Props> = ({ children, modalRootId, modalWidth, onOverlayClick }) => {
  const modalRoot = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const newDiv = document.createElement('div');
    newDiv.id = modalRootId;
    document.body.appendChild(newDiv);

    modalRoot.current = newDiv;
    setIsInitialized(true);

    return () => {
      if (modalRoot.current) {
        document.body.removeChild(modalRoot.current);
      }
    };
  }, [modalRootId]);

  if (!isInitialized) {
    return <></>;
  }

  return ReactDOM.createPortal(
    <HereModal onOverlayClick={onOverlayClick} modalWidth={modalWidth}>
      {children}
    </HereModal>,
    modalRoot.current
  );
};

export default HereModalWithPortal;
