// Also see the following associated user page events in other files:
// 'Enter Room', 'Open Web Lobby Tab'

export const ADD_NEW_USER_CARD = 'Add New User Card'; // params: cardsCount
export const DELETE_USER_CARD = 'Delete User Card';
export const ENTER_USER_CARD_EDIT_MODE = 'Enter User Card Edit Mode';
export const GET_INSPIRED_WITH_PROMPT = 'Get User Card Prompt'; // params: isFirstCard
export const VIEW_USER_PAGE = 'View User Page'; // params: isSelf (bool), isLoggedIn (bool), isMobile (bool), pageOwnerId
export const SHARE_PAGE_CLICK = 'Clicked Share User Page'; // params: isSelf (bool)
export const VIEW_USER_PAGE_CLICK = 'Clicked View User Page'; // params: source
export const USER_CARD_TOOLBAR_BUTTON_CLICK = 'Clicked User Card Toolbar Button'; // params: buttonType
export const USER_CARD_ICON_CLICK = 'Clicked User Card Icon'; // params: isSelf (bool), hasUnreadBadge (bool)
