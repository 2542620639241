import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/fp/isEqual';

import FriendingSystemMessage from '../FriendingSystemMessage';
import { undeleteElement } from '../../../../util/element-util';
import { getDividerData } from '../msgTimeStampUtil.ts';
import { NewDayDivider } from '../NewDayDivider';
import { selectUserDisplayName } from '../../../store/users/selectors';
import {
  OnboardingSystemMessages,
  newGroupChatSystemMessage,
  onboardingSystemMsgTypes,
} from '../../../../definitions/message.ts';
import OnboardingSystemMessage from './OnboardingSystemMessage.tsx';
import NewChatSystemMessage from './NewChatSystemMessage.tsx';

const onboardingMessages = Object.values(OnboardingSystemMessages);

function SystemMessage(props) {
  const {
    id,
    creator,
    chatId,
    text,
    deletedElementId,
    userCanAddContent,
    displayFriendButton,
    createdAt,
    previousMsgCreatedAt,
    roomChatMessageOutsideOfRoom,
    isFeedMsg,
    sysMsgType,
  } = props;

  const { showNewDayDivider, dividerTimestamp } = getDividerData(createdAt, previousMsgCreatedAt);
  const creatorName = useSelector((state) => selectUserDisplayName(state, creator));

  if (onboardingMessages.includes(text) || onboardingSystemMsgTypes.includes(sysMsgType)) {
    return <OnboardingSystemMessage id={id} text={text} type={sysMsgType} />;
  }

  if (text === newGroupChatSystemMessage) {
    return <NewChatSystemMessage chatId={chatId} creatorId={creator} creatorName={creatorName} />;
  }

  return (
    <>
      {showNewDayDivider && <NewDayDivider dividerTimestamp={dividerTimestamp} />}
      <div className="feed-system-message">
        {/* Some 'system' DMs don't have a creator */}
        {creator && isFeedMsg && <here-user-name userId={creator} />}
        {/* If sent from (group) DM, read name from redux store instead */}
        {creator && !isFeedMsg && creatorName}
        <span className="feed-system-message-text">{text}</span>

        {deletedElementId && userCanAddContent && !roomChatMessageOutsideOfRoom && (
          <button className="undelete-button" type="button" onClick={() => undeleteElement(deletedElementId, id)}>
            (undelete)
          </button>
        )}
        {creator && displayFriendButton && <FriendingSystemMessage creator={creator} />}
      </div>
    </>
  );
}

SystemMessage.propTypes = {
  id: PropTypes.string.isRequired,
  creator: PropTypes.string,
  chatId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  deletedElementId: PropTypes.string,
  userCanAddContent: PropTypes.bool.isRequired,
  displayFriendButton: PropTypes.bool,
  createdAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      createdAt: PropTypes.func,
    }),
    PropTypes.number, // Date.now() for local queue messages
  ]).isRequired,
  previousMsgCreatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      previousMsgCreatedAt: PropTypes.func,
    }),
    PropTypes.number, // Date.now() for local queue messages
  ]),
  roomChatMessageOutsideOfRoom: PropTypes.bool,
  isFeedMsg: PropTypes.bool,
  sysMsgType: PropTypes.string,
};

SystemMessage.defaultProps = {
  deletedElementId: '',
  displayFriendButton: false,
  roomChatMessageOutsideOfRoom: false,
  previousMsgCreatedAt: null,
  creator: '',
  isFeedMsg: true,
  sysMsgType: '',
};

const propsAreEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(SystemMessage, propsAreEqual);
