import React, { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { observeRoom, unobserveRoom } from './utils/room-item-observer';

interface RoomItemWithOnScreenProps {
  roomId: string;
  children: ReactNode;
}

const RoomItemWithOnScreen = ({ roomId, children }: RoomItemWithOnScreenProps) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const containerCopy = containerRef.current;

    observeRoom(roomId, containerCopy);

    return () => {
      unobserveRoom(roomId, containerCopy);
    };
  }, [roomId]);

  return (
    <Container data-roomid={roomId} ref={containerRef}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export default RoomItemWithOnScreen;
