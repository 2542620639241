import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { confirmAlert } from 'react-confirm-alert';

import {
  MAX_NUM_CARDS,
  USER_CARDS_GAP_PX,
  USER_CARD_HEIGHT_PX,
  USER_CARD_SLIDE_HEIGHT_PX,
  USER_CARD_WIDTH_PX,
} from '../../../../constants/user-card-constants';
// TODO: not nice...
// eslint-disable-next-line import/no-cycle
import UserProfile from '../..';
import CardDeleteConfirmation from '../../../user-page/CardDeleteConfirmation';
import Card, {
  CardTitle,
  CardContainer as DefaultCardContainer,
  CardOverlay as DefaultCardOverlay,
} from '../../../user-card';
import { track } from '../../../../util/analytics-util';
import { ENTER_USER_CARD_EDIT_MODE } from '../../../../constants/analytics-events/user-page-events';
import { ellipsisTextMixin, hideableMixin, noScrollbarMixin, svgColorMixin } from '../../../mixins';
import { selectCurrentUser } from '../../../store/users/selectors';
import NewCardButton from './NewCardButton';
import CardsLimitMessage from '../../../user-page/CardsLimitMessage';
import { addNewUserCard, removeUserCard } from '../../../user-page/api';
import * as EditOverlayComponents from '../../../user-card/CardEditOverlay';

import PrevArrowIcon from '../../../../../assets/icons/arrows/rounded-chevron-left.svg';
import NextArrowIcon from '../../../../../assets/icons/arrows/rounded-chevron-right.svg';
import EditIcon from '../../../../../assets/icons/pen.svg';
import DeleteIcon from '../../../../../assets/icons/trashcan.svg';
import CheckIcon from '../../../../../assets/icons/check.svg';
import Portal from '../../../components/Portal';
import eventBus, { userCardCreated } from '../../../../event-bus';

const viewScale = 0.65;
const slideWidth = USER_CARD_WIDTH_PX * viewScale;
const cardHeight = USER_CARD_HEIGHT_PX * viewScale;
const slideHeight = USER_CARD_SLIDE_HEIGHT_PX * viewScale;
const gap = USER_CARDS_GAP_PX * viewScale;
const slideWithGapWidth = slideWidth + gap;

const UserCardsList = () => {
  const currentUser = useSelector(selectCurrentUser);
  const { cardIds = [], userId } = currentUser;

  // Cards + profile card + new card button (or cards limit warning)
  const carouselLength = cardIds.length + 2;
  const isScrollable = carouselLength > 2;

  const cardContainerRef = useRef(null);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkPosition = (newPosition: number) => {
    setIsAtBeginning(newPosition <= 0);
    setIsAtEnd(newPosition >= cardContainerRef.current.scrollWidth - cardContainerRef.current.offsetWidth);
  };

  const scroll = (delta: number) => {
    const newPosition = cardContainerRef.current.scrollLeft + delta;
    cardContainerRef.current.scroll({ left: newPosition, behavior: 'smooth' });
    checkPosition(newPosition);
  };

  const onWheel = (e: React.WheelEvent) => {
    checkPosition(cardContainerRef.current.scrollLeft + e.deltaX);
  };

  const [cardBeingEditedId, setCardBeingEditedId] = useState(null);
  useEffect(() => {
    // There are 3 levels of popovers/modals, including the radix one where we cannot pass custom z-index,
    // so z-indexing becomes buggy and it's easier to just temporary hide previous levels which are gonna be
    // under blurry overlay. Not nice though, this should be removed once we have reliable z-index system (never?..)
    const myStuff = document.querySelectorAll('.my-stuff-menu, #user-profile');
    myStuff.forEach((el: HTMLElement) => {
      // Ok this is getting super-ugly, need to revisit it after the release fuss
      const container: HTMLElement = el.closest('[data-radix-popper-content-wrapper]') || el;
      container.style.visibility = cardBeingEditedId ? 'hidden' : null;
      container.style.pointerEvents = cardBeingEditedId ? 'none' : null;
    });
  }, [cardBeingEditedId]);

  const onDeleteCard = useCallback(
    (cardId) =>
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="prevent-profile-closing prevent-on-click-outside">
            <CardDeleteConfirmation
              onCancel={(e) => {
                e.stopPropagation();
                onClose();
              }}
              onConfirm={async (e) => {
                e.stopPropagation();
                setCardBeingEditedId(null);
                onClose();
                removeUserCard({ userId, cardId, currentCardIds: cardIds });
              }}
            />
          </div>
        ),
      }),
    [cardIds, userId]
  );

  const enterEditMode = useCallback((cardId) => {
    setCardBeingEditedId(cardId);
    track(ENTER_USER_CARD_EDIT_MODE);
  }, []);

  const exitEditMode = useCallback(() => {
    setCardBeingEditedId(null);
  }, []);

  const onAddNewCard = useCallback(async () => {
    const newCardId = await addNewUserCard({ userId, currentCardIds: cardIds });
    if (newCardId) {
      enterEditMode(newCardId);
    }
    eventBus.dispatch(userCardCreated);
  }, [cardIds, enterEditMode, userId]);

  return (
    <Container>
      <CardsContainer onWheel={onWheel} ref={cardContainerRef}>
        <UserProfileSlide key="user-profile">
          <UserProfileContainer>
            <UserProfile userId={userId} id="userPage" showUserCards={false} showCTAs={false} />
          </UserProfileContainer>
        </UserProfileSlide>
        {cardIds.map((cardId: string) => (
          <SingleCardContainer key={cardId}>
            <CardOverlay>
              <OverlayButton onClick={() => enterEditMode(cardId)}>
                <EditIcon />
                Edit Card
              </OverlayButton>
            </CardOverlay>
            <Card cardId={cardId} isEditMode={false} isFirstCard={cardIds.length === 1} scale={viewScale} />
          </SingleCardContainer>
        ))}
        <Slide key="add-new-card-button">
          {cardIds.length < MAX_NUM_CARDS ? (
            <NewCardButton
              isFirstCard={!cardIds.length}
              onClick={onAddNewCard}
              slideWidth={slideWidth}
              cardHeight={cardHeight}
            />
          ) : (
            <CardsLimitMessage scale={viewScale} />
          )}
        </Slide>
      </CardsContainer>
      {cardBeingEditedId ? (
        <Portal root={document.body}>
          <EditContainer className="prevent-profile-closing">
            <Card cardId={cardBeingEditedId} isEditMode isFirstCard={cardIds.length === 1} />
            <EditButtons>
              <DeleteButton onClick={() => onDeleteCard(cardBeingEditedId)}>
                <DeleteIcon />
              </DeleteButton>
              <EditOverlayComponents.Button onClick={exitEditMode}>
                <CheckIcon />
              </EditOverlayComponents.Button>
            </EditButtons>
          </EditContainer>
        </Portal>
      ) : null}
      <PrevButton onClick={() => scroll(-slideWithGapWidth)} isDisabled={isAtBeginning} isHiddenDisplay={!isScrollable}>
        <PrevArrowIcon />
      </PrevButton>
      <NextButton onClick={() => scroll(slideWithGapWidth)} isDisabled={isAtEnd} isHiddenDisplay={!isScrollable}>
        <NextArrowIcon />
      </NextButton>
    </Container>
  );
};

export default UserCardsList;

const Container = styled.div`
  width: 100%;
  overscroll-behavior: none;
  position: relative;
`;

const Slide = styled.div`
  height: ${slideHeight}px;
  width: ${slideWidth}px;
  margin-right: ${gap}px;
`;

const UserProfileSlide = styled(Slide)`
  display: block;
`;

const UserProfileContainer = styled.div`
  transform: scale(${viewScale});
  transform-origin: top left;
  outline: 8px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  // Using full width so the outline is correctly displayed when scaled down
  width: ${USER_CARD_WIDTH_PX}px;
  overflow-x: hidden;
  overflow-y: auto;

  ${noScrollbarMixin()}
`;

const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 16px 16px 0;

  &::-webkit-scrollbar {
    display: none;
  }

  ${CardTitle} {
    font-size: 12px;
    width: ${slideWidth}px;

    ${ellipsisTextMixin({ fullWidth: false })}
  }

  ${DefaultCardOverlay} {
    display: none !important;
  }

  ${DefaultCardContainer} {
    outline: none;
  }
`;

const CardOverlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: ${slideWidth}px;
  height: ${cardHeight}px;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 20px;
`;

const OverlayButton = styled.div`
  color: white;
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 20px;

  svg {
    ${svgColorMixin('white')}
    margin-right: 6px;
  }
`;

const SingleCardContainer = styled.div`
  position: relative;

  &:hover {
    ${CardOverlay} {
      display: flex;
    }
  }
`;

const EditContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  ${EditOverlayComponents.OptionsButton},
  ${EditOverlayComponents.CloseButton} {
    visibility: hidden;
  }
`;

const EditButtons = styled.div`
  position: fixed;
  left: calc(50% + ${USER_CARD_WIDTH_PX / 2}px);
  top: calc(50% + ${USER_CARD_HEIGHT_PX / 2}px - 80px);
  display: flex;
  z-index: 999;
  gap: 16px;

  ${EditOverlayComponents.Button} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
`;

const DeleteButton = styled(EditOverlayComponents.Button)`
  ${svgColorMixin('#ff0000')}
`;

const SideButton = styled.div<{ isDisabled: boolean; isHiddenDisplay: boolean }>`
  ${hideableMixin()}

  position: absolute;
  top: 162px;
  z-index: 3;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.2 : 1)};

  svg {
    width: 24px;
  }
`;

const PrevButton = styled(SideButton)`
  left: -20px;
`;

const NextButton = styled(SideButton)`
  right: -20px;
`;
