import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { disableEverything, enableElements } from '../../../onboarding';
import { goNextScreen } from '../../store/signing-in/actions';
import { selectCurrentUserId } from '../../store/users/selectors';

// components
import { AnotherNextNavButton } from '../shared-styled';
import { HintDescription, HintPositionContainer, HintTitle } from '../hints/shared-styles';
import Portal from '../../components/Portal';
import Hint, { OuterContainer } from '../hints/Hint';
import FakeUserAvatarReactions from '../components/FakeUserAvatarReactions';
import LottieAnimation from '../../animations/LottieAnimation';

import animationData from '../../../../assets/lottie/keyboard-with-emojis-without-reactions.json';

let rootCheckerIntervalId: ReturnType<typeof setInterval> | null = null;

const ReactionsHintScreen = () => {
  const dispath = useDispatch();

  const currentUserId = useSelector(selectCurrentUserId);

  const [hintRoot, setHintRoot] = useState<HTMLElement | null>(null);
  const [animationRoot, setAnimationRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    disableEverything();
    enableElements(`.camera-${currentUserId}`);

    let root = document.getElementById('reactions-hint-anchor');
    if (!root) {
      root = document.createElement('div');
      root.id = 'reactions-hint-anchor';
      document.body.appendChild(root);
    }

    setHintRoot(root);

    // 123-animation
    rootCheckerIntervalId = setInterval(() => {
      const cameraRoot = document.querySelector(`.camera-${currentUserId}`);
      if (cameraRoot) {
        setAnimationRoot(cameraRoot as HTMLElement);
        clearInterval(rootCheckerIntervalId);
      }
    }, 400);

    return () => {
      clearInterval(rootCheckerIntervalId);
    };
  }, [currentUserId]);

  useEffect(() => {
    rootCheckerIntervalId = setInterval(() => {
      const root = document.querySelector(`.camera-${currentUserId}`);
      if (root) {
        setAnimationRoot(root as HTMLElement);
        clearInterval(rootCheckerIntervalId);
      }
    }, 400);

    return () => {
      clearInterval(rootCheckerIntervalId);
    };
  }, [currentUserId]);

  const onHintButtonClick = () => {
    dispath(goNextScreen({}));
  };

  if (!hintRoot || !animationRoot) {
    return null;
  }

  return (
    <>
      <Portal root={hintRoot}>
        <MediaPlayerHintPositionContainer>
          <Hint>
            <HintContainer>
              <FakeUserAvatarReactions />

              <HintTitle>Reactions!</HintTitle>
              <HintDescription>
                When a friend joins, you can <br />
                react to them by pressing the <br />
                number keys.
              </HintDescription>
              <NextButton data-testid="reactions-hint-next-button" onClick={onHintButtonClick}>
                Set Yours Now
              </NextButton>
            </HintContainer>
          </Hint>
        </MediaPlayerHintPositionContainer>
      </Portal>

      <Portal root={animationRoot}>
        <AnimationContainer>
          <LottieAnimation animationData={animationData} loop />
          <AnimationText>Try now!</AnimationText>
        </AnimationContainer>
      </Portal>
    </>
  );
};

const MediaPlayerHintPositionContainer = styled(HintPositionContainer)`
  right: 86px;
  bottom: 134px;
  width: fit-content;
  z-index: 1;

  ${OuterContainer} {
    overflow: revert;
  }
`;

const HintContainer = styled.div`
  padding-left: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NextButton = styled(AnotherNextNavButton)`
  margin: 20px 0 0 0;
`;

const AnimationContainer = styled.div`
  position: relative;
  z-index: 10;
  width: 70%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AnimationText = styled.p`
  margin-top: -5px;
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: bolder;
  white-space: nowrap;
  border-radius: 20px;
  text-shadow: 0 0 11px #000000;
`;

export default ReactionsHintScreen;
