import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ size, color, strokeWidth }) => (
  <svg width={size} height={size} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.14286L5.125 10L12 1" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" />
  </svg>
);

export default CheckIcon;

CheckIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  strokeWidth: PropTypes.string,
};

CheckIcon.defaultProps = {
  size: '12',
  color: 'white',
  strokeWidth: '2',
};
