import adapter from 'webrtc-adapter';
import { checkIsElectron } from './platform-util';

let isSafariBrowser = null;
export function isSafari() {
  if (isSafariBrowser === null) {
    isSafariBrowser =
      /constructor/i.test(window.HTMLElement) ||
      ((p) => p.toString() === '[object SafariRemoteNotification]')(
        !window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification)
      );
  }
  return isSafariBrowser;
}

export function isFirefox() {
  return typeof InstallTrigger !== 'undefined';
}

// Get the current browser name
export function browserName() {
  if (isSafari()) {
    return 'Safari';
  }

  if (isFirefox()) {
    return 'Firefox';
  }

  if (navigator.brave) {
    return 'Brave';
  }

  if (checkIsElectron()) {
    return 'Here Desktop';
  }

  const isChrome = adapter.browserDetails.browser === 'chrome';

  if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge';
  }

  return isChrome ? 'Chrome' : 'Unknown';
}
