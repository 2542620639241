import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  contentSelector: string;
  data: object;
}

// Creates nice drag-n-drop experience with properly set ghost image
// (containing only necessary element without overlapping).
const Draggable = ({ children, contentSelector, data }: Props) => {
  const canvasRef = useRef(null);

  const onDragStart = useCallback(
    (e) => {
      e.dataTransfer.effectAllowed = 'copyMove';

      const canvas = canvasRef.current;
      const content = e.target.matches(contentSelector) ? e.target : e.target.querySelector(contentSelector);
      const rect = content.getBoundingClientRect();

      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(content, 0, 0, rect.width, rect.height);

      const dragX = e.clientX - rect.left;
      const dragY = e.clientY - rect.top;
      e.dataTransfer.setDragImage(canvas, dragX, dragY);
      e.dataTransfer.setData(
        'application/json',
        JSON.stringify({
          ...data,
          dragX,
          dragY,
          width: rect.width,
          height: rect.height,
        })
      );
    },
    [contentSelector, data]
  );

  return (
    <Container draggable onDragStart={onDragStart}>
      {/* TODO: properly detect canvas size */}
      <Canvas ref={canvasRef} width={500} height={500} />
      {children}
    </Container>
  );
};

export default Draggable;

const Container = styled.div`
  cursor: pointer;
`;

const Canvas = styled.canvas`
  position: fixed;
  top: 200%;
  width: 500px;
  height: 500px;
  max-width: none;
`;
