import { isEqual } from 'lodash';
import log from '../../log';

const INTERVAL_OFFSET = 100;

export enum ConditionType {
  HasNotChangedInterval = 'do-not-changed-during-interval',
  HasChangedInterval = 'has-changed-during-interval',
}

export interface Condition {
  type: ConditionType;
  intervalMs: number;
  callback: (currentValue: unknown, previousValues: unknown[]) => void;
}

export class MonitorValue {
  private previousValues: unknown[];

  private currentValue: unknown;

  private condition: Condition;

  private timer: ReturnType<typeof setTimeout>;

  constructor(
    conditionType: ConditionType,
    intervalMs: number,
    callback: (oldValue: unknown, newValue: unknown) => void
  ) {
    log.debug(`MonitorValue: condition: ${conditionType} interval: ${intervalMs}`);
    this.condition = { type: conditionType, intervalMs, callback };
  }

  set(newValue: unknown) {
    if (!this.timer) {
      this.timer = setTimeout(this.checkCondition.bind(this), this.condition.intervalMs + INTERVAL_OFFSET);
      this.previousValues = [];
      log.warn(`MonitorValue: starting timer of ${this.condition.intervalMs / 1000} seconds for value: ${newValue}`);
    }

    if (this.currentValue) this.previousValues.push(this.currentValue);

    this.currentValue = newValue;
  }

  private checkCondition() {
    clearTimeout(this.timer);
    this.timer = null;

    let notify = false;

    if (
      this.previousValues.find((pv) => !isEqual(pv, this.currentValue)) &&
      this.condition.type === ConditionType.HasChangedInterval
    ) {
      notify = true;
    }

    if (
      this.previousValues.filter((pv) => isEqual(pv, this.currentValue)).length === this.previousValues.length &&
      this.condition.type === ConditionType.HasNotChangedInterval
    ) {
      notify = true;
    }

    if (notify) {
      log.debug(
        `MonitorValue: Triggering monitoring condition: ${this.condition.type} interval: ${
          this.condition.intervalMs
        } for current: ${this.currentValue} previous values: ${JSON.stringify(this.previousValues)}`
      );
      this.condition.callback(this.currentValue, this.previousValues);
    }
  }
}
