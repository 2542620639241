import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { startReactionInNode } from '../../../reactions';
import { getRandomNumberInRange } from '../../../util';

// components
import { bounceInAnimation } from '../shared-styled';
import { hintContainerSlideDownMs } from '../hints/shared-styles';

// timeouts
let outerTimeoutId: ReturnType<typeof setTimeout> | null = null;
let innerTimeoutId: ReturnType<typeof setTimeout> | null = null;

const animationDurationMs = 550;

const FakeUserAvatarReactions = () => {
  const ref = useRef(null);

  const [isAnimationVisible, setIsAnimationVisible] = useState(false);

  useEffect(() => {
    outerTimeoutId = setTimeout(() => {
      setIsAnimationVisible(true);

      innerTimeoutId = setTimeout(() => {
        setIsAnimationVisible(false);
      }, animationDurationMs);
    }, hintContainerSlideDownMs);

    return () => {
      clearTimeout(outerTimeoutId);
      clearTimeout(innerTimeoutId);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      startReactionInNode('👋', ref.current, getRandomNumberInRange(0, 200), getRandomNumberInRange(0, 200));
    }, 750);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <FakeUserAvatarContainer ref={ref}>
      {isAnimationVisible ? (
        <ShowUpAnimationImage height={480} width={480} src="gif/fake-user-show-up.gif" alt="confetti" />
      ) : null}

      <Image height={210} width={210} src="images/onboarding/fake-user-avatar.gif" alt="fake user avatar" />
    </FakeUserAvatarContainer>
  );
};

const FakeUserAvatarContainer = styled.div`
  position: absolute;
  top: 40px;
  left: -60px;
`;

const delayForGifToPlayMs = 200;

const Image = styled.img`
  height: 210px;
  width: 210px;
  object-fit: cover;
  object-position: -46px 0;
  border-radius: 100%;
  transform: scale(0);
  animation: ${() =>
    css`
      450ms ${bounceInAnimation} linear ${hintContainerSlideDownMs + delayForGifToPlayMs}ms both
    `};
`;

const ShowUpAnimationImage = styled.img`
  position: fixed;
  height: 480px;
  width: 480px;
  margin-left: -140px;
  margin-top: -140px;
`;

export default FakeUserAvatarReactions;
