import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// store
import { selectFriendsInOrderOfOnlineStatus } from '../../store/friends/selectors';

// components
import { svgColorMixin } from '../../mixins';
import HereModal from '../../components/HereModal';

// icons
import PointedChevronLeftIcon from '../../../../assets/icons/arrows/pointed-chevron-left.svg';
import UsersSelect from '../../components/users-select';

interface AddFriendsToRoomModalProps {
  setOpen: (open: boolean) => void;
  continueToRoom: () => void;
  invitesSent: { [key: string]: boolean };
  onFriendInviteClick: (userId: string) => void;
  modalTitle?: string;
  modalButtonText?: string;
}

const AddFriendsToRoomModal = ({
  setOpen,
  continueToRoom,
  invitesSent,
  onFriendInviteClick,
  modalTitle = 'Add Friends to Room',
  modalButtonText = 'Add to Room',
}: AddFriendsToRoomModalProps) => {
  const friendsList = useSelector(selectFriendsInOrderOfOnlineStatus);
  const selectedFriendsCount = useMemo(() => Object.values(invitesSent).filter((item) => item).length, [invitesSent]);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return ReactDOM.createPortal(
    <HereModal onOverlayClick={closeModal}>
      <DialogContent>
        <DialogTitleContainer>
          <CloseDialogButton onClick={closeModal}>
            <PointedChevronLeftIcon width="6" />
            Close
          </CloseDialogButton>

          <DialogTitle>{modalTitle}</DialogTitle>
        </DialogTitleContainer>

        <UsersSelect usersList={friendsList} selectedIds={invitesSent} onToggle={onFriendInviteClick} />

        <SendInvitesButton onClick={continueToRoom} disabled={!selectedFriendsCount}>
          <SendInvitesButtonTitleText>{modalButtonText}</SendInvitesButtonTitleText>
          {selectedFriendsCount ? <SendInvitesButtonText>{selectedFriendsCount} selected</SendInvitesButtonText> : null}
        </SendInvitesButton>
      </DialogContent>
    </HereModal>,
    document.getElementById('invite-friends-modal')
  );
};

const DialogContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 33px 37px;
  margin: auto;
  height: 650px;
  width: 500px;
  z-index: 100000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
`;

const DialogTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogTitle = styled.h3`
  color: #12002d;
  font-weight: bolder;
  font-size: 20px;
`;

const CloseDialogButton = styled.button`
  position: absolute;
  left: 33px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #6b1be3;
  font-weight: bolder;
  font-size: 12px;
  background: none;
  border: none;
  cursor: pointer;

  ${svgColorMixin('#6b1be3')}

  &:hover {
    text-decoration: underline;
  }
`;

const SendInvitesButton = styled.button`
  margin-top: 31px;
  padding: 9px;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  border-radius: 10px;
  background-color: #6b1be3;
  cursor: pointer;

  &:disabled {
    background-color: #6b1be333;
    cursor: not-allowed;
  }
`;

const SendInvitesButtonTitleText = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bolder;
`;

const SendInvitesButtonText = styled.span`
  color: #ffffff7f;
  font-size: 12px;
  font-weight: bolder;
`;

export default AddFriendsToRoomModal;
