import UIkit from 'uikit';
import firebase from '../firebase';
import log from '../log';
import { boardUsers } from '../presence';
import sessionLog from '../session-log.ts';
import { track } from './analytics-util';
import { isRoomPublic } from './public-rooms-util';
import { createUserReport } from '../api/reports-api.ts';

let reportedUser;
let reportCategory;

export function showReportModal(member) {
  reportedUser = member;
  document.getElementById('report-user-main-modal').style.display = 'block';
  const modalEl = document.getElementById('report-user-notice');
  modalEl.querySelector('.report-user-title').textContent = `Report ${reportedUser.name} to Here's Safety Team`;
  const modal = UIkit.modal(modalEl);
  showReportOptions();
  modal.show();
}

export function setReportCategory(category) {
  reportCategory = category;
}

export function showReportOptions() {
  hideReportUi();
  document.getElementById('report-user-option-buttons').style.display = 'block';
}

export function showReportDetails() {
  hideReportUi();
  document.getElementById('report-user-option-details').style.display = 'block';
  const reportDescription = document.getElementById('report-user-description');
  reportDescription.value = '';
  reportDescription.focus();
}

export function showReportSuccess() {
  hideReportUi();
  document.getElementById('report-user-main-modal').style.display = 'none';
  document.getElementById('report-user-option-success').style.display = 'block';
}

function hideReportUi() {
  document.getElementById('report-user-option-buttons').style.display = 'none';
  document.getElementById('report-user-option-details').style.display = 'none';
  document.getElementById('report-user-option-success').style.display = 'none';
}

export async function sendReport() {
  try {
    const sessionId = sessionLog.currentActivityId ? sessionLog.currentActivityId : '';
    const notes = document.getElementById('report-user-description').value;
    const currentUserId = firebase.auth().currentUser.uid;
    const currentUserName = boardUsers[window.currentBoardId][currentUserId].name;
    const currentUserEmail = firebase.auth().currentUser.email;
    const data = {
      reportedUser,
      reportedBy: { name: currentUserName, email: currentUserEmail, id: currentUserId },
      timestamp: new Date(),
      board: { id: window.currentBoardId, title: window.currentBoardData.title },
      category: reportCategory,
      notes,
      sessionId,
      isPublic: isRoomPublic(window.currentBoardData),
    };
    await createUserReport(data);
    track('Sent User Report', { category: data.category });
  } catch (error) {
    log.error('Something bad happened submitting user report:', error);
  } finally {
    showReportSuccess();
  }
}
