import React from 'react';
import PropTypes from 'prop-types';

const DemoteIcon = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 6.06215C5.433 2.7141 9.71413 1.56698 13.0622 3.49997C16.4102 5.43297 17.5574 9.7141 15.6244 13.0622C13.6914 16.4102 9.41023 17.5573 6.06218 15.6243C4.49737 14.7209 3.41334 13.3045 2.89985 11.7102" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.26807 4.19611L3.00012 6.92816L5.73217 6.19611" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default DemoteIcon;

DemoteIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

DemoteIcon.defaultProps = {
  size: '20',
  color: 'black',
};
