import React from 'react';

// store
import { useUserProfileFromStore } from '../../../../hooks/useUserProfileFromStore';

// components
import FirstRoomTopMember from './FirstRoomTopMember';
import RoomTopMember from './RoomTopMember';

interface RoomTopMemberDataWrapperProps {
  userId: string;
  position: number;
  activeMinutes: number;
  isSelf?: boolean;
}

const RoomTopMemberDataWrapper = ({ userId, position, activeMinutes, isSelf = false }: RoomTopMemberDataWrapperProps) => {
  const { userProfile } = useUserProfileFromStore(userId);

  if (position === 1) {
    return (
      <FirstRoomTopMember
        avatarUrl={userProfile?.avatar?.thumbnail}
        displayName={userProfile?.displayName}
        activeMinutes={activeMinutes}
      />
    );
  }

  return (
    <RoomTopMember
      position={position}
      avatarUrl={userProfile?.avatar?.thumbnail}
      displayName={userProfile?.displayName}
      activeMinutes={activeMinutes}
      isSelf={isSelf}
    />
  );
};

export default React.memo(RoomTopMemberDataWrapper);
