import { createContext } from 'react';

import { GroupPermissions } from '../../definitions/groups';

// Used for setting analytics events 'source' property for all nested components.
export const EventSourceContext = createContext(null);

// Separate context for each board element
export const BoardElementControllerContext = createContext(null);

// Context for reading and controlling the viewport from anywhere.
export const ViewportControllerContext = createContext(null);

// Controlling board (e.g. adding new elements) from anywhere inside the board.
export const BoardControllerContext = createContext(null);

export const RoomChatInputContext = createContext<{ elementId: string; caller: string; fontStyleSource: string }>(null);

export const ChatPermissionsContext = createContext<GroupPermissions>(null);
