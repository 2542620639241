import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './init-state';
import {
  setCreatedGroupId,
  createGroup,
  joinGroup,
  joinGroupSuccess,
  joinGroupError,
  deleteGroup,
  deleteGroupSuccess,
  deleteGroupError,
  transferRoomToGroupRequest,
  transferRoomToGroupSuccess,
  transferRoomToGroupError,
  deleteRoomFromGroupRequest,
  deleteRoomFromGroupSuccess,
  deleteRoomFromGroupError,
  setCreateGroupError,
  setCreateGroupSuccess,
} from './actions';

export const groupsReducer = createReducer(initialState, (builder) => {
  builder.addCase(createGroup, (state) => {
    state.isLoadingCreateGroup = true;
    state.createGroupError = '';
  });
  builder.addCase(setCreateGroupError, (state, action) => {
    state.isLoadingCreateGroup = false;
    state.createGroupError = action.payload.error;
  });
  builder.addCase(setCreateGroupSuccess, (state) => {
    state.isLoadingCreateGroup = false;
  });
  builder.addCase(setCreatedGroupId, (state, action) => {
    state.createdGroupId = action.payload.groupId;
  });
  builder.addCase(joinGroup, (state) => {
    state.isLoadingJoinGroup = true;
    state.joinedGroupId = '';
    state.joinGroupError = '';
  });
  builder.addCase(joinGroupSuccess, (state, { payload }) => {
    state.isLoadingJoinGroup = false;
    state.joinedGroupId = payload.group.id;
  });
  builder.addCase(joinGroupError, (state, { payload }) => {
    state.isLoadingJoinGroup = false;
    state.joinGroupError = payload.error;
  });
  builder.addCase(deleteGroup, (state) => {
    state.isLoadingDeleteGroup = true;
    state.deleteGroupError = '';
  });
  builder.addCase(deleteGroupSuccess, (state) => {
    state.isLoadingDeleteGroup = false;
  });
  builder.addCase(deleteGroupError, (state, { payload }) => {
    state.isLoadingDeleteGroup = false;
    state.deleteGroupError = payload.error;
  });
  builder.addCase(transferRoomToGroupRequest, (state) => {
    state.isLoadingTransferRoomToGroup = true;
    state.transferRoomToGroupError = '';
  });
  builder.addCase(transferRoomToGroupSuccess, (state) => {
    state.isLoadingTransferRoomToGroup = false;
  });
  builder.addCase(transferRoomToGroupError, (state, { payload }) => {
    state.isLoadingTransferRoomToGroup = false;
    state.transferRoomToGroupError = payload.error;
  });
  builder.addCase(deleteRoomFromGroupRequest, (state) => {
    state.isLoadingDeleteRoomFromGroup = true;
    state.deleteRoomFromGroupError = '';
  });
  builder.addCase(deleteRoomFromGroupSuccess, (state) => {
    state.isLoadingDeleteRoomFromGroup = false;
  });
  builder.addCase(deleteRoomFromGroupError, (state, { payload }) => {
    state.isLoadingDeleteRoomFromGroup = false;
    state.deleteRoomFromGroupError = payload.error;
  });
});
