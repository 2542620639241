// TODO: Move this out of groups as a fallback theme value
// Theme defaults:
export const DEFAULT_PRIMARY_BACKGROUND = '#12002d';
export const DEFAULT_PRIMARY_FOREGROUND = '#ffffff';
export const DEFAULT_SECONDARY_BACKGROUND = '#6b1be3';
export const DEFAULT_SECONDARY_FOREGROUND = '#ffffff';
export const DEFAULT_THEME_COLORS = {
  primaryBackground: DEFAULT_PRIMARY_BACKGROUND,
  primaryForeground: DEFAULT_PRIMARY_FOREGROUND,
  secondaryBackground: DEFAULT_SECONDARY_BACKGROUND,
  secondaryForeground: DEFAULT_SECONDARY_FOREGROUND,
};
export const DEFAULT_THEME_FONT = 'Inter';
