import React from 'react';
import ReactDOM from 'react-dom';

import OutgoingFriendRequestNotifications from './friend-request-notification/OutgoingFriendRequestNotifications';
import { WithRoomStore } from '../store/Wrapper';
import FriendsModal from './friends-modal/FriendsModal';
import NewMemberFriendSuggestion from './NewMemberFriendSuggestion';
import OutsideReactionFriendSuggestion from './OutsideReactionFriendSuggestion.tsx';
import UserFriendsListModal from './UserFriendsListModal.tsx';

export function renderFriendNotificationsContainer() {
  const notificationsContainer = document.getElementById('friend-notifications');
  ReactDOM.render(
    <WithRoomStore>
      <OutgoingFriendRequestNotifications />
    </WithRoomStore>,
    notificationsContainer
  );
}

export function renderFriendsModal(defaultOpenTab) {
  ReactDOM.render(
    <WithRoomStore>
      <FriendsModal defaultOpenTab={defaultOpenTab} />
    </WithRoomStore>,
    document.getElementById('friends-modal')
  );
}

export function renderUserFriendsModal(userId) {
  ReactDOM.render(
    <WithRoomStore>
      <UserFriendsListModal userId={userId} />
    </WithRoomStore>,
    document.getElementById('friends-modal')
  );
}

export function renderNewMemberFriendSuggestionContainer() {
  ReactDOM.render(
    <WithRoomStore>
      <NewMemberFriendSuggestion />
    </WithRoomStore>,
    document.getElementById('new-member-friend-suggestion')
  );
}

export function renderOutsideReactionFriendSuggestion() {
  ReactDOM.unmountComponentAtNode(document.getElementById('outside-reaction-friend-suggestion'));
  ReactDOM.render(
    <WithRoomStore>
      <OutsideReactionFriendSuggestion />
    </WithRoomStore>,
    document.getElementById('outside-reaction-friend-suggestion')
  );
}
