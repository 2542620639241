import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChatBackgroundPicker from '../../create-chat/components/ChatBackgroundPicker';
import { selectChatByChatId } from '../../../../store/messaging/selectors';
import { requestGroupUpdate } from '../../../../store/groups/actions';
import { getGroupChatParams, track } from '../../../../../util/analytics-util';
import { CHAT_EDIT_SKIN } from '../../../analytics';
import { SkinBackground } from '../../../../store/chat-skinning/init-state';

interface Props {
  chatId: string;
}

const SkinningBackgroundPicker = ({ chatId }: Props) => {
  const dispatch = useDispatch();

  const chat = useSelector((state) => selectChatByChatId(state, chatId));

  const handleBackgroundChange = useCallback(
    (background: SkinBackground) => {
      dispatch(
        requestGroupUpdate({
          id: chat.groupId,
          updates: {
            background: background ? background.original : null,
            theme: {
              isCustom: true,
              skin: {
                ...(chat?.theme?.skin || {}),
                name: 'custom',
                background: background
                  ? {
                      ...background,
                      type: undefined,
                      created_at: undefined,
                      deleted_at: undefined,
                      size: undefined,
                      employeesOnly: undefined,
                    }
                  : null,
              },
            },
          },
        })
      );

      const analyticsParams = {
        chatId,
        name: background?.name || '',
        oldName: chat?.theme?.skin?.background?.name || '',
        category: 'background',
      };
      track(CHAT_EDIT_SKIN, getGroupChatParams(analyticsParams, chat.groupId));
    },
    [dispatch, chat.groupId, chat?.theme?.skin, chatId]
  );

  // TODO: Move ChatBackgroundPicker code to this component after the skinning is added to chat creation flow.
  return (
    <ChatBackgroundPicker
      onChange={handleBackgroundChange}
      currentBackgroundUrl={chat?.background?.thumbnail || chat?.background?.original}
    />
  );
};

export default SkinningBackgroundPicker;
