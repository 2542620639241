import React from 'react';
import styled from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { EmojiData, Picker } from 'emoji-mart';

// components
import { chatButtonMixin } from '../shared-styles';

// icons
import EmojiIcon from '../../../../../assets/icons/emoji.svg';

interface EmojiPickerButtonProps {
  onEmojiSelected: (emoji: EmojiData) => void;
}

const EmojiPickerButton = ({ onEmojiSelected }: EmojiPickerButtonProps) => (
  <Popover>
    <PopoverTrigger asChild>
      <Button>
        <EmojiIcon />
      </Button>
    </PopoverTrigger>

    <PopoverContent side="top" className="prevent-on-click-outside">
      <Picker
        set="twitter"
        sheetSize={16}
        emojiSize={22}
        perLine={9}
        showPreview={false}
        showSkinTones={false}
        onSelect={onEmojiSelected}
        native
      />
    </PopoverContent>
  </Popover>
);

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverContent = styled(PopoverPrimitive.Content)`
  background-color: white;
  border-radius: 10px;
  outline: none;
`;

const Button = styled.button`
  ${chatButtonMixin()}
`;

export default EmojiPickerButton;
