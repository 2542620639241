// README:
//
// Legacy Electron screensharing that we support as backward compatiblity, do not
// rely on this function anymore, we now use session#setDisplayMediaRequestHandler instead.
//
// In this file you will notice there is a few duplicated methods to check platform, the reason is to avoid
// dome dependency cycles while handle backward support easily and flexible to just remove a few lines of code
// and this file to get rid of this in the upcomming months.

import log from '../log';
import { track } from '../util/analytics-util';

function getOSType() {
  if (window.bridge) {
    return window.bridge.getOSType();
  }

  log.error('Cannot get OS type from Electron, not in the electron app');
  return null;
}

export function isWindowsOS() {
  const osType = getOSType();
  if (osType) {
    return osType.toLowerCase().startsWith('win');
  }

  return false;
}

function getMediaStreamConstraints(source) {
  if (isWindowsOS()) {
    return {
      audio: {
        mandatory: {
          echoCancellation: false,
          chromeMediaSource: 'desktop',
          chromeMediaSourceId: source.id,
        },
      },

      video: {
        mandatory: {
          chromeMediaSource: 'desktop',
          chromeMediaSourceId: source.id,
        },
      },
    };
  }

  return {
    audio: false,

    video: {
      mandatory: {
        chromeMediaSource: 'desktop',
        chromeMediaSourceId: source.id,
      },
    },
  };
}

export async function getDisplayStreamSourceLegacy(source) {
  let constraints;
  let stream;

  try {
    constraints = getMediaStreamConstraints(source);
    track('Get User Media', constraints, { isElectron: true });
    stream = await navigator.mediaDevices.getUserMedia(constraints);

    log.debug('Got stream for screenshare', stream);
  } catch (err) {
    try {
      if (err.message.match(/Could not start audio source/i)) {
        trackErrorForConstraints(err, constraints);
        delete constraints.audio;
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        log.debug('Got stream for screenshare', stream);
      }
      trackErrorForConstraints(err, constraints);
    } catch (e) {
      trackErrorForConstraints(e, constraints);
    }
  }
  return stream;
}

function trackErrorForConstraints(error, constraints) {
  track('Get User Media Error', {
    constraints,
    error: error.name,
    message: error.message,
    action: 'getDisplayStreamSourceLegacy',
    isElectron: true,
  });
  log.error('Error starting stream', error);
}
