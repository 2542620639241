import React from 'react';

import { TimerContainerCellContent, TimerContainerGrid } from '../shared-styles';

const CELLS_IN_GRID_COUNT = 12;

const ErrorState = () => (
  <TimerContainerGrid>
    {Array(CELLS_IN_GRID_COUNT)
      .fill(0)
      .map(() => (
        <TimerContainerCellContent />
      ))}
  </TimerContainerGrid>
);

export default ErrorState;
