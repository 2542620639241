import { createSelector } from '@reduxjs/toolkit';

import { orderObjectsByDateField } from '../../../util/date-util';
import { messageTypes } from '../../../constants/chat-constants';
import { allUsers, selectCurrentUserId } from '../users/selectors';
import { selectOpenChatIds } from '../os/selectors.ts';
import { OnlineStatus } from '../../admin/status/enums/onlineStatus';
import { defaultChatPermissions } from '../../chats/constants.ts';
import { ChatMemberRoles } from '../../../definitions/message.ts';

export const selectChatByChatId = (state, chatId) => state.messages.chats[chatId];
export const selectChatByGroupId = (state, groupId) =>
  Object.values(state.messages.chats).find((chat) => chat?.groupId === groupId);

export const messagesByChatIdAsArraySelector = (state, chatId) =>
  state.messages.chats[chatId] &&
  state.messages.chats[chatId].messages &&
  Object.values(state.messages.chats[chatId].messages)
    .map((message) => ({ ...message, createdAt: new Date(message.createdAt) }))
    .sort((a, b) => a.createdAt - b.createdAt);

export const selectMessageQueue = (state) => state.messages.messageQueue;

export const selectMessageQueueByChat = (state, chatId) =>
  state.messages.messageQueue
    .filter((message) => message.chatId === chatId)
    .map((message) => ({ ...message, queue: true, createdAt: new Date(message.createdAt) }));

export const selectFailedMessagesByChat = (state, chatId) =>
  state.messages.failedMessages
    .filter((message) => message.chatId === chatId)
    .map((message) => ({ ...message, sendingFailed: true, createdAt: new Date(message.createdAt) }));

export const selectSendingQueuedMessage = (state) => state.messages.sendingQueuedMessage;

export const selectAllChatMessages = createSelector(
  [messagesByChatIdAsArraySelector, selectMessageQueueByChat, selectFailedMessagesByChat],
  (savedMessages = [], localMessages = [], failedMessages = []) =>
    [...savedMessages, ...localMessages, ...failedMessages].sort((a, b) => a.createdAt - b.createdAt)
);

export const messagesByChatIdSelector = (state, chatId) =>
  state.messages.chats[chatId] && state.messages.chats[chatId].messages;

// For DMs
export const selectReceiverByChatId = createSelector(
  [selectCurrentUserId, selectChatByChatId],
  (currentUserId, chat) => {
    if (chat && chat.isDm) {
      const otherMembers = Object.keys(chat.chatMembers).filter((member) => member !== currentUserId);
      if (otherMembers.length > 0) {
        return otherMembers[0];
      }
    }

    return null;
  }
);

// returns chats that you and receiver are a part of:
export const selectChatByReceiverId = (state, receiverId) =>
  [...Object.values(state.messages.chats), ...Object.values(state.messages.localChats)].filter(
    (chat) => chat?.chatMembers[receiverId] && chat.isDm
  );

export const chatsAsArraySelector = (state) => Object.values(state.messages.chats);

export const selectChatsWithMembers = (state) => {
  const chats = Object.values(state.messages.chats);
  const localChats = Object.values(state.messages.localChats);
  return [...chats, ...localChats].map((chat) => {
    const memberId = Object.keys(chat?.chatMembers || {})[0];
    const member = state.users.users[memberId] || {};

    return {
      ...chat,
      member,
    };
  });
};

export const selectDMChats = createSelector([selectChatsWithMembers], (chats) => chats.filter((chat) => chat.isDm));

export const selectDraftMessage = (state, chatId) => state.messages.chats[chatId]?.draft;

export const selectActiveReceiverId = (state) => state.messages.activeReceiverId;
export const selectActiveChatId = (state) => state.messages.activeChatId;
export const selectActiveGroupChatId = (state) => state.messages.activeGroupChatId;

export const selectGroupIdFromChatId = (state, chatId) => state.messages.chats[chatId]?.groupId;

export const selectMessagesSidebarOpen = (state) => state.messages.messagesSidebarOpen;

export const activeChatIdSelector = (state) => {
  const activeReceiverId = selectActiveReceiverId(state);

  if (!activeReceiverId) {
    return null;
  }

  const chatIds = Object.keys(state.messages.chats);

  return chatIds.find((key) => {
    const chat = state.messages.chats[key];

    return chat?.chatMembers[activeReceiverId];
  });
};

export const selectAllPopupMessages = createSelector(
  [(state) => state.messages.popupMessages, (state) => state.messages.chats],
  (popupMessages, chats) => {
    const messages = Object.values(popupMessages).map((message) => ({
      ...chats[message.chatId]?.messages[message.messageId],
    }));

    return orderObjectsByDateField(messages, 'createdAt');
  }
);

export const selectPopupMessages = createSelector([selectAllPopupMessages], (messages) => {
  const invitePopups = messages.filter((message) => message.type === messageTypes.INVITE);
  if (invitePopups.length) {
    return invitePopups;
  }

  return messages;
});

export const selectInvitePopupMessages = createSelector([selectAllPopupMessages], (messages) =>
  messages.filter((message) => message.type === messageTypes.INVITE)
);

export const selectOsPopupMessages = createSelector(
  [selectAllPopupMessages, selectOpenChatIds],
  (messages, openChatIds) => messages.filter((message) => !openChatIds.includes(message.chatId))
);

export const selectLoadingMessages = (state) => state.messages.loadingMessages;

export const selectChatHasMore = (state, chatId) => (chatId ? state.messages.chats[chatId]?.hasMore : false);
export const selectHasMoreChats = (state) => state.messages.hasMoreChats;

export const selectOldestMessageInChat = createSelector(
  [(state) => state.messages.chats, (state, chatId) => chatId],
  (chats, chatId) => {
    const messages = Object.values(chats[chatId].messages);
    return messages.reduce((acc, item) => (item.createdAt < acc.createdAt ? item : acc), messages[0]);
  }
);

export const selectOldestChat = createSelector([(state) => state.messages.chats], (msgChats) => {
  const chats = Object.values(msgChats);
  return chats.reduce((acc, item) => (item.chatUpdatedAt < acc.chatUpdatedAt ? item : acc), chats[0]);
});

export const selectChatIdsWithActiveInviteCalls = (state) =>
  Object.values(state.messages.activeInviteCalls).map(({ chatId }) => chatId);

export const selectLoadingChats = (state) => state.messages.loadingChats;

export const selectAllUnreads = (state) =>
  Object.values(state.messages.chats).reduce((acc, chat) => acc + (chat.unreadCount || 0), 0);

export const selectAllDMUnreads = (state) =>
  Object.values(state.messages.chats).reduce((acc, chat) => acc + (chat.isDm ? chat.unreadCount || 0 : 0), 0);

// TODO: update this -- we shouldn't be storing groupName on chat here
export const selectActiveChatGroupName = createSelector(
  [(state) => state.messages.chats, selectActiveGroupChatId],
  (chats, activeGroupChatId) => chats[activeGroupChatId]?.groupName
);

// returns chat that is a 1-1 DM with the receiver
export const selectDMChatByReceiverId = (state, receiverId) =>
  [...Object.values(state.messages.chats), ...Object.values(state.messages.localChats)].filter(
    (chat) => Object.keys(chat?.chatMembers || {}).includes(receiverId) && chat?.isDm
  );

export const selectChatMembersByChatId = (state, chatId) => state.messages.chats[chatId]?.chatMembers || {};
export const selectChatMemberIdsByChatId = createSelector([selectChatMembersByChatId], (chatMembers) =>
  Object.keys(chatMembers)
);
export const selectMemberRoleInChat = createSelector(
  [selectChatMembersByChatId, (state, chatId, memberId) => memberId],
  (members, memberId) => members[memberId]?.role
);

export const selectFullChatMembersByChatId = createSelector(
  [selectChatMembersByChatId, (state) => state.users.users],
  (chatMembers, users) => Object.keys(chatMembers || []).map((memberId) => users[memberId] || {})
);

export const selectFullChatMembersWithSelfByChatId = createSelector(
  [selectChatMembersByChatId, allUsers, selectCurrentUserId, selectChatByChatId],
  (chatMembers, users, currentUserId, chat) =>
    Object.keys(chatMembers || [])
      .map(
        (memberId) =>
          ({ ...users[memberId], role: chatMembers[memberId].role, isBanned: chatMembers[memberId].isBanned } || {})
      )
      .concat({ ...users[currentUserId], role: chat?.role } || {})
);

export const selectBannedChatMembers = createSelector([selectFullChatMembersWithSelfByChatId], (chatMembers) =>
  chatMembers.filter((m) => m.isBanned)
);

const rolePriority = {
  [ChatMemberRoles.Owner]: 1,
  [ChatMemberRoles.Admin]: 2,
  [ChatMemberRoles.Member]: 3,
};
// ordered by role
export const selectOrderedFullChatMembersWithSelfByChatId = createSelector(
  [selectFullChatMembersWithSelfByChatId],
  (chatMembers) => chatMembers.sort((a, b) => rolePriority[a.role] - rolePriority[b.role])
);
export const selectOrderedOwnerAndAdmins = createSelector([selectOrderedFullChatMembersWithSelfByChatId], (members) =>
  members.filter((m) => m.role !== ChatMemberRoles.Member)
);

export const selectIsDmByChatId = (state, chatId) => state.messages.chats[chatId]?.isDm;

export const selectCurrentUserRoleInChat = createSelector([selectChatByChatId], (chat) => chat?.role || '');

export const selectChatMemberIdsAsArray = (state, chatId) =>
  state.messages.chats[chatId]?.chatMembers && Object.keys(state.messages.chats[chatId]?.chatMembers);

export const selectChatMemberIdsWithoutCurrentUser = createSelector(
  [selectChatMemberIdsAsArray, selectCurrentUserId],
  (memberIds, currentUserId) => memberIds?.filter((memberId) => memberId !== currentUserId) || []
);

// Note: this selector filters out self
export const selectChatMembersWithoutSelfAsArray = createSelector(
  [selectChatMemberIdsAsArray, allUsers, selectCurrentUserId],
  (memberIds, users, currentUserId) =>
    memberIds
      ?.filter((memberId) => memberId !== currentUserId)
      .map((memberId) => users[memberId])
      .filter((user) => user !== undefined) || []
);

export const selectOnlineChatMembersWithoutSelf = createSelector(
  [selectChatMembersWithoutSelfAsArray],
  (groupMembers) => groupMembers.filter((member) => member.onlineStatus?.status === OnlineStatus.Online)
);

export const selectIsNotificationsOn = createSelector(
  [(state, chatId) => state.messages.chats[chatId]],
  (chat) => chat?.isNotificationsOn
);

export const selectChatMembersWithGroupId = createSelector(
  [selectChatByGroupId],
  (chat) => (chat?.chatMembers && Object.keys(chat?.chatMembers)) || []
);

export const selectChatMembersWithoutSelfWithGroupId = createSelector(
  [selectChatMembersWithGroupId, selectCurrentUserId],
  (memberIds, currentUserId) => memberIds.filter((memberId) => memberId !== currentUserId)
);

export const selectOnlineChatMembersWithoutSelfWithGroupId = createSelector(
  [selectChatMembersWithoutSelfWithGroupId, allUsers],
  (memberIds, users) =>
    memberIds.map((memberId) => users[memberId]).filter((user) => user.onlineStatus?.status === OnlineStatus.Online)
);

export const selectMembersByGroupId = createSelector([selectChatByGroupId], (chat) => chat?.chatMembers || {});

export const selectBoardsByGroupId = createSelector([selectChatByGroupId], (chat) => chat?.boards || []);

export const selectChatIdByGroupId = createSelector([selectChatByGroupId], (chat) => chat?.id || '');

export const selectChatPermissions = createSelector(
  [selectChatByChatId],
  (chat) => chat?.permissions || defaultChatPermissions
);

export const selectPublicChatIds = (state) => state.messages.publicChatIds;
export const selectPublicChats = (state) => state.messages.publicChats;
export const selectPublicChatsArray = createSelector(
  [selectPublicChatIds, selectPublicChats],
  (publicChatIds, publicChats) => publicChatIds.map((chatId) => publicChats[chatId])
);
export const selectPublicChatById = (state, chatId) => state.messages.publicChats[chatId];
export const selectPublicChatMessages = createSelector(
  [selectPublicChatById],
  (chat) => chat?.messages?.map((m) => ({ ...m, createdAt: new Date(m.createdAt) })) || []
);
export const selectPublicChatMessagesForPreview = createSelector(
  [selectPublicChatMessages],
  (messages) => messages?.reverse()?.slice(-10) || []
);
export const selectIsAPublicChat = createSelector([selectChatByChatId], (chat) => chat?.isPublic);
