import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';

// components
import FontSelect from '../../../room/settings/theme/FontSelect';
import { chatButtonMixin } from '../shared-styles';

// constants
import { chatFontSizeOptions } from '../../input-entry/fonts';

// icons
import TextIcon from '../../../../../assets/icons/text.svg';
import ChevronDownIcon from '../../../../../assets/icons/arrows/chevron-down.svg';
import { svgColorMixin } from '../../../mixins';

interface FontStyleButtonProps {
  id: string;
  currentFontFamily: string;
  currentFontSize: string;
  currentFontColor: string;
  fontStyleDropdownDirection?: 'up' | 'down';
  onFontChange: (font: string) => void;
  onFontSizeChange: (fontSize: string) => void;
  onFontColorPick: (color: string) => void;
}

const FontStyleButton = ({
  id,
  currentFontFamily,
  currentFontSize,
  currentFontColor,
  fontStyleDropdownDirection = 'down',
  onFontChange,
  onFontSizeChange,
  onFontColorPick,
}: FontStyleButtonProps) => {
  const colorPickerRef = useRef(null);

  const [isStylePopoverOpen, setIsStylePopoverOpen] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isFontSizePickerOpen, setIsFontSizePickerOpen] = useState(false);

  useEffect(() => {
    const colorPicker = colorPickerRef.current;
    if (isStylePopoverOpen && isColorPickerOpen && colorPicker) {
      colorPicker.addEventListener('colorchange', onFontColorPick);
    } else {
      colorPicker?.removeEventListener('colorchange', onFontColorPick);
    }

    return () => {
      colorPicker?.removeEventListener('colorchange', onFontColorPick);
    };
  }, [isStylePopoverOpen, isColorPickerOpen, onFontColorPick]);

  const onFontStylePopoverOpenChange = useCallback((open) => {
    setIsStylePopoverOpen(open);
    if (!open) {
      setIsColorPickerOpen(false);
    }
  }, []);

  const handleFontSizeChange = (fontSize: string) => {
    setIsFontSizePickerOpen(false);
    onFontSizeChange(fontSize);
  };

  return (
    <Popover onOpenChange={onFontStylePopoverOpenChange}>
      <PopoverTrigger asChild>
        <Button>
          <TextIcon />
        </Button>
      </PopoverTrigger>

      <PopoverContent side="top" className="unthemed prevent-on-click-outside">
        <Controls>
          <FontFamilyPickerContainer>
            <FontSelect
              id={id}
              label=""
              dropdownDirection={fontStyleDropdownDirection}
              currentFont={currentFontFamily}
              onFontChange={onFontChange}
            />
          </FontFamilyPickerContainer>

          <Popover open={isFontSizePickerOpen} onOpenChange={setIsFontSizePickerOpen}>
            <PopoverTrigger asChild>
              <FontSizeSelectButton>
                {`${currentFontSize} `}
                <ChevronDownIcon />
              </FontSizeSelectButton>
            </PopoverTrigger>

            <FontSizesPopoverContent side="top" className="unthemed prevent-on-click-outside">
              <FontSizeOptionsList>
                {chatFontSizeOptions.map((fontSize) => (
                  <FontSizeOption key={fontSize} onClick={() => handleFontSizeChange(fontSize)}>
                    {fontSize}
                  </FontSizeOption>
                ))}
              </FontSizeOptionsList>
            </FontSizesPopoverContent>
          </Popover>

          <Popover onOpenChange={setIsColorPickerOpen}>
            <PopoverTrigger asChild>
              <FontColorIndicator fontColor={currentFontColor} />
            </PopoverTrigger>

            <PopoverContent side="top" className="unthemed prevent-on-click-outside">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <here-color-select ref={colorPickerRef} initialValue={currentFontColor} />
            </PopoverContent>
          </Popover>
        </Controls>
      </PopoverContent>
    </Popover>
  );
};

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverContent = styled(PopoverPrimitive.Content)`
  padding: 10px 15px;
  min-width: 270px;
  background-color: white;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 7px black;
`;

const FontSizesPopoverContent = styled(PopoverContent)`
  min-width: unset;
`;

const Button = styled.button`
  ${chatButtonMixin()}
`;

const Controls = styled.div`
  height: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const FontFamilyPickerContainer = styled.div`
  width: 220px;
`;

const FontSizeSelectButton = styled.button`
  padding: 0 7px;
  height: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  ${svgColorMixin('black')}
`;

const FontColorIndicator = styled.button<{ fontColor: string }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #eaeaea;
  background-color: ${({ fontColor }) => fontColor};
  box-shadow: 0 0 4px black;
  cursor: pointer;
`;

const FontSizeOptionsList = styled.ul`
  height: 170px;
  overflow-y: scroll;
  list-style-type: none;
`;

const FontSizeOption = styled.li`
  padding: 6px 0px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #dddddd;
  }
`;

export default FontStyleButton;
