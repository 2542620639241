import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectChatByChatId } from '../../../../store/messaging/selectors';
import { selectChatSkinningParticles } from '../../../../store/chat-skinning/selectors';
import SkinningParticlesEmitter from './SkinningParticlesEmitter';
import SkinningItemPicker from '../SkinningItemPicker';
import { useChangeSkinProperty } from '../useChangeSkinProperty';

interface Props {
  chatId: string;
}

const SkinningParticlesPicker = ({ chatId }: Props) => {
  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const selectedParticleId = chat?.theme?.skin?.particle?.id;

  const particles = useSelector(selectChatSkinningParticles);
  const handleParticleChange = useChangeSkinProperty(chat, 'particle');

  const items = useMemo(
    () =>
      particles.map((p) => ({
        id: p.id,
        name: p.name,
        preview: (
          <Container>
            <SkinningParticlesEmitter particles={p} uniqueIdPrefix="preview" shouldPauseOnMouseLeave />
          </Container>
        ),
        isSelected: p.id === selectedParticleId,
        onSelect: () => handleParticleChange(p),
        onRemove: () => handleParticleChange(null),
      })),
    [particles, selectedParticleId, handleParticleChange]
  );

  return <SkinningItemPicker items={items} canRemove />;
};

export default SkinningParticlesPicker;

const Container = styled.div`
  aspect-ratio: 1;
`;
