import styled from 'styled-components';

const TypingIndicatorGlow = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10px;
`;

export const TypingIndicatorInnerGlow = styled(TypingIndicatorGlow)`
  box-shadow: inset 0 0 20px 0px #ffffff88;
`;

export const TypingIndicatorOuterGlow = styled(TypingIndicatorGlow)`
  box-shadow: 0 0 20px 10px #ffffff99;
`;
