import { getOS } from './device-util';
import { OS_TYPES } from '../constants/device-constants';

export const WINDOWS_BINARY_URL = 'https://here-desktop.s3.amazonaws.com/Here+Setup+1.5.0.exe';
export const MAC_BINARY_URL = 'https://here-desktop.s3.amazonaws.com/Here-1.5.0-universal.dmg';

export function getDesktopAppBinariesLink() {
  const os = getOS();
  if (os === OS_TYPES.win) {
    return WINDOWS_BINARY_URL;
  }

  if (os === OS_TYPES.mac) {
    return MAC_BINARY_URL;
  }

  return null;
}

/**
 *
 * Tries to open a room in Electron from web. This is done by attempting to
 * click on a URL for which we have registered a custom protocol, and waiting
 * for some period of time for the `blur` event on the app (meaning another
 * app launched... probably)
 *
 * @param {string} boardId The room to attempt to load
 * @param {function} onLoadSuccess Callback when open is successful
 * @param {function} onLoadFail Callback when open is failed
 */
export function attemptLoadInElectron(boardId, onLoadSuccess, onLoadFail) {
  let waitForAppOpenTimeoutId = null;

  function onBrowserFocusLost() {
    window.removeEventListener('blur', onBrowserFocusLost);
    // desktop app is installed
    onLoadSuccess();
    clearTimeout(waitForAppOpenTimeoutId);
  }

  window.addEventListener('blur', onBrowserFocusLost);

  waitForAppOpenTimeoutId = setTimeout(() => {
    // desktop app is not installed, carry on
    window.removeEventListener('blur', onBrowserFocusLost);
    if (onLoadFail) {
      onLoadFail();
    }
  }, 1500);

  // try to open desktop app
  try {
    const link = document.createElement('a');
    link.setAttribute('href', `here-desktop://open-room?boardId=${boardId}`);
    link.click();
  } catch (ex) {
    // For unsupported links, some systems throw a TypeError
    if (ex.message === 'Failed to fetch') {
      return;
    }
    throw ex;
  }
}
