import { api, authedApiInstance } from '../firebase';
import log from '../log';

let serverTime;
let timeOffset = 0;

const axios = require('axios');

export default class ServerDate {
  static setServerTimestamp(newServerTime) {
    serverTime = newServerTime;
    timeOffset = serverTime - new Date().getTime();
  }

  static getTimeOffset() {
    return timeOffset;
  }

  static getServerTimestamp() {
    return serverTime;
  }

  static async getTime() {
    if (!serverTime) {
      const headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      try {
        await axios.get(`${api}/getServerTimestamp`, headers);
      } catch (err) {
        log.warn('error: could not get server timestamp', err);
      }
    }
    return new Date().getTime() + timeOffset;
  }

  /**
   *
   * @returns Epoch time in second resolution
   */
  static async getTimeInSecs() {
    const timeNow = await this.getTime();
    return timeNow / 1000;
  }
}

axios.interceptors.response.use(
  (response) => {
    if (response.headers['x-server-time']) {
      ServerDate.setServerTimestamp(response.headers['x-server-time']);
    }
    return response;
  },
  (error) => Promise.reject(error)
);

authedApiInstance.interceptors.response.use(
  (response) => {
    if (response.headers['x-server-time']) {
      ServerDate.setServerTimestamp(response.headers['x-server-time']);
    }
    return response;
  },
  (error) => Promise.reject(error)
);
