import React from 'react';
import styled from 'styled-components';

// components
import { chatButtonMixin } from '../shared-styles';

// icons
import ImageUploadIcon from '../../../../../assets/icons/picture.svg';

interface UploadButtonProps {
  elementId: string;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadButton = ({ elementId, handleFileUpload }: UploadButtonProps) => {
  const id = `upload-input-${elementId}`;

  return (
    <UploadLabel htmlFor={id}>
      <ImageUploadIcon />
      <UploadInput id={id} type="file" accept="image/*" onChange={handleFileUpload} />
    </UploadLabel>
  );
};

const UploadLabel = styled.label`
  ${chatButtonMixin()}
`;

const UploadInput = styled.input`
  display: none;
`;

export default UploadButton;
