import { DISPLAY_NAME_MAX_LENGTH } from '../sign-in-flow/constants.ts';

const MAX_BIO_LENGTH = 130;

export const validators = {
  isRequired: {
    isValid: (input) => input && input.trim().length > 0,
    errorMessage: 'Cannot be empty',
  },
  bio: {
    isValid: (input) => input.trim().length <= MAX_BIO_LENGTH,
    errorMessage: 'Bio is too long! Try again with 130 characters or less.',
  },
  displayName: {
    isValid: (input) => input.trim().length > 0 && input.trim().length <= DISPLAY_NAME_MAX_LENGTH,
    errorMessage: `Name must be at least 1 character and less than ${DISPLAY_NAME_MAX_LENGTH} characters.`,
  },
};

/**
 *
 * VALIDATE: function used to check if the value in your input field is valid
 *
 * @param {Validator} validator -- should be structured like: { isValid, errorMessage }
 * @param {String} value -- the value being validated
 * @return {String} returns empty string if field is valid, otherwise returns error message
 */

export const validate = (validator, value) => (validator.isValid(value) ? '' : validator.errorMessage);
