import React from 'react';
import styled, { keyframes } from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

// components
import UserAvatarWithStatus from '../components/UserAvatarWithStatus';
import { ellipsisTextMixin } from '../mixins';

// icons
import AddFriendIcon from '../../../assets/icons/add-friend.svg';

interface FriendSuggestionPopoverProps {
  isOnline: boolean;
  text: string;
  showAddFriendButton: boolean;
  member: {
    name: string;
    avatar: {
      thumbnail: string;
    };
  };

  onClose: () => void;
  onAddFriend: () => void;
}

const FriendSuggestionPopover = ({
  isOnline,
  text,
  showAddFriendButton,
  member,
  onClose,
  onAddFriend,
}: FriendSuggestionPopoverProps) => (
  <Popover.Root open>
    <Anchor />
    <Content>
      <InfoBlock>
        <Avatar>
          <UserAvatarWithStatus userProfile={member} status={isOnline ? 'online' : 'offline'} size={36} />
        </Avatar>
        <UserName>{text}</UserName>
        <CloseButton onClick={onClose}>✕</CloseButton>
      </InfoBlock>
      {showAddFriendButton ? (
        <AddFriendButton className="here-button-regular unthemed" onClick={onAddFriend}>
          <AddFriendIcon height="20" width="28" />
          Add Friend
        </AddFriendButton>
      ) : null}
    </Content>
  </Popover.Root>
);

const Anchor = styled(Popover.Anchor)`
  position: fixed;
  top: 57px;
  right: 14px;
`;

const scaleInAnimation = keyframes({
  '0%': { opacity: 0, transform: 'scale(0)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
});
const Content = styled(Popover.Content)`
  background: white;
  border-radius: 10px;
  padding: 8px 16px;
  transform-origin: var(--radix-popover-content-transform-origin);
  animation: ${scaleInAnimation} 0.3s ease-out forwards;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
`;

const AddFriendButton = styled.button`
  padding: 8px 24px;
  margin: 8px auto 0;
`;

const Avatar = styled.div`
  display: inline-block;
  margin-right: 4px;
`;

const UserName = styled.div`
  color: #6a2cdb;
  max-width: 400px;
  ${ellipsisTextMixin({ fullWidth: false })}
`;

const CloseButton = styled.button`
  border: none;
  background: white;
  cursor: pointer;
  display: inline-block;
  margin-left: 4px;
  outline: none;
`;

export default FriendSuggestionPopover;
