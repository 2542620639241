import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import firebase from '../../../../../firebase';
import { uploadChatSound } from '../../../../../upload';
import log from '../../../../../log';

// components
import UploadAreaEmpty from './UploadAreaEmpty';
import UploadAreaEdit from './UploadAreaEdit';
import UploadAreaError from './UploadAreaError';

// utils
import { playSoundEffect } from '../../../../../util/sound-fx-util';
import { trackUserActivationFor } from '../../../../../util/user-activation';

const urlsCache: { [key: string]: string } = {};

const refsCache: { [key: string]: firebase.storage.Reference } = {};

const getSoundFileRef = (soundUrl: string) => {
  if (refsCache[soundUrl]) {
    return refsCache[soundUrl];
  }

  const ref = firebase.storage().refFromURL(soundUrl);
  refsCache[soundUrl] = ref;
  return ref;
};

interface ChatSoundModalContentProps {
  roomId: string;
  soundToEdit?: {
    id: string;
    keyInCollection: number;
    trigger: string;
    soundUrl: string;
  };

  onSave: ({ soundUrl, trigger }: { soundUrl: string; trigger: string; soundId?: number }) => void;
  onDelete?: (soundId: number) => void;
}

const ChatSoundModalContent = ({ roomId, soundToEdit, onSave, onDelete }: ChatSoundModalContentProps) => {
  const [trigger, setTrigger] = useState('');
  const [localFile, setLocalFile] = useState<File | null>(null);
  const [soundFileRef, setSoundFileRef] = useState<firebase.storage.Reference | null>(null);
  const [soundFileRefToDelete, setSoundFileRefToDelete] = useState<firebase.storage.Reference | null>(null);
  const [errorText, setErrorText] = useState('');

  const storagePath = `boards/${roomId}/chat-sounds`;

  useEffect(() => {
    if (soundToEdit) {
      setTrigger(soundToEdit.trigger);

      const ref = getSoundFileRef(soundToEdit.soundUrl);
      setSoundFileRef(ref);
    }
  }, [soundToEdit]);

  const onTriggerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrigger(e.target.value);
  };

  const onFileUploadedLocally = (file: File) => {
    setLocalFile(file);
    setErrorText('');
  };

  const onFileUploaded = (ref: firebase.storage.Reference) => {
    setSoundFileRef(ref);
  };

  const onFileUploadError = (error: string) => {
    log.error('Error uploading file', error);
    setErrorText(error);
  };

  const getSoundFileUrl = async () => {
    if (urlsCache[soundFileRef.fullPath]) {
      return urlsCache[soundFileRef.fullPath];
    }

    const url = await soundFileRef.getDownloadURL();
    urlsCache[soundFileRef.fullPath] = url;
    return url;
  };

  const playLocalSound = () => {
    const localAudioPlayer = new Audio();
    localAudioPlayer.src = URL.createObjectURL(localFile);
    trackUserActivationFor('playLocalSound');
    localAudioPlayer.play();
  };

  const playUploadedSound = async () => {
    const url = await getSoundFileUrl();
    await playSoundEffect(url);
  };

  const deleteLocalSound = () => {
    setLocalFile(null);
  };

  const deleteUploaded = async () => {
    if (soundToEdit) {
      setSoundFileRefToDelete(soundFileRef);
      setSoundFileRef(null);
      return;
    }

    try {
      await soundFileRef.delete();
      setSoundFileRef(null);
    } catch (error) {
      log.error('error', error);
    }
  };

  const onSaveButtonClick = async () => {
    if (soundToEdit) {
      // update existing sound
      if (soundFileRefToDelete && localFile) {
        const uploadingResult = await uploadChatSound({ file: localFile, storagePath });
        refsCache[uploadingResult.fileURL] = uploadingResult.ref;
        urlsCache[uploadingResult.fileURL] = uploadingResult.fileURL;
        await onSave({ soundUrl: uploadingResult.fileURL, trigger, soundId: soundToEdit.keyInCollection });
      } else if (soundFileRef && trigger) {
        const soundUrl = await getSoundFileUrl();
        onSave({ soundUrl, trigger, soundId: soundToEdit.keyInCollection });
      }
    } else {
      // upload new sound
      try {
        const uploadingResult = await uploadChatSound({ file: localFile, storagePath });
        onFileUploaded(uploadingResult.ref);
        await onSave({ soundUrl: uploadingResult.fileURL, trigger });
      } catch (error) {
        log.error(error);
        onFileUploadError(error.message);
      }
    }
  };

  const onDeleteButtonClick = async () => {
    try {
      if (soundToEdit && onDelete) {
        await onDelete(soundToEdit.keyInCollection);

        // DELETE THIS LINE WHEN WE WANT TO REUSE THE SOUND FILES BETWEEN BOARDS
        const ref = soundFileRef || getSoundFileRef(soundToEdit.soundUrl);
        await ref.delete();
      }
    } catch (error) {
      log.error('error', error);
    }
  };

  return (
    <>
      <Title>Create Custom Sound</Title>
      <Subtitle>Upload your audio file!</Subtitle>

      <UploadAreaTitle>Sound File</UploadAreaTitle>

      {soundFileRef && soundToEdit && !localFile && !errorText ? (
        <UploadAreaEdit fileName={soundFileRef.name} playSound={playUploadedSound} deleteUploaded={deleteUploaded} />
      ) : null}

      {!soundToEdit && localFile && !errorText ? (
        <UploadAreaEdit fileName={localFile.name} playSound={playLocalSound} deleteUploaded={deleteLocalSound} />
      ) : null}

      {!soundFileRef && !localFile && !errorText ? (
        <UploadAreaEmpty onFileUploadedLocally={onFileUploadedLocally} onError={onFileUploadError} />
      ) : null}

      {errorText ? (
        <UploadAreaError
          errorText={errorText}
          onFileUploadedLocally={onFileUploadedLocally}
          onError={onFileUploadError}
        />
      ) : null}

      <TriggerLabel>
        <TriggerLabelText>Keyword / Chat Input</TriggerLabelText>
        <TriggerInput
          type="text"
          placeholder="Type a keyword to activate the sound"
          value={trigger}
          onChange={onTriggerChange}
        />
      </TriggerLabel>

      <SubmitButton
        className="here-button"
        disabled={!(trigger && (soundFileRef || localFile))}
        onClick={onSaveButtonClick}
      >
        Save
      </SubmitButton>

      {soundToEdit ? <DeleteButton onClick={onDeleteButtonClick}>Delete</DeleteButton> : null}
    </>
  );
};

const Title = styled.h3`
  color: #12002d;
  text-align: center;
  font-size: 16px;
  font-weight: bolder;
`;

const Subtitle = styled.h4`
  color: #12002d;
  text-align: center;
  font-size: 12px;
`;

const UploadAreaTitle = styled.p`
  margin-top: 20px;
  display: block;
  color: #12002d;
  font-size: 12px;
`;

const TriggerLabel = styled.label`
  margin-top: 8px;
  display: block;
`;

const TriggerLabelText = styled.span`
  display: flex;
  flex-direction: column;
  color: #12002d;
  font-size: 12px;
`;

const TriggerInput = styled.input`
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #12002d33;
  border-radius: 10px;
`;

const SubmitButton = styled.button`
  margin-top: 28px;
  padding: 13px;
  width: 100%;
  border: none;
  border-radius: 10px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const DeleteButton = styled.button`
  margin-top: 28px;
  padding: 13px;
  width: 100%;
  color: #f6335d;
  border: none;
  border-radius: 10px;
  background-color: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default ChatSoundModalContent;
