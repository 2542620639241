import { closeFocusedWindow, hideFocusedWindow, resizeFocusedWindow } from '../../electron-support/electron-support';

import template from './electron-windows-titlebar.html';

import './electron-windows-titlebar.less';

class ElectronWindowsTitlebar extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    // attrs
    this.isMainWindow = this.getAttribute('isMainWindow') === 'true';

    // binds
    this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
    this.onResizeButtonClick = this.onResizeButtonClick.bind(this);
    this.onHideButtonClick = this.onHideButtonClick.bind(this);

    // elements
    this.closeButton = this.querySelector('.electron-windows-button.close');
    this.resizeButton = this.querySelector('.electron-windows-button.resize');
    this.hideButton = this.querySelector('.electron-windows-button.hide');

    // handlers
    this.closeButton.addEventListener('click', this.onCloseButtonClick);
    this.resizeButton.addEventListener('click', this.onResizeButtonClick);
    this.hideButton.addEventListener('click', this.onHideButtonClick);
  }

  onCloseButtonClick() {
    closeFocusedWindow();
  }

  onResizeButtonClick() {
    resizeFocusedWindow();
  }

  onHideButtonClick() {
    hideFocusedWindow();
  }
}

window.customElements.define('here-electron-windows-titlebar', ElectronWindowsTitlebar);
