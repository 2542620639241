import UIkit from 'uikit';

import { isManageAllContentAllowed } from '../../../roles-management';
import chatBackgroundPicker from '../background/chat-background-picker';
import { db } from '../../../firebase';
import log from '../../../log';
import { permanentlyDeleteCollectionFromFirestore } from '../../../util';

import template from './chat-tab.html';

import '../../../../styles/buttons.less';
import './chat-tab.less';
import reduxAdapter from '../../../react/store/redux-adapter';
import { ROOM_SETTINGS_TABS } from '../../../react/store/room/constants';
import { setRoomSettingsCurrentTab } from '../../../react/store/room/store';
import { track } from '../../../util/analytics-util';

class ChatTab extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    // binds
    this.openFeedBackgroundPickerModal = this.openFeedBackgroundPickerModal.bind(this);
    this.showCustomizeChatSoundsPanel = this.showCustomizeChatSoundsPanel.bind(this);
    this.clearFeedHistory = this.clearFeedHistory.bind(this);

    this.feedBackgroundImage = null;
    this.feedBackgroundColor = null;

    this.chatBackgroundPreview = this.querySelector('#feed-background-preview');
    this.chatBackgroundOverlay = this.querySelector('#chat-background-overlay');
    this.chatBackgroundOverlayTitle = this.querySelector('#chat-background-overlay-title');
    this.feedBgPickerButton = this.querySelector('#feed-background-picker-button');
    this.customizeChatSoundsButton = this.querySelector('#customize-chat-sounds-button');
    this.clearChatButton = this.querySelector('#clear-chat-button');
    this.chatSoundFxToggle = this.querySelector('#chat-sound-fx-checkbox');

    this.feedBgPickerButton.addEventListener('click', this.openFeedBackgroundPickerModal);
    this.customizeChatSoundsButton.addEventListener('click', this.showCustomizeChatSoundsPanel);
    this.chatBackgroundPreview.addEventListener('click', this.openFeedBackgroundPickerModal);
    this.clearChatButton.addEventListener('click', this.clearFeedHistory);
    this.chatSoundFxToggle.addEventListener('click', this.toggleChatSoundFx);
  }

  disconnectedCallback() {
    this.feedBgPickerButton.removeEventListener('click', this.openFeedBackgroundPickerModal);
    this.customizeChatSoundsButton.removeEventListener('click', this.showCustomizeChatSoundsPanel);
    this.clearChatButton.removeEventListener('click', this.clearFeedHistory);
  }

  onBoardUpdate(boardData) {
    if (boardData.feedBackgroundImg) {
      this.feedBackgroundImage = boardData.feedBackgroundImg;
      this.feedBackgroundColor = null;
    } else if (boardData.feedBackgroundColor) {
      this.feedBackgroundImage = null;
      this.feedBackgroundColor = boardData.feedBackgroundColor;
    }

    if (boardData.chatSoundFx !== undefined) {
      this.chatSoundFxToggle.checked = boardData.chatSoundFx;
    } else {
      this.chatSoundFxToggle.checked = true;
    }

    this.refreshFeedBackgroundPreview();
  }

  refreshFeedBackgroundPreview() {
    if (this.feedBackgroundImage) {
      this.chatBackgroundPreview.style.backgroundImage = `url(${this.feedBackgroundImage})`;
      this.chatBackgroundPreview.style.backgroundColor = null;
    } else if (this.feedBackgroundColor) {
      this.chatBackgroundPreview.style.backgroundImage = null;
      this.chatBackgroundPreview.style.backgroundColor = this.feedBackgroundColor;
    } else {
      this.chatBackgroundPreview.style.backgroundImage = null;
      this.chatBackgroundPreview.style.backgroundColor = '#12002d'; // default chat bg color
    }
  }

  openFeedBackgroundPickerModal() {
    chatBackgroundPicker.open();
  }

  showCustomizeChatSoundsPanel() {
    reduxAdapter.dispatchAction(setRoomSettingsCurrentTab({ tabName: ROOM_SETTINGS_TABS.chatSounds }));
  }

  clearFeedHistory() {
    if (isManageAllContentAllowed()) {
      UIkit.modal
        .confirm("Are you sure you want to delete all messages in the chat? This can't be undone.")
        .then(() => {
          track('Clear Feed');
          return permanentlyDeleteCollectionFromFirestore(db, `boards/${window.currentBoardId}/feedItems`, 499);
        })
        .catch(() => {
          log.debug('Clear Feed Canceled');
        });
    }
  }

  toggleChatSoundFx(e) {
    db.doc(`/boards/${window.currentBoardId}`).update({ chatSoundFx: e.target.checked });
  }
}

window.customElements.define('here-chat-tab', ChatTab);
