import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  users: {},
  searchResults: null,
  userSearchError: '',
  currentUserId: '',
};

export const updateUsersFromArray = createAction('users/updateFromArray');
export const setUserOnlineStatus = createAction('users/setUserOnlineStatus');

export const currentUserSwitched = createAction('users/currentUserSwitched');
export const currentUserUpdated = createAction('users/currentUserUpdated');
export const currentUserProfileUpdateRequest = createAction('users/currentUserProfileUpdateRequest');
export const currentUserPhoneNumberUpdated = createAction('users/currentUserPhoneNumberUpdated');

export const searchUsers = createAction('users/searchUsers');
export const updateSearchResults = createAction('users/updateSearchResults');
export const searchUsersError = createAction('users/searchUsersError');
export const clearUserSearch = createAction('users/clearUserSearch');

export const usersReducer = createReducer(initialState, {
  [currentUserSwitched]: (state, { payload }) => {
    state.currentUserId = payload.id;
    state.users[payload.id] = {
      userId: payload.id,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      isAdmin: payload.isAdmin,
    };
  },
  [currentUserPhoneNumberUpdated]: (state, { payload }) => {
    state.users[payload.id].phoneNumber = payload.phoneNumber;
  },
  [currentUserUpdated]: (state, { payload }) => {
    state.users[payload.id] = {
      ...state.users[payload.id],
      ...payload,
      messageColor: payload.messageColor || '#612cda',
      fontColor: payload.fontColor || 'white',
      fontSize: payload.fontSize || '16px',
      fontFamily: payload.fontFamily || 'Inter',
      penColor: payload.penColor || '#bd14ff',
      shouldHidePhoneModal: payload.shouldHidePhoneModal || false,
    };
  },
  [updateUsersFromArray]: (state, { payload: { users } }) => {
    users.forEach((u) => {
      state.users[u.userId] = {
        ...state.users[u.userId],
        ...u,
      };
    });
  },
  [updateSearchResults]: (state, { payload: { userIds } }) => {
    state.searchResults = userIds;
  },
  [searchUsersError]: (state, { payload: error }) => {
    state.userSearchError = error;
  },
  [clearUserSearch]: (state) => {
    state.searchResults = null;
  },
});
