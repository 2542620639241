import firebase, { db } from './firebase';
import bus, { boardChanged } from './event-bus';
import { onSnapshot, offSnapshot } from './firestore-watcher';
import { findCameraHandler } from './util/camera-util';

let oldBoardId;
let currentRole;

let currentRolePromiseResolve;
let currentRolePromise = new Promise((resolve) => {
  currentRolePromiseResolve = resolve;
});

// eslint-disable-next-line no-unused-vars
export function isOwnerGranted(role = currentRole) {
  return role === 'owner';
}

// Note: default param currentRole here returns role in current room,
// should not be used to determine whether isHostGranted for room user
// is meant to warp into
export function isHostGranted(role = currentRole) {
  return role === 'owner' || role === 'host';
}

export function isMemberGranted(role = currentRole) {
  return role !== 'viewer';
}

// This getter is needed only in very rare cases.
// Use permission functions below when possible.
// eslint-disable-next-line no-underscore-dangle
export const _getCurrentRole = () => currentRole;

export const isDeleteAllMessagesAllowed = isHostGranted;

// Lock/unlock objects, move locked objects.
export const isLockAllowed = isHostGranted;

// Accept/deny join room requests, join without waitlist.
export const isWaitlistAllowed = async () => {
  await currentRolePromise;
  return isHostGranted();
};

export const isManageAllContentAllowed = isHostGranted;

export const canDeleteContent = () =>
  (!window.currentBoardData.isViewersModeOn || (window.currentBoardData.isViewersModeOn && isMemberGranted())) &&
  (window.currentBoardData.membersCanAddContent === undefined ||
    window.currentBoardData.membersCanAddContent ||
    isHostGranted());

// Special case when a room is not loaded yet, but we need to know
// whether we can join without waiting.
/**
 *
 * @param {string} boardId The board in question
 * @param {string} memberData Optional: preloaded current membership data
 * @returns
 */
export const isWaitlistAllowedForBoardId = async (boardId, memberData = null) => {
  let membership;
  if (!memberData) {
    const membershipSnapshot = await db.doc(`boards/${boardId}/members/${firebase.auth().currentUser.uid}`).get();
    membership = membershipSnapshot.data();
  } else {
    membership = memberData;
  }

  return membership && (membership.role === 'host' || membership.role === 'owner');
};

export const isRoomManagementAllowed = isHostGranted;

export const isElementsInteractionAllowed = isMemberGranted;

const callbacks = [];
export const onRoleChange = (cb) => callbacks.push(cb);
export const offRoleChange = (cb) => {
  const cbIndex = callbacks.findIndex((c) => c === cb);
  if (cbIndex >= 0) {
    callbacks.splice(cbIndex, 1);
  }
};

export const canInteract = ({ isLockedInteraction, hasViewerControls }) =>
  !isLockedInteraction || isLockAllowed() || hasViewerControls;
export const canMove = ({ isLocked, hasViewerControls }) => !isLocked || isLockAllowed() || hasViewerControls;

async function onMemberUpdated(member) {
  // TODO: optimize it to trigger only if a role changed, but consider:
  // - undefined value for role (for now it's equal to the 'member' role)
  // - a user has the same role in a new room when switching rooms

  // When we end a broadcast, we remove 'viewer' role from all viewers and mark them as past viewers.
  // It might lead to a camera element flash before user gets kicked, so ignoring it.
  const isMarkedAsPastViewer = currentRole === 'viewer' && member.isPastViewer;
  if (!member || isMarkedAsPastViewer) return;

  currentRolePromiseResolve();
  currentRole = member.role;
  callbacks.forEach((f) => f());

  document.querySelectorAll('.boardElement').forEach((el) => {
    const permissionsData = {
      isLocked: el.classList.contains('locked'),
      isLockedInteraction: el.classList.contains('lockedInteraction'),
      hasViewerControls: el.classList.contains('viewer-controls'),
    };

    el.classList[canMove(permissionsData) ? 'add' : 'remove']('can-move');
    el.querySelector('.element-container').classList[canInteract(permissionsData) ? 'remove' : 'add']('ignore-pointer');
  });

  document.querySelectorAll('.element-footer-options').forEach((el) => {
    if (isElementsInteractionAllowed() && (!el.classList.contains('locked') || isLockAllowed())) {
      el.classList.remove('hidden');
    } else {
      el.classList.add('hidden');
    }
  });
  document.querySelectorAll('.element-menu-bar').forEach((el) => {
    if (isElementsInteractionAllowed() && (!el.closest('.locked') || isLockAllowed())) {
      el.classList.remove('hidden');
    } else {
      el.classList.add('hidden');
    }
  });
  document.querySelectorAll('.message-add-element-prompt').forEach((el) => {
    if (isElementsInteractionAllowed()) {
      el.classList.remove('hidden');
    } else {
      el.classList.add('hidden');
    }
  });

  document.querySelector('#here-toolbar').style.display = isElementsInteractionAllowed() ? null : 'none';

  const broadcastCameraRequests = document.querySelector('here-broadcast-camera-requests');
  if (isRoomManagementAllowed() && !broadcastCameraRequests) {
    document.querySelector('.notifications-container').append(document.createElement('here-broadcast-camera-requests'));
  } else if (!isRoomManagementAllowed() && broadcastCameraRequests) {
    broadcastCameraRequests.remove();
  }

  const { uid } = firebase.auth().currentUser;
  const userStatusDatabaseRef = firebase.database().ref(`/status/${window.currentBoardId}/${uid}/role`);
  userStatusDatabaseRef.set(currentRole || null);
}

function onMembersListUpdated(updates) {
  updates.forEach((update) => {
    const cameraHandler = findCameraHandler(update.id);
    if (cameraHandler) cameraHandler.userRole = update.role;
  });
}

bus.on(boardChanged, (boardId) => {
  currentRolePromise = new Promise((resolve) => {
    currentRolePromiseResolve = resolve;
  });

  if (!firebase.auth().currentUser) {
    return;
  }

  const userId = firebase.auth().currentUser.uid;

  if (oldBoardId) {
    offSnapshot(`boards/${oldBoardId}/members/${userId}`, onMemberUpdated);
    offSnapshot(`boards/${oldBoardId}/members`, onMembersListUpdated, 'collection');
  }

  oldBoardId = boardId;
  onSnapshot(`boards/${boardId}/members/${userId}`, onMemberUpdated);
  onSnapshot(`boards/${boardId}/members`, onMembersListUpdated, 'collection');
});

let userCanAddContent = false;
const userCanAddContentSubscribers = [];

export const addUserCanAddContentSubscriber = (s) => {
  userCanAddContentSubscribers.push(s);
  s(userCanAddContent);
};

export function startListenIfUserCanAddContent() {
  onSnapshot(`/boards/${window.currentBoardId}`, (boardData) => {
    if (boardData.membersCanAddContent === false && !isHostGranted()) {
      userCanAddContent = false;
    } else {
      userCanAddContent = true;
    }

    userCanAddContentSubscribers.forEach((s) => s(userCanAddContent));
  });

  onRoleChange(async () => {
    const boardRef = await db.doc(`boards/${window.currentBoardId}`).get();
    const boardData = boardRef.data();
    if (boardData.membersCanAddContent === false && !isHostGranted()) {
      userCanAddContent = false;
    } else {
      userCanAddContent = true;
    }

    userCanAddContentSubscribers.forEach((s) => s(userCanAddContent));
  });
}

export const getUserCanAddContent = () => userCanAddContent;
