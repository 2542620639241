import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { useDispatch } from 'react-redux';

// store
import { openToolbarImagePopup } from '../../../store/gif-search/store';

// components
import AddImageContainer from '../../../components/AddImageContainer';
import { chatButtonMixin } from '../shared-styles';

// icons
import GifMenuIcon from '../../../../../assets/icons/gif.svg';

interface GifPickerButtonProps {
  chatId?: string;
  elementId: string;
  caller: string;
}

const GifPickerButton = ({ chatId, elementId, caller }: GifPickerButtonProps) => {
  const dispatch = useDispatch();

  const activeTabNameRef = useRef('Sticker');

  const [popoverOpen, setPopoverOpen] = useState(false);

  const onButtonClick = () => {
    // fetch initial trending gifs/stickers
    dispatch(openToolbarImagePopup());
  };

  const onResultClick = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  const onTabChange = (tabName: string) => {
    activeTabNameRef.current = tabName;
  };

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <Button onClick={onButtonClick}>
          <GifMenuIcon />
        </Button>
      </PopoverTrigger>

      <PopoverContent side="top" className="unthemed prevent-on-click-outside">
        <AddImageContainer
          elementId={`add-image-container-${elementId}`}
          isChat
          elementMenuId={`sticker-select-text-channel-${elementId}`}
          firebaseId={elementId}
          chatId={chatId}
          caller={caller}
          onResultClick={onResultClick}
          onTabChange={onTabChange}
          activeTabName={activeTabNameRef.current}
        />
      </PopoverContent>
    </Popover>
  );
};

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverContent = styled(PopoverPrimitive.Content)`
  padding: 10px 15px;
  width: 420px;
  background-color: white;
  border-radius: 10px;
  outline: none;
`;

const Button = styled.button`
  ${chatButtonMixin()}
`;

export default GifPickerButton;
