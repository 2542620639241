import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const PopIn = ({ time, children, onPopInTrigger, onPopIn }) => {
  useEffect(() => {
    onPopIn();
    // TODO: Figure out what's going on
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPopInTrigger]);

  return <PopInContainer time={time}>{children}</PopInContainer>;
};

export default PopIn;

PopIn.propTypes = {
  time: PropTypes.string,
  children: PropTypes.node.isRequired,
  onPopIn: PropTypes.func,
  onPopInTrigger: PropTypes.string,
};

PopIn.defaultProps = {
  time: '0.2s',
  onPopIn: () => {},
  onPopInTrigger: '',
};

const popInAnimation = css`
  @keyframes pop-in {
    from {
      transform: scale(0);
    }
    80% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
`;

const PopInContainer = styled.div`
  ${popInAnimation}

  animation: ${({ time }) => `pop-in ${time}`};
`;
