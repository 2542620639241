import log from '../log';
import eventBus, { networkInformationUpdated } from '../event-bus';
import { ConditionType, MonitorValue } from './helpers/monitor-value';
import StreamConstraints, { BitrateBoundary } from './stream-constraints';

const NOT_CHANGE_EFFECTIVE_TYPE_MONITOR_INTERVAL = 10000;

interface NetworkInformationComplete extends NetworkInformation {
  effectiveType: string;
  downlink: number;
  rtt: number;
}

export class Adaptability {
  streamConstraints: StreamConstraints;

  private networkEffectiveTypeMonitor: MonitorValue;

  constructor() {
    this.networkEffectiveTypeMonitor = new MonitorValue(
      ConditionType.HasNotChangedInterval,
      NOT_CHANGE_EFFECTIVE_TYPE_MONITOR_INTERVAL,
      this.onNetworkEffectiveTypeDoNotChanged.bind(this)
    );
    eventBus.on(networkInformationUpdated, this.onNetworkInformationUpdated.bind(this));

    if (window.networkInformation)
      this.onNetworkInformationUpdated(window.networkInformation as NetworkInformationComplete);
  }

  onNetworkInformationUpdated(networkInformation: NetworkInformationComplete) {
    this.networkEffectiveTypeMonitor.set(networkInformation.effectiveType);
  }

  onNetworkEffectiveTypeDoNotChanged(type: string) {
    log.warn(`AY: ${type.toUpperCase()} Network for ${NOT_CHANGE_EFFECTIVE_TYPE_MONITOR_INTERVAL / 1000} seconds`);
    if (type === '3g') {
      this.streamConstraints.bitrateBoundary = BitrateBoundary.Low;
    } else if (type === '4g') {
      this.streamConstraints.bitrateBoundary = BitrateBoundary.High;
    }
  }
}
