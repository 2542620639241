import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectChatByChatId } from '../../../../store/messaging/selectors';
import { selectChatSkinningFrames } from '../../../../store/chat-skinning/selectors';
import SkinningItemPicker from '../SkinningItemPicker';
import SkinningFrameWrapper from './SkinningFrameWrapper';
import { useChangeSkinProperty } from '../useChangeSkinProperty';
import { SkinFrame } from '../../../../store/chat-skinning/init-state';

interface Props {
  chatId: string;
}

const SkinningFramePicker = ({ chatId }: Props) => {
  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const selectedFrameId = chat?.theme?.skin?.frame?.id;

  const frames = useSelector(selectChatSkinningFrames);
  const handleFrameChange = useChangeSkinProperty(chat, 'frame');

  const items = useMemo(
    () =>
      frames.map((f) => ({
        id: f.id,
        name: f.name,
        preview: (
          <OuterContainer frame={f}>
            <SkinningFrameWrapper frame={f} borderRadius={8} />
          </OuterContainer>
        ),
        isSelected: f.id === selectedFrameId,
        onSelect: () => handleFrameChange(f),
        onRemove: () => handleFrameChange(null),
      })),
    [frames, handleFrameChange, selectedFrameId]
  );

  return <SkinningItemPicker items={items} canRemove />;
};

export default SkinningFramePicker;

const OuterContainer = styled.div<{ frame: SkinFrame }>`
  position: relative;
  height: 100%;
  width: 100%;
  aspect-ratio: 2/3;
  display: flex;
  align-items: center;
  justify-content: center;
`;
