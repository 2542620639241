import React from 'react';
import PropTypes from 'prop-types';

function Triangle(props) {
  const { color } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 0L11.1962 9H0.803848L6 0Z" fill={color} />
    </svg>
  );
}

Triangle.propTypes = {
  color: PropTypes.string,
};

Triangle.defaultProps = {
  color: '#F6335D',
};

export default Triangle;
