import { uploadImageToChat } from '../../../upload';
import log from '../../../log';

// utils
import { getFilesTotalSize } from '../../../util/file-util';

// constants
import { MAX_CHAT_UPLOAD_BYTES } from '../../../constants/files-constants';
import { fileUploadErrorMessagesMap, fileUploadErrorTypes } from '../../../constants/chat-constants';

/**
 *
 * @param {string} storageFolder
 * @param {Function} onMessageUploadSuccess
 * @param {Function} onMessageUploadFail
 * @returns
 */
export const createUploadHandler = (storageFolder, onMessageUploadSuccess, onMessageUploadFail) => (event) => {
  event.preventDefault();
  event.stopPropagation();

  const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
  const filesArray = Array.from(files);
  const filesToUploadTotalSize = getFilesTotalSize(filesArray);
  if (filesToUploadTotalSize > MAX_CHAT_UPLOAD_BYTES) {
    onMessageUploadFail({
      type: fileUploadErrorTypes.SIZE_EXCEEDED,
      message: fileUploadErrorMessagesMap[fileUploadErrorTypes.SIZE_EXCEEDED],
    });

    return;
  }

  if (files.length) {
    Array.from(files).forEach(async (file) => {
      try {
        const { fileURL: imageUrl, storagePath } = await uploadImageToChat({ file, storageFolder });
        await onMessageUploadSuccess({ imageUrl, storagePath });
      } catch (err) {
        log.error(err);
        if (err.cause === fileUploadErrorTypes.WRONG_TYPE) {
          onMessageUploadFail({
            type: fileUploadErrorTypes.WRONG_TYPE,
            message: `${file.name} is an unsupported format. ${
              fileUploadErrorMessagesMap[fileUploadErrorTypes.WRONG_TYPE]
            }`,
          });
        } else {
          onMessageUploadFail({
            type: fileUploadErrorTypes.DEFAULT,
            message: fileUploadErrorMessagesMap[fileUploadErrorTypes.DEFAULT],
          });
        }
      }
    });
  }
};

let fileUploading = false;

/**
 *
 * @param {string} storageFolder
 * @param {Function} onMessageUploadSuccess
 * @param {Function} onMessageUploadFail
 * @returns
 */
export const createPasteHandler = (storageFolder, onMessageUploadSuccess, onMessageUploadFail) => async (event) => {
  event.stopPropagation();

  if (fileUploading) {
    return;
  }

  const { items } = event.clipboardData || event.originalEvent.clipboardData;
  let blob = null;
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].type.indexOf('image') === 0) {
      blob = items[i].getAsFile();
      break;
    }
  }

  if (blob) {
    try {
      event.preventDefault();

      fileUploading = true;

      const { fileURL: imageUrl, storagePath } = await uploadImageToChat({ file: blob, storageFolder });
      await onMessageUploadSuccess({ imageUrl, storagePath });
    } catch (err) {
      log.error(err);
      if (err.cause === fileUploadErrorTypes.WRONG_TYPE) {
        onMessageUploadFail({
          type: fileUploadErrorTypes.WRONG_TYPE,
          message: `${blob.name} is an unsupported format. ${
            fileUploadErrorMessagesMap[fileUploadErrorTypes.WRONG_TYPE]
          }`,
        });
      } else {
        onMessageUploadFail({
          type: fileUploadErrorTypes.DEFAULT,
          message: fileUploadErrorMessagesMap[fileUploadErrorTypes.DEFAULT],
        });
      }
    } finally {
      fileUploading = false;
    }
  }
};
