import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { db } from '../../firebase';
import { isUsernameValid, saveUserDisplayName } from '../../util/user-util';
import { track } from '../../util/analytics-util';

import { HereTextArea, HereDropdown, HereInput } from '../components/inputs';

import { validate, validators } from './validators';
import { PRONOUNS } from './constants';
import { hideableMixin } from '../mixins';

import PictureIcon from '../../../assets/icons/picture.svg';
import { supportsNewLobby } from '../../electron-support/electron-support';
import { checkIsElectron } from '../../util/platform-util';
import ProfileEffectEmoteInput from './ProfileEffectEmoteInput.tsx';

const fieldNames = {
  displayName: 'displayName',
  username: 'username',
  bio: 'bio',
  pronouns: 'pronouns',
};

let isValidUsernameTimer = null;

const EditProfileForm = ({ userProfile, onEditProfileTheme, canEditProfileTheme }) => {
  const [currentEditValue, setCurrentEditValue] = useState('');
  const [currentEditField, setCurrentEditField] = useState(null);

  const [editErrorMessage, setEditErrorMessage] = useState('');

  // we only want to show Edit Theme button if user is not on older electron version with the old candybar
  // defaulting to true so there's no lag in showing button for web/latest electron versions
  const [showEditThemeButton, setShowEditThemeButton] = useState(true);
  useEffect(() => {
    (async () => {
      if (checkIsElectron()) {
        const supportsTheme = await supportsNewLobby();
        setShowEditThemeButton(supportsTheme);
      }
    })();
  }, []);

  // if input is currently being edited, onClick function is no-op
  // if it is not, onClick function is setting input to edit mode
  const getInputClickFunction = (fieldName) => {
    if (currentEditField === fieldName) {
      return () => {}; // no-op
    }
    return () => {
      setCurrentEditField(fieldName);
      setCurrentEditValue(userProfile[fieldName]);
      setEditErrorMessage('');
    };
  };

  const onFieldChange = (value) => {
    setCurrentEditValue(value);
  };

  const onCancelClick = () => {
    setCurrentEditField(null);
    setCurrentEditValue('');
    setEditErrorMessage('');
  };

  const onSaveClick = () => {
    if (currentEditField === fieldNames.displayName) {
      // display name needs to be set to both firebase
      // auth profile and firestore userProfile record:
      saveUserDisplayName(currentEditValue.trim());
    } else {
      db.doc(`userProfiles/${userProfile.id}`).update({
        [currentEditField]: currentEditValue.trim(),
      });
    }

    track('Edit User Profile Field', { field: currentEditField });

    setCurrentEditField(null);
    setCurrentEditValue('');
  };

  const onUsernameFieldChange = async (value) => {
    onFieldChange(value.toLowerCase());

    if (value === userProfile.username) {
      setEditErrorMessage('');
      return;
    }

    clearTimeout(isValidUsernameTimer);
    isValidUsernameTimer = setTimeout(async () => {
      const response = await isUsernameValid(value);
      if (validate(validators.isRequired, value)) setEditErrorMessage(validate(validators.isRequired, value));
      else if (!response.result) setEditErrorMessage(response.message);
      else setEditErrorMessage('');
    }, 350);
  };

  const onNameFieldChange = (value) => {
    onFieldChange(value);
    const error = validate(validators.displayName, value);
    setEditErrorMessage(error);
  };

  const onBioFieldChange = (value) => {
    onFieldChange(value);
    const error = validate(validators.bio, value);
    setEditErrorMessage(error);
  };

  const onDropdownSelect = (key) => {
    const value = PRONOUNS[key];
    onFieldChange(value);
    db.doc(`userProfiles/${userProfile.id}`).update({ pronouns: value });

    track('Edit User Profile Field', { field: 'pronouns', value });
  };

  const currentlyEditingName = currentEditField === fieldNames.displayName;
  const currentlyEditingUsername = currentEditField === fieldNames.username;
  const currentlyEditingBio = currentEditField === fieldNames.bio;

  return (
    <FormSection>
      <FlexContainer justifyContent="center">
        <FormContainer>
          {/* Name */}
          <FormGroup>
            <InputLabel>Name</InputLabel>
            <HereInput
              controlled
              darkStyle
              smallStyle
              onClick={getInputClickFunction(fieldNames.displayName)}
              onChange={(e) => onNameFieldChange(e.target.value)}
              value={currentlyEditingName ? currentEditValue : userProfile.displayName}
              errorMessage={currentlyEditingName ? editErrorMessage : ''}
              isThemed
              testId="edit-display-name-input-field"
            />
            {currentlyEditingName && (
              <FlexContainer justifyContent="space-between">
                <Button className="here-button-minimal" onClick={onCancelClick}>
                  Cancel
                </Button>
                <Button className="here-button-regular" disabled={editErrorMessage} onClick={onSaveClick}>
                  Save
                </Button>
              </FlexContainer>
            )}
          </FormGroup>
          {/* Username */}
          <FormGroup>
            <InputLabel>Username</InputLabel>
            <HereInput
              controlled
              darkStyle
              smallStyle
              prefix="@"
              onClick={getInputClickFunction(fieldNames.username)}
              onChange={(e) => onUsernameFieldChange(e.target.value)}
              value={currentlyEditingUsername ? currentEditValue : userProfile.username}
              errorMessage={currentlyEditingUsername ? editErrorMessage : ''}
              checkResult={currentlyEditingUsername ? !editErrorMessage : null}
              isThemed
              testId="edit-username-input-field"
            />
            {currentEditField === fieldNames.username && (
              <FlexContainer justifyContent="space-between">
                <Button className="here-button-minimal" onClick={onCancelClick}>
                  Cancel
                </Button>
                <Button className="here-button-regular" disabled={editErrorMessage} onClick={onSaveClick}>
                  Save
                </Button>
              </FlexContainer>
            )}
          </FormGroup>
          {/* Profile Effect Emote */}
          <FormGroup>
            <InputLabel>Select Profile Effect</InputLabel>
            <ProfileEffectEmoteInput emoteUrl={userProfile.effectEmoteUrl} />
          </FormGroup>
          {/* Bio */}
          <FormGroup>
            <InputLabel>Bio</InputLabel>
            <HereTextArea
              smallStyle
              placeholder="Tell us about yourself"
              onClick={getInputClickFunction(fieldNames.bio)}
              onChange={(e) => onBioFieldChange(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              value={currentlyEditingBio ? currentEditValue : userProfile.bio}
              errorMessage={currentlyEditingBio ? editErrorMessage : ''}
              isThemed
              testId="edit-bio-input-field"
            />
            {currentEditField === fieldNames.bio && (
              <FlexContainer justifyContent="space-between">
                <Button className="here-button-minimal" onClick={onCancelClick}>
                  Cancel
                </Button>
                <Button className="here-button-regular" disabled={editErrorMessage} onClick={onSaveClick}>
                  Save
                </Button>
              </FlexContainer>
            )}
          </FormGroup>
          {/* Pronouns */}
          <FormGroup>
            <InputLabel>Pronouns</InputLabel>
            <HereDropdown
              // If there's no edit theme button, it's gonna be at the very bottom,
              // so we need to open the dropdown upwards.
              isFacingUp={!canEditProfileTheme}
              smallStyle
              placeholder="select"
              selectedValue={userProfile.pronouns}
              onClick={getInputClickFunction('pronouns')}
              onSelect={onDropdownSelect}
              options={Object.keys(PRONOUNS).map((pronoun) => ({ key: pronoun, value: PRONOUNS[pronoun] }))}
              dropdownHeaderTestId="pronouns-dropdown-header"
              dropdownListContainerTestId="pronouns-dropdown-list-container"
            />
          </FormGroup>
          <EditThemeButton
            className="here-button-regular"
            type="button"
            onClick={onEditProfileTheme}
            isHiddenDisplay={!showEditThemeButton || !canEditProfileTheme}
          >
            <PictureIconStyled />
            Change Theme
          </EditThemeButton>
        </FormContainer>
      </FlexContainer>
    </FormSection>
  );
};

export default EditProfileForm;

EditProfileForm.propTypes = {
  userProfile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string,
    displayName: PropTypes.string,
    bio: PropTypes.string,
    pronouns: PropTypes.string,
    effectEmoteUrl: PropTypes.string,
  }).isRequired,
  onEditProfileTheme: PropTypes.func,
  canEditProfileTheme: PropTypes.bool,
};

EditProfileForm.defaultProps = {
  onEditProfileTheme: () => {},
  canEditProfileTheme: true,
};

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 13px 0;
  font-size: 12px;
  margin-top: 10px;
  transition: all 0.4s ease;
  width: 47%;
`;

const InputLabel = styled.div`
  font-size: 12px;
  color: var(--primary-foreground, white);
  opacity: 0.5;
  margin-bottom: 5px;
`;

export const FormSection = styled.div`
  background: var(--primary-background, #12002d);
  padding-top: 90px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
`;

const FormContainer = styled.div`
  width: 100%;
  padding: 0 15px;
`;

const EditThemeButton = styled.button`
  ${hideableMixin}
  width: 100%;
  padding: 14px 0;
  margin-bottom: 20px;
`;

const PictureIconStyled = styled(PictureIcon)`
  margin-right: 6px;
`;
