import React from 'react';
import styled from 'styled-components';

interface Props {
  isFirstCard: boolean;
  cardHeight: number;
  slideWidth: number;
  onClick: () => void;
}

const NewCardButton = ({ isFirstCard, cardHeight, slideWidth, onClick }: Props) => (
  <NewButtonContainer onClick={onClick} cardHeight={cardHeight} slideWidth={slideWidth}>
    <AddNewCardSlideCaption className="onboarding-add-card">
      {isFirstCard ? 'Make your first card!' : 'Add new card'}
      <NewCardPlusSign>+</NewCardPlusSign>
    </AddNewCardSlideCaption>
  </NewButtonContainer>
);

export default NewCardButton;

const NewButtonContainer = styled.div<{ cardHeight: number; slideWidth: number }>`
  height: ${({ cardHeight }) => cardHeight}px;
  width: ${({ slideWidth }) => slideWidth}px;
  border: 2px dashed var(--primary-foreground);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  cursor: pointer;
`;

const AddNewCardSlideCaption = styled.div`
  position: relative;
  width: 60%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-foreground);
  background: rgba(255, 255, 255, 0.2);
  padding: 16px 8px;
  border-radius: 30px;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NewCardPlusSign = styled.div`
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  bottom: -20px;
  right: 0;
`;
