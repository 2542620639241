import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Tooltip from '@radix-ui/react-tooltip';

import { MAX_PRESENCE_SHOWN_IN_ROOM_CHATS } from '../../../constants/chat-constants';
import UserAvatar from '../../components/UserAvatar';
import UserName from '../../components/UserName';

const ChatPresence = ({ memberIds }) => {
  const slicedList = useMemo(() => memberIds?.slice(0, MAX_PRESENCE_SHOWN_IN_ROOM_CHATS), [memberIds]);
  const hiddenNumber = memberIds.length - slicedList.length;

  return (
    <MembersContainer>
      {slicedList.map((id) => (
        <UserAvatarWrapper key={id}>
          <Tooltip.Root delayDuration={300}>
            <Tooltip.Trigger asChild>
              {/* Need this UserAvatarTriggerContainer to have a neutral trigger container */}
              <UserAvatarTriggerContainer>
                <UserAvatar userId={id} />
              </UserAvatarTriggerContainer>
            </Tooltip.Trigger>
            <TooltipContent sideOffset={10}>
              <UserName userId={id} />
            </TooltipContent>
          </Tooltip.Root>
        </UserAvatarWrapper>
      ))}
      {hiddenNumber > 0 && <HiddenNumber key="hidden-number">+{hiddenNumber}</HiddenNumber>}
    </MembersContainer>
  );
};

export default ChatPresence;

ChatPresence.propTypes = {
  memberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const MembersContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserAvatarWrapper = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: -10px;
  font-size: 12px;
  cursor: pointer;
`;

const HiddenNumber = styled.div`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  font-size: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TooltipContent = styled(Tooltip.Content)`
  background: white;
  color: black;
  z-index: 100;
  padding: 4px 8px;
  border-radius: 4px;
`;

const UserAvatarTriggerContainer = styled.div`
  height: 100%;
`;
