import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sortBy from 'lodash/fp/sortBy';

import { friendsAsArraySelector } from '../store/friends/selectors';
import UsersSelect from '../components/users-select';
import { addBoardElementTags } from './api';
import { selectRoomId } from '../store/room/selectors';
import { selectCurrentUserId } from '../store/users/selectors';
import TagAvatar from './TagAvatar';
import { useElementData } from '../hooks/useElementData';
import { track } from '../../util/analytics-util';
import { TAG_ELEMENT } from '../../constants/analytics-events/tagging-events';

const TaggingPicker = ({ existingTags, elementId, onClose }) => {
  const currentUserId = useSelector(selectCurrentUserId);
  const currentBoardId = useSelector(selectRoomId);
  const [elementData] = useElementData(currentBoardId, elementId);
  const preselectedUsersMap = useMemo(() => {
    const result = {};
    existingTags.forEach((tag) => {
      if (tag.senderId === currentUserId) {
        result[tag.receiverId] = true;
      }
    });
    return result;
  }, [currentUserId, existingTags]);

  const [currentlySelectedUsersMap, setCurrentlySelectedUsersMap] = useState({});
  const currentlySelectedUserIds = useMemo(
    () => Object.keys(currentlySelectedUsersMap).filter((id) => currentlySelectedUsersMap[id]),
    [currentlySelectedUsersMap]
  );

  const selectedUsersMap = useMemo(
    () => ({ ...preselectedUsersMap, ...currentlySelectedUsersMap }),
    [currentlySelectedUsersMap, preselectedUsersMap]
  );

  const boardId = useSelector(selectRoomId);
  const friends = useSelector(friendsAsArraySelector);
  const sortedFriends = useMemo(
    () =>
      sortBy('sortField')(
        friends.map((f) => {
          let predicate = '3';
          if (preselectedUsersMap[f.userId]) {
            predicate = '1';
          } else if (currentlySelectedUsersMap[f.userId]) {
            predicate = '2';
          }
          return { ...f, sortField: predicate + f.displayName?.toLowerCase() };
        })
      ),
    [friends, preselectedUsersMap, currentlySelectedUsersMap]
  );

  const onToggle = useCallback(
    (userId) =>
      setCurrentlySelectedUsersMap({ ...currentlySelectedUsersMap, [userId]: !currentlySelectedUsersMap[userId] }),
    [currentlySelectedUsersMap]
  );
  const onSubmit = () => {
    track(TAG_ELEMENT, { addedTagsQuantity: currentlySelectedUserIds.length, elementType: elementData.class });
    addBoardElementTags({ receiverIds: currentlySelectedUserIds, boardId, elementId });
    onClose();
  };

  return (
    <Container>
      <Title>Tag your Friends</Title>
      <TagsContainer>
        {existingTags.map((tag) => (
          <TagAvatarContainer key={tag.id}>
            <TagAvatar tag={tag} borderColor="white" />
          </TagAvatarContainer>
        ))}
      </TagsContainer>
      <UsersSelect
        usersList={sortedFriends}
        selectedIds={selectedUsersMap}
        disabledIds={preselectedUsersMap}
        onToggle={onToggle}
      />
      <SubmitButton
        className="here-button-regular unthemed"
        disabled={!currentlySelectedUserIds.length}
        onClick={onSubmit}
      >
        <ButtonTitle>Tag Friends</ButtonTitle>
        {currentlySelectedUserIds.length ? (
          <ButtonSubtitle>{currentlySelectedUserIds.length} selected</ButtonSubtitle>
        ) : null}
      </SubmitButton>
    </Container>
  );
};

export default TaggingPicker;

TaggingPicker.propTypes = {
  existingTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  elementId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Container = styled.div`
  background: white;
  padding: 16px;
  border-radius: 20px;
  width: 400px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
`;

const TagsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const TagAvatarContainer = styled.div`
  margin: 0 -10px;
`;

const SubmitButton = styled.button`
  padding: 9px;
  height: 60px;
  width: 100%;
  font-weight: bolder;
  z-index: 1;
  flex-direction: column;
`;

const ButtonTitle = styled.div`
  font-size: 16px;
`;

const ButtonSubtitle = styled.div`
  opacity: 0.5;
  font-size: 12px;
`;
