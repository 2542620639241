const MOVING_FRAME_DURATION = 1000 / 30;

let movingLoopId = null;

export const CameraState = {
  normal: 0,
  deleting: 1,
};

export function stopKeyboardMoveLoop() {
  if (movingLoopId) {
    window.clearInterval(movingLoopId);
    movingLoopId = null;
  }
}

export function isKeyboardMoveLoopActive() {
  return movingLoopId !== null;
}

export function startKeyboardMoveLoop(f) {
  movingLoopId = setInterval(f, MOVING_FRAME_DURATION);
}

export function findCameraHandler(userId) {
  const cameraHandlers = Object.values(window.elementHandlers).filter(
    (h) => h.constructor.elementType === 'CameraElement'
  );
  const match = cameraHandlers
    ? cameraHandlers.filter((h) => h.userId === userId && h.state !== CameraState.deleting)
    : null;
  return match && match.length > 0 ? match[0] : null;
}

export function updateCaptionText(userId, text) {
  let captionTimeout;
  const container = document.getElementById(`caption-container-${userId}`);
  if (container) {
    container.firstChild.innerHTML = text;
    container.style.display = 'block';
    const textContainer = container.firstChild;
    const numIterations = 3;
    const iterationMS =
      (5000 * Math.max(textContainer.clientWidth, container.clientWidth)) / Math.max(1, container.clientWidth);
    textContainer.animate(
      [{ transform: `translateX(${container.clientWidth}px)` }, { transform: 'translateX(-100%)' }],
      {
        duration: iterationMS,
        iterations: numIterations,
      }
    );
    if (captionTimeout) {
      window.clearTimeout(captionTimeout);
      captionTimeout = null;
    }
    captionTimeout = window.setTimeout(() => {
      container.style.display = 'none';
    }, numIterations * iterationMS);
  }
}
