import kebabCase from 'lodash/fp/kebabCase';

export const removeSpacesSetLowerCase = (str) => str.replace(/ /g, '').toLowerCase();

export const isEmail = (str) => /^[a-zA-Z0-9+._-]+@([a-zA-Z0-9-]{2,20}\.)+[a-zA-Z]{2,6}$/.test(str);

/**
 *
 * @param {String} str
 * @returns String
 *
 * @example
 * capitalizeSentence("I'm a little tea pot") // "I'm A Little Tea Pot"
 */
export function capitalizeSentence(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(' ');
}

export function isEmoji(str) {
  return /\p{Extended_Pictographic}/u.test(str);
}

export function getSanitizedUrl(url) {
  const regex = new RegExp(`https://ip.here.fm/(.*)`);
  const results = regex.exec(url);
  if (results !== null && results[1] !== null) [, url] = results;
  return url;
}

/**
 * Transforms 'inputString' into 'input-string'
 * @param {string} str String to transform
 * @returns {string}
 */
export function toKebabCase(str) {
  return kebabCase(str);
}

/**
 *
 * @param {string} target
 * @param {string} search
 * @returns {boolean}
 *
 * @example
 * inputSearchComparer('test', 'test') // true
 * inputSearchComparer('test', 'tes') // true
 * inputSearchComparer('test', 'tst') // false
 *
 */
export function inputSearchComparer(target, search) {
  const targetLower = target ? target.trim().toLowerCase() : '';
  const searchLower = search ? search.trim().toLowerCase() : '';
  return targetLower.includes(searchLower);
}

const normalizeString = (str) => str.replace(/\s+/g, '').toLowerCase();

export const compareStringsIgnoringSpacesAndCase = (str1, str2) => normalizeString(str1) === normalizeString(str2);
