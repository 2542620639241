import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { db } from '../../../firebase';
import ServerDate from '../../../util/server-date';
import { timerStates } from './constants';

const InactiveTimer = ({ elementId, currTimeLeft, updateDocument }) => {
  const currentBoardId = useSelector((state) => state.room.id);

  const [currInput, setCurrInput] = useState({ hour: '00', min: '00', sec: '00' });
  const [selectedInput, setSelectedInput] = useState(null);

  const onInputClick = (input) => {
    setSelectedInput(input);
  };

  const onKeyPress = (e) => {
    if (e.charCode === 46) {
      e.preventDefault();
    }
  };

  const onInputChange = (value, label) => {
    // restrict maxlength to 2
    let restrictedValue = value;
    if (value.length > 2) {
      restrictedValue = value.slice(0, 2);
    }

    const labelToKey = {
      HR: 'hour',
      MIN: 'min',
      SEC: 'sec',
    };

    setCurrInput({ ...currInput, [labelToKey[label]]: restrictedValue });

    db.collection('boards')
      .doc(currentBoardId)
      .collection('elements')
      .doc(elementId)
      .update({ currTimeLeft: { ...currTimeLeft, [labelToKey[label]]: restrictedValue } });
  };

  const onStartClick = useCallback(
    async (startTime) => {
      const timeNow = await ServerDate.getTimeInSecs();

      // don't allow empty strings
      const emptySafeStartTime = {
        hour: startTime.hour ? startTime.hour : '00',
        min: startTime.min ? startTime.min : '00',
        sec: startTime.sec ? startTime.sec : '00',
      };

      updateDocument({
        currTimeLeft: emptySafeStartTime,
        originalStartTime: emptySafeStartTime,
        timerState: timerStates.active,
        timeStarted: timeNow,
      });
    },
    [updateDocument]
  );

  return (
    <>
      <FlexContainer>
        <TimerInput
          value={currTimeLeft && currTimeLeft.hour}
          label="HR"
          onClick={onInputClick}
          onChange={onInputChange}
          onKeyPress={onKeyPress}
          selectedInput={selectedInput === 'HR'}
        />
        <TimerInputDivider />
        <TimerInput
          value={currTimeLeft && currTimeLeft.min}
          label="MIN"
          onClick={onInputClick}
          onChange={onInputChange}
          onKeyPress={onKeyPress}
          selectedInput={selectedInput === 'MIN'}
        />
        <TimerInputDivider />
        <TimerInput
          value={currTimeLeft && currTimeLeft.sec}
          label="SEC"
          onClick={onInputClick}
          onChange={onInputChange}
          onKeyPress={onKeyPress}
          selectedInput={selectedInput === 'SEC'}
        />
      </FlexContainer>
      <FlexContainer>
        <StyledButton
          className="here-button-regular"
          disabled={!currTimeLeft || (!currTimeLeft.hour && !currTimeLeft.min && !currTimeLeft.sec)}
          onClick={() => onStartClick(currInput)}
        >
          Start
        </StyledButton>
      </FlexContainer>
    </>
  );
};

export default InactiveTimer;

InactiveTimer.propTypes = {
  elementId: PropTypes.string.isRequired,
  currTimeLeft: PropTypes.shape({
    hour: PropTypes.string,
    min: PropTypes.string,
    sec: PropTypes.string,
  }),
  updateDocument: PropTypes.func.isRequired,
};

InactiveTimer.defaultProps = {
  currTimeLeft: null,
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  padding: 14px;
  width: 200px;
  font-size: 20px;
  margin-top: 18px;
`;

const TimerInput = ({ label, value, onChange, onKeyPress, onClick, selectedInput }) => (
  <InputContainer selectedInput={selectedInput} onClick={() => onClick(label)}>
    <div>
      <StyledInput
        type="number"
        value={value || ''}
        placeholder="00"
        min="0"
        onChange={(e) => onChange(e.target.value, label)}
        onKeyPress={onKeyPress}
      />
      {label}
    </div>
  </InputContainer>
);

TimerInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedInput: PropTypes.bool.isRequired,
};

TimerInput.defaultProps = {
  value: '',
};

const StyledInput = styled.input`
  font-size: 36px;
  border: none;
  width: 90%;
  text-align: center;
  font-family: 'Inter';
  font-weight: bold;
  background: inherit;
  color: inherit;

  -moz-appearance: textfield;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: var(--primary-foreground, black);
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
`;

const InputContainer = styled.div`
  width: 64px;
  height: 84px;
  background: var(--primary-background, white);
  line-height: 28.18px;
  color: var(--primary-foreground, #6b1be3);
  display: flex;
  align-items: center;

  ${(props) =>
    props.selectedInput &&
    `
    border: 3px solid var(--primary-foreground, #6b1be3);
  `}
`;

const TimerInputDivider = () => (
  <FlexContainer>
    <StyledColon>:</StyledColon>
  </FlexContainer>
);

const StyledColon = styled.span`
  color: var(--primary-foreground, white);
  font-size: 35.79px;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
`;
