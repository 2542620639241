import { RootState } from '../../../definitions/store';

export const selectIsLoading = (state: RootState) => state.groups.isLoading;
export const selectGroupsError = (state: RootState) => state.groups.error;
export const selectCreatedGroupId = (state: RootState) => state.groups.createdGroupId;

export const selectIsLoadingCreateGroup = (state: RootState) => state.groups.isLoadingCreateGroup;
export const selectCreateGroupError = (state: RootState) => state.groups.createGroupError;

export const selectIsLoadingJoinGroup = (state: RootState) => state.groups.isLoadingJoinGroup;
export const selectJoinedGroupId = (state: RootState) => state.groups.joinedGroupId;
export const selectJoinGroupError = (state: RootState) => state.groups.joinGroupError;

export const selectIsLoadingDeleteGroup = (state: RootState) => state.groups.isLoadingDeleteGroup;
