import axios from 'axios';
import firebase from '../firebase';
import log from '../log';
import { createNewFilename } from '../util';

const FILE_UPLOAD_LIMIT_BYTES = 50000000;

const storage = firebase.storage();

export function getFileType(file) {
  let type;
  if (file instanceof File) {
    type = file.name.split('.').pop();
  } else if (file instanceof Blob) {
    type = file.type.split('/').pop();
  } else {
    log.warn('unknown file object type');
    type = 'png';
  }

  return type;
}

export function getFileTypeByExtension(extension) {
  let type;
  switch (extension ? extension.toLowerCase() : null) {
    case 'gif':
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'svg':
      type = 'image';
      break;
    case 'ppt':
    case 'pptx':
    case 'key':
      type = 'presentation';
      break;
    case 'txt':
    case 'rtf':
    case 'doc':
    case 'docx':
      type = 'text';
      break;
    case 'pdf':
      type = 'pdf';
      break;
    case 'xls':
    case 'xlsx':
    case 'csv':
      type = 'spreadsheet';
      break;
    case 'mp4':
    case 'mov':
      type = 'video';
      break;
    case 'mp3':
    case 'wav':
    case 'm4a':
    case 'ogg':
    case 'flac':
      type = 'audio';
      break;
    default:
      type = 'files';
  }

  return type;
}

export function getFileCategory(fileType) {
  switch (fileType) {
    case 'image': {
      return 'images';
    }
    case 'pdf': {
      return 'pdf';
    }
    default: {
      return 'files';
    }
  }
}

export function uploadToStorage(file, storagePath) {
  return new Promise((resolve, reject) => {
    const uploadTask = storage.ref(storagePath).put(file);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        log.debug(`Upload is ${progress}% done`);
      },
      (error) => {
        log.error('Error uploading file', error);
        reject(error);
      },
      () => {
        resolve(uploadTask);
      }
    );
  });
}

export async function uploadFile(file, storagePath) {
  if (file.size > FILE_UPLOAD_LIMIT_BYTES) {
    log.error('Upload Failed: file is too big');
    return null;
  }

  let fileName = file.name;
  let availableFilename = false;
  let filePath;
  while (!availableFilename) {
    filePath = `${storagePath}/${fileName}`;
    const ref = storage.ref(filePath);
    let urlTest;
    try {
      urlTest = await ref.getDownloadURL();
    } catch (err) {
      if (err.code !== 'storage/object-not-found') {
        log.error(err);
      }
    }

    if (urlTest !== undefined) {
      fileName = createNewFilename(fileName);
    } else {
      availableFilename = true;
    }
  }

  const uploadTask = await uploadToStorage(file, filePath);

  // Upload completed successfully, now we can get the download URL
  const fileURL = await uploadTask.ref.getDownloadURL();
  log.debug('File available at', fileURL);

  return fileURL;
}

/**
 *
 * @param {File[]} files
 * @returns {number} - total size of the files (in bytes)
 */
export const getFilesTotalSize = (files) => files.reduce((totalSize, file) => totalSize + file.size, 0);

export const deleteUploadedFile = (fileURL) => storage.refFromURL(fileURL).delete();

export const getImageDimensions = async (file) => {
  const photoUrl = URL.createObjectURL(file);
  const image = new Image();
  return new Promise((resolve) => {
    image.onload = () => {
      resolve({
        height: image.height,
        width: image.width,
      });
    };
    image.src = photoUrl;
  });
};

export const downloadFile = (fileURL, fileName) => {
  axios({
    url: fileURL,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  });
};
