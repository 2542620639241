import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectIsAdded } from '../store/emotes/selectors';

interface Props {
  url: string;
  onAddClick: () => void;
  onRemoveClick: () => void;
}

const EmoteButton: React.FC<Props> = ({ url, onAddClick, onRemoveClick }) => {
  const isAdded = useSelector((state) => selectIsAdded(state, url));

  return (
    <Emote isAdded={isAdded} onClick={isAdded ? onRemoveClick : onAddClick}>
      <PlusMinus>{isAdded ? '-' : '+'}</PlusMinus>
      <img src={url} alt="emote" width={34} height={34} />
    </Emote>
  );
};

export default EmoteButton;

const PlusMinus = styled.div`
  display: none;

  position: absolute;
  top: -9px;
  right: -9px;

  color: white;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  background: #6b1be3;
  border-radius: 30px;
  padding-bottom: 2px;
`;

const Emote = styled.div<{ isAdded: boolean }>`
  width: 40px;
  height: 40px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  border-radius: 5px;

  box-sizing: border-box;

  &:hover {
    border: 3px solid #6b1be3;

    ${PlusMinus} {
      display: flex;
    }
  }

  img {
    width: 34px;
    height: 34px;
  }

  ${({ isAdded }) =>
    isAdded &&
    `
    background: #6b1be3;
  `}
`;
