import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const SpinnerLoader = ({ color, borderSize, height, width }) => (
  <Spinner color={color} borderSize={borderSize} height={height} width={width} />
);

export default SpinnerLoader;

SpinnerLoader.propTypes = {
  color: PropTypes.string,
  borderSize: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

SpinnerLoader.defaultProps = {
  color: '#6B1BE3',
  borderSize: '3px',
  height: '15px',
  width: '15px',
};

const Spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: ${({ borderSize }) => `${borderSize} solid #f3f3f3`};
  border-top: ${({ color, borderSize }) => `${borderSize} solid ${color}`};
  border-radius: 50%;
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  animation: ${Spin} 0.75s linear infinite;
`;
