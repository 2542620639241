import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';

// components
import ChatWindow from '../../ChatWindow';
import NoDMsWidget from '../NoDMsWidget';
import DMsLoadingWidget from '../DMsLoadingWidget';
import ChatInput, { Container as ChatInputContainer } from '../../chat-input/ChatInput.tsx';
import ChatWindowTopBar from './ChatWindowTopBar';
import CallButton from './CallButton';
import SkinningFrameWrapper from '../../../os/chat/skinning/frame/SkinningFrameWrapper.tsx';
import SkinningParticlesEmitter from '../../../os/chat/skinning/particles/SkinningParticlesEmitter.tsx';
import CloseIcon from '../../../../../assets/icons/x.svg';

// hooks
import { useLocalMessagesList } from '../../../hooks/useLocalMessagesList';
import { useUserProfileData } from '../../../hooks/useUserProfileData';
import { useDMs } from '../../../hooks/useDMs';

// store
import {
  selectDMChatByReceiverId,
  selectLoadingMessages,
  selectChatHasMore,
  selectAllChatMessages,
  selectDraftMessage,
  selectActiveReceiverId,
  selectActiveGroupChatId,
  selectActiveChatGroupName,
  selectChatByChatId,
  selectChatPermissions,
} from '../../../store/messaging/selectors';
import {
  setActiveReceiverId,
  fetchChat,
  openExistingDM,
  addMessage,
  setActiveGroupChatId,
  setActiveChatId,
} from '../../../store/messaging/reducer';
import { selectCurrentUser, selectCurrentUserId } from '../../../store/users/selectors';
import { getRawPresentMembers } from '../../../store/room-members/selectors';
import { selectRoomId } from '../../../store/room/selectors';

// constants
import { CHANGE_MESSAGE_FONT_STYLE_SOURCES } from '../../../../constants/analytics-events/chat-events.ts';
import { messageTypes, chatCallState, INVITE_MESSAGE_DELAY_MS } from '../../../../constants/chat-constants';
import { gifPickerCallers } from '../../../../constants/image-constants';
import { CALL_FROM_DM_WINDOW } from '../../../../constants/analytics-events/dm-events';
import { useTypingUsersList } from '../../../hooks/feed/useTypingUsersList';
import { ChatPermissionsContext, EventSourceContext } from '../../../common/contexts.ts';
import { FRIEND_REQUEST_SOURCES } from '../../../../constants/analytics-events/friend-events';
import { applyChatTheme } from '../../../../util/theming-util';
import { svgColorMixin } from '../../../mixins';

// USAGE NOTE: DMWindow relies on activeReceiverId being set in the redux messages store
const DMWindow = () => {
  const dispatch = useDispatch();

  const activeReceiverId = useSelector(selectActiveReceiverId);
  const activeGroupChatId = useSelector(selectActiveGroupChatId);
  const activeGroupChatName = useSelector(selectActiveChatGroupName);

  const currentProfile = useSelector(selectCurrentUser);
  const {
    userProfile: { displayName, username },
  } = useUserProfileData(activeReceiverId);

  const dmWindowPopoverOpen = useMemo(
    () => !!(activeReceiverId || activeGroupChatId),
    [activeReceiverId, activeGroupChatId]
  );

  // For DM:
  const DMWithYouAndReceiver = useSelector((state) => selectDMChatByReceiverId(state, activeReceiverId));

  const chatId = activeGroupChatId || DMWithYouAndReceiver[0]?.id;

  const draftMessage = useSelector((state) => selectDraftMessage(state, chatId));
  const hasMore = useSelector((state) => selectChatHasMore(state, chatId));

  const storageFolder = useMemo(
    () => (currentProfile.id ? `users/${currentProfile.id}/dms${chatId ? `/${chatId}` : ''}` : null),
    [currentProfile.id, chatId]
  );

  const loadMoreMessages = useCallback(() => {
    dispatch(fetchChat({ chatId, isPagination: true }));
  }, [dispatch, chatId]);

  const loadingMessages = useSelector(selectLoadingMessages);
  const messagesList = useSelector((state) => selectAllChatMessages(state, chatId));
  const { resultMessagesList, addMessageLocally, deleteLocalMessage, deleteAllLocalMessages } = useLocalMessagesList({
    messagesList,
  });

  const userId = useSelector(selectCurrentUserId);

  useEffect(() => {
    if (chatId) {
      // if chat previously exists, fetch previous messages:
      dispatch(openExistingDM({ chatId }));
    }
  }, [dispatch, chatId]);

  const { onSendMessage, onCreateChat, onMessageUploadSuccess, onDeleteMessage } = useDMs({ chatId, activeReceiverId });

  const onMessageUploadFail = React.useCallback(
    (errorData) => {
      const id = `dmwindow-upload-failed-${Math.random()}`;
      addMessageLocally({
        id,
        type: messageTypes.UPLOAD_ERROR,
        errorType: errorData.type,
        creator: userId,
        text: errorData.message,
        createdAt: new Date(),
        deleteSelf: () => deleteLocalMessage(id),
      });
    },
    [userId, addMessageLocally, deleteLocalMessage]
  );

  const inputEntry = useMemo(
    () => (
      <ChatInput
        type={CHANGE_MESSAGE_FONT_STYLE_SOURCES.DM}
        chatId={chatId}
        elementId="DMChatInput"
        caller={gifPickerCallers.DM}
        storageFolder={storageFolder}
        draftMessage={draftMessage}
        onSendMessage={chatId ? onSendMessage : onCreateChat}
        onMessageUploadSuccess={onMessageUploadSuccess}
        onMessageUploadFail={onMessageUploadFail}
        userId={currentProfile.id}
      />
    ),
    [
      chatId,
      draftMessage,
      storageFolder,
      onSendMessage,
      onCreateChat,
      onMessageUploadSuccess,
      onMessageUploadFail,
      currentProfile.id,
    ]
  );

  const closeDMWindow = () => {
    deleteAllLocalMessages();
    dispatch(setActiveReceiverId({ activeReceiverId: null }));
    dispatch(setActiveGroupChatId({ activeGroupChatId: null }));
    dispatch(setActiveChatId({ activeChatId: null }));
  };

  const onClose = () => {
    if (dmWindowPopoverOpen) {
      closeDMWindow();
    }
  };

  const [callState, setCallState] = useState(null);
  const roomId = useSelector(selectRoomId);

  const usersInRoom = useSelector(getRawPresentMembers);
  const userIsCallable = useMemo(() => !usersInRoom[activeReceiverId], [usersInRoom, activeReceiverId]);

  const onCall = useCallback(() => {
    if (chatId) {
      dispatch(addMessage({ type: messageTypes.INVITE, chatId, roomId, inviteMessageSource: CALL_FROM_DM_WINDOW }));
    } else {
      dispatch(
        addMessage({
          type: messageTypes.INVITE,
          receiverId: activeReceiverId,
          createChat: true,
          roomId,
          inviteMessageSource: CALL_FROM_DM_WINDOW,
        })
      );
    }
    setCallState(chatCallState.IS_CALLING);

    setTimeout(() => setCallState(chatCallState.DISABLE), INVITE_MESSAGE_DELAY_MS);
  }, [chatId, roomId, dispatch, activeReceiverId]);

  useEffect(() => {
    if (!userIsCallable) {
      setCallState(chatCallState.DISABLE);
    } else if (userIsCallable && !callState) {
      setCallState(chatCallState.ENABLE);
    }
  }, [callState, userIsCallable, activeGroupChatId]);

  useEffect(() => {
    // reset call state when chat changes
    setCallState(userIsCallable ? chatCallState.ENABLE : chatCallState.DISABLE);
  }, [chatId, userIsCallable]);

  // Added 'ReceiverId' here to prevent collisions with firestore element ids
  const elementId = `ReceiverId${activeReceiverId || activeGroupChatId}`;

  const { typingUsersList } = useTypingUsersList(chatId);

  const containerRef = useRef(null);
  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  useEffect(() => {
    if (!containerRef.current) return;
    applyChatTheme(chat?.theme, containerRef.current);
  }, [chat?.theme]);

  const chatWindowTheme = useMemo(
    () => ({
      container: {
        background: 'transparent',
      },
    }),
    []
  );

  const background = chat?.theme?.skin?.background?.original || chat?.background?.original;

  const permissions = useSelector((state) => selectChatPermissions(state, chatId));

  return (
    <ChatPermissionsContext.Provider value={permissions}>
      <DMWindowWrapper id={`element-${elementId}`} className="dm-window-container unthemed" ref={containerRef}>
        <Background backgroundImage={background} />
        <Background
          backgroundColor={
            chat?.background?.original ? 'var(--primary-background-alpha-40)' : 'var(--primary-background)'
          }
        />
        <SkinningFrameWrapper frame={chat?.theme?.skin?.frame} borderRadius={11}>
          {chat?.theme?.colors?.secondaryBackground ? (
            <Background
              backgroundColor={`linear-gradient(0deg, ${chat.theme.colors.secondaryBackground} 0%, transparent 20%, transparent 80%, ${chat.theme.colors.secondaryBackground} 100%);`}
            />
          ) : null}
          <ParticlesContainer>
            <SkinningParticlesEmitter particles={chat?.theme?.skin?.particle} uniqueIdPrefix={chatId} />
          </ParticlesContainer>
          {/* We only want to pass hub chat source below if it's a group chat */}
          <EventSourceContext.Provider value={activeGroupChatId && FRIEND_REQUEST_SOURCES.HUB_CHAT}>
            <ChatWindowTopBar
              chatTitle={activeGroupChatId ? activeGroupChatName : displayName}
              username={username}
              controls={
                <ControlsContainer>
                  {/* no calling for group chats for now */}
                  {activeGroupChatId ? null : <CallButton callState={callState} onCall={onCall} />}
                  <CloseButton onClick={onClose}>
                    <CloseIcon width={13} height={13} />
                  </CloseButton>
                </ControlsContainer>
              }
            />
            <ThemeProvider theme={chatWindowTheme}>
              <ChatWindow
                chatId={chatId}
                messagesList={resultMessagesList}
                onDeleteMessage={onDeleteMessage}
                loadMoreMessages={loadMoreMessages}
                hasMore={hasMore}
                typingUsersList={typingUsersList}
                canAddToRoom={false}
                noMessagesWidget={
                  loadingMessages ? (
                    <DMsLoadingWidget />
                  ) : (
                    <NoDMsWidget username={`@${username}`} displayName={displayName} groupName={activeGroupChatName} />
                  )
                }
                inputEntry={inputEntry}
              />
            </ThemeProvider>
          </EventSourceContext.Provider>
        </SkinningFrameWrapper>
      </DMWindowWrapper>
    </ChatPermissionsContext.Provider>
  );
};

export default DMWindow;

const DMWindowWrapper = styled.div`
  height: 100%;
  position: relative;
  padding: 8px;

  ${ChatInputContainer} {
    border-radius: 0 0 5px 5px;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  ${svgColorMixin('var(--primary-foreground, white)')}
`;

const ControlsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ParticlesContainer = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;

  div[id] {
    height: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ backgroundImage, backgroundColor }) =>
    backgroundImage ? `url(${backgroundImage})` : backgroundColor};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 11px;
`;
