import { htmlToElement } from '../../util';
import EmbeddedResource from './embedded-resource';

const regex = /trello\.com\/b\/([a-zA-Z\d]+)\/?([^?]*)?/i;

export default class TrelloElement extends EmbeddedResource {
  static getEmbedUrl(url) {
    const match = url.match(regex);
    return match ? `https://trello.com/b/${match[1]}.html` : null;
  }

  getUnderlayContent({ url }) {
    const arr = url.split('/');
    const title = arr[arr.length - 1];
    const elTitle = title || 'Trello';
    this.setTooltip(elTitle);
    return htmlToElement(`
      <div class="underlay-content">
        <div class="underlay-background trello-background"></div>
        <img class="icon" alt="Trello icon" src="images/icons/trello.svg" />
        <span class="title">${elTitle}</span>
      </div>
    `);
  }
}

TrelloElement.elementType = 'TrelloElement';
