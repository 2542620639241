import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Picker } from 'emoji-mart';
import ContentEditable from 'react-contenteditable';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { applyTheme } from '../../../util/theming-util';
import { Mood } from '../../../definitions/user-profile';
import { svgColorMixin } from '../../mixins';
import MoodThemeSettings from './MoodThemeSettings';

import DismissIcon from '../../../../assets/icons/dismiss-thick.svg';
import CheckIcon from '../../../../assets/icons/check-thick.svg';
import PaletteIcon from '../../../../assets/icons/palette.svg';
import EmojiIcon from '../../../../assets/icons/emoji.svg';
import { getRandomHexColor, invertHexColor } from '../../../util/color-util';
import { updateProfile } from '../api';
import { selectCurrentUserId } from '../../store/users/selectors';
import { track } from '../../../util/analytics-util';
import { EDIT_MOOD_TEXT, EDIT_MOOD_THEME, REMOVE_MOOD } from '../../../constants/analytics-events/user-events';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import {
  DEFAULT_SECONDARY_BACKGROUND,
  DEFAULT_SECONDARY_FOREGROUND,
  DEFAULT_THEME_COLORS,
} from '../../groups-lobby/style-constants';
import { EditIcon, MoodContainer, MoodContent } from './shared-styles';
import MarqueeMood from './MarqueeMood';
import { selectCurrentThemeColors } from '../../store/room/selectors';

const moodTextLimit = 50;

type Props = {
  mood?: Mood;
  editState?: boolean;
  editPopoverLocation?: 'top' | 'bottom';
  onEnterEditMode?: () => void;
  onExitEditMode?: () => void;
  hasMarquee?: boolean;
};

const EditableMood: React.FC<Props> = ({
  mood,
  editState = false,
  editPopoverLocation = 'top',
  onEnterEditMode = () => {},
  onExitEditMode = () => {},
  hasMarquee = false,
}) => {
  const currentUserId = useSelector(selectCurrentUserId);
  const resultTheme = useSelector(selectCurrentThemeColors) || DEFAULT_THEME_COLORS;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('emoji-mart/css/emoji-mart.css');
  }, []);

  const draftMoodRef = useRef<Mood>(null);
  const [draftMood, setDraftMood] = useState<Mood>(null);
  const updateDraftMood = (newMood: Mood) => {
    const text = newMood?.text ? newMood.text.substring(0, moodTextLimit) : null;
    draftMoodRef.current = newMood ? { text, theme: newMood.theme } : null;
    setDraftMood(draftMoodRef.current);
  };

  const moodContainerRef = useRef(null);
  const [areThemeSettingsOpened, setAreThemeSettingsOpened] = useState(false);
  const [isEmojiPickerOpened, setIsEmojiPickerOpened] = useState(false);
  const [isThemeChanged, setIsThemeChanged] = useState(false);

  const updateTheme = useCallback(() => {
    const theme = draftMoodRef.current ? draftMoodRef.current.theme : mood?.theme;
    if (theme && moodContainerRef.current) {
      applyTheme(theme.colors, theme.primaryFont, moodContainerRef.current);
    }
  }, [mood]);
  useEffect(updateTheme, [updateTheme]);

  const setMoodRef = useCallback(
    (ref: HTMLElement) => {
      if (ref?.contentEditable === 'true') {
        ref.focus();
        if (draftMoodRef.current?.text) {
          const range = document.createRange();
          const selection = window.getSelection();
          range.setStart(ref.childNodes[0], ref.childNodes[0].textContent.length);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
      moodContainerRef.current = ref;
      updateTheme();
    },
    [updateTheme]
  );

  const createMood = () => {
    const primaryBackground = getRandomHexColor();
    const primaryForeground = invertHexColor(primaryBackground);
    updateDraftMood({ text: '', theme: { colors: { primaryBackground, primaryForeground }, primaryFont: 'Inter' } });
    onEnterEditMode();
  };

  useEffect(() => {
    if (editState) {
      createMood();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState]);

  const editExistingMood = () => {
    updateDraftMood(mood);
    onEnterEditMode();
  };
  const endEditing = () => {
    setAreThemeSettingsOpened(false);
    setIsEmojiPickerOpened(false);
    setIsThemeChanged(false);
    updateDraftMood(null);
    onExitEditMode();
  };

  const endAndSaveEditing = async () => {
    if (!draftMood) return;

    const text = draftMoodRef.current?.text?.trim();

    if (!draftMoodRef.current?.text && mood?.text) {
      track(REMOVE_MOOD);
    } else if (draftMoodRef.current?.text !== mood?.text) {
      track(EDIT_MOOD_TEXT);
    }
    if (draftMoodRef.current?.text && isThemeChanged) {
      track(EDIT_MOOD_THEME);
    }

    updateProfile(currentUserId, {
      mood: draftMoodRef.current?.text ? { ...draftMood, text } : null,
    });
    endEditing();
  };
  const onThemeSettingsClick = () => {
    setAreThemeSettingsOpened(!areThemeSettingsOpened);
    setIsEmojiPickerOpened(false);
    moodContainerRef.current.innerHTML = moodContainerRef.current.innerHTML.replaceAll(/&nbsp;/g, ' ');
  };
  const onEmojiPickerClick = () => {
    setIsEmojiPickerOpened(!isEmojiPickerOpened);
    setAreThemeSettingsOpened(false);
  };
  const onMoodTextChange = () => {
    updateDraftMood({ ...draftMood, text: moodContainerRef.current.textContent });
  };

  const onMoodBlur = () => {
    // manually trigger blur event
    moodContainerRef.current.blur();
  };
  const onMoodKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key !== 'Enter') return;
    e.preventDefault();
    endAndSaveEditing();
  };

  const onThemeChange = useCallback(
    (theme) => {
      updateDraftMood({ ...draftMood, theme });
      setIsThemeChanged(true);
      updateTheme();
    },
    [draftMood, updateTheme]
  );
  const onEmojiSelected = useCallback(
    (data) =>
      updateDraftMood({
        ...draftMood,
        // adding a space manually to avoid addition of non-breaking space
        text: draftMood.text ? `${draftMood.text} ${data.native}` : `${data.native}`,
      }),
    [draftMood]
  );

  const containerRef = useRef(null);
  const additionalContentRef = useRef(null);
  useOnClickOutside(containerRef, () => {
    if (!areThemeSettingsOpened && !isEmojiPickerOpened) {
      endAndSaveEditing();
    }
  });
  useOnClickOutside(additionalContentRef, () => {
    setAreThemeSettingsOpened(false);
    setIsEmojiPickerOpened(false);
  });

  let additionalContent;
  if (areThemeSettingsOpened) {
    additionalContent = (
      <div ref={additionalContentRef}>
        <MoodThemeSettings theme={draftMood.theme} onChange={onThemeChange} />
      </div>
    );
  } else if (isEmojiPickerOpened) {
    additionalContent = (
      <div ref={additionalContentRef}>
        <Picker
          sheetSize={16}
          emojiSize={22}
          perLine={6}
          onSelect={onEmojiSelected}
          showPreview={false}
          showSkinTones={false}
          native
        />
      </div>
    );
  }

  if (!mood && !draftMood) {
    return (
      <AddMoodButton themeColors={resultTheme} className="here-button-regular" onClick={createMood}>
        + Add Mood
      </AddMoodButton>
    );
  }

  return (
    <Container ref={containerRef} isEditing={Boolean(draftMood)}>
      {additionalContent && (
        <AdditionalContainer editPopoverLocation={editPopoverLocation}>{additionalContent}</AdditionalContainer>
      )}
      <MoodContainer>
        {draftMood ? (
          <>
            <TooltipPrimitive.Root>
              <TooltipPrimitive.Trigger asChild>
                <IconButton onClick={onThemeSettingsClick}>
                  <PaletteIcon />
                </IconButton>
              </TooltipPrimitive.Trigger>
              <TooltipPrimitive.Content asChild side="top">
                <ButtonHint>Style & Color</ButtonHint>
              </TooltipPrimitive.Content>
            </TooltipPrimitive.Root>
            <MoodContent
              as={ContentEditable}
              className="themed-font"
              onChange={onMoodTextChange}
              onKeyDown={onMoodKeyDown}
              innerRef={setMoodRef}
              html={draftMood.text || ''}
              onBlur={onMoodBlur}
            />
            <TooltipPrimitive.Root>
              <TooltipPrimitive.Trigger asChild>
                <IconButton onClick={onEmojiPickerClick}>
                  <EmojiIcon />
                </IconButton>
              </TooltipPrimitive.Trigger>
              <TooltipPrimitive.Content asChild side="top">
                <ButtonHint>Emoji</ButtonHint>
              </TooltipPrimitive.Content>
            </TooltipPrimitive.Root>
          </>
        ) : (
          <ViewMoodContainer hasMarquee={hasMarquee} onClick={editExistingMood}>
            {hasMarquee ? (
              <MarqueeMood mood={mood} canEdit />
            ) : (
              <MoodContent className="themed-font" onClick={editExistingMood} ref={setMoodRef} canEdit>
                <EditIcon />
                {mood.text}
              </MoodContent>
            )}
          </ViewMoodContainer>
        )}
      </MoodContainer>
      {draftMood && (
        <ButtonsContainer>
          <Button onClick={endEditing}>
            <DismissIcon width="10" height="10" />
          </Button>
          <Button onClick={endAndSaveEditing}>
            <CheckIcon width="12" height="13" />
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  );
};

export default EditableMood;

const Container = styled.div<{ isEditing: boolean }>`
  width: 100%;
  position: relative;

  ${({ isEditing }) =>
    isEditing &&
    `
    min-width: 200px;
  `}
`;

const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;

  ${svgColorMixin('var(--primary-foreground-alpha-50, rgba(255, 255, 255, 0.5))')}

  &:hover {
    ${svgColorMixin('var(--primary-foreground, white)')}
  }
`;

const AddMoodButton = styled.button<{ themeColors: { secondaryForeground: string; secondaryBackground: string } }>`
  border-radius: 40px;
  padding: 8px;
  margin: 0 auto 4px;
  font-size: 14px;
  font-weight: bold;
  width: 103px;

  ${({ themeColors }) => `
      background: ${themeColors?.secondaryBackground || DEFAULT_SECONDARY_BACKGROUND};
      color: ${themeColors?.secondaryForeground || DEFAULT_SECONDARY_FOREGROUND};

      &:hover {
        background: ${themeColors?.secondaryBackground || DEFAULT_SECONDARY_BACKGROUND};
        color: ${themeColors?.secondaryForeground || DEFAULT_SECONDARY_FOREGROUND};
      }
  `}
`;

const PopoutContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  width: 240px;
  left: calc(50% - 120px);
  position: absolute;
  top: 0;
  transform: translateY(-100%);
`;

const AdditionalContainer = styled(PopoutContainer)<{ editPopoverLocation: 'top' | 'bottom' }>`
  ${({ editPopoverLocation }) => `transform: translateY(${editPopoverLocation === 'bottom' ? '45px' : '-100%'})`}
`;

const ButtonHint = styled.div`
  background: #12002d;
  color: white;
  font-size: 14px;
  padding: 8px;
  border-radius: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0 10px;
`;

const Button = styled.button`
  border-radius: 50%;
  margin: 0 4px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  border: none;
  transform: scale(1);
  transition: transform 0.1s ease-out;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  background: white;
  ${svgColorMixin('#12002d')}
  &:hover {
    transform: scale(1.05);
  }
`;

const ViewMoodContainer = styled.div<{ hasMarquee: boolean }>`
  ${({ hasMarquee }) => hasMarquee && 'max-width: 100%;'}
`;
