import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../store/Wrapper';

import TextChannel from './TextChannel';
import Timer from './Timer';

export function renderTextChannelBoardElement(root, { elementId }) {
  ReactDOM.render(
    <WithRoomStore>
      <TextChannel elementId={elementId} />
    </WithRoomStore>,
    root
  );
}

export function renderTimerBoardElement(root, { elementId }) {
  ReactDOM.render(
    <WithRoomStore>
      <Timer elementId={elementId} />
    </WithRoomStore>,
    root
  );
}
