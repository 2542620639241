import styled, { keyframes } from 'styled-components';
import { PROFILE_CARD_WIDTH_PX } from '../user-profile/constants';

export const ContainerDefault = styled.div`
  width: 440px;
`;

export const ScreenTitle = styled.h2`
  color: #6b1be3;
  font-size: 22px;
  font-weight: bolder;
  text-align: center;
`;

export const ScreenSubTitle = styled.h3<{ textColor?: string }>`
  color: ${({ textColor }) => textColor || '#6b1be3'};
  font-size: 16px;
  font-weight: normal;
  text-align: center;
`;

export const TitleContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  color: #1a1a1a;
`;

export const InputContainer = styled.div``;

export const NavButtonsContainer = styled.div`
  margin-top: 18px;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

export const NavButton = styled.button`
  padding: 4px;
  flex-grow: 1;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.9;
  border-radius: 12px;
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const PrevNavButton = styled(NavButton)`
  color: #1a1a1a;
  background-color: #ededed;
`;

export const NextNavButton = styled(NavButton)`
  color: white;
  background-color: #6b1be3;

  &:disabled {
    background-color: #6b1be366;
  }
`;

const AnotherNavButton = styled(NavButton)`
  width: ${PROFILE_CARD_WIDTH_PX}px;
  padding: 13px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 40px;
`;

export const AnotherPrevNavButton = styled(AnotherNavButton)`
  background: rgba(255, 255, 255, 0.3);
  color: white;
`;

export const AnotherNextNavButton = styled(AnotherNavButton)`
  background: white;
  color: #6b1be3;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);

  &:disabled {
    opacity: 0.2;
  }
`;

export const SingleNextNavButton = styled(NextNavButton)<{ width: string }>`
  width: ${({ width }) => width || '100%'};
  flex-grow: 0;
`;

export const FormContainer = styled.div`
  padding-top: 20px;
`;

export const IconContainer = styled.div`
  padding-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextNavButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextButton = styled.button`
  margin-top: 25px;
  font-size: 14px;
  color: grey;
  font-weight: bold;
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const bounceInAnimation = keyframes`
  0% {
    transform: scale(0);
  }

  85% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
`;

export const BlurredContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
