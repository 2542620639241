import { all, takeEvery, put, select, join, call, takeLatest, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { getUserProfiles } from './api';
import { selectCurrentUserId } from './selectors';
import log from '../../../log';

import * as store from './store';
import firebase, { db } from '../../../firebase';
import { requestAsync } from '../helpers';
import { track } from '../../../util/analytics-util';
import { USER_SEARCH } from '../../../constants/analytics-events/user-events';
import { isHereEmployee } from '../../../util/user-util';
import { offSnapshot, onSnapshot } from '../../../firestore-watcher';

function* setUserOnlineStatus({ payload: { userId, onlineStatus } }) {
  // Debounce so the status doesn't flicker
  yield delay(500);

  try {
    yield put(
      store.updateUsersFromArray({
        users: [
          {
            userId,
            onlineStatus,
          },
        ],
      })
    );
  } catch (err) {
    yield call([log, 'error'], err);
  }
}

function* searchUsers({ payload: { searchQuery, source } }) {
  try {
    const response = yield call(requestAsync, () => getUserProfiles({ searchQuery }));
    const searchResultUserIds = response.profiles.map((u) => u.userId);

    track(USER_SEARCH, { source, searchQuery });

    // add users to store:
    yield put(store.updateUsersFromArray({ users: response.profiles }));
    // add array of userIds as search results to store:
    yield put(store.updateSearchResults({ userIds: searchResultUserIds }));
  } catch (e) {
    yield put(store.searchUsersError({ error: 'There was an error! Please try again' }));
  }
}

function* watchUserSwitched() {
  const channel = eventChannel((emitter) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      const isAdmin = await isHereEmployee(user);
      const payload = user ? { id: user.uid, email: user.email, isAdmin, phoneNumber: user.phoneNumber } : '';
      emitter(payload);
    });

    return unsubscribe;
  });

  yield takeEvery(channel, function* onAuthStateChanged(payload) {
    if (payload) {
      yield put(store.currentUserSwitched(payload));
    }
  });
}

function* watchCurrentUser({ payload: { id } }) {
  if (!id) {
    return;
  }

  const channel = eventChannel((emitter) => {
    const listener = (user) => user && emitter(user);

    onSnapshot(`/userProfiles/${id}`, listener);
    return () => offSnapshot(`/userProfiles/${id}`, listener);
  });

  try {
    const task = yield takeEvery(channel, function* onCurrentUserUpdated(user) {
      yield put(store.currentUserUpdated(user));
    });
    yield join(task);
  } finally {
    channel.close();
  }
}

function* currentUserProfileUpdateRequest({ payload: { patch } }) {
  const userId = yield select(selectCurrentUserId);
  yield call([db.doc(`userProfiles/${userId}`), 'set'], patch, { merge: true });
}

export default function* usersSaga() {
  yield all([
    watchUserSwitched(),
    takeEvery(store.currentUserSwitched, watchCurrentUser),
    takeEvery(store.searchUsers, searchUsers),
    takeEvery(store.currentUserProfileUpdateRequest, currentUserProfileUpdateRequest),
    takeLatest(store.setUserOnlineStatus, setUserOnlineStatus),
  ]);
}
