import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// components
// import ChatTooltip from '../ChatIconTooltip';
import UnreadsBadge, { UnreadsBadgeContainer } from './UnreadsBadge';

// utils
import { selectChatByChatId } from '../../store/messaging/selectors';
import ChatIconTooltip from '../ChatIconTooltip';

const MessageSidebarGroupIcon = ({ chatId, isActive, onClick }) => {
  const groupChat = useSelector((state) => selectChatByChatId(state, chatId));

  if (!groupChat) return null;

  return (
    <ChatIconTooltip title={groupChat.groupName} subtitle="">
      <GroupIconContainer>
        {groupChat.unreadCount > 0 && <UnreadsBadge unreadsCount={groupChat.unreadCount} />}
        <GroupIconButton className="message-sidebar-avatar-container" isActive={isActive} onClick={onClick}>
          {groupChat.background ? (
            <GroupChatImage src={groupChat.background.thumbnail || groupChat.background.original} alt="group chat" />
          ) : (
            <GroupChatIcon />
          )}
        </GroupIconButton>
      </GroupIconContainer>
    </ChatIconTooltip>
  );
};

export default MessageSidebarGroupIcon;

MessageSidebarGroupIcon.propTypes = {
  chatId: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

MessageSidebarGroupIcon.defaultProps = {
  isActive: false,
  onClick: () => {},
};

const groupChatIconSizePx = 42;
const groupChatIconMixin = () => css`
  height: ${groupChatIconSizePx}px;
  width: ${groupChatIconSizePx}px;
  display: inline-block;
  border-radius: 5px;
  transition: transform 0.1s ease-in;
  &:hover {
    transform: scale(1.1);
  }
`;

const GroupIconContainer = styled.div`
  position: relative;
  ${UnreadsBadgeContainer} {
    position: absolute;
    margin-left: 27px;
    margin-top: -7px;
  }
`;

const GroupChatIcon = styled.div`
  ${groupChatIconMixin()}
  // GROUPS TODO: we probably want to change this background at some point, just setting some default for now
  background: linear-gradient(63.8deg, #fc6957 16.96%, #90008c 83.51%);
`;

const GroupChatImage = styled.img`
  ${groupChatIconMixin()}
  object-fit: cover;
`;

const GroupIconButton = styled.div`
  margin: 6px 0;
  padding: 0;
  height: ${groupChatIconSizePx}px;
  width: ${groupChatIconSizePx}px;
  background: none;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 12px rgb(0 0 0 / 40%);
  border-radius: 5px;
  ${({ isActive }) =>
    isActive
      ? `
    ${GroupChatIcon}, ${GroupChatImage} {
      border: 2px solid white;
    }
  `
      : ''}
`;
