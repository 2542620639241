import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { fetchBoard } from '../../api/boards-api';

// store
import { selectIsNewRoomCreating } from '../store/new-room/selectors';
import { selectCurrentUserId } from '../store/users/selectors';

// components
import VibePickerOption from './VibePickerOption';
import { AnotherNextNavButton } from '../sign-in-flow/shared-styled';
import VibePickerCustomOption from './VibePickerCustomOption';

// hooks
import { useVibeRooms } from './useVibeRooms';

// styles
import {
  ContainerBackgroundGradient,
  Subtitle,
  Title,
  VibePickerContent,
  VibeRoomsContainer,
  ButtonsContainer,
} from './shared-styles';

// constants
import { BLANK_ROOM_ID } from '../../constants/board-constants';
import { applyTheme } from '../../util/theming-util';

const VIBE_PICKER_CUSTOM_OPTION_ID = 'vibe-picker-custom-option';

let blankRoomData: object = null;

interface Props {
  onNextClick: (activeRoom: object) => void;
}

const VibePicker: React.FC<Props> = ({ onNextClick }) => {
  const isNewRoomCreating = useSelector(selectIsNewRoomCreating);
  const currentUserId = useSelector(selectCurrentUserId);

  const [activeRoomId, setActiveRoomId] = useState(null);
  const [customBackgroundUrl, setCustomBackgroundUrl] = useState(null);

  const vibeRooms = useVibeRooms();
  const activeRoom = vibeRooms.find((room) => room.id === activeRoomId);

  const containerRef = useRef(null);
  useEffect(() => {
    if (activeRoom) {
      applyTheme(activeRoom.theme?.colors, activeRoom.theme?.primaryFont, containerRef.current);
    } else {
      // clear theme coming down from any parent components if no active room
      applyTheme(null, null, containerRef.current);
    }
  }, [activeRoom]);

  const onClick = async () => {
    let roomData = vibeRooms.find((room) => room.id === activeRoomId);
    let roomBackground;
    const isCustomBackground = !roomData;
    if (roomData) {
      roomBackground = roomData.groupBackground;
    } else {
      if (!blankRoomData) {
        blankRoomData = await fetchBoard({ boardId: BLANK_ROOM_ID });
      }

      roomData = blankRoomData;
      roomBackground = customBackgroundUrl;
    }

    const data = {
      roomData: {
        name: roomData.title,
        creator: currentUserId,
        hasCustomUrl: false,
        joinModeOpen: false,
        customUrl: '',
        template: {
          id: roomData.id,
          name: roomData.title,
          background: roomBackground,
          isBlank: false,
        },
      },

      theme: roomData.theme,

      isCustomBackground,
    };

    onNextClick(data);
  };

  return (
    <Container ref={containerRef}>
      {/* CSS doesn't support gradient transitions so to make gradient backgrounds switch smoothly I have to */}
      {/* 1) Fade out current active gradient; */}
      {/* 2) Fade in new active gradient; */}
      {vibeRooms.map((room) => (
        <ContainerBackgroundGradient
          key={room.id}
          isActive={activeRoom?.id === room.id}
          isCustomVibe={activeRoom?.id === VIBE_PICKER_CUSTOM_OPTION_ID}
          primaryBackground={room.theme?.colors?.primaryBackground}
          secondaryBackground={room.theme?.colors?.secondaryBackground}
        />
      ))}

      <VibePickerContent>
        <Title>Let's get a vibe check real quick</Title>
        <Subtitle>You can always change this later.</Subtitle>
        <VibeRoomsContainer>
          {vibeRooms.map((vibeRoom) => (
            <VibePickerOption
              key={vibeRoom.id}
              id={vibeRoom.id}
              title={vibeRoom.title}
              font={vibeRoom.theme?.primaryFont}
              // we want to pass in null if activeRoomId is not set, because the styling relies on the distinction
              isActive={activeRoomId ? activeRoomId === vibeRoom.id : null}
              bgUrl={vibeRoom.groupBackground}
              onClick={() => setActiveRoomId(vibeRoom.id)}
            />
          ))}

          <VibePickerCustomOption
            key={VIBE_PICKER_CUSTOM_OPTION_ID}
            bgUrl={customBackgroundUrl}
            setBgUrl={setCustomBackgroundUrl}
            // we want to pass in null if activeRoomId is not set, because the styling relies on the distinction
            isActive={activeRoomId ? activeRoomId === VIBE_PICKER_CUSTOM_OPTION_ID : null}
            selectCustomOption={() => setActiveRoomId(VIBE_PICKER_CUSTOM_OPTION_ID)}
          />
        </VibeRoomsContainer>
        <ButtonsContainer>
          <StyledNextButton disabled={!activeRoomId || isNewRoomCreating} onClick={onClick} data-testid="next-button">
            Next
          </StyledNextButton>
        </ButtonsContainer>
      </VibePickerContent>
    </Container>
  );
};

export default VibePicker;

const Container = styled.div`
  padding: 50px;
  max-width: 70vw;
  overflow: auto;
  box-sizing: border-box;
  background: white;
  border-radius: 30px;
  position: relative;

  pointer-events: all;
`;

const StyledNextButton = styled(AnotherNextNavButton)`
  border-radius: 12px;
  background-color: var(--secondary-background, #6b1be2);
  color: var(--secondary-foreground, white);
  box-shadow: none;
  opacity: 0.8;
  font-size: 16px;
  transition: opacity 0.2s;

  &:disabled {
    background: rgba(107, 27, 227, 0.1);
    color: #6b1be3;
  }

  &:hover {
    opacity: 1;
  }
`;
