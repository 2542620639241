import { getUpdates, loadUpdates, createNewUpdateTagHTML } from '../../feedback';
import { htmlToElement } from '../../util';
import { track } from '../../util/analytics-util';

import template from './whats-new.html';

import './whats-new.less';

/**
 *
 * @param {object} update
 * @returns {HTMLElement}
 */
function createUpdateItemElement(updateObj, isElectronVar) {
  let updateElementHTML;
  if (isElectronVar) {
    updateElementHTML = `
      <div class="update-item">
        ${updateObj.img ? `<img class="update-item__image" src="${updateObj.img}" alt="update" />` : ''}
        <p class="update-title-block">
          <span class="update-item__title">${updateObj.title}</span>
          ${updateObj.isNew ? createNewUpdateTagHTML('NEW!') : ''}
        </p>
        <p class="update-item__date">${updateObj.date}</p>
        <p class="update-item__description">
          <span>${updateObj.description}</span>
          <a class="update-item__learn-more uk-drop-close" uk-drop-close href="${
            updateObj.link
          }" target="_blank">Learn more</a>
        </p>
      </div>
    `;
  } else {
    updateElementHTML = `
      <div class="update-item">
        ${updateObj.img ? `<img class="update-item__image" src="${updateObj.img}" alt="update" />` : ''}
        ${updateObj.isNew ? createNewUpdateTagHTML('NEW!') : ''}
        <p class="update-title-block">
          <h4 class="update-item__title">${updateObj.title}</h4>
          <p class="update-item__date">${updateObj.date}</p>
        </p>
        <p class="update-item__description">
          ${updateObj.description}
          <a href="${
            updateObj.link
          }" class="update-item__learn-more uk-drop-close" uk-drop-close target="_blank">Learn more</a>
        </p>
      </div>
    `;
  }

  const updateElement = htmlToElement(updateElementHTML);
  updateElement.querySelector('.update-item__learn-more').addEventListener('click', () => {
    track('Opened Learn More link');
  });

  return updateElement;
}

class WhatsNew extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    // binds
    this.renderUpdatesList = this.renderUpdatesList.bind(this);
    this.show = this.show.bind(this);

    this.updates = [];

    this.show();
  }

  renderUpdatesList(updates) {
    const updateItemsFragment = document.createDocumentFragment();
    const isElectron = this.getAttribute('isElectron') === 'true';
    updates.forEach((updateData) => {
      updateItemsFragment.appendChild(createUpdateItemElement(updateData, isElectron));
    });

    const feedbackUpdates = this.querySelector('.updates-container');
    feedbackUpdates.innerHTML = '';
    feedbackUpdates.appendChild(updateItemsFragment);
  }

  async show() {
    await loadUpdates(true);
    this.updates = getUpdates();
    this.renderUpdatesList(this.updates);
  }
}

window.customElements.define('here-whats-new', WhatsNew);
