import React, { useEffect, useRef } from 'react';

import { Mood } from '../../../definitions/user-profile';
import { MoodContainer, MoodContent } from './shared-styles';
import { applyTheme } from '../../../util/theming-util';
import MarqueeMood from './MarqueeMood';

interface Props {
  mood: Mood;
  hasMarquee?: boolean;
}

const ViewOnlyMood: React.FC<Props> = ({ mood, hasMarquee }) => {
  const moodThemeRef = useRef(null);

  useEffect(() => {
    if (mood?.theme && moodThemeRef.current) {
      applyTheme(mood.theme.colors, mood.theme.primaryFont, moodThemeRef.current);
    }
  }, [mood?.theme, moodThemeRef]);

  if (!mood) {
    return null;
  }

  return (
    <MoodContainer>
      {hasMarquee ? (
        <MarqueeMood mood={mood} canEdit={false} />
      ) : (
        <MoodContent ref={moodThemeRef} canEdit={false}>
          <div className="themed-font">{mood.text}</div>
        </MoodContent>
      )}
    </MoodContainer>
  );
};

export default ViewOnlyMood;
