import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { svgColorMixin } from '../../../mixins';
import LinkIcon from '../../../../../assets/icons/hereos/chat/linked-room.svg';
import PlusIcon from '../../../../../assets/icons/hereos/chat/create-room.svg';
import { openTemplatePickerModal } from '../../../../util/lobby-util';
import ObservableRoomItem from '../../rooms/ObservableRoomItem';
import MainRoomItem from '../../../web-lobby/components/room-items/MainRoomItem';
import { selectVisibleRoomIdsArray } from '../../../store/web-lobby/selectors';
import { useRoomsListMembers } from '../../../web-lobby/hooks/useRoomsListWithMembers';
import { RoomServiceResponse } from '../../../../definitions/room';
import { RoomContainer, RoomsList, Title } from './shared-styles';
import LinkRoomScreen from './screens/LinkRoomScreen';
import { selectBoardsByGroupId } from '../../../store/messaging/selectors';
import { ChatPermissionsContext } from '../../../common/contexts';

interface Props {
  groupId: string;
}

const RoomSettings: React.FC<Props> = ({ groupId }) => {
  const groupRooms = useSelector((state) => selectBoardsByGroupId(state, groupId));
  const visibleRoomIds = useSelector(selectVisibleRoomIdsArray);
  const { roomsListWithMembers } = useRoomsListMembers(groupRooms, visibleRoomIds);

  const [openLinkRoomList, setOpenLinkRoomList] = useState(false);
  const closeLinkRoomList = useCallback(() => setOpenLinkRoomList(false), []);

  const permissions = useContext(ChatPermissionsContext);

  return (
    <Container>
      {openLinkRoomList ? (
        // List of rooms to link
        <LinkRoomScreen groupId={groupId} goBack={closeLinkRoomList} />
      ) : (
        // Root screen
        <>
          <Title>
            {groupRooms.length} Linked Room{groupRooms.length !== 1 ? 's' : ''}
          </Title>
          {permissions.canAddRooms ? (
            <HeaderButtons>
              <Button onClick={() => setOpenLinkRoomList(true)}>
                <LinkIcon />
                Link a Room
              </Button>
              <Button onClick={() => openTemplatePickerModal(groupId)}>
                <PlusIcon />
                Create a Room
              </Button>
            </HeaderButtons>
          ) : null}
          <RoomsList>
            {roomsListWithMembers.map((r: RoomServiceResponse & { members: object[] }) => {
              if (r.id) {
                return (
                  <RoomContainer key={r.id}>
                    <ObservableRoomItem roomId={r.id}>
                      <MainRoomItem type="secondary" roomData={r} />
                    </ObservableRoomItem>
                  </RoomContainer>
                );
              }
              return null;
            })}
          </RoomsList>
        </>
      )}
    </Container>
  );
};

export default RoomSettings;

const Container = styled.div`
  background: var(--primary-background);
  border-radius: 16px;
  height: 100%;
  padding: 23px;

  display: flex;
  flex-direction: column;
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Button = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 36px;
  cursor: pointer;
  font-size: 12px;
  padding: 7.5px 5px;
  width: 120px;
  text-align: center;
  font-weight: bold;
  margin-right: 4px;

  color: var(--primary-foreground);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  svg {
    ${svgColorMixin('var(--primary-foreground)')}
    margin-right: 2px;
  }
`;
