import { messageTypes } from '../../../constants/chat-constants';
import { defaultChatPermissions } from '../../chats/constants.ts';

export const getLastMessageFromMessages = (messages) => (messages[0] ? messages[0].summary || messages[0].message : '');

export const formatMessageFromResponse = (responseMessage) => ({
  // The id here needs to be a string because that's what TextMessage expects:
  id: responseMessage.messageId.toString(10),
  chatId: responseMessage.chatId,
  text:
    responseMessage.type === messageTypes.TEXT || responseMessage.type === messageTypes.SYSTEM
      ? responseMessage.message
      : responseMessage.text,
  roomId: responseMessage.type === messageTypes.INVITE ? responseMessage.message : null,
  url: responseMessage.url,
  imageUrl: responseMessage.imageUrl,
  storagePath: responseMessage.storagePath,
  creator: responseMessage.userId,
  type: responseMessage.type,
  fontColor: responseMessage.fontColor,
  fontFamily: responseMessage.fontFamily,
  fontSize: responseMessage.fontSize,
  messageColor: responseMessage.messageColor,
  createdAt: responseMessage.createdAt,
  updatedAt: responseMessage.updatedAt,
  linkSummary: responseMessage.linkSummary,
  reactions: responseMessage.reactions || [],
  tagMetadata: responseMessage.tagMetadata || {},
  metadata: responseMessage.metadata || {},
});

export const keyMessagesById = (messagesArray) =>
  messagesArray.reduce(
    (currMessagesKeyedById, message) => ({
      ...currMessagesKeyedById,
      [message.messageId]: formatMessageFromResponse(message),
    }),
    {}
  );

export const formatChatFromResponse = (responseChat) => {
  const chatMembers = responseChat.members?.reduce((acc, member) => {
    acc[member.userId] = {
      role: member.role,
    };

    if (member.isBanned !== undefined) {
      acc[member.userId].isBanned = member.isBanned;
    }

    return acc;
  }, {});

  return {
    id: responseChat.chatId,
    createdAt: responseChat.createdAt,
    updatedAt: responseChat.updatedAt,
    chatUpdatedAt: responseChat.chatUpdatedAt,
    deletedAt: responseChat.deletedAt,
    unreadCount: responseChat.unreadCount,
    lastMessage: getLastMessageFromMessages(responseChat.messages),
    chatMembers: chatMembers || {},
    ...(responseChat.permissions ? { permissions: responseChat.permissions || defaultChatPermissions } : {}),
    ...(responseChat.groupId ? { groupId: responseChat.groupId } : {}),
    ...(responseChat.groupName ? { groupName: responseChat.groupName } : {}),
    ...(responseChat.isDm ? { isDm: responseChat.isDm } : {}),
    ...(responseChat.isPublic ? { isPublic: responseChat.isPublic } : {}),
    ...(responseChat.background ? { background: responseChat.background } : {}),
    ...(responseChat.theme ? { theme: responseChat.theme } : {}),
    ...(responseChat.isNotificationsOn ? { isNotificationsOn: responseChat.isNotificationsOn } : {}),
    ...(responseChat.role ? { role: responseChat.role } : {}),
    ...(responseChat.boards ? { boards: responseChat.boards } : {}),
  };
};
