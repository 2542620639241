import React from 'react';
import PropTypes from 'prop-types';

const ResetIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7604 14.4041H2.81059V5.45422" stroke={color} strokeWidth="4.9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.13972 31.2794C11.4344 33.5741 14.3581 35.1368 17.5409 35.7699C20.7238 36.403 24.0229 36.0781 27.0211 34.8362C30.0192 33.5943 32.5818 31.4912 34.3848 28.7929C36.1877 26.0947 37.15 22.9223 37.15 19.6771C37.15 16.4319 36.1877 13.2596 34.3848 10.5613C32.5818 7.86299 30.0192 5.75993 27.0211 4.51804C24.0229 3.27615 20.7238 2.95122 17.5409 3.58433C14.3581 4.21744 11.4344 5.78015 9.13972 8.07486L2.81122 14.4034" stroke={color} strokeWidth="4.9" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ResetIcon;

ResetIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ResetIcon.defaultProps = {
  width: '40',
  height: '39',
  color: 'white',
};
