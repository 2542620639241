import reduxAdapter from './react/store/redux-adapter';

import { roomOnboardingFlow } from './react/sign-in-flow/flows.ts';

// constants
import { FLOW_FINISHED, Screens } from './react/sign-in-flow/constants.ts';

const DISABLED_CLASS = 'onboarding-disabled';

const roomOnboardingScreens = Object.keys(roomOnboardingFlow).filter(
  (step) => ![FLOW_FINISHED, Screens.ROOM_ONBOARDING_FINISHED, 'getFirstScreen'].includes(step)
);

export const disableElements = (selector) =>
  document.querySelectorAll(selector).forEach((el) => el.classList.add(DISABLED_CLASS));
export const enableElements = (selector) =>
  document.querySelectorAll(selector).forEach((el) => el.classList.remove(DISABLED_CLASS));

const elementsToDisable = [
  '#element-feed', // Feed
  '#minimap-container', // Minimap
  '.inner-bar.invite-users', // Invite button container
  '.inner-bar.settings-button-container', // Own profile button
  '#global-menu-bar', // Bar with room name and camera buttons
  '#sidebar-root', // Sidebar with DMs
  '#quick-room-switcher-root', // Exit button
  '#room-menu-bar', // toolbar
  '#room-menu-bar .menu-button', // Every single button for adding an element
  '.feedback-menu-button', // Feedback button (the one with our logo)
  '.boardElement', // Whatever elements are already on the board
  '#trashcan', // Trashcan
  '.element-footer-options', // bottom right buttons of a room element
];

export const disableEverything = () => elementsToDisable.forEach(disableElements);
export const enableEverything = () => enableElements(`.${DISABLED_CLASS}`);

export const isRoomOnboardingInProgress = () =>
  roomOnboardingScreens.includes(reduxAdapter.latestState.signingIn.screenId);
