import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { School } from '../../../definitions/schools';
import { updateProfile } from '../../user-profile/api';
import { selectCurrentUserId } from '../../store/users/selectors';
import ChevronIcon from '../../../../assets/icons/arrows/rounded-chevron-left.svg';
import { svgColorMixin } from '../../mixins';
import { track } from '../../../util/analytics-util';
import { ADD_GRADE } from '../../../constants/analytics-events/user-events';
import { EventSourceContext } from '../../common/contexts';

interface Props {
  school: School;
  goBack: () => void;
  goToNext: () => void;
}

const GRADES = [9, 10, 11, 12];

const SelectGradeScreen: React.FC<Props> = ({ school, goBack, goToNext }) => {
  const [selectedGrade, setSelectedGrade] = useState(null);
  const currentUserId = useSelector(selectCurrentUserId);
  const source = useContext(EventSourceContext);

  const onContinueClick = () => {
    if (!selectedGrade) return;
    updateProfile(currentUserId, { schoolId: school.id, grade: selectedGrade });
    track(ADD_GRADE, { source, grade: selectedGrade });
    goToNext();
  };

  return (
    <Container>
      <BackButton onClick={goBack}>
        <ChevronIcon height="20" width="20" />
        Back
      </BackButton>
      <SchoolName>{school.name}</SchoolName>
      <SchoolCity>{school.city}</SchoolCity>
      <Title>Select your grade</Title>
      <GradeSelectContainer>
        {GRADES.map((grade) => (
          <GradeSelectItem key={grade} selected={grade === selectedGrade}>
            <GradeSelectItemText onClick={() => setSelectedGrade(grade)}>{`${grade}th`}</GradeSelectItemText>
          </GradeSelectItem>
        ))}
      </GradeSelectContainer>
      <ContinueButton disabled={!selectedGrade} onClick={onContinueClick}>
        OK
      </ContinueButton>
      <SkipButton onClick={goToNext}>I don't see my grade</SkipButton>
    </Container>
  );
};

export default SelectGradeScreen;

const Container = styled.div`
  height: 522px;
  padding: 23px 50px 40px 50px;
`;

const BackButton = styled.div`
  color: #12002d;
  font-size: 16px;
  font-weight: 700;

  cursor: pointer;
  position: absolute;
  left: 17px;

  ${svgColorMixin('#12002d')}

  opacity: 0.4;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.6;
  }
`;

const SchoolName = styled.div`
  color: #12002d;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 7px;
`;

const SchoolCity = styled.div`
  color: #12002d;
  text-align: center;
  font-size: 12px;
  opacity: 0.7;
`;

const Title = styled.div`
  color: #6b1be3;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 28px;
  margin: 18px 0 24px 0;
`;

const GradeSelectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const GradeSelectItemText = styled.div`
  color: #12002d;
  opacity: 0.4;

  font-family: Nunito, sans-serif;
  font-size: 50px;
  font-weight: 900;

  transition: opacity 0.3s ease, color 0.3s ease;
`;

const GradeSelectItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(18, 0, 45, 0.2);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
  height: 100px;
  box-sizing: border-box;
  transition: border 0.1s;

  &:hover {
    border: 7px solid #6b1be3;

    ${GradeSelectItemText} {
      color: #6b1be3;
      opacity: 1;
    }
  }

  ${({ selected }) =>
    selected &&
    `
    border: 7px solid #6b1be3;

    ${GradeSelectItemText} {
      color: #6b1be3;
      opacity: 1;
    }
  `}
`;

const ContinueButton = styled.button`
  border-radius: 40px;
  background: #6b1be3;

  margin: 40px auto 0 auto;

  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.8);
  width: 315px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 20px;
  font-weight: 900;

  cursor: pointer;

  outline: none;
  border: none;

  opacity: 0.9;
  transition: opacity 0.1s;

  &:disabled {
    opacity: 0.4;
    cursor: default;

    &:hover {
      opacity: 0.4;
    }
  }

  &:hover {
    opacity: 1;
  }
`;

const SkipButton = styled.div`
  opacity: 0.4;
  color: #12002d;

  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  margin-top: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;
