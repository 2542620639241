import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { hideableMixin, svgColorMixin, ellipsisTextMixin } from '../../../mixins';
import { selectCurrentUserId } from '../../../store/users/selectors';
import UserAvatar from '../../../components/UserAvatar';
import Tooltip from '../../../components/Tooltip';
import { MoreIcon } from '../../../icons';
import UserMenu from '../../../user-menu';
import AddFriendIcon from '../../../../../assets/icons/add-friend.svg';
import AddFriendPendingIcon from '../../../../../assets/icons/add-friend-pending.svg';
import { track } from '../../../../util/analytics-util';
import { useToasts } from '../../../admin-templates/ToastProvider';
import { ERROR_TOAST_COLOR } from '../../../admin-templates/constants';
import { useFriendingButtons } from '../../../hooks/feed/useFriendingButtons';
import {
  ACCEPT_FRIEND,
  REQUEST_FRIEND,
  FRIEND_REQUEST_SOURCES,
} from '../../../../constants/analytics-events/friend-events';

import ActiveKeyIcon from '../../../../../assets/icons/active-key.svg';
import CrownIcon from '../../../../../assets/icons/crown.svg';
import EyeIcon from '../../../../../assets/icons/eye.svg';
import AcceptFriendIcon from '../../../../../assets/icons/add-friend-accept.svg';

import 'react-confirm-alert/src/react-confirm-alert.css';

export const RoomMemberDetailsView = ({ member }) => {
  const currentUserId = useSelector(selectCurrentUserId);
  const isCurrentUser = member.id === currentUserId;
  const { addToast } = useToasts();

  let icon = null;
  let title = '';

  if (member.role === 'owner') {
    icon = <CrownIcon />;
    title = 'Owner';
  } else if (member.role === 'host') {
    icon = <ActiveKeyIcon />;
    title = 'Host';
  } else if (member.role === 'viewer') {
    icon = <GrayedEyeIcon width={26} height={26} />;
    title = 'Viewer';
  }

  // don't show '...'-menu for self
  const shouldShowMenu = !isCurrentUser;

  const overflowMenu = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const { showAddFriend, showAcceptFriend, showPendingIcon, addFriendHandler, acceptFriendHandler } =
    useFriendingButtons(member.id);

  const onAddFriendClick = async () => {
    try {
      const response = await addFriendHandler();
      if (response && response.success) {
        track(REQUEST_FRIEND, {
          source: FRIEND_REQUEST_SOURCES.ROOM_MEMBER_LIST,
          receiverId: member.id,
        });
      } else {
        throw new Error('Adding Friend Failed');
      }
    } catch (error) {
      addToast('There was an error adding friend, try again.', ERROR_TOAST_COLOR);
    }
  };

  const onAcceptFriendRequestClick = async () => {
    try {
      const response = await acceptFriendHandler();
      if (response && response.success) {
        track(ACCEPT_FRIEND, {
          source: FRIEND_REQUEST_SOURCES.ROOM_MEMBER_LIST,
          requesterId: member.id,
        });
      } else {
        throw new Error('Accepting Friend Request Failed');
      }
    } catch (error) {
      addToast('There was an error accepting friend request, try again.', ERROR_TOAST_COLOR);
    }
  };

  useEffect(() => {
    overflowMenu.current.addEventListener('beforeshow', () => setMenuOpen(true));
    overflowMenu.current.addEventListener('hidden', () => setMenuOpen(false));
  }, []);

  return (
    <>
      <UserContainer>
        <AvatarContainer id={`user-profile-avatar-${member.id}`}>
          <AvatarWrapper>
            <UserAvatar userProfile={member} />
          </AvatarWrapper>
        </AvatarContainer>
        <MemberName>{member.name}</MemberName>
        <UserRoleArea>
          {icon}
          <UserRoleTitle isHiddenDisplay={shouldShowMenu} role={member.role}>
            {title}
          </UserRoleTitle>
          {showAcceptFriend && (
            <Tooltip
              content={
                <TooltipStyledContent side="top" sideOffset={4}>
                  <TooltipText>Accept Friend</TooltipText>
                </TooltipStyledContent>
              }
            >
              <AddFriendButton
                onClick={onAcceptFriendRequestClick}
                className="here-button-regular uk-animation-fade uk-animation-fast"
              >
                <AcceptFriendIcon width="19" />
              </AddFriendButton>
            </Tooltip>
          )}
          {showPendingIcon && (
            <Tooltip
              content={
                <TooltipStyledContent side="top" sideOffset={4}>
                  <TooltipText>Request Pending</TooltipText>
                </TooltipStyledContent>
              }
            >
              <PendingButton className="here-button-regular uk-animation-fade uk-animation-fast">
                <AddFriendPendingIcon width="20" />
              </PendingButton>
            </Tooltip>
          )}
          {showAddFriend && (
            <Tooltip
              content={
                <TooltipStyledContent side="top" sideOffset={4}>
                  <TooltipText>Add Friend</TooltipText>
                </TooltipStyledContent>
              }
            >
              <AddFriendButton
                onClick={onAddFriendClick}
                className="here-button-regular uk-animation-fade uk-animation-fast"
              >
                <AddFriendIcon width="19" />
              </AddFriendButton>
            </Tooltip>
          )}
          <MenuIconContainer isHiddenDisplay={!shouldShowMenu}>
            <MoreIcon width="20px" color="#666666" />
          </MenuIconContainer>
          <div ref={overflowMenu} data-uk-dropdown="mode: click; pos: bottom-right" style={{ padding: '0px' }}>
            <UserMenu userId={member.id} reload={menuOpen} />
          </div>
        </UserRoleArea>
      </UserContainer>
    </>
  );
};

RoomMemberDetailsView.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    isOnline: PropTypes.bool,
    role: PropTypes.string,
    ban: PropTypes.shape(),
    color: PropTypes.string,
  }).isRequired,
};

export default RoomMemberDetailsView;

const userContainerBorderWidth = '1px';
const UserContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border: ${userContainerBorderWidth} solid var(--primary-foreground, #eee);
  border-left: none;
  border-right: none;
  margin-top: -${userContainerBorderWidth};
  padding-left: 10px;

  &:hover {
    background: #ddd;

    body.themed & {
      background: var(--primary-foreground-alpha-20);
    }
  }
`;

const avatarSize = '30px';
const AvatarContainer = styled.div`
  height: ${avatarSize};
  width: ${avatarSize};
  margin-right: 20px;
  position: relative;
  flex-shrink: 0;
`;

const MemberName = styled.span`
  ${ellipsisTextMixin({ fullWidth: false })}
`;

const AvatarWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const UserRoleArea = styled.div`
  margin-left: auto;
  margin-right: 8px;
  flex-shrink: 0;

  ${svgColorMixin('var(--secondary-foreground, #6b1be3)')}
`;

const UserRoleElement = styled.span`
  ${hideableMixin()}

  display: inline-block;
  width: 50px;
  text-align: right;
`;

const UserRoleTitle = styled(UserRoleElement)`
  color: ${({ role }) =>
    role === 'viewer' ? 'var(--primary-foreground, #666)' : 'var(--secondary-foreground, #6b1be3)'};
  font-size: 14px;
`;

const MenuIconContainer = styled(UserRoleElement)`
  cursor: pointer;
  width: 30px;
  ${svgColorMixin('var(--primary-foreground, #666)')}
`;

const GrayedEyeIcon = styled(EyeIcon)`
  opacity: 0.4;
`;

const AddFriendButton = styled.button`
  width: 40px;
  height: 40px;
  margin-left: 10px;
  display: inline-block;
  ${svgColorMixin('white')};
`;

const PendingButton = styled(AddFriendButton)`
  cursor: default;
`;

const TooltipStyledContent = styled(TooltipPrimitive.Content)`
  z-index: 100;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #12002d;
  opacity: 0.95;
`;

const TooltipText = styled.p`
  color: #ffffffee;
  font-size: 11px;
  font-family: Inter;
  font-weight: lighter;
  margin: 0;
`;
