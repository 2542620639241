import { css } from 'styled-components';

export const reactionLabelSharedStyles = css`
  padding: 4px;
  height: 24px;
  width: 25px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px 1px #222222;
  border-radius: 50%;
  cursor: pointer;
`;

export const reactionButtonsSharedStyles = css`
  ${reactionLabelSharedStyles}

  position: absolute;
  z-index: -1;
  background-color: #ededed;
  box-shadow: 1px 1px 3px 1px #222222;
  cursor: pointer;

  &:hover {
    z-index: 10;
  }
`;
