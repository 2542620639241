import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../../../store/Wrapper';
import RoomTopMembers from './RoomTopMembers';

export const renderRoomTopMembersWidget = (rootElement: ReactDOM.Container, elementId: string) => {
  ReactDOM.render(
    <WithRoomStore>
      <RoomTopMembers elementId={elementId} />
    </WithRoomStore>,
    rootElement
  );
};
