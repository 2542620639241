import React, { Fragment } from 'react';
import styled from 'styled-components';

import VideoIcon from '../../../../../assets/icons/hereos/chat/video.svg';
import SettingsIcon from '../../../../../assets/icons/hereos/chat/settings-unselected.svg';
import MessageIcon from '../../../../../assets/icons/hereos/chat/message.svg';
import ThemeIcon from '../../../../../assets/icons/hereos/chat/theme.svg';
import { OnboardingSystemMessages, OnboardingSystemMsgTypes } from '../../../../definitions/message';

interface Props {
  id: string;
  text: string;
  type: OnboardingSystemMsgTypes;
}

const OnboardingSystemMessage: React.FC<Props> = ({ id, text, type }) => {
  const renderMessage = (): (string | JSX.Element)[] => {
    let updatedMessage: (string | JSX.Element)[] = [text];

    const replacements: { [key: string]: JSX.Element } = {
      '📹': <VideoIcon />,
      '⚙️': <SettingsIcon />,
      '💬': <MessageIcon />,
      '🎨': <ThemeIcon />,
    };

    Object.keys(replacements).forEach((emoji) => {
      updatedMessage = updatedMessage.flatMap((segment) =>
        typeof segment === 'string'
          ? segment
              .split(emoji)
              .flatMap((part, index, array) => (index < array.length - 1 ? [part, replacements[emoji]] : part))
          : segment
      );
    });

    return updatedMessage;
  };

  const hasCustomStyle =
    type === OnboardingSystemMsgTypes.ONBOARDING_MSG_STYLE_MESSAGE ||
    type === OnboardingSystemMsgTypes.ONBOARDING_ADD_FRIEND_MESSAGE ||
    type === OnboardingSystemMsgTypes.ONBOARDING_JOIN_LINK_PROMPT;

  return (
    <MessageContainer
      customStyle={
        (text === OnboardingSystemMessages.CUSTOMIZE_MESSAGE_PROMPT ||
          text === OnboardingSystemMessages.ADD_FRIEND_PROMPT ||
          hasCustomStyle) && {
          bgColor: '#FEB445',
          fontColor: '#F0209D',
          fontFamily: 'Emilys Candy',
        }
      }
    >
      {renderMessage().map((segment) => (
        <Fragment key={id}>{segment}</Fragment>
      ))}
    </MessageContainer>
  );
};

export default OnboardingSystemMessage;

const MessageContainer = styled.div<{ customStyle?: { bgColor: string; fontColor: string; fontFamily: string } }>`
  background: ${({ customStyle }) => customStyle.bgColor || '#55d8f4'};
  color: ${({ customStyle }) => customStyle.fontColor || 'black'};
  font-family: ${({ customStyle }) => customStyle.fontFamily || 'Inter'};

  padding: 15px 20px;
  font-size: 13px;
  line-height: 15px;
  margin-right: 50px;
  margin-left: 10px;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  margin-top: 9px;

  svg {
    margin-left: 5px;

    ${({ customStyle }) => customStyle.bgColor || 'black'};
  }
`;
