import firebase, { db } from './firebase';
import { elementsIntersect, htmlToElement } from './util';
import { getElementPosition } from './element-transform';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import log from './log';
import BoardElement from './board-element';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

export default class SpawnElement extends BoardElement {
  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, __elementDoc) {
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    // Fall back to camera (for legacy - can delete some day)
    this.supportedElementTypes = elementDoc.data().elementTypes || ['CameraElement'];
    if (this.supportedElementTypes.includes('CameraElement')) {
      this.clip = 'circle'; // TODO establish this from data
    }
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();
    const isCameraSpawn = !data.elementTypes || data.elementTypes.includes('CameraElement');

    const spawn = htmlToElement(`<div class="${isCameraSpawn ? 'spawn-camera' : 'spawn-media'}"></div>`);
    return wrapElement(spawn, elementDoc, {
      preserveAspectRatio: isCameraSpawn,
    });
  }

  // Statics

  static async addElement(elementTypes) {
    const isCameraSpawn = elementTypes.includes('CameraElement');
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'SpawnElement',
        center: screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2),
        creator: firebase.auth().currentUser.uid,
        size: isCameraSpawn ? [240, 240] : [400, 300],
        zIndex: getBehindCameraZIndex(),
        elementTypes,
      })
      .then(() => {
        track(ADD_ELEMENT, { element: ELEMENT_TYPES.SPAWN, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
      })
      .catch((error) => {
        log.error(`Something went wrong creating SpawnElement: ${error}`);
      });
  }

  // Fetch an available spawn rectangle for type elementType, or null if none
  static nextAvailableForType(elementType) {
    const matchingSpawnHandlers = [];
    const matchingHandlers = [];
    Object.values(window.elementHandlers).forEach((handler) => {
      if (handler instanceof SpawnElement && handler.supportedElementTypes.includes(elementType)) {
        matchingSpawnHandlers.push(handler);
      } else if (handler.constructor.elementType && handler.constructor.elementType === elementType) {
        matchingHandlers.push(handler);
      }
    });

    matchingSpawnHandlers.sort((a, b) => {
      const elA = document.getElementById(`element-${a.elementId}`);
      const elB = document.getElementById(`element-${b.elementId}`);
      if (!elA || !elB) {
        // Something went wrong
        log.debug("Can't find element for handler");
        return 0;
      }

      const [ax, ay] = getElementPosition(elA);
      const [bx, by] = getElementPosition(elB);

      if (ax < bx || (ax === bx && ay < by)) {
        return -1;
      }
      return 1;
    });

    // Find the first unoccupied spawn point
    for (const spawnHandler of matchingSpawnHandlers) {
      const spawnElement = document.getElementById(`element-${spawnHandler.elementId}`);
      let newSpawnpoint = false;
      if (matchingHandlers.length > 0) {
        for (const handler of matchingHandlers) {
          const matchElement = document.getElementById(`element-${handler.elementId}`);
          if (!elementsIntersect(spawnElement, matchElement)) {
            newSpawnpoint = true;
          } else {
            newSpawnpoint = false;
            break;
          }
        }
      } else if (spawnElement) {
        newSpawnpoint = true;
      } else {
        console.warn('No element found for spawn handler', spawnHandler);
      }
      if (newSpawnpoint) {
        return {
          center: getElementPosition(spawnElement),
          size: [parseFloat(spawnElement.style.width), parseFloat(spawnElement.style.height)],
          clip: spawnHandler.clip,
        };
      }
    }

    return null;
  }

  // Handler-specific:
}

// Make globally available
window.SpawnElement = SpawnElement;

// Utilities

export function getBehindCameraZIndex() {
  let minZ = null;
  for (const [key, value] of Object.entries(elementHandlers)) {
    if (value.constructor.elementType === 'CameraElement' || value.constructor.elementType === 'ScreenshareElement') {
      const el = document.getElementById(`element-${key}`);
      if (el) {
        const elZ = parseFloat(el.style['z-index']);
        if (!minZ) {
          minZ = elZ;
        }
        minZ = Math.min(minZ, elZ);
      } else {
        log.debug(`Missing camera element from handler: element-${key}`);
      }
    }
  }

  if (!minZ) {
    return window.getFrontZIndex();
  }
  return minZ - 1;
}
