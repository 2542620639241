import { RoomServiceResponse } from '../../../definitions/room';

export interface VisitedRoomsState {
  visitedRooms: RoomServiceResponse[];
  visitedRoomsIndex: { [id: string]: number };
  visitedRoomsLoading: boolean;
  limit?: number;
}

export const initialState: VisitedRoomsState = {
  visitedRooms: [],
  visitedRoomsIndex: {},
  visitedRoomsLoading: true,
};
