import React from 'react';
import ReactDOM from 'react-dom';

import UserMenu from '.';
import { WithRoomStore } from '../store/Wrapper';

export function renderUserMenu(root, { userId, fromUserProfile, forceReload }) {
  ReactDOM.render(
    <WithRoomStore>
      <UserMenu userId={userId} fromUserProfile={fromUserProfile} forceReload={forceReload} />
    </WithRoomStore>,
    root
  );
}
