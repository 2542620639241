interface OutsideReactionsData {
  [key: string]: {
    timestamp: number;
  };
}

const outsideReactionsData: OutsideReactionsData = {};

const outsideReactionsDataString = window.localStorage.getItem('outsideReactionsData');
if (outsideReactionsDataString) {
  Object.assign(outsideReactionsData, JSON.parse(outsideReactionsDataString));
}

export const addOutsideReaction = (roomId: string) => {
  outsideReactionsData[roomId] = {
    timestamp: Date.now(),
  };

  window.localStorage.setItem('outsideReactionsData', JSON.stringify(outsideReactionsData));

  return outsideReactionsData[roomId];
};

export const getRoomLatestOutsideReaction = (roomId: string) => outsideReactionsData[roomId];
