import template from './here-switch.html';
import './here-switch.less';

export default class HereSwitch extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    // binds
    this.onChange = this.onChange.bind(this);

    // elements
    this.checkbox = this.querySelector('.checkbox');

    this.checkbox.checked = this.getAttribute('value') === 'true';

    this.checkbox.addEventListener('change', this.onChange);
  }

  disconnectedCallback() {
    this.checkbox.removeEventListener('change', this.onChange);
  }

  onChange(e) {
    this.dispatchEvent(
      new CustomEvent('here-change', { bubbles: true, composed: true, detail: { checked: e.target.checked } })
    );
  }
}
window.customElements.define('here-switch', HereSwitch);
