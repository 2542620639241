import React from 'react';
import styled from 'styled-components';

// components
import ChatReactionLabel from './ChatReactionLabel';

// utils
import { isCurrentUser } from '../../../../util/user-util';

// constants
import { MAX_VISIBLE_REACTIONS } from '../../../../constants/chat-constants';
import ReactionsEmojiPickerButton from './ReactionsEmojiPickerButton';
import { Emoji } from '../../../../components/emoji-picker/constants';

interface ReactionsLabelsListProps {
  emojiReactions: { reaction: string; customReaction: string; creator: string; creatorDisplayName: string }[];
  onEmojiPick: (emoji: Emoji) => void;
  handleUnreact: (emojiReaction: object) => void;
  addSameReaction: (emojiReaction: object) => void;
}

const ReactionsLabelsList = ({
  emojiReactions,
  onEmojiPick,
  handleUnreact,
  addSameReaction,
}: ReactionsLabelsListProps) => {
  const visibleReactions = emojiReactions.slice(0, MAX_VISIBLE_REACTIONS);
  const hiddenReactionsCount = emojiReactions.length - visibleReactions.length;
  const reactionsShouldShowMinusButton = emojiReactions.map((reaction) => isCurrentUser(reaction.creator));
  const reactionsShouldShowPlusButton = emojiReactions.map(
    (outerReaction, i, array) =>
      !array.find(
        (innerReaction) => isCurrentUser(innerReaction.creator) && innerReaction.reaction === outerReaction.reaction
      )
  );

  return (
    <ReactionsListWrapper>
      <ReactionsList>
        {visibleReactions.map((emojiReaction, index) => (
          <ChatReactionLabel
            key={`${emojiReaction.reaction || emojiReaction.customReaction}-${emojiReaction.creator}`}
            userId={emojiReaction.creator}
            reaction={emojiReaction.reaction}
            customReaction={emojiReaction.customReaction}
            showPlusButton={reactionsShouldShowPlusButton[index]}
            showMinusButton={reactionsShouldShowMinusButton[index]}
            onClick={
              reactionsShouldShowPlusButton[index]
                ? // todo: do these need to be diff in room chats? they're the same in DMs
                  () => addSameReaction(emojiReaction)
                : () => handleUnreact(emojiReaction)
            }
          />
        ))}
        {hiddenReactionsCount ? <ChatHiddenReactionLabel>+{hiddenReactionsCount}</ChatHiddenReactionLabel> : null}
        <ReactionsEmojiPickerButton onEmojiPick={onEmojiPick} emojiReactions={emojiReactions} />
      </ReactionsList>
    </ReactionsListWrapper>
  );
};

const ReactionsListWrapper = styled.div`
  position: absolute;
  bottom: -16px;
  margin-bottom: 20px;

  .local-message & {
    display: none;
  }
`;

const ChatHiddenReactionLabel = styled.div`
  padding: 0.2rem;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid white;
  font-weight: bold;
`;

const ReactionsList = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  margin-top: -4px;
  display: inline-flex;
  align-items: center;
`;

export default ReactionsLabelsList;
