import { authedApiInstance } from '../../firebase';

const TAGGING_ENTITY_TYPES = {
  boardElement: 'BOARD_ELEMENT',
};

export const addBoardElementTags = ({ receiverIds, boardId, elementId }) =>
  authedApiInstance
    .post('/tags', {
      entityType: TAGGING_ENTITY_TYPES.boardElement,
      entityId: elementId,
      boardId,
      receiverIds,
    })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const markTagAsSeen = (tagId) =>
  authedApiInstance
    .post(`/tags/${tagId}/seen`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);
