import React from 'react';
import styled from 'styled-components';

import UserAvatar from '../../../components/UserAvatar';

// icons
import FlagIcon from '../../../../../assets/icons/flag.svg';
import { svgColorMixin } from '../../../mixins';

interface ReportUserPreviewProps {
  userId: string;
  displayName: string;
  username: string;
  showFlag?: boolean;
}

const ReportUserPreview = ({ userId, displayName, username, showFlag }: ReportUserPreviewProps) => (
  <UserContainer>
    <AvatarContainer>
      <UserAvatar userId={userId} />
      {showFlag ? <FlagIcon /> : null}
    </AvatarContainer>
    <DisplayNameContainer>
      <DisplayName>{displayName}</DisplayName>
    </DisplayNameContainer>
    <UsernameContainer>
      <Username>@{username}</Username>
    </UsernameContainer>
  </UserContainer>
);

export default ReportUserPreview;

const UserContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas: 'avatar display-name' 'avatar username';
  grid-template-columns: auto 1fr;
  column-gap: 10px;
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: avatar;

  ${svgColorMixin('#f6335d')}

  svg {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 9px;
  }
`;

const DisplayNameContainer = styled.div`
  display: grid;
  grid-area: display-name;
`;

const DisplayName = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: black;
`;

const Username = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
`;

const UsernameContainer = styled.div`
  display: grid;
  grid-area: username;
`;
