import React from 'react';

import { TimerContainerCellContent, TimerContainerCellLegend, TimerContainerGrid } from '../shared-styles';

interface RoomActiveTimeWidgetSuccessStateProps {
  day: string;
  hour: string;
  min: string;
}

const SuccessState = ({ day, hour, min }: RoomActiveTimeWidgetSuccessStateProps) => (
  <TimerContainerGrid>
    <TimerContainerCellContent>{day}</TimerContainerCellContent>
    <TimerContainerCellContent>:</TimerContainerCellContent>
    <TimerContainerCellContent>{hour}</TimerContainerCellContent>
    <TimerContainerCellContent>:</TimerContainerCellContent>
    <TimerContainerCellContent>{min}</TimerContainerCellContent>

    <TimerContainerCellLegend>DAY</TimerContainerCellLegend>
    <TimerContainerCellContent />
    <TimerContainerCellLegend>HRS</TimerContainerCellLegend>
    <TimerContainerCellContent />
    <TimerContainerCellLegend>MINS</TimerContainerCellLegend>
  </TimerContainerGrid>
);

export default SuccessState;
