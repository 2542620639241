import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import ChatSkinningPresets from './ChatSkinningPresets';
import ChatSkinningCustomization from './ChatSkinningCustomization';
import FolderTabs from '../../../components/FolderTabs';
import { fetchChatSkinningData } from '../../../store/chat-skinning/actions';

interface Props {
  chatId: string;
}

const ChatSkinningSettings = ({ chatId }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchChatSkinningData());
  }, [dispatch]);

  const tabs = useMemo(
    () => [
      { name: 'Themes', node: <ChatSkinningPresets chatId={chatId} /> },
      { name: 'Create Custom', node: <ChatSkinningCustomization chatId={chatId} /> },
    ],
    [chatId]
  );

  return (
    <Container>
      <FolderTabs tabs={tabs} />
    </Container>
  );
};

export default ChatSkinningSettings;

const Container = styled.div`
  height: 100%;
`;
