import UIkit from 'uikit';
import { debounce, delay } from 'lodash';
import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { boardUsers } from './presence';
import { htmlToElement } from './util';
import { showScreenshareError } from './util/device-error-util';
import { getElementPosition } from './element-transform';
import { screenToCanvasCoords } from './util/canvas-util';
import SpawnElement from './spawn';
import log from './log';
import minimap from './minimap';
import wrapElement from './element-wrapper';
import { createFullscreenMenuOption } from './util/fullscreen-util';
import { checkIsElectron } from './util/platform-util';
import BoardElement from './board-element';
import { isCurrentUser, isHereEmployee } from './util/user-util';
import ScreenshareUI from './electron-support/screenshare-ui';
import { getDisplayStreamSourceLegacy } from './electron-support/screenshare-legacy';
import { track } from './util/analytics-util';
import {
  SCREENSHARE_OFF,
  SCREENSHARE_ON,
  SCREENSHARE_RESTART,
  SCREENSHARE_STARTED_WATCHING,
} from './constants/analytics-events/screenshare-events';
import {
  checkScreenAccessPermission,
  getScreenshareSources,
  isLatestScreenshareAPIAvailable,
  onScreenshareSourcesReady,
  setScreenshareSourceHandler,
  startScreenshare,
} from './electron-support/electron-support';

const STREAM_QUALITY_BUTTON_VISIBILITY_TIMEOUT_MS = 5000;

function updateSize(boardId, elementId, newW, newH) {
  db.doc(`/boards/${boardId}/elements/${elementId}`).update({ size: [newW, newH] });
}

const debounceSizeUpdate = debounce(updateSize, 250);

export default class ScreenshareElement extends BoardElement {
  constructor(elementId) {
    super(elementId);

    // Conform ScreenshareElement to CameraElement properties, even through
    // these are always the same
    this.isVideoOn = true;
    this.isHereEmployee = false;
  }

  isBackgroundRemovalEnabled() {
    return false;
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, elementDoc) {
    const data = elementDoc.data();

    const hereAudioControlWrapper = document.querySelector(
      `#video-container-screen-${data.creator} .screenshare-controls-bar`
    );

    if (hereAudioControlWrapper) {
      if (data.isAudioOn) {
        hereAudioControlWrapper.classList.remove('hidden');
      } else if (hereAudioControlWrapper) {
        hereAudioControlWrapper.classList.add('hidden');
      }
    }

    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    const data = elementDoc.data();
    this.userId = data.creator;
    window.rtc.onCameraElementReady(`screen-${data.creator}`);
    isHereEmployee(firebase.auth().currentUser).then((value) => {
      this.isHereEmployee = value;
    });
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();

    const users = boardUsers[window.currentBoardId];
    const name = users && users[data.creator] ? users[data.creator].name : '';
    if (!name) {
      log.warn(`Can't find name for user with camera: ${data.creator}`);
    }

    const screenshare = htmlToElement(`
      <div class="screenshare-fullscreen">
        <div class="animation">
          <div class="camera-container screenshare">
            
            ${
              isCurrentUser(data.creator)
                ? `
              <div class="presentation-mode-controls visible">
                <button type="button" class="presentation-mode-button">
                  <img src="/images/icons/speedometer.svg" alt="chevron down" >
                  <span class="text">Quality: Presentation</span>
                  <img src="/images/icons/chevron-down.svg" alt="chevron down" >
                </button>

                <div uk-dropdown="mode: click" class="presentation-mode-dropdown">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="presentation-mode-list-item">
                      <button type="button" data-stream-quality-value="Gaming">Gaming</button>
                    </li>

                    <li class="presentation-mode-list-item">
                      <button type="button" data-stream-quality-value="Cinema">Cinema</button>
                    </li>
  
                    <li class="presentation-mode-list-item" selected>
                      <button type="button" data-stream-quality-value="Presentation">Presentation</button>
                    </li>
  
                    <li class="presentation-mode-list-item">
                      <button type="button" data-stream-quality-value="Standard">Standard</button>
                    </li>
                  </ul>
                </div>
              </div>
              `
                : ''
            }

            <div class="camera" id="video-container-screen-${data.creator}">
              <div class="screenshare-overlay"></div>
              ${
                !isCurrentUser(data.creator)
                  ? `
                    <div class="screenshare-controls-bar ${data.isAudioOn ? '' : 'hidden'}">
                      <div class="volume-icon">
                      <img src="images/icons/volume-on-white.svg" />
                      <div id="screenshare-${data.creator}-audio-control" class="here-audio-control-wrapper">
                          <here-audio-control />
                        </div>
                      </div>
                    </div>
                    `
                  : ''
              }
              <canvas class="video-snapshot" style="display: none"></canvas>
            </div>
          </div>
          <div class="camera-name-container">
            <span class="camera-name">
              <here-user-name userId="${data.creator}" />
            </span>
          </div>
        </div>
      </div>
    `);

    const presentationModeControls = screenshare.querySelector('.presentation-mode-controls');
    const presentationModeButton = screenshare.querySelector('.presentation-mode-button');
    const presentationModeDropdown = screenshare.querySelector('.presentation-mode-dropdown');
    if (presentationModeControls) {
      const closePresentationModeDropdown = () => {
        UIkit.dropdown(presentationModeDropdown).hide();
        presentationModeButton.classList.remove('uk-open');
        presentationModeButton.setAttribute('aria-expanded', 'false');
        presentationModeDropdown.classList.remove('uk-open');
      };

      // stream quality button should be visible for STREAM_QUALITY_BUTTON_VISIBILITY_TIMEOUT_MS after screenshare element was created
      // after the timeout it'll be visible only when the screenshare element is hovered
      setTimeout(() => {
        presentationModeControls.classList.remove('visible');
      }, STREAM_QUALITY_BUTTON_VISIBILITY_TIMEOUT_MS);

      presentationModeDropdown.addEventListener('click', (e) => {
        if (e.target.dataset.streamQualityValue) {
          // update stream quality
          window.rtc.updateScreensharingQuality(e.target.dataset.streamQualityValue);

          // update button text
          presentationModeButton.querySelector('.text').textContent = `Quality: ${e.target.dataset.streamQualityValue}`;

          // update selected item in dropdown
          const prevSelectedItem = presentationModeDropdown.querySelector('.presentation-mode-list-item.selected');
          if (prevSelectedItem) {
            prevSelectedItem.classList.remove('selected');
          }

          e.target.parentElement.classList.add('selected');

          closePresentationModeDropdown();
        }
      });

      screenshare.addEventListener('mouseleave', (e) => {
        if (e.target.classList.contains('screenshare-fullscreen')) {
          closePresentationModeDropdown();
        }
      });
    }

    const sizeMaintainElements = [screenshare.querySelector('.camera-name-container')];
    const controlsBar = screenshare.querySelector('.screenshare-controls-bar');
    if (controlsBar) sizeMaintainElements.push(controlsBar);

    screenshare.addEventListener('mouseenter', () => {
      // lazy size recalculation to improve perfomance
      sizeMaintainElements.forEach((el) => {
        el.style.transform = `scale(${1 / window.canvasScale})`;
        el.classList.add('maintain-size-in-viewport');
      });
    });

    screenshare.addEventListener('mouseleave', () => {
      sizeMaintainElements.forEach((el) => {
        el.classList.remove('maintain-size-in-viewport');
      });
    });

    const screenshareAudioControl = screenshare.querySelector(`#screenshare-${data.creator}-audio-control`);
    if (screenshareAudioControl) {
      screenshareAudioControl.addEventListener('audiochange', (event) => {
        this.videoEl.volume = Number(event.detail.volume.toFixed(2));
      });

      const volumeIcon = screenshare.querySelector('.volume-icon img');
      screenshareAudioControl.addEventListener('audiochangecommit', (event) => {
        if (Number(event.detail.volume.toFixed(2))) {
          volumeIcon.src = 'images/icons/audio-on.svg';
        } else {
          volumeIcon.src = 'images/icons/audio-off.svg';
        }
      });
    }

    const additionalOptions = [];
    const fullscreenMenuOption = createFullscreenMenuOption(screenshare, 'Fullscreen Screenshare');
    if (fullscreenMenuOption) {
      additionalOptions.push(fullscreenMenuOption);
    }
    if (this.isHereEmployee) {
      // FIXME: This is the same template as the one used in Camera.
      const rtcDebugOption = htmlToElement('<button class="options-menu-option">RTC Debug</button>');
      rtcDebugOption.addEventListener('click', () => window.rtc.toggleUserStats(this.userId, 'ScreenshareElement'));
      additionalOptions.push(rtcDebugOption);
    }

    return wrapElement(screenshare, elementDoc, {
      classes: ['screenshareElement', 'videoElement', `screenshare-${data.creator}`],
      preserveAspectRatio: true,
      additionalOptions,
    });
  }

  videoElement() {
    const el = document.getElementById(`element-${this.elementId}`);
    return el.querySelector('video');
  }

  isOwnCamera() {
    return this.userId === firebase.auth().currentUser.uid;
  }

  onStreamReady(streamId) {
    track(SCREENSHARE_STARTED_WATCHING, { elementId: this.elementId });

    log.debug(`ScreenShare: onStreamReady ${streamId}`);
    const videoContainer = document.getElementById(`video-container-screen-${this.userId}`); // TODO alter for screenshare

    const existing = videoContainer.querySelector('video');
    if (existing && existing.parentNode) {
      log.debug(`SFU: addHTMLForStream: Attaching to existing element for ${this.userId}`);
      existing.style.display = 'block';
      return;
    }

    // Due to a Safari 14.x bug, we don't create video tags explicitly here.
    // See related bug report: https://bugs.webkit.org/show_bug.cgi?id=222657
    // Some discussion without a solution: https://stackoverflow.com/questions/58240755/html-5-video-doesnt-show-up-in-safari-when-rendered-via-domparser-rendering-vi
    const video = document.getElementById('video-template').cloneNode();
    this.videoEl = video;
    video.style.display = 'block';
    video.id = `video-screen-${this.userId}`;
    video.setAttribute('autoplay', true);
    video.setAttribute('playsinline', true);
    if (this.userId === firebase.auth().currentUser.uid) {
      video.setAttribute('muted', true);
    }
    video.style.width = '100%';
    video.style.height = '100%';

    video.addEventListener('loadeddata', (__e) => {
      try {
        video.play();
        // Chrome seems to give us an audio track here even though we ask the
        // video to be muted; wtf
        if (this.userId === firebase.auth().currentUser.uid) {
          const audio = video.srcObject.getAudioTracks();
          if (audio && audio.length > 0) {
            log.warn('Found unwanted self-screenshare audio track, stopping it');
            audio[0].enabled = false;
          }
        }
      } catch (error) {
        log.error(`Unable to play stream for ${this.userId}: `, error);
      }
    });

    // TODO This might be good to set regardless of whether we're the owner of this share
    video.onresize = (__e) => {
      log.debug('screenshare: on resize');
      /* if (video.videoWidth != 0 && video.videoHeight != 0 && !video.paused) {
        HereSFU.hideSnapshot(streamId);
      } */
      // Might not be set yet
      this.onVideoResize(video.videoWidth, video.videoHeight);
    };

    log.debug(`Screenshare: addHTMLForStream: Appending media ${video} to ${videoContainer}`);
    videoContainer.append(video);
  }

  // Statics

  static findScreenshareHandler(userId) {
    const screenHandlers = Object.values(window.elementHandlers).filter((h) => h instanceof ScreenshareElement);
    const match = screenHandlers ? screenHandlers.filter((h) => h.userId === userId) : null;
    return match && match.length > 0 ? match[0] : null;
  }

  static isScreensharing() {
    return this.findScreenshareHandler(firebase.auth().currentUser.uid) !== null;
  }

  // Return: true if a screenshare was removed
  static async remove(boardId) {
    const user = firebase.auth().currentUser;

    const elementsRef = db.collection('boards').doc(boardId).collection('elements');
    const query = elementsRef.where('class', '==', 'ScreenshareElement').where('creator', '==', user.uid);
    const querySnapshot = await query.get();

    // If we have results, delete them.
    if (querySnapshot.size > 0) {
      window.rtc.hangupScreenshare();
      // TODO add to presence
      // removeScreenshareFromPresence();

      querySnapshot.forEach((element) => {
        element.ref.delete();
      });

      Array.from(document.getElementsByClassName('screenshare-on-button')).forEach((item) => {
        item.style.display = null;
      });

      Array.from(document.getElementsByClassName('screenshare-off-button')).forEach((item) => {
        item.style.display = 'none';
      });

      track(SCREENSHARE_OFF);
      return true;
    }

    return false;
  }

  static async startScreenshare(stream, isAudioOn = false) {
    const user = firebase.auth().currentUser;
    window.rtc.startScreenshare(
      () => {
        const elementsRef = db.collection('boards').doc(window.currentBoardId).collection('elements');
        const screenRect = SpawnElement.nextAvailableForType(ScreenshareElement.elementType);

        elementsRef
          .add({
            class: 'ScreenshareElement',
            center: screenRect
              ? screenRect.center
              : screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2),
            size: screenRect ? screenRect.size : [600, 400],
            // TODO maybe the ZIndex directly behind the user's camera?
            zIndex: window.getFrontZIndex(),
            creator: user.uid,
            isAudioOn,
          })
          .then((__docRef) => {
            addSystemMessage('started screen sharing');
            track(SCREENSHARE_ON);
          });

        Array.from(document.getElementsByClassName('screenshare-off-button')).forEach((item) => {
          item.style.display = null;
        });

        Array.from(document.getElementsByClassName('screenshare-on-button')).forEach((item) => {
          item.style.display = 'none';
        });
      },
      async (st) => {
        if (this.isScreensharing()) {
          await this.toggle();
        }
        delay(async () => {
          await this.toggle(st);
        }, 1000);
      },
      stream
    );
  }

  static async toggle(st) {
    const constraints = window.rtc.screensharingConstraints;
    if (this.isScreensharing()) {
      await this.remove(window.currentBoardId);
    } else {
      if (window.rtc.screenshareFramerate) {
        constraints.video.frameRate = { ideal: window.rtc.screenshareFramerate };
      }
      constraints.audio = {
        noiseSuppression: false,
        echoCancellation: false,
        autoGainControl: false,
        googAutoGainControl: false,
      };

      let stream = st;

      // APP is Electron
      if (checkIsElectron()) {
        // Warn users before continue using Session API to check screen access permission
        if (window.bridge.checkScreenAccessPermission) {
          const isAllowed = await checkScreenAccessPermission();
          if (!isAllowed) {
            track('Electron Screenshare Permission Error');
            const e = new Error('Allow Here to access your screen in your operating system settings');
            e.name = 'ElectronNotAllowedScreen';
            await showScreenshareError(e);
          }
        }

        // Latest API to access display media.
        if (isLatestScreenshareAPIAvailable()) {
          const sources = await getScreenshareSources();
          const selectedSource = await new Promise((resolve) => {
            ScreenshareUI.show(sources, resolve);
          });

          log.debug('Source to be used as screenshare handler:', selectedSource);
          await setScreenshareSourceHandler(selectedSource);
          constraints.audio = true;
          constraints.video = true;

          // Legacy API to access display media.
        } else if (!stream) {
          // To make this work we need to trust that there will be a set of functions defined
          // on the legacy (older) Electron versions, like `onScreenshareSourcesReady`
          stream = await new Promise((outerResolve) => {
            onScreenshareSourcesReady(async (sources) => {
              const selectedSource = await new Promise((resolve) => {
                ScreenshareUI.show(sources, resolve);
              });
              outerResolve(await getDisplayStreamSourceLegacy(selectedSource));
            });
            startScreenshare();
          });
        }
      }

      // APP is Web or APP is Electron with latest screenshare API.
      if (!stream && (!checkIsElectron() || isLatestScreenshareAPIAvailable())) {
        try {
          log.debug(
            `## Screenshare starting with framerate: constraints: ${JSON.stringify(constraints)}, ${
              window.rtc.screenshareFramerate
            }, bitrate: ${window.rtc.room?.screenPublisher?.bitrate}`
          );
          stream = await navigator.mediaDevices.getDisplayMedia(constraints);
        } catch (err) {
          if (err.message.match(/Could not start audio source/i)) {
            try {
              delete constraints.audio;
              stream = await navigator.mediaDevices.getDisplayMedia(constraints);
            } catch (e) {
              log.error('Unable to start screenshare even without audio', e);
              showScreenshareError(e);
            }
          } else {
            log.error('Unable to start screenshare', err);
            showScreenshareError(err);
            return;
          }
        }
      }

      if (!stream) {
        showScreenshareError({ name: 'NoStream', message: 'Stream is not available' });
        track('Screenshare Stream Not Available');
        return;
      }

      if (typeof stream.getAudioTracks !== 'function') {
        try {
          track('Screenshare getAudioTracks Not Available', { objectName: stream.constructor.name });
        } catch (e) {
          track('Screenshare getAudioTracks Not Available', { objectName: 'unknown' });
        }
        ScreenshareElement.startScreenshare(stream, false);
        return;
      }

      ScreenshareElement.startScreenshare(stream, !!stream.getAudioTracks().length);
    }
  }

  // create a clone element for screenshare
  // needed when a screenshare is being deleted, but we need it restored.
  static replaceScreenshareElement(originalData) {
    const elementsRef = db.collection('boards').doc(window.currentBoardId).collection('elements');

    elementsRef
      .add({
        class: 'ScreenshareElement',
        center: originalData.center,
        size: originalData.size,
        zIndex: originalData.zIndex,
        creator: originalData.creator,
      })
      .then((__docRef) => {
        track(SCREENSHARE_RESTART);
      });
  }

  // Handler-specific:

  screenshareEnded() {
    ScreenshareElement.remove(window.currentBoardId);
  }

  onVideoResize(width, height) {
    const element = document.getElementById(`element-${this.elementId}`);
    if (!element) {
      return;
    }

    const style = window.getComputedStyle(element);
    const [x, y] = getElementPosition(element);
    const h = parseFloat(style.getPropertyValue('height')) || 0;

    const newHeight = h;
    const newWidth = h * (width / height);

    element.style.width = `${newWidth}px`;
    element.style.height = `${newHeight}px`;
    window.rtc.sendElementResized(element.id, x, y, newWidth, newHeight);

    debounceSizeUpdate(window.currentBoardId, this.elementId, newWidth, newHeight);
    minimap.setNeedsUpdate();
  }
}

ScreenshareElement.elementType = 'ScreenshareElement';
