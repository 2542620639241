import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { BoardElementControllerContext } from '../../common/contexts.ts';

const VideoElement = () => {
  const { elementData } = useContext(BoardElementControllerContext);
  const [areControlsShown, setAreControlsShown] = useState(false);

  return (
    <Video
      controls={areControlsShown}
      preload="metadata"
      poster={elementData.thumbnail}
      onMouseEnter={() => setAreControlsShown(true)}
      onMouseLeave={() => setAreControlsShown(false)}
    >
      <source src={elementData.original} type="video/mp4" />
    </Video>
  );
};

export default VideoElement;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
