import React from 'react';
import PropTypes from 'prop-types';

const ChevronDownIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.5L9 9L16.5 1.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default ChevronDownIcon;

ChevronDownIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

ChevronDownIcon.defaultProps = {
  width: '18',
  height: '11',
  color: 'white',
};
