import styled from 'styled-components';
import { noScrollbarMixin } from '../../../mixins';

export const Title = styled.div`
  font-size: 13px;
  font-weight: 900;
  color: var(--primary-foreground);
  margin-bottom: 10px;
`;

export const RoomsList = styled.div`
  z-index: 1;
  position: relative;
  overflow-y: scroll;
  flex-grow: 1;
  ${noScrollbarMixin()}
`;

export const RoomContainer = styled.div`
  height: 180px;
  width: 100%;
  margin-bottom: 10px;
`;
