export const regex = /^https?:\/\/docs.google.com\/(document|spreadsheets|presentation|forms)\/d\/.*/i;

export const typesMap = {
  document: {
    type: 'Doc',
    icon: 'google-doc.svg',
    backgroundClass: 'google-doc-background',
  },
  presentation: {
    type: 'Slide',
    icon: 'google-doc.svg',
    backgroundClass: 'google-doc-background',
  },
  forms: {
    type: 'Form',
    icon: 'google-doc.svg',
    backgroundClass: 'google-doc-background',
  },
  spreadsheets: {
    type: 'Sheet',
    icon: 'google-sheet.svg',
    backgroundClass: 'google-sheet-background',
  },
};

export function isGoogleDocURL(url) {
  return url.match(regex);
}

export function getGoogleDocType(url) {
  const matches = url.match(regex);
  return matches ? typesMap[matches[1]] && typesMap[matches[1]].type : null;
}
