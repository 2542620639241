import React, { BaseSyntheticEvent, useState, useCallback } from 'react';
import { Formik, Form, FormikHandlers, FormikHelpers, FormikValues } from 'formik';
import { track } from '../../../util/analytics-util';

import { HereInput } from '../../components/inputs';
import {
  ContainerDefault,
  TitleContainer,
  ScreenTitle,
  ScreenSubTitle,
  FormContainer,
  IconContainer,
  NavButtonsContainer,
  NextNavButton,
  PrevNavButton,
  TextNavButtonContainer,
  TextButton,
} from '../../sign-in-flow/shared-styled';
import HereErrorLabel from '../../components/HereErrorLabel';
import { handleErrorCode, FormLabel } from '../phone-util';
import HereStarburst from '../../../../assets/icons/here/here-starburst-bright.svg';

import {
  STILL_DIDNT_GET_CODE_LINK_CLICKED,
  PHONE_NUMBER_SUCCESSFULLY_VERIFIED,
  VERIFY_CODE_CLICKED,
  VERIFY_CODE_ERROR,
  RESEND_CODE_CLICKED,
  RESEND_CODE_ERROR,
} from '../../../constants/analytics-events/phone-events';

const VerifyCodeForm = ({
  onBack,
  onVerify,
  initialVerificationId,
  onValidate,
  handleResendVerificationCode,
}: {
  onBack: () => void;
  onVerify: () => void;
  initialVerificationId: string;
  onValidate: (verificationId: string, confirmationCode: string) => void;
  handleResendVerificationCode: () => Promise<string>;
}) => {
  const [resendCodeClicked, setResendCodeClicked] = useState(false);
  const [verificationId, setVerificationId] = useState(initialVerificationId);

  const onOpenQAClick = () => {
    track(STILL_DIDNT_GET_CODE_LINK_CLICKED);
    window.open('https://help.here.fm/hc/en-us/articles/13437282479251', '_blank');
  };

  const handleConfirmationCodeChange = useCallback(
    (handleChange: FormikHandlers['handleChange']) => (e: BaseSyntheticEvent) => {
      const { value } = e.target;

      if (/^\d{0,6}$/.test(value)) {
        handleChange(e);
      }
    },
    []
  );

  const onClickResendCode = useCallback(
    (setErrors: FormikHelpers<FormikValues>['setErrors']) => async () => {
      setResendCodeClicked(true);

      try {
        track(RESEND_CODE_CLICKED);
        const id = await handleResendVerificationCode();
        setVerificationId(id);
      } catch (e) {
        track(RESEND_CODE_ERROR, { error: e.code });
        setErrors({ confirmationCode: handleErrorCode(e.code) });
      }
    },
    [handleResendVerificationCode]
  );

  return (
    <ContainerDefault>
      <IconContainer>
        <HereStarburst />
      </IconContainer>
      <TitleContainer>
        <ScreenTitle>Enter 6 digit code</ScreenTitle>
        <ScreenSubTitle>We just texted you :)</ScreenSubTitle>
      </TitleContainer>
      <FormContainer>
        <Formik
          initialValues={{ confirmationCode: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(_, { setSubmitting }) => {
            track(PHONE_NUMBER_SUCCESSFULLY_VERIFIED);
            setSubmitting(false);
            onVerify();
          }}
          validate={async ({ confirmationCode }) => {
            const errors: { confirmationCode?: string } = {};
            try {
              track(VERIFY_CODE_CLICKED);
              await onValidate(verificationId, confirmationCode);
            } catch (e) {
              track(VERIFY_CODE_ERROR, { error: e.code });
              errors.confirmationCode = handleErrorCode(e.code);
            }

            return errors;
          }}
        >
          {({ values, handleChange, isSubmitting, errors, setErrors }) => {
            if (values.confirmationCode.length === 0 && Object.keys(errors).length > 0) {
              setErrors({});
            }

            return (
              <Form className="form-container">
                <FormLabel>Confirmation Code</FormLabel>
                <HereInput
                  name="confirmationCode"
                  controlled
                  smallStyle
                  value={values.confirmationCode}
                  onChange={handleConfirmationCodeChange(handleChange)}
                  placeholder="123456"
                  checkResult={errors.confirmationCode && errors.confirmationCode.length > 0 ? false : null}
                  autoFocus
                  testId="confirmation-code-input-field"
                />
                {errors.confirmationCode && <HereErrorLabel errorMessage={errors.confirmationCode} />}
                <NavButtonsContainer>
                  <PrevNavButton type="button" onClick={onBack}>
                    Back
                  </PrevNavButton>
                  <NextNavButton
                    type="submit"
                    disabled={isSubmitting || values.confirmationCode.length === 0}
                    data-testid="verify-confirmation-code-button"
                  >
                    Verify
                  </NextNavButton>
                </NavButtonsContainer>
                <TextNavButtonContainer>
                  {resendCodeClicked ? (
                    <TextButton type="button" onClick={onOpenQAClick}>
                      Still didn't get the code?
                    </TextButton>
                  ) : (
                    <TextButton type="button" onClick={onClickResendCode(setErrors)}>
                      Resend Code
                    </TextButton>
                  )}
                </TextNavButtonContainer>
              </Form>
            );
          }}
        </Formik>
      </FormContainer>
    </ContainerDefault>
  );
};

export default VerifyCodeForm;
