import React from 'react';
import styled from 'styled-components';

import FrequentIcon from '../../../assets/icons/emoji-picker/frequent.svg';
import MyStuffIcon from '../../../assets/icons/my-stuff.svg';
import PeopleIcon from '../../../assets/icons/emoji-picker/people.svg';
import NatureIcon from '../../../assets/icons/emoji-picker/nature.svg';
import FoodIcon from '../../../assets/icons/emoji-picker/food.svg';
import ObjectsIcon from '../../../assets/icons/emoji-picker/objects.svg';
import TravelIcon from '../../../assets/icons/emoji-picker/travel.svg';
import ActivityIcon from '../../../assets/icons/emoji-picker/activity.svg';
import SymbolsIcon from '../../../assets/icons/emoji-picker/symbols.svg';
import FlagsIcon from '../../../assets/icons/emoji-picker/flags.svg';

import { Categories, Emoji, categories } from './constants';
import { useFrequentlyUsed } from './hooks/useFrequentlyUsed';
import { useCurrentCategory } from './hooks/useCurrentCategory';
import FrequentlyUsedSection from './components/FrequentlyUsedSection';
import MyStuffSection from './components/MyStuffSection';
import EmojiSections from './components/EmojiSections';
import { EmoteType } from '../../definitions/emotes';
import { svgColorMixin } from '../../react/mixins';

const categoryToIcon = {
  [Categories.FREQ_USED]: <FrequentIcon />,
  [Categories.MY_STUFF]: <MyStuffIcon />,
  [Categories.PEOPLE]: <PeopleIcon />,
  [Categories.NATURE]: <NatureIcon />,
  [Categories.FOODS]: <FoodIcon />,
  [Categories.ACTIVITY]: <ActivityIcon />,
  [Categories.PLACES]: <TravelIcon />,
  [Categories.OBJECTS]: <ObjectsIcon />,
  [Categories.SYMBOLS]: <SymbolsIcon />,
  [Categories.FLAGS]: <FlagsIcon />,
};

interface Props {
  onSelect: (emoji: Emoji) => void;
}

const EmojiPicker: React.FC<Props> = ({ onSelect }) => {
  const { frequentlyUsed, setFrequentlyUsed } = useFrequentlyUsed();

  const onEmoteClick = (emoteUrl: string, emoteType: EmoteType, pack?: string) => {
    onSelect({ emoteUrl, emoteType, pack });

    if (!frequentlyUsed.find((x) => x.emoteUrl === emoteUrl)) {
      setFrequentlyUsed([...frequentlyUsed, { emoteUrl }]);
    }
  };

  const onEmojiClick = (emoji: string) => {
    onSelect({ emoji });

    if (!frequentlyUsed.find((x) => x.emoji === emoji)) {
      setFrequentlyUsed([...frequentlyUsed, { emoji }]);
    }
  };

  // Keeping track of currently viewed section:
  const { currentCategory, setCategoryRef, scrollToSection } = useCurrentCategory();

  return (
    <Container>
      <NavBar>
        {categories.map((category, index) => (
          <NavIconContainer
            key={category}
            onClick={() => scrollToSection(index)}
            isCurrentSection={currentCategory === category}
          >
            {categoryToIcon[category]}
          </NavIconContainer>
        ))}
      </NavBar>

      <ScrollContainer>
        <FrequentlyUsedSection
          frequentlyUsed={frequentlyUsed}
          onEmojiClick={onEmojiClick}
          onEmoteClick={onEmoteClick}
          setCategoryRef={setCategoryRef}
        />
        <MyStuffSection onSelect={onEmoteClick} setCategoryRef={setCategoryRef} />
        <EmojiSections onSelect={onEmojiClick} setCategoryRef={setCategoryRef} />
      </ScrollContainer>
    </Container>
  );
};

export default EmojiPicker;

const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 320px;
  border: 1px solid #d9d9d9;
`;

const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  border-bottom: 1px solid #d9d9d9;
`;

const NavIconContainer = styled.div<{ isCurrentSection: boolean }>`
  padding: 12px 4px;
  text-align: center;
  transition: fill 0.2s;

  svg {
    height: 18px;
    width: 18px;
    path {
      fill: #858585;
    }
  }

  &:hover {
    ${svgColorMixin('#464646')}
  }

  ${({ isCurrentSection }) =>
    isCurrentSection &&
    `
      border-bottom: 3px rgb(174, 101, 197) solid;

      svg {
        height: 18px;
        width: 18px;
        path {
          fill: rgb(174, 101, 197);
        }
      }

      &:hover {
        ${svgColorMixin('rgb(174, 101, 197)')}
      }
  `}
`;

const ScrollContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 270px;
  padding: 0 6px 6px 6px;
`;
