import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import UserAvatar from '../../../components/UserAvatar';
import { buttonMixin } from '../../../mixins';

const MemberConfirmationModal = ({ title, message, confirm, close, member }) => {
  const confirmAndClose = () => {
    confirm();
    close();
  };

  return (
    <Container>
      <AvatarContainer>
        <UserAvatar userProfile={member} />
      </AvatarContainer>

      <Title>{title}</Title>
      <Message>
        <span>{message} </span>
        <span>{member.name}</span>
        <span>?</span>
      </Message>
      <ButtonsCotainer>
        <CancelButton onClick={close}>Cancel</CancelButton>
        <ConfirmButton onClick={confirmAndClose}>Confirm</ConfirmButton>
      </ButtonsCotainer>
    </Container>
  );
};

MemberConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isOnline: PropTypes.bool,
    role: PropTypes.string,
    ban: PropTypes.shape({}),
    color: PropTypes.string,
    avatar: PropTypes.shape({
      isProcessing: PropTypes.bool,
      thumbnail: PropTypes.string,
      original: PropTypes.string,
    }),
  }).isRequired,
};

const Container = styled.div`
  padding: 37px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: white;
`;

const AvatarContainer = styled.div`
  width: 42px;
  height: 42px;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bolder;
`;

const Message = styled.p`
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  font-weight: normal;
  text-overflow: ellipsis;
  text-align: center;
`;

const ButtonsCotainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const CancelButton = styled.button`
  ${buttonMixin()}

  padding: 11px 34px;
  color: #1a1a1a;
  background-color: #ededed;
`;

const ConfirmButton = styled.button`
  ${buttonMixin()}

  padding: 11px 34px;
  color: white;
  background-color: #f6335d;
`;

export default MemberConfirmationModal;
