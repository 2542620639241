import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import HereModal from '../../../components/HereModal';
import { selectEmotes } from '../../../store/emotes/selectors';
import { EmoteFromResponse } from '../../../../definitions/emotes';
import StickerPacks from '../../../emotes/StickerPacks';
import { removeEmoteRequest } from '../../../store/emotes/actions';
import MyStuffIcon from '../../../../../assets/icons/my-stuff.svg';
import { noScrollbarMixin } from '../../../mixins';

const EmotesSettings: React.FC = () => {
  const dispatch = useDispatch();
  const emotes = useSelector(selectEmotes);
  const [packsModalOpen, setPacksModalOpen] = useState(false);

  const removeEmote = (url: string) => {
    dispatch(removeEmoteRequest({ url }));
  };

  return (
    <Container>
      <Header>
        <MyStuffIcon />
        {emotes.length}
      </Header>
      <ModalContents>
        {emotes.length === 0 ? (
          <EmptyContainer>
            <EmptyTitle>Your Emotes Library is Empty!</EmptyTitle>
            <EmptySubtitle>Customize your emotes to use in any chat.</EmptySubtitle>
          </EmptyContainer>
        ) : (
          <div>
            <EmotesContainer>
              {emotes.map((emote: EmoteFromResponse) => (
                <Emote key={emote.url} onClick={() => removeEmote(emote.url)}>
                  <Minus>-</Minus>
                  <img src={emote.url} alt="emote" width={34} height={34} />
                </Emote>
              ))}
            </EmotesContainer>
          </div>
        )}
      </ModalContents>
      <AddEmotesButton onClick={() => setPacksModalOpen(true)}>Get New Emotes</AddEmotesButton>
      {packsModalOpen && (
        <HereModal modalWidth="763px" onOverlayClick={() => setPacksModalOpen(false)}>
          <StickerPacks onClose={() => setPacksModalOpen(false)} />
        </HereModal>
      )}
    </Container>
  );
};

export default EmotesSettings;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 40px 65px 40px;

  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 14px;

  color: white;
  width: 400px;
  margin-bottom: 16px;

  svg {
    margin-right: 5px;
    width: 20px;
  }
`;

const ModalContents = styled.div`
  width: 400px;
  height: 270px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);

  box-sizing: border-box;

  margin-bottom: 30px;
  padding: 20px 40px;

  display: flex;
  justify-content: center;
  overflow: scroll;

  ${noScrollbarMixin()}
`;

const EmptyContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmotesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 40px;
  gap: 14px;
`;

const EmptyTitle = styled.div`
  text-align: center;
  font-weight: 900;
  font-size: 16px;
  color: white;
`;

const EmptySubtitle = styled.div`
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 14px;
  opacity: 0.8;
`;

const Minus = styled.div`
  display: none;

  position: absolute;
  top: -9px;
  right: -9px;

  color: white;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  background: #6b1be3;
  border-radius: 30px;
  padding-bottom: 2px;
`;

const Emote = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  border-radius: 5px;

  box-sizing: border-box;

  &:hover {
    border: 3px solid #6b1be3;

    ${Minus} {
      display: flex;
    }
  }

  img {
    width: 34px;
    height: 34px;
  }
`;

const AddEmotesButton = styled.div`
  background: #6b1be3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 64px;
  padding: 14px 24px;

  cursor: pointer;

  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 700;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;
