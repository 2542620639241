import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import GroupNameField from '../../../groups-lobby/settings/GroupNameField';
import { getGroupChatParams, track } from '../../../../util/analytics-util';
import { updateNotificationsSetting } from '../../../store/groups/actions';
import NotificationsOnIcon from '../../../../../assets/icons/hereos/chat/notifications-on.svg';
import NotificationsOffIcon from '../../../../../assets/icons/hereos/chat/notifications-off.svg';
import OwnerCrownIcon from '../../../../../assets/icons/owner-crown.svg';
import AdminCrownIcon from '../../../../../assets/icons/admin-crown.svg';
import LeaveChatIcon from '../../../../../assets/icons/hereos/chat/leave-chat.svg';
import DeleteChatIcon from '../../../../../assets/icons/hereos/chat/delete-chat.svg';
import FriendsIcon from '../../../../../assets/icons/friends-v3/friends.svg';
import AddFriendIcon from '../../../../../assets/icons/friends-v3/add-friends.svg';
import DeleteChatHoverIcon from '../../../../../assets/icons/hereos/chat/delete-chat-hover.svg';
import { noScrollbarMixin, svgColorMixin } from '../../../mixins';
import { selectCurrentUserId } from '../../../store/users/selectors';
import {
  selectChatByChatId,
  selectCurrentUserRoleInChat,
  selectIsNotificationsOn,
  selectOrderedFullChatMembersWithSelfByChatId,
} from '../../../store/messaging/selectors';
import { CHAT_NOTIFICATIONS_DISABLE, CHAT_NOTIFICATIONS_ENABLE } from '../../analytics';
import MiniProfile from '../../../user-profile/MiniProfile';
import InviteFriendsModal from './modals/InviteFriendsModal';
import RemoveGroupConfirmationModal from './modals/RemoveGroupConfirmationModal';
import { ChatMemberRoles } from '../../../../definitions/message';
import { ChatPermissionsContext } from '../../../common/contexts';
import MemberContextMenu from './MemberContextMenu';

interface Props {
  chatId: string;
  closeWindow: () => void;
}

const ChatSettings: React.FC<Props> = ({ chatId, closeWindow }) => {
  const dispatch = useDispatch();

  const currentUserId = useSelector(selectCurrentUserId);

  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const groupId = chat?.groupId;

  const fullMembersArr = useSelector((state) => selectOrderedFullChatMembersWithSelfByChatId(state, chatId));

  const role = useSelector((state) => selectCurrentUserRoleInChat(state, chatId));
  const roleString = role === ChatMemberRoles.Owner ? `You are the ${role}` : `You are a ${role}`;

  const isNotificationsOn = useSelector((state) => selectIsNotificationsOn(state, chatId));

  const [inviteFriendsModalOpen, setInviteFriendsModalOpen] = useState(false);
  const [removeGroupConfirmationModalOpen, setRemoveGroupConfirmationModalOpen] = useState(false);

  const [hoverOnDeleteIcon, setHoverOnDeleteIcon] = useState(false);
  const deleteIcon = useMemo(
    () => (hoverOnDeleteIcon ? <DeleteChatHoverIcon /> : <DeleteChatIcon />),
    [hoverOnDeleteIcon]
  );

  const onToggleNotifications = () => {
    dispatch(updateNotificationsSetting({ groupId, userId: currentUserId, isNotificationsOn: !isNotificationsOn }));
    track(
      isNotificationsOn ? CHAT_NOTIFICATIONS_DISABLE : CHAT_NOTIFICATIONS_ENABLE,
      getGroupChatParams({
        chatId,
      })
    );
  };

  const permissions = useContext(ChatPermissionsContext);

  return (
    <Container>
      <Header>
        <NameContainer canInteract={permissions.canChangeGroupSettings}>
          <ChatNameLabel>Chat Name</ChatNameLabel>
          <GroupNameField chatId={chatId} />
        </NameContainer>
        {!chat?.isDm && (
          <HeaderButtons>
            <EmojiButton onClick={onToggleNotifications} canInteract>
              {isNotificationsOn ? <NotificationsOnIcon /> : <NotificationsOffIcon />}
            </EmojiButton>
            <EmojiButton
              onClick={() => setRemoveGroupConfirmationModalOpen(true)}
              onMouseEnter={() => setHoverOnDeleteIcon(true)}
              onMouseLeave={() => setHoverOnDeleteIcon(false)}
              emojiColorOverride={permissions.canDeleteGroup && hoverOnDeleteIcon && '#f6335d'}
              canInteract
            >
              {permissions.canDeleteGroup ? deleteIcon : <LeaveChatIcon />}
            </EmojiButton>
          </HeaderButtons>
        )}
      </Header>
      <ScrollArea>
        <MembersSectionHeader>
          <div>
            <MembersTitle>
              <FriendsIcon /> {fullMembersArr.length} Member{fullMembersArr.length > 1 && 's'}
            </MembersTitle>
            <RoleLabel>{roleString}</RoleLabel>
          </div>
          {permissions.canAddMembers || permissions.canRemoveMembers ? (
            <InviteButton onClick={() => setInviteFriendsModalOpen(true)}>
              Invite Friend <AddFriendIcon />
            </InviteButton>
          ) : null}
        </MembersSectionHeader>
        <MembersContainer>
          {fullMembersArr?.length > 0 &&
            fullMembersArr.map((m) => (
              <MemberContainer key={m.userId}>
                {m.role === ChatMemberRoles.Owner && (
                  <CrownIconContainer>
                    <OwnerCrownIcon />
                  </CrownIconContainer>
                )}
                {m.role === ChatMemberRoles.Admin && (
                  <CrownIconContainer>
                    <AdminCrownIcon />
                  </CrownIconContainer>
                )}

                <MemberContextMenu chatId={chatId} memberId={m.userId} isBanned={m.isBanned} />

                <MiniProfile profile={m} withUserCardsIcon={false} source="lobby > settings member list" />
              </MemberContainer>
            ))}
        </MembersContainer>
      </ScrollArea>

      {inviteFriendsModalOpen && <InviteFriendsModal chatId={chatId} setModalOpen={setInviteFriendsModalOpen} />}
      {removeGroupConfirmationModalOpen && (
        <RemoveGroupConfirmationModal
          chatId={chatId}
          groupId={groupId}
          groupName={chat?.groupName}
          closeModal={() => setRemoveGroupConfirmationModalOpen(false)}
          onConfirmed={closeWindow}
        />
      )}
    </Container>
  );
};

export default ChatSettings;

const Container = styled.div`
  background: var(--primary-background);
  border-radius: 0 16px 16px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 17px 0 17px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NameContainer = styled.div<{ canInteract: boolean }>`
  ${({ canInteract }) => (canInteract ? '' : 'pointer-events: none;')}
`;

const ChatNameLabel = styled.div`
  font-size: 9px;
  color: var(--primary-foreground);
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
`;

const EmojiButton = styled.div<{ emojiColorOverride?: string; canInteract: boolean }>`
  cursor: pointer;
  height: 33px;
  width: 33px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  ${({ canInteract }) => (canInteract ? '' : 'pointer-events: none;')}

  svg {
    ${({ emojiColorOverride }) =>
      emojiColorOverride ? svgColorMixin(emojiColorOverride) : svgColorMixin('var(--primary-foreground)')}
  }
`;

const ScrollArea = styled.div`
  overflow-y: auto;
  padding-bottom: 15px;
  ${noScrollbarMixin()}
`;

const MembersSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 20px;
`;

const MembersTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: var(--primary-foreground);

  ${svgColorMixin('var(--primary-foreground)')}
`;

const RoleLabel = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: var(--primary-foreground);
  opacity: 0.5;
`;

const InviteButton = styled.div`
  background: var(--secondary-background);
  color: var(--secondary-foreground);
  border-radius: 30px;

  width: 120px;
  height: 30px;

  cursor: pointer;

  font-size: 12px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 3px;
    ${svgColorMixin('var(--secondary-foreground)')}
  }

  &:hover {
    background-color: var(--secondary-background-alpha-50);
    color: var(--primary-foreground);

    ${svgColorMixin('var(--primary-foreground)')}
  }
`;

const MembersContainer = styled.div`
  margin-top: 17px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
  padding: 2px 7px;
`;

const CrownIconContainer = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -7px;
  left: -7px;

  z-index: 2;

  background: var(--secondary-background);
  svg {
    ${svgColorMixin('var(--secondary-foreground)')}
  }
`;

const MemberContainer = styled.div`
  width: 117px;
  height: 117px;

  position: relative;

  &:hover {
    .chat-item-context-button {
      display: flex;
    }
  }
`;
