// Triggered when Discord integration link is clicked from the room settings page
// or invite modal (available if no prior integration has been set up)
// to set up a new integration
// params: source (DISCORD_INTEGRATION_LINK_SOURCES)
export const CLICKED_ADD_DISCORD_INTEGRATION_LINK = 'Clicked Add Discord integration link';

export const DISCORD_INTEGRATION_LINK_SOURCES = {
  ADD_FROM_INVITE_MODAL: 'Invite modal',
  ADD_FROM_ROOM_SETTINGS: 'Room settings',
};

// Triggered when Discord integration link is clicked from the room settings page
// to update an existing Discord integration
export const CLICKED_UPDATE_DISCORD_INTEGRATION_LINK = 'Clicked Update Discord integration link';
export const DISCORD_INTEGRATION_COMPLETED_SUCCESSFULLY = 'Discord integration completed successfully';
export const LINK_DISCORD_CHANNEL_TO_ROOM = 'Link Discord Channel To Room';
export const CLICKED_DISCORD_INVITE_LINK = 'Clicked Discord Invite Link';
export const CLICKED_REMOVE_DISCORD_INTEGRATION = 'Clicked remove Discord integration';
export const DISCORD_INTEGRATION_REMOVED_SUCCESSFULLY = 'Discord integration removed successfully';
export const DISCORD_INTEGRATION_REMOVED_FAILED = 'Discord integration removed failed';
