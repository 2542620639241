import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../definitions/store';
import { selectCurrentUser } from '../users/selectors';
import { Kaomoji } from './init-state';

export const selectIsKaomojiLoading = (state: RootState) => state.kaomoji.isLoading;

export const selectDefaultKaomojisCategories = (state: RootState) => state.kaomoji.defaultKaomojis;
export const selectTrendingKaomojis = createSelector(
  [selectDefaultKaomojisCategories],
  (categories) => categories.filter((c) => c.isTrending)[0]?.kaomojis || []
);
export const selectNonTrendingKaomojiCategories = createSelector([selectDefaultKaomojisCategories], (categories) =>
  categories.filter((c) => !c.isTrending)
);

export const selectCustomKaomojis: (state: RootState) => Kaomoji[] = createSelector([selectCurrentUser], (user) =>
  [...(user?.kaomojis || [])].reverse()
);
