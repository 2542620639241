import React, { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Popover from '@radix-ui/react-popover';
import styled from 'styled-components';

import { removeButtonStylingMixin } from '../mixins';
import { db } from '../../firebase';
import { selectCurrentUserId } from '../store/users/selectors';
import { DEFAULT_PROFILE_EFFECTS } from './constants';
import { track } from '../../util/analytics-util';
import { EDIT_PROFILE_EFFECT, REMOVE_PROFILE_EFFECT } from '../../constants/analytics-events/user-events';

interface Props {
  emoteUrl?: string;
}

const ProfileEffectEmoteInput: React.FC<Props> = ({ emoteUrl = '' }) => {
  const userId = useSelector(selectCurrentUserId);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const selectEffect = async (url: string) => {
    await db.doc(`userProfiles/${userId}`).update({
      effectEmoteUrl: url,
    });
    setIsMenuOpened(false);
    track(EDIT_PROFILE_EFFECT, { effectUrl: url });
  };

  const clearEffect = async (e: MouseEvent) => {
    e.stopPropagation();
    await db.doc(`userProfiles/${userId}`).update({
      effectEmoteUrl: null,
    });
    track(REMOVE_PROFILE_EFFECT);
  };

  return (
    <EmotePopoverWrapper open={isMenuOpened} onOpenChange={(isOpened) => setIsMenuOpened(isOpened)}>
      <EmotePopoverTrigger>
        <EmoteInput>
          {emoteUrl ? (
            <InputContents>
              <img alt="emote" src={emoteUrl} />
              <ClearButton onClick={(e) => clearEffect(e)}>Clear</ClearButton>
            </InputContents>
          ) : (
            <InputContents>
              <PlaceholderText>Appears as floating on your profile</PlaceholderText>
            </InputContents>
          )}
        </EmoteInput>
      </EmotePopoverTrigger>
      <EmotePopoverContent className="prevent-profile-closing">
        <ButtonsContainer>
          {DEFAULT_PROFILE_EFFECTS.map((url, i) => (
            <EffectButton key={url} onClick={() => selectEffect(url)}>
              <img src={url} alt={`effect option ${i}`} />
            </EffectButton>
          ))}
        </ButtonsContainer>
      </EmotePopoverContent>
    </EmotePopoverWrapper>
  );
};

export default ProfileEffectEmoteInput;

const EmotePopoverWrapper = Popover.Root;
const EmotePopoverTrigger = styled(Popover.Trigger)`
  ${removeButtonStylingMixin()}
  width: 100%;
`;
const EmotePopoverContent = styled(Popover.Content)`
  background: white;
  border-radius: 5px;
  padding: 10px;
`;

const EmoteInput = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  position: relative;
  height: 38px;
  display: flex;
  align-items: center;
  border: none;
  outline: 1px solid var(--primary-foreground-alpha-20, rgba(0, 0, 0, 0.2));

  cursor: pointer;

  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;

  &:hover {
    outline: 1px solid var(--primary-foreground-alpha-50, rgba(255, 255, 255, 0.5));
  }
`;

const InputContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
  }
`;

const PlaceholderText = styled.div`
  color: var(--primary-foreground-alpha-50, rgba(160, 160, 160, 0.5));
  font-size: 0.95em;
  text-align: left;
`;

const ClearButton = styled.div`
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.5;
  font-size: 12px;
  color: var(--primary-foreground, white);
`;

const ButtonsContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(5, 1fr); /* Creates 5 columns */
  grid-template-rows: repeat(5, 1fr); /* Creates 5 rows */
  gap: 10px;
`;

const EffectButton = styled.div`
  height: 40px;
  width: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover {
    background: #f4f4f4;
  }
`;
