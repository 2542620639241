import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { track } from '../../util/analytics-util';
import { REQUEST_FRIEND, ACCEPT_FRIEND } from '../../constants/analytics-events/friend-events';
import SpinnerLoader from '../components/SpinnerLoader';
import { EventSourceContext } from '../common/contexts.ts';
import { useFriendingButtons } from '../hooks/feed/useFriendingButtons';

import AddFriendPendingIcon from '../../../assets/icons/add-friend-pending.svg';
import AddFriendIcon from '../../../assets/icons/add-friend.svg';
import AcceptFriendIcon from '../../../assets/icons/add-friend-accept.svg';
import log from '../../log';
import { svgColorMixin } from '../mixins';

// Note: This component accepts `theme` through styled-components ThemeProvider
// theme: { buttonHeight, buttonWidth }

const AddFriendButton = ({ userId, onClick }) => {
  const [loading, setLoading] = useState(false);
  const source = useContext(EventSourceContext);

  const { showAddFriend, showAcceptFriend, showPendingIcon, addFriendHandler, acceptFriendHandler } =
    useFriendingButtons(userId);

  const onAcceptFriendClick = useCallback(async () => {
    setLoading(true);
    try {
      const response = await acceptFriendHandler();
      if (response?.success) {
        track(ACCEPT_FRIEND, { source, receiverId: userId });
      }
      setLoading(false);
    } catch (e) {
      log.error('Error Accepting Friend', e);
      setLoading(false);
    }
  }, [acceptFriendHandler, source, userId]);

  const onAddFriendClick = useCallback(async () => {
    setLoading(true);
    onClick();
    try {
      const response = await addFriendHandler();
      if (response?.success) {
        track(REQUEST_FRIEND, { source, receiverId: userId });
      }
      setLoading(false);
    } catch (e) {
      log.error('Error Adding Friend', e);
      setLoading(false);
    }
  }, [addFriendHandler, onClick, source, userId]);

  return (
    <>
      {showAcceptFriend && (
        <Button disabled={loading} onClick={onAcceptFriendClick}>
          <FlexContainer>
            {loading ? <SpinnerLoader /> : <AcceptFriendIcon width="18" />}
            <ButtonText> Accept Friend</ButtonText>
          </FlexContainer>
        </Button>
      )}
      {showPendingIcon && (
        <Button disabled>
          <FlexContainer>
            <AddFriendPendingIcon width="20" />
            <ButtonText> Request Sent</ButtonText>
          </FlexContainer>
        </Button>
      )}
      {showAddFriend && (
        <Button disabled={loading} onClick={onAddFriendClick}>
          <FlexContainer>
            {loading ? <SpinnerLoader /> : <AddFriendIcon width="20" />}
            <ButtonText> Add Friend</ButtonText>
          </FlexContainer>
        </Button>
      )}
    </>
  );
};

export default AddFriendButton;

AddFriendButton.propTypes = {
  userId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

AddFriendButton.defaultProps = {
  onClick: () => {},
};

const Button = styled.div`
  background: ${({ theme }) => theme?.buttonsBackground || '#6b1be3'};
  color: ${({ theme }) => theme?.buttonsFontColor || 'white'};
  font-size: 12px;
  font-family: Inter;
  font-weight: bold;
  text-align: center;
  height: ${({ theme }) => (theme?.buttonHeight ? theme.buttonHeight : '36px')};
  width: ${({ theme }) => (theme?.buttonWidth ? theme.buttonWidth : '130px')};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0%);
  transition: opacity 0.2s;

  ${({ theme }) => (theme ? svgColorMixin(theme.buttonsFontColor) : '')}

  &:hover {
    opacity: 0.85;
  }

  &[disabled] {
    cursor: auto;

    &,
    &:hover {
      opacity: 0.5;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonText = styled.span`
  margin-left: 6px;
  margin-top: 2px;
`;
