import { useCallback, useMemo, useRef } from 'react';

import { EventBus } from '../../event-bus';

const changeRequestedEvent = 'change requested';
const changeEvent = 'change';

export const useViewportController = ({ containerRef, allowedAxis = undefined }) => {
  // Viewport coordinates relate to where viewport top left corner is. The actual offset is different
  // and calculated in Viewport.jsx, but it's encapsulated there, so all users of this controller must
  // use only top left corner notation.
  const coordinatesRef = useRef({ x: 0, y: 0, scale: 1 });
  const rectRef = useRef(containerRef.current?.getBoundingClientRect());

  const bus = useMemo(() => new EventBus(), []);

  const initializedPromiseResolveRef = useRef(null);
  const initializedPromise = useMemo(
    () =>
      new Promise((resolve) => {
        initializedPromiseResolveRef.current = resolve;
      }),
    []
  );

  // getBoundingClientRect is expensive to call, so we must memoize it and recalculate
  // only when viewport size is actually changed (e.g. on window resize)
  const recalculateViewportSize = useCallback(() => {
    rectRef.current = containerRef.current?.getBoundingClientRect();
  }, [containerRef]);

  return useMemo(
    () => ({
      allowedAxis,
      getCoordinates: () => coordinatesRef.current,
      getViewportRect: () => rectRef.current,
      onViewportChanged: (coordinates) => {
        coordinatesRef.current = { ...coordinatesRef.current, ...coordinates };
        bus.dispatch(changeEvent, { ...coordinatesRef.current });
      },
      recalculateViewportSize,
      requestViewportChange: (params) => bus.dispatch(changeRequestedEvent, params),
      subscribeToViewportChangeRequest: (callback) => {
        bus.on(changeRequestedEvent, callback);
        recalculateViewportSize();
        // By default viewport offset is [0, 0], so its top left corder would be at [-width / 2, -height / 2]
        coordinatesRef.current = { x: -rectRef.current.width / 2, y: -rectRef.current.height / 2, scale: 1 };
        initializedPromiseResolveRef.current();
        return () => bus.off(changeRequestedEvent, callback);
      },
      subscribeToViewportChanged: (callback) => {
        bus.on(changeEvent, callback);
        return () => bus.off(changeEvent, callback);
      },
      // Promise is resolved when Viewport component is initialized and listens for position change requests
      initializedPromise,
    }),
    [allowedAxis, recalculateViewportSize, initializedPromise, bus]
  );
};
