import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { drawLines } from '../../../drawing';
import { BoardElementControllerContext } from '../../common/contexts.ts';

const WhiteboardElement = () => {
  const canvas = useRef();
  const { elementData } = useContext(BoardElementControllerContext);

  useEffect(() => {
    if (!elementData.lines) return;

    canvas.current.width = parseFloat(elementData.size[0]) * 2;
    canvas.current.height = parseFloat(elementData.size[1]) * 2;
    drawLines(elementData.lines, canvas.current.getContext('2d'), canvas.current);
  }, [elementData]);

  // Setting canvas size both in useEffect and in JSX since we need it right before drawing,
  // but also we need it to be persistent between renders.

  return (
    <Container backgroundColor={elementData.backgroundColor}>
      {elementData.image ? <BackgroundImage src={elementData.image} alt="" /> : null}
      <Canvas ref={canvas} width={elementData.size[0] * 2} height={elementData.size[1] * 2} />
    </Container>
  );
};

export default WhiteboardElement;

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  overflow: hidden;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5, 0.5);
  max-width: unset;
`;
