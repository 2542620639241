import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CardDeleteConfirmation = ({ onConfirm, onCancel }) => (
  <Container>
    <Image src="/images/misc/cute-cat-delete-confirmation.png" alt="Cute cat asking you if you want to delete" />
    <Title>Are you sure you want to delete this card? You will never see it again...</Title>
    <ButtonsContainer>
      <Button type="button" className="here-button-minimal" onClick={onCancel}>
        Nevermind
      </Button>
      <Button type="button" className="here-button-danger" onClick={onConfirm}>
        Delete
      </Button>
    </ButtonsContainer>
  </Container>
);

export default CardDeleteConfirmation;

CardDeleteConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const Container = styled.div`
  max-width: 600px;
  background: white;
  border-radius: 10px;
  padding: 0 90px 50px;
  text-align: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #12002d;
`;

const Image = styled.img`
  margin-top: -93px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin: 30px auto 0;
`;

const Button = styled.button`
  font-size: 16px;
  font-weight: bold;
  padding: 10px 16px;
  width: 130px;
`;
