import template from './logo-effects.html';
import './logo-effects.svg';
import './logo-effects.less';

function generateFirePalette() {
  const palette = [];
  for (let i = 0; i < 32; i += 1) {
    // ramp gray
    palette.push([i * 2, i * 2, i * 2]);
  }
  for (let i = 0; i < 32; i += 1) {
    // ramp gray
    palette.push([64 - i * 2, 64 - i * 2, 64 - i * 2]);
  }

  for (let i = 0; i < 64; i += 1) {
    // ramp up orange
    palette.push([i * 4, i, 32]);
  }
  for (let i = 0; i < 64; i += 1) {
    // over to yellow
    palette.push([255, i * 2 + 128, 32]);
  }

  for (let i = 0; i < 64; i += 1) {
    // black
    palette.push([255 - i * 4, 255 - i * 4, 32 + i * 2]);
  }

  return palette;
}

function generatePlasmaPalette() {
  const plasmaPalette = [];
  for (let i = 0; i < 32; i += 1) {
    // ramp red
    plasmaPalette.push([i * 4, 0, 0]);
  }
  for (let i = 0; i < 32; i += 1) {
    plasmaPalette.push([255 - i * 4, 0, 0]);
  }
  for (let i = 0; i < 32; i += 1) {
    // ramp cyan
    plasmaPalette.push([0, i * 4, i * 4]);
  }
  for (let i = 0; i < 32; i += 1) {
    plasmaPalette.push([0, 255 - i * 4, 255 - i * 4]);
  }
  for (let i = 0; i < 32; i += 1) {
    // ramp magenta
    plasmaPalette.push([i * 4, 0, i * 4]);
  }
  for (let i = 0; i < 32; i += 1) {
    plasmaPalette.push([255 - i * 4, 0, 255 - i * 4]);
  }
  for (let i = 0; i < 32; i += 1) {
    // ramp yellow
    plasmaPalette.push([i * 4, i * 4, 0]);
  }
  for (let i = 0; i < 32; i += 1) {
    plasmaPalette.push([255 - i * 4, 255 - i * 4, 0]);
  }

  return plasmaPalette;
}

export default class LogoEffects extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    const effectW = 145;
    const effectH = 50;

    const canvas = this.querySelector('.logo-effects');
    const effects = [iteratePlasma2, iteratePlasma1, iterateStatic, iterateFire, iterateMoiree];
    let currentEffect = 0;
    let isActive = false;

    const ctx = canvas.getContext('2d');

    const imageData = ctx.createImageData(effectW, effectH);
    const imageDataRaw = imageData.data;

    const container = this.querySelector('.logo-container');
    const logo = this.querySelector('.logo');
    container.addEventListener('mouseenter', () => {
      canvas.style.display = 'block';
      logo.classList.add('logo-invisible');
      isActive = true;
      requestAnimationFrame(effects[currentEffect]);
    });
    container.addEventListener('mouseleave', () => {
      canvas.style.display = 'none';
      logo.classList.remove('logo-invisible');
      logo.classList.add('logo-visible');
      isActive = false;
      currentEffect += 1;
      currentEffect %= effects.length;
    });

    container.addEventListener('click', () => {
      currentEffect += 1;
      currentEffect %= effects.length;
    });

    function setPx(x, y, r, g, b) {
      const baseIdx = (y * effectW + x) * 4;
      imageDataRaw[baseIdx] = r;
      imageDataRaw[baseIdx + 1] = g;
      imageDataRaw[baseIdx + 2] = b;
      imageDataRaw[baseIdx + 3] = 255;
    }

    /** Flames */
    const flameData = new Array(effectW * effectH).fill(0);
    const firePalette = generateFirePalette();
    function iterateFire() {
      function getFlame(x, y) {
        return flameData[y * effectW + x];
      }

      // base of the fire
      for (let j = effectH - 2; j < effectH; j += 1) {
        for (let i = 0; i < effectW; i += 1) {
          const w = Math.random() > 0.5 ? 255 : 0;
          flameData[j * effectW + i] = w;
        }
      }

      // it's pretty boring, so add random sparks
      for (let i = 0; i < 2; i += 1) {
        const x = Math.floor(Math.random() * effectW);
        const y = Math.floor(Math.random() * (effectH - 1));
        flameData[y * effectW + x] = 255;
        flameData[(y + 1) * effectW + x + 1] = 255;
        flameData[y * effectW + x + 1] = 255;
        flameData[(y + 1) * effectW + x] = 255;
      }

      for (let i = 0; i < effectW; i += 1) {
        for (let j = 0; j < effectH; j += 1) {
          const f = getFlame(i, j);
          const f2 = getFlame(i, j + 1) || 0;
          const f3 = getFlame(i, j + 2) || 0;
          const f4 = getFlame(i - 1, j) || 0;
          const f5 = getFlame(i - 1, j + 1) || 0;
          const f6 = getFlame(i + 1, j + 1) || 0;
          const avg = Math.floor((f + f2 + f3 + f4 + f5 + f6) / 6);
          const color = firePalette[avg];
          flameData[j * effectW + i] = avg;
          setPx(i, j, color[0], color[1], color[2]);
        }
      }

      ctx.putImageData(imageData, 0, 0);
      if (isActive) {
        requestAnimationFrame(effects[currentEffect]);
      }
    }

    /** Static */
    function iterateStatic() {
      // Blit it to the screen
      for (let i = 0; i < effectW / 3; i += 1) {
        for (let j = 0; j < effectH / 3; j += 1) {
          const r = Math.random() < 0.5 ? 0 : 255;
          const g = Math.random() < 0.5 ? 0 : 255;
          const b = Math.random() < 0.5 ? 0 : 255;
          setPx(i * 3, j * 3, r, g, b);
          setPx(i * 3, j * 3 + 1, r, g, b);
          setPx(i * 3, j * 3 + 2, 0, 0, 0);
          setPx(i * 3 + 1, j * 3, r, g, b);
          setPx(i * 3 + 1, j * 3 + 1, r, g, b);
          setPx(i * 3 + 1, j * 3 + 2, 0, 0, 0);
          setPx(i * 3 + 2, j * 3, 0, 0, 0);
          setPx(i * 3 + 2, j * 3 + 1, 0, 0, 0);
          setPx(i * 3 + 2, j * 3 + 2, 0, 0, 0);
        }
      }
      ctx.putImageData(imageData, 0, 0);

      if (isActive) {
        requestAnimationFrame(effects[currentEffect]);
      }
    }

    /** Moiree */
    const mX = [50, 70];
    const mY = [80, 155];
    const mDX = [0, 0];
    const mDY = [0, 0];

    function iterateMoiree() {
      ctx.clearRect(0, 0, effectW, effectH);
      ctx.globalCompositeOperation = 'xor';
      ctx.fillStyle = 'white';

      for (let i = 0; i < mX.length; i += 1) {
        for (let j = 10; j < effectW; j += 10) {
          ctx.beginPath();
          ctx.arc(mX[i], mY[i], j, 0, 2 * Math.PI, false);
          ctx.fill();
        }

        if (mY[i] > effectH / 2) {
          mDY[i] -= 0.5;
        } else {
          mDY[i] += 0.5;
        }
        mY[i] += mDY[i] / 32;

        if (mX[i] > effectW / 2) {
          mDX[i] -= 0.5;
        } else {
          mDX[i] += 0.5;
        }
        mX[i] += mDX[i] / 32;
      }

      if (isActive) {
        requestAnimationFrame(effects[currentEffect]);
      }
    }

    /** Plasma 1 */
    const plasmaPalette = generatePlasmaPalette();

    let counter = 0;
    function iteratePlasma1() {
      for (let i = 0; i < effectW; i += 1) {
        for (let j = 0; j < effectH; j += 1) {
          const ii = i * 2;
          const jj = j * 2;
          const idx = Math.sin((ii + jj + counter) / 80) * jj + Math.cos(ii / 20) * Math.sin(counter / 180) * ii;
          const color = plasmaPalette[Math.abs(Math.floor(idx) + counter) % plasmaPalette.length];
          setPx(i, j, color[0], color[1], color[2]);
        }
      }

      ctx.putImageData(imageData, 0, 0);
      counter += 1;

      if (isActive) {
        requestAnimationFrame(effects[currentEffect]);
      }
    }

    /** Plasma 2 */
    function iteratePlasma2() {
      for (let i = 0; i < effectW; i += 1) {
        for (let j = 0; j < effectH; j += 1) {
          const ii = i * 3;
          const jj = j * 3;
          let r =
            Math.sin((jj + counter) / 40) * (jj + effectH / 2) +
            Math.cos(ii / 50) * Math.sin(counter / 180) * (ii + effectW / 2);
          let g =
            Math.sin((ii + counter) / 80) * (jj + effectH / 2) +
            Math.sin(jj / 70) * Math.cos(counter / 180) * (ii + effectW / 2);
          let b =
            Math.sin((jj + counter) / 30) * (jj + effectH / 2) +
            Math.cos(counter / 50) * Math.sin(jj / 180) * (ii + effectW / 2);
          if (Math.abs(r) < 128) r = 0;
          else r = 255;
          if (Math.abs(g) < 128) g = 0;
          else g = 255;
          if (Math.abs(b) < 128) b = 0;
          else b = 255;
          setPx(i, j, r, g, b);
        }
      }

      ctx.putImageData(imageData, 0, 0);
      counter += 1;

      if (isActive) {
        requestAnimationFrame(effects[currentEffect]);
      }
    }
  }
}

window.customElements.define('here-logo-effects', LogoEffects);
