import React from 'react';
import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { ellipsisTextMixin } from '../mixins';
import { Mood } from '../../definitions/user-profile';

interface UserMoodTooltipProps {
  title: string;
  mood: Mood;
  children: JSX.Element;
}

const UserMoodTooltip = ({ title, mood, children }: UserMoodTooltipProps) => (
  <TooltipRoot delayDuration={100}>
    <TooltipTrigger asChild>{children}</TooltipTrigger>
    <TooltipStyledContent bgColor={mood.theme.colors.primaryBackground} side="right" sideOffset={5}>
      <Title color={mood.theme.colors.primaryForeground}>{title}</Title>
      <MoodText font={mood.theme.primaryFont} color={mood.theme.colors.primaryForeground}>
        {mood.text}
      </MoodText>
      <TooltipArrow offset={10} bgColor={mood.theme.colors.primaryBackground} />
    </TooltipStyledContent>
  </TooltipRoot>
);

export default UserMoodTooltip;

const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipStyledContent = styled(TooltipPrimitive.Content)<{ bgColor: string }>`
  padding: 5px 15px;
  min-width: 100px;
  max-width: 250px;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor};
`;

const TooltipArrow = styled(TooltipPrimitive.Arrow)<{ bgColor: string }>`
  height: 10px;
  width: 10px;
  fill: ${({ bgColor }) => bgColor};
`;

const Title = styled.p<{ color: string }>`
  margin: 0;
  color: #140630;
  font-size: 16px;
  font-weight: bold;
  color: ${({ color }) => color};
  ${ellipsisTextMixin()}
`;

const MoodText = styled.p<{ font: string; color: string }>`
  margin: 0;
  font-family: '${({ font }) => font}', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: ${({ color }) => color};
  ${ellipsisTextMixin()};
`;
