import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import FadeIn from '../components/animate/FadeIn';
import FriendsListItem from './FriendsListItem';
import { friendsAsArraySelector, friendsErrorSelector, loadingFriendsSelector } from '../store/friends/selectors';
import { fetchFriends } from '../store/friends/store';
import { customScrollbarMixin } from '../mixins';

const FriendsList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // initial fetch:
    dispatch(fetchFriends());
  }, [dispatch]);

  const friendsList = useSelector(friendsAsArraySelector);
  const fetchingFriends = useSelector((state) => loadingFriendsSelector(state));
  const fetchFriendsError = useSelector((state) => friendsErrorSelector(state));

  return (
    <ScrollableContainer>
      {friendsList.length > 0 &&
        friendsList.map((friend) => (
          <FriendsListItem
            key={friend.userId}
            userId={friend.userId}
            onlineStatus={friend.onlineStatus && friend.onlineStatus.status}
          />
        ))}
      {friendsList.length === 0 && !fetchingFriends && !fetchFriendsError && (
        <FadeIn>
          <NoFriendsContainer>
            <NoFriendsBackground url="/images/profile-backgrounds/grey.svg" />
            <NoFriendsContent>
              <img src="/images/misc/no-friends-smiley-dudes.svg" alt="No friends" />
              <div>Your friends list is empty</div>
              <NoFriendsSubheader>Invite your friends or find them in friend search!</NoFriendsSubheader>
            </NoFriendsContent>
          </NoFriendsContainer>
        </FadeIn>
      )}
    </ScrollableContainer>
  );
};

export default FriendsList;

FriendsList.defaultProps = {
  friendsList: [],
};

const NoFriendsContainer = styled.div`
  position: relative;
  height: 248px;
`;

const NoFriendsBackground = styled.div`
  background: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const NoFriendsContent = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;

  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  color: #12002d;

  line-height: 24px;
`;

const NoFriendsSubheader = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  border-radius: 10px;
  ${customScrollbarMixin()}
`;
