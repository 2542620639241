import $ from 'jquery';
import Hammer from 'hammerjs';

import firebase, { db } from './firebase';
import {
  screenDrawingContext,
  xGuideline,
  yGuideline,
  clearAlignmentGuidelines,
  setupCanvas,
  drawScreenLines,
} from './drawing';
import minimap from './minimap';
import log from './log';
import { ensureRectVisible, stopEdgeScroll } from './viewport';
import { screenToCanvasCoords, canvasToScreenCoords } from './util/canvas-util';
import { getElementPosition, applyElementTransformFromPartialData, getElementRotationAngle } from './element-transform';
import { handlerForElement, getDocIdFromElementId, isOnScreen, boardElementForEvent } from './util';
import { checkIsMobile } from './util/platform-util';
import { checkBarriers } from './util/barrier-util';
import { deleteElement, duplicate } from './util/element-util';
import { getRelatedPinnedElements, hasPinnedElements } from './util/pinned-util';
import { stopKeyboardMoveLoop } from './util/camera-util';
import { canDeleteContent } from './roles-management';
import { getCurrentBoardIsUserCard } from './util/room-util';

const canvas = document.getElementById('pen-canvas');
const trashcan = document.getElementById('trashcan');
const main = document.getElementById('main');

let isElementMoved = false;
export function elementMoved() {
  return isElementMoved;
}

function resetIsElementMoved() {
  // Put this back on the runloop since we're evaluating click
  // events in this loop based on isElementMoved
  setTimeout(() => {
    isElementMoved = false;
  }, 0);
}

const rotationAnchorStep = 45;
const rotationAnchors = Array(...Array(360 / rotationAnchorStep)).map((_, i) => i * rotationAnchorStep);
const rotationAnchorRange = 8;

let mainHammer = { on: () => {}, off: () => {} };
if (checkIsMobile() && window.isUserInRoom) {
  mainHammer = new Hammer(main);
  mainHammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }));
}

export const dragElement = (e) => {
  const textAreaElements = ['INPUT', 'BUTTON', 'TEXTAREA', 'A'];
  const isTextArea = textAreaElements.includes(e.target.nodeName);
  const isTextEditor = !!e.target.closest('.text-editor');
  const isTouchEvent = e.pointerType === 'touch';
  if (isTextArea || isTextEditor) {
    return;
  }

  // don't move viewport if user tries to select text in chat
  const isChatMessage = !!e.target.closest('.message-container');
  if (isChatMessage) {
    e.stopPropagation();
    return;
  }

  if (!isTouchEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (
    e.shiftKey ||
    e.target.closest('.element-menu') ||
    e.target.closest('.element-submenu') ||
    e.target.closest('.dont-drag-me')
  ) {
    return;
  }

  let shouldDelete = false;
  isElementMoved = false;

  const guidelines = genGuidelines();

  const startCursorX = isTouchEvent ? e.srcEvent.clientX : e.clientX;
  const startCursorY = isTouchEvent ? e.srcEvent.clientY : e.clientY;
  const pixRatio = 1 / window.canvasScale;

  const target = boardElementForEvent(e, e.target);
  if (!target.classList.contains('can-move')) {
    return;
  }

  const style = window.getComputedStyle(target);
  let [startX, startY] = getElementPosition(target);
  const startDragWidth = parseFloat(style.width);
  const startDragHeight = parseFloat(style.height);

  let lastX = startX;
  let lastY = startY;
  let lastWidth = startDragWidth;
  let lastHeight = startDragHeight;

  if (e.target.classList.contains('resize')) {
    handleResize(e.target.classList.contains('aspect-ratio') || hasPinnedElements(getDocIdFromElementId(target.id)));
  } else if (e.target.classList.contains('rotate')) {
    handleRotate();
  } else {
    handleDrag();
  }

  function handleRotate() {
    isElementMoved = true;

    document.body.classList.add('rotating-cursor');
    main.addEventListener('mouseup', closeRotateElement);
    main.addEventListener('mousemove', elementRotate);
    mainHammer.on('panend', closeRotateElement);
    mainHammer.on('panmove', elementRotate);

    target.classList.add('rotating');

    const startAngle = getElementRotationAngle(target);
    let currentAngle = startAngle;

    // All stuff below works with system of coordinates relative to element's center.
    // Math.atan2 calculates angle between a vector and X axis, so in this system of coords
    // we can calculate angle of a vector from element center to the cursor which feels pretty natural!
    const screenCoords = canvasToScreenCoords(startX, startY);
    const relativeZeroX = screenCoords[0] + startDragWidth / pixRatio / 2;
    const relativeZeroY = screenCoords[1] + startDragHeight / pixRatio / 2;

    const getAngle = (x, y) => (Math.atan2(relativeZeroX - x, relativeZeroY - y) * 180) / Math.PI;
    const startRelativeAngle = getAngle(startCursorX, startCursorY);

    function elementRotate(rotateEvent) {
      const clientX = rotateEvent.srcEvent ? rotateEvent.srcEvent.clientX : rotateEvent.clientX;
      const clientY = rotateEvent.srcEvent ? rotateEvent.srcEvent.clientY : rotateEvent.clientY;
      const deltaAngle = startRelativeAngle - getAngle(clientX, clientY);
      currentAngle = (startAngle + deltaAngle + 360) % 360;

      const anchorNearby = rotationAnchors.find((anchor) => Math.abs(anchor - currentAngle) < rotationAnchorRange);
      currentAngle = anchorNearby === undefined ? currentAngle : anchorNearby;

      window.rtc.sendElementRotated(target.id, currentAngle);
      applyElementTransformFromPartialData(target, { rotationAngle: currentAngle });
    }

    function closeRotateElement() {
      document.body.classList.remove('rotating-cursor');
      main.removeEventListener('mouseup', closeRotateElement);
      main.removeEventListener('mousemove', elementRotate);
      mainHammer.off('panend', closeRotateElement);
      mainHammer.off('panmove', elementRotate);

      target.classList.remove('rotating');

      db.doc(target.getAttribute('docPath')).update({
        rotationAngle: currentAngle,
      });

      resetIsElementMoved();
    }
  }

  async function handleResize(preserveAspectRatio) {
    const startWidth = parseFloat(style.width) || 0;
    const startHeight = parseFloat(style.height) || 0;

    const pinnedElementsData = getRelatedPinnedElements(getDocIdFromElementId(target.id), false);
    const pinnedElements = pinnedElementsData
      .map(({ elementId, distance, initiatorElementId }) => {
        const element = document.getElementById(elementId);
        if (!element) {
          return null;
        }

        const [elementStartX, elementStartY] = getElementPosition(element);
        return {
          element,
          elementStartX,
          elementStartY,
          distance,
          initiatorElementId,
          elementStartHeight: parseFloat(element.style.height) || 0,
          elementStartWidth: parseFloat(element.style.width) || 0,
        };
      })
      .filter((item) => Boolean(item));

    window.addEventListener('mouseup', closeResizeElement);
    window.addEventListener('mousemove', elementResize);
    mainHammer.on('panend', closeResizeElement);
    mainHammer.on('panmove', elementResize);

    function elementResize(resizeEvent) {
      if (!isTouchEvent) {
        resizeEvent.preventDefault();
        resizeEvent.stopPropagation();
      }

      // set the element's new position
      const clientX = isTouchEvent && resizeEvent.srcEvent ? resizeEvent.srcEvent.clientX : resizeEvent.clientX;
      const clientY = isTouchEvent && resizeEvent.srcEvent ? resizeEvent.srcEvent.clientY : resizeEvent.clientY;
      const xDiff = (clientX - startCursorX) * pixRatio;
      const yDiff = (clientY - startCursorY) * pixRatio;
      let newWidth = startWidth + xDiff;
      let newHeight = startHeight + yDiff;

      const heightDiff = newHeight / startHeight;
      const widthDiff = newWidth / startWidth;

      if (preserveAspectRatio) {
        if (widthDiff < heightDiff) {
          newHeight = startHeight * widthDiff;
        } else {
          newWidth = startWidth * heightDiff;
        }
      }

      isElementMoved = newWidth !== startWidth || newHeight !== startHeight;

      const [x, y] = getElementPosition(target);
      newWidth = checkGuides(guidelines.x, x + newWidth, target, false) - x;
      newHeight = checkGuides(guidelines.y, y + newHeight, target, true) - y;

      const handler = window.elementHandlers[target.id.replace('element-', '')];
      if (handler && handler.constructor.elementType === 'CameraElement') {
        const newPos = checkBarriers(
          { x: lastX, y: lastY, w: lastWidth, h: lastHeight },
          { x: startX, y: startY, w: newWidth, h: newHeight }
        );
        newWidth = newPos.w;
        newHeight = newPos.h;
      }

      const minSize = handler && handler.minSize && handler.minSize() ? handler.minSize() : [50, 50];

      if (newWidth > minSize[0] && newHeight > minSize[1]) {
        target.style.width = `${newWidth}px`;
        target.style.height = `${newHeight}px`;
        // Move the left and top position as well to fix the resize anchor point
        const newX = startX;
        const newY = startY;
        applyElementTransformFromPartialData(target, { center: [newX, newY] });
        window.rtc.sendElementResized(target.id, newX, newY, newWidth, newHeight);
        lastX = newX;
        lastY = newY;
        lastWidth = newWidth;
        lastHeight = newHeight;

        pinnedElements.forEach(({ element, distance, initiatorElementId, elementStartHeight, elementStartWidth }) => {
          let elementNewHeight = elementStartHeight * heightDiff;
          let elementNewWidth = elementStartWidth * widthDiff;
          if (widthDiff < heightDiff) {
            elementNewHeight = elementStartHeight * widthDiff;
          } else {
            elementNewWidth = elementStartWidth * heightDiff;
          }

          element.style.height = `${elementNewHeight}px`;
          element.style.width = `${elementNewWidth}px`;

          const [initiatorX, initiatorY] = getElementPosition(document.getElementById(initiatorElementId));
          const elementNewX = initiatorX + distance.x * elementNewWidth;
          const elementNewY = initiatorY + distance.y * elementNewHeight;

          applyElementTransformFromPartialData(element, { center: [elementNewX, elementNewY] }, false);
          window.rtc.sendElementResized(element.id, elementNewX, elementNewY, elementNewWidth, elementNewHeight);
        });

        minimap.setNeedsUpdate();

        if (handler && handler.onSizeChange) {
          handler.onSizeChange(newWidth, newHeight);
        }
      }
    }

    async function closeResizeElement(__e) {
      // stop moving when mouse button is released
      window.removeEventListener('mouseup', closeResizeElement);
      window.removeEventListener('mousemove', elementResize);
      mainHammer.off('panend', closeResizeElement);
      mainHammer.off('panmove', elementResize);

      // Clear alignment guides
      clearAlignmentGuidelines();

      const updateElementsPositionsBatch = db.batch();

      // original item
      const originalItemNewWidth = target.style.width.replace('px', '');
      const originalItemNewHeight = target.style.height.replace('px', '');
      const originalItemCenter = getElementPosition(target);
      updateElementsPositionsBatch.update(
        db.doc(`boards/${window.currentBoardId}/elements/${getDocIdFromElementId(target.id)}`),
        {
          size: [originalItemNewWidth, originalItemNewHeight],
          center: originalItemCenter,
        }
      );

      pinnedElements.forEach(({ element }) => {
        // Compute the center
        const newWidth = element.style.width.replace('px', '');
        const newHeight = element.style.height.replace('px', '');
        const center = getElementPosition(element);

        updateElementsPositionsBatch.update(db.doc(element.getAttribute('docpath')), {
          size: [newWidth, newHeight],
          center,
        });
      });

      updateElementsPositionsBatch.commit();

      resetIsElementMoved();
    }
  }

  function handleDrag() {
    stopEdgeScroll();

    if (!$(target).hasClass('screenshareElement') && !$(target).hasClass('videoElement') && canDeleteContent()) {
      showTrashcan();
      trashcan.addEventListener('mouseenter', onTrashcanEnter);
      trashcan.addEventListener('mouseleave', onTrashcanLeave);
    }

    function onTrashcanEnter() {
      shouldDelete = true;
      $(target).fadeTo('fast', 0.3);
      $(target).animate({ transform: 'scale(0.1)' }, 200);
      trashcan.style.backgroundColor = 'rgba(255, 50, 10, 0.4)';
    }

    function onTrashcanLeave() {
      shouldDelete = false;
      $(target).fadeTo('fast', 1.0);
      $(target).animate({ transform: 'scale(1)' }, 200);
      trashcan.style.backgroundColor = 'rgba(255, 255, 255, 0.4)';
    }

    main.addEventListener('mouseup', onDragStop);
    main.addEventListener('mousemove', onDragMove);
    mainHammer.on('panend', onDragStop);
    mainHammer.on('panmove', onDragMove);
    mainHammer.on('panmove', onTouchMove);

    let didScroll = false;
    let didSignificantlyMove = false;

    const handler = handlerForElement(target);

    function onTouchMove(touchEvent) {
      if (document.elementFromPoint(touchEvent.srcEvent.clientX, touchEvent.srcEvent.clientY).id === 'trashcan') {
        onTrashcanEnter();
      } else {
        onTrashcanLeave();
      }
    }

    function onDragMove(dragMoveEvent) {
      if (dragMoveEvent.pointerType === 'mouse') {
        return;
      }

      if (!isTouchEvent) {
        dragMoveEvent.preventDefault();
        dragMoveEvent.stopPropagation();
      }

      if (
        dragMoveEvent.shiftKey ||
        dragMoveEvent.target.closest('.element-menu') ||
        dragMoveEvent.target.closest('.dont-drag-me')
      ) {
        return;
      }

      // set the element's new position

      const draggedElementClientX =
        isTouchEvent && dragMoveEvent.srcEvent ? dragMoveEvent.srcEvent.clientX : dragMoveEvent.clientX;
      const draggedElementClientY =
        isTouchEvent && dragMoveEvent.srcEvent ? dragMoveEvent.srcEvent.clientY : dragMoveEvent.clientY;
      let newX = startX + (draggedElementClientX - startCursorX) * pixRatio;
      let newY = startY + (draggedElementClientY - startCursorY) * pixRatio;

      const newDidSignificantlyMove =
        Math.abs(draggedElementClientX - startCursorX) > 30 || Math.abs(draggedElementClientY - startCursorY) > 30;
      if (!didSignificantlyMove && newDidSignificantlyMove && handler?.elementData?.isDeck) {
        duplicate(handler.elementId, {
          source: 'Deck',
          isSamePosition: true,
          elementData: { ...handler.elementData, disableInitialAnimation: true, isDeck: true },
        });
        db.doc(target.getAttribute('docPath')).update({
          isDeck: false,
          zIndex: window.getFrontZIndex() + 1,
        });
        didSignificantlyMove = true;
      }

      if (handler?.elementData?.isDeck) {
        return;
      }

      let width = parseFloat(target.style.width);
      let height = parseFloat(target.style.height);
      newX = checkGuides(guidelines.x, newX, target, false, 0);
      newX = checkGuides(guidelines.x, newX + width, target, false, 1) - width;
      newY = checkGuides(guidelines.y, newY, target, true, 0);
      newY = checkGuides(guidelines.y, newY + height, target, true, 1) - height;

      const nearbySpawnRect = findSpawn(draggedElementClientX, draggedElementClientY, target.id);
      let time = null;
      if (nearbySpawnRect) {
        [newX, newY, width, height] = nearbySpawnRect;
        time = 0.2;
        target.style.transition = `all ${time}s ease`;
        target.style.width = `${width}px`;
        target.style.height = `${height}px`;
      } else if (width !== startDragWidth || height !== startDragHeight) {
        // Restore size
        width = startDragWidth;
        target.style.width = `${width}px`;
        height = startDragHeight;
        target.style.height = `${height}px`;
        time = 0.2;
        target.style.transition = `all ${time}s ease`;
      } else {
        target.style.transition = null;
      }

      if (handler && handler.constructor.elementType === 'CameraElement') {
        const newPos = checkBarriers(
          { x: lastX, y: lastY, w: lastWidth, h: lastHeight },
          { x: newX, y: newY, w: width, h: height }
        );
        newX = newPos.x;
        newY = newPos.y;
      }

      isElementMoved = newX !== startX || newY !== startY || width !== startDragWidth || height !== startDragHeight;

      window.rtc.sendElementResized(target.id, newX, newY, width, height, time);

      lastX = newX;
      lastY = newY;
      lastWidth = width;
      lastHeight = height;
      const cursorCanvas = screenToCanvasCoords(draggedElementClientX, draggedElementClientY + 40);
      didScroll = didScroll || isOnScreen(target);

      if (didScroll && !getCurrentBoardIsUserCard()) {
        ensureRectVisible(cursorCanvas[0] - 20, cursorCanvas[1] - 40, 40, 60, (ofsX, ofsY) => {
          startX -= ofsX;
          startY -= ofsY;
          newX -= ofsX;
          newY -= ofsY;
          window.rtc.sendElementResized(target.id, newX, newY, width, height, null);
          applyElementTransformFromPartialData(target, { center: [newX, newY] });

          minimap.setNeedsUpdate();
        });
      }

      applyElementTransformFromPartialData(target, { center: [newX, newY] });

      // Stop delayed auto-write if we're messing with our own camera
      if (target.id === window.userCameraId) {
        window.userLocationUpdateTimer = null;
        stopKeyboardMoveLoop();
      }
      minimap.setNeedsUpdate();
    }

    async function onDragStop(dragStopEvent) {
      if (dragStopEvent.pointerType === 'mouse') {
        return;
      }

      async function saveDraggedElement(element, initiatorElementIds = []) {
        const newWidth = Number(element.style.width.replace('px', ''));
        const newHeight = Number(element.style.height.replace('px', ''));
        const center = getElementPosition(element);
        if (shouldDelete) {
          deleteElement(element.id.replace('element-', ''))
            .then(() => {
              trashcan.style.backgroundColor = 'rgba(255, 255, 255, 0.4)';
            })
            .catch((error) => {
              // The document probably doesn't exist.
              log.error('Error removing document: ', error);
            });
        } else if (firebase.auth().currentUser && (isElementMoved || initiatorElementIds.length)) {
          // Compute the center
          // TODO Usually newWidth and newHeight don't need to be computed and updated here.
          // But we're doing it because some local elements might have changed before being written

          // Update the element

          db.doc(element.getAttribute('docPath'))
            .update({
              size: [newWidth, newHeight],
              center,
            })
            .catch((error) => {
              // The document probably doesn't exist.
              log.error('Error updating document with new position: ', error);
            });

          const elementHandler = window.elementHandlers[getDocIdFromElementId(element.id)];
          if (elementHandler) {
            const { pinnedElements } = elementHandler;
            pinnedElements
              .map((doc) => ({ pinnedElement: document.getElementById(`element-${doc.docId}`) }))
              .forEach(({ pinnedElement }) => {
                if (pinnedElement && !initiatorElementIds.includes(pinnedElement.id)) {
                  saveDraggedElement(pinnedElement, [...initiatorElementIds, element.id]);
                }
              });
          }
        }
      }

      if (!isTouchEvent) {
        dragStopEvent.preventDefault();
        dragStopEvent.stopPropagation();
      }

      // stop moving when mouse button is released
      main.removeEventListener('mouseup', onDragStop);
      main.removeEventListener('mousemove', onDragMove);
      mainHammer.off('panend', onDragStop);
      mainHammer.off('panmove', onDragMove);
      mainHammer.off('panmove', onTouchMove);
      trashcan.removeEventListener('mouseenter', onTrashcanEnter);
      trashcan.removeEventListener('mouseleave', onTrashcanLeave);
      hideTrashcan();
      stopEdgeScroll();

      // Clear any guidelines
      clearAlignmentGuidelines();

      await applyElementTransformFromPartialData(target);

      await saveDraggedElement(target);

      resetIsElementMoved();
    }
  }

  function findSpawn(cursorX, cursorY, elementId) {
    const coords = screenToCanvasCoords(cursorX, cursorY);
    const handler = window.elementHandlers[elementId.replace('element-', '')];
    if (!handler) {
      return null;
    }

    const { elementType } = handler.constructor;
    let rect = null;

    Object.values(window.elementHandlers).forEach((h) => {
      if (h instanceof SpawnElement && h.supportedElementTypes.includes(elementType)) {
        const spawnEl = document.getElementById(`element-${h.elementId}`);
        if (spawnEl) {
          const position = getElementPosition(spawnEl);
          if (
            coords[0] > position[0] &&
            coords[1] > position[1] &&
            coords[0] < position[0] + parseFloat(spawnEl.style.width) &&
            coords[1] < position[1] + parseFloat(spawnEl.style.height)
          ) {
            rect = [position[0], position[1], parseFloat(spawnEl.style.width), parseFloat(spawnEl.style.height)];
          }
        } else {
          log.warn("Can't find spawn element for which we have a handler", h.elementId);
        }
      }
    });

    return rect;
  }

  function checkGuides(guideList, value, element, horizontal, guideIdx = 0) {
    let closest = null;
    let closestDistance = null;
    guideList.forEach((el) => {
      const distance = Math.abs(el[0] - value);
      if (distance < 5 && el[1] !== element.id) {
        if (closest == null || closestDistance > distance) {
          [closest] = el;
          closestDistance = distance;
        }
      }
    });

    const shouldRedraw = horizontal ? yGuideline[guideIdx] !== closest : xGuideline[guideIdx] !== closest;
    if (shouldRedraw) {
      if (horizontal) {
        yGuideline[guideIdx] = closest;
      } else {
        xGuideline[guideIdx] = closest;
      }
      if (!screenDrawingContext) {
        setupCanvas();
      }

      screenDrawingContext.clearRect(0, 0, canvas.width, canvas.height);
      drawScreenLines();
    }

    return closest || value;
  }

  function genGuidelines() {
    const xGuides = [];
    const yGuides = [];

    document.querySelectorAll('#elements .boardElement').forEach((item) => {
      const [x, y] = getElementPosition(item);
      const w = parseFloat(item.style.width);
      const h = parseFloat(item.style.height);
      xGuides.push([x, item.id]);
      xGuides.push([x + w, item.id]);
      yGuides.push([y, item.id]);
      yGuides.push([y + h, item.id]);
    });

    return { x: xGuides, y: yGuides };
  }
};

function showTrashcan() {
  trashcan.style.display = 'block';
}

function hideTrashcan() {
  trashcan.style.display = 'none';
}
