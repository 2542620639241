import { Board } from './boards';
import { Theme } from './theme';
import { UserProfile } from './user-profile';

interface TextMessage {
  text: string;
  fontColor: string;
  fontFamily: string;
  fontSize: string;
  messageColor: string;
}

interface StickerMessage {
  url: string;
  type: string;
}

interface ImageMessage {
  imageUrl: string;
  storagePath: string;
}

interface InviteMessage {
  roomId: string;
}

interface SystemMessage {
  text: string;
  metadata?: { onboardingMessage?: boolean; systemMsgType: string };
}

export type ChatMessage = TextMessage | StickerMessage | ImageMessage | InviteMessage | SystemMessage;

export interface Chat {
  id: string;
  chatId: string;
  createdAt: string;
  updatedAt: string;
  background?: {
    original?: string;
    thumbnail?: string;
  };
  title?: string;
  deletedAt?: string;
  groupName?: string;
  groupId?: string;
  unreadCount?: number;
  isDm?: boolean;
  isPublic?: boolean;
  theme?: Theme;
  isNotificationsOn?: boolean;
  role?: string;
  boards?: Board[];
}

export interface PublicChat extends Chat {
  memberCount: number;
  messages: ChatMessage[];
}

export interface ChatMember extends UserProfile {
  unreadCount?: number;
  lastReadMessageId?: string;
  groupId?: string;
  role?: ChatMemberRoles;
  isBanned?: boolean;
}

export enum ChatMemberRoles {
  Owner = 'owner',
  Admin = 'admin',
  Member = 'member',
}

export interface MessageResponse {
  messageId: string;
  chatId: string;
  userId: string;
  message?: string;
  fontFamily?: string;
  fontSize?: string;
  fontColor?: string;
  messageColor?: string;
  type: string;
  url?: string;
  imageUrl?: string;
  storagePath?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  linkSummary?: string;
  summary?: string;
}

export enum SystemMessages {
  JoinHub = 'has joined the hub',
  EnterRoom = 'is here!',
}

export const newGroupChatSystemMessage = 'created a new group';

// NOTE: these are old system messages, the copy may have changed since.
// Moving forward we should be checking for metadata.systemMsgType instead of string equality checks (see OnboardingSystemMsgTypes below)
export enum OnboardingSystemMessages {
  WELCOME = "Welcome to your first official Here Chat! We make it super easy to chat and hangout with friends in a fully customizable space that's totally YOU.",
  ROOM_PROMPT = 'From a Chat, you can go Live by jumping in a Room by clicking this icon: 📹',
  CUSTOMIZE_CHAT_PROMPT = 'Invite friends to the Chat and customize the theme here: 🎨',
  CUSTOMIZE_MESSAGE_PROMPT = 'And change your message style using this button: 💬',
  ADD_FRIEND_PROMPT = 'Add a friend to this chat now in the settings: ⚙️',
}
export const onboardingSystemMessages = Object.values(OnboardingSystemMessages);

export enum OnboardingSystemMsgTypes {
  ONBOARDING_WELCOME_MESSAGE = 'onboardingWelcome',
  ONBOARDING_ROOM_MESSAGE = 'onboardingRoom',
  ONBOARDING_THEME_MESSAGE = 'onboardingTheme',
  ONBOARDING_MSG_STYLE_MESSAGE = 'onboardingMsgStyle',
  ONBOARDING_ADD_FRIEND_MESSAGE = 'onboardingAddFriend',
  ONBOARDING_JOIN_LINK_PROMPT = 'onboardingJoinLink',
}
export const onboardingSystemMsgTypes = Object.values(OnboardingSystemMsgTypes);
