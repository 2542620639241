import firebase, { db } from '../firebase';
import { getSanitizedUrl } from '../util/string-util';
import { getMessageColor, getFontColor, getFontSize, getFontFamily } from '../util/profile-util';
import { Activities, throttleControl } from '../util/throttler';
import { isCurrentUser } from '../util/user-util';
import { track } from '../util/analytics-util';
import { messageTypes } from '../constants/chat-constants';
import { ADD_REACTION, CHAT_TYPE } from '../constants/analytics-events/dm-events';

const storage = firebase.storage();

/**
 *
 * Sends text message to the chat
 *
 * @param {string} messageText
 * @param {string} messagesCollectionPath
 * @returns {Promise}
 */
export function sendMessage(messageText, messagesCollectionPath) {
  let retVal = null;
  throttleControl(
    Activities.SendMessage,
    () => {
      const { currentUser } = firebase.auth();
      retVal = db.collection(messagesCollectionPath).add({
        creator: currentUser.uid,
        type: 'text',
        text: messageText,
        messageColor: getMessageColor(),
        fontColor: getFontColor(),
        fontSize: getFontSize(),
        fontFamily: getFontFamily(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    },
    () => {
      retVal = Promise.resolve();
    }
  );
  return retVal;
}

export function sendSticker(url, messagesCollectionPath, type = 'sticker') {
  let retVal = null;
  throttleControl(
    Activities.SendMessage,
    () => {
      const { currentUser } = firebase.auth();
      url = getSanitizedUrl(url);
      retVal = db.collection(messagesCollectionPath).add({
        creator: currentUser.uid,
        type: type || 'sticker',
        url,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    },
    () => {
      retVal = Promise.resolve();
    }
  );
  return retVal;
}

/**
 *
 * Sends image message to the chat
 *
 * @param {string} imageUrl
 * @param {string} storagePath
 * @param {string} messagesCollectionPath
 * @returns {Promise}
 */
export function sendImageMessage(imageUrl, storagePath, messagesCollectionPath) {
  let retVal = null;
  throttleControl(
    Activities.SendMessage,
    () => {
      const { currentUser } = firebase.auth();
      retVal = db.collection(messagesCollectionPath).add({
        creator: currentUser.uid,
        type: 'image',
        imageUrl,
        storagePath,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    },
    () => {
      retVal = Promise.resolve();
    }
  );
  return retVal;
}

export const sendOutsideReactionMessage = async (messagesCollectionPath, emoji) => {
  const { currentUser } = firebase.auth();
  await db.collection(messagesCollectionPath).add({
    type: messageTypes.OUTSIDE_REACTION,
    text: emoji,
    creator: currentUser.uid,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

/**
 *
 * Adds reaction to the message
 *
 * @param {string} messageId
 * @param {string} messagesCollectionPath
 * @param {string} reaction
 * @param {Array} prevEmojiReactions
 * @returns {Promise}
 */
export function addReaction({
  messageId,
  messagesCollectionPath,
  reaction,
  customReaction,
  emoteType,
  pack,
  prevEmojiReactions,
}) {
  const { currentUser } = firebase.auth();

  const reactionObj = {
    creator: currentUser.uid,
    creatorDisplayName: currentUser.displayName,
    ...(reaction && { reaction }),
    ...(customReaction && { customReaction }),
  };

  const chatType =
    messagesCollectionPath.substring(messagesCollectionPath.length - 8, messagesCollectionPath.length) === 'messages'
      ? CHAT_TYPE.CHAT_WIDGET
      : CHAT_TYPE.ROOM_FEED;

  // if reaction and reactionAuthor pair is unique
  if (reaction && !prevEmojiReactions.find((item) => item.creator === currentUser.uid && item.reaction === reaction)) {
    track(ADD_REACTION, { reaction, chatType });
    return db.doc(`${messagesCollectionPath}/${messageId}`).update({
      emojiReactions: firebase.firestore.FieldValue.arrayUnion(reactionObj),
    });
  }

  // if customReaction and customReactionAuthor pair is unique
  if (
    customReaction &&
    !prevEmojiReactions.find((item) => item.creator === currentUser.uid && item.customReaction === customReaction)
  ) {
    track(ADD_REACTION, { custom: customReaction, chatType, emoteType, pack });
    return db.doc(`${messagesCollectionPath}/${messageId}`).update({
      emojiReactions: firebase.firestore.FieldValue.arrayUnion(reactionObj),
    });
  }

  return Promise.resolve();
}

/**
 *
 * Remove reaction from the message
 *
 * @param {string} messageId
 * @param {string} messagesCollectionPath
 * @param {string} reaction
 * @param {Array} prevEmojiReactions
 * @returns {Promise}
 */
export function removeReaction({ messageId, messagesCollectionPath, reaction, customReaction, prevEmojiReactions }) {
  let emojiReactions = [];

  if (reaction) {
    emojiReactions = prevEmojiReactions.filter(
      (prevReaction) =>
        !isCurrentUser(prevReaction.creator) ||
        (isCurrentUser(prevReaction.creator) && prevReaction.reaction !== reaction)
    );
  }

  if (customReaction) {
    emojiReactions = prevEmojiReactions.filter(
      (prevReaction) =>
        !isCurrentUser(prevReaction.creator) ||
        (isCurrentUser(prevReaction.creator) && prevReaction.customReaction !== customReaction)
    );
  }

  if (emojiReactions.length === prevEmojiReactions.length - 1) {
    track('Remove Reaction', { reaction });
    return db.doc(`${messagesCollectionPath}/${messageId}`).update({
      emojiReactions,
    });
  }

  return Promise.resolve();
}

/**
 *
 * Deletes feed message from the firebase db
 *
 * @param {string} messageText
 * @param {string} messagesCollectionPath
 * @param {string?} storagePath
 * @returns {Promise}
 */
export async function deleteMessage(messageId, messagesCollectionPath, storagePath = null) {
  const deletedRef = await db.doc(`${messagesCollectionPath}/${messageId}`).delete();
  if (storagePath) {
    await storage.ref(storagePath).delete();
  }

  return deletedRef;
}
