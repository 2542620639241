import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SkinDecorator } from '../../../../store/chat-skinning/init-state';
import { oppositeSides } from '../constants';
import { selectChatSkinningDecorators } from '../../../../store/chat-skinning/selectors';

interface Props {
  children: ReactNode;
  decorator: SkinDecorator;
}

const SkinningDecoratorWrapper = ({ decorator, children }: Props) => {
  const defaultDecorators = useSelector(selectChatSkinningDecorators);
  // @ts-ignore
  decorator = {
    ...(decorator || {}),
    size: decorator?.size || defaultDecorators.find((d) => d?.name === decorator?.name)?.size,
  };

  return (
    <>
      {/* A decorator without size cannot be displayed correctly */}
      {decorator?.size ? <DecoratorImage decorator={decorator} /> : null}
      {children}
    </>
  );
};

export default SkinningDecoratorWrapper;

const DecoratorImage = styled.div<{ decorator: SkinDecorator }>`
  pointer-events: none;
  position: absolute;

  ${({ decorator }) => {
    const isHorizontal = decorator.anchor === 'top' || decorator.anchor === 'bottom';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = {
      backgroundImage: `url('${decorator.url}')`,
      backgroundRepeat: `repeat-${isHorizontal ? 'x' : 'y'}`,
    };

    if (decorator.contentType === 'inside') {
      result.top = '0';
      result.bottom = '0';
      result.left = '0';
      result.right = '0';
      result.backgroundSize = isHorizontal ? '100% auto' : 'auto 100%';
      result.backgroundPosition = decorator.anchor;
      if (decorator.inset) {
        result.backgroundClip = 'content-box';
        result.padding = `${decorator.inset.y}px ${decorator.inset.x}px`;
      }
    } else {
      result[oppositeSides[decorator.anchor]] = '100%';
      result[isHorizontal ? 'left' : 'top'] = 0;
      result[isHorizontal ? 'width' : 'height'] = `${100 * decorator.size?.scale}%`;
      result[isHorizontal ? 'height' : 'width'] = `${decorator.size[isHorizontal ? 'height' : 'width']}px`;
      result.transform = `scale(${1 / decorator.size?.scale}) ${
        decorator.translation ? `translate(${decorator.translation.x}px, ${decorator.translation.y}px)` : ''
      }`;
      result.transformOrigin = `${oppositeSides[decorator.anchor]} ${isHorizontal ? 'left' : 'top'}`;
    }

    return result;
  }}
`;
