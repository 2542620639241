import React from 'react';

// screens
import AuthTypeSelectionScreen from './AuthTypeSelectionScreen';
import CreateAccountScreen from './CreateAccountScreen';
import CreateUsernameScreen from './CreateUsernameScreen';
import EmailInputScreen from './EmailInputScreen';
import EmailSignInScreen from './EmailSignInScreen';
import ResetPasswordScreen from './ResetPasswordScreen';
import PasswordWasResetScreen from './PasswordWasResetScreen';
import FriendsSuggestionsScreen from './FriendsSuggestionsScreen';
import PhoneInputScreen from './PhoneInputScreen';
import VerifyPhoneCodeScreen from './VerifyPhoneCodeScreen';
import CreateUsernameAndDisplayNameScreen from './CreateUsernameAndDisplayNameScreen';

// constants
import { Screens } from '../constants';
import SchoolSelectionScreen from './SchoolSelectionScreen';

export const screensMap = {
  [Screens.AUTH_TYPE_SELECTION]: <AuthTypeSelectionScreen />,
  [Screens.CREATE_ACCOUNT]: <CreateAccountScreen />,
  [Screens.CREATE_USERNAME]: <CreateUsernameScreen />,
  [Screens.EMAIL_INPUT]: <EmailInputScreen />,
  [Screens.EMAIL_SIGN_IN]: <EmailSignInScreen />,
  [Screens.RESET_PASSWORD]: <ResetPasswordScreen />,
  [Screens.PASSWORD_WAS_RESET]: <PasswordWasResetScreen />,
  [Screens.FRIENDS_SUGGESTIONS]: <FriendsSuggestionsScreen />,
  [Screens.SCHOOL_SELECTION]: <SchoolSelectionScreen />,
  [Screens.PHONE_INPUT]: ({
    handleSendVerificationCode,
  }: {
    handleSendVerificationCode: (phoneNumber: string) => Promise<string>;
  }) => <PhoneInputScreen handleSendVerificationCode={handleSendVerificationCode} />,
  [Screens.PHONE_VERIFY_CODE]: ({
    handleSendVerificationCode,
  }: {
    handleSendVerificationCode: (phoneNumber: string) => Promise<string>;
  }) => <VerifyPhoneCodeScreen handleSendVerificationCode={handleSendVerificationCode} />,
  [Screens.CREATE_USERNAME_AND_NAME]: <CreateUsernameAndDisplayNameScreen />,
};
