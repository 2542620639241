import React from 'react';
import styled from 'styled-components';

import { setCurrentUserBackground } from '../../api/user-profiles-api';
import { uploadUserBackgroundPhoto } from '../../upload';
import log from '../../log';

// hooks
import { useBackgroundsData } from './useBackgroundsData';

// components
import { LoadingError, OptionImage, TabHeader, TabsHeader, Title, TryAgainButton, UploadInput } from './shared-styles';

// utils
import { track } from '../../util/analytics-util';
import { validateImageToUpload } from './util';

// constants
import { EDIT_PROFILE_PHOTO } from '../../constants/analytics-events/user-events';

interface BackgroundPickerProps {
  close: () => void;
  source: string;
}

const BackgroundPicker = ({ close, source }: BackgroundPickerProps) => {
  const {
    backgrounds: resultBackgroundOptions,
    loading: defaultBackgroundsLoading,
    error: defaultBackgroundsError,
    fetchBackgrounds,
  } = useBackgroundsData();

  const onImageClick = async (title: string, url: string) => {
    try {
      track(EDIT_PROFILE_PHOTO, { photo: 'background photo', source, pickerValue: title });
      await setCurrentUserBackground(url);
      close();
    } catch (error) {
      log.error(error);
    }
  };

  const onBackgroundUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    track(EDIT_PROFILE_PHOTO, { photo: 'background photo', source });

    try {
      const [file] = Array.from(e.target.files);
      if (file) {
        const isValid = validateImageToUpload(file);
        if (!isValid) {
          return;
        }

        await uploadUserBackgroundPhoto(file);
        close();
      }
    } catch (error) {
      log.error(error);
    }
  };

  return (
    <>
      <Title>Change Background</Title>

      <TabsHeader>
        <TabHeader isActive>Illustrations</TabHeader>
        <TabHeader>
          From computer
          <UploadInput id="upload-avatar-input" type="file" accept="image/*" onChange={onBackgroundUpload} />
        </TabHeader>
      </TabsHeader>

      {!defaultBackgroundsLoading && defaultBackgroundsError ? (
        <LoadingError>
          Error loading avatars. <TryAgainButton onClick={fetchBackgrounds}>Try again</TryAgainButton>
        </LoadingError>
      ) : null}
      <BackgroundOptionsList>
        {!defaultBackgroundsLoading &&
          !defaultBackgroundsError &&
          resultBackgroundOptions.map((backgroundOption) => (
            <BackgroundOptionImage
              key={backgroundOption.id}
              src={backgroundOption.url}
              height="120px"
              width="240px"
              alt="background option"
              onClick={() => onImageClick(backgroundOption.data?.title || backgroundOption.id, backgroundOption.url)}
            />
          ))}
      </BackgroundOptionsList>
    </>
  );
};

const BackgroundOptionsList = styled.div`
  margin-top: 33px;
  height: 390px;
  min-width: 660px;
  overflow-y: auto;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 640px) {
    min-width: 540px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const BackgroundOptionImage = styled(OptionImage)`
  height: 119px;
  border-radius: 10px;
`;

export default BackgroundPicker;
