/* eslint-disable @typescript-eslint/lines-between-class-members */
import { boardUsers } from '../presence/db';
import { updateChartData, refreshCharts, addTrackCheckData } from './stats-chart';
import sessionLog from '../session-log';
import log from '../log';
import CameraPublisher from './camera_publisher';
import { ScreenPublisher } from './screen_publisher';
import Subscriber, { RTCCodecStats } from './subscriber';
import { StreamType, SubscribeStatus, UserId } from './definitions/index.definitions';
import { RollingAverage } from './helpers/rolling-average';
import { DomSubscriberStats } from './stats/dom-subscriber-stats';
import { DomPublisherStats } from './stats/dom-publisher-stats';
import { DomStatsBase } from './stats/dom-stats-base';
import { trackInForeground as trackMetric } from '../util/analytics-util';
import { Feed } from './feed';
import { DistributionFieldsEnum } from '../definitions/lib.sessions';
import { RTCInboundRtpStreamStatsComplete } from './stats/definitions';
import { FeedTrack } from './feed-track';

const GATHER_STATS_INTERVAL = 10000;
const GATHER_STATS_INTERVAL_DISPLAYING_STATS = 3000;

interface RTCStatsWithTrackIdentifier extends RTCStats {
  mid: string;
  trackId?: string;
  trackIdentifier?: string;
}
interface RTCRtpTransceiverWithStopped extends RTCRtpTransceiver {
  stopped?: boolean;
}

// FIXME: Simplify this class by using and extending FeedCollection if necessary.
export class Stats {
  publisher: CameraPublisher = null;
  screenPublisher: ScreenPublisher = null;
  subscriber: Subscriber = null;
  previousBytesSent = 0;
  previousTimestamp: Date = null;
  previousTotalDecodeTime = 0;
  previousFramesDecoded = 0;
  screensharePreviousBytesSent = 0;
  screensharePreviousTimestamp: Date = null;
  _monitorInterval = GATHER_STATS_INTERVAL;
  statsTimer: number | undefined = undefined;
  lastPublisherGetStatsCallDurations = new RollingAverage(10);
  lastSubscriberGetStatsCallDurations = new RollingAverage(10);
  lastScreenShareGetStatsCallDurations = new RollingAverage(10);
  domStats: { [key in 'subscriber' | 'publisher']: { [key: UserId]: DomStatsBase } } = {
    subscriber: {},
    publisher: {},
  };

  get showStats() {
    return document.getElementById('webrtc-stats-box').style.display === 'block';
  }

  get monitorInterval() {
    return this._monitorInterval;
  }

  set monitorInterval(interval) {
    log.debug(`Stats: Setting Gathering Interval to ${interval}`);
    this._monitorInterval = interval;
  }

  // Debug function for showing all stats of a peer connection in the stats box
  // eslint-disable-next-line no-unused-vars
  async logStats(peerConnection: RTCPeerConnection) {
    const stats = await peerConnection.getStats();

    let statsOutput = '';

    stats.forEach((report) => {
      statsOutput += `
      <h2>Report: ${report.type}</h3>\n<strong>ID:</strong> ${report.id}<br>
      <strong>Timestamp:</strong> ${report.timestamp}<br>`;

      // Now the statistics for this report; we intentially drop the ones we
      // sorted to the top above

      Object.keys(report).forEach((statName) => {
        if (statName !== 'id' && statName !== 'timestamp' && statName !== 'type') {
          statsOutput += `<strong>${statName}:</strong> ${report[statName]}<br>`;
        }
      });
    });

    document.getElementById('webrtc-stats-box').innerHTML = statsOutput;
  }

  private async getLocalStats() {
    if (this.publisher?.publisherHandle?.webrtcStuff.pc) {
      const t0 = performance.now();
      const stats = await this.publisher?.publisherHandle.webrtcStuff.pc.getStats();
      if (stats && this.publisher?.userId) this.updateDomStats(this.publisher.userId, stats, 'publisher');

      stats.forEach((stat) => {
        if (stat.type === 'outbound-rtp') {
          if (stat.mediaType === 'video') {
            if (stat.mid && stat.mid !== this.publisher.videoMid) {
              return;
            }
            sessionLog.updateElapsed('camera', this.publisher.isVideoOn);
            this.publisher.videoOutboundRTPStats = stat;
            sessionLog.recordPublisherStats('camera', stat, this.publisher.iceRestartStats);
            if (this.publisher.isVideoOn) {
              sessionLog.recordDistributionBitrateField('camera', stat.ssrc, stat.timestamp, stat?.bytesSent);
            }
          }

          if (stat.mediaType === 'audio') {
            sessionLog.updateElapsed('mic', this.publisher.isAudioOn);
            this.publisher.audioOutboundRTPStats = stat;
            sessionLog.recordPublisherStats('mic', stat);
            if (this.publisher.isAudioOn) {
              sessionLog.recordDistributionBitrateField('mic', stat.ssrc, stat.timestamp, stat?.bytesSent);
            }
          }
        }

        if (stat.type === 'candidate-pair') {
          sessionLog.recordDistributionField('camera', DistributionFieldsEnum.RoundTrip, stat?.currentRoundTripTime);
        }
      });

      const t1 = performance.now();
      this.lastPublisherGetStatsCallDurations.push(t1 - t0);
    }

    if (this.screenPublisher?.publisherHandle?.webrtcStuff?.pc) {
      const sst0 = performance.now();
      try {
        const screenshareStats = await this.screenPublisher.publisherHandle.webrtcStuff.pc.getStats();
        if (screenshareStats && this.screenPublisher?.screenUsername) {
          this.updateDomStats(this.screenPublisher.screenUsername, screenshareStats, 'publisher');
        }

        screenshareStats.forEach((stat) => {
          if (stat.type === 'outbound-rtp' && stat.mediaType === 'video') {
            this.screenPublisher.videoOutboundRTPStats = stat;
            sessionLog.updateElapsed('screenshare', this.screenPublisher.isVideoOn);
            sessionLog.recordDistributionBitrateField('screenshare', stat.ssrc, stat.timestamp, stat?.bytesSent);
            sessionLog.recordPublisherStats('screenshare', stat);
          } else if (stat.type === 'outbound-rtp' && stat.mediaType === 'audio') {
            this.screenPublisher.audioOutboundRTPStats = stat;
            sessionLog.updateElapsed('screenshareMic', this.screenPublisher.isAudioOn);
            sessionLog.recordDistributionBitrateField('screenshareMic', stat.ssrc, stat.timestamp, stat?.bytesSent);
            sessionLog.recordPublisherStats('screenshareMic', stat);
          }
          if (stat.type === 'candidate-pair') {
            sessionLog.recordDistributionField('session', DistributionFieldsEnum.RoundTrip, stat?.currentRoundTripTime);
          }
        });
      } catch (error) {
        log.error(`Stats: getLocalStats error ${error}`);
      }

      const sst1 = performance.now();
      this.lastScreenShareGetStatsCallDurations.push(sst1 - sst0);
    }
  }

  private async gatherSubscriberStats() {
    const subscriberPC = this.subscriber?.subscriberHandle?.webrtcStuff?.pc;

    if (subscriberPC?.connectionState !== 'connected') {
      return;
    }

    const t0 = performance.now();
    const stats = await this.subscriber?.subscriberHandle.webrtcStuff.pc.getStats(null);
    const transceivers = subscriberPC?.getTransceivers();

    stats.forEach((stat: RTCInboundRtpStreamStatsComplete) => {
      if (stat?.type === 'codec') {
        this.subscriber.codecStats[stat.id] = stat as unknown as RTCCodecStats;
      } else if (stat?.type === 'candidate-pair') {
        this.subscriber?.feedCollection?.feeds?.forEach((feed) => {
          // FIXME: Need to match
          feed.candidatePairReport = stat as unknown as RTCIceCandidatePairStats;
        });
      } else if (stat?.type === 'inbound-rtp') {
        let feed: Feed = null;
        const { mid } = stat;

        let feedTrack: FeedTrack;
        if (mid) {
          feedTrack = this.subscriber?.feedCollection?.feedTracks.find((ft) => mid && ft?.uniqueMidId === mid);
        } else if (stat.trackIdentifier) {
          feedTrack = this.subscriber?.feedCollection?.feedTracks.find((ft) => ft?.track?.id === stat.trackIdentifier);
        }

        feed = this.subscriber?.feedCollection?.feeds.find((f) => f.id === feedTrack?.feedId);
        if (!feed || !feedTrack) {
          log.warn(`Stats: Gather Subscriber failed, missing elements feed id: ${feed?.id} mid: ${mid}... cancel.`);
          return;
        }
        try {
          const transceiver = transceivers.find((t) => t.mid === mid);
          const track = transceiver?.sender?.track;

          if (!feedTrack && track) {
            const { stopped, direction, currentDirection } = transceiver as RTCRtpTransceiverWithStopped;
            log.error(
              `Stats: Cannot find mid: ${mid}, track kind: ${track?.kind} muted: ${track?.muted} enabled: ${track?.enabled},
                transceiver stopped: ${stopped} direction: ${direction} currentDir: ${currentDirection}`
            );
          }

          feedTrack.inboundRTPStats = stat;
        } catch (error) {
          log.error(
            `Stats: Error gathering 'inbound-rtp': Cannot find mid: ${mid}, user: ${feed.userId}, error: ${error.message}`
          );
          trackMetric('SFU Stats Error Gathering Inbound RTP', { error: error.name, message: error.message });
        }
      }
    });

    const t1 = performance.now();
    this.lastSubscriberGetStatsCallDurations.push(t1 - t0);
  }

  private updateSession(
    userId: UserId,
    audioRTP: RTCInboundRtpStreamStatsComplete,
    videoRTP: RTCInboundRtpStreamStatsComplete
  ) {
    if (!audioRTP && !videoRTP) {
      log.debug('No A/V RTP stats for user, bailing on reporting: ', userId);
      return;
    }

    try {
      if (videoRTP && audioRTP && videoRTP.estimatedPlayoutTimestamp && audioRTP.estimatedPlayoutTimestamp) {
        sessionLog.recordDistributionField(
          'streams',
          DistributionFieldsEnum.AvDrift,
          Math.abs(videoRTP.estimatedPlayoutTimestamp - audioRTP.estimatedPlayoutTimestamp),
          userId
        );
      }
    } catch (e) {
      trackMetric('SFU Stats Processing Error', {
        type: 'recordDistributionField',
        metric: 'streams.avDrift',
        error: e.name,
        message: e.message,
      });
      log.error(`Stats: Error ${e}`);
    }

    if (videoRTP) {
      try {
        const { totalDecodeTime, framesDecoded } = videoRTP;
        // Average time to decode a frame since the last time we logged the metric in microseconds
        sessionLog.recordDistributionField(
          'streams',
          DistributionFieldsEnum.FrameDecodeTime,
          Math.abs(
            ((totalDecodeTime - this.previousTotalDecodeTime) / (framesDecoded - this.previousFramesDecoded)) * 1000
          ),
          userId
        );

        this.previousTotalDecodeTime = totalDecodeTime;
        this.previousFramesDecoded = framesDecoded;
      } catch (e) {
        trackMetric('SFU Stats Processing Error', {
          type: 'recordDistributionField',
          metric: 'streams.frameDecodeTime',
          error: e.name,
          message: e.message,
        });
        log.error(`Stats: Error ${e}`);
      }

      try {
        const { jitter } = videoRTP;
        sessionLog.recordDistributionField('streams', DistributionFieldsEnum.Jitter, jitter, userId);
      } catch (e) {
        trackMetric('SFU Stats Processing Error', {
          type: 'recordDistributionField',
          metric: 'streams.jitter',
          error: e.name,
          message: e.message,
        });
        log.error(`Stats: Error ${e}`);
      }

      try {
        sessionLog.recordRemoteStreamStats(userId, audioRTP, videoRTP);
      } catch (e) {
        trackMetric('SFU Stats Processing Error', {
          type: 'recordRemoteStreamStats',
          audioRTP: !!audioRTP,
          videoRTP: !!videoRTP,
          error: e.name,
          message: e.message,
        });
        log.error(`Stats: Error ${e}`);
      }
    }
  }

  private async calcStats() {
    await this.getLocalStats();
    await this.gatherSubscriberStats();

    if (this.showStats) {
      const statsBox = document.getElementById('webrtc-stats-box');
      statsBox.innerHTML = '';
      addTrackCheckData(this.subscriber.feedCollection.activeStreams);
    }

    this.subscriber?.feedCollection?.feeds.forEach((feed) => {
      const { audioRTPStat: audioRTP, videoRTPStat: videoRTP, candidatePairReport } = feed;

      this.updateChartStats(feed, audioRTP, videoRTP, candidatePairReport);

      this.updateSession(feed.userId, audioRTP, videoRTP);

      this.updateDomStats(feed.userId, [audioRTP, videoRTP, candidatePairReport], 'subscriber');
    });

    if (this.showStats) {
      refreshCharts();
    }
  }

  private updateChartStats(
    feed: Feed,
    audioRTP: RTCInboundRtpStreamStatsComplete,
    videoRTP: RTCInboundRtpStreamStatsComplete,
    candidatePairReport: RTCIceCandidatePairStats
  ) {
    if (this.showStats && (audioRTP || videoRTP)) {
      updateChartData({
        user: feed.userId,
        roundTripTime: candidatePairReport.currentRoundTripTime,
        audioJitter: audioRTP?.jitter,
        videoJitter: videoRTP?.jitter,
        trackSync:
          videoRTP && audioRTP ? videoRTP.estimatedPlayoutTimestamp - audioRTP.estimatedPlayoutTimestamp : null,
        firCount: videoRTP?.firCount,
        pliCount: videoRTP?.pliCount,
        nackCount: videoRTP?.nackCount,
      });
    }
  }

  setMonitorInterval(interval: number) {
    this.monitorInterval = interval;
    this.startMonitor();
  }

  startMonitor() {
    this.stopMonitor();
    this.calcStats();
    this.statsTimer = window.setInterval(this.calcStats.bind(this), this.monitorInterval);
  }

  stopMonitor() {
    if (this.statsTimer) {
      clearInterval(this.statsTimer);
    }
  }

  toggleDOMRtcStats(userId: UserId, isScreenShare = false) {
    if (this.publisher?.userId === userId || this.screenPublisher?.userId === userId) {
      this.toggleDOMStats('publisher', userId, isScreenShare);
    } else if (this.subscriber && Object.keys(this.subscriber.activeStreams).includes(userId)) {
      this.toggleDOMStats('subscriber', userId, isScreenShare);
    } else {
      log.warn(`Stats: Cannot toggleDOMRtcStats, is ${userId} publishing Audio or Video?`);
    }
  }

  private toggleDOMStats(role: 'subscriber' | 'publisher', userId: UserId, isScreenShare = false) {
    userId = isScreenShare ? `screen-${userId}` : userId;

    const dict = this.domStats[role][userId];

    if (dict) {
      log.debug(`Stats: Removing DOM Stats for user: ${userId}`);
      dict.remove();
      this.domStats[role][userId] = null;
      if (Object.keys(this.domStats[role]).filter((uid) => this.domStats[role][uid] !== null).length === 0) {
        this.monitorInterval = GATHER_STATS_INTERVAL;
      }
    } else {
      this.monitorInterval = GATHER_STATS_INTERVAL_DISPLAYING_STATS;
      log.debug(`Stats: Adding DOM Stats for user: ${userId}`);
      const uid = userId.match(/screen-/) ? userId.replace('screen-', '') : userId;
      const username = (boardUsers as { [key: string]: { [key: UserId]: { name: string } } })[window.currentBoardId][
        uid
      ].name;
      if (role === 'publisher') {
        const pub = userId.match(/screen-/) ? this.screenPublisher : this.publisher;
        this.domStats[role][userId] = new DomPublisherStats(userId, username, pub);
      } else {
        this.domStats[role][userId] = new DomSubscriberStats(userId, username, this.subscriber);
      }
    }
  }

  private updateDomStats(userId: string, allStats: RTCStatsReport | RTCStats[], role: 'subscriber' | 'publisher') {
    const stats: RTCStats[] = [];
    const domStat = this.domStats[role][userId];

    if (!domStat?.cameraElement) {
      if (domStat) {
        domStat.remove();
        log.debug(`Stats: Removing DOM Stat for user id: ${userId}`);
      }
      return;
    }

    if (!allStats) {
      log.error('Stats: updateDomStats error allStats is undefined');
      return;
    }

    allStats.forEach((stat: RTCStatsWithTrackIdentifier) => {
      try {
        if (stat?.type === 'candidate-pair') {
          stats.push(stat);
        } else if (stat?.type === 'outbound-rtp') {
          stats.push(stat);
        } else if (stat?.type === 'inbound-rtp' && this.subscriber) {
          const mid = stat.mid || stat.trackIdentifier.replace('janus', '');
          const feedTrack = this.subscriber.feedCollection.feedTracks.find((ft) => ft.uniqueMidId === mid);
          const feed = this.subscriber.feedCollection.feeds.find(
            (f) => f.id === feedTrack?.feedId && f.userId === userId
          );
          if (feed) {
            stats.push(stat);
          }
        }
      } catch (error) {
        log.error(`Stats: Cannot initialize DOM Camera Stats, error: ${error.message}`);
      }
    });

    domStat?.updateStats(stats);
  }

  logGetStatsDuration() {
    const subscribedVideoTracksQty = this.subscriber?.feedCollection?.feedTracks?.filter(
      (f) => f.type === StreamType.Video && f.status === SubscribeStatus.Subscribed
    )?.length;
    const subscribedAudioTracksQty = this.subscriber?.feedCollection?.feedTracks?.filter(
      (f) => f.type === StreamType.Audio && f.status === SubscribeStatus.Subscribed
    )?.length;

    /*
    log.debug(
      `Stats: Durations publisher: ${this.lastPublisherGetStatsCallDurations.mean}, subscriber: ${this.lastSubscriberGetStatsCallDurations.mean}, screenshare: ${this.lastScreenShareGetStatsCallDurations.mean} session log flush: ${sessionLog.lastFlushDurations.mean}`
    );
    */

    trackMetric('SFU Get Stats Durations Avg', {
      publisher: this.lastPublisherGetStatsCallDurations.mean,
      subscriber: this.lastSubscriberGetStatsCallDurations.mean,
      screenshare: this.lastScreenShareGetStatsCallDurations.mean,
      sessionLog: sessionLog.lastFlushDurations.mean,
      isAudioOn: this.publisher?.isAudioOn,
      isVideoOn: this.publisher?.isVideoOn,
      audioTracksQty: subscribedAudioTracksQty,
      videoTracksQty: subscribedVideoTracksQty,
    });
  }
}
