import React, { useCallback, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import ColorSelect from '../../components/ColorSelect';
import { BoardElementControllerContext } from '../../common/contexts.ts';
import { ColorBlock, ToolbarButton } from './shared-styles';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

import FillIcon from '../../../../assets/icons/fill.svg';

const BackgroundPicker = () => {
  const { elementData, patchDbData, patchLiveData } = useContext(BoardElementControllerContext);

  const onBackgroundChange = useCallback(
    (backgroundColor, { isInputComplete }) => {
      const update = isInputComplete ? patchDbData : patchLiveData;
      update({ backgroundColor });
    },
    [patchDbData, patchLiveData]
  );

  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef(null);
  useOnClickOutside(
    containerRef,
    useCallback(() => setIsOpened(false), [])
  );

  return (
    <div ref={containerRef}>
      <Popover.Root open={isOpened}>
        <Popover.Trigger asChild>
          <ToolbarButton className="rdw-option-wrapper" onClick={useCallback(() => setIsOpened(!isOpened), [isOpened])}>
            <FillIcon />
            <ColorBlock background={elementData.backgroundColor} />
          </ToolbarButton>
        </Popover.Trigger>
        <Content className="prevent-on-click-outside">
          <ColorSelect
            onChange={onBackgroundChange}
            value={elementData.backgroundColor || 'rgba(51, 51, 51, 0.733)'}
            hasAlpha
            shouldFireIntermediateValues
          />
        </Content>
      </Popover.Root>
    </div>
  );
};

export default BackgroundPicker;

const Content = styled(Popover.Content)`
  &:focus-visible {
    outline: none;
  }
`;
