import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import { chatButtonMixin } from '../shared-styles';

import KaomojiIcon from '../../../../../assets/icons/kawaii.svg';
import KaomojiPicker from '../../../kaomoji/KaomojiPicker';
import { svgColorMixin } from '../../../mixins';

interface Props {
  side?: 'right' | 'top';
  onSelectKaomoji: (message: string, category: string) => void;
  onMenuOpen?: () => void;
}

const KaomojiPickerButton = ({ onSelectKaomoji, onMenuOpen, side }: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleSelectKaomoji = useCallback(
    (content, category) => {
      setPopoverOpen(false);
      onSelectKaomoji(content, category);
    },
    [onSelectKaomoji]
  );

  const onOpenChange = useCallback(
    (value) => {
      setPopoverOpen(value);
      if (value && onMenuOpen) {
        onMenuOpen();
      }
    },
    [onMenuOpen]
  );

  return (
    <Popover.Root open={popoverOpen} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>
        <Button>
          <KaomojiIcon />
        </Button>
      </Popover.Trigger>

      <Popover.Content side={side} className="prevent-on-click-outside">
        <KaomojiPicker onSelectKaomoji={handleSelectKaomoji} />
      </Popover.Content>
    </Popover.Root>
  );
};

export default KaomojiPickerButton;

KaomojiPickerButton.defaultProps = {
  side: 'top',
};

const Button = styled.button`
  ${chatButtonMixin()}
  ${svgColorMixin('var(--primary-foreground, white)')}
`;
