import { onSnapshot, offSnapshot } from '../../firestore-watcher';

import template from './avatar.html';
import './avatar.less';
import { scrollToElement } from '../../viewport';
import { getUserAvatarColors } from '../../util/user-util';

export default class Avatar extends HTMLElement {
  constructor() {
    super();
    this.onUserChange = this.onUserChange.bind(this);
    this.scrollToVideo = this.scrollToVideo.bind(this);
  }

  scrollToVideo() {
    const userId = this.getAttribute('userid');
    const userVideoContainer = document.getElementById(`video-container-${userId}`);
    if (userVideoContainer) {
      const userVideoElement = userVideoContainer.closest('.boardElement');
      scrollToElement(userVideoElement);
    }
  }

  onUserChange(user) {
    this.installColor(user);

    if (!user) {
      // We don't have user profiles for old users, so just in case we have this attribute.
      const fallbackName = this.getAttribute('fallbackName');
      if (fallbackName) {
        this.showInitial({ displayName: fallbackName });
      } else {
        this.style.display = 'none';
      }
      return;
    }

    if (this.showProcessing && user.avatar && user.avatar.isProcessing) {
      this.processingElement.style.display = 'flex';
    } else {
      this.processingElement.style.display = 'none';
    }

    const avatarImage = user.avatar ? user.avatar.thumbnail || user.avatar.original : null;

    if (user.isAnonymous || (user.avatar && avatarImage)) {
      this.imageElement.style.display = 'inline';
      this.imageElement.src = user.isAnonymous ? '/images/icons/anonymous.svg' : avatarImage;
      this.imageElement.alt = `${user.displayName}'s avatar`;
      this.initialElement.style.display = 'none';
    } else {
      this.showInitial(user);
    }
  }

  connectedCallback() {
    this.innerHTML = template;
    this.processingElement = this.querySelector('.processing');
    this.imageElement = this.querySelector('.image');
    this.initialElement = this.querySelector('.initial');
    this.showProcessing = this.getAttribute('showProcessing');
    const userId = this.getAttribute('userId');

    if (this.classList.contains('color-ring')) {
      this.installColor();
    }

    onSnapshot(`/userProfiles/${userId}`, this.onUserChange);
  }

  disconnectedCallback() {
    const userId = this.getAttribute('userId');
    offSnapshot(`/userProfiles/${userId}`, this.onUserChange);
  }

  showInitial(user) {
    if (!user?.displayName?.length) return;

    const [initial] = user.displayName;
    this.querySelector('.initial').style.display = 'inline';
    this.initialElement.innerHTML = initial;
    this.imageElement.style.display = 'none';
    if (user.profileTheme?.primaryFont) {
      this.initialElement.style.fontFamily = `${user.profileTheme?.primaryFont}, Inter, sans-serif`;
    }
  }

  installColor(userData) {
    const { background, foreground } = getUserAvatarColors(userData);
    const avatarElement = this.querySelector('.avatar');
    avatarElement.style.background = background;
    avatarElement.style.color = foreground;
  }
}
window.customElements.define('here-avatar', Avatar);
