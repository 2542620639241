import UIkit from 'uikit';
import { confirmAlert } from 'react-confirm-alert';
import { deleteField } from 'firebase/firestore';
import firebase, { db } from '../../../firebase';
import bus, { roomSettingsUpdated } from '../../../event-bus';
import { disableViewersMode } from '../../../room';
import template from './admin.html';
import './admin.less';
import { getRoomId } from '../../../util';
import { checkUrlAlias, maxRoomNameLength, ROOM_URL_ALIAS_VALID_MESSAGE } from '../../../util/url-alias-validator';
import { onDiscordIntegrationLinkClick } from '../../../util/external-apps-util';
import { setVoiceControlEnabled } from '../../../sfu/voice-control';
import { isManageAllContentAllowed } from '../../../roles-management';
import { addSystemMessage } from '../../../message-util';
import log from '../../../log';
import { lockEveryElement } from '../../../util/room-util';
import { unpinElement } from '../../../util/pinned-util';
import { browserName } from '../../../util/browser-util';
import { track } from '../../../util/analytics-util';
import { CopyInviteLinkContexts, COPY_INVITE_LINK } from '../../../constants/analytics-events/rooms-events';
import {
  DISCORD_INTEGRATION_LINK_SOURCES,
  CLICKED_ADD_DISCORD_INTEGRATION_LINK,
  CLICKED_UPDATE_DISCORD_INTEGRATION_LINK,
  CLICKED_REMOVE_DISCORD_INTEGRATION,
  DISCORD_INTEGRATION_REMOVED_SUCCESSFULLY,
  DISCORD_INTEGRATION_REMOVED_FAILED,
} from '../../../constants/analytics-events/discord-events.ts';
import { checkIsElectron } from '../../../util/platform-util';

let checkUrlInputDelayTimer;

const inputDebounceTimeout = 1000;

class RoomAdmin extends HTMLElement {
  async connectedCallback() {
    this.innerHTML = template;

    // binds
    this.onChangeMembersCanAddContent = this.onChangeMembersCanAddContent.bind(this);
    this.onChangeMembersCanUseCam = this.onChangeMembersCanUseCam.bind(this);
    this.onChangeMembersCanUseMic = this.onChangeMembersCanUseMic.bind(this);

    // checkboxes
    this.membersCanAddContentCheckbox = this.querySelector('#members-can-add-content-checkbox');
    this.membersCanTurnOnCam = this.querySelector('#members-can-use-cam-checkbox');
    this.membersCanTurnOnMic = this.querySelector('#members-can-use-mic-checkbox');

    this.querySelector('#board-join-mode-checkbox').addEventListener('change', this.onChangeJoinMode.bind(this));
    this.membersCanAddContentCheckbox.addEventListener('change', this.onChangeMembersCanAddContent);
    this.membersCanTurnOnCam.addEventListener('change', this.onChangeMembersCanUseCam);
    this.membersCanTurnOnMic.addEventListener('change', this.onChangeMembersCanUseMic);
    this.querySelector('#company-tag-checkbox').addEventListener('change', this.onChangeTagMode.bind(this));
    this.querySelector('.allow-anonymous-viewers-checkbox').addEventListener(
      'change',
      this.onChangeAnonViewersMode.bind(this)
    );
    this.querySelector('.broadcast-section .end-button').addEventListener('click', disableViewersMode);
    this.querySelector('#room-name-input').addEventListener('keyup', this.onRoomNameInput.bind(this));

    this.querySelector('#lock-all-content-button').addEventListener('click', this.lockAllContent.bind(this));
    this.querySelector('#unlock-all-content-button').addEventListener('click', this.unlockAllContent.bind(this));
    this.querySelector('#delete-all-content-button').addEventListener('click', this.deleteAllContent.bind(this));

    document.addEventListener('mouseup', this.onDocumentMouseUpHandler);
  }

  disconnectedCallback() {
    document.removeEventListener('mouseup', this.onDocumentMouseUpHandler);
  }

  // This is not a perfect way to do it, but this file is probably gonna be removed soon,
  // so leaving this method almost untouched. But I had to move to a separate method to be able
  // to unsubscribe from it (and also not adding a new listener on each room info update).5
  // Note than `this` refers to `window.document` element
  onDocumentMouseUpHandler(e) {
    const elRoomUrlInput = this.querySelector('.update-url-input');
    const elRoomSettings = this.querySelector('.room-settings');
    // TODO: `elRoomSettings` is always gonna be null, but I'm not sure what is going on,
    // added conditional chaining for now, need to figure out what is it for.
    if (
      (!elRoomUrlInput.disabled || elRoomSettings?.parentNode === e.target) &&
      e.target !== this.querySelector('.update-url-button') &&
      e.target !== this.querySelector('.update-url-input')
    ) {
      window.getSelection().removeAllRanges();
      const elChangeUrlButton = this.querySelector('.change-url-button');
      const elUpdateUrlButton = this.querySelector('.update-url-button');
      const elCopyButton = this.querySelector('.update-url-copy');
      const elError = this.querySelector('.update-url-error');
      const elSuccess = this.querySelector('.update-url-success');
      const elAvailableIcon = this.querySelector('.update-url-available-icon');
      const elUnavailableIcon = this.querySelector('.update-url-unavailable-icon');
      elRoomUrlInput.disabled = true;
      elRoomUrlInput.classList.add('gray-text');
      elChangeUrlButton.style.display = 'block';
      elCopyButton.style.display = 'block';
      elUpdateUrlButton.style.display = 'none';
      elError.style.display = 'none';
      elSuccess.style.display = 'none';
      const s = 'here.fm/';
      const currentUrl = getRoomId();
      const urlLocation = currentUrl.substr(currentUrl.lastIndexOf('/') + 1, currentUrl.length);
      elRoomUrlInput.value = s + urlLocation;
      elAvailableIcon.style.display = 'none';
      elUnavailableIcon.style.display = 'none';
    }
  }

  onRoomNameInput(e) {
    const { value } = e.target;
    if (this.roomNameTimeout) {
      window.clearTimeout(this.roomNameTimeout);
    }

    const elError = this.querySelector('.update-room-name-error');

    this.roomNameTimeout = window.setTimeout(() => {
      if (!value.trim()) {
        elError.innerHTML = 'Your room name cannot be empty';
      } else if (value.length >= maxRoomNameLength) {
        elError.innerHTML = 'Your room name is too long - please enter a new one';
      } else {
        elError.innerHTML = '';
        db.collection('boards').doc(window.currentBoardId).update({ title: value });
        bus.dispatch(roomSettingsUpdated);
      }
    }, inputDebounceTimeout);
  }

  updateVoiceControls(isEnabled) {
    db.doc(`boards/${window.currentBoardId}`).update({ voiceControlEnabled: isEnabled });
  }

  updateQuietMode(isEnabled) {
    db.doc(`boards/${window.currentBoardId}`).update({ quietMode: isEnabled });
    track('Change Quiet Mode', { isQuiet: isEnabled });
  }

  openUpdateUrl() {
    const elChangeUrlButton = this.querySelector('.change-url-button');
    const elUpdateUrlButton = this.querySelector('.update-url-button');
    const elUpdateUrlInput = this.querySelector('.update-url-input');
    const elCopyButton = this.querySelector('.update-url-copy');
    elCopyButton.style.display = 'none';
    elChangeUrlButton.style.display = 'none';
    elUpdateUrlButton.style.display = 'block';
    elUpdateUrlInput.disabled = false;
    elUpdateUrlInput.classList.remove('gray-text');
    elUpdateUrlInput.focus();
    const elAvailableIcon = this.querySelector('.update-url-available-icon');
    const elUnavailableIcon = this.querySelector('.update-url-unavailable-icon');
    elAvailableIcon.style.display = 'none';
    elUnavailableIcon.style.display = 'none';
  }

  lockAllContent() {
    if (isManageAllContentAllowed()) {
      UIkit.modal
        .confirm('Are you sure you want to lock all element positions in this room?')
        .then(() => {
          // get here only in case of confirmation
          lockEveryElement();
        })
        .catch(() => {
          log.warn('Lock All Canceled');
        });
    }
  }

  unlockAllContent() {
    if (isManageAllContentAllowed()) {
      UIkit.modal
        .confirm('Are you sure you want to unlock all element positions in this room?')
        .then(() => {
          // get here only in case of confirmation
          track('Unlock All');

          return db
            .runTransaction((transaction) =>
              Promise.all(
                Object.keys(window.elementHandlers).map(async (handlerId) => {
                  const { elementType } = window.elementHandlers[handlerId].constructor;
                  if (elementType !== 'CameraElement' && elementType !== 'ScreenshareElement') {
                    await unpinElement(handlerId);
                    transaction.update(db.doc(`boards/${window.currentBoardId}/elements/${handlerId}`), {
                      locked: false,
                    });
                  }
                })
              )
            )
            .then(() => {
              addSystemMessage('unlocked everything in the room');
            })
            .catch((e) => {
              log.error('Unlocking all failed', e);
            });
        })
        .catch(() => {
          log.warn('Unlock All Canceled');
        });
    }
  }

  deleteAllContent() {
    if (isManageAllContentAllowed()) {
      UIkit.modal
        .confirm("Are you sure you want to delete all elements from this room? This can't be undone.")
        .then(() => {
          // get here only in case of confirmation
          track('Delete All');

          db.runTransaction(async (transaction) => {
            Object.keys(window.elementHandlers).forEach((handlerId) => {
              const { elementType } = window.elementHandlers[handlerId].constructor;
              if (elementType !== 'CameraElement' && elementType !== 'ScreenshareElement') {
                transaction.delete(db.doc(`boards/${window.currentBoardId}/elements/${handlerId}`));
              }
            });
          })
            .then(() => {
              addSystemMessage('cleared the room');
            })
            .catch((e) => {
              log.error('Deleting all failed', e);
            });
        })
        .catch(() => {
          log.warn('Delete All Canceled');
        });
    }
  }

  async updateRoomUrl(newUrl) {
    let newName = newUrl.substring(8, newUrl.length);
    newName = newName.toLowerCase();

    const currentUrl = window.location.href;
    if (currentUrl.substring(currentUrl.lastIndexOf('/') + 1, currentUrl.length).toLowerCase() === newName) {
      this.hideUpdateUrl();
      const elErrorText = this.querySelector('.update-url-error');
      elErrorText.style.display = 'none';
      return;
    }
    this.querySelector('.update-url-error').style.display = 'none';
    const elSuccessText = this.querySelector('.update-url-success');
    elSuccessText.style.display = 'none';

    const urlAliasErrorMessage = await checkUrlAlias(newName);
    if (!newName || urlAliasErrorMessage) {
      this.showUpdateRoomUrlError(urlAliasErrorMessage);
      return;
    }

    await db.collection('boards').doc(window.currentBoardId).set(
      {
        urlAlias: newName,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
    elSuccessText.innerHTML = `Success! You can find this room at here.fm/${newName}`;
    elSuccessText.style.display = 'block';
    window.history.replaceState('string', 'Title', `/${newName}`);
    this.hideUpdateUrl();
    const elBoardShareUrls = document.querySelectorAll('.board-share-url');
    elBoardShareUrls.forEach((el) => {
      el.value = `here.fm/${newName}`;
    });
    track('Room URL updated successfully');
  }

  hideUpdateUrl() {
    window.getSelection().removeAllRanges();
    const elRoomUrlInput = this.querySelector('.update-url-input');
    const elAvailableIcon = this.querySelector('.update-url-available-icon');
    const elUnavailableIcon = this.querySelector('.update-url-unavailable-icon');
    const elChangeUrlButton = this.querySelector('.change-url-button');
    const elUpdateUrlButton = this.querySelector('.update-url-button');
    const elCopyButton = this.querySelector('.update-url-copy');
    elRoomUrlInput.disabled = true;
    elRoomUrlInput.classList.add('gray-text');
    elChangeUrlButton.style.display = 'block';
    elUpdateUrlButton.style.display = 'none';
    elCopyButton.style.display = 'block';
    elAvailableIcon.style.display = 'none';
    elUnavailableIcon.style.display = 'none';
  }

  showUpdateRoomUrlError(error) {
    const el = this.querySelector('.update-url-error');
    el.innerHTML = error;
    el.style.display = 'block';
  }

  onBoardUpdate(board) {
    if (board.urlAlias !== null && board.urlAlias !== undefined)
      window.history.replaceState('string', 'Title', `/${board.urlAlias}`);

    this.querySelector('#room-name-input').value = board.title;

    const updateUrlInput = this.querySelector('.update-url-input');
    updateUrlInput.addEventListener('keyup', function (e) {
      const s = 'here.fm/';
      if (e.target.selectionStart < s.length) e.target.selectionStart = s.length;
      const valid = this.value.substring(0, s.length) === s; // check if text starts with 'here.fm/'

      if (!valid) this.value = s;
    });
    updateUrlInput.addEventListener('mouseup', (e) => {
      const s = 'here.fm/';
      if (e.target.selectionStart < s.length) e.target.selectionStart = s.length;
    });
    updateUrlInput.addEventListener('focus', (e) => {
      e.target.selectionStart = 'here.fm/'.length;
    });
    const elUpdateUrlCopy = this.querySelector('.update-url-copy');
    elUpdateUrlCopy.onclick = () => {
      const elUpdateUrlInput = this.querySelector('.update-url-input');
      const elUrlCopyImage = this.querySelector('.update-url-copy-image');
      const elUrlCopiedImage = this.querySelector('.update-url-copied-image');
      window.navigator.clipboard.writeText(elUpdateUrlInput.value).then(() => {
        elUrlCopiedImage.style.display = 'inline';
        elUrlCopyImage.style.display = 'none';
        track(COPY_INVITE_LINK, { source: CopyInviteLinkContexts.ROOM_SETTINGS });
        setTimeout(() => {
          elUrlCopiedImage.style.display = 'none';
          elUrlCopyImage.style.display = 'inline';
        }, 1500);
      });
    };
    this.querySelector('.change-url-button').onclick = () => {
      this.openUpdateUrl();
    };
    this.querySelector('.update-url-button').onclick = () => {
      const newUrl = updateUrlInput.value;
      this.updateRoomUrl(newUrl);
    };

    const voiceControlCheckboxEl = document.getElementById('voice-control-checkbox');
    if (['Chrome', 'Edge'].includes(browserName())) {
      setVoiceControlEnabled(board.voiceControlEnabled === true || board.voiceControlEnabled === undefined);
    }

    voiceControlCheckboxEl.checked = board.voiceControlEnabled;
    voiceControlCheckboxEl.onclick = ({ target }) => {
      const { checked } = target;
      if (!isManageAllContentAllowed()) {
        target.checked = !checked;
        return;
      }

      const newState = voiceControlCheckboxEl.checked;
      setVoiceControlEnabled(newState);
      bus.dispatch(roomSettingsUpdated);
      this.updateVoiceControls(newState);
    };

    const quietModeCheckboxEl = document.getElementById('quiet-mode-checkbox');
    let enabledOnLoad = false;

    if (board.quietMode === true || (board.joinMode !== 'open' && board.quietMode === undefined)) {
      enabledOnLoad = true;
    }

    quietModeCheckboxEl.checked = enabledOnLoad;

    quietModeCheckboxEl.onclick = ({ target }) => {
      const { checked } = target;
      if (!isManageAllContentAllowed()) {
        target.checked = !checked;
        return;
      }

      const newState = quietModeCheckboxEl.checked;
      this.updateQuietMode(newState);
      bus.dispatch(roomSettingsUpdated);
    };

    if (board.urlAlias !== null && board.urlAlias !== undefined) {
      updateUrlInput.value = `here.fm/${board.urlAlias}`;
      window.history.replaceState('string', 'Title', `/${board.urlAlias}`);
    } else updateUrlInput.value = `here.fm/${board.id}`;

    updateUrlInput.addEventListener(
      'keyup',
      async (e) => {
        if (e.key !== 'Enter') {
          const newUrl = updateUrlInput.value;
          const newName = newUrl.substring(8, newUrl.length).toLowerCase();
          const elUpdateUrlInput = this.querySelector('.update-url-input');
          const elAvailableIcon = this.querySelector('.update-url-available-icon');
          const elUnavailableIcon = this.querySelector('.update-url-unavailable-icon');
          const elUpdateUrlError = this.querySelector('.update-url-error');
          const elUpdateUrlAvailable = this.querySelector('.update-url-success');
          const currentUrl = window.location.href;
          const urlLocation = currentUrl.substr(currentUrl.lastIndexOf('/') + 1, currentUrl.length);
          this.hideUrlUpdateElements();
          clearTimeout(checkUrlInputDelayTimer);
          if (urlLocation.toLowerCase() === newName) {
            return false;
          }
          checkUrlInputDelayTimer = setTimeout(async () => {
            const urlAliasErrorMessage = await checkUrlAlias(newName);
            if (!newName || urlAliasErrorMessage) {
              this.showUpdateRoomUrlError(urlAliasErrorMessage);
              elAvailableIcon.style.display = 'none';
              elUnavailableIcon.style.display = 'block';
              elUpdateUrlAvailable.style.display = 'none';
            } else {
              elUnavailableIcon.style.display = 'none';
              elUpdateUrlError.style.display = 'none';
              if (!elUpdateUrlInput.disabled) {
                elAvailableIcon.style.display = 'block';
                elUpdateUrlAvailable.innerHTML = ROOM_URL_ALIAS_VALID_MESSAGE;
                elUpdateUrlAvailable.style.display = 'block';
              } else {
                elAvailableIcon.style.display = 'none';
                elUpdateUrlAvailable.style.display = 'none';
              }
            }
          }, 350);
        } else {
          const newUrl = updateUrlInput.value;
          this.updateRoomUrl(newUrl);
        }
      },
      false
    );

    if (checkIsElectron()) {
      this.querySelector('.discord-info').style.display = 'none';
      this.querySelector('.link-to-discord').style.display = 'none';
    } else if (board.discordConfig) {
      this.querySelector('.discord-info').style.display = 'flex';
      this.querySelector('.link-to-discord').style.display = 'none';
      if (board.discordConfig.channelName) {
        this.querySelector(
          '.discord-channel'
        ).innerHTML = `#${board.discordConfig.channelName} in ${board.discordConfig.serverName}`;
      } else {
        this.querySelector('.discord-channel').innerHTML = board.discordConfig.serverName;
      }

      this.querySelector('#remove-discord-integration').onclick = () => {
        track(CLICKED_REMOVE_DISCORD_INTEGRATION);

        confirmAlert({
          message: 'Are you sure you want to unlink Discord from this room?',
          buttons: [
            {
              label: 'OK',
              onClick: async () => {
                db.runTransaction(async (transaction) => {
                  transaction.delete(
                    db.doc(`discordConfigs/${board.discordConfig.serverId}/boards/${window.currentBoardId}`)
                  );

                  transaction.update(db.doc(`boards/${window.currentBoardId}`), {
                    discordConfig: deleteField(),
                  });
                })
                  .then(() => {
                    track(DISCORD_INTEGRATION_REMOVED_SUCCESSFULLY);
                  })
                  .catch((e) => {
                    track(DISCORD_INTEGRATION_REMOVED_FAILED);
                    log.error('Deleting discord integration failed', e);
                  });
              },
            },
            {
              label: 'Cancel',
            },
          ],
        });
      };
    } else {
      this.querySelector('.link-to-discord').style.display = 'block';
      this.querySelector('.discord-info').style.display = 'none';
    }

    this.querySelectorAll('.discord-auth-link').forEach((link) => {
      const trackData = {};
      if (link.id === 'change-discord-button') {
        trackData.event = CLICKED_UPDATE_DISCORD_INTEGRATION_LINK;
      } else if (link.id === 'add-discord-button') {
        trackData.event = CLICKED_ADD_DISCORD_INTEGRATION_LINK;
        trackData.params = { source: DISCORD_INTEGRATION_LINK_SOURCES.ADD_FROM_ROOM_SETTINGS };
      }

      link.onclick = () => {
        if (isManageAllContentAllowed()) {
          onDiscordIntegrationLinkClick(window.currentBoardId, trackData);
        }
      };
    });

    this.querySelector('#company-tag-checkbox').checked = !!board.nameTag;
    this.querySelector('.broadcast-section').style.display = board.isViewersModeOn ? null : 'none';
    this.querySelector('.end-broadcast-content').style.display = board.isViewersModeOn ? null : 'none';
    this.querySelector('#board-join-mode-checkbox').checked = board.joinMode === 'waitlist';
    this.membersCanAddContentCheckbox.checked = board.membersCanAddContent !== false;
    const canAddContentToggles = document.querySelectorAll('.room-settings-permission-add-content');
    if (board.membersCanAddContent !== false) {
      canAddContentToggles.forEach((el) => {
        el.querySelector('.option-section-image-slash').style.display = 'none';
        el.setAttribute('uk-tooltip', `title: Visitors can add / remove content; pos: bottom`);
        el.querySelector('h6').classList.add('activated');
      });
    } else {
      canAddContentToggles.forEach((el) => {
        el.querySelector('.option-section-image-slash').style.display = 'block';
        el.setAttribute('uk-tooltip', `title: Visitors can't add / remove content; pos: bottom`);
        el.querySelector('h6').classList.remove('activated');
      });
    }
    this.membersCanTurnOnCam.checked = board.membersCanUseCam !== false;
    const canUseCamToggles = document.querySelectorAll('.room-settings-permission-camera');
    if (board.membersCanUseCam !== false) {
      canUseCamToggles.forEach((el) => {
        el.querySelector('.option-section-image-slash').style.display = 'none';
        el.setAttribute('uk-tooltip', `title: Visitors can turn on their camera; pos: bottom`);
        el.querySelector('h6').classList.add('activated');
      });
    } else {
      canUseCamToggles.forEach((el) => {
        el.querySelector('.option-section-image-slash').style.display = 'block';
        el.setAttribute('uk-tooltip', `title: Visitors can't turn on their camera; pos: bottom`);
        el.querySelector('h6').classList.remove('activated');
      });
    }
    this.membersCanTurnOnMic.checked = board.membersCanUseMic !== false;
    const canUseMicToggles = document.querySelectorAll('.room-settings-permission-microphone');
    if (board.membersCanUseMic !== false) {
      canUseMicToggles.forEach((el) => {
        el.querySelector('.option-section-image-slash').style.display = 'none';
        el.setAttribute('uk-tooltip', `title: Visitors can turn on their microphone; pos: bottom`);
        el.querySelector('h6').classList.add('activated');
      });
    } else {
      canUseMicToggles.forEach((el) => {
        el.querySelector('.option-section-image-slash').style.display = 'block';
        el.setAttribute('uk-tooltip', `title: Visitors can't turn on their microphone; pos: bottom`);
        el.querySelector('h6').classList.remove('activated');
      });
    }
    this.querySelector('.allow-anonymous-viewers').style.display = board.isViewersModeOn ? null : 'none';
    this.querySelector('.allow-anonymous-viewers-checkbox').checked = !!board.areAnonymousViewersAllowed;
  }

  hideUrlUpdateElements() {
    const elAvailableIcon = this.querySelector('.update-url-available-icon');
    const elUnavailableIcon = this.querySelector('.update-url-unavailable-icon');
    const elUpdateUrlError = this.querySelector('.update-url-error');
    const elUpdateUrlAvailable = this.querySelector('.update-url-success');
    elAvailableIcon.style.display = 'none';
    elUnavailableIcon.style.display = 'none';
    elUpdateUrlError.style.display = 'none';
    elUpdateUrlAvailable.style.display = 'none';
  }

  async onChangeMembersCanAddContent({ target }) {
    const { checked } = target;
    if (!isManageAllContentAllowed()) {
      target.checked = !checked;
      return;
    }

    track('Change Members Can Add Content', { canAdd: target.checked });
    await db.collection('boards').doc(window.currentBoardId).update({ membersCanAddContent: target.checked });
    bus.dispatch(roomSettingsUpdated);
  }

  async onChangeMembersCanUseCam({ target }) {
    const { checked } = target;
    if (!isManageAllContentAllowed()) {
      target.checked = !checked;
      return;
    }

    track('Change Members Can Use Cam', { canUseCam: target.checked });
    await db.collection('boards').doc(window.currentBoardId).update({ membersCanUseCam: target.checked });
    bus.dispatch(roomSettingsUpdated);
  }

  async onChangeMembersCanUseMic({ target }) {
    const { checked } = target;
    if (!isManageAllContentAllowed()) {
      target.checked = !checked;
      return;
    }

    track('Change Members Can Use Mic', { canUseMic: target.checked });
    await db.collection('boards').doc(window.currentBoardId).update({ membersCanUseMic: target.checked });
    bus.dispatch(roomSettingsUpdated);
  }

  async onChangeJoinMode({ target }) {
    const { checked } = target;
    if (!isManageAllContentAllowed()) {
      target.checked = !checked;
      return;
    }

    const snapshot = await db.collection('boards').doc(window.currentBoardId).get();
    const boardData = snapshot.data();

    let quietMode = false;

    if (
      boardData &&
      ((boardData.joinMode === 'waitlist' && boardData.quietMode === undefined) || boardData.quietMode === true)
    ) {
      quietMode = true;
    }

    track('Change Room Privacy', { private: checked });
    db.collection('boards')
      .doc(window.currentBoardId)
      .update({ joinMode: checked ? 'waitlist' : 'open', quietMode });

    bus.dispatch(roomSettingsUpdated);
  }

  onChangeTagMode({ target }) {
    const { checked } = target;
    if (!isManageAllContentAllowed()) {
      target.checked = !checked;
      return;
    }

    track('Change Nametag Support', { supported: target.checked });
    db.collection('boards').doc(window.currentBoardId).update({ nameTag: target.checked });
    bus.dispatch(roomSettingsUpdated);
  }

  onChangeAnonViewersMode({ target }) {
    const { checked } = target;
    if (!isManageAllContentAllowed()) {
      target.checked = !checked;
      return;
    }

    track('Change Anonymous Viewers Allowed', { allowed: target.checked });
    db.collection('boards').doc(window.currentBoardId).update({ areAnonymousViewersAllowed: target.checked });
  }
}

window.customElements.define('here-room-admin', RoomAdmin);

export default RoomAdmin;
