import log from '../log';
import { Snapshot } from '../sfu/snapshot';
import { track } from '../util/analytics-util';
import { VideoRenderer, attachStreams } from './video-renderer';
import { CameraElementHandler } from './camera-element';

const timeupdateListeners: { [key: string]: () => void } = {};
const playbackListeners: { [key: string]: () => void } = {};

// FIXME: Should be part of Alive Monitor then Stats take it? think Pinky
const stallTimer: { [key: string]: ReturnType<typeof setTimeout> } = {};

export const clearStallTimers = () => {
  Object.keys(stallTimer).forEach((key) => {
    clearTimeout(stallTimer[key]);
  });
};

export class DefaultVideoRenderer extends VideoRenderer {
  static attachStream(
    camera: CameraElementHandler,
    streamId: string,
    stream: MediaStream,
    onPaused: (e: Event) => void
  ): void {
    const cameraElement = document.getElementById(`element-${camera.elementId}`);
    const videoElement = cameraElement?.querySelector('video') as HTMLVideoElement;

    // TODO investigate why this happens - user might be in a stuck state if they get here without a
    // video tag. Letting it crash for now for visibility.
    if (!videoElement) {
      log.warn(`SFU: attachStream: No videoElement for ${streamId}`);
      track('SFU Missing Video Tag');
    }

    if (!videoElement.paused) {
      Snapshot.hideSnapshot(streamId);
    }

    if (timeupdateListeners[streamId]) {
      videoElement.removeEventListener('timeupdate', timeupdateListeners[streamId]);
    }

    if (stream && stream.getVideoTracks().length > 0) {
      timeupdateListeners[streamId] = () => {
        clearTimeout(stallTimer[streamId]);

        stallTimer[streamId] = setTimeout(() => {
          // log.debug("Player: Time update didn't get called - capture snapshot");
          Snapshot.saveSnapshot(streamId);
        }, 500);
      };
      videoElement.addEventListener('timeupdate', timeupdateListeners[streamId]);
    }

    if (playbackListeners[streamId]) {
      videoElement.removeEventListener('play', playbackListeners[streamId]);
    }

    if (onPaused) {
      videoElement.removeEventListener('pause', onPaused);
    }
    videoElement.addEventListener('pause', onPaused);

    playbackListeners[streamId] = async () => {
      Snapshot.hideSnapshot(streamId);
      await window.cameraPlaying(streamId);
    };
    videoElement.addEventListener('play', playbackListeners[streamId]);

    attachStreams(camera, videoElement, streamId, stream);
  }

  static showVideo(camera: CameraElementHandler): void {
    const container = document.getElementById(`video-container-${camera.userId}`);
    const video = container.querySelector('video') as HTMLVideoElement;
    const snapshot = container.querySelector('.video-snapshot') as HTMLElement;
    if (video.paused) {
      snapshot.style.display = 'block';
      video.style.display = 'none';
    } else {
      video.style.display = 'block';
      snapshot.style.display = 'none';
    }
  }

  static hideVideo(camera: CameraElementHandler): void {
    const container = document.getElementById(`video-container-${camera.userId}`);
    const video = container.querySelector('video') as HTMLVideoElement;
    const snapshot = container.querySelector('.video-snapshot') as HTMLElement;
    if (video) {
      // The video is disabled by a user, so need to remove it.
      // We don't remove it on retrieving empty video stream because it can also
      // happen with bad internet connection.
      video.style.display = 'none';
      snapshot.style.display = 'none';
    }
  }
}
