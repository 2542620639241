import React from 'react';
import styled from 'styled-components';

// icons
import HoorayIcon from '../../../../assets/icons/hooray.svg';

const FriendsListEmptyState = () => (
  <Container>
    <HoorayIcon width="84" />
    <Text>
      Text it to friends. Share it on socials. <br /> It’s your room, do whatever you want. <br /> But do it with
      friends!
    </Text>
  </Container>
);

const Container = styled.div`
  margin-top: 21px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
`;

const Text = styled.p`
  color: white;
  font-size: 16px;
  font-weight: bolder;
  line-height: 24px;
  text-align: center;
`;

export default FriendsListEmptyState;
