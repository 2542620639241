import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Portal from '../../../components/Portal';
import { selectRoomData } from '../../../store/room/selectors';
import { useBoardElementsData } from '../../../hooks/useBoardElementsData';
import { clamp, getBackgroundStyle } from '../../../../util';
import ChangeableRectangle from '../../../components/ChangeableRectangle';
import { ROOM_LARGE_SIZE } from '../../../store/room/constants';
import { dashedAnimatedBorder } from '../../../mixins';

const RoomBordersPreview = ({ borders, onBordersUpdate, sizeLabel, defaultViewport, onDefaultViewportUpdate }) => {
  const containerRef = useRef();

  const roomData = useSelector(selectRoomData);
  const [roomElements] = useBoardElementsData(roomData.id);
  const validRoomElements = useMemo(() => roomElements.filter((el) => el.center && el.size), [roomElements]);

  const [borderSizePixels, setBorderSizePixels] = useState(null);
  // Coordinates of zero point in pixels related to the border
  const [roomZeroCoordinatesPixels, setRoomZeroCoordinatesPixels] = useState([0, 0]);
  const [backgroundStyles, setBackgroundStyles] = useState({});

  // Recalculating everything when data is changed
  const pixelToPointsRatio = useRef(0);
  useEffect(() => {
    if (!containerRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const minContainerDimension = Math.min(containerRect.width, containerRect.height);
    pixelToPointsRatio.current = (minContainerDimension * 0.8) / ROOM_LARGE_SIZE; // L size border should take 80% of container's lesser dimension.

    if (borders) {
      // Shorter border should take no less than 30% of container's lesser dimension.
      const minBorderPixelRatio = (minContainerDimension * 0.5) / Math.min(borders[2], borders[3]);
      // Longer border should take no more than 80% of container's lesser dimension
      const maxBorderPixelRatio = (minContainerDimension * 0.8) / Math.max(borders[2], borders[3]);
      pixelToPointsRatio.current = clamp(
        pixelToPointsRatio.current,
        // We are not sure which suggested pixel ratio is actually higher, so sorting them for correct clamping
        Math.min(minBorderPixelRatio, maxBorderPixelRatio),
        Math.max(minBorderPixelRatio, maxBorderPixelRatio)
      );
      setBorderSizePixels([borders[2] * pixelToPointsRatio.current, borders[3] * pixelToPointsRatio.current]);
    } else {
      setBorderSizePixels(null);
    }

    // Setting container that represents room [0, 0] point related to room borders element
    const zeroPoint = borders
      ? [-borders[0] * pixelToPointsRatio.current, -borders[1] * pixelToPointsRatio.current]
      : [containerRect.width / 2, containerRect.height / 2];
    setRoomZeroCoordinatesPixels(zeroPoint);

    // Calculating background styles
    const background = getBackgroundStyle(roomData);
    if (roomData.backgroundScroll === 'scroll') {
      const backgroundSize = roomData.backgroundWidth * pixelToPointsRatio.current;
      background.backgroundSize = `${backgroundSize}px`;
      background.backgroundPosition = `${zeroPoint[0] - backgroundSize / 2}px ${zeroPoint[1]}px`;
    } else {
      background.backgroundSize = 'cover';
    }
    background.backgroundRepeat = roomData.backgroundTile === 'tile' ? 'repeat' : 'no-repeat';
    setBackgroundStyles(background);
  }, [borders, roomData]);

  const onBorderMove = useCallback(
    ({ deltaX, deltaY }) => {
      onBordersUpdate([
        borders[0] - deltaX / pixelToPointsRatio.current,
        borders[1] - deltaY / pixelToPointsRatio.current,
        borders[2],
        borders[3],
      ]);
    },
    [borders, onBordersUpdate]
  );
  const onBorderResize = useCallback(
    ({ deltaX, deltaY }) => {
      onBordersUpdate([
        borders[0],
        borders[1],
        borders[2] + (deltaX * 2) / pixelToPointsRatio.current,
        borders[3] + (deltaY * 2) / pixelToPointsRatio.current,
      ]);
    },
    [borders, onBordersUpdate]
  );

  const onDefaultViewportMove = useCallback(
    ({ deltaX, deltaY }) => {
      onDefaultViewportUpdate([
        defaultViewport[0] + deltaX / pixelToPointsRatio.current,
        defaultViewport[1] + deltaY / pixelToPointsRatio.current,
        defaultViewport[2],
        defaultViewport[3],
      ]);
    },
    [defaultViewport, onDefaultViewportUpdate]
  );
  const onDefaultViewportResize = useCallback(
    ({ deltaX, deltaY }) => {
      onDefaultViewportUpdate([
        defaultViewport[0],
        defaultViewport[1],
        defaultViewport[2] + deltaX / pixelToPointsRatio.current,
        defaultViewport[3] + deltaY / pixelToPointsRatio.current,
      ]);
    },
    [defaultViewport, onDefaultViewportUpdate]
  );

  return (
    <Portal root={document.body}>
      <PreviewContainer ref={containerRef}>
        <BorderPreviewBorder dimensions={borderSizePixels}>
          <BorderPreview dimensions={borderSizePixels} style={backgroundStyles}>
            <ChangeableRectangle isMoveable={!!borders} onMove={onBorderMove} onResize={onBorderResize}>
              <BorderLabel>Room Size - {sizeLabel}</BorderLabel>
              <BoardZeroPoint coordinates={roomZeroCoordinatesPixels}>
                {validRoomElements.map((el) => (
                  <BoardElement
                    key={el.id}
                    rectangle={[
                      el.center[0] * pixelToPointsRatio.current,
                      el.center[1] * pixelToPointsRatio.current,
                      el.size[0] * pixelToPointsRatio.current,
                      el.size[1] * pixelToPointsRatio.current,
                    ]}
                  />
                ))}
                <DefaultViewport
                  key="default-viewport"
                  rectangle={defaultViewport.map((x) => x * pixelToPointsRatio.current)}
                >
                  <ChangeableRectangle onMove={onDefaultViewportMove} onResize={onDefaultViewportResize}>
                    <BorderLabel>Start View</BorderLabel>
                  </ChangeableRectangle>
                </DefaultViewport>
              </BoardZeroPoint>
            </ChangeableRectangle>
            <CustomizeHint>Drag to resize</CustomizeHint>
          </BorderPreview>
        </BorderPreviewBorder>
      </PreviewContainer>
    </Portal>
  );
};

export default RoomBordersPreview;

RoomBordersPreview.propTypes = {
  onBordersUpdate: PropTypes.func.isRequired,
  sizeLabel: PropTypes.string.isRequired,
  defaultViewport: PropTypes.arrayOf(PropTypes.number).isRequired,
  onDefaultViewportUpdate: PropTypes.func.isRequired,
  borders: PropTypes.arrayOf(PropTypes.number),
};

RoomBordersPreview.defaultProps = {
  borders: null,
};

const PreviewContainer = styled.div`
  position: fixed;
  left: 368px;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  z-index: 99999;
  overflow: hidden;
`;

const BorderPreviewBorder = styled.div`
  ${({ dimensions }) =>
    dimensions
      ? css`
          ${dashedAnimatedBorder({ borderWidthPx: 4, durationSec: 16 })}
          padding: 4px;
        `
      : `
      width: 100%;
      height: 100%;
    `}
`;

const BorderPreview = styled.div.attrs(({ dimensions }) => ({
  style: {
    width: dimensions ? `${dimensions[0]}px` : '100%',
    height: dimensions ? `${dimensions[1]}px` : '100%',
  },
}))`
  position: relative;
`;

const BorderLabel = styled.div`
  padding: 4px;
  background: white;
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const CustomizeHint = styled.div`
  text-transform: uppercase;
  color: white;
  position: absolute;
  right: 8px;
  bottom: 4px;
  font-weight: bold;
  font-size: 10px;
`;

const BoardZeroPoint = styled.div.attrs(({ coordinates }) => ({
  style: { transform: `translate(${coordinates[0]}px, ${coordinates[1]}px)` },
}))`
  position: absolute;
  top: 0;
  left: 0;
`;

const PositionedElement = styled.div.attrs(({ rectangle }) => ({
  style: {
    transform: `translate(${rectangle[0]}px, ${rectangle[1]}px)`,
    width: `${rectangle[2]}px`,
    height: `${rectangle[3]}px`,
  },
}))`
  position: absolute;
  top: 0;
  left: 0;
`;

const BoardElement = styled(PositionedElement)`
  background: rgba(0, 0, 0, 0.4);
  border: 1px dotted white;
  border-radius: 2px;
`;

const DefaultViewport = styled(PositionedElement)`
  ${dashedAnimatedBorder({ borderWidthPx: 4, dashesLengthPx: 4, durationSec: 16, color1: 'transparent' })}
`;
