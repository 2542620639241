import React, { FormEvent, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { createUserReport } from '../../../../../../api/reports-api';
import { selectCurrentUser, selectUserById } from '../../../../../store/users/selectors';
import ReportUserPreview from '../../ReportUserPreview';
import { omitUndefinedFields } from '../../../../../../util/js-util';

import {
  ButtonsContainer,
  CancelButton,
  StepContainer,
  ReportButton,
  Title,
  UserPreviewContainer,
  Description,
} from '../../shared-styles';
import { track } from '../../../../../../util/analytics-util';
import { selectIsAPublicChat } from '../../../../../store/messaging/selectors';

// icons
import FlagIcon from '../../../../../../../assets/icons/flag.svg';

const availableTags = ['harassment', 'scam', 'sexualActivity', 'other'];

interface ReportFormStepProps {
  userId: string;
  chatId: string;
  reportedMessage?: string;
  close: () => void;
  goNext: () => void;
}

const ReportFormStep = ({ userId, chatId, reportedMessage, close, goNext }: ReportFormStepProps) => {
  const formRef = useRef(null);

  const currentUser = useSelector(selectCurrentUser);
  const user = useSelector((state) => selectUserById(state, userId));
  const isPublic = useSelector((state) => selectIsAPublicChat(state, chatId));

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const formData = new FormData(formRef.current);

    // @ts-ignore
    const result = formData.entries().reduce((acc, entry) => {
      const value = entry[1].trim();
      if (!value) {
        return acc;
      }

      return { ...acc, [entry[0]]: value };
    }, {});

    const tags = Object.keys(result).filter((key) => availableTags.includes(key));

    await createUserReport(
      omitUndefinedFields({
        tags,
        chatId,
        isPublic: !!isPublic,
        reportedMessage,
        type: 'chat',
        notes: result.notes,
        reportedUser: omitUndefinedFields({ id: userId, name: user.displayName }),
        reportedBy: omitUndefinedFields({
          id: currentUser.id,
          name: currentUser.displayName,
          email: currentUser.email,
        }),
        timestamp: new Date(), // keep the same format with legacy reports
      })
    );

    track('Sent User Report', { type: 'chat' });

    goNext();
  };

  return (
    <StepContainer>
      <Title>
        <FlagIcon />

        <span>Report User</span>
      </Title>

      <UserPreviewContainer>
        {user ? <ReportUserPreview userId={userId} displayName={user.displayName} username={user.username} /> : null}
      </UserPreviewContainer>

      <Form ref={formRef} onSubmit={onSubmit}>
        <Description>
          Help Here stay safe by reporting users who violate our community guidelines. <br /> Our Trust & Safety Team
          reviews every report and will take appropriate action.
        </Description>

        <LabelsList>
          <input id="report-user-harassment-checkbox" type="checkbox" name="harassment" />
          <Label htmlFor="report-user-harassment-checkbox">Harassment/Hate Speech</Label>

          <input id="report-user-scam-checkbox" type="checkbox" name="scam" />
          <Label htmlFor="report-user-scam-checkbox">Scam/Fraud</Label>

          <input id="report-user-sexualActivity-checkbox" type="checkbox" name="sexualActivity" />
          <Label htmlFor="report-user-sexualActivity-checkbox">Nudity/Sexual Activity</Label>

          <input id="report-user-other-checkbox" type="checkbox" name="other" />
          <Label htmlFor="report-user-other-checkbox">Other</Label>
        </LabelsList>

        <TextareaContainer>
          <Textarea name="notes" placeholder="Let us know why you are reporting this user..." rows={5} />
        </TextareaContainer>

        <ButtonsContainer>
          <CancelButton type="button" onClick={close}>
            Never Mind
          </CancelButton>
          <ReportButton type="submit">Send Report</ReportButton>
        </ButtonsContainer>
      </Form>
    </StepContainer>
  );
};

const Form = styled.form`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  padding: 10px;
  color: black;
  font-weight: bold;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid black;
  cursor: pointer;

  &:checked {
    color: white;
    background-color: #a1a1a1;
    border: 1px solid #afafaf;
  }
`;

const LabelsList = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 10px;

  input {
    display: none;
  }

  input:checked + ${Label} {
    color: white;
    background-color: #a1a1a1;
    border: 1px solid #afafaf;
  }
`;

const TextareaContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Textarea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  display: inline-block;
  font-size: 12px;
  background-color: rgba(191, 191, 191, 0.5);
  border-radius: 15px;
  border: none;
  resize: none;
`;

export default ReportFormStep;
