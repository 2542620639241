/* Room creation, deletion, and member management */
import UIkit from 'uikit';

import firebase, { db } from './firebase';
import log from './log';
import { sanitize } from './util';
import { track } from './util/analytics-util';
import { USER_CARD_BOARD_TYPE } from './constants/board-constants';

export async function updateMembership(boardId, title, creatorId, type) {
  const user = firebase.auth().currentUser;
  const update = {
    lastAccess: firebase.firestore.FieldValue.serverTimestamp(),
    feedLastVisibleTime: firebase.firestore.FieldValue.serverTimestamp(),
    lastEnteredRoom: firebase.firestore.FieldValue.serverTimestamp(),
    ...(title && { title }),
    ...(creatorId && { creator: creatorId }),
    ...(type && { type }),
  };
  try {
    await db
      .collection('memberships')
      .doc(user.uid)
      .collection(type === USER_CARD_BOARD_TYPE ? 'userCards' : 'boards') // store user card memberships in a diff collection so that it doesn't affect other clients
      .doc(boardId)
      .set(update, { merge: true });
  } catch (error) {
    log.error('Something bad happened updating membership:', type, error);
  }
}

// Remove member from room
export const removeMember = async (boardId, memberId) => {
  try {
    await db.collection('boards').doc(boardId).collection('members').doc(memberId).delete();
    await db.collection('memberships').doc(memberId).collection('boards').doc(boardId).delete();

    track('Remove member from room');
  } catch (error) {
    log.error('Something bad happened while removing member', error);
  }
};

// Remove membership from board
export function removeBoard(boardId, title) {
  UIkit.modal.confirm(`Permanently leave ${sanitize(title)}?`).then(
    () => {
      db.collection('memberships').doc(firebase.auth().currentUser.uid).collection('boards').doc(boardId).delete();

      track('Leave Room');
    },
    () => {
      log.debug('Declined.');
    }
  );
}

export async function enableViewersMode() {
  const viewers = await db
    .collection(`boards/${window.currentBoardId}/members`)
    .where('isPastViewer', '==', true)
    .get();

  const batch = db.batch();
  viewers.docs.forEach((v) =>
    batch.update(v.ref, {
      role: 'viewer',
      isPastViewer: false,
    })
  );

  const viewerSession = await db.collection('viewerSessions').add({
    board: window.currentBoardId,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    broadcastStartedBy: firebase.auth().currentUser.uid,
  });
  batch.update(db.doc(`boards/${window.currentBoardId}`), { isViewersModeOn: true, viewerSession: viewerSession.id });

  await batch.commit();
  return true;
}

export async function disableViewersMode({ showConfirmation = true } = {}) {
  if (showConfirmation) {
    try {
      const text = `
        <div style="text-align: center">
          <h3>Are you sure you want to end this broadcast?</h3>
          The Broadcast will end and all Viewers will be kicked to the lobby
        </div>
      `;
      const labels = {
        ok: 'End',
        cancel: 'Nevermind',
      };
      await UIkit.modal.confirm(text, { stack: true, labels, clsPage: 'danger-confirm' });
    } catch (err) {
      return false;
    }
  }

  const viewers = await db.collection(`boards/${window.currentBoardId}/members`).where('role', '==', 'viewer').get();

  const batch = db.batch();
  viewers.docs.forEach((v) =>
    batch.update(v.ref, {
      role: null,
      isPastViewer: true,
      kick: {
        mode: 'viewers',
        by: firebase.auth().currentUser.uid,
        at: firebase.firestore.FieldValue.serverTimestamp(),
      },
    })
  );

  batch.update(db.doc(`boards/${window.currentBoardId}`), {
    isViewersModeOn: false,
  });

  await batch.commit();
  return true;
}
