/* eslint-disable no-console */

const logTraces = process.env.LOG_SOURCE_TRACE;

function captureStackTrace(logFn, args) {
  let trace = [];

  try {
    if (logTraces) {
      window.none.none();
    }
  } catch (e) {
    if (logTraces) {
      try {
        const match = e.stack.match(new RegExp('at Object.[debug|warn|log|error].*(.*)'));
        const startIdx = match[0].length + match.index;
        trace = e.stack.slice(startIdx).trim().split('\n');
      } catch (err) {
        if (e.stack) {
          trace = e.stack.split('\n');
        }
      }
    }
    trace = trace.map((line) => `${line.trim()}\n`);
  }
  logFn(...args);
  if (trace.length > 0) {
    console.groupCollapsed('%cTrace', 'color: #ccc;');
    logFn(...trace);
  }
  console.groupEnd();
}

function forwardLoggingToiOS(...args) {
  if (window.webkit && window.webkit.messageHandlers.mobileLogHandler) {
    window.webkit.messageHandlers.mobileLogHandler.postMessage(args);
  }
}

const logger = console;

export default {
  log(...args) {
    captureStackTrace(logger.log, args);
    forwardLoggingToiOS(args);
  },
  debug(...args) {
    // captureStackTrace(logger.debug, args);
    // How eat debug?
    captureStackTrace(logger.log, args);
    forwardLoggingToiOS(args);
  },
  internal(...args) {
    if (window.location.host.match(/(web\.app|localhost:)/i)) {
      captureStackTrace(logger.log, args);
      forwardLoggingToiOS(args);
    }
  },
  warn(...args) {
    logger.warn(...args);
    forwardLoggingToiOS(args);
  },
  error(...args) {
    logger.error(...args);
    forwardLoggingToiOS(args);
  },
};
