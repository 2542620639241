import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Circle = ({ color, percentage }) => {
  const r = 110;
  const circ = 2 * Math.PI * r;
  const strokePercentage = ((100 - percentage) * circ) / 100;
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePercentage !== circ ? color : ''}
      strokeWidth="4px"
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePercentage : 0}
      style={{ transition: 'all 0.5s' }}
    />
  );
};

Circle.propTypes = {
  color: PropTypes.string.isRequired,
  percentage: PropTypes.number,
};

Circle.defaultProps = {
  percentage: 100,
};

const CircleProgress = ({ percentage, color, children }) => (
  <RelativeWrapper>
    <AbsoluteWrapper>
      <FlexWrapper>
        <div>{children}</div>
      </FlexWrapper>
    </AbsoluteWrapper>
    <div>
      <svg width={229} height={229}>
        <g transform={`rotate(-90 ${'115 100'})`}>
          <Circle color="var(--primary-background, #12002d)" />
          <Circle color={color} percentage={percentage} />
        </g>
      </svg>
    </div>
  </RelativeWrapper>
);

export default CircleProgress;

CircleProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string,
  children: PropTypes.node,
};

CircleProgress.defaultProps = {
  color: '#6b1be3',
  children: null,
};

const RelativeWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
