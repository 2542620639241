import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ThemeItem from './ThemeItem';

const ThemeSelect = ({ availableThemes, selectedThemeName, onThemeSelected }) => {
  const themesWithHandlers = useMemo(
    () =>
      availableThemes.map((theme) => ({
        theme,
        isSelected: selectedThemeName === theme.name,
        onClick: () => onThemeSelected({ theme, oldThemeName: selectedThemeName }),
      })),
    [availableThemes, onThemeSelected, selectedThemeName]
  );

  return (
    <Container>
      {themesWithHandlers.map((themeData) => (
        <ThemeItem
          theme={themeData.theme}
          key={themeData.theme.name}
          onThemeClick={themeData.onClick}
          isSelected={themeData.isSelected}
        />
      ))}
    </Container>
  );
};

export default ThemeSelect;

ThemeSelect.propTypes = {
  availableThemes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedThemeName: PropTypes.string.isRequired,
  onThemeSelected: PropTypes.func.isRequired,
};

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
