import styled, { css } from 'styled-components';

import { hideableMixin, whiteShimmerAnimationMixin } from '../mixins';
import { createSlideDownAnimation, scaleIn } from '../components/animate/keyframes';
import { ContextMenuButton } from './components/room-items/RoomItemContextMenu';
import BoldArrowIcon from '../../../assets/icons/arrows/bold-arrow.svg';

const offsetWidthPx = 8;
const tileBorderRadiusPx = 20;
export const tileContainerScaleInMs = 350;
export const titleContainerSlideDownMs = 600;
export const appearAnimationsMs = 700;

export const titleContainerSlideDownStartPx = 25;

export const mainContainerPaddingMixin = () => css`
  @media (max-width: 1200px) {
    padding: 0px 50px;
  }

  @media (max-width: 1000px) {
    padding: 0px 40px;
  }

  @media (max-width: 800px) {
    padding: 0px 30px;
  }

  @media (max-width: 600px) {
    padding: 0px 20px;
  }

  @media (max-width: 400px) {
    padding: 0px 10px;
  }
`;

export const TabContainer = styled.div`
  padding: 0px 60px;
  flex-grow: 1;

  ${mainContainerPaddingMixin()}
  ${hideableMixin()}
`;

export const RoomListItemTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  line-height: 28px;
`;

export const RoomListItemPrimaryTitle = styled(RoomListItemTitle)`
  font-size: 24px;
`;

export const RoomListItemSecondaryTitle = styled(RoomListItemTitle)`
  font-size: 16px;
`;

export const ClickableRoomListItemSecondaryTitle = styled(RoomListItemSecondaryTitle)`
  cursor: pointer;
`;

export const RoomListItemIconsContainer = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const MembersContainer = styled.div<{ isActive: boolean; gap?: string }>`
  padding-right: 5px;
  display: flex;
  align-items: center;

  gap: ${({ gap }) => gap || '3px'};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};

  margin-left: 10px;
`;

export const MembersNumber = styled.span`
  font-weight: bold;
`;

export const RoomItemTile = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const RoomItemTitle = styled.h3`
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  animation: ${() => css`
    ${titleContainerSlideDownMs}ms ${createSlideDownAnimation(
      -titleContainerSlideDownStartPx
    )} ease-in-out ${tileContainerScaleInMs}ms both
  `};
`;

export const TownSquareLabel = styled.div`
  height: 25px;
  width: 110px;

  border-radius: 9999px;
  background: linear-gradient(44.77deg, #90008c 0.28%, #7000a5 39.03%, #2100e3 119.87%);
  border: 2px solid white;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  z-index: 2;

  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
`;

export const HereClubLogoContainer = styled.div`
  height: 50px;
  width: 80px;

  border-radius: 100px;
  background: linear-gradient(44.77deg, #90008c 0.28%, #7000a5 39.03%, #2100e3 119.87%);

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 15px;
  margin-top: 20px;

  position: absolute;
  top: 0;
  right: 0;
`;

export const ChatInputContainer = styled.div`
  padding: 0 9px 9px 9px;
`;

export const RoomItemStats = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;

  animation: ${() => css`
    ${titleContainerSlideDownMs}ms ${createSlideDownAnimation(
      -titleContainerSlideDownStartPx
    )} ease-in-out ${tileContainerScaleInMs}ms both
  `};
`;

export const RoomActiveLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 15px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background-color: #39c360;
  border-bottom-right-radius: ${tileBorderRadiusPx}px;
`;

export const RoomActiveLabelTitle = styled.span`
  color: white;
  font-size: 12px;
  font-weight: bolder;
`;

export const RoomActiveLabelSubtitle = styled.span`
  color: white;
  font-size: 10px;
`;

export const SendOutsideReactionLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px 15px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #39c360;
  border-top-left-radius: ${tileBorderRadiusPx}px;
  border-bottom-right-radius: ${tileBorderRadiusPx}px;
`;

export const TileContainer = styled.div<{ isActive?: boolean; hasTileLink?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  border-radius: ${tileBorderRadiusPx}px;
  aspect-ratio: 2 / 1;
  outline: ${({ isActive }) => (isActive ? `${offsetWidthPx}px solid #39c360` : '')};
  outline-offset: -${offsetWidthPx}px;
  cursor: pointer;
  animation: ${() =>
    css`
      ${tileContainerScaleInMs}ms ${scaleIn} ease-in-out
    `};

  &:hover {
    outline: ${({ isActive, theme }) =>
      isActive
        ? `${offsetWidthPx}px solid #31b055`
        : `${offsetWidthPx}px solid ${theme.borderColor ? theme.borderColor : 'white'}`};

    ${RoomActiveLabel}, ${SendOutsideReactionLabel} {
      background-color: #31b055;
    }

    ${ContextMenuButton} {
      display: flex;
    }
  }
`;

export const TileLoadingBlock = styled.div<{ isOnScreen: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -2;
  ${whiteShimmerAnimationMixin()}
  animation-play-state: ${({ isOnScreen }) => (isOnScreen ? 'running' : 'paused')};
`;

export const tileCoverMixin = () => css`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1; // should be under the outline
`;

export const TileImage = styled.img<{ bgImageFetched?: boolean }>`
  ${tileCoverMixin()}

  object-fit: cover;
  opacity: ${({ bgImageFetched }) => (bgImageFetched ? 1 : 0)};
  transition: opacity 250ms linear;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const ClickableBoldArrowIcon = styled(BoldArrowIcon)`
  cursor: pointer;
`;

export const StaticTileImage = styled(TileImage)`
  opacity: 1;
`;

export const TileContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const IconContainer = styled.div`
  margin-left: 20%;
  width: 27%;
  aspect-ratio: 1 / 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0px 0px 20px 5px #ffffffb2;
  transition: box-shadow 0.25s ease-in-out;

  img {
    width: 100%;
  }
`;

export const RoomsListPreviewButtonContainer = styled.div<{ bg: string; iconMainColor: string }>`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  aspect-ratio: 2 / 1;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  background: ${({ bg }) => `url(${bg})`};
  background-repeat: no-repeat;
  background-size: cover;
  outline: ${({ iconMainColor }) => `8px solid ${iconMainColor}`};
  outline-offset: -8px;
  cursor: pointer;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    z-index: -1;
    height: 45%;
    width: 100%;
    background-color: #0000003f;
  }

  &:hover {
    outline: 8px solid white;
  }
`;

export const RoomItemContainer = styled.div`
  height: 100%;
  width: 100%;
  min-width: 0; // resetting grid's default "min-width: auto; to prevent its items from shrinking
`;
