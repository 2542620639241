import { all, call, put, takeEvery } from 'redux-saga/effects';

import { addStickerPackEmote, fetchEmotes, fetchStickerPacks, removeEmote } from '../../../api/emotes-api';
import {
  addFromEmotePackError,
  addFromEmotePackRequest,
  addFromEmotePackSuccess,
  fetchEmotesError,
  fetchEmotesRequest,
  fetchEmotesSuccess,
  fetchStickerPacksError,
  fetchStickerPacksRequest,
  fetchStickerPacksSuccess,
  removeEmoteError,
  removeEmoteRequest,
  removeEmoteSuccess,
} from './actions';
import { EmoteFromResponse, EmoteType, StickerPack } from '../../../definitions/emotes';

function* handleFetchEmotes() {
  const resp: { success: boolean; defaults: EmoteFromResponse[]; emotes: EmoteFromResponse[] } = yield call(
    fetchEmotes
  );

  if (resp?.success) {
    yield put(fetchEmotesSuccess({ hereEmotePack: resp?.defaults, emotes: resp?.emotes }));
  } else {
    yield put(fetchEmotesError({ error: 'There was an error fetching emotes' }));
  }
}

function* handleFetchStickerPacks() {
  const resp: { success: boolean; stickerPacks: StickerPack[] } = yield call(fetchStickerPacks);

  if (resp?.success) {
    yield put(fetchStickerPacksSuccess({ stickerPacks: resp?.stickerPacks }));
  } else {
    yield put(fetchStickerPacksError({ error: 'There was an error fetching sticker packs' }));
  }
}

function* handleAddFromEmotePack({ payload: { url, pack } }: { payload: { url: string; pack: string } }) {
  const resp: { success: boolean } = yield call(addStickerPackEmote, { url, stickerPack: pack });

  if (resp?.success) {
    yield put(addFromEmotePackSuccess({ emote: { url, type: EmoteType.LIBRARY, stickerPack: pack } }));
  } else {
    yield put(addFromEmotePackError({ error: 'There was an error adding emote' }));
  }
}

function* handleRemoveEmote({ payload: { url } }: { payload: { url: string } }) {
  const resp: { success: boolean } = yield call(removeEmote, url);

  if (resp?.success) {
    yield put(removeEmoteSuccess({ url }));
  } else {
    yield put(removeEmoteError({ error: 'There was an error removing emote' }));
  }
}

export default function* emotesSaga() {
  yield all([
    takeEvery(fetchEmotesRequest, handleFetchEmotes),
    takeEvery(fetchStickerPacksRequest, handleFetchStickerPacks),
    takeEvery(addFromEmotePackRequest, handleAddFromEmotePack),
    takeEvery(removeEmoteRequest, handleRemoveEmote),
  ]);
}
