import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Description, StepContainer, UserPreviewContainer } from '../../shared-styles';
import { selectUserById } from '../../../../../store/users/selectors';
import { BanModalTitle } from '../shared-styles';
import ReportUserPreview from '../../ReportUserPreview';

interface BannedStepProps {
  userId: string;
}

const BannedStep = ({ userId }: BannedStepProps) => {
  const user = useSelector((state) => selectUserById(state, userId));

  return (
    <StepContainer>
      <BanModalTitle>{user.displayName} has been Banned from your chat</BanModalTitle>

      <UserPreviewContainer>
        <ReportUserPreview userId={userId} displayName={user.displayName} username={user.username} />
      </UserPreviewContainer>

      <BanDescription>
        {user.displayName} is now banned and has been kicked from the chat. <br />
        To unban, go to <strong>Manage Users</strong> tab in the settings.
      </BanDescription>
    </StepContainer>
  );
};

const BanDescription = styled(Description)`
  text-align: center;
`;

export default BannedStep;
