import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectSearchResultProfilesThatAreNotFriends } from '../store/friends/selectors';
import FadeIn from '../components/animate/FadeIn';
import { customScrollbarMixin } from '../mixins';
import { track } from '../../util/analytics-util';
import { HIT_FRIEND_REQUEST_LIMIT } from '../../constants/analytics-events/friend-events';
import UserListItemLayout from '../user-profile/UserListItemLayout.tsx';
import AddFriendButtonWithBackground from './AddFriendButtonWithBackground.tsx';

const MAX_REQUESTS_PER_SEARCH = 10;

const FriendsSearchResults = () => {
  const [numOfRequestsSent, setNumOfRequestsSent] = useState(0);
  const searchResults = useSelector(selectSearchResultProfilesThatAreNotFriends);
  const hitRequestLimit = numOfRequestsSent >= MAX_REQUESTS_PER_SEARCH;

  useEffect(() => {
    if (hitRequestLimit) track(HIT_FRIEND_REQUEST_LIMIT, { source: 'friend search' });
  }, [hitRequestLimit]);

  useEffect(() => {
    setNumOfRequestsSent(0);
  }, [searchResults]);

  const onAddFriendClick = useCallback(() => {
    setNumOfRequestsSent(numOfRequestsSent + 1);
  }, [setNumOfRequestsSent, numOfRequestsSent]);

  if (!searchResults?.length) return null;

  return (
    <RelativeContainer>
      <ScrollableContainer>
        {searchResults.map((user) => (
          <UserListItemContainer key={user.userId}>
            <UserListItemLayout
              displayName={user.displayName}
              username={user.username}
              onlineStatus={user.onlineStatus?.status}
              avatar={user.avatar}
              userCTAsSection={
                <AddFriendButtonWithBackground
                  userId={user.userId}
                  backgroundPhotoUrl={user.backgroundPhoto?.original}
                  onClick={onAddFriendClick}
                />
              }
            />
          </UserListItemContainer>
        ))}
      </ScrollableContainer>
      {hitRequestLimit && (
        <FadeIn>
          <RestrictRequestsContainer>
            <div>
              <RestrictRequestsHeader>
                <span role="img" aria-label="flushed">
                  😳
                </span>
                <RestrictRequestsHeaderText>Slow down there, partner</RestrictRequestsHeaderText>
                <span role="img" aria-label="cowboy">
                  🤠
                </span>
              </RestrictRequestsHeader>
              <RestrictRequestsSubheader>
                Try searching for someone else before sending another friend request
              </RestrictRequestsSubheader>
            </div>
          </RestrictRequestsContainer>
        </FadeIn>
      )}
    </RelativeContainer>
  );
};

export default FriendsSearchResults;

const UserListItemContainer = styled.div`
  margin-bottom: 5px;
`;

export const RelativeContainer = styled.div`
  position: relative;
  height: calc(100% - 80px);
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  border-radius: 10px;
  ${customScrollbarMixin()}
`;

const RestrictRequestsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background: rgba(18, 0, 45, 0.75);

  border-radius: 10px;

  text-align: center;
  padding: 0 18px;
  box-sizing: border-box;
  color: white;

  display: flex;
  align-items: center;
`;

const RestrictRequestsHeader = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const RestrictRequestsSubheader = styled.div`
  font-size: 16px;
  padding: 0 32px;
`;

const RestrictRequestsHeaderText = styled.span`
  margin: 0 4px;
`;
