import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import firebase from '../../../../firebase';
import { BoardControllerContext, BoardElementControllerContext } from '../../../common/contexts.ts';
import { track } from '../../../../util/analytics-util';
import { TIKTOK_VIDEO_ADDED, getTiktokAnalyticsData } from './analytics';
import ActivatableElement from '../../ActivatableElement';

const TiktokEmbedElement = () => {
  const { elementData, patchDbData } = useContext(BoardElementControllerContext);
  const { groupId } = useContext(BoardControllerContext);
  const { videoId } = elementData;

  useEffect(() => {
    (async () => {
      const currentUserId = firebase.auth().currentUser.uid;
      if (currentUserId === elementData.creator && !elementData.isAnalyticsSent) {
        patchDbData({ isAnalyticsSent: true });
        const analyticsData = await getTiktokAnalyticsData(videoId);
        track(TIKTOK_VIDEO_ADDED, {
          ...analyticsData,
          groupId,
        });
      }
    })();
    // Need to send this only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ActivatableElement>
      <Iframe src={`https://www.tiktok.com/embed/v2/${videoId}`} />
    </ActivatableElement>
  );
};

export default TiktokEmbedElement;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;
