import React from 'react';
import ReactDOM from 'react-dom';

import FullscreenAnimations from '.';
import { WithRoomStore } from '../store/Wrapper';

export function renderFullscreenAnimations() {
  ReactDOM.render(
    <WithRoomStore>
      <FullscreenAnimations />
    </WithRoomStore>,
    document.getElementById('fullscreen-animations')
  );
}
