import { createSelector } from '@reduxjs/toolkit';

export const getHasMoreOfflineMembers = (state) => state.members.hasMoreOfflineMembers;
export const getRawMembers = (state) => state.members.current;
export const getRawPresentMembers = (state) => state.members.online;
export const getPresentMemberIds = (state) => Object.keys(state.members.online);
export const selectMemberIds = (state) => Object.keys(state.members.current);

export const getMembers = createSelector(
  getRawMembers,
  (state) => state.members.online,
  (state) => state.members.profiles,
  (members, onlineMembers, profiles) =>
    members
      .map((m) => {
        const onlineMemberData = onlineMembers[m.id]; // Contains color information
        return {
          color: onlineMemberData && onlineMemberData.color,
          isOnline: !!onlineMemberData,
          ...m,
          ...(profiles[m.id] || {}),
        };
      })
      .filter((m) => !m.isAnonymous)
);
export const getMember = (state, { id }) => state.members.current.find((m) => m.id === id) || state.members.online[id];
export const getMemberProfile = (state, { id }) => state.members.profiles[id];
export const getCurrentMembers = (state) => state.members.current;
export const getOnlineMembers = createSelector(getMembers, (members) => members.filter((m) => m.isOnline && !m.ban));
export const getOfflineMembers = createSelector(getMembers, (members) => members.filter((m) => !m.isOnline && !m.ban));
export const getBannedMembers = createSelector(getMembers, (members) => members.filter((m) => m.ban));

export const getMemberForMenu = createSelector(
  getMembers,
  (state, { id }) => id,
  (members, id) => members.find((m) => m.id === id)
);
export const getMemberForMenuCurried = (id) => (state) => getMemberForMenu(state, { id });

export const getMemberName = createSelector(
  getMembers,
  (state, { id }) => id, // TODO: this is not cached properly without re-reselect
  (state) => state.members.online,
  (members, id, onlineMembers) => {
    const member = members.find((m) => m.id === id);
    if (member) {
      return members.find((m) => m.id === id).name;
    }

    if (onlineMembers[id]) {
      return onlineMembers[id].name;
    }

    return null;
  }
);
export const getMemberIsOnline = createSelector(
  getMembers,
  (state, { id }) => id,
  (members, id) => members.find((m) => m.id === id)?.isOnline
);
export const curriedGetMemberIsOnline = (id) => (state) => getMemberIsOnline(state, { id });

export const getIsMemberGranted = createSelector(
  getRawMembers,
  (state, { id }) => id,
  (members, id) => {
    const member = members.find((m) => m.id === id);
    return !!member && member.role !== 'viewer';
  }
);
export const curriedGetIsMemberGranted = (id) => (state) => getIsMemberGranted(state, { id });

export const getIsHostGranted = createSelector(
  getRawMembers,
  (state, { id }) => id,
  (members, id) => {
    const member = members.find((m) => m.id === id);
    return !!member && (member.role === 'host' || member.role === 'owner');
  }
);
export const curriedGetIsHostGranted = (id) => (state) => getIsHostGranted(state, { id });

export const getIsOwnerGranted = createSelector(
  getRawMembers,
  (state, { id }) => id,
  (members, id) => {
    const member = members.find((m) => m.id === id);
    return !!member && member.role === 'owner';
  }
);
export const curriedGetIsOwnerGranted = (id) => (state) => getIsOwnerGranted(state, { id });
