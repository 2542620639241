/* eslint-disable @typescript-eslint/lines-between-class-members */
import { isNumber } from 'lodash';
import firebase from '../firebase';
import log from '../log';
import JanusJS from './janus/janus.definitions.original';
import {
  ConnectionStatus,
  PublisherStreamSource,
  ScreenShareQuality,
  UserId,
  RTCRtpEncodingParametersPlusFramerate,
  PublisherType,
} from './definitions/index.definitions';
import { JanusPluginWrapperType } from './janus/videoroom';
import ScreenshareElement from '../screenshare';
import { JanusVideoRoomError, MediaType, OfferParams } from './janus/janus.definitions';
import { BasePublisher, PublisherDelegateProtocol } from './base_publisher';
import { trackInForeground as trackMetric } from '../util/analytics-util';
import { browserName } from '../util/browser-util';
import { CodecName } from './util';

export interface ScreenPublisherDelegateProtocol {
  publisherStreamReadyForHTML: (stream: MediaStream, username: string, type: PublisherStreamSource) => void;
}

const isLandscape = () => window.screen.width > window.screen.height;

const INCREASE_AUDIO_BITRATE = false;

const QUALITY_CONFIGS: {
  [key in ScreenShareQuality]: {
    video: MediaTrackConstraints;
    audio?: boolean | MediaTrackConstraints;
    bitrate: number;
  };
} = {
  [ScreenShareQuality.Gaming]: {
    video: {
      height: { ideal: isLandscape ? window.screen.height : window.screen.width },
      frameRate: 30,
    },
    audio: {},
    bitrate: 3400000,
  },
  [ScreenShareQuality.Presentation]: {
    video: {
      height: { ideal: isLandscape ? window.screen.height : window.screen.width },
      // frameRate: 30,
    },
    audio: true,
    bitrate: 1500000,
  },
  [ScreenShareQuality.Cinema]: {
    video: {
      height: { ideal: isLandscape ? 720 : 1280 },
      // frameRate: 30,
    },
    audio: true,
    bitrate: 1500000,
  },
  [ScreenShareQuality.Standard]: {
    video: {
      height: { ideal: isLandscape ? 480 : 640 },
      // frameRate: 30,
    },
    audio: true,
    bitrate: 800000,
  },
  /*
  ,
  [ScreenShareQuality.Performance]: {
    video: {
      height: { ideal: isLandscape ? 240 : 320 },
      // frameRate: 30,
    },
    videocodec: 'h264,av1,vp8',
    audio: true,
    bitrate: 300000,
  },
  */
};

export class ScreenPublisher extends BasePublisher {
  // FIXME: This field is public for room-loading to be able to use it from SFU.
  screenshareOriginalStream: MediaStream = null;
  status: ConnectionStatus = ConnectionStatus.Disconnected;
  userId: UserId;
  screenUsername: string;
  screenshareStream: MediaStream = null;
  publisherHandle: JanusPluginWrapperType = null;
  currentQuality: ScreenShareQuality = ScreenShareQuality.Presentation;

  private roomId: string;
  private opaqueId: string;
  private overrideBitrate: number;
  private screenshareTracks: { [key: string]: MediaStreamTrack } = {};
  private onLocalStreamReady: () => void;
  private restartScreenshare: () => void;
  private blackListCodecs: string[] = [];
  private latestOfferJsep: { sdp: string };
  private preserveStream = false;

  constructor(roomId: string, delegate: PublisherDelegateProtocol) {
    super();
    this.roomId = roomId;
    this.delegate = delegate;
    this.encodingPriority = 'medium';
  }

  async dispose() {
    try {
      await this.publisherHandle?.send({ message: { request: 'leave' } });
      this.publisherHandle?.hangup(true);
      this.onUpdatedRates = null;
    } catch (error) {
      log.error('ScreenPublisher: Error on dispose: ', error);
    } finally {
      this.status = ConnectionStatus.Disconnected;
      this.publisherHandle = null;
    }
  }

  get publisherType() {
    return PublisherType.Screenshare;
  }

  /*
  get isAudioOn() {
    return this.publisherHandle?.webrtcStuff?.myStream?.getAudioTracks().length > 0;
  }

  get isVideoOn() {
    return this.publisherHandle?.webrtcStuff?.myStream?.getVideoTracks().length > 0;
  }
  */

  set bitrate(value: number) {
    // FIXME: Make this inmediate on current ongoing screenshare.
    this.overrideBitrate = value;
  }

  get currentQualityConfig() {
    return QUALITY_CONFIGS[this.currentQuality];
  }

  get currentConstraints(): { video: MediaTrackConstraints; audio?: boolean | MediaTrackConstraints } {
    const config = this.currentQualityConfig;
    return { video: config.video, audio: config.audio };
  }

  get linkQuality() {
    return 4;
  }

  get videoSender() {
    return this.peerConnection?.getSenders().find((s) => s.track.kind === 'video');
  }

  /**
   * If there is a PeerConnection Sender being used this codec will return the codec name.
   */
  get currentVideoCodec(): CodecName | undefined {
    const codecs = this.videoSender?.getParameters().codecs || [];
    const codec = codecs.find((c) => c.mimeType !== 'video/rtx');
    if (codec) {
      return codec.mimeType.replace('video/', '').toLowerCase() as CodecName;
    }
    return undefined;
  }

  override get audioTrackProvider() {
    return {
      type: 'audio',
      getTrack: async () => this.screenshareOriginalStream?.getAudioTracks()[0],
      suspend: async () => {},
    };
  }

  override set audioTrackProvider(provider) {}

  override get videoTrackProvider() {
    return {
      type: 'video',
      getTrack: async () => this.screenshareOriginalStream?.getVideoTracks()[0],
      suspend: async () => {},
    };
  }

  override set videoTrackProvider(provider) {}

  hangupScreenshare() {
    this.hangedUp = true;
    if (this.publisherHandle) {
      this.publisherHandle.hangup();
    }

    Object.values(this.screenshareTracks).forEach((t) => {
      t.stop();
    });
    this.screenshareStream?.getTracks().forEach((t) => {
      t.stop();
      this.screenshareStream.removeTrack(t);
    });

    if (this.screenshareOriginalStream && !this.preserveStream) {
      this.screenshareOriginalStream.getTracks().forEach((t) => {
        t.stop();
        this.screenshareOriginalStream.removeTrack(t);
      });

      this.screenshareOriginalStream = null;
    }
  }

  async shareScreen(ready: () => void, restart: () => void, stream: MediaStream) {
    this.hangedUp = false;
    this.onLocalStreamReady = ready;
    this.restartScreenshare = restart;
    this.screenshareOriginalStream = stream;

    log.log(`ScreenPublisher: Screen sharing session created: ${this.roomId}`);
    this.userId = firebase.auth().currentUser.uid;
    this.screenUsername = `screen-${this.userId}`;
    const register = {
      request: 'join',
      room: this.roomId,
      ptype: 'publisher',
      display: this.screenUsername,
    };
    await this.publisherHandle.send({ message: register });
  }

  get codecPreference() {
    if (['Safari', 'Edge'].includes(browserName())) {
      return 'h264';
    }
    return this.blackListCodecs.includes('av1') ? 'h264' : 'av1';
  }

  getNegotiatedCodec(sdp: string) {
    if (sdp.match(/h264/i)) return 'H264';
    if (sdp.match(/AV1/i)) return 'AV1';
    if (sdp.match(/VP8/i)) return 'VP8';
    return 'Unknown';
  }

  customizeSdp(jsep: { sdp: string; type: 'offer' | 'answer' }) {
    if (INCREASE_AUDIO_BITRATE && jsep.type === 'offer' && jsep.sdp.match(/useinbandfec=1/)) {
      jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1; stereo=1; maxaveragebitrate=64000');
    }
  }

  async sendPublishRequest(offer: OfferParams) {
    try {
      this.latestOfferJsep = await this.publisherHandle.createOffer(offer, { customizeSdp: this.customizeSdp });
      const { codecPreference } = this;
      log.debug(`ScreenPublisher: Attempt to Publish with ${codecPreference}`);
      log.debug(this.latestOfferJsep);
      const publish = {
        request: 'configure',
        videocodec: codecPreference,
        audio: true,
        video: true,
        // bitrate: this.overrideBitrate || this.currentQualityConfig.bitrate,
      };
      await this.publisherHandle.send({ message: publish, jsep: this.latestOfferJsep });
    } catch (error) {
      trackMetric('SFU Screenshare Publish Error', { error: error.message });
      log.error('ScreenPublisher: WebRTC error:', error);
      // TODO deal with permissions errors from screensharing.
    }
  }

  dispatchRoomEventMessage(msg: JanusJS.Message) {
    super.dispatchRoomEventMessage(msg);
    if (msg.error !== undefined && msg.error !== null) {
      log.error(`ScreenPublisher: VideoRoom publisherHandle error - ${msg.error}`);
      if (msg.error_code === JanusVideoRoomError.INVALID_ELEMENT) {
        this.blackListCodecs.push(this.codecPreference);
        const newCodec = this.codecPreference;
        log.warn('ScreenPublisher: Attempt to republish with codec:', newCodec);
        this.restartScreenshare();
      }
    }
  }

  onJSEP(jsep: JanusJS.JSEP, _msg: JanusJS.Message) {
    const codec = this.getNegotiatedCodec(jsep?.sdp);
    log.debug('ScreenPublisher: Handling SDP with negotiated codec:', codec);
    log.debug(jsep);
    this.publisherHandle.handleRemoteJsep({ jsep });
  }

  oncleanup() {
    log.log('ScreenPublisher: ::: Got a cleanup notification :::');
    this.screenshareTracks = {};
    if (this.screenshareOriginalStream && !this.preserveStream) {
      this.screenshareOriginalStream.getTracks().forEach((t) => {
        t.stop();
        this.screenshareOriginalStream.removeTrack(t);
      });

      this.screenshareOriginalStream = null;
    }
  }

  onLocalTrack(track: MediaStreamTrack, on: boolean) {
    log.debug(`ScreenPublisher: onLocalTrack track ${on ? 'added' : 'removed'}:`, track);
    if (track.kind === 'audio') {
      // Ignore local audio tracks
      return;
    }
    // We use the track ID as name of the element, but it may contain invalid characters
    const trackId = track.id.replace(/[{}]/g, '');
    if (!on) {
      // Track removed, get rid of the stream and the rendering
      const t = this.screenshareTracks[trackId];
      if (t) {
        try {
          t.stop();
        } catch (e) {
          log.warn('Something went wrong stopping local tracks', e);
        }
      }
      delete this.screenshareTracks[trackId];
      return;
    }
    // If we're here, a new track was added
    if (this.screenshareTracks[trackId]) {
      // We've been here already
      if (this.onLocalStreamReady) {
        this.onLocalStreamReady();
      }
      return;
    }
    if (!this.screenshareStream) this.screenshareStream = new MediaStream();
    this.screenshareStream.addTrack(track.clone());
    this.screenshareTracks[trackId] = track;

    this.delegate.publisherStreamReadyForHTML(
      this.screenshareStream,
      this.screenUsername,
      PublisherStreamSource.ScreenShare
    );
    if (this.onLocalStreamReady) {
      this.onLocalStreamReady();
    }

    // Handle stream ending via Chrome button
    track.onended = this.onEnded.bind(this);

    if (!this.publisherHandle?.webrtcStuff?.pc) {
      log.error('ScreenPublisher onlocalstream but missing peer connection');
    }
  }

  async onEnded(event?: Event) {
    log.debug(`ScreenPublisher: stream onended() event: ${event}`);
    const leave = {
      request: 'leave',
      room: this.roomId,
    };
    if (this.publisherHandle) {
      await this.publisherHandle.send({ message: leave });
    }
    // FIXME: Do not depend on window here.
    const handler = Object.values(window.elementHandlers).find(
      (h) => h.userId === this.userId && h instanceof ScreenshareElement
    );
    if (handler) {
      handler.screenshareEnded();
    }
  }

  onMediaState(medium: MediaType, on: boolean, mid: number) {
    super.onMediaState(medium, on, mid);
    if (on && medium === 'video') {
      this.publisherHandle.send({
        message: {
          request: 'configure',
          keyframe: true,
        },
      });
    }
    log.debug(
      `ScreenPublisher: Janus ${on ? 'started' : 'stopped'} receiving our Screensharing ${medium}, mid: ${mid}`
    );
    trackMetric(`SFU Janus ${on ? 'started' : 'stopped'} receiving our Screensharing ${medium}`);
  }

  onConfigureSuccess() {
    /** We do no thing here for ScreenPublisher */
  }

  async updateQuality(quality: ScreenShareQuality) {
    trackMetric('SFU Update Screenshare Quality', {
      fromQuality: this.currentQuality,
      toQuality: quality,
    });

    const previousQuality = this.currentQuality;
    this.currentQuality = quality;
    const newConstraints = QUALITY_CONFIGS[quality];

    const sender = this.videoSender;
    if (!sender) {
      log.warn('ScreenPublisher: Cannot update quality no Peer Connection!');
      return;
    }

    log.log(`ScreenPublisher: Setting new constraints to quality: ${quality}`);
    log.debug(`ScreenPublisher: ${quality}, constraints: ${JSON.stringify(newConstraints, null, 2)}`);

    try {
      if (this.peerConnection?.iceConnectionState !== 'connected') {
        throw new Error('ScreenPublisher: Will not apply constraints beacuse client is not publishing');
      }

      if (!sender) {
        throw new Error('ScreenPublisher: No video sender found in my PeerConnection');
      }

      const senderParams = sender.getParameters();

      try {
        this.publisherHandle.send({ message: { request: 'configure', bitrate: this.currentQualityConfig.bitrate } });
      } catch (error) {
        log.error('ScreenShare: Error setting Janus bitrate');
        trackMetric('SFU Screenshare Error Setting Janus Bitrate', { bitrate: this.currentQualityConfig.bitrate });
      }

      if (!senderParams?.encodings || senderParams.encodings.length === 0) {
        throw new Error(`ScreenPublisher: No encodings on sender params: ${JSON.stringify(senderParams)}`);
      }

      let ratio = sender.track.getSettings().height / (newConstraints.video.height as { ideal: number }).ideal;
      if (!isNumber(ratio)) {
        log.warn(`ScreenPublisher: ratio: ${ratio} is not a number, can't use to change quality.`);
        trackMetric('SFU ScreenPublisher Wrong Ratio', {
          ratio,
          trackHeight: sender.track.getSettings().height,
          idealHeight: (newConstraints.video.height as { ideal: number }).ideal,
        });
        ratio = 1;
      }

      if (!senderParams.encodings) {
        senderParams.encodings = [{}];
      }

      const encoding = senderParams.encodings[0] as RTCRtpEncodingParametersPlusFramerate;
      encoding.scaleResolutionDownBy = Math.max(ratio, 1);
      encoding.maxBitrate = this.currentQualityConfig.bitrate;
      if (this.currentQualityConfig.video.frameRate) {
        encoding.maxFramerate = this.currentQualityConfig.video.frameRate as number;
      }

      await sender.setParameters(senderParams);

      trackMetric('SFU Update Screenshare Quality Succeed', {
        fromQuality: previousQuality,
        toQuality: quality,
      });
    } catch (error) {
      log.error(`ScreenPublisher: Error updating quality, error: ${error}`);
      trackMetric('SFU Update Screenshare Quality Error', {
        error: error.message,
        fromQuality: this.currentQuality,
        toQuality: quality,
      });
    }
  }

  restoreICE(forceIceRestart = false) {
    this.publishOwnFeed(this.isAudioOn, this.isVideoOn, false, 0, true, forceIceRestart);
  }
}
