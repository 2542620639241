import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { HereInput } from '../../components/inputs';
import SearchIcon from '../../../../assets/icons/search.svg';
import { selectSearchResultProfilesThatAreNotFriends } from '../../store/friends/selectors';
import { searchUsers } from '../../store/users/store';
import ArrowIcon from '../../../../assets/icons/button-arrow.svg';
import { customScrollbarMixin } from '../../mixins';
import SuggestedFriendsList from '../suggested-friends/SuggestedFriendsList';
import { useFriendsSuggestions } from '../../hooks/useFriendsSuggestions.tsx';
import LottieAnimation from '../../animations/LottieAnimation';
import animationData from '../../../../assets/lottie/loading.json';
import FriendsSearchResults from '../FriendsSearchResults';

const defaultImagePath = 'images/misc/no-friends-smiley-dudes.svg';
const noResultsImagePath = 'images/misc/no-friend-requests-tear.svg';

const defaultHeader = 'Here is better with friends!';
const noResultsHeader = 'No Results :(';

const defaultSubheader = 'Search for friends above that you want to hang out with and talk to whenever you want.';
const noResultsSubheader = 'No one found by that name. Try again with their username or display name.';

const AddFriendsTabContent = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');

  const { suggestedFriends, nextPage, isLoadingSuggestedFriends } = useFriendsSuggestions();

  const searchResults = useSelector(selectSearchResultProfilesThatAreNotFriends);

  const onSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onSubmitSearch = useCallback(() => {
    dispatch(searchUsers({ searchQuery: searchValue, source: 'add friend search' }));
  }, [dispatch, searchValue]);

  const searchButton = useMemo(
    () => (
      <SearchButton onClick={onSubmitSearch}>
        <ArrowIcon />
      </SearchButton>
    ),
    [onSubmitSearch]
  );

  const onKeyDown = (e) => {
    if (e.key === 'Enter') onSubmitSearch();
  };

  return (
    <>
      <InputContainer className="unthemed">
        <HereInput
          controlled
          prefix={<SearchIcon />}
          value={searchValue}
          onChange={onSearchInputChange}
          onKeyDown={onKeyDown}
          placeholder="Search by username or name"
          addOnAfter={searchButton}
        />
      </InputContainer>

      {/* no search made yet and no suggested friends case: */}
      {!searchResults && suggestedFriends.length === 0 && (
        <NoResultsContainer>
          {isLoadingSuggestedFriends ? (
            <NoResultsContainer>
              <LottieAnimation animationData={animationData} loop />
            </NoResultsContainer>
          ) : (
            <>
              <NoResultsBackground url="images/profile-backgrounds/grey.svg" />
              <NoResultsContent>
                <img src={defaultImagePath} alt="Add Friends" />
                <NoResultsHeader>{defaultHeader}</NoResultsHeader>
                <NoResultsSubheader>{defaultSubheader}</NoResultsSubheader>
              </NoResultsContent>
            </>
          )}
        </NoResultsContainer>
      )}

      {/* no search made yet but suggested friends case: */}
      {!searchResults && suggestedFriends.length > 0 && (
        <>
          <hr />
          <SuggestedFriendsListTitle>People You May Know</SuggestedFriendsListTitle>
          <RelativeContainer heightOffset={120}>
            <ScrollableContainer>
              <SuggestedFriendsList initialSuggestedFriends={suggestedFriends} initialNextPage={nextPage} />
            </ScrollableContainer>
          </RelativeContainer>
        </>
      )}

      {/* no results case: */}
      {searchResults && searchResults.length <= 0 && (
        <NoResultsContainer>
          <NoResultsBackground url="images/profile-backgrounds/grey.svg" />
          <NoResultsContent>
            <img src={noResultsImagePath} alt="No Results" />
            <NoResultsHeader style={{ marginTop: '18px' }}>{noResultsHeader}</NoResultsHeader>
            <NoResultsSubheader>{noResultsSubheader}</NoResultsSubheader>
          </NoResultsContent>
        </NoResultsContainer>
      )}

      <FriendsSearchResults />
    </>
  );
};

export default AddFriendsTabContent;

const InputContainer = styled.div`
  margin-bottom: 30px;
`;

const NoResultsContainer = styled.div`
  position: relative;
  height: 296px;
`;

const NoResultsBackground = styled.div`
  background: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const NoResultsContent = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;

  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  color: #12002d;
`;

const NoResultsHeader = styled.div`
  margin-bottom: 14px;
`;

const NoResultsSubheader = styled.div`
  font-size: 12px;
  font-weight: 400;
  width: 336px;
  margin: 0 auto;
`;

const SearchButton = styled.div`
  background: #6b1be3;
  padding: 0 12px;
  border-radius: 5px;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
  height: calc(100% - ${({ heightOffset }) => heightOffset}px);
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  border-radius: 10px;
  position: relative;
  ${customScrollbarMixin()}
`;

const SuggestedFriendsListTitle = styled.h4`
  font-size: 13px;
  font-weight: bolder;
  margin-bottom: 15px;
`;
