import firebase from '../firebase';
import { RtpStats, TrackId, TrackMap } from '../util/stats-parser';
import log from '../log';
import { ConnectionStatus, OnlineStatus, StreamType, SubscribeStatus, UserId } from './definitions/index.definitions';
import { FeedId, MediaType } from './janus/janus.definitions';
import Room from './room';
import Session from './session';
import { trackInForeground as trackMetric } from '../util/analytics-util';

type OperationId = string;

enum Alarms {
  WatchUsers = 'WatchUsers',
  WatchAudioFeedTracks = 'WatchAudioFeedTracks',
  WatchVideoFeedTracks = 'WatchVideoFeedTracks',
  WatchAudioStreamTracks = 'WatchAudioStreamTracks',
  WatchVideoStreamTracks = 'WatchVideoStreamTracks',
  WatchPeerConnections = 'WatchPeerConnections',
}

const ALARM_INTERVALS: { [key in Alarms]: number } = {
  [Alarms.WatchUsers]: 3 * 1000,
  [Alarms.WatchAudioFeedTracks]: 2 * 1000,
  [Alarms.WatchVideoFeedTracks]: 5 * 1000,
  [Alarms.WatchAudioStreamTracks]: 5 * 1000,
  [Alarms.WatchVideoStreamTracks]: 2 * 1000,
  [Alarms.WatchPeerConnections]: 5 * 1000,
};

const ALARM_COUNT_THRESHOLD: { [key in Alarms]: number } = {
  [Alarms.WatchUsers]: 2,
  [Alarms.WatchAudioFeedTracks]: 2,
  [Alarms.WatchVideoFeedTracks]: 2,
  [Alarms.WatchAudioStreamTracks]: 5,
  [Alarms.WatchVideoStreamTracks]: 5,
  [Alarms.WatchPeerConnections]: 5,
};

export default class SFUWatchDog {
  room: Room;

  session: Session;

  peerConnectionsTimer: ReturnType<typeof setInterval>;

  watchdogTimer: ReturnType<typeof setInterval>;

  dryRun = true;

  online = OnlineStatus.Unknown;

  alarmCounts: { [key in Alarms]?: { [key: FeedId]: { [key: OperationId]: number } } } = {
    [Alarms.WatchUsers]: {},
    [Alarms.WatchAudioFeedTracks]: {},
    [Alarms.WatchVideoFeedTracks]: {},
    [Alarms.WatchAudioStreamTracks]: {},
    [Alarms.WatchVideoStreamTracks]: {},
    [Alarms.WatchPeerConnections]: {},
  };

  constructor() {
    this.init();
    this.dryRun = false;
  }

  init() {
    this.peerConnectionsTimer = null;
    this.watchdogTimer = null;
    this.room = null;
  }

  start(room: Room, dryRun = true) {
    this.stop();
    this.room = room;
    this.dryRun = dryRun;

    setTimeout(() => {
      log.log('Watchdog: Initializing ', Alarms.WatchUsers);
      this.watchdogTimer = setInterval(this.watchUsers.bind(this), ALARM_INTERVALS[Alarms.WatchUsers]);

      log.log('Watchdog: Initializing ', Alarms.WatchPeerConnections);
      this.peerConnectionsTimer = setInterval(
        this.watchPeerConnections.bind(this),
        ALARM_INTERVALS[Alarms.WatchPeerConnections]
      );
    }, 5000 + room.subscriber.feedCollection.feeds.length * 500);
  }

  stop() {
    if (this.peerConnectionsTimer) {
      clearInterval(this.peerConnectionsTimer);
    }
    if (this.watchdogTimer) {
      clearInterval(this.watchdogTimer);
    }
    this.init();
  }

  get subscriber() {
    return this.room?.subscriber;
  }

  get cameras() {
    return Object.values(window.elementHandlers).filter((h) => h.constructor.elementType === 'CameraElement');
  }

  get curUser() {
    return firebase.auth().currentUser;
  }

  get subscriberHandle() {
    return this.subscriber?.subscriberHandle;
  }

  get subscriberPC() {
    return this.subscriberHandle.webrtcStuff.pc;
  }

  get feedCollection() {
    return this.subscriber?.feedCollection;
  }

  get feedIdDisplayIdDictionary() {
    return this.feedCollection?.feedIdDisplayIdDictionary;
  }

  get dryRunLogMessage() {
    return this.dryRun ? 'DRY-ON' : '';
  }

  setCountersEdge() {
    Object.keys(this.alarmCounts).forEach((alarm: Alarms) => {
      Object.keys(this.alarmCounts[alarm]).forEach((feedId: FeedId) => {
        Object.keys(this.alarmCounts[alarm]).forEach((operationId: OperationId) => {
          this.alarmCounts[alarm][feedId][operationId] = ALARM_COUNT_THRESHOLD[alarm];
        });
      });
    });
  }

  incrementAlarmAndExecute(id: FeedId | UserId, operationId: OperationId, alarm: Alarms, executeFunction: () => void) {
    this.alarmCounts[alarm][id] = this.alarmCounts[alarm][id] || {};
    this.alarmCounts[alarm][id][operationId] = this.alarmCounts[alarm][id][operationId] || 0;

    if (this.alarmCounts[alarm][id][operationId] > ALARM_COUNT_THRESHOLD[alarm]) {
      this.alarmCounts[alarm][id][operationId] = -ALARM_COUNT_THRESHOLD[alarm];
      log.debug(`Watchdog: Firing Alarm ${alarm} ${operationId} feed/user id ${id}`);
      trackMetric('SFU Watchdog Firing Alarm', { alarm });
      executeFunction();
    }
    this.alarmCounts[alarm][id][operationId] += 1;
    log.debug(
      `Watchdog: An Alarm ${alarm} has been bounced for id: ${id} operation: ${operationId} for ${this.alarmCounts[alarm][id][operationId]} times.`
    );
  }

  resetAlarm(alarm: Alarms, id: FeedId | UserId, operationId: OperationId) {
    if (this.alarmCounts[alarm][id]) {
      this.alarmCounts[alarm][id][operationId] = 0;
    }
  }

  async watchPeerConnections() {
    const subscriberPC = this.room?.subscriber?.subscriberHandle?.webrtcStuff?.pc;
    const publisherPC = this.room?.cameraPublisher?.publisherHandle?.webrtcStuff?.pc;
    const screensharePC = this.room?.screenPublisher?.publisherHandle?.webrtcStuff?.pc;

    if (
      (!publisherPC || publisherPC.iceConnectionState === 'disconnected') &&
      (!subscriberPC || subscriberPC.iceConnectionState === 'disconnected') &&
      (!screensharePC || screensharePC.iceConnectionState === 'disconnected') &&
      [ConnectionStatus.Connecting, ConnectionStatus.Connected].includes(this.session.status) &&
      [ConnectionStatus.Connecting, ConnectionStatus.Connected].includes(this.room?.status)
    ) {
      this.incrementAlarmAndExecute('peer_connections', 'missing-session', Alarms.WatchPeerConnections, () => {
        log.warn(`Watchdog: ${this.dryRunLogMessage} Cannot find Publisher and Subscribe PeerConnections... Rebuild!`);
        this.session.reconnect(true);
      });
    } else {
      this.resetAlarm(Alarms.WatchPeerConnections, 'peer_connections', 'missing-session');
    }
  }

  async watchUsers() {
    if (!this.curUser || !window.currentBoardId) {
      return;
    }

    this.cameras.forEach(async (cam) => {
      if (cam.userId === this.curUser.uid) {
        return;
      }
      if (!(await this.watchFeed(cam.userId))) {
        return;
      }

      [
        { type: StreamType.Video, status: cam.isVideoOn },
        { type: StreamType.Audio, status: cam.isAudioOn },
      ].forEach((media) => {
        if (!media.status) return;

        if (!this.watchFeedTrack(cam.userId, media.type)) {
          return;
        }

        this.watchFeedStreamTrack(cam.userId, media.type);
      });
    });
  }

  /**
   * Watch for the existece of a Feed or trigger an alarm.
   *
   * @param userId UserId / Display ID for the user camera.
   * @returns A boolean true indicating if the Feed has been found or webrtc participant doesn't exists.
   */
  private async watchFeed(userId: UserId): Promise<boolean> {
    if (!this.feedCollection) {
      log.warn("Watchdog: Can't watch feed, no feedCollection in watchdog");
      // trackMetric('SFU Watchdog Cannot Find Feed Collection', { session: !!this.session });
      return false;
    }
    const feed = this.feedCollection.feeds.find((f) => f.display === userId);
    if (feed) {
      this.resetAlarm(Alarms.WatchUsers, userId, 'missing-feed');
      return true;
    }

    const participants = await this.room?.listParticipants();
    const part = participants?.find((p) => p.display === userId);
    if (!part) {
      this.resetAlarm(Alarms.WatchUsers, userId, 'missing-feed');
      return true;
    }

    this.incrementAlarmAndExecute(userId, 'missing-feed', Alarms.WatchUsers, () => {
      log.error(`Watchdog: ${this.dryRunLogMessage} Cannot find Feed ${part.id} UserId ${userId}`);
      if (!this.dryRun && this.online !== OnlineStatus.Offline) this.subscriber.reSubscribeFeed(part.id);
    });

    return false;
  }

  /**
   * Watch for existence of FeedTrack or trigger an alarm.
   *
   * @param userId User Id of the camera user.
   * @param mediaType Media type of the FeedTrack we are looking for.
   * @returns Boolean indicating if the FeedTrack was found or is not neccesary to alarm about it.
   */
  private watchFeedTrack(userId: UserId, mediaType: string): boolean {
    const feed = this.feedCollection?.feeds.find((f) => f.display === userId);
    const feedTrack = feed?.feedTracks.find((ft) => ft.type === mediaType);

    let alarm = mediaType === MediaType.Audio ? Alarms.WatchAudioFeedTracks : null;
    alarm = mediaType === MediaType.Video ? Alarms.WatchVideoFeedTracks : alarm;

    if (feedTrack || !feed) {
      this.resetAlarm(alarm, userId, `missing-${mediaType}-feedtrack`);
      return true;
    }

    this.incrementAlarmAndExecute(userId, `missing-${mediaType}-feedtrack`, alarm, () => {
      log.warn(
        `Watchdog: ${this.dryRunLogMessage} Cannot find FeedTrack ${mediaType} for Feed ${feed.id} UserId ${userId}`
      );
      if (!this.dryRun) this.subscriber.reSubscribeFeed(feed.id);
    });
    return false;
  }

  /**
   * Watch for the existence of a Media Stream Track or trigger an alarm.
   * This method will check if the track already exists in the PeerConnection and try to recover before trigger the alarm.
   *
   * @param userId User Id of the camera user.
   * @param mediaType Media type we are looking for.
   * @returns Boolean indicating if the Feed Stream Track was found.
   */
  private watchFeedStreamTrack(userId: UserId, mediaType: string) {
    if (!this.feedCollection) {
      return false;
    }

    const feed = this.feedCollection.feeds.find((f) => f.display === userId);
    const feedTrack = feed?.feedTracks.find((ft) => ft.type === mediaType);
    if (
      !feedTrack ||
      feedTrack.disabled ||
      feedTrack.status === SubscribeStatus.Off ||
      feedTrack.status === SubscribeStatus.Idle
    )
      return true;

    const tracks = feed.stream.getTracks().filter((t) => t.kind === mediaType);
    if (tracks.length > 0) return true;

    const pcTrack = this.subscriberPC
      .getReceivers()
      .find((r) => r.track.kind === mediaType && r.track.id === `janus${feedTrack.uniqueMidId}`)?.track;

    let alarm = mediaType === MediaType.Audio ? Alarms.WatchAudioStreamTracks : null;
    alarm = mediaType === MediaType.Video ? Alarms.WatchVideoStreamTracks : alarm;

    if (pcTrack && pcTrack.muted) {
      this.resetAlarm(alarm, feed.id, `missing-media-stream-track-${mediaType}`);
      return true;
    }

    if (pcTrack && !pcTrack.muted && !this.dryRun) {
      log.warn(
        `Watchdog: Found missing ${mediaType} track for Feed ${feed.id} mid: ${feedTrack.feedMidId} unique: ${feedTrack.uniqueMidId} in PeerConnection ...adding back.`
      );
      const { muted, enabled, readyState, kind } = pcTrack;
      const qtyTracks = kind === 'audio' ? feed.stream.getAudioTracks().length : feed.stream.getVideoTracks().length;
      trackMetric('SFU Watchdog Added Found Track In PC', { muted, enabled, readyState, kind, qtyTracks });
      this.resetAlarm(alarm, feed.id, `missing-media-stream-track-${mediaType}`);
      feed.stream.addTrack(pcTrack);
      this.subscriber.streamReadyForHTML(feed.stream, feed.userId);
      return true;
    }

    this.incrementAlarmAndExecute(feed.id, `missing-media-stream-track-${mediaType}`, alarm, () => {
      log.warn(
        `Watchdog: ${this.dryRunLogMessage} ${mediaType} missing for UserId ${userId} FeedId ${feedTrack.feedId}`
      );
      if (!this.dryRun) this.subscriber.reSubscribeFeed(feedTrack.feedId, feedTrack.feedMidId);
    });

    return false;
  }

  private getBytesReceivedByReceiver(trackMap: TrackMap, rtpStats: RtpStats) {
    const obj: { [key: TrackId]: { bytesReceived: number; type: string } } = {};
    Object.values(window.rtc.remoteTracks).forEach((track) => {
      const mappedTrackObj = trackMap[track.label];

      if (!mappedTrackObj) {
        return;
      }

      let mappedTrack: { id: string };
      let type;

      if (mappedTrackObj.audioTrack) {
        mappedTrack = mappedTrackObj.audioTrack;
        obj[mappedTrack.id] = { bytesReceived: rtpStats[mappedTrack.id].audioRTP.bytesReceived, type };
      }

      if (mappedTrackObj.videoTrack) {
        mappedTrack = mappedTrackObj.videoTrack;
        obj[mappedTrack.id] = { bytesReceived: rtpStats[mappedTrack.id].videoRTP.bytesReceived, type };
      }
    });

    return obj;
  }
}
