import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setUsernameWithValidation, submitUsernameAndDisplayNameScreen } from '../../store/signing-in/actions.ts';

// store
import { selectUsername, selectUsernameValidationResult } from '../../store/signing-in/selectors.ts';

// components
import FlowInput from '../FlowInput.tsx';
import FlowInputsContainer from '../FlowInputsContainer.tsx';
import {
  ContainerDefault,
  InputContainer,
  NavButtonsContainer,
  NextNavButton,
  ScreenSubTitle,
  ScreenTitle,
  TitleContainer,
} from '../shared-styled.ts';

const CreateUsernameAndDisplayNameScreen = () => {
  const dispatch = useDispatch();

  const [displayName, setDisplayName] = useState('');

  const username = useSelector(selectUsername);
  const validationResult = useSelector(selectUsernameValidationResult);
  const handleUsernameChange = useCallback(
    async (e) => {
      dispatch(setUsernameWithValidation({ username: e.target.value }));
    },
    [dispatch]
  );
  const handleDisplayNameChange = useCallback(async (e) => {
    setDisplayName(e.target.value);
  }, []);

  const onNextClick = () => {
    dispatch(submitUsernameAndDisplayNameScreen({ displayName }));
  };

  const isValid = displayName && validationResult.isValid && !validationResult.isChecking;
  const errorMessage = !validationResult.isValid ? validationResult.message : '';

  return (
    <ContainerDefault>
      <TitleContainer>
        <ScreenTitle>What's your name?</ScreenTitle>
        <ScreenSubTitle textColor="#101010b2">
          Add your full name and a unique username below. You can change both of these later.
        </ScreenSubTitle>
      </TitleContainer>

      <FlowInputsContainer isValid={isValid} onSubmit={onNextClick}>
        <InputContainer>
          <FlowInput
            label="Name"
            type="text"
            placeholder="Your Name"
            id="set-display-name-input"
            value={displayName}
            onChange={handleDisplayNameChange}
            autoFocus
            testId="create-account-display-name-input-field"
          />
        </InputContainer>
        <InputContainer>
          <FlowInput
            label="Username"
            errorMessage={errorMessage}
            id="set-username-input"
            checkResult={validationResult.isChecking || validationResult.isGenerating ? null : validationResult.isValid}
            prefix="@"
            value={username}
            onChange={handleUsernameChange}
            testId="create-account-username-input-field"
          />
        </InputContainer>
      </FlowInputsContainer>

      <NavButtonsContainer>
        <NextNavButton onClick={onNextClick} disabled={!isValid} data-testid="next-button">
          Next
        </NextNavButton>
      </NavButtonsContainer>
    </ContainerDefault>
  );
};

export default CreateUsernameAndDisplayNameScreen;
