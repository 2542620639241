import BoardElement from './board-element';
import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import log from './log';
import { renderTimerBoardElement } from './react/board-elements/render';

import '../styles/timer.less';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

export default class TimerElement extends BoardElement {
  constructor(elementId) {
    super(elementId);

    this.title = 'Timer';
  }

  elementDescription() {
    return 'timer';
  }

  minSize() {
    return [254, 320];
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, elementDoc) {
    const document = elementDoc.data();
    this.timerDone = document.timerDone;
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(__elementId, __elementDoc) {}

  // Required method
  getElement(elementDoc) {
    const timer = htmlToElement(`
      <div style="height: 100%">
      </div>
    `);
    renderTimerBoardElement(timer, { elementId: this.elementId });
    return wrapElement(timer, elementDoc);
  }

  static async addElement() {
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'CountdownTimerElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 110) + window.innerHeight / 2
        ),
        size: [432, 345],
        zIndex: window.getFrontZIndex(),
        creator: firebase.auth().currentUser.uid,
        originalStartTime: { hour: '00', min: '00', sec: '00' },
        timerState: 'inactive',
        timeStarted: -1,
        timerDone: false,
      })
      .then(() => {
        addSystemMessage('added a timer');
        track(ADD_ELEMENT, { element: ELEMENT_TYPES.COUNTDOWN_TIMER, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
      })
      .catch((error) => {
        log.error(`Something bad happened creating Timer: ${error}`);
      });
  }
}

TimerElement.elementType = 'TimerElement';
