import React, { useState } from 'react';
import styled from 'styled-components';

// components
import UserAvatarWithStatus from '../components/UserAvatarWithStatus';
import { grayShimmerAnimationMixin, whiteShimmerAnimationMixin } from '../mixins';

const MAX_DISPLAY_NAME_LENGTH = 21;

interface UserListItemLayoutProps {
  displayName: string;
  username: string;
  onlineStatus?: string;
  avatar?: {
    thumbnail: string;
  };

  isLoading?: boolean;
  withDarkBackground?: boolean;
  ctaSectionHoverChange?: boolean;

  userCTAsSection?: React.ReactNode;
}

const UserListItemLayout = ({
  displayName,
  username,
  onlineStatus = '',
  avatar,

  isLoading,
  withDarkBackground,
  ctaSectionHoverChange,

  userCTAsSection = null,
}: UserListItemLayoutProps) => {
  const [currHovered, setCurrHovered] = useState(false);

  const userProfile = {
    displayName,
    username,
    avatar,
  };

  return (
    <ListItemContainer
      onMouseEnter={() => setCurrHovered(true)}
      onMouseLeave={() => setCurrHovered(false)}
      isLoading={isLoading}
      withDarkBackground={withDarkBackground}
    >
      <UserContainer>
        <FlexContainer>
          <UserAvatarWithStatus
            userProfile={userProfile}
            status={onlineStatus}
            statusBorderColor={currHovered ? '#e5e5e5' : '#f2f2f2'}
            showOnlineStatusTooltip
            isLoading={isLoading}
          />
          <NamesContainer>
            {isLoading && (
              <>
                <DisplayNameLoading />
                <UsernameLoading />
              </>
            )}
            {displayName && (
              <div
                uk-tooltip={
                  displayName.length > MAX_DISPLAY_NAME_LENGTH ? `title: ${displayName}; pos: top-right` : undefined
                }
              >
                <DisplayName>{displayName}</DisplayName>
              </div>
            )}
            {username && (
              // MAX_DISPLAY_NAME_LENGTH - 1 here is to account for '@'
              <div
                uk-tooltip={
                  username.length > MAX_DISPLAY_NAME_LENGTH - 1 ? `title: @${username}; pos: top-right` : undefined
                }
              >
                <Username>@{username}</Username>
              </div>
            )}
          </NamesContainer>
        </FlexContainer>
      </UserContainer>
      {userCTAsSection && (
        <UserCTAsSection currHovered={currHovered} ctaSectionHoverChange={ctaSectionHoverChange}>
          {userCTAsSection}
        </UserCTAsSection>
      )}
    </ListItemContainer>
  );
};

export default UserListItemLayout;

export const ListItemContainer = styled.div<{
  isLoading?: boolean;
  withDarkBackground?: boolean;
}>`
  position: relative;
  border-radius: 10px;
  background: ${({ theme }) => theme?.userItemBackground || 'rgba(0, 0, 0, 0.05)'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme?.userItemInterval && `margin: ${theme.userItemInterval}px 0;`}
  border: ${({ theme }) => theme?.userItemBorder || 'none'};

  height: ${({ theme }) => theme?.userItemHeight || 70}px;

  &:hover {
    background: ${({ theme }) => theme?.userItemBackgroundHover || 'rgba(0, 0, 0, 0.1)'};
  }

  @media (max-width: 615px) {
    padding-right: 0px;
  }

  ${({ isLoading, withDarkBackground }) =>
    isLoading && (withDarkBackground ? whiteShimmerAnimationMixin() : grayShimmerAnimationMixin())}
`;

const DisplayNameLoading = styled.div`
  border-radius: 8px;
  height: 45%;
  width: 30%;
  background: rgba(255, 255, 255, 0.1);
`;

const UsernameLoading = styled.div`
  border-radius: 8px;
  margin-top: 5px;
  height: 30%;
  width: 15%;
  background: rgba(255, 255, 255, 0.1);
`;

const UserContainer = styled.div`
  padding: 12px 8px 12px 18px;
  box-sizing: border-box;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const NamesContainer = styled.div`
  margin-left: 14px;
  width: 0;
  flex-grow: 1;
`;

export const DisplayName = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme?.userFontColor || '#12002d'};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Username = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme?.userFontColor || '#12002d'};
  opacity: 0.5;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserCTAsSection = styled.div<{
  currHovered: boolean;
  ctaSectionHoverChange: boolean;
}>`
  ${({ currHovered, ctaSectionHoverChange }) => ctaSectionHoverChange && `opacity: ${currHovered ? '1.0' : '0.8'};`}
`;
