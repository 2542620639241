import { createAction, createReducer } from '@reduxjs/toolkit';

import availableThemes from '../../../../assets/data/themes.json';

const initialState = {
  id: null,
  current: null,

  settings: {
    isOpened: false,
    currentTab: null,
  },

  theme: {
    availableList: availableThemes,
    current: null,
  },

  sounds: [],
};

export const roomSwitched = createAction('room/switched');
export const roomDataUpdateRequested = createAction('room/dataUpdateRequested');
export const roomDataUpdated = createAction('room/dataUpdated');
export const roomThemeUpdated = createAction('room/themeUpdated');
export const setIsCreating = createAction('room/setIsCreating');
export const setRoomSounds = createAction('room/setRoomSounds');

export const openRoomSettings = createAction('room/openSettings');
export const closeRoomSettings = createAction('room/closeSettings');
export const setRoomSettingsCurrentTab = createAction('room/setSettingsTab');
export const themeSelected = createAction('room/themeSelected');
export const toggleDisableSound = createAction('room/toggleDisableSound');
export const setThemes = createAction('room/setThemes');

export const selectIsHereClub = (state) => state?.room?.current?.isHereClub || false;

export const roomReducer = createReducer(initialState, {
  [roomSwitched]: (state, { payload: { roomId } }) => {
    state.id = roomId;
    state.current = null;
  },
  [roomDataUpdated]: (state, { payload: { data } }) => {
    state.current = data;
  },
  [roomThemeUpdated]: (state, { payload: { theme } }) => {
    state.theme.current = theme;
  },
  [setRoomSounds]: (state, { payload: { sounds } }) => {
    state.sounds = sounds;
  },
  [openRoomSettings]: (state, { payload: { initialTab } = {} }) => {
    state.settings.isOpened = true;
    if (initialTab) {
      state.settings.currentTab = initialTab;
    }
  },
  [closeRoomSettings]: (state) => {
    state.settings.isOpened = false;
    state.settings.currentTab = null;
  },
  [setRoomSettingsCurrentTab]: (state, { payload: { tabName } }) => {
    state.settings.currentTab = tabName;
  },
  [setThemes]: (state, { payload: { themes } }) => {
    state.theme.availableList = themes;
  },
});
