import React from 'react';
import PropTypes from 'prop-types';

function Star(props) {
  const { color } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0L6.78384 3.84642L9.85673 1.40373L7.98475 4.8541L11.9088 4.95811L8.25698 6.39797L11.1962 9L7.47314 7.75562L8.05212 11.6382L6 8.2918L3.94788 11.6382L4.52686 7.75562L0.803848 9L3.74302 6.39797L0.0911536 4.95811L4.01525 4.8541L2.14327 1.40373L5.21616 3.84642L6 0Z"
        fill={color}
      />
    </svg>
  );
}

Star.propTypes = {
  color: PropTypes.string,
};

Star.defaultProps = {
  color: '#39C360',
};

export default Star;
