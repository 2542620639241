import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ChatSettings from './ChatSettings';
import FolderTabs, { TabData } from '../../../components/FolderTabs';
import AdminSettings from './admin-settings';
import { selectCurrentUserRoleInChat, selectIsAPublicChat } from '../../../store/messaging/selectors';
import AdminIcon from '../../../../../assets/icons/admin-crown.svg';
import OwnerIcon from '../../../../../assets/icons/owner-crown.svg';
import { svgColorMixin } from '../../../mixins';
import { ChatMemberRoles } from '../../../../definitions/message';

interface Props {
  chatId: string;
  closeWindow: () => void;
}

const EditChatSettings: React.FC<Props> = ({ chatId, closeWindow }) => {
  const isPublic = useSelector((state) => selectIsAPublicChat(state, chatId));
  const currentUserRole = useSelector((state) => selectCurrentUserRoleInChat(state, chatId));

  const tabs = useMemo(() => {
    const result: TabData[] = [{ name: 'Settings', node: <ChatSettings chatId={chatId} closeWindow={closeWindow} /> }];

    if (isPublic) {
      let icon = null;
      if (currentUserRole !== ChatMemberRoles.Member) {
        icon = (
          <IconContainer>{currentUserRole === ChatMemberRoles.Admin ? <AdminIcon /> : <OwnerIcon />}</IconContainer>
        );
      }

      result.push({ name: <>{icon} Manage Users</>, node: <AdminSettings chatId={chatId} /> });
    }

    return result;
  }, [chatId, closeWindow, currentUserRole, isPublic]);

  return (
    <Container>
      <FolderTabs tabs={tabs} />
    </Container>
  );
};

export default EditChatSettings;

const Container = styled.div`
  height: 100%;
`;

const IconContainer = styled.div`
  background: var(--secondary-foreground);
  display: inline-block;
  margin-right: 1px;
  border-radius: 50%;
  padding: 2px;
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  position: relative;
  bottom: 1px;

  ${svgColorMixin('var(--secondary-background)')}
`;
