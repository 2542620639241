import orangeFlameAnimation from '../../assets/lottie/flames/orange-flame.json';
import blueFlameAnimation from '../../assets/lottie/flames/blue-flame.json';
import greenFlameAnimation from '../../assets/lottie/flames/green-flame.json';

import ServerDate from './server-date';

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export const calculateStreakInRoom = async (streakStartedAt) => {
  const timeNow = await ServerDate.getTime();
  let streakStartedAtDate;

  if (typeof streakStartedAt === 'number') {
    streakStartedAtDate = new Date(streakStartedAt);
  } else {
    streakStartedAtDate = streakStartedAt.toDate();
  }

  return Math.ceil((timeNow - streakStartedAtDate) / ONE_DAY_IN_MS);
};

const STREAK_LEVEL_COLORS = { 1: '236, 82, 29', 2: '53,15,255', 3: '32,197,125' };

export const STREAK_LEVEL_ANIMATIONS = {
  1: orangeFlameAnimation,
  2: blueFlameAnimation,
  3: greenFlameAnimation,
};

export const getStreakLevel = (streak) => {
  if (streak >= 15) return 3;
  if (streak > 5) return 2;
  return 1;
};

export const getWidgetColor = (streak) => {
  const streakLevel = getStreakLevel(streak);
  return STREAK_LEVEL_COLORS[streakLevel];
};

export const getWidgetGif = (streak) => {
  const streakLevel = getStreakLevel(streak);
  return STREAK_LEVEL_ANIMATIONS[streakLevel];
};

export const getShowLobbyRoomStreak = (streak) => streak >= 2;
