import { isNumber } from 'lodash';
import log from '../log';
import { isHereEmployeeCheck } from '../util/user-util';
import CameraPublisher from './camera_publisher';
import { MetricRateKeys, MetricRate, PublisherType } from './definitions/index.definitions';
import { MediaType } from './janus/janus.definitions';
import StreamConstraints, { BitrateBoundary } from './stream-constraints';

interface CameraLiteModeElement extends HTMLElement {
  askToLowerQuality: () => Promise<boolean>;
  askToTestHigherQuality: () => Promise<boolean>;
  askToShutdownVideo: () => Promise<boolean>;
  askTurnVideoBackOn: () => Promise<boolean>;
}

enum FlowSteps {
  Pending = 1,
  AcceptedLowerQuality,
  AcceptedVideoOff,
  Completed,
}

const DIALOG_WAIT_MS = 1000 * 60;

const BOUNDARY_CHANGE_WAIT_MS = 1000 * 60;

export default class CameraLiteMode {
  private publisher: CameraPublisher;

  private constraints: StreamConstraints;

  private isEmployee = false;

  private flow = FlowSteps.Pending;

  private lastRateProcessed: number;

  private _rateInSeconds: MetricRate = {
    [MetricRateKeys.NackCount]: 0,
    [MetricRateKeys.PliCount]: 0,
    [MetricRateKeys.FirCount]: 0,
    [MetricRateKeys.QualityBandwidth]: 0,
    [MetricRateKeys.QualityCpu]: 0,
  };

  private dialogsElement: CameraLiteModeElement;

  constructor(publisher: CameraPublisher, constraints: StreamConstraints) {
    this.publisher = publisher;
    this.constraints = constraints;
    this.dialogsElement = document.querySelector('here-camera-lite-mode');
    if (!this.dialogsElement) {
      this.dialogsElement = document.createElement('here-camera-lite-mode') as CameraLiteModeElement;
      document.getElementById('main').appendChild(this.dialogsElement);
    }
    isHereEmployeeCheck().then((value) => {
      this.isEmployee = value || process.env.NODE_ENV === 'development';
    });
  }

  get rateInSeconds() {
    return this._rateInSeconds;
  }

  public set rateInSeconds(rateInSeconds: MetricRate) {
    this._rateInSeconds = rateInSeconds;

    if (this.publisher.publisherType !== PublisherType.Camera) {
      return;
    }

    // Uncomment this code to test
    // this.rateInSeconds['qualityLimitationDurations.bandwidth'] =
    //  window.dummyValue || this.rateInSeconds['qualityLimitationDurations.bandwidth'];

    this.lowBoundaryApproach();
    this.dialogApproach();

    this.lastRateProcessed = new Date().getTime();
  }

  dialogApproach() {
    if (!this.isEmployee) {
      return;
    }

    if (this.flow === FlowSteps.Completed) {
      return;
    }

    if (new Date().getTime() - this.lastRateProcessed < DIALOG_WAIT_MS) {
      return;
    }

    if (this.hasBeenDegradationInQuality()) {
      if (this.flow === FlowSteps.Pending && this.constraints.bitrateBoundary === BitrateBoundary.High) {
        this.askToLowerQuality();
      } else if (this.flow === FlowSteps.Pending && this.constraints.bitrateBoundary === BitrateBoundary.Low) {
        this.flow = FlowSteps.AcceptedLowerQuality;
        this.askToShutdownVideo();
      } else if (this.flow === FlowSteps.AcceptedLowerQuality) {
        if (this.publisher.isVideoOn) {
          this.askToShutdownVideo();
        } else {
          this.flow = FlowSteps.AcceptedVideoOff;
        }
      }
    }

    if (this.hasBeenAnImprovementInQuality()) {
      if (this.flow === FlowSteps.AcceptedLowerQuality) {
        this.askToTestHigherQuality();
      } else if (this.flow === FlowSteps.AcceptedVideoOff) {
        if (this.publisher.isVideoOn === false) {
          this.askTurnVideoBackOn();
        } else {
          this.flow = FlowSteps.AcceptedLowerQuality;
        }
      }
    }
  }

  hasBeenDegradationInQuality() {
    if (
      isNumber(this.rateInSeconds[MetricRateKeys.QualityBandwidth]) &&
      this.rateInSeconds[MetricRateKeys.QualityBandwidth] > 0.0
    ) {
      return true;
    }
    if (
      isNumber(this.rateInSeconds[MetricRateKeys.QualityCpu]) &&
      this.rateInSeconds[MetricRateKeys.QualityCpu] > 0.0
    ) {
      return true;
    }
    return false;
  }

  hasBeenAnImprovementInQuality() {
    if (
      isNumber(this.rateInSeconds[MetricRateKeys.QualityBandwidth]) &&
      this.rateInSeconds[MetricRateKeys.QualityBandwidth] === 0.0
    ) {
      if (
        isNumber(this.rateInSeconds[MetricRateKeys.QualityCpu]) &&
        this.rateInSeconds[MetricRateKeys.QualityCpu] === 0.0
      ) {
        return true;
      }
    }

    return false;
  }

  askToLowerQuality() {
    // this.dialogsElement.askToLowerQuality().then((accepted: boolean) => {
    //   if (accepted) {
    log.log('Accepted to lower video quality');
    this.flow = FlowSteps.AcceptedLowerQuality;
    this.constraints.bitrateBoundary = BitrateBoundary.Low;
    //   } else {
    //     log.log('Rejected to lower video quality');
    //     this.flow = FlowSteps.Completed;
    //   }
    // });
  }

  askToTestHigherQuality() {
    // this.dialogsElement.askToTestHigherQuality().then((accepted: boolean) => {
    //  if (accepted) {
    log.log('Accepted test higher video quality');
    this.flow = FlowSteps.Pending;
    this.constraints.bitrateBoundary = BitrateBoundary.High;
    //  } else {
    //    log.log('Rejected test higher video quality');
    //    this.flow = FlowSteps.Completed;
    //  }
    // });
  }

  askToShutdownVideo() {
    this.dialogsElement.askToShutdownVideo().then((accepted: boolean) => {
      if (accepted) {
        log.log('Accepted turn video off');
        this.flow = FlowSteps.AcceptedVideoOff;
        this.publisher.muteLocal(MediaType.Video);
      } else {
        log.log('Rejected turn video off');
        this.flow = FlowSteps.Completed;
      }
    });
  }

  askTurnVideoBackOn() {
    this.dialogsElement.askTurnVideoBackOn().then((accepted: boolean) => {
      if (accepted) {
        log.log('Accepted turn video on');
        this.flow = FlowSteps.AcceptedLowerQuality;
        this.publisher.unmuteLocal(MediaType.Video);
      } else {
        log.log('Rejected turn video on');
        this.flow = FlowSteps.Completed;
      }
    });
  }

  lowBoundaryApproach() {
    if (new Date().getTime() - this.lastRateProcessed < BOUNDARY_CHANGE_WAIT_MS) {
      return;
    }

    if (this.hasBeenDegradationInQuality()) {
      this.constraints.lock = false;
      this.constraints.bitrateBoundary = BitrateBoundary.Low;
      this.constraints.lock = true;
    } else if (this.hasBeenAnImprovementInQuality()) {
      this.constraints.lock = false;
      this.constraints.bitrateBoundary = BitrateBoundary.High;
    }
  }
}
