import React, { BaseSyntheticEvent, useMemo } from 'react';
import styled from 'styled-components';
import { FormikHelpers, FormikValues } from 'formik';
import { getCountries, getCountryCallingCode, Country } from 'react-phone-number-input';
import label from 'react-phone-number-input/locale/en.json';
import { FormLabel, getFlagEmoji } from './phone-util';
import { HereDropdown } from '../components/inputs';

const PhoneNumber = ({
  isDisabled = false,
  countryCode = 'US',
  phoneNumber = '',
  setFieldValue = () => {},
  hasError = false,
  handleCountrySelect = () => {},
  autoFocus = false,
  flexBasis = '40%',
}: {
  isDisabled?: boolean;
  countryCode?: Country;
  phoneNumber?: string;
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
  hasError?: boolean;
  handleCountrySelect?: (country: Country) => void;
  autoFocus?: boolean;
  flexBasis: string;
}) => {
  const selectedValue = useMemo(
    () => `${getFlagEmoji(countryCode)} +${getCountryCallingCode(countryCode)}`,
    [countryCode]
  );

  const countryDropdownList = useMemo(() => {
    const countries = getCountries()
      .map((c) => ({
        key: c,
        label: label[c],
        value: (
          <CountryDropdownOption>
            <CountryLabel>
              {getFlagEmoji(c)} {label[c]}
            </CountryLabel>
            <span> +{getCountryCallingCode(c)}</span>
          </CountryDropdownOption>
        ),
      }))
      // sort in alphabetical order
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    return countries;
  }, []);

  const handlePhoneInputChange = useMemo(
    () => (e: BaseSyntheticEvent) => {
      const inputValue = e.target.value.replace(/-/g, '');
      if (/^\d{0,15}$/.test(inputValue)) {
        setFieldValue('phoneNumber', inputValue);
      }
    },
    [setFieldValue]
  );

  return (
    <>
      <FormLabel>Phone Number</FormLabel>
      <Container className="unthemed" isDisabled={isDisabled} hasError={hasError}>
        <CountryCodeContainer flexBasis={flexBasis}>
          <HereDropdown
            smallStyle
            selectedValue={selectedValue}
            onSelect={handleCountrySelect}
            options={countryDropdownList}
            customDropdownStyles={{
              height: '200px',
              width: '320px',
            }}
            inputTextColor={isDisabled ? 'grey' : 'black'}
            isDisabled={isDisabled}
            showOutline={false}
            dropdownHeaderTestId="country-code-dropdown-header"
            dropdownListContainerTestId="country-code-dropdown-list-container"
          />
        </CountryCodeContainer>
        <Divider />
        <PhoneInput
          name="phoneNumber"
          value={phoneNumber}
          onChange={handlePhoneInputChange}
          placeholder="123 456-7890"
          disabled={isDisabled}
          autoFocus={autoFocus}
          data-testid="phone-number-input-field"
        />
      </Container>
    </>
  );
};

const Container = styled.div<{ isDisabled: boolean; hasError: boolean }>`
  border-radius: 5px;
  position: relative;
  height: 38px;
  display: flex;
  align-items: center;
  border: ${({ isDisabled }: { isDisabled: boolean }) => (isDisabled ? '1px solid rgba(0, 0, 0, 0.2)' : 'none')};
  outline: 1px solid var(--primary-foreground-alpha-20, rgba(0, 0, 0, 0.2));
  background: ${({ isDisabled }: { isDisabled: boolean }) => (isDisabled ? 'rgba(196, 196, 196, 0.15)' : 'none')};

  &:focus-within {
    outline: 2px solid #6b1be3;
  }

  ${(props: { hasError: boolean }) =>
    props.hasError &&
    `
    outline: 2px solid rgba(246, 51, 93, 1);

    &:focus-within {
      outline: 2px solid rgba(246, 51, 93, 1);
    }
  `}
`;

const CountryCodeContainer = styled.div<{ flexBasis: string }>`
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: ${({ flexBasis }) => flexBasis};
`;

const PhoneInput = styled.input`
  border: none;
  height: 100%;
  flex: 3 0 55%;
  font-weight: bold;

  &::placeholder {
    font-weight: normal;
    color: #c1bdc8;
  }

  &:focus {
    outline: none;
  }
`;

const Divider = styled.hr`
  border-left: 1px solid var(--primary-foreground-alpha-20, rgba(0, 0, 0, 0.2));
  margin: 10px;
  height: 25px;
`;

const CountryDropdownOption = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CountryLabel = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default PhoneNumber;
