import UIkit from 'uikit';

import firebase, { db } from './firebase';

// utils
import { htmlToElement } from './util';

import '../styles/feedback.less';

import { checkIsElectron } from './util/platform-util';
import reduxAdapter from './react/store/redux-adapter';
import { selectCurrentUserIsAdmin } from './react/store/users/selectors';
import { track } from './util/analytics-util';

const newImagePath = 'images/icons/new.png';
const hiImagePath = 'images/icons/hi.png';

let jsonUpdates = [];
let currentUpdates;
let newUpdateCount = 0;

let isCurrentUserAdmin = false;
reduxAdapter.subscribe((state) => {
  isCurrentUserAdmin = selectCurrentUserIsAdmin(state);
});

export function getCurrentUpdates() {
  return currentUpdates;
}

export function getUpdates() {
  return (jsonUpdates || []).filter(
    (update) => !update.important && (!update.employeeOnly || (update.employeeOnly && isCurrentUserAdmin))
  );
}

/**
 *
 * Returns the feedback menu view to its original state
 */
export function resetFeedbackMenu() {
  document.getElementById('feedback-tabs-content').classList.remove('feedback-tabs-content--closed');
  document.getElementById('unvieved-updates-container').innerHTML = '';
}

export function getNewUpdatesCount() {
  return newUpdateCount;
}

/**
 *
 * Looks for the passed update in the array of viewed updates.
 *
 * @param {object} update
 * @returns {boolean} - whether the submitted update has not been viewed yet.
 */
function isUpdateNew(update) {
  // No saved updates
  if (currentUpdates === null) {
    return true;
  }

  return !currentUpdates.some(
    (currentUpdate) => update.title === currentUpdate.title && update.date === currentUpdate.date
  );
}

export function createNewUpdateTagHTML(textContent) {
  return `<p class="new-update-tag">${textContent}</p>`;
}

// event handlers

function renderUnviewedUpdatesCount(unviewedUpdatesCount) {
  const unvievedUpdatesTag = htmlToElement(
    createNewUpdateTagHTML(unviewedUpdatesCount < 10 ? `0${unviewedUpdatesCount}` : unviewedUpdatesCount)
  );

  document.getElementById('unvieved-updates-container').appendChild(unvievedUpdatesTag);
}

function renderUpdatesList() {
  const feedbackUpdates = document.getElementById('feedback-updates');
  feedbackUpdates.innerHTML = '';
  feedbackUpdates.appendChild(htmlToElement('<here-whats-new />'));
}

export async function loadUpdates(shouldOverwrite) {
  const currentUpdatesJSON = localStorage.getItem('here-updates');
  currentUpdates = JSON.parse(currentUpdatesJSON);

  if (!jsonUpdates.length) {
    // Get updates from firestore (sorted by date), go through and see if they're new
    const updates = await db.collection('updates').orderBy('createdAt', 'desc').get();

    jsonUpdates = updates.docs.map((doc) => {
      const { date, description, link, title, important, video, img } = doc.data();

      return {
        date,
        description,
        title,
        link,
        important,
        video,
        img,
      };
    });
  }

  newUpdateCount = 0;
  jsonUpdates.forEach((update) => {
    if (isUpdateNew(update)) {
      update.isNew = true;
      newUpdateCount += 1;
    } else {
      update.isNew = false;
    }
  });

  const unvievedUpdatesCount = newUpdateCount;

  if (shouldOverwrite) {
    localStorage.setItem('here-updates', JSON.stringify(jsonUpdates));
    newUpdateCount = 0;
  }

  return unvievedUpdatesCount;
}

window.openFeedbackTab = function openFeedbackTab(tabId) {
  // Hide all tab content
  const tabcontent = Array.from(document.getElementsByClassName('feedback-menu-tab'));
  tabcontent.forEach((tab) => {
    tab.style.display = 'none';
  });

  // Turn off buttons
  const tablinks = Array.from(document.getElementsByClassName('feedback-tab-link-active'));
  tablinks.forEach((tabLink) => {
    tabLink.classList.remove('feedback-tab-link-active');
  });

  // Show current tab
  const el = document.getElementById(`${tabId}-content`);
  el.style.display = 'block';
  document.getElementById(tabId).classList.add('feedback-tab-link-active');

  if (tabId !== 'feedback-whats-new') {
    document.getElementById('unvieved-updates-container').innerHTML = '';
  }

  if (tabId === 'feedback-whats-new') {
    newUpdateCount = 0;
    updateBadgeIcon();
    const unviewedUpdatesCount = loadUpdates(false);
    if (unviewedUpdatesCount > 0) {
      renderUnviewedUpdatesCount(unviewedUpdatesCount);
    }

    track("Opened What's New menu");
  }

  renderUpdatesList();
};

export function updateBadgeIcon() {
  Array.from(document.getElementsByClassName('update-badge')).forEach((el) => {
    if (newUpdateCount === 0) {
      el.innerHTML = `<img style="width: 60%;" src="${hiImagePath}" />`;
      el.classList = 'feedback-badge-hi';
    } else {
      el.innerHTML = `<img style="width: 85%;" src="${newImagePath}" />`;
      el.classList = 'feedback-badge-new';
    }
  });
}

let lastImportantUpdate = null;

function markImportantUpdateotificationAsViewed({ target }) {
  UIkit.notification.closeAll();
  window.localStorage.setItem('lastViewedImportantUpdateDate', lastImportantUpdate.date);
  target.removeEventListener('click', markImportantUpdateotificationAsViewed);
  document.getElementById('main').removeEventListener('click', markImportantUpdateotificationAsViewed);
}

function showImportantUpdateNotification(updateData) {
  let assetsBlock = '';
  if (updateData.video) {
    assetsBlock = `<div class="assets=block"><video src="${updateData.video}" controls playsinline uk-video></video></div>`;
  } else if (updateData.img) {
    assetsBlock = `<div class="assets=block"><img src="${updateData.img}"></div>`;
  }

  UIkit.notification({
    pos: 'bottom-left',

    timeout: 500000,

    message: `
      <div id="important-update-notification" class="important-update-notification">
        ${assetsBlock}
        <div class="text-block">
          <div class="title">
            <h3>${updateData.title}
              <div class="new-badge new-update-tag">NEW!</div>
            </h3>
            <div class="update-date">${updateData.date}</div>
          </div>
  
          <p>
            <span>${updateData.popupDescription ? updateData.popupDescription : updateData.description} </span>
            <a href="${updateData.link}" target="_blank" class="feedback-link">Learn more -&gt;</a>
          </p>
        </div>
      </div>`,
  });

  const notification = document.getElementById('important-update-notification');
  notification.addEventListener('click', markImportantUpdateotificationAsViewed);
  document.getElementById('main').addEventListener('click', markImportantUpdateotificationAsViewed);
}

export function showLastUnviewedImportantUpdateNotification() {
  // TODO: allow these in Electron and allow platform specificity for these updates
  if (firebase.auth().currentUser && !checkIsElectron()) {
    const lastViewedImportantUpdateDate = window.localStorage.getItem('lastViewedImportantUpdateDate');
    lastImportantUpdate = jsonUpdates.find((update) => update.important);
    if (
      lastImportantUpdate &&
      (!lastViewedImportantUpdateDate || new Date(lastImportantUpdate.date) > new Date(lastViewedImportantUpdateDate))
    ) {
      if (!lastImportantUpdate.employeeOnly || (lastImportantUpdate.employeeOnly && isCurrentUserAdmin)) {
        showImportantUpdateNotification(lastImportantUpdate);
      }
    }
  }
}
