import React, { useRef } from 'react';

import { useSettingsTabsAdapter } from '../useSettingsTabsAdapter';

const NotificationSettings = () => {
  const webComponentRef = useRef();
  useSettingsTabsAdapter(webComponentRef);
  return <here-notifications-tab ref={webComponentRef} />;
};

export default NotificationSettings;
