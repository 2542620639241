import log from '../log';
import { boardUsers } from '../presence';
import reduxAdapter from '../react/store/redux-adapter';
import { selectActiveGroupId } from '../react/store/web-lobby/selectors.ts';
import { checkIsElectron, checkIsElectronBrowser } from './platform-util';
import { calculateStreakInRoom } from './room-streak-util';
import { selectFullChatMembersByChatId, selectChatByChatId } from '../react/store/messaging/selectors';

const logAnalytics = () =>
  window.logAnalytics || ['true', '1'].includes(process.env.LOG_ANALYTICS?.toString().toLowerCase());

let friendCount;
let globalActivityId;
let electronVersion;

let initialized = false;
let identified = false;
let identInfo = null;

let project = null;

function isProduction() {
  return window.location.host.includes('here.fm');
}

function isSandbox() {
  return (
    window.location.host.includes('here-sandbox.web.app') ||
    window.location.host.includes('here-sandbox.firebaseapp.com')
  );
}

function identifyInternal(userId, opts) {
  try {
    if (window.analytics.identify) {
      window.analytics.identify(userId, opts);
      identified = true;
    } else {
      log.warn('analytics.identify not found, not adding user info to analytics');
    }
  } catch (err) {
    log.error(`Error in window.analytics.identify: ${err}`);
  }
}

function initInternal(projectId) {
  if (isProduction() || isSandbox()) {
    if (!window.analytics?.initialize) {
      window.analytics = undefined;
    }

    window.setupAnalytics(projectId);
    initialized = true;
  }
}

export function initAnalytics(projectId) {
  project = projectId;
  // Defer initialization for Electron until our first event
  if (checkIsElectron() || checkIsElectronBrowser()) {
    return;
  }
  initInternal(projectId);
}

export function setEventsFriendCount(count) {
  friendCount = count;
}

export function setGlobalActivityId(id) {
  globalActivityId = id;
}

export function setElectronVersion(version) {
  electronVersion = version;
}

export function identifyAnalytics(userId, opts) {
  identInfo = { userId, opts };

  // Defer identify calls when running Electron so we don't start sessions
  // immediately upon app launch.
  if (checkIsElectron() || checkIsElectronBrowser()) {
    return;
  }

  identifyInternal(userId, opts);
}

// Tracks events and amends data with common info we generally want to know.
export async function track(event, params) {
  if ((!initialized && project) || window.analyticsProjectId) {
    initInternal(project || window.analyticsProjectId);
  }
  if (!identified && identInfo) {
    identifyInternal(identInfo.userId, identInfo.opts);
  }

  const allParams = { ...params };
  if (window.currentBoardId) {
    allParams.roomId = window.currentBoardId;
  }
  if (window.currentBoardData) {
    const { discordConfig } = window.currentBoardData;

    if (discordConfig && discordConfig.serverId && discordConfig.channelId) {
      allParams.discordServerId = discordConfig.serverId;
      allParams.discordChannelId = discordConfig.channelId;
    }
  }

  if (window.currentBoardId && boardUsers[window.currentBoardId]) {
    const users = Object.keys(boardUsers[window.currentBoardId]);
    if (users && users.length) {
      allParams.userCount = users.length;
    }
  }
  if (window.currentBoardData && window.currentBoardData.streakStartedAt) {
    allParams.currentRoomStreak = await calculateStreakInRoom(window.currentBoardData.streakStartedAt);
  }

  if (window.currentBoardData && window.currentBoardData.groupId) {
    allParams.groupId = window.currentBoardData.groupId;
  }
  if (window.rtc?.serverPrefix) {
    allParams.videoServer = window.rtc.serverPrefix;
  }
  if (friendCount) {
    allParams.friendCount = friendCount;
  }
  if (globalActivityId) {
    allParams.globalActivityId = globalActivityId;
  }

  if (electronVersion) {
    allParams.electronVersion = electronVersion;
  } else if (checkIsElectron()) {
    const version = await window.bridge.getAppVersion();
    setElectronVersion(version);
    allParams.electronVersion = version;
  }

  window.analytics.track(event, allParams);
  if (logAnalytics()) {
    log.internal('%ctrack', 'background: blue; color: white', event, params);
  }
}

export function getHubTrackParams(params) {
  const state = reduxAdapter.latestState;
  const extendedParams = { ...params };
  const groupId = selectActiveGroupId(state);
  if (extendedParams.groupId || groupId) {
    extendedParams.groupId = extendedParams.groupId || groupId;
  }
  return extendedParams;
}

export const getGroupChatParams = (params, chatId) => {
  const resultChatId = params.chatId || chatId;
  if (!resultChatId) {
    return params;
  }

  const extendedParams = { ...params };
  const state = reduxAdapter.latestState;

  const chat = selectChatByChatId(state, resultChatId);
  if (!chat) {
    return params;
  }

  const fullChatMembers = selectFullChatMembersByChatId(state, resultChatId);
  const isPublic = !!chat.isPublic;

  // '+1' is for the current user
  extendedParams.memberCount = (fullChatMembers.length || 0) + 1;
  extendedParams.onlineMemberCount =
    (fullChatMembers.filter((member) => member?.onlineStatus?.status === 'online' || member?.onlineStatus?.status === 1)
      .length || 0) + 1;

  extendedParams.isPublic = !!isPublic;

  const chatResultName = chat.title || chat.groupName;
  if (chat.title || chat.groupName) {
    extendedParams.name = chatResultName;
  }

  return extendedParams;
};

export async function trackInForeground(event, params) {
  if (!checkIsElectron() || !document.hidden) {
    track(event, params);
  }
}
