import UIkit from 'uikit';
import { getDesktopAppBinariesLink } from '../../util/electron-util';

import template from './open-in-desktop-modal.html';

import './open-in-desktop-modal.less';

class OpenInDesktopModal extends HTMLElement {
  constructor() {
    super();

    this.innerHTML = template;

    this.modal = null;
    this.roomUrl = null;

    // binds
    this.beforeHide = this.beforeHide.bind(this);
    this.setModalTitle = this.setModalTitle.bind(this);
    this.setOpenInWebAppLinkUrl = this.setOpenInWebAppLinkUrl.bind(this);
    this.setDownloadAppLinkUrl = this.setDownloadAppLinkUrl.bind(this);

    // elements
    this.openInDesktopModalTitleEl = null;
  }

  beforeHide() {
    this.setModalTitle('');
    this.querySelector('#download-desktop-app-link').classList.add('hidden');
    this.setOpenInWebAppLinkUrl('javascript:void(0)');
    this.setDownloadAppLinkUrl('javascript:void(0)');
  }

  open({ roomTitle, roomUrl, desktopAppOpened }) {
    if (!this.modal) {
      this.modal = UIkit.modal(this, {});
      UIkit.util.on(this.modal, 'beforehide', this.beforeHide);
    }

    if (desktopAppOpened) {
      this.setModalTitle(`Opened "${roomTitle}" in Here App`);
    } else {
      this.setModalTitle(`Seems like you don't have our desktop app installed yet :'(`);
    }

    const desktopAppBinariesLink = getDesktopAppBinariesLink();
    if (desktopAppBinariesLink && !desktopAppOpened) {
      this.querySelector('#download-desktop-app-link').classList.remove('hidden');
      this.setDownloadAppLinkUrl(desktopAppBinariesLink);
    }

    this.setOpenInWebAppLinkUrl(roomUrl);

    this.modal.show();
  }

  setModalTitle(title) {
    this.querySelector('#open-in-desktop-modal-title').textContent = title;
  }

  setDownloadAppLinkUrl(url) {
    this.querySelector('#download-desktop-app-link').setAttribute('href', url);
  }

  setOpenInWebAppLinkUrl(url) {
    this.querySelector('#open-in-web-app-link').setAttribute('href', url);
  }
}

window.customElements.define('here-open-in-desktop-modal', OpenInDesktopModal);

export default new OpenInDesktopModal();
