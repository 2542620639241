import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  friendByIdSelector,
  incomingPendingRequestsByRequesterSelector,
  selectPendingOrIgnoredSentRequest,
  selectCanAddToFriend,
} from '../../store/friends/selectors';
import { selectCurrentUserId } from '../../store/users/selectors';
import { fetchFriends } from '../../store/friends/store';
import { createFriendRequest, approveFriendRequest, ignoreFriendRequest } from '../../store/friends/api';
import { addAnimation } from '../../store/animations/store';
import { ANIMATION_NAMES } from '../../store/animations/constants';

export const useFriendingButtons = (userId) => {
  const dispatch = useDispatch();
  const [friendAccepted, setFriendAccepted] = useState(false);
  const currentUserId = useSelector(selectCurrentUserId);
  const isCurrentUser = userId === currentUserId;
  const canAddToFriend = useSelector((state) => selectCanAddToFriend(state, userId));

  const isFriends = useSelector((state) => friendByIdSelector(state, userId));
  const [friendRequested, setFriendRequested] = useState(false);
  const incomingPendingRequests = useSelector((state) => incomingPendingRequestsByRequesterSelector(state, userId));
  const pendingOrIgnoredRequestSent = useSelector((state) => selectPendingOrIgnoredSentRequest(state, userId));

  const requestSent = pendingOrIgnoredRequestSent || friendRequested;

  const showAddFriend = canAddToFriend && !friendRequested && !friendAccepted && !incomingPendingRequests;
  const showAcceptFriend = incomingPendingRequests && !friendAccepted;
  const showPendingIcon = requestSent && !isFriends && !incomingPendingRequests;

  useEffect(() => {
    setFriendRequested(false);
    setFriendAccepted(false);
  }, [isFriends]);

  useEffect(() => {
    if (incomingPendingRequests) {
      setFriendRequested(false);
    }
  }, [incomingPendingRequests]);

  useEffect(() => {
    if (!isCurrentUser) {
      dispatch(fetchFriends());
    }
  }, [dispatch, isCurrentUser]);

  // Don't forget to catch the errors when using the handlers
  const addFriendHandler = async () => {
    try {
      setFriendRequested(true);
      const response = await createFriendRequest(userId);
      if (response && response.success) {
        return response;
      }
      throw new Error('Adding Friend Failed');
    } catch (error) {
      setFriendRequested(false);
      throw new Error('Add Friend Handler Failed');
    }
  };

  const acceptFriendHandler = async () => {
    try {
      setFriendAccepted(true);
      const response = await approveFriendRequest(userId);

      if (response && response.success) {
        dispatch(fetchFriends());
        dispatch(addAnimation({ name: ANIMATION_NAMES.confettiBurst, id: `${userId}-friendship-confetti` }));
        return response;
      }
      throw new Error('Accepting Friend Request Failed');
    } catch (error) {
      setFriendAccepted(false);
      throw new Error('Accept Friend Handler Failed');
    }
  };

  const ignoreFriendHandler = async () => {
    try {
      const response = await ignoreFriendRequest(userId);

      if (response && response.success) {
        return response;
      }
      throw new Error('Ignore Friend Request Failed');
    } catch (error) {
      throw new Error('Ignore Friend Handler Failed');
    }
  };

  return {
    showAddFriend,
    showAcceptFriend,
    showPendingIcon,
    addFriendHandler,
    acceptFriendHandler,
    ignoreFriendHandler,
  };
};
