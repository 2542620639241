import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cloneDeep from 'lodash/fp/cloneDeep';

import { selectChatByChatId } from '../../../store/messaging/selectors';
import { selectChatSkins } from '../../../store/chat-skinning/selectors';
import { requestGroupUpdate } from '../../../store/groups/actions';
import SkinningItemPicker from './SkinningItemPicker';
import { getGroupChatParams, track } from '../../../../util/analytics-util';
import { CHAT_EDIT_SKIN } from '../../analytics';
import ThemePreview from '../../../room/settings/theme/ThemePreview';
import { noScrollbarMixin } from '../../../mixins';

interface Props {
  chatId: string;
}

const ChatSkinningPresets = ({ chatId }: Props) => {
  const dispatch = useDispatch();

  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const selectedSkinName = chat?.theme?.skin?.name;

  const skins = useSelector(selectChatSkins);
  const onSkinChange = useCallback(
    (skin) => {
      const updates = {
        background: skin.background.original,
        theme: {
          isCustom: false,
          ...(skin.colors ? { colors: skin.colors } : {}),
          skin: cloneDeep(skin),
        },
      };

      delete updates.theme.skin.colors;
      delete updates.theme.skin.type;
      delete updates.theme.skin.created_at;
      delete updates.theme.skin.deleted_at;
      delete updates.theme.skin.particle?.thumbnail;
      delete updates.theme.skin.employeesOnly;

      dispatch(requestGroupUpdate({ id: chat.groupId, updates }));
      const analyticsParams = {
        chatId,
        category: 'theme',
        name: skin?.name || '',
        oldName: chat.theme?.skin?.name || '',
      };
      track(CHAT_EDIT_SKIN, getGroupChatParams(analyticsParams, chatId));
    },
    [chat.groupId, chat.theme?.skin?.name, chatId, dispatch]
  );

  const items = useMemo(
    () =>
      skins.map((s) => ({
        id: s.id,
        name: s.name,
        preview: (
          <SkinPreview background={s.background.thumbnail}>
            {s.colors ? (
              <ColorsPreviewContainer>
                <ThemePreview colors={s.colors} width={70} height={36} />
              </ColorsPreviewContainer>
            ) : null}
          </SkinPreview>
        ),
        isSelected: s.name === selectedSkinName,
        onSelect: () => onSkinChange(s),
        onRemove: () => onSkinChange(null),
      })),
    [onSkinChange, selectedSkinName, skins]
  );

  return (
    <Container>
      <SkinningItemPicker items={items} />
    </Container>
  );
};

export default ChatSkinningPresets;

const Container = styled.div`
  height: 100%;
  border-radius: 0 16px 16px 16px;
  background: var(--primary-background);
  overflow: auto;
  padding: 16px;

  ${noScrollbarMixin()}
`;

const SkinPreview = styled.div<{ background: string }>`
  aspect-ratio: 2/3;
  width: 100%;
  background: url('${({ background }) => background}');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ColorsPreviewContainer = styled.div`
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
`;
