// param: elementType
export const ENABLE_DECK = 'Enable Deck';
// param: elementType
export const DISABLE_DECK = 'Disable Deck';

// param: element, source
export const DUPLICATE_ELEMENT = 'Duplicate Element';

// param: element, source (when element: image), destination
export const ADD_ELEMENT = 'Add Element';

export const ADD_ELEMENT_DESTINATION_TYPES = {
  MURAL: 'mural',
  ROOM: 'room',
  CARD: 'card',
  OS: 'os',
};

export const ADD_ELEMENT_SOURCE_TYPES = {
  SEARCH: 'search',
  DECORATIONS: 'decorations',
  UPLOAD_FROM_COMPUTER: 'upload from computer',
  DRAGGED_FROM_COMPUTER: 'dragged from computer',
  PASTED: 'pasted',
};

export const ELEMENT_TYPES = {
  APP_ADMIN: 'app-admin',
  BARRIER: 'barrier',
  BEACON: 'beacon',
  CHAT: 'chat',
  CHESS: 'chess',
  COUNTDOWN_TIMER: 'countdown timer',
  COBROWSER: 'cobrowser',
  COBROWSER_GAME: 'cobrowser-game',
  CODENAMES: 'codenames',
  CODE_EDITOR: 'code editor',
  Dice: 'Dice',
  DRAWING: 'drawing',
  EMBEDDED: 'embedded',
  EMBEDDED_WEB_OBJECT: 'Embedded Web Object',
  FILE: 'file',
  FOUR_IN_A_ROW: 'Four in a Row',
  GIF: 'gif',
  GIFTEXT: 'giftext',
  IMAGE: 'image',
  MEDIA_PLAYER: 'media player',
  POKER: 'poker',
  Poll: 'Poll',
  ROOM_ACTIVE_TIME: 'room active time',
  ROOM_STREAKS: 'room streaks',
  STICKER: 'sticker',
  TOP_MEMBERS: 'top members',
  SDK_APP: 'sdk-app',
  SKRIBBLIO: 'skribblio',
  SPAWN: 'spawn',
  SPOTIFY: 'spotify',
  SYNTHESIZER: 'synthesizer',
  TEXT: 'text',
  TIMER: 'timer',
  TIC_TAC_TOE: 'tictactoe',
  TRIVIA: 'Trivia',
  ToDo: 'ToDo',
  WARP: 'warp',
  WHATS_THAT_VOICE: 'whatsthatvoice',
  MOOD: 'mood',
  REMIX: 'remix',
  YOUTUBE_EMBED: 'YouTube video',
  TIKTOK_EMBED: 'TikTok video',
  CHAT_WINDOW: 'chat window',
  ROOM_SHORTCUT: 'room shortcut',
};

export const IMAGE_TYPE_TO_ELEMENT_TYPE = {
  gifs: ELEMENT_TYPES.GIF,
  stickers: ELEMENT_TYPES.STICKER,
  text: ELEMENT_TYPES.GIFTEXT,
  image: ELEMENT_TYPES.IMAGE,
  remix: ELEMENT_TYPES.REMIX,
};
