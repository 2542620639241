import { updatePenColorUI } from '../drawing';
import firebase, { authedApiInstance, db } from '../firebase';
import { onSnapshot } from '../firestore-watcher';
import log from '../log';
import {
  updateMessageColorUI,
  updateMessageFontColorUI,
  updateMessageFontFamilyUI,
  updateMessageFontSizeUI,
} from '../react/chats/feed/feed-util';

const UserProfile = {
  messageColor: '',
  fontColor: '',
  fontSize: '',
  fontFamily: '',
  seenScreenshareNotice: false,
  penColor: '#bd14ff',
  soundEffectsEnabled: true,
};

export async function updateProfile(payload) {
  await db.doc(`userProfiles/${firebase.auth().currentUser.uid}`).update(payload);
}

export function getMessageColor() {
  return UserProfile.messageColor;
}

export function getFontColor() {
  return UserProfile.fontColor;
}

export function getFontSize() {
  return UserProfile.fontSize;
}

export function getFontFamily() {
  return UserProfile.fontFamily;
}

export function getPenColor() {
  return UserProfile.penColor;
}

export function getSoundEffectsEnabled() {
  return UserProfile.soundEffectsEnabled;
}

export function hasSeenScreenshareNotice() {
  return UserProfile.seenScreenshareNotice;
}

export function canViewPublicRoom() {
  return UserProfile.canViewPublicRooms;
}

export async function createUniqueUsername(usernameInput) {
  try {
    const response = await authedApiInstance.post('/username/generate', { accountName: usernameInput });

    if (response && response.data) {
      return response.data.username;
    }

    log.error('Failed to generate username');
    return '';
  } catch (e) {
    log.error('Generate username call failed', e);
    return '';
  }
}

export function startListenForProfileChange() {
  onSnapshot(`/userProfiles/${firebase.auth().currentUser.uid}`, (userData) => {
    if (!userData) {
      return;
    }

    if (userData.messageColor !== undefined) UserProfile.messageColor = userData.messageColor;
    else UserProfile.messageColor = '#612cda';

    if (userData.fontColor !== undefined) UserProfile.fontColor = userData.fontColor;
    else UserProfile.fontColor = 'white';

    if (userData.fontSize !== undefined) UserProfile.fontSize = userData.fontSize;
    else UserProfile.fontSize = '16px';

    if (userData.fontFamily !== undefined) UserProfile.fontFamily = userData.fontFamily;
    else UserProfile.fontFamily = 'Inter';

    if (userData.seenScreenshareNotice !== undefined)
      UserProfile.seenScreenshareNotice = userData.seenScreenshareNotice;
    else UserProfile.seenScreenshareNotice = false;

    if (userData.penColor !== undefined) UserProfile.penColor = userData.penColor;
    else UserProfile.penColor = '#bd14ff';

    if (userData.canCreatePublicRooms !== undefined) UserProfile.canCreatePublicRooms = userData.canCreatePublicRooms;
    else UserProfile.canCreatePublicRooms = true;

    if (userData.canViewPublicRooms !== undefined) UserProfile.canViewPublicRooms = userData.canViewPublicRooms;
    else UserProfile.canViewPublicRooms = true;

    if (userData.soundEnabled !== undefined) UserProfile.soundEffectsEnabled = userData.soundEnabled;
    else UserProfile.soundEffectsEnabled = true;

    updateMessageColorUI(UserProfile.messageColor);
    updateMessageFontColorUI(UserProfile.fontColor);
    updateMessageFontSizeUI(UserProfile.fontSize);
    updateMessageFontFamilyUI(UserProfile.fontFamily);
    updatePenColorUI(UserProfile.penColor);
  });

  UserProfile.createdAt = new Date(firebase.auth().currentUser.metadata.creationTime);
}
