import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { disableEverything, enableElements, enableEverything } from '../../../onboarding';

// components
import Portal from '../../components/Portal';
import { HintDescription, HintPositionContainer, HintTitle } from '../hints/shared-styles';
import Hint, { InnerContainer } from '../hints/Hint';

let rootCheckerIntervalId: ReturnType<typeof setInterval> | null = null;

const preventDefaultStopPropagation = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
};

const MediaPlayerHintScreen = () => {
  const [hintRoot, setHintRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    let mediaPlayer: Element | null = null;
    rootCheckerIntervalId = setInterval(() => {
      const root = document.querySelector('.onboarding-hint-anchor');
      if (root) {
        setHintRoot(root as HTMLElement);
        clearInterval(rootCheckerIntervalId);

        disableEverything();
        enableElements('.boardElement.youtube-element:last-child');

        // disable the overflow button, so it won't open on right click
        mediaPlayer = document.querySelector('.boardElement.youtube-element:last-child');
        mediaPlayer.addEventListener('contextmenu', preventDefaultStopPropagation);
        mediaPlayer.classList.add('media-player-onboarding');
      }
    }, 400);

    return () => {
      clearInterval(rootCheckerIntervalId);

      enableEverything();

      // enable the overflow button, so you can open it on right click again
      mediaPlayer.removeEventListener('contextmenu', preventDefaultStopPropagation);

      mediaPlayer.classList.remove('media-player-onboarding');
    };
  }, []);

  if (!hintRoot) {
    return null;
  }

  return (
    <Portal root={hintRoot}>
      <MediaPlayerHintPositionContainer>
        {/* arrow */}
        <ArrowImage src="gif/onboarding-arrow.gif" />

        {/* hint */}
        <HintContainer>
          <Hint>
            <HintTitle>Well done.</HintTitle>
            <HintDescription>
              Now pick something for <br /> you and your friends to <br /> watch...
            </HintDescription>
          </Hint>
        </HintContainer>
      </MediaPlayerHintPositionContainer>
    </Portal>
  );
};

const MediaPlayerHintPositionContainer = styled(HintPositionContainer)`
  bottom: -17%;
  margin-left: -60%;
  width: fit-content;
  z-index: -10;
`;

const ArrowImage = styled.img`
  position: absolute;
  margin-top: 120px;
  margin-left: 246px;
  width: 125px;
  z-index: 1;
  grid-row: 1;
  grid-column: 2;
  transform: rotate(77deg);
`;

const HintContainer = styled.div`
  padding-right: 45px;
  grid-row: 2;
  grid-column: 1 / 2;

  ${InnerContainer} {
    padding: 30px 90px 30px 45px;
  }
`;

export default MediaPlayerHintScreen;
