import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ChevronDownIcon } from '../../icons';
import { svgColorMixin } from '../../mixins';

const HereDropdown = ({
  placeholder,
  onClick,
  onSelect,
  options,
  selectedValue,
  smallStyle,
  inputTextColor,
  isDisabled,
  showOutline,
  customDropdownStyles,
  isFacingUp,
  dropdownHeaderTestId,
  dropdownListContainerTestId,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onDropdownClick = () => {
    onClick();
    setDropdownOpen(!dropdownOpen);
  };

  const onDropdownSelect = (value) => {
    onSelect(value);
    setDropdownOpen(false);
  };

  return (
    <DropdownWrapper customDropdownStyles={customDropdownStyles}>
      <DropdownHeader
        onClick={isDisabled ? null : onDropdownClick}
        dropdownOpen={dropdownOpen}
        disabled={isDisabled}
        showOutline={showOutline}
        color={inputTextColor}
        data-testid={dropdownHeaderTestId}
      >
        <HeaderText smallStyle={smallStyle} selectedValue={selectedValue} color={inputTextColor}>
          {selectedValue || placeholder}
        </HeaderText>
        <ChevronDownIcon color={inputTextColor} />
      </DropdownHeader>
      {dropdownOpen && (
        // If dropdown is instantiated in a room, getFrontZIndex, else just 1
        <DropdownListContainer
          isFacingUp={isFacingUp}
          zIndex={window.getFrontZIndex ? window.getFrontZIndex() : '1'}
          className="dropdown-list-container"
          data-testid={dropdownListContainerTestId}
        >
          {options.map(({ key, value }) => (
            <Option key={key} smallStyle={smallStyle} onClick={() => onDropdownSelect(key)}>
              {value}
            </Option>
          ))}
        </DropdownListContainer>
      )}
    </DropdownWrapper>
  );
};

export default HereDropdown;

HereDropdown.propTypes = {
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]) })
  ).isRequired,
  selectedValue: PropTypes.string,
  smallStyle: PropTypes.bool,
  inputTextColor: PropTypes.string,
  customDropdownStyles: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  showOutline: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFacingUp: PropTypes.bool,
  dropdownHeaderTestId: PropTypes.string,
  dropdownListContainerTestId: PropTypes.string,
};

HereDropdown.defaultProps = {
  selectedValue: '',
  placeholder: '',
  onClick: () => {}, // no-op
  onSelect: () => {}, // no-op
  smallStyle: false,
  inputTextColor: 'white',
  customDropdownStyles: {},
  isDisabled: false,
  showOutline: true,
  isFacingUp: false,
  dropdownHeaderTestId: null,
  dropdownListContainerTestId: null,
};

const DropdownWrapper = styled.div`
  position: relative;

  ${(props) =>
    props.customDropdownStyles &&
    `
    .dropdown-list-container {
      height: ${props.customDropdownStyles.height};
      width: ${props.customDropdownStyles.width};
    }
    `}
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;

  ${(props) => !props.disabled && `cursor: pointer;`}

  width: 100%;
  border-radius: 5px;
  height: 45px;

  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;

  ${(props) =>
    props.showOutline &&
    `
    outline: 1px solid var(--primary-foreground-alpha-20, rgba(255, 255, 255, 0.2));
    &:hover {
      outline: 1px solid var(--primary-foreground-alpha-50, rgba(255, 255, 255, 0.5));
    }
  `}

  ${(props) =>
    props.dropdownOpen &&
    props.showOutline &&
    `
    &,
    &:hover {
      outline: 2px solid var(--secondary-background, #6b1be3);
    }
  `}

  ${(props) =>
    props.smallStyle &&
    `
    height: 40px;
  `}

  ${(props) => svgColorMixin(`var(--primary-foreground, ${props.color})`)}
`;

const HeaderText = styled.div`
  font-size: 1em;
  color: var(--primary-foreground-alpha-40, rgba(255, 255, 255, 0.4));

  ${(props) => props.selectedValue && `color: var(--primary-foreground, ${props.color});`}

  ${(props) =>
    props.smallStyle &&
    `
    font-size: 0.95em;
  `}
`;

const DropdownListContainer = styled.div`
  background: var(--primary-background, white);
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 10px;
  margin-top: 5px;
  border: 1px solid rgba(18, 0, 45, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px;
  box-sizing: border-box;
  z-index: ${(props) => props.zIndex};
  overflow-y: scroll;
  ${({ isFacingUp }) => isFacingUp && 'bottom: 54px;'}
`;

const Option = styled.div`
  height: 45px;
  padding: 0 12px;
  font-size: 1em;
  display: flex;
  align-items: center;
  color: var(--primary-foreground, #12002d);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: var(--secondary-foreground, #6b1be3);
    border-radius: 5px;
    background: var(--secondary-background, #eee);
  }

  ${(props) =>
    props.smallStyle &&
    `
    font-size: 0.95em;
  `}
`;
