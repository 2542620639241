export function isRoomPublic(roomData) {
  if (roomData.isPublic === true) return true;
  if (
    roomData.publicRoomMessageId !== null &&
    roomData.publicRoomMessageId !== undefined &&
    roomData.publicRoomMessageId !== ''
  )
    return true;
  return false;
}
