import { ThemeColors } from '../../../definitions/theme-colors';

type Direction = 'top' | 'bottom' | 'left' | 'right';
type AnotherDirection = 'up' | 'down' | 'left' | 'right';

export interface SkinSize {
  scale: number;
  width: number;
  height: number;
}

export interface BaseSkinElement {
  id?: string;
  name?: string;
  type?: string;
  employeesOnly?: boolean;
  created_at?: string;
  deleted_at?: string;
}

export interface SkinDecorator extends BaseSkinElement {
  contentType: 'inside' | 'outside';
  anchor: Direction;
  url: string;
  inset?: {
    x: number;
    y: number;
  };
  translation?: {
    x: number;
    y: number;
  };
  size: SkinSize;
}

export interface SkinParticles extends BaseSkinElement {
  images: string[];
  direction: AnotherDirection;
  scale: number;
  scaleRange: number;
  lifetime: number;
  birthRate: number;
  velocity: number;
  velocityRange: number;
  yAcceleration: number;
  xAcceleration: number;
  spin: number;
  spinRange: number;
  thumbnail?: string;
  wave?: {
    xForce: number;
    yForce: number;
    frequency: number;
  };
}

export interface SkinFrame extends BaseSkinElement {
  images: {
    bottom: string;
    bottomLeft: string;
    bottomRight: string;
    left: string;
    right: string;
    top: string;
    topLeft: string;
    topRight: string;
  };
  sizes: {
    bottom: SkinSize;
    bottomLeft: SkinSize;
    bottomRight: SkinSize;
    left: SkinSize;
    right: SkinSize;
    top: SkinSize;
    topLeft: SkinSize;
    topRight: SkinSize;
  };
}

export interface SkinBackground extends BaseSkinElement {
  original: string;
  thumbnail?: string;
  size?: SkinSize;
}

export interface SkinData extends BaseSkinElement {
  background?: SkinBackground;
  decorator?: SkinDecorator;
  particle?: SkinParticles;
  frame?: SkinFrame;
  colors?: ThemeColors;
  fonts?: {
    title?: string;
    section?: string;
    system?: string;
  };
}

export interface UnfoldedSkinsData {
  skins: SkinData[];
  frames: SkinFrame[];
  decorators: SkinDecorator[];
  backgrounds: SkinBackground[];
  particles: SkinParticles[];
}

export interface ChatSkinningState {
  defaultData: UnfoldedSkinsData;
  isLoading: boolean;
}

export const initialState: ChatSkinningState = {
  defaultData: {
    skins: [],
    frames: [],
    decorators: [],
    backgrounds: [],
    particles: [],
  },
  isLoading: false,
};
