import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { selectChatByReceiverId } from '../../store/messaging/selectors';
import UserAvatarWithStatus from '../../components/UserAvatarWithStatus';
import LottieAnimation from '../../animations/LottieAnimation';
import { ConditionalWrapper } from '../../components/ConditionalWrapper';

import SendInviteSvg from '../../../../assets/icons/send-invite.svg';
import InviteSentSvg from '../../../../assets/icons/invite-sent.svg';
import animationData from '../../../../assets/lottie/invite-sent.json';
import { ellipsisTextMixin, hideableMixin } from '../../mixins';

const FriendsInviteButton = ({ user, inviteAlreadySent, alreadyInRoom, onFriendInviteClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [sendAnimationPlayed, setSendAnimationPlayed] = useState(false);

  useEffect(() => {
    // if inviteAlreadySent goes from true -> false (in the case where user switches room and it's reset),
    // then we want to reset sendAnimationPlayed also
    if (!inviteAlreadySent) {
      setSendAnimationPlayed(false);
    }
  }, [inviteAlreadySent]);

  const chatsWithYouAndReceiver = useSelector((state) => selectChatByReceiverId(state, user.userId));
  const chatId = chatsWithYouAndReceiver.length >= 1 ? chatsWithYouAndReceiver[0].id : null;

  return (
    <ConditionalWrapper
      condition={alreadyInRoom}
      wrapper={(children) => <div uk-tooltip="Already in room!">{children}</div>}
    >
      <FriendContainer
        disableButton={sendAnimationPlayed || alreadyInRoom}
        onClick={inviteAlreadySent || alreadyInRoom ? () => {} : () => onFriendInviteClick({ user, chatId })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <UserAvatarWithStatus userProfile={user} status={user.onlineStatus.status} size={60} />
        {!inviteAlreadySent && !alreadyInRoom && (
          <HideableWrapper isHiddenDisplay={!isHovered}>
            <SendInviteIcon />
          </HideableWrapper>
        )}
        {inviteAlreadySent && !sendAnimationPlayed && (
          <AnimationContainer>
            <LottieAnimation animationData={animationData} onComplete={() => setSendAnimationPlayed(true)} />
          </AnimationContainer>
        )}
        {inviteAlreadySent && sendAnimationPlayed && <InviteSentIcon />}
        <FriendName>{user.displayName}</FriendName>
      </FriendContainer>
    </ConditionalWrapper>
  );
};

export default FriendsInviteButton;

FriendsInviteButton.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    avatar: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    onlineStatus: PropTypes.shape({
      status: PropTypes.string,
    }),
  }).isRequired,
  inviteAlreadySent: PropTypes.bool,
  alreadyInRoom: PropTypes.bool,
  onFriendInviteClick: PropTypes.func.isRequired,
};

FriendsInviteButton.defaultProps = {
  inviteAlreadySent: false,
  alreadyInRoom: false,
};

export const FriendContainer = styled.div`
  padding: 12px;
  width: 84px;
  margin: 6px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;

  cursor: pointer;

  &:hover {
    box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.2);
  }

  ${({ disableButton }) =>
    disableButton &&
    `
    cursor: auto;
    opacity: 0.6;

    &:hover {
      box-shadow: none;
    }
  `}
`;

export const FriendName = styled.div`
  font-size: 12px;
  text-align: center;
  color: var(--primary-foreground, #12002d);
  margin-top: 8px;

  ${ellipsisTextMixin()}
`;

const HideableWrapper = styled.div`
  ${hideableMixin()}
`;

const iconStyling = css`
  position: absolute;
  top: 3px;
  right: 0;
`;

const AnimationContainer = styled.div`
  width: 63x;
  height: 63px;
  position: absolute;
  top: -14px;
  right: -14px;
`;

const SendInviteIcon = styled(SendInviteSvg)`
  ${iconStyling}
`;

const InviteSentIcon = styled(InviteSentSvg)`
  ${iconStyling}
`;
