import { authedApiInstance } from '../../../firebase';
import { messageTypes } from '../../../constants/chat-constants';

export const createChatWithoutMessage = ({ receiverId, theme, background }) =>
  authedApiInstance
    .post('/v3/chat', {
      uid: receiverId,
      theme,
      background,
    })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const createChat = ({ receiverId, message, fontColor, fontFamily, fontSize, messageColor, theme, background }) =>
  authedApiInstance
    .post('/v3/chat', {
      uid: receiverId,
      type: messageTypes.TEXT,
      message,
      fontColor,
      fontFamily,
      fontSize,
      messageColor,
      theme,
      background,
    })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const createChatFromImage = ({ receiverId, imageUrl, storagePath, background, theme }) =>
  authedApiInstance
    .post('/v3/chat', { uid: receiverId, type: messageTypes.IMAGE, imageUrl, storagePath, background, theme })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const createChatFromSticker = ({ receiverId, url, type = messageTypes.STICKER, background, theme }) =>
  authedApiInstance
    .post('/v3/chat', { uid: receiverId, type, url, background, theme })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const createChatFromInvite = ({ receiverId, roomId, background, theme }) =>
  authedApiInstance
    .post('/v3/chat', { uid: receiverId, type: messageTypes.INVITE, message: roomId, background, theme })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const sendMessage = (chatId, message, fontColor, fontFamily, fontSize, messageColor) =>
  authedApiInstance
    .post('/chat/message', { chatId, type: messageTypes.TEXT, message, fontColor, fontFamily, fontSize, messageColor })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const deleteMessage = (messageId, chatId) =>
  authedApiInstance
    .delete(`/chat/message/${messageId}`, { data: { chatId } })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getChatsListV3 = ({ before, limit }) =>
  authedApiInstance
    .get('/v3/chat', { params: { before, limit } })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const sendStickerMessage = ({ chatId, url, type = messageTypes.STICKER }) =>
  authedApiInstance
    .post('/chat/message', { chatId, type, url })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const sendAudioMessage = ({ chatId, url, storagePath }) =>
  authedApiInstance
    .post('/chat/message', { chatId, type: messageTypes.AUDIO, url, storagePath })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const sendVideoMessage = ({ chatId, url, storagePath }) =>
  authedApiInstance
    .post('/chat/message', { chatId, type: messageTypes.VIDEO, url, storagePath })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const sendImageMessage = ({ chatId, imageUrl, storagePath }) =>
  authedApiInstance
    .post('/chat/message', { type: messageTypes.IMAGE, chatId, imageUrl, storagePath })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const sendInviteMessage = ({ chatId, roomId }) =>
  authedApiInstance
    .post('/chat/message', { type: messageTypes.INVITE, chatId, message: roomId })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

// Note: this should only be used for chat onboarding messages, the backend will not allow for any other msg
export const sendSystemMessage = ({ chatId, message, metadata }) =>
  authedApiInstance
    .post('/chat/message', { type: messageTypes.SYSTEM, chatId, message, metadata })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getChatV3 = ({ chatId, before, limit }) =>
  authedApiInstance
    .get(`/v3/chat/${chatId}`, { params: { before, limit } })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const resetUnreadMessages = ({ chatId, lastReadMessageId }) =>
  authedApiInstance
    .post('/chat/unread', { chatId, lastReadMessageId })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const addEmojiReactionToMessage = ({ messageId, reaction }) =>
  authedApiInstance
    .post(`/chat/message/${messageId}/reaction`, { reaction })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const addEmoteReactionToMessage = ({ messageId, emoteUrl }) =>
  authedApiInstance
    .post(`/chat/message/${messageId}/reaction`, { customReaction: emoteUrl })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getPublicChats = () =>
  authedApiInstance
    .get('/chat/public')
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getPublicChat = (chatId) =>
  authedApiInstance
    .get(`/chat/${chatId}/preview`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);
