import { toKebabCase } from './string-util';

export function setClass(className, value, element) {
  element.classList[value ? 'add' : 'remove'](className);
}

export function toCssVariableName(name) {
  return `--${toKebabCase(name)}`;
}

export function readCssVariable(name, element) {
  return window.getComputedStyle(element).getPropertyValue(toCssVariableName(name));
}

export function setCssVariable(name, value, element) {
  element.style.setProperty(toCssVariableName(name), value);
}

export function setCssPixelsVariable(name, value, element) {
  setCssVariable(name, `${value}px`, element);
}

export function removeCssVariable(name, element) {
  element.style.setProperty(toCssVariableName(name), 'initial');
}
