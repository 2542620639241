import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { enableEverything } from '../../../onboarding';
import { goNextScreen } from '../../store/signing-in/actions';

// store
import { showTip } from '../../store/tips-and-tricks/actions';
import { TipsAndTricks } from '../../store/tips-and-tricks/init-state';

// components
import Portal from '../../components/Portal';
import Hint, { InnerContainer, OuterContainer } from '../hints/Hint';
import { HintTitle } from '../hints/shared-styles';

const RoomOnboardingFinishedScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    enableEverything();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(goNextScreen({}));
      dispatch(showTip({ tip: TipsAndTricks.ADD_ROOM_DECORATION, source: 'onboarding finished' }));
    }, 5000);
  }, [dispatch]);

  return (
    <Portal root={document.getElementById('root')}>
      <HintPositionContainer>
        <Hint>
          <HintContent>
            <ClapImage src="gif/clap.gif" />
            <OnboardingLastStepTitle>Room Unlocked!</OnboardingLastStepTitle>
            <SparklesImage src="gif/sparkles.gif" />
          </HintContent>
        </Hint>
      </HintPositionContainer>
    </Portal>
  );
};

const HintPositionContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  z-index: 10111; // should be above the header

  ${OuterContainer} {
    padding: 11px;
  }

  ${InnerContainer} {
    padding: 0 15px;
  }
`;

const ClapImage = styled.img`
  margin-right: 10px;
  height: 92px;
  width: 92px;
`;

const OnboardingLastStepTitle = styled(HintTitle)`
  font-size: 20px;
`;

const HintContent = styled.div`
  display: flex;
  align-items: center;
`;

const SparklesImage = styled.img`
  position: absolute;
  right: -95px;
  height: 200px;
  width: 200px;
`;

export default RoomOnboardingFinishedScreen;
