import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { Picker } from 'emoji-mart';

import { startDirectedReaction } from '../../reactions';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { currentUserProfileUpdateRequest } from '../store/users/store';
import { selectCurrentUserReactions, selectCurrentUserProfileTheme, selectCurrentUser } from '../store/users/selectors';
import { track } from '../../util/analytics-util';
import UserAvatar from '../components/UserAvatar';
import PieIcon from '../../../assets/icons/pie.svg';
import { svgColorMixin } from '../mixins';

const ReactionsPicker = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    import('emoji-mart/css/emoji-mart.css');
  }, []);

  const [selectedReaction, setSelectedReaction] = useState(null);
  const [hoveredReaction, setHoveredReaction] = useState(null);
  const [pressedReaction, setPressedReaction] = useState(null);
  const closePicker = useCallback(() => setSelectedReaction(null), []);

  const reactions = useSelector(selectCurrentUserReactions);
  const onReactionSelected = useCallback(
    (data) => {
      dispatch(currentUserProfileUpdateRequest({ patch: { reactions: { [selectedReaction]: data.native } } }));
      closePicker();
      track('Customize reaction', { reaction: data.native });
    },
    [dispatch, selectedReaction, closePicker]
  );

  const pickerContainerRef = useRef();
  // TODO: Actually this can be done with updating major version of emoji-mart to the latest one.
  useOnClickOutside(pickerContainerRef, closePicker);

  const reactionsInHumanReadableOrder = useMemo(() => {
    const [zeroIndexReaction, ...restReactions] = Object.entries(reactions).sort(
      ([a], [b]) => parseInt(a, 10) - parseInt(b, 10)
    );
    return [...restReactions, zeroIndexReaction];
  }, [reactions]);

  const currentProfile = useSelector(selectCurrentUser);

  const avatarRef = useRef(null);
  useEffect(() => {
    const center = avatarRef?.current.getBoundingClientRect();
    const keyDownHandler = (e) => {
      const reaction = reactions[e.key];
      if (!reaction) return;

      e.stopPropagation();
      startDirectedReaction(reaction, {
        x: center.x + center.width / 2,
        y: center.y + center.height / 2,
        doesHit: true,
        container: document.body,
        speed: 0.4,
      });
      setPressedReaction(e.key);
    };
    const keyUpHandler = () => setPressedReaction(null);

    document.addEventListener('keydown', keyDownHandler, true);
    document.addEventListener('keyup', keyUpHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, true);
      document.removeEventListener('keyup', keyUpHandler);
    };
  }, [reactions]);

  const theme = useSelector(selectCurrentUserProfileTheme);
  const isGradientTheme = useMemo(() => theme?.colors?.primaryBackground?.includes('gradient'), [theme]);

  return (
    <Container>
      {selectedReaction !== null && (
        <PickerContainer ref={pickerContainerRef}>
          <Picker
            sheetSize={16}
            emojiSize={22}
            perLine={7}
            onSelect={onReactionSelected}
            showPreview={false}
            showSkinTones={false}
            native
          />
          <EmojiPickerCloseButton className="here-button-minimal unthemed" onClick={closePicker}>
            ⨉
          </EmojiPickerCloseButton>
        </PickerContainer>
      )}
      <Background isGradientTheme={isGradientTheme}>
        <AdditionalBackground />
      </Background>
      {reactionsInHumanReadableOrder.map(([key, defaultEmoji]) => (
        <CircleSector
          key={key}
          index={key}
          totalCount={reactionsInHumanReadableOrder.length}
          onClick={() => setSelectedReaction(key)}
          onMouseEnter={() => setHoveredReaction(key)}
        >
          <CircleSectorInnerContainer isPressed={pressedReaction === key}>
            <Key>{key}</Key>
            <EmojiContainer>
              <Emoji isSelected={selectedReaction === key}>{reactions[key] || defaultEmoji}</Emoji>
            </EmojiContainer>
          </CircleSectorInnerContainer>
        </CircleSector>
      ))}
      <AvatarContainer ref={avatarRef}>
        <UserAvatar userProfile={currentProfile} />
      </AvatarContainer>
      <HighlightContainer index={hoveredReaction} totalCount={reactionsInHumanReadableOrder.length}>
        <Highlight />
      </HighlightContainer>
    </Container>
  );
};

export default ReactionsPicker;

const CIRCLE_OFFSET = -3;

const AdditionalBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66%;
  height: 66%;
  border-radius: 50%;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  mask: radial-gradient(farthest-side, transparent 50%, white 50%);
  border-radius: 50%;

  &,
  ${AdditionalBackground} {
    background: ${({ isGradientTheme }) =>
      isGradientTheme
        ? 'var(--primary-background)'
        : 'conic-gradient(from 360deg at 50% 50%, var(--primary-background-alpha-80, rgba(253, 74, 163, 0.8)) 0deg, var(--primary-background-alpha-20, rgba(253, 74, 163, 0.2)) 360deg)'};
  }
`;

const PickerContainer = styled.div`
  top: 20px;
  position: absolute;
  right: -260px;
  z-index: 2;

  @media (max-width: 950px) {
    position: fixed;
    top: calc(50% - 200px);
    right: 10px;
  }

  .emoji-mart {
    position: relative;
  }
`;

const EmojiContainer = styled.div`
  margin-left: 10px;
`;

const Emoji = styled.div`
  font-size: 40px;
  transition: transform 0.2s ease-out;
  ${({ isSelected }) => isSelected && 'opacity: 0.5;'}
`;

const Key = styled.div`
  color: var(--primary-foreground, white);
  font-size: 18px;
  position: absolute;
  left: 36px;
  top: 61px;
`;

const CircleSector = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 144px;
  clip-path: polygon(0 37%, 100% 0%, 100% 100%, 0 70%);
  cursor: pointer;
  z-index: 1;
  ${({ index, totalCount }) => {
    const rotation = (360 / totalCount) * (parseInt(index, 10) + CIRCLE_OFFSET);
    return `
      transform: translate(-50%, -50%) rotate(${rotation}deg) translate(150px);

      ${EmojiContainer}, ${Key} {
        transform: rotate(${-rotation}deg);
      }
    `;
  }}

  &:hover {
    ${Emoji} {
      transform: scale(1.2);
    }

    ${Key} {
      color: var(--secondary-foreground, #6b1be3);
    }
  }
`;

const bounceInAnimation = keyframes`
  0% {
    transform: scale(0.3);
  }
  50% {
    animation-timing-function: ease-in-out;
    transform: scale(1.05);
  }
  70% {
    animation-timing-function: ease-in-out;
    transform: scale(0.95);
  }
  100% {
    animation-timing-function: ease-in-out;
    transform: scale(1);
  }
`;
const CircleSectorInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${({ isPressed }) =>
    isPressed &&
    css`
      animation: ${bounceInAnimation} 0.2s infinite;
    `}
`;

let previousAngle = 0;
const HighlightContainer = styled.div`
  position: absolute;
  width: 214px;
  height: 116px;
  left: 200px;
  top: 142px;
  transition: transform 0.2s ease-out;
  transform-origin: left;
  transform: ${({ index, totalCount }) => {
    let newAngle = (360 / totalCount) * (parseInt(index, 10) + CIRCLE_OFFSET);
    const diff = newAngle - previousAngle;
    if (diff > 180) newAngle -= 360;
    else if (diff < -180) newAngle += 360;
    previousAngle = newAngle;
    return `rotate(${newAngle}deg)`;
  }};
  display: none;
`;

const Container = styled.div`
  width: 400px;
  height: 400px;
  position: relative;
  animation: ${bounceInAnimation} 0.7s;
  margin: 18px auto;

  &:hover ${HighlightContainer} {
    display: block;
  }
`;

const Highlight = styled(PieIcon)`
  position: absolute;
  right: 0;

  ${svgColorMixin('var(--secondary-background, white)')}
`;

const EmojiPickerCloseButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: -7px;
  top: -7px;
  background: white;
  border: 1px solid gray;
  padding-bottom: 2px;
  box-sizing: border-box;
  z-index: 101;
`;

const AvatarContainer = styled.div`
  width: 160px;
  height: 160px;
  position: absolute;
  top: 120px;
  left: 120px;
`;
