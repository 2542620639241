import CobrowserElement from './cobrowser';
import firebase, { db } from './firebase';
import log from './log';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';

import { track } from './util/analytics-util';
import { STARTED_GAME, Games } from './constants/analytics-events/games-events';

import '../styles/poker.less';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

const PokerState = {
  unstarted: 'unstarted',
  starting: 'starting',
  started: 'started',
};

export default class PokerElement extends CobrowserElement {
  constructor(elementId) {
    super(elementId);

    this.state = PokerState.unstarted;
  }

  elementDescription() {
    return 'poker';
  }

  icon() {
    return 'slingshot';
  }

  static async addElement() {
    await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'PokerElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
        ),
        creator: firebase.auth().currentUser.uid,
        size: [800, 450],
        state: PokerState.unstarted,
        zIndex: window.getFrontZIndex(),
      });
    track(ADD_ELEMENT, { element: ELEMENT_TYPES.POKER, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });

    return false;
  }

  handleUpdate(element, elementDoc) {
    super.handleUpdate(element, elementDoc);
    let result = true;
    if (this.state !== elementDoc.data().state) {
      log.debug('Poker changing state, reload it');
      result = false;
    }

    this.state = elementDoc.data().state;

    return result;
  }

  setup(elementId, elementDoc) {
    const data = elementDoc.data();
    this.state = data.state;
    const element = document.getElementById(elementId);
    if (this.state === PokerState.started) {
      log.debug(`Poker game enabled at ${this.url}`);
      super.setup(elementId, elementDoc);
    } else if (this.state === PokerState.unstarted) {
      element.querySelector('.game-th').addEventListener('click', () => this.startGame('th'));
    }
  }

  getElement(elementDoc) {
    const data = elementDoc.data();
    log.debug(`Poker state: ${data.state}`);

    let game;
    switch (data.state) {
      case PokerState.unstarted: {
        game = htmlToElement(`
          <div class="game-title-screen-div">
            <h3>Texas Holdem</h3>
            <h1>POKER</h1>
            <button class="game-th here-button-regular">Start a New Game</button>
            <p>Maximium Players: 10</p>
          </div>
        `);
        break;
      }
      case PokerState.starting: {
        game = htmlToElement(`
          <div class="game-title-screen-div">
            <h3>Starting Game...</h3>
          </div>
        `);
        break;
      }
      case PokerState.started: {
        track(STARTED_GAME, { game: Games.POKER });
        this.url = `https://www.pokernow.club/games/${data.gameID}?ut=${firebase.auth().currentUser.uid}`;
        return super.getElement(elementDoc);
      }
      default: {
        log.error('Unknown poker game state', this.state);
        return null;
      }
    }

    return wrapElement(game, elementDoc, {
      classes: ['poker-element'],
      preserveAspectRatio: this.preserveAspectRatio(),
    });
  }

  preserveAspectRatio() {
    return true;
  }

  async startGame(gameType) {
    log.debug('Starting the game');
    const start = firebase.functions().httpsCallable('startPokerGame');

    if (start) {
      await db.collection('boards').doc(window.currentBoardId).collection('elements').doc(this.elementId).update({
        state: PokerState.starting,
      });
      const result = await start({ gameType });

      if (result && result.data.gameID) {
        db.collection('boards').doc(window.currentBoardId).collection('elements').doc(this.elementId).update({
          state: PokerState.started,
          gameID: result.data.gameID,
        });
      }
    }
  }
}
