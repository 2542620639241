import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserAvatar from '../../../../components/UserAvatar';
import { useUserProfileData } from '../../../../hooks/useUserProfileData';
import { messageTypes } from '../../../../../constants/chat-constants';
import { ellipsisTextMixin, hideableMixin } from '../../../../mixins';

const AVATAR_SIZE_IN_PX = 20;

const RoomItemMessagePreview = ({ message }) => {
  const { type, text, creator } = message;
  const { userProfile } = useUserProfileData(creator);

  const messagePreviewRef = useRef(null);
  const displayNameRef = useRef(null);
  const messageTextRef = useRef(null);

  const [displayText, setDisplayText] = useState(text);

  useEffect(() => {
    if (type === messageTypes.SYSTEM) {
      if (text === 'is here!') {
        setDisplayText(`${userProfile.displayName} joined`);
      } else {
        setDisplayText(`${userProfile.displayName} ${text}`);
      }
    }
    if (type === messageTypes.OUTSIDE_REACTION) {
      setDisplayText(`${userProfile.displayName} has sent ${text} to this room!`);
    }
    if (type === messageTypes.IMAGE) {
      setDisplayText(`${userProfile.displayName} sent an ${type}`);
    }
    if (type === messageTypes.GIF || type === messageTypes.STICKER) {
      setDisplayText(`${userProfile.displayName} sent a ${type}`);
    }
    if (type === messageTypes.TEXT) {
      setDisplayText(text);
    }
  }, [type, text, userProfile.displayName]);

  const [messageTextWidth, setMessageTextWidth] = useState('');

  useEffect(() => {
    const totalRoomItemWidth = messagePreviewRef.current?.offsetWidth;

    if (type === messageTypes.TEXT) {
      const displayNameWidth = displayNameRef?.current?.offsetWidth || 0;
      const width = totalRoomItemWidth - (AVATAR_SIZE_IN_PX + displayNameWidth + 40);
      setMessageTextWidth(width);
    } else {
      const width = totalRoomItemWidth - (AVATAR_SIZE_IN_PX + 40);
      setMessageTextWidth(width);
    }
  }, [displayNameRef.current?.offsetWidth, messagePreviewRef.current?.offsetWidth, type]);

  return (
    <MessagePreviewContainer ref={messagePreviewRef}>
      <FlexContainer>
        <AvatarContainer>
          <UserAvatar userId={creator} />
        </AvatarContainer>
        <UserDisplayName ref={displayNameRef} isHiddenDisplay={type !== messageTypes.TEXT}>
          {userProfile.displayName}
        </UserDisplayName>
        <DisplayText
          ref={messageTextRef}
          color={type === messageTypes.TEXT ? '#feb445' : '#39c360'}
          width={messageTextWidth}
        >
          {displayText}
        </DisplayText>
      </FlexContainer>
    </MessagePreviewContainer>
  );
};

export default RoomItemMessagePreview;

RoomItemMessagePreview.propTypes = {
  message: PropTypes.shape({
    creator: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
  }).isRequired,
};

const MessagePreviewContainer = styled.div`
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const AvatarContainer = styled.div`
  // need both set to make sure avatar is not stretched or
  // squished from variable name and variable text widths
  min-width: ${AVATAR_SIZE_IN_PX}px;
  max-width: ${AVATAR_SIZE_IN_PX}px;
  height: ${AVATAR_SIZE_IN_PX}px;
  border: 1px solid white;
  border-radius: 50%;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 1px #333;

  margin-left: 7px;
`;

const UserDisplayName = styled(Text)`
  ${hideableMixin()}
  color: white;
  ${ellipsisTextMixin({ fullWidth: false })}
`;

const DisplayText = styled(Text)`
  color: ${({ color }) => color};

  ${({ width }) => width && `width: ${width}px;`}
  ${ellipsisTextMixin({ fullWidth: false })}
`;
