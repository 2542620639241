import ReactDOM from 'react-dom';

import { db } from '../firebase';
import { htmlToElement } from '../util';

export const setUserNotification = (userId, avatarElement) => {
  const notification = htmlToElement('<span class="notification-count"></span>');

  db.collection('friendRequests')
    .where('receiver', '==', userId)
    .where('status', '==', 'pending')
    .onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push(doc.data());
      });
      const notificationsCount = docs.length;

      if (notificationsCount > 0) {
        avatarElement.insertAdjacentElement('afterend', notification);
        notification.innerHTML = notificationsCount;
      } else {
        notification.remove();
      }
    });
};

export function closeFriendsModal() {
  ReactDOM.unmountComponentAtNode(document.getElementById('friends-modal'));
}
