import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { deleteBoardRoomOnboardingStepId, updateBoardRoomOnboardingStepId } from '../../api/boards-api';
import { roomOnboardingFlow } from './flows';

// store
import { selectCurrentScreenId } from '../store/signing-in/selectors';
import { selectCurrentUserProfileTheme } from '../store/users/selectors';

// screens
import HighlightMediaPlayerButtonScreen from './screens/HighlightMediaPlayerButtonScreen';
import MediaPlayerHintScreen from './screens/MediaPlayerHintScreen';
import MediaPlayerInviteFriendsHintScreen from './screens/MediaPlayerInviteFriendsHintScreen';
import RoomOnboardingFinishedScreen from './screens/RoomOnboardingFinishedScreen';
import ReactionsScreen from './screens/ReactionsScreen';
import ReactionsHintScreen from './screens/ReactionsHintScreen';

// utils
import { applyTheme } from '../../util/theming-util';
import { track } from '../../util/analytics-util';

// constants
import { Screens } from './constants';
import { ROOM_ONBOARDING_FINISHED, ROOM_ONBOARDING_STARTED } from '../../constants/analytics-events/auth-events';

const screens: { [k in Screens]?: React.FC } = {
  [Screens.REACTIONS_HINT]: ReactionsHintScreen,
  [Screens.REACTIONS_ONBOARDING]: ReactionsScreen,
  [Screens.MEDIA_PLAYER_ONBOARDING_HIGHLIGHT_BUTTON]: HighlightMediaPlayerButtonScreen,
  [Screens.MEDIA_PLAYER_ONBOARDING_PLAYER_HINT]: MediaPlayerHintScreen,
  [Screens.MEDIA_PLAYER_ONBOARDING_INVITE_FRIENDS]: MediaPlayerInviteFriendsHintScreen,
  [Screens.ROOM_ONBOARDING_FINISHED]: RoomOnboardingFinishedScreen,
};

const screenIdsToKeepInFirebase = [
  Screens.REACTIONS_HINT,
  Screens.REACTIONS_ONBOARDING,
  Screens.MEDIA_PLAYER_ONBOARDING_HIGHLIGHT_BUTTON,
  Screens.MEDIA_PLAYER_ONBOARDING_PLAYER_HINT,
  Screens.MEDIA_PLAYER_ONBOARDING_INVITE_FRIENDS,
];

const RoomOnboarding = () => {
  const screenId = useSelector(selectCurrentScreenId) as Screens;
  const Screen = screens[screenId];

  useEffect(() => {
    // if room onboarding flow is just started
    if (screenId === roomOnboardingFlow.getFirstScreen().id) {
      track(ROOM_ONBOARDING_STARTED);
    }

    // if room onboarding flow is finished
    if (screenId === Screens.ROOM_ONBOARDING_FINISHED) {
      track(ROOM_ONBOARDING_FINISHED);
    }
  }, [screenId]);

  useEffect(() => {
    if (screenIdsToKeepInFirebase.includes(screenId)) {
      updateBoardRoomOnboardingStepId(window.currentBoardId, screenId);
    } else if (screenId === Screens.ROOM_ONBOARDING_FINISHED) {
      deleteBoardRoomOnboardingStepId(window.currentBoardId);
    }
  }, [screenId]);

  const ref = useRef(null);
  const theme = useSelector(selectCurrentUserProfileTheme);

  useEffect(() => {
    if (ref.current) {
      applyTheme(theme?.colors, theme?.primaryFont, ref.current);
    }
  }, [theme?.colors, theme?.primaryFont]);

  return <div ref={ref}>{Screen ? <Screen /> : null}</div>;
};

export default RoomOnboarding;
