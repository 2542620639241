/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/lines-between-class-members */
import JanusJS, { PublisherFeed } from './janus/janus.definitions';
import log from '../log';
import CameraPublisher from './camera_publisher';
import Subscriber, { SubscriberDelegateProtocol } from './subscriber';
import { JanusWrapperType } from './janus/wrapper';
import { JanusPluginWrapperType } from './janus/videoroom';

import Janus from '../lib/janus';
import { ConnectionStatus, PublisherStreamSource, PublisherType, UserId } from './definitions/index.definitions';
import StreamConstraints from './stream-constraints';
import { ScreenPublisher } from './screen_publisher';
import { asyncDebounce } from './util';
import { trackInForeground as trackMetric } from '../util/analytics-util';
import { isHereEmployeeCheck } from '../util/user-util';
import { BasePublisher, PublisherDelegateProtocol } from './base_publisher';

const MAX_PUBLISHERS = 50;
export interface RoomDelegateProtocol {
  roomPCChanged: (publisher: BasePublisher, room: Room, isPeerConnected: boolean) => void;
  roomPublishers: (room: Room, publishers: object[]) => void;
  roomLeave: (room: Room) => void;
  roomJoined: (room: Room, publisher: CameraPublisher, publishersCount: number) => void;
  roomDestroyed?: (room: Room) => void;
  publisherStreamReadyForHTML: (stream: MediaStream, username: string, type: PublisherStreamSource) => void;
  subscriberStreamReadyForHTML: (stream: MediaStream, userId: string) => void;
  subscriberTrackRemoved: (track: MediaStreamTrack, stream: MediaStream, userId: string) => void;
  iceRestartCancelled: (state: RTCIceConnectionState) => void;
  noICE: (publisher: BasePublisher) => void;
}

export enum RoomError {
  PublisherHandleError = 'publisher_handle_error',
  HandleAttachError = 'handle_attach_error',
  JoinError = 'join_error',
}
export default class Room implements PublisherDelegateProtocol {
  roomId: string;
  janus: JanusWrapperType;
  subscriber: Subscriber = null;
  cameraPublisher: CameraPublisher = null;
  constraints: StreamConstraints;
  screenPublisher: ScreenPublisher = null;
  autoSubscribe = true;
  destroyed = false;
  isEmployee = false;

  /** When this property is set to true, this instance shouldn't be used anymore */
  teardown = false;

  private _status: ConnectionStatus;
  private _publisherHandle: JanusPluginWrapperType = null;
  private _screenPublisherHandle: JanusPluginWrapperType = null;

  private delegate: RoomDelegateProtocol & SubscriberDelegateProtocol;
  private opaqueId: string;
  private myid: string;
  private myusername: string;
  private mypvtid: string;

  constructor(
    roomId: string,
    opaqueId: string,
    janus: JanusWrapperType,
    constraints: StreamConstraints,
    delegate: RoomDelegateProtocol & SubscriberDelegateProtocol
  ) {
    this.roomId = roomId;
    this.opaqueId = opaqueId;
    this.janus = janus;
    this.constraints = constraints;
    this.delegate = delegate;

    if (!this.delegate) {
      throw new Error('We cannot move forward without a RoomDelegate');
    }
  }

  set status(status: ConnectionStatus) {
    this._status = status;
    if (this.cameraPublisher) this.cameraPublisher.status = status;
  }

  get status() {
    return this._status;
  }

  set publisherHandle(handle: JanusPluginWrapperType) {
    this._publisherHandle = handle;
    if (this.cameraPublisher) this.cameraPublisher.publisherHandle = handle;
  }

  get publisherHandle() {
    return this._publisherHandle;
  }

  set screenPublisherHandle(handle: JanusPluginWrapperType) {
    this._screenPublisherHandle = handle;
    if (this.screenPublisher) this.screenPublisher.publisherHandle = handle;
  }

  get screenPublisherHandle() {
    return this._screenPublisherHandle;
  }

  get activeStreams(): { [key: UserId]: MediaStream } {
    const streams: { [userId: string]: MediaStream } = {};

    if (this.cameraPublisher?.status === ConnectionStatus.Connected && this.cameraPublisher?.localStream)
      streams[this.cameraPublisher.userId] = this.cameraPublisher.localStream;
    if (this.screenPublisher?.status === ConnectionStatus.Connected && this.screenPublisher?.screenshareStream)
      streams[this.screenPublisher.screenUsername] = this.screenPublisher.screenshareStream;

    Object.assign(streams, this.subscriber?.activeStreams || {});
    return streams;
  }

  get isScreenSharing() {
    return this.screenPublisher.status === ConnectionStatus.Connected;
  }

  subscribeToPublishers(feedList: PublisherFeed[]) {
    this.subscriber?.subscribeToPublishers(feedList);
  }

  /**
   * Joins a Janus Room.
   * Attach the VideoRoom plugin first if neccesary.
   *
   * If this Join operation succeeds `roomJoined` will be called in the delegate,
   * if there was an error `roomError` will be called instead. @see RoomDelegate
   *
   * @param username A string representing the username of the publisher peer.
   */
  async join(username: string) {
    this.status = ConnectionStatus.Connecting;
    log.log('Room: Joining room id: ', this.roomId);
    this.myusername = username;

    try {
      if (!this.publisherHandle || this.publisherHandle.detached) {
        await this.attachToVideoroomPlugin();
      }

      if (!(await this.roomExists(this.roomId))) {
        await this.createRoom(this.roomId);
      }

      const message = {
        request: 'join',
        room: this.roomId,
        ptype: 'publisher',
        display: this.myusername,
      };

      await this.publisherHandle.send({ message });
    } catch (error) {
      this.roomError(RoomError.JoinError, error);
    }
  }

  roomError(code: RoomError, error: Error) {
    log.error(`Room ${this.roomId} error: ${code} \n${error}`);
    trackMetric('SFU Room Error', { roomErrorCode: code, errorStr: error.message });
  }

  onPeerConnectionStatusChange(publisher: BasePublisher, isConnected: boolean) {
    log.log(`Room: Publishing WebRTC ${publisher.publisherType} PeerConnection is ${isConnected ? 'up' : 'down'} now`);
    this.delegate.roomPCChanged(publisher, this, isConnected);
  }

  async leave() {
    await this.dispose();
  }

  roomJoined(msg: JanusJS.Message) {
    this.delegate.roomJoined(this, this.cameraPublisher, (msg?.publishers || []).length);
    this.myid = msg.id;
    this.mypvtid = msg.private_id;
    log.log(`Room: Joined room ${msg.room} with ID ${this.myid}`);
    this.status = ConnectionStatus.Connected;
    if (!this.myusername) throw new Error('Cannnot continue without a username.');
    if (msg.publishers) {
      if (this.autoSubscribe) {
        this.subscribeToPublishers(msg.publishers);
      }
      this.delegate.roomPublishers(this, msg.publishers);
    }
  }

  async screenshareRoomJoined(msg: JanusJS.Message) {
    log.log(`screenshare: Successfully joined room ${msg.room} with ID ${msg.id}`);
    try {
      this.screenPublisher.status = ConnectionStatus.Connected;
      const videoOn = this.screenPublisher?.screenshareOriginalStream?.getVideoTracks().length > 0;
      const audioOn = this.screenPublisher?.screenshareOriginalStream?.getAudioTracks().length > 0;
      await this.screenPublisher.publishOwnFeed(audioOn, videoOn, false, 0);
    } catch (error) {
      log.error(`Room: Error Screensharing ${error.name} ${error.message}`);
    }
  }

  async dispatchVideoRoomEvent(publisher: BasePublisher, event: string, msg: JanusJS.Message) {
    try {
      switch (event) {
        case 'event':
          this.dispatchRoomEventMessage(publisher, msg);
          break;
        case 'joined':
          log.debug(`Room: VideoRoom ${publisher.publisherType} joined event`);
          try {
            if (publisher.publisherType === PublisherType.Camera) {
              this.roomJoined(msg);
            } else {
              await this.screenshareRoomJoined(msg);
            }
          } catch (error) {
            trackMetric('SFU Room After Join Error', {
              publisherType: publisher.publisherType,
              error: error.name,
              message: error.message,
            });
          }
          break;
        case 'destroyed':
          if (publisher.publisherType === PublisherType.Camera) {
            log.debug('Room: VideoRoom destroyed event');
            this.destroyed = true;
            this.delegate.roomDestroyed(this);
          }
          break;
        default:
          log.warn('Room: Unhandled event: ', event);
          break;
      }
    } catch (error) {
      trackMetric('SFU Dispatch Room Event Error', {
        publisherType: publisher.publisherType,
        error: error.name,
        message: error.message,
      });
    }
  }

  dispatchRoomEventMessage(publisher: BasePublisher, msg: JanusJS.Message) {
    if (msg.error !== undefined && msg.error !== null) {
      this.roomError(RoomError.PublisherHandleError, new Error(`${publisher.publisherType}: msg.error`));
      return;
    }

    if (msg.unpublished) {
      const { unpublished } = msg;
      if (unpublished === 'ok') {
        trackMetric('SFU Unpublished OK');
        log.log(`${publisher.publisherType}: We stop publishing!`);
      } else {
        log.log(`${publisher.publisherType}: Publisher unpublished: ${unpublished}`);
      }
    }

    if (publisher.publisherType !== PublisherType.Camera) {
      return;
    }

    if (msg.publishers) {
      log.debug('Room: VideoRoom got publishers');
      if (this.autoSubscribe) {
        this.subscribeToPublishers(msg.publishers);
      }
      this.delegate.roomPublishers(this, msg.publishers);
    } else if (msg.leaving) {
      log.log(`Room: Publisher left: ${msg.leaving}`);
      if (msg.leaving !== this.cameraPublisher?.userId) {
        this.subscriber?.feedCollection.deleteFeedId(msg.leaving);
        this.updateLocalStreamConstraints();
      }
    } else if (!msg.configured) {
      log.warn(`Room: unhandled message ${JSON.stringify(msg)}`);
    }
  }

  async roomExists(roomId: string): Promise<boolean> {
    const transaction = Janus.randomString(12);
    log.debug('Room: checking room ', roomId, 'exists, transaction:', transaction);
    const result = await this.publisherHandle.send({
      message: {
        request: 'exists',
        room: roomId,
        transaction,
      },
    });

    return result?.videoroom && result?.exists;
  }

  async createRoom(roomId: string): Promise<boolean> {
    log.debug('Room: creating room ', roomId);
    const result = await this.publisherHandle.send({
      message: {
        request: 'create',
        dummy_publisher: true,
        dummy_streams: [
          {
            codec: 'opus',
          },
        ],
        room: roomId,
        bitrate: 2500000,
        fir_freq: 10,
        videocodec: 'h264,vp8,av1',
        h264_profile: '42e01f',
        publishers: MAX_PUBLISHERS,
      },
    });

    log.debug('Room: creation result: ', result);

    return result?.videoroom && result?.videoroom === 'created';
  }

  private async attachToVideoroomPlugin() {
    log.log('Room: Attaching to videoroom plugin...');

    this.isEmployee = await isHereEmployeeCheck();

    this.subscriber = new Subscriber(this.janus, this.mypvtid, this.roomId, this.opaqueId, this.delegate, [
      this.myusername,
    ]);
    this.cameraPublisher = new CameraPublisher(this, this.myusername, this.constraints.defaultBitrate);
    this.screenPublisher = new ScreenPublisher(this.roomId, this);

    this.cameraPublisher.shouldMuteAfterConfigure = this.isEmployee;
    this.constraints.publisherEntity = this.cameraPublisher;

    this.publisherHandle = await this.cameraPublisher.attachPlugin({ opaqueId: this.opaqueId, delegate: this });
  }

  async startScreenshare(ready: () => void, restart: () => void, stream: MediaStream) {
    try {
      this.screenPublisherHandle = await this.screenPublisher.attachPlugin({
        opaqueId: `screenshare-${this.opaqueId}`,
        delegate: this,
      });
      this.screenPublisher.shareScreen(ready, restart, stream);
    } catch (error) {
      log.error(`Room: ScreenPublisher Attach Error: ${error.name} ${error.message}`);
      trackMetric('SFU Screenshare Attach Error', { error: error.message });
    }
  }

  listParticipants = asyncDebounce(
    async () => {
      const response: { [participants: string]: { id: string; display: string }[] } =
        await this.subscriber?.subscriberHandle?.send({
          message: { request: 'listparticipants', room: this.roomId },
        });

      if (!response) {
        log.warn('Room: Failed to call listParticipants, subscriber status:', this.subscriber?.status);
        return null;
      }
      log.debug('Room: listParticipants Me and', response.participants.length - 1, 'more');
      return response.participants;
    },
    10000,
    { leading: true }
  );

  async dispose() {
    log.debug('Room: Disposing');
    try {
      await this.cameraPublisher?.dispose();
      await this.subscriber?.dispose();
      await this.screenPublisher?.dispose();
    } catch (error) {
      log.error('Room: Has been an error leaving the room, ', error);
      this.publisherHandle?.hangup(true);
    } finally {
      this._status = ConnectionStatus.Disconnected;

      this.cameraPublisher = null;
      this.screenPublisher = null;
      this.subscriber = null;
      this.publisherHandle = null;

      if (!this.destroyed) this.delegate.roomLeave(this);
    }
  }

  async teardownRoom() {
    this.teardown = true;
    this.dispose();
  }

  publisherStreamReadyForHTML(stream: MediaStream, username: string, type: PublisherStreamSource) {
    if (this.cameraPublisher.hasVideoTracks) {
      this.constraints.updateLocalStreamConstraints(this.subscriber?.activeStreams || {}, this.isScreenSharing);
    }
    this.delegate.publisherStreamReadyForHTML(stream, username, type);
  }

  iceRestartCancelled(state: RTCIceConnectionState) {
    this.delegate.iceRestartCancelled(state);
  }

  updateLocalStreamConstraints() {
    if (this.cameraPublisher?.hasVideoTracks) {
      this.constraints.updateLocalStreamConstraints(this.subscriber?.activeStreams || {}, this.isScreenSharing);
    }
  }

  noICE(publisher: BasePublisher): void {
    this.delegate.noICE(publisher);
  }
}
