import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FontSelect from '../../room/settings/theme/FontSelect';
import { BoardElementControllerContext } from '../../common/contexts.ts';

const FontPicker = ({ currentState, onChange }) => {
  const { elementData } = useContext(BoardElementControllerContext);
  const onFontChange = useCallback(
    (fontData) => {
      if (fontData.family !== currentState.fontFamily) {
        onChange(fontData.family);
      }
    },
    [onChange, currentState.fontFamily]
  );

  const [dropdownDirection, setDropdownDirection] = useState('down');
  const onMouseEnter = useCallback((e) => {
    const rect = e.target.getBoundingClientRect();
    const direction = rect.bottom + 200 > window.innerHeight ? 'up' : 'down';
    setDropdownDirection(direction);
  }, []);

  return (
    <Container className="prevent-viewport-scrolling" onMouseEnter={onMouseEnter}>
      <FontSelect
        label=""
        id={elementData.id}
        currentFont={currentState.fontFamily}
        onFontChange={onFontChange}
        dropdownDirection={dropdownDirection}
      />
    </Container>
  );
};

export default FontPicker;

FontPicker.propTypes = {
  currentState: PropTypes.shape({
    fontFamily: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

const Container = styled.div`
  margin: 0 2px;
  width: 200px;

  // Increasing specificity
  .dropdown-button.dropdown-button {
    &,
    &:hover {
      background: none;
      height: 31px;
    }

    &:hover {
      box-shadow: 1px 1px 0px #bfbdbd;
    }
  }

  .font-list {
    background: white;
  }
`;
