import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import log from './log';
import BoardElement from './board-element';
import { track } from './util/analytics-util';
import { STARTED_GAME, Games } from './constants/analytics-events/games-events';

import '../styles/tictactoe.less';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

const GameStates = {
  X: '✕',
  O: '○',
  XWIN: 'xWin',
  OWIN: 'oWin',
  TIE: 'tie',
};

const winningConditions = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  [0, 4, 8],
  [2, 4, 6],
];

export default class TictactoeElement extends BoardElement {
  constructor(elementId) {
    super(elementId);

    this.boardState = ['', '', '', '', '', '', '', '', ''];
    this.gameState = GameStates.X;
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    this.updateBoardState(elementDoc.data().boardState, elementDoc.data().gameState);
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    const el = document.getElementById(`element-${elementDoc.id}`);
    this.statusDisplay = el.querySelector('.ttt-status');
    track(STARTED_GAME, { game: Games.TICTACTOE });
    el.querySelectorAll('.ttt-cell').forEach((cell) =>
      cell.addEventListener('click', (e) => {
        this.handleCellClick(e);
      })
    );
    el.querySelector('.ttt-restart').addEventListener('click', (e) => {
      this.handleRestartGame(e);
    });

    this.updateBoardState(elementDoc.data().boardState, elementDoc.data().gameState);
  }

  // Required method
  getElement(elementDoc) {
    const game = htmlToElement(`
      <div class="game-title-screen-div">
        <div class="ttt-game">
          <div>
            <span class="ttt-status">X's turn</span>
            <button class="ttt-restart here-button-regular">↻</button>
          </div>
          <div class="ttt-container">
            <div data-cell-index="0" class="ttt-cell"></div>
            <div data-cell-index="1" class="ttt-cell"></div>
            <div data-cell-index="2" class="ttt-cell"></div>
            <div data-cell-index="3" class="ttt-cell"></div>
            <div data-cell-index="4" class="ttt-cell"></div>
            <div data-cell-index="5" class="ttt-cell"></div>
            <div data-cell-index="6" class="ttt-cell"></div>
            <div data-cell-index="7" class="ttt-cell"></div>
            <div data-cell-index="8" class="ttt-cell"></div>
          </div>
        </div>
      </div>
    `);

    return wrapElement(game, elementDoc, {
      classes: ['tictactoe-card'],
      preserveAspectRatio: true,
    });
  }

  // Statics

  static async addElement() {
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'TictactoeElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 110) + window.innerHeight / 2
        ),
        size: [300, 300],
        zIndex: window.getFrontZIndex(),
        creator: firebase.auth().currentUser.uid,
        boardState: ['', '', '', '', '', '', '', '', ''],
        gameState: GameStates.X,
      })
      .then(() => {
        addSystemMessage('added tic tac toe');
        track(ADD_ELEMENT, { element: ELEMENT_TYPES.TIC_TAC_TOE, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
      })
      .catch((error) => {
        log.error(`Something bad happened creating TictactoeElement: ${error}`);
      });
  }

  minSize() {
    return [250, 250];
  }

  // Handler-specific:

  handleRestartGame() {
    this.updateBoardState(['', '', '', '', '', '', '', '', ''], GameStates.X);
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .doc(this.elementId)
      .update({ boardState: this.boardState, gameState: this.gameState });
  }

  updateBoardState(newBoard, newState) {
    const el = document.getElementById(`element-${this.elementId}`);

    this.boardState = newBoard;
    this.gameState = newState;

    switch (this.gameState) {
      case GameStates.X:
      case GameStates.O:
        this.statusDisplay.innerHTML = `${this.gameState}'s turn`;
        break;
      case GameStates.TIE:
        this.statusDisplay.innerHTML = "It's a draw.";
        break;
      case GameStates.XWIN:
        this.statusDisplay.innerHTML = `Player ${GameStates.X} wins!`;
        break;
      case GameStates.OWIN:
        this.statusDisplay.innerHTML = `Player ${GameStates.O} wins!`;
        break;
      default:
        log.warn(`weird game state: ${this.gameState}`);
    }

    el.querySelectorAll('.ttt-cell').forEach((cell) => {
      const id = cell.getAttribute('data-cell-index');
      let playerClass = '';
      let edgeClass = '';
      if (this.boardState[id] === GameStates.X) {
        playerClass = 'ttt-x';
      } else if (this.boardState[id] === GameStates.O) {
        playerClass = 'ttt-o';
      }
      if (id % 3 === 0) {
        edgeClass += 'ttt-left';
      } else if (id % 3 === 2) {
        edgeClass += 'ttt-right';
      }
      if (id < 3) {
        edgeClass += ' ttt-top';
      } else if (id >= 6) {
        edgeClass += ' ttt-bottom';
      }
      cell.className = `ttt-cell ${playerClass} ${edgeClass}`;
      cell.innerHTML = `${this.boardState[id].length > 0 ? this.boardState[id] : '&nbsp;'}`;
    });
  }

  handleCellPlayed(clickedCell, clickedCellIndex) {
    if (this.gameState === GameStates.X || this.gameState === GameStates.O) {
      this.boardState[clickedCellIndex] = this.gameState;
      this.handleResultValidation();
    }

    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .doc(this.elementId)
      .update({ boardState: this.boardState, gameState: this.gameState });
  }

  handleCellClick(clickedCellEvent) {
    const clickedCell = clickedCellEvent.target;
    const clickedCellIndex = parseInt(clickedCell.getAttribute('data-cell-index'), 10);
    if (
      this.boardState[clickedCellIndex] !== '' ||
      (this.gameState !== GameStates.X && this.gameState !== GameStates.O)
    ) {
      return;
    }
    this.handleCellPlayed(clickedCell, clickedCellIndex);
  }

  handleResultValidation() {
    let roundWon = false;
    for (let i = 0; i < winningConditions.length; i += 1) {
      const winCondition = winningConditions[i];
      const a = this.boardState[winCondition[0]];
      const b = this.boardState[winCondition[1]];
      const c = this.boardState[winCondition[2]];
      if (a === '' || b === '' || c === '') {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (a === b && b === c) {
        roundWon = true;
        break;
      }
    }
    if (roundWon) {
      if (this.gameState === GameStates.X) {
        this.gameState = GameStates.XWIN;
      } else {
        this.gameState = GameStates.OWIN;
      }
      return;
    }
    const roundDraw = !this.boardState.includes('');
    if (roundDraw) {
      this.gameState = GameStates.TIE;
      return;
    }

    // Change players
    this.gameState = this.gameState === GameStates.X ? GameStates.O : GameStates.X;
  }
}
