import firebase, { db } from '../firebase';
import { addSystemMessage } from '../message-util';
import { getDocIdFromElementId, getRandomNumberInRange, htmlToElement } from '../util';
import { playSoundEffect, soundTypes } from './sound-fx-util';
import log from '../log';
import { getElementPosition, getTranslateDistanceBetweenElements } from '../element-transform';
import { reduceUniquePinnedElements, unpinElement } from './pinned-util';
import { isManageAllContentAllowed, canDeleteContent } from '../roles-management';
import { DUPLICATE_ELEMENT } from '../constants/analytics-events/element-events';
import { track } from './analytics-util';

function getSystemMessageElementType(elementId) {
  const target = document.getElementById(`element-${elementId}`);

  let elementType = null;
  const handler = window.elementHandlers[elementId];
  if (handler && handler.elementDescription) {
    elementType = handler.elementDescription();
    delete window.elementHandlers[elementId];
  } else if (target.classList.contains('textCard')) {
    elementType = 'note';
  } else if (target.classList.contains('imageCard')) {
    elementType = 'GIF';
  } else if (target.classList.contains('warp')) {
    elementType = 'warp';
  } else {
    log.warn('Unknown element type being deleted');
  }

  return elementType;
}

/**
 *
 * Vertically mirrors element
 *
 * @param {string} docId
 */
export async function mirror(docId) {
  const ref = db.doc(`boards/${window.currentBoardId}/elements/${docId}`);
  const data = (await ref.get()).data();
  await ref.update({ isMirrored: !data.isMirrored });
}

export async function duplicate(elementId, { isSamePosition, elementData, source } = {}) {
  const positionDeviation = 150;
  const el = document.getElementById(`element-${elementId}`);
  const newPosition = getElementPosition(el);

  const duplicatedElementData = {
    ...(elementData || (await db.doc(`boards/${window.currentBoardId}/elements/${elementId}`).get()).data()),
    center: [
      isSamePosition ? newPosition[0] : newPosition[0] + positionDeviation * getRandomNumberInRange(-1, 1),
      isSamePosition ? newPosition[1] : newPosition[1] + positionDeviation * getRandomNumberInRange(-1, 1),
    ],
    zIndex: window.getFrontZIndex(),
    creator: firebase.auth().currentUser.uid,
  };

  delete duplicatedElementData.pinnedElements;

  await db
    .collection(`boards/${window.currentBoardId}/elements`)
    .add(duplicatedElementData)
    .then(() => {
      addSystemMessage('duplicated something!');
      track(DUPLICATE_ELEMENT, { element: duplicatedElementData.class, source });
    })
    .catch((error) => {
      log.error(`Something bad happened creating ${duplicatedElementData.class}: ${error}`);
    });
}

/**
 *
 * Deletes element from the board.
 * After successful deletion, sends a message to the chat with option to undelete.
 *
 * @param {string} elementId
 * @returns {Promise}
 */
export async function deleteElement(elementId) {
  if (isManageAllContentAllowed() || canDeleteContent()) {
    const elementToDelete = await db.doc(`boards/${window.currentBoardId}/elements/${elementId}`);

    // create snapshot for possible restoring
    const elementToDeleteSnapshot = await elementToDelete.get();
    const elementToDeleteSnapshotData = elementToDeleteSnapshot.data();
    elementToDeleteSnapshotData.deletedAt = firebase.firestore.FieldValue.serverTimestamp();
    elementToDeleteSnapshotData.deletedBy = firebase.auth().currentUser.uid;
    delete elementToDeleteSnapshotData.pinnedElements;

    // save snapshot
    db.doc(`boards/${window.currentBoardId}/deletedElements/${elementToDeleteSnapshot.id}`).set(
      elementToDeleteSnapshotData
    );

    const elementType = getSystemMessageElementType(elementId);
    if (elementType) {
      addSystemMessage(`trashed a ${elementType}`, elementId);
    }

    await elementToDelete.delete();

    // clean up pinned elements local data (just in case)
    Object.keys(window.elementHandlers).forEach((elementHandlerDocId) => {
      if (window.elementHandlers[elementHandlerDocId].pinnedElements) {
        window.elementHandlers[elementHandlerDocId].pinnedElements = window.elementHandlers[
          elementHandlerDocId
        ].pinnedElements.filter((pinnedElement) => pinnedElement.docId !== elementId);
      }
    });

    playSoundEffect(soundTypes.TRASH);
    track('Delete Element', { element: elementType });
  }
}

export async function undeleteElement(elementId, systemMessageId) {
  const deleteRef = db.collection('boards').doc(window.currentBoardId).collection('deletedElements').doc(elementId);
  const docRef = await deleteRef.get();
  if (docRef.exists) {
    const data = { ...docRef.data() };
    delete data.deletedAt;
    delete data.deletedBy;

    // Add back to element list
    await db.collection('boards').doc(window.currentBoardId).collection('elements').doc(elementId).set(data);

    // Remove from deleted items list
    deleteRef.delete();

    // Remove delete ID from log
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('feedItems')
      .doc(systemMessageId)
      .update({ deletedElementId: null });

    track('Undelete Element');
  } else {
    log.error(`Unknown deleted element ${elementId}, can't undelete`);
  }
}

function updatePinToastAndCloseIt(toastElement, text, closeTimeout = 2000) {
  toastElement.innerHTML = `<p>${text}</p>`;

  setTimeout(() => {
    toastElement.remove();
  }, closeTimeout);
}

// You can find some info about pinning here - https://github.com/herefm/here-web/wiki/Pinning-Elements
export function prepareToPin(elementToPinDocId) {
  // show toast
  const pinToElementToast = htmlToElement(`
  <div class="pin-to-element-toast">
    <p>Select an object to pin to</p>
  </div>
`);

  document.getElementById('main').appendChild(pinToElementToast);

  function resetCursorIcon() {
    document.body.style.cursor = 'auto';
    document.removeEventListener('click', resetCursorIconOnEsc);
    document.querySelectorAll('.pinnable').forEach((boardElement) => {
      boardElement.classList.remove('pinnable');
    });
  }

  function resetCursorIconOnEsc(event) {
    if (event.key === 'Escape') {
      resetCursorIcon();
      document.removeEventListener('click', tryPin);
      updatePinToastAndCloseIt(pinToElementToast, 'Pinning Canceled');
    }
  }

  async function tryPin(event) {
    const { target } = event;

    // check if clicked item is board element and original element wasn't deleted from the DOM
    const boardElementPinTo = target.closest('.boardElement');
    const originalElement = document.getElementById(`element-${elementToPinDocId}`);
    const isBoardElementPinToLocked =
      boardElementPinTo &&
      (boardElementPinTo.classList.contains('locked') || !boardElementPinTo.classList.contains('can-move'));
    if (boardElementPinTo && originalElement && !isBoardElementPinToLocked) {
      // update db
      const originalElementDocId = getDocIdFromElementId(originalElement.id);
      const boardElementPinToDocId = getDocIdFromElementId(boardElementPinTo.id);

      const abDistance = getTranslateDistanceBetweenElements(originalElement, boardElementPinTo);
      const abXRelatedDistance = abDistance[0] / Number(originalElement.style.width.replace('px', ''));
      const abYRelatedDistance = abDistance[1] / Number(originalElement.style.height.replace('px', ''));

      const baDistance = getTranslateDistanceBetweenElements(boardElementPinTo, originalElement);
      const baXRelatedDistance = baDistance[0] / Number(boardElementPinTo.style.width.replace('px', ''));
      const baYRelatedDistance = baDistance[1] / Number(boardElementPinTo.style.height.replace('px', ''));

      const pinnedElements1 = [
        ...window.elementHandlers[boardElementPinToDocId].pinnedElements,
        {
          docId: originalElementDocId,
          distance: {
            x: abXRelatedDistance,
            y: abYRelatedDistance,
          },
        },
      ].reduce(reduceUniquePinnedElements, []);

      const pinnedElements2 = [
        ...window.elementHandlers[originalElementDocId].pinnedElements,
        {
          docId: boardElementPinToDocId,
          distance: {
            x: baXRelatedDistance,
            y: baYRelatedDistance,
          },
        },
      ].reduce(reduceUniquePinnedElements, []);

      if (originalElementDocId !== boardElementPinToDocId) {
        await Promise.all([
          db.doc(`${boardElementPinTo.getAttribute('docpath')}`).update({
            pinnedElements: pinnedElements1,
          }),

          db.doc(`${originalElement.getAttribute('docpath')}`).update({
            pinnedElements: pinnedElements2,
          }),
        ]);

        track('Pin element');
        updatePinToastAndCloseIt(pinToElementToast, 'Pinned!');
      } else {
        updatePinToastAndCloseIt(pinToElementToast, "You can't pin element to itself!");
      }
    } else if (boardElementPinTo === null) {
      if (target.id === 'main') {
        // TODO: if user clicked background, pin item to the background
        // to be implemented in the https://app.clubhouse.io/hereapp/story/5320/pin-object-to-background

        // updatePinToastAndCloseIt(pinToElementToast, 'Locked!');
        updatePinToastAndCloseIt(pinToElementToast, "You can't pin anything to the background!");
      } else {
        updatePinToastAndCloseIt(pinToElementToast, 'Please select an object in the room to pin to!');
      }
    } else if (isBoardElementPinToLocked) {
      updatePinToastAndCloseIt(pinToElementToast, "You can't pin anything to a locked element");
    }

    resetCursorIcon();
    document.removeEventListener('click', tryPin);
  }

  // set cursor icon
  document.body.style.cursor = 'url("/images/icons/pin-cursor.svg"), auto';

  document.querySelectorAll('.can-move:not(.locked)').forEach((boardElement) => {
    if (`element-${elementToPinDocId}` !== boardElement.id) {
      boardElement.classList.add('pinnable');
    }
  });

  // reset icon on esc
  document.addEventListener('keydown', resetCursorIconOnEsc);

  setTimeout(() => {
    // handle pin attempt (any click with pin cursor)
    document.addEventListener('click', tryPin);
  }, 0);
}

export async function unpinFromAll(elementToUnpinDocId) {
  try {
    track('Unpin Element');

    await unpinElement(elementToUnpinDocId);

    // show  toast
    const pinToElementToast = htmlToElement(`
      <div class="pin-to-element-toast">
        <p>Unpinned!</p>
      </div>
    `);

    document.getElementById('main').appendChild(pinToElementToast);

    setTimeout(() => {
      pinToElementToast.remove();
    }, 1000);
  } catch (err) {
    log.error('Unpinning failed', err);
  }
}

export function getRotationTransforms({ width, height, rotationAngle }) {
  // Calculating rotated element coordinates. By taking max X and min Y
  // coords we can calculate bottom right coordinate of containing rectangle
  // that is parallel to axis. If we assume element center is 0,0, subtracting
  // from it original bottom right coords (i.e. w/2 and h/2) gives us
  // necessary footer buttons offset.
  // Credits to this guy for the formula https://math.stackexchange.com/a/270204
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const cos = Math.cos((rotationAngle * Math.PI) / 180);
  const sin = Math.sin((rotationAngle * Math.PI) / 180);

  const newPoints = [
    [-halfWidth, halfHeight],
    [halfWidth, halfHeight],
    [halfWidth, -halfHeight],
    [-halfWidth, -halfHeight],
  ].map(([x, y]) => [x * cos - y * sin, x * sin + y * cos]);
  const maxX = Math.max(...newPoints.map((p) => p[0]));
  const minY = Math.min(...newPoints.map((p) => p[1]));

  return {
    rightOffset: maxX - halfWidth,
    bottomOffset: -minY - halfHeight,
    leftOffset: halfWidth - maxX,
    topOffset: minY + halfHeight,
    containingRectangleScaleX: maxX / halfWidth,
    containingRectangleScaleY: -minY / halfHeight,
  };
}

export const calculateDistance = (point1, point2) => {
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;
  return Math.hypot(dx, dy);
};
