import React from 'react';
import styled from 'styled-components';

// components
import { svgColorMixin } from '../mixins';

// icons
import LeaveIcon from '../../../assets/icons/leave.svg';

interface PickerModalProps {
  close: () => void;
  children: React.ReactNode;
}

const PickerModal = ({ close, children }: PickerModalProps) => (
  <PickerContainer>
    <CloseButton onClick={close}>
      <LeaveIcon />
    </CloseButton>

    {children}
  </PickerContainer>
);

const PickerContainer = styled.div`
  padding: 27px 22px;
  border-radius: 20px;
  background-color: white;
`;

const CloseButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 25px;
  }

  ${svgColorMixin('#6b1be3')}
`;

export default PickerModal;
