import { addSystemMessage } from './message-util';
import firebase, { db } from './firebase';
import { joinWaitlist, leaveWaitlist, boardUsers, getPresenceElements } from './presence';
import { htmlToElement } from './util';
import { elementMoved } from './drag';
import { screenToCanvasCoords } from './util/canvas-util';
import log from './log';
import { offSnapshot, onSnapshot } from './firestore-watcher';
import { isWaitlistAllowedForBoardId } from './roles-management';
import wrapElement from './element-wrapper';
import { getCapacityInfoForBoardId } from './room-capacity';
import BoardElement from './board-element';
import eventBus, { loadBoardRequested } from './event-bus';

import '../styles/warp.less';
import { playSoundEffect, soundTypes } from './util/sound-fx-util';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

export default class WarpElement extends BoardElement {
  constructor(elementDoc) {
    super(elementDoc.id);

    this.watchMembership = this.watchMembership.bind(this);
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, __elementDoc) {
    return true;
  }

  watchMembership(membership) {
    this.isAllowed = !membership || !membership.ban;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  async setup(elementId, elementDoc) {
    const docData = elementDoc.data();

    this.destinationBoardId = docData.boardId;
    // TODO Save warp subscriptions to unsubscribe when leaving the room.

    this.isAllowed = true;
    onSnapshot(`boards/${docData.boardId}/members/${firebase.auth().currentUser.uid}`, this.watchMembership);

    const el = document.getElementById(`element-${elementDoc.id}`);
    const warpCenter = el.querySelector('.warp-center');
    warpCenter.addEventListener('click', async () => {
      if (elementMoved() || !this.isAllowed) {
        return;
      }

      const { isCapacityReached } = await getCapacityInfoForBoardId(docData.boardId);
      if (isCapacityReached) {
        window.location.pathname = `/${docData.boardId}`;
        return;
      }

      const isAllowedToBypassWaitlist = await isWaitlistAllowedForBoardId(docData.boardId);

      if (this.joinMode === 'waitlist' && !isAllowedToBypassWaitlist) {
        // Join waitlist
        joinWaitlist(
          this.destinationBoardId,
          () => {
            leaveWaitlist(this.destinationBoardId);
            this.handleJoinRoom();
          },
          (message) => {
            log.debug(`Received message from host: ${message}`);
            // TODO: handle incoming host messages for warps
          }
        );
        const waitlistMsg = el.querySelector('.warp-waitlist');
        if (waitlistMsg) {
          waitlistMsg.innerHTML = 'Waiting for Host';
          waitlistMsg.style.display = 'inline';
          warpCenter.classList.add('warp-pending');
          warpCenter.classList.remove('warp-animations');
          waitlistMsg.classList.remove('warp-waitlist-animations');
        }
      } else {
        this.handleJoinRoom();
      }
      playSoundEffect(soundTypes.WARP);
    });

    log.debug(`warp: Listening to changes for ${docData.boardId}`);
    this.boardUnsubscribe = db
      .collection('boards')
      .doc(docData.boardId)
      .onSnapshot(
        async (snapshot) => {
          if (!snapshot.exists) {
            log.debug(`Removing invalid warp for ${docData.boardId}`);
            await elementDoc.ref.delete();
            this.teardown();
            return;
          }

          const snapData = snapshot.data();
          this.joinMode = snapData.joinMode;
          const warpTitleEl = document.getElementById(`warp-title-${elementDoc.id}`);
          if (warpTitleEl) {
            warpTitleEl.innerHTML = snapData.title;
          }

          const waitlistMessage = el.querySelector('.warp-waitlist');
          if (waitlistMessage) {
            waitlistMessage.remove();
          }

          const isAllowedToBypassWaitlist = await isWaitlistAllowedForBoardId(docData.boardId);
          if (this.joinMode === 'waitlist' && !isAllowedToBypassWaitlist) {
            const waitMessage = htmlToElement(
              '<div class="warp-waitlist-container"><div class="warp-waitlist warp-waitlist-animations">Ask to Join</div></div>'
            );
            el.appendChild(waitMessage);
          }

          const { background } = snapData;
          if (background) {
            if (Number.isNaN(Number(background))) {
              warpCenter.style['background-image'] = `url('${background}')`;
            } else {
              warpCenter.style['background-image'] = `url('images/bg/thumb-${background}.jpg')`;
            }
          } else if (snapData.backgroundColor) {
            warpCenter.style['background-color'] = snapData.backgroundColor;
            warpCenter.style['background-image'] = '';
          }
        },
        (err) => {
          log.error('Error subscribing to warp board info', err);
        }
      );

    log.debug(`Updating warp presence with ${boardUsers[docData.boardId]}`);
    WarpElement.updatePresence(docData.boardId, boardUsers[docData.boardId]);
  }

  teardown() {
    this.boardUnsubscribe();
    offSnapshot(`boards/${this.destinationBoardId}/members/${firebase.auth().currentUser.uid}`, this.watchMembership);
  }

  // Required method
  getElement(elementDoc) {
    const warp = htmlToElement(`
      <div class="warp-container">
        <div class="warp-center warp-animations rotatable-container"></div>
        <div class="warp-users"></div>
        <div class="warp-title-container">
          <div class="warp-title" id="warp-title-${elementDoc.id}"></div>
        </div>
      </div>
    `);

    return wrapElement(warp, elementDoc, {
      classes: ['warp'],
      preserveAspectRatio: true,
      hasViewerControls: true,
    });
  }

  handleJoinRoom() {
    const { isAudioOn, isVideoOn } = window.rtc;

    log.debug(`Warp click - warping to ${this.destinationBoardId}`);
    track('Warp click');
    window.history.pushState(null, document.title, `${this.destinationBoardId}`);
    eventBus.dispatch(loadBoardRequested, {
      roomId: this.destinationBoardId,
      title: null,
      isVideoOn,
      isAudioOn,
    });
  }

  // Statics

  static async addElement(boardId, title, mode = null) {
    try {
      await db
        .collection('boards')
        .doc(window.currentBoardId)
        .collection('elements')
        .add({
          class: 'WarpElement',
          center: screenToCanvasCoords(
            Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
            Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
          ),
          size: [200, 200],
          boardId,
          mode,
          zIndex: window.getFrontZIndex(),
          boardTitle: title,
          creator: firebase.auth().currentUser.uid,
        });

      addSystemMessage(`added a warp to ${title}`);
      track(ADD_ELEMENT, { element: ELEMENT_TYPES.WARP, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
    } catch (error) {
      log.error(`Something bad happened creating WarpElement: ${error}`);
    }
  }

  static updatePresence(boardId, users) {
    const warps = Object.values(window.elementHandlers).filter((h) => h.constructor.elementType === 'WarpElement');
    warps.forEach((warp) => {
      if (warp.destinationBoardId !== boardId) {
        return;
      }

      const el = document.getElementById(`element-${warp.elementId}`);
      const usersContainer = el.querySelector('.warp-users');
      usersContainer.innerHTML = '';
      if (users) {
        usersContainer.append(...getPresenceElements(users, boardId, 'warp-presence'));
      }
    });
  }
}

WarpElement.elementType = 'WarpElement';
