import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { observeRoom, unobserveRoom } from '../../web-lobby/components/room-items/utils/room-item-observer';
import eventBus, { roomVisibilityChanged } from '../../../event-bus';
import { selectIsRoomVisible } from '../../store/web-lobby/selectors';

interface Props {
  roomId: string;
  children: React.ReactNode;
}

const ObservableRoomItem = ({ roomId, children }: Props) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const isVisibleInStore = useSelector((state) => selectIsRoomVisible(state, roomId));

  useEffect(() => {
    const containerCopy = containerRef.current;
    const listener = (changedRoomId: string, isVisibleValue: boolean) => {
      if (changedRoomId === roomId) {
        setIsVisible(isVisibleValue);
      }
    };

    observeRoom(roomId, containerCopy);
    eventBus.on(roomVisibilityChanged, listener);

    return () => {
      unobserveRoom(roomId, containerCopy);
      eventBus.off(roomVisibilityChanged, listener);
    };
  }, [roomId]);

  return (
    <Container ref={containerRef} data-roomid={roomId}>
      {isVisible || isVisibleInStore ? children : null}
    </Container>
  );
};

export default ObservableRoomItem;

const Container = styled.div`
  height: 100%;
  width: 100%;
`;
