import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { requestGroupUpdate } from '../../../store/groups/actions';
import { getGroupChatParams, track } from '../../../../util/analytics-util';
import { CHAT_EDIT_SKIN, SkinPropertyToCategory } from '../../analytics';
import { Chat } from '../../../../definitions/message';

const useChangeSkinProperty = (chat: Chat, property: 'background' | 'decorator' | 'particle' | 'frame') => {
  const dispatch = useDispatch();

  return useCallback(
    (value) => {
      const updates = {
        theme: {
          isCustom: false,
          skin: {
            ...(chat?.theme?.skin || {}),
            name: 'custom',
            [property]: value ? { ...value } : null,
          },
        },
      };

      if (value) {
        delete updates.theme.skin[property].type;
        delete updates.theme.skin[property].created_at;
        delete updates.theme.skin[property].deleted_at;
        delete updates.theme.skin[property].employeesOnly;
        delete updates.theme.skin?.particle?.thumbnail;
      } else {
        delete updates.theme.skin[property];
      }

      dispatch(requestGroupUpdate({ id: chat.groupId, updates }));
      const params = getGroupChatParams(
        {
          chatId: chat.id,
          // @ts-ignore
          category: SkinPropertyToCategory[property],
          name: value?.name || '',
          // @ts-ignore
          oldName: chat?.theme?.skin?.[property]?.name || '',
        },
        chat.id
      );
      track(CHAT_EDIT_SKIN, params);
    },
    [chat?.theme?.skin, chat.groupId, chat.id, property, dispatch]
  );
};

export { useChangeSkinProperty };
