import React, { useEffect, useRef } from 'react';
import {
  ContainerDefault,
  TitleContainer,
  ScreenTitle,
  ScreenSubTitle,
  NavButtonsContainer,
  SingleNextNavButton,
  IconContainer,
} from '../../sign-in-flow/shared-styled';
import CheckBurst from '../../../../assets/icons/check-burst.svg';

const Success = ({ closePhoneModal }: { closePhoneModal: () => void }) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    // Set a timer to close out the modal after 3 seconds if modal has not been manually closed
    setTimeout(() => {
      if (isMounted.current) {
        closePhoneModal();
      }
    }, 3000);

    return () => {
      isMounted.current = false;
    };
  }, [closePhoneModal]);

  return (
    <ContainerDefault>
      <IconContainer>
        <CheckBurst />
      </IconContainer>
      <TitleContainer>
        <ScreenTitle>Phone Number Verified!</ScreenTitle>
        <ScreenSubTitle>Thank you.</ScreenSubTitle>
      </TitleContainer>
      <NavButtonsContainer>
        <SingleNextNavButton onClick={closePhoneModal} width="50%">
          Close
        </SingleNextNavButton>
      </NavButtonsContainer>
    </ContainerDefault>
  );
};

export default Success;
