import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../../../firebase';
import { selectCountryCode, selectDomesticPhoneNumber, selectVerificationId } from '../../store/signing-in/selectors';
import { goPrevScreen, verifyConfirmationCode } from '../../store/signing-in/actions';
import VerifyCodeForm from '../../phone/screens/VerifyCodeForm';
import { formatPhone } from '../../phone/phone-util';

const VerifyPhoneCodeScreen = ({
  handleSendVerificationCode,
}: {
  handleSendVerificationCode: (phoneNumber: string) => Promise<string>;
}) => {
  const dispatch = useDispatch();
  const domesticPhoneNumber = useSelector(selectDomesticPhoneNumber);
  const countryCode = useSelector(selectCountryCode);
  const phoneNumber = formatPhone(countryCode, domesticPhoneNumber);
  const initialVerificationId = useSelector(selectVerificationId);
  const [isNewUser, setIsNewUser] = useState(false);
  const [uid, setUid] = useState(null);

  const onBack = () => {
    dispatch(goPrevScreen({ screenData: {} }));
  };

  const onVerify = useCallback(() => {
    dispatch(verifyConfirmationCode({ isNewUser, uid }));
  }, [isNewUser, uid, dispatch]);

  const onValidate = useCallback(async (verificationId: string, confirmationCode: string) => {
    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, confirmationCode);
    const result = await firebase.auth().signInWithCredential(credential);

    setIsNewUser(result?.additionalUserInfo?.isNewUser);
    setUid(result?.user?.uid);
  }, []);

  const handleResendVerificationCode = useCallback(
    () => handleSendVerificationCode(phoneNumber),
    [phoneNumber, handleSendVerificationCode]
  );

  return (
    <VerifyCodeForm
      onBack={onBack}
      onVerify={onVerify}
      handleResendVerificationCode={handleResendVerificationCode}
      initialVerificationId={initialVerificationId}
      onValidate={onValidate}
    />
  );
};

export default VerifyPhoneCodeScreen;
