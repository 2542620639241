import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { removeButtonStylingMixin, svgColorMixin } from '../../mixins';
import { CloseIcon } from '../../icons';
import ChevronLeftIcon from '../../../../assets/icons/arrows/pointed-chevron-left.svg';

const RoomSettingsHeader = ({ label, hasBackButton, onBack, onClose }) => (
  <Container>
    {hasBackButton ? (
      <BackButton onClick={onBack} type="button">
        <ChevronLeftIcon />
      </BackButton>
    ) : null}
    <Label>{label}</Label>
    <CloseButton onClick={onClose} type="button">
      <CloseIcon color="black" width={14} height={14} />
    </CloseButton>
  </Container>
);

export default RoomSettingsHeader;

RoomSettingsHeader.propTypes = {
  label: PropTypes.string.isRequired,
  hasBackButton: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

RoomSettingsHeader.defaultProps = {
  onBack: () => {},
};

const Container = styled.div`
  background: var(--primary-background, white);
  color: var(--primary-foreground, black);
  box-shadow: 0 -3px 27px -6px black;
  height: 64px;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: 22px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const IconButton = styled.button`
  ${removeButtonStylingMixin()}
  ${svgColorMixin('var(--primary-foreground, black)')}
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.15);
  }
`;

const BackButton = styled(IconButton)`
  margin-left: 22px;
`;

const CloseButton = styled(IconButton)`
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const Label = styled.div`
  font-size: 16px;
  margin-left: 28px;
  flex: 1;
  margin-top: 2px;
  font-weight: 600;
`;
