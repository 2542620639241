import { useState, useEffect } from 'react';

import { offSnapshot, onSnapshot } from '../../firestore-watcher';

export function useBoardData(currentBoardId) {
  const [currentBoardData, setCurrentBoardData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function handleBoardUpdate(boardData) {
      setCurrentBoardData(boardData);
      setLoading(false);
    }

    onSnapshot(`boards/${currentBoardId}`, handleBoardUpdate);

    return () => {
      if (currentBoardId) {
        offSnapshot(`boards/${currentBoardId}`, handleBoardUpdate);
      }
      setLoading(true);
    };
  }, [currentBoardId]);

  return [currentBoardData, loading];
}
