import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// util
import { isMobileOrTablet } from '../../util/platform-util';

// ui
import MessagesSidebar from '../chats/messaging/MessagesSidebar';
import { hideableMixin } from '../mixins';

// store
import { selectMessagesSidebarOpen } from '../store/messaging/selectors';

const Sidebar = () => {
  const messagesOpen = useSelector(selectMessagesSidebarOpen);
  const [userOnDesktop, setUserOnDesktop] = useState(true);

  useEffect(() => {
    if (isMobileOrTablet()) {
      setUserOnDesktop(false);
    }
  }, []);

  return (
    <SidebarContainer className="sidebar-container" messagesOpen={messagesOpen} isHiddenDisplay={!userOnDesktop}>
      <MessagesSidebarContainer className="messages-sidebar-container">
        <MessagesSidebar messagesOpen={messagesOpen} />
      </MessagesSidebarContainer>
    </SidebarContainer>
  );
};

export default Sidebar;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ messagesOpen }) => (messagesOpen ? '100%' : 'unset')};

  ${({ messagesOpen }) =>
    messagesOpen &&
    `
      &:hover {
        background: linear-gradient(-90deg, rgba(19, 1, 46, 0) 0%, rgba(19, 2, 46, 0.5) 100%);
      }
    `}

  @media (max-width: 576px) {
    display: none;
  }

  ${hideableMixin()}
`;

const MessagesSidebarContainer = styled.div`
  padding-top: 56px;
  padding-bottom: 10px;
  display: block;
  height: 100%;
`;
