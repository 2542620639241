export interface Kaomoji {
  content: string;
  createdAt?: Date;
}

export interface KaomojiCategory {
  name: string;
  isTrending?: boolean;
  kaomojis: Kaomoji[];
}

export interface KaomojiState {
  isLoading: boolean;
  defaultKaomojis: KaomojiCategory[];
}

export const initialState: KaomojiState = {
  isLoading: false,
  defaultKaomojis: [],
};
