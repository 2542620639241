import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { track } from '../../util/analytics-util';

import { PencilIcon } from '../icons';
import SmileyIcon from '../../../assets/icons/smiley.svg';

import { closeUserProfile } from './utils';
import { renderFriendsModal } from '../friends/render';
import { incomingPendingRequestsSelector } from '../store/friends/selectors';
import { VIEW_FRIENDS } from '../../constants/analytics-events/friend-events';
import MyStuffSettings from './my-stuff-settings/index.tsx';
import eventBus, { profileEdited } from '../../event-bus';

const ProfileCTAsForSelf = ({ setEditingMode, profileWrapperElement }) => {
  // subscribe to pending friend requests if user profile is self
  const pendingFriendRequests = useSelector(incomingPendingRequestsSelector);

  // only show friends button in cases where friends modal exists:
  const showFriendsButton = Boolean(document.getElementById('friends-modal'));

  const onEditClick = () => {
    setEditingMode(true);

    // Source param here is so we can differentiate with editing from big user profile later:
    track('Edit User Profile', { source: 'small user profile' });
    eventBus.dispatch(profileEdited);
  };

  const closeRoomSettingsModal = () => {
    const roomSettingsModal = document.querySelector('here-room-settings');
    if (roomSettingsModal) roomSettingsModal.classList.remove('uk-flex', 'uk-open');
  };

  const openFriendsModal = () => {
    // for case where profile is opened from settings modal:
    closeRoomSettingsModal();

    profileWrapperElement.style.display = 'none';
    closeUserProfile();

    renderFriendsModal();

    // this button opens directly to friends list so:
    track(VIEW_FRIENDS, { source: 'user profile friends button click' });
  };

  return (
    <>
      <ButtonContainer>
        {showFriendsButton && (
          <>
            <CTAButton onClick={openFriendsModal} className="here-button-regular" data-testid="friends-profile-button">
              <IconContainer>
                <SmileyIcon />
              </IconContainer>
              Friends
            </CTAButton>
            {pendingFriendRequests.length > 0 && (
              <FriendsNotificationContainer>
                <FriendsNotification>{pendingFriendRequests.length}</FriendsNotification>
              </FriendsNotificationContainer>
            )}
          </>
        )}
        <MyStuffSettings />
        <EditAccountButton
          onClick={onEditClick}
          className="here-button-regular onboarding-edit-profile"
          showFriendsButton={showFriendsButton}
          data-testid="edit-profile-button"
        >
          <IconContainer>
            <PencilIcon size="12" strokeWidth="3" />
          </IconContainer>
          Edit
        </EditAccountButton>
      </ButtonContainer>
    </>
  );
};

export default ProfileCTAsForSelf;

ProfileCTAsForSelf.propTypes = {
  setEditingMode: PropTypes.func.isRequired,
  profileWrapperElement: PropTypes.instanceOf(Element),
};

ProfileCTAsForSelf.defaultProps = {
  profileWrapperElement: null,
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`;

const CTAButton = styled.div`
  padding: 11px;
  font-size: 12px;
  line-height: 17px;
  z-index: 2;
  flex: 1 0 auto;
`;

const EditAccountButton = styled(CTAButton)`
  ${({ showFriendsButton }) =>
    !showFriendsButton &&
    `
    width: 100%;
    margin-left: 0px;
  `}
`;

const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const FriendsNotificationContainer = styled.div`
  position: relative;
  z-index: 3;
`;

const FriendsNotification = styled.div`
  position: absolute;
  background: #f6335d;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  font-family: 'Inter';
  font-weight: 600;
  color: #ffffff;
  border: 2px solid #12002d;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -18px;
  top: -7px;
`;
