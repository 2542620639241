import {
  ONBOARDING_COMPLETED,
  ONBOARDING_INVITE_COPY_ROOM_LINK_CLICKED,
  ONBOARDING_MEDIA_PLAYER_BUTTON_CLICKED,
  ONBOARDING_MEDIA_PLAYER_VIDEO_SELECTED,
  ONBOARDING_REACTIONS_HINT_STEP_COMPLETED,
  ONBOARDING_REACTIONS_STEP_COMPLETED,
} from '../../constants/analytics-events/onboarding-events';
import { track } from '../../util/analytics-util';
import { AuthTypes, FLOW_FINISHED, Screens, CreateOsDesktopFlowSteps, CreateOsChatFlowSteps } from './constants';

const authTypeSelectionStep = {
  id: Screens.AUTH_TYPE_SELECTION,

  getPrevScreen() {
    throw new Error('Should not be able to go back from this screen');
  },

  getNextScreen({ authType, hasUsername }: { authType: AuthTypes; hasUsername: boolean }) {
    if (authType === AuthTypes.EMAIL) {
      return Screens.EMAIL_INPUT;
    }

    if (authType === AuthTypes.PHONE) {
      return Screens.PHONE_INPUT;
    }

    if (authType === AuthTypes.GMAIL || authType === AuthTypes.APPLE) {
      if (hasUsername === true) {
        return FLOW_FINISHED;
      }

      if (hasUsername === false) {
        return Screens.CREATE_USERNAME;
      }

      throw new Error('Wrong hasUsername');
    }

    throw new Error('Wrong auth type');
  },
};

const flowFinishedStep = {
  id: FLOW_FINISHED,

  getPrevScreen() {
    throw new Error('Should not be able to go back from this screen');
  },

  getNextScreen() {
    throw new Error('Should not be able to go forward from this screen');
  },
};

const emailSignInStep = {
  id: Screens.EMAIL_SIGN_IN,

  getPrevScreen() {
    return Screens.EMAIL_INPUT;
  },

  getNextScreen({ hasUsername, resetPassword }: { hasUsername: boolean; resetPassword: boolean }) {
    if (resetPassword === true) {
      return Screens.RESET_PASSWORD;
    }

    if (hasUsername === true) {
      return FLOW_FINISHED;
    }

    if (hasUsername === false) {
      return Screens.CREATE_USERNAME;
    }

    throw new Error('Wrong hasUsername value');
  },
};

const resetPasswordStep = {
  id: Screens.RESET_PASSWORD,

  getPrevScreen() {
    return Screens.EMAIL_SIGN_IN;
  },

  getNextScreen() {
    return Screens.PASSWORD_WAS_RESET;
  },
};

const passwordWasResetStep = {
  id: Screens.PASSWORD_WAS_RESET,

  getPrevScreen() {
    throw new Error('Should not be able to go back from this screen');
  },

  getNextScreen() {
    return Screens.EMAIL_SIGN_IN;
  },
};

const phoneInputStep = {
  id: Screens.PHONE_INPUT,

  getPrevScreen() {
    return Screens.AUTH_TYPE_SELECTION;
  },

  getNextScreen() {
    return Screens.PHONE_VERIFY_CODE;
  },
};

const createUsernameAndNameStep = {
  id: Screens.CREATE_USERNAME_AND_NAME,

  getPrevScreen() {
    throw new Error('Should not be able to go back from this screen');
  },

  getNextScreen({
    inSchoolSupportedLocation,
    hasFriendsSuggestions,
  }: {
    inSchoolSupportedLocation: boolean;
    hasFriendsSuggestions: boolean;
  }) {
    if (inSchoolSupportedLocation) {
      return Screens.SCHOOL_SELECTION;
    }

    if (hasFriendsSuggestions) {
      return Screens.FRIENDS_SUGGESTIONS;
    }

    return FLOW_FINISHED;
  },
};

const verifyPhoneCodeStep = {
  id: Screens.PHONE_VERIFY_CODE,

  getPrevScreen() {
    return Screens.PHONE_INPUT;
  },

  getNextScreen({ isNewUser }: { isNewUser: boolean }) {
    if (isNewUser) {
      return Screens.CREATE_USERNAME_AND_NAME;
    }

    return FLOW_FINISHED;
  },
};

const emailInputStep = {
  id: Screens.EMAIL_INPUT,

  getPrevScreen() {
    return Screens.AUTH_TYPE_SELECTION;
  },

  getNextScreen({ profileAlreadyExists }: { profileAlreadyExists: boolean }) {
    if (profileAlreadyExists === true) {
      return Screens.EMAIL_SIGN_IN;
    }

    if (profileAlreadyExists === false) {
      return Screens.CREATE_ACCOUNT;
    }

    throw new Error('Wrong profileAlreadyExists value');
  },
};

const createAccountStep = {
  id: Screens.CREATE_ACCOUNT,

  getPrevScreen() {
    return Screens.EMAIL_INPUT;
  },

  getNextScreen() {
    return Screens.CREATE_USERNAME;
  },
};

const createUsernameStep = {
  id: Screens.CREATE_USERNAME,

  getPrevScreen() {
    throw new Error('Should not be able to go back from this screen');
  },

  getNextScreen({
    inSchoolSupportedLocation,
    hasFriendsSuggestions,
  }: {
    inSchoolSupportedLocation: boolean;
    hasFriendsSuggestions: boolean;
  }) {
    if (inSchoolSupportedLocation) {
      return Screens.SCHOOL_SELECTION;
    }

    if (hasFriendsSuggestions) {
      return Screens.FRIENDS_SUGGESTIONS;
    }

    return FLOW_FINISHED;
  },
};

const schoolSelectionStep = {
  id: Screens.SCHOOL_SELECTION,

  getPrevScreen() {
    throw new Error('Should not be able to go back from this screen');
  },

  getNextScreen({ hasFriendsSuggestions }: { hasFriendsSuggestions: boolean }) {
    if (hasFriendsSuggestions === true) {
      return Screens.FRIENDS_SUGGESTIONS;
    }

    if (hasFriendsSuggestions === false) {
      return FLOW_FINISHED;
    }

    throw new Error('Wrong hasFriendsSuggestions value');
  },
};

const friendsSuggestionsStep = {
  id: Screens.FRIENDS_SUGGESTIONS,

  getPrevScreen() {
    throw new Error('Should not be able to go back from this screen');
  },

  getNextScreen() {
    return FLOW_FINISHED;
  },
};

export const onboardingFlow = {
  [Screens.AUTH_TYPE_SELECTION]: authTypeSelectionStep,

  [Screens.PHONE_INPUT]: phoneInputStep,

  [Screens.PHONE_VERIFY_CODE]: verifyPhoneCodeStep,

  [Screens.CREATE_USERNAME_AND_NAME]: createUsernameAndNameStep,

  [Screens.EMAIL_INPUT]: emailInputStep,

  [Screens.EMAIL_SIGN_IN]: emailSignInStep,

  [Screens.RESET_PASSWORD]: resetPasswordStep,

  [Screens.PASSWORD_WAS_RESET]: passwordWasResetStep,

  [Screens.CREATE_USERNAME]: createUsernameStep,

  [Screens.SCHOOL_SELECTION]: schoolSelectionStep,

  [Screens.FRIENDS_SUGGESTIONS]: friendsSuggestionsStep,

  [Screens.CREATE_ACCOUNT]: createAccountStep,

  [FLOW_FINISHED]: flowFinishedStep,

  getFirstScreen() {
    return this[Screens.AUTH_TYPE_SELECTION];
  },
};

export const deepLinkFlow = {
  [Screens.AUTH_TYPE_SELECTION]: authTypeSelectionStep,

  [Screens.EMAIL_INPUT]: emailInputStep,

  [Screens.EMAIL_SIGN_IN]: emailSignInStep,

  [Screens.RESET_PASSWORD]: resetPasswordStep,

  [Screens.PASSWORD_WAS_RESET]: passwordWasResetStep,

  [Screens.PHONE_INPUT]: phoneInputStep,

  [Screens.PHONE_VERIFY_CODE]: verifyPhoneCodeStep,

  [Screens.CREATE_USERNAME_AND_NAME]: {
    id: Screens.CREATE_USERNAME_AND_NAME,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return FLOW_FINISHED;
    },
  },

  [Screens.CREATE_USERNAME]: createUsernameStep,

  [Screens.SCHOOL_SELECTION]: schoolSelectionStep,

  [Screens.FRIENDS_SUGGESTIONS]: friendsSuggestionsStep,

  [Screens.CREATE_ACCOUNT]: createAccountStep,

  [FLOW_FINISHED]: flowFinishedStep,

  getFirstScreen() {
    return this[Screens.AUTH_TYPE_SELECTION];
  },
};

export const createUsernameFlow = {
  [Screens.CREATE_USERNAME]: {
    id: Screens.CREATE_USERNAME,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return FLOW_FINISHED;
    },
  },

  [FLOW_FINISHED]: flowFinishedStep,

  getFirstScreen() {
    return this[Screens.CREATE_USERNAME];
  },
};

export const createUsernameAndDisplayNameFlow = {
  [Screens.CREATE_USERNAME_AND_NAME]: {
    id: Screens.CREATE_USERNAME_AND_NAME,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return FLOW_FINISHED;
    },
  },

  [FLOW_FINISHED]: flowFinishedStep,

  getFirstScreen() {
    return this[Screens.CREATE_USERNAME_AND_NAME];
  },
};

export const roomOnboardingFlow = {
  [Screens.REACTIONS_HINT]: {
    id: Screens.REACTIONS_HINT,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      track(ONBOARDING_REACTIONS_HINT_STEP_COMPLETED);
      return Screens.REACTIONS_ONBOARDING;
    },
  },

  [Screens.REACTIONS_ONBOARDING]: {
    id: Screens.REACTIONS_ONBOARDING,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      track(ONBOARDING_REACTIONS_STEP_COMPLETED);
      return Screens.MEDIA_PLAYER_ONBOARDING_HIGHLIGHT_BUTTON;
    },
  },

  [Screens.MEDIA_PLAYER_ONBOARDING_HIGHLIGHT_BUTTON]: {
    id: Screens.MEDIA_PLAYER_ONBOARDING_HIGHLIGHT_BUTTON,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      track(ONBOARDING_MEDIA_PLAYER_BUTTON_CLICKED);
      return Screens.MEDIA_PLAYER_ONBOARDING_PLAYER_HINT;
    },
  },

  [Screens.MEDIA_PLAYER_ONBOARDING_PLAYER_HINT]: {
    id: Screens.MEDIA_PLAYER_ONBOARDING_PLAYER_HINT,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      track(ONBOARDING_MEDIA_PLAYER_VIDEO_SELECTED);
      return Screens.MEDIA_PLAYER_ONBOARDING_INVITE_FRIENDS;
    },
  },

  [Screens.MEDIA_PLAYER_ONBOARDING_INVITE_FRIENDS]: {
    id: Screens.MEDIA_PLAYER_ONBOARDING_INVITE_FRIENDS,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      track(ONBOARDING_INVITE_COPY_ROOM_LINK_CLICKED);
      return Screens.ROOM_ONBOARDING_FINISHED;
    },
  },

  [Screens.ROOM_ONBOARDING_FINISHED]: {
    id: Screens.ROOM_ONBOARDING_FINISHED,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      track(ONBOARDING_COMPLETED);
      return FLOW_FINISHED;
    },
  },

  [FLOW_FINISHED]: flowFinishedStep,

  getFirstScreen(screenId?: string) {
    if (screenId) {
      return this[screenId];
    }

    return this[Screens.REACTIONS_HINT];
  },
};

export const createOsDesktopFlow = {
  [FLOW_FINISHED]: flowFinishedStep,

  getFirstScreen() {
    return this[CreateOsDesktopFlowSteps.VIBE_PICKER];
  },

  [CreateOsDesktopFlowSteps.VIBE_PICKER]: {
    id: CreateOsDesktopFlowSteps.VIBE_PICKER,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return CreateOsDesktopFlowSteps.DESKTOP_CREATION;
    },
  },

  [CreateOsDesktopFlowSteps.DESKTOP_CREATION]: {
    id: CreateOsDesktopFlowSteps.DESKTOP_CREATION,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return FLOW_FINISHED;
    },
  },
};

export const createOsChatFlow = {
  [FLOW_FINISHED]: flowFinishedStep,

  getFirstScreen() {
    return this[CreateOsChatFlowSteps.CREATE_CHAT];
  },

  [CreateOsChatFlowSteps.CREATE_CHAT]: {
    id: CreateOsChatFlowSteps.CREATE_CHAT,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return CreateOsChatFlowSteps.CUSTOMIZE_CHAT;
    },
  },

  [CreateOsChatFlowSteps.CUSTOMIZE_CHAT]: {
    id: CreateOsChatFlowSteps.CUSTOMIZE_CHAT,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return CreateOsChatFlowSteps.CHAT_INSTRUCTIONS;
    },
  },

  [CreateOsChatFlowSteps.CHAT_INSTRUCTIONS]: {
    id: CreateOsChatFlowSteps.CHAT_INSTRUCTIONS,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return CreateOsChatFlowSteps.CHAT_COMPLETION;
    },
  },

  [CreateOsChatFlowSteps.CHAT_COMPLETION]: {
    id: CreateOsChatFlowSteps.CHAT_COMPLETION,

    getPrevScreen() {
      throw new Error('Should not be able to go back from this screen');
    },

    getNextScreen() {
      return FLOW_FINISHED;
    },
  },
};
