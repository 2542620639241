import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRemixItems } from './remix-data';
import Loader from '../../components/Loader';
import { selectActiveGroupId } from '../../store/web-lobby/selectors.ts';

const RemixTab = ({ categoryId, isActive, onItemSelected }) => {
  const groupId = useSelector(selectActiveGroupId);
  const [items, setItems] = useState([]);
  useEffect(() => {
    const init = async () => setItems(await getRemixItems(categoryId, groupId));
    if (isActive && !items.length) {
      init();
    }
  }, [categoryId, groupId, isActive, items]);

  const onDefaultItemClick = useCallback(
    (e) => {
      const rect = e.target.getBoundingClientRect();
      const aspectRatio = rect.width / rect.height;
      const url = e.target.src;
      onItemSelected({ url, aspectRatio, category: categoryId });
    },
    [onItemSelected, categoryId]
  );

  return (
    <Container>
      {!items.length && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <ItemsContainer>
        {items.map((item) => (
          <Item key={item.id} alt={item.id} src={item.url} loading="lazy" onClick={onDefaultItemClick} />
        ))}
      </ItemsContainer>
    </Container>
  );
};

export default RemixTab;

RemixTab.propTypes = {
  categoryId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onItemSelected: PropTypes.func.isRequired,
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 250px;
`;

const ItemsContainer = styled.div`
  height: fill-available;
  overflow: auto;
  position: absolute;
  text-align: center;
`;

const Item = styled.img`
  width: 100px;
  display: inline-block;
  cursor: pointer;
  margin: 8px;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
