import firebase, { db } from './firebase';
import { handleWheelEvent } from './viewport';
import { screenToCanvasCoords } from './util/canvas-util';
import log from './log';
import { elementMoved } from './drag';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import BoardElement from './board-element';

import '../styles/cobrowser.less';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';
import { track } from './util/analytics-util';
import { USER_CARD_BOARD_TYPE } from './constants/board-constants';

export default class CobrowserElement extends BoardElement {
  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, __elementDoc) {
    return true;
  }

  setupOverlay(element) {
    const overlay = element.querySelector('.cobrowser-overlay');
    overlay.addEventListener('click', () => {
      if (elementMoved()) {
        return;
      }

      track('Focus', { element: this.elementType });

      const iframe = element.querySelector('iframe');
      if (iframe) {
        iframe.focus();
      }

      element.querySelector('.underlay').classList.add('cobrowser-focused');
      overlay.style.display = 'none';
      document.addEventListener('mouseup', this.onMouseUp.bind(this));
    });
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    const element = document.getElementById(elementId);
    const iframe = element.querySelector('iframe');
    iframe.addEventListener('load', () => {
      try {
        iframe.contentWindow.addEventListener('wheel', handleWheelEvent, { passive: false });
      } catch (e) {
        log.debug("Failed to capture wheel event, iframe probably doesn't support cross domain events");
      }
    });
    iframe.src = this.url || elementDoc.data().url;

    this.setupOverlay(element);
  }

  // Required method
  getElement(elementDoc) {
    const browser = htmlToElement(`
      <div class="cobrowser-container">
        <div class="underlay"></div>
        <div class="iframe-bounding-box">
          <iframe class="cobrowser-iframe ${this.iframeClasses()}"></iframe>
          <div class="cobrowser-overlay"></div>
        </div>
      </div>
    `);

    return wrapElement(browser, elementDoc, {
      classes: ['cobrowser-element'],
      preserveAspectRatio: this.preserveAspectRatio(),
    });
  }

  preserveAspectRatio() {
    return false;
  }

  iframeClasses() {
    return '';
  }

  // Statics

  static async addElement(url, additionalProperties = {}) {
    const center =
      additionalProperties.center || screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2 - 200);
    delete additionalProperties.center;
    const boardRef = db.collection('boards').doc(window.currentBoardId);
    const boardData = (await boardRef.get()).data();
    await boardRef.collection('elements').add({
      class: this.elementType || 'CobrowserElement',
      center,
      creator: firebase.auth().currentUser.uid,
      size: [350, 500],
      url,
      zIndex: window.getFrontZIndex(),
      ...additionalProperties,
    });
    track(ADD_ELEMENT, {
      element: ELEMENT_TYPES.COBROWSER,
      cobrowserType: this.constructor.elementType,
      destination:
        boardData.type === USER_CARD_BOARD_TYPE
          ? ADD_ELEMENT_DESTINATION_TYPES.CARD
          : ADD_ELEMENT_DESTINATION_TYPES.ROOM,
    });
  }

  // Handler-specific

  onMouseUp(e) {
    const el = document.getElementById(`element-${this.elementId}`);

    // if the target of the click isn't the container nor a descendant of the container
    if (!el || el === e.target || el.contains(e.target)) {
      return;
    }

    document.removeEventListener('mouseup', this.onMouseUp);
    el.querySelector('.underlay').classList.remove('cobrowser-focused');
    const overlay = el.querySelector('.cobrowser-overlay');
    overlay.style.display = 'block';
  }
}

CobrowserElement.elementType = 'CobrowserElement';
