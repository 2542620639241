import { Theme } from '../../../definitions/theme';

export enum NewRoomFlowSteps {
  CREATE_NAME = 'CREATE_NAME',
  INVITE_FRIENDS = 'INVITE_FRIENDS',
}

export interface NewRoomData {
  id: string;
  name: string;
  creator: string;
  hasCustomUrl: boolean;
  joinModeOpen: boolean;
  customUrl: string;
  template: {
    id: string;
    name: string;
    background: string;
    isBlank: boolean;
  };
  groupId?: string;
}

export interface NewRoomState {
  step: NewRoomFlowSteps;
  isNewRoomCreating: boolean;
  isOnboarding: boolean;
  newRoom: NewRoomData;

  // onboarding only
  newRoomTheme: Theme | null;
  newRoomHasCustomBackground: boolean;
}

export const initialState: NewRoomState = {
  step: NewRoomFlowSteps.CREATE_NAME,
  isNewRoomCreating: false,
  isOnboarding: false,

  // onboarding only
  newRoomTheme: null,
  newRoomHasCustomBackground: false,

  newRoom: {
    id: '',
    name: '',
    creator: '',
    hasCustomUrl: false,
    joinModeOpen: false,
    customUrl: '',
    template: {
      id: '',
      name: '',
      background: '',
      isBlank: false,
    },
  },
};
