import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../../../store/Wrapper';
import RoomBordersSettings from './RoomBordersSettings';

export function renderRoomBordersSettings(container) {
  ReactDOM.render(
    <WithRoomStore>
      <RoomBordersSettings />
    </WithRoomStore>,
    container
  );
}

export function unmountRoomBordersSettings(container) {
  ReactDOM.unmountComponentAtNode(container);
}
