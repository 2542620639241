import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import { MessageReactionsContextProvider } from './messages/reactions/MessageReactionsContext';
import TypingIndicator from './typing-indicator/TypingIndicator';
import { ChatListScrollWrapper, BackToPresentButton } from './chats-shared-styles';
import Message from './messages/Message';

// hooks
import { useUserCanAddContent } from '../hooks/useUserCanAddContent';

// constants
import { messageTypes, typingIndicatorAppearingAnimations } from '../../constants/chat-constants';
import { JOIN_HUB_SYS_MSG } from '../../message-util';
import { selectActiveChatId, selectChatByChatId } from '../store/messaging/selectors';
import { resetChatUnreads } from '../store/messaging/reducer';

const ChatWindow = ({
  chatItemMenuContextValue,
  messagesList,
  loadMoreMessages,
  hasMore,
  onDeleteMessage,
  typingUsersList,
  noMessagesWidget,
  showNoMessagesWidget,
  chatId,
  inputEntry,
  showTypingIndicator,
  canAddToRoom,
}) => {
  const dispatch = useDispatch();
  const topMessage = messagesList[0];

  const activeChatId = useSelector(selectActiveChatId);

  const [isScrolledUp, setIsScrolledUp] = useState();
  const [showScrollToBottomButton, setShowScrollToBottomButton] = useState(false);
  const [showNewMessageButton, setShowNewMessageButton] = useState(false);

  const messagesListRef = useRef(null);
  const bottomOfTheListRef = useRef(null);

  const [userCanAddContent] = useUserCanAddContent();

  const showEmptyState = showNoMessagesWidget || !messagesList.length;

  const [isPageVisible, setPageVisible] = useState(!document.hidden);
  useEffect(() => {
    const handleVisibilityChange = () => {
      setPageVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    // reset chat unreads if chat is active and user is not scrolled up and page is visible
    if (activeChatId === chatId && !isScrolledUp && isPageVisible) {
      dispatch(resetChatUnreads({ chatId }));
    }
  }, [activeChatId, chatId, isScrolledUp, dispatch, isPageVisible]);

  function scrollToChatBottom() {
    messagesListRef.current.scrollTo({ top: messagesListRef.current.scrollHeight, behavior: 'smooth' });
    setShowScrollToBottomButton(false);
    setIsScrolledUp(false);
    setShowNewMessageButton(false);
  }

  const onChatScroll = () => {
    if (messagesListRef.current.scrollTop < -400) {
      setShowScrollToBottomButton(true);
    } else {
      setShowScrollToBottomButton(false);
    }

    if (messagesListRef.current.scrollTop < -20) {
      // if user is less than 20px from bottom, then we don't consider them as scrolled up
      setIsScrolledUp(true);
    } else {
      setIsScrolledUp(false);
      setShowNewMessageButton(false);
    }
  };

  const prevMessagesListLength = useRef();
  React.useEffect(() => {
    if (prevMessagesListLength.current === undefined) {
      prevMessagesListLength.current = messagesList.length;
      return;
    }

    const newMessage = messagesList.length - prevMessagesListLength.current === 1;
    if (newMessage) {
      if (isScrolledUp) {
        setShowNewMessageButton(true);
      } else {
        scrollToChatBottom();
      }
    }

    prevMessagesListLength.current = messagesList.length;
  }, [isScrolledUp, messagesList.length]);

  const observer = useRef();
  const topMessageRef = React.useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreMessages();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [hasMore, loadMoreMessages]
  );

  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const decorator = chat?.theme?.skin?.decorator;

  const renderChatWindowItem = useCallback(
    (message, idx, array) => {
      const nextCreator = idx > 0 ? array[idx - 1].creator : null;
      const nextMsgCreatedAt = idx > 0 ? array[idx - 1].createdAt : null;
      const previousCreator = idx < array.length - 1 ? array[idx + 1].creator : null;
      const previousMsgCreatedAt = idx < array.length - 1 ? array[idx + 1].createdAt : null;

      const displaySystemFriendButton = message?.type === messageTypes.SYSTEM && message?.text === JOIN_HUB_SYS_MSG;

      return (
        <MessageContentWrapper ref={idx === array.length - 1 ? topMessageRef : null} key={message.id}>
          <Message
            message={{ ...message, chatId, idx }}
            nextCreator={nextCreator}
            nextMsgCreatedAt={nextMsgCreatedAt}
            previousCreator={previousCreator}
            previousMsgCreatedAt={previousMsgCreatedAt}
            userCanAddContent={userCanAddContent && canAddToRoom}
            deleteMessage={onDeleteMessage}
            isLastMessage={idx === 0}
            displaySystemFriendButton={displaySystemFriendButton}
            isFeedMsg={false}
            decorator={decorator}
          />
        </MessageContentWrapper>
      );
    },
    [chatId, onDeleteMessage, topMessageRef, userCanAddContent, canAddToRoom, decorator]
  );

  const renderedMessages = React.useMemo(
    () => messagesList.slice().reverse().map(renderChatWindowItem),
    [messagesList, renderChatWindowItem]
  );

  return (
    <ChatWindowContainer id="chat-window" className="text-channel-container">
      <MessageReactionsContextProvider value={chatItemMenuContextValue}>
        <ChatListScrollWrapper
          ref={messagesListRef}
          className="feed-list-scroll-wrapper"
          onScroll={onChatScroll}
          isTyping={showTypingIndicator && typingUsersList.length}
        >
          {showTypingIndicator && typingUsersList.length ? (
            <TypingIndicator
              typingUsers={typingUsersList}
              appearingAnimationType={typingIndicatorAppearingAnimations.TRANSLATE}
            />
          ) : null}

          {topMessage && !showEmptyState ? (
            <>
              <div ref={bottomOfTheListRef} />
              {showScrollToBottomButton && !showNewMessageButton && (
                <BackToPresentButtonContainer>
                  <ChatWindowBackToPresentButton
                    aria-label="Scroll to bottom"
                    type="button"
                    onClick={scrollToChatBottom}
                  />
                </BackToPresentButtonContainer>
              )}
              {showNewMessageButton ? (
                <BackToPresentButtonContainer onClick={scrollToChatBottom}>
                  <WithNewBadgeContainer>
                    <ChatWindowBackToPresentButton aria-label="Scroll to bottom" type="button" />
                    <NewBadge>new</NewBadge>
                  </WithNewBadgeContainer>
                </BackToPresentButtonContainer>
              ) : null}
              {renderedMessages}
            </>
          ) : null}

          {showEmptyState ? noMessagesWidget : null}
        </ChatListScrollWrapper>
      </MessageReactionsContextProvider>

      {inputEntry}
    </ChatWindowContainer>
  );
};

export default ChatWindow;

ChatWindow.propTypes = {
  chatItemMenuContextValue: PropTypes.shape({}), // only needed for TextChannel :(
  onDeleteMessage: PropTypes.func.isRequired,
  messagesList: PropTypes.arrayOf(PropTypes.shape({})),
  loadMoreMessages: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  showTypingIndicator: PropTypes.bool,
  typingUsersList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  noMessagesWidget: PropTypes.node,
  showNoMessagesWidget: PropTypes.bool,
  chatId: PropTypes.string,
  inputEntry: PropTypes.node.isRequired,
  canAddToRoom: PropTypes.bool,
};

ChatWindow.defaultProps = {
  chatItemMenuContextValue: {},
  messagesList: [],
  typingUsersList: [],
  noMessagesWidget: <></>,
  showNoMessagesWidget: false,
  chatId: '',
  showTypingIndicator: true,
  canAddToRoom: true,
};

export const ChatWindowContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) =>
    theme?.chatWindowContainer?.background
      ? theme.chatWindowContainer.background
      : 'var(--primary-background, #12002e)'};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;

  .text-entry-click-in {
    width: calc(100% - 150px);
    vertical-align: bottom !important;
    opacity: 1;
    cursor: pointer;
    justify-content: left;
    margin-left: 8px;
    overflow: hidden;
  }

  .text-entry-click-in-closed {
    width: calc(100% - 170px);
    vertical-align: bottom !important;
    opacity: 1;
    cursor: pointer;
    justify-content: left;
    margin-left: 8px;
    overflow: hidden;
  }

  .text-entry-click-in-closed-text-channel {
    width: calc(100% - 128px);
    vertical-align: bottom !important;
    opacity: 1;
    cursor: pointer;
    justify-content: left;
    margin-left: 8px;
    overflow: hidden;
  }

  .text-entry {
    display: flex;
    min-height: 32px;
    height: auto;
    border-radius: 35px;
    position: relative;

    .text-entry-avatar {
      display: inline-block;
      height: 37px;
      width: 37px;
      clip-path: circle(50%);
      margin-top: auto;
    }
  }

  .font-option {
    padding: 6px 0px !important;
    font-size: 14px;
  }

  .text-entry-closed {
    background: #34274a;
    border-radius: 20px;
    padding: 6px;

    body.themed & {
      background: var(--primary-foreground-alpha-20);
    }
  }
`;

const MessageContentWrapper = styled.div`
  width: 100%;
`;

const BackToPresentButtonContainer = styled.div`
  position: absolute;
  right: 33px;
  height: 30px;

  cursor: pointer;
`;

const ChatWindowBackToPresentButton = styled(BackToPresentButton)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const NewBadge = styled.div`
  font-size: 12px;
  font-weight: bolder;
  background-color: #f6335d;
  border-radius: 20px;
  text-transform: uppercase;
  position: absolute;
  z-index: 11;
  color: white;
  top: 0px;
  left: 0px;
  padding: 3px 6px;
`;

const WithNewBadgeContainer = styled.div`
  position: fixed;
  bottom: 103px;
  right: 0px;
  z-index: 1;

  width: 60px;
  height: 40px;
`;
