import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import Item from './Item';
import * as store from '../store/template/store';
import SearchNoResults from '../template/SearchNoResults';
import RoomCreation from '../room/creation/RoomCreation.tsx';
import { selectIsNewRoomCreating } from '../store/new-room/selectors.ts';
import { hideableMixin, customScrollbarMixin } from '../mixins';
import { fetchRandomTemplate } from '../../api/admin-templates-api';

// utils
import { maybeShowIntegrationCTA } from '../../util/cta-util';
import { capitalizeSentence } from '../../util/string-util';
import { selectCurrentUserFirstName } from '../store/users/selectors';
import { track } from '../../util/analytics-util';

// icons
const basicsRoomIcon = '/images/icons/basics-room-icon.svg';
const blankRoomIcon = '/images/icons/blank-room-icon.svg';
const randomRoomIcon = '/images/icons/random-room-icon.svg';

export const ContainerView = ({
  type,
  isFirstTimeUser,
  templates,
  kits,
  categories,
  chosenCategory,
  chosenItem,
  isCreatingRoom,
  isBlankRoomSelected,
  isBasicsRoomSelected,
  isLoading,
  onCategorySelected,
  onItemSelected,
  onKitSelected,
  onBackgroundClicked,
  isBackgroundClicked,
  onBlankRoomSelected,
  onBasicsRoomSelected,
  fetchTemplatesPage,
  fetchCategories,
  hasMore,
  setNextPage,
  setIsLoading,
  isElectron,
  isWindowsOS,
  groupId,
}) => {
  const itemBoxRef = React.useRef(null);

  const category = chosenCategory !== undefined ? chosenCategory : 'all';
  const isItemChosen = chosenItem !== null || isBlankRoomSelected || isBasicsRoomSelected;

  const [mobileCategoriesPickerActive, setMobileCategoriesPickerActive] = React.useState(false);
  const [templatesFilter, setTemplatesFilter] = React.useState('');
  const [lastTemplateRequest, setLastTemplateRequest] = React.useState(null);

  const userFirstName = useSelector(selectCurrentUserFirstName);

  const mobilePickedCategory = React.useMemo(() => {
    switch (chosenCategory) {
      case 'templates': {
        return 'All Templates';
      }

      case 'kits': {
        return 'All Kits';
      }

      default: {
        return chosenCategory;
      }
    }
  }, [chosenCategory]);

  const observer = React.useRef(null);
  const lastTemplateItemRef = React.useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchTemplatesPage({ filter: templatesFilter, replace: false });
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchTemplatesPage, hasMore, templatesFilter]
  );

  function fetchTemplatesPageCb(filter) {
    setIsLoading(true);
    setNextPage(1);
    fetchTemplatesPage({ filter, replace: true });
  }

  const onFilterChanged = useDebouncedCallback(fetchTemplatesPageCb, 700);

  const items = React.useMemo(() => (type === 'templates' ? templates : kits), [type, templates, kits]);

  React.useEffect(() => {
    if (!categories.length) {
      fetchCategories();
      fetchTemplatesPage({ filter: '', replace: true });
    }
  }, [categories, fetchCategories, fetchTemplatesPage]);

  React.useEffect(() => {
    function resetMobileCategoriesPicker() {
      setMobileCategoriesPickerActive(false);
    }

    window.addEventListener('resize', resetMobileCategoriesPicker);

    return () => {
      window.removeEventListener('resize', resetMobileCategoriesPicker);
    };
  }, []);

  function handleBackgroundClicked() {
    onBackgroundClicked();
    maybeShowIntegrationCTA();
  }

  function handleOnItemClick(item) {
    if (type === store.itemTypes.templates) {
      onItemSelected({ ...item, ...(groupId ? { groupId } : {}) });
    } else if (type === store.itemTypes.kits) {
      onKitSelected(item);
    }
  }

  function onTemplatesFilterChange(event) {
    const {
      target: { value },
    } = event;

    setTemplatesFilter(value);

    onFilterChanged.cancel();
    onFilterChanged.callback(value);
  }

  function resetFilter() {
    setTemplatesFilter('');
    onFilterChanged.cancel();
    fetchTemplatesPageCb('');
  }

  function onCategoryClick(clickedCategory) {
    setIsLoading(true);
    itemBoxRef.current.scroll({ top: 0 });
    onCategorySelected(clickedCategory);
    setNextPage(1);
    fetchTemplatesPage({ filter: templatesFilter, replace: true });
  }

  function onMobileCategorySelected(cat) {
    onCategoryClick(cat);
    setMobileCategoriesPickerActive(false);
  }

  function onMobileCategoryReset(e) {
    e.stopPropagation();
    onCategoryClick(type);
  }

  async function createRandomRoom() {
    const randomRoom = await fetchRandomTemplate();
    track('Create Random Room', { boardId: randomRoom.board });
    onItemSelected({ ...randomRoom, ...(groupId ? { groupId } : {}) });
  }

  return (
    <Container
      id="template-picker-container"
      isItemChosen={isItemChosen}
      isHidden={isBackgroundClicked}
      isFirstTimeUser={isFirstTimeUser}
    >
      <LoadingOverlay isHiddenDisplay={!isCreatingRoom}>Preparing your room...</LoadingOverlay>
      {isItemChosen ? (
        ''
      ) : (
        <MasterContainer kits={type === store.itemTypes.kits} isElectron={isElectron} isWindowsOS={isWindowsOS}>
          <CategoryWindow isElectron={isElectron}>
            <CategoryWindowTitle>{type === store.itemTypes.kits ? 'Sticker Packs' : 'Templates'}</CategoryWindowTitle>

            <CategoryLink selected={category === type} key="all" onClick={() => onCategoryClick(`${type}`)}>
              All {type === store.itemTypes.kits ? 'Packs' : 'Templates'}
            </CategoryLink>

            <CategoryLink selected={category === 'new'} key="new" onClick={() => onCategoryClick('new')}>
              New
            </CategoryLink>

            <CategoryLink selected={category === 'popular'} key="popular" onClick={() => onCategoryClick('popular')}>
              Popular
            </CategoryLink>

            <CategoryLink
              selected={category === 'tutorials'}
              key="tutorials"
              onClick={() => onCategoryClick('tutorials')}
            >
              Tutorials
            </CategoryLink>

            <LineBreak />

            {categories.map((cat) => (
              <CategoryLink key={cat} selected={category === cat} onClick={() => onCategoryClick(cat)}>
                {cat}
              </CategoryLink>
            ))}
          </CategoryWindow>
          <ItemWindow>
            <ItemBox ref={itemBoxRef} mobileCategoriesPickerActive={mobileCategoriesPickerActive}>
              {type === store.itemTypes.templates ? (
                <WindowHeader>
                  <WindowTitle>
                    <span>
                      {isFirstTimeUser ? (
                        <>
                          <span>{`🎉 Welcome${userFirstName !== '' ? `, ${userFirstName}` : ''}. `}</span>
                          <FirstTimeUserWindowTitleDescription>
                            Let's create a room!
                          </FirstTimeUserWindowTitleDescription>
                        </>
                      ) : (
                        `Select a Template`
                      )}
                    </span>

                    {!isFirstTimeUser && !isElectron ? (
                      <CloseModalButton type="button" onClick={handleBackgroundClicked}>
                        ✕
                      </CloseModalButton>
                    ) : null}
                  </WindowTitle>
                  <WindowText>
                    Explore our templates below for inspiration or{' '}
                    <BlankRoomText onClick={() => onBlankRoomSelected(groupId)}>start with a blank room</BlankRoomText>.
                  </WindowText>
                </WindowHeader>
              ) : null}

              {!isElectron && type === store.itemTypes.kits ? (
                <WindowHeader>
                  <WindowTitle>
                    <span>Select a pack</span>

                    <CloseModalButton type="button" onClick={handleBackgroundClicked}>
                      ✕
                    </CloseModalButton>
                  </WindowTitle>
                </WindowHeader>
              ) : null}

              <TemplatesFilterBlock>
                <TemplatesFilterIcon src="/images/icons/search-black.svg" alt="search" />
                <TemplatesFilter
                  type="text"
                  placeholder="Search"
                  value={templatesFilter}
                  onChange={onTemplatesFilterChange}
                />

                {templatesFilter ? (
                  <TemplatesFilterClearButton onClick={resetFilter}>✕</TemplatesFilterClearButton>
                ) : null}
              </TemplatesFilterBlock>

              <ItemContainersWrapper>
                {isFirstTimeUser &&
                category === type &&
                type === store.itemTypes.templates &&
                !templatesFilter.replace(/ /g, '') ? (
                  <>
                    <LargeStarterItems>
                      <LargeItemContainer
                        key="The Basics"
                        isSelected={false}
                        index={-1}
                        isHiddenOpacity={isItemChosen}
                        onClick={() => onBasicsRoomSelected(groupId)}
                      >
                        <LargeStyledItem
                          isSelected={false}
                          gradient={['#FC6957', '#90008C']}
                          icon={basicsRoomIcon}
                          backgroundImage={null}
                          isRandom={false}
                          name=""
                        />
                        <ItemTitle>Start Here</ItemTitle>
                        <ItemDescription>Learn how Here works and the many things you can do!</ItemDescription>
                      </LargeItemContainer>
                      <LargeItemContainer
                        key="Empty Room"
                        isSelected={false}
                        isHiddenOpacity={isItemChosen}
                        onClick={() => onBlankRoomSelected(groupId)}
                      >
                        <LargeStyledItem
                          isSelected={false}
                          gradient={['#5B2DA0', '#1AC1A3']}
                          icon={blankRoomIcon}
                          backgroundImage={null}
                          isRandom={false}
                          name=""
                        />
                        <ItemTitle>Blank Room</ItemTitle>
                        <ItemDescription>Start from scratch and build your room from the ground up.</ItemDescription>
                      </LargeItemContainer>
                    </LargeStarterItems>

                    <WindowLineBreak />
                  </>
                ) : null}

                {!isFirstTimeUser &&
                category === type &&
                type === store.itemTypes.templates &&
                !templatesFilter.replace(/ /g, '') ? (
                  <>
                    <ItemContainer
                      key="Start Here"
                      isSelected={false}
                      index={-1}
                      isHiddenOpacity={isItemChosen}
                      onClick={() => onBasicsRoomSelected(groupId)}
                    >
                      <StyledItem
                        isSelected={false}
                        gradient={['#FC6957', '#90008C']}
                        backgroundImage={null}
                        icon={basicsRoomIcon}
                        isRandom={false}
                        name=""
                      />
                      <ItemTitle>Start Here</ItemTitle>
                      <ItemDescription>Learn how Here works and the many things you can do!</ItemDescription>
                    </ItemContainer>
                    <ItemContainer
                      key="random-room"
                      isSelected={false}
                      isHiddenOpacity={isItemChosen}
                      onClick={createRandomRoom}
                    >
                      <StyledItem
                        isSelected={false}
                        gradient={['#ffca5e', '#fc6f57']}
                        backgroundImage={null}
                        icon={randomRoomIcon}
                        isRandom={false}
                        name=""
                      />
                      <ItemTitle>Surprise me</ItemTitle>
                      <ItemDescription>Start with a random template and see where it takes you!</ItemDescription>
                    </ItemContainer>
                    <ItemContainer
                      key="Empty Room"
                      isSelected={false}
                      isHiddenOpacity={isItemChosen}
                      onClick={() => onBlankRoomSelected(groupId)}
                    >
                      <StyledItem
                        isSelected={false}
                        gradient={['#5B2DA0', '#1AC1A3']}
                        backgroundImage={null}
                        icon={blankRoomIcon}
                        isRandom={false}
                        name=""
                      />
                      <ItemTitle>Blank Room</ItemTitle>
                      <ItemDescription>Start from scratch and build your room from the ground up.</ItemDescription>
                    </ItemContainer>
                  </>
                ) : null}

                {!isLoading &&
                  items.map((item, index) => (
                    <ItemContainer
                      ref={items.length - 1 === index ? lastTemplateItemRef : null}
                      key={item.id}
                      isSelected={isItemChosen && chosenItem.id === item.id}
                      isHiddenOpacity={isItemChosen && chosenItem.id !== item.id}
                      onClick={() => handleOnItemClick(item)}
                    >
                      <StyledItem
                        isSelected={isItemChosen && chosenItem.id === item.id}
                        gradient={item.gradient}
                        backgroundImage={item.image}
                        isRandom={item.isRandom && (!isItemChosen || chosenItem.id !== item.id)}
                        name=""
                      />
                      <ItemTitle>{item.name}</ItemTitle>
                      <ItemDescription>{item.description ? item.description : ''}</ItemDescription>
                    </ItemContainer>
                  ))}

                {templatesFilter && !items.length && !isLoading ? (
                  <SearchNoResults
                    contentType={type}
                    templatesFilter={templatesFilter}
                    lastTemplateRequest={lastTemplateRequest}
                    setLastTemplateRequest={setLastTemplateRequest}
                    onBlankRoomSelected={() => onBlankRoomSelected(groupId)}
                    resetFilter={resetFilter}
                  />
                ) : null}
              </ItemContainersWrapper>

              <MobileTemplatesCategorySelectBlock mobileCategoriesPickerActive={mobileCategoriesPickerActive}>
                <MobileTemplatesCategorySelect
                  mobileCategoriesPickerActive={mobileCategoriesPickerActive}
                  isType={chosenCategory === type}
                  onClick={() => setMobileCategoriesPickerActive(!mobileCategoriesPickerActive)}
                >
                  <FilterIcon
                    src={
                      mobileCategoriesPickerActive
                        ? '/images/icons/filter-icon-white.svg'
                        : '/images/icons/filter-icon.svg'
                    }
                    alt="filter"
                  />

                  <MobileTemplatesCategoryItem
                    mobileCategoriesPickerActive={mobileCategoriesPickerActive}
                    isType={chosenCategory === type}
                    onClick={chosenCategory !== type ? (e) => onMobileCategoryReset(e) : () => {}}
                    selected
                  >
                    <span style={{ fontSize: '16px' }}>{mobilePickedCategory}</span>
                    <MobileTemplatesResetCategoryItem visible={chosenCategory !== type}>
                      ✕
                    </MobileTemplatesResetCategoryItem>
                  </MobileTemplatesCategoryItem>

                  <MobileTemplatesCategorySelectButton mobileCategoriesPickerActive={mobileCategoriesPickerActive} />
                </MobileTemplatesCategorySelect>
              </MobileTemplatesCategorySelectBlock>

              <MobileTemplatesCategoriesContainer mobileCategoriesPickerActive={mobileCategoriesPickerActive}>
                {categories.map((cat) => (
                  <MobileTemplatesCategoryItem
                    key={cat}
                    selected={cat === mobilePickedCategory}
                    onClick={() => onMobileCategorySelected(cat)}
                  >
                    {capitalizeSentence(cat)}
                  </MobileTemplatesCategoryItem>
                ))}
              </MobileTemplatesCategoriesContainer>
            </ItemBox>
          </ItemWindow>
        </MasterContainer>
      )}

      {isItemChosen && type === store.itemTypes.templates ? <RoomCreation /> : null}

      {isFirstTimeUser === true ? <ContainerDarkener /> : <ContainerDarkener onClick={handleBackgroundClicked} />}
    </Container>
  );
};

ContainerView.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      gradient: PropTypes.arrayOf(PropTypes.string),
      icon: PropTypes.string,
    })
  ).isRequired,
  kits: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      gradient: PropTypes.arrayOf(PropTypes.string),
      icon: PropTypes.string,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  chosenItem: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    board: PropTypes.string,
    image: PropTypes.string,
    priority: PropTypes.string,
    description: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    isTemplate: PropTypes.bool,
    isRoomKit: PropTypes.bool,
  }),
  isCreatingRoom: PropTypes.bool.isRequired,
  isBlankRoomSelected: PropTypes.bool.isRequired,
  isBasicsRoomSelected: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCategorySelected: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onKitSelected: PropTypes.func.isRequired,
  onBackgroundClicked: PropTypes.func.isRequired,
  onBlankRoomSelected: PropTypes.func.isRequired,
  onBasicsRoomSelected: PropTypes.func.isRequired,
  fetchTemplatesPage: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  setNextPage: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  isFirstTimeUser: PropTypes.bool,
  chosenCategory: PropTypes.string.isRequired,
  isBackgroundClicked: PropTypes.bool.isRequired,
  isElectron: PropTypes.bool,
  isWindowsOS: PropTypes.bool,
  groupId: PropTypes.string,
};

ContainerView.defaultProps = {
  chosenItem: null,
  isFirstTimeUser: false,
  isElectron: false,
  isWindowsOS: false,
  groupId: '',
};

export default connect(
  (state) => ({
    templates: store.getTemplates(state),
    kits: store.getKits(state),
    categories: store.getCategories(state),
    type: store.getType(state),
    chosenItem: store.getChosenItem(state),
    chosenCategory: store.getChosenCategory(state),
    isCreatingRoom: selectIsNewRoomCreating(state),
    isBlankRoomSelected: store.getIsBlankRoomSelected(state),
    isBasicsRoomSelected: store.getIsBasicsRoomSelected(state),
    isLoading: store.getIsLoading(state),
    isBackgroundClicked: store.getIsBackgroundClicked(state),
    hasMore: store.getHasMore(state),
  }),
  {
    onItemSelected: (item) => store.itemChosen({ item }),
    onKitSelected: (kit) => store.kitChosen({ kit }),
    onBackgroundClicked: store.backgroundClicked,
    onCategorySelected: (category) => store.categoryChosen({ category }),
    onBlankRoomSelected: (groupId) => store.blankRoomSelected({ groupId }),
    onBasicsRoomSelected: (groupId) => store.basicsRoomSelected({ groupId }),
    fetchTemplatesPage: store.fetchTemplatesPage,
    fetchCategories: store.fetchCategories,
    setNextPage: (page) => store.setNextPage({ page }),
    setIsLoading: (isLoading) => store.setIsLoading({ isLoading }),
  }
)(ContainerView);

const ContainerDarkener = styled.div`
  background: black;
  opacity: 50%;
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
`;

const ItemWindow = styled.div`
  position: relative;
  height: 100%;
  width: 80%;
  flex-grow: 1;
  line-height: 24px !important;

  @media (max-width: 867px) {
    font-size: 13px;
  }
`;

const WindowHeader = styled.div`
  padding: 40px 0 10px;
  margin: auto;
  width: 92.8%;
  box-sizing: border-box;
  text-align: left;
`;

const WindowTitle = styled.h3`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  color: black;
  font-size: 24px;
  font-weight: bold;
`;

const FirstTimeUserWindowTitleDescription = styled.span`
  @media (max-width: 640px) {
    display: none;
  }
`;

const CloseModalButton = styled.button`
  font-size: 22px;
  color: #000000;
  background-color: inherit;
  border: none;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const WindowText = styled.p`
  color: #868686;
  font-size: 16px;
  margin-top: 10px;

  @media (max-width: 867px) {
    display: none;
  }
`;

const CategoryLink = styled.div`
  margin-top: 8px;
  padding: 4px 12px !important;
  width: auto;
  display: table;
  color: ${({ selected }) => (selected ? 'white' : '#535151')};
  font-weight: ${({ selected }) => (selected ? 600 : 500)};
  font-size: 16px;
  text-transform: capitalize;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#6b1be3' : 'inherit')};
  border-radius: 20px;

  &:hover {
    background-color: ${({ selected }) => (selected ? '#6b1be3' : '#dfdfdf')};
  }

  @media (max-width: 1475px) and (min-width: 1372px) {
    font-size: 15px;
  }

  @media (max-width: 1371px) and (min-width: 868px) {
    font-size: 14px;
  }

  @media (max-width: 867px) {
    font-size: 13px;
  }
`;

const TemplatesFilterBlock = styled.div`
  position: relative;
  margin: auto;
  width: 92.8%;
`;

const TemplatesFilterIcon = styled.img`
  position: absolute;
  top: calc(50% - 16px / 2);
  left: 17px;
`;

const TemplatesFilter = styled.input`
  margin: auto;
  padding-left: 46px;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  border: 1px solid #0000004c;
  border-radius: 5px;

  &:focus {
    outline: none;
    border: 1px solid #6b1be3;
  }

  &::after {
    content: ' ';
    height: 17px;
    width: 17px;
    display: block;
    background: url(..);
  }
`;

const TemplatesFilterClearButton = styled.button`
  position: absolute;
  top: calc(50% - 23px / 2);
  right: 17px;
  font-size: 1rem;
  background-color: inherit;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const MobileTemplatesCategoryItem = styled.button`
  padding: 7.5px 15px;
  height: fit-content;
  display: flex;
  align-items: center;
  color: ${({ mobileCategoriesPickerActive, selected, isType }) => {
    if (mobileCategoriesPickerActive) {
      return '#efefef';
    }

    if (isType) {
      return '#612cda';
    }

    if (selected) {
      return '#e1e1e1';
    }

    return '#000000';
  }};

  background-color: ${({ selected, isType }) => {
    if (isType) {
      return 'inherit';
    }

    if (selected) {
      return '#612cda';
    }

    return 'inherit';
  }};

  border: none;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: ${({ selected, isType }) => {
      if (isType) {
        return 'inherit';
      }

      if (selected) {
        return '#5626c7';
      }

      return '#d1d1d1';
    }};
  }
`;

const MobileTemplatesCategorySelectBlock = styled.div`
  position: absolute;
  top: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? '40px' : '150px')};
  right: 0;
  left: 0;
  margin: auto;
  width: 92.8%;
  display: none;
  justify-content: center;
  align-items: center;
  transition: top ease-in-out 0.3s;

  @media (max-width: 867px) {
    display: flex;
  }
`;

const MobileTemplatesCategorySelect = styled.p`
  position: relative;
  padding: 7px 53px;
  height: 47px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  color: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? '#f3f3f3' : '#742ae4')};
  background-color: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? '#742ae4' : '#f3f3f3')};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? '#6925ce' : '#efefef')};
  }

  ${MobileTemplatesCategoryItem} {
    background-color: ${({ mobileCategoriesPickerActive, isType }) =>
      mobileCategoriesPickerActive || isType ? 'inherit' : '#612cda'};
  }
`;

const FilterIcon = styled.img`
  position: absolute;
  left: 24px;
  width: 15px;
`;

const MobileTemplatesCategorySelectButton = styled.button`
  position: absolute;
  right: 30px;
  padding: 0;
  height: 15px;
  width: 15px;
  box-sizing: border-box;
  background-color: inherit;
  background-image: ${({ mobileCategoriesPickerActive }) =>
    mobileCategoriesPickerActive ? 'url(images/icons/up-white.svg)' : 'url(images/icons/down-black.svg)'};
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const MobileTemplatesCategoriesContainer = styled.div`
  position: absolute;
  top: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? '97px' : '1000px')};
  right: 0;
  bottom: 100px;
  left: 0;
  margin: auto;
  padding: 10px;
  width: 92.8%;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  opacity: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? 1 : 0)};
  flex-wrap: wrap;
  transition: opacity ease-in-out 0.3s, background-color ease-in-out 0.3s;
  background-color: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? '#e1e1e1' : '#ffffff')};
  border-radius: 5px;

  ${MobileTemplatesCategoryItem} {
    padding: 10px 15px;
  }
`;

const MobileTemplatesResetCategoryItem = styled.span`
  padding: 0;
  margin-left: 10px;
  height: 16px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  color: #ffffff;
`;

const WindowLineBreak = styled.hr`
  border: 0.5px solid rgba(0, 0, 0, 0.05) !important;
  width: 93.5%;
  margin: auto;
  margin-left: 3%;
  margin-bottom: 30px;
  float: left;
`;

const LineBreak = styled.hr`
  border: 0.5px solid rgba(0, 0, 0, 0.1) !important;
`;

const FullscreenElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10006 !important; /* this z-index should be higher that z-index of the .room-menu-container (toolbar) */
`;

const LargeStarterItems = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 620px) {
    flex-direction: column;
  }
`;

const CategoryWindow = styled.div`
  height: 100%;
  width: 20%;
  padding: ${({ isElectron }) => (isElectron ? '13px 30px 30px 30px' : '34px 30px 30px 30px')};
  flex-grow: 1;
  box-sizing: border-box;
  background: #e9e9e9;
  line-height: 24px;
  overflow-y: auto;

  ${customScrollbarMixin()}

  @media (max-width: 1475px) and (min-width: 1372px) {
    padding: 34px 20px 30px 20px;
  }

  @media (max-width: 1371px) and (min-width: 868px) {
    padding: 34px 10px 30px 10px;
    min-width: 200px;
  }

  @media (max-width: 867px) {
    display: none;
  }
`;

const Container = styled(FullscreenElement)`
  ${({ isFirstTimeUser }) =>
    isFirstTimeUser === false ? '' : 'background: linear-gradient(#4c0e4e 0%, #2d1062 100%);'};
  display: ${({ isHidden }) => (isHidden === true ? 'none' : 'flex')};
  align-items: center;
  justify-content: ${({ isItemChosen }) => (isItemChosen ? 'center' : 'space-around')};
  flex-direction: column;
  z-index: 1;
`;

export const MasterContainer = styled.div`
  bottom: 10vh;
  min-width: 375px;
  height: ${({ kits }) => (kits ? '82%' : '95%')};
  width: 86%;
  margin: auto;
  display: flex;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  animation: fadeIn ease 0.75s;

  ${({ isElectron }) =>
    isElectron
      ? `
        width: 100%;
        height: 100%;
        border-radius: 0;`
      : ''}

  ${({ isWindowsOS }) => (isWindowsOS ? 'padding-top: 20px;' : '')}

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media (max-width: 867px) {
    width: 96%;
  }
`;

const LoadingOverlay = styled(FullscreenElement)`
  ${hideableMixin()}

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  font-size: 5vh;
  color: white;
  z-index: 10;
`;

const CategoryWindowTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 8px;
  padding-right: 8px;
`;

const ItemTitle = styled.div`
  margin-top: 10px;
  font-size: 18px;
  color: black;
  font-weight: 400;
`;

const ItemDescription = styled.div`
  font-size: 16px;
  color: gray;
  line-height: 24px;
`;

const ItemContainersWrapper = styled.div`
  margin: auto;
  margin-top: 10px;
  width: 92.8%;

  @media (max-width: 867px) {
    margin-top: 70px;
  }

  @media (max-width: 620px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ItemBox = styled.div`
  ${hideableMixin()}

  position: relative;
  height: 100%;
  justify-content: center;
  overflow-y: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? 'hidden' : 'auto')};
  overflow-x: hidden;
  transition: opacity ease-in-out 0.3s;

  ${customScrollbarMixin()}

  ${WindowHeader} {
    opacity: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? 0 : 1)};
  }

  ${TemplatesFilterBlock} {
    opacity: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? 0 : 1)};
  }

  ${ItemContainersWrapper} {
    opacity: ${({ mobileCategoriesPickerActive }) => (mobileCategoriesPickerActive ? 0 : 1)};
  }
`;

const LargeItemContainer = styled.div`
  background-size: cover;
  background-position: center;
  padding: 22px;
  position: relative;
  width: 46%;
  padding-bottom: 30px;
  margin: 10px 2%;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  color: #000000;
  background-origin: border-box;
  cursor: pointer;

  ${hideableMixin()}

  position: relative;

  &:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 620px) {
    width: 100%;
  }
`;

const ItemContainer = styled.div`
  background-size: cover;
  background-position: center;
  padding: 1.4% 1.4% 17px;
  width: 32.43%;
  margin: 0.45% 0.45% 10px;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  background-origin: border-box;
  cursor: pointer;
  vertical-align: top;

  ${hideableMixin()}

  position: relative;
  display: inline-block;
  height: auto;

  &:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 993px) and (min-width: 621px) {
    padding: 1.8% 1.8% 17px;
    width: 49.1%;
  }

  @media (max-width: 620px) {
    width: 100%;
    max-width: unset;
  }
`;

const StyledItem = styled(Item)`
  height: unset;
  width: 100%;
  aspect-ratio: 1.75 /1;
  background: url(${(props) =>
    props.backgroundImage
      ? props.backgroundImage
      : 'linear-gradient(204deg, rgba(82, 27, 67, 1) 0%, rgba(21, 33, 107, 1) 100%)'});
  background-size: cover;
  background-position: center;
`;

const LargeStyledItem = styled(Item)`
  height: 250px;
  background: url(${(props) =>
    props.backgroundImage
      ? props.backgroundImage
      : 'linear-gradient(204deg, rgba(82, 27, 67, 1) 0%, rgba(21, 33, 107, 1) 100%)'});
  background-size: cover;
  background-position: center;
`;

const BlankRoomText = styled.span`
  text-decoration: underline;
  color: #612cda;
  padding-left: 1px;
  cursor: pointer;
`;
