import { useEffect } from 'react';

export function useKeyPress(key, handler) {
  useEffect(() => {
    function onKeyUp(e) {
      if (e.key === key) {
        handler();
      }
    }

    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [key, handler]);
}
