import React from 'react';
import styled from 'styled-components';

import AddFriendButton from './AddFriendButton';

interface Props {
  userId: string;
  backgroundPhotoUrl: string;
  onClick: () => void;
}

const AddFriendButtonWithBackground: React.FC<Props> = ({ userId, backgroundPhotoUrl = '', onClick = () => {} }) => (
  <div>
    <BackgroundPhotoContainer backgroundUrl={backgroundPhotoUrl} />
    <ButtonContainer>
      <AddFriendButton userId={userId} onClick={onClick} />
    </ButtonContainer>
  </div>
);


export default AddFriendButtonWithBackground;

type BackgroundPhotoContainerProps = {
  backgroundUrl?: string;
};

const BackgroundPhotoContainer = styled.div<BackgroundPhotoContainerProps>`
  background: ${({ backgroundUrl }) =>
    backgroundUrl ? `url(${backgroundUrl})` : 'url(images/profile-backgrounds/green.svg)'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 154px;
  height: ${({ theme }) => theme?.userItemHeight || 69}px;

  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  position: absolute;
  top: 0;
  right: 0;
`;

const ButtonContainer = styled.div`
  margin-right: 12px;
`;
