import React from 'react';
import PropTypes from 'prop-types';

import { useUserProfileData } from '../hooks/useUserProfileData';

const UserName = ({ userId }) => {
  const { userProfile } = useUserProfileData(userId);
  return <>{userProfile?.displayName}</>;
};

export default UserName;

UserName.propTypes = {
  userId: PropTypes.string.isRequired,
};
