import { RootState } from '../../../definitions/store';

export const selectRoomCreationStep = (state: RootState) => state.newRoom.step;
export const selectIsNewRoomCreating = (state: RootState) => state.newRoom.isNewRoomCreating;
export const selectIsOnboarding = (state: RootState) => state.newRoom.isOnboarding;

// new room data
export const selectNewRoom = (state: RootState) => state.newRoom.newRoom;
export const selectNewRoomId = (state: RootState) => state.newRoom.newRoom.id;
export const selectNewRoomName = (state: RootState) => state.newRoom.newRoom.name;
export const selectNewRoomCustomUrl = (state: RootState) => state.newRoom.newRoom.customUrl;
export const selectNewRoomHasCustomUrl = (state: RootState) => state.newRoom.newRoom.hasCustomUrl;
export const selectNewRoomJoinModeOpen = (state: RootState) => state.newRoom.newRoom.joinModeOpen;
export const selectNewRoomCreator = (state: RootState) => state.newRoom.newRoom.creator;
export const selectNewRoomTemplate = (state: RootState) => state.newRoom.newRoom.template;
export const selectNewRoomTemplateBackground = (state: RootState) => state.newRoom.newRoom.template.background;

export const selectNewRoomTheme = (state: RootState) => state.newRoom.newRoomTheme;
export const selectNewRoomHasCustomBackground = (state: RootState) => state.newRoom.newRoomHasCustomBackground;
