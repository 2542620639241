import styled from 'styled-components';

const EMOJI_SIZE = 22;

export const EmojiContainer = styled.div`
  display: inline-block;
  padding: 6px;
  font-size: 22px;
  width: ${EMOJI_SIZE}px;
  height: ${EMOJI_SIZE}px;
  box-sizing: content-box;
  border-radius: 100%;
  line-height: 1.15;
  cursor: pointer;

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 500;
  padding: 5px 6px 0 6px;
  width: 100%;
  font-size: 16px;
`;
