import React from 'react';
import PropTypes from 'prop-types';

const MoreIcon = ({ width, height, opacity, color }) => (
  <svg width={width} height={height} viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <circle cx="2" cy="2" r="2" fill={color} />
      <circle cx="10" cy="2" r="2" fill={color} />
      <circle cx="18" cy="2" r="2" fill={color} />
    </g>
  </svg>
);

export default MoreIcon;

MoreIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  opacity: PropTypes.string,
  color: PropTypes.string,
};

MoreIcon.defaultProps = {
  width: '20',
  height: '4',
  opacity: '1.0',
  color: 'white',
};
