import UIkit from 'uikit';

import firebase, { db } from './firebase';
import bus, { boardChanged, capacityInfoUpdated } from './event-bus';
import { onUserListChanged, offUserListChanged } from './presence';
import { applyBackgroundStyle, sanitize } from './util';

import '../styles/room-capacity-dialog.less';
import { isRoomPublic } from './util/public-rooms-util';

export const STATIONS_MAX_CAPACITY = 75;
export const UNLISTED_ROOM_MAX_CAPACITY = 50;
export const PUBLIC_ROOM_MAX_CAPACITY = 20;
let currentMaxCapacity = UNLISTED_ROOM_MAX_CAPACITY;

let currentBoardId;

bus.on(boardChanged, (boardId) => {
  if (currentBoardId === boardId) return;

  if (currentBoardId) {
    offUserListChanged(currentBoardId, onOnlineListChangedCallback);
  }

  currentBoardId = boardId;
  onUserListChanged(currentBoardId, onOnlineListChangedCallback);
});

function onOnlineListChangedCallback(onlineUsers) {
  const capacityInfo = buildCapacityInfo(onlineUsers);
  bus.dispatch(capacityInfoUpdated, capacityInfo);
}

function buildCapacityInfo(onlineUsers) {
  onlineUsers = onlineUsers || {};

  const viewersCount = Object.values(onlineUsers).filter((u) => u.role === 'viewer').length;
  const membersCount = Object.values(onlineUsers).filter((u) => u.role !== 'viewer').length;
  const totalCount = viewersCount + membersCount;

  return {
    viewersCount,
    membersCount,
    totalCount,
    isCapacityReached: totalCount >= currentMaxCapacity,
  };
}

/**
 *
 * @param {string} boardId
 * @param {object} boardData optional preloaded board data
 * @returns { viewersCount, membersCount, totalCount, isCapacityReached }
 */
export async function getCapacityInfoForBoardId(boardId, boardData = null) {
  const onlineUsersSnapshot = await firebase.database().ref(`/status/${boardId}`).once('value');

  let data = null;
  if (boardData) {
    data = boardData;
  } else {
    const snapshot = await db.collection('boards').doc(boardId).get();

    if (snapshot.exists) {
      data = snapshot.data();
    }
  }

  if (data) {
    if (data.isViewersModeOn) {
      currentMaxCapacity = STATIONS_MAX_CAPACITY;
    } else if (isRoomPublic(data)) {
      currentMaxCapacity = PUBLIC_ROOM_MAX_CAPACITY;
    } else {
      currentMaxCapacity = UNLISTED_ROOM_MAX_CAPACITY;
    }
  }
  return buildCapacityInfo(onlineUsersSnapshot.val());
}

export async function showCapacityDialog(boardId) {
  UIkit.modal('#room-capacity-dialog').show();
  document.querySelector('#room-capacity-dialog .capacity').innerHTML = `${currentMaxCapacity}/${currentMaxCapacity}`;

  const roomCapacityDialogImage = document.getElementById('room-capacity-dialog-image');
  roomCapacityDialogImage.src = roomCapacityDialogImage.dataset.src;

  const roomInfoSnapshot = await db.doc(`boards/${boardId}`).get();
  const roomData = roomInfoSnapshot.data();
  applyBackgroundStyle(document.querySelector('.board-background'), roomData);

  document.querySelector('#room-capacity-dialog .room-name').innerHTML = sanitize(roomData.title);
}
