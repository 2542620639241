import React, { useState } from 'react';
import styled from 'styled-components';

// components
import { UploadAreaContainer, UploadAreaIconContainer } from '../shared-styles';
import { svgColorMixin } from '../../../../mixins';
import UploadSoundLabel from './UploadSoundLabel';

// utils
import { validateCustomSoundFile } from '../../../../../util/custom-sounds-util';

// constants
import {
  CUSTOM_SOUND_MAX_SIZE_MB,
  customSoundAllowedFileTypes,
} from '../../../../../constants/custom-sounds-constants';

// icons
import { SpeakerIcon } from '../../../../icons';

interface UploadAreaEmptyProps {
  onFileUploadedLocally: (file: File) => void;
  onError: (message: string) => void;
}

const UploadAreaEmpty = ({ onFileUploadedLocally, onError }: UploadAreaEmptyProps) => {
  const [isDragActive, setIsDragActive] = useState(false);

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === e.currentTarget) {
      setIsDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);

    const [fileToUpload] = Array.from(e.dataTransfer.files || []);
    const validFile = validateCustomSoundFile(fileToUpload, onError);
    if (validFile) {
      onFileUploadedLocally(fileToUpload);
    }
  };

  return (
    <EmptyUploadAreaContainer
      isDragActive={isDragActive}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={handleDrop}
    >
      <IconContainer>
        <SpeakerIcon />
      </IconContainer>
      <TextContainer>
        <SoundAreaTitle>
          Drop your file or <UploadSoundLabel text="browse" onUpload={onFileUploadedLocally} onError={onError} />
        </SoundAreaTitle>
        <SoundAreaSubtitle>
          {customSoundAllowedFileTypes.map((type) => type.toUpperCase()).join(', ')}, etc • {CUSTOM_SOUND_MAX_SIZE_MB}MB
          max
        </SoundAreaSubtitle>
      </TextContainer>
    </EmptyUploadAreaContainer>
  );
};

const EmptyUploadAreaContainer = styled(UploadAreaContainer)<{ isDragActive: boolean }>`
  border-color: ${({ isDragActive }) => (isDragActive ? '#6b1be3' : '#12002d33')};
`;

const IconContainer = styled(UploadAreaIconContainer)`
  background-color: #12002d19;

  ${svgColorMixin('#12002d4c')}
`;

const TextContainer = styled.div``;

const SoundAreaTitle = styled.h5`
  font-size: 14px;
  font-weight: bolder;
`;

const SoundAreaSubtitle = styled.h6`
  font-size: 12px;
`;

export default UploadAreaEmpty;
