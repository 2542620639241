import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';

import { applyTheme } from '../../util/theming-util';
import UserAvatarWithStatus, { StatusContainer } from '../components/UserAvatarWithStatus';
import DMIcon from '../../../assets/icons/dms/send-dm.svg';
import { ellipsisTextMixin, svgColorMixin } from '../mixins';
import { setActiveChatId, setActiveReceiverId, setMessagesSidebarOpen } from '../store/messaging/reducer';
import { setActiveTab } from '../store/web-lobby/actions';
import { WebLobbyTabs } from '../../definitions/web-lobby';
import { openUserProfile } from './utils';
import { isCurrentUser, trackUserPageVisit } from '../../util/user-util';
import UserCardIconWithBadge from '../user-card/UserCardIconWithBadge';
import UserMood from './mood';
import { MoodContent } from './mood/shared-styles';
import { selectDMChatByReceiverId } from '../store/messaging/selectors';
import { selectDefaultTheme, selectIsInOs } from '../store/room/selectors';
import { openChat as openOsChat } from '../store/os/actions';
import { UserProfile } from '../../definitions/user-profile';
import { selectIsFriendsByIdCurried } from '../store/friends/selectors';

type MiniProfileProps = {
  status?: string;
  source?: string;
  withDMButton?: boolean;
  withUserCardsIcon?: boolean;
  profile: UserProfile;
};

const userAvatarTheme = { statusBorderWidth: 4, statusSize: 20, offlineStatusMiddleCircleSize: 6 };

const MiniProfile = ({ profile, status, withDMButton = true, withUserCardsIcon = true, source }: MiniProfileProps) => {
  const dispatch = useDispatch();

  const isSelf = isCurrentUser(profile.userId);
  const isFriends = useSelector((state) => selectIsFriendsByIdCurried(profile.userId)(state));

  const chatsWithYouAndReceiver = useSelector((state) => selectDMChatByReceiverId(state, profile.userId));
  const activeChatId = chatsWithYouAndReceiver.length >= 1 ? chatsWithYouAndReceiver[0].id : null;
  const isHereOS = useSelector(selectIsInOs);

  const onDMClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (isHereOS) {
        dispatch(openOsChat({ chatId: activeChatId }));
      } else {
        dispatch(setMessagesSidebarOpen({ messagesSidebarOpen: true }));
        dispatch(setActiveReceiverId({ activeReceiverId: profile.userId }));
        dispatch(setActiveChatId({ activeChatId }));
        dispatch(setActiveTab({ activeTab: WebLobbyTabs.DMs }));
      }
    },
    [dispatch, profile.userId, activeChatId, isHereOS]
  );

  const containerRef = useRef(null);

  const defaultTheme = useSelector(selectDefaultTheme);
  useEffect(() => {
    const themeToApply = profile.profileTheme || defaultTheme;
    applyTheme(themeToApply?.colors, themeToApply?.primaryFont, containerRef.current);
  }, [defaultTheme, profile]);

  const openFullProfile = (openToUserCards: boolean) => {
    const itemRefCoordinates = containerRef.current.getBoundingClientRect();
    const topPosition = itemRefCoordinates.y - 10;
    const leftPosition: number = itemRefCoordinates.x + containerRef.current.offsetWidth / 2;
    openUserProfile({
      userId: profile.userId,
      topPosition,
      leftPosition,
      openToUserCards,
      source,
      preventOnClickOutside: true,
    });
  };

  const onMiniProfileClick = () => openFullProfile(false);

  const onUserCardsIconClick = () => {
    openFullProfile(true);
    trackUserPageVisit(profile.userId, 'Mini Profile');
  };

  return (
    <RelativeContainer>
      <Container ref={containerRef} onClick={onMiniProfileClick} backgroundImg={profile.backgroundPhoto?.original}>
        <Background
          background={
            profile.backgroundPhoto?.original ? 'var(--primary-background-alpha-40)' : 'var(--primary-background)'
          }
        />
        {withDMButton && !isSelf && isFriends && (
          <DMButton onClick={onDMClick}>
            <DMIcon />
          </DMButton>
        )}
        <NamesContainer>
          <Username>@{profile.username}</Username>
          <Name>{profile.displayName}</Name>
        </NamesContainer>
        <AvatarContainer>
          <ThemeProvider theme={userAvatarTheme}>
            <UserAvatarWithStatus userProfile={profile} status={status} size={null} borderWidth={4} />
          </ThemeProvider>

          {profile.mood ? (
            <MoodContainer>
              <UserMood mood={profile.mood} canEdit={isSelf} hasMarquee />
            </MoodContainer>
          ) : null}
        </AvatarContainer>
      </Container>
      {withUserCardsIcon && profile.cardIds?.length ? (
        <UserCardButton>
          <UserCardIconWithBadge userId={profile.userId} onClick={onUserCardsIconClick} />
        </UserCardButton>
      ) : null}
    </RelativeContainer>
  );
};

export default MiniProfile;

const RelativeContainer = styled.div`
  position: relative;
  max-width: 260px;
  width: 100%;
`;

const DMButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
  background: white;
  width: 38px;
  height: 38px;
  display: none;

  z-index: 2;

  svg {
    ${svgColorMixin('#6b1be3')};
    position: relative;
    top: 3px;
    left: 9px;
  }
`;

export const Container = styled.div<{ backgroundImg: string }>`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary-background, #12002d);
  position: relative;
  cursor: pointer;

  ${({ backgroundImg }) =>
    backgroundImg &&
    `
    background: url('${backgroundImg}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}

  &:hover {
    outline: 2px solid white;

    ${DMButton} {
      display: block;
    }
  }
`;

const Background = styled.div<{ background: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 11px;
  background: ${({ background }) => background};
`;

const AvatarContainer = styled.div`
  z-index: 1;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  aspect-ratio: 1;

  ${StatusContainer} {
    bottom: unset;
    top: 0px;
  }

  ${MoodContent} {
    padding: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const MoodContainer = styled.div`
  position: absolute;
  max-width: 100%;
  margin-bottom: -25px;
`;

const NamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 38%;
  color: var(--primary-foreground, white);
  text-align: center;
  width: 100%;
  z-index: 1;
`;

const Name = styled.div`
  font-family: var(--primary-font, Inter, sans-serif);
  font-weight: bold;
  padding: 0 10px;
  ${ellipsisTextMixin()}
`;

const Username = styled.div`
  color: var(--primary-foreground, white);
  opacity: 0.5;
  font-size: 12px;
  padding: 0 7px;
  ${ellipsisTextMixin()}
`;

const UserCardButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 14px;
  right: 14px;

  &:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
  }
`;
