import { createReducer } from '@reduxjs/toolkit';

import {
  setScreenId,
  setLastInteractedScreenId,
  setEmail,
  setPhoneNumber,
  setInputEmailScreenError,
  setSignInWithEmailAndPasswordError,
  setDisplayName,
  setGeneratedUsername,
  setUsernameWithValidation,
  setUsernameValidationResult,
  setProfileId,
  setFriendSuggestions,
  setCreateAccountEmailError,
  setCreateAccountPasswordError,
  resetErrors,
  reset,
  setIsGeneratingUsername,
  setVerificationId,
  setResetPasswordError,
  setFlowId,
} from './actions';
import { initialState } from './init-state';
import { Screens } from '../../sign-in-flow/constants';

export const signingInReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setFlowId, (state, action) => {
      state.flowId = action.payload.flowId;
    })
    .addCase(setScreenId, (state, action) => {
      state.screenId = action.payload.screenId;
    })
    .addCase(setLastInteractedScreenId, (state, action) => {
      state.lastInteractedScreenId = action.payload.screenId;
    })
    .addCase(setEmail, (state, action) => {
      state.profile.email = action.payload.email;
    })
    .addCase(setPhoneNumber, (state, action) => {
      state.screens[Screens.PHONE_INPUT].domesticPhoneNumber = action.payload.domesticPhoneNumber;
      state.screens[Screens.PHONE_INPUT].countryCode = action.payload.countryCode;
    })
    .addCase(setVerificationId, (state, action) => {
      state.screens[Screens.PHONE_VERIFY_CODE].verificationId = action.payload.verificationId;
    })
    .addCase(setDisplayName, (state, action) => {
      state.profile.displayName = action.payload.displayName;
    })
    .addCase(setProfileId, (state, action) => {
      state.profile.id = action.payload.id;
    })
    .addCase(setGeneratedUsername, (state, action) => {
      state.profile.username = action.payload.username;
      state.screens[Screens.CREATE_USERNAME].isValid = true;
      state.screens[Screens.CREATE_USERNAME].isGenerating = false;
    })
    .addCase(setUsernameWithValidation, (state, action) => {
      state.profile.username = action.payload.username;
      state.screens[Screens.CREATE_USERNAME].isChecking = true;
    })
    .addCase(setInputEmailScreenError, (state, action) => {
      state.screens[Screens.EMAIL_INPUT].error = action.payload.error;
    })
    .addCase(setSignInWithEmailAndPasswordError, (state, action) => {
      state.screens[Screens.EMAIL_SIGN_IN].error = action.payload.error;
    })
    .addCase(setCreateAccountEmailError, (state, action) => {
      state.screens[Screens.CREATE_ACCOUNT].emailError = action.payload.error;
    })
    .addCase(setCreateAccountPasswordError, (state, action) => {
      state.screens[Screens.CREATE_ACCOUNT].passwordError = action.payload.error;
    })
    .addCase(setIsGeneratingUsername, (state, action) => {
      state.screens[Screens.CREATE_USERNAME].isGenerating = action.payload.isGenerating;
    })
    .addCase(setUsernameValidationResult, (state, action) => {
      state.screens[Screens.CREATE_USERNAME] = {
        message: action.payload.message,
        isValid: action.payload.isValid,
        isChecking: false,
        isGenerating: false,
      };
    })
    .addCase(setFriendSuggestions, (state, action) => {
      state.screens[Screens.FRIENDS_SUGGESTIONS].suggestions = action.payload.suggestions;
      state.screens[Screens.FRIENDS_SUGGESTIONS].nextPage = action.payload.nextPage;
    })
    .addCase(setResetPasswordError, (state, action) => {
      state.screens[Screens.RESET_PASSWORD].error = action.payload.error;
    })
    .addCase(resetErrors, (state) => {
      state.screens[Screens.EMAIL_INPUT].error = '';
      state.screens[Screens.EMAIL_SIGN_IN].error = '';
      state.screens[Screens.RESET_PASSWORD].error = '';
      state.screens[Screens.CREATE_ACCOUNT].emailError = '';
      state.screens[Screens.CREATE_ACCOUNT].passwordError = '';
      state.screens[Screens.CREATE_USERNAME].message = '';
      state.screens[Screens.CREATE_USERNAME].isChecking = false;
      state.screens[Screens.CREATE_USERNAME].isGenerating = true;
    })
    .addCase(reset, (state) => {
      state.screenId = initialState.screenId;
      state.lastInteractedScreenId = initialState.lastInteractedScreenId;
      state.profile = initialState.profile;
      state.screens = initialState.screens;
    });
});
