import React from 'react';
import { useSelector } from 'react-redux';

import ProfileHeader from './ProfileHeader';
import { selectCurrentUser, selectCurrentUserId } from '../store/users/selectors';
import { EventSourceContext } from '../common/contexts.ts';

const ProfileHeaderForSignup = () => {
  const userId = useSelector(selectCurrentUserId);
  const userProfile = useSelector(selectCurrentUser);

  return (
    <EventSourceContext.Provider value="Sign-up">
      <ProfileHeader userId={userId} isEditing userProfile={userProfile} />
    </EventSourceContext.Provider>
  );
};

export default ProfileHeaderForSignup;
