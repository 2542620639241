import React from 'react';
import ReactDOM from 'react-dom';
import Loader from '../components/Loader';

import { WithRoomStore } from '../store/Wrapper';

export async function renderSillyActions() {
  const SillyActions = React.lazy(() => import('./SillyActions'));
  ReactDOM.render(
    <WithRoomStore>
      <React.Suspense fallback={<Loader />}>
        <SillyActions />
      </React.Suspense>
    </WithRoomStore>,
    document.getElementById('silly-actions')
  );
}
