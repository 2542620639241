import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardEditOverlay from './CardEditOverlay';
import { hideableMixin, svgColorMixin } from '../mixins';
import {
  DEFAULT_WIDGET_PARAMS,
  USER_CARDS_GAP_PX,
  USER_CARD_HEIGHT_PX,
  USER_CARD_SLIDE_HEIGHT_PX,
  USER_CARD_WIDTH_PX,
} from '../../constants/user-card-constants';
import { useBoardData } from '../hooks/useBoardData';
import { selectCurrentUserId } from '../store/users/selectors';
import { useBaseBoardController } from '../hooks/useBaseBoardController';
import { useBoardElementsData } from '../hooks/useBoardElementsData';
import firebase, { db } from '../../firebase';
import { track } from '../../util/analytics-util';
import { getElementType } from '../board-elements/element-definitions';
import {
  ADD_ELEMENT,
  ADD_ELEMENT_DESTINATION_TYPES,
  ADD_ELEMENT_SOURCE_TYPES,
} from '../../constants/analytics-events/element-events';
import log from '../../log';
import { getImageDimensions, uploadFile } from '../../util/file-util';
import { elementClasses } from '../board-elements/elements.ts';
import { USER_CARD_BOARD_TYPE } from '../../constants/board-constants';
import { BoardControllerContext } from '../common/contexts.ts';
import CardView from './CardView';

import PenIcon from '../../../assets/icons/pen.svg';

const Card = ({ cardId, isEditMode, onEnterEditMode, onExitEditMode, onDelete, isFirstCard, scale }) => {
  const currentUserId = useSelector(selectCurrentUserId);

  const cardWidth = USER_CARD_WIDTH_PX * scale;
  const cardHeight = USER_CARD_HEIGHT_PX * scale;

  const [elementsData] = useBoardElementsData(cardId);
  const baseBoardController = useBaseBoardController(elementsData);

  const [lastAddedElementId, setLastAddedElementId] = useState(null);
  const addElement = useCallback(
    async (data, { analyticsParams } = { analyticsParams: {} }) => {
      const x = data.center ? data.center[0] : DEFAULT_WIDGET_PARAMS.center[0];
      const y = data.center ? data.center[1] : DEFAULT_WIDGET_PARAMS.center[1];

      try {
        const elementRef = await db.collection(`boards/${cardId}/elements`).add({
          zIndex: baseBoardController.maxZIndex + 1,
          rotationAngle: 0,
          ...data,
          center: [x, y],
          x,
          y,
          w: data.size[0],
          h: data.size[1],
          creator: currentUserId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        setLastAddedElementId(elementRef.id);
        track(ADD_ELEMENT, {
          element: getElementType(data),
          destination: ADD_ELEMENT_DESTINATION_TYPES.CARD,
          ...analyticsParams,
        });
      } catch (err) {
        log.error(`error adding ${data.class}`, err);
      }
    },
    [cardId, baseBoardController.maxZIndex, currentUserId]
  );

  const uploadImage = useCallback(
    async (file, center) => {
      const [url, { width, height }] = await Promise.all([
        uploadFile(file, `boards/${cardId}/images`),
        getImageDimensions(file),
      ]);

      addElement(
        {
          class: elementClasses.IMAGE,
          size: [
            DEFAULT_WIDGET_PARAMS.width,
            (DEFAULT_WIDGET_PARAMS.width * height) / width || DEFAULT_WIDGET_PARAMS.width,
          ],
          imageURL: url,
          imageType: 'image',
          center,
        },
        { analyticsParams: { source: ADD_ELEMENT_SOURCE_TYPES.UPLOAD_FROM_COMPUTER } }
      );
    },
    [addElement, cardId]
  );

  const onFileUpload = useCallback(
    async (e) => {
      if (e.target.files[0]) {
        await uploadImage(e.target.files[0]);
        e.target.value = null;
      }
    },
    [uploadImage]
  );

  const boardController = useMemo(
    () => ({ addElement, uploadImage, lastAddedElementId, type: USER_CARD_BOARD_TYPE, ...baseBoardController }),
    [addElement, baseBoardController, uploadImage, lastAddedElementId]
  );

  const [isHovered, setIsHovered] = useState(false);
  const [{ creator, title }] = useBoardData(cardId);
  const canEdit = creator === currentUserId;

  return (
    <Container
      onMouseEnter={useCallback(() => setIsHovered(true), [])}
      onMouseLeave={useCallback(() => setIsHovered(false), [])}
    >
      <EditOverlay isHiddenDisplay={!isEditMode} />
      <CardAndTitleContainer
        isEditMode={isEditMode}
        height={USER_CARD_SLIDE_HEIGHT_PX * scale}
        gap={USER_CARDS_GAP_PX * scale}
      >
        <CardContainer
          height={cardHeight}
          width={cardWidth}
          showBorder={isHovered && !isEditMode}
          isEditMode={isEditMode}
          onWheel={useCallback((e) => isEditMode && e.stopPropagation(), [isEditMode])}
        >
          <CardOverlay isHiddenDisplay={!canEdit || isEditMode || !isHovered}>
            <EditButton
              className="edit-button"
              onClick={useCallback(() => onEnterEditMode(cardId), [onEnterEditMode, cardId])}
            >
              <PenIcon />
            </EditButton>
          </CardOverlay>
          {isEditMode && (
            <CardEditOverlay
              cardId={cardId}
              onClose={onExitEditMode}
              onAddElement={addElement}
              onFileUpload={onFileUpload}
              isFirstCard={isFirstCard}
              onDelete={onDelete}
            />
          )}
          <BoardControllerContext.Provider value={boardController}>
            <CardView cardId={cardId} isEditMode={isEditMode} scale={scale} />
          </BoardControllerContext.Provider>
        </CardContainer>

        {!isEditMode && <CardTitle>{title}</CardTitle>}
      </CardAndTitleContainer>
    </Container>
  );
};

export default Card;

Card.propTypes = {
  cardId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  onEnterEditMode: PropTypes.func,
  onExitEditMode: PropTypes.func,
  scale: PropTypes.number,
  onDelete: PropTypes.func,
  isFirstCard: PropTypes.bool,
};

Card.defaultProps = {
  scale: 1,
  isFirstCard: false,
  onDelete: () => {},
  onEnterEditMode: () => {},
  onExitEditMode: () => {},
};

const Container = styled.div`
  pointer-events: all;
`;

const CardAndTitleContainer = styled.div`
  height: ${({ height }) => height}px;
  margin-right: ${({ gap }) => gap}px; ;
`;

const EditOverlay = styled.div`
  ${hideableMixin()}
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  z-index: 99;
`;

export const CardOverlay = styled.div`
  ${hideableMixin()}

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const CardContainer = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  position: relative;
  ${({ isEditMode }) => (isEditMode ? 'z-index: 100;' : '')}
  ${({ showBorder }) =>
    showBorder &&
    `
    outline: white 5px solid;
    border-radius: 20px;
  `}

  flex: 0 0 360px;
`;

const EditButton = styled.div`
  pointer-events: all;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 9px 13px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
  background: white;
  ${svgColorMixin('black')}

  &:hover {
    background: #6b1be3;
    ${svgColorMixin('white')}
  }
`;

export const CardTitle = styled.div`
  width: 100%;
  margin-top: 10px;

  font-size: 20px;
  color: white;
  font-weight: bold;
  text-align: center;
`;
