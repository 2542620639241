import { useState, useEffect, useCallback } from 'react';

import { db } from '../../firebase';

export function useElementData(currentBoardId, docId) {
  const [elementData, setElementData] = useState({});

  useEffect(() => {
    let unsubscribe = () => {};
    if (currentBoardId && docId)
      unsubscribe = db.doc(`boards/${currentBoardId}/elements/${docId}`).onSnapshot((snapshot) => {
        setElementData({ id: snapshot.id, ...snapshot.data() });
      });

    return () => {
      unsubscribe();
    };
  }, [currentBoardId, docId]);

  const updateElementData = useCallback(
    (updates) => db.doc(`boards/${currentBoardId}/elements/${docId}`).update(updates),
    [currentBoardId, docId]
  );

  return [elementData, updateElementData];
}
