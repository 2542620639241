import styled from 'styled-components';
import { getCountryCallingCode, Country } from 'react-phone-number-input';

export function formatPhone(countryCode: Country, phoneNumber: string) {
  const countryNumber = getCountryCallingCode(countryCode);
  return `+${countryNumber}${phoneNumber}`;
}

const ERROR_CODE_MESSAGE_MAP: { [key: string]: string } = {
  'auth/invalid-phone-number': 'Invalid phone number format',
  'auth/too-many-requests': 'Too many requests, please try again later',
  'auth/invalid-verification-code': 'Incorrect code',
  'auth/account-exists-with-different-credential': 'This phone number is already linked to another account',
};

export function handleErrorCode(code: string): string {
  if (ERROR_CODE_MESSAGE_MAP[code]) {
    return ERROR_CODE_MESSAGE_MAP[code];
  }

  return 'Something went wrong. Please try again later';
}

export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char: string) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export const FormLabel = styled.div`
  font-size: 12px;
  color: rgba(1, 1, 1, 0.5);
  margin-bottom: 5px;
  margin-top: 5px;
`;
