import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';

interface FlowInputsContainerProps {
  isValid: boolean;
  onSubmit: () => void;
  children: ReactNode;
}

const FlowInputsContainer = ({ isValid, onSubmit, children }: FlowInputsContainerProps) => {
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (isValid) onSubmit();
    },
    [isValid, onSubmit]
  );

  return (
    <Container onSubmit={handleSubmit}>
      {children}
      <input type="submit" hidden />
    </Container>
  );
};

export default FlowInputsContainer;

const Container = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
