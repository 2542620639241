import { FORMAT_GROUP_ERROR, FormatGroupErrorTypes } from '../../../constants/analytics-events/groups-events';
import log from '../../../log';
import { track } from '../../../util/analytics-util';
import { Group, GroupChatMember, GroupInResponse, GroupMember, GroupResponse } from '../../../definitions/groups';
import { getLastMessageFromMessages } from '../messaging/normalizers';

const getMemberIdsFromGroupResponse = (members: GroupMember[]): { [key: string]: boolean } =>
  members.reduce(
    (currMemberIds, member) => ({
      ...currMemberIds,
      [member.userId]: true,
    }),
    {}
  );

export const getGroupFromGroupResponse = (
  groupResponse: GroupResponse,
  currentUserId: string,
  type: FormatGroupErrorTypes,
  prevGroupInStore?: Group // only pass in cases where group may have already existed in store
): Group => {
  const selfMember: GroupMember = groupResponse.group?.members.filter((member) => member.userId === currentUserId)[0];

  // Adding this error tracking here because we came across this prev
  // and want to know what's happening if it happens again
  if (!selfMember) {
    log.error('No self in members in group response');
    track(FORMAT_GROUP_ERROR, { error: 'No self in members', userId: currentUserId, response: groupResponse, type });
  }

  let latestLastAccess = selfMember?.lastAccess;
  if (prevGroupInStore?.lastAccess > selfMember?.lastAccess) {
    latestLastAccess = prevGroupInStore.lastAccess;
  }

  return {
    id: groupResponse.group?.id,
    name: groupResponse.group?.groupName,
    unreadCount: groupResponse.group?.unreadCount,
    background: groupResponse.group?.background,
    chatId: groupResponse.group?.chat.chatId,
    boards: groupResponse.group?.boards,
    memberIds: groupResponse.group?.members ? getMemberIdsFromGroupResponse(groupResponse.group.members) : {},
    theme: groupResponse.group?.theme,
    muralId: groupResponse.group?.muralId,
    isNotificationsOn: groupResponse.group?.isNotificationsOn,
    createdAt: groupResponse.group?.createdAt,
    updatedAt: groupResponse.group?.updatedAt,
    role: selfMember?.role,
    muralLastUpdatedAt: groupResponse.group?.muralLastUpdatedAt,
    lastAccess: latestLastAccess,
  };
};

export const formatGroupChatFromGroupResponse = (groupResponse: GroupInResponse, currentUserId: string) => {
  const chatMembers = groupResponse.members?.reduce<{ [key: string]: GroupChatMember }>((acc, member) => {
    // we need to filter out self from members in chat in group response
    // to stay consistent with members returned in chat response (which doesn't include self)
    if (member.userId !== currentUserId) {
      acc[member.userId] = {
        role: member.role,
      };
    }
    return acc;
  }, {});

  const { chat } = groupResponse;
  return {
    id: chat.chatId,
    background: chat.background,
    createdAt: chat.createdAt,
    updatedAt: chat.updatedAt,
    deletedAt: chat.deletedAt,
    unreadCount: groupResponse.unreadCount, // this assumes that group unread count and chat unread count are the same
    lastMessage: getLastMessageFromMessages(chat.messages),
    chatMembers: chatMembers || {},
    groupId: groupResponse.id,
    groupName: groupResponse.groupName,
  };
};
