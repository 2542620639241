import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { MoodTheme } from '../../../definitions/user-profile';
import CustomThemeColorSelect, { Label } from '../../room/settings/theme/CustomThemeColorSelect';
import FontSelect from '../../room/settings/theme/FontSelect';
import { applyTheme } from '../../../util/theming-util';

type MoodThemeSettingsProps = {
  theme: MoodTheme;
  onChange: (theme: MoodTheme) => void;
};

const MoodThemeSettings = ({ theme, onChange }: MoodThemeSettingsProps) => {
  const onFontChange = useCallback((font) => onChange({ ...theme, primaryFont: font.family }), [onChange, theme]);
  const onBackgroundChange = useCallback(
    (color) => onChange({ ...theme, colors: { ...theme.colors, primaryBackground: color } }),
    [theme, onChange]
  );
  const onForegroundChange = useCallback(
    (color) => onChange({ ...theme, colors: { ...theme.colors, primaryForeground: color } }),
    [theme, onChange]
  );

  const containerRef = useRef(null);
  useEffect(() => {
    if (theme && containerRef.current) {
      applyTheme(theme.colors, theme.primaryFont, containerRef.current);
    }
  }, [theme]);

  return (
    <Container ref={containerRef} className="unthemed">
      <div className="unthemed">
        <FontSelect
          label="Font"
          id="moodTheme"
          dropdownDirection="up"
          currentFont={theme.primaryFont}
          onFontChange={onFontChange}
        />
      </div>
      <ColorsContainer>
        <Color>
          <CustomThemeColorSelect label="Color" value={theme.colors.primaryBackground} onChange={onBackgroundChange} />
        </Color>
        <Color>
          <CustomThemeColorSelect
            previewText="Tt"
            label="Text"
            value={theme.colors.primaryForeground}
            onChange={onForegroundChange}
          />
        </Color>
      </ColorsContainer>
    </Container>
  );
};

export default MoodThemeSettings;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  padding: 8px 16px;
  margin-bottom: 10px;
  box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.3);
`;

const ColorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Color = styled.div`
  width: 100px;

  ${Label} {
    color: black;
  }
`;
