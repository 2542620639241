export const FAQ_LINK = 'https://help.here.fm';
export const CAMERA_HELP_LINK = 'https://help.here.fm/hc/en-us/sections/4414012852627-Camera-Mic-Guide';
export const HIRING_LINK = 'https://jobs.ashbyhq.com/here';

export const DISCORD_INVITE_WEBHOOK_URL =
  'https://discord.com/api/webhooks/785943632736944139/nzioqBYJ6k0CNCJiLcMzBYNrprJm50UkoUgRex3qSoWBz1vlX2FcvKRCYtyim77JPkV1';

export const DISCORD_FEEDBACK_WEBHOOK_URL =
  'https://discord.com/api/webhooks/785944123353858109/tqMmLhdu-YtW-w-J0zlgukb8cXYLeQJNcWtclalISxfngIkFla4y9PMEmDpzOzCnsu4w';

export const GROUPS_FEEDBACK_WEBHOOK_URL =
  'https://discord.com/api/webhooks/1148278383181045810/Lhy3f3BLYEnidxtB2aQXlMuFozVXdbejh0qjzL9aKouwSg-wHF5k9clV7V_zZyz-ldWz';

export const TRUST_AND_SAFETY_NOTIFICATION = '<@&995672506423853179>';
