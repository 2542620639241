import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { ellipsisTextMixin } from '../mixins';

const ChatIconTooltip = ({ title, subtitle, children, position }) => (
  <TooltipRoot delayDuration={100}>
    <TooltipTrigger asChild>{children}</TooltipTrigger>
    <TooltipStyledContent side={position} sideOffset={5}>
      <Title className="themed-font">{title}</Title>
      {subtitle ? <Username>{subtitle}</Username> : null}
    </TooltipStyledContent>
  </TooltipRoot>
);

export default ChatIconTooltip;

ChatIconTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
};

ChatIconTooltip.defaultProps = {
  position: 'right',
  subtitle: '',
};

const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipStyledContent = styled(TooltipPrimitive.Content)`
  padding: 5px 15px;
  max-width: 250px;
  border-radius: 8px;
  background: var(--primary-background, #ffffff);
`;

const Title = styled.p`
  margin: 0;
  color: var(--primary-foreground, #140630);
  font-size: 16px;

  ${ellipsisTextMixin()}
`;

const Username = styled.p`
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  color: var(--primary-foreground-alpha-50, #1406307f);

  ${ellipsisTextMixin()}
`;
