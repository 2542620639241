import React, { useRef } from 'react';

import { useSettingsTabsAdapter } from '../useSettingsTabsAdapter';

const BackgroundSettings = () => {
  const webComponentRef = useRef();
  useSettingsTabsAdapter(webComponentRef);
  return (
    <div>
      <div className="settings-container">
        <here-background-properties ref={webComponentRef} />
      </div>
      <div className="background-chooser-container">
        <here-room-details showSpaces="true" />
      </div>
    </div>
  );
};

export default BackgroundSettings;
