import $ from 'jquery';

import fastdom from 'fastdom';
import log from './log';

import '../styles/interface-visibility.less';
import { NEW_DEFAULT_OS_BOARD_BG, OLD_DEFAULT_OS_BOARD_BG } from './react/os/constants';

export { getQueryStringValue } from './util/get-query-string-value';

export const sanitize = (str) => (str ? `${str}`.replace(/</g, '&lt;').replace(/>/g, '&gt;') : '');

export function linkify(inputText) {
  // URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  let replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^/])(www\.[-A-Z0-9_]+\.[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  // Change email addresses to mailto:: links. TODO do we want this?
  /* replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>'); */

  return replacedText;
}

export function isValidUrl(string) {
  const res = string.match(
    /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g
  );

  return res !== null;
}

export function hasProtocol(url) {
  return /^[A-Z]+:\/\//i.test(url);
}

export const boardElementForEvent = (e, target = e.target) =>
  $(target).hasClass('boardElement') ? target : $(target).closest('.boardElement').get(0);

export function getCurrentRoomURL(boardId, urlAlias) {
  return `https://here.fm/${urlAlias || boardId}`;
}

export function htmlToElement(html) {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  return template.content.firstChild;
}

/**
 * Triggers download of a file at a specified URI.
 *
 * @param {string} uri Path of object to download
 * @param {string} name (optional) name of file when downloaded
 */
export function downloadURI(uri, name = '') {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.setAttribute('target', '_blank');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function isPointInsideElement(el, x, y) {
  const rect = el.getBoundingClientRect();
  return x >= rect.left && y >= rect.top && x <= rect.right && y <= rect.bottom;
}

export function rectIntersects(rectA, rectB) {
  const xA1 = parseFloat(rectA.x);
  const yA1 = parseFloat(rectA.y);
  const xA2 = parseFloat(rectA.x) + parseFloat(rectA.w);
  const yA2 = parseFloat(rectA.y) + parseFloat(rectA.h);
  const xB1 = parseFloat(rectB.x);
  const yB1 = parseFloat(rectB.y);
  const xB2 = parseFloat(rectB.x) + parseFloat(rectB.w);
  const yB2 = parseFloat(rectB.y) + parseFloat(rectB.h);

  if (xA1 > xB2 || xB1 > xA2) {
    return false;
  }
  if (yA1 > yB2 || yB1 > yA2) {
    return false;
  }
  return true;
}

/**
 *
 * @param {*} el
 * @returns true if any part of the element's rectangle is on screen
 */
export function isPartiallyOnScreen(el) {
  const rect = el.getBoundingClientRect();

  const h = window.innerHeight || document.documentElement.clientHeight;
  const w = window.innerWidth || document.documentElement.clientWidth;

  return rectIntersects({ x: rect.left, y: rect.top, w: rect.width, h: rect.height }, { x: 0, y: 0, w, h });
}

/**
 *
 * @param {*} el The element to examine
 * @returns true if element is 100% on-screen
 */
export function isOnScreen(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const viewportObserver = new IntersectionObserver(onElementViewportChange);

function onElementViewportChange(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.childNodes.forEach((node) => {
        if (node.style) {
          fastdom.mutate(() => {
            node.style.display = 'block';
          });
        }
      });
    } else {
      entry.target.childNodes.forEach((node) => {
        if (node.style) {
          const elId = node.id.replace('element-', '');
          const handler = window.elementHandlers[elId];
          if (handler) {
            const type = handler.constructor.elementType;
            if (type === 'CameraElement' || type === 'ScreenshareElement' || type === 'MediaPlayerElement') {
              return; // Safari won't play audio from hidden elements initially :-| thx for nothing
            }
          }
          fastdom.mutate(() => {
            node.style.display = 'none';
          });
        }
      });
    }
  });
}

export function observeElementIntersection(el) {
  viewportObserver.observe(el);
}

export function handlerForElement(el) {
  const elementId = el.id.replace('element-', '');
  return window.elementHandlers[elementId];
}

export function elementsIntersect(a, b) {
  if (!a || !b) {
    return false;
  }

  const rect1 = a.getBoundingClientRect();
  const rect2 = b.getBoundingClientRect();

  return !(
    rect1.right <= rect2.left ||
    rect1.left >= rect2.right ||
    rect1.bottom <= rect2.top ||
    rect1.top >= rect2.bottom
  );
}

export function clamp(val, min, max) {
  return Math.max(Math.min(val, max), min);
}

export function randomVideoServer() {
  const servers = ['v', 'v2'];
  return servers[Math.floor(Math.random() * servers.length)];
}

export function getRandomNumberInRange(min, max) {
  return Math.random() * (max - min) + min;
}

let pageMuted = false;

export function isPageMuted() {
  return pageMuted;
}

export function setPageMuted(isMuted) {
  pageMuted = isMuted;
}

const dateFormatter = new Intl.DateTimeFormat('en', {
  hour12: true,
  day: '2-digit',
  month: 'short',
  hour: '2-digit',
  minute: '2-digit',
});

export function formatDate(date) {
  return dateFormatter.format(date);
}

// This is the vanity url or the board id from the location href
export function getRoomId(currentUrl = window.location.href) {
  const matches = currentUrl.match(/.*\/\/.*?\/(.*)/);
  let boardId = matches[1];
  if (window.location.hash !== '' && window.location.hash !== null) {
    boardId = window.location.hash;
  } else if (boardId.includes('?')) {
    boardId = boardId.substr(0, boardId.indexOf('?'));
  }
  if (boardId.includes('/')) {
    boardId = boardId.substr(0, boardId.indexOf('/'));
  }
  if (boardId.includes('#')) {
    boardId = boardId.substr(boardId.indexOf('#') + 1, boardId.length);
  }
  // Ignore /index.html type names
  return boardId.endsWith('.html') ? null : boardId;
}

export function getFileTypeByExtension(extension) {
  let type;
  switch (extension ? extension.toLowerCase() : null) {
    case 'gif':
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'svg':
    case 'webp':
      type = 'image';
      break;
    case 'ppt':
    case 'pptx':
    case 'key':
      type = 'presentation';
      break;
    case 'txt':
    case 'rtf':
    case 'doc':
    case 'docx':
      type = 'text';
      break;
    case 'pdf':
      type = 'pdf';
      break;
    case 'xls':
    case 'xlsx':
    case 'csv':
      type = 'spreadsheet';
      break;
    case 'mp4':
    case 'mov':
      type = 'video';
      break;
    case 'mp3':
    case 'wav':
    case 'm4a':
    case 'ogg':
    case 'flac':
      type = 'audio';
      break;
    default:
      type = 'files';
  }

  return type;
}

export function createNewFilename(oldName) {
  const extension = oldName.split('.').pop();
  const fileNameNoExtension = oldName.substr(0, oldName.lastIndexOf('.'));
  // NOTE: This is quick method to get pseudo-random string. It shouldn't be used for security purposes,
  // but it's totally fine for creating unique file name.
  const randomPart = (Math.random() + 1).toString(36).substring(3, 9);
  const newName = `${fileNameNoExtension} (${randomPart}).${extension}`;
  return newName;
}

export function isImage(fileName) {
  return fileName.match(/[/.](gif|jpg|jpeg|png)$/i);
}

// Change numbers like 43934 into 43.9K, etc
export const shortenNumber = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
  return n;
};

export function getEmojiCount(input) {
  return ((input || '').match(/\p{Extended_Pictographic}/gu) || []).length;
}

export function containsText(str) {
  const output = str.replace(
    /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g,
    ''
  );
  let result;
  if (output !== '') result = true;
  else result = false;
  return result;
}

/**
 * Calculate brightness value by RGB or HEX color. Ignores alpha.
 * @param color (String) The color value in RGB or HEX
 *   (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
export function brightnessByColor(inputColor) {
  const color = `${inputColor}`;
  const isHEX = color.indexOf('#') === 0;
  const isRGB = color.indexOf('rgb') === 0;
  let r = 0;
  let g = 0;
  let b = 0;
  if (isHEX) {
    const hasFullSpec = color.length === 7 || color.length === 9;
    const m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
    if (m) {
      r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16);
      g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16);
      b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g);
    if (m) {
      [r, g, b] = m;
    }
  }
  if (typeof r !== 'undefined') {
    return (r * 299 + g * 587 + b * 114) / 1000;
  }

  return 0;
}

export function indexInParent(node) {
  const children = node.parentNode.childNodes;
  let num = 0;

  for (let i = 0; i < children.length; i += 1) {
    if (children[i] === node) return num;
    if (children[i].nodeType === 1) num += 1;
  }

  return -1;
}

// ///// Wake lock

let wakeLock = null;

const handleVisibilityChange = async () => {
  if (wakeLock !== null && document.visibilityState === 'visible') {
    await requestWakeLock();
  }
};

export async function requestWakeLock() {
  if (wakeLock) {
    await wakeLock.release();
  }

  try {
    wakeLock = await navigator.wakeLock.request('screen');
  } catch (__err) {
    // this can fail if we request while not frontmost tab, etc
  }

  document.removeEventListener('visibilityChange', handleVisibilityChange);
  document.addEventListener('visibilitychange', handleVisibilityChange);
}

export async function releaseWakeLock() {
  if (!wakeLock) {
    return;
  }
  try {
    log.debug('Release wake lock requested');
    await wakeLock.release();
    wakeLock = null;
  } catch (err) {
    log.error(`${err.name}, ${err.message}`);
  }
}

export const getRoomBackground = (background, backgroundColor) => {
  if (background) {
    const url = Number.isInteger(background) ? `/images/bg/${background}.jpg` : background;
    return { url };
  }

  if (backgroundColor) {
    return { backgroundColor };
  }

  return null;
};

export function getBackgroundStyle({ background, backgroundColor } = {}) {
  const backgroundData = getRoomBackground(background, backgroundColor);
  if (backgroundData) {
    if (backgroundData.url && backgroundData.url === OLD_DEFAULT_OS_BOARD_BG) {
      return { backgroundImage: `url('${NEW_DEFAULT_OS_BOARD_BG}')` };
    }
    if (backgroundData.url) {
      return { backgroundImage: `url('${backgroundData.url}')` };
    }

    if (backgroundData.backgroundColor) {
      return { backgroundColor: backgroundData.backgroundColor };
    }
  }

  return null;
}

export function applyBackgroundStyle(el, { background, backgroundColor }, lazyLoadImage = false) {
  const backgroundStyle = getBackgroundStyle({ background, backgroundColor });
  if (background) {
    el[lazyLoadImage ? 'dataset' : 'style'].backgroundImage = backgroundStyle.backgroundImage;
  } else if (backgroundColor) {
    el.style.backgroundColor = backgroundStyle.backgroundColor;
  }
}

export function isArrowKey(key) {
  return key === 'ArrowUp' || key === 'ArrowDown' || key === 'ArrowLeft' || key === 'ArrowRight';
}

export function onIdle(f, opts) {
  if (window.requestIdleCallback) {
    return window.requestIdleCallback(f, opts);
  }

  const requestAnimationFrameSet = window.requestAnimationFrame(() => {
    f();
  });

  return requestAnimationFrameSet;
}

export function hideInterface(animated = true) {
  document.body.style.setProperty('--interface-animation-duration', animated ? '0.5s' : '0.0s');

  const roomContainer = document.getElementById('room-menu-container');
  roomContainer.classList.add('hide-down');
  roomContainer.classList.remove('show-down');

  document.getElementById('global-menu-container').classList.add('hide-up');
  document.getElementById('global-menu-container').classList.remove('show-up');

  document.querySelector('.feed-bar').classList.add('hide-down');
  document.querySelector('.feed-bar').classList.remove('show-down');

  document.querySelector('.minimap-container').classList.add('hide-right');
  document.querySelector('.minimap-container').classList.remove('show-right');

  document.querySelector('.users-bar').classList.add('hide-up');
  document.querySelector('.users-bar').classList.remove('show-up');

  document.querySelector('.messages-sidebar-container').classList.add('hide-message-sidebar');
  document.querySelector('.messages-sidebar-container').classList.remove('show-message-sidebar');
}

export function showInterface(animated = true) {
  document.body.style.setProperty('--interface-animation-duration', animated ? '0.5s' : '0.0s');

  const roomContainer = document.getElementById('room-menu-container');
  roomContainer.classList.remove('hide-down');
  roomContainer.classList.add('show-down');

  document.getElementById('global-menu-container').classList.remove('hide-up');
  document.getElementById('global-menu-container').classList.add('show-up');

  document.querySelector('.feed-bar').classList.remove('hide-down');
  document.querySelector('.feed-bar').classList.add('show-down');

  document.querySelector('.minimap-container').classList.remove('hide-right');
  document.querySelector('.minimap-container').classList.add('show-right');

  document.querySelector('.users-bar').classList.remove('hide-up');
  document.querySelector('.users-bar').classList.add('show-up');

  document.querySelector('.messages-sidebar-container').classList.remove('hide-message-sidebar');
  document.querySelector('.messages-sidebar-container').classList.add('show-message-sidebar');
}

export function toggleInterfaceVisible() {
  const roomContainer = document.getElementById('room-menu-container');
  if (roomContainer.classList.contains('hide-down')) {
    showInterface();
  } else {
    hideInterface();
  }
}

export function getDocIdFromElementId(elementId) {
  return elementId.replace('element-', '');
}

export function getElementCenter(element) {
  const { top, left, width, height } = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}

export function getAbsoluteDistanceBetweenElements(elementA, elementB) {
  const aPosition = getElementCenter(elementA);
  const bPosition = getElementCenter(elementB);
  return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
}

export function getDistanceBetweenElements(elementA, elementB) {
  const aPosition = getElementCenter(elementA);
  const bPosition = getElementCenter(elementB);
  return { x: aPosition.x - bPosition.x, y: aPosition.y - bPosition.y };
}

/**
 *
 * Returns string inside the 'url()' of the passed element's background.
 *
 * For example:
 * Receives - 'url(\"https://images.unsplash.com/photo-1446071103084-c257b5f70672?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNzY2NTR8MHwxfGFsbHwyfHx8fHx8MXx8MTYyNzM4NzgwOA&ixlib=rb-1.2.1&q=80&w=1080\")'
 * Returns - "https://images.unsplash.com/photo-1446071103084-c257b5f70672?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNzY2NTR8MHwxfGFsbHwyfHx8fHx8MXx8MTYyNzM4NzgwOA&ixlib=rb-1.2.1&q=80&w=1080"
 *
 * @param {string} background
 * @returns {string}
 */
export function getBackgroundImageUrl(background) {
  const imageUrl = background.match(/url(?:\(['"]?)(.*?)(?:['"]?\))/);
  return imageUrl && imageUrl.length >= 2 ? imageUrl[1] : null;
}

export function executeWebhook(webhookUrl, content) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Cookie', '__cfduid=d4f6cafb7107aa3f09146a621dc183a311607110804');

  const raw = JSON.stringify({ content, tts: false, embed: '' });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  return fetch(webhookUrl, requestOptions);
}

// https://firebase.google.com/docs/firestore/manage-data/delete-data#collections
async function deleteQueryBatch(db, query, resolve) {
  const snapshot = await query.get();

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = db.batch();
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });

  await batch.commit();

  // Recurse on the next process tick, to avoid
  // exploding the stack.
  setTimeout(() => {
    deleteQueryBatch(db, query, resolve);
  }, 0);
}

export async function permanentlyDeleteCollectionFromFirestore(db, collectionPath, batchSize) {
  const collectionRef = db.collection(collectionPath);
  const query = collectionRef.orderBy('__name__').limit(batchSize);
  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, resolve).catch(reject);
  });
}

export const performActionWithInterval = (times, interval, action) => {
  let i = 0;
  const intervalId = setInterval(() => {
    action();

    i += 1;
    if (i === times) {
      clearInterval(intervalId);
    }
  }, interval);
};

export async function objectFromURL(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => resolve(URL.createObjectURL(blob)))
      .catch((error) => {
        log.error(`Has been an error preloading signal quality images: ${error}`);
        reject(error);
      });
  });
}

export const getDefinedFieldsObject = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => (value !== undefined ? { ...acc, [key]: value } : acc), {});

/**
 *
 * @param {Array} objectsList
 * @param {string[]} orderList
 * @param {string} field
 * @return {Array}
 */
export const orderObjectsByField = (objectsList, orderList, field) => {
  const mentionedObjects = objectsList.filter((i) => orderList.includes(i[field]));
  const notMentionedObjects = objectsList.filter((i) => !orderList.includes(i[field]));
  return mentionedObjects
    .sort((a, b) => orderList.findIndex((i) => i === a[field]) - orderList.findIndex((i) => i === b[field]))
    .concat(notMentionedObjects);
};

export const rotatePoint = (pointToRotate, originPoint, angle) => {
  // Transforming pointToRotate to be in originPoint-based relative coordinates
  // so it's easier to rotate it around relative (0, 0)
  const relativePoint = [pointToRotate[0] - originPoint[0], pointToRotate[1] - originPoint[1]];
  const sin = Math.sin(angle);
  const cos = Math.cos(angle);
  const rotatedRelativePoint = [
    relativePoint[0] * cos - relativePoint[1] * sin,
    relativePoint[1] * cos + relativePoint[0] * sin,
  ];
  return [rotatedRelativePoint[0] + originPoint[0], rotatedRelativePoint[1] + originPoint[1]];
};
