import { useEffect, useRef, useState } from 'react';

interface Params {
  parentSelector: string;
  element: HTMLElement;
  tickIntervalMs?: number;
}

export const useDomElement = ({ parentSelector, element, tickIntervalMs = 400 }: Params) => {
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const [mountedElement, setMountedElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const parent = document.querySelector(parentSelector);
      if (parent) {
        parent.appendChild(element);
        setMountedElement(element);
        clearInterval(intervalRef.current);
      }
    }, tickIntervalMs);

    return () => {
      clearInterval(intervalRef.current);
      element.remove();
      setMountedElement(null);
    };
  }, [parentSelector, tickIntervalMs, element]);

  return mountedElement;
};
