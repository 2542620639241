import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { confirmAlert } from 'react-confirm-alert';
import UIkit from 'uikit';

import { selectIsViewersModeOn, selectRoomId } from '../../../store/room/selectors';
import { selectCurrentUserId } from '../../../store/users/selectors';
import { removeMember } from '../../../../room';
// TODO: There should be no direct firebase reference here.
import firebase from '../../../../firebase';

import { showReportModal } from '../../../../util/report-util';
import { track } from '../../../../util/analytics-util';
import { scrollToElement } from '../../../../viewport';

import { hideableMixin, moreMenuItemMixin } from '../../../mixins';

import { LocationIcon, BanIcon, ReportIcon, DemoteIcon, UserIcon, KeyIcon } from '../../../icons';
import AddFriendIcon from '../../../../../assets/icons/add-friend.svg';
import EyeIcon from '../../../../../assets/icons/eye.svg';
import RemoveIcon from '../../../../../assets/icons/remove.svg';
import KickIcon from '../../../../../assets/icons/kick.svg';

import { openUserProfile } from '../../../user-profile/utils';
import { createFriendRequest, getFriendRequest } from '../../../store/friends/api';
import { FRIEND_REQUEST_STATUS } from '../../../user-profile/constants';
import { friendByIdSelector, loadingFriendsSelector } from '../../../store/friends/selectors';
import { fetchFriends } from '../../../store/friends/store';
import {
  curriedGetIsHostGranted,
  curriedGetIsOwnerGranted,
  curriedGetMemberIsOnline,
} from '../../../store/room-members/selectors';
import {
  memberBanRequested,
  memberInfoUpdateRequested,
  memberKickRequested,
  memberUnbanRequested,
  promotionToMemberRequested,
  removeMember as removeMemberAction,
} from '../../../store/room-members/store';
import ViewUserPageMenuItem from '../../../user-page/ViewUserPageMenuItem';
import MemberConfirmationModal from './MemberConfirmationModal';
import { FRIEND_REQUEST_SOURCES, REQUEST_FRIEND } from '../../../../constants/analytics-events/friend-events';
import { EventSourceContext } from '../../../common/contexts.ts';

const RoomMemberMenu = ({ member, fromUserProfile, fromUserProfileShowAddFriend, reload, memberUsername }) => {
  const dispatch = useDispatch();

  const currentRoomId = useSelector(selectRoomId);
  const currentMemberId = useSelector(selectCurrentUserId);
  const isCurrentUser = member.id === currentMemberId;

  const isViewersModeOn = useSelector(selectIsViewersModeOn);

  const isMemberOnline = useSelector(curriedGetMemberIsOnline(member.id));
  const isMemberHost = useSelector(curriedGetIsHostGranted(member.id));

  const isHostGranted = useSelector(curriedGetIsHostGranted(currentMemberId));
  const isOwnerGranted = useSelector(curriedGetIsOwnerGranted(currentMemberId));

  const memberHostGranted = member.role === 'host' || member.role === 'owner';

  // Only owner and hosts can kick non-host and non-owner online and not banned users
  const shouldShowKickOption = !isHostGranted || memberHostGranted || member.ban || !member.isOnline;

  // Only owner and hosts can ban non-host and non-owner online and not banned users
  const shouldShowBanOption = !isHostGranted || memberHostGranted;
  const shouldShowUnbanOption = !isHostGranted;

  // Only owner and hosts can remove non-host and non-owners who are offline
  const memberIsRemovable = !isMemberOnline && !isMemberHost && !member.ban;
  const shouldShowRemoveOption = isHostGranted && memberIsRemovable;

  const onMemberUpdated = useCallback(
    (data) => {
      dispatch(memberInfoUpdateRequested({ id: member.id, data }));
    },
    [member.id, dispatch]
  );

  const getKickConfirmationModal = useCallback(
    (onClose) => (
      <MemberConfirmationModal
        title="Kick this member?"
        message="Are you sure you want to kick"
        member={member}
        confirm={() => dispatch(memberKickRequested({ id: member.id }))}
        close={onClose}
      />
    ),
    [member, dispatch]
  );

  const kickClickConfirmModal = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => getKickConfirmationModal(onClose),
    });
  }, [getKickConfirmationModal]);

  const onRemoveClick = useCallback(async () => {
    await removeMember(currentRoomId, member.id);
    dispatch(removeMemberAction({ memberId: member.id }));
  }, [currentRoomId, member.id, dispatch]);

  const getRemoveConfirmationModal = useCallback(
    (onClose) => (
      <MemberConfirmationModal
        title="Remove this member?"
        message="Are you sure you want to remove"
        member={member}
        confirm={onRemoveClick}
        close={onClose}
      />
    ),
    [member, onRemoveClick]
  );

  const removeClickConfirmModal = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => getRemoveConfirmationModal(onClose),
    });
  }, [getRemoveConfirmationModal]);

  const getBanConfirmationModal = useCallback(
    (onClose) => (
      <MemberConfirmationModal
        title="Ban this member?"
        message="Are you sure you want to ban"
        member={member}
        confirm={() => dispatch(memberBanRequested({ id: member.id }))}
        close={onClose}
      />
    ),
    [dispatch, member]
  );

  const onBanClick = useCallback(
    () =>
      member.ban
        ? dispatch(memberUnbanRequested({ id: member.id }))
        : confirmAlert({
            customUI: ({ onClose }) => getBanConfirmationModal(onClose),
          }),
    [getBanConfirmationModal, member.ban, member.id, dispatch]
  );

  const onReportClick = useCallback(() => {
    showReportModal({ name: member.name, id: member.id });
  }, [member]);

  const toggleMemberViewerRole = useCallback(() => {
    if (member.role === 'viewer') {
      dispatch(promotionToMemberRequested({ id: member.id }));
    } else {
      onMemberUpdated({ role: 'viewer' });
    }
  }, [member.role, member.id, onMemberUpdated, dispatch]);

  const scrollToUser = useCallback(() => {
    const userId = member.id;
    const userVideoElement = document.getElementById(`video-container-${userId}`).closest('.boardElement');
    scrollToElement(userVideoElement);

    // for case where menu is called from a user profile:
    const moreMenu = document.getElementById('user-profile-more-menu');
    if (moreMenu) UIkit.dropdown(moreMenu).hide();

    // for case where menu is called from room settings menu:
    const roomSettingsModal = document.querySelector('here-room-settings');
    if (roomSettingsModal) roomSettingsModal.classList.remove('uk-flex', 'uk-open');
  }, [member]);

  const onViewProfileClick = (e) => {
    // TODO: A component shouldn't know where it's being rendered.
    const roomSettingsOffcanvas = document.querySelector('#room-settings-react div:first-child');
    const topPosition = e.clientY;
    const leftPosition = roomSettingsOffcanvas.offsetWidth;

    openUserProfile({ userId: member.id, topPosition, leftPosition });
  };

  // friend stuff:
  const [friendRequested, setFriendRequested] = useState(false);
  const [existingRequestReceived, setExistingRequestReceived] = useState(null);
  const [existingRequestSent, setExistingRequestSent] = useState(null);
  const [existingRequestsLoading, setExistingRequestsLoading] = useState(false);

  const currentUserId = firebase.auth().currentUser.uid;
  const isFriends = useSelector((state) => friendByIdSelector(state, member.id));
  const isLoadingFriends = useSelector((state) => loadingFriendsSelector(state));

  useEffect(() => {
    const getExistingFriendRequestReceived = async () => {
      const existing = await getFriendRequest(member.id, currentUserId);
      setExistingRequestReceived(existing);
    };

    const getExistingFriendRequestSent = async () => {
      const existing = await getFriendRequest(currentUserId, member.id);
      setExistingRequestSent(existing);
    };

    const getExistingRequests = async () => {
      setExistingRequestsLoading(true);
      getExistingFriendRequestReceived();
      getExistingFriendRequestSent();
      setExistingRequestsLoading(false);
    };

    if (!isCurrentUser && reload) {
      dispatch(fetchFriends());
      getExistingRequests();
    }
  }, [currentUserId, member.id, isCurrentUser, dispatch, reload]);

  const requestSentPending =
    (existingRequestSent && existingRequestSent.status === FRIEND_REQUEST_STATUS.pending) || friendRequested;
  const requestSentIgnored = existingRequestSent && existingRequestSent.status === FRIEND_REQUEST_STATUS.ignored;

  const requestReceivedPending =
    existingRequestReceived && existingRequestReceived.status === FRIEND_REQUEST_STATUS.pending;
  const requestReceivedIgnored =
    existingRequestReceived && existingRequestReceived.status === FRIEND_REQUEST_STATUS.ignored;

  const requestSent = requestSentPending || requestSentIgnored;
  const showAddFriend =
    (!requestSent && !requestReceivedPending && !isFriends) || (requestReceivedIgnored && !requestSentPending);

  const onAddFriendClick = async () => {
    setFriendRequested(true);

    const response = await createFriendRequest(member.id);
    if (response && response.success) {
      track(REQUEST_FRIEND, {
        source: FRIEND_REQUEST_SOURCES.MEMBER_OVERFLOW_MENU,
        receiverId: member.id,
      });
    } else {
      setFriendRequested(false);
    }
  };

  return (
    <MenuContainer>
      <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.IN_ROOM_MEMBER_MENU}>
        <ViewUserPageMenuItem username={memberUsername} themeStyling />
        {!fromUserProfile && (
          <MenuItem className="uk-dropdown-close" role="button" onClick={onViewProfileClick}>
            <IconContainer>
              <UserIcon color="black" />
            </IconContainer>
            <MenuItemText>View Profile</MenuItemText>
          </MenuItem>
        )}
        {isViewersModeOn ? (
          <MenuItem
            // Only owner and hosts can turn users to hosts and hosts to users
            isHiddenDisplay={!isHostGranted || member.ban}
            className="uk-dropdown-close"
            role="button"
            onClick={toggleMemberViewerRole}
          >
            {member.role === 'viewer' ? (
              <>
                <span data-uk-icon="user" />
                <MenuItemText>Make Member</MenuItemText>
              </>
            ) : (
              <>
                <IconContainer>
                  <EyeIcon />
                </IconContainer>
                <MenuItemText>Demote to Viewer</MenuItemText>
              </>
            )}
          </MenuItem>
        ) : null}
        <MenuItem
          // Only owner can turn users to hosts and hosts to users
          // and owner cannot make themselves host
          isHiddenDisplay={!isOwnerGranted || member.ban || isCurrentUser}
          className="uk-dropdown-close"
          role="button"
          onClick={useCallback(
            () => onMemberUpdated({ role: member.role === 'host' ? null : 'host' }),
            [onMemberUpdated, member.role]
          )}
        >
          {member.role === 'host' ? (
            <>
              <IconContainer>
                <DemoteIcon width="18" />
              </IconContainer>
              <MenuItemText>Demote to Member</MenuItemText>
            </>
          ) : (
            <>
              <IconContainer>
                <KeyIcon />
              </IconContainer>
              <MenuItemText>Make Host</MenuItemText>
            </>
          )}
        </MenuItem>
        <MenuItem isHiddenDisplay={!shouldShowRemoveOption} role="button" onClick={removeClickConfirmModal}>
          <IconContainer>
            <RemoveIcon />
          </IconContainer>
          <MenuItemText>Remove</MenuItemText>
        </MenuItem>
        <MenuItem
          isHiddenDisplay={shouldShowKickOption}
          className="uk-dropdown-close"
          role="button"
          onClick={kickClickConfirmModal}
        >
          <IconContainer>
            <KickIcon color="black" />
          </IconContainer>
          <MenuItemText>Kick</MenuItemText>
        </MenuItem>

        {member.ban ? (
          <MenuItem
            isHiddenDisplay={shouldShowUnbanOption}
            className="uk-dropdown-close"
            role="button"
            onClick={onBanClick}
          >
            <UnbanIconContainer>
              <BanIcon />
            </UnbanIconContainer>
            <MenuItemText>Unban</MenuItemText>
          </MenuItem>
        ) : (
          <MenuItem
            // Only owner and hosts can ban non-host and non-owner online and not banned users
            isHiddenDisplay={shouldShowBanOption}
            className="uk-dropdown-close"
            role="button"
            onClick={onBanClick}
          >
            <>
              <IconContainer>
                <BanIcon />
              </IconContainer>
              <MenuItemText>Ban</MenuItemText>
            </>
          </MenuItem>
        )}
        <MenuItem
          // User cannot report self
          isHiddenDisplay={isCurrentUser}
          className="uk-dropdown-close"
          role="button"
          onClick={onReportClick}
        >
          <IconContainer>
            <ReportIcon width="11" />
          </IconContainer>
          <MenuItemText>Report</MenuItemText>
        </MenuItem>
        <MenuItem
          // Users can scroll only to online users
          // Users can't scroll to self, banned and offline ones
          isHiddenDisplay={!member.isOnline || isCurrentUser || member.ban}
          onClick={scrollToUser}
        >
          <IconContainer>
            <LocationIcon width="13" color="black" />
          </IconContainer>
          <MenuItemText>Jump to</MenuItemText>
        </MenuItem>
        <MenuItem
          isHiddenDisplay={
            isCurrentUser ||
            isLoadingFriends ||
            existingRequestsLoading ||
            !showAddFriend ||
            (fromUserProfile && !fromUserProfileShowAddFriend)
          }
          className="uk-dropdown-close"
          role="button"
          onClick={onAddFriendClick}
        >
          <AddFriendIconContainer>
            <AddFriendIcon width="18" height="20" />
          </AddFriendIconContainer>
          <MenuItemText>Add Friend</MenuItemText>
        </MenuItem>
      </EventSourceContext.Provider>
    </MenuContainer>
  );
};

export default RoomMemberMenu;

RoomMemberMenu.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isOnline: PropTypes.bool,
    role: PropTypes.string,
    ban: PropTypes.shape(),
  }).isRequired,
  fromUserProfile: PropTypes.bool,
  fromUserProfileShowAddFriend: PropTypes.bool,
  reload: PropTypes.bool,
  memberUsername: PropTypes.string,
};

RoomMemberMenu.defaultProps = {
  fromUserProfile: false,
  fromUserProfileShowAddFriend: false,
  reload: false,
  memberUsername: '',
};

const MenuContainer = styled.div`
  padding: 4px;
  background: var(--primary-background, white);
  border-radius: 10px;
`;

const MenuItem = styled.div`
  ${hideableMixin()}
  ${moreMenuItemMixin({ themed: true })}
`;

const IconContainer = styled.div`
  width: 22px;
`;

const UnbanIconContainer = styled(IconContainer)`
  opacity: 0.35;
`;

const AddFriendIconContainer = styled(IconContainer)`
  margin-top: -2px;
  svg {
    g {
      stroke-width: 1.5;
    }
  }
`;

const MenuItemText = styled.span`
  display: inline-block;
  margin-left: 10px;
`;
