import React from 'react';

import { Mood } from '../../../definitions/user-profile';
import EditableMood from './EditableMood';
import ViewOnlyMood from './ViewOnlyMood';

type Props = {
  mood?: Mood;
  canEdit?: boolean;
  editUserMood?: boolean;
  editPopoverLocation?: 'top' | 'bottom';
  hasMarquee?: boolean;
  onEnterEditMode?: () => void;
  onExitEditMode?: () => void;
};

const UserMood: React.FC<Props> = ({
  mood,
  canEdit = false,
  editUserMood = false,
  editPopoverLocation = 'top',
  hasMarquee = false,
  onEnterEditMode = () => {},
  onExitEditMode = () => {},
}) => {
  if (canEdit) {
    return (
      <EditableMood
        mood={mood}
        editState={editUserMood}
        editPopoverLocation={editPopoverLocation}
        onEnterEditMode={onEnterEditMode}
        onExitEditMode={onExitEditMode}
        hasMarquee={hasMarquee}
      />
    );
  }

  return <ViewOnlyMood mood={mood} hasMarquee={hasMarquee} />;
};

export default UserMood;
