/* eslint-disable @typescript-eslint/lines-between-class-members */
import { throttle, uniq, uniqBy } from 'lodash/fp';
import { delay } from 'lodash';

import log from '../log';
// import { setVideoVolume } from '../spatial-audio';
// FIXME: Which metrics will we take?
import { trackInForeground as __OriginalTrackMetric } from '../util/analytics-util';
import { ConnectionStatus, StreamType, SubscribeStatus, UserId } from './definitions/index.definitions';
import { JanusPluginWrapperType } from './janus/videoroom';
import {
  FeedId,
  IceState,
  JanusAttachedEvent,
  JanusVideoRoomError,
  JoinAndSubscribeFeedPayload,
  MediaType,
  MidId,
  PublisherFeed,
  StreamEventParams,
  TrackOption,
  UnsubscribePayload,
} from './janus/janus.definitions';
import JanusJS from './janus/janus.definitions.original';
import { JanusWrapperType } from './janus/wrapper';
import { FeedCollection, FeedCollectionDelegateProtocol } from './feed-collection';
import { FeedTrack } from './feed-track';
import { Feed } from './feed';
import { asyncDebounce, shouldExclude } from './util';
import { shouldUseCustomAudioMixer } from '../electron-support/electron-support';
import { trackUserActivationFor } from '../util/user-activation';

// Manages our subscriber peer connections
export interface SubscriberDelegateProtocol {
  subscriberUp: (subsriber: Subscriber) => void;
  subscriberDown: (subsriber: Subscriber) => void;
  subscriberStreamReadyForHTML: (stream: MediaStream, userId: UserId) => void;
  subscriberTrackRemoved: (track: MediaStreamTrack, stream: MediaStream, userId: string) => void;
  updateLocalStreamConstraints: () => void;
  // FIXME: Type here please
  handleDataMessage: (sender: UserId, data: { [key: string]: unknown }) => void;
  subscriberNotRecoverableException: () => void;
}

const FLUSH_SUBSCRIBE_INTERVAL = 1500;
const RESUBSCRIBE_FEED_INTERVAL = 3000;
const CONFIGURE_STREAM_INTERVAL = 3000;

enum JoinStatus {
  Joining = 'joining',
  Joined = 'joined',
  NotJoined = 'not_joined',
}

export interface RTCCodecStats {
  id: string;
  type: 'codec';
  clockRate: number;
  payloadType: number;
  transportId: string;
  mimeType: string;
  timestamp: number;
  sdpFmtpLine?: string;
  channels?: number;
}

export default class Subscriber implements FeedCollectionDelegateProtocol {
  _status: ConnectionStatus = ConnectionStatus.Disconnected;
  excludeSubscriptionIds: string[];
  roomId: string;
  mypvtid: string;
  opaqueId: string;
  subscriberHandle: JanusPluginWrapperType = null;
  feedCollection: FeedCollection;
  codecStats: { [key: string]: RTCCodecStats } = {};

  private joinStatus: JoinStatus = JoinStatus.NotJoined;
  private disposing = false;
  private delegate: SubscriberDelegateProtocol;
  private janus: JanusWrapperType;
  private subscribeQueue: FeedId[] = [];
  private lastSubscribeQueue: FeedId[] = [];

  constructor(
    janusInstance: JanusWrapperType,
    mypvtid: string,
    roomId: string,
    opaqueId: string,
    delegate: SubscriberDelegateProtocol,
    excludeSubscriptionIds?: string[]
  ) {
    this.janus = janusInstance;
    this.mypvtid = mypvtid;
    this.roomId = roomId;
    this.opaqueId = opaqueId;
    this.delegate = delegate;
    this.excludeSubscriptionIds = excludeSubscriptionIds;
    this.feedCollection = new FeedCollection(this, this.excludeSubscriptionIds);
  }

  get status() {
    return this._status;
  }

  set status(status: ConnectionStatus) {
    this._status = status;
  }

  get activeStreams() {
    return this.feedCollection?.activeStreams;
  }

  get peerConnection() {
    return this.subscriberHandle?.webrtcStuff?.pc;
  }

  get peerConnectionStatus() {
    return this.peerConnection?.connectionState;
  }

  get iceState(): IceState {
    return this.subscriberHandle?.webrtcStuff?.pc?.iceConnectionState as IceState;
  }

  private trackMetric(name: string, props?: { [key: string]: unknown }) {
    __OriginalTrackMetric(name, Object.assign(props || {}, this.commonMetricProps || {}));
  }

  get commonMetricProps() {
    return {
      subcriberStatus: this.status,
      joinStatus: this.joinStatus,
      subscriberPc: this.peerConnectionStatus,
      subscriberIce: this.iceState,
      feedCount: this.feedCollection?.feeds?.length,
    };
  }

  getCodec(feedTrack: FeedTrack) {
    if (feedTrack && this.codecStats) {
      const { codecId } = feedTrack.inboundRTPStats;
      const key = Object.keys(this.codecStats).find((k) => this.codecStats[k].id.match(codecId));
      return this.codecStats[key];
    }
    return undefined;
  }

  getVideoCodecMime(feedTrack: FeedTrack) {
    return this.getCodec(feedTrack)?.mimeType;
  }

  getAudioCodecMime(feedTrack: FeedTrack) {
    return this.getCodec(feedTrack)?.mimeType;
  }

  getVideoCodecProfile(feedTrack: FeedTrack) {
    const codec = this.getCodec(feedTrack);
    if (codec?.sdpFmtpLine) {
      return codec.sdpFmtpLine.substring(codec.id.length - 6, codec.id.length);
    }
    return undefined;
  }

  logStreams() {
    const actives = this.activeStreams;
    const each = JSON.stringify(Object.values(actives).map((s) => s.getTracks().length));
    log.log('Subscriber: Active Streams:', Object.values(actives).length, each);
  }

  async dispose() {
    if (this.disposing || !this.subscriberHandle) {
      return;
    }

    log.log('Subscriber: Dispose');
    this.disposing = true;
    this.subscribeQueue = [];
    this.lastSubscribeQueue = [];

    try {
      await this.subscriberHandle?.send({ message: { request: 'leave' } });
      if (this.subscriberHandle && this.janus.isConnected()) {
        this.subscriberHandle?.hangup(true);
      }
    } catch (error) {
      log.warn('Subscriber: Error on dispose: ', error);
    } finally {
      this.status = ConnectionStatus.Disconnected;
      this.disposing = false;
    }
  }

  async unsubscribeFromFeedId(feedId: string, feedMidId?: MidId, removeFeed?: boolean) {
    if (!this.feedCollection) {
      log.debug(
        `Subscriber: No FeedCollection when attempting to unsubscribe from Feed ${feedId} mid: ${feedMidId || 'all'}`
      );
      return;
    }
    const feed = this.feedCollection.getFeedById(feedId);
    log.log(`Subscriber: Unsubscribing from Feed ${feedId} mid: ${feedMidId || 'all'}`);
    if (removeFeed) {
      this.feedCollection.deleteFeedId(feedId);
    }
    if (!feed) {
      log.debug(`Subscriber: Already unsubscribed from Feed Id ${feedId}`);
      return;
    }
    const unsubscribe: UnsubscribePayload = {
      request: 'unsubscribe',
      streams: [{ feed: feedId }],
    };
    if (feedMidId) {
      unsubscribe.streams[0].mid = feedMidId;
      const ft = feed.feedTracks.find((feedTrack) => feedTrack.feedMidId === feedMidId);
      if (ft) ft.status = SubscribeStatus.Idle;
    } else {
      feed.feedTracks.forEach((feedTrack) => {
        feedTrack.status = SubscribeStatus.Idle;
      });
    }
    await this.subscriberHandle?.send({ message: unsubscribe });
  }

  async subscribeToPublishers(feedList: PublisherFeed[]) {
    log.debug(`Subscriber: subscribeToPublishers ${JSON.stringify(feedList, null, 2)}`);
    const sanitizedFeedList: FeedId[] = [];
    feedList.forEach((pub) => {
      (pub.streams || []).forEach((stream) => {
        if (stream.disabled) {
          this.feedCollection.teardownFeedTrack(pub.id, stream.mid);
        } else {
          this.feedCollection.updateWithPublisherFeedParams(pub);
          const feedTrack = this.feedCollection.getFeedTrackByFeedIdAndFeedMidId(stream.type, pub.id, stream.mid);
          if (
            ![SubscribeStatus.Subscribed, SubscribeStatus.Off, SubscribeStatus.Subscribing].includes(feedTrack?.status)
          ) {
            sanitizedFeedList.push(pub.id);
          }
        }
      });
    });

    const list = uniq(sanitizedFeedList);

    log.debug(
      `Subscriber: subscribeToPublishers status=${this.status} joinStatus=${this.joinStatus} effective ${JSON.stringify(
        list,
        null,
        2
      )}`
    );

    if (list.length === 0) return;

    if (![ConnectionStatus.Connecting, ConnectionStatus.Connected].includes(this.status)) {
      await this.setupSubscriberHandle();
    }

    list.forEach((feedId) => this.subscribeQueue.push(feedId));

    await this.flushSubscribeQueue();
  }

  flushSubscribeQueue = asyncDebounce(
    async (attempts = 0) => {
      log.debug('Subscriber: start flushSubscribeQueue');

      if (this.subscribeQueue.length === 0) return;

      let list = [...this.subscribeQueue];
      if (list.length !== this.subscribeQueue.length) {
        this.trackMetric('SFU Subscriber Queue Mismatch', {
          listLength: list.length,
          queueLength: this.subscribeQueue.length,
        });
      }
      this.subscribeQueue = [];
      this.lastSubscribeQueue = list;
      list = uniq(list);

      log.debug(
        `Subscriber: Flushing subscribe queue join status: ${this.joinStatus}, list: ${list.length}:\n`,
        JSON.stringify(list, null, 2)
      );

      if (this.joinStatus === JoinStatus.NotJoined) {
        await this.joinAndSubscribe(list);
      } else if (this.joinStatus === JoinStatus.Joined) {
        await this.subscribeToFeedIds(list);
      } else if (this.joinStatus === JoinStatus.Joining) {
        this.trackMetric('SFU Subscriber Attempt While Joining', {
          status: this.status,
          joinStatus: this.joinStatus,
          listLength: list.length,
          attempts,
        });

        if (attempts > 5) {
          this.joinStatus = JoinStatus.NotJoined;
          attempts = 0;
        }
        this.subscribeQueue = this.subscribeQueue.concat(list);
        delay(this.flushSubscribeQueue.bind(this), FLUSH_SUBSCRIBE_INTERVAL + 1, attempts + 1);
      }
    },
    FLUSH_SUBSCRIBE_INTERVAL,
    { trailing: true }
  );

  private async joinAndSubscribe(feedIds: FeedId[], options = { forceReconnect: false }) {
    if (this.disposing || !this.feedCollection) {
      log.debug(
        `Subscriber: will not joinAndSubscribe disposing: ${this.disposing}, feed collection: ${!!this.feedCollection}`
      );
      return;
    }

    this.joinStatus = JoinStatus.Joining;

    this.trackMetric('SFU Subscriber Attempt to Join');
    const feeds = feedIds.map((id) => this.feedCollection.feeds.find((f) => f.id === id));

    if (this.status !== ConnectionStatus.Connected) {
      log.error('Cannot joinAndSubscribe if the room is not connected: ', this.status);
      this.trackMetric('SFU Subscriber Attempt to Join Cancelled', {
        reason: 'Not Connected',
        joinStatus: this.joinStatus,
        forceReconnect: options.forceReconnect,
      });
      try {
        if (options.forceReconnect) {
          if (this.subscriberHandle && !this.subscriberHandle.detached) {
            this.subscriberHandle.detach();
          }
          delay(async () => {
            await this.setupSubscriberHandle();
            await this.joinAndSubscribe(feedIds);
          }, 1000);
        }
      } catch (error) {
        log.error('Subscriber: Error forcing reconnect');
        this.trackMetric('SFU Subscriber Error Forcing Reconnect', {
          status: this.status,
          joinStatus: this.joinStatus,
          disposing: this.disposing,
        });
      }
      return;
    }

    const streams = this.subscriberPayloadFromFeedList(feeds);
    if (streams.length === 0) {
      log.warn('Subscriber: There are no stream to joinAndSubscribe after filtering feed list');
      if (this.joinStatus === JoinStatus.Joining) this.joinStatus = JoinStatus.NotJoined;
      return;
    }

    log.debug(`Subscriber: Join and Subscribe to ${streams.length} Streams`);

    const subscribe = {
      request: 'join',
      room: this.roomId,
      ptype: 'subscriber',
      streams,
      private_id: this.mypvtid,
    };

    await this.subscriberHandle.send({ message: subscribe });
  }

  private async subscribeToFeedListPayload(feedListPayload: JoinAndSubscribeFeedPayload[]) {
    if (feedListPayload && feedListPayload.length > 0) {
      // FIXME: This is ok just in case, but fix how feedListPayload is processed!!!
      feedListPayload = uniqBy((entry: JoinAndSubscribeFeedPayload) => [entry.feed, entry.mid].join(), feedListPayload);

      log.debug(
        `Subscriber: Requesting additional ${feedListPayload.length} track subscriptions\n`,
        JSON.stringify(feedListPayload, null, 2)
      );
      try {
        await this.subscriberHandle.send({
          message: {
            request: 'subscribe',
            streams: feedListPayload,
          },
        });
      } catch (err) {
        log.error('Subscriber: Error trying to subscribe on existing subscriber handle', err);
        // FIXME: Do something?
      }
    }
  }

  // FIXME
  // Ensure the following operations are "awaited" when an event with the used
  // transaction id has arrived, timeout after 1m? if not response.
  private async subscribeToFeedId(feedId: FeedId, mid?: MidId) {
    if (!this.feedCollection) {
      log.error(`Subscriber: Trying to subscribe to feed ${feedId} before we have a feedCollection`);
      return;
    }
    const feed = this.feedCollection.feeds.find((f) => f.id === feedId);
    const feedListPayload = this.subscriberPayloadFromFeedList([feed], [mid]);
    await this.subscribeToFeedListPayload(feedListPayload);
  }

  private async subscribeToFeedIds(feedIds: FeedId[]) {
    const feedList = feedIds.map((id) => this.feedCollection.feeds.find((f) => f.id === id));
    const feedListPayload = this.subscriberPayloadFromFeedList(feedList);
    await this.subscribeToFeedListPayload(feedListPayload);
  }

  private onWebrtcState(on: boolean) {
    this.status = on ? ConnectionStatus.Connected : ConnectionStatus.Disconnected;
    log.debug(`Subscriber: WebRTC PeerConnection feed is ${on ? 'up' : 'down'} now`);
    if (on) {
      this.delegate.subscriberUp(this);
      this.trackMetric('SFU Subscriber PC is up');
    } else {
      this.delegate.subscriberDown(this);
      this.trackMetric('SFU Subscriber PC is down');
    }
  }

  private onIceStateChange(state: IceState) {
    log.debug(`Subscriber: ICE connection state ${state}`);
    if ([IceState.Failed, IceState.Disconnected].includes(state)) {
      log.warn(`Subscriber: Ice State went wrong: ${state}`);
      this.trackMetric('SFU Subscriber ICE State Failed', { current_ice: state });
      if (this.subscriberHandle && !this.subscriberHandle.detached && state === IceState.Disconnected) {
        delay(this.restartIceConnection.bind(this), 3000);
      } else if (!this.disposing && this.feedCollection?.feeds) {
        this.joinAndSubscribe(
          this.feedCollection.feeds.map((f) => f.id),
          { forceReconnect: true }
        );
      }
    }
  }

  private restartIceConnection() {
    if (this.disposing || ![IceState.Disconnected, IceState.Failed].includes(this.iceState)) {
      log.warn(
        `Subscriber: restartIceConnection canceled, disposing: ${this.disposing} current state: ${this.iceState}`
      );
      return;
    }
    log.warn('Subscriber: Restarting ICE connection with status: ', this.iceState);
    this.trackMetric('SFU Subscriber Attempt to ICE Restart');
    try {
      this.subscriberHandle.send({
        message: {
          request: 'configure',
          restart: true,
        },
      });
    } catch (err) {
      log.error('Subscriber: Ice Restart attempt failed: ', err);
      this.trackMetric('SFU Subscriber ICE Restart Failed');
    }
  }

  eventMediaTrackProps(event: Event) {
    const transceiver = this.peerConnection?.getTransceivers().find((t) => t.receiver.track === event.target);
    const mid = transceiver?.mid ? transceiver.mid : (event.target as MediaStreamTrack).id.replace('janus', '');
    const feedTrack = this.feedCollection?.getFeedTrackbyUniqueMid(mid);
    const feed = this.feedCollection?.feeds?.find((f) => feedTrack?.feedId && feedTrack.feedId === f.id);
    return {
      mid,
      t: event.target as MediaStreamTrack,
      feedTrack,
      feed,
    };
  }

  private assignMediaStreamTrack(track: MediaStreamTrack, feedTrack: FeedTrack) {
    trackUserActivationFor('assignMediaStreamTrack');

    log.debug(
      `Subscriber: assignMediaStreamTrack ${track.kind} ${track.id} feedTrack unique mid: ${feedTrack.uniqueMidId}, feed: ${feedTrack.feedId}`
    );
    const feed = this.feedCollection.feeds.find((f) => f.id === feedTrack?.feedId);
    if (!feed) {
      log.warn(
        `Subscriber: Cannot Assign feed track, feed: ${feedTrack.feedId}, mid: ${feedTrack.uniqueMidId}, feed mid ${feedTrack.feedMidId}`
      );
      return;
    }
    if (feedTrack.track !== track) {
      if (feedTrack.track) {
        log.debug(`Subscriber: assignMediaStreamTrack removing old track ${feedTrack.track.id}`);
        this.unassignMediaStreamTrack(feedTrack, false);
      }
      log.debug(`Subscriber: assignMediaStreamTrack adding track ${track.id}`);
      feedTrack.track = track;
      if (feedTrack.status !== SubscribeStatus.Off) feedTrack.status = SubscribeStatus.Subscribed;
    }

    if ((feedTrack && feedTrack?.disabled) || !feedTrack?.active) {
      log.warn(`Subscriber: Adding disabled or inactive track to MediaStream! ${JSON.stringify(feedTrack)}`);
      this.trackMetric('SFU Subscriber Adding Disabled/Inactive Track', {
        active: feedTrack?.active,
        disabled: feedTrack?.disabled,
      });
    }

    feed.feedTracks
      .filter((ft) => ft.status === SubscribeStatus.Off && ft.track)
      .forEach((ft) => {
        feed.stream.removeTrack(ft.track);
      });

    if (!feed.stream.getTrackById(track.id) && feedTrack.status !== SubscribeStatus.Off) {
      // feed.stream
      //  .getTracks()
      //  .filter((t) => t.kind === track.kind)
      //  .forEach((t) => feed.stream.removeTrack(t));
      const trackQty: { [key: string]: number } = {
        audio: feed.stream.getTracks().filter((t) => t.kind === 'audio').length,
        video: feed.stream.getTracks().filter((t) => t.kind === 'video').length,
      };
      feed.stream.addTrack(track);
      if (!track.enabled) {
        this.trackMetric('SFU Subscriber Assign Not Enabled Media Track', { trackQty, media: track.kind });
        if (trackQty[track.kind] === 0) {
          track.enabled = true;
        }
      }
      this.streamReadyForHTML(feed.stream, feed.userId);
    }
    if (track.kind === 'video') {
      this.delegate.updateLocalStreamConstraints();
    }

    if (feed?.display.match(/screen/)) {
      const videoReceiver = this.peerConnection
        .getReceivers()
        .find((r) => r.track.id === track.id) as RTCRtpReceiver & {
        playoutDelayHint: number;
        jitterBufferDelayHint: number;
      };
      if (videoReceiver) {
        videoReceiver.playoutDelayHint = 1;
        // videoReceiver.jitterBufferDelayHint
        // Is an alternative way to do it but it will be context dependant and more risky IMO. (Alvaro)
      }
    }
  }

  private unassignMediaStreamTrack(feedTrack: FeedTrack, notify = true) {
    const feed = this.feedCollection.feeds.find((f) => f.id === feedTrack?.feedId);
    if (!feed && feedTrack?.track) {
      log.warn(
        `Subscriber: Cannot Un-Assign feed track id: ${feedTrack.track.id}, feed: ${feedTrack.feedId}, mid: ${feedTrack.uniqueMidId}, feed mid ${feedTrack.feedMidId}`
      );
      return;
    }
    if (feedTrack?.track) {
      feed.stream.removeTrack(feedTrack.track);
    }
    if (feedTrack?.feedMidId) {
      this.unsubscribeFromFeedId(feedTrack.feedId, feedTrack.feedMidId, false);
    }
    if (notify) this.streamReadyForHTML(feed.stream, feed.userId);
  }

  onremotetrack(track: MediaStreamTrack, mid: string, on: boolean) {
    const feedTrack = this.feedCollection.getFeedTrackbyUniqueMid(mid);

    log.debug(
      `Subscriber: onremotetrack ${on ? 'on' : 'off'} mid: ${mid}, type: ${track?.kind}, feedId: ${feedTrack?.feedId}`
    );

    if (on && track.readyState === 'ended') {
      // Hack: Due to a Mac bug, this seems to happen occasionally. Re-request the track.
      // See: https://bugs.chromium.org/p/chromium/issues/detail?id=1187576
      this.trackMetric('SFU Adding Track Warning', { status: 'ended' });
      log.error(`Subscriber: Trying to add ENDED ${track.kind} track`, track);
      if (feedTrack) this.reSubscribeFeed(feedTrack.feedId, feedTrack.feedMidId);
    } else if (on) {
      if (feedTrack) {
        this.assignMediaStreamTrack(track, feedTrack);
      }

      // Custom audio mixer expects tracks to remain disabled in the stream we're sending to the <video> tag
      if (track.kind !== 'audio' || !shouldUseCustomAudioMixer()) {
        track.enabled = true;
      }

      // Defining this callback event will give us control on the rest by janus.js lib.
      track.onended = this.trackOnEnded.bind(this);

      track.onunmute = this.trackOnUnMute.bind(this);

      track.onmute = this.trackOnMute.bind(this);
    }
  }

  trackOnEnded(event: Event) {
    const { feed, feedTrack, t } = this.eventMediaTrackProps(event);
    log.debug(`Subscriber: Track Ended kind: ${t.kind} id: ${t.id}`);

    log.warn(
      `Subscriber: On ENDED track ${feedTrack?.type},feed id: ${feedTrack?.feedId} feed mid: ${feedTrack?.feedMidId} mid: ${feedTrack?.uniqueMidId} `
    );

    if (feedTrack && feedTrack.status !== SubscribeStatus.Off) feedTrack.status = SubscribeStatus.Idle;
    if (feed) {
      this.streamTrackRemoved(t, feed.stream, feed.userId);
    }
    this.delegate.updateLocalStreamConstraints();
  }

  trackOnUnMute(event: Event, attempts = 0) {
    const { t, feed, feedTrack, mid } = this.eventMediaTrackProps(event);

    // Note: This condition does not seem to trigger - but leaving in here to continue to monitor
    // custom audio mixer expects tracks to be disabled in the stream we're sending to the <video> tag
    if (!t.enabled && !shouldUseCustomAudioMixer()) {
      log.warn('TRACK.onunmute: Discarding event because track not enabled');
      this.trackMetric('SFU Unmute Track Warning', { status: 'disabled', feedCollection: !!this.feedCollection });
      return;
    }
    if (t.readyState === 'ended') {
      log.warn('TRACK.onunmute: Discarding event because track readyState is ended');
      this.trackMetric('SFU Unmute Track Warning', { status: 'ended', feedCollection: !!this.feedCollection });
      return;
    }
    if (t.muted) {
      log.warn('TRACK.onunmute: track is actually muted');
    }

    // TODO I don't think this does anything, see safety check above that aborts if track is not enabled
    if (!shouldUseCustomAudioMixer()) {
      t.enabled = true;
    }

    if (feedTrack) {
      if (feedTrack.status !== SubscribeStatus.Off) feedTrack.status = SubscribeStatus.Subscribed;
      feedTrack.track = t;
    } else if (!feedTrack && attempts === 0) {
      log.warn(`TRACK.onunmute: without FeedTrack! mid ${mid} track id: ${t.id} kind: ${t.kind}`);
    }

    if (!feedTrack && attempts < 3) {
      delay(this.trackOnUnMute.bind(this), 1500, event, attempts + 1);
    } else if (!feedTrack) {
      log.error(`TRACK.onunmute: without FeedTrack! mid ${mid} track id: ${t.id} kind: ${t.kind} too many attempts!`);
      this.trackMetric('SFU Unmute Track Warning', {
        status: 'too many attempts',
        feedCollection: !!this.feedCollection,
      });
    }

    if (feed) {
      const existentTrack = feed.stream.getTracks().find((tt) => tt.kind === t.kind);
      if (existentTrack && existentTrack.id === t.id) {
        // this.streamReadyForHTML(feed.stream, feed.userId);
      } else if (existentTrack && existentTrack.id !== t.id) {
        feed.stream.removeTrack(existentTrack);
        this.assignMediaStreamTrack(t, feedTrack);
      } else if (!existentTrack) {
        this.assignMediaStreamTrack(t, feedTrack);
      }
    } else {
      this.trackMetric('SFU OnUnmute Track Without Feed');
      log.warn(`TRACK.onunmute: without Feed! mid ${mid} track id: ${t.id}`);
    }
  }

  trackOnMute(event: Event) {
    const { t, feed } = this.eventMediaTrackProps(event);
    if (feed && t?.muted) {
      // this.streamTrackRemoved(t, feed.stream, feed.userId);
      // feed.stream.removeTrack(t);
    }
  }

  reSubscribeFeed = throttle(RESUBSCRIBE_FEED_INTERVAL, (feedId: FeedId, midId?: MidId) => {
    log.warn('Subscriber: Re-Subscribing feed: ', feedId, ', mid: ', midId);
    this.__InternalReSubscribeFeed(feedId, midId);
  });

  async __InternalReSubscribeFeed(feedId: FeedId, midId?: MidId) {
    let fullSubscribe = false;
    const feed = this.feedCollection.getFeedById(feedId);

    const activeParticipant = await this.getParticipantActive(feedId);
    if (!activeParticipant) {
      log.warn(`Subscriber: reSubscribeFeed feed ${feedId} not longer active ...skipping.`);
      return;
    }

    if (!feed) {
      log.warn(`Subscriber: reSubscribeFeed We don't have information of feed ${feedId} but exist ...subscribing`);
      this.feedCollection.updateWithPublisherFeedParams(activeParticipant);
      await this.subscribeToFeedIds([feedId]);
    } else if (feed.subscribed) {
      const feedTracks = midId ? feed.feedTracks.filter((ft) => ft.feedMidId === midId) : feed.feedTracks;
      feedTracks.forEach((ft) => {
        if (ft.status === SubscribeStatus.Off) {
          log.log(`Subscriber: Not Re-configuring feed: ${feed.id} mid: ${midId} status is voluntary off`);
          return;
        }

        if (ft.uniqueMidId) {
          log.log(`Subscriber: Re-configuring feed: ${feed.id} unique mid: ${ft.uniqueMidId}`);
          this.configureStream(ft, true);
        } else {
          fullSubscribe = true;
        }
      });
    } else {
      fullSubscribe = true;
    }

    if (fullSubscribe) {
      log.warn(`Subscriber: Full Subscribe Re-subscribing feed: ${feed.id}`);
      try {
        await this.unsubscribeFromFeedId(feed.id);
        delay(this.subscribeToFeedId.bind(this), 2000, feed.id);
      } catch (error) {
        log.error(`Subscriber: Could not fullSubscribe feed ${feed?.id}`);
      }
    }
  }

  private async getParticipantActive(feedId: FeedId): Promise<{
    id: FeedId;
    display: UserId;
    publisher: boolean;
    talking: boolean;
  }> {
    const message = { request: 'listparticipants', room: this.roomId };
    const response = await this.subscriberHandle.send({ message });
    return (response.participants || []).find(
      (part: { id: string; publisher: boolean }) => part.id === feedId && part.publisher
    );
  }

  ondata(json: string, feedId: FeedId) {
    const { data } = JSON.parse(json);
    const sender = this.feedCollection?.feedIdDisplayIdDictionary[feedId];
    if (sender) this.delegate.handleDataMessage(sender, data);
  }

  onMessage(msg: JanusJS.Message, jsep: JanusJS.JSEP) {
    try {
      const event = msg.videoroom;
      log.debug(`Subscriber Message: ${event}, ${msg.streams ? `streams: ${msg.streams.length}` : ''}`);
      try {
        if (event) {
          this.dispatchVideoRoomEvent(event, msg);
        }
      } catch (e) {
        if (jsep) {
          log.error('Subscriber: Error processing message, but moving on with JSEP: ', msg, e, '\n', jsep);
        } else {
          throw e;
        }
      }
      if (jsep) {
        this.onJSEP(jsep);
      }
    } catch (e) {
      log.error('Subscriber: Error processing message: ', msg, e);
    }
  }

  dispatchVideoRoomEvent(event: string, msg: JanusJS.Message | JanusJS.Message) {
    switch (event) {
      case 'attached':
        this.joinStatus = JoinStatus.Joined;
        this.status = ConnectionStatus.Connected;
        (msg as JanusAttachedEvent).streams.forEach((stream) => {
          this.feedCollection.updateWithStreamParams(stream);
        });
        break;
      case 'slow_link':
        log.warn('Subscriber: Subscribe-side slow link message', Date.now());
        break;
      case 'event':
        this.dispatchRoomEventMessage(msg);
        break;
      case 'updated':
        msg.streams?.forEach((streamParams: StreamEventParams) => {
          this.feedCollection.updateWithStreamParams(streamParams);
          const feedTrack = this.feedCollection.getFeedTrackByFeedIdAndFeedMidId(
            streamParams?.type,
            streamParams?.feed_id,
            streamParams?.feed_mid
          );
          if (feedTrack?.active && !feedTrack?.disabled) {
            const transceiver = this.peerConnection
              ?.getTransceivers()
              .find((t) => t.receiver.track.id === `janus${feedTrack?.uniqueMidId}`);
            const track = transceiver?.receiver?.track;
            if (track) this.assignMediaStreamTrack(track, feedTrack);
          } else if (feedTrack?.disabled) {
            this.unassignMediaStreamTrack(feedTrack);
          }
        });
        break;
      case 'updating':
        break;
      default:
        log.warn('Subscriber: Unhandled event: ', event);
        break;
    }
  }

  dispatchRoomEventMessage(msg: JanusJS.Message) {
    if (msg.error) {
      let errorCodeMsg = 'unknown';
      try {
        errorCodeMsg =
          Object.keys(JanusVideoRoomError)[
            Object.values(JanusVideoRoomError).findIndex((v) => v.toString() === msg.error_code.toString())
          ];
      } catch (error) {
        log.warn('Subscriber: Cannnot parse Error Messsage from Janus');
      }
      this.trackMetric('SFU Subscriber Error', { error_code: msg.error_code, error: errorCodeMsg });
      log.error(`Subscriber: Handle Error: ${msg.error}`);
      if (msg.error_code === JanusVideoRoomError.NO_SUCH_FEED) {
        try {
          log.error('Subscriber: NO_SUCH_FEED msg: ', msg);
          const { feedId } = msg.error.match(/No such feed\s\((?<feedId>.*)\)/).groups;
          this.feedCollection.deleteFeedId(feedId);
        } catch (e) {
          log.error(`Subscriber: Error parsing NO_SUCH_FEED error: ${e}, msg: ${msg}`);
        } finally {
          log.warn('Subscriber: Attempt to Recover NO_SUCH_FEED');
          if (this.joinStatus === JoinStatus.Joining) {
            this.joinStatus = JoinStatus.NotJoined;
          }
          this.subscribeQueue = (this.lastSubscribeQueue || []).concat(this.subscribeQueue);
          this.flushSubscribeQueue();
        }
      }
    } else if (msg.started) {
      log.debug('Subscriber: started', msg.room);
    } else if (msg.configured) {
      // TODO:
      // FIXME:
      // This is an async response event that requires to match caller with transaction id.
      // Unfortunately Janus doesn't provide the transaction id neither when calling or getting
      // this response event, patch library is required to make this work properly and avoid
      // race conditions.
    } else {
      log.warn('Subscriber: Unhandled event msg: ', msg);
    }
  }

  onJSEP(jsep: JanusJS.JSEP) {
    log.debug('Subscriber: Handling SDP as well, creating answer...');

    const tracks: TrackOption[] = [
      { type: 'data', recv: true },
      { type: MediaType.Video, recv: true },
      { type: MediaType.Audio, recv: true },
    ];

    this.subscriberHandle.createAnswer({
      jsep,
      tracks,
      success: this.onAnswerJSEP.bind(this),
      error: this.onAnswerError.bind(this),
    });
  }

  onAnswerError(error: string) {
    log.error(`Subscriber: WebRTC error on subscriber handle feed: ${error}`);
    this.trackMetric('SFU Subscriber onJSEP Error', { error });
  }

  onAnswerJSEP(answerJsep: JanusJS.JSEP) {
    log.debug('Subscriber: Got SDP!');
    const body = { request: 'start', room: this.roomId };
    try {
      this.subscriberHandle.send({ message: body, jsep: answerJsep });
    } catch (err) {
      log.error('subscriberHandle: Error sending answer', err);
    }
  }

  // FIXME: Should we more than once?
  async setupSubscriberHandle() {
    this.status = ConnectionStatus.Connecting;
    // A new feed has been published, create a new plugin handle and attach to it as a subscriber
    const pluginOptions = {
      plugin: 'janus.plugin.videoroom',
      opaqueId: `subscriber-${this.opaqueId}`,
      onmessage: this.onMessage.bind(this),
      mediaState(type: MediaType, receiving: boolean, mid: number) {
        log.error(`Subscriber: Media state for ${type}, receiving: ${receiving}, mid: ${mid}`);
      },
      webrtcState: this.onWebrtcState.bind(this),
      iceState: this.onIceStateChange.bind(this),
      onlocaltrack() {
        // Nothing for subscribers
      },
      onremotetrack: this.onremotetrack.bind(this),
      ondataopen: this.onDataOpen.bind(this),
      ondata: this.ondata.bind(this),
      oncleanup: this.dispose.bind(this),
      ondetached() {
        log.debug(' ::: Got a detached notification (all subscriptions) :::');
      },
      slowLink(uplink: boolean, lost: number, mid: string) {
        log.log(`Subscriber: Slow ${uplink ? 'Janus to Us' : 'Us to Janus'} link detected lost: ${lost} mid: ${mid}`);
      },
    };

    try {
      this.subscriberHandle = await this.janus.attach(pluginOptions);
      this.status = ConnectionStatus.Connected;
    } catch (error) {
      this.joinStatus = JoinStatus.NotJoined;
      this.status = ConnectionStatus.Disconnected;
      log.error('Subscriber:  -- Error attaching plugin...', error);
      // FIXME: Notify? Delegate? Restart?
    }
  }

  private onDataOpen(...arg: unknown[]) {
    if (!this.feedCollection) {
      log.warn('Subscriber: onDataOpen called before we have a feedCollection');
      return;
    }
    const feedIds = arg.length ? arg : [arg];
    feedIds.forEach((feedId) => {
      const feedTrack = this.feedCollection.feedTracks.find(
        (ft) => ft.feedId === feedId && ft.type === StreamType.Data
      );
      if (feedTrack) {
        feedTrack.status = SubscribeStatus.Subscribed;
        log.debug('Subscriber: DataChannel subscribed to feed', feedId);
        this.trackMetric('SFU Subscriber DataChannel Subscribed');
      }
    });
  }

  private subscriberPayloadFromFeedList(feeds: Feed[] = [], includeMids: MidId[] = []): JoinAndSubscribeFeedPayload[] {
    const subscription: JoinAndSubscribeFeedPayload[] = [];
    feeds.forEach((feed, i) => {
      if (!feed || shouldExclude(feed, this.excludeSubscriptionIds)) {
        log.warn('Subscriber: skipping excluded feed: ', feed?.id);
        return;
      }

      feed.feedTracks.forEach((feedTrack: FeedTrack) => {
        if (includeMids[i] && includeMids[i] !== feedTrack.feedMidId) return;

        if (feedTrack.disabled) {
          log.debug(
            `Subscriber: skipping FeedTrack disabled feed id ${feedTrack.feedId} feed mid: ${feedTrack.feedMidId} unique mid: ${feedTrack.uniqueMidId}`
          );
          return;
        }

        if (feedTrack.status === SubscribeStatus.Off) {
          log.warn('Subscriber: skipping FeedTrack subscription with status Voluntary Off.', feedTrack);
          return;
        }

        if (feedTrack.status === SubscribeStatus.Subscribed) {
          log.debug(
            `Subscriber: skipping FeedTrack subscription feed: ${feed.id} feed mid: ${feedTrack.feedMidId}, status not .Idle`
          );
          /*
            `\nFeed: ${JSON.stringify(feed, null, 2)}`,
            `\nFeedTrack: ${JSON.stringify(feedTrack, null, 2)}`,
            `\nAll Feed FeedTracks: ${JSON.stringify(feed.feedTracks, null, 2)}`
          );
          */
          return;
        }

        subscription.push({
          feed: feedTrack.feedId,
          mid: feedTrack.feedMidId,
        });
      });
    });
    return subscription;
  }

  async subscribeToVideo(userId: UserId) {
    const feedTrack = this.feedCollection.getVideoFeedTrackForUserId(userId);
    if (feedTrack) {
      if (feedTrack.status === SubscribeStatus.Off) feedTrack.status = SubscribeStatus.Idle;
      await this.configureStream(feedTrack, true);
    }
  }

  async unsubscribeFromVideo(userId: string) {
    const feedTrack = this.feedCollection.getVideoFeedTrackForUserId(userId);
    if (feedTrack) {
      feedTrack.status = SubscribeStatus.Off;
      await this.configureStream(feedTrack, false, true);
      const stream = this.feedCollection.getFeedByUserId(userId)?.stream;
      if (stream && feedTrack.track) {
        stream.removeTrack(feedTrack.track);
      }
    }
  }

  configureStream = throttle(CONFIGURE_STREAM_INTERVAL, (feedTrack: FeedTrack, send: boolean, force = false) =>
    this.__InternalConfigureStream(feedTrack, send, force)
  );

  private async __InternalConfigureStream(
    feedTrack: FeedTrack,
    send: boolean,
    force = false
  ): Promise<FeedTrack | undefined> {
    const feed = this.feedCollection.getFeedById(feedTrack.feedId);
    const { id, userId } = feed;
    const { type } = feedTrack;
    log.debug(`Subscriber: configureStream send:${send} ${type} feed: ${feedTrack.feedId} force:${force}`);

    if (!feed) {
      log.warn(`Subscriber: Cannot configureStream to send:${send} feed: ${feedTrack.feedId} missing Feed`);
      return;
    }
    if (feed.status !== SubscribeStatus.Subscribed) {
      log.warn(`Subscriber: Cannot configureStream send:${send} feed ${id} status not .Subscribed`);
      return;
    }

    if (!feedTrack.uniqueMidId) {
      log.warn('Subscriber: Cannot configureStream without a FeedTrack unique mid');
      return;
    }

    if (!feedTrack || !feedTrack.uniqueMidId) {
      log.warn(
        `Subscriber: Cannot configureStream send:${send} user id ${userId} missing FeedTrack or uniqueMidId ${type}`,
        feedTrack
      );
      return;
    }

    if (!force && feedTrack.status !== SubscribeStatus.Idle) {
      // If we are not forcing still migtht be a few exceptions
      let configure = false;
      const mediaFeedTracksQty = feed.feedTracks.filter((ft) => ft.type !== StreamType.Data).length;

      if (feedTrack.track && feedTrack.track.muted) {
        configure = true;
        // FIXME: Revisit why this
        // feed.stream.removeTrack(feedTrack.track);
      } else if (feed.stream.getTracks().length !== mediaFeedTracksQty) {
        configure = true;
      } else {
        this.streamReadyForHTML(feed.stream, feed.userId);
      }

      if (!configure) {
        log.warn(`Subscriber: Cannot configureStream send:${send} user id ${userId} ${type} already configured`);
        return;
      }

      feedTrack.status = SubscribeStatus.Idle;
    }

    await this.subscriberHandle.send({
      message: {
        request: 'configure',
        send,
        mid: feedTrack.uniqueMidId,
      },
    });

    log.debug(
      `Subscriber: configureStream:OK send:${send} ${type} userId: ${userId} force:${force} feedTrack mid: ${feedTrack.uniqueMidId} status:${feedTrack.status}`
    );

    Promise.resolve(feedTrack);
  }

  //
  // Implementes FeedCollectionDelegateProtocol
  //
  streamReadyForHTML(stream: MediaStream, userId: string) {
    this.delegate.subscriberStreamReadyForHTML(stream, userId);
  }
  streamTrackRemoved(track: MediaStreamTrack, stream: MediaStream, userId: string) {
    this.delegate.subscriberTrackRemoved(track, stream, userId);
  }
}
