import { eventChannel } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { fetchRoomsList } from './api';
import log from '../../../log';

// types
import { RoomServiceResponse } from '../../../definitions/room';

// store
import { setVisitedRooms, setVisitedRoomsLoading, startVisitedRoomsPolling, fetchVisitedRooms } from './actions';
import { selectVisitedRoomsLimit } from './selectors';

const POLLING_INTERVAL_MS = 30000;
const FETCH_ROOMS_AGAIN = 'FETCH_ROOMS_AGAIN';

let pollingStarted = false;

async function getRoomsList(params?: { limit?: number }): Promise<RoomServiceResponse[] | null> {
  try {
    const { boards } = await fetchRoomsList(params);
    return boards.filter((board) => !!board);
  } catch (err) {
    log.error(err);
    return null;
  }
}

const fetchVisitedRoomsInterval = () =>
  eventChannel((emitter) => {
    const id = setInterval(() => {
      emitter(FETCH_ROOMS_AGAIN);
    }, POLLING_INTERVAL_MS);

    return () => {
      clearInterval(id);
    };
  });

function* handleFetchVisitedRooms() {
  yield put(setVisitedRoomsLoading({ loading: true }));

  const limit: ReturnType<typeof selectVisitedRoomsLimit> = yield select(selectVisitedRoomsLimit);
  const visitedRooms: RoomServiceResponse[] | null = yield call(getRoomsList, { limit });
  // if another longpolling iteration returned with error, just ignore it
  if (visitedRooms) {
    yield put(setVisitedRooms({ visitedRooms }));
  }

  yield put(setVisitedRoomsLoading({ loading: false }));
}

function* handleStartVisitedRoomsPolling() {
  if (pollingStarted) {
    return;
  }

  pollingStarted = true;

  yield call(handleFetchVisitedRooms);

  const channel: ReturnType<typeof fetchVisitedRoomsInterval> = yield call(fetchVisitedRoomsInterval);
  yield takeEvery(channel, function* handler() {
    const anotherVisitedRooms: RoomServiceResponse[] | null = yield call(getRoomsList);
    // if another longpolling iteration returned with error, just ignore it (wait for the next longpolling iteration)
    if (anotherVisitedRooms) {
      yield put(setVisitedRooms({ visitedRooms: anotherVisitedRooms }));
    }
  });
}

export default function* visitedRoomsSaga() {
  yield all([
    takeEvery(startVisitedRoomsPolling, handleStartVisitedRoomsPolling),
    takeEvery(fetchVisitedRooms, handleFetchVisitedRooms),
  ]);
}
