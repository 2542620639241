import React, { useContext, useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { openUserProfile } from '../../user-profile/utils';
import { selectUserById } from '../../store/users/selectors';
import UserAvatar from '../../components/UserAvatar';
import UserMood from '../../user-profile/mood';
import { BoardElementControllerContext } from '../../common/contexts';

const MoodElement: React.FC = () => {
  const profileButtonRef = useRef(null);
  const { elementData, containerRef } = useContext(BoardElementControllerContext);
  const { creator: userId, mood, id: elementId } = elementData;
  const userProfile = useSelector((state) => selectUserById(state, userId));

  const onProfileButtonClick = () => {
    if (profileButtonRef.current?.className.includes('open')) {
      profileButtonRef.current.classList.remove('open');
    } else {
      profileButtonRef.current.classList.add('open');

      const { top, left, height, width } = profileButtonRef.current.getBoundingClientRect();

      const onClickOutside = (e: Event) => {
        const isClickedOnAvatar = profileButtonRef.current.contains(e.target);
        if (!isClickedOnAvatar) {
          profileButtonRef.current.classList.remove('open');
          document.removeEventListener('click', onClickOutside);
        }
      };

      document.addEventListener('click', onClickOutside);

      openUserProfile({
        userId,
        topPosition: top + height,
        leftPosition: left + width,
      });
    }
  };

  const [zoom, setZoom] = useState<number>();
  useEffect(() => {
    let widthObserver: ResizeObserver;
    if (containerRef.current) {
      widthObserver = new ResizeObserver((entries) => {
        // matching mobile here, if we need to change, pls check with them to make sure we look as similar as possible cross platform
        setZoom(entries[0].contentRect.width / 160);
      });
      const moodEl = containerRef.current;
      if (moodEl) widthObserver.observe(moodEl);
    }

    return () => {
      if (widthObserver) widthObserver.disconnect();
    };
  }, [elementId, containerRef]);

  return (
    <ElementWrapper zoom={zoom}>
      <Container>
        <AvatarContainer
          id="profile-wrapper"
          ref={profileButtonRef}
          onClick={onProfileButtonClick}
          moodColor={mood.theme?.colors?.primaryBackground}
        >
          <UserAvatar userProfile={userProfile} />
        </AvatarContainer>
        <MoodContainer>
          <UserMood mood={mood} canEdit={false} />
        </MoodContainer>
      </Container>
    </ElementWrapper>
  );
};

export default MoodElement;

const ElementWrapper = styled.div<{ zoom?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  // Note: scaling may not work on older browsers
  ${({ zoom }) =>
    zoom &&
    css`
      zoom: ${zoom};
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AvatarContainer = styled.div<{ moodColor?: string }>`
  height: 93px;
  width: 93px;
  border-radius: 100%;
  border: 4px solid ${({ moodColor }) => moodColor || 'white'};
`;

const MoodContainer = styled.div`
  margin-top: -10px;
`;
