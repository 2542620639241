import { useCallback, useEffect, useState } from 'react';

import log from '../../log';
import { DefaultImageOption, getDefaultBackgrounds } from '../../api/default-images-api';

let backgroundsCache: DefaultImageOption[] = [];

export const useBackgroundsData = () => {
  const [backgrounds, setBackgrounds] = useState<DefaultImageOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const fetchBackgrounds = useCallback(async () => {
    try {
      const response = await getDefaultBackgrounds();
      setBackgrounds(response.defaultBackgrounds);
      backgroundsCache = response.defaultBackgrounds;
      setError('');
    } catch (err) {
      log.error(err);
      setError('Failed to load backgrounds');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (backgroundsCache.length) {
        setBackgrounds(backgroundsCache);
        setLoading(false);
        setError('');
        return;
      }

      await fetchBackgrounds();
    })();
  }, [fetchBackgrounds]);

  return {
    backgrounds,
    loading,
    error,
    fetchBackgrounds,
  };
};
