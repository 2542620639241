import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlipMove from 'react-flip-move';
import styled from 'styled-components';

import { selectCustomKaomojis, selectTrendingKaomojis } from '../store/kaomoji/selectors';
import { addCustomKaomoji } from '../store/kaomoji/actions';
import KaomojiItem from './KaomojiItem';

interface Props {
  onSelect: (params: { content: string; category: string }) => void;
}

const maxKaomojiLength = 200;
const maxKaomojiCount = 50;

const CustomKaomojisTab = ({ onSelect }: Props) => {
  const dispatch = useDispatch();

  const customKaomojis = useSelector(selectCustomKaomojis);
  const trendingKaomojis = useSelector(selectTrendingKaomojis);

  const [inputValue, setInputValue] = useState('');
  const onSaveKaomoji = () => {
    dispatch(addCustomKaomoji({ content: inputValue }));
    setInputValue('');
  };

  const errorMessage = useMemo(() => {
    if (customKaomojis.length >= maxKaomojiCount) return 'You have reached maximum number of kaomojis';
    if (inputValue.length >= maxKaomojiLength) return 'The kaomoji is too long';
    if (customKaomojis.find((k) => k.content === inputValue)) return 'Such kaomoji already exists';
    return null;
  }, [customKaomojis, inputValue]);

  const onSelectCustom = useCallback((content) => onSelect({ content, category: 'my combos' }), [onSelect]);
  const onSelectTrending = useCallback((content) => onSelect({ content, category: 'trending' }), [onSelect]);

  return (
    <>
      <InputArea>
        <Input
          type="text"
          className="themed-input"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
        />
        <SaveButton
          disabled={!!errorMessage || !inputValue}
          type="button"
          className="here-button-regular"
          onClick={onSaveKaomoji}
        >
          Save
        </SaveButton>
      </InputArea>
      {errorMessage ? <Copy>{errorMessage}</Copy> : null}
      {customKaomojis.length ? (
        <>
          <Title>My Combos</Title>
          <FlipMove>
            {customKaomojis.map((k) => (
              <div key={k.content}>
                <KaomojiItem isCustom content={k.content} onSelect={onSelectCustom} />
              </div>
            ))}
          </FlipMove>
        </>
      ) : (
        <Copy>Paste your personal favorite emoji combos here to store them for quick access!</Copy>
      )}
      {trendingKaomojis.length ? (
        <>
          <Title>Trending</Title>
          {trendingKaomojis.map((k) => (
            <KaomojiItem key={k.content} content={k.content} onSelect={onSelectTrending} />
          ))}
        </>
      ) : null}
    </>
  );
};

export default CustomKaomojisTab;

const InputArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 4px;
`;

const Input = styled.input`
  padding: 8px 12px;
  flex-grow: 1;
`;

const SaveButton = styled.button`
  padding: 8px 12px;
  margin-left: 8px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-foreground);
  margin-top: 8px;
`;

const Copy = styled.div`
  font-size: 14px;
  color: var(--primary-foreground);
`;
