import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// utils
import { track } from '../../../util/analytics-util';

// store
import { startProviderAuth, goNextScreen } from '../../store/signing-in/actions.ts';

// components
import { TitleContainer, ScreenTitle, ScreenSubTitle } from '../shared-styled.ts';

// constants
import { AuthTypes } from '../constants.ts';
import {
  CONTINUE_WITH_PHONE_CLICKED,
  CONTINUE_WITH_APPLE_CLICKED,
  CONTINUE_WITH_EMAIL_CLICKED,
  CONTINUE_WITH_GOOGLE_CLICKED,
} from '../../../constants/analytics-events/auth-events.ts';

// icons
import GoogleIcon from '../../../../assets/icons/sign-in-modal/google-icon.svg';
import AppleIcon from '../../../../assets/icons/external/apple.svg';
import EmailIcon from '../../../../assets/icons/sign-in-modal/email-icon.svg';
import MobileIcon from '../../../../assets/icons/sign-in-modal/mobile-icon.svg';

const AuthTypeSelectionScreen = () => {
  const dispatch = useDispatch();

  const signInWithGoogle = () => {
    track(CONTINUE_WITH_GOOGLE_CLICKED);
    dispatch(startProviderAuth({ authType: AuthTypes.GMAIL }));
  };

  const signInWithEmail = () => {
    track(CONTINUE_WITH_EMAIL_CLICKED);
    dispatch(goNextScreen({ screenData: { authType: AuthTypes.EMAIL } }));
  };

  const signInWithApple = () => {
    track(CONTINUE_WITH_APPLE_CLICKED);
    dispatch(startProviderAuth({ authType: AuthTypes.APPLE }));
  };

  const signInWithPhone = () => {
    track(CONTINUE_WITH_PHONE_CLICKED);
    dispatch(goNextScreen({ screenData: { authType: AuthTypes.PHONE } }));
  };

  return (
    <>
      <TitleContainer>
        <ScreenTitle>Welcome to Here</ScreenTitle>
        <ScreenSubTitle>Sign in to get started</ScreenSubTitle>
      </TitleContainer>

      <Buttons>
        <AuthTypeButton bgColor="#4285f4" onClick={signInWithGoogle} data-testid="continue-with-google-button">
          <IconContainer>
            <GoogleIcon />
          </IconContainer>
          <ButtonText>Continue with Google</ButtonText>
        </AuthTypeButton>

        <AuthTypeButton bgColor="#202124" onClick={signInWithApple} data-testid="continue-with-apple-button">
          <IconContainer>
            <AppleIcon />
          </IconContainer>
          <ButtonText>Continue with Apple</ButtonText>
        </AuthTypeButton>

        <AuthTypeButton bgColor="#f6335d" onClick={signInWithEmail} data-testid="continue-with-email-button">
          <IconContainer>
            <EmailIcon />
          </IconContainer>
          <ButtonText>Continue with Email</ButtonText>
        </AuthTypeButton>

        <AuthTypeButton bgColor="#701ce4" onClick={signInWithPhone} data-testid="continue-with-phone-button">
          <IconContainer>
            <MobileIcon />
          </IconContainer>
          <ButtonText>Continue with Phone</ButtonText>
        </AuthTypeButton>
      </Buttons>

      <Separator />
    </>
  );
};

const buttonsContainerWidthPx = 300;

const Buttons = styled.div`
  width: ${buttonsContainerWidthPx}px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
`;

const AuthTypeButton = styled.button`
  position: relative;
  padding: 14px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 27px;
  background-color: ${({ bgColor }) => bgColor};
  border: none;
  border-radius: 10px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.span`
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const Separator = styled.div`
  margin-top: 66px;
  width: ${buttonsContainerWidthPx}px;
  border-top: 1px solid #13131333;
`;

export default AuthTypeSelectionScreen;
