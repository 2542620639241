import { css, keyframes } from 'styled-components';

export const hideableMixin = () => css`
  ${(props) => {
    if (props.isHiddenVisibility) {
      return 'visibility: hidden !important;';
    }

    if (props.isHiddenDisplay) {
      return 'display: none !important;';
    }

    if (props.isHiddenOpacity) {
      return `
        opacity: 0 !important;
        cursor: default !important;
        pointer-events: none !important;
      `;
    }

    return 'opacity: 1;';
  }}
`;

export const unreadCountMixin = ({ unreadsCount }) => css`
  height: 20px;
  width: 24px;
  display: flex;
  ${unreadsCount === 0 && 'visibility: hidden;'}
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Inter;
  font-size: 12px;
  font-weight: bolder;
  background-color: #f6335d;
  border-radius: 20px;
`;

export const ellipsisTextMixin = ({ fullWidth = true } = {}) => css`
  ${fullWidth ? 'width: 100%;' : ''}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ellipsisTwoLinesTextMixin = () => css`
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ellipsisMultiLineTextMixin = ({ lines = 3 }) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${lines || '3'};
  -webkit-box-orient: vertical;
`;

export const noScrollbarMixin = () => css`
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const customScrollbarMixin = ({
  trackColor = '#efefef',
  thumbColor = '#c5c5c5',
  hoverColor = '#c5c5c5',
  borderRadius = '100px',
  width = '10px',
} = {}) => css`
  &::-webkit-scrollbar {
    width: ${width};
    border-radius: ${borderRadius};
  }

  &::-webkit-scrollbar-track {
    background-color: ${trackColor};
    border-radius: ${borderRadius};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${thumbColor};
    border-radius: ${borderRadius};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${hoverColor};
  }
`;

export const buttonMixin = () => css`
  border: none;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Inter';

  text-align: center;

  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 10px;

  cursor: pointer;
`;

export const errorMessageMixin = () => css`
  color: #f6335d;
  font-weight: 400;
  margin-top: 5px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-family: 'Inter';
`;

export const svgColorMixin = (color) => css`
  path,
  circle,
  g,
  rect {
    &[fill]:not([fill='none']) {
      fill: ${color};
    }

    &[stroke] {
      stroke: ${color};
    }
  }
`;

export const moreMenuMixin = () => css`
  border-radius: 10px;
  background: #ffffff;
`;

export const moreMenuItemMixin = ({ themed }) => css`
  color: black;
  font-size: 16px;
  font-weight: bolder;

  padding: 10px 0;
  width: 100%;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f3f3f3;
  }

  ${themed &&
  css`
    ${svgColorMixin('var(--primary-foreground, black)')}
    color: var(--primary-foreground, black);

    &:hover {
      ${svgColorMixin('var(--secondary-foreground, black)')}

      background: var(--secondary-background, #f3f3f3);
      color: var(--secondary-foreground, black);
    }
  `};
`;

export const removeButtonStylingMixin = () => css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const starburstClipPathMixin = () => css`
  clip-path: polygon(
    100% 50%,
    91% 61%,
    93% 75%,
    80% 80%,
    75% 93%,
    61% 91%,
    50% 100%,
    39% 91%,
    25% 93%,
    20% 80%,
    7% 75%,
    9% 61%,
    0% 50%,
    9% 39%,
    7% 25%,
    20% 20%,
    25% 7%,
    39% 9%,
    50% 0%,
    61% 9%,
    75% 7%,
    80% 20%,
    93% 25%,
    91% 39%
  );
`;

export const shimmerAnimation = keyframes`
  from {
    background-position: 150% 150%;
  }

  to {
    background-position: -25% -25%;
  }
`;

const shimmerAnimationMixin = () => css`
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmerAnimation};
  animation-timing-function: linear;
  background-size: 200% 200%;
  background-repeat: no-repeat;
`;

export const whiteShimmerAnimationMixin = () => css`
  ${shimmerAnimationMixin()}

  background-color: rgba(255, 255, 255, 0.1);
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 35%,
    rgba(255, 255, 255, 0.05) 40%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.05) 60%,
    rgba(255, 255, 255, 0) 65%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const grayShimmerAnimationMixin = () => css`
  ${shimmerAnimationMixin()}

  background-color: rgba(0, 0, 0, 0.02);
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 35%,
    rgba(0, 0, 0, 0.05) 40%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0.05) 60%,
    rgba(0, 0, 0, 0) 65%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const desktopOnly = () => css`
  @media (max-width: 576px) {
    display: none;
  }
`;

export const mobileOnly = () => css`
  @media (min-width: 576px) {
    display: none;
  }
`;

export const borderAnimation = keyframes`
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
`;
export const dashedAnimatedBorder = ({
  borderWidthPx = 1,
  dashesLengthPx = 8,
  durationSec = 8,
  color1 = 'black',
  color2 = 'white',
} = {}) => css`
  background: linear-gradient(90deg, ${color1} 50%, ${color2} 50%), linear-gradient(90deg, ${color1} 50%, ${color2} 50%),
    linear-gradient(0deg, ${color1} 50%, ${color2} 50%), linear-gradient(0deg, ${color1} 50%, ${color2} 50%);
  background-size: ${dashesLengthPx * 2}px ${borderWidthPx}px, ${dashesLengthPx * 2}px ${borderWidthPx}px,
    ${borderWidthPx}px ${dashesLengthPx * 2}px, ${borderWidthPx}px ${dashesLengthPx * 2}px;
  background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  animation: ${borderAnimation} ${durationSec}s infinite linear;
`;
