import React from 'react';
import styled from 'styled-components';

interface RoomTimeSpentProps {
  timeSpentInMins: string;
}

const RoomTimeSpent = ({ timeSpentInMins }: RoomTimeSpentProps) => (
  <RoomItemTimeSpent>{timeSpentInMins} min</RoomItemTimeSpent>
);

export default RoomTimeSpent;

export const RoomItemTimeSpent = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: white;
  padding: 6px 20px;
  font-weight: bold;
`;
