import React from 'react';
import PropTypes from 'prop-types';

const LocationIcon = ({ width, height, color, opacity, strokeWidth }) => (
  <svg width={width} height={height} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <path d="M8.83301 20.2008L8.76254 20.128C8.76262 20.1279 8.7627 20.1279 8.76277 20.1278L8.76254 20.128L8.83301 20.2008ZM8.83301 20.2008L8.90347 20.128C8.90339 20.1279 8.90332 20.1279 8.90324 20.1278L8.90347 20.128L8.83301 20.2008ZM3.454 14.8523C1.8855 13.3338 1 11.244 1 9.06087L1 8.83308C0.999999 4.50703 4.50696 1.00007 8.83301 1.00007C13.1591 1.00007 16.666 4.50703 16.666 8.83308L16.666 9.06087C16.666 11.244 15.7805 13.3338 14.212 14.8523L8.83301 20.0598L3.454 14.8523Z" stroke={color} strokeWidth={strokeWidth} />
      <circle cx="8.83262" cy="8.83255" r="2.52372" fill={color} />
    </g>
  </svg>
);

export default LocationIcon;

LocationIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
  strokeWidth: PropTypes.string,
};

LocationIcon.defaultProps = {
  width: '18',
  height: '22',
  color: 'white',
  opacity: '1.0',
  strokeWidth: '2',
};
