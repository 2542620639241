import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const NewDayDivider = ({ dividerTimestamp }) => (
  <Container>
    <DividerLine />
    <Text className="dont-drag-me">{dividerTimestamp}</Text>
    <DividerLine />
  </Container>
);

NewDayDivider.propTypes = {
  dividerTimestamp: PropTypes.string.isRequired,
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #bfbfbf;
  margin: 18px 0;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  padding: 0px 9px;
`;

const DividerLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: white;
  opacity: 30%;
`;

const Text = styled.span`
  user-select: text;
  cursor: text;
  font-family: var(--chat-section-font, Inter, sans-serif);
`;
