import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

// store
import { selectEmail, selectResetPasswordScreenError } from '../../store/signing-in/selectors';
import { goPrevScreen, resetPassword, setResetPasswordError } from '../../store/signing-in/actions';

// components
import {
  ContainerDefault,
  InputContainer,
  NavButtonsContainer,
  NextNavButton,
  PrevNavButton,
  ScreenSubTitle,
  ScreenTitle,
  TitleContainer,
} from '../shared-styled';
import FlowInput from '../FlowInput';
import FlowInputsContainer from '../FlowInputsContainer';

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();

  const currentUserEmail = useSelector(selectEmail);
  const resetPasswordScreenError = useSelector(selectResetPasswordScreenError);

  const [email, setEmail] = useState(() => currentUserEmail);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (resetPasswordScreenError) {
      dispatch(setResetPasswordError({ error: '' }));
    }
  };

  const onPrevClick = () => {
    dispatch(goPrevScreen({ screenData: {} }));
  };

  const onNextClick = () => {
    dispatch(resetPassword({ email }));
  };

  return (
    <ContainerDefault>
      <TitleContainer>
        <ScreenTitle>Recover Password</ScreenTitle>
        <ResetPasswordScreenSubtitle>
          Get instructions sent to this email that explain <br /> how to reset your password.
        </ResetPasswordScreenSubtitle>
      </TitleContainer>

      <FlowInputsContainer isValid onSubmit={onNextClick}>
        <InputContainer>
          <FlowInput
            label="Email"
            errorMessage={resetPasswordScreenError}
            id="sign-in-first-email-input"
            type="email"
            placeholder="example@email.com"
            checkResult={null}
            value={email}
            autoFocus
            onChange={handleEmailChange}
          />
        </InputContainer>
      </FlowInputsContainer>

      <NavButtonsContainer>
        <PrevNavButton onClick={onPrevClick}>Cancel</PrevNavButton>
        <NextNavButton type="submit" disabled={!email} onClick={onNextClick}>
          Send
        </NextNavButton>
      </NavButtonsContainer>
    </ContainerDefault>
  );
};

const ResetPasswordScreenSubtitle = styled(ScreenSubTitle)`
  margin-top: 13px;
  color: #12002daf;
  font-weight: 400;
  font-size: 16px;
`;

export default ResetPasswordScreen;
