import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { goNextScreen } from '../../store/signing-in/actions';
import { setNewRoomHasCustomBackground, setNewRoomTheme, updateNewRoomData } from '../../store/new-room/actions';
import VibePicker from '../../vibe-picker/VibePicker';
import { CreateFirstHubScreenContainer, Logo } from '../../vibe-picker/shared-styles';
import SignInBackground from '../SignInBackground';
import { track } from '../../../util/analytics-util';
import { ONBOARDING_STEP_VIEW } from '../../../constants/analytics-events/onboarding-events';

const VibePickerScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    track(ONBOARDING_STEP_VIEW, { step: 'Vibe Picker' });
  }, []);

  const onNextClick = useCallback(
    ({ roomData: vibe, theme, isCustomBackground }) => {
      // save picked vibe
      dispatch(
        updateNewRoomData({
          data: {
            ...vibe,
          },
        })
      );

      dispatch(setNewRoomTheme({ theme }));
      dispatch(setNewRoomHasCustomBackground({ hasCustomBackground: isCustomBackground }));

      // go next step
      dispatch(goNextScreen({}));
    },
    [dispatch]
  );

  return (
    <CreateFirstHubScreenContainer>
      <SignInBackground />
      <Logo />
      <VibePicker onNextClick={onNextClick} />
    </CreateFirstHubScreenContainer>
  );
};

export default VibePickerScreen;
