import $ from 'jquery';
import UIkit from 'uikit';

import { trackUserActivationFor } from './util/user-activation';

import firebase, { db } from './firebase';
import { getPushToken } from './messaging';
import { monitorAudio, stopMonitoringAudio, drawAudioMultiBar } from './audio';
import { joinWaitlist, nameTags, leaveWaitlist, onUserListChanged, offUserListChanged } from './presence';
import { htmlToElement, getQueryStringValue, sanitize, getRoomId } from './util';
import log from './log';
import { isWaitlistAllowedForBoardId, isHostGranted, onRoleChange, offRoleChange } from './roles-management';
import { showCapacityDialog, getCapacityInfoForBoardId } from './room-capacity';
import { preloadBoard, loadBoard, unloadBoard, loadUserCard } from './room-loading';
import { Devices } from './sfu/devices.ts';

import { startAuth } from './electron-support/electron-support';
import { checkIsElectron } from './util/platform-util';

import { createRoom, isHereEmployee, isNewUser } from './util/user-util';
import { startListenForProfileChange } from './util/profile-util';
import { isRoomPublic } from './util/public-rooms-util';
import { getCameraDeviceId, getMicDeviceId, setCameraDeviceId, setMicDeviceId } from './util/device-util';
import { BASICS_ROOM_ID, HOMEPAGE_ROOM_ID, NUM_QUICK_JUMP_ROOMS } from './constants/board-constants';
import { CAMERA_MIC_ERROR } from './constants/analytics-events/camera-events';

// react
import { renderSidebar } from './react/sidebar/render';
import { renderQuickRoomSwitcher } from './react/sidebar/render-quick-jump';
import renderSignInFlow, { unmountSignInFlow } from './react/sign-in-flow/render';
import { setVisitedRoomsLimit, startVisitedRoomsPolling } from './react/store/visited-rooms/actions.ts';
import { renderOpenedInDesktop } from './react/opened-in-desktop/render.tsx';

import '../styles/join-room.less';
import { identifyAnalytics, initAnalytics, track } from './util/analytics-util';
import { initGrowthBookUser } from './api/growthbook-handler';
import { attemptLoadInElectron } from './util/electron-util';
import { flows } from './react/sign-in-flow/constants.ts';
import reduxAdapter from './react/store/redux-adapter';
import { goToLobby } from './util/navigation-util';
import { openVibePicker } from './util/lobby-util';
import { BANNED_BY_QUERY_PARAM } from './constants/lobby-constants';
import { HERE_OS_PATH } from './definitions/groups.ts';

initGrowthBookUser();

const landingPages = {
  main: HOMEPAGE_ROOM_ID,
  vday: 'RZzYRa8HwUYv4fRCdKEi',
};

$('#preview-video-input, #preview-audio-input').change(updatePreviewCameraDevice);

let splashPageEntryPoint = false;
let initialTemplateId = null;

let previewStream = null;
let previewCameraOn = true;
let previewMicOn = true;
let previewAudioNode = null;
let previewCameraAudioNode = null;
let preloaded = false;
let roomLoadedInElectron = false;

let onCameraOn = null;
let onJoinRoomPressed = null;

$('#preview-mic-on-button').click(turnOnPreviewMic);
$('#preview-mic-off-button').click(turnOffPreviewMic);

async function getDisabledMediaButtonTitle(media) {
  let device;
  let error;

  const caps = await Devices.getInstance().testCapabilities();

  if (['mic', 'audio', 'microphone'].includes(media.toLowerCase())) {
    media = 'audio';
    device = 'Microphone';
    error = caps.audioNotCapableError;
  }

  if (['video', 'camera'].includes(media.toLowerCase())) {
    media = 'video';
    device = 'Camera';
    error = caps.videoNotCapableError;
  }
  let title = 'title: [Camera | Microphone] Unavailable';
  if (error?.name === 'NotAllowedError') {
    title = `title: Grant access to the ${device} first`;
  } else if (error?.name === 'NotFoundError') {
    title = `title: Connect a ${device} and reload to use it`;
  }
  return title;
}

function turnOffPreviewMic() {
  log.debug('Auth: Turn OFF Preview Mic');
  if (previewAudioNode) {
    stopMonitoringAudio(previewAudioNode);
  }
  if (previewCameraAudioNode) {
    stopMonitoringAudio(previewCameraAudioNode);
  }
  previewMicOn = false;
  $('#preview-mic-on-button').show();
  $('#preview-mic-off-button').hide();
  $('#preview-mic-levels').hide();
  $('#preview-audio-input').prop('disabled', 'disabled');
  $('.preview-audio').animate({ opacity: 0 }, 300);
  $('#preview-camera-audio-level').hide();
}

async function turnOnPreviewMic() {
  const caps = await Devices.getInstance().testCapabilities();
  if (caps.isAudioCapable) {
    log.debug('Auth: Turn ON Preview Mic');
    previewMicOn = true;
    $('#preview-mic-on-button').hide();
    $('#preview-mic-off-button').show();
    $('#preview-mic-levels').show();
    $('.preview-audio').animate({ opacity: 1 }, 300);
    $('#preview-audio-input').removeAttr('disabled');
    await monitorPreviewAudio();
  } else {
    log.warn(`Auth: Cannot turn preview Mic on because device is not audio capable... warning instead.`);
    handleDeviceError('mic', caps.audioNotCapableError);
  }
}

function getPreviewAudioInputText() {
  return ![null, undefined, ''].includes($('#preview-audio-input').val()) ? $('#preview-audio-input').val() : null;
}

function getPreviewVideoInputText() {
  return ![null, undefined, ''].includes($('#preview-video-input').val()) ? $('#preview-video-input').val() : null;
}

function getMediaConfig() {
  const audioId = getPreviewAudioInputText();
  const videoId = getPreviewVideoInputText();

  const config = {};

  if (previewMicOn && audioId) {
    config.audio = { deviceId: { exact: audioId } };
  }
  if (previewCameraOn && videoId) {
    if (videoId) {
      config.video = { deviceId: { exact: videoId } };
    }
  }

  log.debug(`Auth: Media configuration:
  video: ${videoId}
  mic: ${audioId}`);

  return config;
}

/**
 *
 * @returns true if we're running on prod, false if on sandbox / local
 */
function isProduction() {
  return window.location.host.includes('here.fm');
}

async function handleRoleChange() {
  await updateCameraButtonsForPermissions(
    window.currentBoardData.membersCanUseCam !== false || isHostGranted(),
    window.currentBoardData.membersCanUseMic !== false || isHostGranted()
  );
}

let deviceScanningTimer = null;
function disableJoinButton() {
  document.querySelectorAll('.join-room-button').forEach((el) => {
    el.disabled = true;
  });
  // Let the user know that we're scanning for their devices if it takes a moment...
  deviceScanningTimer = setTimeout(() => {
    document.getElementById('device-scanning').style.display = 'block';
  }, 750);
}

function enableJoinButton() {
  document.querySelectorAll('.join-room-button').forEach((el) => {
    el.disabled = false;
  });
  clearTimeout(deviceScanningTimer);
  document.getElementById('device-scanning').style.display = 'none';
}

async function monitorPreviewAudio() {
  let stream;
  const constraints = getMediaConfig();
  try {
    track('Get User Media', { constraints });
    stream = await navigator.mediaDevices.getUserMedia(constraints);
  } catch (error) {
    track('Get User Media Error', {
      constraints,
      error: error.name,
      message: error.message,
      action: 'monitorPreviewAudio',
    });
    if (error.name === 'NotAllowedError') {
      // Permission denied
      showCameraError(
        `<p>Here doesn't have permission to use your microphone.
        Please enable via the icon in your browser's location bar, and reload to try again.</p>
        <img src="/images/misc/camera-permissions.gif" width="988px" height="372px"></img>`
      );

      log.warn('Microphone permissions denied');
    } else if (error.name === 'NotFoundError') {
      showCameraError(
        "Here can't find any microphone :( please enable your microphone if you have one, and reload to try again."
      );
      log.warn('No microphone found');
    }
    return;
  }
  if (previewAudioNode) {
    stopMonitoringAudio(previewAudioNode);
  }
  if (previewCameraAudioNode) {
    stopMonitoringAudio(previewCameraAudioNode);
  }

  previewAudioNode = await monitorAudio(stream, 'preview-mic-levels');
  previewCameraAudioNode = await monitorAudio(stream, 'preview-camera-audio-level', drawAudioMultiBar);
  $('#preview-camera-audio-level').show();
}

function joinText() {
  if (previewCameraOn) {
    return 'Join with camera on';
  }
  return 'Join with camera off';
}

async function showCameraError(errorMessage) {
  $('#preview-mic-on-button').hide();
  $('#preview-mic-off-button').hide();
  $('#preview-mic-levels').hide();
  $('.preview-audio').hide();
  turnOffPreviewCamera(errorMessage);
}

$('#preview-camera-off-button').click(() => turnOffPreviewCamera());
async function turnOffPreviewCamera(errorMessage = null) {
  log.debug('Auth: Turn OFF Preview Camera');
  previewCameraOn = false;
  $('#preview-camera-on-button').show();
  $('#preview-camera-off-button').hide();
  $('#preview-camera-message').html(joinText());
  $('#preview-video-input').prop('disabled', 'disabled');
  $('.preview-video').animate({ opacity: 0 }, 300);
  $('#camera-preview').hide();
  document.getElementById('camera-preview-icon').style.display = 'block';

  if (previewStream) {
    previewStream.getTracks().forEach((t) => {
      t.stop();
    });

    // If we still have the mic on, switch audio processor to mic only
    if (previewMicOn) {
      monitorPreviewAudio();
    } else {
      if (previewAudioNode) {
        stopMonitoringAudio(previewAudioNode);
      }
      if (previewCameraAudioNode) {
        stopMonitoringAudio(previewCameraAudioNode);
      }
    }
  }

  if (errorMessage !== null) {
    $('#preview-camera-error').html(errorMessage);
    $('#preview-camera-error').show();
  }
}

$('#preview-camera-on-button').click(turnOnPreviewCamera);

async function testDeviceCapabilities() {
  let stream;

  const { audioNotCapableError, videoNotCapableError, isAudioCapable, isVideoCapable } =
    await Devices.getInstance().testCapabilities();

  if (audioNotCapableError?.name === 'NotAllowedError' && videoNotCapableError?.name === 'NotAllowedError') {
    handleDeviceError(videoNotCapableError ? 'video' : 'mic', videoNotCapableError || audioNotCapableError);
    enableJoinButton();
    return { enableCamera: false, error: videoNotCapableError || audioNotCapableError };
  }

  const constraints = { audio: isAudioCapable, video: isVideoCapable };
  try {
    track('Get User Media', constraints);
    stream = await navigator.mediaDevices.getUserMedia(constraints);
  } catch (error) {
    track('Get User Media Error', {
      constraints,
      error: error.name,
      message: error.message,
      action: 'testDeviceCapabilitiesAudioOnly',
    });
    return { enableCamera: false, error };
  } finally {
    if (stream) stream.getTracks().forEach((t) => t.stop());
  }

  enableJoinButton();
  stopPreviewStream();
  if (isAudioCapable || isVideoCapable) {
    stream = await navigator.mediaDevices.getUserMedia({ audio: isAudioCapable, video: isVideoCapable });
  }

  if (stream) {
    stream.getTracks().forEach((t) => {
      t.stop();
    });
  }

  return { enableCamera: isVideoCapable, error: null };
}

async function turnOnPreviewCamera() {
  // Guard against race condition where join screen is loaded while electron gets loaded
  if (!checkIsElectron() && roomLoadedInElectron) {
    return;
  }

  log.debug('Auth: Turn ON Preview Camera');

  disableJoinButton();

  try {
    previewCameraOn = true;
    $('#preview-video-input').removeAttr('disabled');
    const cameraPreviewWrapper = document.getElementById('camera-preview-wrapper');
    cameraPreviewWrapper.style.display = 'block';
    $('#camera-preview').show();

    await updatePreviewCameraDevice();
    const caps = await Devices.getInstance().testCapabilities();

    // Even though we set previewCameraOn up above, some users turn it off again while we're testing device capabilities
    if (caps.isVideoCapable && previewCameraOn) {
      $('#preview-camera-on-button').hide();
      $('#preview-camera-off-button').show();

      $('#preview-camera-message').html(joinText());
      $('.preview-video').animate({ opacity: 1 }, 300);
      document.getElementById('camera-preview-icon').style.display = 'none';
    } else {
      track('SFU Publisher Without Camera Device');
      onCameraOn = turnOffPreviewCamera;
      window.rtc.isVideoOn = false;
      turnOffPreviewCamera();
      if (previewMicOn) {
        await turnOnPreviewMic();
      } else {
        track('SFU Publisher Without Audio Device');
        await turnOffPreviewMic();
      }
    }

    cameraPreviewWrapper.classList.remove('inactive-camera-element');
    cameraPreviewWrapper.classList.add('active-camera-element');

    if (previewAudioNode) {
      stopMonitoringAudio(previewAudioNode);
    }

    previewAudioNode = await monitorAudio(previewStream, 'preview-mic-levels');

    const vid = document.getElementById('camera-preview');
    vid.srcObject = previewStream;
    vid.onloadedmetadata = () => {
      trackUserActivationFor('turnOnPreviewCamera');
      vid.play();
    };

    await populateDevicesMenu(previewStream);

    if (onCameraOn) {
      onCameraOn();
    }
  } catch (err) {
    log.error('Error trying to get device list', err);
  } finally {
    enableJoinButton();
  }
}

const landingPage = () => {
  const lp = getQueryStringValue('lp');
  if (lp && landingPages[lp]) {
    return landingPages[lp];
  }
  return landingPages.main;
};

async function populateDevicesMenu(stream) {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoTracks = stream ? stream.getVideoTracks() : null;
  const audioTracks = stream ? stream.getAudioTracks() : null;
  const savedCameraId = getCameraDeviceId();
  const savedMicId = getMicDeviceId();

  let currentVideo = null;
  let currentAudio = null;
  if (savedCameraId) {
    const matches = devices.filter((device) => device.deviceId === savedCameraId);
    if (matches && matches.length > 0) {
      currentVideo = savedCameraId;
    }
  }
  if (!currentVideo) {
    currentVideo = videoTracks && videoTracks.length > 0 ? videoTracks[0].deviceId : null;
  }

  if (savedMicId) {
    const matches = devices.filter((device) => device.deviceId === savedMicId);
    if (matches && matches.length > 0) {
      currentAudio = savedMicId;
    }
  }
  if (!currentAudio) {
    currentAudio = audioTracks && audioTracks.length > 0 ? audioTracks[0].deviceId : null;
  }

  log.debug(
    'Device Options:',
    JSON.stringify(
      devices.map((d) => [d.label, d.kind, d.deviceId]),
      null,
      2
    )
  );
  log.debug(`Currently selected video: ${currentVideo}`);
  log.debug(`Currently selected audio: ${currentAudio}`);
  devices.forEach((device) => {
    if (device.deviceId === currentVideo || device.deviceId === currentAudio) {
      device.selected = true;
    }
  });

  // Update menu options
  $('#preview-audio-input, #preview-video-input').find('option').remove();

  let hasVideoInput = false;
  devices.forEach((device) => {
    let { label } = device;
    if (label === null || label === undefined || label === '') label = device.deviceId;
    const option = htmlToElement(`
      <option value="${device.deviceId}" ${device.selected ? 'selected' : ''}>
        ${label}
      </option>
    `);
    if (device.kind === 'audioinput') {
      $('#preview-audio-input').append(option);
    } else if (device.kind === 'videoinput') {
      $('#preview-video-input').append(option);
      hasVideoInput = true;
    }
  });

  if (!hasVideoInput) {
    await turnOffPreviewCamera();
  }
  await updatePreviewCameraDevice();
}

function handleDeviceError(device, error) {
  // Full sized avatar, but no audio along with camera off
  $('#camera-preview-wrapper').removeClass('inactive-camera-element');
  $('#camera-preview-wrapper').addClass('active-camera-element');
  $('.preview-audio').animate({ opacity: 0 }, 300);

  const deviceKind = device.match(/mic/) ? 'audio' : device;

  if (error?.name === 'NotAllowedError') {
    // Permission denied
    showCameraError(
      `Here doesn't have permission to use your ${device}.
      Please enable via the icon in your browser's location bar, and reload to try again.
      <img src="/images/misc/camera-permissions.gif" width="658px" height="248px"></img>`
    );
    log.warn(`Auth: handle ${device} error permissions denied`);
    track(CAMERA_MIC_ERROR, { kind: deviceKind, name: 'NotAllowedError' });
  } else if (error?.name === 'NotFoundError') {
    showCameraError(`Here can't find ${device} device :( please enable it if you have one, and reload to try again.`);
    log.warn('Auth: No devices found');
    track(CAMERA_MIC_ERROR, { kind: deviceKind, name: 'NotFoundError' });
  } else if (error?.name === 'NotReadableError') {
    // Probably in use by someone else
    showCameraError(
      `Here can't access your ${device}. If another application is using it, please close that app and reload to try again.`
    );
    log.warn(`Auth: ${device} not readable`);
    track(CAMERA_MIC_ERROR, { kind: deviceKind, name: 'NotReadableError' });
  } else {
    log.error('Received unknown camera error', error);
    track(CAMERA_MIC_ERROR, { kind: deviceKind, name: `Unknown ${device} Error` });
  }
}

async function updatePreviewCameraDevice() {
  const audioId = getPreviewAudioInputText();
  const videoId = getPreviewVideoInputText();

  if (!audioId && !videoId) {
    // not ready
    track('Update Preview Camera Not Ready Error');
    return;
  }

  setCameraDeviceId(videoId);
  setMicDeviceId(audioId);

  const constraints = getMediaConfig();

  if (constraints.video || constraints.audio) {
    try {
      track('Get User Media', { constraints });
      stopPreviewStream();
      previewStream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
      track('Get User Media Error', {
        constraints,
        error: error.name,
        message: error.message,
        action: 'updatePreviewCameraDevice',
      });
      track('Auth Update Preview Camera Error', { error: error.name });
      handleDeviceError('video', error);
      return;
    }
  }

  const vid = document.getElementById('camera-preview');
  vid.srcObject = previewStream || new MediaStream();

  if (previewAudioNode) {
    stopMonitoringAudio(previewAudioNode);
  }
  previewAudioNode = await monitorAudio(previewStream, 'preview-mic-levels');

  vid.onloadedmetadata = () => {
    trackUserActivationFor('updatePreviewCameraDevice');
    vid.play();
  };
}

function stopPreviewStream() {
  if (previewStream) {
    previewStream.getTracks().forEach((mediaTrack) => {
      mediaTrack.stop();
    });
  }
  const vid = document.getElementById('camera-preview');
  if (vid) {
    vid.srcObject = null;
  }
}

async function handleJoinRoom({ boardId, boardData, userProfileData }) {
  offRoleChange(handleRoleChange);
  offUserListChanged(boardId, checkForUserUpdates);
  const nameTag = $('#name-tag-input').val();
  if (nameTag && nameTag.trim().length > 0) {
    nameTags[boardId] = nameTag.trim();
  }

  stopPreviewStream();

  if (previewAudioNode) {
    stopMonitoringAudio(previewAudioNode);
  }
  if (previewCameraAudioNode) {
    stopMonitoringAudio(previewCameraAudioNode);
  }
  document.removeEventListener('keydown', handleJoinRoomKeys);

  const audioInput = document.getElementById('preview-audio-input');
  if (audioInput.value) {
    setMicDeviceId(audioInput.value);
    window.rtc.audioDeviceId = audioInput.value;
    window.rtc.audioDeviceLabel = audioInput.options[audioInput.selectedIndex]
      ? audioInput.options[audioInput.selectedIndex].text
      : null;
  }
  const videoInput = document.getElementById('preview-video-input');
  if (videoInput.value) {
    setCameraDeviceId(videoInput.value);

    window.rtc.videoDeviceId = videoInput.value;
    window.rtc.videoDeviceLabel = videoInput.options[videoInput.selectedIndex]
      ? videoInput.options[videoInput.selectedIndex].text
      : null;
  }
  await loadBoard({
    docId: boardId,
    title: null,
    isVideoOn: previewCameraOn,
    isAudioOn: previewMicOn,
    isPreloaded: preloaded,
    joinSound: true,
    boardData,
    userProfileData,
  });
}

function handleWaitlist(boardId) {
  joinWaitlist(
    boardId,
    () => {
      UIkit.modal('#join-room-dialog').hide();
      leaveWaitlist(boardId);
      handleJoinRoom({ boardId });
    },
    (message) => {
      log.debug(`Received message from host: ${message}`);
      document.querySelectorAll('.wait-message').forEach((el) => {
        el.innerHTML = `<here-loader>${message}</here-loader>`;
      });
    }
  );

  document.querySelectorAll('.wait-message').forEach((el) => {
    el.innerHTML = '<here-loader>The host will let you in shortly.</here-loader>';
  });
  $('.wait-message').show();
  $('.join-room-button').html('Waiting...');
  $('.join-room-button').prop('disabled', true);
}

async function showDesktopAppMessage(user, boardId, title = window.currentBoardData.title) {
  track('Room Opened in Electron');
  await turnOffPreviewCamera();
  turnOffPreviewMic();
  unloadBoard();
  offUserListChanged(boardId, checkForUserUpdates);

  preloaded = false;

  document.getElementById('join-room-content').style.display = 'none';

  renderOpenedInDesktop(title, () => {
    document.getElementById('join-room-content').style.display = 'block';
    roomLoadedInElectron = false;
    showJoinRoomModal(user, boardId, false, true);
  });
}

function showRoomNotFound() {
  track('Room Not Found');
  document.getElementById('join-room-content').style.display = 'none';
  document.getElementById('join-room-error').style.display = 'block';
  document.getElementById('error-lobby-button').addEventListener('click', () => {
    goToLobby();
  });
}

async function setCameraMicButtonDisabled(button, media) {
  button.disabled = true;
  button.classList.add('inactive-button');
  button.setAttribute('uk-tooltip', await getDisabledMediaButtonTitle(media));
  const cameraPreviewWrapper = document.getElementById('camera-preview-wrapper');
  cameraPreviewWrapper.classList.remove('inactive-camera-element');
  cameraPreviewWrapper.classList.add('active-camera-element');
  document.getElementById('device-scanning').style.display = 'none';
}

async function updateCameraButtonsForPermissions(canUseCamera, canUseMic) {
  const caps = await Devices.getInstance().testCapabilities();
  const camButton = document.getElementById('preview-camera-on-button');
  if (canUseCamera && caps.isVideoCapable) {
    camButton.disabled = false;
    camButton.classList.remove('inactive-button');
    camButton.setAttribute('uk-tooltip', 'title: Turn Camera On');
  } else {
    turnOffPreviewCamera();
    await setCameraMicButtonDisabled(camButton, 'video');
  }

  const micButton = document.getElementById('preview-mic-on-button');
  if (canUseMic && caps.isAudioCapable) {
    micButton.disabled = false;
    micButton.classList.remove('inactive-button');
    micButton.setAttribute('uk-tooltip', 'title: Turn Microphone On');
  } else {
    turnOffPreviewMic();
    await setCameraMicButtonDisabled(micButton, 'audio');
  }
}

/* This is the interstitial page before a user joins the room. */
async function showJoinRoomModal(user, boardId, isDesktopApp, ignoreDesktopCheck = false, userProfileData = null) {
  document.getElementById('elements').innerHTML = '';
  document.getElementById('welcome-bar').style.display = 'none';

  const modal = UIkit.modal('#join-room-dialog');
  try {
    const snapshot = await db.doc(`boards/${boardId}`).get();
    if (!snapshot.exists) {
      modal.show();
      showRoomNotFound();
      return;
    }

    const boardData = snapshot.data();

    if (isDesktopApp) {
      await showDesktopAppMessage(user, boardId, boardData.title);
      return;
    }

    const { isCapacityReached } = await getCapacityInfoForBoardId(boardId, boardData);
    if (isCapacityReached) {
      showCapacityDialog(boardId);
      return;
    }

    const member = user ? await db.doc(`boards/${boardId}/members/${user.uid}`).get() : null;
    const isAllowedToBypassWaitlist = await isWaitlistAllowedForBoardId(boardId, member?.data());
    const shouldWaitToEnter = boardData?.joinMode === 'waitlist' && !isAllowedToBypassWaitlist;

    // bypass for users who just want to get into a room without camera
    if (!shouldWaitToEnter && getQueryStringValue('skip-preview')) {
      previewCameraOn = false;
      previewMicOn = false;

      handleJoinRoom({ boardId, boardData, userProfileData });
      return;
    }

    let isHost = false;

    if (user) {
      const membership = user ? await db.doc(`memberships/${user.uid}/boards/${boardId}`).get() : null;
      if (!shouldWaitToEnter && membership.exists) {
        log.debug('Preloading board');
        await preloadBoard(boardId, user);
        preloaded = true;
      }

      const memberData = member.data();
      if (memberData) {
        isHost = isHostGranted(memberData.role);
      }
    }

    await testDeviceCapabilities();

    if (boardData.membersCanUseCam !== false || isHost) {
      await turnOnPreviewCamera();
    } else if (boardData.membersCanUseCam === false && !isHost) {
      log.debug('no cam use');
    } else {
      await turnOnPreviewCamera();
    }

    await updateCameraButtonsForPermissions(
      boardData.membersCanUseCam !== false || isHost,
      boardData.membersCanUseMic !== false || isHost
    );

    window.setupBoardUI({ id: boardId, ...boardData });
    if (boardData.nameTag) {
      $('#name-tag-entry').show();
      $('#name-tag-input').focus();
    }

    if (shouldWaitToEnter) {
      $('.join-room-button').html('Ask to Join');
    }

    const roomTitle = document.querySelector('#preview-room-title');
    roomTitle.textContent = boardData.title;
    const onJoinRoom = () => {
      if (shouldWaitToEnter) {
        handleWaitlist(boardId);
      } else {
        modal.hide();
        handleJoinRoom({ boardId });
      }
    };

    document.querySelectorAll('.join-room-button').forEach((el) => {
      if (onJoinRoomPressed) {
        el.removeEventListener('click', onJoinRoomPressed);
      }
      el.addEventListener('click', onJoinRoom);
    });

    onJoinRoomPressed = onJoinRoom;

    const joinRoomTitles = document.querySelectorAll('.join-room-title');
    joinRoomTitles.forEach((el) => {
      el.innerHTML = sanitize(boardData.title);
    });
  } catch (err) {
    log.error('Error fetching board', err);
    track('Board Load Error');
    document.getElementById('join-room-content').style.display = 'none';
    document.getElementById('join-room-error').style.display = 'block';
  }

  if (!checkIsElectron() && !isDesktopApp && !ignoreDesktopCheck) {
    onUserListChanged(boardId, checkForUserUpdates);
  }

  // it looks strange but we really need to close this modal before opening it
  // classic UIKit issue ¯\_(ツ)_/¯
  modal.hide();
  modal.show();

  document.removeEventListener('keydown', handleJoinRoomKeys);
  document.addEventListener('keydown', handleJoinRoomKeys);

  onRoleChange(handleRoleChange);
}

/**
 *
 * Callback from presence, check what users are up to.
 *
 */
function checkForUserUpdates(users, _lastValue, boardId) {
  const { uid } = firebase.auth().currentUser;
  if (users && users[uid]?.platform === 'desktop') {
    showDesktopAppMessage(firebase.auth().currentUser, boardId);
  }
}

// Keyboard shortcuts
function handleJoinRoomKeys(keyEvent) {
  log.debug(keyEvent);
  if (keyEvent.altKey) {
    if (keyEvent.code === 'KeyM') {
      if (previewMicOn) {
        turnOffPreviewMic();
      } else {
        turnOnPreviewMic();
      }
    } else if (keyEvent.code === 'KeyC') {
      if (previewCameraOn) {
        turnOffPreviewCamera();
      } else {
        turnOnPreviewCamera();
      }
    }
  }
}

async function getBoardIdFromUrl() {
  let boardId = getRoomId();
  if (boardId) {
    const existingName = await db.collection('boards').where('urlAlias', '==', boardId).get();
    if (existingName.size > 0) boardId = existingName.docs[0].id;
  }

  return boardId;
}

// TODO: export this function instead of mutating the window?
// TODO: rename it?
window.authCreateRoom = async function authCreateRoom(initialTemplate = null) {
  initAnalytics('d1qfQF3jv7VD26CCxHapwhzU7HKkeJDS');

  if (initialTemplate) {
    initialTemplateId = initialTemplate;
  }

  if (checkIsElectron()) {
    startAuth();
  } else {
    renderSignInFlow();
  }
};

document
  .getElementById('sticky-signup-button')
  .addEventListener('click', () => window.authCreateRoom(null, 'auth-type-selection-screen'));
document
  .getElementById('get-started-button')
  .addEventListener('click', () => window.authCreateRoom(null, 'auth-type-selection-screen-get-started'));

const updateUserProfile = async (user) => {
  const userProfile = await db.doc(`userProfiles/${user.uid}`).get();
  if (!userProfile.exists) {
    // This should be done only for new users. But we didn't do it for those
    // who rejected notification, so updating it for all just in case.
    await userProfile.ref.set({
      displayName: user.displayName,
    });
  }
  return userProfile;
};

/* The main authentication and traffic cop when landing on here.fm */
(async () => {
  if (/(sandbox|localhost)/.test(window.location.host) && !firebase.auth().currentUser) {
    const email = getQueryStringValue('e');
    const password = getQueryStringValue('p');
    if (email && password) {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    }
  }
  log.debug('bootstrap: start');

  firebase.auth().onAuthStateChanged(async (user) => {
    if (getQueryStringValue('is-user-page')) {
      loadUserCard(getRoomId());
      return;
    }

    if (user) {
      if (!(await isNewUser(user)) && document.getElementById('sign-in-modal-container') && window.currentBoardId) {
        log.debug('Found sign in modal, removing it');
        track('Force-Removing Sign In');
        unmountSignInFlow();
      }

      const [candidateBoardId, anyMembership, profile] = await Promise.all([
        getBoardIdFromUrl(),
        db.collection(`memberships/${user.uid}/boards`).limit(1).get(),
        db.doc(`userProfiles/${user.uid}`).get(),
      ]);

      const profileData = profile.data();

      const firstName = user.displayName ? user.displayName.split(' ')[0] : null;

      identifyAnalytics(user.uid, {
        displayName: user.displayName,
        email: user.email,
        firstName,
      });

      const signingInInProgress = !!reduxAdapter.latestState.signingIn.screenId;

      // Authed with an initial template pre-set
      if (anyMembership.empty && initialTemplateId) {
        const starterBoard = await db.collection('boards').doc(initialTemplateId).get();
        const title = firstName ? `⛺️ ${firstName}'s Room` : '⛺️ My Room';
        await createRoom({ starterBoard, title }, { templateName: starterBoard.data().title });
        return;
      }

      if ((profileData?.isOnboarding === true && candidateBoardId.length <= 1) || getQueryStringValue('onboarding')) {
        if (!signingInInProgress) {
          // Display the template screen for a first time user who has just finished signing up
          openVibePicker();
        }

        return;
      }

      // Create default room if a new user visits somebody's room with direct link.
      if (anyMembership.empty && candidateBoardId.length > 1 && !user.isAnonymous) {
        const title = firstName ? `⛺️ ${firstName}'s Room` : '⛺️ My Room';
        const starterBoard = await db.collection('boards').doc(BASICS_ROOM_ID).get();
        createRoom(
          { title, starterBoard },
          {
            shouldRedirect: false,
            isNewUserRoom: true,
          }
        ).catch((err) => {
          log.error('failed to create new user room', err);
        });

        // Deeplinked users will have to go through HereOS onboarding once they visit the HereOS page
        await db.doc(`userProfiles/${user.uid}`).update({ isOsOnboardingPending: true });
      }

      // User is signed in.
      if (window.currentBoardId === null || Object.values(landingPages).includes(window.currentBoardId)) {
        // Check for the URL ending in #<board-id> and open that board if exists
        // Otherwise send user to selector.
        if (candidateBoardId && candidateBoardId.length > 0) {
          const [membership, userProfile] = await Promise.all([
            db.doc(`memberships/${user.uid}/boards/${candidateBoardId}`).get(),
            db.doc(`userProfiles/${user.uid}`).get(),
          ]);

          if (membership.exists && membership.data().ban) {
            const { ban, title } = membership.data();
            document.location = `/l?${BANNED_BY_QUERY_PARAM}=${ban.by}&roomName=${encodeURIComponent(title)}`;
            return;
          }

          if (
            !checkIsElectron() &&
            !getQueryStringValue('skip-preview') &&
            !getQueryStringValue('newroom') &&
            isProduction()
          ) {
            const boardData = await db.doc(`boards/${candidateBoardId}`).get();
            attemptLoadInElectron(candidateBoardId, () => {
              roomLoadedInElectron = true;
              showDesktopAppMessage(user, candidateBoardId, boardData.data().title);
            });
          }

          if (!signingInInProgress && userProfile.exists) {
            const userProfileData = userProfile.data();
            if (!userProfileData.username) {
              // 1) User is signed in
              // 2) User doesn't have username (and display name)
              renderSignInFlow(
                {
                  flowId: !userProfileData.displayName ? flows.CREATE_USERNAME_AND_DISPLAY_NAME : flows.CREATE_USERNAME,
                  onClose: (createdUserProfile) => {
                    showJoinRoomModal(
                      { ...createdUserProfile, uid: createdUserProfile.id },
                      candidateBoardId,
                      roomLoadedInElectron,
                      false,
                      userProfile.data()
                    );
                  },
                },
                { profileId: user.uid }
              );
            } else {
              // 1) User is signed in
              // 2) User has username
              showJoinRoomModal(user, candidateBoardId, roomLoadedInElectron, false, userProfile.data());
            }
          }

          // render sidebar only if user is signed in
          reduxAdapter.dispatchAction(setVisitedRoomsLimit({ limit: NUM_QUICK_JUMP_ROOMS }));
          reduxAdapter.dispatchAction(startVisitedRoomsPolling());
          renderSidebar();
          renderQuickRoomSwitcher('quick-room-switcher-root');
        } else {
          // do not redirect user to the lobby if auth flow is in progress
          // let auth flow do redirecting
          const hereSignInModal = document.getElementById('sign-in-modal-container');
          if (hereSignInModal && hereSignInModal.classList.contains('uk-open')) {
            return;
          }

          document.location = `/${HERE_OS_PATH}`;
          return;
        }
      }

      const userProfile = await updateUserProfile(user);
      const userData = userProfile.data();
      startListenForProfileChange();
      const snapshot = await db.collection('boards').doc(candidateBoardId).get();
      const roomData = snapshot.data();

      if (await isNewUser(user)) {
        track('New User Signin', {
          firstName,
          fullName: user.displayName,
          email: user.email,
          entryPoint: splashPageEntryPoint ? 'splash' : 'room deep link',
        });

        if (user.photoURL) {
          userProfile.ref.update({
            // Requesting larger avatar from Google
            'avatar.original': user.photoURL.replace(/(=s)(\d+)/, '$1500'),
          });
        }

        if (getQueryStringValue('landingPage')) {
          userProfile.ref.update({ acquisitionSource: getQueryStringValue('landingPage') });
        }

        // Call back to the mothership on conversion
        if (window.gtag) {
          window.gtag('event', 'conversion', { send_to: 'AW-367811840/xjZ4CNy_25MCEIC6sa8B' });
        } else {
          log.error('No GTAG defined, not tracking');
        }
      } else {
        // existing user
        track('Existing User Signin', {
          firstName,
          fullName: user.displayName,
          email: user.email,
        });
      }

      // this user is not allowed in public rooms, redirect to lobby
      if (userData && userData.canViewPublicRooms === false && isRoomPublic(roomData)) {
        document.location.href = '/l';
        return;
      }

      if (firebase.messaging.isSupported()) {
        await getPushToken();
      }

      $('#camera-preview-wrapper div.camera').prepend(`<here-avatar userId="${user.uid}"></here-avatar>`);

      // DEBUG: For turning on and off Trivia button visibility
      const addTriviaSection = document.getElementById('newTriviaButton').parentNode;
      if (await isHereEmployee(user)) {
        addTriviaSection.style.display = 'block';
      } else {
        addTriviaSection.style.display = 'none';
      }
    } else {
      // We can't call getBoardIdFromUrl because 1) it makes request to the firebase database; 2) Unauthorized users can't make requests to the firebase database
      let candidateBoardId = getRoomId();
      log.debug('no user is signed in');
      // No user is signed in.
      if (!candidateBoardId || candidateBoardId.length === 0) {
        candidateBoardId = landingPage();
        loadBoard({ docId: candidateBoardId });
        // Enable background-click to close for non deep-linked login page
        $('#auth-dialog').attr('esc-close', 'true');
        $('#auth-dialog').attr('bg-close', 'true');
        splashPageEntryPoint = true;
      } else {
        // User is not signed in
        renderSignInFlow({
          flowId: flows.DEEP_LINK_SIGN_IN,
          onClose: async (createdUserProfile) => {
            candidateBoardId = await getBoardIdFromUrl();
            showJoinRoomModal(
              { ...createdUserProfile, uid: createdUserProfile.id },
              candidateBoardId,
              roomLoadedInElectron
            );
          },
        });
      }
    }
  });
})();
