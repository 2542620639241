import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.475 6.66824C8.87518 6.31504 9.19616 5.87821 9.41616 5.38737C9.63615 4.89654 9.75001 4.36319 9.75 3.82353C9.75 2.80947 9.35491 1.83694 8.65165 1.11989C7.94839 0.402835 6.99456 0 6 0C5.00544 0 4.05161 0.402835 3.34835 1.11989C2.64509 1.83694 2.25 2.80947 2.25 3.82353C2.24999 4.36319 2.36385 4.89654 2.58384 5.38737C2.80384 5.87821 3.12482 6.31504 3.525 6.66824C2.4751 7.15297 1.58435 7.93576 0.959235 8.923C0.334121 9.91025 0.00110389 11.0602 0 12.2353C0 12.4381 0.0790176 12.6326 0.21967 12.776C0.360322 12.9194 0.551088 13 0.75 13C0.948912 13 1.13968 12.9194 1.28033 12.776C1.42098 12.6326 1.5 12.4381 1.5 12.2353C1.5 11.0184 1.97411 9.85138 2.81802 8.99092C3.66193 8.13046 4.80653 7.64706 6 7.64706C7.19347 7.64706 8.33807 8.13046 9.18198 8.99092C10.0259 9.85138 10.5 11.0184 10.5 12.2353C10.5 12.4381 10.579 12.6326 10.7197 12.776C10.8603 12.9194 11.0511 13 11.25 13C11.4489 13 11.6397 12.9194 11.7803 12.776C11.921 12.6326 12 12.4381 12 12.2353C11.9989 11.0602 11.6659 9.91025 11.0408 8.923C10.4157 7.93576 9.5249 7.15297 8.475 6.66824ZM6 6.11765C5.55499 6.11765 5.11998 5.9831 4.74997 5.73102C4.37996 5.47894 4.09157 5.12065 3.92127 4.70145C3.75097 4.28226 3.70642 3.82098 3.79323 3.37597C3.88005 2.93095 4.09434 2.52218 4.40901 2.20134C4.72368 1.88051 5.12459 1.66201 5.56105 1.57349C5.9975 1.48497 6.4499 1.5304 6.86104 1.70404C7.27217 1.87768 7.62357 2.17172 7.87081 2.54899C8.11804 2.92625 8.25 3.3698 8.25 3.82353C8.25 4.43197 8.01295 5.01549 7.59099 5.44572C7.16903 5.87595 6.59674 6.11765 6 6.11765Z" fill={color} />
  </svg>
);

export default UserIcon;

UserIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

UserIcon.defaultProps = {
  size: '12',
  color: '#6B1BE3',
};
