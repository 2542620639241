import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getFilesTotalSize } from '../../../../../util/file-util';
import { uploadVibeImage } from '../../../../../upload';
import log from '../../../../../log';
import { fileUploadErrorTypes } from '../../../../../constants/chat-constants';
import { MAX_CHAT_UPLOAD_BYTES } from '../../../../../constants/files-constants';
import { selectChatSkinningBackgrounds } from '../../../../store/chat-skinning/selectors';

interface Props {
  currentBackgroundUrl: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

const BackgroundUploadButton: React.FC<Props> = ({ currentBackgroundUrl, onChange }) => {
  const [uploadingErrorMessage, setUploadingErrorMessage] = useState(null);
  const defaultBackgrounds = useSelector(selectChatSkinningBackgrounds);

  const isUploaded = useMemo(
    () =>
      currentBackgroundUrl &&
      !defaultBackgrounds.some(
        ({ original, thumbnail }) => original === currentBackgroundUrl || thumbnail === currentBackgroundUrl
      ),
    [currentBackgroundUrl, defaultBackgrounds]
  );
  const uploadedBackgroundUrl = isUploaded ? currentBackgroundUrl : null;

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setUploadingErrorMessage(null);

    const { files } = e.target;
    const filesArray = Array.from(files);
    const filesToUploadTotalSize = getFilesTotalSize(filesArray);
    if (filesToUploadTotalSize > MAX_CHAT_UPLOAD_BYTES) {
      setUploadingErrorMessage('Image too large!');
      return;
    }

    const [lastFile] = filesArray.slice(-1);

    try {
      const { fileURL } = await uploadVibeImage({ file: lastFile });
      onChange(fileURL);
    } catch (err) {
      log.error('Chat Background Upload: handleFileUpload', err);
      if (err.cause === fileUploadErrorTypes.WRONG_TYPE) {
        setUploadingErrorMessage('Wrong file type. Please try again.');
      } else {
        setUploadingErrorMessage('Something went wrong. Please try again.');
      }
    } finally {
      e.target.value = null;
    }
  };

  return (
    <>
      <BackgroundImagePreview bg={uploadedBackgroundUrl}>
        <BackgroundImageButton>
          {uploadedBackgroundUrl ? 'Change' : 'Upload'}
          <UploadInput type="file" accept="image/*" onChange={handleFileUpload} />
        </BackgroundImageButton>
      </BackgroundImagePreview>
      {uploadingErrorMessage ? <Error>{uploadingErrorMessage}</Error> : null}
    </>
  );
};

export default BackgroundUploadButton;

const BackgroundImagePreview = styled.div<{ bg: string }>`
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bg }) => (bg ? `url('${bg}');` : 'rgba(255, 255, 255, 0.3)')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  flex: 1;
  height: 100%;
`;

const BackgroundImageButton = styled.label`
  width: 55px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-foreground-alpha-80);
  font-size: 12px;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  background: var(--secondary-background-alpha-50);
  cursor: pointer;
`;

const UploadInput = styled.input`
  display: none;
`;

const Error = styled.div`
  background-color: #f6335d;
  color: white;
  margin-top: 3px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-radius: 10px;
  padding: 0 10px 0 15px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(18, 0, 45, 0.2);
`;
