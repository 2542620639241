import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';

// components
import { NewMessageButtonStyles } from '../chats-shared-styles';
import FriendsSearchableList from '../../friends/FriendsSearchableList';

// store
import { setActiveReceiverId } from '../../store/messaging/reducer';

// icons
import PlusIcon from '../../../../assets/icons/plus.svg';

const closeIcon = '/images/icons/electron-lobby/windows-close-dark.svg';

const NewMessagePopover = ({ trigger, anchor, onClick, isMessagesSidebar }) => {
  const dispatch = useDispatch();

  const [popoverOpen, setPopoverOpen] = React.useState(false);

  function onFriendClick(activeReceiverId, chatId) {
    // close NewMessagePopover
    setPopoverOpen(false);

    // This is temporary until we get rid of activeReceiverId from the store
    if (isMessagesSidebar) {
      // open dm window
      dispatch(setActiveReceiverId({ activeReceiverId, source: 'NewMessagePopover' }));
    } else {
      onClick(activeReceiverId, chatId);
    }
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <TriggerContainer>
        <PopoverTrigger asChild>
          {trigger || (
            <NewMessageButtonStyles>
              <PlusIcon />
            </NewMessageButtonStyles>
          )}
        </PopoverTrigger>

        {/* We need this PopoverAnchor here because */}
        {/* 1) By default (if there is no PopoverAnchor), popover's position based on the PopoverTrigger; */}
        {/* 2) We have a scaling hover effect for the PopoverTrigger */}
        {/* The 1) and 2) mean that **if position of the popover based on the PopoverTrigger, it will change when hovering the PopoverTrigger** -> we need to have the static PopoverAnchor so popover will be bound to it */}
        {anchor || <PopoverAnchor />}
      </TriggerContainer>

      <PopoverContent side="right" sideOffset={10}>
        <PopoverContentFlex>
          <FriendsSearchableList onFriendClick={onFriendClick} setPopoverOpen={setPopoverOpen} canSearchForFriends />
        </PopoverContentFlex>

        <PopoverArrow />
        <PopoverClose>
          <img height="13px" width="13px" src={closeIcon} alt="close" />
        </PopoverClose>
      </PopoverContent>
    </Popover>
  );
};

NewMessagePopover.propTypes = {
  trigger: PropTypes.node,
  anchor: PropTypes.node,
  onClick: PropTypes.func,
  isMessagesSidebar: PropTypes.bool,
};

NewMessagePopover.defaultProps = {
  trigger: null,
  anchor: null,
  onClick: () => {},
  isMessagesSidebar: false,
};

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = styled(PopoverPrimitive.Anchor)`
  transform: translateY(-28px);
`;

const PopoverClose = styled(PopoverPrimitive.Close)`
  all: unset;
  position: absolute;
  top: -8px;
  right: 20px;
  display: inline-flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  opacity: 0.75;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const PopoverArrow = styled(PopoverPrimitive.Arrow)`
  fill: #ffffff;
`;

const PopoverContent = styled(PopoverPrimitive.Content)`
  margin-top: -25px;
  padding: 16px 20px;
  height: 494px;
  min-width: 310px;
  overflow: hidden auto;
  background-color: #ffffff;
  border-radius: 10px;
`;

const TriggerContainer = styled.div`
  position: relative;
`;

const PopoverContentFlex = styled.div`
  max-width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default NewMessagePopover;
