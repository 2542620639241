import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, useStore } from 'react-redux';
import styled from 'styled-components';

import eventBus, { unmountSignInModalRequested } from '../../event-bus';
import { useVerifyPhone } from '../phone/useVerifyPhone.ts';
import { screensMap } from './screens/screens-map.tsx';
import { startFlow, reset, setProfileId } from '../store/signing-in/actions.ts';
import { selectCurrentScreenId } from '../store/signing-in/selectors.ts';
import { getRoomId } from '../../util';
import { FLOW_FINISHED, flows, Screens } from './constants.ts';
import HereStarburst from '../../../assets/icons/here/here-starburst-bright.svg';
import { IconContainer } from './shared-styled.ts';
import SignInBackground from './SignInBackground.tsx';
import { Logo } from '../vibe-picker/shared-styles.ts';

const pathParts = window.location.pathname.split('/');
const isUserPage = pathParts[1] === 'u';

const SignInModal = ({ flowId, profileId, onClose }) => {
  const store = useStore();

  const dispatch = useDispatch();

  const modalOverlayRef = useRef(null);

  const currentScreenId = useSelector(selectCurrentScreenId);

  const showGradientBg = useMemo(
    () => currentScreenId === Screens.CREATE_USERNAME || currentScreenId === Screens.FRIENDS_SUGGESTIONS,
    [currentScreenId]
  );

  const showHereIcon = useMemo(
    () =>
      currentScreenId !== Screens.FRIENDS_SUGGESTIONS &&
      currentScreenId !== Screens.PHONE_VERIFY_CODE &&
      currentScreenId !== Screens.SCHOOL_SELECTION,
    [currentScreenId]
  );

  const showTermsAndConditions = useMemo(
    () =>
      currentScreenId === Screens.AUTH_TYPE_SELECTION ||
      currentScreenId === Screens.EMAIL_INPUT ||
      currentScreenId === Screens.PHONE_INPUT ||
      currentScreenId === Screens.CREATE_ACCOUNT,
    [currentScreenId]
  );

  useEffect(() => {
    dispatch(startFlow({ flowId }));
    dispatch(setProfileId({ id: profileId }));
  }, [dispatch, flowId, profileId]);

  useEffect(
    () => () => {
      const state = store.getState();
      if (state?.signingIn?.screenId === FLOW_FINISHED) {
        dispatch(reset());
        onClose(state?.signingIn?.profile);
      }
    },
    [dispatch, onClose, store]
  );

  const onOverlayClick = (e) => {
    if (
      e.target === modalOverlayRef.current && // if clicked overlay itself, not bubbled event
      currentScreenId === Screens.AUTH_TYPE_SELECTION && // if the first screen
      (!getRoomId() || isUserPage) // if homepage (not room) or user page
    ) {
      eventBus.dispatch(unmountSignInModalRequested);
    }
  };

  const { sendVerificationCode } = useVerifyPhone();
  const handleSendVerificationCode = useCallback(
    (phoneNumber) => sendVerificationCode(phoneNumber),
    [sendVerificationCode]
  );

  const getScreen = useCallback(() => {
    if (currentScreenId === Screens.PHONE_INPUT || currentScreenId === Screens.PHONE_VERIFY_CODE) {
      const Component = screensMap[currentScreenId];
      return <Component handleSendVerificationCode={handleSendVerificationCode} />;
    }

    return screensMap[currentScreenId];
  }, [currentScreenId, handleSendVerificationCode]);

  return (
    <ModalOverlay ref={modalOverlayRef} showGradientBg={showGradientBg} onClick={onOverlayClick}>
      <SignInBackground />
      <Logo />
      <Modal>
        <Screen key={currentScreenId}>
          {showHereIcon && (
            <IconContainer>
              <HereStarburst />
            </IconContainer>
          )}

          {getScreen()}
        </Screen>
        {showTermsAndConditions ? (
          <Footer>
            By continuing, you are indicating that you accept our <br />
            <a href="/terms.html" target="_blank">
              Terms of Service
            </a>
            {' and '}
            <a href="/privacy.html" target="_blank">
              Privacy Policy
            </a>
            .
          </Footer>
        ) : null}
        <div id="recaptcha-container" />
      </Modal>
    </ModalOverlay>
  );
};

SignInModal.propTypes = {
  flowId: PropTypes.string,
  profileId: PropTypes.string,
  onClose: PropTypes.func,
};

SignInModal.defaultProps = {
  flowId: flows.STANDARD_ONBOARDING,
  profileId: '',
  onClose: () => {},
};

const ModalOverlay = styled.div`
  position: absolute;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000007f;
  background: ${({ showGradientBg }) =>
    showGradientBg ? 'linear-gradient(90deg, #1b01aa 0%, #55017f 100%)' : 'rgba(0, 0, 0, 0.5)'};
`;

const Modal = styled.div`
  position: relative;
  margin: auto;
  min-height: 600px;
  width: 35%;
  min-width: 520px;
  overflow: hidden;
  background-color: white;
  border-radius: 20px;

  pointer-events: all;
`;

const Screen = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: opacity linear 0.35s;
`;

const Footer = styled.div`
  margin: 0 auto;
  width: 70%;
  color: #919191;
  font-size: 12px;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 24px;

  a {
    color: #6b1be3;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default SignInModal;
