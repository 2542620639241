import firebase, { db } from '../firebase';

import { compensateParentTransform, getAverageElementPosition, getElementPosition } from '../element-transform';

export function getRelatedPinnedElements(docId, includeSelf = true) {
  const resultPinnedElements = [];

  function findPinnedElements(eDocId, initiatorElementIds = []) {
    const handler = window.elementHandlers[eDocId];
    if (!handler) {
      return;
    }

    const { pinnedElements } = handler;
    pinnedElements.forEach((pinnedElement) => {
      if (!resultPinnedElements.some((element) => element.docId === pinnedElement.docId)) {
        resultPinnedElements.push({ ...pinnedElement, initiatorElementId: `element-${eDocId}` });
      }

      if (!initiatorElementIds.includes(pinnedElement.docId)) {
        findPinnedElements(pinnedElement.docId, [...initiatorElementIds, eDocId]);
      }
    });
  }

  findPinnedElements(docId);

  const formattedPinnedElements = resultPinnedElements.map((element) => ({
    ...element,
    elementId: `element-${element.docId}`,
  }));

  if (includeSelf) {
    return formattedPinnedElements;
  }

  return formattedPinnedElements.filter(({ elementId }) => elementId !== `element-${docId}`);
}

/**
 *
 * Removes links to other items from passed element.
 * Removes links from other items to the passed element.
 *
 * @param {string} docId
 */
export async function unpinElement(docId) {
  const { pinnedElements } = window.elementHandlers[docId];

  const batch = db.batch();

  pinnedElements.forEach((pinnedElement) => {
    const handler = window.elementHandlers[pinnedElement.docId];
    if (handler) {
      const elementsWithoutUnpinned = window.elementHandlers[pinnedElement.docId].pinnedElements.filter(
        (e) => e.docId !== docId
      );

      batch.update(db.doc(`boards/${window.currentBoardId}/elements/${pinnedElement.docId}`), {
        pinnedElements: elementsWithoutUnpinned,
      });
    }
  });

  await batch.commit();

  await db
    .doc(`boards/${window.currentBoardId}/elements/${docId}`)
    .update({ pinnedElements: firebase.firestore.FieldValue.delete() });
}

export function hasPinnedElements(docId) {
  if (!window.elementHandlers[docId]) {
    throw new Error(`Element with docId=${docId} is not exist!`);
  }

  return !!(window.elementHandlers[docId].pinnedElements && window.elementHandlers[docId].pinnedElements.length);
}

export function wrapPinnedGroup(pinnedElementDOMRefs) {
  // create wrapper
  const pinnedGroupWrapper = document.createElement('div');
  pinnedGroupWrapper.classList.add('pinned-group-wrapper');

  const rotationContainer = document.createElement('div');
  rotationContainer.classList.add('pinninng-group-wrapper-rotation-container');

  const pinnedElementPositions = [];

  // move pinned group under the wrapper
  pinnedElementDOMRefs.forEach((pinnedElementDOMRef) => {
    pinnedElementDOMRef.remove();
    pinnedElementPositions.push(getElementPosition(pinnedElementDOMRef));
  });

  const averagePosition = getAverageElementPosition(pinnedElementPositions);
  pinnedGroupWrapper.style.transform = `translate3d(${Math.round(averagePosition[0])}px, ${Math.round(
    averagePosition[1]
  )}px, 0px)`;

  const compensatedElements = compensateParentTransform(pinnedElementDOMRefs, averagePosition);
  rotationContainer.append(...compensatedElements);
  pinnedGroupWrapper.appendChild(rotationContainer);
  document.getElementById('elements').appendChild(pinnedGroupWrapper);

  return { pinnedGroupWrapper, rotationContainer };
}

/**
 *
 * @param {Element} wrapperDOMRef
 */
export function unwrapPinnedGroup(wrapperDOMRef, pinnedElementDOMRefs) {
  const wrapperPosition = getElementPosition(wrapperDOMRef, false);
  const compensatedElements = compensateParentTransform(pinnedElementDOMRefs, wrapperPosition, true);
  document.getElementById('elements').append(...compensatedElements);
  wrapperDOMRef.remove();
}

export function reduceUniquePinnedElements(acc, item) {
  if (!acc.some((uniqueItem) => uniqueItem.docId === item.docId)) {
    return [...acc, item];
  }

  return acc;
}
