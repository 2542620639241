import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserAvatar from '../components/UserAvatar';
import UnfriendIcon from '../../../assets/icons/unfriend.svg';
import { buttonMixin } from '../mixins';
import { friendByIdSelector } from '../store/friends/selectors';
import { unfriend } from '../store/friends/store';
import { track } from '../../util/analytics-util';
import { UNFRIEND } from '../../constants/analytics-events/friend-events';

const UnfriendConfirmationModal = ({ userId, onClose }) => {
  // Note: right now, we're only using this confirmation off of the FriendsList so it's safe
  // to assume we don't need to dispatch a fetchFriends here.
  const friend = useSelector((state) => friendByIdSelector(state, userId));

  const dispatch = useDispatch();
  const onUnfriend = () => {
    dispatch(unfriend(userId));
    track(UNFRIEND);
    onClose();
  };

  return (
    <UnfriendConfirmation>
      <AvatarContainer>
        <UserAvatar userProfile={friend} />
      </AvatarContainer>
      {`Are you sure you want to unfriend ${friend.displayName}?`}
      <ConfirmationButtonsContainer>
        <NevermindButton onClick={onClose}>Nevermind</NevermindButton>
        <UnfriendButton onClick={onUnfriend}>
          <UnfriendIcon />
          Unfriend
        </UnfriendButton>
      </ConfirmationButtonsContainer>
    </UnfriendConfirmation>
  );
};

export default UnfriendConfirmationModal;

UnfriendConfirmationModal.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const UnfriendConfirmation = styled.div`
  background: #fff;
  border-radius: 10px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  width: 598px;
  padding: 37px;
  text-align: center;
  color: #1a1a1a;
  word-wrap: break-word;

  @media (max-width: 615px) {
    width: 78vw;
  }
`;

const AvatarContainer = styled.div`
  width: 42px;
  height: 42px;
  margin: 0 auto;
  margin-bottom: 18px;
`;

const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const NevermindButton = styled.button`
  ${buttonMixin()}

  padding-left: 34px;
  padding-right: 34px;
  background: #ededed;
  color: #1a1a1a;

  @media (max-width: 615px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const UnfriendButton = styled.button`
  ${buttonMixin()}

  padding-left: 34px;
  padding-right: 34px;
  background: #f6335d;
  color: #fff;

  display: flex;
  align-items: center;

  margin-left: 24px;

  svg {
    margin-right: 12px;
    circle {
      stroke: #fff;
    }
    path {
      stroke: #fff;
    }
  }

  @media (max-width: 615px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
