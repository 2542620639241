// Damn UIkit wants anchor tags to be used as buttons...
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChevronIcon from '../../../assets/icons/arrows/pointed-chevron-left.svg';
import { hideableMixin, svgColorMixin } from '../mixins';

const Tabs = ({ uniqueId, tabs, onTabChange, isTabsListScrollable, activeTabName }) => {
  const tabsListRef = useRef(null);
  const [shouldShowLeftArrow, setShouldShowLeftArrow] = useState(false);
  const [shouldShowRightArrow, setShouldShowRightArrow] = useState(isTabsListScrollable);
  const [activeTabInitialized, setActiveTabInitialized] = useState(false);

  const syncArrows = (newScrollingPosition) => {
    if (!isTabsListScrollable) return;
    setShouldShowLeftArrow(newScrollingPosition > 0);
    setShouldShowRightArrow(newScrollingPosition < tabsListRef.current.scrollWidth - tabsListRef.current.clientWidth);
  };

  const onTabsListScroll = (e) => {
    if (isTabsListScrollable && e.deltaY) {
      e.currentTarget.scrollLeft += e.deltaY;
    }
    syncArrows(e.currentTarget.scrollLeft);
  };

  const scrollManually = (delta) => {
    const newScrollingPosition = tabsListRef.current.scrollLeft + delta;
    tabsListRef.current.scroll({ left: newScrollingPosition, behavior: 'smooth' });
    syncArrows(newScrollingPosition);
  };

  useEffect(() => {
    if (activeTabName) {
      setTimeout(() => {
        const tabElements = tabsListRef.current?.querySelectorAll('.tab-link');
        const activeTab =
          tabElements &&
          Array.from(tabElements).find((tab) => tab.innerText.toLowerCase() === activeTabName.toLowerCase());

        if (activeTab) {
          activeTab.click();
        }

        setActiveTabInitialized(true);
      }, 25);
    }
  }, [activeTabName]);

  return (
    <Container
      className="full-height"
      hidden={!activeTabInitialized && activeTabName}
      isTabsListScrollable={isTabsListScrollable}
      shouldShowLeftArrow={shouldShowLeftArrow}
      shouldShowRightArrow={shouldShowRightArrow}
    >
      <LeftArrow onClick={() => scrollManually(-200)} isHiddenDisplay={!shouldShowLeftArrow}>
        <ChevronIcon />
      </LeftArrow>
      <RightArrow onClick={() => scrollManually(200)} isHiddenDisplay={!shouldShowRightArrow}>
        <ChevronIcon />
      </RightArrow>
      <ul
        className="tabs-header"
        data-uk-tab={`{connect: '#${uniqueId}'}`}
        onWheel={onTabsListScroll}
        ref={tabsListRef}
      >
        {tabs.map(({ name }) => (
          <li key={name} onClick={() => onTabChange(name)}>
            <a className="tab-link">{name}</a>
          </li>
        ))}
      </ul>
      <ul className="uk-switcher full-height-container" id={uniqueId}>
        {tabs.map((tab) => (
          <li key={tab.name} className="full-height">
            {tab.node}
          </li>
        ))}
      </ul>
    </Container>
  );
};

Tabs.propTypes = {
  // Stupid UIkit won't work without unique id
  uniqueId: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      node: PropTypes.node,
    })
  ).isRequired,
  onTabChange: PropTypes.func,
  isTabsListScrollable: PropTypes.bool,
  activeTabName: PropTypes.string,
};

Tabs.defaultProps = {
  onTabChange: () => {},
  isTabsListScrollable: false,
  activeTabName: '',
};

export default Tabs;

// Some styles depend on uikit-driven class names, so gonna write
// everything here.
const Container = styled.div`
  position: relative;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};

  .tabs-header {
    ${({ shouldShowLeftArrow, shouldShowRightArrow }) =>
      `-webkit-mask: linear-gradient(to right, transparent 0%, black ${shouldShowLeftArrow ? 20 : 0}%, black ${
        shouldShowRightArrow ? 80 : 100
      }%, transparent 100%) center/contain;`}
  }

  .uk-tab {
    margin-left: 0;

    &::before {
      display: none;
    }

    li {
      padding-left: 0;
    }

    li:hover,
    .uk-active {
      a {
        color: var(--primary-foreground, black);
        opacity: 1;
        font-weight: bold;
      }
    }

    a {
      color: var(--primary-foreground, black);
      opacity: 0.4;
      min-width: 40px;
      border-bottom: 1px solid var(--primary-foreground-alpha-20, rgba(0, 0, 0, 0.2));
    }

    .uk-active a {
      border-color: var(--primary-foreground, #1e87f0);
    }

    ${({ isTabsListScrollable }) =>
      isTabsListScrollable &&
      `
        flex-wrap: nowrap;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
    `}
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 3px;
  cursor: pointer;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-background, rgba(0, 0, 0, 0.1));
  border-radius: 50%;
  padding: 6px;
  box-sizing: content-box;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 0 12px var(--primary-foreground-alpha-20);

  ${svgColorMixin('var(--primary-foreground, white)')}
  ${hideableMixin()}

  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 7px;
    position: relative;
    right: 2px;
  }
`;

const LeftArrow = styled(Arrow)`
  left: 0;
`;

const RightArrow = styled(Arrow)`
  right: 0;
  transform: rotate(180deg);
`;
