import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';

// types
import { tabsToPath, tabsToTitle, WebLobbyTabs } from '../../../definitions/web-lobby';

// store
import { setActiveGroupId, setActiveTab } from './actions';
import { setActiveChatId, setActiveReceiverId } from '../messaging/reducer';
import { selectActiveReceiverId, selectDMChats } from '../messaging/selectors';

// utils
import { orderObjectsByDateField } from '../../../util/date-util';
import { GROUP_PATH } from '../../../definitions/groups';
import { selectIsInOs } from '../room/selectors';

interface HandleSetActiveTabArgs {
  payload: {
    activeTab: WebLobbyTabs;
  };
}

export const handleUpdateUrl = (activeTab: WebLobbyTabs, username: string) => {
  const newPagePath = activeTab === WebLobbyTabs.UserPage ? `/u/${username}` : tabsToPath[activeTab];

  // Don't rewrite the path and erase the query string if we aren't navigating someplace new
  // (we pass query parameters back to the lobby to show survey, show you've been banned etc)
  if (window.location.pathname !== newPagePath) {
    window.history.pushState(null, tabsToTitle[activeTab], newPagePath);
  }
  document.title = tabsToTitle[activeTab];
};

function* handleSetActiveTab({ payload: { activeTab } }: HandleSetActiveTabArgs) {
  if (activeTab !== WebLobbyTabs.DMs) {
    yield put(setActiveChatId({ activeChatId: null }));
    yield put(setActiveReceiverId({ activeReceiverId: null }));
  } else {
    const activeReceiverId: ReturnType<typeof selectActiveReceiverId> = yield select(selectActiveReceiverId);
    if (activeReceiverId === null) {
      const chatsWithMembers: ReturnType<typeof selectDMChats> = yield select(selectDMChats); // only DMs in the DM tab
      const orderedChats: ReturnType<typeof orderObjectsByDateField> = yield call(
        orderObjectsByDateField,
        chatsWithMembers,
        'updatedAt'
      );

      // This delay is a dirty hack to prevent animation glitching for the DMs tab appearing.
      yield delay(200);
      if (orderedChats[0]) {
        yield put(setActiveReceiverId({ activeReceiverId: orderedChats[0].member.userId }));
        yield put(setActiveChatId({ activeChatId: orderedChats[0].id }));
      }
    }
  }
}

function* handleSetActiveGroupId({ payload: { activeGroupId } }: { payload: { activeGroupId: string } }) {
  // hack to close all modals that are open when switching between groups:
  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  document.body.dispatchEvent(event);

  const isInOs: boolean = yield select(selectIsInOs);
  if (isInOs) return;

  // don't do this if hereos
  // set path to new groupId
  const newPagePath = `/${GROUP_PATH}/${activeGroupId}`;
  if (window.location.pathname !== newPagePath) {
    window.history.pushState(null, '', newPagePath);
  }
}

export default function* webLobbySaga() {
  yield all([takeEvery(setActiveTab, handleSetActiveTab), takeEvery(setActiveGroupId, handleSetActiveGroupId)]);
}
