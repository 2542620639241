import log from '../log';
import firebase, { api, db } from '../firebase';
import { leaveBoard } from '../presence';
import { track } from './analytics-util';
import { checkIsElectron, checkIsMobile } from './platform-util';
import { CREATE_NEW_USER_ROOM, CREATE_ROOM } from '../constants/analytics-events/rooms-events';
import { homepageConceptLocalStorageItem } from '../constants/homepage-constants';
import { VIEW_USER_PAGE } from '../constants/analytics-events/user-page-events';
import { replaceOpacityString } from './theming-util';

export async function isNewUser(user) {
  const recentlyRegistered = user.metadata.creationTime === user.metadata.lastSignInTime;
  if (!recentlyRegistered) return false;
  const userInfo = await db.collection('userProfiles').doc(user.uid).get();
  if (userInfo === null || userInfo === undefined) return true;
  const userInfoData = userInfo.data();
  return userInfoData === undefined || userInfoData.firstTimeUser === null || userInfoData.firstTimeUser === undefined;
}

export function getUserFirstName() {
  const fullName = firebase.auth().currentUser.displayName;
  if (fullName) {
    return fullName.indexOf(' ') !== -1 ? fullName.substr(0, fullName.indexOf(' ')) : fullName;
  }
  return '';
}

export function getUserAvatarColors(profile) {
  const background =
    profile?.mood?.theme?.colors?.primaryBackground || profile?.profileTheme?.colors?.primaryBackground || '#12002d';
  const foreground =
    profile?.mood?.theme?.colors?.primaryForeground || profile?.profileTheme?.colors?.primaryForeground || 'white';
  return {
    background: replaceOpacityString(background, 1),
    foreground: replaceOpacityString(foreground, 1),
  };
}

export async function isUsernameValid(username) {
  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const resp = await fetch(`${api}/username/validate`, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        username,
      }),
    });

    if (resp.status === 200) {
      return { result: true, message: 'Looking good! This username is available 🙂' };
    }

    const data = await resp.json();
    return { result: false, message: data.error };
  } catch (e) {
    track('Username update failed', { reason: 'API Failure' });
    return { result: false, message: 'Username is not available' };
  }
}

// These are accounts we use to message our users
export function isOfficialHereUser(user) {
  // TODO: eventually we should move away from checking usernames to checking something like email
  const admins = ['hereteam', 'borplehere', 'herecommunity'];

  return user && admins.includes(user.username);
}

export async function isHereEmployee(user) {
  if (user && user.getIdTokenResult) {
    const token = await user.getIdTokenResult();
    return token?.claims?.admin || false;
  }
  return false;
}

export async function isHereEmployeeCheck() {
  return isHereEmployee(firebase.auth().currentUser);
}

/**
 *
 * @param {firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>} starterBoard
 * @param {Object} [opts]
 * @param {String} [opts.templateName]
 * @param {Boolean} [opts.isNewUserRoom]
 * @param {Boolean} [opts.shouldRedirect]
 * @param {String} [opts.entryPoint]
 * @return {undefined | Object}
 */
export async function createRoom(roomData, opts = {}) {
  const { templateName } = opts || 'blank';
  const { isNewUserRoom } = opts || false;
  const { shouldRedirect } = opts || true;

  // Copy the template if we have one, then replace
  // creator and title with our own.
  const { uid } = firebase.auth().currentUser;

  const boardData = {
    background: 22,
    uid,
  };

  boardData.voiceControlEnabled = true;
  if (roomData.starterBoard) {
    boardData.templateBoardId = roomData.starterBoard.id;
    const { voiceControlEnabled } = roomData.starterBoard.data();
    boardData.voiceControlEnabled = voiceControlEnabled === undefined ? true : voiceControlEnabled;
  }

  boardData.name = roomData.title;
  boardData.type = roomData.type;
  boardData.allowAnonymous = false;
  if (roomData.joinMode) boardData.joinModeOpen = roomData.joinMode === 'waitlist';
  if (roomData.urlAlias) {
    boardData.url = roomData.urlAlias;
  } else {
    boardData.url = null;
  }

  log.debug('Creating new board with data', boardData);
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  const createRoomResp = await fetch(`${api}/room/create`, {
    headers,
    method: 'POST',
    body: JSON.stringify(boardData),
  });

  const newBoardData = await createRoomResp.json();

  if (!newBoardData.id) {
    log.error('create room failed');
    return null;
  }

  if (isNewUserRoom) {
    const user = firebase.auth().currentUser;
    const firstName = user.displayName ? user.displayName.split(' ')[0] : null;
    const homepageConcept = localStorage.getItem(homepageConceptLocalStorageItem);

    track(CREATE_NEW_USER_ROOM, {
      firstName,
      fullName: user.displayName,
      email: user.email,
      roomId: newBoardData.id,
      template: templateName,
      entryPoint: templateName ? 'homepage' : 'room deep link',
      userOrigin: checkIsElectron() ? 'electron' : 'web',
      homepageConcept,
    });
  } else {
    track(CREATE_ROOM, {
      title: boardData.name,
      roomId: newBoardData.id,
      template: templateName,
      userOrigin: checkIsElectron() ? 'electron' : 'web',
    });
  }

  if (shouldRedirect) {
    window.location.href = `/${newBoardData.id}`;
  } else {
    // Used for tracking when new room is created for new user through deep link
    // eslint-disable-next-line consistent-return
    return newBoardData;
  }
  return newBoardData.id;
}

export async function signout({ needLeave = true, newLocationUrl = '/' } = {}) {
  if (needLeave) {
    await leaveBoard(window.currentBoardId);
  }

  await firebase.auth().signOut();
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });

  document.location = newLocationUrl;
}

export function isCurrentUser(userId) {
  const currentUserId = firebase.auth().currentUser?.uid;
  return userId === currentUserId;
}

export async function getSoundEnabled(userId) {
  let resultUserId = userId;
  if (!resultUserId) {
    const { uid } = firebase.auth().currentUser;
    resultUserId = uid;
  }

  const userData = await db
    .collection('userProfiles')
    .doc(resultUserId)
    .get()
    .then((ref) => ref.data());

  return userData.soundEnabled;
}

export async function setSoundEnabled(soundEnabled) {
  const { uid } = firebase.auth().currentUser;
  await db.collection('userProfiles').doc(uid).update({ soundEnabled });
  if (soundEnabled) {
    track('Turn Sound Effects On');
  } else {
    track('Turn Sound Effects Off');
  }
}

export async function saveUserDisplayName(displayName) {
  const { currentUser } = firebase.auth();
  const authPromise = currentUser.updateProfile({ displayName });
  const firestorePromise = db.doc(`/userProfiles/${currentUser.uid}`).update({ displayName });
  await Promise.all([authPromise, firestorePromise]);
}

const updateOrCreateDocument = (collectionPath, documentId, dataToUpdate) => {
  db.collection(collectionPath)
    .doc(documentId)
    // try to update existing doc:
    .set(dataToUpdate, { merge: true })
    .catch(() => {
      // if doc doesn't exist, catch error and create new doc instead
      db.collection(collectionPath).doc(documentId).set(dataToUpdate);
    });
};

export async function trackUserPageVisit(userId, source) {
  const currentUserId = firebase.auth().currentUser?.uid;
  const isSelf = userId === currentUserId;

  track(VIEW_USER_PAGE, {
    isSelf,
    isLoggedIn: Boolean(currentUserId),
    isMobile: checkIsMobile(),
    pageOwnerId: userId,
    source,
  });

  if (!isSelf) {
    db.doc(`userProfiles/${userId}`).update({
      userPageVisitors: firebase.firestore.FieldValue.increment(1),
    });

    updateOrCreateDocument(`userPageViews/${currentUserId}/views/`, userId, {
      lastViewed: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }
}

export function hasMobileApps(userProfile) {
  return (
    (userProfile?.androidToken && userProfile?.androidToken !== '') ||
    (userProfile?.apnToken && userProfile?.apnToken !== '')
  );
}

export const isMemberRole = (role) => role === 'member' || role === undefined;

export const getFirstName = (displayName) => (displayName ? displayName.split(' ')[0] : '');
