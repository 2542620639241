import React from 'react';
import styled from 'styled-components';

// components
import { UploadAreaContainer, UploadAreaIconContainer } from '../shared-styles';
import UploadSoundLabel from './UploadSoundLabel';

interface UploadAreaErrorProps {
  errorText: string;
  onFileUploadedLocally: (file: File) => void;
  onError: (message: string) => void;
}

const UploadAreaError = ({ errorText, onFileUploadedLocally, onError }: UploadAreaErrorProps) => (
  <Container>
    <IconContainer>!</IconContainer>
    <Title>
      {errorText} <UploadSoundLabel text="Try Again" onUpload={onFileUploadedLocally} onError={onError} />
    </Title>
  </Container>
);

const Container = styled(UploadAreaContainer)`
  padding-left: 40px;
  justify-content: flex-start;
  border: 2px solid #f6335d;
`;

const IconContainer = styled(UploadAreaIconContainer)`
  color: white;
  font-size: 30px;
  font-weight: bolder;
  background-color: #f6335d;
`;

const Title = styled.h5`
  color: #f6335d;
  font-size: 14px;
  font-weight: bolder;
`;

export default UploadAreaError;
