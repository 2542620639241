import { all, takeEvery, put, select, call, take, race, delay } from 'redux-saga/effects';
import omit from 'lodash/fp/omit';

import eventBus, { loadBoardRequested } from '../../../event-bus';
import log from '../../../log';
import { loadRoom, setBadge } from '../../../electron-support/electron-support';

import {
  createChat as createChatApiRequest,
  createChatFromImage as createChatFromImageApiRequest,
  createChatFromSticker as createChatFromStickerApiRequest,
  sendMessage as sendMessageApiRequest,
  sendImageMessage as sendImageMessageApiRequest,
  deleteMessage as deleteMessageApiRequest,
  resetUnreadMessages,
  sendStickerMessage,
  sendInviteMessage,
  createChatFromInvite,
  addEmojiReactionToMessage,
  addEmoteReactionToMessage,
  getChatsListV3,
  getChatV3,
  createChatWithoutMessage as createChatWithoutMessageApiRequest,
  sendSystemMessage,
} from './api';
import { formatChatFromResponse, formatMessageFromResponse, keyMessagesById } from './normalizers';
import {
  messagesByChatIdSelector,
  activeChatIdSelector,
  selectActiveReceiverId,
  selectOldestMessageInChat,
  selectInvitePopupMessages,
  selectMessageQueue,
  selectSendingQueuedMessage,
  selectChatIdsWithActiveInviteCalls,
  selectDraftMessage,
  selectOldestChat,
  selectAllDMUnreads,
  selectActiveGroupChatId,
  selectIsNotificationsOn,
} from './selectors';
import { selectCurrentUser, selectCurrentUserId } from '../users/selectors';
import { updateUsersFromArray } from '../users/store';

import * as store from './reducer';

import { requestAsync } from '../helpers';

// utils
import { getGroupChatParams, track } from '../../../util/analytics-util';
import { playSoundEffect, soundTypes } from '../../../util/sound-fx-util';
import { checkIsElectron } from '../../../util/platform-util';

// constants
import {
  DELAY_BEFORE_FADEOUT_MS,
  INVITE_MESSAGE_DELAY_MS,
  MAX_MESSAGES_IN_PAGE,
  MAX_CHATS_IN_PAGE,
  messageTypes,
} from '../../../constants/chat-constants';
import {
  ADD_REACTION,
  CHAT_TYPE,
  CREATE_CHAT,
  EMBED_MESSAGE_CREATED,
  OPEN_DM_WINDOW,
  RECEIVE_CHAT,
} from '../../../constants/analytics-events/dm-events';
import { selectIsCurrentlyInRoom, selectIsInOs, selectRoomId } from '../room/selectors';
import { selectActiveGroupId } from '../web-lobby/selectors.ts';
import { selectOpenChatIds, selectOpenChats } from '../os/selectors.ts';
import { onboardingSystemMessages } from '../../../definitions/message.ts';
import { CHAT_RECEIVE_MESSAGE, CHAT_SEND_MESSAGE } from '../../os/analytics.ts';
import { db } from '../../../firebase';
import websocketClient from '../../../api/websocket-client.ts';
import { WebsocketEvents } from '../../../constants/websocket-events.ts';

const omitChatRelatedFields = omit(['isBanned', 'role']);

function* fetchChatsList({ payload: { isPagination = false } }) {
  try {
    let requestParams = {};
    if (isPagination) {
      const oldestChat = yield select(selectOldestChat);
      const oldestChatUpdatedAt = oldestChat ? oldestChat.chatUpdatedAt : null;

      requestParams = { before: oldestChatUpdatedAt, limit: MAX_CHATS_IN_PAGE };
    } else {
      requestParams = { before: null, limit: MAX_CHATS_IN_PAGE };
    }

    let response = null;
    response = yield call(requestAsync, () => getChatsListV3(requestParams));

    const chatsArr = Object.values(response.chats);
    const hasMoreChats = chatsArr.length === MAX_CHATS_IN_PAGE;

    const chatsResult = {};
    for (let i = 0; i < chatsArr.length; i += 1) {
      const chat = chatsArr[i];
      const currMessages = yield select((state) => messagesByChatIdSelector(state, chat.chatId));
      const draft = yield select((state) => selectDraftMessage(state, chat.chatId));
      const messages = currMessages || {};

      chatsResult[chat.chatId] = {
        ...formatChatFromResponse(chat),
        hasMore: Object.values(messages).length % MAX_MESSAGES_IN_PAGE === 0,
        messages,
        draft,
      };
    }

    // updates state with user from each chat:
    const usersToUpdate = [];
    chatsArr.forEach((chat) => {
      chat.members?.forEach((m) => usersToUpdate.push(omitChatRelatedFields(m)));
    });

    if (usersToUpdate.length) {
      yield put(updateUsersFromArray({ users: usersToUpdate }));
    }

    // once that's done, we update loading state to false:
    yield put(store.fetchChatsListSuccess({ chats: chatsResult, hasMoreChats }));
  } catch (e) {
    yield put(store.messagesError({ error: 'There was an error. Please try again.' }));
  }
}

function* openExistingDM({ payload: { chatId } }) {
  const currMessages = yield select((state) => messagesByChatIdSelector(state, chatId));
  if (!currMessages) {
    // if no messages were returned, it means that the fake chat was opened
    return;
  }

  try {
    if (!currMessages.length) {
      yield put(store.fetchChat({ chatId }));
    }

    // wait until store.fetchChat resolves then reset unread count
    yield take(store.fetchChatSuccess);

    yield put(store.resetChatUnreads({ chatId }));
  } catch (e) {
    log.error(e);
    yield put(store.messagesError({ error: 'There was an error. Please try again.' }));
  }
}

function* fetchChat({ payload: { chatId, isPagination = false } }) {
  try {
    let response = null;
    if (isPagination) {
      const oldestMessageInChat = yield select((state) => selectOldestMessageInChat(state, chatId));
      const topMessageCreatedAt = oldestMessageInChat ? oldestMessageInChat.createdAt : null;
      response = yield call(requestAsync, () =>
        getChatV3({ chatId, before: topMessageCreatedAt, limit: MAX_MESSAGES_IN_PAGE })
      );
    } else {
      response = yield call(requestAsync, () => getChatV3({ chatId, before: null, limit: MAX_MESSAGES_IN_PAGE }));
    }

    const currMessages = yield select((state) => messagesByChatIdSelector(state, chatId));
    const draft = yield select((state) => selectDraftMessage(state, chatId));

    const chat = {
      ...formatChatFromResponse(response),
      hasMore: response.messages.length === MAX_MESSAGES_IN_PAGE,
      messages: { ...currMessages, ...keyMessagesById(response.messages) },
      draft,
    };

    yield put(store.fetchChatSuccess({ chat }));
  } catch (e) {
    log.error(e);
    yield put(store.messagesError({ error: 'There was an error. Please try again.' }));
    // Removing non-existent opened chat.
    // TODO: Need to remove it only when the error is 404. But 1) API returns incorrect error code (400)
    // when the chat is not found and 2) requestAsync removes any error info. Need to fix that.
    const isInOs = yield select(selectIsInOs);
    const openChats = yield select(selectOpenChats);
    if (isInOs && openChats[chatId]) {
      const boardId = yield select(selectRoomId);
      yield call([db.doc(`boards/${boardId}/elements/${openChats[chatId].elementId}`), 'delete']);
    }
  }
}

function* createChatWithoutMessage({ payload: { receiverId, theme, background } }) {
  try {
    const response = yield call(requestAsync, () =>
      createChatWithoutMessageApiRequest({ receiverId, theme, background })
    );

    let formattedMessage = {};
    // There should be only one message in response, so:
    if (response.messages[0]) {
      formattedMessage = formatMessageFromResponse(response.messages[0]);
    }

    const formattedChat = formatChatFromResponse(response);

    const formattedChatWithMessages = {
      ...formattedChat,
      messages: { [formattedMessage.id]: formattedMessage },
    };

    // put chat in state
    yield put(store.createChatSuccess({ chat: formattedChatWithMessages }));
    // put users in state
    yield put(updateUsersFromArray({ users: response.members }));

    yield put(store.setActiveChatId({ activeChatId: response.chatId }));
  } catch (e) {
    yield put(store.messagesError({ error: `There was an error: ${e}` }));
  }
}

function* createChat({ payload }) {
  const {
    receiverId,
    text,
    roomId,
    fontColor,
    fontFamily,
    fontSize,
    messageColor,
    imageUrl,
    storagePath,
    url,
    chatId, // fake local chat id
    inviteMessageSource,
    groupId,
    theme,
    background,
  } = payload;

  try {
    let response = null;
    if (text) {
      // text messages
      response = yield call(requestAsync, () =>
        createChatApiRequest({ receiverId, text, fontColor, fontFamily, fontSize, messageColor, theme, background })
      );
      yield call(track, CREATE_CHAT, {
        messageType: messageTypes.TEXT,
        groupId,
      });
    } else if (imageUrl && storagePath) {
      // uploaded image messages
      response = yield call(requestAsync, () =>
        createChatFromImageApiRequest({ receiverId, imageUrl, storagePath, theme, background })
      );
      yield call(track, CREATE_CHAT, {
        messageType: messageTypes.IMAGE,
        groupId,
      });
    } else if (url) {
      // gif messages
      response = yield call(requestAsync, () =>
        createChatFromStickerApiRequest({ receiverId, url, background, theme })
      );
      yield call(track, CREATE_CHAT, {
        messageType: messageTypes.STICKER,
        groupId,
      });
    } else if (roomId) {
      // invite messages
      response = yield call(requestAsync, () => createChatFromInvite({ receiverId, roomId, background, theme }));
      yield call(track, CREATE_CHAT, {
        messageType: messageTypes.INVITE,
        inviteMessageSource,
        groupId,
      });
    } else {
      yield call([log, 'error'], 'Wrong sendMessage args');
    }

    // There should be only one message in response, so:
    const formattedMessage = formatMessageFromResponse(response.messages[0]);

    const formattedChatMembers = {};
    response.members.forEach((member) => {
      formattedChatMembers[member.userId] = {
        role: member.role,
      };
    });

    const formattedChatWithMessages = {
      id: response.chatId,
      chatMembers: formattedChatMembers,
      messages: { [formattedMessage.id]: formattedMessage },
    };

    // delete local fake chat
    yield put(store.removeLocalChat({ chatId }));

    yield put(store.setActiveChatId({ activeChatId: response.chatId }));

    // updates state with chat data:
    yield put(store.createChatSuccess({ chat: formattedChatWithMessages }));

    // updates state with users:
    yield put(updateUsersFromArray({ users: response.members }));

    yield put(store.queuedMessageSent());
  } catch (e) {
    yield put(store.messagesError({ error: `There was an error: ${e}` }));
    yield put(store.queuedMessageError());
  }
}

function* sendMessage({
  payload: {
    chatId,
    text,
    roomId,
    url,
    imageUrl,
    storagePath,
    fontColor,
    fontFamily,
    fontSize,
    messageColor,
    inviteMessageSource,
    groupId,
    type,
  },
}) {
  try {
    let response = null;
    const isInOs = yield select(selectIsInOs);
    const msgEvent = CHAT_SEND_MESSAGE;
    const analyticsParams = getGroupChatParams({ chatId });
    const extendedAnalyticsParams = { ...analyticsParams, chatId, source: isInOs ? 'lobby' : 'room' };
    if (type === messageTypes.SYSTEM) {
      // system messages
      if (onboardingSystemMessages.includes(text)) {
        response = yield call(requestAsync, () =>
          sendSystemMessage({ chatId, message: text, metadata: { onboardingMessage: true } })
        );
      } else {
        yield call([log, 'error'], 'Only onboarding system messages are allowed to be sent as system messages.');
      }
    } else if (text) {
      // text messages
      response = yield call(requestAsync, () =>
        sendMessageApiRequest(chatId, text, fontColor, fontFamily, fontSize, messageColor)
      );
      yield call(track, msgEvent, { messageType: 'text', ...extendedAnalyticsParams });
      if (response.message?.linkSummary?.embed) {
        yield call(track, EMBED_MESSAGE_CREATED, {
          type: response.message.linkSummary.embed.type,
          title: response.message.linkSummary.title,
          creator: response.message.linkSummary.author,
          source: groupId ? CHAT_TYPE.HUB : CHAT_TYPE.DM,
        });
      }
    } else if (imageUrl && storagePath) {
      // uploaded image messages
      response = yield call(requestAsync, () => sendImageMessageApiRequest({ chatId, imageUrl, storagePath }));
      yield call(track, msgEvent, { messageType: 'uploaded file', ...extendedAnalyticsParams });
    } else if (url) {
      // gif messages
      response = yield call(requestAsync, () => sendStickerMessage({ type: 'gif', url, chatId }));
      yield call(track, msgEvent, { messageType: 'gif', ...extendedAnalyticsParams });
    } else if (roomId) {
      // invite messages
      response = yield call(requestAsync, () => sendInviteMessage({ chatId, roomId }));
      yield call(track, msgEvent, { messageType: 'invite', inviteMessageSource, ...extendedAnalyticsParams });
    } else {
      yield call([log, 'error'], 'Wrong sendMessage args');
    }

    const formattedMessage = formatMessageFromResponse(response.message);

    // TODO: is it possible for backend to send members inside chat like it does for other requests
    // (so we don't have to create the structureChatForNormalizer):
    const structureChatForNormalizer = {
      ...response.chat,
      members: response.members,
      messages: [
        { chatId, message: text, roomId, url, imageUrl, storagePath, fontColor, fontFamily, fontSize, messageColor },
      ],
    };

    const formattedChat = formatChatFromResponse(structureChatForNormalizer);

    yield put(
      store.sendMessageSuccess({
        chat: formattedChat,
        message: formattedMessage,
      })
    );
  } catch (e) {
    yield put(store.messagesError({ error: `There was an error: ${e}` }));
    yield put(store.queuedMessageError());
  }
}

function* deleteMessage({ payload: { messageId, chatId, unreadCount } }) {
  try {
    const response = yield call(requestAsync, () => deleteMessageApiRequest(messageId, chatId));
    yield put(store.deleteMessageSuccess({ chatId: response.chatId, messageId: response.messageId, unreadCount }));
  } catch (e) {
    yield put(store.messagesError({ error: 'There was an error deleting message. Please try again.' }));
  }
}

function* handleInviteMessageReceived(chatId, messageId) {
  yield put(store.addActiveInviteCall({ chatId, messageId }));
  yield put(store.setInviteCallSoundActive({ activeInviteCall: true }));

  // because of the delay this block should be in the bottom
  const isElectron = yield call(checkIsElectron);
  if (isElectron) {
    yield delay(INVITE_MESSAGE_DELAY_MS + DELAY_BEFORE_FADEOUT_MS); // wait until melody plays
    yield put(store.removeActiveInviteCall({ messageId }));
    const chatIdsWithActiveInviteCalls = yield select(selectChatIdsWithActiveInviteCalls);
    if (!chatIdsWithActiveInviteCalls.length) {
      yield put(store.setInviteCallSoundActive({ activeInviteCall: false }));
    }
  }
}

function* handlePopupMessageReceived(message, activeReceiverId) {
  const currentUserId = yield select(selectCurrentUserId);
  if (message.userId === currentUserId) {
    return;
  }

  const isNotificationsOn = yield select((state) => selectIsNotificationsOn(state, message.chatId));
  if (!isNotificationsOn) {
    return;
  }

  const isOs = yield select(selectIsInOs);
  if (isOs) {
    const openOsChatIds = yield select(selectOpenChatIds);
    if (!openOsChatIds.includes(message.chatId)) {
      yield put(store.addPopupMessage({ message }));
    }

    return;
  }

  const activeGroupChatId = yield select(selectActiveGroupChatId);
  if (!isOs && !activeReceiverId && !activeGroupChatId) {
    yield put(store.addPopupMessage({ message }));
  }
}

function* receiveNewChat({ payload: { chat, message, members, unreadCount, lastReadMessageId } }) {
  // TODO: is it possible for backend to send members inside chat like it does for other requests
  const chatWithMembers = { ...chat, members, messages: [message] };
  const formattedChat = formatChatFromResponse(chatWithMembers);
  const chatWithMessageAndMembers = {
    ...formattedChat,
    messages: { [message.messageId]: formatMessageFromResponse(message) },
    unreadCount,
    lastReadMessageId,
  };
  yield put(store.receiveNewChatSuccess({ chat: chatWithMessageAndMembers }));
  yield put(updateUsersFromArray({ users: members }));

  const currentUserId = yield select(selectCurrentUserId);

  if (message.userId !== currentUserId) {
    track(RECEIVE_CHAT, { chatId: formattedChat.id, messageType: message.type });
  }

  // If no open DM Window, add message to pop up preview messages:
  const activeReceiverId = yield select(selectActiveReceiverId);
  const invitePopupMessages = yield select(selectInvitePopupMessages);
  if (!(invitePopupMessages.length && message.type !== messageTypes.INVITE)) {
    yield call(handlePopupMessageReceived, message, activeReceiverId);
  }

  const uid = yield select(selectCurrentUserId);
  if (message.userId !== uid) {
    if (message.type === messageTypes.INVITE) {
      yield call(handleInviteMessageReceived, chat.chatId, message.messageId);
    } else {
      const isNotificationsOn = yield select((state) => selectIsNotificationsOn(state, message.chatId));

      if (members[0]?.userId !== activeReceiverId && isNotificationsOn) {
        // If DM Window isn't currently open, play DM sound unless it's an invite:
        playSoundEffect(soundTypes.DIRECTMESSAGE);
      }
    }
  }
}

function* receiveMessage({ payload: { message, chat, members, unreadCount, lastReadMessageId } }) {
  const formattedMessage = formatMessageFromResponse(message);

  // TODO: is it possible for backend to send members inside chat like it does for other requests
  // (so we don't have to create the structureChatForNormalizer):
  const structureChatForNormalizer = { ...chat, members, messages: [message] };
  const formattedChat = formatChatFromResponse(structureChatForNormalizer);

  const activeReceiverId = yield select(selectActiveReceiverId);
  const currentUserId = yield select(selectCurrentUserId);
  const activeGroupId = yield select(selectActiveGroupId);
  // if user received a message from a chat that is currently open, reset unread count
  if (formattedChat.chatMembers[activeReceiverId] || (chat.groupId && chat.groupId === activeGroupId)) {
    yield put(store.resetChatUnreads({ chatId: formattedChat.id, lastReadMessageId }));
  }

  yield put(store.queuedMessageSent());

  yield put(
    store.receiveMessageSuccess({
      chat: formattedChat,
      message: formattedMessage,
      unreadCount: formattedChat.chatMembers[activeReceiverId] ? 0 : unreadCount, // if user received a message from a chat that is currently open, do not update unread count
      lastReadMessageId,
    })
  );

  yield put(updateUsersFromArray({ users: members }));

  if (message.userId !== currentUserId) {
    track(
      CHAT_RECEIVE_MESSAGE,
      getGroupChatParams({
        chatId: formattedChat.id,
        messageType: formattedMessage.type,
        // for system messages we also want to track the message itself so that we can target certain messages
        ...(formattedMessage.type === 'system' && { text: message.message }),
      })
    );

    yield call(handlePopupMessageReceived, message, activeReceiverId);
  }

  if (formattedMessage.metadata?.onboardingMessage) {
    // we don't want to play sound for onboarding messages
    return;
  }

  const uid = yield select(selectCurrentUserId);
  if (members[0]?.userId !== activeReceiverId && message.userId !== uid) {
    if (formattedMessage.type === messageTypes.INVITE) {
      yield call(handleInviteMessageReceived, chat.chatId, message.messageId);
    } else {
      const isNotificationsOn = yield select((state) => selectIsNotificationsOn(state, message.chatId));
      if (isNotificationsOn) {
        yield call(playSoundEffect, soundTypes.DIRECTMESSAGE);
      }
    }
  }
}

function* resetChatUnreads({ payload: { chatId, lastReadMessageId } }) {
  try {
    const { unreadCount } = yield call(requestAsync, () => resetUnreadMessages({ chatId, lastReadMessageId }));
    yield put(
      store.resetChatUnreadsSuccess({
        chatId,
        unreadCount,
      })
    );
  } catch (e) {
    yield put(store.resetChatUnreadsError({ error: `There was an error: ${e}` }));
  }
}

function* onSetActiveReceiverId({ payload: { activeReceiverId, source } }) {
  if (activeReceiverId) {
    yield call(track, OPEN_DM_WINDOW, { source });
  }
}

function* onSocketReconnect() {
  yield put(store.fetchChatsList({ isPagination: false }));
  const activeChatId = yield select((state) => activeChatIdSelector(state));
  const openChatIds = yield select(selectOpenChatIds);

  if (openChatIds.length > 0) {
    yield all(openChatIds.map((id) => put(store.fetchChat({ chatId: id }))));
    return; // don't need to fetch chat for active chat below, it will already be fetched above
  }

  if (activeChatId) {
    yield put(store.fetchChat({ chatId: activeChatId }));
  }
}

function* onRemovePopupMessage() {
  const invitePopupMessages = yield select(selectInvitePopupMessages);
  if (!invitePopupMessages.length) {
    yield put(store.setInviteCallSoundActive({ activeInviteCall: false }));
  }
}

let stopInviteDirectMessageSound = null;
function* onSetInviteCallSoundActive({ payload: { activeInviteCall } }) {
  try {
    if (activeInviteCall && !stopInviteDirectMessageSound) {
      stopInviteDirectMessageSound = yield call(playSoundEffect, soundTypes.INVITECALL);
    } else if (!activeInviteCall && stopInviteDirectMessageSound) {
      yield call(stopInviteDirectMessageSound);
      stopInviteDirectMessageSound = null;
    }
  } catch (err) {
    yield call(log.error, err);
  }
}

// Joining room from invite:
function* onJoinRoom({ payload: { chatId, messageId, room } }) {
  // if calling from DM
  if (chatId) {
    // reset unreads
    yield put(store.resetChatUnreads({ chatId, lastReadMessageId: messageId }));

    // wait until unreads are reset
    yield race([take(store.resetChatUnreadsSuccess), take(store.resetChatUnreadsError)]);

    // track joining room from invite call
    yield call(track, 'Join Room from Call', { roomId: room.id, chatId });
  }

  // open room
  const isElectron = yield call(checkIsElectron);
  if (isElectron) {
    yield call(loadRoom, room.urlAlias ? room.urlAlias : room.id);
  } else {
    const newUrl = `/${room.urlAlias ? room.urlAlias : room.id}`;

    const isCurrentlyInRoom = yield select(selectIsCurrentlyInRoom);

    if (isCurrentlyInRoom) {
      eventBus.dispatch(loadBoardRequested, {
        roomId: room.id,
        title: room.title,
        isAudioOn: window.rtc?.isAudioOn,
        isVideoOn: window.rtc?.isVideoOn,
        isPreloaded: false,
        joinSound: false,
      });

      window.history.pushState(null, document.title, newUrl);
    } else {
      window.location.href = newUrl;
    }
  }

  // dismiss room card
  yield put(store.removePopupMessage({ messageId }));
}

function* onAddMessage({ payload }) {
  const messagePayloadCopy = { ...payload };
  const createdAt = Date.now();
  const currentProfile = yield select(selectCurrentUser);

  // if we need to create a chat, we need to fake a chat object
  // 1) create a fake chat (don't forget to add message received to this chat)
  // 2) set this fake chat id to the message
  // 3) remove this fake chat just in case before saving a chat locally

  if (payload.createChat) {
    const localChatId = `${currentProfile.id}-${payload.receiverId}-${createdAt}`;
    const fakeLocalChat = {
      id: localChatId,
      background: null,
      createdAt,
      updatedAt: createdAt,
      unreadCount: 0,
      hasMore: false,
      chatMembers: {
        [payload.receiverId]: { role: 'member' },
      },
    };

    yield put(store.addLocalChat({ chat: fakeLocalChat }));

    messagePayloadCopy.chatId = fakeLocalChat.id;
  }

  yield put(
    store.putMessageInQueue({ ...messagePayloadCopy, id: `${createdAt}`, creator: currentProfile.id, createdAt })
  );
}

function* processQueuedMessages() {
  const messageQueue = yield select(selectMessageQueue);
  const sendingQueuedMessage = yield select(selectSendingQueuedMessage);

  if (!sendingQueuedMessage && messageQueue.length) {
    yield put(store.setSendingQueuedMessage({ sendingQueuedMessage: true }));

    const queuedMessage = messageQueue[0];

    if (queuedMessage.createChat) {
      yield call(createChat, { payload: queuedMessage });
    } else {
      yield call(sendMessage, { payload: queuedMessage });
    }
  }
}

function* handleAddEmojiReaction({ payload: { messageId, chatId, reaction, groupId } }) {
  try {
    const response = yield call(() => addEmojiReactionToMessage({ messageId, reaction }));

    if (response.success) {
      yield put(store.addDMReactionSuccess({ messageId, chatId, reactions: response.reactions }));
      const chatType = groupId ? CHAT_TYPE.HUB : CHAT_TYPE.DM;
      track(ADD_REACTION, getGroupChatParams({ chatId, reaction, chatType }));
    } else {
      log.error(response);
    }
  } catch (e) {
    log.error(e);
  }
}

function* sendUnreadsToElectron() {
  const unreads = yield select(selectAllDMUnreads);
  yield call(setBadge, unreads);
}

function* watchUnreadCountChanges() {
  yield takeEvery(store.fetchChatsList, sendUnreadsToElectron);
  yield takeEvery(store.deleteMessageSuccess, sendUnreadsToElectron);
  yield takeEvery(store.receiveNewChat, sendUnreadsToElectron);
  yield takeEvery(store.receiveMessageSuccess, sendUnreadsToElectron);
  yield takeEvery(store.resetChatUnreadsSuccess, sendUnreadsToElectron);
}

function* handleAddEmoteReaction({ payload: { messageId, chatId, emoteUrl, isDm, emoteType, pack } }) {
  try {
    const response = yield call(() => addEmoteReactionToMessage({ messageId, emoteUrl }));

    if (response.success) {
      yield put(store.addDMReactionSuccess({ messageId, chatId, reactions: response.reactions }));
      const chatType = isDm ? CHAT_TYPE.DM : CHAT_TYPE.HUB;
      track(ADD_REACTION, getGroupChatParams({ chatId, custom: emoteUrl, chatType, emoteType, pack }));
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleSetActiveChatId({ payload: { activeChatId } }) {
  const openChatIds = yield select(selectOpenChatIds);
  if (activeChatId) {
    yield call([websocketClient, 'emit'], WebsocketEvents.UPDATE_SUPER_PRESENCE, {
      chats: { active: activeChatId, open: openChatIds },
    });
  } else {
    yield call([websocketClient, 'emit'], WebsocketEvents.UPDATE_SUPER_PRESENCE, {
      chats: { active: null, open: openChatIds },
    });
  }
}

export default function* messagesSaga() {
  yield all([
    takeEvery(store.fetchChatsList, fetchChatsList),
    takeEvery(store.openExistingDM, openExistingDM),
    takeEvery(store.fetchChat, fetchChat),
    takeEvery(store.deleteMessage, deleteMessage),
    takeEvery(store.receiveNewChat, receiveNewChat),
    takeEvery(store.receiveMessage, receiveMessage),
    takeEvery(store.resetChatUnreads, resetChatUnreads),
    takeEvery(store.setActiveReceiverId, onSetActiveReceiverId),
    takeEvery(store.removePopupMessage, onRemovePopupMessage),
    takeEvery(store.setInviteCallSoundActive, onSetInviteCallSoundActive),
    takeEvery(store.joinRoom, onJoinRoom),
    takeEvery(store.socketReconnect, onSocketReconnect),
    takeEvery(store.addMessage, onAddMessage),
    takeEvery(store.putMessageInQueue, processQueuedMessages),
    takeEvery(store.queuedMessageSent, processQueuedMessages),
    takeEvery(store.queuedMessageError, processQueuedMessages),
    takeEvery(store.resendFailedMessage, processQueuedMessages),
    takeEvery(store.addDMEmojiReaction, handleAddEmojiReaction),
    takeEvery(store.addDMEmoteReaction, handleAddEmoteReaction),
    takeEvery(store.createChatWithoutMessage, createChatWithoutMessage),
    takeEvery(store.setActiveChatId, handleSetActiveChatId),
  ]);

  yield watchUnreadCountChanges();
}
