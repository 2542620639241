const INPUT_DEBOUNCE_TIMEOUT = 1000;

class BackgroundPicker extends HTMLElement {
  async loadPopularImages(page = 1) {
    await this.retrieveImages('getPopularImages', page);
    this.loadNextImages = () => this.loadPopularImages(page + 1);
  }

  async searchImages(query, page = 1) {
    await this.retrieveImages('searchImages', query, page);
    this.loadNextImages = () => this.searchImages(query, page + 1);
  }

  onSearchResultsScroll(e) {
    const { target } = e;
    // Results area height is in 'vh' units, so there might be rounding error
    const maxScrollHeight = target.scrollHeight - 1;
    if (!this.isLoading && target.offsetHeight + target.scrollTop >= maxScrollHeight) {
      this.loadNextImages();
    }
  }

  onSearchInput(e) {
    const { value } = e.target;
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = window.setTimeout(() => {
      this.searchResults.innerHTML = '';
      if (value) {
        this.searchImages(e.target.value);
      } else {
        this.loadPopularImages();
      }
    }, INPUT_DEBOUNCE_TIMEOUT);
  }

  retrieveImages() {
    throw new Error('retrieveImages method should be overwritten!');
  }
}

export default BackgroundPicker;
