import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { RoomContainer, RoomsList, Title } from '../shared-styles';
import { selectVisitedRooms } from '../../../../store/visited-rooms/selectors';
import { selectVisibleRoomIdsArray } from '../../../../store/web-lobby/selectors';
import { useRoomsListMembers } from '../../../../web-lobby/hooks/useRoomsListWithMembers';
import ObservableRoomItem from '../../../rooms/ObservableRoomItem';
import { RoomServiceResponse } from '../../../../../definitions/room';
import RoomItem from '../../../../web-lobby/components/room-items/RoomItem';
import RoomItemMembersList from '../../../../web-lobby/components/room-items/components/RoomItemMembersList';
import { transferRoomToGroupRequest } from '../../../../store/groups/actions';
import { track } from '../../../../../util/analytics-util';
import { ATTACH_EXISTING_ROOM_TO_GROUP } from '../../../../../constants/analytics-events/groups-events';
import { selectBoardsByGroupId } from '../../../../store/messaging/selectors';

interface Props {
  groupId: string;
  goBack: () => void;
}

const LinkRoomScreen: React.FC<Props> = ({ groupId, goBack }) => {
  const dispatch = useDispatch();
  const groupRooms = useSelector((state) => selectBoardsByGroupId(state, groupId));
  const visitedRooms = useSelector(selectVisitedRooms);

  const filteredRooms = useMemo(
    () => visitedRooms.filter((room) => !groupRooms.find((groupRoom) => groupRoom.id === room.id)),
    [groupRooms, visitedRooms]
  );

  const visibleRoomIds = useSelector(selectVisibleRoomIdsArray);
  const { roomsListWithMembers } = useRoomsListMembers(filteredRooms, visibleRoomIds);

  const onRoomClick = (roomId: string) => {
    dispatch(transferRoomToGroupRequest({ roomId, groupId }));
    track(ATTACH_EXISTING_ROOM_TO_GROUP, { groupId, roomId });
    goBack();
  };

  return (
    <>
      <Header>
        <LinkRoomTitle>Link a room to the chat</LinkRoomTitle>
        <BackButton onClick={goBack}>Back</BackButton>
      </Header>
      <RoomsList>
        {roomsListWithMembers.map((r: RoomServiceResponse & { members: object[] }) => (
          <RoomContainer key={r.id}>
            <ObservableRoomItem roomId={r.id}>
              <RoomItem
                title={r.title}
                roomData={r}
                onClickOverride={() => onRoomClick(r.id)}
                membersListComponent={<RoomItemMembersList members={r.members} />}
              />
            </ObservableRoomItem>
          </RoomContainer>
        ))}
      </RoomsList>
    </>
  );
};

export default LinkRoomScreen;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
`;

const LinkRoomTitle = styled(Title)`
  margin: 0;
`;

const BackButton = styled.div`
  padding: 3px 8px;
  opacity: 0.5;
  font-size: 12px;
  background: var(--secondary-background);
  color: var(--secondary-foreground);
  cursor: pointer;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
`;
