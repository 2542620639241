import log from './log';

let context: AudioContext = null;

const mediaStreamSources: { [streamId: string]: MediaStreamAudioSourceNode } = {};

export function addAudioTrack(track: MediaStreamTrack, streamId: string) {
  if (!context) {
    context = new AudioContext();
  }
  log.debug('Adding track to mixer: ', streamId, track);
  if (mediaStreamSources[streamId]) {
    log.debug('Replacing existing track');
    mediaStreamSources[streamId].disconnect();
    const { mediaStream } = mediaStreamSources[streamId];
    const mediaStreamAudio = mediaStream.getAudioTracks();
    if (mediaStreamAudio?.length > 0) {
      mediaStreamAudio[0].enabled = false;
    }
  }
  const newTrack = track.clone();
  newTrack.enabled = true;
  const newStream = new MediaStream([newTrack]); // must be an array
  const newSource = context.createMediaStreamSource(newStream);
  newSource.connect(context.destination);
  mediaStreamSources[streamId] = newSource;
}
