import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { addCustomKaomoji, defaultKaomojisFetched, fetchDefaultKaomojis, removeCustomKaomoji } from './actions';
import * as api from '../../../api/kaomoji-api';
import { Kaomoji, KaomojiCategory } from './init-state';
import log from '../../../log';
import { selectCustomKaomojis } from './selectors';
import { getHubTrackParams, track } from '../../../util/analytics-util';
import { ADD_KAOMOJI, REMOVE_KAOMOJI } from './analytics';

function* handleFetchDefaultKaomojis() {
  try {
    const categories: KaomojiCategory[] = yield call([api, 'getDefaultKaomojis']);
    yield put(defaultKaomojisFetched({ categories }));
  } catch (error) {
    log.error(error);
  }
}

function* handleAddCustomKaomoji({ payload: { content } }: PayloadAction<{ content: string }>) {
  try {
    const customKaomojis: Kaomoji[] = yield select(selectCustomKaomojis);
    yield call([api, 'addCustomKaomoji'], content);
    yield call(track, ADD_KAOMOJI, getHubTrackParams({ kaomoji: content, count: customKaomojis.length + 1 }));
  } catch (error) {
    log.error(error);
  }
}

function* handleRemoveCustomKaomoji({ payload: { content } }: PayloadAction<{ content: string }>) {
  try {
    const customKaomojis: Kaomoji[] = yield select(selectCustomKaomojis);
    yield call([api, 'removeCustomKaomoji'], content);
    yield call(track, REMOVE_KAOMOJI, getHubTrackParams({ kaomoji: content, count: customKaomojis.length - 1 }));
  } catch (error) {
    log.error(error);
  }
}

export default function* kaomojiSaga() {
  yield all([
    takeLatest(fetchDefaultKaomojis, handleFetchDefaultKaomojis),
    takeLatest(addCustomKaomoji, handleAddCustomKaomoji),
    takeLatest(removeCustomKaomoji, handleRemoveCustomKaomoji),
  ]);
}
