import traverse from 'traverse';
import firebase from '../../firebase';

export default () => (next) => (action) => {
  traverse(action.payload).forEach(function onValue(value) {
    if (value instanceof firebase.firestore.Timestamp) {
      this.update(value.toMillis());
    }
  });

  return next(action);
};
