import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

// store
import { selectIsRoomVisible } from '../../../store/web-lobby/selectors.ts';

// components
import { ContextMenuButton } from './RoomItemContextMenu';
import {
  RoomItemTile,
  RoomItemTitle,
  HereClubLogoContainer,
  TileContainer,
  TileLoadingBlock,
  TitleContainer,
  RoomActiveLabel,
  TileImage,
  tileCoverMixin,
  RoomActiveLabelTitle,
  RoomActiveLabelSubtitle,
  SendOutsideReactionLabel,
} from '../../shared-styles.ts';
import { checkIsElectron } from '../../../../util/platform-util';
import { loadRoom } from '../../../../electron-support/electron-support';
import { selectIsInActualRoom } from '../../../store/room/selectors';
import { jumpIntoRoom } from '../../../../util/room-util';

const HereClubLogo = () => (
  <HereClubLogoContainer>
    <img width="60" src="images/misc/here-logo-sparkle.gif" alt="Here Logo" />
  </HereClubLogoContainer>
);

const RoomItem = ({
  roomData,
  title,
  onClickOverride,
  contextMenuComponent,
  outsideReactionComponent,
  membersListComponent,
}) => {
  const ref = useRef(null);

  const isOnScreen = useSelector((state) => selectIsRoomVisible(state, roomData.id));
  const isInRoom = useSelector(selectIsInActualRoom);

  const [bgImageFetched, setBgImageFetched] = useState(() => !roomData.background);

  const onTileImageLoad = useCallback(() => {
    setBgImageFetched(true);
  }, []);

  const isActive = !!roomData.members?.length;
  const roomItemHref = `/${roomData.urlAlias || roomData.id}?skip-preview=true`;
  const backgroundUrl = Number.isInteger(roomData.background)
    ? `/images/bg/${roomData.background}.jpg`
    : roomData.background;

  // onClick if user is not already currently in a room
  const goIntoRoom = () => {
    if (checkIsElectron()) {
      loadRoom(roomData.id);
    } else {
      window.location.href = roomItemHref;
    }
  };

  // onClick if user is already currently in a room
  const goToDifferentRoom = useCallback(() => {
    jumpIntoRoom({ roomId: roomData.id, title });
  }, [roomData.id, title]);

  const onTileClick = () => {
    if (onClickOverride) {
      onClickOverride();
      return;
    }

    if (isInRoom) {
      goToDifferentRoom();
    } else {
      goIntoRoom();
    }
  };

  return (
    <RoomItemContainer ref={ref}>
      <RoomItemTile>
        <TileContainer onClick={onTileClick} isActive={isActive}>
          {!bgImageFetched ? <TileLoadingBlock isOnScreen={isOnScreen} /> : null}

          {backgroundUrl ? (
            <TileImage src={backgroundUrl} bgImageFetched={bgImageFetched} loading="lazy" onLoad={onTileImageLoad} />
          ) : (
            <TileColor tileColor={roomData.backgroundColor || '#6b1be3'} />
          )}

          <OutsideFallingReactionContainer id={`room-${roomData.id}-outside-falling-reactions-container`} />
          <OutsideScaleInReactionContainer id={`room-${roomData.id}-outside-scale-in-reactions-container`} />

          {isActive ? (
            <RoomActiveLabel>
              <RoomActiveLabelTitle>Active now</RoomActiveLabelTitle>
              <RoomActiveLabelSubtitle>
                {roomData.members.length > 1 ? `${roomData.members.length} members` : '1 member'}
              </RoomActiveLabelSubtitle>
            </RoomActiveLabel>
          ) : null}

          {roomData.isHereClub && <HereClubLogo />}

          {membersListComponent}
        </TileContainer>

        {isActive && outsideReactionComponent ? (
          <SendOutsideReactionLabel onClick={(e) => e.stopPropagation()}>
            {outsideReactionComponent}
          </SendOutsideReactionLabel>
        ) : null}

        {contextMenuComponent}
      </RoomItemTile>

      <RoomItemTitle>
        <TitleContainer>{title}</TitleContainer>
      </RoomItemTitle>
    </RoomItemContainer>
  );
};

export default RoomItem;

RoomItem.propTypes = {
  title: PropTypes.node.isRequired,
  roomData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundColor: PropTypes.string,
    urlAlias: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.shape({})),
    isHereClub: PropTypes.bool,
    groupName: PropTypes.string,
    groupId: PropTypes.string,
  }).isRequired,
  onClickOverride: PropTypes.func,
  contextMenuComponent: PropTypes.node,
  outsideReactionComponent: PropTypes.node,
  membersListComponent: PropTypes.node.isRequired,
};

RoomItem.defaultProps = {
  onClickOverride: null,
  contextMenuComponent: null,
  outsideReactionComponent: null,
};

const OutsideScaleInReactionContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const OutsideFallingReactionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const TileColor = styled.div`
  ${tileCoverMixin()}

  height: 100%;
  background-color: ${({ tileColor }) => tileColor};
`;

export const RoomItemContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &:hover {
    ${ContextMenuButton} {
      display: flex;
    }
  }
`;
