import { SuggestionReasons } from '../../react/hooks/useFriendsSuggestions.tsx';

export const ACCEPT_FRIEND = 'Friend Request Accepted'; // pass `source` and `requesterId` as params
export const IGNORE_FRIEND = 'Friend Request Ignored'; // pass `source` as param

export const REQUEST_FRIEND = 'Friend Request Sent';
// Params:
// `source` (one of FRIEND_REQUEST_SOURCES below)
// `receiverId`
// `suggestionReason` (only needed if `source` is FRIEND_SUGGESTION, should be one of FRIEND_SUGGESTION_REASONS below)
export const FRIEND_REQUEST_SOURCES = {
  CHAT_HEAD_MENU: 'chat head menu',
  NEW_MEMBER_SUGGESTION: 'new member suggestion',
  MEMBER_OVERFLOW_MENU: 'member overflow menu',
  SMALL_USER_PROFILE: 'small user profile',
  ROOM_MEMBER_LIST: 'room member list',
  ROOM_CHAT_FEED: 'room chat feed',
  FRIENDS_MODAL: 'friends modal',
  FRIENDS_PAGE: 'friends page',
  OS_PAGE: 'os page',
  FRIEND_SUGGESTION: 'friend suggestion',
  ONBOARDING_FRIEND_SUGGESTION: 'onboarding friend suggestion',
  FRIEND_REQUEST_ALERT: 'friend request alert',
  FRIEND_LIST_MORE_MENU: 'friends list more menu',
  IN_ROOM_MEMBER_MENU: 'in room member menu',
  MINI_PROFILE_FROM_FRIEND_LIST: 'mini profile from friends list',
  OUTSIDE_REACTION_SUGGESTION: 'outside reaction suggestion',
  INVITE_RECENT_VISITOR_MODAL: 'invite recent visitor modal',
  HUB_CHAT: 'hub chat',
  USER_FRIENDS_MODAL: 'user friends modal',
};

// Note: the keys below need to match the friend suggestion sources sent from the backend
export const FRIEND_SUGGESTION_REASONS = {
  [SuggestionReasons.MUTUAL_FRIEND]: 'mutual friend suggestion',
  [SuggestionReasons.DOMAIN_SUGGESTION]: 'domain suggestion',
  [SuggestionReasons.COMMON_ROOM_MEMBERSHIP]: 'common room membership suggestion',
  [SuggestionReasons.CONTACT_BOOK]: 'contact book suggestion',
  [SuggestionReasons.SAME_SCHOOL]: 'same school',
};

export const UNFRIEND = 'Unfriended';
export const VIEW_FRIENDS = 'Friend List Viewed'; // pass source as param

export const FRIENDS_SUGGESTED = 'Friends Were Suggested'; // pass source as param

export const HIT_FRIEND_REQUEST_LIMIT = 'Hit Friend Request Limit';
// triggered whenever a set limit of friend requests has been hit (spamming friend requests)
// params: source
