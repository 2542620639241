import UIkit from 'uikit';

import AirtableElement from './airtable';
import FigmaElement from './figma';
import PdfElement from './pdf';
import TrelloElement from './trello';
import GSuiteElement from './gsuite';

const handlers = [AirtableElement, FigmaElement, TrelloElement, PdfElement, GSuiteElement];

export default function getAddElementFunctionForUrl(url) {
  for (let i = 0; i < handlers.length; i += 1) {
    const embedUrl = handlers[i].getEmbedUrl(url);
    if (embedUrl) {
      return handlers[i].addElement.bind(handlers[i], embedUrl, { originalUrl: url, isEmbeddedResource: true });
    }
  }

  return null;
}

export function setupEmbeddedResourceUrlHandler() {
  document.getElementById('embedded-resource-url').addEventListener('keydown', async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    } else {
      return;
    }

    const resourceUrl = e.target.value.trim();
    const addElementFunction = getAddElementFunctionForUrl(resourceUrl);

    if (addElementFunction) {
      addElementFunction();
      e.target.value = '';
      UIkit.dropdown('.file-upload-dialog').hide();
    } else {
      // TODO Error messaging
    }
  });

  document.getElementById('embedded-resource-submit').addEventListener('click', () => {
    const input = document.getElementById('embedded-resource-url');
    const resourceUrl = input.value.trim();
    const addElementFunction = getAddElementFunctionForUrl(resourceUrl);

    if (addElementFunction) {
      addElementFunction();
      input.value = '';
      UIkit.dropdown('.file-upload-dialog').hide();
    } else {
      // TODO Error messaging
    }
  });
}
