import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import RoomItemMessagePreview from './RoomItemMessagePreview';

const START_DISPLAY_IN_MS = 10;
const CSS_TRANSITION_TIMEOUT_IN_MS = 300;
const STOP_DISPLAY_IN_MS = 4900;
const UNMOUNT_IN_MS = 5000;

const cssTransitionName = 'message';

const RoomItemMessagePreviewWithAnimation = ({ message, unmount }) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    // animate upon enter
    const triggerEnterTimeout = setTimeout(() => {
      setShowMessage(true);
    }, START_DISPLAY_IN_MS);

    return () => clearTimeout(triggerEnterTimeout);
  }, []);

  useEffect(() => {
    // animate upon exit
    const triggerExitTimeout = setTimeout(() => {
      setShowMessage(false);
    }, STOP_DISPLAY_IN_MS);
    // unmount whole component after exit
    const unmountTimeout = setTimeout(() => {
      unmount();
    }, UNMOUNT_IN_MS);

    return () => {
      clearTimeout(triggerExitTimeout);
      clearTimeout(unmountTimeout);
    };
  }, [unmount]);

  return (
    <Container>
      <CSSTransition
        in={showMessage}
        classNames={cssTransitionName}
        timeout={CSS_TRANSITION_TIMEOUT_IN_MS}
        unmountOnExit
      >
        <MessagePreviewContainer>
          <RoomItemMessagePreview
            // key is needed to re-trigger animation
            // for consecutive messages:
            key={message?.id}
            message={message}
          />
        </MessagePreviewContainer>
      </CSSTransition>
    </Container>
  );
};

export default RoomItemMessagePreviewWithAnimation;

RoomItemMessagePreviewWithAnimation.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
  }).isRequired,
  unmount: PropTypes.func.isRequired,
};

const Container = styled.div`
  .${cssTransitionName}-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  .${cssTransitionName}-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 300ms, transform 300ms;
  }
  .${cssTransitionName}-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  .${cssTransitionName}-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 300ms, transform 300ms;
  }
`;

const MessagePreviewContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 70px;
  height: 22px;

  padding: 0 20px;
  width: 100%;
`;
