import { Country } from 'react-phone-number-input';
import { Screens } from '../../sign-in-flow/constants';

export interface SigningInState {
  flowId: string;
  screenId: string;
  lastInteractedScreenId: string;

  profile: {
    id: string;
    email: string;
    displayName: string;
    username: string;
  };

  screens: {
    [Screens.EMAIL_INPUT]: {
      error: string;
    };

    [Screens.PHONE_INPUT]: {
      domesticPhoneNumber: string;
      countryCode: Country;
    };

    [Screens.PHONE_VERIFY_CODE]: {
      verificationId: string;
    };

    [Screens.EMAIL_SIGN_IN]: {
      error: string;
    };

    [Screens.RESET_PASSWORD]: {
      error: string;
    };

    [Screens.CREATE_ACCOUNT]: {
      emailError: string;
      passwordError: string;
    };

    [Screens.CREATE_USERNAME]: {
      message: string;
      isChecking: boolean;
      isValid: boolean;
      isGenerating: boolean;
    };

    [Screens.CREATE_USERNAME_AND_NAME]: {
      message: string;
      isChecking: boolean;
      isValid: boolean;
    };

    [Screens.FRIENDS_SUGGESTIONS]: {
      suggestions: Record<string, unknown>[];
      nextPage: string;
    };
  };
}

export const initialState: SigningInState = {
  flowId: '',
  screenId: '',
  lastInteractedScreenId: '',

  profile: {
    id: '',
    email: '',
    displayName: '',
    username: '',
  },

  screens: {
    [Screens.EMAIL_INPUT]: {
      error: '',
    },

    [Screens.PHONE_INPUT]: {
      domesticPhoneNumber: '',
      countryCode: 'US',
    },

    [Screens.PHONE_VERIFY_CODE]: {
      verificationId: '',
    },

    [Screens.EMAIL_SIGN_IN]: {
      error: '',
    },

    [Screens.RESET_PASSWORD]: {
      error: '',
    },

    [Screens.CREATE_ACCOUNT]: {
      emailError: '',
      passwordError: '',
    },

    [Screens.CREATE_USERNAME]: {
      message: '',
      isValid: false,
      isChecking: false,
      isGenerating: true,
    },

    [Screens.CREATE_USERNAME_AND_NAME]: {
      message: '',
      isValid: true,
      isChecking: false,
    },

    [Screens.FRIENDS_SUGGESTIONS]: {
      suggestions: [],
      nextPage: null,
    },
  },
};
