import { authedApiInstance } from '../firebase';

interface DefaultImageOptionData {
  title: string;
}

export interface DefaultImageOption {
  id: string;
  url: string;
  data?: DefaultImageOptionData;
}

interface GetDefaultAvatarsResponse {
  success: boolean;
  defaultAvatars: DefaultImageOption[];
}

interface GetDefaultBackgroundsResponse {
  success: boolean;
  defaultBackgrounds: DefaultImageOption[];
}

export const getDefaultAvatars = () =>
  authedApiInstance.get<GetDefaultAvatarsResponse>('/user/defaultAvatars').then((res) => res.data);

export const getDefaultBackgrounds = () =>
  authedApiInstance.get<GetDefaultBackgroundsResponse>('/user/defaultBackgrounds').then((res) => res.data);
