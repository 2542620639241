import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CameraIcon from '../icons/CameraIcon';
import UserAvatar from '../components/UserAvatar';
import HereOfficialBadge from '../../../assets/icons/here-official-badge.svg';
import animationData from '../../../assets/lottie/avatar_sparkles.json';
import LottieAnimation from '../animations/LottieAnimation';
import { svgColorMixin } from '../mixins';
import { EventSourceContext } from '../common/contexts.ts';
import Portal from '../components/Portal';
import AvatarPicker from '../avatar-picker/AvatarPicker.tsx';
import BackgroundPicker from '../avatar-picker/BackgroundPicker.tsx';
import PickerModal from '../avatar-picker/PickerModal.tsx';

const ProfileHeader = ({ userId, userProfile, isEditing, isOfficialHereUser, isAvatarButtonHighlighted }) => {
  const onlineMember = useSelector((state) => state.members.online[userId]);

  const source = useContext(EventSourceContext);

  const [isAvatarPickerOpen, setIsAvatarPickerOpen] = useState(false);
  const [isBackgroundPickerOpen, setIsBackgroundPickerOpen] = useState(false);

  const closeAvatarPicker = () => {
    setIsAvatarPickerOpen(false);
    setIsBackgroundPickerOpen(false);
  };

  const onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeAvatarPicker();
    }
  };

  return (
    <ProfileHeaderContainer>
      {isEditing && (
        <EditPhotoButton type="background" top="10px" left="10px" onClick={() => setIsBackgroundPickerOpen(true)} />
      )}
      {isEditing && <HoverCover />}

      <BackgroundPhoto
        url={userProfile?.backgroundPhoto?.thumbnail || userProfile?.backgroundPhoto?.original}
        color={onlineMember ? onlineMember.color : 'default'}
      />
      <AbsoluteWrapper>
        <FlexWrapper>
          <AvatarContainer>
            <UserAvatar userProfile={userProfile} borderRadius="46px" borderWidth={4} />
          </AvatarContainer>
          {isOfficialHereUser && (
            <HereOfficialBadgeContainer>
              <HereOfficialBadge />
              <AnimationContainer>
                <LottieAnimation animationData={animationData} loop />
              </AnimationContainer>
            </HereOfficialBadgeContainer>
          )}
          {isEditing && (
            <EditPhotoButton
              type="avatar"
              bottom="-28px"
              right="72px"
              isHighlighted={isAvatarButtonHighlighted}
              onClick={() => setIsAvatarPickerOpen(true)}
            />
          )}
        </FlexWrapper>
      </AbsoluteWrapper>

      {isAvatarPickerOpen || isBackgroundPickerOpen ? (
        <Portal root={document.body} id="picker-modal">
          <Overlay className="prevent-profile-closing" onClick={onOverlayClick}>
            <PickerModal close={closeAvatarPicker}>
              {isAvatarPickerOpen ? <AvatarPicker source={source} close={closeAvatarPicker} /> : null}
              {isBackgroundPickerOpen ? <BackgroundPicker source={source} close={closeAvatarPicker} /> : null}
            </PickerModal>
          </Overlay>
        </Portal>
      ) : null}
    </ProfileHeaderContainer>
  );
};

export default ProfileHeader;

ProfileHeader.propTypes = {
  userId: PropTypes.string.isRequired,
  userProfile: PropTypes.shape({
    backgroundPhoto: PropTypes.shape({
      thumbnail: PropTypes.string,
      original: PropTypes.string,
    }),
    mood: PropTypes.shape({}),
  }),
  isEditing: PropTypes.bool,
  isOfficialHereUser: PropTypes.bool,
  isAvatarButtonHighlighted: PropTypes.bool,
};

ProfileHeader.defaultProps = {
  isEditing: false,
  userProfile: null,
  isOfficialHereUser: false,
  isAvatarButtonHighlighted: false,
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const EditPhotoButton = ({ top, right, bottom, left, type, isHighlighted, onClick }) => (
  <CameraButtonWrapper>
    <CameraButton
      className={`upload-${type}-input${isHighlighted ? ' highlight-round-pulse' : ''}`}
      htmlFor={`upload-${type}-input`}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      onClick={onClick}
    >
      <CameraIcon width="18" />
    </CameraButton>
  </CameraButtonWrapper>
);

EditPhotoButton.propTypes = {
  type: PropTypes.string.isRequired,
  top: PropTypes.string,
  bottom: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  isHighlighted: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

EditPhotoButton.defaultProps = {
  top: '',
  bottom: '',
  right: '',
  left: '',
  isHighlighted: false,
};

export const ProfileHeaderContainer = styled.div`
  position: relative;
  background: var(--primary-background, #12002d);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const HereOfficialBadgeContainer = styled.div`
  position: absolute;
  right: 64px;

  width: 55px;
`;

const AnimationContainer = styled.div`
  position: absolute;

  top: -15px;
  left: -15px;

  height: 90px;
  width: 90px;
`;

const BackgroundPhoto = styled.div`
  background: url(${({ url, color }) => url || `/images/profile-backgrounds/${color}.svg`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 120px;
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const CameraButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const CameraButton = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background: #413357;
  border-radius: 50%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px var(--primary-background, #12002d) solid;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  ${svgColorMixin('var(--secondary-foreground, white)')}

  /* fix an issue when pulsation animation becomes clickable */
  &::before,
  &::after {
    pointer-events: none;
  }
`;

const HoverCover = styled.div`
  background: rgba(18, 0, 45, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
`;

export const AvatarContainer = styled.div`
  position: absolute;

  top: 0px;
  width: 136px;
  height: 136px;
  border-radius: 50px;
  font-size: 2em;
`;
