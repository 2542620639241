import React, { memo, useMemo, useRef } from 'react';
import styled from 'styled-components';
import UserAvatar from '../../components/UserAvatar';
import { openUserProfile } from '../../user-profile/utils';
import { clamp } from '../../../util';

interface Props {
  memberIds: string[]; // array of member ids
  avatarSize?: number;
  maxVisibleAvatars?: number;
  onOverflowClick?: () => void;
}

const MembersAvatarsList: React.FC<Props> = ({ memberIds, avatarSize, maxVisibleAvatars, onOverflowClick }) => {
  const { visibleMemberIds, hiddenMembersCount } = useMemo(
    () => ({
      visibleMemberIds: maxVisibleAvatars ? memberIds.slice(0, maxVisibleAvatars) : memberIds,
      hiddenMembersCount: maxVisibleAvatars ? clamp(memberIds.length - maxVisibleAvatars, 0, memberIds.length) : 0,
    }),
    [memberIds, maxVisibleAvatars]
  );

  const profileButtonRef = useRef(null);
  const onProfileButtonClick = (userId: string) => {
    if (profileButtonRef.current?.className.includes('open')) {
      profileButtonRef.current?.classList.remove('open');
    } else {
      profileButtonRef.current?.classList.add('open');

      const coordinates = profileButtonRef.current?.getBoundingClientRect();

      const onClickOutside = (e: Event) => {
        const isClickedOnAvatar = profileButtonRef.current?.contains(e.target);
        if (!isClickedOnAvatar) {
          profileButtonRef.current?.classList.remove('open');
          document.removeEventListener('click', onClickOutside);
        }
      };

      document.addEventListener('click', onClickOutside);

      openUserProfile({
        userId,
        source: 'lobby > chat header member list',
        topPosition: coordinates?.top + coordinates?.height,
        leftPosition: coordinates?.left + coordinates?.width,
      });
    }
  };

  return (
    <Container>
      <AvatarsContainer avatarSize={avatarSize}>
        {visibleMemberIds.map((memberId) => (
          <AvatarContainer
            ref={profileButtonRef}
            key={memberId}
            onClick={() => onProfileButtonClick(memberId)}
            avatarSize={avatarSize}
          >
            <UserAvatar userId={memberId} />
          </AvatarContainer>
        ))}
      </AvatarsContainer>
      {hiddenMembersCount > 0 && (
        <HiddenMembersCount avatarSize={avatarSize} count={hiddenMembersCount} onClick={onOverflowClick}>
          {`+${hiddenMembersCount}`}
        </HiddenMembersCount>
      )}
    </Container>
  );
};

export default memo(MembersAvatarsList);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarsContainer = styled.div<{ avatarSize?: number }>`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: -${({ avatarSize }) => (avatarSize ? avatarSize / 2.5 : 15)}px;
  }
`;

const AvatarContainer = styled.div<{ avatarSize?: number }>`
  height: ${({ avatarSize }) => avatarSize || '35'}px;
  width: ${({ avatarSize }) => avatarSize || '35'}px;
  border-radius: 30px;
  border: 2px solid white;
  cursor: pointer;
`;

const HiddenMembersCount = styled.div<{ avatarSize?: number; count: number; onClick?: () => void }>`
  height: ${({ avatarSize }) => avatarSize || '35'}px;
  width: ${({ avatarSize }) => avatarSize || '35'}px;
  border-radius: 30px;
  border: 2px solid white;
  color: white;
  margin-left: 4px;
  background-color: inherit;
  font-size: ${({ count }) => (count < 99 ? '11px' : '9px')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;
