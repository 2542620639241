import $ from 'jquery';

import UIkit from 'uikit';
import CameraElement from './camera';
import ScreenshareElement from './screenshare';
import SpawnElement from './spawn';
import TextElement from './notes';
import WarpElement from './warp';
import CobrowserElement from './cobrowser';
import TrelloElement from './widgets/embedded/trello';
import FigmaElement from './widgets/embedded/figma';
import AirtableElement from './widgets/embedded/airtable';
import PdfElement from './widgets/embedded/pdf';
import GSuiteElement from './widgets/embedded/gsuite';
import ChatElement from './chat';
import EmbeddedElement from './embedded';
import BeaconElement from './beacon';
import AppElement from './app-element';
import WhiteboardElement from './whiteboard';
import TictactoeElement from './tictactoe';
import StopwatchElement from './stopwatch';
import TimerElement from './timer';
import RoomStreaksElement from './room-streaks';
import WhatsThatVoiceElement from './whatsthatvoice';
import PokerElement from './poker';
import CodenamesElement from './codenames';
import SkribblioElement from './skribblio';
import ChessElement from './chess';
import ImageElement from './image';
import FileElement from './file';
import AppAdminElement from './app-admin';
import BarrierElement from './barriers';
import RoomActiveTimeWidget from './widgets/room-active-time-widget';
import RoomTopMembersWidget from './widgets/room-top-members-widget';

import { renderFullscreenAnimations } from './react/animations/render';
import { renderFeed } from './react/chats/feed/render';
import { listenToRenderUserProfile } from './react/user-profile/render';

import minimap from './minimap';
import log from './log';
import wrapElement, { updateElementAttributes } from './element-wrapper';
import { addSystemMessage } from './message-util';
import {
  ensureViewportIsInBorders,
  moveToDefaultViewport,
  moveToMatchBorders,
  moveToViewport,
  updateCanvasPosition,
} from './viewport';
import { canvasToScreenCoords } from './util/canvas-util';
import {
  htmlToElement,
  sanitize,
  applyBackgroundStyle,
  getQueryStringValue,
  observeElementIntersection,
  requestWakeLock,
  releaseWakeLock,
} from './util';
import { checkIsMobile } from './util/platform-util';
import spawnCamera from './util/spawn-camera';
import { isNewUser } from './util/user-util';
import { fetchAndWaitForVideoServer } from './util/video-server-util';
import { track } from './util/analytics-util';
import { canViewPublicRoom } from './util/profile-util';
import { PlayerStates } from './util/media-util';
import { isRoomPublic } from './util/public-rooms-util';
import { playSoundEffect, soundTypes } from './util/sound-fx-util';
import { isFirefox, isSafari } from './util/browser-util';

import { clearCanvas, removeLineTimers } from './drawing';
import { showLastUnviewedImportantUpdateNotification, updateBadgeIcon } from './feedback';
import firebase, { api, db } from './firebase';
import { updateWayfinders, clearAllWayfinders } from './wayfinders';
import bus, { boardChanged, preBoardChanged, loadBoardRequested, openVibePickerRequested } from './event-bus';
import {
  onWaitlistChanged,
  enterBoard,
  leaveBoard,
  approveWaitlistUser,
  sendWaitlistMessage,
  trackIdleChange,
  onUserListChanged,
  offUserListChanged,
} from './presence';
import { setVoiceControlEnabled } from './sfu/voice-control';
import { updateMembership } from './room';
import {
  addUserCanAddContentSubscriber,
  isHostGranted,
  isWaitlistAllowed,
  offRoleChange,
  onRoleChange,
  startListenIfUserCanAddContent,
} from './roles-management';

import { renderAppAlerts } from './react/app-alerts/render';
import { renderSillyActions } from './react/new-users/render';
import { ConnectionStatus } from './sfu/definitions/index.definitions.ts';
import { HOMEPAGE_ROOM_ID, MURAL_BOARD_TYPE, USER_CARD_BOARD_TYPE } from './constants/board-constants';
import { ENTER_ROOM } from './constants/analytics-events/rooms-events';
import { getCurrentBoardIsUserCard } from './util/room-util';
import reduxAdapter from './react/store/redux-adapter';
import { selectIsHereClub } from './react/store/room/store';
import websocketClient from './api/websocket-client.ts';
import { WebsocketEvents } from './constants/websocket-events.ts';
import { openVibePicker } from './util/lobby-util';
import { renderRoomOnboardingFlow } from './react/sign-in-flow/render';
import { REMOVED_ROOM_QUERY_PARAM } from './constants/lobby-constants';
import { renderTipsAndTricks } from './react/tips-and-tricks/render.tsx';
import { renderTaggingContainer } from './react/tagging/render';

let isHereClub = false;
reduxAdapter.subscribe((state) => {
  const nextIsHereClub = selectIsHereClub(state);

  if (isHereClub !== nextIsHereClub) {
    if (nextIsHereClub) {
      $('#is-here-club').show();
    } else {
      $('#is-here-club').hide();
    }
  }
  isHereClub = nextIsHereClub;
});

// subscription handles for releasing when user switches rooms
let boardUnsubscribe = null;
let boardElementUnsubscribe = null;

let boardElementsLoaded = false;

const recentlyRemovedUsers = [];
const REMOVED_USERS_CHECK_SECONDS = 3;

listenToRenderUserProfile();

bus.on(openVibePickerRequested, openVibePicker);

// Return true if element is successfully modified in-place, false if it needs whole replacement
const modifyElement = (elementDoc) => {
  const elementData = elementDoc.data();

  const element = document.querySelector(`#element-${elementDoc.id}`);
  if (!element) {
    return false;
  }

  // If we're changing lock state, replace the whole thing
  if (
    (element.classList.contains('locked') && !elementData.locked) ||
    (!element.classList.contains('locked') && elementData.locked)
  ) {
    log.debug(`Lock status changed: ${element.classList.contains('locked')}/${elementData.locked}`);
    return false;
  }

  // If we're changing lockedInteraction state, replace the whole thing
  if (
    (element.classList.contains('lockedInteraction') && !elementData.lockedInteraction) ||
    (!element.classList.contains('lockedInteraction') && elementData.lockedInteraction)
  ) {
    log.debug(
      `LockedInteraction status changed: ${element.classList.contains('lockedInteraction')}/${
        elementData.lockedInteraction
      }`
    );
    return false;
  }

  if (window.elementHandlers[elementDoc.id]) {
    updateElementAttributes(element, elementDoc);
    window.elementHandlers[elementDoc.id].setPinnedElements(elementData.pinnedElements);
    return window.elementHandlers[elementDoc.id].handleUpdate(element, elementDoc);
  }

  log.debug('Falling through, update failed');
  return false;
};

const removeElement = (elementDoc) => {
  const elementData = elementDoc.data();
  const element = $(`#element-${elementDoc.id}`);
  if (elementData.class === 'CameraElement') {
    // TODO move this to camera.js
    const removeCamera = () => {
      element.remove();
      minimap.setNeedsUpdate();
      updateWayfinders();
    };

    const { uid } = firebase.auth().currentUser;

    const creatorId = elementData.creator;
    setTimeout(() => {
      if (recentlyRemovedUsers.includes(creatorId)) {
        const index = recentlyRemovedUsers.indexOf(creatorId);
        recentlyRemovedUsers.splice(index, 1);
      }
    }, REMOVED_USERS_CHECK_SECONDS * 1000);
    recentlyRemovedUsers.push(creatorId);

    if (elementData.creator === uid) {
      removeCamera();
      // Our own camera has been removed, possibly because the webapp went to sleep
      // Let's revive ourselves now.
      if (elementData.creator === uid) {
        log.warn('Re-adding my own camera');
        track('Lost Own Camera');

        if (window.rtc.room?.status === ConnectionStatus.Connected) {
          if (boardElementsLoaded && document.querySelectorAll(`.camera-${uid}`).length === 0) {
            log.debug('Nulling out ownCamera on reconnection');
            CameraElement.ownCamera = null;
          }

          CameraElement.initOwnCamera({
            isVideoOn: elementData.isVideoOn,
            isAudioOn: elementData.isAudioOn,
            position: { center: elementData.center, size: elementData.size, clip: elementData.clip },
          });
        } else {
          log.debug('Deferring Camera Element creation');
          window.rtc.onReconnectComplete = () => {
            log.debug('Reconnect complete, creating camera element');
            document.getElementById('error-message').style.display = 'none';
            if (boardElementsLoaded && document.querySelectorAll(`.camera-${uid}`).length === 0) {
              log.debug('board elements loaded, Nulling out ownCamera on deferred camera element creation');
              CameraElement.ownCamera = null;
            }

            CameraElement.initOwnCamera({
              isVideoOn: elementData.isVideoOn,
              isAudioOn: elementData.isAudioOn,
              position: { center: elementData.center, size: elementData.size, clip: elementData.clip },
            });

            // Run this only once.
            window.rtc.onReconnectComplete = null;
          };
        }
      }
    } else {
      const handler = window.elementHandlers[elementDoc.id];
      if (handler) {
        handler.onStartDeleting();
      }
      element.addClass('disappearing-camera-element');
      setTimeout(removeCamera, 1000);
    }

    const streamId = elementData.creator;
    log.debug(`Removing stream ${streamId}`);
    window.rtc.removeStream(streamId);
  } else {
    if (elementData.class === 'ScreenshareElement' && elementData.creator === firebase.auth().currentUser.uid) {
      if (window.rtc.room?.status === ConnectionStatus.Connected && window.rtc.screenshareOriginalStream) {
        log.warn('Re-adding my own screenshare');
        ScreenshareElement.replaceScreenshareElement(elementData);
      }
      // TODO handle restoring screenshare when connectivity completely goes down
    }
    element.remove();
  }

  if (window.elementHandlers[elementDoc.id]) {
    if (window.elementHandlers[elementDoc.id].teardown) {
      window.elementHandlers[elementDoc.id].teardown();
    }
    delete window.elementHandlers[elementDoc.id];
  }
};

const removeWaitlistMessage = (uid) => {
  const el = document.getElementById(`waitlist-message-${uid}`);
  if (el) {
    el.parentNode.removeChild(el);
    const elCounter = document.querySelector('#waitlist-button > span');
    elCounter.innerText = +elCounter.innerText - 1;
    if (elCounter.innerText === '0') {
      document.querySelector('.waitlist-button-wrapper').style.display = 'none';
    }
  }
};

const showWaitlistMessage = (data) => {
  removeWaitlistMessage(data.id);
  const waitlistEl = htmlToElement(`
    <div id="waitlist-message-${data.id}" class="room-message">
      <div class="waitlist-message-icon">
        <img src="images/icons/waiting-msg.svg">
      </div>

      <div class="waitlist-message-content-grid">
        <div class="waitlist-message-text-wrapper">
          <div class="msg-sent" id="msg-sent-${data.id}" style="display: none;">
            <img style="margin-right: 5px" src="images/icons/msg-sent.svg">
            Message Sent!
          </div>
          <div class="waitlist-message-text">
            <span class="waitlist-message-text-content">${data.name}</span>
            <span class="waitlist-message-text-content">is waiting to join.</span>
          </div>
        </div>

        <div class="room-message-buttons">
          <button class="waitlist-allow-button" id="waitlist-allow-${data.id}">Allow</button>
          <button class="waitlist-message-button" id="waitlist-send-message-${data.id}"><img src="images/icons/waiting-msg-btn.svg"></button>
        </div>

        <button class="waitlist-delete-button" id="waitlist-delete-message-${data.id}"><img src="images/icons/waiting-del-btn.svg"></button>
      </div>
    </div>
    `);

  document.getElementById('room-message-list').prepend(waitlistEl);

  document.getElementById(`waitlist-allow-${data.id}`).onclick = () => {
    track('Allow from Waitlist');
    approveWaitlistUser(data.id);
    removeWaitlistMessage(data.id);
  };

  document.getElementById(`waitlist-delete-message-${data.id}`).onclick = () => {
    track('Delete from Waitlist');
    removeWaitlistMessage(data.id);
  };

  document.getElementById(`waitlist-send-message-${data.id}`).onclick = () => {
    document.querySelector(`#waitlist-message-${data.id} .waitlist-message-icon`).classList.add('message-input-active');
    document.querySelector(`#waitlist-message-${data.id} .room-message-buttons`).style.display = 'none';
    document.querySelector(`#waitlist-message-${data.id} .waitlist-message-text-wrapper`).style.display = 'none';
    const el = document.getElementById(`waitlist-message-${data.id}`);
    const messageEl = htmlToElement(`
      <div class="room-send-message" id="waitlist-message-compose-${data.id}">
        <div class="room-send-message-title">
          <div class="room-send-message-text">Message to ${data.name}</div>
          <div class="room-send-message-delete">Cancel</div>
        </div>
        <div class="room-send-message-input-wrapper">
          <input id="room-message-input-${data.id}" class="room-message-input" placeholder="Enter Message"></input>
          <img class="room-send-message-input-button" id="room-send-message-input-button-${data.id}" src="images/icons/waiting-msg-send.png">
        </div>
      </div>
      `);
    el.appendChild(messageEl);
    document.getElementById(`room-message-input-${data.id}`).focus();

    document
      .querySelector(`#waitlist-message-compose-${data.id} .room-send-message-delete`)
      .addEventListener('click', () => {
        document
          .querySelector(`#waitlist-message-${data.id} .waitlist-message-icon`)
          .classList.remove('message-input-active');

        closeMsgInput();
      });

    function closeMsgInput() {
      el.removeChild(el.lastChild);
      document.querySelector(`#waitlist-message-${data.id} .room-message-buttons`).style.display = 'block';
      document.querySelector(`#waitlist-message-${data.id} .waitlist-message-text-wrapper`).style.display = 'block';
    }

    function sendMsg(message) {
      closeMsgInput();
      document.getElementById(`msg-sent-${data.id}`).style.display = 'block';
      setTimeout(() => {
        sendWaitlistMessage(
          window.currentBoardId,
          data.id,
          `Message from ${firebase.auth().currentUser.displayName}: ${message}`
        );
        track('Message from Waitlist');
      }, 600);
    }

    document.getElementById(`room-message-input-${data.id}`).addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
        const message = e.target.value.trim();
        if (message.length > 0) {
          e.target.value = '';
          sendMsg(message);
        }
      }
    });

    document.getElementById(`room-send-message-input-button-${data.id}`).addEventListener('click', () => {
      const message = document.getElementById(`room-message-input-${data.id}`).value.trim();
      if (message.length > 0) {
        document.getElementById(`room-message-input-${data.id}`).value = '';
        sendMsg(message);
      }
    });
  };

  const joinModalActive = UIkit.modal('#join-room-dialog').isToggled();
  if (!joinModalActive) {
    playSoundEffect(soundTypes.WAITLIST);
  }
};

window.setupBoardUI = (data) => {
  window.currentBoardTitle = data.title;
  window.currentBoardDefaultViewport = data.defaultViewport;
  window.currentBoardData = data;

  if (data.type !== USER_CARD_BOARD_TYPE) {
    setVoiceControlEnabled(data.voiceControlEnabled === undefined ? true : data.voiceControlEnabled);
  }

  if (data.background && `${data.background}`.length > 0) {
    applyBackgroundStyle(document.querySelector('.board-background'), data);

    const main = document.getElementById('main');
    if (data.backgroundScroll !== 'scroll') {
      main.classList.add('uk-background-cover');
      main.style.removeProperty('background-size');
      main.style.removeProperty('background-position');
    } else {
      main.classList.remove('uk-background-cover');
      if (data.backgroundTile === 'tile') {
        main.classList.add('tile-background');
        main.classList.remove('no-tile-background');
      } else {
        main.classList.remove('tile-background');
        main.classList.add('no-tile-background');
      }

      if (getCurrentBoardIsUserCard()) {
        main.style.backgroundSize = 'cover';
        main.style.backgroundPosition = 'center';
      } else if (data.backgroundWidth) {
        main.style.backgroundSize = `${window.canvasScale * data.backgroundWidth}px`;
        const pos = canvasToScreenCoords(-data.backgroundWidth / 2, 0);
        main.style.backgroundPosition = `${pos[0]}px ${pos[1]}px`;
      }
    }
  } else {
    $('.board-background').css('background-image', '');
  }
  if (data.backgroundColor) {
    $('.board-background').css('background-color', data.backgroundColor);
  }

  const htmlTitle = sanitize(window.currentBoardTitle);
  document.getElementById('board-title').innerHTML = window.currentBoardData.allowAnonymous
    ? `[𝚝𝚎𝚖𝚙𝚕𝚊𝚝𝚎] ${htmlTitle}`
    : htmlTitle;
  $('#board-title-input').val(htmlTitle);

  if (isSafari() || isFirefox()) {
    // No audio out selection support in Safari or Firefox
    document.getElementById('audio-output-container').style.display = 'none';
  }

  // Don't mess around with camera enable/disable if this gets called during preload
  // TODO relying on currentUser set here because we know that we also joinRTCRoom when
  // a user exists. This is a bit inferential and brittle - we might want this
  // to be a room property instead.
  if (window.currentBoardId && firebase.auth().currentUser) {
    const isRoomManagement = isHostGranted();
    const camButton = document.getElementById('camera-on-button');
    if (data.membersCanUseCam === false && !isRoomManagement) {
      CameraElement.disableOwnCamera();
      camButton.disabled = true;
      camButton.classList.add('inactive-button');
      camButton.setAttribute('uk-tooltip', 'title: Admin Disabled');
    } else {
      camButton.disabled = false;
      camButton.classList.remove('inactive-button');
      camButton.setAttribute('uk-tooltip', 'title: Turn Camera On');
    }

    const micButton = document.getElementById('mic-on-button');
    if (data.membersCanUseMic === false && !isRoomManagement) {
      CameraElement.disableOwnAudio();
      micButton.disabled = true;
      micButton.classList.add('inactive-button');
      micButton.setAttribute('uk-tooltip', 'title: Admin Disabled');
    } else {
      micButton.disabled = false;
      micButton.classList.remove('inactive-button');
      micButton.setAttribute('uk-tooltip', 'title: Turn Microphone On');
    }
  }
};

// Called when the list of users in our current room have changed
const userListChanged = async (users, lastUsers) => {
  // no presence for user pages:
  if (getCurrentBoardIsUserCard()) return;

  if (users && window.currentBoardId) {
    // There appears to be an issue with onDisconnect() being called while a user
    // is still present. Reconnect if this happens
    const currentUserId = firebase.auth().currentUser.uid;
    if (!users[currentUserId] || !users[currentUserId].name || users[currentUserId].queueForRemoval) {
      log.warn('Presence: Lost self in presence list. Re-adding');
      enterBoard(window.currentBoardId);
      track('Lost User Presence While Present');
    }

    // Keep the screen from dimming if you're not alone in the room
    const userKeys = Object.keys(users);
    if (userKeys.length > 1) {
      await requestWakeLock();
    } else if (userKeys.length === 1) {
      await releaseWakeLock();
    }

    // Track user count changes
    const prevCount = lastUsers !== undefined && lastUsers !== null ? Object.keys(lastUsers).length : 0;
    const curCount = users ? Object.keys(users).length : 0;
    if (prevCount !== curCount) {
      track('Users In Room', {
        count: curCount,
        increase: curCount > prevCount,
      });
    }
  }
};

const startBoardElementSnapshot = (docId, user) => {
  log.debug(`Starting element subscription boardId: ${docId} userId: ${user?.uid}`);
  boardElementUnsubscribe = db
    .collection('boards')
    .doc(docId)
    .collection('elements')
    .onSnapshot(
      (snapshot) => {
        if (window.currentBoardId && docId !== window.currentBoardId) {
          log.warn('Trying to update an element from previous board');
          return;
        }

        snapshot.docChanges().forEach((change) => {
          if (change.type === 'removed') {
            removeElement(change.doc);
          } else if (change.type === 'modified' || change.type === 'added') {
            if (!(change.type === 'modified' && modifyElement(change.doc, docId))) {
              addElement(change.doc, docId);
            }
          } else {
            log.debug('Unrecognized change type ', change.type);
          }
        });
        minimap.setNeedsUpdate();

        if (!boardElementsLoaded && user) {
          boardElementsLoaded = true;
        }
      },
      (error) => {
        log.error('Error subscribing to elements: ', error);
      }
    );

  onUserListChanged(docId, userListChanged);
};

async function addElement(elementDoc, boardId) {
  let element;
  const elementData = elementDoc.data();
  const elementId = `element-${elementDoc.id}`;
  const elementClass = elementData.class;
  let handler = null;
  if (document.getElementById(elementId)) {
    document.getElementById(elementId).remove();
    if (window.elementHandlers[elementDoc.id]?.teardown) {
      window.elementHandlers[elementDoc.id]?.teardown();
    }
  }
  if (elementClass === 'TextElement') {
    // This is a HACK for the signup page
    const text = elementData.text || elementData.content; // .text is antiquated
    if (text && text.includes('{{{signup')) {
      element = null;
      handler = null;
    } else {
      handler = new TextElement(elementDoc);
    }
  } else if (elementClass === 'ChatElement') {
    handler = new ChatElement(elementDoc.id);
  } else if (elementClass === 'BeaconElement') {
    handler = new BeaconElement(elementDoc.id);
  } else if (elementClass === 'ImageElement') {
    handler = new ImageElement(elementDoc.id);
  } else if (elementClass === 'FileElement') {
    handler = new FileElement(elementDoc.id);
  } else if (elementClass === 'MediaPlayerElement' || elementClass === 'YoutubeElement') {
    if (elementData.useEmbeddedElement) {
      handler = new EmbeddedElement(elementDoc.id);
    } else {
      const { default: MediaPlayerElement } = await import('./media-player/media-player');
      handler = new MediaPlayerElement(elementDoc.id);
    }
  } else if (elementClass === 'WhiteboardElement') {
    handler = new WhiteboardElement(elementDoc.id);
  } else if (elementClass === 'BarrierElement') {
    handler = new BarrierElement(elementDoc.id);
  } else if (elementClass === 'AppAdminElement') {
    handler = new AppAdminElement(elementDoc.id);
  } else if (elementClass === 'AppElement') {
    handler = new AppElement(elementDoc.id);
  } else if (elementClass === 'CameraElement') {
    handler = new CameraElement(elementDoc.id);
    if (firebase.auth().currentUser && firebase.auth().currentUser.uid === elementData.creator) {
      CameraElement.ownCamera = handler;
    }
  } else if (elementClass === 'CobrowserElement') {
    handler = new CobrowserElement(elementDoc.id);
  } else if (elementClass === 'EmbeddedElement') {
    handler = new EmbeddedElement(elementDoc.id);
  } else if (elementClass === 'GSuiteElement') {
    handler = new GSuiteElement(elementDoc.id);
  } else if (elementClass === 'TictactoeElement') {
    handler = new TictactoeElement(elementDoc.id);
  } else if (elementClass === 'TimerElement') {
    handler = new StopwatchElement(elementDoc.id);
  } else if (elementClass === 'CountdownTimerElement') {
    handler = new TimerElement(elementDoc.id);
  } else if (elementClass === 'RoomStreaksElement') {
    handler = new RoomStreaksElement(elementDoc.id);
  } else if (elementClass === 'WhatsThatVoiceElement') {
    handler = new WhatsThatVoiceElement(elementDoc.id);
  } else if (elementClass === 'PokerElement') {
    handler = new PokerElement(elementDoc.id);
  } else if (elementClass === 'CodenamesElement') {
    handler = new CodenamesElement(elementDoc.id);
  } else if (elementClass === 'SkribblioElement') {
    handler = new SkribblioElement(elementDoc.id);
  } else if (elementClass === 'ChessElement') {
    handler = new ChessElement(elementDoc.id);
  } else if (elementClass === 'ScreenshareElement') {
    handler = new ScreenshareElement(elementDoc.id);
  } else if (elementClass === 'WarpElement') {
    handler = new WarpElement(elementDoc);
  } else if (elementClass === 'SpawnElement') {
    handler = new SpawnElement(elementDoc.id);
  } else if (elementClass === 'TrelloElement') {
    handler = new TrelloElement(elementDoc.id);
  } else if (elementClass === 'FigmaElement') {
    handler = new FigmaElement(elementDoc.id);
  } else if (elementClass === 'PdfElement') {
    handler = new PdfElement(elementDoc.id);
  } else if (elementClass === 'AirtableElement') {
    handler = new AirtableElement(elementDoc.id);
  } else if (elementClass === 'SynthesizerElement') {
    const { default: SynthesizerElement } = await import('./synthesizer');
    handler = new SynthesizerElement(elementDoc.id);
  } else if (elementClass === 'RoomActiveTimeWidget') {
    handler = new RoomActiveTimeWidget(elementDoc.id);
  } else if (elementClass === 'RoomTopMembersWidget') {
    handler = new RoomTopMembersWidget(elementDoc.id);
  } else if (elementClass === 'SpotifyElement') {
    const { default: SpotifyElement } = await import('./widgets/spotify');
    handler = new SpotifyElement(elementDoc.id);
    SpotifyElement.checkSdkScript();
  } else {
    log.warn(`Unrecognized element: ${elementClass}`);
  }

  if (handler) {
    handler.setPinnedElements(elementData.pinnedElements);
    window.elementHandlers[elementDoc.id] = handler;
    element = handler.getElement(elementDoc);
  }

  if (element) {
    observeElementIntersection(element);
    document.querySelector('#elements').append(element);
    const elementFooterOptions = element.querySelector('.element-footer-options');
    renderTaggingContainer(elementDoc.id);

    const currentUserId = firebase.auth().currentUser?.uid;
    if (getCurrentBoardIsUserCard() && window.currentBoardData.creator !== currentUserId) {
      elementFooterOptions.style.display = 'none';
    } else {
      element.addEventListener('mouseenter', () => {
        // lazy size recalculation to improve perfomance
        elementFooterOptions.style.transform = `scale(${1 / window.canvasScale})`;
        elementFooterOptions.style.display = 'flex';
        elementFooterOptions.classList.add('maintain-size-in-viewport');
      });

      element.addEventListener('mouseleave', () => {
        const menus = element.querySelectorAll('.element-submenu');
        let shouldHide = true;
        menus.forEach((menu) => {
          shouldHide = shouldHide && !$(menu).is(':visible');
        });

        if (shouldHide) {
          elementFooterOptions.style.display = 'none';
          elementFooterOptions.classList.remove('maintain-size-in-viewport');
        }
      });
    }

    const joinModalActive = UIkit.modal('#join-room-dialog').isToggled();
    if (
      !joinModalActive &&
      boardElementsLoaded &&
      elementClass !== 'CameraElement' &&
      !elementData.disableInitialAnimation
    ) {
      const container = element.querySelector('.element-container');
      container.classList.add('animate-in');
      setTimeout(() => container.classList.remove('animate-in'), 650);
      playSoundEffect(soundTypes.DROP);
    }

    const user = firebase.auth().currentUser;
    if (
      !joinModalActive &&
      user &&
      boardElementsLoaded &&
      elementClass === 'CameraElement' &&
      elementData.creator !== user.uid
    ) {
      if (!recentlyRemovedUsers.includes(elementData.creator)) playSoundEffect(soundTypes.JOIN);
    }

    // Any post-markup addition work (set up video stream, etc)
    if (handler) {
      handler.setup(elementId, elementDoc, boardId);
    }
  } else {
    log.debug(`Something went wrong with ${elementData.class}`);
  }
}

const startBoardSnapshot = (docId, user) => {
  let lastBoardData;

  bus.dispatch(preBoardChanged, docId);

  const handleRoleChangeForPermissions = () => {
    const camButton = document.getElementById('camera-on-button');
    if (window.currentBoardData.membersCanUseCam === false && !isHostGranted()) {
      CameraElement.disableOwnCamera();
      camButton.disabled = true;
      camButton.classList.add('inactive-button');
      camButton.setAttribute('uk-tooltip', 'title: Admin Disabled');
    } else {
      camButton.disabled = false;
      camButton.classList.remove('inactive-button');
      camButton.setAttribute('uk-tooltip', 'title: Turn Camera On');
    }

    const micButton = document.getElementById('mic-on-button');
    if (window.currentBoardData.membersCanUseMic === false && !isHostGranted()) {
      CameraElement.disableOwnAudio();
      micButton.disabled = true;
      micButton.classList.add('inactive-button');
      micButton.setAttribute('uk-tooltip', 'title: Admin Disabled');
    } else {
      micButton.disabled = false;
      micButton.classList.remove('inactive-button');
      micButton.setAttribute('uk-tooltip', 'title: Turn Microphone On');
    }
  };

  const handleRoleChangeForViewer = async () => {};

  boardUnsubscribe = db
    .collection('boards')
    .doc(docId)
    .onSnapshot(
      async (snapshot) => {
        if (!snapshot.exists) {
          document.location = `/l?${REMOVED_ROOM_QUERY_PARAM}=${encodeURIComponent(lastBoardData.title)}`;
          return;
        }

        const boardData = snapshot.data();

        if (
          boardData.borders?.some((x, index) => (lastBoardData?.borders ? lastBoardData?.borders[index] !== x : true))
        ) {
          ensureViewportIsInBorders(boardData.borders);
        }

        if (boardData.type !== USER_CARD_BOARD_TYPE) {
          if (lastBoardData) {
            if (!lastBoardData.isViewersModeOn && boardData.isViewersModeOn) {
              document.querySelector('.broadcast-start-notification').style.display = null;
            } else if (lastBoardData.isViewersModeOn && !boardData.isViewersModeOn) {
              document.querySelector('.broadcast-end-notification').style.display = null;
            }
          }

          if (boardData.isViewersModeOn) {
            document.querySelectorAll('.on-air-divider, here-on-air').forEach((el) => {
              el.style.display = null;
            });
          } else {
            document.querySelectorAll('.on-air-divider, here-on-air').forEach((el) => {
              el.style.display = 'none';
            });
          }

          offRoleChange(handleRoleChangeForViewer);
          offRoleChange(handleRoleChangeForPermissions);
          onRoleChange(handleRoleChangeForPermissions);
          if (boardData.isViewersModeOn) {
            onRoleChange(handleRoleChangeForViewer);
          }
        }

        lastBoardData = boardData;
        window.setupBoardUI({ id: docId, ...boardData });

        if (user) {
          // update title in membership
          const memberUpdate = {
            title: boardData.title,
            background: boardData.background || null,
            backgroundColor: boardData.backgroundColor || null,
            urlAlias: boardData.urlAlias || null,
          };

          document.title = `${boardData.title} | Here`;

          db.collection('memberships')
            .doc(user.uid)
            .collection(boardData.type === USER_CARD_BOARD_TYPE ? 'userCards' : 'boards')
            .doc(docId)
            .update(memberUpdate)
            .catch((error) => {
              log.error('Error updating membership room title: ', error);
            });
        } else {
          log.debug('Moving not-logged-in user to default viewport');
          moveToDefaultViewport();
        }
      },
      (err) => {
        log.error('Error monitoring board: ', err);
      }
    );
};

function signupButtonElement(elementDoc, id, title) {
  const button = htmlToElement(`
    <button class="signup-button main-signup">
      ${title || '<span>Create an Account</span>'}
    </button>
  `);

  button.addEventListener('click', () => {
    window.authCreateRoom(id);
  });

  return wrapElement(button, elementDoc, { hasViewerControls: true });
}

const findViewport = async (docId, user) => {
  const boardElementId = getQueryStringValue('board-element-id');

  const headers = new Headers();
  headers.append('Accept', 'application/json');

  const query = [];
  if (user.uid) query.push(`uid=${user.uid}`);
  if (boardElementId) query.push(`elementId=${boardElementId}`);

  const response = await fetch(`${api}/room/${docId}/findViewport?${query.join('&')}`, {
    headers,
    method: 'GET',
  });

  if (response.ok) {
    const data = await response.json();

    let moveResult = false;
    if (data.viewport) {
      moveResult = moveToViewport(data.viewport);
    }

    if (data.offsets) {
      moveResult = updateCanvasPosition(
        window.innerWidth / 2 - parseFloat(data.offsets.offsetX),
        window.innerHeight / 2 - parseFloat(data.offsets.offsetY),
        1.0
      );
    }

    if (!moveResult) {
      moveToMatchBorders();
    }
  }
};

const refreshVideoServer = (docId) => {
  setTimeout(() => {
    fetchAndWaitForVideoServer(docId, true);
    refreshVideoServer(docId);
  }, 30000);
};

export const preloadBoard = async (docId, user) => {
  document.getElementById('elements').style.visibility = 'hidden';
  startBoardSnapshot(docId, user);
  startBoardElementSnapshot(docId, user);
  findViewport(docId, user);
  fetchAndWaitForVideoServer(docId);
  refreshVideoServer(docId);
};

let wasIdle = false;
let visibilityTimeout = null;
const trackVisibilityChange = () => {
  const isVisible = document.visibilityState === 'visible';
  clearTimeout(visibilityTimeout);

  if (window.currentBoardId === HOMEPAGE_ROOM_ID) {
    return;
  }

  if (wasIdle && isVisible) {
    visibilityTimeout = setTimeout(() => {
      trackIdleChange(window.currentBoardId, false);
      wasIdle = false;
    }, 5000);
  } else if (!wasIdle && !isVisible) {
    const { uid } = firebase.auth().currentUser;
    const { isAudioOn, isVideoOn } = window.rtc;

    if (!isAudioOn && !isVideoOn) {
      const hasActivity = Object.values(window.elementHandlers).find((handler) => {
        if (handler instanceof ScreenshareElement) {
          return true;
        }

        if (handler instanceof CameraElement) {
          if (handler.userId !== uid && (handler.isAudioOn || handler.isVideoOn)) {
            return true;
          }
        }

        if (handler.constructor.elementType === 'MediaPlayerElement') {
          if (handler.playerState === PlayerStates.PLAYING) {
            return true;
          }
        }

        return false;
      });

      if (!hasActivity) {
        visibilityTimeout = setTimeout(() => {
          trackIdleChange(window.currentBoardId, true);
          wasIdle = true;
        });
      }
    }
  }
};

export const unloadBoard = () => {
  if (boardUnsubscribe) {
    boardUnsubscribe();
  }
  if (boardElementUnsubscribe) {
    boardElementUnsubscribe();
  }

  document.getElementById('elements').innerHTML = '';

  document
    .getElementById('main')
    .querySelectorAll('.wayfinder')
    .forEach((wayfinder) => {
      wayfinder.remove();
    });
  clearAllWayfinders();
  window.elementHandlers = {};
  boardElementsLoaded = false;
};

export const loadBoard = async ({
  docId,
  title,
  isVideoOn,
  isAudioOn,
  isPreloaded = false,
  joinSound = false,
  boardData = null,
  userProfileData = null,
}) => {
  const startTime = new Date();

  // 1235px is screen width when feed stays in the right and **toolbar jumps to the left** (there is no more space in left for the 'important update'-widget)
  const user = firebase.auth().currentUser;

  if (
    boardData &&
    !boardData.roomOnboardingStepId &&
    !checkIsMobile() &&
    window.innerWidth >= 1236 &&
    user &&
    !(await isNewUser(user))
  ) {
    showLastUnviewedImportantUpdateNotification();
  }

  if (docId === window.currentBoardId) {
    // Don't rejoin
    return;
  }

  // Show welcome bar if homepage
  if (window.location.pathname === '/') {
    $('#welcome-bar').show();
  } else {
    $('#welcome-bar').hide();

    // show the minimap only if it's not the homepage
    $('.minimap-container').show();
  }

  boardElementsLoaded = false;

  if (user && !getQueryStringValue('is-user-page')) {
    $('.header').show();
    $('#global-menu-container').css('display', 'flex');
    document.getElementById('global-menu-bar').addEventListener('click', (e) => e.stopPropagation());
    document.getElementById('waitlist-button-wrapper').addEventListener('click', (e) => e.stopPropagation());
    document.getElementById('electron-toolbar').addEventListener('click', (e) => e.stopPropagation());
    $('.users-bar').css('display', 'flex');
    document.getElementById('sidebar-root').style.display = 'block';

    if (!userProfileData) {
      const userProfile = await db.collection('userProfiles').doc(firebase.auth().currentUser.uid).get();
      userProfileData = userProfile.data();
    }
  }

  document.getElementById('elements').style.visibility = 'visible';

  // Erase previous board
  if (!isPreloaded) {
    document.getElementById('elements').innerHTML = '';
    document.querySelectorAll('.wayfinder').forEach((e) => e.remove());

    window.elementHandlers = {};
    clearAllWayfinders();
    window.userCameraId = null;
  }

  const oldBoardId = window.currentBoardId;
  window.currentBoardId = docId;

  if (oldBoardId) {
    window.rtc?.room?.teardownRoom();
    // Presence
    leaveBoard(oldBoardId);
    fetchAndWaitForVideoServer(window.currentBoardId, true);
    await new Promise((res) => setTimeout(res, 1000));
    offUserListChanged(oldBoardId, userListChanged);
  }

  // on each board update or current user role update check if current user can add content
  // enable menu if user can add content
  // disable menu othervise
  const enabledMenuButtons = document.getElementById('enabled-menu-buttons');
  const disabledMenuButtons = document.getElementById('disabled-menu-buttons');
  addUserCanAddContentSubscriber((userCanAddContentUpdated) => {
    if (userCanAddContentUpdated) {
      enabledMenuButtons.style.display = '';
      disabledMenuButtons.style.display = 'none';
    } else {
      enabledMenuButtons.style.display = 'none';
      disabledMenuButtons.style.display = '';
    }
  });

  if (user) {
    startListenIfUserCanAddContent();
  }

  if (!boardData) {
    const boardDoc = await db.collection('boards').doc(docId).get();
    window.currentBoardData = { id: boardDoc.id, ...boardDoc.data() };
  } else {
    window.currentBoardData = boardData;
  }

  // bounce the user if they're not allowed in this room.
  if (userProfileData && !canViewPublicRoom(userProfileData) && isRoomPublic(window.currentBoardData)) {
    document.location.href = '/l';
    return;
  }

  if (user) {
    // Presence
    enterBoard(window.currentBoardId);

    // Update / become member
    updateMembership(docId, title);

    // RTC
    const videoServer = await fetchAndWaitForVideoServer(docId, true);
    log.debug('Joining RTC room at server', videoServer);
    window.rtc.onCameraUpdate = CameraElement.onCameraUpdate.bind(CameraElement);
    window.rtc.joinRTCRoom(docId, videoServer, isAudioOn, isVideoOn);

    addSystemMessage('is here!');
    renderFeed(docId);
    renderAppAlerts();
    renderFullscreenAnimations();

    if (window.currentBoardData?.roomOnboardingStepId && window.currentBoardData?.creator === user.uid) {
      renderRoomOnboardingFlow(window.currentBoardData.roomOnboardingStepId);
    }

    if (userProfileData?.hasUnstartedSillyActions) {
      renderSillyActions();
    }

    renderTipsAndTricks();

    const member = await db.collection('boards').doc(docId).collection('members').doc(user.uid).get();
    const memberData = member.data();
    const isHost = memberData ? isHostGranted(memberData.role) : false;
    const hasUsername =
      userProfileData &&
      userProfileData.username !== '' &&
      userProfileData.username !== undefined &&
      userProfileData.username !== null;
    const isCamAvailableForUser = window.currentBoardData.membersCanUseCam !== false || isHost;
    const isMicAvailableForUser = window.currentBoardData.membersCanUseMic !== false || isHost;
    const isUserNew = await isNewUser(user);

    if (isUserNew && !checkIsMobile()) {
      spawnCamera(user, isMicAvailableForUser ? isAudioOn : false, isCamAvailableForUser ? isVideoOn : false);
      db.collection('userProfiles').doc(user.uid).set({ firstTimeUser: false }, { merge: true });
    } else if (!isUserNew && !hasUsername) {
      // TODO don't show this immediately just to hide it again, let's not show it at all 'til we need it
      document.querySelector('here-users-bar').hideInviteModal();
    } else {
      spawnCamera(user, isMicAvailableForUser ? isAudioOn : false, isCamAvailableForUser ? isVideoOn : false);
    }
  }

  // Reset a few things

  clearCanvas();
  removeLineTimers();
  updateBadgeIcon();

  // TODO unsubscribe from this when leaving / switching rooms
  onWaitlistChanged(docId, {
    async updated(boardId, values) {
      const isAllowed = await isWaitlistAllowed();
      if (!isAllowed || boardId !== window.currentBoardId) return;

      if (values && Object.keys(values).length > 0) {
        document.querySelector('.waitlist-button-wrapper').style.display = '';
        document.querySelector('#waitlist-button').classList.add('active-waitlist');
        document.querySelector('#waitlist-button > span').innerText = Object.keys(values).length;
      } else {
        document.querySelector('.waitlist-button-wrapper').style.display = 'none';
      }
    },
    async added(boardId, data, length) {
      const isAllowed = await isWaitlistAllowed();
      if (!isAllowed || boardId !== window.currentBoardId) return;

      showWaitlistMessage(data);
      document.querySelector('.waitlist-button-wrapper').style.display = '';
      document.querySelector('#waitlist-button').classList.add('active-waitlist');
      document.querySelector('#waitlist-button > span').innerText = length;
    },
    async removed(boardId, id) {
      const isAllowed = await isWaitlistAllowed();
      if (!isAllowed || boardId !== window.currentBoardId) return;

      removeWaitlistMessage(id);
    },
  });

  if (!isPreloaded) {
    if (boardUnsubscribe) {
      boardUnsubscribe();
    }

    startBoardSnapshot(docId, user);
    if (user) {
      findViewport(docId, user);
    }
  }

  if (window.currentBoardData.urlAlias !== null && window.currentBoardData.urlAlias !== undefined) {
    window.history.replaceState('string', 'Title', `/${window.currentBoardData.urlAlias}`);
  } else if (
    window.currentBoardId &&
    window.location.pathname.length > 1 &&
    window.location.pathname.includes(window.currentBoardId)
  ) {
    window.history.replaceState('string', 'Title', `/${window.currentBoardId}`); // Clean the URL
  }

  // Watch changes on this board
  if (!isPreloaded && boardElementUnsubscribe) {
    boardElementUnsubscribe();
  }

  if (!isPreloaded) {
    startBoardElementSnapshot(docId, user);
  }

  track(ENTER_ROOM, { type: window.currentBoardData?.type, joinMode: window.currentBoardData?.joinMode });
  const endTime = new Date();

  let timeDiff = endTime - startTime; // in ms
  // strip the ms
  timeDiff /= 1000;

  // get seconds
  const seconds = Math.round(timeDiff * 100) / 100;
  log.debug(`Room loaded in ${seconds} seconds`);
  track('Room Loaded', { seconds });
  bus.dispatch(boardChanged, docId);

  if (getQueryStringValue('trid')) {
    const transfer = firebase.functions().httpsCallable('transferRoomOwnerByTransferID');
    const result = await transfer({ transferId: getQueryStringValue('trid'), boardId: window.currentBoardId });
    if (result.data.success) {
      window.history.replaceState(null, document.title, `/${window.currentBoardId}`);
    }
  }
  setVoiceControlEnabled(window.currentBoardData.voiceControlEnabled);

  if (joinSound) playSoundEffect(soundTypes.JOIN);

  document.removeEventListener('visibilitychange', trackVisibilityChange);
  document.addEventListener('visibilitychange', trackVisibilityChange);

  websocketClient.emit(WebsocketEvents.JOIN_ROOM, { boardId: docId });
};

export const loadUserCard = async (docId) => {
  document.body.classList.add('user-card');

  // Watch changes on this board
  const user = firebase.auth().currentUser;
  startBoardElementSnapshot(docId, user);
  startBoardSnapshot(docId, user);

  const boardDoc = await db.collection('boards').doc(docId).get();
  window.currentBoardData = { id: boardDoc.id, ...boardDoc.data() };
  window.currentBoardId = boardDoc.id;
  if (window.currentBoardData.type !== USER_CARD_BOARD_TYPE) {
    log.warn('Board is not a user card!');
    document.location = '/';
  }

  track(ENTER_ROOM, { type: window.currentBoardData?.type });

  if (user?.uid === window.currentBoardData?.creator) {
    startListenIfUserCanAddContent();
    await updateMembership(docId, null, null, window.currentBoardData?.type);
    bus.dispatch(boardChanged, docId);
  }

  // TODO: A temporary hack since we have two file inputs with the same id, but different 'accepts' values.
  document.querySelector('.upload-default #upload-file')?.setAttribute('accept', 'image/*');

  moveToDefaultViewport();
};

bus.on(loadBoardRequested, ({ roomId, title, isAudioOn, isVideoOn, isPreloaded, joinSound }) => {
  loadBoard({ docId: roomId, title, isVideoOn, isAudioOn, isPreloaded, joinSound });
});
