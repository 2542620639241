import { SkinData } from '../react/store/chat-skinning/init-state';
import { Board } from './boards';
import { ChatMessage, MessageResponse } from './message';
import { Theme } from './theme';
import { ThemeColors } from './theme-colors';

export const GROUP_PATH = 'h';
export const HERE_OS_PATH = 'd';
export const GROUP_JOIN_PATH = 'join';

type ISODateString = string;

export interface GroupPermissions {
  canAddMessages: boolean;
  canDeleteOwnMessage: boolean;
  canAddMembers: boolean;
  canRemoveMembers: boolean;
  canChangeMemberRole: boolean;
  canAddRooms: boolean;
  canRemoveRooms: boolean;
  canChangeTheme: boolean;
  canChangeGroupSettings: boolean;
  canDeleteAnyMessage: boolean;
  canDeleteGroup: boolean;
}

export interface Group {
  id: string;
  name: string;
  unreadCount?: number;
  backgroundColor?: string;
  background?: {
    original: string;
    thumbnail?: string;
    isProcessing: boolean;
  };
  isNotificationsOn: boolean;
  chatId: string;
  boards?: Board[];
  memberIds: { [key: string]: boolean }; // key here is memberId
  theme?: {
    skin?: SkinData;
    isCustom?: boolean;
    primaryFont?: string;
    colors?: ThemeColors;
  };
  createdAt: string;
  updatedAt: string;
  muralId: string;
  role: string;
  muralLastUpdatedAt?: ISODateString;
  lastAccess?: ISODateString;
  permissions?: GroupPermissions;
}

export enum Roles {
  Member = 'member',
  Owner = 'owner',
  Host = 'host', // Note: doesn't seem like we really use host in the groups context, this may change
}

interface ChatFromGroupResponse {
  messages: MessageResponse[];
  background?: string;
  chatId: string;
  createdAt: string;
  deletedAt?: string;
  groupId: string;
  id: string;
  updatedAt: string;
}

export interface GroupInResponse {
  id: string;
  groupName: string;
  background?: {
    original: string;
    thumbnail?: string;
    isProcessing: boolean;
  };
  lastReadMessageId?: string;
  unreadCount: number;
  members: GroupMember[];
  boards: Board[];
  chat: ChatFromGroupResponse;
  theme?: {
    isCustom?: boolean;
    primaryFont?: string;
    colors?: ThemeColors;
  };
  muralId: string;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  isNotificationsOn: boolean;
  muralLastUpdatedAt?: ISODateString;
}

export interface GroupResponse {
  success?: boolean;
  group: GroupInResponse;
  resultType?: JoinGroupResultType;
}

export enum JoinGroupResultType {
  INVITED_USER_ADDED = 'INVITED_USER_ADDED',
  INVITED_USER_ALREADY_MEMBER = 'INVITED_USER_ALREADY_MEMBER',
  ERROR_ADDING_INVITED_USER = 'ERROR_ADDING_INVITED_USER',
}

export enum GroupsLobbyAreas {
  PROFILE_AREA = 'PROFILE_AREA',
  CHAT_AREA = 'CHAT_AREA',
  MAIN_AREA = 'MAIN_AREA',
}

export interface GroupsListResponse {
  success: boolean;
  groups: {
    id: string;
    groupName: string;
    background?: string;
    lastReadMessageId?: string;
    unreadCount: number;
    members: GroupMember[];
    muralId: string;
    boards: {
      board_id: string;
    };
    chatId?: string;
    theme?: {
      isCustom?: boolean;
      primaryFont?: string;
      colors?: ThemeColors;
    };
    createdAt: ISODateString;
    updatedAt: ISODateString;
    muralLastUpdatedAt?: ISODateString;
  }[];
}

export enum OnlineStatus {
  Online = 'online',
  Offline = 'offline',
}

export interface GroupMember {
  userId: string;
  groupId: string;
  role: string;
  username?: string;
  displayName: string;
  avatar?: {
    original: string;
    thumbnail?: string;
    isProcessing: boolean;
  };
  backgroundPhoto?: {
    original: string;
    thumbnail?: string;
    isProcessing: boolean;
  };
  lastReadMessageId?: string;
  unreadCount: number;
  onlineStatus: {
    status: OnlineStatus;
  };
  lastAccess: ISODateString;
}

export interface CreateGroupRequest {
  vibeId?: string;
  customVibeBackgroundUrl?: string;
  name: string;
  userIds?: string[];
  theme?: Theme;
  skipRoomCreation?: boolean;
  isOnboarding?: boolean;
}

export interface SocketUpdateGroupSettingsMessage {
  groupId: string;
  name?: string;

  theme?: Theme;

  background?: {
    original: string;
  };
}

export interface GroupChatMember {
  role: string;
  isBanned?: boolean;
}

export interface GroupChatInStore {
  id: string;
  background?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  unreadCount: number;
  lastMessage?: string;
  chatMembers: {
    [key: string]: GroupChatMember;
  };
  groupId: string;
  groupName: string;
  hasMore: boolean;
  messages: { [key: string]: ChatMessage };
  isNotificationsOn?: boolean;
}
