import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TagAvatar from './TagAvatar';

const maxTagsNumber = 5;

const TagsBubble = ({ tags, onTaggingPickerOpen }) => {
  const [shownTags, restTags] = useMemo(() => [tags.slice(0, maxTagsNumber), tags.slice(maxTagsNumber)], [tags]);

  if (!tags.length) return null;

  return (
    <Container>
      {shownTags.map((tag) => (
        <TagContainer key={tag.id}>
          <TagAvatar borderColor="black" tag={tag} />
        </TagContainer>
      ))}
      {restTags.length ? (
        <PlusButtonContainer key="plus">
          <PlusButton onClick={onTaggingPickerOpen}>+{restTags.length}</PlusButton>
        </PlusButtonContainer>
      ) : null}
    </Container>
  );
};

export default TagsBubble;

TagsBubble.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onTaggingPickerOpen: PropTypes.func.isRequired,
};

const Container = styled.div`
  position: absolute;
  left: -20px;
  display: flex;
  background: black;
  border: 2px solid white;
  padding: 4px 16px;
  border-radius: 30px;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    transform: rotate(228deg) scale(0.5, 1);
    border-top: 0;
  }

  &::before {
    left: -26px;
    bottom: -24px;
    border: solid 19px transparent;
    border-bottom-color: white;
  }

  &::after {
    left: -17px;
    bottom: -15px;
    border: solid 14px transparent;
    border-bottom-color: black;
  }
`;

const TagContainer = styled.div`
  margin: 0 -10px;
`;

const PlusButtonContainer = styled(TagContainer)`
  width: 28px;
`;

const PlusButton = styled.div`
  background: white;
  color: black;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: 11px;
  left: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
  }
`;
