import React, { useEffect, useState, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import UserAvatar from '../../components/UserAvatar';

import { db } from '../../../firebase';
import { openUserProfile } from '../../user-profile/utils';
import { selectRoomId } from '../../store/room/selectors';
import { useBoardData } from '../../hooks/useBoardData';
import { calculateStreakInRoom, STREAK_LEVEL_ANIMATIONS, getWidgetColor, getWidgetGif, getStreakLevel } from '../../../util/room-streak-util';

import LottieAnimation from '../../animations/LottieAnimation';

const cssStreakTransition = 'streak';
const CSS_TRANSITION_TIMEOUT_IN_MS = 300;
const MAX_RECENT_VISITORS = 3;

export const RoomStreaks = ({ elementId }) => {
  const currentBoardId = useSelector(selectRoomId);
  const [gif, setGif] = useState(STREAK_LEVEL_ANIMATIONS['1']);
  const [showStreak, setShowStreak] = useState(true);
  const [showBorderAnimation, setShowBorderAnimation] = useState(false);
  const [streakFromServer, setStreakFromServer] = useState(1);
  const [streak, setStreak] = useState(streakFromServer - 1);
  const [recentVisitors, setRecentVisitors] = useState([]);
  const [zoom, setZoom] = useState();
  const avatar = useRef(null);

  const widgetColor = getWidgetColor(streak);
  const [{ streakStartedAt }] = useBoardData(currentBoardId);

  useEffect(() => {
    const unsubscribe = db
      .collection(`boards/${currentBoardId}/members`)
      .orderBy('lastEnteredRoom', 'desc')
      .onSnapshot((querySnapshot) => {
        const users = [];
        const { docs } = querySnapshot;

        for (let i = 0; i < docs.length; i += 1) {
          const docData = docs[i].data();
          const { id } = docs[i];
          // don't include banned users
          if (!docData.ban) users.push({ userId: id, name: docData.name });
          // limit recent visitors to 3
          if (users.length === MAX_RECENT_VISITORS) break;
        }
        setRecentVisitors(users);
      });

    return () => {
      unsubscribe();
    };
  }, [currentBoardId]);

  useEffect(() => {
    (async () => {
      if (streakStartedAt) {
        setStreakFromServer(await calculateStreakInRoom(streakStartedAt));
      }
    })();
  }, [streakStartedAt]);

  useEffect(() => {
    const streakGif = getWidgetGif(streakFromServer);

    setTimeout(() => {
      setShowStreak(false);
      setGif(streakGif);
      setTimeout(() => {
        setStreak(streakFromServer);
        setShowBorderAnimation(true);
        setShowStreak(true);
        setTimeout(() => setShowBorderAnimation(false), 100);
      }, 300);
    }, 500);
  }, [streakFromServer]);

  useEffect(() => {
    const widthObserver = new ResizeObserver((entries) => {
      setZoom(entries[0].contentRect.width / 190);
    });
    const roomStreakEl = document.getElementById(`element-${elementId}`);
    if (roomStreakEl) widthObserver.observe(roomStreakEl);
    return () => {
      widthObserver.disconnect();
    };
  }, [elementId]);

  const onAvatarClick = (userId) => {
    const elementEl = document.getElementById(`element-${elementId}`).children[0];
    const topPosition = elementEl.getBoundingClientRect().y - 8;
    const leftPosition = elementEl.getBoundingClientRect().x + elementEl.getBoundingClientRect().width + 10;

    openUserProfile({ userId, topPosition, leftPosition });

    const onDocumentClick = (e) => {
      const isClickedOnAvatar = avatar.current?.contains(e.target);
      if (!isClickedOnAvatar) {
        document.removeEventListener('click', onDocumentClick);
      }
    };

    document.addEventListener('click', onDocumentClick);
  };

  return (
    <Container animate={showBorderAnimation} color={widgetColor} zoom={zoom}>
      <AnimationContainer>
        {getStreakLevel(streak) === 1 && (
          <LottieAnimation animationData={gif} loop />
        )}
        {getStreakLevel(streak) === 2 && (
          <TwoAnimations>
            <GifContainerLeft>
              <LottieAnimation animationData={gif} loop />
            </GifContainerLeft>
            <GifContainerRight>
              <LottieAnimation animationData={gif} loop />
            </GifContainerRight>
          </TwoAnimations>
        )}
        {getStreakLevel(streak) === 3 && (
          <>
            <GifContainerLeft>
              <LottieAnimation animationData={gif} loop />
            </GifContainerLeft>
            <LottieAnimation animationData={gif} loop />
            <GifContainerRight>
              <LottieAnimation animationData={gif} loop />
            </GifContainerRight>
          </>
        )}
      </AnimationContainer>


      <Top>
        <Streak>
          <CSSTransition
            in={showStreak}
            classNames={cssStreakTransition}
            timeout={CSS_TRANSITION_TIMEOUT_IN_MS}
            unmountOnExit
          >
            <div>{streak}</div>
          </CSSTransition>
        </Streak>
        <H3>day streak</H3>
        <Divider />
      </Top>
      <Bottom>
        <H4>recent visitors</H4>
        <RecentVisitors>
          {recentVisitors.map((visitor) => (
            <AvatarContainer
              key={visitor.userId}
              ref={avatar}
              onClick={() => onAvatarClick(visitor.userId)}
              zoom={zoom}
            >
              <UserAvatar userId={visitor.userId} />
            </AvatarContainer>
          ))}
        </RecentVisitors>
      </Bottom>
    </Container>
  );
};

RoomStreaks.propTypes = {
  elementId: PropTypes.string.isRequired,
};

const pulseOut = (color) => keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(${color}, 1);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(${color}, 0);
  }
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px solid rgba(18, 0, 45, 0.3);
  border-radius: 20px;
  background-color: rgb(${({ color }) => color});
  color: white;
  padding: 10px;
  padding-bottom: 10px;
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  ${({ zoom }) =>
    zoom &&
    css`
      zoom: ${zoom};
    `}

  animation: ${({ animate, color }) =>
    animate &&
    css`
      ${pulseOut(color)} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both
    `};
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  gap: 7px;
`;

const Divider = styled.div`
  width: 160px;
  border: dashed 1px white;
  opacity: 30%;
  margin-top: 17px;
  z-index: 3;
`;

const RecentVisitors = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const H3 = styled.h3`
  font-size: 12px;
  font-style: bold;
  color: white;
  z-index: 3;
`;

const H4 = styled.h3`
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
`;

const H1 = styled.h1`
  font-size: 70px;
  font-weight: 900;
  color: white;
`;

const Streak = styled(H1)`
  line-height: 1;
  z-index: 1;

  .${cssStreakTransition}-enter {
    opacity: 0;
    transform: translateY(-100%);
  }
  .${cssStreakTransition}-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 300ms, transform 300ms;
  }
  .${cssStreakTransition}-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  .${cssStreakTransition}-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 300ms, transform 300ms;
  }
`;

const AnimationContainer = styled.div`
  display: flex;
  position: absolute;
  top: -100px;
  height: 135px;
`;

const TwoAnimations = styled.div`
  display:flex;
  gap: 15px;
`;

const GifContainerLeft = styled.div`
  margin-right: -15px;
  width: 100%;
`;

const GifContainerRight = styled.div`
  margin-left: -15px;
  width: 100%;
`;

const AvatarContainer = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;

  ${({ zoom }) =>
    zoom &&
    css`
      left: ${zoom * 190 + 10}px;
    `}
`;
