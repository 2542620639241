import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import log from './log';
import { htmlToElement } from './util';
import wrapElement from './element-wrapper';

import '../styles/whatsthatvoice.less';
import BoardElement from './board-element';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

const voicePrompts = [
  'Arnold Schwarzenegger',
  'Jack Nicholson',
  'Fran Drescher',
  'Christopher Walken',
  'Donald Trump',
  'Kanye West',
  'John Wayne',
  'Marilyn Monroe',
  'Alan Rickman',
  'Marlon Brando',
  'Barack Obama',
  'Julie Andrews',
  'Samuel L. Jackson',
  'Lois Griffin',
  'Gilbert Gottfried',
  'Bill Clinton',
  'Richard Nixon',
  'Forrest Gump',
  'Ringo Starr',
  'Velma Dinkley',
  'Scooby Doo',
  'Chris Farley',
  'Shaggy',
  'Dolly Parton',
  'Vincent Price',
  'Stewie Griffin',
  'Peter Griffin',
  'Adam Sandler',
  'Tracy Morgan',
  'The Little Mermaid',
  'Keanu Reeves',
  'Sylvester Stallone',
  'Elvis Presley',
  'Robin Williams',
  'Morgan Freeman',
  'Oprah Winfrey',
  'Gandalf the Grey',
  'Darth Vader',
  'Borat Sagdiyev',
  'Chewbacca',
  'Owen Wilson',
  'Yoda',
  'Jay Leno',
  'Britney Spears',
  'Tim Gunn',
  'Jim Carrey',
  'Eminem',
  'Jar Jar Binks',
  'Matthew McConaughey',
  'Mickey Mouse',
  'Goofy',
  'Bob Dylan',
  'Werner Herzog',
  'Simon Cowell',
  'Porky Pig',
  'Abraham Lincoln',
  'Pee Wee Herman',
  'Barbra Streisand',
  'Amy Weinhouse',
  'Clint Eastwood',
  'Sean Connery',
  'Barbara Walters',
  'George Takei',
  'William Shatner',
  'Eddie Murphy',
  'Michael Jackson',
  'Homer Simpson',
  'Eric Cartman',
];

const gameLength = 30;

const GameStates = {
  Title: 'Title',
  Select: 'Select',
  Game: 'Game',
  Result: 'Result',
};

export default class WhatsThatVoiceElement extends BoardElement {
  constructor(elementId) {
    super(elementId);

    this.currentVoicePrompt = '';
    // this.gameState = GameStates.Title;
    this.currentPlayer = '';
    this.currentPlayerName = '';
    this.currentScore = 0;
    this.gameEndTimestamp = -1;
    this.pastVoicePrompts = [];
    this.voicePromptResult = [];
    this.intervalId = null;
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    this.updateTimer(this.gameEndTimestamp);
    this.updateVariables(elementDoc.data());
    this.updateBoardState(elementDoc.data().currentVoicePrompt, elementDoc.data().gameState, elementDoc);
    const now = new Date().getTime() / 1000;
    if (this.gameState === GameStates.Game) {
      this.updateTimer(this.gameEndTimestamp);
      if (this.gameEndTimestamp > now) {
        this.updateTimer(this.gameEndTimestamp);
      } else if (elementDoc.data().gameEndTimestamp < now) {
        this.gameState = GameStates.Result;
        this.emitUpdate();
      }
    }
    return true;
  }

  updateVariables(elementData) {
    this.currentPlayer = elementData.currentPlayer;
    this.currentPlayerName = elementData.currentPlayerName;
    this.currentScore = elementData.currentScore;
    this.pastVoicePrompts = elementData.pastVoicePrompts;
    this.voicePromptResult = elementData.voicePromptResult;
    this.gameEndTimestamp = elementData.gameEndTimestamp;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    const el = document.getElementById(`element-${elementDoc.id}`);
    this.gameState = elementDoc.data().gameState;
    this.gameEndTimestamp = elementDoc.data().gameEndTimestamp;
    if (el.querySelector('.wtv-start')) {
      el.querySelector('.wtv-start').addEventListener('click', (__e) => {
        this.startGame();
      });
    }
    if (el.querySelector('.wtv-restart')) {
      el.querySelector('.wtv-restart').addEventListener('click', (__e) => {
        this.restartGame();
      });
    }
    this.updateBoardState(elementDoc.data().currentVoicePrompt, this.gameState, elementDoc);
  }

  // Required method
  getElement(elementDoc) {
    const game = htmlToElement(`
      <div class="wtv-bg">
        <div id="game-content" class="game-content"></div>
      </div>
    `);

    return wrapElement(game, elementDoc, {
      classes: ['wtv-card'],
    });
  }

  getDescription() {
    return 'From making silly voices, to impersonating celebrities and more - guess at clues from your friends before time runs out!';
  }

  // Statics
  static async addElement() {
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'WhatsThatVoiceElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 110) + window.innerHeight / 2
        ),
        size: [525, 350],
        zIndex: window.getFrontZIndex(),
        creator: firebase.auth().currentUser.uid,
        currentVoicePrompt: '',
        gameState: GameStates.Title,
        currentPlayer: '',
        currentPlayerName: '',
        currentScore: 0,
        gameEndTimestamp: -1,
        voicePromptResult: [],
        pastVoicePrompts: [],
        intervalId: null,
      })
      .then(() => {
        addSystemMessage('added Whats That Voice?');
        track(ADD_ELEMENT, {
          element: ELEMENT_TYPES.WHATS_THAT_VOICE,
          destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM,
        });
      })
      .catch((error) => {
        log.error(`Something bad happened creating WhatsThatVoiceElement: ${error}`);
      });
  }

  /* ////////////////////////////////////////////////////////////////////////////////////////// */

  startGame() {
    this.gameState = GameStates.Game;
    this.currentVoicePrompt = this.getVoicePrompt();
    this.currentPlayer = firebase.auth().currentUser.uid;
    this.currentPlayerName = firebase.auth().currentUser.displayName;
    this.gameEndTimestamp = new Date().getTime() / 1000 + gameLength;
    this.emitUpdate();
  }

  restartGame() {
    if (this.intervalId !== '') clearInterval(this.intervalId);
    this.gameState = GameStates.Title;
    this.currentVoicePrompt = '';
    this.currentPlayer = '';
    this.currentPlayerName = '';
    this.currentScore = 0;
    this.gameEndTimestamp = -1;
    this.pastVoicePrompts = [];
    this.voicePromptResult = [];
    this.emitUpdate();
  }

  getVoicePrompt() {
    let newPrompt = '';
    if (this.pastVoicePrompts.length !== 0) {
      const filtered = voicePrompts.filter((f) => !this.pastVoicePrompts.includes(f));
      newPrompt = filtered[Math.floor(Math.random() * filtered.length)];
    } else {
      newPrompt = voicePrompts[Math.floor(Math.random() * voicePrompts.length)];
    }
    this.pastVoicePrompts.push(newPrompt);
    return newPrompt;
  }

  userIsPlaying() {
    if (this.currentPlayer === firebase.auth().currentUser.uid) return true;
    return false;
  }

  // Handler-specific:
  updateBoardState(newPrompt, newState, elementDoc) {
    const el = document.getElementById(`element-${this.elementId}`);
    const content = el.querySelector('#game-content');
    this.currentVoicePrompt = newPrompt;
    this.pastVoicePrompts = elementDoc.data().pastVoicePrompts;
    this.voicePromptResult = elementDoc.data().voicePromptResult;
    this.currentPlayer = elementDoc.data().currentPlayer;
    this.currentPlayerName = elementDoc.data().currentPlayerName;
    this.gameState = newState;
    this.currentScore = elementDoc.data().currentScore;

    const secondsLeft = this.gameEndTimestamp - new Date().getTime() / 1000;
    let newHTML = '';
    switch (this.gameState) {
      // TITLE
      case GameStates.Title:
        newHTML = `
          <div class="game-title-div">
            <div>
              <span class="wtv-status title">What's That Voice?</span>
            </div>
            <button class="start-playing-button wtv-start">Start a New Game</button>
            <br />
            <small class="sub-header">2-8 Players Recommended</small>
            <br />
            <h6 class="game-description"> ${this.getDescription()} </h6>
          </div>
          `;
        content.innerHTML = newHTML;
        el.querySelector('.wtv-start').addEventListener('click', (__e) => {
          this.startGame();
        });
        break;

      // GAME
      case GameStates.Game: {
        if (this.intervalId !== '') clearInterval(this.intervalId);
        this.intervalId = setInterval(() => {
          this.updateTimer(elementDoc.data().gameEndTimestamp);
          if (elementDoc.data().gameEndTimestamp - new Date().getTime() / 1000 < 0) {
            clearInterval(this.intervalId);
            this.gameState = GameStates.Result;
            this.emitUpdate();
          }
        }, 1000);
        if (this.userIsPlaying()) {
          newHTML = `
          <div class="in-game">
            <h4 class="guess-tooltip">Guess at clues from your friends</h4>
            <button id="correct" class="wtv-button green-button width-50 title correct">
            <h5 style="margin:auto;">Correct</h5>
            </button>
            <br>
            <button id="pass" class="wtv-button red-button width-50 title pass">
            <h5 style="margin:auto;">Pass</h5>
            </button>
          </div>
          <div id="game-footer" class="full-width full-height">
            ${this.nowPlayingElement(this.currentPlayerName)}
          </div>
          `;
        } else {
          newHTML = `
          <div class="in-game">
            <h2 class="label timer" style="color:white;margin:0;padding-top:25px;">${secondsLeft.toFixed(0)}</h2>
            <br>
            <h2 class="label title voice-prompt">${this.currentVoicePrompt}</h3>
            <p style="line-height:0;color:white;font-size:7.5pt;">Help your friend guess by doing this voice</p>
          </div>
          <div id="game-footer" class="full-width">
            ${this.nowPlayingElement(this.currentPlayerName)}
          </div>
          `;
        }
        content.innerHTML = newHTML;
        el.querySelector('#game-footer').innerHTML = this.nowPlayingElement(this.currentPlayerName);
        const correctEl = el.querySelector('#correct');
        if (correctEl) {
          correctEl.addEventListener('click', (__e) => {
            this.correct();
          });
        }
        const passEl = el.querySelector('#pass');
        if (passEl) {
          passEl.addEventListener('click', (__e) => {
            this.pass();
          });
        }
        break;
      }

      // RESULT
      case GameStates.Result:
        newHTML = `
          <div>
            <button class="uk-button wtv-restart">↻</button>
          </div>
          <div class='in-game'>
            <h3 class='label title result-score'>${this.currentScore}</h3>
            <div class='scroll-pane'>
        `;
        log.debug(this.pastVoicePrompts);
        for (let i = 0; i < this.pastVoicePrompts.length; i += 1) {
          if (this.voicePromptResult[i]) {
            newHTML += `<h6 class="wtv-result-correct">${this.pastVoicePrompts[i]}</h6>`;
          } else {
            newHTML += `<h6 class="wtv-result-incorrect">${this.pastVoicePrompts[i]}</h6>`;
          }
        }
        newHTML += `</div></div>
        <div id="game-footer" class="full-width">
          ${this.nowPlayingElement(this.currentPlayerName)}
        </div>
        `;
        content.innerHTML = newHTML;
        if (el.querySelector('.wtv-restart')) {
          el.querySelector('.wtv-restart').addEventListener('click', (__e) => {
            this.restartGame();
          });
        }
        el.querySelector('#game-footer').innerHTML = this.nowPlayingElement(this.currentPlayerName);
        break;
      default:
        log.debug('No game state found');
    }
  }

  updateTimer(GameEndTimeStamp) {
    const secondsLeft = GameEndTimeStamp - new Date().getTime() / 1000;
    const el = document.getElementById(`element-${this.elementId}`);
    if (el) {
      const content = el.querySelector('.timer');
      if (!this.userIsPlaying() && this.gameState === GameStates.Game) {
        content.innerHTML = secondsLeft.toFixed(0);
      }
    } else {
      // Game no longer exists
      clearInterval(this.intervalId);
    }
  }

  correct() {
    this.currentScore += 1;
    this.voicePromptResult.push(true);
    log.debug(this.voicePromptResult);
    this.handleSelection();
  }

  pass() {
    log.debug('hit pass!');
    this.voicePromptResult.push(false);
    log.debug(this.voicePromptResult);
    this.handleSelection();
  }

  handleSelection() {
    this.currentVoicePrompt = this.getVoicePrompt();
    this.emitUpdate();
  }

  nowPlayingElement(currentPlayer) {
    return `<h6 class="white guesser-text">Guesser: ${currentPlayer}</h6>`;
  }

  emitUpdate() {
    db.collection('boards').doc(window.currentBoardId).collection('elements').doc(this.elementId).update({
      currentVoicePrompt: this.currentVoicePrompt,
      gameState: this.gameState,
      currentPlayer: this.currentPlayer,
      currentPlayerName: this.currentPlayerName,
      currentScore: this.currentScore,
      gameEndTimestamp: this.gameEndTimestamp,
      pastVoicePrompts: this.pastVoicePrompts,
      voicePromptResult: this.voicePromptResult,
    });
  }
}
