import firebase, { db } from '../firebase';
import log from '../log';

export async function fetchOfflineMembersPage(boardId, pageSize, lastMemberId) {
  if (lastMemberId) {
    const lastMemberRef = await db.doc(`boards/${boardId}/members/${lastMemberId}`).get();
    return db.collection(`boards/${boardId}/members`).orderBy('name').startAfter(lastMemberRef).limit(pageSize).get();
  }

  return db.collection(`boards/${boardId}/members`).orderBy('name').limit(pageSize).get();
}

export function fetchBannedMembers(boardId) {
  return db.collection(`boards/${boardId}/members`).orderBy('ban').where('ban', '!=', null).get();
}

export async function fetchCurrentMemberData(boardId, memberId) {
  const memberDoc = await db.doc(`boards/${boardId}/members/${memberId}`).get();
  return { id: memberId, ...memberDoc.data() };
}

export function fetchCurrentMembersData(boardId, memberIds) {
  return db
    .collection(`boards/${boardId}/members`)
    .where(firebase.firestore.FieldPath.documentId(), 'in', memberIds)
    .get();
}

export const updateFeedLastVisibleTime = (userId, roomId) =>
  db
    .doc(`memberships/${userId}/boards/${roomId}`)
    .update({ feedLastVisibleTime: firebase.firestore.FieldValue.serverTimestamp() })
    .catch((error) => {
      log.error(error);
    });
