export const ROOM_ID_LENGTH = 20;
export const ROOM_URL_ALIAS_MIN_LENGTH = 2;
export const ROOM_URL_ALIAS_MAX_LENGTH = 50;
export const NUM_QUICK_JUMP_ROOMS = 5;
export const VISIBLE_MEMBERS_COUNT = 5;

export const HOMEPAGE_ROOM_ID = '213NJUH9Th2frB70pVz4';
export const BASICS_ROOM_ID = 'QiJk7YDjxxItQNfZZG9k';
export const BLANK_ROOM_ID = 'kbcqktUgQsumTNNJbSFN';

export const USER_CARD_TEMPLATE_BOARD_ID = 'IDejj3ij4ZugTF3u2hbh';

export const USER_CARD_BOARD_TYPE = 'user card';
export const ROOM_BOARD_TYPE = 'room';
export const MURAL_BOARD_TYPE = 'mural';
export const OS_PAGE_BOARD_TYPE = 'os';

// board element constants
export const ROOM_TOP_MEMBERS_WIDGET_MIN_WIDTH_PX = 540;
export const ROOM_TOP_MEMBERS_WIDGET_MIN_HEIGHT_PX = 330;

export const ROOM_ACTIVE_TIME_WIDGET_MIN_HEIGHT_PX = 190;
export const ROOM_ACTIVE_TIME_WIDGET_MIN_WIDTH_PX = 350;
