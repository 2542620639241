import { useCallback, useEffect, useRef } from 'react';
import { calculateDistance } from '../../util/element-util';
import { removeCssVariable, setCssVariable } from '../../util/styles-util';

const minDragDistance = 5;

export function useHandleDragging({
  onStartDragging = () => {},
  onDragging = () => {},
  onStopDragging = () => {},
  cursor,
}) {
  const initialPosition = useRef(null);
  const currentPosition = useRef(null);

  const isDraggingRef = useRef(null);

  const onStartDraggingRef = useRef(onStartDragging);
  useEffect(() => {
    onStartDraggingRef.current = onStartDragging;
  }, [onStartDragging]);

  const onDraggingRef = useRef(onDragging);
  useEffect(() => {
    onDraggingRef.current = onDragging;
  }, [onDragging]);

  const onStopDraggingRef = useRef(onStopDragging);
  useEffect(() => {
    onStopDraggingRef.current = onStopDragging;
  }, [onStopDragging]);

  const onPointerMove = useCallback((e) => {
    const distance = calculateDistance(initialPosition.current, { x: e.clientX, y: e.clientY });

    // Only consider it as a drag if the distance is greater than minDragDistance
    if (distance > minDragDistance) {
      isDraggingRef.current = true;
    }

    e.stopPropagation();
    onDraggingRef.current({
      x: e.clientX,
      y: e.clientY,
      deltaX: e.clientX - currentPosition.current.x,
      deltaY: e.clientY - currentPosition.current.y,
    });
    currentPosition.current = { x: e.clientX, y: e.clientY };
  }, []);

  const onPointerUp = useCallback(() => {
    document.removeEventListener('pointermove', onPointerMove);
    document.removeEventListener('pointerup', onPointerUp);
    onStopDraggingRef.current();
    initialPosition.current = null;
    currentPosition.current = null;

    removeCssVariable('currentCursor', document.body);
  }, [onPointerMove]);

  const onPointerDown = useCallback(
    (e) => {
      if (e.target.closest('.dont-drag-me')) {
        return;
      }

      isDraggingRef.current = false;

      // Instead of calling stopPropagation, don't let useHandleDragging users override each other
      // via this custom prop, but at the same time allow other listeners to capture this event.
      if (e.isDraggingHandled) {
        return;
      }
      e.isDraggingHandled = true;

      onStartDraggingRef.current({ x: e.clientX, y: e.clientY });
      initialPosition.current = { x: e.clientX, y: e.clientY };
      currentPosition.current = { x: e.clientX, y: e.clientY };
      document.addEventListener('pointermove', onPointerMove);
      document.addEventListener('pointerup', onPointerUp);

      if (cursor) {
        setCssVariable('currentCursor', cursor, document.body);
      }
    },
    [cursor, onPointerMove, onPointerUp]
  );

  const onClickCapture = useCallback((e) => {
    if (e.target.closest('.dont-drag-me')) {
      return;
    }

    if (isDraggingRef.current) {
      e.stopPropagation();
      e.preventDefault();
    }
  }, []);

  return { onPointerDown, onClickCapture };
}
