import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { categories } from './remix-data';
import Tabs from '../../components/Tabs';
import RemixTab from './RemixTab';
import { gifTypes, getSearchTerm } from '../../store/gif-search/store';
import { getRandomNumberInRange, rotatePoint } from '../../../util';
import { BoardControllerContext, BoardElementControllerContext } from '../../common/contexts.ts';
import { MURAL_REMIX_DEFAULT_WIDTH } from '../../../constants/group-constants';
import { elementClasses } from '../elements.ts';
import GifSearchContainer from '../../gif-search/GifSearchContainer';
import { gifPickerCallers } from '../../../constants/image-constants';
import { getElementType } from '../element-definitions';

const categoryIds = Object.keys(categories);

const getRandomCoordinatesInsideElement = (elementData, size) => {
  const randomCoordinates = [
    getRandomNumberInRange(elementData.center[0], elementData.center[0] + elementData.size[0] - size[0]),
    getRandomNumberInRange(elementData.center[1], elementData.center[1] + elementData.size[1] - size[1]),
  ];
  const actualElementCenter = [
    elementData.center[0] + elementData.size[0] / 2,
    elementData.center[1] + elementData.size[1] / 2,
  ];
  return rotatePoint(randomCoordinates, actualElementCenter, elementData.rotationAngle || 0);
};

const RemixMenu = ({ onItemSelected }) => {
  const searchTerm = useSelector((state) => getSearchTerm(state, { type: gifTypes.stickers }));
  const { addElement } = useContext(BoardControllerContext);
  const { elementData } = useContext(BoardElementControllerContext);

  const addRemix = useCallback(
    ({ url, aspectRatio, useImageProxy = false, category }) => {
      const isFrame = category === 'frames';
      // Picking random coordinate inside an element as if it wasn't rotated, then rotate this coordinate
      // related to element's center, so the remix remains inside the element even if it's rotated.
      const size = isFrame ? elementData.size : [MURAL_REMIX_DEFAULT_WIDTH, MURAL_REMIX_DEFAULT_WIDTH / aspectRatio];
      const center = isFrame ? elementData.center : getRandomCoordinatesInsideElement(elementData, size);
      const rotationAngle = isFrame ? elementData.rotationAngle : getRandomNumberInRange(0, 359);
      addElement(
        {
          class: elementClasses.IMAGE,
          size,
          center,
          rotationAngle,
          imageURL: url,
          imageType: gifTypes.remix,
          useImageProxy,
          baseElementId: elementData.id,
        },
        {
          moveViewportToElement: false,
          analyticsParams: { source: category || 'search', baseElement: getElementType(elementData) },
        }
      );
      onItemSelected();
    },
    [addElement, elementData, onItemSelected]
  );

  const onSearchResultClick = useCallback(
    ({ url, width, height }) => {
      addRemix({ url, aspectRatio: width / height });
    },
    [addRemix]
  );

  const [activeTab, setActiveTab] = useState(categories[categoryIds[0]]);
  const tabs = useMemo(
    () =>
      categoryIds.map((id) => ({
        name: categories[id],
        node: <RemixTab categoryId={id} isActive={activeTab === categories[id]} onItemSelected={addRemix} />,
      })),
    [activeTab, addRemix]
  );

  return (
    <Container onPointerDownCapture={(e) => e.stopPropagation()}>
      <GifSearchContainer
        height={searchTerm ? '300px' : '0'}
        type={gifTypes.stickers}
        caller={gifPickerCallers.REMIX}
        onResultClick={onSearchResultClick}
        columnsCount={3}
      />
      {searchTerm ? null : <Tabs uniqueId="remix" onTabChange={setActiveTab} tabs={tabs} />}
    </Container>
  );
};

export default RemixMenu;

RemixMenu.propTypes = {
  onItemSelected: PropTypes.func.isRequired,
};

const Container = styled.div`
  width: 510px;
  padding: 16px;

  .tabs-header {
    justify-content: space-between;
    margin: 8px 0;

    &::before {
      display: none;
    }

    li {
      // Overriding uikit styles
      margin: 0 !important;
    }
  }
`;
