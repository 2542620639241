import $ from 'jquery';
import firebase, { db } from './firebase';
import log from './log';
import '../styles/chess.less';
import { screenToCanvasCoords } from './util/canvas-util';
import './lib/chessboard';
import { htmlToElement } from './util';
import wrapElement from './element-wrapper';
import BoardElement from './board-element';

import { track } from './util/analytics-util';
import { STARTED_GAME, Games } from './constants/analytics-events/games-events';

import '../styles/lib/chessboard.css';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

const Chess = require('chess.js');

const GameState = {
  unstarted: 'unstarted',
  started: 'started',
};

const whiteSquareGrey = '#a9a9a9';
const blackSquareGrey = '#696969';

export default class ChessElement extends BoardElement {
  constructor(elementId) {
    super(elementId);

    this.state = GameState.unstarted;
    this.fen = '';
    this.game = new Chess();
    this.board = null;
  }

  elementDescription() {
    return 'Chess';
  }

  icon() {
    return 'slingshot';
  }

  minSize() {
    return [250, 250];
  }

  static async addElement() {
    await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'ChessElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
        ),
        creator: firebase.auth().currentUser.uid,
        size: [500, 580],
        state: GameState.unstarted,
        fen: '',
        zIndex: window.getFrontZIndex(),
      });
    track(ADD_ELEMENT, { element: ELEMENT_TYPES.CHESS, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });

    return false;
  }

  handleUpdate(element, elementDoc) {
    log.debug('Received update', this.state, elementDoc.data());

    let result = true;
    if (this.state !== elementDoc.data().state) {
      log.debug('Chess changing state, reload it');
      result = false;
    }
    this.updateObject(elementDoc);
    if (result && GameState.started === this.state) {
      this.board = this.createChessBoard(elementDoc);
      this.board.resize();
      this.updateStatus();
    }
    return result;
  }

  setup(elementId, elementDoc) {
    this.updateObject(elementDoc);
    this.game = new Chess(elementDoc.data().fen);
    if (this.state === GameState.started) {
      this.board = this.createChessBoard(elementDoc);
      this.board.resize();
      this.updateStatus();
    } else if (this.state === GameState.unstarted) {
      const element = document.getElementById(elementId);
      element.querySelector('.game-start').addEventListener('click', () => this.startGame());
    }
  }

  updateObject(elementDoc) {
    const data = elementDoc.data();
    this.state = data.state;
    this.fen = data.fen;
    this.game = new Chess(data.fen);
    this.board = data.board;
  }

  createChessBoard(elementDoc) {
    const config = {
      draggable: true,
      position: elementDoc.data().fen,
      onDragStart: this.onDragStart.bind(this),
      onDrop: this.onDrop.bind(this),
      onMouseoutSquare: this.onMouseoutSquare.bind(this),
      onMouseoverSquare: this.onMouseoverSquare.bind(this),
      onSnapEnd: this.onSnapEnd.bind(this),
    };
    const board = window.Chessboard(`chessboard-${elementDoc.id}`, config);
    return board;
  }

  getElement(elementDoc) {
    const data = elementDoc.data();
    log.debug(`Chess state: ${data.state}`);

    let game;
    switch (data.state) {
      case GameState.unstarted: {
        game = htmlToElement(`
          <div class="game-title-div themed-shadow">
            <h1>Chess</h1>
            <button class="game-start here-button-regular">Start a New Game</button>
          </div>
        `);
        break;
      }
      case GameState.started: {
        track(STARTED_GAME, { game: Games.CHESS });
        game = htmlToElement(`
          <div style="padding:20px;">
            <div class="chess-status" id="status-${elementDoc.id}"></div>
            <div id="chessboard-${elementDoc.id}" class="dont-drag-me chessboard"></div>
          </div>
        `);
        break;
      }
      default: {
        log.error('Unknown Chess game state', this.state);
        return null;
      }
    }

    if (data.locked) {
      game.addEventListener('mousedown', this.stopPropagation);
      game.addEventListener('dragstart', this.preventDefault);
    } else {
      game.removeEventListener('mousedown', this.stopPropagation);
      game.removeEventListener('dragstart', this.preventDefault);
    }

    return wrapElement(game, elementDoc, {
      classes: ['chess-element'],
      preserveAspectRatio: true,
    });
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  preventDefault(e) {
    e.preventDefault();
  }

  startGame() {
    log.debug('Starting the game');
    this.game = new Chess();
    this.saveChess();
  }

  saveChess() {
    db.collection('boards').doc(window.currentBoardId).collection('elements').doc(this.elementId).update({
      state: GameState.started,
      fen: this.game.fen(),
    });
  }

  removeGreySquares() {
    $(`#chessboard-${this.elementId} .square-55d63`).css('background', '');
  }

  greySquare(square) {
    const $square = $(`#chessboard-${this.elementId} .square-${square}`);

    let background = whiteSquareGrey;
    if ($square.hasClass('black-3c85d')) {
      background = blackSquareGrey;
    }

    $square.css('background', background);
  }

  onDragStart(source, piece) {
    this.game = new Chess(this.fen);
    // do not pick up pieces if the game is over
    if (this.game.game_over()) return false;

    // only pick up pieces for the side to move
    if (
      (this.game.turn() === 'w' && piece.search(/^b/) !== -1) ||
      (this.game.turn() === 'b' && piece.search(/^w/) !== -1)
    ) {
      return false;
    }

    return true;
  }

  onDrop(source, target) {
    this.removeGreySquares();

    // see if the move is legal
    const move = this.game.move({
      from: source,
      to: target,
      promotion: 'q', // NOTE: always promote to a queen for example simplicity
    });

    // illegal move
    if (move === null) return 'snapback';

    this.updateStatus();

    return null;
  }

  onMouseoverSquare(square) {
    // get list of possible moves for this square
    const moves = this.game.moves({
      square,
      verbose: true,
    });

    // exit if there are no moves available for this square
    if (moves.length === 0) return;

    // highlight the square they moused over
    this.greySquare(square);

    // highlight the possible squares for this piece
    for (let i = 0; i < moves.length; i += 1) {
      this.greySquare(moves[i].to);
    }
  }

  onMouseoutSquare() {
    this.removeGreySquares();
  }

  // update the board position after the piece snap
  // for castling, en passant, pawn promotion
  onSnapEnd() {
    this.board.position(this.game.fen());
    this.updateStatus();
    this.saveChess();
  }

  updateStatus() {
    let status = '';

    let moveColor = 'White';
    if (this.game.turn() === 'b') {
      moveColor = 'Black';
    }

    // checkmate?
    if (this.game.in_checkmate()) {
      status = `Game over! ${moveColor} is in checkmate.`;
    } else if (this.game.in_draw()) {
      // draw?
      status = "Game over! It's a draw.";
    } else {
      // game still on
      status = `${moveColor} to move`;

      // check?
      if (this.game.in_check()) {
        status += `, and ${moveColor} is in check`;
      }
    }

    $(`#status-${this.elementId}`).html(status);
  }
}
