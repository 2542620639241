import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import * as store from '../store/template/store';
import { renderRoomKitSelectionScreen } from '../template/render';
import Draggable from './Draggable.tsx';
import FilterIcon from '../../../assets/icons/filter.svg';
import { svgColorMixin } from '../mixins';

export const ContainerView = ({
  kits,
  height,
  isLoading,
  hasMoreKits,
  onItemSelected,
  fetchTemplatesPage,
  setIsLoading,
  setNextPage,
  setType,
}) => {
  const [kitsFilter, setKitsFilter] = React.useState('');

  const onFilterChanged = useDebouncedCallback(() => {
    setIsLoading(true);
    setNextPage(1);
    fetchTemplatesPage({ filter: kitsFilter, replace: true });
  }, 700);

  React.useEffect(() => {
    setType('kits');
  }, [setType]);

  React.useEffect(() => {
    onFilterChanged.callback(kitsFilter);

    return () => {
      onFilterChanged.cancel();
    };
  }, [kitsFilter, onFilterChanged]);

  const observer = React.useRef(null);
  const lastTemplateItemRef = React.useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreKits) {
          fetchTemplatesPage({ filter: kitsFilter, replace: false });
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchTemplatesPage, hasMoreKits, kitsFilter]
  );

  function onSearchTermChange({ target: { value } }) {
    setKitsFilter(value);
  }

  return (
    <div>
      <InputContainer>
        <SearchIconStyled className="uk-form-icon uk-preserve" data-uk-icon="icon: search" />

        <Input
          type="text"
          className="uk-input themed-input"
          aria-label="Sticker packs search term"
          value={kitsFilter}
          onChange={onSearchTermChange}
        />

        <ViewFilters onClick={() => renderRoomKitSelectionScreen()}>
          <FilterIconStyled />
          <span>View Filters</span>
        </ViewFilters>
      </InputContainer>

      <KitsList height={height}>
        {!isLoading &&
          kits.map((item, index) => (
            <Item
              ref={index === kits.length - 1 ? lastTemplateItemRef : null}
              key={item.name}
              onClick={() => onItemSelected(item)}
            >
              <Draggable
                key={item.name}
                contentSelector="img"
                data={{ url: item.backgroundImage, type: 'roomKit', id: item.board }}
              >
                <StyledItem src={item.image} gradient={item.gradient} backgroundImage={item.image} name="" />
                <ItemTitle>{item.name}</ItemTitle>
                <ItemDescription>{item.description ? item.description : ''}</ItemDescription>
              </Draggable>
            </Item>
          ))}
      </KitsList>
    </div>
  );
};

ContainerView.propTypes = {
  kits: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      backgroundImage: PropTypes.string,
      image: PropTypes.string,
      gradient: PropTypes.string,
    })
  ),
  height: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMoreKits: PropTypes.bool.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  fetchTemplatesPage: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setNextPage: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
};

ContainerView.defaultProps = {
  kits: [],
};

export default connect(
  (state) => ({
    kits: store.getKits(state),
    isLoading: store.getIsLoading(state),
    hasMoreKits: store.getHasMore(state),
  }),
  {
    onItemSelected: (kit) => store.kitChosen({ kit }),
    fetchTemplatesPage: store.fetchTemplatesPage,
    setIsLoading: (isLoading) => store.setIsLoading({ isLoading }),
    setNextPage: (page) => store.setNextPage({ page }),
    setType: (type) => store.setType({ type }),
  }
)(ContainerView);

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SearchIconStyled = styled.span`
  ${svgColorMixin('var(--primary-foreground, black)')}
`;

const FilterIconStyled = styled(FilterIcon)`
  flex-grow: 0;
  margin-right: 0.5rem;

  ${svgColorMixin('var(--secondary-background, #6b1be3)')}
`;

// TODO: Reuse it from SearchableContent (or why not reusing the whole SearchableContent?)
const Input = styled.input`
  width: 100%;
`;

const ViewFilters = styled.h5`
  margin: auto 0.5rem;
  width: 30%;
  display: flex;
  font-size: 1rem;
  color: var(--secondary-background, #6b1be3);
  cursor: pointer;

  span {
    flex-grow: 1;
  }
`;

const KitsList = styled.div`
  height: ${({ height }) => height};
  overflow-y: auto;
`;

const Item = styled.div`
  background-size: cover;
  background-position: center;
  padding: 18px 18px 30px;
  width: 45%;
  max-width: 400px;
  margin: 14px 2% 10px;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  background-origin: border-box;
  cursor: pointer;
  vertical-align: top;
  position: relative;
  display: inline-block;
  height: auto;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const ItemTitle = styled.div`
  margin-top: 10px;
  font-size: 18px;
  color: var(--primary-foreground, black);
  font-weight: 600;
  line-height: 26px;
`;

const ItemDescription = styled.div`
  font-size: 16px;
  color: var(--primary-foreground, gray);
  line-height: 26px;
`;

const StyledItem = styled.img`
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  object-fit: cover;
`;
