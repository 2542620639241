/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/indent */
import _, { debounce, throttle } from 'lodash';
import log from '../log';

interface ObjectWithDisplayOrFeedId {
  display?: string;
  feed_display?: string;
  feed_id?: string;
  id?: string;
}

export enum CodecName {
  H264 = 'h264',
  AV1 = 'av1',
  VP8 = 'vp8',
}

export function isCodecSupported(codec: CodecName): boolean {
  const { codecs } = RTCRtpSender.getCapabilities('video');
  return !!codecs.find((c) => c.mimeType.toUpperCase().match(codec.toUpperCase()));
}

export function isAtLeastOneCodecSupported(codecs: CodecName[]) {
  return !!codecs.find((c) => isCodecSupported(c));
}

export function shouldExclude(inputParams: ObjectWithDisplayOrFeedId, excludeIds: string[]) {
  if (!inputParams) return false;
  const feedDisplay = inputParams.display ? inputParams.display : inputParams.feed_display;
  const feedId = inputParams.id ? inputParams.id : inputParams.feed_id;
  const found =
    [feedDisplay || '', feedId || ''].filter((id) => {
      if (excludeIds.includes(id.replace('screen-', ''))) {
        log.log(`Filtering by ${id}, excludeIds: ${excludeIds}`);
        return true;
      }
      return false;
    }).length > 0;
  return found;
}
export interface MemoizeDebouncedFunction<F extends (...args: any[]) => any> {
  (...args: Parameters<F>): void;
  flush: (...args: Parameters<F>) => void;
}

export function memoizeDebounce<F extends (...args: any[]) => any>(
  func: F,
  wait = 0,
  options: _.DebounceSettings = {},
  resolver?: (...args: Parameters<F>) => unknown
): MemoizeDebouncedFunction<F> {
  const debounceMemo = _.memoize<(...args: Parameters<F>) => _.DebouncedFunc<F>>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (..._args: Parameters<F>) => _.debounce(func, wait, options),
    resolver
  );

  function wrappedFunction(...args: Parameters<F>): ReturnType<F> | undefined {
    return debounceMemo(...args)(...args);
  }

  wrappedFunction.flush = (...args: Parameters<F>): void => {
    debounceMemo(...args).flush();
  };

  return wrappedFunction as unknown as MemoizeDebouncedFunction<F>;
}

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number,
  options: _.DebounceSettings = {}
) {
  const debounced = debounce(
    (resolve, reject, args: Parameters<F>) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    },
    wait,
    options
  );
  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    }) as ReturnType<F>;
}

export function asyncThrottle<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number,
  options: _.ThrottleSettings = {}
) {
  const throttled = throttle(
    (resolve, reject, args: Parameters<F>) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    },
    wait,
    options
  );
  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      throttled(resolve, reject, args);
    }) as ReturnType<F>;
}
