import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ChatPermissionsContext } from '../../../common/contexts';
import AdminCrownIcon from '../../../../../assets/icons/admin-crown.svg';
import { setMemberRole, unbanMember } from '../../../store/messaging/actions';
import { ChatMemberRoles } from '../../../../definitions/message';
import { selectMemberRoleInChat } from '../../../store/messaging/selectors';
import { renderBanUserModal } from '../../../web-lobby/modals/chat-management/render';
import ContextMenu from '../../../components/ContextMenu';
import { selectCurrentUserId } from '../../../store/users/selectors';
import MessageContextButton from '../../../chats/messages/context-menu/MessageContextButton';
import { svgColorMixin } from '../../../mixins';

interface Props {
  chatId: string;
  memberId: string;
  isBanned: boolean;
}

const MemberContextMenu = ({ chatId, memberId, isBanned }: Props) => {
  const dispatch = useDispatch();

  const permissions = useContext(ChatPermissionsContext);
  const memberRole = useSelector((state) => selectMemberRoleInChat(state, chatId, memberId));
  const currentUserId = useSelector(selectCurrentUserId);

  const onBanOptionClick = async () => {
    if (isBanned) {
      dispatch(unbanMember({ chatId, memberId }));
    } else {
      renderBanUserModal(memberId, chatId);
    }
  };

  const items = [];
  if (permissions.canRemoveMembers && memberId !== currentUserId && memberRole === ChatMemberRoles.Member) {
    items.push(
      <MessageContextButton key="ban" onClick={onBanOptionClick}>
        {isBanned ? 'Unban' : 'Ban'}
      </MessageContextButton>
    );
  }
  if (permissions.canChangeMemberRole && memberRole === ChatMemberRoles.Member && !isBanned) {
    items.push(
      <MessageContextButton
        onClick={() => dispatch(setMemberRole({ chatId, memberId, role: ChatMemberRoles.Admin }))}
        key="make-admin"
      >
        <StyledAdminCrownIcon />
        Make Admin
      </MessageContextButton>
    );
  }

  if (!items.length) return null;

  return (
    <ContextMenuContainer>
      <ContextMenu>
        <ContextMenuButtonsContainer className="prevent-on-click-outside">{items}</ContextMenuButtonsContainer>
      </ContextMenu>
    </ContextMenuContainer>
  );
};

export default MemberContextMenu;

const ContextMenuButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ContextMenuContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  z-index: 10;

  .chat-item-context-button {
    display: none;
  }
`;

const StyledAdminCrownIcon = styled(AdminCrownIcon)`
  margin-right: 2px;
  ${svgColorMixin('black')}
`;
