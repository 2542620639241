import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import axios from 'axios';

import log from './log';

const Typesense = require('typesense');

const ENV_PRODUCTION = 'prod';

const env = process.env.FIREBASE_ENV || ENV_PRODUCTION;
const useLocalServer = process.env.LOCAL_SERVER || false;

firebase.initializeApp(JSON.parse(process.env.FIREBASE_CONFIG));
firebase.analytics();

let apiUrl = process.env.API_URL ? process.env.API_URL : '';

if (useLocalServer) {
  apiUrl = 'http://localhost:8080';
}

export const db = firebase.firestore();
export const api = apiUrl;

export function isProductionEnv() {
  return env === ENV_PRODUCTION;
}

export const typesenseClient = new Typesense.Client({
  nodes: [
    {
      host: 'search-api.here.fm',
      protocol: 'https',
    },
  ],
  apiKey: 'OgmD4yQE8OfJUop3m4EstlwlncZwYFR7nmEgPh0FrB9Qkd2T',
  numRetries: 1,
  connectionTimeoutSeconds: 10,
});

// create axios instance:
export const authedApiInstance = axios.create({ baseURL: api });
export const unAuthedApiInstance = axios.create({ baseURL: api });

// set auth header for all requests made with axios instance:
authedApiInstance.interceptors.request.use(async (axiosConfig) => {
  const authToken = await firebase.auth().currentUser.getIdToken();
  axiosConfig.headers['X-Auth-Token'] = authToken;
  return axiosConfig;
});

window.getAuthToken = async () => {
  const token = await firebase.auth().currentUser.getIdToken();
  log.debug(token);

  return token;
};

export default firebase;
