import template from './chat-image-preview-modal.html';

import './chat-image-preview-modal.less';

export default class ChatImagePreviewModal extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;
  }
}

window.customElements.define('here-chat-image-preview-modal', ChatImagePreviewModal);
