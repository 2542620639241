import styled from 'styled-components';

// components
import { ellipsisTwoLinesTextMixin } from '../../../mixins';

export const MessageBubble = styled.div<{ bgColor?: string }>`
  margin-top: 6px;
  max-width: 190px;
  & {
    display: inline-block;
  }
  align-items: center;
  border-radius: 10px 10px 10px 0px;
  padding: 8px 9px;
  margin: 0;
  vertical-align: middle;

  ${({ bgColor }) =>
    bgColor &&
    `
  background: ${bgColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
`}

  ${ellipsisTwoLinesTextMixin()}
`;

export const MessageText = styled.div<{ fontFamily: string; fontColor: string; fontSize: string; lineHeight?: string }>`
  color: ${({ fontColor }) => fontColor};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight}` : '')}
`;

export const MessageImg = styled.img`
  width: 180px;
`;

export const ImageMargin = styled.div`
  margin-top: 6px;
`;

export const UserDisplayName = styled.div`
  font-size: 10px;
  color: white;
`;
