import React from 'react';

import { Categories, Emoji, categories, categoryToTitle } from '../constants';
import { SectionTitle } from '../shared-styles';
import EmoteButton from './EmoteButton';
import EmojiButton from './EmojiButton';
import { EmoteType } from '../../../definitions/emotes';

interface Props {
  frequentlyUsed: Emoji[];
  onEmojiClick: (emoji: string) => void;
  onEmoteClick: (emoteUrl: string, emoteType: EmoteType, pack?: string) => void;
  setCategoryRef: (element: HTMLDivElement | null, index: number) => void;
}

const FrequentlyUsedSection: React.FC<Props> = ({ frequentlyUsed, onEmojiClick, onEmoteClick, setCategoryRef }) => (
  <div id={Categories.FREQ_USED} ref={(el) => setCategoryRef(el, categories.indexOf(Categories.FREQ_USED))}>
    <SectionTitle>{categoryToTitle[Categories.FREQ_USED]}</SectionTitle>
    {frequentlyUsed.map((freqUsed) => {
      if (freqUsed.emoteUrl) {
        return <EmoteButton key={`freq-${freqUsed.emoteUrl}`} emoteUrl={freqUsed.emoteUrl} onSelect={onEmoteClick} />;
      }
      return <EmojiButton key={`freq-${freqUsed.emoji}`} emoji={freqUsed.emoji} onSelect={onEmojiClick} />;
    })}
  </div>
);

export default FrequentlyUsedSection;
