import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { confirmAlert } from 'react-confirm-alert';

// store
import { selectCurrentUser } from '../../../store/users/selectors';
import { fetchVisitedRooms } from '../../../store/visited-rooms/actions.ts';

// components
import { svgColorMixin } from '../../../mixins';

// web components
import openInDesktopModal from '../../../../components/open-in-desktop-modal';

// utils
import {
  copyLobbyRoomLink,
  deleteLobbyRoom,
  getRoomContextMenuOptions,
  leaveLobbyRoom,
} from '../../../../util/lobby-util';
import { attemptLoadInElectron } from '../../../../util/electron-util';

// constants
import {
  COPY_ROOM_LINK_OPTION,
  DELETE_ROOM_OPTION,
  DUPLICATE_ROOM_OPTION,
  LEAVE_ROOM_OPTION,
  OPEN_IN_DESKTOP_OPTION,
} from '../../../../constants/lobby-constants';

// icons
import HorizontalMoreIcon from '../../../../../assets/icons/horizontal-more.svg';
import { deleteRoomFromGroup } from '../../../../api/groups-api.ts';
import log from '../../../../log';
import { track } from '../../../../util/analytics-util';
import {
  DELETE_ROOM_FROM_GROUP,
  DeleteRoomFromGroupSources,
} from '../../../../constants/analytics-events/groups-events.ts';

const RoomItemContextMenu = ({ roomData }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [contextMenuOptions, setContextMenuOptions] = useState([]);

  const closeContextMenu = useCallback(() => {
    setContextMenuOpen(false);
  }, []);

  const refreshRoomsList = useCallback(() => {
    dispatch(fetchVisitedRooms());
  }, [dispatch]);

  const contextMenuOptionClickHandlers = useMemo(
    () =>
      Object.entries({
        [COPY_ROOM_LINK_OPTION.id]: () => {
          copyLobbyRoomLink(roomData);
        },

        [OPEN_IN_DESKTOP_OPTION.id]: () => {
          attemptLoadInElectron(roomData.id, () => {
            // desktop app is installed
            openInDesktopModal.open({
              roomTitle: roomData.title || 'Untitled Room',
              roomUrl: roomData.urlAlias || roomData.id || '/l',
              desktopAppOpened: true,
            });
          });
        },

        [DUPLICATE_ROOM_OPTION.id]: () => {
          const hereDuplicateDialog = document.getElementById('here-room-duplicate-dialog');
          hereDuplicateDialog.show(roomData, false);
        },

        [DELETE_ROOM_OPTION.id]: () => {
          confirmAlert({
            title: 'Delete Room',
            message: `Are you sure you want to delete the room "${roomData.title}"?`,
            buttons: [
              {
                label: 'OK',
                onClick: async () => {
                  try {
                    if (roomData.groupId) {
                      await deleteRoomFromGroup({ groupId: roomData.groupId, roomId: roomData.id });
                      track(DELETE_ROOM_FROM_GROUP, {
                        groupId: roomData.groupId,
                        roomId: roomData.id,
                        source: DeleteRoomFromGroupSources.ROOMS_TAB,
                      });
                    } else {
                      await deleteLobbyRoom(roomData.id);
                    }
                  } catch (e) {
                    log.error('Error deleting room from rooms tab', e);
                  }
                  refreshRoomsList();
                },
              },
              {
                label: 'Cancel',
              },
            ],
          });
        },

        [LEAVE_ROOM_OPTION.id]: () => {
          confirmAlert({
            title: 'Leave Room',
            message: `Are you sure you want to leave the room "${roomData.title}"?`,
            buttons: [
              {
                label: 'OK',
                onClick: async () => {
                  await leaveLobbyRoom(roomData.id);
                  refreshRoomsList();
                },
              },
              {
                label: 'Cancel',
              },
            ],
          });
        },
      }).reduce(
        (acc, [key, value]) => ({
          ...acc,

          [key]: () => {
            value();
            closeContextMenu();
          },
        }),
        {}
      ),
    [roomData, closeContextMenu, refreshRoomsList]
  );

  const onContextMenuButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const options = getRoomContextMenuOptions({ roomData, user: currentUser })
        .map((item) =>
          item.id === LEAVE_ROOM_OPTION.id || item.id === DELETE_ROOM_OPTION.id ? { ...item, isRed: true } : item
        )
        .sort((a, b) => a.order - b.order);

      setContextMenuOptions(options);
      setContextMenuOpen(true);
    },
    [roomData, currentUser]
  );

  return (
    <Popover open={contextMenuOpen}>
      <PopoverTrigger asChild>
        <ContextMenuButton contextMenuOpen={contextMenuOpen} onClick={onContextMenuButtonClick}>
          <HorizontalMoreIcon />
        </ContextMenuButton>
      </PopoverTrigger>

      <PopoverContent onPointerDownOutside={closeContextMenu} onOpenAutoFocus={(e) => e.preventDefault()}>
        <RoomOptionList>
          {contextMenuOptions.map((option) => (
            <RoomOptionListItem key={option.id}>
              <RoomOptionButton isRed={option.isRed} onClick={contextMenuOptionClickHandlers[option.id]}>
                <option.Icon height="16" width="16" />

                <span>{option.title}</span>
              </RoomOptionButton>
            </RoomOptionListItem>
          ))}
        </RoomOptionList>
      </PopoverContent>
    </Popover>
  );
};

RoomItemContextMenu.propTypes = {
  roomData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundColor: PropTypes.string,
    urlAlias: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
    groupId: PropTypes.string,
  }).isRequired,
};

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = styled(PopoverPrimitive.Content)`
  margin-top: 9px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 1px 10px 0 #00000080;
`;

const contextMenuButtonSizePx = 35;
export const ContextMenuButton = styled.button`
  height: ${contextMenuButtonSizePx}px;
  width: ${contextMenuButtonSizePx}px;
  display: ${({ contextMenuOpen }) => (contextMenuOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 100%;
  transform: rotate(90deg);
  background-color: #ffffffcc;
  cursor: pointer;

  ${svgColorMixin('#12002d')};

  &:hover {
    background-color: white;
  }
`;

const RoomOptionList = styled.ul`
  list-style: none;
`;

const RoomOptionListItem = styled.li``;

const RoomOptionButton = styled.button`
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  color: ${({ isRed }) => (isRed ? '#f6335d' : '#12002d')};
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background: none;
  cursor: pointer;

  ${({ isRed }) => (isRed ? svgColorMixin('#f6335d') : '')}

  &:hover {
    background-color: ${({ isRed }) => (isRed ? '#f6335d33' : '#efefef')};
  }

  &:focus {
    outline: none;
  }

  img {
    width: 15px;
  }
`;

export default RoomItemContextMenu;
