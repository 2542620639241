import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

// TODO: The reason is that we have 'addElement' call in saga. A better way
// would be to have a generic 'addElement' that can handle any type, since implementation
// of all 'addElement' methods is pretty the same. In that case the dependency cycle will be resolved.
// eslint-disable-next-line import/no-cycle
import { WithRoomStore } from '../../store/Wrapper';
import ChangeAvatarConfirmation from './ChangeAvatarConfirmation';

export default function showChangeAvatarConfirmation(newAvatarUrl) {
  confirmAlert({
    /* eslint-disable react/prop-types */
    customUI: ({ onClose }) => (
      <WithRoomStore>
        <ChangeAvatarConfirmation onClose={onClose} newAvatarUrl={newAvatarUrl} />
      </WithRoomStore>
    ),
    /* eslint-enable react/prop-types */
  });
}
