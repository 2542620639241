import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { addMessage } from '../../store/messaging/reducer';
import { selectRoomId } from '../../store/room/selectors';

import FriendsInviteButton from './FriendsInviteButton';
import { selectFriendsInOrderOfPresence } from '../../store/friends/selectors';
import { getRawPresentMembers } from '../../store/room-members/selectors';
import firebase, { db } from '../../../firebase';
import { fetchFriends } from '../../store/friends/store';
import { messageTypes } from '../../../constants/chat-constants';
import { CALL_FROM_INVITE_MODAL } from '../../../constants/analytics-events/dm-events';

const FriendsInviteList = () => {
  const dispatch = useDispatch();
  const [invitesSent, setInvitesSent] = useState({});
  const friendsListContainer = useRef(null);

  const usersInRoom = useSelector(getRawPresentMembers);
  const roomId = useSelector(selectRoomId);

  const orderedFriends = useSelector(selectFriendsInOrderOfPresence);

  useEffect(() => {
    // initial fetch:
    dispatch(fetchFriends());

    // TODO: we're doing this in a couple places now, it could probably be a hook
    const unsubscribe = db
      .collection('friendRequests')
      .where('requester', '==', firebase.auth().currentUser.uid)
      .where('status', '==', 'pending')
      .onSnapshot(() => {
        dispatch(fetchFriends());
      });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    // If the user switches rooms, we reset invites sent
    setInvitesSent({});
  }, [roomId]);

  const onFriendInviteClick = ({ user, chatId }) => {
    if (chatId) {
      dispatch(addMessage({ type: messageTypes.INVITE, chatId, roomId, inviteMessageSource: CALL_FROM_INVITE_MODAL }));
    } else {
      dispatch(
        addMessage({
          type: messageTypes.INVITE,
          receiverId: user.userId,
          roomId,
          createChat: true,
          inviteMessageSource: CALL_FROM_INVITE_MODAL,
        })
      );
    }

    setInvitesSent({ ...invitesSent, [user.userId]: true });
  };

  // This is necessary so that our users with a mouse can side scroll with their mousewheels
  const onMouseScroll = (e) => {
    if (e.deltaY) {
      friendsListContainer.current.scrollLeft += e.deltaY;
    }
  };

  return (
    <FriendsInviteListContainer ref={friendsListContainer} onWheel={onMouseScroll}>
      {roomId &&
        orderedFriends &&
        orderedFriends.map((f) => (
          <FriendsInviteButton
            key={f.userId}
            roomId={roomId}
            user={f}
            inviteAlreadySent={invitesSent[f.userId]}
            alreadyInRoom={!!usersInRoom[f.userId]}
            onFriendInviteClick={onFriendInviteClick}
          />
        ))}
    </FriendsInviteListContainer>
  );
};

export default FriendsInviteList;

const FriendsInviteListContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
