import { screenToCanvasCoords } from './util/canvas-util';
import firebase, { db } from './firebase';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import BoardElement from './board-element';

import '../styles/barrier.less';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

export default class BarrierElement extends BoardElement {
  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, elementDoc) {
    const data = elementDoc.data();
    [this.x, this.y] = data.center;
    [this.w, this.h] = data.size;

    if (data.backgroundColor) {
      const element = document.getElementById(`element-${elementDoc.id}`);
      element.querySelector('.barrier').style.backgroundColor = data.backgroundColor;
    }

    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(__elementId, elementDoc) {
    const data = elementDoc.data();
    [this.x, this.y] = data.center;
    [this.w, this.h] = data.size;
  }

  // Required method
  getElement(elementDoc) {
    const barrier = htmlToElement(`
      <div class="barrier">
      </div>
    `);

    const data = elementDoc.data();
    if (data.backgroundColor) {
      barrier.style.backgroundColor = data.backgroundColor;
    }

    const wrappedElement = wrapElement(barrier, elementDoc, {
      classes: ['barrier-element'],
    });

    const colorButton = htmlToElement(`
      <div>
      <div
        class="element-option-button bg-color-select-button"
        uk-tooltip="title: Background Color"
        style="background-image: url(images/icons/bg-fill-black.png)">
      </div>
      <div uk-dropdown="mode: click; pos: top-justify" class="element-submenu color-dropdown">
        <here-color-select class="barrier-bg-color-select" alpha="true" />
      </div>
      </div>
    `);
    colorButton.querySelector('.barrier-bg-color-select').addEventListener('colorchange', (e) => {
      const backgroundColor = e.detail.color;
      barrier.style.backgroundColor = backgroundColor;
      if (e.detail.inputComplete) {
        elementDoc.ref.update({ backgroundColor });
      }
    });

    // inject the bg color select button
    const optionsButton = wrappedElement.querySelector('.element-overflow');
    optionsButton.parentNode.insertBefore(colorButton, optionsButton);

    return wrappedElement;
  }

  preserveAspectRatio() {
    return false;
  }

  // Statics

  static async addElement(additionalProperties = {}) {
    const center =
      additionalProperties.center ||
      screenToCanvasCoords(
        Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
        Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
      );
    delete additionalProperties.center;
    await db.collection(`boards/${window.currentBoardId}/elements`).add({
      class: 'BarrierElement',
      center,
      creator: firebase.auth().currentUser.uid,
      size: [50, 500],
      zIndex: window.getFrontZIndex(),
      ...additionalProperties,
    });
    track(ADD_ELEMENT, { element: ELEMENT_TYPES.BARRIER, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
  }
}
BarrierElement.elementType = 'BarrierElement';
