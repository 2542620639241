import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../store/Wrapper';
import Sidebar from './Sidebar';

export function renderSidebar() {
  ReactDOM.render(
    <WithRoomStore>
      <Sidebar />
    </WithRoomStore>,
    document.getElementById('sidebar-root')
  );
}
