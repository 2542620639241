import { useCallback, useEffect, useState } from 'react';

import { offUserListChanged, onUserListChanged } from '../../../presence';

/**
 *
 * GOAL: we need to know current members of the room
 * For example, active rooms in the lobby should display list of current members
 *
 * LIMITATION: we need to reduce number of onUserListChanged-subscriptions
 * We need to reduce it because:
 * 1) In _some_ cases users doesn't need to know about members of the room deep down in the list -> it does make sense to add members subscriptions only for visible rooms
 * 2) When a real-time subscription (onUserListChanged) is active, each update causes performing a read operation; read operations in firebase are paid
 * 3) Change of the members list makes UI to rerender -> it does make sense to rerender only visible rooms
 *
 * @param {object[]} roomsList - list of room objects
 * @param {string[]} roomsToListenIds  - list of rooms ids to listen for memebers
 * @returns {{roomsListWithMembers: object[]}} - passed roomsList param + rooms having ids in the roomsToListenIds param have 'members'-field
 */
export const useRoomsListMembers = (roomsList = [], roomsToListenIds = []) => {
  // on each roomsToListenIds change we need to know:
  // 1) which rooms were visible but now hidden (for example, after user scrolled; user deleted a room);
  // 2) which rooms were hidden but now visible (for example, after user scrolled; user created a room);
  // 3) which rooms are still visible;
  // to calculate it, we need to know visible rooms from the previous iteration
  const [, setPrevRoomsToListenIds] = useState([]);

  // each room from the returned list is expected to have a 'members'-field
  const [roomsListWithMembers, setRoomsListWithMembers] = useState(() =>
    roomsList.map((room) => ({ ...room, members: [] }))
  );

  // onUserListChangedHandler updates 'members'-field of the related room
  // using useCallback here because we need to keep 'onUserListChangedHandler'-reference the same, so offUserListChanged will unsubscribe correctly
  const onUserListChangedHandler = useCallback((members, _lastValue, roomId) => {
    setRoomsListWithMembers((prevRoomsList) =>
      prevRoomsList.map((room) =>
        room.id === roomId
          ? {
              ...room,
              members: Object.entries(members || {}).map(([memberId, member]) => ({ id: memberId, ...member })),
            }
          : room
      )
    );
  }, []);

  useEffect(() => {
    // the main goal of this hook is to attach a 'members'-field to each item of passed roomsList-param
    // this effects listens to roomsList; its task -- make each room item have 'members'-field
    // also it (just in case!) calculates items deleted from the list and triggers unsubscribe
    setRoomsListWithMembers((prevRoomsList) => {
      const roomsToStopListenIds = prevRoomsList
        .filter((prevRoom) => !roomsList.find((room) => room.id === prevRoom.id))
        .map(({ id }) => id);

      setPrevRoomsToListenIds((prevRoomsIds) =>
        prevRoomsIds.filter((roomId) => !roomsToStopListenIds.includes(roomId))
      );

      roomsToStopListenIds.forEach((roomId) => {
        offUserListChanged(roomId, onUserListChangedHandler);
      });

      return roomsList.map((room) => {
        const prevRoomData = prevRoomsList.find((prevRoom) => prevRoom.id === room.id);
        if (prevRoomData) {
          return { ...prevRoomData, ...room };
        }

        return { ...room, members: [] };
      });
    });
  }, [roomsList, onUserListChangedHandler]);

  useEffect(() => {
    // this effect listens to the list of visible room ids
    // when the list of visible room ids changes, it calculates:
    // 1) which rooms are not visible anymore (roomsToStopListenIds) -- in this case we need to cancel a onUserListChanged subscription
    // 2) which rooms are visible now (roomsToStartListenIds) -- in this case we need to add a onUserListChanged subscription
    // 3) which rooms are STILL visible (roomsToKeepListeningIds) -- in this case we don't update it's subscription

    // I need to use the setPrevRoomsToListenIds-setter instead of the prevRoomsToListenIds-value,
    // because adding the prevRoomsToListenIds to the effect deps will cause an endless loop, as we set the prevRoomsToListenIds in this effect
    setPrevRoomsToListenIds((prevRoomsIds) => {
      // calculate values
      const roomsToStopListenIds = prevRoomsIds.filter((prevRoomId) => !roomsToListenIds.includes(prevRoomId));
      const roomsToStartListenIds = roomsToListenIds.filter((roomId) => !prevRoomsIds.includes(roomId));
      const roomsToKeepListeningIds = prevRoomsIds.filter((prevRoomId) => roomsToListenIds.includes(prevRoomId));

      // add subscriptions
      roomsToStartListenIds.forEach((roomId) => {
        onUserListChanged(roomId, onUserListChangedHandler);
      });

      // remove subscriptions
      roomsToStopListenIds.forEach((roomId) => {
        offUserListChanged(roomId, onUserListChangedHandler);
      });

      // update prevRoomsToListenIds value
      return (
        [...roomsToStartListenIds, ...roomsToKeepListeningIds]
          // there won't be any duplicates, but just in case, filtering array to keep only unique values
          .filter((id, index, array) => array.indexOf(id) === index)
      );
    });
  }, [roomsList, roomsToListenIds, onUserListChangedHandler]);

  return { roomsListWithMembers };
};
