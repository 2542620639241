import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HereTextArea = ({ onClick, onChange, onKeyPress, placeholder, value, errorMessage, smallStyle, testId }) => (
  <>
    <TextAreaContainer onClick={onClick} errorMessage={errorMessage}>
      <StyledTextArea
        row="3"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        smallStyle={smallStyle}
        data-testid={testId}
      />
    </TextAreaContainer>
    {errorMessage && <ErrorMessage smallStyle>{errorMessage}</ErrorMessage>}
  </>
);

export default HereTextArea;

HereTextArea.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  smallStyle: PropTypes.bool,
  testId: PropTypes.string,
};

HereTextArea.defaultProps = {
  value: '',
  onClick: () => {}, // no-op
  onChange: () => {}, // no-op
  onKeyPress: () => {}, // no-op
  errorMessage: '',
  placeholder: '',
  smallStyle: false,
  testId: null,
};

const TextAreaContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  height: 105px;

  background: rgba(255, 255, 255, 0.1);
  outline: 1px solid var(--primary-foreground-alpha-20, rgba(255, 255, 255, 0.2));
  transition: all 0.3s ease;

  &:hover {
    outline: 1px solid var(--primary-foreground-alpha-50, rgba(255, 255, 255, 0.5));
  }

  &:focus-within {
    outline: 2px solid var(--secondary-background, #6b1be3);
  }

  ${(props) =>
    props.errorMessage &&
    `
    border: 1px solid rgba(246, 51, 93, 1);

    &:hover {
      border: 1px solid rgba(246, 51, 93, 1);
    }

    &:focus-within {
      border: 1px solid rgba(246, 51, 93, 1);
    }
  `}
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  font-size: 1em;
  color: var(--primary-foreground, white);
  line-height: 24px;

  padding: 10px;

  border: none;
  overflow: auto;
  outline: none;

  box-shadow: none;
  resize: none;
  background: top;

  ${(props) =>
    props.smallStyle &&
    `
    font-size: 0.95em;
  `}

  &::placeholder {
    color: var(--primary-foreground-alpha-50, rgba(160, 160, 160, 0.5));
  }
`;

const ErrorMessage = styled.div`
  color: #f6335d;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  margin-top: 5px;

  ${(props) =>
    props.smallStyle &&
    `
    font-size: 12px;
    line-height: 18px;
  `}
`;
