import { eventChannel } from 'redux-saga';
import { select, call, all, takeEvery, put, takeLatest, join, fork } from 'redux-saga/effects';

import firebase, { db } from '../../../firebase';
import { addSystemMessage } from '../../../message-util';
import { onSnapshot, offSnapshot } from '../../../firestore-watcher';
import { onUserListChanged, offUserListChanged } from '../../../presence';

import * as store from './store';
import { roomSwitched } from '../room/store';
import { selectRoomId } from '../room/selectors';
import { selectCurrentUserId } from '../users/selectors';
import { watchCameraContextMenuItemClick } from '../common-sagas';

import {
  fetchBannedMembers,
  fetchCurrentMemberData,
  fetchCurrentMembersData,
  fetchOfflineMembersPage,
} from '../../../api/members-api';
import {
  getCurrentMembers,
  getMember,
  getMemberIsOnline,
  getMemberName,
  getPresentMemberIds,
  getRawMembers,
  getRawPresentMembers,
} from './selectors';
import { selectCanAddToFriend, selectFirstTimeMemberFriendSuggestion } from '../friends/selectors';
import { setFirstTimeMemberFriendSuggestion } from '../friends/store';
import { banUser } from './api.ts';
import { BANNED_BY_QUERY_PARAM, KICKED_BY_QUERY_PARAM } from '../../../constants/lobby-constants';

const MAX_MEMBERS_IN_PAGE = 50;
const STATE_NAME_BAN = 'ban';
const STATE_NAME_KICK = 'kick';

// TODO: Put them into users store
const memberProfileChannels = {};
function* watchMemberProfileUpdated(id) {
  memberProfileChannels[id] = eventChannel((emitter) => {
    const listener = (user) =>
      user &&
      emitter({
        ...user,
        name: user.displayName,
        isAnonymous: !!user.isAnonymous,
      });

    onSnapshot(`userProfiles/${id}`, listener);
    return () => offSnapshot(`userProfiles/${id}`, listener);
  });

  yield takeEvery(memberProfileChannels[id], function* handleMemberProfileUpdated(data) {
    yield put(store.memberProfileUpdated({ id, data }));
  });
}

const membershipChannels = {};
function* watchMembershipUpdates(boardId, memberId) {
  membershipChannels[memberId] = eventChannel((emitter) => {
    const listener = (member) => member && emitter(member);
    onSnapshot(`boards/${boardId}/members/${memberId}`, listener);
    return () => offSnapshot(`boards/${boardId}/members/${memberId}`, listener);
  });

  yield takeEvery(membershipChannels[memberId], function* handleMembershipUpdate(data) {
    yield put(store.currentMemberUpdated({ id: memberId, data }));
  });
}

function* handleMembersListUpdated({ payload: { members, oldMembers } }) {
  const roomId = yield select(selectRoomId);

  const oldIds = oldMembers.map((m) => m.id);
  const newIds = members.map((m) => m.id);

  const idsToRemove = oldIds.filter((id) => !newIds.includes(id));
  idsToRemove.forEach((id) => {
    if (memberProfileChannels[id]) {
      memberProfileChannels[id].close();
      delete memberProfileChannels[id];
    }

    if (membershipChannels[id]) {
      membershipChannels[id].close();
      delete membershipChannels[id];
    }
  });

  const idsToAdd = newIds.filter((id) => !oldIds.includes(id));
  for (let i = 0; i < idsToAdd.length; i += 1) {
    yield fork(watchMemberProfileUpdated, idsToAdd[i]);
    yield fork(watchMembershipUpdates, roomId, idsToAdd[i]);
  }
}

function* handleNewMemberJoined(memberId) {
  const canAddToFriend = yield select((state) => selectCanAddToFriend(state, memberId));
  const suggestion = yield select(selectFirstTimeMemberFriendSuggestion);
  if (canAddToFriend && !suggestion) {
    yield put(setFirstTimeMemberFriendSuggestion({ suggestion: { memberId } }));
  }
}

function* handleOfflineMembersPageRequested({ payload: { lastMemberId, replace } }) {
  const roomId = yield select(selectRoomId);
  const membersPageCollections = yield call(fetchOfflineMembersPage, roomId, MAX_MEMBERS_IN_PAGE, lastMemberId);
  const membersPage = membersPageCollections.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const oldMembers = yield select(getCurrentMembers);
  const onlineMembers = yield select(getRawPresentMembers);
  const oldMembersWithoutJustFetched = oldMembers.filter(
    (oldMember) => !membersPage.find((newMember) => newMember.id === oldMember.id)
  );

  const members = replace ? membersPage : [...oldMembersWithoutJustFetched, ...membersPage];

  const notStoredMemberIds = Object.keys(onlineMembers).filter(
    (onlineMemberId) => !members.find((member) => member.id === onlineMemberId)
  );

  if (notStoredMemberIds.length) {
    const notStoredMembers = yield call(fetchCurrentMembersData, roomId, notStoredMemberIds);
    const notStoredMembersData = notStoredMembers.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    members.push(...notStoredMembersData);
  }

  yield put(store.setHasMoreOfflineUsers({ hasMoreOfflineMembers: membersPage.length === MAX_MEMBERS_IN_PAGE }));
  yield put(store.setCurrentMembers({ members, oldMembers }));
  return membersPageCollections;
}

function* handleBannedMembersRequested() {
  const roomId = yield select(selectRoomId);
  const bannedMembersCollections = yield call(fetchBannedMembers, roomId);
  const bannedMembers = bannedMembersCollections.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  yield put(store.setBannedMembers({ bannedMembers }));
}

function* watchOnlineMembers({ payload: { roomId } }) {
  if (!roomId) {
    return;
  }

  const channel = eventChannel((emitter) => {
    const listener = (members) => members && emitter(members);

    onUserListChanged(roomId, listener);
    return () => offUserListChanged(roomId, listener);
  });

  try {
    const task = yield takeEvery(channel, function* onMembersListUpdated(members) {
      const oldMemberIds = yield select(getPresentMemberIds);
      const newMemberIds = members ? Object.keys(members) : [];

      if (oldMemberIds.length !== newMemberIds.length || !oldMemberIds.every((id) => newMemberIds.includes(id))) {
        const oldMembers = yield select(getRawMembers);

        const notStoredMemberIds = Object.keys(members).filter(
          (onlineMemberId) => !oldMembers.find((member) => member.id === onlineMemberId)
        );

        const notStoredMembers = yield call(() =>
          Promise.all(notStoredMemberIds.map((memberId) => fetchCurrentMemberData(roomId, memberId)))
        );

        yield put(store.setCurrentMembers({ members: [...oldMembers, ...notStoredMembers], oldMembers }));
        yield put(store.onlineMembersListUpdated({ members }));

        if (oldMembers.length > 0 && oldMembers.length < MAX_MEMBERS_IN_PAGE) {
          // For now we can't tell if a new member has just joined if pagination is enabled
          // (i.e. current members count equal or greater than MAX_MEMBERS_IN_PAGE).
          // TODO: Do something to always know if a new member is really new.
          for (let i = 0; i < notStoredMemberIds.length; i += 1) {
            yield call(handleNewMemberJoined, notStoredMemberIds[i]);
          }
        }
      }
    });

    yield join(task);
  } finally {
    channel.close();
  }
}

function* watchMembersList({ payload: { roomId } }) {
  if (!roomId) {
    return;
  }

  const membersPageCollections = yield call(handleOfflineMembersPageRequested, {
    payload: { lastMemberId: null, replace: true },
  });

  yield call(handleBannedMembersRequested);
  yield put(
    store.setHasMoreOfflineUsers({ hasMoreOfflineMembers: membersPageCollections.docs.length === MAX_MEMBERS_IN_PAGE })
  );

  yield call(watchOnlineMembers, { payload: { roomId } });
}

function* handleMemberInfoUpdateRequested({ payload: { id, data } }) {
  const roomId = yield select(selectRoomId);
  yield call([db.doc(`boards/${roomId}/members/${id}`), 'update'], data);
}

// Handles both being kicked (membership is removed) and banned (the flag is set in the membership).
function* handleBeingKicked({ payload: { data } }) {
  const myId = yield select(selectCurrentUserId);

  const isKicked = data.id === myId && data.kick;
  const isBanned = data.id === myId && data.ban;

  if (!isKicked && !isBanned) return;

  if (isBanned) {
    window.location.href = `/l?${BANNED_BY_QUERY_PARAM}=${data.ban.by}`;
  } else if (isKicked) {
    window.location.href = `/l?${KICKED_BY_QUERY_PARAM}=${data.kick.by}${
      data.kick.mode ? `&mode=${data.kick.mode}` : ''
    }`;
  }
}

function* handleMemberUnbanRequested({ payload: { id } }) {
  yield call(handleMemberInfoUpdateRequested, { payload: { id, data: { ban: null } } });
}

function* updateMemberState(id, stateName) {
  const roomId = yield select(selectRoomId);
  if (stateName === STATE_NAME_BAN) {
    yield call(banUser, roomId, id);
  } else {
    const currentUserId = yield select(selectCurrentUserId);
    const data = {
      [stateName]: {
        by: currentUserId,
        at: firebase.firestore.FieldValue.serverTimestamp(),
      },
    };

    yield call([db.doc(`boards/${roomId}/members/${id}`), 'update'], data);
  }
}

function* handleMemberKickRequested({ payload: { id } }) {
  yield call(updateMemberState, id, STATE_NAME_KICK);

  const name = yield select(getMemberName, { id });
  const [firstName] = name.split(' ');
  yield call(addSystemMessage, `kicked ${firstName} from the room`);

  yield call([window.analytics, 'track'], 'Kick User');
}

function* handleMemberBanRequested({ payload: { id } }) {
  yield call(updateMemberState, id, STATE_NAME_BAN);

  const member = yield select(getMember, { id });
  const [firstName] = member.name.split(' ');
  yield call(addSystemMessage, `banned ${firstName} from the room`);

  yield call([window.analytics, 'track'], 'Ban User');
}

function* handlePromotionToMemberRequested({ payload: { id } }) {
  const roomId = yield select(selectRoomId);
  const currentUserId = yield select(selectCurrentUserId);
  const isOnline = yield select(getMemberIsOnline, { id });

  if (isOnline) {
    const ref = firebase.database().ref(`/memberPromotions/${roomId}/${id}`);
    yield call([ref, 'set'], {
      status: 'requested',
      by: currentUserId,
    });
  } else {
    yield call([db.doc(`boards/${roomId}/members/${id}`), 'update'], { role: null });
  }
}

function* handleCameraContextMenuItemClick(payload) {
  if (payload.option === 'make-host') {
    yield put(store.memberInfoUpdateRequested({ id: payload.userId, data: { role: 'host' } }));
  } else if (payload.option === 'make-member') {
    yield put(store.memberInfoUpdateRequested({ id: payload.userId, data: { role: null } }));
  } else if (payload.option === STATE_NAME_KICK) {
    yield put(store.memberKickRequested({ id: payload.userId }));
  } else if (payload.option === STATE_NAME_BAN) {
    yield put(store.memberBanRequested({ id: payload.userId }));
  }
}

export default function* roomMembersSaga() {
  yield all([
    takeEvery(store.memberInfoUpdateRequested, handleMemberInfoUpdateRequested),
    takeEvery(store.memberKickRequested, handleMemberKickRequested),
    takeEvery(store.memberBanRequested, handleMemberBanRequested),
    takeEvery(store.memberUnbanRequested, handleMemberUnbanRequested),
    takeEvery(store.setCurrentMembers, handleMembersListUpdated),
    takeEvery(store.currentMemberUpdated, handleBeingKicked),
    takeEvery(store.offlineMembersPageRequested, handleOfflineMembersPageRequested),
    takeEvery(store.bannedMembersRequested, handleBannedMembersRequested),
    takeLatest(roomSwitched, watchMembersList),
    takeEvery(store.promotionToMemberRequested, handlePromotionToMemberRequested),
    watchCameraContextMenuItemClick(handleCameraContextMenuItemClick),
  ]);
}
