import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import roomRootStore from './room-root-store';

export const WithRoomStore = ({ children }) => <Provider store={roomRootStore}>{children}</Provider>;

WithRoomStore.propTypes = {
  children: PropTypes.node.isRequired,
};
