import ReactDOM from 'react-dom';

import firebase, { api, authedApiInstance, db } from '../firebase';
import log from '../log';

// react
import { renderLobbyTemplateSelectionScreen } from '../react/template/render';

// utils
import { getRandomNumberInRange } from '../util';
import { createRoom } from './user-util';
import { checkIsElectron, checkIsMobile } from './platform-util';
import { track } from './analytics-util';

// constants
import {
  COPY_ROOM_LINK_OPTION,
  DELETE_ROOM_OPTION,
  HIDE_VIDEO_QUALITY_SURVEY,
  LEAVE_ROOM_OPTION,
  OPEN_IN_DESKTOP_OPTION,
  DUPLICATE_ROOM_OPTION,
} from '../constants/lobby-constants';
import { DUPLICATE_ROOM, SUBMIT_FEEDBACK } from '../constants/analytics-events/lobby-events';
import { renderCreateOsDesktopFlow } from '../react/sign-in-flow/render';
import { Roles } from '../definitions/groups.ts';

export const roomLastAccessed = {};

export function copyLobbyRoomLink({ id, urlAlias }) {
  let url;
  if (urlAlias) {
    url = `${window.location.origin}/${urlAlias}`;
  } else {
    url = `${window.location.origin}/${id}`;
  }

  window.navigator.clipboard.writeText(url).then(() => {
    log.debug('Room link copied');
  });
}

export async function deleteLobbyRoom(id) {
  await authedApiInstance.delete(`${api}/room/${id}`);
}

export async function leaveLobbyRoom(id) {
  await authedApiInstance.delete(`${api}/room/${id}/leave`);
}

export async function deleteLobbyRoomOLD(id) {
  const members = await db.collection(`boards/${id}/members`).get();
  const memberIds = members.docs.map((m) => m.id);
  const batch = db.batch();
  memberIds.forEach((mid) => batch.delete(db.doc(`memberships/${mid}/boards/${id}`)));
  batch.delete(db.doc(`boards/${id}`));
  await batch.commit();
}

export async function leaveLobbyRoomOLD(id, userId) {
  const batch = db.batch();
  batch.delete(db.doc(`memberships/${userId}/boards/${id}`));
  batch.delete(db.doc(`boards/${id}/members/${userId}`));
  await batch.commit();
}

export function generateDuplicatedRoomTitle(roomTitle) {
  let resultTitle = roomTitle;

  const titleChunks = resultTitle.split(' ');
  const lastChunk = titleChunks[titleChunks.length - 1];
  const lastChunkNum = parseInt(lastChunk, 10);
  if (lastChunkNum.toString() === lastChunk) {
    resultTitle = `${titleChunks.slice(0, titleChunks.length - 1).join(' ')} ${lastChunkNum + 1}`;
  } else {
    resultTitle = `${resultTitle} 2`;
  }

  return resultTitle;
}

export async function duplicateRoom({ boardId, title }) {
  const board = await db.doc(`boards/${boardId}`).get();
  await createRoom({ starterBoard: board, title, urlAlias: null });
  track(DUPLICATE_ROOM, { id: boardId });
}

const GREETINGS = ['Hello', 'Yo', 'Hiya', 'Hey'];

export const getRandomGreeting = () => GREETINGS[Math.floor(getRandomNumberInRange(0, GREETINGS.length))];

export const getRoomContextMenuOptions = ({ user, roomData }) => {
  // always show 'Copy Room Link'-option
  const options = [{ ...COPY_ROOM_LINK_OPTION }];

  // only the owner or host is able to duplicate a room
  if (user.id === roomData.creator || roomData.role === Roles.Owner || roomData.role === Roles.Host) {
    options.push({ ...DUPLICATE_ROOM_OPTION });

    // only creator is able to delete their room
    if (user.id === roomData.creator) {
      options.push({ ...DELETE_ROOM_OPTION });
    }
  } else {
    options.push({ ...LEAVE_ROOM_OPTION });
  }

  if (!checkIsElectron() && user.isAdmin) {
    options.push({ ...OPEN_IN_DESKTOP_OPTION });
  }

  return options;
};

export const openTemplatePickerModal = (groupId = '') => {
  // pass in groupId if you want to create room for a specific group
  document.querySelector('#render-template-select').style.height = '100%';
  ReactDOM.unmountComponentAtNode(document.querySelector('#render-template-select'));
  renderLobbyTemplateSelectionScreen({ groupId });
};

export const openVibePicker = () => {
  let createOsDesktopContainer = document.querySelector('#create-os-desktop');
  if (!createOsDesktopContainer) {
    createOsDesktopContainer = document.createElement('div');
    createOsDesktopContainer.id = 'create-os-desktop';
    createOsDesktopContainer.style.position = 'absolute';
    createOsDesktopContainer.style.top = '0';
    createOsDesktopContainer.style.left = '0';
    createOsDesktopContainer.style.zIndex = '100000';
    document.body.appendChild(createOsDesktopContainer);
  }

  renderCreateOsDesktopFlow(createOsDesktopContainer);
};

/**
 *
 * Checks if the QualitySurvey modal should be shown
 *
 * @param {string} activityId
 * @returns {boolean}
 */
export const isQualitySurveyRequired = async (activityId) => {
  const item = localStorage.getItem(HIDE_VIDEO_QUALITY_SURVEY);
  const expiration = 1000 * 60 * 60 * 24 * 14; // two weeks
  if (item && new Date().getTime() - item < expiration) {
    return false;
  }

  const activity = await db.collection('globalActivity').doc(activityId).get();
  const data = activity.data();
  const uids = Object.keys(data.users);
  return uids.length > 1; // if more than 1 user were in room
};

export const sendFeedback = async ({ rating, details, feedbackTags, activityId }) => {
  const sessionSnapshot = await db.collection('globalActivity').doc(activityId).get();
  const videoServer = sessionSnapshot.data().videoServer || null;
  const isPublic = sessionSnapshot.data().isPublic || null;

  track(SUBMIT_FEEDBACK, { rating, feedbackTags, videoServer, isPublic });

  await db.collection('qualitySurvey').add({
    rating,
    details,
    feedbackTags,
    user: firebase.auth().currentUser.uid,
    videoServer,
    isPublic,
    activityId,
    isMobileWeb: checkIsMobile(),
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export const cleanQueryString = () => window.history.replaceState(null, document.title, '/l');
