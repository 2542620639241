import { db } from '../../../firebase';
import bus, { roomSettingsUpdated } from '../../../event-bus';

import template from './background-props.html';

import './background-props.less';
import { isManageAllContentAllowed } from '../../../roles-management';
import { renderRoomBordersSettings, unmountRoomBordersSettings } from '../../../react/room/settings/background/render';
import { track } from '../../../util/analytics-util';

class BackgroundProperties extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    this.roomBackgroundOverlay = this.querySelector('#room-background-overlay');
    this.roomBackgroundOverlayTitle = this.querySelector('#room-background-overlay-title');

    this.querySelector('.change-background').addEventListener('click', () => this.onChangeBackground());
    this.querySelector('.bg-pen-indicator').addEventListener('click', () => this.onChangeBackground());
    this.querySelector('.bg-layout').addEventListener('change', this.onBackgroundTile.bind(this));
    this.querySelector('.bg-scrolling').addEventListener('change', this.onBackgroundScroll.bind(this));
    this.querySelector('.bg-size').addEventListener('change', this.onBackgroundSize.bind(this));
    this.querySelector('.bg-size').addEventListener('input', this.updatePreviewBackgroundSize.bind(this));
    this.querySelector('here-color-select').addEventListener('colorchange', this.onColorSelect.bind(this));
    if (window.currentBoardData) {
      this.onBoardUpdate(window.currentBoardData);
    }

    this.bordersSettingsContainer = this.querySelector('#room-borders-settings');
    if (isManageAllContentAllowed()) {
      renderRoomBordersSettings(this.bordersSettingsContainer);
    }
  }

  disconnectedCallback() {
    unmountRoomBordersSettings(this.bordersSettingsContainer);
  }

  restartObjectAnimation() {
    this.querySelector('.faux-canvas-object-1').style.animation = 'none';
    this.querySelector('.faux-canvas-object-2').style.animation = 'none';
    // Trigger a reflow to restart the animation
    // eslint-disable-next-line no-unused-expressions
    this.querySelector('.faux-canvas-object-1').offsetHeight;
    // eslint-disable-next-line no-unused-expressions
    this.querySelector('.faux-canvas-object-2').offsetHeight;
    this.querySelector('.faux-canvas-object-1').style.animation = '';
    this.querySelector('.faux-canvas-object-2').style.animation = '';
  }

  onBoardUpdate(board) {
    const bgColor = board.backgroundColor || 'black';
    if (bgColor) {
      this.querySelector('here-color-select').setColor(bgColor);
    }
    this.querySelector('.bg-color-select').backgroundColor = bgColor;

    this.querySelector('.bg-size').value = board.backgroundWidth || 1000;
    if (board.backgroundScroll) {
      this.querySelector('.bg-scrolling').value = board.backgroundScroll;
    }
    if (board.backgroundTile) {
      this.querySelector('.bg-layout').value = board.backgroundTile;
    }
    this.currentBackground = board.background;

    const preview = this.querySelector('.background-preview');
    preview.style.backgroundColor = bgColor;
    if (board.backgroundScroll !== 'scroll') {
      if (preview.classList.contains('canvas-movement')) {
        this.restartObjectAnimation();
        preview.classList.remove('canvas-movement');
      }
      preview.style.backgroundImage = '';
      if (board.background) {
        if (isNaN(board.background)) {
          this.querySelector('.browser-window').style.backgroundImage = `url('${board.background}')`;
        } else {
          this.querySelector('.browser-window').style.backgroundImage = `url('images/bg/${board.background}.jpg')`;
        }
      }
      preview.style.backgroundColor = 'black';
    } else {
      this.querySelector('.browser-window').style.backgroundImage = '';
      if (!preview.classList.contains('canvas-movement')) {
        this.restartObjectAnimation();
        preview.classList.add('canvas-movement');
      }
      if (board.backgroundTile === 'tile') {
        preview.classList.add('tile-background');
        preview.classList.remove('no-tile-background');
      } else {
        preview.classList.remove('tile-background');
        preview.classList.add('no-tile-background');
      }
      if (board.backgroundWidth) {
        preview.style.backgroundSize = `${(window.canvasScale * board.backgroundWidth) / 3}px`;
      }
      if (isNaN(board.background)) {
        preview.style.backgroundImage = `url('${board.background}')`;
      } else {
        preview.style.backgroundImage = `url('images/bg/${board.background}.jpg')`;
      }
    }

    // Tiled backgrounds can't adjust color
    const hasBackground = board.background && `${board.background}`.length > 0;
    this.querySelector('.bg-scrolling').disabled = !hasBackground;
    this.querySelector('.bg-layout').disabled = !hasBackground || board.backgroundScroll !== 'scroll';
    this.querySelector('.bg-color-select').disabled =
      board.backgroundScroll !== 'scroll' || board.backgroundTile === 'tile';
    this.querySelector('.bg-size').disabled = !hasBackground || board.backgroundScroll !== 'scroll';

    // Update label treatments
    ['.bg-scrolling', '.bg-layout', '.bg-color-select', '.bg-size'].forEach((c) => {
      if (this.querySelector(c).disabled) {
        this.querySelector(`${c}-label`).classList.add('disabled');
      } else {
        this.querySelector(`${c}-label`).classList.remove('disabled');
      }
    });
  }

  onChangeBackground() {
    if (!isManageAllContentAllowed()) {
      return;
    }

    const modal = document.getElementById('room-settings-react');
    const bgProps = modal.querySelector('.settings-container');
    const bgChooser = modal.querySelector('.background-chooser-container');

    modal.style.overflow = 'hidden';
    bgChooser.style.display = 'inline-block';
    bgChooser.classList.remove('scroll-out-to-right');
    bgChooser.classList.add('scroll-in-from-right');
    bgProps.classList.remove('scroll-in-from-left');
    bgProps.classList.add('scroll-out-to-left');
  }

  onColorSelect(e) {
    if (!isManageAllContentAllowed()) {
      return;
    }

    const { color, inputComplete } = e.detail;

    this.querySelector('.bg-color-select').style.backgroundColor = color;
    this.querySelector('.background-preview').style.backgroundColor = color;
    if (!inputComplete) {
      return;
    }

    track('Change Background Color');
    db.collection('boards').doc(window.currentBoardId).update({ backgroundColor: color });
    bus.dispatch(roomSettingsUpdated);
  }

  updatePreviewBackgroundSize(e) {
    if (!isManageAllContentAllowed()) {
      return;
    }

    const width = e.target.value;
    const preview = this.querySelector('.background-preview');
    preview.style.backgroundSize = `${(window.canvasScale * width) / 3}px`;
  }

  onBackgroundSize(e) {
    if (!isManageAllContentAllowed()) {
      return;
    }

    db.collection('boards').doc(window.currentBoardId).update({ backgroundWidth: e.target.value });
    bus.dispatch(roomSettingsUpdated);
    track('Change Background Size');
  }

  onBackgroundScroll(e) {
    if (!isManageAllContentAllowed()) {
      return;
    }

    db.collection('boards').doc(window.currentBoardId).update({ backgroundScroll: e.target.value });
    track('Change Background Scrolling', { state: e.target.value });
  }

  onBackgroundTile(e) {
    if (!isManageAllContentAllowed()) {
      return;
    }

    db.collection('boards').doc(window.currentBoardId).update({ backgroundTile: e.target.value });
    bus.dispatch(roomSettingsUpdated);
    // Tiled backgrounds can't adjust color
    this.querySelector('.bg-color-select').disabled = e.target.value === 'tile';
    track('Change Background Tiling', { state: e.target.value });
  }
}

window.customElements.define('here-background-properties', BackgroundProperties);

export default BackgroundProperties;
