import React from 'react';
import PropTypes from 'prop-types';

import EditProfileForm from './EditProfileForm';
import ProfileHeader from './ProfileHeader';
import ProfileSettings from './ProfileSettings';
import { EventSourceContext } from '../common/contexts.ts';

const EditProfile = ({ userProfile, onEditProfileTheme }) => (
  <EventSourceContext.Provider value="Edit profile">
    <ProfileHeader isEditing userId={userProfile.id} userProfile={userProfile} />
    <EditProfileForm userProfile={userProfile} onEditProfileTheme={onEditProfileTheme} />
    <ProfileSettings />
  </EventSourceContext.Provider>
);

export default EditProfile;

EditProfile.propTypes = {
  userProfile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string,
    displayName: PropTypes.string,
    bio: PropTypes.string,
    pronouns: PropTypes.string,
    avatar: PropTypes.shape({
      thumbnail: PropTypes.string,
      original: PropTypes.string,
    }),
    backgroundPhoto: PropTypes.shape({
      original: PropTypes.string,
    }),
  }).isRequired,
  onEditProfileTheme: PropTypes.func,
};

EditProfile.defaultProps = {
  onEditProfileTheme: () => {},
};
