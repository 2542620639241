import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { confirmAlert } from 'react-confirm-alert';

import UserListItem from '../user-profile/UserListItem';
import UnfriendConfirmationModal from './UnfriendConfirmationModal';
import ViewUserPageMenuItem from '../user-page/ViewUserPageMenuItem';
import { moreMenuItemMixin, moreMenuMixin } from '../mixins';
import { WebLobbyTabs } from '../../definitions/web-lobby.ts';
import { EventSourceContext } from '../common/contexts.ts';

// store
import { friendByIdSelector } from '../store/friends/selectors';
import { WithRoomStore } from '../store/Wrapper';
import { setActiveReceiverId, setMessagesSidebarOpen } from '../store/messaging/reducer';
import { setActiveTab } from '../store/web-lobby/actions.ts';

// utils
import { openUserProfile } from '../user-profile/utils';
import { closeFriendsModal } from '../../util/friends-util';

// icons
import { UserIcon } from '../icons';
import VerticalMoreIcon from '../../../assets/icons/vertical-more.svg';
import UnfriendIcon from '../../../assets/icons/unfriend.svg';
import PlaneIcon from '../../../assets/icons/plane.svg';
import { FRIEND_REQUEST_SOURCES } from '../../constants/analytics-events/friend-events';

const FriendsListItem = ({ userId, onlineStatus }) => {
  const dispatch = useDispatch();

  const itemRef = useRef(null);
  const onlineMember = useSelector((state) => state.members.online[userId]);
  const friend = useSelector((state) => friendByIdSelector(state, userId));

  const viewProfileClick = () => {
    const itemRefCoordinates = itemRef.current.getBoundingClientRect();
    const topPosition = itemRefCoordinates.y - 10;
    const leftPosition = itemRefCoordinates.x + itemRef.current.offsetWidth / 2;
    openUserProfile({ userId, topPosition, leftPosition });
  };

  const getConfirmationModal = (onClose) => (
    <WithRoomStore>
      <UnfriendConfirmationModal userId={userId} onClose={onClose} />
    </WithRoomStore>
  );

  const unfriendClick = () => {
    confirmAlert({
      customUI: ({ onClose }) => getConfirmationModal(onClose),
    });
  };

  const sendMessageClick = () => {
    // close friends modal
    closeFriendsModal();

    // open sidebar
    dispatch(setMessagesSidebarOpen({ messagesSidebarOpen: true }));
    // open dmwindow
    dispatch(setActiveReceiverId({ activeReceiverId: userId }));
    dispatch(setActiveTab({ activeTab: WebLobbyTabs.DMs }));
  };

  const getFriendFooter = () => (
    <div>
      <BackgroundPhotoContainer
        backgroundUrl={friend.backgroundPhoto && friend.backgroundPhoto.original}
        backgroundColor={onlineMember ? onlineMember.color : 'default'}
      />

      <FooterButtonsContainer>
        <SendMessageButton onClick={sendMessageClick}>
          <PlaneIcon />
        </SendMessageButton>

        <MoreButton>
          <VerticalMoreIcon />
        </MoreButton>
      </FooterButtonsContainer>
    </div>
  );

  return (
    <FriendContainer key={userId} ref={itemRef}>
      <UserListItem
        userId={userId}
        userCTAsSection={getFriendFooter()}
        ctaSectionHoverChange
        onlineStatus={onlineStatus}
      />
      <div data-uk-dropdown="mode: click; pos: bottom-right; offset: -30" style={{ padding: '0px' }}>
        <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.FRIEND_LIST_MORE_MENU}>
          <MoreMenuContainer>
            <ViewUserPageMenuItem username={friend.username} />
            <MoreMenuItem onClick={viewProfileClick}>
              <IconContainer>
                <UserIcon color="black" />
              </IconContainer>
              View Profile
            </MoreMenuItem>
            <MoreMenuItem hideOnMobile onClick={sendMessageClick}>
              <IconContainer>
                <PlaneIcon height="15" width="15" />
              </IconContainer>
              Send Message
            </MoreMenuItem>
            <MoreMenuItem onClick={unfriendClick}>
              <IconContainer>
                <UnfriendIcon />
              </IconContainer>
              Unfriend
            </MoreMenuItem>
          </MoreMenuContainer>
        </EventSourceContext.Provider>
      </div>
    </FriendContainer>
  );
};

export default FriendsListItem;

FriendsListItem.propTypes = {
  userId: PropTypes.string.isRequired,
  onlineStatus: PropTypes.string,
};

FriendsListItem.defaultProps = {
  onlineStatus: '',
};

const footerContainerMixin = () => css`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const BackgroundPhotoContainer = styled.div`
  background: ${({ backgroundUrl, backgroundColor }) =>
    backgroundUrl ? `url(${backgroundUrl})` : `url(images/profile-backgrounds/${backgroundColor}.svg)`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 154px;
  height: 69px;

  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (max-width: 615px) {
    width: 85px;
  }
`;

const FooterButtonsContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  gap: 4px;
`;

const FriendContainer = styled.div`
  margin-bottom: 5px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const SendMessageButton = styled.button`
  ${footerContainerMixin()}

  padding: 1px 6px;

  @media (max-width: 992px) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`;

const MoreButton = styled.div`
  ${footerContainerMixin()}

  svg {
    height: 14px;
  }
`;

const MoreMenuContainer = styled.div`
  ${moreMenuMixin()}
`;

const MoreMenuItem = styled.div`
  ${moreMenuItemMixin({ themed: false })}

  @media (max-width: 992px) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`;

const IconContainer = styled.div`
  margin-right: 10px;
  width: 20px;

  display: flex;
`;
