import { memo } from 'react';

function areMembersListsEqual(list1, list2) {
  if (list1.length !== list2.length) {
    return false;
  }

  return !list1.some((list1Item) => !list2.find((list2Item) => list2Item.id === list1Item.id));
}

export const memoRoomItem = (component) =>
  memo(
    component,
    (prevProps, nextProps) =>
      prevProps.roomData.id === nextProps.roomData.id &&
      prevProps.roomData.title === nextProps.roomData.title &&
      prevProps.roomData.urlAlias === nextProps.roomData.urlAlias &&
      prevProps.roomData.background === nextProps.roomData.background &&
      prevProps.roomData.backgroundColor === nextProps.roomData.backgroundColor &&
      areMembersListsEqual(prevProps.roomData.members, nextProps.roomData.members)
  );
