import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FriendRequestsList from '../FriendRequestsList';
import { customScrollbarMixin } from '../../mixins';

const FriendRequestsTabContent = ({ friendRequests }) => (
  <ScrollableContainer>
    <FriendRequestsList friendRequests={friendRequests} />
  </ScrollableContainer>
);

export default FriendRequestsTabContent;

FriendRequestsTabContent.propTypes = {
  friendRequests: PropTypes.arrayOf(
    PropTypes.shape({
      requester: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 436px;
  border-radius: 10px;
  ${customScrollbarMixin()}
`;
