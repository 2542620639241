import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

// components
import RoomItem from './RoomItem';
import RoomItemWithOnScreen from './RoomItemWithOnScreen.tsx';
import { MembersContainer, MembersNumber, RoomListItemSecondaryTitle } from '../../shared-styles.ts';
import RoomItemMembersList from './components/RoomItemMembersList';

// utils
import { memoRoomItem } from './utils/memo-room-item';

// icons
import PersonWavingIcon from '../../../../../assets/icons/room-member.svg';

const QUICK_ROOM_SWITCHER_MAX_MEMBERS = 3;

const QuickRoomSwitcherRoomItem = ({ roomData }) => {
  const title = useMemo(
    () => (
      <TitleContainer hasMembers={roomData.members.length > 0}>
        <RoomListItemSecondaryTitle title={roomData.title}>{roomData.title}</RoomListItemSecondaryTitle>

        <MembersContainer isActive={!!roomData.members.length}>
          <PersonWavingIcon height="15" width="20" />
          <MembersNumber>{roomData.members.length > 0 ? roomData.members.length : null}</MembersNumber>
        </MembersContainer>
      </TitleContainer>
    ),
    [roomData.title, roomData.members]
  );

  const membersListComponent = useMemo(
    () => <RoomItemMembersList members={roomData.members} visibleMembersCount={QUICK_ROOM_SWITCHER_MAX_MEMBERS} />,
    [roomData.members]
  );

  return (
    <RoomItemWithOnScreen roomId={roomData.id}>
      <ThemeProvider theme={{ borderColor: '#6b1be3' }}>
        <RoomItem roomData={roomData} title={title} membersListComponent={membersListComponent} />
      </ThemeProvider>
    </RoomItemWithOnScreen>
  );
};

QuickRoomSwitcherRoomItem.propTypes = {
  roomData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundColor: PropTypes.string,
    urlAlias: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ hasMembers }) => (hasMembers ? 1 : 0.5)};
`;

export default memoRoomItem(QuickRoomSwitcherRoomItem);
