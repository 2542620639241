import { authedApiInstance } from '../../../firebase';

import { RoomServiceResponse } from '../../../definitions/room';

interface RoomsResponse {
  success: boolean;
  boards: RoomServiceResponse[];
}

export const fetchRoomsList = (params?: { limit?: number }) =>
  authedApiInstance.get<RoomsResponse>('/rooms', { params }).then(({ data }) => data);
