import CobrowserElement from '../../cobrowser';
import bus, { canvasScaleUpdated } from '../../event-bus';

import '../../../styles/widgets/embedded-resource.less';

// Abstract class that represents a resource that has an iframe in normal size
// and a thumbnail in a small size.
export default class EmbeddedResource extends CobrowserElement {
  handleUpdate(__element, elementDoc) {
    const data = elementDoc.data();
    if (data.embedTitle !== this.embedTitle) {
      this.embedTitle = data.embedTitle;
      const titleElement = document.querySelector(`#element-${this.elementId} .underlay-content .title`);
      titleElement.innerHTML = this.processEmbedTitle(this.embedTitle);
    }

    return true;
  }

  // Can be overridden in child components to change title format.
  processEmbedTitle(title) {
    return title;
  }

  getElement(elementDoc) {
    const cobrowserElement = super.getElement(elementDoc);
    const { embedTitle, originalUrl, url } = elementDoc.data();

    this.underlay = cobrowserElement.querySelector('.underlay');
    this.underlay.classList.add('embedded-underlay');
    this.underlay.innerHtml = '';
    this.underlay.append(
      this.getUnderlayContent({ url: originalUrl || url, title: this.processEmbedTitle(embedTitle) })
    );
    this.underlayBackground = this.underlay.querySelector('.underlay-background');

    this.content = cobrowserElement.querySelector('.iframe-bounding-box');

    return cobrowserElement;
  }

  setup(...args) {
    super.setup(...args);

    // TODO: unsubscribe on element removing when we have this implemented.
    bus.on(canvasScaleUpdated, this.onSizeChange.bind(this));
    this.onSizeChange();
  }

  onSizeChange() {
    const element = document.getElementById(`element-${this.elementId}`);
    if (!element) return;
    const { width, height } = element.getBoundingClientRect();

    if (width > 200 && height > 200) {
      this.removeUnderlayClass('overlay');
      this.content.classList.remove('blur');
      this.setUnderlayOpacity(0.5);
      if (this.tooltipText !== undefined) element.setAttribute('uk-tooltip', `title: ${this.tooltipText}`);
    } else {
      this.addUnderlayClass('overlay');
      if (this.tooltipText !== undefined) element.removeAttribute('uk-tooltip');

      this.content.style.display = 'block';

      if (width > 200 && height > 200) {
        this.setUnderlayOpacity(0.25);
      } else if (width > 150 && height > 150) {
        this.setUnderlayOpacity(0.5);
        this.content.classList.add('blur');
      } else if (width > 100 && height > 100) {
        this.setUnderlayOpacity(0.75);
        this.content.classList.add('blur');
      } else {
        this.setUnderlayOpacity(0.5);
        this.content.style.display = 'none';
      }
    }
  }

  setTooltip(tooltipText) {
    this.tooltipText = tooltipText;
  }

  addUnderlayClass(cls) {
    if (!this.underlay.classList.contains(cls)) {
      this.underlay.classList.add(cls);
    }
  }

  removeUnderlayClass(cls) {
    if (this.underlay.classList.contains(cls)) {
      this.underlay.classList.remove(cls);
    }
  }

  setUnderlayOpacity(value) {
    this.underlayBackground.style.opacity = value;
  }
}
