import styled, { keyframes } from 'styled-components';
import { TypingIndicatorWrapper } from './typing-indicator/TypingIndicator';
import { noScrollbarMixin } from '../mixins';

export const DM_WINDOW_TOPBAR_HEIGHT_PX = 63;

export const ChatListScrollWrapper = styled.div`
  position: relative;
  padding: ${({ isTyping }) => `${isTyping ? '2' : '1'}rem 0.4rem`};
  height: 600px;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  overflow: hidden auto;
  border-radius: ${({ theme }) =>
    theme?.chatListScrollWrapper?.borderRadius ? theme.chatListScrollWrapper.borderRadius : '10px 10px 0 0'};
  background: ${({ background }) => background};

  ${TypingIndicatorWrapper} {
    position: inherit;
    height: 35px;
  }

  ${noScrollbarMixin()}
`;

export const BackToPresentButton = styled.button`
  position: fixed;
  right: 33px;
  height: 30px;
  width: 30px;
  z-index: 10;
  color: #fbfbfb;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #340083;
  background-image: url(/images/icons/down-white.png);
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;

  &:hover {
    background-color: #4801b3;
  }

  &:focus {
    outline: none;
  }
`;

export const newMessageButtonSizePx = 46;
export const NewMessageButtonStyles = styled.button`
  width: ${newMessageButtonSizePx}px;
  height: ${newMessageButtonSizePx}px;
  display: block;
  margin: 0 10px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  color: #6b1be3;
  transition: transform 0.1s ease-in;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
`;

export const loadingPulsationKeyframes = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`;
