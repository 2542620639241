import UIkit from 'uikit';

import firebase from '../../firebase';
import sessionLog from '../../session-log.ts';
import log from '../../log';

// utils
import { executeWebhook, htmlToElement } from '../../util';

// constants
import {
  CAMERA_HELP_LINK,
  DISCORD_FEEDBACK_WEBHOOK_URL,
  DISCORD_INVITE_WEBHOOK_URL,
  FAQ_LINK,
  HIRING_LINK,
  TRUST_AND_SAFETY_NOTIFICATION,
} from '../../constants/contact-us-constants';

import template from './feedback.html';

import './feedback.less';
import { track } from '../../util/analytics-util';

class Feedback extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    // bind 'this'
    this.onFeedbackSendButtonClick = this.onFeedbackSendButtonClick.bind(this);
    this.openFAQPage = this.openFAQPage.bind(this);
    this.openCameraHelpPage = this.openCameraHelpPage.bind(this);
    this.toggleFeedbackMenuContactUsForm = this.toggleFeedbackMenuContactUsForm.bind(this);
    this.closeFeedbackForm = this.closeFeedbackForm.bind(this);
    this.openDiscordLink = this.openDiscordLink.bind(this);
    this.openHereJobs = this.openHereJobs.bind(this);
    this.showInviteUsModal = this.showInviteUsModal.bind(this);
    this.hideInviteUsModal = this.hideInviteUsModal.bind(this);
    this.onFeedbackInput = this.onFeedbackInput.bind(this);
    this.inviteUs = this.inviteUs.bind(this);

    // elements
    this.feedbackHelpButtons = this.querySelector('#feedback-help-buttons');
    this.contactUsFormContent = this.querySelector('#contact-us-form-content');
    this.feedbackSendButton = this.querySelector('#feedback-send-button');
    this.feedbackTextArea = this.querySelector('#feedback-textarea');
    this.feedbackErrorMessage = this.querySelector('#feedback-error-message');
    this.contactUsCloseButton = this.querySelector('#contact-us-form-close-button');
    this.contactUsCancelButton = this.querySelector('#contact-us-form-back-button');
    this.contactUsSendFeedbackButton = this.querySelector('#contact-us-send-feedback-item');
    this.cameraMicTroubleMicButton = this.querySelector('#need-help-link');
    this.faqButton = this.querySelector('#contact-us-learn-more-item');
    this.ourDiscordButton = this.querySelector('#contact-us-our-discord-item');
    this.inviteUsDiscordLink = this.querySelector('#invite-us-discord-link');
    this.weHiringButton = this.querySelector('#contact-us-we-hiring-item');
    this.inviteUsButton = this.querySelector('#contact-us-invite-us-item');
    this.feedbackResponseContainer = this.querySelector('#feedback-response');

    this.inviteUsModal = this.querySelector('#contact-us-invite-us-item-confirm');
    this.contactUsInviteUsCancelButton = this.querySelector('#contact-us-invite-us-item-cancel-button');
    this.contactUsConfirmButton = this.querySelector('#contact-us-invite-us-item-confirm-button');

    // listeners
    this.contactUsCloseButton.addEventListener('click', this.closeFeedbackForm);
    this.contactUsCancelButton.addEventListener('click', this.closeFeedbackForm);
    this.feedbackSendButton.addEventListener('click', this.onFeedbackSendButtonClick);
    this.contactUsSendFeedbackButton.addEventListener('click', this.toggleFeedbackMenuContactUsForm);
    this.faqButton.addEventListener('click', this.openFAQPage);
    this.ourDiscordButton.addEventListener('click', this.openDiscordLink);
    this.inviteUsDiscordLink.addEventListener('click', this.openDiscordLink);
    this.weHiringButton.addEventListener('click', this.openHereJobs);
    this.cameraMicTroubleMicButton.addEventListener('click', this.openCameraHelpPage);
    this.inviteUsButton.addEventListener('click', this.showInviteUsModal);
    this.contactUsInviteUsCancelButton.addEventListener('click', this.hideInviteUsModal);
    this.contactUsConfirmButton.addEventListener('click', this.hideInviteUsModal);
    this.contactUsConfirmButton.addEventListener('click', this.inviteUs);
    this.feedbackTextArea.addEventListener('input', this.onFeedbackInput);
  }

  disconnectedCallback() {
    this.contactUsCloseButton.removeEventListener('click', this.closeFeedbackForm);
    this.contactUsCancelButton.removeEventListener('click', this.closeFeedbackForm);
    this.feedbackSendButton.removeEventListener('click', this.onFeedbackSendButtonClick);
    this.contactUsSendFeedbackButton.removeEventListener('click', this.toggleFeedbackMenuContactUsForm);
    this.faqButton.removeEventListener('click', this.openFAQPage);
    this.ourDiscordButton.removeEventListener('click', this.openDiscordLink);
    this.inviteUsDiscordLink.removeEventListener('click', this.openDiscordLink);
    this.weHiringButton.removeEventListener('click', this.openHereJobs);
    this.cameraMicTroubleMicButton.removeEventListener('click', this.openCameraHelpPage);
    this.inviteUsButton.removeEventListener('click', this.showInviteUsModal);
    this.contactUsInviteUsCancelButton.removeEventListener('click', this.hideInviteUsModal);
    this.contactUsConfirmButton.removeEventListener('click', this.hideInviteUsModal);
    this.contactUsConfirmButton.removeEventListener('click', this.inviteUs);
    this.feedbackTextArea.removeEventListener('input', this.onFeedbackInput);
  }

  toggleFeedbackMenuContactUsForm() {
    this.feedbackHelpButtons.classList.toggle('contact-us-form-content--closed');
    this.contactUsFormContent.classList.toggle('contact-us-form-content--closed');
  }

  closeFeedbackForm() {
    this.toggleFeedbackMenuContactUsForm();
    this.feedbackTextArea.value = '';
    this.feedbackResponseContainer.innerHTML = '';
    this.feedbackErrorMessage.textContent = '';
  }

  openCameraHelpPage() {
    window.open(CAMERA_HELP_LINK, '_blank');
    track('Settings Modal Camera / Mic Help');
  }

  openFAQPage() {
    window.open(FAQ_LINK, '_blank');
    track('Settings Modal Need Help');
  }

  async onFeedbackSendButtonClick() {
    try {
      const feedback = this.feedbackTextArea.value;
      this.feedbackTextArea.value = '';
      if (!feedback) {
        this.feedbackResponseContainer.innerHTML = '';
        this.feedbackErrorMessage.textContent = "Don't forget to write in your message 🙂";
        return;
      }

      const { displayName, email, uid } = firebase.auth().currentUser;
      const activityId = sessionLog.currentActivityId
        ? `Session: ${window.location.origin}/admin/sessions?session=${sessionLog.currentActivityId}`
        : '';
      const content = `${displayName} (\`${email}\`, ${uid}) has sent the following feedback from ${window.currentBoardTitle} (${window.location}):\n${feedback}\n${activityId}`;

      const responseHTML = `
      <p class="feedback-response__text">
        Thanks for the message.  We'll get back to you fast — in the meantime, please join our <a class="feedback-link">Discord</a> to chat live and get to know the rest of the Here community.
      </p>
    `;

      await executeWebhook(DISCORD_FEEDBACK_WEBHOOK_URL, content);

      this.feedbackTextArea.value = '';

      this.feedbackErrorMessage.textContent = '';

      this.feedbackSendButton.textContent = 'Feedback Sent!';
      setTimeout(() => {
        this.feedbackSendButton.textContent = 'Send Message';
      }, 2000);

      const responseElement = htmlToElement(responseHTML);
      responseElement.querySelector('.feedback-link').addEventListener('click', this.openDiscordLink);
      this.feedbackResponseContainer.innerHTML = '';
      this.feedbackResponseContainer.appendChild(responseElement);

      track('Sent feedback message');
    } catch (err) {
      log.error(err);
      this.feedbackErrorMessage.textContent = 'Feedback sending error!';
    }
  }

  onFeedbackInput() {
    this.feedbackErrorMessage.textContent = '';
    this.feedbackResponseContainer.innerHTML = '';
  }

  openDiscordLink() {
    window.open('https://discord.com/invite/kDyGzQT', '_blank');
    track('Opened here.fm Discord server link');
  }

  openHereJobs() {
    window.open(HIRING_LINK, '_blank');
    track('Opened here.fm jobs link');
  }

  showInviteUsModal() {
    UIkit.modal(this.inviteUsModal).show({ stack: true });
  }

  hideInviteUsModal() {
    UIkit.modal(this.inviteUsModal).hide();
  }

  inviteUs() {
    const { displayName, email, uid } = firebase.auth().currentUser;
    const content = `${TRUST_AND_SAFETY_NOTIFICATION} ${displayName} (${email}, ${uid}) would like assistance in ${window.currentBoardTitle}\n${window.location}`;

    executeWebhook(DISCORD_INVITE_WEBHOOK_URL, content);

    this.hideInviteUsModal();
    const inviteUsConfirmationModalEl = UIkit.modal('#contact-us-invite-us-item-confirm-response');
    inviteUsConfirmationModalEl.show();
    track('Invited Here rep to room');
  }
}

window.customElements.define('here-feedback', Feedback);
