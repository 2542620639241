import { createAction } from '@reduxjs/toolkit';

import { TipsAndTricks } from './init-state';

// setters
// keep in mind that there should be only one tip on the screen at a time
export const addTip = createAction<{ tip: TipsAndTricks }>('tipsAndTricks/addTip');
export const removeTip = createAction<{ tip: TipsAndTricks }>('tipsAndTricks/removeTip');

// actions with effects
export const showTip = createAction<{ tip: TipsAndTricks; source: string }>('tipsAndTricks/showTip');
export const hideTip = createAction<{ tip: TipsAndTricks; source: string }>('tipsAndTricks/hideTip');
