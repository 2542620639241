/* duplicated in functions/utils/photobooth.js */ export const MURAL_WIDTH = 500;
/* duplicated in functions/utils/photobooth.js */ export const MURAL_ELEMENT_DEFAULT_WIDTH = MURAL_WIDTH / 2;
/* duplicated in functions/utils/photobooth.js */ export const MURAL_NEW_ELEMENT_OFFSET = 10;

export const HUB_MAX_WIDTH = 2000;

export const MURAL_REMIX_DEFAULT_WIDTH = 100;

export const HAS_SEEN_HUBS_INTRO_FIELD = 'hasSeenHubsIntro';
export const HUBS_INTRO_VIDEO = '/videos/hubs-intro.mp4';
