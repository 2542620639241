import template from './loader.html';
import './loader.less';

export default class Loader extends HTMLElement {
  connectedCallback() {
    const message = this.innerHTML;
    this.innerHTML = template;
    if (message) {
      this.querySelector('.message').innerHTML = `&nbsp;${message}&nbsp;`;
    }
  }
}
window.customElements.define('here-loader', Loader);
