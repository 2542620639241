// utils
import { checkIsElectron, checkIsMobile } from './platform-util';
import { getDesktopAppBinariesLink } from './electron-util';

// constants
import {
  DISCORD_INTEGRATION,
  GOOGLE_CALENDAR_EXTENSION,
  DESKTOP_UPSELL,
  HERE_CHROME_EXTENSION_ID,
  HIDE_DISCORD_INTEGRATION_PROMO,
  HIDE_HIRING_PROMO,
  HIDE_LOBBY_EXTENSION_PROMO,
  HIDE_DESKTOP_UPSELLS,
  HIRING_PROMO,
  LOBBY_EXTENSION_PROMO_EXPIRATION,
  HERE_CHROME_EXTENSION_INSTALL_PAGE_LINK,
  MOBILE_UPSELL,
  HIDE_MOBILE_UPSELL,
} from '../constants/integration-cta-constants';
import { getOS } from './device-util';
import { OS_TYPES } from '../constants/device-constants';
import { HIRING_LINK } from '../constants/contact-us-constants';

const integrationCTAs = [
  {
    id: DISCORD_INTEGRATION,

    async canBeShown() {
      return !window.localStorage.getItem(HIDE_DISCORD_INTEGRATION_PROMO);
    },
  },

  {
    id: MOBILE_UPSELL,

    async canBeShown() {
      return !window.localStorage.getItem(HIDE_MOBILE_UPSELL);
    },
  },

  {
    id: DESKTOP_UPSELL,

    async canBeShown() {
      return !window.localStorage.getItem(HIDE_DESKTOP_UPSELLS) && getDesktopAppBinariesLink() && !checkIsElectron();
    },
  },
];

export async function getIntegrationCTA() {
  if (checkIsMobile()) {
    return null;
  }

  const prevCTA = window.localStorage.getItem('prev-CTA');
  const prevCTAIndex = integrationCTAs.findIndex((item) => item.id === prevCTA);
  const orderedIntegrationCTAs = [
    ...integrationCTAs.slice(prevCTAIndex + 1),
    ...integrationCTAs.slice(0, prevCTAIndex + 1),
  ];
  let result = null;
  for (let i = 0; i < orderedIntegrationCTAs.length; i += 1) {
    const canBeShown = await orderedIntegrationCTAs[i].canBeShown();
    if (canBeShown) {
      result = orderedIntegrationCTAs[i].id;
      break;
    }
  }

  if (!result) {
    return null;
  }

  window.localStorage.setItem('prev-CTA', result);
  return result;
}

// TODO: delete or rewrite
export function getIntegrationCTAData(type) {
  switch (type) {
    case GOOGLE_CALENDAR_EXTENSION: {
      return {
        type,
        icon: 'images/icons/lobby-cta/calendar-extencion-cta-icon.png',
        text: { summary: 'Make meeting up insanely easy.', text: 'Install the Here Google Calendar extension!' },

        onSubmit: () => {
          window.open(HERE_CHROME_EXTENSION_INSTALL_PAGE_LINK, '_blank').focus();
        },

        onCancel: () => {
          window.localStorage.setItem(HIDE_LOBBY_EXTENSION_PROMO, Date.now());
        },
      };
    }

    case HIRING_PROMO: {
      return {
        type,
        icon: 'images/icons/lobby-cta/hiring-icon-cta.svg',
        text: {
          summary: 'We are hiring!',
          text: 'Want to work at Here.fm? We are growing fast and always looking for new talent!',
        },

        onSubmit: () => {
          window.localStorage.setItem(HIDE_HIRING_PROMO, true);
          window.open(HIRING_LINK, '_blank');
        },

        onCancel: () => {
          window.localStorage.setItem(HIDE_HIRING_PROMO, true);
        },
      };
    }

    case DISCORD_INTEGRATION: {
      return {
        type,
        icon: 'images/icons/lobby-cta/discord-circle-icon.png',
        text: {
          summary: 'Join us on Discord!',
          text: 'Learn about new features, find cool rooms, chat with the community!',
        },

        onSubmit: () => {
          window.localStorage.setItem(HIDE_DISCORD_INTEGRATION_PROMO, true);
          window.open('https://discord.com/invite/kDyGzQT', '_blank');
        },

        onCancel: () => {
          window.localStorage.setItem(HIDE_DISCORD_INTEGRATION_PROMO, true);
        },
      };
    }

    case DESKTOP_UPSELL: {
      const data = {
        type,
        icon: 'images/icons/windows-white-icon.svg',
        text: { summary: 'Download the Here Desktop App!', text: 'Hanging with friends just got a lot more fun.' },

        onSubmit: () => {
          const desktopAppBinariesLink = getDesktopAppBinariesLink();
          const a = document.createElement('a');
          a.setAttribute('href', desktopAppBinariesLink);
          a.click();
          window.localStorage.setItem(HIDE_DESKTOP_UPSELLS, true);
        },

        onCancel: () => {
          window.localStorage.setItem(HIDE_DESKTOP_UPSELLS, true);
        },
      };

      if (getOS() === OS_TYPES.win) {
        data.icon = 'images/icons/windows-white-icon.svg';
      } else {
        data.icon = 'images/icons/apple-white-icon.svg';
      }

      return data;
    }

    default: {
      return { type, icon: null, text: { summary: null, text: null }, onSubmit: () => {}, onCancel: () => {} };
    }
  }
}

export async function maybeShowIntegrationCTA() {
  const integrationCTA = document.getElementById('integration-cta');
  if (!integrationCTA) {
    return;
  }

  const isItNecessaryToShowGoogleCalendarExtensionCTA = await checkIsItNecessaryToShowGoogleCalendarExtensionCTA();
  const hideDiscordIntegrationPromo = window.localStorage.getItem(HIDE_DISCORD_INTEGRATION_PROMO);
  const hideHiringPromo = window.localStorage.getItem(HIDE_HIRING_PROMO);
  const hideDesktopUpsells = window.localStorage.getItem(HIDE_DESKTOP_UPSELLS);
  if (
    isItNecessaryToShowGoogleCalendarExtensionCTA &&
    !hideDiscordIntegrationPromo &&
    !hideHiringPromo &&
    !hideDesktopUpsells &&
    !checkIsMobile()
  ) {
    integrationCTA.classList.remove('closed');
  }
}

export function hideIntegrationCTA() {
  const integrationCTA = document.getElementById('integration-cta');
  integrationCTA.classList.add('closed');
}

// chrome calendar extension
export function checkIsItNecessaryToShowGoogleCalendarExtensionCTA() {
  // show CTA for Chrome only
  if (navigator.userAgent.indexOf('Chrome') === -1) {
    return Promise.resolve(false);
  }

  let hasUserDismissedCTA = false;
  const hideLobbyExtensionPromoTimestamp = window.localStorage.getItem(HIDE_LOBBY_EXTENSION_PROMO);
  if (
    hideLobbyExtensionPromoTimestamp &&
    Date.now() - Number(hideLobbyExtensionPromoTimestamp) < LOBBY_EXTENSION_PROMO_EXPIRATION
  ) {
    hasUserDismissedCTA = true;
  }

  return new Promise((resolve) => {
    if (hasUserDismissedCTA) {
      resolve(false);
    }

    if (window.chrome && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(HERE_CHROME_EXTENSION_ID, { type: 'manifest' }, (response) => {
        if (response) {
          // if extension installed, don't show CTA
          resolve(false);
        } else {
          // if some other extension is installed and user didn't dismiss CTA yet, show CTA
          resolve(true);
        }
      });
    } else {
      // if extension is not installed and user didn't dismiss CTA yet, show CTA
      resolve(true);
    }
  });
}
