import { createAction } from '@reduxjs/toolkit';

import { Theme } from '../../../definitions/theme';
import { NewRoomData, NewRoomFlowSteps } from './init-state';

export const resetToInitState = createAction('newRoom/resetToInitState');
export const createRoom = createAction('newRoom/createRoom');
export const sendInvites = createAction<{ userIds: string[] }>('newRoom/sendInvites');
export const continueToRoom = createAction('newRoom/continueToRoom');
export const createRoomAndSendInvites = createAction<{ userIds: string[] }>('newRoom/createRoomAndSendInvites');
export const createVibeGroupOnboarding = createAction<{
  theme?: Theme;
  isCustomBackground?: boolean;
}>('newRoom/createVibeGroupOnboarding');

// setters
export const setCreateRoomFlowStep = createAction<{ step: NewRoomFlowSteps }>('newRoom/setCreateRoomFlowStep');
export const updateNewRoomData = createAction<{ data: Partial<NewRoomData> }>('newRoom/updateNewRoomData');
export const setIsNewRoomCreating = createAction<{ isNewRoomCreating: boolean }>('newRoom/setIsNewRoomCreating');
export const setIsOnboarding = createAction<{ isOnboarding: boolean }>('newRoom/setIsOnboarding');
export const setNewRoomTheme = createAction<{ theme: Theme }>('newRoom/setNewRoomTheme');
export const setNewRoomHasCustomBackground = createAction<{ hasCustomBackground: boolean }>(
  'newRoom/setNewRoomHasCustomBackground'
);
