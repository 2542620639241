import React, { useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

// types
import { RoomServiceResponse } from '../../definitions/room';

// store
import { selectVisitedRooms } from '../store/visited-rooms/selectors';
import { selectRoomId } from '../store/room/selectors';
import { selectVisibleRoomIdsArray } from '../store/web-lobby/selectors';
import { selectCurrentUserId } from '../store/users/selectors';

// components
import Tooltip from '../components/Tooltip';
import { TileContainer, TileLoadingBlock, TitleContainer } from '../web-lobby/shared-styles';
import QuickRoomSwitcherRoomItem from '../web-lobby/components/room-items/QuickRoomSwitcherRoomItem';
// TODO: having components inside of components seems wrong to me. We should probably group components into 'lower' components differently
import { MembersListItem } from '../web-lobby/components/room-items/components/RoomItemMembersList';
import { grayShimmerAnimationMixin, svgColorMixin } from '../mixins';

// hooks
import { useRoomsListMembers } from '../web-lobby/hooks/useRoomsListWithMembers';
import { useUserProfileFromStore } from '../hooks/useUserProfileFromStore';

// utils
import { goToLobby } from '../../util/navigation-util';
import { hasMobileApps } from '../../util/user-util';
import { track } from '../../util/analytics-util';

// icons
import LeaveIcon from '../../../assets/icons/leave.svg';

const QuickRoomSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);

  const currentBoardId = useSelector(selectRoomId);
  const roomsList = useSelector(selectVisitedRooms);
  const visibleRoomIds = useSelector(selectVisibleRoomIdsArray);

  const { roomsListWithMembers: visitedRooms } = useRoomsListMembers(roomsList, visibleRoomIds) as {
    roomsListWithMembers: RoomServiceResponse[];
  };

  const tooltipContent = useMemo(
    () => (
      <TooltipStyledContent side="right" sideOffset={4}>
        <TooltipText>Leave Room</TooltipText>
      </TooltipStyledContent>
    ),
    []
  );

  const filteredRooms = useMemo(
    () => visitedRooms.filter((room) => room.id !== currentBoardId).slice(0, 5),
    [visitedRooms, currentBoardId]
  );

  const currentUserId = useSelector(selectCurrentUserId);
  const { userProfile } = useUserProfileFromStore(currentUserId);

  const onRoomContainerClick = () => {
    setIsOpen(false);
    track('Quick Jump to Room');
  };

  const preventAutoFocus = (event: Event) => {
    event.preventDefault();
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <Tooltip content={tooltipContent}>
        <PopoverTrigger asChild>
          <QuickRoomSwitcherButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
            <LeaveIconStyled />
          </QuickRoomSwitcherButton>
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent
        sideOffset={5}
        data-side="right"
        onOpenAutoFocus={preventAutoFocus}
        onCloseAutoFocus={preventAutoFocus}
      >
        <LeaveToLobbyButton
          type="button"
          onClick={() => goToLobby(!hasMobileApps(userProfile))}
          data-testid="leave-to-lobby-button"
        >
          Leave to Desktop
        </LeaveToLobbyButton>

        <VisitedRoomsList>
          {filteredRooms.map((roomData) => (
            <RoomItemContainer key={roomData.id} onClick={onRoomContainerClick}>
              <QuickRoomSwitcherRoomItem key={roomData.id} roomData={roomData} />
            </RoomItemContainer>
          ))}
        </VisitedRoomsList>
      </PopoverContent>
    </Popover>
  );
};

export default QuickRoomSwitcher;

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(12px)' },

  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverContent = styled(PopoverPrimitive.Content)`
  padding: 20px;
  max-height: 370px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 10px;
  animation: ${slideLeftAndFade} 500ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const activeBackground = 'var(--secondary-background, #6b1be3)';
const activeIconColor = 'var(--secondary-foreground, white)';
const QuickRoomSwitcherButton = styled.button`
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  border: 4px solid var(--primary-background, #12002d);
  border-radius: 30px;
  background: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? activeBackground : 'var(--primary-background, #12002d)'};
  cursor: pointer;
  ${({ isOpen }: { isOpen: boolean }) => svgColorMixin(isOpen ? activeIconColor : '#f6335d')}

  &:hover {
    ${svgColorMixin(activeIconColor)}
    background: ${activeBackground};
  }

  @media (max-width: 990px) {
    border-radius: 30px 0 0 30px;
  }
`;

const LeaveToLobbyButton = styled.button`
  margin-bottom: 12px;
  padding: 11px 45px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Inter';
  border: none;
  border-radius: 12px;
  background-color: #f6335ddf;
  cursor: pointer;

  &:hover {
    background-color: #f6335dff;
  }

  &:focus {
    outline: none;
  }
`;

const TooltipStyledContent = styled(TooltipPrimitive.Content)`
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #12002d;
`;

const TooltipText = styled.p`
  margin: 0;
  color: white;
  font-size: 11px;
  font-weight: normal;
`;

const VisitedRoomsList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;

  ${TitleContainer} {
    color: #121212;
    font-weight: bolder;

    ${svgColorMixin('#12002d')}
  }

  ${TileContainer} {
    aspect-ratio: 3 / 1;
  }

  ${TileLoadingBlock} {
    ${grayShimmerAnimationMixin()}
  }

  ${MembersListItem} {
    height: 31px;
    width: 31px;
  }
`;

const RoomItemContainer = styled.div``;

const LeaveIconStyled = styled(LeaveIcon)``;
