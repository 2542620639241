import React from 'react';
import styled from 'styled-components';

import { setCurrentUserAvatar } from '../../api/user-profiles-api';
import { uploadAvatar } from '../../upload';
import log from '../../log';

// hooks
import { useAvatarsData } from './useAvatarsData';

// components
import { LoadingError, OptionImage, TabHeader, TabsHeader, Title, TryAgainButton, UploadInput } from './shared-styles';

// utils
import { track } from '../../util/analytics-util';
import { validateImageToUpload } from './util';

// constants
import { EDIT_PROFILE_PHOTO } from '../../constants/analytics-events/user-events';

interface AvatarPickerProps {
  close: () => void;
  source: string;
}

const AvatarPicker = ({ close, source }: AvatarPickerProps) => {
  const {
    avatars: resultAvatarOptions,
    loading: defaultAvatarsLoading,
    error: defaultAvatarsError,
    fetchAvatars,
  } = useAvatarsData();

  const onImageClick = async (title: string, url: string) => {
    try {
      track(EDIT_PROFILE_PHOTO, { photo: 'avatar', source, pickerValue: title });
      await setCurrentUserAvatar(url, true);
      close();
    } catch (error) {
      log.error(error);
    }
  };

  const onAvatarUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    track(EDIT_PROFILE_PHOTO, { photo: 'avatar', source });

    try {
      const [file] = Array.from(e.target.files);
      if (file) {
        const isValid = validateImageToUpload(file);
        if (!isValid) {
          return;
        }

        await uploadAvatar(file);
        close();
      }
    } catch (error) {
      log.error(error);
    }
  };

  return (
    <>
      <Title>Change Avatar</Title>

      <TabsHeader>
        <TabHeader isActive>Trending</TabHeader>
        <TabHeader>
          From computer
          <UploadInput id="upload-avatar-input" type="file" accept="image/*" onChange={onAvatarUpload} />
        </TabHeader>
      </TabsHeader>

      {!defaultAvatarsLoading && defaultAvatarsError ? (
        <LoadingError>
          Error loading avatars. <TryAgainButton onClick={fetchAvatars}>Try again</TryAgainButton>
        </LoadingError>
      ) : null}

      <AvatarOptionsList>
        {!defaultAvatarsLoading &&
          !defaultAvatarsError &&
          resultAvatarOptions.map((avatarOption) => (
            <AvatarOptionImage
              key={avatarOption.id}
              src={avatarOption.url}
              height="134px"
              width="134px"
              alt="avatar option"
              onClick={() => onImageClick(avatarOption.data?.title || avatarOption.id, avatarOption.url)}
            />
          ))}
      </AvatarOptionsList>
    </>
  );
};

const AvatarOptionsList = styled.div`
  margin-top: 33px;
  height: 320px;
  min-width: 774px;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(5, 1fr);
  overflow: auto;

  @media (max-width: 700px) {
    min-width: 450px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const AvatarOptionImage = styled(OptionImage)`
  height: 134px;
  object-fit: cover;
  border-radius: 30px;
`;

export default AvatarPicker;
