import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../store/Wrapper';
import QuickRoomSwitcher from './QuickRoomSwitcher.tsx';

// Keeping it separate because lobby doesn't need it
export function renderQuickRoomSwitcher() {
  document.getElementById('quick-room-switcher-root').classList.remove('hidden');

  ReactDOM.render(
    <WithRoomStore>
      <QuickRoomSwitcher />
    </WithRoomStore>,
    document.getElementById('quick-room-switcher-root')
  );
}
