import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useThrottledCallback } from 'use-debounce';

import SchoolListItem from '../components/SchoolListItem';
import { noScrollbarMixin } from '../../mixins';
import { School } from '../../../definitions/schools';
import SearchIcon from '../../../../assets/icons/search.svg';
import { fetchSchools } from '../../../api/schools-api';
import { updateProfile } from '../../user-profile/api';
import { selectCurrentUserId } from '../../store/users/selectors';
import { LINK_SCHOOL } from '../../../constants/analytics-events/user-events';
import { track } from '../../../util/analytics-util';
import { EventSourceContext } from '../../common/contexts';

interface Props {
  goToNext: (s: School) => void;
  onSkip: () => void;
}

const SelectSchoolScreen: React.FC<Props> = ({ goToNext, onSkip }) => {
  const [schools, setSchools] = useState([]);

  const currentUserId = useSelector(selectCurrentUserId);
  const source = useContext(EventSourceContext);

  const onSchoolClick = (school: School) => {
    updateProfile(currentUserId, { schoolId: school.id });
    track(LINK_SCHOOL, { schoolId: school.id, isPostSecondary: school.isPostSecondary, source });
    goToNext(school);
  };

  const throttledFetchSchools = useThrottledCallback(async (query) => {
    const resp = await fetchSchools(query);
    if (resp?.schools) {
      setSchools(resp.schools);
    }
  }, 1000);

  useEffect(() => {
    (async () => {
      const resp = await fetchSchools();
      if (resp?.schools) {
        setSchools(resp.schools);
      }
    })();
  }, []);

  return (
    <Container>
      <SkipButton onClick={onSkip}>Skip</SkipButton>
      <HeaderSection>
        <ImgContainer>
          <img height={95} width={115} src="/gif/pencil-man.gif" alt="schools" />
        </ImgContainer>
        <Title>Find friends from school</Title>
        <Subtitle>We won’t show your school on your profile.</Subtitle>
      </HeaderSection>
      <InputContainer>
        <SearchIcon />
        <Input onChange={(e) => throttledFetchSchools.callback(e.target.value)} placeholder="Search for your school" />
      </InputContainer>
      <SchoolListContainer>
        {schools.map((s) => (
          <SchoolListItem key={s.id} school={s} onSchoolClick={() => onSchoolClick(s)} />
        ))}
      </SchoolListContainer>
    </Container>
  );
};

export default SelectSchoolScreen;

const Container = styled.div`
  height: 600px;
  padding: 0 44px;
  overflow-y: scroll;

  ${noScrollbarMixin()}
`;

const SkipButton = styled.div`
  position: absolute;
  top: 14px;
  right: 20px;

  color: #12002d;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 0.4;
  cursor: pointer;

  transition: opacity 0.2s;
  &:hover {
    opacity: 0.6;
  }
`;

const HeaderSection = styled.div`
  margin: 17px 0 30px 0;
  text-align: center;
`;

const ImgContainer = styled.div`
  width: 115px;
  height: 95px;
  margin: 0 auto;
`;

const Title = styled.div`
  color: #6b1be3;
  font-family: Nunito, sans-serif;
  font-size: 30px;
  font-weight: 900;
`;

const Subtitle = styled.div`
  color: #12002d;
  font-size: 14px;
  line-height: 26px;
  opacity: 0.7;
`;

const InputContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;

  padding-left: 18px;
  height: 50px;

  border-radius: 10px;
  border: 1px solid rgba(18, 0, 45, 0.2);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

  margin-bottom: 10px;
`;

const Input = styled.input`
  border: none;
  outline: none;

  padding-left: 15px;

  height: 100%;
  width: 100%;

  color: #12002d;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  &::placeholder {
    opacity: 0.3;
  }
`;

const SchoolListContainer = styled.div`
  padding-bottom: 18px;
`;
