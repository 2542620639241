/* eslint-disable @typescript-eslint/lines-between-class-members */
import { defer, isNumber } from 'lodash';
import log from '../log';
import { track } from '../util/analytics-util';
import { StreamType, SubscribeStatus } from './definitions/index.definitions';
import { FeedId, MidId, PublisherStreamParams } from './janus/janus.definitions';
import { RTCInboundRtpStreamStatsComplete } from './stats/definitions';

interface RateInSecondsInterface {
  nackCount: number;
  packetsLost: number;
  bytesReceived: number | undefined;
}

export class FeedTrack {
  _uniqueMidId: string;
  feedMidId: string;
  feedId: string;
  type: StreamType;
  status: SubscribeStatus = SubscribeStatus.Idle;
  _track: MediaStreamTrack;
  active: boolean;
  codec: string;
  send: boolean;
  ready: boolean;
  svc: boolean;
  simulcast: boolean;

  private _inboundRTPStats: RTCInboundRtpStreamStatsComplete;

  /** The percentage of a metric in between save stats intervals */
  private percentageInterval = { nackCount: 0, packetsLost: 0 };

  /** Rate of a metric in seconds */
  private rateInSeconds: RateInSecondsInterface = { nackCount: 0, packetsLost: 0, bytesReceived: undefined };

  /** If true, it means this stream is currently inactive/disabled (and so codec, description, etc. will be missing)> */
  disabled?: boolean;

  constructor(type: StreamType, feedId?: FeedId, feedMidId?: MidId, params?: PublisherStreamParams) {
    if ([type, feedId, feedMidId].find((e) => !e))
      throw new Error('FeedTrack: Cannot create Stream without type, feedId and feedMidId!');

    this.type = type;
    this.feedId = feedId;
    this.feedMidId = feedMidId;
    if (params) {
      this.disabled = params.disabled as boolean;
      this.codec = params.codec as string;
      this.svc = params.svc;
      this.simulcast = params.simulcast;
    }
  }

  get uniqueMidId() {
    return this._uniqueMidId;
  }

  set uniqueMidId(mid: MidId) {
    if (this.uniqueMidId && this.uniqueMidId !== mid && this.status === SubscribeStatus.Subscribed) {
      const { uniqueMidId, type } = this;
      defer(() => {
        track('SFU Feedtrack Subscribed Changed Mid', { old: uniqueMidId, new: mid, kind: type });
      });
    }
    this._uniqueMidId = mid;
  }

  get track() {
    return this._track;
  }

  set track(t) {
    this._track = t;
    if (this.status === SubscribeStatus.Off) return;
    if (t) this.status = SubscribeStatus.Subscribed;
    else this.status = SubscribeStatus.Idle;
  }

  get inboundRTPStats() {
    return this._inboundRTPStats;
  }

  set inboundRTPStats(stat: RTCInboundRtpStreamStatsComplete) {
    if (this.inboundRTPStats) {
      if (isNumber(stat.bytesReceived)) {
        this.calculateRateInSeconds(stat, 'bytesReceived');
      }

      if (isNumber(stat.nackCount)) {
        if (isNumber(stat.bytesReceived)) {
          this.percentageInterval.nackCount = this.calculatePercentage(stat, 'nackCount', 'packetsReceived');
        }
        this.calculateRateInSeconds(stat, 'nackCount');
      }

      if (isNumber(stat.packetsLost)) {
        if (isNumber(stat.bytesReceived)) {
          this.percentageInterval.packetsLost = this.calculatePercentage(stat, 'packetsLost', 'packetsReceived');
        }
        this.calculateRateInSeconds(stat, 'packetsLost');
      }
    }
    /*
    log.debug(
      `FeedTrack: type=${this.type} feed id=${this.feedId}\nInterval/%: ${JSON.stringify(
        this.percentageInterval
      )}\nRate/s: ${JSON.stringify(this.rateInSeconds)}`
    );
    */
    this._inboundRTPStats = stat;
  }

  get nackRatePerSecond() {
    return this.rateInSeconds.nackCount || 0;
  }

  get packetsLostRatePerSecond() {
    return this.rateInSeconds.packetsLost || 0;
  }

  get bitrate(): number | undefined {
    try {
      const bitrate = Math.round((this.rateInSeconds.bytesReceived * 8) / 1000);
      return bitrate;
    } catch (e) {
      return undefined;
    }
  }

  private calculateRateInSeconds(stat: RTCInboundRtpStreamStatsComplete, field: keyof typeof this.rateInSeconds) {
    try {
      const value = (stat[field] as number) - (this.inboundRTPStats[field] as number);
      const seconds = (stat.timestamp - this.inboundRTPStats.timestamp) / 1000;
      this.rateInSeconds[field] = value / seconds;
      return this.rateInSeconds[field];
    } catch (e) {
      log.error(`FeedTrack: calculateRate failed with error: ${e}`);
      return undefined;
    }
  }

  private calculatePercentage(
    stat: RTCInboundRtpStreamStatsComplete,
    field: keyof typeof this.percentageInterval,
    baseField: keyof RTCInboundRtpStreamStatsComplete
  ): number | undefined {
    try {
      const value = (stat[field] as number) - (this.inboundRTPStats[field] as number);
      const base = (stat[baseField] as number) - (this.inboundRTPStats[baseField] as number);
      this.percentageInterval[field] = (value * 100) / base;
      return this.percentageInterval[field];
    } catch (e) {
      log.error(`FeedTrack: calculatePerce failed with error: ${e}`);
      return undefined;
    }
  }
}
