import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ChangeableRectangle = ({ isMoveable, isResizable, onMove, onResize, children }) => {
  const lastMoveMousePosition = useRef(null);
  const lastResizeMousePosition = useRef(null);

  // Handling custom position
  const onMoveMouseDown = (e) => {
    if (!isMoveable) return;
    e.stopPropagation();
    lastMoveMousePosition.current = { x: e.clientX, y: e.clientY };
  };
  const onResizeMouseDown = (e) => {
    e.stopPropagation();
    lastResizeMousePosition.current = { x: e.clientX, y: e.clientY };
  };

  const onMouseMove = (e) => {
    if (!lastMoveMousePosition.current && !lastResizeMousePosition.current) return;

    const positionRef = lastMoveMousePosition.current ? lastMoveMousePosition : lastResizeMousePosition;
    const deltaX = e.clientX - positionRef.current.x;
    const deltaY = e.clientY - positionRef.current.y;
    const callback = lastMoveMousePosition.current ? onMove : onResize;
    callback({ deltaX, deltaY });
    positionRef.current = { x: e.clientX, y: e.clientY };
  };
  const onMouseUp = () => {
    lastMoveMousePosition.current = null;
    lastResizeMousePosition.current = null;
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  return (
    <Container isMoveable={isMoveable} onMouseDown={onMoveMouseDown}>
      {children}
      {isResizable && <ResizeKnob onMouseDown={onResizeMouseDown} />}
    </Container>
  );
};

export default ChangeableRectangle;

ChangeableRectangle.propTypes = {
  isMoveable: PropTypes.bool,
  isResizable: PropTypes.bool,
  onMove: PropTypes.func,
  onResize: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ChangeableRectangle.defaultProps = {
  isMoveable: true,
  isResizable: true,
  onMove: () => {},
  onResize: () => {},
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ isMoveable }) => isMoveable && 'cursor: move;'}
`;

const ResizeKnob = styled.div`
  position: absolute;
  right: -4px;
  bottom: -4px;
  width: 8px;
  height: 8px;
  background: white;
  cursor: nwse-resize;
`;
