import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { animated, to, useSpring } from '@react-spring/web';

import { svgColorMixin } from '../mixins';

// icons
import PlayIcon from '../../../assets/icons/play-rounded.svg';
import PauseIcon from '../../../assets/icons/pause-rounded.svg';
import AudioWaves from '../../../assets/icons/audio-waves.svg';
import { useDurationValue } from '../hooks/useDurationValue';
import { trackUserActivationFor } from '../../util/user-activation';

type AudioItemProps = {
  audio: HTMLAudioElement | null;
  onTogglePlayback: ({ isStarted, isPlaying }: { isStarted: boolean; isPlaying: boolean }) => void;
};

const AudioItem = ({ audio, onTogglePlayback }: AudioItemProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [{ currentPosition }, api] = useSpring(() => ({ currentPosition: 0 }));

  const togglePlayback = () => {
    onTogglePlayback({ isStarted, isPlaying });

    if (isPlaying) {
      setIsPlaying(false);
      audio?.pause();
      api.stop();
    } else {
      setIsPlaying(true);
      setIsStarted(true);
      trackUserActivationFor('togglePlayback');
      audio?.play();
      api.start({ currentPosition: 100, config: { duration: (audio.duration - audio.currentTime) * 1000 } });
    }
  };

  // Handling playback end
  useEffect(() => {
    const listener = () => {
      setIsPlaying(false);
      setIsStarted(false);
      api.start({ currentPosition: 0, immediate: true });
    };

    audio?.addEventListener('ended', listener);
    return () => audio?.removeEventListener('ended', listener);
  }, [audio, api]);

  // Updating current time
  const [passedSeconds, setPassedSeconds] = useState(0);
  useEffect(() => {
    const listener = () => {
      const seconds = Math.floor(audio.currentTime);
      if (passedSeconds !== seconds) {
        setPassedSeconds(seconds);
      }
    };
    audio?.addEventListener('timeupdate', listener);
    return () => audio?.removeEventListener('timeupdate', listener);
  }, [audio, passedSeconds]);

  const audioDuration = audio?.duration || 0;
  const displayedDuration = useDurationValue((isStarted ? passedSeconds : audioDuration) * 1000);

  return (
    <>
      <TogglePlay onClick={togglePlayback}>
        {isPlaying ? (
          <PauseContainer>
            <PauseIcon />
          </PauseContainer>
        ) : (
          <PlayContainer>
            <PlayIcon />
          </PlayContainer>
        )}
      </TogglePlay>

      <ProgressContainer>
        <BackgroundWaves />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ForegroundWavesContainer position={currentPosition}>
          <ForegroundWaves />
        </ForegroundWavesContainer>
      </ProgressContainer>

      <CurrentTime>{displayedDuration}</CurrentTime>
    </>
  );
};

export default AudioItem;

const TogglePlay = styled.div`
  cursor: pointer;
  display: flex;
`;

const ProgressContainer = styled.div`
  position: relative;
  flex-grow: 1;
  margin: 0 3%;
  display: flex;

  ${({ color }) => svgColorMixin(color)}
`;

const BackgroundWaves = styled(AudioWaves)`
  opacity: 0.3;
  width: 100%;
`;

const ForegroundWaves = styled(BackgroundWaves)`
  opacity: 1;
`;

const ForegroundWavesContainer = styled(animated.div).attrs(({ position }: { position: number }) => ({
  style: {
    clipPath: to([position], (currentPosition) => `inset(0 ${100 - currentPosition}% 0 0)`),
  },
}))`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
`;

const PlayContainer = styled.div`
  svg {
    height: ${({ theme }) => `${theme?.playIcon?.size}px`};
  }
`;

const PauseContainer = styled.div`
  svg {
    height: ${({ theme }) => `${theme?.pauseIcon?.size}px`};
  }
`;

const CurrentTime = styled.div`
  font-size: ${({ theme }) => theme?.time?.size}px;
`;
