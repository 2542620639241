export enum ResultTypes {
  LOCATION_UNSUPPORTED = 'LOCATION_UNSUPPORTED',
  LOCATION_SUPPORTED = 'LOCATION_SUPPORTED',
}

export interface School {
  id: string;
  name: string;
  city: string;
  state: string;
  isPostSecondary: boolean;
  isMiddleSchool: boolean;
}
