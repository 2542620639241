export const MAX_NUM_CARDS = 10;

export const USER_CARD_WIDTH_PX = 300;
export const USER_CARD_HEIGHT_PX = 512;
export const USER_CARDS_GAP_PX = 20;

export const USER_CARD_SLIDE_HEIGHT_PX = 585;

export const MOBILE_FOOTER_HEIGHT_PX = 66;

// Should we bind it to user card usecase, or there are other usecases as well?
export const DEFAULT_WIDGET_PARAMS = {
  width: USER_CARD_WIDTH_PX / 2,
  height: USER_CARD_WIDTH_PX / 3,
  center: [-USER_CARD_WIDTH_PX / 4, 0], // It's a lie, it's not a center, but top-left corner coords :(
};

export const FIRST_TIME_USER_PROMPTS = [
  'My Horoscope',
  'My Besties',
  'My Hobbies',
  "Shows I'm watching",
  'Fave food',
  'How I’m Feeling Right Now',
  'What I’m Listening To',
  'Top Movies',
  'Notes for Friends',
  'My Art Skills',
  'My Irrational Fear',
  'My Fashion Aesthetic',
  'The Best Season',
  'Boy Bands/Girl Groups',
  'Fictional Character Boyfriend',
  'Fictional Character Girlfriend',
  'Places I’ve Traveled',
  'A Favorite Color',
  'What I Nerd Out On',
  "What I'm Listening To",
  'My Ice Cream Order',
  'Best Anime Character',
  'Pokémon Starter',
  'Me as a Meme',
  "Ask About This & I'll Scream",
  'Things I Won’t Shut Up About',
  'Unhealthy Song Obsessions',
  'My ideal place to live',
  'Me as a Videogame Character',
  'Me in My Slay Era',
  'This is My Child (Pets)',
  'My Icks or Pet Peeves',
  'Where I go to have fun',
  'Weirdest Animal',
  'Niche Animal Interest',
  'Shelves with Plants',
  'What’s On Your Book Shelf',
  'JukeBox Song List',
  'Cool Planets',
  'Fun Things with Friends',
  'Retro Games I Still Play',
  'My Fave Boots',
  'My Dream Car',
];

export const AFFIRMATIVE_RESPONSES = ['you got it', 'sure', 'alrighty', 'okie dokes', 'gotchu'];
export const EDIT_TOOLBAR_BUTTONS = {
  GIF: 'Gif',
  GIPHYTEXT: 'Giphy Text',
  TEXT: 'Text',
  DRAW: 'Draw',
  UPLOAD: 'Upload',
};
