import { OS_TYPES } from '../constants/device-constants';

const CAMERA_DEVICE_ID = 'CameraDeviceId';
const MIC_DEVICE_ID = 'MicDeviceId';

export function getCameraDeviceId() {
  return localStorage.getItem(CAMERA_DEVICE_ID);
}
export function getMicDeviceId() {
  return localStorage.getItem(MIC_DEVICE_ID);
}

export function setCameraDeviceId(videoId) {
  localStorage.setItem(CAMERA_DEVICE_ID, videoId);
}

export function setMicDeviceId(audioId) {
  localStorage.setItem(MIC_DEVICE_ID, audioId);
}

export function getOS() {
  if (window.navigator.userAgent.indexOf(OS_TYPES.win) !== -1) {
    return OS_TYPES.win;
  }

  if (window.navigator.userAgent.indexOf(OS_TYPES.mac) !== -1) {
    return OS_TYPES.mac;
  }

  if (window.navigator.userAgent.indexOf(OS_TYPES.chromeOs) !== -1) {
    return OS_TYPES.chromeOs;
  }

  if (window.navigator.userAgent.indexOf(OS_TYPES.linux) !== -1) {
    return OS_TYPES.linux;
  }

  return null;
}

export const isChromeOs = () => getOS() === OS_TYPES.chromeOs;
