import UIkit from 'uikit';

import firebase from '../../firebase';
import { onUserListChanged, getPresenceElements } from '../../presence';
import bus, { boardChanged } from '../../event-bus';
import { htmlToElement, getQueryStringValue } from '../../util';
import { isNewUser } from '../../util/user-util';
import { setUserNotification } from '../../util/friends-util';
import { getCurrentBoardIsUserCard } from '../../util/room-util';

import { BUTTON_CLICK_FEEDBACK_DURATION } from '../../react/admin-templates/constants';
import { openUserProfile } from '../../react/user-profile/utils';
import {
  renderFriendNotificationsContainer,
  renderNewMemberFriendSuggestionContainer,
  renderOutsideReactionFriendSuggestion,
} from '../../react/friends/render';
import { MOBILE_BREAKPOINT } from '../../constants/breakpoints';

import store from '../../react/store/room-root-store';
import { openRoomSettings } from '../../react/store/room/store';
import { ROOM_SETTINGS_TABS } from '../../react/store/room/constants';
import { fetchFriends } from '../../react/store/friends/store';

import '../users-count';

import template from './users-bar.html';
import './users-bar.less';
import { getRecentVisitors } from '../../react/room/invite-recent-visitors/helpers.ts';
import { renderInviteRecentVisitorsModal } from '../../react/room/render';
import { track } from '../../util/analytics-util';

export default class UsersBar extends HTMLElement {
  constructor() {
    super();

    this.isInviteShown = false;

    // binds
    this.onBoardChanged = this.onBoardChanged.bind(this);
  }

  onBoardChanged(boardId) {
    const { currentUser } = firebase.auth();
    if (!currentUser) {
      return;
    }

    onUserListChanged(boardId, async (users, lastUsers) => {
      if (!users || boardId !== window.currentBoardId) {
        return;
      }

      const otherUsers = { ...users };
      delete otherUsers[currentUser.uid];

      const elements = getPresenceElements(otherUsers, window.currentBoardId);
      const userList = this.querySelector('.users-list');
      userList.innerHTML = '';
      userList.append(...elements);

      const user = firebase.auth().currentUser;

      this.usersNumberEl.textContent = elements.length + 1;

      if (elements.length > 0) {
        const divider = document.createElement('span');
        divider.classList.add('user-bar-divider');
        userList.append(divider);
      }

      // get presence elements that are user avatars:
      const userAvatars = elements.filter((element) => element.classList.contains('settings-button-container'));

      const closeToggleElsewhere = (e, avatarButton) => {
        const isClickedOnAvatar = avatarButton.contains(e.target);
        if (!isClickedOnAvatar) avatarButton.classList.remove('open');
      };
      // attach onClicks to each of them that open their respective user profiles:
      userAvatars.forEach((userAvatar) => {
        userAvatar.addEventListener('click', () => {
          if (userAvatar.className.includes('open')) {
            userAvatar.classList.remove('open');

            document.removeEventListener('click', (e) => closeToggleElsewhere(e, userAvatar));
          } else {
            userAvatar.classList.add('open');

            const usersBar = document.querySelector('.users-bar');

            const topPosition = userAvatar.offsetTop + userAvatar.offsetHeight;
            const leftPosition = usersBar.offsetLeft + userAvatar.offsetLeft + userAvatar.offsetWidth;

            // clears user profile open id if profile is hidden from outside of avatar:
            document.addEventListener('click', (e) => closeToggleElsewhere(e, userAvatar));

            const userId = userAvatar.getAttribute('userid');
            openUserProfile({ userId, topPosition, leftPosition });
          }
        });
      });

      const moreUsersEl = document.querySelector('.more-icon');
      if (moreUsersEl) {
        moreUsersEl.addEventListener('click', () => {
          store.dispatch(openRoomSettings({ initialTab: ROOM_SETTINGS_TABS.members }));
        });
      }

      // Here, we also need to check that the one user is the current user as there could be a case
      // where for a second as they're leaving the room with one other person in it, it flashes the
      // recent visitor invite modal
      const hasNoOtherUsers = Object.keys(users).length === 1 && users[user.uid];

      if (
        hasNoOtherUsers &&
        !this.isInviteShown &&
        !(await isNewUser(user)) &&
        !getCurrentBoardIsUserCard() &&
        !window.currentBoardData?.roomOnboardingStepId
      ) {
        const recentVisitors = await getRecentVisitors(window.currentBoardId, currentUser.uid);
        if (recentVisitors.length > 0 && !lastUsers) {
          // we don't show modal unless lastUsers is null because we don't want it to show if
          // the last other user leaves the room
          renderInviteRecentVisitorsModal(recentVisitors);
        } else {
          this.showInviteModal();
        }
      }
    });

    document.getElementById('publicize-copy-link').onclick = (e) => {
      this.copyShareLink(e);
    };
  }

  copyShareLink(e) {
    const boardShareUrl = this.querySelector('.board-share-url');
    window.navigator.clipboard.writeText(boardShareUrl.value).then(() => {
      e.target.innerHTML = 'Copied!';
      setTimeout(() => {
        e.target.innerHTML = '<img src="images/icons/link.svg" class="publicize-button-icon" /> Copy Link';
      }, BUTTON_CLICK_FEEDBACK_DURATION);
    });
    track('Copy Share Link', { from: 'public-room-success' });
  }

  connectedCallback() {
    this.innerHTML = template;

    if (window.innerWidth > MOBILE_BREAKPOINT) {
      this.querySelector('.invite-button').after(
        htmlToElement(`
        <div uk-dropdown="mode: click; pos: bottom-right; offset: 12; toggle: .invite-button" class="invite-menu discord-invite-modal">
          <here-invite></here-invite>
        </div>`)
      );

      UIkit.util.on('#users-bar', 'show', '.invite-menu', () => {
        // We want to make sure to fetch friends every time invite modal is opened.
        // What we display on modal depends on users friends
        store.dispatch(fetchFriends());
      });
    } else {
      document.getElementById('global-menu-container').after(
        htmlToElement(`
        <div uk-dropdown="mode: click; pos: bottom-left; boundary: #global-menu-container; toggle: .invite-button" class="invite-menu discord-invite-modal">
          <here-invite></here-invite>
        </div>`)
      );
    }

    // elements
    this.usersNumberEl = this.querySelector('#users-number');

    bus.on(boardChanged, this.onBoardChanged);
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        return;
      }

      const userAvatar = htmlToElement(`
        <span class="presence-icon presence-bar-item settings-button" uk-tooltip="title: My Account; pos: left" data-testid="my-account-button">
          <here-avatar class="color-ring" userId="${user.uid}" />
        </span>
      `);

      const closeElsewhere = (e) => {
        const isClickedOnAvatar = userAvatar.contains(e.target);

        if (!isClickedOnAvatar) userAvatar.classList.remove('open');
      };

      userAvatar.addEventListener('click', () => {
        if (userAvatar.className.includes('open')) {
          userAvatar.classList.remove('open');

          document.removeEventListener('click', closeElsewhere);
        } else {
          userAvatar.classList.add('open');
          const usersBar = document.querySelector('.users-bar');

          const topPosition = usersBar.offsetTop + usersBar.offsetHeight - 10;
          const leftPosition = usersBar.offsetLeft + usersBar.offsetWidth - 10;

          // clears user profile open id if profile is hidden from outside of avatar:
          document.addEventListener('click', closeElsewhere);

          openUserProfile({ userId: user.uid, topPosition, leftPosition });
        }
      });

      this.querySelector('.settings-button-container').appendChild(userAvatar);
      this.querySelector('.settings-button-container').appendChild(
        htmlToElement('<div id="friend-notifications"></div>')
      );
      this.querySelector('.settings-button-container').appendChild(
        htmlToElement('<div id="new-member-friend-suggestion"></div>')
      );
      this.querySelector('.settings-button-container').appendChild(
        htmlToElement('<div id="outside-reaction-friend-suggestion"></div>')
      );

      if (!getQueryStringValue('is-user-page')) {
        renderFriendNotificationsContainer();
        renderNewMemberFriendSuggestionContainer();
        renderOutsideReactionFriendSuggestion();
      }
      setUserNotification(user.uid, userAvatar);
    });
  }

  disconnectedCallback() {
    bus.off(boardChanged, this.onBoardChanged);
  }

  showInviteModal() {
    UIkit.dropdown('.discord-invite-modal').show();
    this.isInviteShown = true;
  }

  hideInviteModal() {
    this.querySelector('.invite-button').click();
    this.isInviteShown = false;
  }
}
window.customElements.define('here-users-bar', UsersBar);
