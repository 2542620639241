import React, { KeyboardEvent, FocusEvent, useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useThrottledCallback } from 'use-debounce';

import { createPasteHandler, createUploadHandler } from './upload-to-chat';

// store
import {
  selectCurrentUserMessageColor,
  selectCurrentUserFontColor,
  selectCurrentUserFontSize,
  selectCurrentUserFontFamily,
} from '../../store/users/selectors';
import { saveDraftMessage } from '../../store/messaging/reducer';
import { currentUserProfileUpdateRequest } from '../../store/users/store';

// components
import MessageInput from './MessageInput';
import MessageColorButton from './buttons/MessageColorButton';
import FontStyleButton from './buttons/FontStyleButton';
import EmojiPickerButton from './buttons/EmojiPickerButton';
import GifPickerButton from './buttons/GifPickerButton';
import UploadButton from './buttons/UploadButton';
import { svgColorMixin } from '../../mixins';

// utils
import { applyTheme } from '../../../util/theming-util';
import { getGroupChatParams, track } from '../../../util/analytics-util';
import { CHANGE_MESSAGE_FONT_STYLE } from '../../../constants/analytics-events/chat-events';
import { DEFAULT_PRIMARY_FOREGROUND } from '../../groups-lobby/style-constants';
import websocketClient from '../../../api/websocket-client';
import { WebsocketEvents } from '../../../constants/websocket-events';
import { TypingStatus } from '../../../definitions/websocket';
import KaomojiPickerButton from './buttons/KaomojiPickerButton';
import { chatTypes } from '../../../constants/chat-constants';
import UserAvatar from '../../components/UserAvatar';
import { ChatPermissionsContext, EventSourceContext } from '../../common/contexts';
import { SEND_KAOMOJI } from '../../store/kaomoji/analytics';

interface ChatInputProps {
  chatId: string;
  elementId: string;
  caller: string;
  storageFolder: string;
  draftMessage?: string;
  theme?: { colors: object; primaryFont: string };
  useCustomTheme?: boolean;
  onSendMessage: (message: string) => void;
  onMessageUploadSuccess: (uploadingResult: { imageUrl: string; storagePath: string }) => void;
  onMessageUploadFail: (errorData: { type: string; message: string }) => void;
  type: string;
  userId: string;
  autoFocus?: boolean;
}

const ChatInput = ({
  chatId,
  elementId,
  caller,
  storageFolder,
  draftMessage,
  theme,
  useCustomTheme,
  onSendMessage,
  onMessageUploadSuccess,
  onMessageUploadFail,
  type,
  userId,
  autoFocus = true,
}: ChatInputProps) => {
  const dispatch = useDispatch();

  const messageColor = useSelector(selectCurrentUserMessageColor);
  const fontSize = useSelector(selectCurrentUserFontSize);
  const fontColor = useSelector(selectCurrentUserFontColor);
  const fontFamily = useSelector(selectCurrentUserFontFamily);

  const [message, setMessage] = useState('');

  const inputRef = useRef(null);

  const permissions = useContext(ChatPermissionsContext);
  const source = useContext(EventSourceContext);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('emoji-mart/css/emoji-mart.css');
  }, []);

  const containerRef = useRef(null);
  useEffect(() => {
    if (useCustomTheme) {
      applyTheme(theme?.colors, theme?.primaryFont, containerRef.current);
    }
  }, [theme, useCustomTheme]);

  useEffect(() => {
    if (draftMessage) {
      setMessage(draftMessage);
    } else {
      setMessage('');
    }
  }, [chatId, draftMessage]);

  const sendTypingEventThrottled = useThrottledCallback((params) => {
    websocketClient.emit(WebsocketEvents.USER_TYPING, params);
  }, 800);

  const onMessageChange = useCallback(
    (e) => {
      sendTypingEventThrottled.callback({ chatId, typingStatus: TypingStatus.ACTIVE, userId });

      setMessage(e.target.value);
    },
    [chatId, userId, sendTypingEventThrottled]
  );

  // message setting buttons handlers
  const onMessageColorPick = useCallback(
    (color) => {
      dispatch(currentUserProfileUpdateRequest({ patch: { messageColor: color.detail.color } }));
    },
    [dispatch]
  );

  const onFontChange = useCallback(
    (font) => {
      if (font.family !== fontFamily) {
        track(CHANGE_MESSAGE_FONT_STYLE, { source: type });
        dispatch(currentUserProfileUpdateRequest({ patch: { fontFamily: font.family } }));
      }
    },
    [dispatch, type, fontFamily]
  );

  const onFontSizeChange = useCallback(
    (updatedFontSize) => {
      dispatch(currentUserProfileUpdateRequest({ patch: { fontSize: updatedFontSize } }));
    },
    [dispatch]
  );

  const onFontColorPick = useCallback(
    (color) => {
      dispatch(currentUserProfileUpdateRequest({ patch: { fontColor: color.detail.color } }));
    },
    [dispatch]
  );

  // attachment buttons handlers
  const onEmojiSelected = useCallback(
    (emoji) => {
      sendTypingEventThrottled.callback({ chatId, typingStatus: TypingStatus.ACTIVE, userId });
      setMessage((prevMessage) => `${prevMessage}${emoji.native}`);
    },
    [chatId, userId, sendTypingEventThrottled]
  );

  const onKaomojiSelected = useCallback(
    (kaomoji, category) => {
      sendTypingEventThrottled.callback({ chatId, typingStatus: TypingStatus.ACTIVE, userId });
      setMessage((prevMessage) => `${prevMessage}${kaomoji}`);
      inputRef?.current.focus();
      track(SEND_KAOMOJI, getGroupChatParams({ chatId, kaomoji, category, source }));
    },
    [chatId, sendTypingEventThrottled, userId, source]
  );

  const handleFileUpload = useMemo(
    () => createUploadHandler(storageFolder, onMessageUploadSuccess, onMessageUploadFail),
    [storageFolder, onMessageUploadSuccess, onMessageUploadFail]
  );

  const handlePaste = useMemo(
    () => createPasteHandler(storageFolder, onMessageUploadSuccess, onMessageUploadFail),
    [storageFolder, onMessageUploadSuccess, onMessageUploadFail]
  );

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();

      const trimmedMessage = message.trim();
      if (trimmedMessage) {
        onSendMessage(message);
        sendTypingEventThrottled.callback({ chatId, typingStatus: TypingStatus.FINISHED, userId });
        setMessage('');
      }
    }
  };

  const onBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      const draft = e?.target?.value;
      dispatch(saveDraftMessage({ chatId, draft }));
    },
    [dispatch, chatId]
  );

  const onMessageInputUnmount = useCallback(
    (messageInputValue) => {
      dispatch(saveDraftMessage({ chatId, draft: messageInputValue }));
    },
    [dispatch, chatId]
  );

  return (
    <Container ref={containerRef} isAllowed={permissions.canAddMessages}>
      <InputContainer>
        <ButtonsContainer>
          <ButtonsGroupContainer>
            <KaomojiPickerButton onSelectKaomoji={onKaomojiSelected} side={type === chatTypes.DM ? 'right' : 'top'} />
            <EmojiPickerButton onEmojiSelected={onEmojiSelected} />
            <GifPickerButton chatId={chatId} elementId={elementId} caller={caller} />
            <UploadButton elementId={elementId} handleFileUpload={handleFileUpload} />
          </ButtonsGroupContainer>

          <ButtonsGroupContainer>
            <FontStyleButton
              id="dmWindow"
              currentFontFamily={fontFamily}
              currentFontSize={fontSize}
              currentFontColor={fontColor}
              onFontChange={onFontChange}
              onFontSizeChange={onFontSizeChange}
              onFontColorPick={onFontColorPick}
            />
            <MessageColorButton messageColor={messageColor} onColorPick={onMessageColorPick} />
          </ButtonsGroupContainer>
        </ButtonsContainer>

        <MessageInput
          inputId={`chat-input-${chatId}`}
          ref={inputRef}
          key={chatId}
          // input props
          autoFocus={autoFocus}
          disabled={!permissions.canAddMessages}
          // message
          message={message}
          maxMessageLength={512}
          // message settings
          messageColor={messageColor}
          fontColor={fontColor}
          fontSize={fontSize}
          fontFamily={fontFamily}
          // event handlers
          onChange={onMessageChange}
          onPaste={handlePaste}
          onDrop={handleFileUpload}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          //
          onUnmount={onMessageInputUnmount}
        />
      </InputContainer>
      <AvatarContainer>
        <UserAvatar userId={userId} />
      </AvatarContainer>
    </Container>
  );
};

export default ChatInput;

const AVATAR_SIZE = 54;

export const Container = styled.div<{ isAllowed: boolean }>`
  padding: ${({ theme }) => theme?.container?.padding || '10px'};
  display: flex;
  align-items: flex-end;

  background: ${({ theme }) => theme?.container?.background || 'var(--primary-background, #12002d)'};
  gap: ${({ theme }) => (theme?.container?.gap ? `${theme?.container?.gap}px` : '9px')};
  border-radius: ${({ theme }) => theme?.container?.borderRadius || '5px'};
  ${({ isAllowed }) => (isAllowed ? '' : 'pointer-events: none; opacity: 0.5;')}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${AVATAR_SIZE}px);
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const ButtonsGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  ${svgColorMixin(`var(--primary-foreground, ${DEFAULT_PRIMARY_FOREGROUND})`)};
`;

const AvatarContainer = styled.div`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;
