import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import HereModal from '../../components/HereModal';
import InviteRecentVisitorTile from './InviteRecentVisitorTile';
import { RecentVisitor } from './helpers';
import { svgColorMixin } from '../../mixins';
import LinkIcon from '../../../../assets/icons/link.svg';
import { track } from '../../../util/analytics-util';
import { CopyInviteLinkContexts, COPY_INVITE_LINK, INVITE_RECENT_VISITORS_MODAL_DISPLAYED, INVITE_RECENT_VISTORS_MODAL_DISMISSED } from '../../../constants/analytics-events/rooms-events';
import { selectCurrentRoomUrlAlias } from '../../store/room/selectors';
import FadeIn from '../../components/animate/FadeIn';

interface Props {
  recentVisitors: RecentVisitor[];
}

const InviteRecentVisitorsModal: React.FC<Props> = ({ recentVisitors }) => {
  const [urlCopied, setUrlCopied] = useState(false);
  const urlAlias = useSelector(selectCurrentRoomUrlAlias);

  useEffect(() => {
    track(INVITE_RECENT_VISITORS_MODAL_DISPLAYED);
  }, []);

  const onCopyLinkClick = useCallback(() => {
    window.navigator.clipboard.writeText(window.location.href);

    setUrlCopied(true);

    setTimeout(() => {
      setUrlCopied(false);
    }, 1000);

    track(COPY_INVITE_LINK, { source: CopyInviteLinkContexts.INVITE_RECENT_VISITOR_MODAL });
  }, []);

  const onOverlayClick = useCallback(() => {
    document.getElementById('recent-visitors-invite-modal').style.display = 'none';

    track(INVITE_RECENT_VISTORS_MODAL_DISMISSED);
  }, []);

  return (
    <FadeIn time="0.5s">
      <HereModal modalWidth="620px">
        <GradientContainer />
      </HereModal>
      <HereModal onOverlayClick={onOverlayClick} modalWidth="600px">
        <ModalContentsContainer>
          <Title>Nobody is here. Invite them back!</Title>
          
          <VisitorsContainer>
            {recentVisitors && recentVisitors.map((visitor) => (
              <div key={visitor.id}>
                <TimeSinceLabel>{visitor.timeSince}</TimeSinceLabel>
                <InviteRecentVisitorTile userId={visitor.id} />
              </div>
            ))}
          </VisitorsContainer>
          <Divider />
          <InviteTitle>Send an invite</InviteTitle>
          <InviteContainer>
            <UrlInput onClick={onCopyLinkClick}><StyledLinkIcon />{`here.fm/${urlAlias || window.currentBoardId}`}</UrlInput>
            <CopyInviteButton onClick={onCopyLinkClick}>
              {urlCopied ? 'Copied!' : 'Copy Invite Link'}
            </CopyInviteButton>
          </InviteContainer>
          <InviteDescription>Anyone with the link can join.</InviteDescription>
        </ModalContentsContainer>
      </HereModal>
    </FadeIn>
  );
};

export default InviteRecentVisitorsModal;

const GradientContainer = styled.div`
  background: linear-gradient(#B501B0, #2A07F4);
  height: 603px;
  border-radius: 20px;
`;

const ModalContentsContainer = styled.div`
  padding: 35px 50px;
`;

const Title = styled.div`
  text-align: center;
  color: #12002D;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;

  margin-bottom: 25px;
`;

const VisitorsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  max-width: 835px;
  margin: 0 auto 40px auto;
`;

const TimeSinceLabel = styled.div`
  color: black;
  font-size: 12px;
  line-height: 24px;
  opacity: 0.5;
  text-align: center;
  height: 24px;
`;

const Divider = styled.hr`
  margin: 0;
`;

const InviteContainer = styled.div`
  width: 440px;
  margin: 0 auto;
`;

const StyledLinkIcon = styled(LinkIcon)`
  ${svgColorMixin('black')}
  opacity: 0.5;

  height: 12px;
  margin-right: 10px;
`;

const UrlInput = styled.div`
  width: 100%;
  text-align: center;

  color: #12002D;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  padding: 13px 0;

  border: 1px rgba(18, 0, 45, 0.2) solid;
  border-radius: 10px;
  box-shadow: 0 5px 7px rgb(0 0 0 / 20%);

  cursor: pointer;

  transition: border 0.2s;

  &:hover {
    border: 1px rgba(18, 0, 45, 0.35) solid;
  }
`;

const CopyInviteButton = styled.div`
  border-radius: 10px;
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  background-color: #6B1BE3;
  width: 100%;

  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin-top: 8px;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.85;
  }
`;

const InviteTitle = styled.div`
  color: rgba(18, 0, 45, 0.60);
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;

  padding-top: 25px;
  text-align: center;
  margin-bottom: 10px
`;

const InviteDescription = styled.div`
  color: rgba(18, 0, 45, 0.60);
  font-size: 12px;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
`;
