import React from 'react';
import styled from 'styled-components';

import UploadIcon from '../../../assets/icons/upload.svg';

const DropArea = () => (
  <Container>
    <UploadIcon />
  </Container>
);

export default DropArea;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 10px;
  border: 2px dashed black;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  svg {
    width: 30px;
  }
`;
