import BoardElement from '../board-element';
import wrapElement from '../element-wrapper';
import firebase, { db } from '../firebase';
import log from '../log';

// react
import { renderRoomActiveTimeWidget } from '../react/board-elements/widgets/room-active-time/render-room-active-time-widget.tsx';

// utils
import { screenToCanvasCoords } from '../util/canvas-util';
import { track } from '../util/analytics-util';
import {
  ROOM_ACTIVE_TIME_WIDGET_MIN_HEIGHT_PX,
  ROOM_ACTIVE_TIME_WIDGET_MIN_WIDTH_PX,
} from '../constants/board-constants';
import {
  ADD_ELEMENT,
  ADD_ELEMENT_DESTINATION_TYPES,
  ELEMENT_TYPES,
} from '../constants/analytics-events/element-events';

export default class RoomActiveTimeWidget extends BoardElement {
  elementDescription() {
    return 'active-time-widget';
  }

  icon() {
    return 'clock';
  }

  minSize() {
    return [ROOM_ACTIVE_TIME_WIDGET_MIN_WIDTH_PX, ROOM_ACTIVE_TIME_WIDGET_MIN_HEIGHT_PX];
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, __elementDoc) {
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(__elementId, __elementDoc) {}

  getElement(elementDoc) {
    log.log('Creating room active time widget');
    const element = document.createElement('div');
    element.style.height = '100%';
    element.style.minWidth = `${ROOM_ACTIVE_TIME_WIDGET_MIN_WIDTH_PX}px`;
    element.style.minHeight = `${ROOM_ACTIVE_TIME_WIDGET_MIN_HEIGHT_PX}px`;
    renderRoomActiveTimeWidget(element, window.currentBoardData.id);
    return wrapElement(element, elementDoc, { preserveAspectRatio: true });
  }

  static async addElement() {
    const center = screenToCanvasCoords(
      Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
      Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
    );

    await db.collection(`boards/${window.currentBoardId}/elements`).add({
      class: 'RoomActiveTimeWidget',
      center,
      size: [ROOM_ACTIVE_TIME_WIDGET_MIN_WIDTH_PX, ROOM_ACTIVE_TIME_WIDGET_MIN_HEIGHT_PX],
      creator: firebase.auth().currentUser.uid,
      backgroundColor: '#ffffff',
      zIndex: window.getFrontZIndex(),
    });

    track(ADD_ELEMENT, { element: ELEMENT_TYPES.ROOM_ACTIVE_TIME, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
  }
}
