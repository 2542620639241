import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ControlButton from './ControlButton';
import { BoardElementControllerContext, ViewportControllerContext } from '../../common/contexts.ts';
import { useHandleDragging } from '../../hooks/useHandleDragging';
import { elementDefinitions } from '../element-definitions';

import ResizeIcon from '../../../../assets/icons/resize.svg';

const ResizeButton = () => {
  const { elementData, patchLiveData, patchDbData, setAreControlButtonsAlwaysVisible } =
    useContext(BoardElementControllerContext);
  const viewportController = useContext(ViewportControllerContext);
  const { minSize, preserveAspectRatio } = elementDefinitions[elementData.class];

  const onStartDragging = () => setAreControlButtonsAlwaysVisible(true);
  const onDragging = ({ deltaX, deltaY }) => {
    // If one of dimensions is less than minimum size, allow only enlarging
    if (elementData.size[0] <= minSize[0] && deltaX < 0) deltaX = 0;
    if (elementData.size[1] <= minSize[1] && deltaY < 0) deltaY = 0;

    const scale = viewportController?.getCoordinates().scale || 1;
    deltaX /= scale;
    deltaY /= scale;

    let newWidth = parseFloat(elementData.size[0]) + deltaX;
    let newHeight = parseFloat(elementData.size[1]) + deltaY;
    if (preserveAspectRatio) {
      const originalRatio = elementData.size[0] / elementData.size[1];
      if (deltaX > deltaY) {
        newWidth = newHeight * originalRatio;
      } else {
        newHeight = newWidth / originalRatio;
      }
    }
    patchLiveData({ size: [newWidth, newHeight] });
  };
  const onStopDragging = () => {
    setAreControlButtonsAlwaysVisible(false);
    patchDbData({ size: elementData.size, w: elementData.size[0], h: elementData.size[1] });
  };
  const draggingHandlers = useHandleDragging({
    onStartDragging,
    onDragging,
    onStopDragging,
    cursor: 'move',
  });

  return (
    <Container {...draggingHandlers}>
      <ResizeIcon />
    </Container>
  );
};

export default ResizeButton;

ResizeButton.propTypes = {
  onResizeStart: PropTypes.func,
  onResizeFinish: PropTypes.func,
};

ResizeButton.defaultProps = {
  onResizeStart: () => {},
  onResizeFinish: () => {},
};

const Container = styled(ControlButton)`
  cursor: move;
`;
