import { all, select, takeEvery, put, call } from 'redux-saga/effects';

import firebase, { db } from '../../../firebase';
import { fetchCategories, fetchRoomTemplatesPage } from '../../../api/admin-templates-api';
import { copyElementsRequest } from './api';

// store
import * as store from './store';
import { updateNewRoomData } from '../new-room/actions.ts';

// utils
import { screenToCanvasCoords } from '../../../util/canvas-util';
import { getUserFirstName } from '../../../util/user-util';

// constants
import { BASICS_ROOM_ID, BLANK_ROOM_ID } from '../../../constants/board-constants';
import { track } from '../../../util/analytics-util';

const MAX_TEMPLATES_IN_PAGE = 10;
const MAX_ROOM_NAME_LENGTH = 99;

const cropRoomName = (roomName) => roomName.slice(0, MAX_ROOM_NAME_LENGTH);

function* handleItemChosen({ payload }) {
  const { item } = payload;
  if (item === null) {
    return;
  }

  const firstName = yield select(getUserFirstName);

  // load template info
  const newBoardRef = yield call([db.collection('boards').doc(item.board), 'get']);
  const newBoardData = newBoardRef.data();
  const { background } = newBoardData;
  yield put(
    updateNewRoomData({
      data: {
        name: cropRoomName(`${firstName}'s ${item.name}`),
        template: {
          id: item.board,
          name: item.name,
          background,
          isBlank: false,
        },
        ...(item.groupId ? { groupId: item.groupId } : {}),
      },
    })
  );
}

function* handleBlankRoomSelected({ payload: { groupId } }) {
  const firstName = yield select(getUserFirstName);
  yield put(
    updateNewRoomData({
      data: {
        name: cropRoomName(`⛺️ ${firstName}'s Room`),
        template: {
          id: BLANK_ROOM_ID,
          name: 'Blank Room',
          background: 'images/bg/22.jpg',
          isBlank: true,
        },
        ...(groupId ? { groupId } : {}),
      },
    })
  );
}

function* handleBasicsRoomSelected({ payload: { groupId } }) {
  const firstName = yield select(getUserFirstName);

  // load template info
  const newBoardRef = yield call([db.collection('boards').doc(BASICS_ROOM_ID), 'get']);
  const newBoardData = newBoardRef.data();
  const { background } = newBoardData;
  yield put(
    updateNewRoomData({
      data: {
        name: cropRoomName(`${firstName}'s Basics`),
        template: {
          id: BASICS_ROOM_ID,
          name: 'The Basics',
          background,
          isBlank: false,
        },
      },
      ...(groupId ? { groupId } : {}),
    })
  );
}

function* handleFetchTemplatesPage(action) {
  const type = yield select(store.getType);
  const nextPage = yield select(store.getNextPage);
  const chosenCategory = yield select(store.getChosenCategory);
  const itemsType = yield select(store.getType);

  const { templatesPage, found } = yield call(
    fetchRoomTemplatesPage,
    MAX_TEMPLATES_IN_PAGE,
    action.payload.filter,
    nextPage,
    chosenCategory === 'templates' || chosenCategory === 'kits' || !!action.payload.filter ? null : chosenCategory,
    type
  );

  let prevTemplates = [];
  if (itemsType === 'templates') {
    prevTemplates = yield select(store.getTemplates);
  } else if (itemsType === 'kits') {
    prevTemplates = yield select(store.getKits);
  } else {
    throw new Error('Wrong items type');
  }

  let templatesToSet = [];
  if (action.payload.replace) {
    templatesToSet = templatesPage;
  } else {
    templatesToSet = [...prevTemplates, ...templatesPage];
  }

  if (type === 'templates') {
    yield put(store.setTemplates({ templates: templatesToSet }));
  } else {
    yield put(store.setKits({ kits: templatesToSet }));
  }

  if (templatesPage.length === MAX_TEMPLATES_IN_PAGE) {
    yield put(store.setHasMore({ hasMore: true }));
    yield put(store.setNextPage({ page: nextPage + 1 }));
  } else {
    yield put(store.setHasMore({ hasMore: false }));
  }

  yield put(store.setIsLoading({ isLoading: false }));

  if (action.payload.filter) {
    track('Templates Search', { filter: action.payload.filter, resultsCount: found });
  }
}

function* handleFetchTemplateCategories() {
  const itemsType = yield select(store.getType);
  const allSortedCategories = yield call(fetchCategories, itemsType);
  const categories = allSortedCategories.map(({ name }) => name);
  yield put(store.setCategories({ categories }));
}

function* handleKitSelected({ payload }) {
  const { kit } = payload;
  const kitBoardRef = yield call([db.collection('boards').doc(kit.board), 'get']);
  const kitBoardData = kitBoardRef.data();
  const { defaultViewport } = kitBoardData;
  const copyElementsData = {
    sourceBoardId: kit.board,
    destinationBoardId: window.currentBoardId,
    createdBy: firebase.auth().currentUser.uid,
    options: {
      center: screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2 - 200),
      defaultViewport,
    },
  };
  yield call(copyElementsRequest, copyElementsData);
  yield put(store.setChosenKit({ kit }));
  track('Add Room Kit', { kit: kitBoardData.title });
}

export default function* mainSaga() {
  yield all([
    takeEvery(store.blankRoomSelected, handleBlankRoomSelected),
    takeEvery(store.basicsRoomSelected, handleBasicsRoomSelected),
    takeEvery(store.itemChosen, handleItemChosen),
    takeEvery(store.kitChosen, handleKitSelected),
    takeEvery(store.fetchCategories, handleFetchTemplateCategories),
    takeEvery(store.fetchTemplatesPage, handleFetchTemplatesPage),
  ]);
}
