import { RootState } from '../../../definitions/store';
import { Screens } from '../../sign-in-flow/constants';

export const selectFlowId = (state: RootState) => state.signingIn.flowId;

// navigation
export const selectCurrentScreenId = (state: RootState) => state.signingIn.screenId;
export const selectLastInteractedScreenId = (state: RootState) => state.signingIn.lastInteractedScreenId;

// profile
export const selectProfileId = (state: RootState) => state.signingIn.profile.id;
export const selectEmail = (state: RootState) => state.signingIn.profile.email;
export const selectDisplayName = (state: RootState) => state.signingIn.profile.displayName;
export const selectUsername = (state: RootState) => state.signingIn.profile.username;

// phone
export const selectDomesticPhoneNumber = (state: RootState) =>
  state.signingIn.screens[Screens.PHONE_INPUT].domesticPhoneNumber;
export const selectCountryCode = (state: RootState) => state.signingIn.screens[Screens.PHONE_INPUT].countryCode;
export const selectVerificationId = (state: RootState) =>
  state.signingIn.screens[Screens.PHONE_VERIFY_CODE].verificationId;

// errors
export const selectInputEmailScreenError = (state: RootState) => state.signingIn.screens[Screens.EMAIL_INPUT].error;
export const selectTrySignInWithEmailAndPasswordError = (state: RootState) =>
  state.signingIn.screens[Screens.EMAIL_SIGN_IN].error;

export const selectTryCreateAccountEmailError = (state: RootState) =>
  state.signingIn.screens[Screens.CREATE_ACCOUNT].emailError;

export const selectTryCreateAccountPasswordError = (state: RootState) =>
  state.signingIn.screens[Screens.CREATE_ACCOUNT].passwordError;

export const selectUsernameValidationResult = (state: RootState) => state.signingIn.screens[Screens.CREATE_USERNAME];
export const selectUsernameAndNameValidationResult = (state: RootState) =>
  state.signingIn.screens[Screens.CREATE_USERNAME_AND_NAME];

export const selectFriendsSuggestions = (state: RootState) =>
  state.signingIn.screens[Screens.FRIENDS_SUGGESTIONS].suggestions;
export const selectNextPage = (state: RootState) => state.signingIn.screens[Screens.FRIENDS_SUGGESTIONS].nextPage;

export const selectResetPasswordScreenError = (state: RootState) =>
  state.signingIn.screens[Screens.RESET_PASSWORD].error;
