import { select, call, all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import { GiphyFetch } from '@giphy/js-fetch-api';

import log from '../../../log';
// eslint-disable-next-line import/no-cycle
import ImageElement from '../../../image';
import { sendSticker } from '../../../api/feed-api';

// store
import * as store from './store';
import * as messagingStore from '../messaging/reducer';
import { watchAddImagePopupOpened, watchAddRoomKitPopupOpened } from '../common-sagas';
import { selectActiveReceiverId } from '../messaging/selectors';
import { selectActiveGroupId } from '../web-lobby/selectors.ts';

// utils
import { track } from '../../../util/analytics-util';
import { getSanitizedUrl } from '../../../util/string-util';
import { getSourceFromCaller } from '../../chats/feed/feed-util';

// constants
import { gifPickerCallers } from '../../../constants/image-constants';
import { messageTypes } from '../../../constants/chat-constants';
import { SEND_CHAT_MESSAGE, SEND_FEED_MESSAGE } from '../../../constants/analytics-events/chat-events.ts';
import {
  ADD_ELEMENT_SOURCE_TYPES,
  IMAGE_TYPE_TO_ELEMENT_TYPE,
} from '../../../constants/analytics-events/element-events';

const gf = new GiphyFetch('LuWUONRKC9uPYfHVnbhSplL6IcSdzaYp');

function* loadGifs(type, caller) {
  const searchTerm = yield select(store.getSearchTerm, { type });
  const limit = yield select(store.getPerPage, { type });
  const offset = yield select(store.getResultsLength, { type });
  const options = { limit, type, offset };

  try {
    let result;
    if (type === store.gifTypes.text) {
      result = searchTerm ? yield call([gf, 'animate'], searchTerm) : yield call([gf, 'animate'], 'here.fm');
    } else if (searchTerm) {
      result = yield call([gf, 'search'], searchTerm, options);
    } else if (caller === gifPickerCallers.REMIX || caller === gifPickerCallers.OS || type === store.gifTypes.remix) {
      result = { data: [] };
    } else {
      result = yield call([gf, 'trending'], options);
    }

    return result.data;
  } catch (err) {
    log.error(err);
    return [];
  }
}

function* handleResultsRequested({ payload: { type, caller } }) {
  const results = yield call(loadGifs, type, caller);
  yield put(store.gotResults({ type, results }));

  const searchTerm = yield select(store.getSearchTerm, { type });
  if (searchTerm) {
    let eventName = 'Unknown Search';
    const params = { search: searchTerm };
    if (caller === gifPickerCallers.REMIX) {
      eventName = 'Remix Search';
      params.groupId = yield select(selectActiveGroupId);
    } else if (caller === gifPickerCallers.OS) {
      eventName = 'Lobby - Element Search';
    } else if (type === store.gifTypes.stickers) {
      eventName = 'Sticker Search';
    } else if (type === store.gifTypes.gifs) {
      eventName = 'GIF Search';
    } else if (type === store.gifTypes.text) {
      eventName = 'Text Search';
    }

    yield call(track, eventName, params);
  }
}

function* handleMoreResultsRequested({ payload: { type } }) {
  const results = yield call(loadGifs, type);
  yield put(store.gotMoreResults({ type, results }));
}

// TODO:
// using this handleAddGifRequested gets harder and harder
// we need to move all these cases from the switch to the callers

// TODO: investigate if we need isChat at all
// eslint-disable-next-line no-unused-vars
function* handleAddGifRequested({ payload: { url, isChat, chatId, __elementId, firebaseId, type, caller, ...rest } }) {
  // this saga is called when the user clicks on a gif in the gif picker
  // we have a gif picker in the:
  // 1) feed -- should send a gif-message to the feed
  // 2) text channel -- should send a gif-message to the text channel
  // 3) dms -- should send a gif-message to the dms
  // 4) room -- should add an image element to the room

  switch (caller) {
    case gifPickerCallers.DM: {
      url = getSanitizedUrl(url);
      if (chatId) {
        // if chat is already exists
        yield put(messagingStore.addMessage({ chatId, url }));
      } else {
        // if user tries to start a new chat with a gif
        try {
          const activeReceiverId = yield select(selectActiveReceiverId);
          if (activeReceiverId) {
            yield put(
              messagingStore.addMessage({ receiverId: activeReceiverId, url, type: messageTypes.GIF, createChat: true })
            );
          }
        } catch (e) {
          yield put(messagingStore.messagesError({ error: `Error of creating a chat via sticker` }));
          log.error(`Error of creating a chat via sticker`, e);
        }
      }

      break;
    }

    case gifPickerCallers.WEB_LOBBY_ROOM_CHAT:
    case gifPickerCallers.ROOM_CHAT:
    case gifPickerCallers.FEED: {
      const messagesCollectionPath = `boards/${chatId || window.currentBoardId}/feedItems`;
      sendSticker(url, messagesCollectionPath, type).then(() => {
        const source = getSourceFromCaller(caller);
        if (source) {
          track(SEND_FEED_MESSAGE, { messageType: 'gif', addMode: 'click', source });
        } else {
          track(SEND_FEED_MESSAGE, { messageType: 'gif', addMode: 'click' });
        }
      });

      break;
    }

    case gifPickerCallers.TEXT_CHANNEL: {
      const messagesCollectionPath = `boards/${window.currentBoardId}/elements/${firebaseId}/messages`;
      sendSticker(url, messagesCollectionPath, type).then(() => {
        const eventName = firebaseId === 'feed' ? SEND_FEED_MESSAGE : SEND_CHAT_MESSAGE;
        track(eventName, { messageType: 'gif', addMode: 'click' });
      });

      break;
    }

    case gifPickerCallers.TOOLBAR: {
      const elementType = IMAGE_TYPE_TO_ELEMENT_TYPE[rest.imageType];
      yield call(
        [ImageElement, 'addElement'],
        { imageURL: url, ...rest },
        elementType,
        ADD_ELEMENT_SOURCE_TYPES.SEARCH
      );

      break;
    }

    case gifPickerCallers.ROOM_DECORATION_PICKER: {
      yield call([ImageElement, 'addElement'], { imageURL: url, ...rest }, 'gif');
      yield call(track, 'Room Decor Added', { category: rest.roomDecorationCategory });

      break;
    }

    case gifPickerCallers.MURAL:
    case gifPickerCallers.REMIX:
    case gifPickerCallers.OS:
    case gifPickerCallers.USER_PAGE: {
      break;
    }

    default: {
      throw new Error(`Unknown caller: ${caller}`);
    }
  }
}

function* handleAddImagePopupOpened() {
  const types = Object.values(store.gifTypes);
  for (let i = 0; i < types.length; i += 1) {
    const type = types[i];
    const hasResults = yield select(store.getResultsLength, { type });
    if (!hasResults) {
      yield put(store.resultsRequested({ type }));
    }
  }
}

function* handleAddRoomKitPopupOpened() {
  const types = Object.values(store.gifTypes);
  for (let i = 0; i < types.length; i += 1) {
    const type = types[i];
    const hasResults = yield select(store.getResultsLength, { type });
    if (!hasResults) {
      yield put(store.resultsRequested({ type }));
    }
  }
}

export default function* roomManagementSaga() {
  yield all([
    takeEvery(store.resultsRequested, handleResultsRequested),
    takeLatest(store.moreResultsRequested, handleMoreResultsRequested),
    takeEvery(store.addGifRequested, handleAddGifRequested),
    takeEvery(store.openChatImagePopup, handleAddRoomKitPopupOpened),
    takeEvery(store.openToolbarImagePopup, handleAddRoomKitPopupOpened),
    watchAddImagePopupOpened(handleAddImagePopupOpened),
    watchAddRoomKitPopupOpened(handleAddRoomKitPopupOpened),
  ]);
}
