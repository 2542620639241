import { htmlToElement } from '../../util';
import EmbeddedResource from './embedded-resource';

const regex = /https:\/\/airtable\.com\/embed\/[^/]+/i;

export default class AirtableElement extends EmbeddedResource {
  static getEmbedUrl(url) {
    const isAirtableUrl = regex.test(url);
    return isAirtableUrl ? url : null;
  }

  getUnderlayContent({ title }) {
    const elTitle = title || 'Airtable';
    this.setTooltip(elTitle);
    return htmlToElement(`
      <div class="underlay-content">
        <div class="underlay-background airtable-background"></div>
        <img class="icon" alt="Airtable icon" src="images/icons/airtable.svg" />
        <span class="title">${elTitle}</span>
      </div>
    `);
  }

  processEmbedTitle(title) {
    return title ? title.replace(' - Airtable', '') : '';
  }
}

AirtableElement.elementType = 'AirtableElement';
