import UIkit from 'uikit';
import { htmlToElement } from '../util';
import '../../styles/electron-ui.less';

class SourcesSelector {
  sources = [];

  onSelectedCallback = null;

  selectedElement = null;

  selectedSource = null;

  constructor() {
    const sourceModal = htmlToElement(`
    <div id="screenshare-source-select" uk-modal>
      <div class="uk-modal-dialog uk-modal-body screenshare-sources-body">
        <div class="screenshare-select-content">
          <div class="title">Share Your Screen</div>
          <div class="subtitle">Choose what you'd like to share</div>
        
          <div id="screenshare-source-list">
          </div>
        </div>
        <div class="screenshare-control-bottom">
          <button class="here-button share-button" disabled>Share</button>
          <button class="here-button cancel-button">Cancel</button>
        </div>
      </div>
    </div>`);
    document.body.appendChild(sourceModal);
  }

  get sourceModal() {
    return document.getElementById('screenshare-source-select');
  }

  get shareButton() {
    return this.sourceModal.querySelector('.share-button');
  }

  get cancelButton() {
    return this.sourceModal.querySelector('.cancel-button');
  }

  get sourcesList() {
    return this.sourceModal.querySelector('#screenshare-source-list');
  }

  addSources() {
    this.sourcesList.innerHTML = '';
    this.sources.forEach((source) => {
      let el = null;
      if (source.thumbnailDataURL === 'data:image/png;base64,') {
        el = htmlToElement(
          `<div class="screenshare-option"><h6 class="screenshare-thumb">Allow Here to access your screen in system settings to share this screen.</h6><div class="screenshare-title">${source.name}</div></div>`
        );
      } else {
        el = htmlToElement(
          `<div class="screenshare-option"><img class="screenshare-thumb" src="${source.thumbnailDataURL}" /><div class="screenshare-title">${source.name}</div></div>`
        );
      }
      el.addEventListener('click', () => {
        if (this.selectedElement) {
          this.selectedElement.classList.remove('selected');
        }
        el.classList.add('selected');
        this.selectedElement = el;
        this.selectedSource = source;
        this.shareButton.disabled = false;
      });
      this.sourcesList.appendChild(el);
    });
  }

  show(sources, onSelected) {
    if (this.shareButton && this.onSelectedCallback) {
      this.shareButton.removeEventListener('click', this.onShareClick.bind(this));
    }

    this.sources = sources;
    this.onSelectedCallback = onSelected;

    this.shareButton.disabled = true;
    this.shareButton.addEventListener('click', this.onShareClick.bind(this));
    this.cancelButton.addEventListener('click', this.hide.bind(this));

    this.addSources();
    UIkit.modal(this.sourceModal).show();
  }

  onShareClick() {
    this.onSelectedCallback(this.selectedSource);
    this.hide();
  }

  hide() {
    UIkit.modal(this.sourceModal).hide();
  }
}

export default new SourcesSelector();
