export const nop = () => {};

export const nil = () => null;

export const omitUndefinedFields = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === undefined) {
      return acc;
    }

    return { ...acc, [key]: value };
  }, {});
