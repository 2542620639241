import React from 'react';
import { EmojiContainer } from '../shared-styles';

interface Props {
  emoji: string;
  onSelect: (emoji: string) => void;
}

const EmojiButton: React.FC<Props> = ({ emoji, onSelect }) => (
  <EmojiContainer onClick={() => onSelect(emoji)}>{emoji}</EmojiContainer>
);

export default EmojiButton;
