import { select, call, all, takeEvery, put, takeLatest } from 'redux-saga/effects';

import log from '../../../log';
// eslint-disable-next-line import/no-cycle
import ImageElement from '../../../image';

import * as store from './store';
import { sendSticker } from '../../../api/feed-api';
import { watchAddRoomKitPopupOpened } from '../common-sagas';
import { track } from '../../../util/analytics-util';
import { SEND_FEED_MESSAGE } from '../../../constants/analytics-events/chat-events.ts';
import { gifPickerCallers } from '../../../constants/image-constants';
import { ADD_ELEMENT_SOURCE_TYPES } from '../../../constants/analytics-events/element-events';

const subscriptionKey = '59ffcd7d016e41178914ddfaa53a2005';

function* loadImages() {
  const offset = yield select(store.getResultsLength);
  const count = yield select(store.getPerPage);
  let searchTerm = yield select(store.getSearchTerm);

  if (!searchTerm) {
    const defaultSearchTerms = yield select(store.getDefaultSearchTerms);
    searchTerm = defaultSearchTerms[Math.floor(Math.random() * defaultSearchTerms.length)];
  }

  const path = 'https://api.bing.microsoft.com/v7.0/images/search';
  const query = `?q=${encodeURIComponent(searchTerm)}&imageType=transparent&count=${count}&offset=${offset}`;
  const url = path + query;

  const requestParams = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': subscriptionKey,
    },
  };

  let response;
  try {
    response = yield call(fetch, url, requestParams);
  } catch (err) {
    log.error('Network error while fetching bing images', err);
    return [];
  }

  if (!response.ok) {
    log.error('Something went wrong with fetching bing images', response);
    return [];
  }

  const json = yield call([response, 'json']);

  return json.value.map((image) => ({
    id: image.imageId,
    thumbnail: image.thumbnailUrl,
    url: image.contentUrl,
    title: image.name,
    useImageProxy: true,
    size: {
      width: image.width,
      height: image.height,
    },
  }));
}

function* handleResultsRequested() {
  const results = yield call(loadImages);
  yield put(store.gotResults({ results }));

  const query = yield select(store.getSearchTerm);
  yield call([window.analytics, 'track'], 'Image Search', { query });
}

function* handleMoreResultsRequested() {
  const results = yield call(loadImages);
  yield put(store.gotMoreResults({ results }));
}

function* handleAddImageRequested({ payload: { url, thumbnail, caller, firebaseId, isChat } }) {
  if (caller === gifPickerCallers.MURAL) return;

  if (!isChat) {
    try {
      yield call(
        [ImageElement, 'addElement'],
        {
          imageURL: url,
          source: 'bing',
          useImageProxy: true,
        },
        'image',
        ADD_ELEMENT_SOURCE_TYPES.SEARCH
      );
    } catch (err) {
      log.error(err);
    }
  } else {
    const messagesCollectionPath =
      firebaseId === 'feedItems'
        ? `boards/${window.currentBoardId}/feedItems`
        : `boards/${window.currentBoardId}/elements/${firebaseId}/messages`;
    sendSticker(thumbnail, messagesCollectionPath).then(() => {
      track(SEND_FEED_MESSAGE, { messageType: 'image' });
    });
  }
}

function* handleAddRoomKitPopupOpened() {
  const hasResults = yield select(store.getResultsLength);
  if (!hasResults) {
    yield put(store.resultsRequested());
  }
}

export default function* roomManagementSaga() {
  yield all([
    takeEvery(store.resultsRequested, handleResultsRequested),
    takeLatest(store.moreResultsRequested, handleMoreResultsRequested),
    takeEvery(store.addImageRequested, handleAddImageRequested),
    watchAddRoomKitPopupOpened(handleAddRoomKitPopupOpened),
  ]);
}
