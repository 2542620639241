import { useEffect, useState } from 'react';
import { getVibes } from '../../api/vibes-api';
import log from '../../log';

export const useVibeRooms = () => {
  const [vibeRooms, setVibeRooms] = useState([]);

  useEffect(() => {
    (async () => {
      const vibesResult = await getVibes();
      if (vibesResult && vibesResult.success) {
        setVibeRooms(vibesResult.vibes);
        return;
      }
      log.error('VibePicker: Failed to fetch vibe rooms');
    })();
  }, []);

  return vibeRooms;
};
