import { useMemo } from 'react';

export const useBaseBoardController = (elementsData) => {
  const minZIndex = useMemo(
    () => (elementsData.length ? Math.min(...elementsData.map((el) => el.zIndex || 0)) : 0),
    [elementsData]
  );
  const maxZIndex = useMemo(
    () => (elementsData.length ? Math.max(...elementsData.map((el) => el.zIndex || 0)) : 0),
    [elementsData]
  );

  return useMemo(() => ({ minZIndex, maxZIndex }), [maxZIndex, minZIndex]);
};
