import { RootState } from '../../../definitions/store';

export const selectIsChatSkinningLoading = (state: RootState) => state.chatSkinning.isLoading;
export const selectIsChatSkinningLoaded = (state: RootState) => state.chatSkinning.defaultData.backgrounds.length > 0;

export const selectChatSkinningBackgrounds = (state: RootState) => state.chatSkinning.defaultData.backgrounds;
export const selectChatSkinningDecorators = (state: RootState) => state.chatSkinning.defaultData.decorators;
export const selectChatSkinningFrames = (state: RootState) => state.chatSkinning.defaultData.frames;
export const selectChatSkinningParticles = (state: RootState) => state.chatSkinning.defaultData.particles;
export const selectChatSkins = (state: RootState) => state.chatSkinning.defaultData.skins;
