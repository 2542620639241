import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { db } from '../../../firebase';
import RoomItem, { RoomItemWrapper, RoomItemTitle, RoomItemFooter } from '../../electron-lobby/components/RoomItem';
import UserAvatarWithStatus from '../../components/UserAvatarWithStatus';
import { loadingPulsationKeyframes } from '../chats-shared-styles';
import { ellipsisTextMixin } from '../../mixins';
import { selectUserById } from '../../store/users/selectors';
import { joinRoom, removePopupMessage } from '../../store/messaging/reducer';
import { selectRoomId } from '../../store/room/selectors';

// icons
import { useCanDeleteMessage } from './context-menu/useCanDeleteMessage.ts';
import { renderReportUserModal } from '../../web-lobby/modals/chat-management/render.tsx';
import MessageContextButton from './context-menu/MessageContextButton.tsx';
import { isCurrentUser } from '../../../util/user-util';
import SperakerIcon from '../../../../assets/icons/speaker.svg';
import InviteMessageLoading from '../../../../assets/icons/invite-message-loading.svg';
import { cacheRoom, getCachedRoom } from '../../../rooms-cache';
import DeleteButton from './context-menu/DeleteButton.tsx';
import ContextMenu from '../../components/ContextMenu.tsx';

const crossIcon = 'images/icons/cross-black.svg';

function RoomCardMessage({
  messageId,
  dmChatId,
  creator,
  roomId,
  isInvitorBlockVisible,
  isCardAvatarVisible,
  deleteMessage,
}) {
  const dispatch = useDispatch();

  const [room, setRoom] = useState(null);

  const creatorProfile = useSelector((state) => selectUserById(state, creator));
  const currentRoomId = useSelector(selectRoomId);

  const alreadyInThisRoom = useMemo(() => currentRoomId === roomId, [roomId, currentRoomId]);

  useEffect(() => {
    (async () => {
      const cachedRoom = getCachedRoom(roomId);
      if (cachedRoom) {
        setRoom({ ...cachedRoom, id: cachedRoom.id });
      } else {
        const roomRef = await db.doc(`boards/${roomId}`).get();
        const roomData = await roomRef.data();
        const roomDataWithId = { ...roomData, id: roomId };
        cacheRoom(roomDataWithId);
        setRoom(roomDataWithId);
      }
    })();
  }, [roomId]);

  const dismissRoomCard = () => dispatch(removePopupMessage({ messageId }));

  const onJoinButtonClick = async () => {
    dispatch(joinRoom({ chatId: dmChatId, messageId, room }));
  };

  const deleteMessageCallback = React.useCallback(
    (event) => {
      event.stopPropagation();
      deleteMessage(messageId);
    },
    [messageId, deleteMessage]
  );

  const onReportClick = (e) => {
    e.stopPropagation();
    renderReportUserModal(creator, dmChatId, `https://here.fm/${roomId}`);
  };

  const canDelete = useCanDeleteMessage(creator);

  return (
    <RoomCardMessageWrapper>
      {room ? (
        <>
          {/* this button visible only for popups (not in the chat window) */}
          <RoomCardDismissButton onClick={dismissRoomCard}>
            <img height="14" width="14" src={crossIcon} alt="close" />
          </RoomCardDismissButton>

          <ContextMenu>
            {dmChatId && !isCurrentUser(creator) ? (
              <MessageContextButton onClick={onReportClick}>Report</MessageContextButton>
            ) : null}

            {canDelete ? <DeleteButton onClick={deleteMessageCallback} /> : null}
          </ContextMenu>

          {isInvitorBlockVisible ? (
            <InvitorBlock>
              {isCardAvatarVisible ? (
                <AvatarWrapper>
                  <UserAvatarWithStatus userProfile={creatorProfile} status={creatorProfile.onlineStatus.status} />
                </AvatarWrapper>
              ) : null}

              <InvitorLabel>invite from</InvitorLabel>
              <InvitorUsername>{creatorProfile.displayName}</InvitorUsername>
              <InvitorIcon>
                <SperakerIcon />
              </InvitorIcon>
            </InvitorBlock>
          ) : null}

          <RoomItem
            id={room.id}
            title={room.title}
            background={room.background}
            backgroundColor={room.backgroundColor}
            creator={room.creator}
            url={room.urlAlias}
            publicShareMessage={room.publicShareMessage}
            showRoomMembersIcon
          />

          {!alreadyInThisRoom ? (
            <JoinButton type="button" onClick={onJoinButtonClick}>
              Join
            </JoinButton>
          ) : null}
        </>
      ) : (
        <LoadingStateWrapper>
          <InviteMessageLoading />
        </LoadingStateWrapper>
      )}
    </RoomCardMessageWrapper>
  );
}

RoomCardMessage.propTypes = {
  messageId: PropTypes.string,
  dmChatId: PropTypes.string,
  creator: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  isCardAvatarVisible: PropTypes.bool,
  isInvitorBlockVisible: PropTypes.bool,
  deleteMessage: PropTypes.func,
};

RoomCardMessage.defaultProps = {
  messageId: '',
  dmChatId: '',
  isCardAvatarVisible: true,
  isInvitorBlockVisible: true,
  deleteMessage: () => {},
};

export const RoomCardMessageWrapper = styled.div`
  position: relative;
  padding: 12px;
  max-width: 274px;
  min-width: 165px;
  background-color: #12002d;
  border-radius: 20px;

  .feed-list-scroll-wrapper & {
    min-width: 216px;
  }

  .dm-window-container & {
    min-width: 165px;
  }

  ${RoomItemWrapper} {
    margin-top: 0;
    cursor: default;

    &:hover {
      background-color: inherit;
    }
  }

  ${RoomItemTitle} {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .feed-list-scroll-wrapper & {
      max-width: 140px;
    }

    .dm-window-container & {
      max-width: 110px;
    }
  }

  ${RoomItemFooter} {
    padding: 10px;
  }
`;

const roomCardDismissButtonSize = 38;
export const RoomCardDismissButton = styled.button`
  position: absolute;
  top: -${roomCardDismissButtonSize / 2}px;
  right: -${roomCardDismissButtonSize / 2}px;
  padding: 10px;
  height: ${roomCardDismissButtonSize}px;
  width: ${roomCardDismissButtonSize}px;
  display: none;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const InvitorBlock = styled.div`
  padding-bottom: 11px;
  display: grid;
  column-gap: 6px;
  grid-template-areas: 'avatar label icon' 'avatar username icon';
  grid-template-columns: auto 1fr;
  border-bottom: 1px solid #ffffff19;
`;

const AvatarWrapper = styled.div`
  grid-area: avatar;
`;

const InvitorLabel = styled.p`
  margin: 0%;
  grid-area: label;
  color: #ffffff;
  font-size: 12px;
  opacity: 0.7;
`;

const InvitorUsername = styled.p`
  margin: 0;
  grid-area: username;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;

  ${ellipsisTextMixin()}
`;

const invitorIconPulsatingAnimation = keyframes`
  0% {
    transform: scale(.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(.8);
  }
`;

const invitorIconBackgroundPulsatingAnimation = keyframes`
0% {
    transform: scale(.33);
  }

  80%, 100% {
    opacity: 0;
  }
`;

const InvitorIcon = styled.div`
  height: 43px;
  width: 43px;
  grid-area: icon;
  justify-self: end;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  animation: ${invitorIconPulsatingAnimation} 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;

  svg {
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    margin-top: -46%;
    z-index: 1;
    display: block;
    width: 170%;
    height: 170%;
    background-color: #38c15f;
    border-radius: 50%;
    animation: ${invitorIconBackgroundPulsatingAnimation} 1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
`;

const JoinButton = styled.button`
  padding: 10px 0;
  margin-top: 10px;
  width: 100%;
  display: block;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  opacity: 0.9;
  background-color: #39c360;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    text-decoration: none;
    opacity: 1;
  }
`;

const LoadingStateWrapper = styled.div`
  animation: ${() => css`1s ${loadingPulsationKeyframes} infinite`};
`;

export default RoomCardMessage;
