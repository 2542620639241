import { authedApiInstance } from '../../firebase';

export const getOnlineStatus = (userId) =>
  authedApiInstance
    .get('/online', { params: { uids: [userId] } })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const updatePhone = () =>
  authedApiInstance
    .patch('/user/updatePhone')
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const updateProfile = (id, profilePatch) =>
  authedApiInstance
    .patch(`/userProfiles/${id}`, { profilePatch })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const fetchUserUnreads = () =>
  authedApiInstance
    .get(`/user/unreads`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);
