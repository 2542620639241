import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SkinFrame } from '../../../../store/chat-skinning/init-state';
import { oppositeSides } from '../constants';
import { selectChatSkinningFrames } from '../../../../store/chat-skinning/selectors';

interface Props {
  children?: ReactNode;
  frame: SkinFrame;
  borderRadius: number;
}

const SkinningFrameWrapper = ({ frame, children, borderRadius }: Props) => {
  const defaultFrames = useSelector(selectChatSkinningFrames);
  // @ts-ignore
  frame = {
    ...(frame || {}),
    sizes: frame?.sizes || defaultFrames.find((f) => f?.name === frame?.name)?.sizes,
  };

  return frame?.sizes ? ( // A frame without sizes provided cannot be displayed correctly
    <>
      <Container borderRadius={borderRadius}>
        <SideFrame frame={frame} side="left" />
        <SideFrame frame={frame} side="right" />
        <SideFrame frame={frame} side="top" />
        <SideFrame frame={frame} side="bottom" />
        <CornerFrame frame={frame} xSide="left" ySide="top" />
        <CornerFrame frame={frame} xSide="right" ySide="top" />
        <CornerFrame frame={frame} xSide="left" ySide="bottom" />
        <CornerFrame frame={frame} xSide="right" ySide="bottom" />
      </Container>
      {children}
    </>
  ) : (
    <>{children}</>
  );
};

export default SkinningFrameWrapper;

const Container = styled.div<{ borderRadius: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius}px;
`;

const SideFrame = styled.div<{
  frame: SkinFrame;
  side: 'left' | 'right' | 'top' | 'bottom';
}>`
  position: absolute;
  background-size: contain;

  ${({ frame, side }) => {
    const isHorizontal = side === 'top' || side === 'bottom';
    const size = frame.sizes[side][isHorizontal ? 'height' : 'width'] / frame.sizes[side].scale;

    return {
      backgroundImage: `url('${frame.images[side]}')`,
      [oppositeSides[side]]: `calc(100% - ${size}px)`,
      [isHorizontal ? 'height' : 'width']: `${size}px`,
      [isHorizontal ? 'width' : 'height']: '100%',
      [isHorizontal ? 'left' : 'top']: '0',
      backgroundRepeat: `repeat-${isHorizontal ? 'x' : 'y'}`,
      backgroundPosition: side,
    };
  }};
`;

const CornerFrame = styled.div<{
  frame: SkinFrame;
  xSide: 'left' | 'right';
  ySide: 'top' | 'bottom';
}>`
  position: absolute;
  background-repeat: no-repeat;

  ${({ frame, xSide, ySide }) => {
    const side = ySide + xSide[0].toUpperCase() + xSide.slice(1);

    const height = frame.sizes[ySide].height / frame.sizes[ySide].scale;
    const width = frame.sizes[xSide].width / frame.sizes[xSide].scale;

    return {
      // @ts-ignore
      backgroundImage: `url('${frame.images[side]}')`,
      // @ts-ignore
      backgroundSize: `${frame.sizes[side].width / frame.sizes[side].scale}px ${
        // @ts-ignore
        frame.sizes[side].height / frame.sizes[side].scale
      }px`,
      backgroundPosition: `${ySide} ${xSide}`,
      [oppositeSides[xSide]]: `calc(100% - ${width}px)`,
      [oppositeSides[ySide]]: `calc(100% - ${height}px)`,
      width: `${width}px`,
      height: `${height}px`,
    };
  }}
`;
