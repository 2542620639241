import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { ellipsisTextMixin, svgColorMixin } from '../../../mixins';
import CheckIcon from '../../../../../assets/icons/check.svg';

interface SkinningItem {
  id: string;
  name: string;
  preview: ReactNode;
  isSelected: boolean;
  onSelect: () => void;
  onRemove?: () => void;
}

interface Props {
  items: SkinningItem[];
  canRemove?: boolean;
}

const SkinningItemPicker = ({ items, canRemove }: Props) => {
  const onItemClick = (item: SkinningItem) => {
    if (item.isSelected && canRemove && item.onRemove) {
      item.onRemove();
    } else if (!item.isSelected) {
      item.onSelect();
    }
  };

  return (
    <Container>
      {items.map((item) => (
        <ItemContainer key={item.id} canRemove={canRemove}>
          <PreviewContainer isSelected={item.isSelected} onClick={() => onItemClick(item)}>
            {item.preview}
          </PreviewContainer>
          {item.isSelected ? (
            <SelectedIndicator onClick={item.onRemove}>
              <CheckIconContainer>
                <CheckIcon />
              </CheckIconContainer>
              <RemoveIconContainer>×</RemoveIconContainer>
            </SelectedIndicator>
          ) : null}
          <Title>{item.name}</Title>
        </ItemContainer>
      ))}
    </Container>
  );
};

export default SkinningItemPicker;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
`;

const ItemContainer = styled.div<{ canRemove: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &:hover {
    ${({ canRemove }) =>
      canRemove &&
      `
        ${CheckIconContainer} {
          display: none;
        }

        ${RemoveIconContainer} {
          display: block;
        }
      `}
  }
`;

const CheckIconContainer = styled.div``;
const RemoveIconContainer = styled.div`
  display: none;
  line-height: 6px;
`;

export const PreviewContainer = styled.div<{ isSelected: boolean }>`
  position: relative;
  border: 2px solid
    ${({ isSelected }) => (isSelected ? 'var(--primary-foreground)' : 'var(--primary-foreground-alpha-50)')};
  border-radius: 10px;
  overflow: hidden;
  ${({ isSelected }) => !isSelected && 'opacity: 0.7;'}

  &:hover {
    border-color: var(--primary-foreground);
  }
`;

const checkmarkSize = 20;
const SelectedIndicator = styled.div`
  width: ${checkmarkSize}px;
  height: ${checkmarkSize}px;
  position: absolute;
  top: ${-checkmarkSize / 2}px;
  right: ${-checkmarkSize / 2}px;
  border: 2px solid var(--primary-foreground);
  background: var(--secondary-background);
  border-radius: 50%;
  padding: 4px 3px 3px;
  color: var(--secondary-foreground);
  ${svgColorMixin('var(--secondary-foreground)')}
`;

const Title = styled.div`
  color: var(--primary-foreground);
  text-align: center;
  font-weight: bold;
  margin-top: 8px;
  font-size: 12px;
  ${ellipsisTextMixin()}
`;
