import { createSelector } from '@reduxjs/toolkit';

import { getThemeName, isThemeCustom } from '../../../util/theming-util';
import { ROOM_DEFAULT_FONT } from './constants';
import { OS_PAGE_BOARD_TYPE, ROOM_BOARD_TYPE } from '../../../constants/board-constants';

export const selectIsCurrentlyInRoom = (state) =>
  Boolean(state.room?.id) && state.room?.current?.type !== OS_PAGE_BOARD_TYPE;
export const selectRoomId = (state) => state.room.id;
export const selectCurrentRoomId = (state) => state.room.current?.id;
export const selectRoomTitle = (state) => state.room.current?.title || '';
export const selectIsViewersModeOn = (state) => state.room.current.isViewersModeOn || false;
export const selectIsInActualRoom = (state) =>
  !!state.room.id && state.room.current && (!state.room.current.type || state.room.current.type === ROOM_BOARD_TYPE);
export const selectIsInOs = (state) => state.room.current?.type === OS_PAGE_BOARD_TYPE;
export const selectCurrentOsDesktopId = (state) => (state.room.current?.type && state.room.id ? state.room.id : ''); // only returns id if is in OS
export const selectRoomData = (state) => state.room.current;
export const selectCurrentRoomUrlAlias = (state) => state.room.current?.urlAlias;
export const selectRoomChatSounds = (state) => state.room.sounds;
export const selectOrderedUniqueChatSounds = createSelector(selectRoomChatSounds, (sounds) =>
  [...sounds]
    .reduce((acc, soundData) => {
      if (!acc.includes(soundData.trigger)) {
        return [...acc, soundData];
      }

      return acc;
    }, [])
    .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
);
export const selectRoomGroupId = createSelector(selectRoomData, (roomData) => roomData?.groupId);

export const selectAreSettingsOpened = (state) => state.room.settings.isOpened;
export const selectCurrentSettingsTabName = (state) => state.room.settings.currentTab;

export const selectCurrentTheme = (state) => state.room.theme.current;
export const selectCurrentThemeColors = (state) => state.room.theme.current?.colors;
export const selectCurrentThemePrimaryFont = (state) => state.room.theme.current?.primaryFont || ROOM_DEFAULT_FONT;
export const selectAvailableThemes = (state) => state.room.theme.availableList;
export const selectAvailableNonCustomThemes = createSelector(selectAvailableThemes, (themesList) =>
  themesList.filter((theme) => !theme.isCustom)
);
export const selectDefaultTheme = createSelector(selectAvailableThemes, (themesList) =>
  themesList.find((theme) => theme.isDefault)
);
export const selectIsCurrentThemeCustom = createSelector(
  selectCurrentTheme,
  selectAvailableThemes,
  (currentTheme, themesList) => isThemeCustom(currentTheme, themesList)
);
export const selectSelectedThemeName = createSelector(
  selectIsCurrentThemeCustom,
  selectCurrentThemeColors,
  selectAvailableThemes,
  (isCustom, colors, themesList) => getThemeName(isCustom, colors, themesList)
);
