import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Offcanvas from '../components/Offcanvas';
import { useBoardData } from '../hooks/useBoardData';
import RoomSettingsHeader from '../room/settings/Header';
import Portal from '../components/Portal';

import '../../components/color-select';
import '../../components/room-settings/background';

const CardEditBackground = ({ isOpened, cardId, onClose, onBackgroundUpload }) => {
  const ref = useRef(null);
  const boardData = useBoardData(cardId);

  useEffect(() => {
    ref.current.onBoardUpdate(boardData);
  }, [boardData]);

  useEffect(() => {
    const input = ref.current.querySelector('#background-upload-input');
    const listener = (e) => {
      e.stopPropagation();
      if (e.target.files && e.target.files[0]) {
        onBackgroundUpload(e.target.files[0]);
        e.target.value = null;
      }
    };
    input.addEventListener('change', listener, true);
    return () => input.removeEventListener('change', listener, true);
  }, [onBackgroundUpload]);

  return (
    <Portal root={document.body} className="unthemed">
      <OutsideContainer className="prevent-profile-closing">
        <Offcanvas isOpened={isOpened} width={380} fullscreenBreakpoint={400} onClose={onClose} hasOverlay={false}>
          <Container>
            <RoomSettingsHeader label="Background" onClose={onClose} hasBackButton={false} />
            <Content onChangeCapture={onBackgroundUpload}>
              <here-room-details boardId={cardId} ref={ref} />
            </Content>
          </Container>
        </Offcanvas>
      </OutsideContainer>
    </Portal>
  );
};

export default CardEditBackground;

CardEditBackground.propTypes = {
  cardId: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackgroundUpload: PropTypes.func.isRequired,
};

const OutsideContainer = styled.div`
  position: relative;
  z-index: 999999;
`;

const Container = styled.div`
  background: rgba(255, 255, 255, 0.9);
  height: 100%;

  .background-group .search-results {
    max-height: calc(100vh - 150px);

    .image-container {
      height: 184px;
    }
  }

  .done-container {
    display: none !important;
  }
`;

const Content = styled.div`
  padding: 16px 8px;
`;
