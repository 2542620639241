import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function Wiggle({ children, time, delay }) {
  return (
    <WiggleAnimationWrapper time={time} delay={delay}>
      {children}
    </WiggleAnimationWrapper>
  );
}

Wiggle.propTypes = {
  children: PropTypes.node.isRequired,
  time: PropTypes.number,
  delay: PropTypes.number,
};

Wiggle.defaultProps = {
  time: 0.4,
  delay: 0,
};

const wiggleAnimation = css`
  @keyframes wiggle-animation {
    0% {
      transform: rotate(0deg);
    }

    10% {
      transform: rotate(-3deg);
    }

    20% {
      transform: rotate(0deg);
    }

    30% {
      transform: rotate(3deg);
    }

    40% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(0deg);
    }

    60% {
      transform: rotate(0deg);
    }

    70% {
      transform: rotate(0deg);
    }

    80% {
      transform: rotate(-3deg);
    }

    90% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(3deg);
    }
  }
`;

const WiggleAnimationWrapper = styled.div`
  ${wiggleAnimation}

  animation: ${({ time }) => `wiggle-animation ${time}s ease-in-out infinite`};
  animation-delay: ${({ delay }) => `${delay}s`};
`;

export default Wiggle;
