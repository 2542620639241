import React, { useEffect, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useUserProfileData } from '../../../hooks/useUserProfileData';
import { Mood } from '../../../../definitions/user-profile';
import { hideableMixin } from '../../../mixins';
import { MoodContainer, MoodContent } from '../shared-styles';
import UserMood from '..';
import { openUserProfile } from '../../utils';
import { selectCurrentUserId } from '../../../store/users/selectors';

interface MoodChatHeadProps {
  userId: string;
}

const MoodChatHead = ({ userId }: MoodChatHeadProps) => {
  const currentUserId = useSelector(selectCurrentUserId);

  const { userProfile } = useUserProfileData(userId) as {
    userProfile: { mood: Mood };
    loading: boolean;
  };

  const onAddMoodClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target as Element;
    const coordinates = target.getBoundingClientRect();
    const topPosition = coordinates.y;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const leftPosition = coordinates.x + target.offsetWidth + 20;
    openUserProfile({ userId, topPosition, leftPosition, editUserMood: true });
  };

  const [showMoodOnLoad, setShowMoodOnLoad] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMoodOnLoad(false);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container isHiddenDisplay={!showMoodOnLoad}>
      {userProfile?.mood?.text ? <UserMood mood={userProfile?.mood} canEdit={false} /> : null}

      {!userProfile?.mood?.text && userId === currentUserId ? (
        <AddMoodButton onClick={onAddMoodClick}>+ Add Mood</AddMoodButton>
      ) : null}
    </Container>
  );
};

export default MoodChatHead;

const Container = styled.div<{ isHiddenDisplay: boolean }>`
  ${hideableMixin()}
  animation-duration: 0.25s;
  animation-name: slideup;

  ${MoodContainer} {
    margin: 0;
  }

  ${MoodContent} {
    border-radius: 25px;
    margin: 0;
    max-width: 600px;
  }

  .boardElement:hover & {
    display: block !important;
  }
`;

const AddMoodButton = styled.button`
  margin-bottom: -20px;
  padding: 10px 20px;
  max-width: 600px;
  background-color: #ffffff77;
  border: none;
  border-radius: 25px;
  cursor: pointer;
`;
