import React from 'react';
import PropTypes from 'prop-types';

const PlayIcon = ({ color, width, height, opacity }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity={opacity} d="M27.5227 16.2686L2.46481 1.05356C2.25375 0.925406 2.01214 0.85543 1.76483 0.850828C1.51752 0.846227 1.27345 0.907168 1.05771 1.02738C0.841965 1.14759 0.662352 1.32274 0.537342 1.5348C0.412331 1.74687 0.346436 1.98819 0.346436 2.23395V32.6641C0.346436 32.9098 0.412331 33.1512 0.537342 33.3632C0.662352 33.5753 0.841965 33.7504 1.05771 33.8707C1.27345 33.9909 1.51752 34.0518 1.76483 34.0472C2.01214 34.0426 2.25375 33.9726 2.46481 33.8445L27.5227 18.6294C27.7262 18.5058 27.8944 18.3324 28.011 18.1257C28.1277 17.919 28.189 17.686 28.189 17.449C28.189 17.212 28.1277 16.979 28.011 16.7723C27.8944 16.5656 27.7262 16.3922 27.5227 16.2686Z" fill={color} />
  </svg>
);

export default PlayIcon;

PlayIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  opacity: PropTypes.string,
};

PlayIcon.defaultProps = {
  width: '29',
  height: '35',
  color: 'white',
  opacity: '1.0',
};
