import React from 'react';
import { useSelector } from 'react-redux';

// store
import { TipsAndTricks } from '../store/tips-and-tricks/init-state';
import { selectTipsAndTricksList } from '../store/tips-and-tricks/selectors';

// components
import AddFurnitureTip from './AddFurnitureTip';

const tipsAndTricksMap = {
  [TipsAndTricks.ADD_ROOM_DECORATION]: <AddFurnitureTip key={TipsAndTricks.ADD_ROOM_DECORATION} />,
};

const TipsAndTricksManager = () => {
  const tipsAndTricks = useSelector(selectTipsAndTricksList);

  return <>{tipsAndTricks.map((tip) => tipsAndTricksMap[tip])}</>;
};

export default TipsAndTricksManager;
