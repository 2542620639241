import { useState, useEffect } from 'react';

import { addUserCanAddContentSubscriber } from '../../roles-management';

export function useUserCanAddContent() {
  const [userCanAddContent, setUserCanAddContent] = useState(false);

  useEffect(() => {
    addUserCanAddContentSubscriber((userCanAddContentUpdated) => {
      setUserCanAddContent(userCanAddContentUpdated);
    });
  }, []);

  return [userCanAddContent];
}
