import $ from 'jquery';

import log from '../log';
import { track } from '../util/analytics-util';

const HereSFU = {};

HereSFU.hideSnapshot = (uid) => {
  const videoContainer = $(`#video-container-${uid}`);
  if (videoContainer.length === 0) {
    // no container (yet)
    return;
  }
  const canvas = videoContainer.find('.video-snapshot').get(0);
  const video = videoContainer.find('video').get(0);

  if (!video) {
    log.error('Trying to restore video, but there is no video tag!');
    track('Snapshot Hide Without Video Tag');
    return;
  }
  if (!video || (video.videoWidth === 0 && video.videoHeight === 0)) {
    log.error('Trying to restore video, but video not ready (width and height are 0)');
    track('Snapshot Hide Without Video Size');
    return;
  }
  if (canvas.style.display === 'none') {
    // TODO: This block is used to prevent showing last snapshot if a video is off.
    // Need to move all this code to the camera.js with a proper state management.
    return;
  }

  canvas.style.display = 'none';
  if (video) {
    video.style.display = 'block';
  }
};

HereSFU.saveSnapshot = (uid) => {
  const videoContainer = document.getElementById(`video-container-${uid}`);
  if (!videoContainer) {
    return;
  }

  const video = videoContainer.querySelector('video');

  if (!video) {
    log.debug('snapshot: No video in container, bailing');
    return;
  }

  const width = video.videoWidth;
  const height = video.videoHeight;

  if (width && height) {
    const canvas = videoContainer.querySelector('.video-snapshot');
    const context = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);

    canvas.style.width = video.style.width;
    canvas.style.height = video.style.height;
  } else {
    log.warn('snapshot: VideoWidth and height are 0, not capturing video id', video.getAttribute('id'));
  }
};

HereSFU.replaceWithSnapshot = (uid) => {
  const videoContainer = $(`#video-container-${uid}`);
  if (videoContainer.length === 0) {
    log.warn(`snapshot: Failed to replace video - no container found for ${uid}`);
  }

  const video = videoContainer.find('video').get(0);

  if (video === undefined || video.style.display === 'none') {
    log.debug(`snapshot: No video in container for user ${uid}, bailing`);
    return;
  }

  log.debug('Snapshot: Replacing video with snapshot for user: ', uid);
  const canvas = videoContainer.find('.video-snapshot').get(0);

  if (canvas.width > 0 && canvas.height > 0) {
    video.style.display = 'none';
    canvas.style.display = 'block';
  }
};

export const Snapshot = {
  replaceWithSnapshot: HereSFU.replaceWithSnapshot,
  hideSnapshot: HereSFU.hideSnapshot,
  saveSnapshot: HereSFU.saveSnapshot,
};
