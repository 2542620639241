import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUserProfile } from '../../api/user-profiles-api';

// store
import { selectUserById } from '../store/users/selectors';
import { updateUsersFromArray } from '../store/users/store';

const userProfileFetchesMap: { [key: string]: boolean } = {};

export const useUserProfileFromStore = (userId: string) => {
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => selectUserById(state, userId));

  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      if (!userProfile && userProfileFetchesMap[userId] !== true) {
        try {
          userProfileFetchesMap[userId] = true;
          const userProfileDoc = await fetchUserProfile(userId);
          dispatch(updateUsersFromArray({ users: [{ ...userProfileDoc.data(), userId: userProfileDoc.id }] }));
        } catch (e) {
          setError(e);
        }
      }
    })();
  }, [dispatch, userProfile, userId]);

  return { userProfile, error };
};
