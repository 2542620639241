import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  ButtonsContainer,
  CancelButton,
  Description,
  ReportButton,
  StepContainer,
  UserPreviewContainer,
} from '../../shared-styles';
import { BanModalTitle } from '../shared-styles';
import ReportUserPreview from '../../ReportUserPreview';
import { selectUserById } from '../../../../../store/users/selectors';
import { BanIcon } from '../../../../../icons';
import { svgColorMixin } from '../../../../../mixins';
import { banMember } from '../../../../../store/messaging/actions';

interface BanStepProps {
  userId: string;
  chatId: string;
  next: () => void;
  close: () => void;
}

const BanStep = ({ userId, chatId, next, close }: BanStepProps) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => selectUserById(state, userId));

  const onBan = () => {
    dispatch(banMember({ chatId, memberId: userId }));
    next();
  };

  return (
    <StepContainer>
      <BanModalTitle>
        <BanIconContainer>
          <BanIcon />
        </BanIconContainer>
        Ban User from this Chat
      </BanModalTitle>

      <UserPreviewContainer>
        <ReportUserPreview userId={userId} displayName={user.displayName} username={user.username} />
      </UserPreviewContainer>

      <BanDescription>
        Is this user ruining your Here experience? <br />
        Banning them will ensure they cannot rejoin the chat.
      </BanDescription>

      <ButtonsContainer>
        <CancelButton onClick={close}>Never Mind</CancelButton>
        <ReportButton onClick={onBan}>Ban User</ReportButton>
      </ButtonsContainer>
    </StepContainer>
  );
};

const BanIconContainer = styled.div`
  width: 22px;

  ${svgColorMixin('#f6335d')}
`;

const BanDescription = styled(Description)`
  text-align: center;
`;

export default BanStep;
