import { BoardElementEnum } from './BoardElementEnum';

export const DEFAULT_TAG_IMAGE_MAP = {
  [BoardElementEnum.OTHER]:
    'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/tags%2FSomething.gif?alt=media&token=26d81567-cee5-4a67-b97b-ea216d3c1074',
  [BoardElementEnum.VIDEO]:
    'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/tags%2FVideo.gif?alt=media&token=dd9a808a-3fec-447f-9bcf-5100e790b5e7',
  [BoardElementEnum.GIF]:
    'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/tags%2FGIF.gif?alt=media&token=a67efee2-f17a-4cda-bff2-958022ba7e51',
  [BoardElementEnum.IMAGE]:
    'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/tags%2FImage.gif?alt=media&token=03477c9d-5a60-4ec7-a0ff-16d088c0bbf6',
  [BoardElementEnum.DRAWING]:
    'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/tags%2FDrawing.gif?alt=media&token=af987cf1-6318-4ed5-bc7b-a3bc97c2656d',
  [BoardElementEnum.NOTE]:
    'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/tags%2FNote.gif?alt=media&token=ea2136f8-f46b-4d7f-9fba-5723b8e8380a',
  [BoardElementEnum.GAME]:
    'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/tags%2FGame.gif?alt=media&token=ffd6a19d-e961-43e0-85bc-fd095be46432',
};
