import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import WaveIcon from '../../../assets/icons/wave.svg';

import '../../components/logo-effects';

const links = {
  FAQ: 'https://help.here.fm/hc/en-us',
  iOS: 'https://apps.apple.com/tr/app/here-talk-text-watch/id1541854332',
  Android: 'https://play.google.com/store/apps/details?id=fm.here.android',
  Desktop: 'https://here.fm/desktop',
  Discord: 'https://discord.com/invite/kDyGzQT',
};

const DEFAULT_ONLINE = 50.2;
const ONLINE_RANGE = 2;
const CHANGE_INTERVAL_MS = 3200;

const getRandomOnline = () => DEFAULT_ONLINE + ONLINE_RANGE * (Math.random() * 2 - 1);

const NewHomePage = () => {
  const [online, setOnline] = useState(getRandomOnline);

  useEffect(() => {
    setInterval(() => setOnline(getRandomOnline()), CHANGE_INTERVAL_MS);
  }, []);

  return (
    <Container>
      <VideoBackground
        playsInline
        autoPlay
        muted
        loop
        poster="https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/homepage%2Fhomepage_actual_vid_preview.jpg?alt=media&token=6f5453d4-be51-4005-a2c5-982c9eb20fa2"
      >
        <source
          src="https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/homepage%2Fhomepage_actual_vid.mp4?alt=media&token=09ef34c9-9c2f-4bef-a9fc-37935d879fd2"
          type="video/mp4"
        />
      </VideoBackground>
      <VideoOverlay />
      <BottomOverlay />
      <LogoContainer>
        <here-logo-effects />
      </LogoContainer>
      <LinksContainer>
        {Object.keys(links).map((title) => (
          <Link key={title} href={links[title]} target="_blank">
            {title}
          </Link>
        ))}
      </LinksContainer>
      <MainElement>
        <Title>F*** around with your BFFs while watching videos together</Title>
        <ButtonsContainer>
          <SignInButton
            onClick={useCallback(() => window.authCreateRoom(null, 'auth-type-selection-screen'), [])}
            className="here-button-minimal"
          >
            Log In
          </SignInButton>
          <Button
            onClick={useCallback(() => window.authCreateRoom(null, 'auth-type-selection-screen-get-started'), [])}
            className="here-button-regular"
          >
            Sign Up
          </Button>
        </ButtonsContainer>
      </MainElement>
      <AnalyticsContainer>
        <AnalyticsItem>
          <AnalyticsTitle>{online.toFixed(1)}K</AnalyticsTitle>
          <AnalyticsSubtitle>
            <OnlineDot>•&nbsp;</OnlineDot>online now
          </AnalyticsSubtitle>
        </AnalyticsItem>
        <WaveIcon />
        <AnalyticsItem>
          <AnalyticsTitle>102M</AnalyticsTitle>
          <AnalyticsSubtitle>total minutes spent</AnalyticsSubtitle>
        </AnalyticsItem>
        <WaveIcon />
        <AnalyticsItem>
          <AnalyticsTitle>30</AnalyticsTitle>
          <AnalyticsSubtitle>seconds to join</AnalyticsSubtitle>
        </AnalyticsItem>
      </AnalyticsContainer>
    </Container>
  );
};

export default NewHomePage;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const VideoBackground = styled.video`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const VideoOverlay = styled(Container)`
  background: #12002d;
  opacity: 0.4;

  @media (min-width: 681px) {
    display: none;
  }
`;

const BottomOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  background: linear-gradient(179.43deg, rgba(18, 0, 45, 0) 0.5%, rgba(18, 0, 45, 0.69) 99.5%);
  opacity: 0.75;

  @media (max-width: 680px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 135px;
`;

const LinksContainer = styled.div`
  position: absolute;
  top: 36px;
  right: 20px;
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
  margin-left: 16px;

  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

const MainElement = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 960px;
  width: 80%;
  height: 372px;
  border-radius: 50%;
  background: rgba(18, 0, 45, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 680px) {
    background: none;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  width: 60%;

  @media (max-width: 840px) {
    font-size: 30px;
    width: 80%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 840px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  height: 70px;
  font-weight: bold;
  margin: 0 12px;
  color: white;
  padding: 0 50px;
  font-size: 30px;

  &,
  &:hover {
    border: 1px solid white;
  }

  @media (max-width: 840px) {
    font-size: 24px;
    height: 56px;
    margin: 12px 0;
  }
`;

const SignInButton = styled(Button)`
  background: rgba(255, 255, 255, 0.2);

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;

const AnalyticsContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const AnalyticsItem = styled.div`
  color: white;
  margin: 0 50px;
  font-weight: bold;
  text-align: center;
  width: 110px;
  flex: 0 1 210px;

  @media (max-width: 680px) {
    margin: 0 20px;
  }
`;

const AnalyticsTitle = styled.div`
  font-size: 50px;

  @media (max-width: 680px) {
    font-size: 30px;
  }
`;

const AnalyticsSubtitle = styled.div`
  font-size: 16px;

  @media (max-width: 680px) {
    font-size: 12px;
  }
`;

const OnlineDot = styled.span`
  color: #3ce16b;
`;
