// Redux adapter for non-react code to avoid dependency cycles
// (since it doesn't depend on anything).

class ReduxAdapter {
  constructor() {
    this.listeners = [];
    this.latestState = null;
  }

  /**
   * Callback will be also called fired one time right after subscription
   * with the current state.
   */
  subscribe(callback) {
    this.listeners.push(callback);
    if (this.latestState) {
      callback(this.latestState);
    }
  }

  unsubscribe(callback) {
    this.listeners = this.listeners.filter((f) => f !== callback);
  }

  setDispatchingHandler(dispatchingHandler) {
    this.dispatchAction = dispatchingHandler;
  }

  dispatchAction() {
    throw new Error('dispatchAction was not initialized');
  }

  dispatchStateChange(state) {
    this.latestState = state;
    this.listeners.forEach((f) => f(state));
  }
}

export default new ReduxAdapter();
