export const ROOM_SETTINGS_TABS = {
  details: 'Room Details',
  background: 'Background',
  theme: 'Theme',
  members: 'Members',
  chat: 'Chat',
  chatSounds: 'Chat Sounds',
  notifications: 'Notifications',
  help: 'Help',
};

export const ROOM_DEFAULT_FONT = 'Inter';

// Fields that we do not trigger `roomDataUpdated` action. They are either not important,
// or there is a dedicated action for the update.
export const ROOM_DATA_IGNORED_FIELDS = ['updatedAt', 'push', 'feedItemsUpdatedAt', 'theme'];

export const ROOM_LARGE_SIZE = 8000;
export const ROOM_SIZES = {
  '∞': null,
  'S': [-ROOM_LARGE_SIZE / 8, -ROOM_LARGE_SIZE / 8, ROOM_LARGE_SIZE / 4, ROOM_LARGE_SIZE / 4],
  'M': [-ROOM_LARGE_SIZE / 4, -ROOM_LARGE_SIZE / 4, ROOM_LARGE_SIZE / 2, ROOM_LARGE_SIZE / 2],
  'L': [-ROOM_LARGE_SIZE / 2, -ROOM_LARGE_SIZE / 2, ROOM_LARGE_SIZE, ROOM_LARGE_SIZE],
};
