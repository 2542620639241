import React from 'react';
import ReactDOM from 'react-dom';

// components
import HereModal from '../../../../components/HereModal';

// hooks
import { useDomElement } from '../../../../hooks/useDomElement';

const newModalRoot = document.createElement('div');
newModalRoot.id = 'upload-sound-modal';

interface UploadSoundModalProps {
  onOverlayClick: () => void;
}

const ChatSoundModal = ({ children, onOverlayClick }: React.PropsWithChildren<UploadSoundModalProps>) => {
  const modalRoot = useDomElement({
    parentSelector: 'body',
    element: newModalRoot,
  });

  if (!modalRoot) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ReactDOM.createPortal(<HereModal onOverlayClick={onOverlayClick}>{children}</HereModal>, modalRoot);
};

export default ChatSoundModal;
