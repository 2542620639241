/* Everything supporting movement around the canvas */

import $ from 'jquery';
import Impetus from 'impetus';
import Hammer from 'hammerjs';
import CodeMirror from 'codemirror';
import fastdom from 'fastdom';
import firebase, { db } from './firebase';
import log from './log';
import {
  screenDrawingContext,
  screenDrawingCanvas,
  drawScreenLines,
  startDrawing,
  penUp,
  penMove,
  DrawCursorStyle,
} from './drawing';
import { boardUsers } from './presence';
import minimap from './minimap';
import { cameraHandMove } from './camera-hand';
import { onIdle, clamp, getQueryStringValue, boardElementForEvent } from './util';
import { checkIsMobile } from './util/platform-util';
import { getElementPosition, applyElementTransformFromPartialData } from './element-transform';
import { updateWayfinders } from './wayfinders';
import { updateSpatialAudio } from './spatial-audio';
import { isElementsInteractionAllowed } from './roles-management';
import bus, { canvasScaleUpdated } from './event-bus';
import { checkBarriers } from './util/barrier-util';
import { canvasToScreenCoords, screenToCanvasCoords, screenToCustomCanvasCoords } from './util/canvas-util';
import { getCurrentBoardIsUserCard } from './util/room-util';
import { USER_CARD_HEIGHT_PX, USER_CARD_WIDTH_PX } from './constants/user-card-constants';
import { getOS } from './util/device-util';
import { OS_TYPES } from './constants/device-constants';
import { MAC_BINARY_URL, WINDOWS_BINARY_URL } from './util/electron-util';
import { track } from './util/analytics-util';

// Open canvas movement
window.canvasOffsetX = 0;
window.canvasOffsetY = 0;
window.canvasScale = 1.0;
const MaxScale = 3.0;
const MinScale = 0.2;

let zoom = null;

window.userLocationUpdateTimer = null;

let idleCallback = null;
let needsVolumeUpdate = false;

let lastPanTime = null;

export function moveCameraToScreen(x, y, width, height) {
  const padding = 10; // How much space do we allow between the camera and the edge?
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;

  const cameraScreenCoords = canvasToScreenCoords(x, y);
  let camScreenX = cameraScreenCoords[0];
  let camScreenY = cameraScreenCoords[1];

  let needsUpdate = false;
  if (camScreenX < padding) {
    needsUpdate = true;
    camScreenX = padding;
  }
  if (camScreenY < padding) {
    needsUpdate = true;
    camScreenY = padding;
  }
  if (camScreenX + width * window.canvasScale + padding > winWidth) {
    needsUpdate = true;
    camScreenX = winWidth - width * window.canvasScale - padding;
  }
  if (camScreenY + height * window.canvasScale + padding > winHeight) {
    needsUpdate = true;
    camScreenY = winHeight - height * window.canvasScale - padding;
  }

  if (needsUpdate) {
    let newX = (camScreenX - winWidth / 2) / window.canvasScale - window.canvasOffsetX + winWidth / 2;
    let newY = (camScreenY - winHeight / 2) / window.canvasScale - window.canvasOffsetY + winHeight / 2;
    const newPos = checkBarriers({ x, y, w: width, h: height }, { x: newX, y: newY, w: width, h: height });
    newX = newPos.x;
    newY = newPos.y;
    return {
      x: newX,
      y: newY,
    };
  }

  return null;
}

const setupDownloadDesktopAppButtons = () => {
  const os = getOS();
  switch (os) {
    case OS_TYPES.mac: {
      const downloadMacButton = document.getElementById('download-mac-desktop-button');
      downloadMacButton.classList.remove('hidden');
      downloadMacButton.href = MAC_BINARY_URL;
      downloadMacButton.onclick = () => {
        track('Download App', { platform: 'Mac', source: 'homepage' });
      };
      break;
    }

    case OS_TYPES.win: {
      const downloadWinButton = document.getElementById('download-win-desktop-button');
      downloadWinButton.classList.remove('hidden');
      downloadWinButton.href = WINDOWS_BINARY_URL;
      downloadWinButton.onclick = () => {
        track('Download App', { platform: 'Windows', source: 'homepage' });
      };
      break;
    }

    default: {
      // do nothing -- don't suggest downloading desktop app for other OS
    }
  }
};

let isCanvasPositionInitiated = false;
export function updateCanvasPosition(
  x,
  y,
  scale = window.canvasScale,
  { isAnimated = false, shouldCheckBorders = true } = {}
) {
  if (getCurrentBoardIsUserCard()) {
    if (isCanvasPositionInitiated) return false;
    // It's always fixed for user card
    window.canvasOffsetX = USER_CARD_WIDTH_PX / 2;
    window.canvasOffsetY = USER_CARD_HEIGHT_PX / 2;
    isCanvasPositionInitiated = true;
    return true;
  }

  const { borders } = window.currentBoardData || {};
  const { innerWidth, innerHeight } = window;
  if (borders) {
    window.impetus.setBoundX([
      ((innerWidth * (scale + 1)) / 2 / scale - borders[0] - borders[2]) * scale,
      ((innerWidth * (scale - 1)) / 2 / scale - borders[0]) * scale,
    ]);
    window.impetus.setBoundY([
      ((innerHeight * (scale + 1)) / 2 / scale - borders[1] - borders[3]) * scale,
      ((innerHeight * (scale - 1)) / 2 / scale - borders[1]) * scale,
    ]);
  } else {
    window.impetus.setBoundX([-Infinity, Infinity]);
    window.impetus.setBoundY([-Infinity, Infinity]);
  }

  if (shouldCheckBorders && borders) {
    // If new viewport is larger than the borders - don't even need to check anything,
    // otherwise it may result in glitching
    if (innerWidth / scale >= borders[2] || innerHeight / scale >= borders[3]) {
      return false;
    }

    const bordersCheckResult = isViewportInBorders({
      canvasOffsetX: x,
      canvasOffsetY: y,
      canvasScale: scale,
    });

    x = bordersCheckResult.xAxis ? parseFloat(x) : bordersCheckResult.xAllowedValue;
    y = bordersCheckResult.yAxis ? parseFloat(y) : bordersCheckResult.yAllowedValue;
    scale = bordersCheckResult.xAxis && bordersCheckResult.yAxis ? scale : window.canvasScale;
  } else {
    x = parseFloat(x);
    y = parseFloat(y);
  }

  if (Number.isNaN(x) || Number.isNaN(y)) {
    throw new Error(`Trying to set invalid canvas position ${x}, ${y}`);
  }

  window.canvasOffsetX = x;
  window.canvasOffsetY = y;

  const matrix = [
    scale,
    0,
    0,
    scale,
    window.canvasOffsetX * scale - window.innerWidth / 2,
    window.canvasOffsetY * scale - window.innerHeight / 2,
  ];
  const transition = isAnimated ? 'transform 0.3s' : '';
  window.impetus.setValues(window.canvasOffsetX * scale, window.canvasOffsetY * scale);

  // update hover styles so they maintain normal relative size
  const elementsToMaintainSize = document.querySelectorAll('.maintain-size-in-viewport');
  const scaleTransform = `scale3d(${1 / scale}, ${1 / scale}, 1)`;
  elementsToMaintainSize.forEach((element) => {
    // use scale3d instead of scale to delegate painting work to the GPU
    fastdom.mutate(() => {
      element.style.transform = scaleTransform;
    });
  });

  if (window.currentBoardData.backgroundScroll === 'scroll' && !isAnimated && !getCurrentBoardIsUserCard()) {
    const bgImageWidth = window.currentBoardData.backgroundWidth || 1000;
    const main = document.getElementById('main');
    fastdom.mutate(() => {
      main.style.backgroundSize = `${scale * bgImageWidth}px`;
      const pos = canvasToScreenCoords(-bgImageWidth / 2, 0);
      main.style.backgroundPosition = `${pos[0]}px ${pos[1]}px`;
    });
  }

  $('#elements').css({
    transition,
    'transition-timing-function': 'ease-in',
    'transform': `matrix(${matrix.join(',')})`,
  });

  // update and possibly animate zoom of canvas
  if (isAnimated) {
    $('#pen-canvas').css({
      transition,
      'transition-timing-function': 'ease-in',
      'transform': `scale(${scale / window.canvasScale},${scale / window.canvasScale})`,
    });

    setTimeout(() => {
      // Update drawing overlay
      if (screenDrawingContext !== undefined) {
        screenDrawingContext.clearRect(0, 0, screenDrawingCanvas.width, screenDrawingCanvas.height);
        drawScreenLines();
      }

      $('#pen-canvas').css({
        transition: '',
        transform: 'scale(1,1)',
      });
    }, 300);
  } else if (screenDrawingContext !== undefined) {
    // Update drawing overlay
    screenDrawingContext.clearRect(0, 0, screenDrawingCanvas.width, screenDrawingCanvas.height);
    drawScreenLines();
  }

  if (scale !== window.canvasScale) {
    CodeMirror.setDisplayScale(scale);
    /* $('.CodeMirror-cursors').css({
      transform: `scale(${1 / canvasScale})`,
      transformOrigin: '0 0',
    }); */

    Object.values(window.elementHandlers).forEach((handler) => {
      if (handler.constructor.elementType === 'TextElement') {
        handler.refresh();
      }
    });

    bus.dispatch(canvasScaleUpdated, scale);
  }

  // Manually animate background because we can't use a css transition :(
  if (isAnimated && window.currentBoardData.backgroundScroll === 'scroll' && !getCurrentBoardIsUserCard()) {
    let i = 0;
    const frames = 10;
    const increment = (scale - window.canvasScale) / frames;
    const main = document.getElementById('main');
    let s = window.canvasScale;

    const bgImageWidth = window.currentBoardData.backgroundWidth || 1000;
    clearInterval(zoom);
    zoom = setInterval(() => {
      fastdom.mutate(() => {
        main.style.backgroundSize = `${s * bgImageWidth}px`;
        const pos = canvasToScreenCoords(-bgImageWidth / 2, 0, s);
        main.style.backgroundPosition = `${pos[0]}px ${pos[1]}px`;
      });
      s += increment;
      i += 1;
      if (i >= frames) {
        clearInterval(zoom);
      }
    }, 300 / frames);
  }

  window.canvasScale = scale;

  minimap.setNeedsUpdate();

  // Move your own camera to keep it in the frame
  if (window.userCameraId !== null) {
    const camera = document.getElementById(window.userCameraId);
    if (camera !== undefined && camera !== null) {
      const [xPos, yPos] = getElementPosition(camera);
      const width = parseFloat(camera.style.width);
      const height = parseFloat(camera.style.height);
      const newCoords = moveCameraToScreen(xPos, yPos, width, height);

      if (newCoords) {
        applyElementTransformFromPartialData(camera, { center: [newCoords.x, newCoords.y] });
        window.rtc.sendElementMoved(camera.id, newCoords.x, newCoords.y);
        if (window.userLocationUpdateTimer) {
          clearTimeout(window.userLocationUpdateTimer);
          window.userLocationUpdateTimer = null;
        }

        window.userLocationUpdateTimer = setTimeout(() => {
          if (window.userLocationUpdateTimer != null) {
            const cameraPath = $(`#${window.userCameraId}`).attr('docPath');
            if (cameraPath !== undefined && newCoords) {
              db.doc(cameraPath)
                .update({ center: [newCoords.x, newCoords.y] })
                .catch((error) => {
                  // The document probably doesn't exist.
                  log.error('Error updating camera with new position: ', error);
                });
            }
          }
        }, 5000);
      }
    }
  }

  needsVolumeUpdate = true;
  if (!idleCallback) {
    idleCallback = onIdle(
      () => {
        updateWayfinders();

        if (needsVolumeUpdate) {
          updateSpatialAudio();
          needsVolumeUpdate = false;
        }
        idleCallback = null;
      },
      { timeout: 500 }
    );
  }

  if (
    !firebase.auth().currentUser &&
    !getQueryStringValue('lp') && // Specific landing pages don't get this bar
    (window.canvasOffsetX !== window.innerWidth / 2 || window.canvasOffsetY !== window.innerHeight / 2) &&
    document.getElementById('welcome-bar-buttons').classList.contains('hidden') &&
    window.location.pathname === '/' // we want to setup the homepage only on the root url
  ) {
    setupDownloadDesktopAppButtons();
    document.getElementById('welcome-bar-buttons').classList.remove('hidden');
    document.getElementById('welcome-bar-buttons').classList.add('active');
    document.getElementById('welcome-bar').classList.add('active');

    // prevent zooming on the welcome bar
    document.body.style.pointerEvents = 'none';

    // prevent scrolling where pointer events are enabled
    document.getElementById('welcome-bar-buttons').addEventListener('wheel', (e) => {
      e.stopPropagation();
      e.preventDefault();
    });

    document.querySelector('.homepage-here-logo').style.display = 'block';
    document.querySelector('.get-the-mobile-app-logo').style.display = 'block';
  }

  return true;
}

function isViewportInBorders({
  canvasOffsetX,
  canvasOffsetY,
  canvasScale,
  borders = window.currentBoardData?.borders,
}) {
  if (!borders) {
    return { xAxis: true, yAxis: true, xAllowedValue: canvasOffsetX, yAllowedValue: canvasOffsetY };
  }

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const [borderX, borderY, borderWidth, borderHeight] = borders;
  const windowTopLeft = screenToCustomCanvasCoords({ x: 0, y: 0, canvasOffsetX, canvasOffsetY, canvasScale });
  const windowBottomRight = screenToCustomCanvasCoords({
    x: windowWidth,
    y: windowHeight,
    canvasOffsetX,
    canvasOffsetY,
    canvasScale,
  });

  let xAxis = true;
  let yAxis = true;
  let xAllowedValue;
  let yAllowedValue;
  if (windowTopLeft[0] < borderX) {
    xAxis = false;
    xAllowedValue = (windowWidth * (canvasScale - 1)) / 2 / canvasScale - borderX;
  }
  if (windowBottomRight[0] > borderX + borderWidth) {
    xAxis = false;
    xAllowedValue = (windowWidth * (canvasScale + 1)) / 2 / canvasScale - borderX - borderWidth;
  }
  if (windowTopLeft[1] < borderY) {
    yAxis = false;
    yAllowedValue = (windowHeight * (canvasScale - 1)) / 2 / canvasScale - borderY;
  }
  if (windowBottomRight[1] > borderY + borderHeight) {
    yAxis = false;
    yAllowedValue = (windowHeight * (canvasScale + 1)) / 2 / canvasScale - borderY - borderHeight;
  }

  return { xAxis, yAxis, xAllowedValue, yAllowedValue };
}

export function isRectangleInBorders(rectangle, borders) {
  return (
    rectangle[0] > borders[0] &&
    rectangle[1] > borders[1] &&
    rectangle[0] + rectangle[2] < borders[0] + borders[2] &&
    rectangle[1] + rectangle[3] < borders[1] + borders[3]
  );
}

export function isObjectInBorders({ x, y, w, h }) {
  if (!window.currentBoardData.borders) {
    return { xAxis: true, yAxis: true, xAllowedValue: x, yAllowedValue: y };
  }

  const [borderX, borderY, borderWidth, borderHeight] = window.currentBoardData.borders;

  let xAxis = true;
  let yAxis = true;
  let xAllowedValue = x;
  let yAllowedValue = y;
  if (x < borderX) {
    xAxis = false;
    xAllowedValue = borderX;
  }
  if (x + w > borderX + borderWidth) {
    xAxis = false;
    xAllowedValue = borderX + borderWidth - w;
  }
  if (y < borderY) {
    yAxis = false;
    yAllowedValue = borderY;
  }
  if (y + h > borderY + borderHeight) {
    yAxis = false;
    yAllowedValue = borderY + borderHeight - h;
  }

  return { xAxis, yAxis, xAllowedValue, yAllowedValue };
}

function getRectOutCanvasOffsets(x, y, w, h) {
  const [screenL, screenT] = screenToCanvasCoords(0, 0);
  const [screenR, screenB] = screenToCanvasCoords(window.innerWidth, window.innerHeight);
  const [rectL, rectT] = [x, y];
  const [rectR, rectB] = [x + w, y + h];

  let ofsX = 0;
  let ofsY = 0;

  if (screenL > rectL) {
    ofsX = screenL - rectL;
  } else if (screenR < rectR) {
    ofsX = screenR - rectR;
  }

  if (screenT > rectT) {
    ofsY = screenT - rectT;
  } else if (screenB < rectB) {
    ofsY = screenB - rectB;
  }

  return { ofsX, ofsY };
}

export function scrollCanvasToRect(x, y, w, h) {
  const { ofsX, ofsY } = getRectOutCanvasOffsets(x, y, w, h);
  updateCanvasPosition(window.canvasOffsetX + ofsX, window.canvasOffsetY + ofsY);
}

let scrollUpdateTimer = null;

/* Stops auto-scrolling from a drag to edge
 */
export const stopEdgeScroll = () => {
  if (scrollUpdateTimer) {
    clearInterval(scrollUpdateTimer);
    scrollUpdateTimer = null;
  }
};

export const ensureRectVisible = (x, y, w, h, onScroll) => {
  stopEdgeScroll();
  // Left, Top, Right, Bottom of screen
  let { ofsX, ofsY } = getRectOutCanvasOffsets(x, y, w, h);

  if (ofsX !== 0 || ofsY !== 0) {
    ofsX = clamp(ofsX, -50, 50);
    ofsY = clamp(ofsY, -50, 50);
    scrollUpdateTimer = setInterval(() => {
      updateCanvasPosition(window.canvasOffsetX + ofsX / 4, window.canvasOffsetY + ofsY / 4, window.canvasScale);
      onScroll(ofsX / 4, ofsY / 4);
    }, 10);
  }
};

// Takes a canvas X/Y/Width/Height rectangle and ensures it's visible
export const updateViewableRectangle = (x, y, width, height) => {
  // Cancel location update -- is this the right place to do it?
  if (window.userLocationUpdateTimer) {
    clearTimeout(window.userLocationUpdateTimer);
    window.userLocationUpdateTimer = null;
  }

  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const scaleX = winWidth / width;
  const scaleY = winHeight / height;
  const scale = Math.min(scaleX, scaleY);
  const centerX = x + width / 2;
  const centerY = y + height / 2;
  return updateCanvasPosition(winWidth / 2 - centerX, winHeight / 2 - centerY, scale, { isAnimated: true });
};

/**
 *
 * @param {Object} viewport { x, y, w, h } map for the viewable rectangle to move to.
 */
export const moveToViewport = (viewport) =>
  viewport
    ? updateViewableRectangle(viewport.x, viewport.y, viewport.w, viewport.h)
    : updateCanvasPosition(window.innerWidth / 2, window.innerHeight / 2, 1.0, { isAnimated: true });

export const moveToDefaultViewport = () => {
  if (window.currentBoardData?.defaultViewport) {
    moveToViewport({
      x: window.currentBoardData.defaultViewport[0],
      y: window.currentBoardData.defaultViewport[1],
      w: window.currentBoardData.defaultViewport[2],
      h: window.currentBoardData.defaultViewport[3],
    });
  } else {
    moveToViewport(null);
  }
};

const defaultViewport = [-250, -250, 500, 500];
export const getRoomDefaultViewport = (roomData) => {
  if (!roomData) {
    return defaultViewport;
  }

  if (roomData.defaultViewport) {
    return roomData.defaultViewport;
  }

  if (roomData.borders) {
    const [x, y, width, height] = roomData.borders;
    return [x + width / 4, y + height / 4, width / 2, height / 2];
  }

  return defaultViewport;
};

export const moveToMatchBorders = (borders = window.currentBoardData.borders) => {
  if (!borders) return;
  const [borderX, borderY, borderWidth, borderHeight] = borders;

  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const scaleX = winWidth / borderWidth;
  const scaleY = winHeight / borderHeight;
  const scale = Math.max(scaleX, scaleY) * 1.2;
  const centerX = borderX + borderWidth / 2;
  const centerY = borderY + borderHeight / 2;
  updateCanvasPosition(winWidth / 2 - centerX, winHeight / 2 - centerY, scale);
};

export const ensureViewportIsInBorders = (borders) => {
  const checkResult = isViewportInBorders({
    canvasOffsetX: window.canvasOffsetX,
    canvasOffsetY: window.canvasOffsetY,
    canvasScale: window.canvasScale,
    borders,
  });
  if (!checkResult.xAxis || !checkResult.yAxis) {
    moveToMatchBorders(borders);
  }
};

export function handleWheelEvent(e) {
  let posX = window.canvasOffsetX;
  let posY = window.canvasOffsetY;
  let scale = window.canvasScale;

  // Zoom - pinch
  if (e.ctrlKey) {
    // it's so weird that this works.
    e.stopPropagation();
    e.preventDefault();
    // zoom/scale factor
    scale -= e.deltaY * 0.005;
    if (scale > MaxScale) {
      scale = MaxScale;
    } else if (scale < MinScale) {
      scale = MinScale;
    }
  } else {
    const time = new Date();
    // Pan only if a text container doesn't want to scroll
    // ... but don't interrupt a smooth scroll
    if (time - lastPanTime > 100) {
      const target = boardElementForEvent(e);
      if (target != null) {
        if (target.classList.contains('youtube-element')) {
          const listResults = target.querySelector('.player-bottom');
          if (e.target === listResults || listResults.contains(e.target)) return;
          const listResultsSearch = target.querySelector('.player-bottom-search');
          if (e.target === listResultsSearch || listResultsSearch.contains(e.target)) return;
        }
        if (target.classList.contains('sdk-element')) {
          const appContainer = $(target).find('.app-container').get(0);
          if (appContainer && appContainer.scrollHeight > appContainer.clientHeight) {
            return;
          }
        }
        if (target.classList.contains('textCard')) {
          const textContainer = $(target).find('.text-container').get(0);
          const codeMirror = $(target).find('.CodeMirror-sizer').get(0);
          const textarea = $(target).find('textarea').get(0); // TODO: remove after transition to new editor

          if (
            (textContainer && textContainer.scrollHeight > textContainer.clientHeight) ||
            (codeMirror && codeMirror.scrollHeight > codeMirror.clientHeight) ||
            (textarea && textarea.scrollHeight > textarea.clientHeight)
          ) {
            return;
          }
        } else if (target.classList.contains('chat-element') || target.closest('.chat-element')) {
          return;
        } else if (
          e.target.classList.contains('scrollable-board-element') ||
          e.target.closest('.scrollable-board-element')
        ) {
          // TODO: Use this approach in other cases
          return;
        }
      }
    }
    e.stopPropagation();
    e.preventDefault();

    lastPanTime = time;

    // trackpad X and Y positions
    posX -= e.deltaX / window.canvasScale;
    posY -= e.deltaY / window.canvasScale;
  }

  updateCanvasPosition(posX, posY, scale);
}

export function zoomIn() {
  if (window.canvasScale < MaxScale) {
    updateCanvasPosition(window.canvasOffsetX, window.canvasOffsetY, window.canvasScale * 1.2, { isAnimated: true });
    track('Zoom In', { scale: window.canvasScale });
  }
}

export function zoomOut() {
  if (window.canvasScale > MinScale) {
    updateCanvasPosition(window.canvasOffsetX, window.canvasOffsetY, window.canvasScale * 0.8, { isAnimated: true });
    track('Zoom Out', { scale: window.canvasScale });
  }
}

// Monitor mouse movements for canvas stuff
let drawing = false;

export const mouseMoveDraw = (e) => {
  if (!isElementsInteractionAllowed()) return;

  const leftButtonDown = e.buttons === undefined ? e.which === 1 : e.buttons === 1;

  if (e.shiftKey || (leftButtonDown && (drawing || e.target.closest('.whiteboard-canvas')))) {
    window.impetus.pause();

    const penCanvas = document.getElementById('pen-canvas');
    if (!drawing) {
      drawing = true;
      const drawHandler = startDrawing(e);
      if (drawHandler) {
        penCanvas.style.cursor = drawHandler.getCursorStyle();
      } else {
        penCanvas.style.cursor = DrawCursorStyle;
      }
    } else {
      penMove(e);
    }

    if (!penCanvas.classList.contains('pen-down')) {
      penCanvas.classList.add('pen-down');
      penCanvas.style.pointerEvents = 'auto';
    }
  } else if (e.ctrlKey && firebase.auth().currentUser) {
    const location = screenToCanvasCoords(e.clientX, e.clientY);
    window.rtc.sendCameraHand(location[0], location[1]);
    cameraHandMove(firebase.auth().currentUser.uid, location[0], location[1]);
  } else if (drawing) {
    window.impetus.resume();
    penUp();
    drawing = false;
    const penCanvas = document.getElementById('pen-canvas');
    penCanvas.classList.remove('pen-down');
    penCanvas.style.pointerEvents = 'none';
  }

  const x = (e.clientX - window.innerWidth / 2) / window.canvasScale - window.canvasOffsetX + window.innerWidth / 2;
  const y = (e.clientY - window.innerHeight / 2) / window.canvasScale - window.canvasOffsetY + window.innerHeight / 2;
  const users = boardUsers[window.currentBoardId];
  if (firebase.auth().currentUser && users) {
    window.rtc.sendCursorLocation(x, y);
  }
};

export function scrollToElement(element) {
  const [x, y] = getElementPosition(element);
  const ow = parseFloat(element.style.width);
  const oh = parseFloat(element.style.height);
  const canvasX = (window.innerWidth - ow) / 2 - x;
  const canvasY = (window.innerHeight - oh) / 2 - y;
  updateCanvasPosition(canvasX, canvasY, window.canvasScale, { isAnimated: true });
}

// Safari zoom support
let initialZoom = 1.0;

function handleSafariZoom(e) {
  if (e.type === 'gesturestart') {
    initialZoom = window.canvasScale;
  }
  const scale = clamp(initialZoom * e.scale, MinScale, MaxScale);
  e.preventDefault();
  window.canvasScale = scale;
  updateCanvasPosition(window.canvasOffsetX, window.canvasOffsetY, window.canvasScale);
}

(() => {
  // HACK: This is an implicit check about whether we are in a room (in which case these elements exist),
  // Or on another page which got this module through dependency hell. :-/
  if (document.getElementById('main') && document.getElementById('elements')) {
    document.getElementById('main').addEventListener('wheel', handleWheelEvent, { passive: false });
    // Prevent zooming on other UI elements (like toolbars, menus etc.) because it's gonna zoom the page instead.
    window.addEventListener(
      'wheel',
      (e) => {
        if (e.ctrlKey) {
          e.preventDefault();
        }
      },
      { passive: false }
    );

    window.impetus = new Impetus({
      source: document.getElementById('main'),
      update: (x, y) => {
        updateCanvasPosition(x / window.canvasScale, y / window.canvasScale, window.canvasScale, {
          shouldCheckBorders: false,
        });
      },
    });

    let hammer = { on: () => {} };
    if (checkIsMobile()) {
      hammer = new Hammer(document, {});
      hammer.get('pinch').set({ enable: true });
    }

    hammer.on('pinchstart pinch', (e) => {
      if (e.type === 'pinchstart') {
        initialZoom = window.canvasScale;
      }

      const scale = clamp(initialZoom * e.scale, MinScale, MaxScale);
      e.preventDefault();
      window.canvasScale = scale;
      updateCanvasPosition(window.canvasOffsetX, window.canvasOffsetY, window.canvasScale);
    });

    document.addEventListener('gesturestart', handleSafariZoom);
    document.addEventListener('gesturechange', handleSafariZoom);
    document.addEventListener('gestureend', handleSafariZoom);

    // Protect against right-click bug in Impetus
    $('#main').on('contextmenu', (__e) => {
      window.impetus.pause();
      window.impetus.resume();
    });

    $('#main')
      .mousedown(() => {
        $('#main').css('cursor', 'all-scroll');
      })
      .mouseup(() => {
        const main = $('#main');
        main.css('cursor', '');
        main.unbind('mousemove', mouseMoveDraw);
        main.mousemove(mouseMoveDraw);
      })
      .mouseout(() => {
        const main = $('#main');
        main.css('cursor', '');
        main.unbind('mousemove', mouseMoveDraw);
        main.mousemove(mouseMoveDraw);
      });

    window.addEventListener('resize', () => {
      if (window.currentBoardData?.borders) {
        ensureViewportIsInBorders(window.currentBoardData.borders);
      }
    });
  }
})();
