import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectChatSkinningBackgrounds } from '../../../../store/chat-skinning/selectors';
import BackgroundUploadButton from './BackgroundUploadButton';
import { ChatBackgroundSources } from '../../../analytics';
import SkinningItemPicker from '../../skinning/SkinningItemPicker';
import { SkinBackground } from '../../../../store/chat-skinning/init-state';

interface Props {
  onChange: (background: SkinBackground, source: ChatBackgroundSources) => void;
  currentBackgroundUrl: string;
}

const ChatBackgroundPicker = ({ onChange, currentBackgroundUrl }: Props) => {
  const defaultBackgrounds = useSelector(selectChatSkinningBackgrounds);

  const onUpload = useCallback(
    (url) => onChange({ original: url, name: 'custom upload' }, ChatBackgroundSources.UPLOAD),
    [onChange]
  );
  const onDefaultSelect = useCallback((background) => onChange(background, ChatBackgroundSources.DEFAULT), [onChange]);

  const items = useMemo(
    () => [
      {
        id: 'custom',
        name: 'Custom',
        preview: (
          <DefaultBackgroundItem as="div">
            <BackgroundUploadButton currentBackgroundUrl={currentBackgroundUrl} onChange={onUpload} />
          </DefaultBackgroundItem>
        ),
        isSelected: false,
        onSelect: () => {},
      },
      ...defaultBackgrounds.map((b) => ({
        id: b.id,
        name: b.name,
        preview: <DefaultBackgroundItem alt={`${b.name} chat background preview`} src={b.thumbnail} />,
        isSelected: b.original === currentBackgroundUrl || b.thumbnail === currentBackgroundUrl,
        onSelect: () => onDefaultSelect(b),
        onRemove: () => onDefaultSelect(null),
      })),
    ],
    [currentBackgroundUrl, defaultBackgrounds, onDefaultSelect, onUpload]
  );

  return <SkinningItemPicker items={items} />;
};

export default ChatBackgroundPicker;

const DefaultBackgroundItem = styled.img`
  object-fit: cover;
  aspect-ratio: 2 / 3;
  min-width: 100%;
`;
