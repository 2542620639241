import React from 'react';
import styled from 'styled-components';

// components
import { UploadAreaContainer, UploadAreaIconContainer } from '../shared-styles';
import { svgColorMixin } from '../../../../mixins';

// icons
import { SpeakerIcon } from '../../../../icons';
import PlayIcon from '../../../../icons/PlayIcon';
import TrashcanIcon from '../../../../../../assets/icons/trashcan.svg';

interface UploadAreaEditProps {
  fileName: string;
  playSound: () => void;
  deleteUploaded: () => void;
}

const UploadAreaEdit = ({ fileName, playSound, deleteUploaded }: UploadAreaEditProps) => (
  <Container>
    <IconContainer>
      <SpeakerIcon />
    </IconContainer>

    <TextContainer>
      <FileName>{fileName}</FileName>
    </TextContainer>

    <ButtonsContainer>
      <PlayButton onClick={playSound}>
        <PlayIcon />
      </PlayButton>

      <DeleteButton onClick={deleteUploaded}>
        <TrashcanIcon />
      </DeleteButton>
    </ButtonsContainer>
  </Container>
);

const Container = styled(UploadAreaContainer)`
  position: relative;
  justify-content: flex-start;
  background-color: #12002d19;
`;

const IconContainer = styled(UploadAreaIconContainer)`
  ${svgColorMixin('#6b1be3')}
`;

const TextContainer = styled.div``;

const FileName = styled.p`
  color: #12002d;
  font-size: 14px;
  font-weight: bolder;
`;

const ButtonsContainer = styled.div`
  top: 5px;
  right: 5px;
  position: absolute;
  display: flex;
  gap: 5px;
`;

const buttonSizePx = 34;
const Button = styled.button`
  height: ${buttonSizePx}px;
  width: ${buttonSizePx}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 100%;
  cursor: pointer;

  ${svgColorMixin('#12002d')}

  svg {
    opacity: 0.8;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const PlayButton = styled(Button)`
  svg {
    margin-left: 3px;
    width: 14px;
  }
`;

const DeleteButton = styled(Button)`
  svg {
    width: 17px;
  }
`;

export default UploadAreaEdit;
