import React from 'react';
import ReactDOM from 'react-dom';

// components
import { WithRoomStore } from '../store/Wrapper';
import RoomDecorationMenu from './RoomDecorationMenu';

let hasRendered = false;

export const renderRoomDecorationMenu = () => {
  if (!hasRendered) {
    ReactDOM.render(
      <WithRoomStore>
        <RoomDecorationMenu />
      </WithRoomStore>,
      document.getElementById('add-room-decoration-container')
    );

    hasRendered = true;
  }
};
