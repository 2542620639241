import log from '../log';
import { trackInForeground } from '../util/analytics-util';
import eventBus, { networkInformationUpdated } from '../event-bus';
import { checkIsElectronBrowser } from '../util/platform-util';

window.networkInformation = null;

let offlineDate = null;
let lastNetworkInformation = null;
let lastNetworkInformationCB = null;
const RECONNECT_THRESHOLD = 1000;

(() => {
  window.addEventListener('online', () => {
    const dateDiff = offlineDate ? new Date() - offlineDate : null;
    log.warn(`We're back! After ${dateDiff} ms`);
    if (window.rtc) {
      window.rtc.online = 'online';
    }
    if (window.currentBoardId && dateDiff && dateDiff > RECONNECT_THRESHOLD) {
      window.rtc.reconnect();
      trackInForeground('Reconnect After Online');
    }
  });

  window.addEventListener('offline', () => {
    log.warn("Looks like we're offline");
    offlineDate = new Date();
    if (window.rtc) {
      window.rtc.online = 'offline';
    }
  });
})();

function monitorNetworkInformation(updated = false) {
  const updatedStr = updated ? 'Updated' : '';

  if (lastNetworkInformation && lastNetworkInformationCB) {
    lastNetworkInformation.removeEventListener('change', lastNetworkInformationCB);
  }

  lastNetworkInformation = navigator.connection;
  if (!lastNetworkInformation) {
    trackInForeground('Network Information Not Supported');
    return;
  }
  lastNetworkInformationCB = () => {
    monitorNetworkInformation(true);
    eventBus.dispatch(networkInformationUpdated, lastNetworkInformation);
  };

  const { downlink, effectiveType, rtt, saveData, type } = lastNetworkInformation;
  log.debug(`Network Information ${updatedStr} ${JSON.stringify({ downlink, effectiveType, rtt, saveData, type })}`);
  // NOTE: Electron starts on OS startup and fires these events, creating ghost sessions and costing us $
  // This is a bit of a hack to save us the sessions. This event also fires BEFORE we instantiate the bridge setup
  // which makes this extra gross, we'll check the browser - which means this won't report on *any* electron app,
  // not just ours
  if (!checkIsElectronBrowser()) {
    trackInForeground(`Network Information ${updatedStr}`, { downlink, effectiveType, rtt, saveData, type });
  }
  lastNetworkInformation.addEventListener('change', lastNetworkInformationCB);
  window.networkInformation = lastNetworkInformation;
}

monitorNetworkInformation();
