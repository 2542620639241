import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { htmlToElement } from '../../util';

const Portal = ({ root, children, id, onPortalMount, onPortalUnmount, className }) => {
  const container = useRef(htmlToElement(`<div id="${id}" class="here-portal ${className}"></div>`));
  const currContainer = container.current;

  useEffect(() => {
    root.appendChild(currContainer);
    onPortalMount();

    return () => {
      onPortalUnmount();
      root.removeChild(currContainer);
    };
  }, [root, currContainer, onPortalMount, onPortalUnmount]);

  return ReactDOM.createPortal(children, currContainer);
};

export default Portal;

Portal.propTypes = {
  root: PropTypes.instanceOf(Element).isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  onPortalMount: PropTypes.func,
  onPortalUnmount: PropTypes.func,
};

Portal.defaultProps = {
  id: '',
  className: '',
  onPortalMount: () => {},
  onPortalUnmount: () => {},
};
