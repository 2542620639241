import React from 'react';
import styled from 'styled-components';

import MainRoomItem from '../../web-lobby/components/room-items/MainRoomItem';
import { getBackgroundStyle } from '../../../util';
import { checkIsElectron } from '../../../util/platform-util';
import { loadRoom } from '../../../electron-support/electron-support';
import { ellipsisTextMixin } from '../../mixins';
import { ContextMenuButton } from '../../web-lobby/components/room-items/RoomItemContextMenu';
import { RoomItemContainer } from '../../web-lobby/components/room-items/RoomItem';
import { roomViewOverridesMixin } from './shared-styles';
import { RoomServiceResponse } from '../../../definitions/room';

interface Props {
  roomDataWithMembers: RoomServiceResponse & { members: object[] };
}

// Presentational part of the element without any board-element related references
// to use outside of a board.
const RoomShortcutView = ({ roomDataWithMembers }: Props) => {
  const isActive = !!roomDataWithMembers?.members?.length;

  const onClick = () => {
    if (checkIsElectron()) {
      loadRoom(roomDataWithMembers.id);
    } else {
      window.location.href = `/${roomDataWithMembers.urlAlias || roomDataWithMembers.id}?skip-preview=true`;
    }
  };

  return isActive ? (
    <ActiveRoom>
      <MainRoomItem type="secondary" roomData={roomDataWithMembers} />
    </ActiveRoom>
  ) : (
    <InactiveRoom style={getBackgroundStyle(roomDataWithMembers)} onClick={onClick}>
      <RoomTitle className="themed-font">{roomDataWithMembers?.title}</RoomTitle>
      <HoverOverlay>Enter</HoverOverlay>
    </InactiveRoom>
  );
};

export default RoomShortcutView;

const ActiveRoom = styled.div`
  height: 100%;

  ${RoomItemContainer} {
    row-gap: 0;

    ${ContextMenuButton} {
      display: none;
    }
  }

  ${roomViewOverridesMixin({ isActive: true })}
`;

const HoverOverlay = styled.div`
  display: none;
  background: rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 20px;
  color: white;
  font-size: 36px;
  font-weight: bold;
`;

const InactiveRoom = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    outline: 2px solid rgba(255, 255, 255, 0.5);

    ${HoverOverlay} {
      display: flex;
    }
  }
`;

const titleHeight = 32;
const RoomTitle = styled.div`
  position: absolute;
  height: ${titleHeight}px;
  line-height: ${titleHeight}px;
  top: -${titleHeight / 2}px;
  left: -30px;
  border-radius: ${titleHeight / 2}px;
  background: var(--primary-background);
  color: var(--primary-foreground);
  padding: 0 12px;
  z-index: 2;
  max-width: 100%;

  ${ellipsisTextMixin({ fullWidth: false })}
`;
