import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getGroupChatParams, track } from '../../../../../util/analytics-util';
import { CHAT_DELETE, CHAT_LEAVE } from '../../../analytics';
import { deleteGroup, leaveGroup } from '../../../../store/groups/actions';
import HereModalWithPortal from '../../../../components/HereModalWithPortal';
import MembersAvatarsList from '../../../../groups-lobby/main-area/MembersAvatarsList';
import { selectChatMemberIdsAsArray } from '../../../../store/messaging/selectors';
import { ChatPermissionsContext } from '../../../../common/contexts';

const CONFIRMATION_MODAL_ID = 'group-chat-leave-or-delete-confirmation';

interface Props {
  chatId: string;
  groupId: string;
  groupName: string;
  closeModal: () => void;
  onConfirmed: () => void;
}

const RemoveGroupConfirmationModal: React.FC<Props> = ({ chatId, groupId, groupName, closeModal, onConfirmed }) => {
  const dispatch = useDispatch();

  const memberIds = useSelector((state) => selectChatMemberIdsAsArray(state, chatId));
  const permissions = useContext(ChatPermissionsContext);

  const onLeaveGroupConfirmationClick = () => {
    dispatch(leaveGroup({ groupId }));
    track(CHAT_LEAVE, getGroupChatParams({ chatId }));
    onConfirmed();
  };

  const onDeleteGroupConfirmationClick = () => {
    if (!permissions.canDeleteGroup) return;

    dispatch(deleteGroup({ groupId }));
    track(CHAT_DELETE, getGroupChatParams({ chatId: groupId }));
    onConfirmed();
  };

  const confirmationModalGroupSection = (
    <>
      <ChatTitle>&quot;{groupName}&quot;</ChatTitle>
      {memberIds?.length > 0 ? (
        <AvatarListContainer>
          <MembersAvatarsList memberIds={memberIds} maxVisibleAvatars={20} />
        </AvatarListContainer>
      ) : null}
    </>
  );

  return (
    <HereModalWithPortal modalRootId={CONFIRMATION_MODAL_ID} modalWidth="520px" onOverlayClick={closeModal}>
      <div className="prevent-on-click-outside">
        <ConfirmationModal>
          <ModalTitle>Woah There!</ModalTitle>
          <ModalSubtitle>
            Are you sure you want to {permissions.canDeleteGroup ? 'delete' : 'leave'} this chat?
            {permissions.canDeleteGroup ? " This will affect all members. Deleted chats can't be restored!" : ''}
          </ModalSubtitle>
          {confirmationModalGroupSection}
          <ButtonsContainer>
            <CancelButton onClick={closeModal}>I've changed my mind</CancelButton>
            <RemoveGroupButton
              onClick={permissions.canDeleteGroup ? onDeleteGroupConfirmationClick : onLeaveGroupConfirmationClick}
            >
              {permissions.canDeleteGroup ? 'Delete' : 'Leave'} this Chat
            </RemoveGroupButton>
          </ButtonsContainer>
        </ConfirmationModal>
      </div>
    </HereModalWithPortal>
  );
};

export default RemoveGroupConfirmationModal;

const ConfirmationModal = styled.div`
  padding: 40px;
  text-align: center;
`;

const ModalTitle = styled.div`
  color: black;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
  padding: 0 50px;
  margin-bottom: 15px;
`;

const ModalSubtitle = styled.div`
  font-size: 16px;
  text-align: center;
  color: black;
  margin-bottom: 40px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
`;

const ModalButton = styled.div`
  border-radius: 12px;
  width: 213px;
  padding: 13px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
`;

const CancelButton = styled(ModalButton)`
  background: #ededed;
  color: #12002d;

  margin-right: 12px;
`;

const RemoveGroupButton = styled(ModalButton)`
  background: #f6335d;
  color: white;
`;

const ChatTitle = styled.div`
  font-family: var(--primary-font, Inter);
  font-weight: bold;
  font-size: 16px;
`;

const AvatarListContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
