import { GroupResponse, GroupsListResponse } from '../definitions/groups';
import { ChatMemberRoles } from '../definitions/message';
import { Theme } from '../definitions/theme';
import { ThemeColors } from '../definitions/theme-colors';
import { authedApiInstance } from '../firebase';

export async function canJoinGroupRoom(boardId: string) {
  return authedApiInstance.get<{ success: boolean; isAllowed: boolean }>(`/room/${boardId}/canJoin`);
}

export const createGroup = ({
  vibeId,
  name,
  userIds,
  customVibeBackgroundUrl,
  roomOnboardingStepId,
  theme,
  skipRoomCreation,
  isWebOnboarding,
}: {
  vibeId?: string;
  name?: string;
  userIds?: string[];
  customVibeBackgroundUrl?: string;
  roomOnboardingStepId?: string;
  theme?: Theme;
  skipRoomCreation?: boolean;
  isWebOnboarding?: boolean;
}) =>
  authedApiInstance
    .post<GroupResponse>('/groups', {
      vibeId,
      name,
      userIds,
      customVibeBackgroundUrl,
      roomOnboardingStepId,
      theme,
      skipRoomCreation,
      isWebOnboarding,
    })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getGroups = () =>
  authedApiInstance
    .get<GroupsListResponse>('/groups')
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getGroup = ({ groupId }: { groupId: string }) =>
  authedApiInstance
    .get<GroupResponse>(`/groups/${groupId}`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const addMembersToGroup = ({ groupId, userIds }: { groupId: string; userIds: string[] }) =>
  authedApiInstance
    .post(`/groups/${groupId}/members`, { userIds })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const patchGroup = ({
  groupId,
  updates,
}: {
  groupId: string;
  updates: {
    name?: string;
    background?: string;
    theme?: { isCustom?: boolean; primaryFont?: string; colors?: ThemeColors };
  };
}) =>
  authedApiInstance
    .patch<{ success: boolean }>(`/groups/${groupId}`, updates)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const patchGroupNotifications = ({
  groupId,
  userId,
  isNotificationsOn,
}: {
  groupId: string;
  userId: string;
  isNotificationsOn: boolean;
}) =>
  authedApiInstance
    .patch(`/groups/${groupId}/members/${userId}`, { isNotificationsOn })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const changeMemberRole = ({
  groupId,
  memberId,
  role,
}: {
  groupId: string;
  memberId: string;
  role: ChatMemberRoles;
}) =>
  authedApiInstance
    .patch(`/groups/${groupId}/members/${memberId}`, { role })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const joinGroup = ({ groupId }: { groupId: string }) =>
  authedApiInstance
    .post(`/groups/${groupId}/invite`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const removeMemberFromGroup = ({ groupId, userId }: { groupId: string; userId: string }) =>
  authedApiInstance
    .delete(`/groups/${groupId}/members/${userId}`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const updateGroupMemberLastAccess = ({ groupId, userId }: { groupId: string; userId: string }) =>
  authedApiInstance
    .put(`/groups/${groupId}/members/${userId}/lastAccess`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const deleteGroup = ({ groupId }: { groupId: string }) =>
  authedApiInstance
    .delete(`/groups/${groupId}`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const transferRoomToGroup = ({ groupId, roomId }: { groupId: string; roomId: string }) =>
  authedApiInstance
    .post(`/groups/${groupId}/transferRoom/${roomId}`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const deleteRoomFromGroup = ({ groupId, roomId }: { groupId: string; roomId: string }) =>
  authedApiInstance
    .delete(`/groups/${groupId}/rooms/${roomId}`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);
