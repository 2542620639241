import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../../../store/Wrapper';
import RoomActiveTimeWidget from './RoomActiveTime';

export const renderRoomActiveTimeWidget = (rootElement: ReactDOM.Container, roomId: string) => {
  ReactDOM.render(
    <WithRoomStore>
      <RoomActiveTimeWidget roomId={roomId} />
    </WithRoomStore>,
    rootElement
  );
};
