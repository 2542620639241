import { elementClasses } from './elements.ts';
import { ELEMENT_TYPES, IMAGE_TYPE_TO_ELEMENT_TYPE } from '../../constants/analytics-events/element-events';
import ImageElement from './image';
import MoodElement from './mood/index.tsx';
import NoteElement from './note';
import WhiteboardElement from './whiteboard';
import VideoElement from './video';
import AudioNote from './audio-note';
import YoutubeEmbedElement from './embed/youtube';
import TiktokEmbedElement from './embed/tiktok';
import RoomShortcut from './room-shortcut/index.tsx';
import ChatWindowElement from './chat-window/index.tsx';

export const elementDefinitions = {
  [elementClasses.IMAGE]: {
    Component: ImageElement,
    minSize: [100, 100],
    preserveAspectRatio: true,
  },
  [elementClasses.TEXT]: {
    Component: NoteElement,
    minSize: [40, 40],
  },
  [elementClasses.WHITEBOARD]: {
    Component: WhiteboardElement,
    minSize: [200, 200],
  },
  [elementClasses.MOOD]: {
    Component: MoodElement,
    minSize: [60, 60],
    preserveAspectRatio: true,
  },
  [elementClasses.AUDIO_NOTE]: {
    Component: AudioNote,
    minSize: [144, 28],
    preserveAspectRatio: true,
    hasClearView: true,
  },
  [elementClasses.YOUTUBE_EMBED]: {
    Component: YoutubeEmbedElement,
    minSize: [150, 150],
    defaultAspectRatio: 16 / 9,
    hasClearView: true,
    restrictPortals: true,
  },
  [elementClasses.TIKTOK_EMBED]: {
    Component: TiktokEmbedElement,
    minSize: [324, 576],
    defaultSize: [324, 576],
    hasClearView: true,
    restrictPortals: true,
  },
  [elementClasses.VIDEO]: {
    Component: VideoElement,
    minSize: [100, 100],
    hasClearView: true,
  },
  [elementClasses.ROOM_SHORTCUT]: {
    Component: RoomShortcut,
    minSize: [200, 100],
    hasClearView: true,
  },
  [elementClasses.CHAT_WINDOW]: {
    Component: ChatWindowElement,
    minSize: [320, 250],
    hideTrashcan: true,
  },
};

export const getElementType = (elementData) => {
  switch (elementData.class) {
    case elementClasses.TEXT:
      return ELEMENT_TYPES.TEXT;
    case elementClasses.WHITEBOARD:
      return ELEMENT_TYPES.DRAWING;
    case elementClasses.IMAGE:
      return IMAGE_TYPE_TO_ELEMENT_TYPE[elementData.imageType] || 'Image of unknown type';
    case elementClasses.MOOD:
      return ELEMENT_TYPES.MOOD;
    case elementClasses.YOUTUBE_EMBED:
      return ELEMENT_TYPES.YOUTUBE_EMBED;
    case elementClasses.TIKTOK_EMBED:
      return ELEMENT_TYPES.TIKTOK_EMBED;
    case elementClasses.CHAT_WINDOW:
      return ELEMENT_TYPES.CHAT_WINDOW;
    case elementClasses.ROOM_SHORTCUT:
      return ELEMENT_TYPES.ROOM_SHORTCUT;
    default:
      return null;
  }
};

export const notesDefaultBackgrounds = ['#333333bb', '#f5427e59', '#45916ce2', '#f27d00ef', '#f2f2f2'];
