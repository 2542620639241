import styled from 'styled-components';

export const Title = styled.h4`
  color: #12002d7f;
  font-family: Nunito, Inter, sans-serif;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
`;

export const OptionImage = styled.img`
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const TabsHeader = styled.div`
  position: relative;
  margin-top: 60px;
  display: flex;
  justify-content: space-around;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background-color: #00000033;
  }
`;

export const TabHeader = styled.label<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? '#6b1be3' : '#12002d7f')};
  font-size: 20px;
  font-family: Inter, sans-serif;
  font-weight: bolder;
  border-bottom: ${({ isActive }) => (isActive ? '2px solid #6b1be3' : 'none')};
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    margin-top: 10px;
    width: 100%;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    background-color: #6b1be3;
    border-radius: 100%;
  }

  &:hover {
    color: ${({ isActive }) => (isActive ? '#6b1be3' : '#12002d')};
  }
`;

export const UploadInput = styled.input`
  display: none;
`;

export const LoadingError = styled.p`
  color: #f6335d;
`;

export const TryAgainButton = styled.button`
  color: black;
  background: none;
  border: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
