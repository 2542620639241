import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { applyOpacityToVariable } from '../../../../util/theming-util';

const ThemePreview = ({ colors, width, height }) => (
  <Container containerWidth={width} containerHeight={height} colors={colors}>
    <PrimaryPreviewContainer>
      <SmallPrimaryTextPreview containerWidth={width} containerHeight={height} colors={colors} />
      <LargePrimaryTextPreview containerWidth={width} containerHeight={height} colors={colors} />
    </PrimaryPreviewContainer>
    <SecondaryPreviewContainer containerWidth={width} containerHeight={height} colors={colors}>
      <SecondaryTextPreview containerWidth={width} containerHeight={height} colors={colors} />
    </SecondaryPreviewContainer>
  </Container>
);

export default ThemePreview;

ThemePreview.propTypes = {
  colors: PropTypes.shape({
    primaryForeground: PropTypes.string.isRequired,
    primaryBackground: PropTypes.string.isRequired,
    secondaryForeground: PropTypes.string.isRequired,
    secondaryBackground: PropTypes.string.isRequired,
  }).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

const containerWidthPercents = (percents) =>
  css`
    ${({ containerWidth, theme }) =>
      `${(theme?.themePreview?.width ? theme.themePreview.width * percents : containerWidth * percents) / 100}px`}
  `;
const containerHeightPercents = (percents) =>
  css`
    ${({ containerHeight, theme }) =>
      `${(theme?.themePreview?.height ? theme.themePreview.height * percents : containerHeight * percents) / 100}px`}
  `;

const SausageShape = styled.div`
  border-radius: ${containerHeightPercents(50)};
`;

const Container = styled(SausageShape)`
  width: ${containerWidthPercents(100)};
  height: ${containerHeightPercents(100)};
  background: ${({ colors }) => applyOpacityToVariable(colors.primaryBackground, 1)};
  border: 1px solid ${({ colors }) => applyOpacityToVariable(colors.primaryForeground, 0.3)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextPreview = styled(SausageShape)`
  height: ${containerHeightPercents(17)};
`;

const PrimaryPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const PrimaryTextPreview = styled(TextPreview)`
  background: ${({ colors }) => applyOpacityToVariable(colors.primaryForeground, 1)};
`;
const SmallPrimaryTextPreview = styled(PrimaryTextPreview)`
  width: ${containerWidthPercents(11)};
  margin-left: ${containerWidthPercents(11)};
  margin-right: ${containerWidthPercents(4)};
`;
const LargePrimaryTextPreview = styled(PrimaryTextPreview)`
  width: ${containerWidthPercents(25)};
`;

const SecondaryPreviewContainer = styled(SausageShape)`
  background: ${({ colors }) => applyOpacityToVariable(colors.secondaryBackground, 1)};
  width: ${containerWidthPercents(39)};
  height: ${containerHeightPercents(70)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${containerWidthPercents(6)};
`;
const SecondaryTextPreview = styled(TextPreview)`
  background: ${({ colors }) => applyOpacityToVariable(colors.secondaryForeground, 1)};
  width: ${containerWidthPercents(17)};
`;
