import CobrowserGameElement from './cobrowser-game';
import firebase, { db } from './firebase';
import log from './log';
import '../styles/skribblio.less';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import { boardUsers } from './presence';
import { addSystemMessage } from './message-util';
import { track } from './util/analytics-util';
import { STARTED_GAME, Games } from './constants/analytics-events/games-events';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

const SkribblioState = {
  unstarted: 'unstarted',
  started: 'started',
};

const skribblioLogo = 'images/games/skribblio-logo.gif';
const skribblHostGif = 'images/games/skribbl-host.gif';
const skribblPlayerGif = 'images/games/skribbl-player.gif';

export default class SkribblioElement extends CobrowserGameElement {
  constructor(elementId) {
    super(elementId);
    this.state = SkribblioState.unstarted;
  }

  elementDescription() {
    return 'Skribblio';
  }

  icon() {
    return 'slingshot';
  }

  static async addElement() {
    const width = 1100;
    const height = 700;
    await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'SkribblioElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + (window.innerWidth - width * window.canvasScale) / 2,
          Math.floor(Math.random() * 200 - 100) + (window.innerHeight - height * window.canvasScale) / 2
        ),
        creator: firebase.auth().currentUser.uid,
        size: [width, height],
        state: SkribblioState.unstarted,
        zIndex: window.getFrontZIndex(),
      });
    track(ADD_ELEMENT, { element: ELEMENT_TYPES.SKRIBBLIO, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });

    return false;
  }

  handleUpdate(element, elementDoc) {
    log.debug('Received update', this.state, elementDoc.data());
    super.handleUpdate(element, elementDoc);
    const data = elementDoc.data();
    let result = true;
    if (this.state !== data.state) {
      log.debug('Skribblio changing state, reload it');
      result = false;
    }

    if (data.url && this.url !== data.url && this.url !== '' && firebase.auth().currentUser.uid !== data.startedBy) {
      log.debug('New Skribblio share URL detected, reload it');
      this.url = data.url;
      result = false;
      track('Skribbl', { action: 'Received game link from host' });
    }

    this.updateInstructionsButton();
    this.state = data.state;

    return result;
  }

  setup(elementId, elementDoc) {
    const data = elementDoc.data();
    this.state = data.state;
    const element = document.getElementById(elementId);
    const userId = firebase.auth().currentUser.uid;
    if (data.url !== null && data.url !== undefined) this.url = data.url;
    if (this.state === SkribblioState.started) {
      log.debug(`Skribblio game enabled at ${this.url}`);
      if (userId === data.startedBy && !this.isSkribblioURL(data.url)) {
        super.setup(elementId, elementDoc);
        if (userId === data.startedBy) this.showShareURL(true);
        element.querySelector('.share-room-submit').addEventListener('click', () => this.shareUrl());
      } else if (this.isSkribblioURL(this.url)) {
        this.showInstructions('Enter your name and hit PLAY to join Friends in this room');
        super.setup(elementId, elementDoc);
      }
    } else if (this.state === SkribblioState.unstarted) {
      element.querySelector('.game-start').addEventListener('click', () => this.startGame());
    }

    this.updateInstructionsButton();

    if (this.state === SkribblioState.started) {
      if (data.startedBy !== userId) return;
      const overlayElement = htmlToElement(`
        <div class="player-gif-div">
          <div class="player-gif-div-background" />
          <img class="host-gif" src=${skribblHostGif}>
          <button class="uk-close-large skribbl-close-button" type="button" uk-close />
        </div>
      `);
      const divBackground = overlayElement.querySelector('.player-gif-div-background');
      divBackground.addEventListener('click', () => {
        overlayElement.style.display = 'none';
      });
      const closeButton = overlayElement.querySelector('.skribbl-close-button');
      closeButton.onclick = () => {
        overlayElement.style.display = 'none';
      };

      const cobrowserIframeEl = element.querySelector('.cobrowser-iframe');
      cobrowserIframeEl.parentNode.insertBefore(overlayElement, cobrowserIframeEl);
    }
  }

  shareUrl() {
    const el = document.getElementById(`element-${this.elementId}`);
    const shareUrl = el.querySelector('.share-room-input').value;
    this.updateGameUrl(shareUrl);
    track('Skribbl', { action: 'Shared game link with room' });
  }

  updateGameUrl(shareUrl) {
    this.url = shareUrl;
    this.showShareURL(false);
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .doc(this.elementId)
      .set({ url: shareUrl, state: SkribblioState.started }, { merge: true });
    this.showInstructions('Game Link Shared! <br /> Tell Friends to Enter Name and PLAY');
    addSystemMessage('shared a Skribbl game');
  }

  isSkribblioURL(url) {
    if (!url) return false;
    const regex = /skribbl.io\/\?([-_A-Za-z0-9]+)/;
    const matches = url.match(regex);
    return matches;
  }

  getElement(elementDoc) {
    const data = elementDoc.data();
    log.debug(`Skribblio state: ${data.state}`);
    let game;
    switch (data.state) {
      case SkribblioState.unstarted: {
        game = htmlToElement(`
          <div>
            <img class="title-logo" src=${skribblioLogo}>
            <div class="game-title-screen-div">
            <p>Multiplayer Drawing & Guessing Game</p>
              <button class="game-start here-button-regular">Start a New Game</button>
            </div>
          </div>
        `);
        break;
      }
      case SkribblioState.started: {
        track(STARTED_GAME, { game: Games.SKRIBBLIO });
        const { uid } = firebase.auth().currentUser;
        if (data.startedBy === uid) {
          this.url = 'https://skribbl.io/';
          return super.getElement(elementDoc);
        }
        if (data.url && data.url !== '') {
          this.url = data.url;
          return super.getElement(elementDoc);
        }
        const waitingOnName = data.startedByName;
        game = htmlToElement(`
          <div style="height: 100%;">
            <img class="title-logo-small" src=${skribblioLogo}>
            <p style="margin: auto;">${waitingOnName} is starting a new game...</p>
            <img class="player-gif" src=${skribblPlayerGif}>
          </div>
        `);
        break;
      }
      default: {
        log.error('Unknown Skribblio game state', this.state);
        return null;
      }
    }

    return wrapElement(game, elementDoc, {
      classes: ['skribblio-element'],
      preserveAspectRatio: this.preserveAspectRatio(),
    });
  }

  iframeClasses() {
    return 'skribblio-background';
  }

  preserveAspectRatio() {
    return false;
  }

  updateInstructionsButton() {
    const element = document.getElementById(`element-${this.elementId}`);
    const instructionsButtonEl = element.querySelector('.how-to-start-button');
    if (instructionsButtonEl) {
      instructionsButtonEl.addEventListener('click', () => {
        this.onInstructionsButtonClicked();
      });
    }
  }

  onInstructionsButtonClicked() {
    const url = 'https://help.here.fm/hc/en-us/articles/4414571074835-How-does-Skribbl-work-in-Here-';
    window.open(url, '_blank');
    track('Skribbl', { action: 'Clicked how-to-start-a-game button' });
  }

  async startGame() {
    log.debug('Starting the game');
    const startedBy = firebase.auth().currentUser.uid;
    await db.collection('boards').doc(window.currentBoardId).collection('elements').doc(this.elementId).update({
      state: SkribblioState.started,
      startedBy,
      startedByName: boardUsers[window.currentBoardId][startedBy].name,
    });
  }
}
