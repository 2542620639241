import React from 'react';
import { connect } from 'react-redux';

import SearchableContent from '../components/SearchableContent';
import ImageSearchResult from './ImageSearchResult';
import * as store from '../store/image-search/store';

export default connect(
  (state, props) => ({
    entityName: 'images',
    searchTerm: store.getSearchTerm(state),
    isLoading: store.getIsLoading(state),
    classNames: ['bing-images-container'],
    results: store.getResults(state).map((image) => (
      <div key={image.id}>
        <ImageSearchResult
          title={image.title}
          thumbnail={image.thumbnail}
          url={image.url}
          isChat={props.isChat}
          elementId={props.elementId}
          elementMenuId={props.elementMenuId}
          firebaseId={props.firebaseId}
          onResultClick={props.onResultClick}
          size={image.size}
          caller={props.caller}
        />
      </div>
    )),
  }),
  (dispatch) => ({
    onSearchTermChanged: (searchTerm) => dispatch(store.searchTermChanged({ searchTerm })),
    onResultsRequest: () => dispatch(store.resultsRequested()),
    onLoadNext: () => dispatch(store.moreResultsRequested()),
  })
)(SearchableContent);
