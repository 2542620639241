import firebase, { db } from './firebase';
import { updateViewableRectangle } from './viewport';
import { screenToCanvasCoords } from './util/canvas-util';
import { elementMoved } from './drag';
import wrapElement from './element-wrapper';
import { sanitize, htmlToElement } from './util';
import BoardElement from './board-element';

import '../styles/beacon.less';
import CameraElement from './camera';
import log from './log';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';
import { track } from './util/analytics-util';

export default class BeaconElement extends BoardElement {
  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, elementDoc) {
    const data = elementDoc.data();
    this.title = data.title;
    this.destination = data.destination;
    if (data.backgroundColor) {
      const element = document.getElementById(`element-${elementDoc.id}`);
      element.querySelector('.beacon').style.backgroundColor = data.backgroundColor;
    }

    if (data.title && !this.isEditing) {
      this.showTitle(this.title);
    }
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    const data = elementDoc.data();
    if (!data.title) {
      this.editTitle();
    }
    if (data.backgroundColor) {
      const element = document.getElementById(`element-${elementDoc.id}`);
      element.querySelector('.beacon').style.backgroundColor = data.backgroundColor;
    }
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();
    this.destination = data.destination;
    this.title = data.title;
    const beacon = htmlToElement(`
      <div class="beacon">
        <div class="title-content">${sanitize(this.title)}</div>
      </div>
    `);

    beacon.addEventListener('click', this.goToDestination.bind(this));

    const renameOption = htmlToElement(
      '<button class="options-menu-option interaction-option separated-option">Rename</button>'
    );
    renameOption.addEventListener('click', this.editTitle.bind(this));
    const setDestOption = htmlToElement(
      '<button class="options-menu-option interaction-option">Set Destination</button>'
    );
    setDestOption.addEventListener('click', this.editDestination.bind(this));

    const wrappedElement = wrapElement(beacon, elementDoc, {
      classes: ['beacon-element'],
      additionalOptions: [renameOption, setDestOption],
    });

    const colorButton = htmlToElement(`
      <div>
      <div
        class="element-option-button bg-color-select-button"
        uk-tooltip="title: Background Color"
        style="background-image: url(images/icons/bg-fill-black.png)">
      </div>
      <div uk-dropdown="mode: click; pos: top-justify" class="element-submenu color-dropdown">
        <here-color-select class="beacon-bg-color-select" alpha="true" />
      </div>
      </div>
    `);
    colorButton.querySelector('.beacon-bg-color-select').addEventListener('colorchange', (e) => {
      const backgroundColor = e.detail.color;
      const element = document.getElementById(`element-${elementDoc.id}`);
      element.querySelector('.beacon').style.backgroundColor = backgroundColor;
      if (e.detail.inputComplete) {
        db.doc(element.getAttribute('docPath')).update({ backgroundColor });
      }
    });

    const optionsButton = wrappedElement.querySelector('.element-overflow');
    optionsButton.parentNode.insertBefore(colorButton, optionsButton);

    return wrappedElement;
  }

  // Statics

  static async addElement() {
    await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'BeaconElement',
        center: screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2 - 200),
        creator: firebase.auth().currentUser.uid,
        size: [350, 200],
        zIndex: window.getFrontZIndex(),
        destination: { x: 0, y: 0, width: 400, height: 400 },
      });

    track(ADD_ELEMENT, { element: ELEMENT_TYPES.BEACON, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
  }

  // Handler-specific

  showTitle(title) {
    const element = document.getElementById(`element-${this.elementId}`);
    const content = element.querySelector('.title-content');
    content.innerHTML = sanitize(title);
  }

  editTitle() {
    this.isEditing = true;
    const element = document.getElementById(`element-${this.elementId}`);
    const content = element.querySelector('.title-content');
    content.innerHTML = '';
    const input = htmlToElement(
      `<input class="update-title" placeholder="Title" value="${sanitize(this.title)}"></input>`
    );
    input.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
        const t = e.target.value.trim();
        if (t && t.length > 0) {
          this.isEditing = false;
          db.collection('boards')
            .doc(window.currentBoardId)
            .collection('elements')
            .doc(this.elementId)
            .update({ title: t });
          this.showTitle(t);
        }
      }
    });
    content.appendChild(input);
    input.focus();
  }

  removeSetDestInterface() {
    const dialog = document.getElementById('beacon-set-destination-dialog');
    if (dialog) {
      dialog.parentNode.removeChild(dialog);
    }
  }

  editDestination() {
    const setDestinationInterface = htmlToElement(`
      <div id="beacon-set-destination-dialog">
      <div>Move your window to the destination of this beacon</div>
      <button class="set-button">Set</button>
      <a class="cancel-set-button">Cancel</a>
      </div>`);
    setDestinationInterface.querySelector('.cancel-set-button').addEventListener('click', () => {
      this.removeSetDestInterface();
    });
    setDestinationInterface.querySelector('.set-button').addEventListener('click', () => {
      const topLeft = screenToCanvasCoords(0, 0);
      const bottomRight = screenToCanvasCoords(window.innerWidth, window.innerHeight);
      db.collection('boards')
        .doc(window.currentBoardId)
        .collection('elements')
        .doc(this.elementId)
        .update({
          destination: {
            x: topLeft[0],
            y: topLeft[1],
            width: bottomRight[0] - topLeft[0],
            height: bottomRight[1] - topLeft[1],
          },
        });
      this.removeSetDestInterface();
    });
    this.removeSetDestInterface();
    document.getElementById('main').appendChild(setDestinationInterface);
  }

  goToDestination() {
    if (elementMoved() || this.isEditing) {
      return;
    }
    if (this.destination) {
      updateViewableRectangle(this.destination.x, this.destination.y, this.destination.width, this.destination.height);

      const { ownCamera } = CameraElement;
      if (ownCamera) {
        const [width, height] = ownCamera.elementData.size;
        const path = `/boards/${window.currentBoardId}/elements/${ownCamera.elementId}`;
        db.doc(path)
          .update({
            center: [
              this.destination.x + (this.destination.width - width) / 2,
              this.destination.y + (this.destination.height - height) / 2,
            ],
          })
          .catch((error) => {
            // The document probably doesn't exist.
            log.error('Error updating camera with new position: ', error);
          });
      }
    }
  }
}

BeaconElement.elementType = 'BeaconElement';
