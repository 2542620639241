import { useState, useEffect, useCallback } from 'react';
import { isPossiblePhoneNumber, Country } from 'react-phone-number-input';
import firebase from '../../firebase';
import { track } from '../../util/analytics-util';
import { formatPhone } from './phone-util';
import {
  RECAPTCHA_CALLBACK,
  RECAPTCHA_EXPIRED_CALLBACK,
  RECAPTCHA_ERROR_CALLBACK,
  SEND_CODE_CLICKED,
  SEND_CODE_ERROR,
} from '../../constants/analytics-events/phone-events';

export type Values = { countryCode: Country; phoneNumber: string };

export const useVerifyPhone = (): {
  validatePhoneNumber: ({ phoneNumber, countryCode }: Values) => { phoneNumber?: string };
  sendVerificationCode: (phoneNumber: string) => Promise<string>;
} => {
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

  useEffect(() => {
    const newRecaptcha = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': () => {
        track(RECAPTCHA_CALLBACK);
      },
      'expired-callback': () => {
        track(RECAPTCHA_EXPIRED_CALLBACK);
      },
      'error-callback': () => {
        track(RECAPTCHA_ERROR_CALLBACK);
      },
    });

    setRecaptchaVerifier(newRecaptcha);
  }, []);

  const validatePhoneNumber = useCallback(({ phoneNumber, countryCode }: Values) => {
    track(SEND_CODE_CLICKED);
    const errors: { phoneNumber?: string } = {};

    if (!isPossiblePhoneNumber(formatPhone(countryCode, phoneNumber))) {
      errors.phoneNumber = 'Invalid phone number';
      track(SEND_CODE_ERROR, { error: errors.phoneNumber });
    }

    return errors;
  }, []);

  const sendVerificationCode = useCallback(
    async (phoneNumber: string) => {
      const phoneProvider = new firebase.auth.PhoneAuthProvider();
      const id = await phoneProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);

      return id;
    },
    [recaptchaVerifier]
  );

  return {
    validatePhoneNumber,
    sendVerificationCode,
  };
};
