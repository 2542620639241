/* eslint-disable @typescript-eslint/lines-between-class-members */

import { StreamType, SubscribeStatus } from './definitions/index.definitions';
import { FeedTrack } from './feed-track';
import { FeedId, PublisherFeed } from './janus/janus.definitions';

export interface FeedDelegateProtocol {
  getFeedTracksByFeedId: (feedId: FeedId) => FeedTrack[];
}
export class Feed {
  _id: string;
  _status: SubscribeStatus = SubscribeStatus.Idle;
  display: string;
  stream: MediaStream;
  video_codec?: string;
  audio_codec?: string;

  candidatePairReport: RTCIceCandidatePairStats;
  private delegate: FeedDelegateProtocol;

  constructor(delegate: FeedDelegateProtocol, params: PublisherFeed) {
    this.delegate = delegate;
    if (!params.id || !params.display)
      throw new Error(`Cannot create a Feed without an id and display params: ${params}`);

    this.id = params.id;
    this.display = params.display;
    this.video_codec = params.video_codec;
    this.audio_codec = params.audio_codec;
    this.stream = new MediaStream();
  }

  set id(id: string) {
    if (!id) throw new Error('Cannot create a Feed without and id');
    this._id = id;
  }

  get id() {
    return this._id;
  }

  get subscribed() {
    return this.status === SubscribeStatus.Subscribed;
  }

  get userId() {
    return this.display;
  }

  get feedTracks(): FeedTrack[] {
    return this.delegate.getFeedTracksByFeedId(this.id);
  }

  get status() {
    return this._status;
  }

  set status(newStatus: SubscribeStatus) {
    // log.debug(`Feed: ${this.id} status is now ${newStatus}`);
    this._status = newStatus;
  }

  get audioRTPStat() {
    return this.getMediaRTPStats(StreamType.Audio);
  }

  get videoRTPStat() {
    return this.getMediaRTPStats(StreamType.Video);
  }

  get linkQuality() {
    if (!this.audioRTPStat && !this.videoRTPStat) return 4;

    const videoFT = this.feedTracks.find((ft) => ft.type === StreamType.Video);
    const audioFT = this.feedTracks.find((ft) => ft.type === StreamType.Audio);

    let currentLink = 4;

    // Top notch link
    if (
      videoFT?.nackRatePerSecond === 0 &&
      (!audioFT?.packetsLostRatePerSecond || audioFT.packetsLostRatePerSecond === 0)
    ) {
      currentLink = 4;
    }

    // Our link is having issues
    if (videoFT?.nackRatePerSecond > 0 || audioFT?.packetsLostRatePerSecond > 0) {
      currentLink = 3;
    }

    // How bad this issues are?
    if (videoFT?.nackRatePerSecond >= 2.0 || audioFT?.packetsLostRatePerSecond >= 2.0) currentLink = 2;
    if (videoFT?.nackRatePerSecond >= 10.0 || audioFT?.packetsLostRatePerSecond >= 20.0) currentLink = 1;

    /*
    Not working properly!
    if (
      (videoFT?.bitrate !== undefined && videoFT?.bitrate <= 20) ||
      (audioFT?.bitrate !== undefined && audioFT?.bitrate <= 10)
    ) {
      currentLink = 1;
    }
    */

    return currentLink;
  }

  private getMediaRTPStats(type: StreamType) {
    return this.feedTracks.find((ft) => ft.type === type && ft.track && ft.inboundRTPStats)?.inboundRTPStats;
  }
}
