import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import MessageContextButton from './context-menu/MessageContextButton';
import AudioItem from '../../components/AudioItem';
import { svgColorMixin } from '../../mixins';
import log from '../../../log';
import { downloadFile } from '../../../util/file-util';
import { track } from '../../../util/analytics-util';
import { AUDIO_MESSAGE_PAUSED, AUDIO_MESSAGE_PLAYED } from '../../../constants/analytics-events/dm-events';
import MessageContextMenu from './context-menu/MessageContextMenu';

interface AudioMessageProps {
  id: string;
  chatId: string;
  url: string;
  storagePath: string;
  creator: string;
  deleteMessage: (id: string, storagePath: string) => Promise<void>;
}

const allowedAudioTypes = ['mp3'];

const AudioMessage = ({ id, chatId, storagePath, url, creator, deleteMessage }: AudioMessageProps) => {
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    const newAudio = new Audio(url);
    newAudio.preload = 'metadata';
    setAudio(newAudio);
  }, [url]);

  const downloadAudio = async () => {
    try {
      const parts = storagePath.split('.');
      let fileType = 'mp3';
      if (parts.length > 2 && allowedAudioTypes.includes(parts[parts.length - 1])) {
        fileType = parts[parts.length - 1];
      }

      await downloadFile(url, `audio-message-${Date.now()}.${fileType}`);
    } catch (error) {
      log.error(error);
    }
  };

  const onTogglePlayback = useCallback(({ isStarted, isPlaying }) => {
    if (isPlaying) {
      track(AUDIO_MESSAGE_PLAYED);
    } else if (!isStarted) {
      track(AUDIO_MESSAGE_PAUSED);
    }
  }, []);

  const theme = useMemo(
    () => ({
      playIcon: {
        size: 15,
      },

      pauseIcon: {
        size: 15,
      },

      time: {
        size: 12,
      },
    }),
    []
  );

  return (
    <Container>
      <AudioContainer>
        <MessageContextMenu
          chatId={chatId}
          text={url}
          creator={creator}
          options={[<MessageContextButton onClick={downloadAudio}>Download</MessageContextButton>]}
          deleteSelf={() => deleteMessage(id, storagePath)}
        />

        <AudioItemContainer>
          <ThemeProvider theme={theme}>
            <AudioItem audio={audio} onTogglePlayback={onTogglePlayback} />
          </ThemeProvider>
        </AudioItemContainer>
      </AudioContainer>
    </Container>
  );
};

const Container = styled.div``;

const AudioContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const AudioItemContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  background-color: white;
  border-radius: 50px;

  ${svgColorMixin('#12002d')}
`;

export default AudioMessage;
