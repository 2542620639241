import ServerDate from '../util/server-date';
import log from '../log';
import { PlayerStates, getVideoDataFromYouTubeURL } from '../util/media-util';
import { STARTED_PLAYER } from '../constants/analytics-events/media-player-events';
import { track } from '../util/analytics-util';

export const placeholderImg = 'maxresdefault.jpg';

/*
YouTube Handler
*/
export class YouTubeHandler {
  constructor(mediaPlayerHandler) {
    this.mediaPlayerHandler = mediaPlayerHandler;
  }

  createVideoPlayer(videoElementId, videoId) {
    this.mediaPlayerHandler.resetForPlayback();
    return new YT.Player(videoElementId, {
      height: '100%',
      width: '100%',
      videoId,
      playerVars: {
        origin: window.location.origin,
        modestbranding: 1,
        showinfo: 0,
        controls: 0,
        rel: 0,
        fs: 0,
        disablekb: 1,
        autoplay: 0,
        cc_load_policy: 1,
      },
      events: {
        onStateChange: this.mediaPlayerHandler.onPlayerStateChange.bind(this.mediaPlayerHandler),
        onReady: this.mediaPlayerHandler.onPlayerReady.bind(this.mediaPlayerHandler),
        onPlaybackRateChange: this.onPlaybackSpeedChange.bind(this.mediaPlayerHandler),
        onApiChange: this.mediaPlayerHandler.onApiChange.bind(this.mediaPlayerHandler),
      },
    });
  }

  onPlaybackSpeedChange(event) {
    event.target.setPlaybackRate(1);
  }

  setUpPlayer() {
    this.mediaPlayerHandler.player.setPlaybackRate(1);
  }

  handlerStateToPlayerState(data) {
    if (data === undefined) return null;
    const state = data.data !== undefined ? data.data : data;
    let newState;
    switch (state) {
      case YT.PlayerState.PLAYING:
        newState = PlayerStates.PLAYING;
        break;
      case YT.PlayerState.BUFFERING:
        newState = PlayerStates.BUFFERING;
        break;
      case YT.PlayerState.PAUSED:
        newState = PlayerStates.PAUSED;
        break;
      case YT.PlayerState.ENDED:
        newState = PlayerStates.ENDED;
        break;
      case YT.PlayerState.CUED:
        newState = PlayerStates.CUED;
        break;
      default:
        newState = state;
    }
    return newState;
  }

  fetchDataUrl(videoId) {
    const youtubeDataUrl = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,statistics&id=${videoId}&key=${process.env.YOUTUBE_API_KEY}`;
    return youtubeDataUrl;
  }

  async playVideo(embedded = false) {
    if (!embedded) {
      track(STARTED_PLAYER, { target: 'YouTube' });
    }
    if (this.mediaPlayerHandler.player === undefined || this.mediaPlayerHandler.player.playVideo === undefined) return;
    if (this.mediaPlayerHandler.playerState === PlayerStates.ENDED) {
      this.currentMediaTime = 0;
      this.timestamp = await ServerDate.getTimeInSecs();
      this.seekTo(0);
    } else {
      this.mediaPlayerHandler.player.playVideo();
    }
  }

  pauseVideo() {
    if (this.mediaPlayerHandler.player === undefined || this.mediaPlayerHandler.player.pauseVideo === undefined) return;
    this.mediaPlayerHandler.player.pauseVideo();
  }

  // Volume in 0-1 range for us vs. 0-100 for YouTube
  setVolume(newVol) {
    if (this.mediaPlayerHandler.player === undefined || this.mediaPlayerHandler.player.setVolume === undefined) return;
    this.mediaPlayerHandler.player.setVolume(newVol * 100);
  }

  getVolume() {
    if (this.mediaPlayerHandler.player === undefined || this.mediaPlayerHandler.player.getVolume === undefined)
      return null;
    return this.mediaPlayerHandler.player.getVolume() / 100;
  }

  mute() {
    if (this.mediaPlayerHandler.player === undefined || this.mediaPlayerHandler.player.mute === undefined) return;
    this.mediaPlayerHandler.player.mute();
  }

  unMute() {
    if (this.mediaPlayerHandler.player === undefined || this.mediaPlayerHandler.player.unMute === undefined) return;
    this.mediaPlayerHandler.player.unMute();
  }

  seekTo(newTime) {
    if (this.mediaPlayerHandler.player === undefined || this.mediaPlayerHandler.player.seekTo === undefined) return;
    this.mediaPlayerHandler.player.seekTo(newTime);
  }

  getCurrentMediaTime() {
    if (this.mediaPlayerHandler.player.getCurrentTime !== undefined)
      return this.mediaPlayerHandler.player.getCurrentTime();
    return null;
  }

  getDuration() {
    if (this.mediaPlayerHandler.player.getDuration !== undefined) return this.mediaPlayerHandler.player.getDuration();
    return null;
  }

  getSearchUrl(searchTerms) {
    return `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=20&q=${searchTerms}&type=video&key=${process.env.YOUTUBE_API_KEY}`;
  }

  getVideoPlaceholderImageUrl() {
    return `${process.env.IMAGE_PROXY_URL}/https://img.youtube.com/vi/${this.mediaPlayerHandler.videoId}/${placeholderImg}`;
  }

  searchPlatform(searchTerms) {
    const searchUrl = this.getSearchUrl(searchTerms);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(searchUrl, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        this.mediaPlayerHandler.processSearchResults(res.items, 'search');
      })
      .catch((error) => {
        log.error('error', error);
      });
  }

  getVideoIdFromPlayer(player) {
    if (player !== undefined) {
      const videoData = player.getVideoData();
      if (videoData) {
        return videoData.video_id;
      }
    }
    return null;
  }

  getVideoDataFromURL(url) {
    const videoId = getVideoDataFromYouTubeURL(url);
    return videoId;
  }

  getPlayerInfoFromPlayer() {
    if (this.mediaPlayerHandler.player === undefined) return null;
    const playerInfo = this.mediaPlayerHandler.player.getVideoData();
    playerInfo.handler = this;
    playerInfo.state = this.handlerStateToPlayerState(this.mediaPlayerHandler.playerState);
    return playerInfo;
  }

  doUrlSearch(videoData) {
    const videoId = videoData.id;
    const youtubeDataUrl = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,statistics&id=${videoId}&key=${process.env.YOUTUBE_API_KEY}`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(youtubeDataUrl, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        this.mediaPlayerHandler.processSearchResults(res.items, 'searchUrl');
      })
      .catch((error) => {
        log.error('Error searching YouTube', error);
      });
  }

  isMuted() {
    if (this.mediaPlayerHandler.player !== undefined && this.mediaPlayerHandler.player.isMuted !== undefined)
      return this.mediaPlayerHandler.player.isMuted();
    return false;
  }
}
