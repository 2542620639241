import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { EmoteFromResponse, EmoteType } from '../../../definitions/emotes';
import { selectEmotes } from '../../../react/store/emotes/selectors';
import { Categories, categories, categoryToTitle } from '../constants';
import { SectionTitle } from '../shared-styles';
import EmoteButton from './EmoteButton';

interface Props {
  onSelect: (emoteUrl: string, emoteType: EmoteType, pack?: string) => void;
  setCategoryRef: (element: HTMLDivElement | null, index: number) => void;
}

const HerePackSection: React.FC<Props> = ({ onSelect, setCategoryRef }) => {
  const myEmotes: EmoteFromResponse[] = useSelector(selectEmotes);

  return (
    <div id={Categories.MY_STUFF} ref={(el) => setCategoryRef(el, categories.indexOf(Categories.MY_STUFF))}>
      <SectionTitle>{categoryToTitle[Categories.MY_STUFF]}</SectionTitle>
      {myEmotes.length ? (
        myEmotes.map((hereEmote) => <EmoteButton key={hereEmote.url} emoteUrl={hereEmote.url} onSelect={onSelect} />)
      ) : (
        <EmptyMessage>Empty! Go to your profile to add new emotes.</EmptyMessage>
      )}
    </div>
  );
};

export default HerePackSection;

const EmptyMessage = styled.div`
  margin: 10px 0 10px 6px;
  text-align: left;
  font-size: 12px;
`;
