// Yes, it's camera hands
import { htmlToElement } from './util';
import { getElementPosition } from './element-transform';
import log from './log';

import firebase from './firebase';
import '../styles/camera-hand.less';
import { boardUsers } from './presence';
import colorValues from './components/avatar/colors';
import { emitParticles } from './particles';
import { findCameraHandler } from './util/camera-util';
import { track } from './util/analytics-util';
import eventBus, { headHandsActivated } from './event-bus';

export const CameraHandTypes = {
  Paper: 'paper',
  Rock: 'rock',
  Scissors: 'scissors',
  MiddleFinger: 'middleFinger',
  Mitten: 'mitten',
};

export const DefaultHandType = CameraHandTypes.Mitten;

const handTimers = {};
const handPositions = {};

const ParticleSendFrequency = 333; // milliseconds between particle bursts

let lastParticlesSent = 0;
let trackingTimer = null;

const handImageForType = (handType) => {
  switch (handType) {
    case CameraHandTypes.Paper:
      return '/images/hands/paper.svg';
    case CameraHandTypes.Rock:
      return '/images/hands/rock.svg';
    case CameraHandTypes.Scissors:
      return '/images/hands/scissors.svg';
    case CameraHandTypes.MiddleFinger:
      return '/images/hands/middleFinger.svg';
    case CameraHandTypes.Mitten:
    default:
      return '/images/hands/glove.svg';
  }
};

export const cameraHandUp = (uid, handType) => {
  const hand = document.getElementById(`hand-${uid}-${handType}`);
  if (hand) {
    hand.parentNode.removeChild(hand);
  }

  if (uid === firebase.auth().currentUser.uid && trackingTimer) {
    clearTimeout(trackingTimer);
    trackingTimer = null;
  }
  delete handPositions[uid][handType];
};

export const cameraHandMove = (uid, x, y, handType) => {
  const type = handType || DefaultHandType;
  const handImageIcon = handImageForType(type);
  const handler = findCameraHandler(uid);
  if (!handler) {
    log.warn("no camera handler, can't add hand");
    return;
  }

  let el = document.getElementById(`hand-${uid}-${handType}`);
  if (!el) {
    el = htmlToElement(
      `<div id="hand-${uid}-${handType}" class="camera-hand"><div class="camera-arm"></div><img src=${handImageIcon} class="hand-image"></img></div>`
    );
    document.getElementById('elements').appendChild(el);
  }

  const cameraEl = document.getElementById(`element-${handler.elementId}`);
  const camWidth = parseFloat(cameraEl.style.width);
  const camHeight = parseFloat(cameraEl.style.height);
  const cameraPos = [...getElementPosition(cameraEl)];
  const handSize = camWidth / 4;
  cameraPos[0] += (camWidth - handSize) / 2;
  cameraPos[1] += camHeight / 2;
  const xDiff = cameraPos[0] - x;
  const yDiff = cameraPos[1] - y;
  let deg = -Math.atan(xDiff / yDiff);
  if (yDiff < 0) {
    deg += Math.PI;
  }

  el.style.transform = `translate(${x - handSize / 2}px, ${y}px) rotate(${deg}rad)`;
  el.style.zIndex = cameraEl.style.zIndex - 1;
  const arm = el.querySelector('.camera-arm');
  const hand = el.querySelector('.hand-image');
  const hypotenuse = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
  arm.style.height = `${hypotenuse}px`;
  const boardUser = boardUsers[window.currentBoardId][uid];
  arm.style.backgroundColor = colorValues[boardUser.color || 0];
  const newScale = `${handSize}px`;
  el.style.width = newScale;
  hand.style.width = newScale;
  hand.style.height = newScale;

  if (handTimers[uid]?.[handType]) {
    clearTimeout(handTimers[uid]?.[handType]);
    delete handTimers[uid]?.[handType];
  }

  if (!handPositions[uid]) {
    handPositions[uid] = {};
  }

  handPositions[uid][handType] = { x, y, size: handSize };

  eventBus.dispatch(headHandsActivated);

  if (uid === firebase.auth().currentUser.uid) {
    let handsIntersect = false;
    Object.keys(handPositions).forEach((userId) => {
      if (userId !== uid) {
        Object.keys(handPositions[userId]).forEach((userHandType) => {
          const xDist = handPositions[userId][userHandType].x - x;
          const yDist = handPositions[userId][userHandType].y - y;
          const distance = Math.sqrt(xDist * xDist + yDist * yDist);
          if (distance <= Math.max(handSize, handPositions[userId][userHandType].size)) {
            handsIntersect = true;
          }
        });
      }
    });

    if (handsIntersect) {
      const now = new Date().getTime();
      if (now - lastParticlesSent > ParticleSendFrequency) {
        const rect = [x, y, handSize, handSize];
        emitParticles({ rect });
        window.rtc.sendParticleEffect({ rect });
        lastParticlesSent = now;
      }
    }

    // Track initially placing the hand
    //
    // We put this one on a timer because on some Windows machines / click-wheel mice,
    // The scroll wheel temporarily triggers a simulated Ctrl keypress so this
    // could create false positives
    if (!handTimers[uid]?.[handType] && !trackingTimer) {
      trackingTimer = setTimeout(() => {
        if (handPositions[uid]?.[handType]) {
          track('Extend Head Hand');
        }
      }, 1000);
    }
  }

  if (!handTimers[uid]) {
    handTimers[uid] = {};
  }
  handTimers[uid][handType] = setTimeout(() => {
    cameraHandUp(uid, handType);
  }, 500);
};
