import firebase from '../../firebase';
import log from '../../log';

// types
interface RoomDecorationImage {
  id: string;
  url: string;
}

/**
 * !!! FurnitureType VALUES must match the folder names in the firestore bucket !!!
 */
export enum FurnitureType {
  Spaces = 'spaces',
  Seating = 'seating',
  Tables = 'tables',
  Decor = 'decorations',
  Accessories = 'accessories',
  Snacks = 'snacks',
  Pets = 'pets',
}

const storage = firebase.storage();

// refs
const furnitureStorageRefMap: { [key in FurnitureType]: firebase.storage.Reference } = Object.values(
  FurnitureType
).reduce(
  (acc, key) => ({
    ...acc,
    [key]: storage.ref(`furniture/${key}`),
  }),
  {} as { [key in FurnitureType]: firebase.storage.Reference }
);

const getImages = async (storageRef: firebase.storage.Reference) => {
  try {
    const imagesRef = await storageRef.listAll();
    return await Promise.all(
      imagesRef.items.map(async (item) => ({
        id: item.name,
        url: await item.getDownloadURL(),
      }))
    );
  } catch (err) {
    log.error(err);
    return [];
  }
};

class FurnitureCategoryFetcher {
  private type: FurnitureType;

  private images: RoomDecorationImage[];

  constructor(type: FurnitureType) {
    this.type = type;
    this.images = [];
  }

  async getImages() {
    if (this.images.length) {
      return this.images;
    }

    const storageRef = furnitureStorageRefMap[this.type];
    this.images = await getImages(storageRef);

    return this.images;
  }
}

export const furnitureCategoryFetcherMap: { [key in FurnitureType]: FurnitureCategoryFetcher } = Object.values(
  FurnitureType
).reduce(
  (acc, key) => ({
    ...acc,
    [key]: new FurnitureCategoryFetcher(key as FurnitureType),
  }),
  {} as { [key in FurnitureType]: FurnitureCategoryFetcher }
);
