import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UIkit from 'uikit';

// icons
import { MoreIcon } from '../icons';
import AddFriendIcon from '../../../assets/icons/add-friend.svg';
import PlaneIcon from '../../../assets/icons/plane.svg';
import AcceptFriendIcon from '../../../assets/icons/add-friend-accept.svg';
import PendingFriendIcon from '../../../assets/icons/add-friend-pending.svg';
import SmileyIcon from '../../../assets/icons/smiley.svg';

// utils
import { htmlToElement } from '../../util';
import { track } from '../../util/analytics-util';
import { closeFriendsModal } from '../../util/friends-util';
import { closeUserProfile } from './utils';

import { renderUserMenu } from '../user-menu/render';

// redux
import { setActiveChatId, setActiveReceiverId, setMessagesSidebarOpen } from '../store/messaging/reducer';
import { closeRoomSettings } from '../store/room/store';
import { setActiveTab } from '../store/web-lobby/actions.ts';
import { WebLobbyTabs } from '../../definitions/web-lobby.ts';
import { useFriendingButtons } from '../hooks/feed/useFriendingButtons';

import ProfileCTAsForSelf from './ProfileCTAsForSelf';
import {
  ACCEPT_FRIEND,
  FRIEND_REQUEST_SOURCES,
  IGNORE_FRIEND,
  REQUEST_FRIEND,
} from '../../constants/analytics-events/friend-events';
import { selectDMChatByReceiverId } from '../store/messaging/selectors';
import { renderUserFriendsModal } from '../friends/render';
import { selectIsInOs } from '../store/room/selectors';
import { openChat as openOsChat } from '../store/os/actions.ts';
import { EventSourceContext } from '../common/contexts.ts';

const ProfileCTAs = ({ userId, isSelf, setEditingMode, profileWrapperElement, isFriends, isFetchingFriends }) => {
  const dispatch = useDispatch();
  const isOnline = Boolean(useSelector((state) => state.members.online[userId]));
  const {
    showAddFriend,
    showAcceptFriend,
    showPendingIcon,
    addFriendHandler,
    acceptFriendHandler,
    ignoreFriendHandler,
  } = useFriendingButtons(userId);

  const [errorMessage, setErrorMessage] = useState('');

  const isCandybar = window.location.pathname === '/electron-candybar';

  const userProfileSource = useContext(EventSourceContext);

  const onAddFriendClick = async () => {
    setErrorMessage('');
    const response = await addFriendHandler(userId);
    if (response && response.success) {
      if (userProfileSource) {
        track(REQUEST_FRIEND, { source: `${userProfileSource}`, receiverId: userId });
      } else {
        track(REQUEST_FRIEND, { source: FRIEND_REQUEST_SOURCES.SMALL_USER_PROFILE, receiverId: userId });
      }
    } else {
      setErrorMessage('Friend request unsuccessful. Please try again.');
    }
  };

  const onAcceptFriendRequestClick = async () => {
    setErrorMessage('');
    const response = await acceptFriendHandler(userId);
    if (response && response.success) {
      track(ACCEPT_FRIEND, { source: FRIEND_REQUEST_SOURCES.SMALL_USER_PROFILE, requesterId: userId });
    } else {
      setErrorMessage('There was an error. Please try again.');
    }
  };

  const onIgnoreFriendRequestClick = async () => {
    setErrorMessage('');
    const response = await ignoreFriendHandler(userId);
    if (response && response.success) {
      track(IGNORE_FRIEND, { source: 'small user profile' });
    } else {
      setErrorMessage('There was an error. Please try again.');
    }
  };

  // show accept/ignore friend request button
  const showFriendAcceptIgnoreButtons = showPendingIcon;

  const collapseMoreButton =
    showAddFriend || showAcceptFriend || showFriendAcceptIgnoreButtons || (isFriends && isOnline);

  const onMoreClick = () => {
    let moreMenuAnchor;
    let moreMenu;
    if (!document.getElementById('user-profile-more-menu')) {
      moreMenuAnchor = htmlToElement('<div id="user-profile-more-menu-ghost-anchor"></div>');
      moreMenu = htmlToElement('<div uk-dropdown="mode: click; pos: bottom-left" id="user-profile-more-menu"></div>');
      document.body.append(moreMenuAnchor);
      document.body.append(moreMenu);
    } else {
      moreMenuAnchor = document.getElementById('user-profile-more-menu-ghost-anchor');
      moreMenu = document.getElementById('user-profile-more-menu');
    }

    const userProfileModal = document.getElementById('user-profile');
    const moreButton = document.getElementById('user-profile-more-button');

    const ghostAnchor = document.getElementById('user-profile-more-menu-ghost-anchor');
    ghostAnchor.style.top = `${userProfileModal.offsetTop + moreButton.offsetTop + moreButton.offsetHeight + 5}px`;
    ghostAnchor.style.left = `${userProfileModal.offsetLeft + moreButton.offsetLeft}px`;

    renderUserMenu(moreMenu, {
      userId,
      fromUserProfile: true,
      fromUserProfileShowAddFriend: showAddFriend,
    });
    moreMenu.style.zIndex = '100002';

    const preventHide = (e) => {
      e.preventDefault();
    };

    // Need to add prevent hide so that user profile modal doesn't close upon menu open
    userProfileModal.addEventListener('beforehide', preventHide);
    UIkit.dropdown(moreMenu).show();
    // Then we remove it so that user can close it as usual after
    userProfileModal.removeEventListener('beforehide', preventHide);
  };

  const chatsWithYouAndReceiver = useSelector((state) => selectDMChatByReceiverId(state, userId));
  const activeChatId = chatsWithYouAndReceiver.length >= 1 ? chatsWithYouAndReceiver[0].id : null;
  const isHereOs = useSelector(selectIsInOs);

  const onSendMessageClick = () => {
    // close room settings
    dispatch(closeRoomSettings());

    // close friends modal
    closeFriendsModal();

    // close user profile
    closeUserProfile();

    // open sidebar
    dispatch(setMessagesSidebarOpen({ messagesSidebarOpen: true }));

    // open dmwindow
    if (isHereOs) {
      dispatch(openOsChat({ chatId: activeChatId }));
    } else {
      dispatch(setActiveReceiverId({ activeReceiverId: userId }));
      dispatch(setActiveChatId({ activeChatId }));
    }

    // set active tab (for the lobby)
    dispatch(setActiveTab({ activeTab: WebLobbyTabs.DMs }));
  };

  const onFriendsClick = () => {
    closeUserProfile();
    renderUserFriendsModal(userId);
  };

  return (
    <CTAsWrapper>
      {isSelf && <ProfileCTAsForSelf setEditingMode={setEditingMode} profileWrapperElement={profileWrapperElement} />}
      {!isSelf && !isFetchingFriends && (
        <ButtonContainer>
          {isFriends && !isCandybar && (
            <DMButton onClick={onSendMessageClick} className="here-button-regular">
              <PlaneIconContainer>
                <PlaneIcon />
              </PlaneIconContainer>
              Message
            </DMButton>
          )}
          {isFriends && !isCandybar ? (
            <FriendsButton className="here-button-minimal" onClick={onFriendsClick}>
              <IconContainer>
                <SmileyIcon />
              </IconContainer>
              Friends
            </FriendsButton>
          ) : null}
          {showAddFriend && (
            <AddFriendButton onClick={onAddFriendClick} className="here-button-minimal">
              <AddFriendIconContainer>
                <AddFriendIcon width="20" />
              </AddFriendIconContainer>
              Add Friend
            </AddFriendButton>
          )}
          {showFriendAcceptIgnoreButtons && (
            <AddFriendButton disabled className="here-button-minimal">
              <IconContainer>
                <PendingFriendIcon width="18" />
              </IconContainer>
              Request Sent
            </AddFriendButton>
          )}
          {showAcceptFriend && (
            <>
              <AcceptFriendButton onClick={onAcceptFriendRequestClick} className="here-button-regular">
                <IconContainer>
                  <AcceptFriendIcon width="16" />
                </IconContainer>
                Accept Friend
              </AcceptFriendButton>
              <IgnoreFriendButton onClick={onIgnoreFriendRequestClick} className="here-button-minimal">
                Ignore
              </IgnoreFriendButton>
            </>
          )}
          <CTAButton small id="user-profile-more-button" onClick={onMoreClick} className="here-button-minimal">
            {collapseMoreButton && isOnline ? (
              <MoreIcon opacity="0.8" width="14" />
            ) : (
              <>
                <IconContainer>
                  <MoreIcon opacity="0.8" width="14" />
                </IconContainer>
                More
              </>
            )}
          </CTAButton>
        </ButtonContainer>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </CTAsWrapper>
  );
};

export default ProfileCTAs;

ProfileCTAs.propTypes = {
  userId: PropTypes.string.isRequired,
  isSelf: PropTypes.bool.isRequired,
  setEditingMode: PropTypes.func.isRequired,
  profileWrapperElement: PropTypes.instanceOf(Element),
  isFriends: PropTypes.bool.isRequired,
  isFetchingFriends: PropTypes.bool.isRequired,
};

ProfileCTAs.defaultProps = {
  profileWrapperElement: null,
};

const CTAsWrapper = styled.div`
  background: transparent;
  padding: 6px 15px;
  min-height: 50px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CTAButton = styled.div`
  width: 100%;
  padding: 11px 0;
  font-size: 12px;
  line-height: 17px;
  z-index: 2;

  ${({ small }) =>
    small &&
    `
    width: auto;
    padding: 11px;
  `}
`;

const DMButton = styled(CTAButton)`
  width: 170px;
  margin-right: 6px;
`;

const FriendsButton = styled(CTAButton)`
  width: 130px;
  margin-right: 6px;
`;

const AddFriendButton = styled(CTAButton)`
  width: 173px;
  margin-right: 6px;
`;

const AcceptFriendButton = styled(CTAButton)`
  width: 130px;
  margin-right: 6px;
`;

const IgnoreFriendButton = styled(CTAButton)`
  width: 86px;
  margin-right: 6px;
`;

const IconContainer = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const PlaneIconContainer = styled(IconContainer)`
  svg {
    width: 14px;
    path {
      opacity: 0.8;
    }
  }
`;

const AddFriendIconContainer = styled(IconContainer)`
  svg {
    g {
      opacity: 0.8;
    }
  }
`;

const ErrorMessage = styled.div`
  color: #f6335d;
  font-weight: 400;
  margin-top: 5px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;
