import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectChatByChatId } from '../../../../store/messaging/selectors';
import { selectChatSkinningDecorators } from '../../../../store/chat-skinning/selectors';
import SkinningDecoratorWrapper from './SkinningDecoratorWrapper';
import SkinningItemPicker, { PreviewContainer } from '../SkinningItemPicker';
import { useChangeSkinProperty } from '../useChangeSkinProperty';

interface Props {
  chatId: string;
}

const SkinningDecoratorPicker = ({ chatId }: Props) => {
  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const selectedDecoratorId = chat?.theme?.skin?.decorator?.id;

  const decorators = useSelector(selectChatSkinningDecorators);
  const handleDecoratorChange = useChangeSkinProperty(chat, 'decorator');

  const items = useMemo(
    () =>
      decorators.map((d) => ({
        id: d.id,
        name: d.name,
        preview: (
          <SkinningDecoratorWrapper decorator={d}>
            <ExampleMessage>hey! sup??</ExampleMessage>
          </SkinningDecoratorWrapper>
        ),
        isSelected: d.id === selectedDecoratorId,
        onSelect: () => handleDecoratorChange(d),
        onRemove: () => handleDecoratorChange(null),
      })),
    [decorators, handleDecoratorChange, selectedDecoratorId]
  );

  return (
    <Container>
      <SkinningItemPicker items={items} canRemove />
    </Container>
  );
};

export default SkinningDecoratorPicker;

const Container = styled.div`
  ${PreviewContainer} {
    overflow: visible;
  }
`;

const ExampleMessage = styled.div`
  background: var(--secondary-background);
  color: var(--secondary-foreground);
  font-size: 17px;
  font-weight: bold;
  width: 100%;
  padding: 16px 8px;
  border-radius: 9px;
  border-bottom-left-radius: 0;
`;
