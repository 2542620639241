import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import SectionWrapper from '../common/SectionWrapper';
import SectionSeparator from '../common/SectionSeparator';
import CustomThemeSettings from './CustomThemeSettings';
import ThemeSelect from './ThemeSelect';
import FontSelect from './FontSelect';
import { track } from '../../../../util/analytics-util';

import {
  selectAvailableThemes,
  selectCurrentThemeColors,
  selectCurrentThemePrimaryFont,
  selectIsCurrentThemeCustom,
  selectSelectedThemeName,
} from '../../../store/room/selectors';
import { roomDataUpdateRequested, themeSelected } from '../../../store/room/store';

const ThemeSettings = () => {
  const dispatch = useDispatch();
  const isCustomTheme = useSelector(selectIsCurrentThemeCustom);

  const availableThemes = useSelector(selectAvailableThemes);
  const selectedThemeName = useSelector(selectSelectedThemeName);

  const currentThemeColors = useSelector(selectCurrentThemeColors);
  const onCustomThemeColorUpdate = useCallback(
    (property, color) => {
      dispatch(roomDataUpdateRequested({ patch: { theme: { colors: { [property]: color } } } }));
      track('Custom Theme Changed', { property });
    },
    [dispatch]
  );
  const onFontChange = useCallback(
    ({ family }) => {
      dispatch(roomDataUpdateRequested({ patch: { theme: { primaryFont: family } } }));
      track('Theme Font Changed', { family });
    },
    [dispatch]
  );

  return (
    <Container>
      <FontSelect
        label="Room Name Font"
        id="roomTheme"
        dropdownDirection="down"
        currentFont={useSelector(selectCurrentThemePrimaryFont)}
        onFontChange={onFontChange}
      />
      <SectionSeparator />

      <SectionWrapper title="Interface" description="Select a theme or customize your own">
        <ThemeSelect
          availableThemes={availableThemes}
          selectedThemeName={selectedThemeName}
          onThemeSelected={useCallback(
            ({ theme, oldThemeName }) => dispatch(themeSelected({ theme, oldThemeName })),
            [dispatch]
          )}
        />
      </SectionWrapper>
      {isCustomTheme ? (
        <CustomThemeSettings colors={currentThemeColors} onColorUpdate={onCustomThemeColorUpdate} />
      ) : null}
    </Container>
  );
};

export default ThemeSettings;

const Container = styled.div`
  padding-bottom: 48px;
  position: relative;
`;
