import log from '../log';
import { clamp, htmlToElement } from '../util';
import { VideoRenderer, attachStreams } from './video-renderer';
import { CameraElementHandler } from './camera-element';

const showForCamera: { [cameraId: string]: boolean } = {};

/**
 * A video renderer with an alpha channel.
 *
 * The image being delivered over the stream is split vertically, with the alpha mask (on the red channel) being sent on top
 */
export class AlphaVideoRenderer extends VideoRenderer {
  static setupForCamera(camera: CameraElementHandler) {
    if (!super.setupForCamera(camera)) {
      return false;
    }

    const videoContainer = document.getElementById(`video-container-${camera.userId}`);
    const canvas = htmlToElement(`<canvas id="camera-canvas-${camera.userId}" class="mirror"></canvas>`);
    videoContainer.append(canvas);
    return true;
  }

  static teardownForCamera(camera: CameraElementHandler) {
    super.teardownForCamera(camera);
    const el = camera.domElement;

    const videoContainer = el.querySelector(`#video-container-${camera.userId}`);
    const canvas = videoContainer.querySelector(`#camera-canvas-${camera.userId}`);
    if (canvas) {
      videoContainer.removeChild(canvas);
    }
  }

  static attachStream(
    camera: CameraElementHandler,
    streamId: string,
    stream: MediaStream,
    onPaused: (e: Event) => void
  ): void {
    const videoContainer = document.getElementById(`video-container-${camera.userId}`);
    if (!videoContainer) {
      log.warn(`Alpha renderer: Failed to enable - no container found for ${camera.userId}`);
      return;
    }

    const video = videoContainer.querySelector('video');

    if (!video) {
      log.debug('Alpha renderer: No video in container, bailing');
      return;
    }

    if (onPaused) {
      video.removeEventListener('pause', onPaused);
    }
    video.addEventListener('pause', onPaused);

    const canvas = videoContainer.querySelector(`#camera-canvas-${camera.userId}`) as HTMLCanvasElement;
    canvas.classList.add('transparent-video');
    const context = canvas.getContext('2d', {
      willReadFrequently: true,
    });

    const step = () => {
      if (!camera.isBackgroundRemovalEnabled()) return;

      if (showForCamera[camera.elementId]) {
        // Transparent image comes in as two images composited together: mask on top, raw camera on bottom
        const width = video.videoWidth;
        const height = video.videoHeight;
        if (width && height) {
          canvas.width = width;
          canvas.height = height / 2;

          // Draw the mask image first
          context.save();
          context.clearRect(0, 0, width, height);
          context.globalCompositeOperation = 'source-out';
          context.drawImage(video, 0, 0, width, height / 2, 0, 0, canvas.width, canvas.height);

          const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
          // Convert red to alpha
          const { data } = imgData;
          for (let i = 0; i < data.length; i += 4) {
            data[i + 3] = clamp(data[i] * 2, 0, 255);
          }
          context.putImageData(imgData, 0, 0);

          // Use the mask to erase background
          context.globalCompositeOperation = 'source-in';
          context.drawImage(video, 0, height / 2, width, height / 2, 0, 0, canvas.width, canvas.height);
          context.restore();
        }
      }
      requestAnimationFrame(step);
    };
    requestAnimationFrame(step);

    canvas.style.width = '100%';
    canvas.style.height = '100%';

    attachStreams(camera, video, streamId, stream);
    camera.updateVideoVisibility();
  }

  static showVideo(camera: CameraElementHandler): void {
    const canvas = document.getElementById(`camera-canvas-${camera.userId}`) as HTMLCanvasElement;
    canvas.style.display = 'block';
    showForCamera[camera.elementId] = true;
  }

  static hideVideo(camera: CameraElementHandler): void {
    const canvas = document.getElementById(`camera-canvas-${camera.userId}`) as HTMLCanvasElement;
    canvas.style.display = 'none';
    showForCamera[camera.elementId] = false;
  }
}
