import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { db } from '../../firebase';
import Offcanvas from '../components/Offcanvas';
import RoomSettingsHeader from '../room/settings/Header';
import ThemeSelect from '../room/settings/theme/ThemeSelect';
import CustomThemeSettings from '../room/settings/theme/CustomThemeSettings';
import FontSelect from '../room/settings/theme/FontSelect';
import Field from '../components/inputs/Field';
import { selectAvailableThemes, selectDefaultTheme } from '../store/room/selectors';

import { getThemeName, isThemeCustom, applyTheme } from '../../util/theming-util';
import { track } from '../../util/analytics-util';
import { PROFILE_FONT_CHANGED, PROFILE_THEME_CHANGED } from '../../constants/analytics-events/theme-events';

const EditProfileTheme = ({ userProfile, isOpened, onClose, id }) => {
  const availableThemes = useSelector(selectAvailableThemes);
  const defaultTheme = useSelector(selectDefaultTheme);

  const isCustomTheme = useMemo(
    () => isThemeCustom(userProfile.profileTheme, availableThemes),
    [userProfile, availableThemes]
  );

  const selectedThemeName = useMemo(
    () =>
      getThemeName(
        isThemeCustom(userProfile.profileTheme, availableThemes),
        userProfile.profileTheme?.colors,
        availableThemes
      ),
    [userProfile, availableThemes]
  );

  const updateTheme = useCallback(
    (patch) =>
      db
        .doc(`userProfiles/${userProfile.id}`)
        .update({ profileTheme: { ...(userProfile.profileTheme || {}), ...patch } }),
    [userProfile]
  );

  const onThemeSelected = useCallback(
    ({ theme, oldThemeName }) => {
      if (theme.name === selectedThemeName) return;

      let colors = null;
      if (theme.isCustom) {
        colors = selectedThemeName === defaultTheme?.name ? defaultTheme?.colors : userProfile.profileTheme.colors;
      } else if (!theme.isDefault) {
        colors = theme.colors;
      }

      updateTheme({ colors, isCustom: !!theme.isCustom });
      track(PROFILE_THEME_CHANGED, { name: theme.name, oldTheme: oldThemeName });
    },
    [selectedThemeName, updateTheme, userProfile, defaultTheme]
  );
  const onCustomThemeColorUpdate = useCallback(
    (property, color) => updateTheme({ colors: { ...userProfile.profileTheme.colors, [property]: color } }),
    [updateTheme, userProfile.profileTheme]
  );

  const onFontChange = useCallback(
    ({ family }) => {
      updateTheme({ primaryFont: family });
      track(PROFILE_FONT_CHANGED, { name: family });
    },
    [updateTheme]
  );

  const containerRef = useRef(null);
  useEffect(() => {
    if (userProfile && containerRef.current) {
      applyTheme(userProfile.profileTheme?.colors, userProfile.profileTheme?.primaryFont, containerRef.current);
    }
  }, [userProfile]);

  return (
    <div ref={containerRef}>
      <Offcanvas isOpened={isOpened} width={368} fullscreenBreakpoint={380} onClose={onClose}>
        <Container>
          <RoomSettingsHeader label="Theme" onClose={onClose} hasBackButton={false} />
          <Content>
            <FontSelectContainer>
              <FontSelect
                label="Display Name Font"
                id={`profileTheme${id}`}
                dropdownDirection="down"
                currentFont={userProfile.profileTheme?.primaryFont}
                onFontChange={onFontChange}
              />
            </FontSelectContainer>
            <Field label="Theme">
              <ThemeSelect
                availableThemes={availableThemes}
                selectedThemeName={selectedThemeName}
                onThemeSelected={onThemeSelected}
              />
            </Field>
            {isCustomTheme ? (
              <CustomThemeSettings colors={userProfile.profileTheme.colors} onColorUpdate={onCustomThemeColorUpdate} />
            ) : null}
          </Content>
        </Container>
      </Offcanvas>
    </div>
  );
};

export default EditProfileTheme;

EditProfileTheme.propTypes = {
  userProfile: PropTypes.shape({
    id: PropTypes.string,
    profileTheme: PropTypes.shape({
      colors: PropTypes.shape({}),
      primaryFont: PropTypes.string,
    }),
  }).isRequired,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: var(--primary-background-alpha-90, rgba(255, 255, 255, 0.9));
  backdrop-filter: blur(20px);
  box-sizing: border-box;
  overflow: auto;
`;

const Content = styled.div`
  padding: 24px 16px;
`;

const FontSelectContainer = styled.div`
  margin-bottom: 16px;
`;
