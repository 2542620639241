import { css } from 'styled-components';
import {
  RoomItemStats,
  RoomItemTitle,
  RoomListItemSecondaryTitle,
  TileContainer,
  TileImage,
} from '../../web-lobby/shared-styles';
import { ContextMenuButton } from '../../web-lobby/components/room-items/RoomItemContextMenu';
import { RoomItemTimeSpent } from '../../web-lobby/components/room-items/components/RoomTimeSpent';
import { RoomStreakAndExpiryContainer } from '../../web-lobby/components/room-items/components/RoomStreak';

export const roomViewOverridesMixin = ({ isActive }: { isActive: boolean }) => css`
  ${RoomListItemSecondaryTitle} {
    color: var(--primary-foreground);
    font-size: 14px;
  }

  ${ContextMenuButton} {
    height: 20px;
    width: 20px;
  }

  ${TileContainer} {
    outline-width: 2px;
    outline-offset: -2px;
    aspect-ratio: auto;
  }

  &:hover ${TileContainer} {
    ${!isActive && 'border: 2px solid var(--primary-foreground);'}
  }

  &:not(:hover) {
    ${RoomItemTitle}, ${TileImage} {
      ${isActive ? '' : 'opacity: 0.5;'}
    }
  }

  ${RoomItemStats} {
    position: static;
  }

  ${RoomStreakAndExpiryContainer} {
    position: absolute;
    top: 28px;
    right: 8px;
  }

  ${RoomItemTimeSpent} {
    font-size: 12px;
    position: absolute;
    bottom: -112px;
    left: 5px;
    right: auto;
    top: auto;
    padding: 6px 12px;
    ${isActive && 'display: none;'}
  }
`;
