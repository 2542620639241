import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import '../../components/color-select';

// TODO: This is uncontrolled input based on web component based on vanilla js library.
// We need to either migrate to some React color picker or use native picker instead.
const ColorSelect = ({ hasAlpha, value, shouldFireIntermediateValues, onChange }) => {
  const pickerRef = useRef(null);
  useEffect(() => {
    if (!pickerRef.current) {
      return () => {};
    }

    const picker = pickerRef.current;
    const handler = (e) => {
      if (e.detail?.color && (shouldFireIntermediateValues || e.detail.inputComplete)) {
        onChange(e.detail.color, { isInputComplete: !shouldFireIntermediateValues || e.detail.inputComplete });
      }
    };

    picker.addEventListener('colorchange', handler);
    return () => picker.removeEventListener('colorchange', handler);
  }, [onChange, pickerRef, shouldFireIntermediateValues, hasAlpha]);

  return (
    <Container hasAlpha={hasAlpha}>
      <here-color-select ref={pickerRef} alpha={hasAlpha.toString()} initialValue={value} />
    </Container>
  );
};

export default ColorSelect;

ColorSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasAlpha: PropTypes.bool,
  shouldFireIntermediateValues: PropTypes.bool,
};

ColorSelect.defaultProps = {
  hasAlpha: false,
  shouldFireIntermediateValues: false,
};

const Container = styled.div`
  background: white;
  height: ${({ hasAlpha }) => (hasAlpha ? 254 : 218)}px;
  padding: 20px 10px;
  border-radius: 10px;
`;
