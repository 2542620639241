import React, { useState } from 'react';
import styled from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { MOBILE_BREAKPOINT } from '../../../../constants/breakpoints';
import { reactionButtonsSharedStyles } from './shared-styles';
import EmojiPicker from '../../../../components/emoji-picker';
import { Emoji } from '../../../../components/emoji-picker/constants';

type Props = {
  onEmojiPick: (emoji: Emoji) => void;
  emojiReactions: { reaction: string; creator: string; creatorDisplayName: string }[];
};

const toggleEmojiPickerImage = '/images/icons/toggle-emoji-picker.svg';

const ReactionsEmojiPickerButton: React.FC<Props> = ({ onEmojiPick, emojiReactions }) => {
  const [open, setOpen] = useState<boolean>(false);

  const onSelect = (emojiData: Emoji) => {
    onEmojiPick(emojiData);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <ToggleEmojiPickerButton hasReactions={!!emojiReactions.length} className="toggle-emoji-picker-button">
          <img src={toggleEmojiPickerImage} alt="toggle-emoji-picker" />
        </ToggleEmojiPickerButton>
      </PopoverTrigger>

      <PopoverContent side="top" data-state="open">
        <EmojiPicker onSelect={onSelect} />
      </PopoverContent>
    </Popover>
  );
};

export default ReactionsEmojiPickerButton;

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverContent = styled(PopoverPrimitive.Content)`
  background-color: white;
  border-radius: 10px;
  outline: none;
`;

const ToggleEmojiPickerButton = styled.div<{ hasReactions: boolean }>`
  ${reactionButtonsSharedStyles}

  // using opacity instead of display here because the popover needs the button
  // to still be there as an anchor even when the user is not hovered on it
  // opacity is set to 1 in chat-message-shared-styles.ts
  opacity: 0;

  display: flex;
  align-items: center;
  position: static;
  margin-left: 5px;
  height: 16px;
  width: 28px;
  background-color: #4e3e68;
  border-radius: 12px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: ${({ hasReactions }) => (hasReactions ? 'flex !important' : 'none')};
  }

  img {
    height: 20px;
    opacity: 0.75;
  }

  &:hover {
    opacity: 1;

    img {
      opacity: 1;
    }
  }
`;
