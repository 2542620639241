import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { selectRoomData } from '../../../store/room/selectors';
import { roomDataUpdateRequested } from '../../../store/room/store';
import RoomBordersPreview from './RoomBordersPreview';
import { getRoomDefaultViewport, isRectangleInBorders } from '../../../../viewport';
import { track } from '../../../../util/analytics-util';
import { ROOM_SIZES } from '../../../store/room/constants';
import { clamp } from '../../../../util';
import { SAVE_DEFAULT_VIEWPORT, SAVE_ROOM_BORDERS } from '../../../../constants/analytics-events/rooms-events';

const minBorderSize = ROOM_SIZES.S[2];
const minViewportSize = minBorderSize / 2;
const getSizeLabel = (borders) => {
  if (!borders) return '∞';

  let resultLabel = 'Custom';
  Object.entries(ROOM_SIZES).forEach(([label, borderDefinition]) => {
    if (borderDefinition && borderDefinition[2] === borders[2] && borderDefinition[3] === borders[3]) {
      resultLabel = label;
    }
  });
  return resultLabel;
};

const RoomBordersSettings = () => {
  const dispatch = useDispatch();

  const roomData = useSelector(selectRoomData);

  // Initializing borders and viewport, and also their refs (to have updated values when component is unmounted)
  const [draftBorders, setDraftBorders] = useState(roomData.borders);
  const [draftDefaultViewport, setDraftDefaultViewport] = useState(
    roomData.defaultViewport || getRoomDefaultViewport(roomData)
  );
  const draftBordersRef = useRef({ value: draftBorders, isChanged: false });
  const draftDefaultViewportRef = useRef({ value: draftDefaultViewport, isChanged: false });

  // Handling draft updates
  const onBordersUpdate = useCallback((borders) => {
    if (borders) {
      borders = [borders[0], borders[1], Math.max(borders[2], minBorderSize), Math.max(borders[3], minBorderSize)];
      const oldViewport = draftDefaultViewportRef.current.value;
      if (!isRectangleInBorders(oldViewport, borders)) {
        const newViewportWidth = clamp(oldViewport[2], minViewportSize, borders[2]);
        const newViewportHeight = clamp(oldViewport[3], minViewportSize, borders[3]);
        const newViewport = [
          clamp(oldViewport[0], borders[0], borders[0] + borders[2] - newViewportWidth),
          clamp(oldViewport[1], borders[1], borders[1] + borders[3] - newViewportHeight),
          newViewportWidth,
          newViewportHeight,
        ];
        setDraftDefaultViewport(newViewport);
        draftDefaultViewportRef.current = { value: newViewport, isChanged: true };
      }
    }

    setDraftBorders(borders);
    draftBordersRef.current = { value: borders, isChanged: true };
  }, []);
  const onDefaultViewportUpdate = useCallback((defaultViewport) => {
    defaultViewport = [
      defaultViewport[0],
      defaultViewport[1],
      Math.max(defaultViewport[2], minBorderSize / 2),
      Math.max(defaultViewport[3], minBorderSize / 2),
    ];
    if (draftBordersRef.current.value && !isRectangleInBorders(defaultViewport, draftBordersRef.current.value)) return;

    setDraftDefaultViewport(defaultViewport);
    draftDefaultViewportRef.current = { value: defaultViewport, isChanged: true };
  }, []);

  const sizeLabel = useMemo(() => getSizeLabel(draftBorders), [draftBorders]);

  const setPredefinedBorders = (label) => {
    if (label === sizeLabel) return;

    const newBorders =
      draftBorders && ROOM_SIZES[label]
        ? [draftBorders[0], draftBorders[1], ROOM_SIZES[label][2], ROOM_SIZES[label][3]]
        : ROOM_SIZES[label];
    if (newBorders) {
      const newViewport = getRoomDefaultViewport({ borders: newBorders });
      setDraftDefaultViewport(newViewport);
      draftDefaultViewportRef.current = { value: newViewport, isChanged: true };
    }

    onBordersUpdate(newBorders);
  };

  // Saving new data to firestore on unmounting the component (i.e. on exiting background settings)
  useEffect(
    () => () => {
      const patch = {};
      if (draftBordersRef.current.isChanged) {
        patch.borders = draftBordersRef.current.value;
        track(SAVE_ROOM_BORDERS, { size: getSizeLabel(draftBordersRef.current.value) });
      }
      if (draftDefaultViewportRef.current.isChanged) {
        patch.defaultViewport = draftDefaultViewportRef.current.value;
        track(SAVE_DEFAULT_VIEWPORT);
      }
      if (Object.keys(patch).length) {
        dispatch(roomDataUpdateRequested({ patch }));
      }
    },
    // Since it should happen only on component unmounting, ensuring it's gonna happen only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <LabelText>Room Size</LabelText>
      <BorderSizesList>
        {Object.keys(ROOM_SIZES).map((label) => (
          <BorderSize key={label} onClick={() => setPredefinedBorders(label)} isSelected={label === sizeLabel}>
            {label}
          </BorderSize>
        ))}
      </BorderSizesList>
      <RoomBordersPreview
        borders={draftBorders}
        onBordersUpdate={onBordersUpdate}
        defaultViewport={draftDefaultViewport}
        onDefaultViewportUpdate={onDefaultViewportUpdate}
        sizeLabel={sizeLabel}
      />
    </>
  );
};

export default RoomBordersSettings;

const LabelText = styled.div`
  font-size: 16px;
  margin: 4px 0 2px;
  color: var(--primary-foreground, inherit);
`;

const BorderSizesList = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BorderSize = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 auto;
  padding: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  font-size: 32px;
  font-weight: bold;
  background: var(--primary-background);
  border-radius: 8px;
  width: 22%;
  box-sizing: border-box;
  ${({ isSelected }) =>
    isSelected
      ? `
    color: var(--primary-foreground, black);
    border: 4px solid var(--primary-foreground, black);`
      : `
    color: var(--primary-foreground-alpha-50, rgba(0, 0, 0, 0.5));
    border: 1px solid var(--primary-foreground-alpha-30, rgba(0, 0, 0, 0.3));
    cursor: pointer;
  `}
`;
