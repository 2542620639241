import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Country } from 'react-phone-number-input';
import { track } from '../../../util/analytics-util';
import { useVerifyPhone, Values } from '../useVerifyPhone';

import PhoneNumber from '../PhoneNumber';
import {
  ContainerDefault,
  TitleContainer,
  ScreenTitle,
  ScreenSubTitle,
  FormContainer,
  NavButtonsContainer,
  SingleNextNavButton,
  IconContainer,
  TextNavButtonContainer,
  TextButton,
} from '../../sign-in-flow/shared-styled';
import HereErrorLabel from '../../components/HereErrorLabel';
import { formatPhone, handleErrorCode } from '../phone-util';

import { SEND_CODE_ERROR } from '../../../constants/analytics-events/phone-events';

const PhoneNumberInputForm = ({
  onSendCode,
  closePhoneModal,
}: {
  onSendCode: (code: string) => Promise<void>;
  closePhoneModal: () => void;
}) => {
  const onSubmit = useCallback(
    ({ countryCode, phoneNumber }: Values, { setSubmitting, setErrors }) => {
      onSendCode(formatPhone(countryCode, phoneNumber)).catch((e) => {
        track(SEND_CODE_ERROR, { error: e.code || e.message });
        setErrors({ phoneNumber: handleErrorCode(e.code) });
      });

      setSubmitting(false);
    },
    [onSendCode]
  );

  const { validatePhoneNumber } = useVerifyPhone();

  const initialValues = useMemo(() => ({ phoneNumber: '', countryCode: 'US' }), []);

  return (
    <ContainerDefault>
      <IconContainer>
        <PhoneImage src="/images/misc/phone.png" alt="phone" />
      </IconContainer>
      <TitleContainer>
        <ScreenTitle>Allow friends to find you via Phone</ScreenTitle>
        <ScreenSubTitle>Add your phone number below.</ScreenSubTitle>
      </TitleContainer>
      <FormContainer>
        <Formik
          initialValues={initialValues as Values}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
          validate={validatePhoneNumber}
        >
          {({ values, isSubmitting, errors, setFieldValue }) => (
            <Form className="form-container">
              <PhoneNumber
                flexBasis="25%"
                phoneNumber={values.phoneNumber}
                countryCode={values.countryCode}
                setFieldValue={setFieldValue}
                handleCountrySelect={(val: Country) => setFieldValue('countryCode', val)}
                hasError={errors.phoneNumber && errors.phoneNumber.length > 0}
              />
              {errors.phoneNumber && <HereErrorLabel errorMessage={errors.phoneNumber} />}
              <NavButtonsContainer>
                <SingleNextNavButton
                  type="submit"
                  disabled={isSubmitting || values.phoneNumber.length === 0}
                  width="75%"
                >
                  Send Code
                </SingleNextNavButton>
              </NavButtonsContainer>
              <TextNavButtonContainer>
                <TextButton type="button" onClick={closePhoneModal}>
                  Don't Add Phone
                </TextButton>
              </TextNavButtonContainer>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </ContainerDefault>
  );
};

const PhoneImage = styled.img`
  width: 120px;
`;

export default PhoneNumberInputForm;
