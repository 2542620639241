import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ResetIcon from '../../icons/ResetIcon';

const ResetButton = ({ onClick }) => (
  <Button onClick={onClick} className="here-button-minimal">
    <ResetIcon />
  </Button>
);

export default ResetButton;

ResetButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const Button = styled.button`
  width: 60px;
  padding: 13px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
