export const CHAT_CLOSE = 'Chat - Close'; // chatId, memberCount
export const CHAT_CLOSE_ALL = 'Chat - Close All';
export const CHAT_CREATE = 'Chat - Create'; // chatId, memberCount
export const CHAT_DELETE = 'Chat - Delete'; // chatId, memberCount
export const CHAT_LEAVE = 'Chat - Leave'; // chatId, memberCount, onlineMemberCount
export const CHAT_VIEW = 'Chat - View'; // chatId, memberCount, onlineMemberCount, openChats
export const CHAT_JOIN = 'Chat - Join'; // chatId, groupId
export const CHAT_COPY_LINK = 'Chat - Copy Link'; // chatId, memberCount, source, onlineMemberCount
export const CHAT_EDIT_NAME = 'Chat Edit - Name'; // chatId, font, memberCount
export const CHAT_EDIT_THEME = 'Chat Edit - Theme'; // chatId, theme
export const CHAT_EDIT_SKIN = 'Chat Edit - Skin Preview'; // chatId, memberCount, onlineMemberCount, category, name, oldName
export const CHAT_MEMBERS_ADD = 'Chat Members - Add'; // chatId, memberCount, onlineMemberCount
export const CHAT_MEMBERS_REMOVE = 'Chat Members - Remove'; // chatId, memberCount, onlineMemberCount
export const CHAT_NOTIFICATIONS_ENABLE = 'Chat Notifications - Enable'; // chatId, memberCount, onlineMemberCount
export const CHAT_NOTIFICATIONS_DISABLE = 'Chat Notifications - Disable'; // chatId, memberCount, onlineMemberCount
export const CHAT_SEND_MESSAGE = 'Chat - Send Message'; // chatId, memberCount, onlineMemberCount
export const CHAT_RECEIVE_MESSAGE = 'Chat - Receive Message'; // chatId, memberCount
export const CHAT_PROMOTE_MEMBER = 'Chat Members - Promote'; // role
export const CHAT_DEMOTE_MEMBER = 'Chat Members - Demote'; // role

export enum ChatBackgroundSources {
  UPLOAD = 'custom',
  DEFAULT = 'default',
}

export const SkinPropertyToCategory = {
  decorator: 'decorator',
  frame: 'frames',
  particle: 'particle',
};

export const fontPropertyToCategory = {
  title: 'font-title',
  section: 'font-section',
  system: 'font-system',
};

export const colorPropertyToCategory = {
  primaryBackground: 'main-color',
  primaryForeground: 'main-text',
  secondaryBackground: 'accent-color',
  secondaryForeground: 'accent-text',
};

export const ADD_ELEMENT = 'Lobby - Add Element'; // element, source
export const DELETE_ELEMENT = 'Lobby - Delete Element'; // element

export const OPEN_MENU = 'Lobby - Open Menu'; // menu
export enum OsMenus {
  DESKTOP = 'desktop',
  FRIENDS_ONLINE = 'friendsOnline',
  FRIENDS_SIDEBAR = 'friendsSidebar',
  MY_STUFF = 'myStuff',
  ROOMS = 'rooms',
  DISCOVER = 'discover',
}

export const MENU_TAB_CLICK = 'Lobby - Tab Click'; // menu, tab

export const EDIT_BACKGROUND = 'Lobby Edit - Background'; // source, background?
export enum EditBackgroundSources {
  COLOR = 'color',
  SPACES = 'spaces',
  PREDEFINED = 'unsplash',
  UPLOAD = 'custom',
}
export const EDIT_THEME = 'Lobby Edit - Theme'; // name, oldTheme
export const EDIT_THEME_COLOR = 'Lobby Edit - Custom Theme Color'; // property
export const EDIT_FONT = 'Lobby Edit - Font'; // font

// chat header button clicks:
export const CHAT_HEADER_ADD_MEMBERS_CLICK = 'Chat Header - Clicked Add Members Button';

export const ONBOARDING_STEP_COMPLETED = 'Onboarding - Step Completed';

export const CUSTOMIZE_CHAT_GIF_CLICK = 'Clicked customize chat gif';

export const USER_ACTIVITY_FEED_FIND_FRIENDS_CLICK = 'User Activity Feed - Find Friends Clicked';
