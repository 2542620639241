import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import { BoardElementControllerContext } from '../../common/contexts.ts';
import RemixMenu from './RemixMenu';
import { svgColorMixin } from '../../mixins';
import { inverseTheme } from '../../../util/theming-util';

import SprayIcon from '../../../../assets/icons/spray.svg';

const RemixButton = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { setIsTemporaryOnTop, setAreControlButtonsAlwaysVisible } = useContext(BoardElementControllerContext);
  useEffect(() => {
    setAreControlButtonsAlwaysVisible(isOpened);
    setIsTemporaryOnTop(isOpened);
  }, [isOpened, setAreControlButtonsAlwaysVisible, setIsTemporaryOnTop]);

  const isThemeInitialized = useRef(false);
  const onContentRefSet = useCallback((element) => {
    if (element && !isThemeInitialized.current) {
      inverseTheme(element);
      isThemeInitialized.current = true;
    }
  }, []);

  return (
    <Popover.Root open={isOpened} onOpenChange={setIsOpened}>
      <Popover.Trigger asChild>
        <Button>
          <SprayIcon />
          Remix
        </Button>
      </Popover.Trigger>
      <Content sideOffset={10} ref={onContentRefSet}>
        <RemixMenu onItemSelected={useCallback(() => setIsOpened(false), [])} />
      </Content>
    </Popover.Root>
  );
};

export default RemixButton;

const Button = styled.div`
  height: 30px;
  border-radius: 15px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #12002d;
  font-size: 12px;
  padding: 4px 8px;
  user-select: none;

  svg {
    width: 18px;
    margin-right: 2px;
    ${svgColorMixin('#12002d')}
  }
`;

const Content = styled(Popover.Content)`
  background: var(--primary-background-alpha-80, rgba(255, 255, 255, 0.8));
  border-radius: 10px;

  &:focus-visible {
    outline: none;
  }
`;
