import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import log from '../../../../log';
import { addCustomChatSound, deleteChatSound, updateChatSound } from '../../../../api/boards-api';

// store
import {
  selectCurrentUserFontColor,
  selectCurrentUserFontFamily,
  selectCurrentUserFontSize,
  selectCurrentUserMessageColor,
} from '../../../store/users/selectors';
import { selectOrderedUniqueChatSounds, selectRoomId } from '../../../store/room/selectors';
import { toggleDisableSound } from '../../../store/room/store';

// components
import ChatSoundRow from './ChatSoundRow';
import ChatSoundModal from './modal/ChatSoundModal';
import ChatSoundModalContent from './modal/ChatSoundModalContent';

// utils
import { playSoundEffect } from '../../../../util/sound-fx-util';
import { track } from '../../../../util/analytics-util';

// constants
import {
  ADD_CUSTOM_CHAT_SOUND,
  DELETE_CUSTOM_CHAT_SOUND,
  EDIT_CUSTOM_CHAT_SOUND,
  PLAY_CHAT_SOUND,
} from '../../../../constants/analytics-events/chat-sound-events';

const ChatSounds = () => {
  const dispatch = useDispatch();

  const sounds = useSelector(selectOrderedUniqueChatSounds);
  const messageColor = useSelector(selectCurrentUserMessageColor);
  const fontSize = useSelector(selectCurrentUserFontSize);
  const fontColor = useSelector(selectCurrentUserFontColor);
  const fontFamily = useSelector(selectCurrentUserFontFamily);
  const roomId = useSelector(selectRoomId);

  const [isCreateSounsModalOpen, setIsCreateSoundModalOpen] = useState(false);
  const [soundToEditId, setSoundToEditId] = useState(null);

  const soundToEdit = useMemo(() => sounds.find((sound) => sound.id === soundToEditId), [soundToEditId, sounds]);

  const playSound = (id: string) => {
    const soundToPlay = sounds.find((sound) => sound.id === id);
    if (soundToPlay) {
      track(PLAY_CHAT_SOUND);
      playSoundEffect(soundToPlay.soundUrl);
    }
  };

  const toggle = (soundId: string, currentState: boolean) => {
    dispatch(toggleDisableSound({ soundId, currentState }));
  };

  const onEditSoundClick = (id: string) => {
    setSoundToEditId(id);
  };

  const onAddSoundClick = () => {
    setIsCreateSoundModalOpen(true);
  };

  const saveSound = async ({ soundUrl, trigger }: { soundUrl: string; trigger: string }) => {
    try {
      track(ADD_CUSTOM_CHAT_SOUND);
      await addCustomChatSound(roomId, { soundUrl, trigger });
      setIsCreateSoundModalOpen(false);
    } catch (error) {
      log.error('Error adding custom chat sound', error);
    }
  };

  const patchSound = async ({ soundUrl, trigger, soundId }: { soundUrl: string; trigger: string; soundId: number }) => {
    try {
      track(EDIT_CUSTOM_CHAT_SOUND);
      await updateChatSound(roomId, soundId, { soundUrl, trigger });
      setSoundToEditId(null);
    } catch (error) {
      log.error('Error updating chat sound', error);
    }
  };

  const deleteSound = async (soundId: number) => {
    try {
      track(DELETE_CUSTOM_CHAT_SOUND);
      await deleteChatSound(roomId, soundId);
      setSoundToEditId(null);
    } catch (error) {
      log.error('Error deleting chat sound', error);
    }
  };

  return (
    <Container>
      <AddSoundButton className="here-button-ghost" onClick={onAddSoundClick}>
        + Add Sound
      </AddSoundButton>

      {sounds.map((sound) => (
        <ChatSoundRow
          key={sound.id}
          id={sound.id}
          trigger={sound.trigger}
          isEnabled={sound.isEnabled || sound.isEnabled === undefined}
          isDefault={sound.isDefault}
          // text bubble params
          messageColor={messageColor}
          fontSize={fontSize}
          fontColor={fontColor}
          fontFamily={fontFamily}
          // handlers
          onPlaySound={playSound}
          onEditSoundClick={onEditSoundClick}
          onToggleDisableSound={toggle}
        />
      ))}

      {isCreateSounsModalOpen ? (
        <ChatSoundModal onOverlayClick={() => setIsCreateSoundModalOpen(false)}>
          <Modal>
            <ChatSoundModalContent roomId={roomId} onSave={saveSound} />
          </Modal>
        </ChatSoundModal>
      ) : null}

      {soundToEdit ? (
        <ChatSoundModal onOverlayClick={() => setSoundToEditId(null)}>
          <Modal>
            <ChatSoundModalContent
              roomId={roomId}
              soundToEdit={soundToEdit}
              onSave={patchSound}
              onDelete={deleteSound}
            />
          </Modal>
        </ChatSoundModal>
      ) : null}
    </Container>
  );
};

const Container = styled.div``;

const AddSoundButton = styled.button`
  padding: 10px 0;
  width: 100%;
`;

const Modal = styled.div`
  padding: 36px 54px;
  background-color: white;
  border-radius: 10px;
`;

export default ChatSounds;
