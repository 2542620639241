import React from 'react';
import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

// components
import { svgColorMixin } from '../../../mixins';
import Tooltip from '../../../components/Tooltip';

// icons
import PlaySoundIcon from '../../../../../assets/icons/play-in-circle.svg';
import { SpeakerIcon } from '../../../icons';
import CrossedSpeakerIcon from '../../../../../assets/icons/crossed-speaker.svg';
import PenIcon from '../../../../../assets/icons/pen.svg';

interface ChatSoundRowProps {
  id: string;
  trigger: string;
  isEnabled: boolean;
  isDefault: boolean;

  // text bubble params
  messageColor: string;
  fontSize: string;
  fontColor: string;
  fontFamily: string;

  // handlers
  onPlaySound: (trigger: string) => void;
  onEditSoundClick: (id: string) => void;
  onToggleDisableSound: (id: string, currentState: boolean) => void;
}

const ChatSoundRow = ({
  id,
  trigger,
  isEnabled,
  isDefault,

  // text bubble params
  messageColor,
  fontSize,
  fontColor,
  fontFamily,

  // handlers
  onToggleDisableSound,
  onEditSoundClick,
  onPlaySound,
}: ChatSoundRowProps) => (
  <Container isDisabled={!isEnabled}>
    <TextBubble
      messageColor={messageColor}
      messageFontSize={fontSize}
      messageFontColor={fontColor}
      messageFontFamily={fontFamily}
    >
      {trigger}
    </TextBubble>

    <ButtonsContainer>
      <Tooltip
        content={
          <TooltipStyledContent side="top" align="center">
            <TooltipText>Play sound</TooltipText>
          </TooltipStyledContent>
        }
      >
        <Button onClick={() => onPlaySound(id)}>
          <PlaySoundIcon />
        </Button>
      </Tooltip>

      {!isDefault ? (
        <Tooltip
          content={
            <TooltipStyledContent side="top" align="center">
              <TooltipText>Edit sound</TooltipText>
            </TooltipStyledContent>
          }
        >
          <Button iconSize={16} onClick={() => onEditSoundClick(id)}>
            <PenIcon />
          </Button>
        </Tooltip>
      ) : null}

      <Tooltip
        content={
          <TooltipStyledContent side="top" align="center">
            <TooltipText>{isEnabled ? 'Disable' : 'Enable'}</TooltipText>
          </TooltipStyledContent>
        }
      >
        <Button onClick={() => onToggleDisableSound(id, isEnabled)}>
          {isEnabled ? <SpeakerIcon /> : <CrossedSpeakerIcon />}
        </Button>
      </Tooltip>
    </ButtonsContainer>
  </Container>
);

const Container = styled.div<{ isDisabled: boolean }>`
  padding: 17px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.35 : 1)};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const TextBubble = styled.div<{
  messageColor: string;
  messageFontSize: string;
  messageFontColor: string;
  messageFontFamily: string;
}>`
  padding: 9px 14px;
  color: ${({ messageFontColor }) => messageFontColor};
  font-family: ${({ messageFontFamily }) => messageFontFamily};
  font-size: ${({ messageFontSize }) => messageFontSize};
  background-color: ${({ messageColor }) => messageColor};
  border-radius: 10px 10px 10px 0;
`;

const ButtonsContainer = styled.div`
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const Button = styled.button<{ iconSize?: number }>`
  opacity: 0.75;
  border: none;
  background: none;
  cursor: pointer;

  ${svgColorMixin('var(--primary-foreground, #12002d)')}

  &:hover {
    opacity: 1;
  }

  svg {
    width: ${({ iconSize }) => `${iconSize || 18}px`};
  }
`;

const TooltipStyledContent = styled(TooltipPrimitive.Content)`
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #12002d;
`;

const TooltipText = styled.p`
  margin: 0;
  color: white;
  font-size: 11px;
`;

export default ChatSoundRow;
