import { css } from 'styled-components';

export const chatButtonMixin = () => css`
  padding: 0 4px 0 0px;
  display: flex;
  align-items: center;
  vertical-align: sub;
  background: transparent;
  border: none;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
    outline: none;
  }

  svg {
    height: 18px;
  }
`;
