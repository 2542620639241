import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as Popover from '@radix-ui/react-popover';

import { ChatPermissionsContext } from '../../../../common/contexts';
import ChatUserData from '../ChatUserData';
import { ChatMember, ChatMemberRoles } from '../../../../../definitions/message';
import { selectCurrentUserId } from '../../../../store/users/selectors';
import AdminIcon from '../../../../../../assets/icons/admin-crown.svg';
import OwnerIcon from '../../../../../../assets/icons/owner-crown.svg';
import CrossIcon from '../../../../../../assets/icons/dismiss.svg';
import { setMemberRole } from '../../../../store/messaging/actions';
import { svgColorMixin } from '../../../../mixins';

interface Props {
  user: ChatMember;
  chatId: string;
}

const AdminListItem = ({ user, chatId }: Props) => {
  const dispatch = useDispatch();

  const permissions = useContext(ChatPermissionsContext);
  const currentUserId = useSelector(selectCurrentUserId);
  const isOwner = user.role === ChatMemberRoles.Owner;
  const canPerformAction = user.userId !== currentUserId && permissions.canChangeMemberRole && !isOwner;

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Container canPerformAction={canPerformAction} isOwner={isOwner}>
          <ChatUserData userId={user.userId} isSelected={isOwner} />
          <IconContainer isOwner={isOwner}>
            {user.role === ChatMemberRoles.Admin ? <AdminIcon /> : <OwnerIcon />}
          </IconContainer>
        </Container>
      </Popover.Trigger>
      <Popover.Close asChild>
        <Menu side="right" className="prevent-on-click-outside">
          <MenuItem
            onClick={() => dispatch(setMemberRole({ chatId, memberId: user.userId, role: ChatMemberRoles.Member }))}
          >
            <CrossIcon />
            Remove Admin
          </MenuItem>
        </Menu>
      </Popover.Close>
    </Popover.Root>
  );
};

export default AdminListItem;

const Container = styled.div<{ canPerformAction: boolean; isOwner: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  padding: 0 10px;
  height: 40px;
  margin: 4px 0;
  background: ${({ isOwner }) => (isOwner ? 'var(--secondary-background-alpha-30)' : 'rgba(255, 255, 255, 0.1)')};
  ${({ canPerformAction }) => (canPerformAction ? 'cursor: pointer;' : 'pointer-events: none;')}
`;

const IconContainer = styled.div<{ isOwner: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  margin-left: 2px;
  background: ${({ isOwner }) => (isOwner ? 'var(--secondary-foreground)' : 'var(--secondary-background)')};
`;

const Menu = styled(Popover.Content)`
  background: white;
  padding: 4px;
  border-radius: 8px;

  &:focus-visible {
    outline: none;
  }
`;

const MenuItem = styled.div`
  cursor: pointer;
  color: black;
  font-size: 12px;
  padding: 6px 18px;
  border-radius: 8px;

  ${svgColorMixin('black')}

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  svg {
    margin-right: 4px;
  }
`;
