// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import UIKit from 'uikit';

import React from 'react';
import styled from 'styled-components';

import { renderFriendsModal } from '../render';

// utils
import { track } from '../../../util/analytics-util';

// constants
import { VIEW_FRIENDS } from '../../../constants/analytics-events/friend-events';
import { FRIENDS_MODAL_TABS } from '../../../constants/friends-modal-constants';

// icons
import SearchIcon from '../../../../assets/icons/search.svg';

const closeRoomSettingsModal = () => {
  const roomSettingsModal = document.querySelector('here-room-settings');
  if (roomSettingsModal) roomSettingsModal.classList.remove('uk-flex', 'uk-open');
};

const FriendsInviteEmptyState = () => {
  const onClick = () => {
    // hide invite modal
    UIKit.dropdown('.invite-menu').hide(0);

    // for case where profile is opened from settings modal:
    closeRoomSettingsModal();

    // show friends modal
    renderFriendsModal(FRIENDS_MODAL_TABS.add);

    // this button opens directly to friends list so:
    track(VIEW_FRIENDS, { source: 'friends invite empty state' });
  };

  return (
    <Container>
      <Title>Or</Title>

      <Button onClick={onClick} className="here-button-regular">
        <SearchIcon />
        Find Friends
      </Button>

      <Subtitle>you already have on Here</Subtitle>
    </Container>
  );
};

const Container = styled.div`
  padding: 40px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #00000010;
  background-image: url(images/icons/invite-friends-empty-state-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const Title = styled.h4`
  display: flex;
  align-items: center;
  color: var(--primary-foreground, #12002d);
  font-size: 14px;
  font-weight: bolder;
  opacity: 0.6;

  &::before,
  &::after {
    content: '';
    margin: 0 10px;
    height: 2px;
    width: 40px;
    background-color: var(--primary-foreground, #12002d);
  }
`;

const Subtitle = styled.h5`
  margin-top: 15px;
  color: var(--primary-foreground, #12002d);
  font-size: 14px;
  font-weight: bolder;
`;

const Button = styled.button`
  margin-top: 15px;
  padding: 11px;
  width: 100%;
  font-size: 1rem;

  svg {
    margin-right: 7px;
    opacity: 0.5;
  }
`;

export default FriendsInviteEmptyState;
