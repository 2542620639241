import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectRoomData, selectRoomId } from '../../store/room/selectors';

// An adapter to call web component methods on changing redux data.
// Should be removed once all tabs are re-written in React.
export function useSettingsTabsAdapter(tabRef) {
  const currentRoomData = useSelector(selectRoomData);
  useEffect(() => {
    if (tabRef.current?.onBoardUpdate) tabRef.current.onBoardUpdate(currentRoomData);
  }, [currentRoomData, tabRef]);

  const currentRoomId = useSelector(selectRoomId);
  useEffect(() => {
    if (tabRef.current?.onSwitchingBoards) tabRef.current.onSwitchingBoards(currentRoomId);
  }, [currentRoomId, tabRef]);
}
