import React from 'react';
import styled from 'styled-components';

import { useMultistep } from '../../../../hooks/useMultistep';
import ReportFormStep from './steps/ReportFormStep';
import PostReportStep from './steps/PostReportStep';
import { Overlay } from '../../shared-styles';

interface ReportUserModalProps {
  userId: string;
  chatId: string;
  reportedMessage?: string;
  close: () => void;
}

const ReportUserModal = ({ userId, chatId, reportedMessage, close }: ReportUserModalProps) => {
  const { step } = useMultistep([
    (goNext: () => void) => (
      <ReportFormStep userId={userId} chatId={chatId} reportedMessage={reportedMessage} close={close} goNext={goNext} />
    ),
    () => <PostReportStep userId={userId} close={close} />,
  ]);

  return (
    <Overlay onClick={close}>
      <Container onClick={(e) => e.stopPropagation()}>{step}</Container>
    </Overlay>
  );
};

const Container = styled.div`
  padding: 40px 60px;
  background-color: white;
  border-radius: 20px;
`;

export default ReportUserModal;
