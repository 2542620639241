import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { EmojiData, Picker } from 'emoji-mart';

import { sendOutsideReactionMessage } from '../../../../api/feed-api';
import log from '../../../../log';
import { startDroppingReactionAnimation, startScalingInReactionAnimation } from '../../../../reactions';
import { addOutsideReaction, getRoomLatestOutsideReaction } from '../../../../outside-reactions-data';

// components
import Tooltip from '../../../components/Tooltip';
import { svgColorMixin } from '../../../mixins';

// utils
import { track } from '../../../../util/analytics-util';

// constants
import { SEND_OUTSIDE_REACTION } from '../../../../constants/analytics-events/chat-events';

// icons
import BoldArrowIcon from '../../../../../assets/icons/arrows/bold-arrow.svg';

const SEND_REACTION_COOLDOWN_MS = 60000;
const SEND_REACTION_COOLDOWN_TICK_MS = 1000;

interface OutsideReactionProps {
  roomId: string;
  type: 'primary' | 'secondary';
}

let hoverIntervalId: ReturnType<typeof setInterval> | null = null;

const getIsDisabled = (lastSendingTimestamp: number) => Date.now() - lastSendingTimestamp < SEND_REACTION_COOLDOWN_MS;

const getCooldownProgress = (lastSendingTimestamp: number) =>
  +((Date.now() - lastSendingTimestamp) / SEND_REACTION_COOLDOWN_MS).toFixed(2);

const OutsideReaction = ({ roomId, type }: OutsideReactionProps) => {
  const [emojiToSend, setEmojiToSend] = useState('💖️');

  const [lastSendingTimestamp, setLastSendingTimestamp] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [cooldownProgress, setCooldownProgress] = useState(1);

  useEffect(
    () => () => {
      if (hoverIntervalId) {
        clearInterval(hoverIntervalId);
      }
    },
    []
  );

  useEffect(() => {
    const outsideReactionData = getRoomLatestOutsideReaction(roomId);
    if (outsideReactionData) {
      setLastSendingTimestamp(outsideReactionData.timestamp);
      setCooldownProgress(getCooldownProgress(outsideReactionData.timestamp));
      setIsDisabled(getIsDisabled(outsideReactionData.timestamp));
    }
  }, [roomId]);

  useEffect(() => {
    setIsDisabled(getIsDisabled(lastSendingTimestamp));
    setCooldownProgress(getCooldownProgress(lastSendingTimestamp));

    const intervalId = setInterval(() => {
      const disabled = getIsDisabled(lastSendingTimestamp);
      setIsDisabled(disabled);

      if (!disabled) {
        clearInterval(intervalId);
      }
    }, SEND_REACTION_COOLDOWN_TICK_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [lastSendingTimestamp]);

  const onMouseEnter = () => {
    hoverIntervalId = setInterval(() => {
      startDroppingReactionAnimation({
        reaction: emojiToSend,
        reactionsNumber: 1,
        reactionsInterval: 10,
        fallingReactionsParent: document.getElementById(`room-${roomId}-outside-falling-reactions-container`),
      });
    }, 200);
  };

  const onMouseLeave = () => {
    if (hoverIntervalId) {
      clearInterval(hoverIntervalId);
    }
  };

  const onClick = async () => {
    try {
      const outsideReactionData = addOutsideReaction(roomId);
      setLastSendingTimestamp(outsideReactionData.timestamp);
      onMouseLeave();

      await sendOutsideReactionMessage(`boards/${roomId}/feedItems`, emojiToSend);

      track(SEND_OUTSIDE_REACTION, { reaction: emojiToSend });

      startDroppingReactionAnimation({
        reaction: emojiToSend,
        reactionsNumber: 50,
        reactionsInterval: 15,
        fallingReactionsParent: document.getElementById(`room-${roomId}-outside-falling-reactions-container`),
      });

      startScalingInReactionAnimation({
        reaction: emojiToSend,
        scalingInReactionsParent: document.getElementById(`room-${roomId}-outside-scale-in-reactions-container`),
      });
    } catch (e) {
      log.error(e);
    }
  };

  const tooltipContent = (
    <TooltipStyledContent side="top" sideOffset={4}>
      <TooltipText>Send {emojiToSend}</TooltipText>
    </TooltipStyledContent>
  );

  const onEmojiSelected = (emoji: EmojiData) => {
    // TODO: add analytics

    // TODO: investigate why ts doesn't like it
    // TODO: investigate how it works with custom emojis
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setEmojiToSend(emoji.native);
  };

  return (
    <Container>
      <Popover>
        <PopoverTrigger asChild>
          <SettingsButton buttonType={type}>
            <SettinsButtonText>{emojiToSend}</SettinsButtonText>
          </SettingsButton>
        </PopoverTrigger>
        <PopoverContent side="top">
          <Picker
            set="twitter"
            sheetSize={16}
            emojiSize={22}
            perLine={9}
            showPreview={false}
            showSkinTones={false}
            onSelect={onEmojiSelected}
            native
          />
        </PopoverContent>
      </Popover>

      <Tooltip content={tooltipContent}>
        <OutsideReactionButton
          buttonType={type}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          disabled={isDisabled}
        >
          <SendArrowWrapper>
            <BoldArrowIcon />
          </SendArrowWrapper>

          {isDisabled ? <CooldownProgress progress={cooldownProgress} /> : null}
        </OutsideReactionButton>
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 7.5px;
  align-items: center;
`;

const buttonSizePx = 30;

const OutsideReactionButton = styled.button<{ buttonType: 'primary' | 'secondary' }>`
  position: relative;
  padding: ${({ buttonType }) => (buttonType === 'primary' ? '5px' : '0')};
  height: ${buttonSizePx}px;
  width: ${buttonSizePx}px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  line-height: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #ffffff77;
  }
`;

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverContent = styled(PopoverPrimitive.Content)`
  background-color: white;
  border-radius: 10px;
  outline: none;
`;

const SettinsButtonText = styled.span``;

const SettingsButton = styled.button<{ buttonType: 'primary' | 'secondary' }>`
  position: relative;
  height: ${buttonSizePx}px;
  width: ${buttonSizePx}px;
  background: none;
  border: none;
  cursor: pointer;

  ${svgColorMixin('white')}

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: ${buttonSizePx}px;
    width: ${buttonSizePx}px;
    z-index: 1;
    opacity: 0;
    background-image: url('/images/icons/pen.svg');
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    ${SettinsButtonText} {
      font-size: ${({ buttonType }) => (buttonType === 'primary' ? '20px' : '17px')};
      opacity: 0.5;
    }
  }

  ${SettinsButtonText} {
    font-size: ${({ buttonType }) => (buttonType === 'primary' ? '20px' : '17px')};
    opacity: 1;
  }
`;

const cooldownProgressAnimation = keyframes`
  100% {
    height: 100%;
    display: none;
  }
`;

const CooldownProgress = styled.div<{ progress: number }>`
  position: absolute;
  bottom: 0;
  height: ${({ progress }) => progress * 100}%;
  width: 100%;
  z-index: -1;
  animation: ${({ progress }) => css`
    ${cooldownProgressAnimation} ${SEND_REACTION_COOLDOWN_MS * (1 - progress)}ms linear forwards 1
  `};

  background-color: rgba(255, 255, 255, 0.3);
`;

const TooltipStyledContent = styled(TooltipPrimitive.Content)`
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #12002d;
`;

const TooltipText = styled.p`
  margin: 0;
  color: white;
  font-size: 11px;
  font-weight: normal;
`;

const SendArrowWrapper = styled.span`
  transform: rotate(-90deg);
`;

export default OutsideReaction;
