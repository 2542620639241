import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import Marquee from 'react-fast-marquee';

import ChatWindow from '../../chats/ChatWindow';
import {
  selectActiveChatId,
  selectAllChatMessages,
  selectChatByChatId,
  selectChatHasMore,
  selectDraftMessage,
  selectChatMemberIdsByChatId,
  selectChatPermissions,
  selectIsAPublicChat,
} from '../../store/messaging/selectors';
import { useLocalMessagesList } from '../../hooks/useLocalMessagesList';
import { addMessage, deleteMessage, fetchChat } from '../../store/messaging/reducer';
import { getGroupChatParams, track, trackInForeground } from '../../../util/analytics-util';
import { DELETE_DM } from '../../../constants/analytics-events/dm-events';
import {
  selectCurrentUserFontColor,
  selectCurrentUserFontFamily,
  selectCurrentUserFontSize,
  selectCurrentUserHasCustomizedChat,
  selectCurrentUserId,
  selectCurrentUserMessageColor,
} from '../../store/users/selectors';
import { useTypingUsersList } from '../../hooks/feed/useTypingUsersList';
import { messageTypes } from '../../../constants/chat-constants';
import ChatInput from '../../chats/chat-input/ChatInput';
import { CHANGE_MESSAGE_FONT_STYLE_SOURCES } from '../../../constants/analytics-events/chat-events';
import { gifPickerCallers } from '../../../constants/image-constants';
import { applyChatTheme } from '../../../util/theming-util';
import { selectCurrentTheme } from '../../store/room/selectors';
import ChatHeader from './chat-header';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Background, ChatContainer, Container, chatInputTheme, chatWindowTheme } from './shared-styles';
import RoomSettings from './room-settings/RoomSettings';
import ChatSkinningSettings from './skinning';
import { selectOpenChatIds } from '../../store/os/selectors';
import SkinningFrameWrapper from './skinning/frame/SkinningFrameWrapper';
import SkinningParticlesEmitter from './skinning/particles/SkinningParticlesEmitter';
import { CHAT_CLOSE, CHAT_VIEW, CUSTOMIZE_CHAT_GIF_CLICK } from '../analytics';
import { selectCurrentScreenId } from '../../store/signing-in/selectors';
import { CreateOsChatFlowSteps } from '../../sign-in-flow/constants';
import { updateUserProfile } from '../../store/users/api';
import { ChatPermissionsContext } from '../../common/contexts';
import EditChatSettings from './edit-chat';

const enum SettingsWindows {
  NONE = '',
  SETTINGS = 'settings',
  THEME = 'theme',
  VIDEO = 'video',
}

interface Props {
  chatId: string;
  onClose: () => void;
  autoFocusInput?: boolean;
}

const sideWindowWidth = 300;
const sideWindowGap = 3;

const OsChatWindow: React.FC<Props> = ({ chatId, onClose, autoFocusInput = true }) => {
  const dispatch = useDispatch();

  const containerRef = useRef(null);
  const chatViewEventTrackedRef = useRef(false);

  const [openSettingsWindow, setOpenSettingsWindow] = useState(SettingsWindows.NONE);

  useOnClickOutside(containerRef, () => {
    setOpenSettingsWindow(SettingsWindows.NONE);
  });

  const onSettingsWindowClick = (window: SettingsWindows) => {
    if (openSettingsWindow === window) {
      setOpenSettingsWindow(SettingsWindows.NONE);
    } else {
      setOpenSettingsWindow(window);
    }
  };

  const currentUserId = useSelector(selectCurrentUserId);
  const hasCustomizedChat = useSelector(selectCurrentUserHasCustomizedChat);
  const currOnboardingScreen = useSelector(selectCurrentScreenId);
  const isInOnboarding =
    currOnboardingScreen === CreateOsChatFlowSteps.CHAT_INSTRUCTIONS ||
    currOnboardingScreen === CreateOsChatFlowSteps.CHAT_COMPLETION;

  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const memberIds = useSelector((state) => selectChatMemberIdsByChatId(state, chat?.id));
  const isActive = useSelector((state) => selectActiveChatId(state) === chatId);
  const openChatIds = useSelector(selectOpenChatIds);

  useEffect(() => {
    if (isActive && !chatViewEventTrackedRef.current) {
      trackInForeground(CHAT_VIEW, getGroupChatParams({ chatId, openChats: openChatIds.length }));
      chatViewEventTrackedRef.current = true;
    }
  }, [chatId, isActive, chat?.groupId, openChatIds]);

  useEffect(() => {
    if (chatId) {
      dispatch(fetchChat({ chatId }));
    }
  }, [chatId, dispatch]);

  const boardTheme = useSelector(selectCurrentTheme);

  useEffect(() => {
    if (!containerRef.current) return;

    // if group theme for chat is available, use it, otherwise use hereos board theme
    const theme = chat?.theme?.colors && chat?.theme?.primaryFont ? chat?.theme : boardTheme;
    applyChatTheme(theme, containerRef.current);
  }, [chat?.theme, boardTheme]);

  const messagesList = useSelector((state) => selectAllChatMessages(state, chatId));
  const draftMessage = useSelector((state) => selectDraftMessage(state, chatId));
  const hasMore = useSelector((state) => selectChatHasMore(state, chatId) || false);

  const { resultMessagesList, addMessageLocally, deleteLocalMessage } = useLocalMessagesList({
    messagesList,
  });

  const loadMoreMessages = useCallback(() => {
    if (chatId) {
      dispatch(fetchChat({ chatId, isPagination: true }));
    }
  }, [dispatch, chatId]);

  const storageFolder = useMemo(
    () => (currentUserId ? `users/${currentUserId}/dms${chatId ? `/${chatId}` : ''}` : null),
    [currentUserId, chatId]
  );

  const fontColor = useSelector(selectCurrentUserFontColor);
  const messageColor = useSelector(selectCurrentUserMessageColor);
  const fontSize = useSelector(selectCurrentUserFontSize);
  const fontFamily = useSelector(selectCurrentUserFontFamily);

  const { typingUsersList } = useTypingUsersList(chatId);

  const onSendMessage = useCallback(
    (message: string) => {
      dispatch(
        addMessage({
          chatId,
          type: messageTypes.TEXT,
          text: message,
          fontColor,
          messageColor,
          fontSize,
          fontFamily,
          groupId: chat?.groupId,
        })
      );
    },
    [chatId, dispatch, fontColor, messageColor, fontSize, fontFamily, chat?.groupId]
  );

  const onDeleteMessage = useCallback(
    (messageId) => {
      dispatch(deleteMessage({ messageId, chatId }));
      track(DELETE_DM);
    },
    [dispatch, chatId]
  );

  const onMessageUploadSuccess = useCallback(
    ({ imageUrl, storagePath }: { imageUrl: string; storagePath: string }) => {
      dispatch(addMessage({ chatId, imageUrl, storagePath, type: messageTypes.IMAGE }));
    },
    [chatId, dispatch]
  );

  const onMessageUploadFail = useCallback(
    (errorData) => {
      const id = `dmwindow-upload-failed-${chatId}`;
      addMessageLocally({
        id,
        type: messageTypes.UPLOAD_ERROR,
        errorType: errorData.type,
        creator: currentUserId,
        text: errorData.message,
        createdAt: new Date(),
        deleteSelf: () => deleteLocalMessage(id),
      });
    },
    [currentUserId, addMessageLocally, deleteLocalMessage, chatId]
  );

  const inputEntry = useMemo(
    () => (
      <ThemeProvider theme={chatInputTheme}>
        <ChatInput
          type={CHANGE_MESSAGE_FONT_STYLE_SOURCES.DM}
          chatId={chatId}
          elementId={`DMChatInput-${chatId}`}
          caller={gifPickerCallers.DM}
          storageFolder={storageFolder}
          draftMessage={draftMessage}
          onSendMessage={onSendMessage}
          onMessageUploadSuccess={onMessageUploadSuccess}
          onMessageUploadFail={onMessageUploadFail}
          userId={currentUserId}
          autoFocus={autoFocusInput}
        />
      </ThemeProvider>
    ),
    [
      chatId,
      draftMessage,
      storageFolder,
      onSendMessage,
      onMessageUploadSuccess,
      onMessageUploadFail,
      currentUserId,
      autoFocusInput,
    ]
  );

  const closeChat = () => {
    onClose();
    track(CHAT_CLOSE, getGroupChatParams({ chatId }));
  };

  const setHasCustomizedChat = useCallback(async () => {
    if (!hasCustomizedChat) {
      await updateUserProfile(currentUserId, { hasCustomizedChat: true });
    }
  }, [currentUserId, hasCustomizedChat]);

  const onCustomizeGifClick = () => {
    onSettingsWindowClick(SettingsWindows.THEME);
    track(CUSTOMIZE_CHAT_GIF_CLICK);
    setHasCustomizedChat();
  };

  const [hasEnoughSpaceOnLeft, setHasEnoughSpaceOnLeft] = useState(true);
  useEffect(() => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (!containerRect) return;
    setHasEnoughSpaceOnLeft(
      containerRect.left + containerRect.width + sideWindowWidth + sideWindowGap < window.innerWidth
    );
  }, [openSettingsWindow]);

  const background = chat?.theme?.skin?.background?.original || chat?.background?.original;

  const permissions = useSelector((state) => selectChatPermissions(state, chatId));

  const showPublicChatMarquee = useSelector((state) => selectIsAPublicChat(state, chatId)) && isActive;
  const publicChatMarquee = (type: 'bottom' | 'top') => (
    <MarqueeContainer type={type}>
      <Marquee play speed={20} autoFill>
        <MarqueeText>Public Chat</MarqueeText>
      </Marquee>
    </MarqueeContainer>
  );

  return (
    <ChatPermissionsContext.Provider value={permissions}>
      <Container ref={containerRef} backgroundImg={background} className={isActive ? 'isActive' : ''}>
        {showPublicChatMarquee ? publicChatMarquee('top') : null}
        {!openSettingsWindow && isActive && !hasCustomizedChat && !isInOnboarding && permissions.canChangeTheme && (
          <CustomizeGif onClick={onCustomizeGifClick} src="/gif/customize-chat.gif" alt="Customize your chat" />
        )}
        <Background background={background ? 'var(--primary-background-alpha-40)' : 'var(--primary-background)'} />
        <SkinningFrameWrapper frame={chat?.theme?.skin?.frame} borderRadius={11}>
          {chat?.theme?.colors?.secondaryBackground ? (
            <Background
              background={`linear-gradient(0deg, ${chat.theme.colors.secondaryBackground} 0%, transparent 20%, transparent 80%, ${chat.theme.colors.secondaryBackground} 100%);`}
            />
          ) : null}
          <ParticlesContainer>
            <SkinningParticlesEmitter particles={chat?.theme?.skin?.particle} uniqueIdPrefix={chatId} />
          </ParticlesContainer>
          <ChatHeader
            chatId={chatId}
            chatName={chat?.groupName}
            memberIds={memberIds}
            isDm={chat?.isDm}
            onClose={closeChat}
            isActive={isActive}
            onSettingsClick={() => onSettingsWindowClick(SettingsWindows.SETTINGS)}
            onThemeClick={() => onSettingsWindowClick(SettingsWindows.THEME)}
            onVideoClick={() => onSettingsWindowClick(SettingsWindows.VIDEO)}
          />
          <ChatContainer>
            <ThemeProvider theme={chatWindowTheme}>
              <ChatWindow
                chatId={chatId}
                messagesList={resultMessagesList}
                loadMoreMessages={loadMoreMessages}
                inputEntry={inputEntry}
                hasMore={hasMore}
                typingUsersList={typingUsersList}
                onDeleteMessage={onDeleteMessage}
              />
            </ThemeProvider>
          </ChatContainer>
          {openSettingsWindow === SettingsWindows.SETTINGS && (
            <SideWindowContainer hasEnoughSpaceOnLeft={hasEnoughSpaceOnLeft}>
              <EditChatSettings chatId={chatId} closeWindow={closeChat} />
            </SideWindowContainer>
          )}
          {openSettingsWindow === SettingsWindows.THEME && (
            <SideWindowContainer hasEnoughSpaceOnLeft={hasEnoughSpaceOnLeft}>
              <ChatSkinningSettings chatId={chatId} />
            </SideWindowContainer>
          )}
          {openSettingsWindow === SettingsWindows.VIDEO && (
            <SideWindowContainer hasEnoughSpaceOnLeft={hasEnoughSpaceOnLeft}>
              <RoomSettings groupId={chat?.groupId} />
            </SideWindowContainer>
          )}
        </SkinningFrameWrapper>
        {showPublicChatMarquee ? publicChatMarquee('bottom') : null}
      </Container>
    </ChatPermissionsContext.Provider>
  );
};

export default OsChatWindow;

const SideWindowContainer = styled.div<{ hasEnoughSpaceOnLeft: boolean }>`
  position: absolute;
  ${({ hasEnoughSpaceOnLeft }) => `${hasEnoughSpaceOnLeft ? 'left' : 'right'}: calc(100% + ${sideWindowGap}px);`}
  top: 0;
  height: 100%;
  width: ${sideWindowWidth}px;
`;

const ParticlesContainer = styled.div`
  z-index: 1;
  pointer-events: none;
`;

const CustomizeGif = styled.img`
  height: 200px;
  width: 200px;
  cursor: pointer;

  position: absolute;
  top: -75px;
  right: -75px;

  z-index: 1;
`;

const MarqueeContainer = styled.div<{ type: 'bottom' | 'top' }>`
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: var(--primary-foreground);
  z-index: 1;
  ${({ type }) =>
    type === 'bottom'
      ? `
    bottom: -7px;
    border-top: 3px solid var(--primary-foreground);
    border-radius: 0 0 10px 10px;

  `
      : `
    top: -1px;
    border-bottom: 3px solid var(--primary-foreground);
    border-radius: 10px 10px 0 0;

  `};
`;

const MarqueeText = styled.div`
  color: var(--primary-background);
  font-size: 9px;
  font-weight: bold;
  padding: 0 3px;
`;
