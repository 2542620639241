import { AlphaVideoRenderer } from './alpha-video-renderer';
import { DefaultVideoRenderer } from './default-video-renderer';
import { VideoRenderer } from './video-renderer';
import { CameraElementHandler } from './camera-element';

// const defaultRenderer = new DefaultVideoRenderer();
// const alphaRenderer = new AlphaVideoRenderer();

export function rendererForCamera(camera: CameraElementHandler): typeof VideoRenderer {
  if (camera.isBackgroundRemovalEnabled()) {
    return AlphaVideoRenderer;
  }
  return DefaultVideoRenderer;
}
