import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../store/Wrapper';

import LensPicker from './LensPicker';

export function renderLensPicker(root: ReactDOM.Container) {
  ReactDOM.render(
    <WithRoomStore>
      <LensPicker />
    </WithRoomStore>,
    root
  );
}
