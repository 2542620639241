export const TIKTOK_VIDEO_ADDED = 'TikTok Video Added'; // params: groupId, name, URL

export const getTiktokAnalyticsData = async (tiktokId) => {
  const response = await fetch(`https://www.tiktok.com/oembed?url=https://www.tiktok.com/video/${tiktokId}`);
  const data = await response.json();
  return {
    URL: `${data.author_url}/video/${tiktokId}`,
    name: data.title,
  };
};
