import log from './log';
import { getDocIdFromElementId } from './util';
import { getRotationTransforms } from './util/element-util';

const elementsTransformCache = {};

export function applyTransformToPinnedElements(element, { initiatorElementIds, center: initiatorCenter }) {
  const elementHandler = window.elementHandlers[getDocIdFromElementId(element.id)];
  if (!elementHandler) {
    return;
  }

  elementHandler.pinnedElements.forEach(({ docId, distance }) => {
    const pinnedElementId = `element-${docId}`;
    const pinnedElement = document.getElementById(pinnedElementId);
    if (pinnedElement && !initiatorElementIds.includes(pinnedElement.id)) {
      const cachedData = elementsTransformCache[pinnedElementId];
      const elAbsoluteX = distance.x * Number(pinnedElement.style.width.replace('px', ''));
      const elAbsoluteY = distance.y * Number(pinnedElement.style.height.replace('px', ''));

      applyElementTransform(pinnedElement, {
        rotationAngle: cachedData ? cachedData.rotationAngle : undefined,
        isMirrored: cachedData ? cachedData.isMirrored : undefined,
        center: [initiatorCenter[0] + elAbsoluteX, initiatorCenter[1] + elAbsoluteY],
        initiatorElementIds: [...initiatorElementIds, element.id],
      });
    }
  });
}

export async function applyElementTransform(
  element,
  { center, rotationAngle = 0, isMirrored = false, initiatorElementIds = [] },
  shouldMovePinnedElements = true
) {
  if (element.id) {
    elementsTransformCache[element.id] = { center, rotationAngle, isMirrored };
  } else {
    log.warn('Element has no id, not able to use cache', element);
  }

  const translate = `translate3d(${Math.round(center ? center[0] : 0)}px, ${Math.round(center ? center[1] : 0)}px, 0)`;
  const scale = `scale3d(${isMirrored ? -1 : 1}, 1, 1)`;
  const rotate = `rotate3d(0, 0, 1, ${Math.round(rotationAngle)}deg)`;

  element.style.transform = translate;

  // getElementsByClassName is faster than querySelector
  const rotatableContainer = element.getElementsByClassName('rotatable-container')[0];
  if (rotatableContainer) {
    rotatableContainer.style.transform = `${rotate} ${scale}`;
  } else {
    log.warn('No rotatable container!', element);
  }

  const transforms = getRotationTransforms({
    width: parseInt(element.style.width, 10),
    height: parseInt(element.style.height, 10),
    rotationAngle,
  });
  const footer = element.getElementsByClassName('element-footer-options-translate')[0];
  if (footer) {
    footer.style.transform = `translate3d(${transforms.rightOffset}px, ${transforms.bottomOffset}px, 0)`;
  } else {
    log.warn('No footer!', element);
  }

  const containingRectangle = element.getElementsByClassName('containing-rectangle')[0];
  if (containingRectangle) {
    containingRectangle.style.transform = `scale3d(${transforms.containingRectangleScaleX}, ${transforms.containingRectangleScaleY}, 1)`;
  } else {
    log.warn('No containing rectangle!', element);
  }

  if (shouldMovePinnedElements) {
    applyTransformToPinnedElements(element, {
      center,
      rotationAngle,
      isMirrored,
      initiatorElementIds,
    });
  }
}

// Sometimes we've got only certain element data (e.g. it's not transferred completely through webrtc
// due to performance reasons, or we resize/move it). In that case missing data is taken from cache.
export function applyElementTransformFromPartialData(
  element,
  { center, rotationAngle, isMirrored } = {},
  shouldMovePinnedElements = true
) {
  if (!elementsTransformCache[element.id]) {
    log.warn(`Missing transform cache data for ${element.id} element`);
    return null;
  }

  const cacheData = elementsTransformCache[element.id];
  const data = {
    center: center || cacheData.center,
    rotationAngle: rotationAngle === undefined ? cacheData.rotationAngle : rotationAngle,
    isMirrored: isMirrored === undefined ? cacheData.isMirrored : isMirrored,
  };

  return applyElementTransform(element, data, shouldMovePinnedElements);
}

export function getElementPosition(element, useCache = true) {
  if (!element) {
    return [0, 0];
  }

  const cacheData = elementsTransformCache[element.id];
  if (cacheData && useCache) {
    return cacheData.center;
  }
  log.warn(`No cache data for element ${element.id}`);

  const matrixMatch = getComputedStyle(element).transform.match(/matrix.*\((.+)\)/);
  if (!matrixMatch) {
    return [0, 0];
  }
  const matrix = matrixMatch[1].split(', ');
  const x = parseFloat(matrix[4]) || 0;
  const y = parseFloat(matrix[5]) || 0;

  return [x, y];
}

export function getTranslateDistanceBetweenElements(elementA, elementB) {
  const [elementAX, elementAY] = getElementPosition(elementA, false);
  const [elementBX, elementBY] = getElementPosition(elementB, false);
  return [elementAX - elementBX, elementAY - elementBY];
}

export function getElementRotationAngle(element) {
  const cacheData = elementsTransformCache[element.id];
  if (cacheData) {
    return cacheData.rotationAngle;
  }

  // TODO: Try to extract it from matrix? But is this case ever gonna happen?
  log.warn(`No cache data for element ${element.id}`);
  return 0;
}

function getMatrixOfElement(element) {
  const matrix = [1, 0, 0, 1, 0, 0]; // default matrix (no transforms)
  const rawTransform = window.getComputedStyle(element).transform;

  if (rawTransform && rawTransform !== 'none') {
    // remove string wrapper 'matrix(' and split into parts
    const parts = rawTransform.slice(7, -1).split(',');
    for (let i = 0; i < parts.length; i += 1) {
      matrix[i] = parseFloat(parts[i]); // put string parts into matrix as float
    }
  }

  return matrix;
}

function prependMatrix(m1, m2) {
  // matrix multiplication
  const a1 = m1[0];
  const c1 = m1[2];
  const tx1 = m1[4];

  m1[0] = m2[0] * a1 + m2[2] * m1[1];
  m1[1] = m2[1] * a1 + m2[3] * m1[1];
  m1[2] = m2[0] * c1 + m2[2] * m1[3];
  m1[3] = m2[1] * c1 + m2[3] * m1[3];
  m1[4] = m2[0] * tx1 + m2[2] * m1[5] + m2[4];
  m1[5] = m2[1] * tx1 + m2[3] * m1[5] + m2[5];
}

export function getCombinedMatrix(element, topLimit) {
  const matrix = getMatrixOfElement(element);
  let node = element.parentNode;

  while (node && node instanceof Element && node !== topLimit) {
    // traverse dom tree
    const nodeMatrix = getMatrixOfElement(node);
    prependMatrix(matrix, nodeMatrix); // prepend matrix of parent node
    node = node.parentNode;
  }

  return matrix;
}

export function getElementPositionCombined(element, topLimit = document) {
  const combinedMatrix = getCombinedMatrix(element, topLimit);
  return [combinedMatrix[4], combinedMatrix[5]];
}

export function getAverageElementPosition(positions) {
  const [sumX, sumY] = positions.reduce((acc, [x, y]) => [acc[0] + x, acc[1] + y], [0, 0]);
  return [sumX / positions.length, sumY / positions.length];
}

export function compensateParentTransform(elements, parentTransform, reverse = false) {
  const [parentTransformX, parentTransformY] = parentTransform;
  return elements.map((element) => {
    const [x, y] = reverse
      ? getElementPositionCombined(element, document.querySelector('.pinned-group-wrapper'))
      : getElementPosition(element);

    element.style.transform = `translate3d(${Math.round(
      reverse ? x + parentTransformX : x - parentTransformX
    )}px, ${Math.round(reverse ? y + parentTransformY : y - parentTransformY)}px, 0px)`;

    return element;
  });
}
