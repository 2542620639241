import firebase, { db } from './firebase';
import { screenToCanvasCoords } from './util/canvas-util';
import log from './log';
import { htmlToElement } from './util';
import CobrowserElement from './cobrowser';
import '../styles/cobrowser.less';
import '../styles/cobrowser-game.less';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';
import { USER_CARD_BOARD_TYPE } from './constants/board-constants';

export default class CobrowserGameElement extends CobrowserElement {
  constructor(elementId) {
    super(elementId);
    this.elementId = elementId;
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    log.debug('Received update', this.state, elementDoc.data());
    super.handleUpdate(element, elementDoc);
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    super.setup(elementId, elementDoc);
  }

  // Required method
  getElement(elementDoc) {
    const el = super.getElement(elementDoc);
    const bottomGameUiElements = htmlToElement(`
      <div class="share-room-master">
        <div class="share-room-div">
          <h5 class="share-room-directions">Create a private room and share the game URL</h5>
          <input placeholder="Paste URL" class="share-room-input themed-input">
          <button class="share-room-submit here-button-regular">Share URL</button>
        </div>
        <div class="instructions-div">
          <h5 class="instructions-directions">Create a private room and share the game URL</h5>
          <button class="instructions-close">Okay</button>
        </div>
      </div>
    `);
    const topGameUiElements = htmlToElement(`
      <div class="share-room-master-top">
        <div style="width: max-content; margin: auto;">
          <button class="dont-drag-me how-to-start-button here-button-ghost">
            How to Start a Game
          </button>
        </div>
      </div>
    `);
    const elBoundingBox = el.querySelector('.iframe-bounding-box');
    elBoundingBox.append(bottomGameUiElements);
    elBoundingBox.prepend(topGameUiElements);
    return el;
  }

  showShareURL(shouldShow) {
    const el = document.getElementById(`element-${this.elementId}`);
    const shareRoomInput = el.querySelector(`.share-room-input`);
    if (!shareRoomInput) return;
    const shareRoomDiv = el.querySelector(`.share-room-div`);
    if (shouldShow) {
      shareRoomDiv.style.display = 'block';
      shareRoomInput.addEventListener('keydown', (e) => {
        if (e.key !== 'Enter') {
          return;
        }
        this.shareUrl();
      });
    } else shareRoomDiv.style.display = 'none';
  }

  showInstructions(text) {
    const el = document.getElementById(`element-${this.elementId}`);
    const elInstructionsDiv = el.querySelector(`.instructions-div`);
    const elDirections = el.querySelector(`.instructions-directions`);
    if (elDirections) elDirections.innerHTML = text;
    let elCloseButton;
    if (elInstructionsDiv) {
      elCloseButton = elInstructionsDiv.querySelector(`.instructions-close`);
      elCloseButton.onclick = function () {
        elInstructionsDiv.style.display = 'none';
        el.querySelector('.share-room-master-top').style.display = 'none';
        el.querySelector('.share-room-master').style.display = 'none';
        const skribblioEl = el.querySelector('.skribblio-background');
        skribblioEl.classList.remove('skribblio-background');
        skribblioEl.classList.add('skribblio-background-full');
      };
      elInstructionsDiv.style.display = 'block';
    }
  }

  iframeClasses() {
    return '';
  }

  shareUrl() {
    log.debug('share url');
  }

  // Statics

  static async addElement(url) {
    const boardRef = db.collection('boards').doc(window.currentBoardId);
    const boardData = (await boardRef.get()).data();
    await boardRef.collection('elements').add({
      class: 'CobrowserGameElement',
      center: screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2 - 200),
      creator: firebase.auth().currentUser.uid,
      size: [350, 500],
      url,
      zIndex: window.getFrontZIndex(),
    });
    track(ADD_ELEMENT, {
      element: ELEMENT_TYPES.COBROWSER_GAME,
      destination:
        boardData.type === USER_CARD_BOARD_TYPE
          ? ADD_ELEMENT_DESTINATION_TYPES.CARD
          : ADD_ELEMENT_DESTINATION_TYPES.ROOM,
    });
  }

  // Handler-specific
}

CobrowserGameElement.elementType = 'CobrowserGameElement';
