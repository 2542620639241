import React from 'react';
import ReactDOM from 'react-dom';

import MobileUpsellRoomModal from './MobileUpsellRoomModal';

const mobileUpsellModalId = 'mobile-upsell-modal-container';

export const renderMobileUpsellModal = () => {
  let mobileUpsellModalContainer = document.getElementById(mobileUpsellModalId);
  if (!mobileUpsellModalContainer) {
    mobileUpsellModalContainer = document.createElement('div');
    mobileUpsellModalContainer.setAttribute('id', mobileUpsellModalId);
    document.body.append(mobileUpsellModalContainer);
  }

  ReactDOM.render(<MobileUpsellRoomModal />, mobileUpsellModalContainer);
};
