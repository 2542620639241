import { useContext } from 'react';

import { ChatPermissionsContext } from '../../../common/contexts';
import { isCurrentUser } from '../../../../util/user-util';
import { defaultChatPermissions } from '../../constants';

export const useCanDeleteMessage = (creatorId: string) => {
  const permissions = useContext(ChatPermissionsContext) || defaultChatPermissions;
  const isSelf = isCurrentUser(creatorId);
  const canDelete = (isSelf && permissions.canDeleteOwnMessage) || (!isSelf && permissions.canDeleteAnyMessage);
  return canDelete;
};
