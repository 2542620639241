import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../store/Wrapper';
import BoardElementTagging from './BoardElementTagging';

export function renderTaggingContainer(elementId) {
  const container = document.querySelector(`#element-${elementId} .tagging-container`);
  if (container) {
    ReactDOM.render(
      <WithRoomStore>
        <BoardElementTagging elementId={elementId} />
      </WithRoomStore>,
      container
    );
  }
}
