import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserListItem from '../../user-profile/UserListItem';
import SuggestedFriendsListItem from './SuggestedFriendsListItem';
import { authedApiInstance } from '../../../firebase';

const SuggestedFriendsList = ({ initialSuggestedFriends, initialNextPage, isLobby, disableProfileOpenClick }) => {
  const [suggestedFriendsToDisplay, setSuggestedFriendsToDisplay] = useState(initialSuggestedFriends);
  const [nextPage, setNextPage] = useState(initialNextPage);
  const [isLoading, setIsLoading] = useState(false);

  const loadMoreFriends = useCallback(async () => {
    setIsLoading(true);
    const { data } = await authedApiInstance(nextPage);
    if (data.success && data.suggestedFriends.length) {
      setSuggestedFriendsToDisplay((prevState) => [...prevState, ...data.suggestedFriends]);
      if (data.metadata.next) {
        setNextPage(data.metadata.next);
      } else {
        setNextPage(null);
      }

      setIsLoading(false);
    }
  }, [nextPage]);

  useEffect(() => {
    setNextPage(initialNextPage);
  }, [initialNextPage]);

  const observer = useRef();
  const ref = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          loadMoreFriends();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [nextPage, loadMoreFriends]
  );

  return (
    <Container>
      {suggestedFriendsToDisplay.map((suggestedFriend, idx) => (
        <SuggestedFriendsListItem
          key={suggestedFriend.profile.userId}
          suggestedFriend={suggestedFriend}
          ref={idx === suggestedFriendsToDisplay.length - 1 ? ref : null}
          disableProfileOpenClick={disableProfileOpenClick}
        />
      ))}
      {isLoading && (
        <>
          <UserListItem isLoading withDarkBackground={isLobby} />
          <UserListItem isLoading withDarkBackground={isLobby} />
        </>
      )}
    </Container>
  );
};

export default SuggestedFriendsList;

SuggestedFriendsList.propTypes = {
  initialSuggestedFriends: PropTypes.arrayOf(
    PropTypes.shape({
      profile: PropTypes.shape({
        userId: PropTypes.string,
        username: PropTypes.string,
        onlineStatus: PropTypes.shape({
          status: PropTypes.string,
        }),
        backgroundPhoto: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      }).isRequired,
      suggestionReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
      contactBookData: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    })
  ).isRequired,
  initialNextPage: PropTypes.string,
  isLobby: PropTypes.bool,
  disableProfileOpenClick: PropTypes.bool,
};

SuggestedFriendsList.defaultProps = {
  initialNextPage: null,
  isLobby: false,
  disableProfileOpenClick: false,
};

export const Container = styled.div``;
