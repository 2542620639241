import { createAction } from '@reduxjs/toolkit';
import { Country } from 'react-phone-number-input';

export const reset = createAction('signingIn/reset');
export const setFlowId = createAction<{ flowId: string }>('signingIn/setFlowId');

// navigation
export const startFlow = createAction<{ flowId: string; screenId?: string }>('signingIn/startFlow');
export const setScreenId = createAction<{ screenId: string }>('signingIn/setScreenId');
export const setLastInteractedScreenId = createAction<{ screenId: string }>('signingIn/setLastInteractedScreenId');
export const goPrevScreen = createAction<{ screenData?: object }>('signingIn/goPrevScreen');
export const goNextScreen = createAction<{ screenData?: object }>('signingIn/goNextScreen');

// profile
export const setProfileId = createAction<{ id: string }>('signingIn/setProfileId');
export const setEmail = createAction<{ email: string }>('signingIn/setEmail');
export const setDisplayName = createAction<{ displayName: string }>('signingIn/setDisplayName');
export const setGeneratedUsername = createAction<{ username: string }>('signingIn/setGeneratedUsername');
export const setUsernameWithValidation = createAction<{ username: string }>('signingIn/setUsernameWithValidation');

// screens
export const startProviderAuth = createAction<{ authType: string }>('signingIn/startProviderAuth');
export const setInputEmailScreenError = createAction<{ error: string }>('signingIn/setInputEmailScreenError');
export const submitInputEmailScreen = createAction<{ email: string }>('signingIn/submitInputEmailScreen');
export const trySignInWithEmailAndPassword = createAction<{ email: string; password: string }>(
  'signingIn/trySignInWithEmailAndPassword'
);
export const setVerificationId = createAction<{ verificationId: string }>('signingIn/setVerificationId');
export const setPhoneNumber = createAction<{ domesticPhoneNumber: string; countryCode: Country }>(
  'signingIn/setPhoneNumber'
);

export const tryCreateAccountWithEmail = createAction<{ email: string; name: string; password: string }>(
  'signingIn/tryCreateAccountWithEmail'
);

export const setSignInWithEmailAndPasswordError = createAction<{ error: string }>(
  'signingIn/setSignInWithEmailAndPasswordError'
);

export const setCreateAccountEmailError = createAction<{ error: string }>('signingIn/setCreateAccountEmailError');
export const setCreateAccountPasswordError = createAction<{ error: string }>('signingIn/setCreateAccountPasswordError');

export const setIsGeneratingUsername = createAction<{ isGenerating: boolean }>('signingIn/setIsGeneratingUsername');
export const setUsernameValidationResult = createAction<{ message: string; isValid: boolean }>(
  'signingIn/setUsernameValidationResult'
);
export const submitUsernameScreen = createAction('signingIn/submitUsernameScreen');
export const submitUsernameAndDisplayNameScreen = createAction('signingIn/submitUsernameAndDisplayNameScreen');

export const submitProfileDetails = createAction('signingIn/submitProfileDetails');

export const schoolSelectionComplete = createAction('signingIn/schoolSelectionComplete');

export const setFriendSuggestions = createAction<{ suggestions: Record<string, unknown>[]; nextPage: string }>(
  'signingIn/setFriendSuggestions'
);

export const submitFriendSuggestions = createAction('signingIn/submitFriendSuggestions');

export const sendCode = createAction<{ domesticPhoneNumber: string; countryCode: Country; verificationId: string }>(
  'signingIn/sendCode'
);
export const resendCode = createAction('signingIn/resendCode');
export const verifyConfirmationCode = createAction<{ isNewUser: boolean; uid: string }>(
  'signinIn/verifyConfirmationCode'
);

export const resetPassword = createAction<{ email: string }>('signingIn/resetPassword');
export const setResetPasswordError = createAction<{ error: string }>('signingIn/setResetPasswordError');

export const resetErrors = createAction('signingIn/resetErrors');
