import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import firebase from '../../firebase';
import * as store from '../store/template/store';

// icons
import ButtonArrow from '../../../assets/icons/button-arrow.svg';
import { track } from '../../util/analytics-util';

const DISCORD_REQUEST_TEMPLATE_WEBHOOK =
  'https://discord.com/api/webhooks/855107375610462228/GbzLy0uoj5bOKvH-3KrD-NvTYXADBUQ8SYkEuStm-q2c2DA3H0zvwhJawxdp4aDzJE4Z';

function sendDiscordTemplateRequest(content) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({ content, tts: false, embed: '' });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  return window.fetch(DISCORD_REQUEST_TEMPLATE_WEBHOOK, requestOptions);
}

function SearchNoResults(props) {
  const {
    contentType,
    templatesFilter,
    lastTemplateRequest,
    setLastTemplateRequest,
    onBlankRoomSelected,
    resetFilter,
  } = props;

  const [requestTemplateButtonDisabled, setRequestTemplateButtonDisabled] = React.useState(false);
  const [requestTemplateButtonText, setRequestTemplateButtonText] = React.useState(
    `Request ${contentType === store.itemTypes.kits ? 'Sticker Pack' : 'Template'}`
  );

  React.useEffect(() => {
    if (templatesFilter === lastTemplateRequest) {
      setRequestTemplateButtonDisabled(true);
      setRequestTemplateButtonText('Requested!');
    } else {
      setRequestTemplateButtonDisabled(false);
      setRequestTemplateButtonText(`Request ${contentType === store.itemTypes.kits ? 'Sticker Pack' : 'Template'}`);
    }
  }, [templatesFilter, lastTemplateRequest, contentType]);

  function createBlankRoom() {
    onBlankRoomSelected();
    resetFilter();
  }

  function requestTemplate() {
    track(contentType === store.itemTypes.kits ? 'New Sticker Pack Requested' : 'New Template Requested');

    const { displayName, email, uid } = firebase.auth().currentUser;
    const content = `${displayName} (${email}, ${uid}) has requested the following ${
      contentType === store.itemTypes.kits ? 'sticker pack' : 'template'
    }: ${templatesFilter}`;

    sendDiscordTemplateRequest(content)
      .then(() => {
        setLastTemplateRequest(templatesFilter);
      })
      .finally(() => {
        setRequestTemplateButtonText('Requested!');
      });
  }

  return (
    <SearchNoResultsContainer>
      <SearchNoResultsTitle>
        {contentType === store.itemTypes.kits
          ? "We couldn't find a matching sticker pack"
          : "We couldn't find a matching template"}
      </SearchNoResultsTitle>

      <SearchNoResultsBlocks>
        <SearchNoResultsBlock>
          <SearchNoResultsBlockTitle>
            {contentType === store.itemTypes.kits ? 'Create Your Own Sticker Pack!' : 'Create Your Own Template!'}
          </SearchNoResultsBlockTitle>
          <SearchNoResultsBlockText>
            {contentType === store.itemTypes.kits
              ? 'Start with a blank room and create your own sticker pack.'
              : 'Start with a blank room and create your own template.'}
          </SearchNoResultsBlockText>
          <SearchNoResultsBlockButton onClick={createBlankRoom}>
            <span>Create Blank Room</span>
            <ButtonArrow />
          </SearchNoResultsBlockButton>
        </SearchNoResultsBlock>

        <SearchNoResultsBlock>
          <SearchNoResultsBlockTitle>
            Request this {contentType === store.itemTypes.kits ? 'Sticker Pack' : 'Template'}
          </SearchNoResultsBlockTitle>
          <SearchNoResultsBlockText>
            Got a great idea that you want to share with everyone? Let us know.
          </SearchNoResultsBlockText>
          <SearchNoResultsBlockButton onClick={requestTemplate} disabled={requestTemplateButtonDisabled}>
            <span>{requestTemplateButtonText}</span>
            <ButtonArrow />
          </SearchNoResultsBlockButton>
        </SearchNoResultsBlock>
      </SearchNoResultsBlocks>
    </SearchNoResultsContainer>
  );
}

SearchNoResults.propTypes = {
  contentType: PropTypes.string.isRequired,
  templatesFilter: PropTypes.string.isRequired,
  lastTemplateRequest: PropTypes.string,
  setLastTemplateRequest: PropTypes.func.isRequired,
  onBlankRoomSelected: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
};

SearchNoResults.defaultProps = {
  lastTemplateRequest: null,
};

const SearchNoResultsContainer = styled.div`
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 15px;
  width: 94%;
`;

const SearchNoResultsTitle = styled.h3`
  font-weight: 400;
  font-size: 1.9rem;
  color: #12121250;
`;

const SearchNoResultsBlocks = styled.div`
  margin-top: 59px;
  display: flex;
  justify-content: space-between;
`;

const SearchNoResultsBlock = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
`;

const SearchNoResultsBlockTitle = styled.h4`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 1.2rem;
  color: #121212;
`;

const SearchNoResultsBlockText = styled.p`
  flex-grow: 1;
  margin-top: 4px;
  font-weight: 400;
  font-size: 1.2rem;
  color: #12121250;
`;

const SearchNoResultsBlockButton = styled.button`
  width: 198px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #ffffff;
  background-color: #6b1be3;
  border: none;
  border-radius: 12px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #8a67da;
    cursor: not-allowed;

    svg {
      display: none;
    }
  }
`;

export default SearchNoResults;
