import React from 'react';
import ReactDOM from 'react-dom';

import AddImageContainer from '../components/AddImageContainer';
import roomRootStore from '../store/room-root-store';
import { openToolbarImagePopup } from '../store/gif-search/store';
import { WithRoomStore } from '../store/Wrapper';

export function renderAddImageContainer({ elementId, stickerMenuId, firebaseId, isChat, chatId, caller }) {
  // TODO: we probably shouldn't be dispatching actions in render functions like below
  roomRootStore.dispatch(openToolbarImagePopup());
  ReactDOM.render(
    <WithRoomStore>
      <AddImageContainer
        elementId={elementId}
        isChat={isChat}
        elementMenuId={stickerMenuId}
        firebaseId={firebaseId}
        chatId={chatId}
        caller={caller}
      />
    </WithRoomStore>,
    document.getElementById(elementId)
  );
}
