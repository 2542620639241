import { isEqual } from 'lodash';
import { TrackProvider } from '../sfu/track-provider';

class DefaultVideoTrackProvider implements TrackProvider {
  type = 'defaultVideo';

  currentStream?: MediaStream = null;

  currentMediaConfig?: object = null;

  async getTrack(videoMediaConfig: object) {
    if (
      !this.currentStream?.active ||
      this.currentStream?.getVideoTracks()?.length === 0 ||
      !isEqual(videoMediaConfig, this.currentMediaConfig)
    ) {
      if (this.currentStream) {
        this.currentStream.getTracks().forEach((t) => t.stop());
      }
      this.currentStream = await navigator.mediaDevices.getUserMedia({ video: videoMediaConfig || true });
      this.currentMediaConfig = videoMediaConfig;
    }
    return this.currentStream.getVideoTracks()[0];
  }

  async suspend() {}
}

export default new DefaultVideoTrackProvider();
