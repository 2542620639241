import { db } from '../../../firebase';
import { htmlToElement } from '../../../util';
import unsplashApi from '../../../unsplash-api';
import { uploadBackground } from '../../../upload';
import { addSystemMessage } from '../../../message-util';
import bus, { roomSettingsUpdated } from '../../../event-bus';
import BackgroundPicker from './background-picker';
import { CHANGE_BACKGROUND_COLOR, CHANGE_BACKGROUND_IMAGE } from '../../../constants/analytics-events/background-event';

import template from './background.html';
import './background.less';
import { track } from '../../../util/analytics-util';

class RoomDetails extends BackgroundPicker {
  connectedCallback() {
    this.innerHTML = template;

    this.searchResults = this.querySelector('.search-results');
    this.searchResults.addEventListener('scroll', this.onSearchResultsScroll.bind(this));

    this.onBackToProperties = this.onBackToProperties.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.onColorSelect = this.onColorSelect.bind(this);

    this.querySelector('.done-button').addEventListener('click', this.onBackToProperties);
    this.querySelector('#background-search-input').addEventListener('keyup', this.onSearchInput);
    this.querySelector('#background-upload-input').addEventListener('change', this.onImageUpload);
    this.querySelector('here-color-select').addEventListener('colorchange', this.onColorSelect);

    const unsplashLink = this.querySelector('.unsplash-link');
    unsplashLink.href = unsplashApi.addUtm(unsplashLink.href);

    this.loadPopularImages();

    if (this.getAttribute('boardId')) {
      if (this.getAttribute('showSpaces')) {
        this.querySelector('here-spaces-select')?.setAttribute('boardId', this.getAttribute('boardId'));

        const isOsDesktop = this.getAttribute('isOsDesktop');
        if (isOsDesktop) {
          this.querySelector('here-spaces-select')?.setAttribute('isOsDesktop', isOsDesktop);
        }
      } else {
        this.spacesTab = this.querySelector('.spaces-tab');
        this.spacesTab.style.display = 'none';
        this.spacesTab.classList.remove('uk-active');
      }
    }

    const customTracking = this.getAttribute('customTracking');
    if (customTracking) {
      this.customTracking = JSON.parse(customTracking);
      this.querySelector('here-spaces-select').setAttribute('customTracking', customTracking);
    }
  }

  disconnectedCallback() {
    this.querySelector('.done-button').removeEventListener('click', this.onBackToProperties);
    this.querySelector('#background-search-input').removeEventListener('keyup', this.onSearchInput);
    this.querySelector('#background-upload-input').removeEventListener('change', this.onImageUpload);
    this.querySelector('here-color-select').removeEventListener('colorchange', this.onColorSelect);
  }

  onBoardUpdate(board) {
    if (board.backgroundColor) {
      this.querySelector('here-color-select').setColor(board.backgroundColor);
    }

    this.currentBackground = board.background;
    this.refreshBackgroundSelection();
  }

  onBackToProperties() {
    const modal = document.getElementById('room-settings-react');
    const bgProps = modal.querySelector('.settings-container');
    const bgChooser = modal.querySelector('.background-chooser-container');
    bgChooser.classList.remove('scroll-out-from-right');
    bgChooser.classList.add('scroll-out-to-right');
    bgProps.classList.remove('scroll-out-to-left');
    bgProps.classList.add('scroll-in-from-left');
    setTimeout(() => {
      bgChooser.style.display = 'none';
      modal.style.overflow = '';
    }, 400);
  }

  async retrieveImages(methodName, ...args) {
    this.isLoading = true;
    const imageLoader = this.querySelector('.images-loader');
    imageLoader.style.opacity = 1;

    const images = await unsplashApi[methodName](...args);
    const elements = images.map((image) => {
      const element = htmlToElement(`
        <div class="image-container" data-url="${image.large}">
        <div class="check-container"><span uk-icon="icon: check"></span></div>
          <img src="${image.small}" alt="${image.description}" />
          <div class="author-info">
            <a href="${image.author.url}" target="_blank">${image.author.name}</a>
          </div>
        </div>
      `);

      element.addEventListener('click', () => {
        if (image.large === this.currentBackground) {
          return;
        }

        db.collection('boards')
          .doc(this.getAttribute('boardId') || window.currentBoardId)
          .update({ background: image.large, backgroundColor: null });
        unsplashApi.sendDownloadRequest(image.download);
        if (!this.getAttribute('boardId')) {
          // For now custom board id is used only for user cards where we don't need to provide system message.
          // Whether it should depend on boardId prop or depend on another prop depends on how we're gonna use
          // this component somewhere else.
          addSystemMessage('changed the background image');
        }
        if (this.customTracking?.predefined) {
          track(...this.customTracking.predefined);
        } else {
          track(CHANGE_BACKGROUND_IMAGE);
        }
        this.querySelector('.done-container').style.display = 'block';
        bus.dispatch(roomSettingsUpdated);
      });

      return element;
    });

    this.searchResults.append(...elements);
    imageLoader.style.opacity = 0;
    this.refreshBackgroundSelection();
    this.isLoading = false;
  }

  refreshBackgroundSelection() {
    const selectedClass = 'selected';
    const previousBackground = this.querySelector(`.image-container.${selectedClass}`);
    const currentBackground = this.querySelector(`[data-url="${this.currentBackground}"]`);
    if (previousBackground === currentBackground) {
      return;
    }

    if (previousBackground) {
      previousBackground.classList.remove(selectedClass);
    }
    if (currentBackground) {
      currentBackground.classList.add(selectedClass);
    }
  }

  async onImageUpload(e) {
    const { files } = e.target;
    if (!files || !files[0]) {
      return;
    }

    await uploadBackground(files[0]);
    this.querySelector('.done-container').style.display = 'block';
    bus.dispatch(roomSettingsUpdated);
    e.target.value = null;
  }

  async onColorSelect(e) {
    const { color, inputComplete } = e.detail;
    if (!inputComplete) {
      return;
    }

    this.querySelector('.done-container').style.display = 'block';
    addSystemMessage('changed the background color');
    if (this.customTracking?.color) {
      track(...this.customTracking.color);
    } else {
      track(CHANGE_BACKGROUND_COLOR);
    }
    db.collection('boards')
      .doc(this.getAttribute('boardId') || window.currentBoardId)
      .update({ background: null, backgroundColor: color });
    bus.dispatch(roomSettingsUpdated);
  }
}

window.customElements.define('here-room-details', RoomDetails);

export default RoomDetails;
