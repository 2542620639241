import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

import SpinnerLoader from '../../components/SpinnerLoader';
import HereModal from '../../components/HereModal.tsx';

import AddFriendIcon from '../../../../assets/icons/add-friend.svg';
import SmileyIcon from '../../../../assets/icons/smiley.svg';

import { errorMessageMixin, svgColorMixin } from '../../mixins';

import {
  loadingFriendsSelector,
  friendsErrorSelector,
  incomingPendingRequestsSelector,
  friendsAsArraySelector,
} from '../../store/friends/selectors';
import FriendsList from '../FriendsList';
import { track } from '../../../util/analytics-util';
import AddFriendsTabContent from './AddFriendsTabContent';
import { clearUserSearch } from '../../store/users/store';
import { selectUserSearchError } from '../../store/users/selectors';
import FriendRequestsTabContent from './FriendRequestsTabContent';
import { FRIEND_REQUEST_SOURCES, VIEW_FRIENDS } from '../../../constants/analytics-events/friend-events';
import { EventSourceContext } from '../../common/contexts.ts';
import { FRIENDS_MODAL_TABS } from '../../../constants/friends-modal-constants.ts';

const ModalContent = styled.div`
  min-height: 496px;
`;

const ModalTitle = styled.div`
  margin-bottom: 20px;
  padding: 30px 30px 0 30px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  color: #12002d;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
`;

const TotalFriendsNumber = styled.span`
  font-weight: normal;
`;

const TabsContainer = styled.div`
  margin-bottom: 28px;
  padding-left: 30px;
  padding-right: 30px;

  display: flex;
  align-items: center;
`;

const Tab = styled.div`
  display: inline-block;
  cursor: pointer;

  ${({ active }) => active && 'border-bottom: 2px solid #6B1BE3;'}
`;

const IconContainer = styled.div`
  ${({ active }) => active && svgColorMixin('#6b1be3')}
  ${({ active, hovered }) => !active && svgColorMixin(hovered ? '#12002d80' : '#12002d40')}
`;

const TabWithMargin = styled(Tab)`
  margin-right: 20px;
`;

const SmileyIconContainer = styled(IconContainer)`
  width: 11px;
  display: flex;
  align-items: center;
`;

const AddFriendIconContainer = styled(IconContainer)`
  margin-top: -4px;
  svg {
    width: 15px;
  }
`;

const RequestsNotification = styled.div`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6335d;
  border-radius: 50%;
  font-size: 10px;
  font-family: 'Inter';
  font-weight: 600;
  color: #ffffff;
  margin-left: 7px;
  margin-right: 20px;
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
`;

const TabLabel = styled.div`
  margin-left: 5px;

  ${({ active }) => active && 'color: #6b1be3;'}
  ${({ active, hovered }) =>
    !active &&
    `
    color: ${hovered ? '#12002d80' : '#12002d40'};
  `}
    font-size: 14px;
  font-family: 'Inter';
  line-height: 29px;
`;

const TabsContentContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;

  height: 436px;

  @media (max-width: 615px) {
    height: 50vh;
  }
`;

const ErrorMessage = styled.div`
  ${errorMessageMixin()}

  position: absolute;
  right: 40px;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  right: 40px;
`;

const FriendsModal = ({ defaultOpenTab }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(defaultOpenTab);
  const [tabHovered, setTabHovered] = useState('');

  const loadingFriends = useSelector(loadingFriendsSelector);

  const friendsError = useSelector(friendsErrorSelector);
  const userSearchError = useSelector(selectUserSearchError);
  const hasError = Boolean(friendsError) || Boolean(userSearchError);

  const friendRequests = useSelector(incomingPendingRequestsSelector);
  const friendsList = useSelector(friendsAsArraySelector);

  const closeModal = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('friends-modal'));
  };

  const friendsActiveTab = activeTab === FRIENDS_MODAL_TABS.friends;
  const addFriendsActiveTab = activeTab === FRIENDS_MODAL_TABS.add;
  const requestsActiveTab = activeTab === FRIENDS_MODAL_TABS.requests;
  const totalFriendsNumber = friendsList.length;

  const onFriendsTabClick = () => {
    setActiveTab(FRIENDS_MODAL_TABS.friends);
    track(VIEW_FRIENDS, { source: 'friends tab click' });
  };

  const onAddFriendsTabClick = () => {
    setActiveTab(FRIENDS_MODAL_TABS.add);
    // reset user search:
    dispatch(clearUserSearch());
  };

  const FriendRequestTab = friendRequests.length > 0 ? Tab : TabWithMargin;

  return (
    <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.FRIENDS_MODAL}>
      <HereModal onOverlayClick={closeModal}>
        <ModalContent>
          <ModalTitle>
            <span>Friends</span>

            {totalFriendsNumber ? <TotalFriendsNumber>{totalFriendsNumber}</TotalFriendsNumber> : null}
          </ModalTitle>

          <TabsContainer>
            <TabWithMargin
              active={friendsActiveTab}
              onClick={onFriendsTabClick}
              onMouseEnter={useCallback(() => setTabHovered(FRIENDS_MODAL_TABS.friends), [])}
              onMouseLeave={useCallback(() => setTabHovered(''), [])}
            >
              <TabContent>
                <SmileyIconContainer active={friendsActiveTab} hovered={tabHovered === FRIENDS_MODAL_TABS.friends}>
                  <SmileyIcon />
                </SmileyIconContainer>
                <TabLabel active={friendsActiveTab} hovered={tabHovered === FRIENDS_MODAL_TABS.friends}>
                  All Friends
                </TabLabel>
              </TabContent>
            </TabWithMargin>

            <FriendRequestTab
              active={requestsActiveTab}
              onClick={() => setActiveTab(FRIENDS_MODAL_TABS.requests)}
              onMouseEnter={useCallback(() => setTabHovered(FRIENDS_MODAL_TABS.requests), [])}
              onMouseLeave={useCallback(() => setTabHovered(''), [])}
            >
              <TabContent>
                <AddFriendIconContainer active={requestsActiveTab} hovered={tabHovered === FRIENDS_MODAL_TABS.requests}>
                  <AddFriendIcon />
                </AddFriendIconContainer>
                <TabLabel active={requestsActiveTab} hovered={tabHovered === FRIENDS_MODAL_TABS.requests}>
                  Requests
                </TabLabel>
              </TabContent>
            </FriendRequestTab>
            {friendRequests.length > 0 && <RequestsNotification>{friendRequests.length}</RequestsNotification>}

            <Tab
              active={addFriendsActiveTab}
              onClick={onAddFriendsTabClick}
              onMouseEnter={useCallback(() => setTabHovered(FRIENDS_MODAL_TABS.add), [])}
              onMouseLeave={useCallback(() => setTabHovered(''), [])}
            >
              <TabContent>
                <AddFriendIconContainer active={addFriendsActiveTab} hovered={tabHovered === FRIENDS_MODAL_TABS.add}>
                  <AddFriendIcon />
                </AddFriendIconContainer>
                <TabLabel active={addFriendsActiveTab} hovered={tabHovered === FRIENDS_MODAL_TABS.add}>
                  Find Friends
                </TabLabel>
              </TabContent>
            </Tab>
            {loadingFriends && friendsActiveTab && (
              <SpinnerContainer>
                <SpinnerLoader />
              </SpinnerContainer>
            )}
            {hasError && <ErrorMessage>{friendsError || userSearchError}</ErrorMessage>}
          </TabsContainer>

          <TabsContentContainer>
            {requestsActiveTab && <FriendRequestsTabContent friendRequests={friendRequests} />}
            {addFriendsActiveTab && <AddFriendsTabContent />}
            {friendsActiveTab && <FriendsList />}
          </TabsContentContainer>
        </ModalContent>
      </HereModal>
    </EventSourceContext.Provider>
  );
};

export default FriendsModal;

FriendsModal.propTypes = {
  defaultOpenTab: PropTypes.string,
};

FriendsModal.defaultProps = {
  defaultOpenTab: FRIENDS_MODAL_TABS.friends,
};
