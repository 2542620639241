import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../../store/Wrapper';

// components
import FriendsInviteList from './FriendsInviteList';
import FriendsInviteEmptyState from './FriendsInviteEmptyState.tsx';

export function renderFriendsInviteList(element) {
  ReactDOM.render(
    <WithRoomStore>
      <FriendsInviteList />
    </WithRoomStore>,
    element
  );
}

export function renderFriendsInviteEmptyState(element) {
  ReactDOM.render(
    <WithRoomStore>
      <FriendsInviteEmptyState />
    </WithRoomStore>,
    element
  );
}
