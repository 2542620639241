import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import RoomMemberDetails from './RoomMemberDetails';
import { hideableMixin } from '../../../mixins';
import { getHasMoreOfflineMembers } from '../../../store/room-members/selectors';
import { offlineMembersPageRequested } from '../../../store/room-members/store';
import { ToastProvider } from '../../../admin-templates/ToastProvider';

function RoomMembersList({ label, users, type, hasMoreOfflineMembers, requestOfflineMembersPage }) {
  const observer = React.useRef();
  const lastMemberRef = React.useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreOfflineMembers) {
          requestOfflineMembersPage(users[users.length - 1].id);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [hasMoreOfflineMembers, requestOfflineMembersPage, users]
  );

  return (
    <ToastProvider toastsPosition={{ bottom: 0, right: 0 }}>
      <Container isHiddenDisplay={users.length === 0}>
        <Heading>{label}</Heading>
        <div>
          {users.map((u, index) => (
            <div ref={type === 'offline' && index === users.length - 1 ? lastMemberRef : null} key={u.id}>
              <RoomMemberDetails member={u} />
            </div>
          ))}
        </div>
      </Container>
    </ToastProvider>
  );
}

RoomMembersList.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  hasMoreOfflineMembers: PropTypes.bool,
  requestOfflineMembersPage: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
};

RoomMembersList.defaultProps = {
  hasMoreOfflineMembers: false,
};

export default connect(
  (state) => ({
    hasMoreOfflineMembers: getHasMoreOfflineMembers(state),
  }),
  {
    requestOfflineMembersPage: (lastMemberId) => offlineMembersPageRequested({ lastMemberId }),
  }
)(RoomMembersList);

const Container = styled.div`
  ${hideableMixin()}

  margin: 30px 0;
  color: var(--primary-foreground, inherit);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled.div`
  padding-bottom: 10px;
`;
