import CameraElement from '../camera';
import defaultVideoTrackProvider from './default-video-track-provider';
import defaultAudioTrackProvider from './default-audio-track-provider';
import segmentationVideoTrackProvider from './selfie-segmentation-video-provider';
import log from '../log';
import { TrackProvider } from '../sfu/track-provider';
import snapchatVideoTrackProvider from './snapchat-video-provider';

export function isSnapchatLensEnabled(): boolean {
  return !!snapchatVideoTrackProvider.currentLens;
}

let previousProvider: TrackProvider;
export function getCurrentVideoTrackProvider(): TrackProvider {
  let provider = defaultVideoTrackProvider;
  if (!CameraElement.ownCamera) {
    log.error('getCurrentVideoTrackProvider: Own camera not initialized, defaulting to normal video');
  } else if (isSnapchatLensEnabled()) {
    provider = snapchatVideoTrackProvider;
  } else if (CameraElement.ownCamera.isBackgroundRemovalEnabled()) {
    provider = segmentationVideoTrackProvider;
  }

  if (previousProvider?.type !== provider?.type) {
    previousProvider?.suspend();
  }
  previousProvider = provider;

  return provider;
}

export function getCurrentAudioTrackProvider(): TrackProvider {
  return defaultAudioTrackProvider;
}
