import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import UIKit from 'uikit';

import { disableEverything, enableElements, enableEverything } from '../../../onboarding';

// components
import Portal from '../../components/Portal';
import { goNextScreen } from '../../store/signing-in/actions';

const hideEverythingInInviteMenu = () => {
  document.querySelectorAll('.invite-menu .invite-container > *').forEach((el) => {
    el.classList.add('onboarding-hidden');
  });

  document.querySelectorAll('.invite-menu .invite-container .invite-content > *').forEach((el) => {
    el.classList.add('onboarding-hidden');
  });

  // show copy link section
  document
    .querySelector('.invite-menu .invite-container .invite-content .invite-tab-link-section')
    .classList.remove('onboarding-hidden');

  document.querySelector('.invite-menu .invite-container .invite-content').classList.remove('onboarding-hidden');
};

const showOnboardingInviteMenu = () => {
  // show onboarding UI
  document.querySelector('#invite-popup-onboarding-step').classList.remove('onboarding-hidden');

  // update paddings
  (document.querySelector('.invite-menu') as HTMLElement).style.paddingTop = '50px';
  (document.querySelector('.invite-menu') as HTMLElement).style.paddingBottom = '30px';

  // highlight copy link button
  document.querySelector('#users-bar .copy-share-link').classList.add('highlight-default-pulse');
};

const showEverythingInInviteMenu = () => {
  document.querySelectorAll('.invite-menu .invite-container *').forEach((el) => {
    el.classList.remove('onboarding-hidden');
  });
};

const hideOnboardingInviteMenu = () => {
  // hide onboarding UI
  document.querySelector('#invite-popup-onboarding-step').classList.add('onboarding-hidden');

  // reset paddings
  (document.querySelector('.invite-menu') as HTMLElement).style.paddingTop = null;
  (document.querySelector('.invite-menu') as HTMLElement).style.paddingBottom = null;

  // unhighlight copy link button
  document.querySelector('#users-bar .copy-share-link').classList.remove('highlight-default-pulse');
};

const MediaPlayerInviteFriendsHintScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    disableEverything();
    enableElements('.inner-bar.invite-users');

    hideEverythingInInviteMenu();
    showOnboardingInviteMenu();

    UIKit.dropdown('.invite-menu').show();

    // go next step on dropdown close
    const goNextStep = () => {
      dispatch(goNextScreen({}));
    };

    UIKit.util.on('.invite-menu', 'beforehide', goNextStep);

    const onCopyLink = () => {
      UIKit.dropdown('.invite-menu').hide(0);
    };

    // go to next step on copy link
    document.querySelector('.copy-share-link').addEventListener('click', onCopyLink);

    return () => {
      enableEverything();

      document.querySelector('.copy-share-link').removeEventListener('click', onCopyLink);

      showEverythingInInviteMenu();
      hideOnboardingInviteMenu();

      UIKit.util.off('.invite-menu', 'beforehide', goNextStep);
    };
  }, [dispatch]);

  return (
    <Portal root={document.querySelector('#copy-invite-link-hint-arrow-anchor')}>
      <ArrowImage src="gif/onboarding-arrow.gif" />
    </Portal>
  );
};

const ArrowImage = styled.img`
  position: fixed;
  margin-top: -50px;
  margin-left: -110px;
  width: 125px;
  z-index: 10;
  transform: rotate(80deg);
`;

export default MediaPlayerInviteFriendsHintScreen;
