export function canvasToScreenCoords(x, y, scale = window.canvasScale) {
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  return [
    (parseFloat(x) + window.canvasOffsetX - winWidth / 2) * scale + winWidth / 2,
    (parseFloat(y) + window.canvasOffsetY - winHeight / 2) * scale + winHeight / 2,
  ];
}

export function screenToCanvasCoords(x, y) {
  return screenToCustomCanvasCoords({
    x,
    y,
    canvasOffsetX: window.canvasOffsetX,
    canvasOffsetY: window.canvasOffsetY,
    canvasScale: window.canvasScale,
  });
}

export function screenToCustomCanvasCoords({ x, y, canvasOffsetX, canvasOffsetY, canvasScale }) {
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  return [
    (parseFloat(x) - winWidth / 2) / canvasScale - canvasOffsetX + winWidth / 2,
    (parseFloat(y) - winHeight / 2) / canvasScale - canvasOffsetY + winHeight / 2,
  ];
}
