import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

// store
import { goPrevScreen, goNextScreen, trySignInWithEmailAndPassword } from '../../store/signing-in/actions.ts';
import { selectEmail, selectTrySignInWithEmailAndPasswordError } from '../../store/signing-in/selectors.ts';

// components
import FlowInput from '../FlowInput.tsx';
import FlowInputsContainer from '../FlowInputsContainer.tsx';
import {
  InputContainer,
  NavButtonsContainer,
  NextNavButton,
  PrevNavButton,
  TitleContainer,
  ScreenTitle,
  ContainerDefault,
} from '../shared-styled.ts';

const EmailSignInScreen = () => {
  const dispatch = useDispatch();

  const savedEmail = useSelector(selectEmail);
  const emailPasswordScreenError = useSelector(selectTrySignInWithEmailAndPasswordError);

  const [email, setEmail] = useState(() => savedEmail);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [password, setPassword] = useState('');

  const canGoNext = isEmailValid && password.length > 0;

  useEffect(() => {
    // preventing weird autocomplete
    setEmail(savedEmail);
    setIsEmailValid(true);
    setPassword('');
  }, [savedEmail]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onPrevClick = () => {
    dispatch(goPrevScreen({ screenData: {} }));
  };

  const onNextClick = () => {
    dispatch(trySignInWithEmailAndPassword({ email, password }));
  };

  const onForgotPasswordClick = () => {
    dispatch(goNextScreen({ screenData: { resetPassword: true } }));
  };

  // TODO: Password validation?
  const isValid = isEmailValid && password;

  return (
    <ContainerDefault>
      <TitleContainer>
        <ScreenTitle>Sign In With Email</ScreenTitle>
      </TitleContainer>

      <FlowInputsContainer isValid={isValid} onSubmit={onNextClick}>
        <InputContainer>
          <FlowInput
            id="sign-in-email-input"
            type="email"
            label="Email"
            placeholder="example@email.com"
            value={email}
            disabled
          />
        </InputContainer>
        <InputContainer>
          <FlowInput
            label="Password"
            errorMessage={emailPasswordScreenError}
            id="sign-in-password-input"
            type="password"
            value={password}
            autoFocus
            onChange={handlePasswordChange}
            testId="sign-in-password-input-field"
          />
        </InputContainer>
      </FlowInputsContainer>

      <NavButtonsContainer>
        <PrevNavButton onClick={onPrevClick}>Cancel</PrevNavButton>
        <NextNavButton onClick={onNextClick} disabled={!canGoNext} data-testid="next-button">
          Next
        </NextNavButton>
      </NavButtonsContainer>

      <ForgotPasswordButtonContainer>
        <ForgotPasswordButton type="button" onClick={onForgotPasswordClick}>
          Forgot Password?
        </ForgotPasswordButton>
      </ForgotPasswordButtonContainer>
    </ContainerDefault>
  );
};

const ForgotPasswordButtonContainer = styled.div`
  margin-top: 31px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ForgotPasswordButton = styled.button`
  color: #12002d66;
  font-size: 16px;
  font-weight: bolder;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: #12002d;
    text-decoration: underline;
  }
`;

export default EmailSignInScreen;
