import CobrowserElement from './cobrowser';
import firebase, { db } from './firebase';
import log from './log';
import '../styles/codenames.less';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import { track } from './util/analytics-util';
import { STARTED_GAME, Games } from './constants/analytics-events/games-events';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

const CodenamesState = {
  unstarted: 'unstarted',
  starting: 'starting',
  started: 'started',
};

export default class CodenamesElement extends CobrowserElement {
  constructor(elementId) {
    super(elementId);

    this.state = CodenamesState.unstarted;
  }

  elementDescription() {
    return 'Codenames';
  }

  icon() {
    return 'slingshot';
  }

  static async addElement() {
    await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'CodenamesElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
        ),
        creator: firebase.auth().currentUser.uid,
        size: [800, 825],
        state: CodenamesState.unstarted,
        zIndex: window.getFrontZIndex(),
      });
    track(ADD_ELEMENT, { element: ELEMENT_TYPES.CODENAMES, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });

    return false;
  }

  handleUpdate(element, elementDoc) {
    log.debug('Received update', this.state, elementDoc.data());
    super.handleUpdate(element, elementDoc);
    let result = true;
    if (this.state !== elementDoc.data().state) {
      log.debug('Codenames changing state, reload it');
      result = false;
    }

    this.state = elementDoc.data().state;

    return result;
  }

  setup(elementId, elementDoc) {
    const data = elementDoc.data();
    this.state = data.state;
    if (this.state === CodenamesState.started) {
      log.debug(`Codenames game enabled at ${this.url}`);
      super.setup(elementId, elementDoc);
    } else if (this.state === CodenamesState.unstarted) {
      const element = document.getElementById(elementId);
      element.querySelector('.game-start').addEventListener('click', () => this.startGame());
    }
  }

  getElement(elementDoc) {
    const data = elementDoc.data();
    log.debug(`Codenames state: ${data.state}`);

    let game;
    switch (data.state) {
      case CodenamesState.unstarted: {
        game = htmlToElement(`
          <div class="game-title-screen-div">
            <h1>Codenames</h1>
            <button class="game-start here-button-regular">Start a New Game</button>
            <p>
              Don't know how to play?
              <a target="_blank" href="https://czechgames.com/files/rules/codenames-rules-en.pdf">See rules</a>
            </p>
          </div>
        `);
        break;
      }
      case CodenamesState.started: {
        track(STARTED_GAME, { game: Games.CODENAMES });
        this.url = `https://www.horsepaste.com/${data.gameId}`;
        return super.getElement(elementDoc);
      }
      default: {
        log.error('Unknown Codenames game state', this.state);
        return null;
      }
    }

    return wrapElement(game, elementDoc, {
      classes: ['codenames-element'],
      preserveAspectRatio: this.preserveAspectRatio(),
    });
  }

  iframeClasses() {
    return 'codenames-background';
  }

  preserveAspectRatio() {
    return true;
  }

  async startGame() {
    log.debug('Starting the game');
    await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .doc(this.elementId)
      .update({
        state: CodenamesState.started,
        gameId: Math.floor(100000000 + Math.random() * 900000000),
      });
  }
}
