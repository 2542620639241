import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PlayIcon from '../../icons/PlayIcon';

const PlayButton = ({ onClick, disabled }) => (
  <Button disabled={disabled} onClick={onClick} className="here-button-minimal">
    <PlayIcon opacity={disabled ? '0.3' : '1.0'} />
  </Button>
);

export default PlayButton;

PlayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const Button = styled.button`
  width: 60px;
  padding: 12px 11px 12px 20px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  &:not([disabled]):hover {
    transform: scale(1.1);
  }
`;
