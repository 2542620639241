import { authedApiInstance, db } from '../../../firebase';
import log from '../../../log';

export const getOnlineStatusesFromArray = (uids) =>
  authedApiInstance
    .get('/online', { params: { uids } })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getUserProfiles = ({ searchQuery, email, userId, username }) =>
  authedApiInstance
    .get('/userProfiles', { params: { searchQuery, email, userId, username } })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getUserFromFirebase = async (userId) => {
  const profileRef = await db.doc(`userProfiles/${userId}`).get();
  return {
    id: profileRef.id,
    ...profileRef.data(),
  };
};

export const updateUserProfile = async (userId, patch) => {
  try {
    await db.doc(`userProfiles/${userId}`).update(patch);
  } catch (e) {
    log.error(`There was an error updating userProfile ${userId} in firebase: `, e);
  }
};

export const getUserFriends = (userId) =>
  authedApiInstance
    .get(`/user/${userId}/friends`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);
