import React from 'react';
import styled from 'styled-components';

// components
import { ActiveMinutes, Avatar, AvatarContainer, Container, DisplayName, InitalIcon } from './shared-styles';
import { svgColorMixin } from '../../../../mixins';

// icons
import TopOneRoomMemberCrown from '../../../../../../assets/icons/top-one-room-member-crown.svg';

interface FirstRoomTopMemberProps {
  avatarUrl: string | null;
  displayName: string | null;
  activeMinutes: number;
}

const FirstRoomTopMember = ({ avatarUrl, displayName, activeMinutes }: FirstRoomTopMemberProps) => (
  <Container>
    <AvatarContainer>
      <CupIconGif src="/gif/top-1-cup.gif" alt="top 1 member cup" />
      {avatarUrl ? (
        <FirstMemberAvatar src={avatarUrl} />
      ) : (
        <FirstMemberInitialIcon>{(displayName || '')[0]}</FirstMemberInitialIcon>
      )}
      <PurpleFireGif src="/gif/purple-fire.gif" alt="purple fire" />
    </AvatarContainer>
    <FirstMemberDisplayNameContainer>
      <TopOneRoomMemberCrown />
      <DisplayName>{displayName || ''}</DisplayName>
    </FirstMemberDisplayNameContainer>
    <FirstMemberActiveMinutes>{activeMinutes} min</FirstMemberActiveMinutes>
  </Container>
);

const cupIconSizePx = 62;
const CupIconGif = styled.img`
  position: absolute;
  top: -${cupIconSizePx / 4}px;
  left: -${cupIconSizePx / 4}px;
  height: ${cupIconSizePx}px;
  width: ${cupIconSizePx}px;
  z-index: 2;
`;

const FirstMemberAvatar = styled(Avatar)`
  position: relative;
  z-index: 1;
`;

const FirstMemberInitialIcon = styled(InitalIcon)`
  position: relative;
  z-index: 1;
`;

const PurpleFireGif = styled.img`
  position: absolute;
  top: -45px;
  left: -10px;
  max-width: unset; // override the max-width from the uikit.css
  width: 100px;
`;

const FirstMemberDisplayNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${svgColorMixin('var(--secondary-foreground, white)')}
`;

const FirstMemberActiveMinutes = styled(ActiveMinutes)`
  font-weight: bold;
  max-width: unset;
  z-index: 0;
`;

export default FirstRoomTopMember;
