import { authedApiInstance } from '../firebase';
import { UnfoldedSkinsData } from '../react/store/chat-skinning/init-state';

interface ChatSkinningResponse extends UnfoldedSkinsData {
  success: boolean;
}

export const getChatSkinningData: () => Promise<UnfoldedSkinsData> = () =>
  authedApiInstance
    .get<ChatSkinningResponse>('/skins')
    .then((response) => response.data)
    .catch((error) => error?.response?.data);
