import { db } from './firebase';
import log from './log';

// Key is docpath. Value is object with 'listeners' and 'lastValue' keys.
// We need last value to simulate original 'onSnapshot' behavior: it calls
// listener with the current value immediately.
const listenerCache = {};

export function onSnapshot(path, listener, type = 'doc') {
  if (listenerCache[path]) {
    listenerCache[path].listeners.push(listener);
    if (listenerCache[path].lastValue) {
      // Making its behavior async so it's consistent.
      setTimeout(() => listener(listenerCache[path].lastValue), 0);
    }
    return;
  }

  listenerCache[path] = { listeners: [listener] };

  // Docs and collections paths shouldn't interfere, so it'll be fine.
  db[type](path).onSnapshot((snapshot) => {
    const value = type === 'collection' ? snapshot.docs.map(getDocWithId) : getDocWithId(snapshot);
    listenerCache[path].lastValue = value;
    listenerCache[path].listeners.forEach((f) => f(value));
  });
}

export function offSnapshot(path, listener) {
  if (!listenerCache[path]) {
    log.warn(`trying to remove listener for non-presented in cache path: ${path}`);
    return;
  }

  const listenerIndex = listenerCache[path].listeners.findIndex((f) => f === listener);
  if (listenerIndex === -1) {
    log.warn(`trying to remove non-added listener for ${path}`);
  }
  listenerCache[path].listeners.splice(listenerIndex, 1);
  // Do we need to unsubscribe if there're no listeners left?
  // Need to consider it from the pricing perspective.
}

function getDocWithId(doc) {
  const data = doc.data();
  return data
    ? {
        ...doc.data(),
        id: doc.id,
      }
    : null;
}
