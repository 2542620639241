import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_REACTIONS } from '../../user-profile/constants';

// general user selectors:

export const selectUserById = (state, userId) => state.users.users[userId];
export const allUsers = (state) => state.users.users;

// current user selectors:

export const selectCurrentUserId = (state) => state.users.currentUserId;
export const selectCurrentUser = createSelector(
  [selectCurrentUserId, allUsers],
  (userId, users) => users[userId] || {}
);
export const selectIsCurrentUserLoaded = createSelector(
  [selectCurrentUserId, allUsers],
  (userId, users) => !!users[userId]?.displayName
);

export const selectCurrentUserIsAdmin = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.isAdmin || false
);
export const selectCurrentUserUsername = createSelector([selectCurrentUser], (currentUser) => currentUser?.username);
export const selectCurrentUserDisplayName = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.displayName
);
export const selectCurrentUserFirstName = createSelector(
  selectCurrentUserDisplayName,
  (name) => name && name.split(' ')[0]
);
export const selectCurrentUserAvatarUrl = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.avatar?.thumbnail || currentUser?.avatar?.original
);
export const selectCurrentUserEmail = createSelector([selectCurrentUser], (currentUser) => currentUser?.email);
export const selectCurrentUserPhoneNumber = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.phoneNumber || ''
);
export const selectCurrentUserShouldHidePhoneModal = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.shouldHidePhoneModal === undefined || currentUser?.shouldHidePhoneModal
);
export const selectCurrentUserBackground = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.backgroundPhoto
);
export const selectCurrentUserReactions = createSelector([selectCurrentUser], (currentUser) => ({
  ...DEFAULT_REACTIONS,
  ...currentUser?.reactions,
}));
export const selectCurrentUserHasCustomizedChat = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.hasCustomizedChat
);
export const selectCurrentUserHasClickedDiscoverButton = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.hasClickedDiscoverButton
);
export const selectCurrentUserHasAcceptedPublicChats = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.hasUserAcceptedPublicChats
);
export const selectCurrentUserProfileTheme = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.profileTheme
);
export const selectCurrentUserVisitorsCount = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.userPageVisitors
);
export const selectCurrentUserSchoolId = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.schoolId || ''
);
export const selectCurrentUserGrade = createSelector([selectCurrentUser], (currentUser) => currentUser?.grade || '');

export const selectCurrentUserMessageColor = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.messageColor
);
export const selectCurrentUserFontColor = createSelector([selectCurrentUser], (currentUser) => currentUser?.fontColor);
export const selectCurrentUserFontSize = createSelector([selectCurrentUser], (currentUser) => currentUser?.fontSize);
export const selectCurrentUserFontFamily = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.fontFamily
);
export const selectCurrentUserPenColor = createSelector([selectCurrentUser], (currentUser) => currentUser?.penColor);

export const selectCurrentUserGroupsOnboardingStepId = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.groupsOnboardingStepId
);

export const selectUserFirstName = createSelector([selectUserById], (user) => user?.displayName?.split(' ')[0]);
export const selectUserDisplayName = createSelector([selectUserById], (user) => user?.displayName);
export const selectCurrentUserOsPageId = createSelector([selectCurrentUser], (currentUser) => currentUser?.osPageId);

// user search selectors:

// Note: the following search results selectors could return null if search has not
// been perfomed yet. Do a null check before doing a map
export const selectUserSearchResults = (state) => state.users.searchResults;
export const selectUserSearchResultProfiles = createSelector(
  [selectUserSearchResults, allUsers],
  (searchResultUserIds, users) => searchResultUserIds && searchResultUserIds.map((id) => users[id])
);
export const selectUserSearchError = (state) => state.users.userSearchError;
