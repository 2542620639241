import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import ColorSelect from '../../components/ColorSelect';
import { ColorBlock, ToolbarButton } from './shared-styles';
import { BoardElementControllerContext } from '../../common/contexts.ts';
import { invertHexColor } from '../../../util/color-util';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const FontColorPicker = ({ currentState, onChange }) => {
  const { elementData } = useContext(BoardElementControllerContext);
  const currentColor = useMemo(
    () => currentState.color || invertHexColor(elementData.backgroundColor || '#000000', { preserveOpacity: false }),
    [currentState.color, elementData.backgroundColor]
  );

  const [isOpened, setIsOpened] = useState(false);
  const onColorChange = useCallback((color) => onChange('color', color), [onChange]);

  const containerRef = useRef(null);
  useOnClickOutside(
    containerRef,
    useCallback(() => setIsOpened(false), [])
  );

  return (
    <div ref={containerRef}>
      <Popover.Root open={isOpened}>
        <Popover.Trigger asChild>
          <ToolbarButton className="rdw-option-wrapper" onClick={useCallback(() => setIsOpened(!isOpened), [isOpened])}>
            <div>A</div>
            <ColorBlock background={currentColor} />
          </ToolbarButton>
        </Popover.Trigger>
        <Content className="prevent-on-click-outside" onOpenAutoFocus={useCallback((e) => e.preventDefault(), [])}>
          <ColorSelect onChange={onColorChange} value={currentColor} />
        </Content>
      </Popover.Root>
    </div>
  );
};

export default FontColorPicker;

FontColorPicker.propTypes = {
  currentState: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

const Content = styled(Popover.Content)`
  &:focus-visible {
    outline: none;
  }
`;
