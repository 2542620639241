import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { fetchDefaultKaomojis } from '../store/kaomoji/actions';
import { selectIsKaomojiLoading, selectNonTrendingKaomojiCategories } from '../store/kaomoji/selectors';
import Loader from '../components/Loader';
import CustomKaomojisTab from './CustomKaomojisTab';
import KaomojiCategoryTab from './KaomojiCategoryTab';
import Tabs from '../components/Tabs';

interface Props {
  onSelectKaomoji: (content: string, category: string) => void;
}

const KaomojiPicker = ({ onSelectKaomoji }: Props) => {
  const dispatch = useDispatch();

  const categories = useSelector(selectNonTrendingKaomojiCategories);
  const isLoading = useSelector(selectIsKaomojiLoading);

  const onSelect = useCallback(
    ({ content, category }) => {
      onSelectKaomoji(content, category);
    },
    [onSelectKaomoji]
  );

  useEffect(() => {
    if (!isLoading && !categories.length) {
      dispatch(fetchDefaultKaomojis());
    }
  }, [dispatch, isLoading, categories.length]);

  const tabs = useMemo(
    () => [
      {
        name: '(｡˃ ᵕ ˂ )♡',
        node: (
          <TabContainer>
            <CustomKaomojisTab onSelect={onSelect} />
          </TabContainer>
        ),
      },
      ...categories.map((c) => ({
        name: c.name,
        node: (
          <TabContainer>
            <KaomojiCategoryTab category={c} onSelect={onSelect} />
          </TabContainer>
        ),
      })),
    ],
    [categories, onSelect]
  );

  return (
    <Container>
      {isLoading || !categories.length ? (
        <Loader />
      ) : (
        <Tabs tabs={tabs} uniqueId="kaomoji-picker" isTabsListScrollable />
      )}
    </Container>
  );
};

export default KaomojiPicker;

const sidePadding = '32px';

const Container = styled.div`
  width: 360px;
  background: var(--primary-background, white);
  padding: 16px ${sidePadding} 0;
  border-radius: 10px;
`;

const TabContainer = styled.div`
  height: 580px;
  overflow: auto;
  margin: 0 -${sidePadding} 0;
  padding: 0 ${sidePadding};

  @media (max-height: 800px) {
    height: 450px;
  }
`;
