import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';
import sample from 'lodash/fp/sample';

import GifSearchContainer from '../gif-search/GifSearchContainer';
import CardEditBackground from './CardEditBackground';
import CardEditTitle from './CardEditTitle';
import { useBoardData } from '../hooks/useBoardData';
import AddImageContainer from '../components/AddImageContainer';
import { svgColorMixin } from '../mixins';
import { gifPickerCallers } from '../../constants/image-constants';
import { db } from '../../firebase';
import { gifTypes } from '../store/gif-search/store';
import { track } from '../../util/analytics-util';

import PictureIcon from '../../../assets/icons/picture.svg';
import GifIcon from '../../../assets/icons/gif.svg';
import TextIcon from '../../../assets/icons/textNote.svg';
import GifText from '../../../assets/icons/gifText.svg';
import UploadIcon from '../../../assets/icons/upload.svg';
import OptionsIcon from '../../../assets/icons/options-dots.svg';
import DeleteIcon from '../../../assets/icons/trashcan.svg';

import { USER_CARD_WIDTH_PX, EDIT_TOOLBAR_BUTTONS, DEFAULT_WIDGET_PARAMS } from '../../constants/user-card-constants';
import { USER_CARD_TOOLBAR_BUTTON_CLICK } from '../../constants/analytics-events/user-page-events';
import { CheckIcon } from '../icons';
import { useMarkUserPageAsEdited } from '../user-page/useMarkUserPageAsEdited';
import {
  ADD_ELEMENT_DESTINATION_TYPES,
  ADD_ELEMENT_SOURCE_TYPES,
} from '../../constants/analytics-events/element-events';
import { uploadBackground } from '../../upload';
import { elementClasses } from '../board-elements/elements.ts';
import { notesDefaultBackgrounds } from '../board-elements/element-definitions';
import { textNotesFormat } from '../board-elements/note/htmlConverters';

const CardEditOverlay = ({ cardId, onAddElement, onFileUpload, onDelete, isFirstCard, onClose }) => {
  const [isBackgroundSettingOpened, setIsBackgroundSettingOpened] = useState(false);
  const [isGifPanelOpened, setIsGifPanelOpened] = useState(false);
  const [isGifTextPanelOpened, setIsGifTextPanelOpened] = useState(false);
  const [{ title = '' }] = useBoardData(cardId);

  // marks user page as edited if board or board elements are changed:
  useMarkUserPageAsEdited(cardId);

  const updateCardTitle = useCallback(
    (newTitle) => {
      db.doc(`boards/${cardId}`).update({
        title: newTitle,
      });
    },
    [cardId]
  );

  const onGifClick = useCallback(
    ({ url, width, height, imageType, useImageProxy, gifType }) => {
      onAddElement(
        {
          class: elementClasses.IMAGE,
          size: [
            DEFAULT_WIDGET_PARAMS.width,
            (DEFAULT_WIDGET_PARAMS.width * height) / width || DEFAULT_WIDGET_PARAMS.width,
          ],
          imageURL: url,
          imageType,
          useImageProxy: useImageProxy || gifType === gifTypes.text,
        },
        { analyticsParams: { source: ADD_ELEMENT_SOURCE_TYPES.SEARCH } }
      );
      setIsGifPanelOpened(false);
      setIsGifTextPanelOpened(false);
    },
    [onAddElement]
  );

  const onToolBarButtonClick = useCallback((buttonType) => {
    track(USER_CARD_TOOLBAR_BUTTON_CLICK, { buttonType });
  }, []);

  const onBackgroundUpload = useCallback(
    (file) => {
      uploadBackground(file, cardId, {
        destination: ADD_ELEMENT_DESTINATION_TYPES.CARD,
        shouldAddSystemMessage: false,
      });
    },
    [cardId]
  );

  return (
    <Container>
      <BackgroundButton onClick={() => setIsBackgroundSettingOpened(true)}>
        <PictureIcon /> Edit Background
      </BackgroundButton>
      <CardEditBackground
        cardId={cardId}
        isOpened={isBackgroundSettingOpened}
        onClose={useCallback(() => setIsBackgroundSettingOpened(false), [])}
        onBackgroundUpload={onBackgroundUpload}
      />
      <CardEditTitle title={title} onTitleUpdate={updateCardTitle} isFirstCard={isFirstCard} />
      <EditPanel>
        <CloseButton onClick={onClose}>
          <CheckIcon size="15px" />
        </CloseButton>
        <WidgetsPanel>
          <Popover.Root
            open={isGifPanelOpened}
            onOpenChange={(open) => {
              setIsGifPanelOpened(open);
              if (open) onToolBarButtonClick(EDIT_TOOLBAR_BUTTONS.GIF);
            }}
          >
            <Popover.Trigger asChild>
              <Button>
                <GifIcon />
              </Button>
            </Popover.Trigger>
            <Popover.Content
              side="right"
              asChild
              sideOffset={12}
              align="start"
              alignOffset={-100}
              onDragEnd={useCallback(() => setIsGifPanelOpened(false), [])}
            >
              <GifPanelContainer>
                <AddImageContainer
                  elementId="card-edit"
                  isGifsOnly
                  firebaseId={cardId}
                  caller={gifPickerCallers.USER_PAGE}
                  onResultClick={onGifClick}
                  isUserCard
                />
              </GifPanelContainer>
            </Popover.Content>
          </Popover.Root>
          <Popover.Root
            open={isGifTextPanelOpened}
            onOpenChange={useCallback(
              (open) => {
                setIsGifTextPanelOpened(open);
                if (open) onToolBarButtonClick(EDIT_TOOLBAR_BUTTONS.GIPHYTEXT);
              },
              [onToolBarButtonClick]
            )}
          >
            <Popover.Trigger asChild>
              <GifTextButton>
                <GifText />
              </GifTextButton>
            </Popover.Trigger>
            <Popover.Content
              side="right"
              asChild
              sideOffset={12}
              align="start"
              alignOffset={-100}
              onDragEnd={useCallback(() => setIsGifTextPanelOpened(false), [])}
            >
              <GifPanelContainer>
                <GifSearchContainer
                  height="40vh"
                  type={gifTypes.text}
                  elementId="card-edit-gif-text"
                  firebaseId={cardId}
                  caller={gifPickerCallers.USER_PAGE}
                  onResultClick={onGifClick}
                />
              </GifPanelContainer>
            </Popover.Content>
          </Popover.Root>
          <Button
            onClick={() => {
              onAddElement({
                class: elementClasses.TEXT,
                size: [DEFAULT_WIDGET_PARAMS.width, DEFAULT_WIDGET_PARAMS.height],
                backgroundColor: sample(notesDefaultBackgrounds),
                format: textNotesFormat.DRAFTJS,
              });
              onToolBarButtonClick(EDIT_TOOLBAR_BUTTONS.TEXT);
            }}
          >
            <TextIcon />
          </Button>
          <Button as="label" onClick={() => onToolBarButtonClick(EDIT_TOOLBAR_BUTTONS.UPLOAD)}>
            <UploadIcon />
            <input type="file" hidden onChange={onFileUpload} accept="image/*" />
          </Button>
        </WidgetsPanel>
        <Popover.Root>
          <Popover.Trigger asChild>
            <OptionsButton>
              <OptionsIcon />
            </OptionsButton>
          </Popover.Trigger>
          <Popover.Content side="right" asChild sideOffset={12}>
            <OptionsPanel>
              <Popover.Close asChild>
                <DeleteButton onClick={onDelete}>
                  <DeleteIcon /> Delete
                </DeleteButton>
              </Popover.Close>
            </OptionsPanel>
          </Popover.Content>
        </Popover.Root>
      </EditPanel>
    </Container>
  );
};

export default CardEditOverlay;

CardEditOverlay.propTypes = {
  cardId: PropTypes.string.isRequired,
  onAddElement: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isFirstCard: PropTypes.bool,
};

CardEditOverlay.defaultProps = {
  isFirstCard: false,
};

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;

  pointer-events: all;
`;

export const Button = styled.div`
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
  background: white;
  color: black;
  ${svgColorMixin('black')}

  &:hover,
  &[data-state="open"] {
    background: #6b1be3;
    color: white;
    ${svgColorMixin('white')}
  }
`;

const GifTextButton = styled.div`
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
  background: white;
  color: black;

  &:hover,
  &[data-state='open'] {
    background: #6b1be3;
    color: white;
    path,
    mask,
    rect {
      &.black-gif-text {
        fill: white;
      }
      &.white-gif-text {
        fill: black;
      }
    }
  }
`;

const BackgroundButton = styled(Button)`
  position: absolute;
  top: -56px;
  left: 63px;
  border-radius: 24px;
  padding: 9px 16px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const EditPanel = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${USER_CARD_WIDTH_PX + 12}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CloseButton = styled(Button)`
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
`;

const WidgetsPanel = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 8px 3px;
  border-radius: 30px;

  ${Button}, ${GifTextButton} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    margin: 4px 0;
    box-sizing: content-box;
  }
`;

export const OptionsButton = styled(Button)`
  padding: 9px 11px;

  svg {
    width: 20px;
  }
`;

const OptionsPanel = styled.div`
  background: white;
  border-radius: 10px;
  padding: 4px;

  &:focus-visible {
    outline: none;
  }
`;

const OptionsPanelButton = styled.div`
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-right: 12px;
    max-width: none;
  }
`;

const DeleteButton = styled(OptionsPanelButton)`
  color: #f00;
  ${svgColorMixin('#f00')}
`;

const GifPanelContainer = styled.div.attrs({ className: 'unthemed prevent-profile-closing' })`
  background: white;
  width: 380px;
  padding: 10px;
  border-radius: 10px;
  border: black solid 1px;

  &:focus-visible {
    outline: none;
  }
`;
