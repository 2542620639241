import { useEffect, useState } from 'react';
import { DEFAULT_FREQ_USED, FREQ_USED_STORAGE_KEY } from '../constants';

export const useFrequentlyUsed = () => {
  const [frequentlyUsed, setFrequentlyUsed] = useState<{ emoteUrl?: string; emoji?: string }[]>(
    DEFAULT_FREQ_USED.map((emoji) => ({ emoji }))
  );

  // grab from localstorage upon initial load
  useEffect(() => {
    const storage = localStorage.getItem(FREQ_USED_STORAGE_KEY);

    if (storage) {
      setFrequentlyUsed(JSON.parse(storage));
    }
  }, []);

  // set localstorage if is updated
  useEffect(() => {
    localStorage.setItem(FREQ_USED_STORAGE_KEY, JSON.stringify(frequentlyUsed));
  }, [frequentlyUsed]);

  return { frequentlyUsed, setFrequentlyUsed };
};
