import Showdown from 'showdown';
import DiffMatchPatch from 'diff-match-patch';
import CodeMirror from 'codemirror';
import Firepad from 'firepad';
import $ from 'jquery';

import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { elementMoved } from './drag';
import { sanitize, linkify, htmlToElement, onIdle } from './util';
import { screenToCanvasCoords } from './util/canvas-util';
import log from './log';
import { isElementsInteractionAllowed, isLockAllowed } from './roles-management';
import wrapElement from './element-wrapper';
import BoardElement from './board-element';

import '../styles/notes.less';
import 'codemirror/theme/tomorrow-night-eighties.css';
import { textNotesFormat } from './react/board-elements/note/htmlConverters';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';
import { USER_CARD_BOARD_TYPE } from './constants/board-constants';

require('codemirror/mode/javascript/javascript');
require('codemirror/addon/runmode/runmode');

const showdown = new Showdown.Converter();

let newElementId = null;

export const TextElementMode = {
  Label: 'label',
  Document: 'document',
  Code: 'code',
};

export default class TextElement extends BoardElement {
  constructor(elementDoc) {
    super(elementDoc.id);

    this.entryMode = elementDoc.data().mode;
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    const elementData = elementDoc.data();
    this.elementData = elementData;
    this.locked = elementData.locked;

    // TODO: Update text inline here too
    // We're transitioning from "text" to "content" fields here.
    if (
      (this.text !== undefined && this.text !== null && this.text === elementData.text) ||
      (this.content !== undefined && this.content !== null && this.content === elementData.content) ||
      this.firepad ||
      this.entryMode === TextElementMode.Code
    ) {
      return true;
    }

    this.setBackground(elementData.backgroundColor);
    return false;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    const data = elementDoc.data();

    this.locked = data.locked;
    this.setupEditClick();

    if (elementDoc.id === newElementId) {
      newElementId = null;
      this.beginEditText();
    }

    document.getElementById(`note-bg-color-${this.elementId}`).addEventListener('colorchange', (e) => {
      this.setBackground(e.detail.color);
      if (e.detail.inputComplete) {
        db.doc(document.getElementById(`element-${elementDoc.id}`).getAttribute('docPath')).update({
          backgroundColor: e.detail.color,
        });
      }
    });

    document.getElementById(`done-${elementDoc.id}`).addEventListener('click', (e) => {
      this.doneEditing();
      e.stopPropagation();
    });

    this.entryMode = data.mode;
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();
    this.elementData = data;
    if (data.content && data.content.length > 0) {
      this.content = linkify(data.content);
      this.rawContent = data.rawContent;
      if (this.entryMode === TextElementMode.Code) {
        this.code = data.rawContent;
      }
    } else {
      // legacy note format
      this.text = data.text;
      this.content = showdown.makeHtml(sanitize(this.setStyle(data.text)));
    }

    const editor = htmlToElement(`
      <div style="height: 100%">
        <div class="text-editor ${this.entryMode === TextElementMode.Code ? 'code-editor' : ''}"></div>
        <div class="text-container rotatable-container">${this.content}</div>
        <div class="element-menu-bar ${
          !isElementsInteractionAllowed() || (data.locked && !isLockAllowed()) ? 'hidden' : ''
        }">
          <button
            class="menu-button"
            id="done-${elementDoc.id}"
            uk-tooltip="title: Done Editing"
            style="display: none">
            <here-inline-svg src="images/icons/check.svg"></here-inline-svg>
          </button>
          <button
            class="menu-button"
            id="edit-${elementDoc.id}"
            uk-tooltip="title: Edit">
            <here-inline-svg src="images/icons/pen.svg"></here-inline-svg>
          </button>
          <button
            class="menu-button"
            id="color-${elementDoc.id}"
            uk-tooltip="title: Background Color">
            <here-inline-svg src="images/icons/fill.svg"></here-inline-svg>
          </button>
          <div uk-dropdown="mode: click; pos: top-justify" class="element-submenu color-dropdown">
            <here-color-select alpha="true" id="note-bg-color-${this.elementId}" />
          </div>
        </div>
      </div>
    `);
    editor.querySelector(`#edit-${elementDoc.id}`).addEventListener('click', () => this.beginEditText());

    const wrappedElement = wrapElement(editor, elementDoc, {
      classes: ['textCard'],
    });

    this.setBackground(data.backgroundColor, wrappedElement);

    const copyButton = htmlToElement(`
      <div
        uk-tooltip="title: Copy to Clipboard"
        class="element-option-button copy-button">
      </div>
    `);
    copyButton.addEventListener('click', () => this.copyToClipboard());
    const optionsButton = wrappedElement.querySelector('.element-overflow');
    // Not sure which way of adding custom buttons will remain, so monkey-patching for now 🐒
    optionsButton.parentNode.insertBefore(copyButton, optionsButton);

    return wrappedElement;
  }

  setBackground(backgroundColor, element) {
    const background = backgroundColor || '#333333bb';
    const container = element || document.getElementById(`element-${this.elementId}`);
    container.querySelectorAll('.text-editor, .text-container').forEach((el) => {
      el.style.background = background;
    });
  }

  refresh() {
    if (this.codeMirror) {
      this.codeMirror.refresh();
    }
  }

  publishApp() {
    // TODO
  }

  copyToClipboard() {
    let html = '';
    let text = '';
    if (this.firepad) {
      html = this.firepad.getHtml();
      text = this.firepad.getText();
    } else {
      const target = $(`#element-${this.elementId}`);
      const textElement = target.find('.text-container');
      html = textElement.html();
      text = textElement.get(0).innerText;
    }

    function listener(e) {
      e.clipboardData.setData('text/html', html);
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    const options = $(`#footer-menu-${this.elementId}`);
    const copyMsgId = `copy-message-${this.elementId}`;
    options.prepend(`<div id="${copyMsgId}">Copied!</div>`);
    setTimeout(() => {
      $(`#${copyMsgId}`).remove();
    }, 1000);
  }

  // Statics

  static getCodeMarkup(text) {
    const element = document.createElement('div');
    CodeMirror.runMode(text, 'text/javascript', element);
    return `<pre class="cm-s-tomorrow-night-eighties">${element.innerHTML}</pre>`;
  }

  static finishAllEdits() {
    Object.values(window.elementHandlers)
      .filter((h) => h instanceof TextElement && h.isEditing)
      .forEach((h) => h.doneEditing());
  }

  static async addCodeElement(initialCode) {
    const code = initialCode || '// Code goes here';
    const ref = await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'TextElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
        ),
        creator: firebase.auth().currentUser.uid,
        size: [400, 400],
        backgroundColor: '#000000ee',
        zIndex: window.getFrontZIndex(),
        mode: TextElementMode.Code,
        content: this.getCodeMarkup(code),
        rawContent: code,
      });

    addSystemMessage('added a code editor');
    track(ADD_ELEMENT, { element: ELEMENT_TYPES.CODE_EDITOR, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
    return ref;
  }

  /* options: width, height, backgroundColor */
  static async addElement(opts) {
    try {
      const boardRef = db.collection('boards').doc(window.currentBoardId);
      const boardData = (await boardRef.get()).data();
      const docRef = await boardRef.collection('elements').add({
        class: 'TextElement',
        center:
          opts.center ||
          screenToCanvasCoords(
            Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
            Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
          ),
        creator: firebase.auth().currentUser.uid,
        size: [opts.width, opts.height],
        mode: opts.mode ? opts.mode : TextElementMode.Document,
        backgroundColor: opts.backgroundColor || null,
        zIndex: window.getFrontZIndex(),
        text: '',
      });

      addSystemMessage('added a note');
      track(ADD_ELEMENT, {
        element: ELEMENT_TYPES.TEXT,
        destination:
          boardData.type === USER_CARD_BOARD_TYPE
            ? ADD_ELEMENT_DESTINATION_TYPES.CARD
            : ADD_ELEMENT_DESTINATION_TYPES.ROOM,
      });

      if (window.elementHandlers[docRef.id]) {
        window.elementHandlers[docRef.id].beginEditText(opts);
      } else {
        newElementId = docRef.id;
      }
    } catch (error) {
      log.error('Something went wrong creating TextElement: ', error);
    }
  }

  // Internal

  beginEditText(opts = {}) {
    this.isEditing = true;
    document.getElementById(`edit-${this.elementId}`).style.display = 'none';
    document.getElementById(`done-${this.elementId}`).style.display = 'block';

    const target = document.getElementById(`element-${this.elementId}`);
    const textElement = target.querySelector('.text-container');
    target.classList.add('text-editing');

    const editorElement = target.querySelector('.text-editor');
    // TODO: Update scale and scroll to visible area.
    // https://app.clubhouse.io/hereapp/story/155/zoom-to-1-0-and-ensure-text-is-on-screen-when-editing-begins
    /* if (canvasScale !== 1.0) {
        updateCanvasPosition(canvasOffsetX, canvasOffsetY, 1.0, true);
        ensureComponentVisible(target.id);
      } */

    const loadTimer = window.setTimeout(() => {
      const loader = htmlToElement('<here-loader>Loading</here-loader>');
      textElement.insertBefore(loader, textElement.firstChild);
    }, 250);

    $(`#element-${this.elementId}`).off('click');
    const firepadRef = firebase.database().ref(`/notes/${this.elementId}`);

    if (!this.firepad || !this.codeMirror) {
      editorElement.innerHTML = '';
      // Create Firepad (with rich text toolbar and shortcuts enabled).
      if (this.entryMode === TextElementMode.Code) {
        this.codeMirror = CodeMirror(editorElement, {
          lineWrapping: true,
          matchBrackets: true,
          lineNumbers: false,
          mode: 'javascript',
          theme: 'tomorrow-night-eighties',
        });

        this.firepad = Firepad.fromCodeMirror(firepadRef, this.codeMirror, {
          richTextShortcuts: false,
          richTextToolbar: false,
          userId: firebase.auth().currentUser.uid,
        });
      } else {
        this.codeMirror = CodeMirror(editorElement, { lineWrapping: true, mode: null });
        // Firepad doesn't support <h?> tags and it looks like extending is going to suck :(
        this.firepad = Firepad.fromCodeMirror(firepadRef, this.codeMirror, {
          richTextShortcuts: true,
          richTextToolbar: true,
          userId: firebase.auth().currentUser.uid,
        });
      }
    }

    const self = this;
    let firepadReady = false;
    this.firepad.on('ready', () => {
      clearTimeout(loadTimer);
      target.querySelector('.text-container').style.display = 'none';
      $(editorElement).show();
      if (self.firepad.isHistoryEmpty()) {
        if (self.entryMode === TextElementMode.Code) {
          self.firepad.setText(self.rawContent);
        } else {
          let html = self.content;
          html = html.replace(/<h1/gi, '<div style="font-size: 2em"');
          html = html.replace(/<h2/gi, '<div style="font-size: 1.5em"');
          html = html.replace(/<h3/gi, '<div style="font-size: 1.1em"');
          html = html.replace(/<h4/gi, '<div style="font-size: 1.0em"');
          html = html.replace(/<\/h.>/gi, '</div>');
          self.firepad.setHtml(html);
        }
      }

      self.codeMirror.focus();
      self.codeMirror.setCursor({ line: 0, ch: 0 });
      if (opts.fontSize) {
        self.firepad.fontSize(opts.fontSize);
      }
      if (opts.color) {
        self.firepad.color(opts.color);
      }

      firepadReady = true;
    });

    if (!this.firepadChangedEvent) {
      this.firepadChangedEvent = self.firepadChanged.bind(this);
    }
    this.firepad.off('synced', this.firepadChangedEvent);
    this.firepad.on('synced', this.firepadChangedEvent);

    if (!this.codemirrorTextChangedEvent) {
      this.codemirrorTextChangedEvent = () => {
        if (firepadReady && this.onTextChanged) {
          this.code = this.firepad.getText();
          this.onTextChanged(this.code);
        }
      };
    }
    this.codeMirror.off('change', this.codemirrorTextChangedEvent);
    this.codeMirror.on('change', this.codemirrorTextChangedEvent);

    if (!this.mouseDownEvent) {
      this.mouseDownEvent = this.onMouseDown.bind(this);
    }

    if (!this.stopPropEvent) {
      this.stopPropEvent = this.stopProp.bind(this);
    }

    $(editorElement).on('mousedown', (e) => {
      this.stopProp(e);
    });
    // textElement.removeEventListener('mousedown', this.stopPropEvent);
    // textElement.addEventListener('mousedown', this.stopPropEvent);
    document.removeEventListener('mousedown', this.mouseDownEvent);
    document.addEventListener('mousedown', this.mouseDownEvent);
  }

  stopProp(e) {
    // if the target of the click isn't the container nor a descendant of the container
    if (this.firepad) {
      e.stopPropagation();
    }
  }

  doneEditing() {
    this.isEditing = false;
    this.rawContent = this.firepad.getText();
    const newMarkup =
      this.entryMode === TextElementMode.Code ? TextElement.getCodeMarkup(this.rawContent) : this.firepad.getHtml();
    const target = $(`#element-${this.elementId}`);
    if (!target.get(0).classList.contains('text-editing')) {
      return;
    }

    $(target).removeClass('text-editing');

    if (this.entryMode !== TextElementMode.Code) {
      const container = target.get(0).querySelector('.text-container');
      container.innerHTML = linkify(newMarkup);
      $(target).find('.text-container').show();
      $(target).find('.text-editor').hide();

      this.firepad.dispose();
      this.firepad = null;
      this.codeMirror = null;
    } else {
      const overlay = htmlToElement('<div class="text-editor-overlay"></div>');
      target.get(0).appendChild(overlay);
    }

    db.doc($(target).attr('docPath'))
      .update({
        content: newMarkup,
        rawContent: this.rawContent,
        lastEditedBy: firebase.auth().currentUser.uid,
        format: textNotesFormat.FIREPAD,
      })
      .catch((error) => {
        log.error('Error updating note', error);
      });
    document.getElementById(`edit-${this.elementId}`).style.display = 'block';
    document.getElementById(`done-${this.elementId}`).style.display = 'none';
    this.setupEditClick();
  }

  onMouseDown(e) {
    const target = $(`#element-${this.elementId}`);

    // if the target of the click isn't the container nor a descendant of the container
    if (target.is(e.target) || target.has(e.target).length !== 0) {
      return;
    }

    // TODO fix this
    document.removeEventListener('mousedown', this.mouseDownEvent);
    if (!target.get(0)) {
      // check because textEditor can be deleted
      return;
    }

    target.get(0).removeEventListener('mousedown', this.stopPropEvent);

    if (this.firepad) {
      this.doneEditing();
    }
  }

  firepadChanged() {
    // Code gets sent as raw text, everything else as html
    let newMarkup = this.entryMode === TextElementMode.Code ? this.code : this.firepad.getHtml();
    const oldMarkup = this.entryMode === TextElementMode.Code ? this.rawContent : this.content;
    if (this.entryMode === TextElementMode.Label) {
      const newlineMarker = '<div>&nbsp;</div>';
      if (newMarkup.includes(newlineMarker)) {
        this.doneEditing();
        newMarkup = newMarkup.replace(newlineMarker, '');
        this.firebase.setHtml(newMarkup);
      }
    }

    if (this.firepadUpdateCallback) {
      window.cancelIdleCallback(this.firepadUpdateCallback);
    }

    this.firepadUpdateCallback = onIdle(
      () => {
        if (!this.dmp) {
          this.dmp = new DiffMatchPatch();
        }
        const patch = this.dmp.patch_make(oldMarkup, newMarkup);
        const patchText = this.dmp.patch_toText(patch);
        window.rtc.sendMarkupDiff(this.elementId, patchText);
      },
      { timeout: 500 }
    );
  }

  applyMarkupDiff(patchData) {
    // Don't apply if we are editing, too.
    if (this.firepad) {
      return;
    }

    if (!this.dmp) {
      this.dmp = new DiffMatchPatch();
    }
    const patches = this.dmp.patch_fromText(patchData);
    const oldText = this.entryMode === TextElementMode.Code ? this.rawContent : this.content;
    const newText = this.dmp.patch_apply(patches, oldText)[0];
    // Code gets sent as raw text - let's stylize it.
    let newMarkup = newText;
    if (this.entryMode === TextElementMode.Code) {
      newMarkup = TextElement.getCodeMarkup(newText);
    }
    $(`#element-${this.elementId}`).find('.text-container').html(linkify(newMarkup));

    if (this.entryMode === TextElementMode.Code) {
      this.code = newText;
    }
    if (this.onTextChanged) {
      this.onTextChanged(newText);
    }
  }

  // This whole thing is a hack, but maybe informative for the future
  setStyle(text) {
    let style = '';
    // HACK extract style info here
    const bgRegex = /\{bg: ?(#........)\}/gi;
    const fgRegex = /\{fg: ?(#........)\}/gi;
    const bgResult = bgRegex.exec(this.text);
    if (bgResult) {
      style += `background-color: ${bgResult[1]};`;
      text = text.replace(bgRegex, '');
    }
    const fgResult = fgRegex.exec(this.text);
    if (fgResult) {
      style += `color: ${fgResult[1]};`;
      text = text.replace(fgRegex, '');
    }

    const oldStyle = document.getElementById(`style-${this.elementId}`);
    if (oldStyle) {
      oldStyle.parentNode.removeChild(oldStyle); // remove these styles
    }

    if (style.length) {
      const elName = `#element-${this.elementId}`;
      const styleTag = htmlToElement(`
        <style id="style-${this.elementId}">
          ${elName}, ${elName} p,
          ${elName} li, ${elName} h1,
          ${elName} h2, ${elName} h3 {
            ${style}
          }
      `);
      document.head.append(styleTag);
    }

    return text;
  }

  setupEditClick() {
    const element = document.getElementById(`element-${this.elementId}`);
    element.addEventListener('click', (e) => {
      if (elementMoved()) {
        return;
      }

      if (
        this.elementData.readOnly ||
        (this.elementData.lockedInteraction && !isLockAllowed()) ||
        !element.classList.contains('can-move')
      )
        return;

      const overlay = element.querySelector('.text-editor-overlay');
      if (overlay) {
        overlay.parentNode.removeChild(overlay);
      }

      this.beginEditText(e);
    });
  }

  minSize() {
    return [160, 50];
  }
}

TextElement.elementType = 'TextElement';
