import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// icons
import UploadErrorMessageIcon from '../../../../assets/icons/red-triangle-alert.svg';

const UploadErrorMessage = (props) => {
  const { text } = props;

  return (
    <UploadErrorMessageContainer>
      <UploadErrorMessageIconCotnainer>
        <UploadErrorMessageIcon />
      </UploadErrorMessageIconCotnainer>
      <UploadErrorMessageText>{text}</UploadErrorMessageText>
    </UploadErrorMessageContainer>
  );
};

UploadErrorMessage.propTypes = {
  text: PropTypes.string.isRequired,
};

const UploadErrorMessageContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const UploadErrorMessageIconCotnainer = styled.div`
  svg {
    width: 45px;
  }
`;

const UploadErrorMessageText = styled.p`
  margin: 0 0 0 4px;
  color: #f6335d;
  font-size: 14px;
  line-height: 17px;
`;

export default UploadErrorMessage;
