import { IceRestartStatsType, QualityLimitationDurations } from '../sfu/stats/definitions';

export type UUID = string;

export type UserId = string;

export type DistributionField = { [key: number]: number };

export type MeasuredKey = number | string;
export type MeasuredValue = number;
export type DataSampleObject = {
  [key: MeasuredKey]: MeasuredValue;
};
export type DataSample = DataSampleObject | number | number[];

export enum SessionMetricPath {
  /** Session Level Metrics */
  Elapsed = 'elapsed',

  /** Publisher Level Metrics */
  CameraPublisherQualityLimitationDurationsCpu = 'camera.qualityLimitationDurations.cpu',
  CameraPublisherQualityLimitationDurationsBandWidth = 'camera.qualityLimitationDurations.bandwidth',
  CameraPublisherQualityLimitationDurationsNone = 'camera.qualityLimitationDurations.none',
  CameraPublisherQualityLimitationDurationsOther = 'camera.qualityLimitationDurations.other',

  ScreensharePublisherQualityLimitationDurationsCpu = 'screenshare.qualityLimitationDurations.cpu',
  ScreensharePublisherQualityLimitationDurationsBandWidth = 'screenshare.qualityLimitationDurations.bandwidth',
  ScreensharePublisherQualityLimitationDurationsNone = 'screenshare.qualityLimitationDurations.none',
  ScreensharePublisherQualityLimitationDurationsOther = 'screenshare.qualityLimitationDurations.other',

  PublisherCameraElapsed = 'camera.elapsed',
  PublisherCameraBitrate = 'camera.bitrate',
  PublihserCameraFirCount = 'camera.firCount',
  PublihserCameraPliCount = 'camera.pliCount',
  PublihserCameraNackCount = 'camera.nackCount',

  IceRestartStats = 'camera.iceRestartsStats',

  PublisherMicElapsed = 'mic.elapsed',
  PublisherMicBitrate = 'mic.bitrate',
  PublihserMicNackCount = 'mic.nackCount',

  PublisherScreenShareElapsed = 'screenshare.elapsed',
  PublisherScreenshareBitrate = 'screenshare.bitrate',
  PublihserScreenshareFirCount = 'screenshare.firCount',
  PublihserScreensharePliCount = 'screenshare.pliCount',
  PublihserScreenshareNackCount = 'screenshare.nackCount',

  PublisherScreenShareMicElapsed = 'screenshareMic.elapsed',
  PublisherScreenshareMicBitrate = 'screenshareMic.bitrate',
  PublisherScreenshareMicNackCount = 'screenshareMic.nackCount',

  RoundTripTimes = 'roundTripTimes',

  /** Streams Level Metrics */
  Streams = 'streams',
  SubscriberAvDrift = 'streams.avDrift',
  SubscriberFrameDecodeTime = 'streams.frameDecodeTime',
  SubscriberVideoNack = 'streams.videoNack',
  SubscriberVideoFir = 'streams.fir',
  SubscriberVideoPli = 'streams.pli',
  SubscriberAudioNack = 'streams.audioNack',
  SubscriberVideoStallCount = 'streams.stallCount',
  SubscriberJitter = 'streams.jitter',
}

export enum ScreenshareMetricsEnum {
  Bitrate = 'bitrate',
  RoundTrip = 'roundTrip',
}

export enum SessionMediaNames {
  Audio = 'audio',
  Video = 'video',
}

export enum SessionsRemoteStreamMetricNames {
  AudioNack = 'audioNack',
  VideoNack = 'videoNack',
  FIR = 'fir',
  PLI = 'pli',
  StallCount = 'stallCount',
  AvDrift = 'avDrift',
  FrameDecodeTime = 'frameDecodeTime',
  Jitter = 'jitter',
}

export enum PublisherMetricNames {
  publisherFirCount = 'publisherFirCount',
  publisherNackCount = 'publisherNackCount',
  publisherPliCount = 'publisherPliCount',
}

export type UsersDictionary = {
  [key in UUID]: string;
};

export enum DistributionFieldsEnum {
  Bitrate = 'bitrate',
  RoundTrip = 'roundTrip',
  AvDrift = 'avDrift',
  FrameDecodeTime = 'frameDecodeTime',
  Jitter = 'jitter',
}

export type PublisherDistributionFields = DistributionFieldsEnum.Bitrate;

export type SubscriberDistributionFields = DistributionFieldsEnum.AvDrift | DistributionFieldsEnum.FrameDecodeTime;

export type ScreenshareMetricsInterface = {
  [key in ScreenshareMetricsEnum]: { [key: number]: number };
};

export interface SessionRemoteStreamStat {
  audio: boolean;
  video: boolean;
  audioNack: number;
  videoNack: number;
  fir: number;
  pli: number;
  stallCount: number;
  avDrift: DistributionField;
  frameDecodeTime: DistributionField;
  jitter: DistributionField;
}

export type MediaSource = 'camera' | 'mic' | 'screenshare' | 'screenshareMic';

export interface SessionPublisherStat {
  source: MediaSource;
  elapsed: number;
  nackCount: number;
  firCount: number;
  pliCount: number;
  qualityLimitationDurations: QualityLimitationDurations | null;
  bitrate: DistributionField;
  iceRestartsStats: IceRestartStatsType;
}
export interface SessionLogDefinition {
  /** Last update timestamp */
  timestamp: number;

  /** This session duration so far */
  elapsed: number;

  /** Video Out Stats */
  camera: SessionPublisherStat | null;

  /** Screenshare Out Stats */
  screenshare: SessionPublisherStat | null;

  /** Audio Out Stats */
  mic: SessionPublisherStat | null;

  /** Screenshare Audio Out Stats */
  screenshareMic: SessionPublisherStat | null;

  /** Incoming Streams */
  streams: { [key: UserId]: SessionRemoteStreamStat };

  /** Roundtrip times for each candidate id */
  roundTripTimes?: DistributionField;

  /** @deprecated roundTrip time it is being measured mixing candidates */
  roundTrip?: DistributionField;

  /** @deprecated recorded per output media type see PublisherStat */
  publisherNackCount?: number;

  /** @deprecated recorded per output media type see PublisherStat */
  publisherFirCount?: number;

  /** @deprecated recorded per output media type see PublisherStat */
  publisherPliCount?: number;

  /** @deprecated because it is unavailable in most platforms */
  publisherSliCount?: number;

  /** @deprecated recorded per output media type see PublisherStat */
  publisherQualityLimitationDurations?: number;

  /** @deprecated stallCount should be per-stream basis, and maybe a distribution field? */
  stallCount?: number;
}
export interface WebRTCLogDocument {
  id: UUID;
  data: () => SessionLogDefinition;
}

/*
declare enum ClientType {
  Unknown = 'Unknown',
}

declare interface BoardInterface {
  id: string;
  title: string;
}

declare enum ActivityType {
  Camera = 'camera',
}

declare interface CameraLogsInterface {
  audioDeviceName: string;
  client: ClientType;
  startTime: number;
  endTime: number;
  platform: string;
  user: string;
  videoDeviceName: string;
}
declare interface GlobalActivityInterface {
  // Properties
  type: ActivityType;
  createdAt: string;
  videoServer: string;
  board: BoardInterface;
  users: UsersDictionary;

  // Collections
  cameraLogs: CameraLogsInterface[];
  webrtcLogs: WebRTCLogDocument[];
}
*/
