import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useMultistep = (steps: Function[]) => {
  const [stepIndex, setStepIndex] = useState(0);

  const goNext = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
    }
  };

  const goPrev = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  return {
    step: steps[stepIndex](goNext, goPrev),
  };
};
