import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import ColorSelect from '../../../components/ColorSelect';

import { svgColorMixin } from '../../../mixins';
import PenIcon from '../../../../../assets/icons/pen.svg';

const CustomThemeColorSelect = ({ label, value, previewText, isSecondary, onChange, buttonHeight }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div>
      <Label isOpened={isOpened}>{label}</Label>
      <Popover.Root onOpenChange={setIsOpened}>
        <PopoverButton height={buttonHeight}>
          <ColorIndicator isSecondary={isSecondary} isOpened={isOpened} height={buttonHeight}>
            {previewText}
            <EditIconContainer isOpened={isOpened}>
              <PenIcon />
            </EditIconContainer>
          </ColorIndicator>
        </PopoverButton>
        <Popover.Content side="right" sideOffset={20}>
          <div className="prevent-profile-closing prevent-on-click-outside">
            <ColorSelect value={value || '#000000'} onChange={onChange} />
          </div>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};

export default CustomThemeColorSelect;

CustomThemeColorSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  buttonHeight: PropTypes.number,
  previewText: PropTypes.string,
  isSecondary: PropTypes.bool,
};

CustomThemeColorSelect.defaultProps = {
  value: '',
  buttonHeight: 70,
  previewText: '',
  isSecondary: false,
};

// No fallback values for CSS vars here since it's displayed only when a theme is selected.

export const Label = styled.span`
  color: var(--primary-foreground);
  opacity: ${({ isOpened }) => (isOpened ? 1 : 0.7)};
  font-weight: ${({ isOpened }) => (isOpened ? 'bold' : 'normal')};
  margin-bottom: 4px;
  display: inline-block;
  font-size: 14px;
`;

const PopoverButton = styled(Popover.Trigger)`
  display: block;
  height: ${({ height }) => height}px;
  width: 100%;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const EDIT_ICON_SIZE = 30;
const EditIconContainer = styled.div`
  ${svgColorMixin('var(--secondary-foreground)')}

  background: var(--secondary-background);
  position: absolute;
  right: -7px;
  top: 0;
  border-radius: 50%;
  width: ${EDIT_ICON_SIZE}px;
  height: ${EDIT_ICON_SIZE}px;
  border: 1px solid var(--secondary-foreground);
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

const INACTIVE_SUFFIX = '-alpha-70';
const ColorIndicator = styled.div`
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  background: ${({ isSecondary, isOpened }) =>
    `var(--${isSecondary ? 'secondary' : 'primary'}-background${isOpened ? INACTIVE_SUFFIX : ''})`};
  color: var(--${({ isSecondary }) => (isSecondary ? 'secondary' : 'primary')}-foreground);
  border-radius: ${({ height }) => height / 2}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ height }) => height / 2}px;
  font-family: Inter, sans-serif;
  position: relative;
  outline: ${({ isOpened }) =>
    isOpened
      ? '2px solid var(--secondary-background)'
      : '1px solid var(--primary-foreground-alpha-20,rgba(0,0,0,0.2))'};

  &:hover {
    outline: ${({ isOpened }) => (isOpened ? '2' : '1')}px solid var(--secondary-background);
    background: ${({ isSecondary }) => `var(--${isSecondary ? 'secondary' : 'primary'}-background${INACTIVE_SUFFIX})`};

    & ${EditIconContainer} {
      display: flex;
    }
  }
`;
