/* eslint-disable @typescript-eslint/lines-between-class-members */
// import Janus from './janus';
import { delay, isEqual } from 'lodash';
import log from '../log';
import { trackInForeground as trackMetric } from '../util/analytics-util';
import { ConfiguredTrack, MediaType, OfferParams, TrackOption } from './janus/janus.definitions';
import JanusJS from './janus/janus.definitions.original';
import {
  ConnectionStatus,
  OnCameraUpdateFnPrototype,
  PublisherStreamSource,
  PublisherType,
  UserId,
} from './definitions/index.definitions';
import { getCurrentAudioTrackProvider, getCurrentVideoTrackProvider } from '../camera/track-provider-util';
import {
  BasePublisher,
  DeferCommand,
  DeferReason,
  DeferStatus,
  PublisherDelegateProtocol,
  PublishParams,
  ToggleMediaStrategy,
} from './base_publisher';
import VideoroomWrapper from './janus/videoroom';
import { Devices } from './devices';

const RECONFIGURE_ATTEMPTS_LIMIT = 200;

export default class CameraPublisher extends BasePublisher {
  /** Alpha numeric user id provided by application */
  userId: string;

  // FIXME:
  onUnpublish: (value: unknown) => void;

  private onCameraUpdate: OnCameraUpdateFnPrototype;

  /**
   * Creates a new instance of Publisher class.
   *
   * @param publisherDelegate An object that implements PublisherDelegateProtocol to callback.
   * @param userId Alphanumeric string representing the id of the publishing user.
   * @param bitrateConstraint A number representing the amount of kbps this publisher should target.
   *
   * @see PublisherDelegateProtocol
   */
  public constructor(publisherDelegate: PublisherDelegateProtocol, userId: UserId, bitrateConstraint: number) {
    super();
    this.delegate = publisherDelegate;
    this.userId = userId;
    this.bitrateConstraint = bitrateConstraint;
    this.localStream = new MediaStream();
    this.configureRequestName = 'configure';
    this.toggleMediaStrategy = ToggleMediaStrategy.EnabledProperty;
  }

  set toggleMediaStrategy(value) {
    this._toggleMediaStrategy = value;
  }

  get toggleMediaStrategy() {
    return this._toggleMediaStrategy;
  }

  get hasVideoTracks() {
    return this.localStream?.getVideoTracks().length > 0;
  }

  get publisherType() {
    return PublisherType.Camera;
  }

  async dispose() {
    log.debug('CameraPublisher: Dispose');
    this.onCameraUpdate = null;
    this.onUpdatedRates = null;
    try {
      await this.publisherHandle?.send({ message: { request: 'leave' } });
      this.publisherHandle?.hangup(true);
    } catch (error) {
      log.warn('CameraPublisher: Error on dispose: ', error);
    } finally {
      this.publisherHandle = null;
      this.localStream = null;
    }
  }

  /**
   * Removes a local stream based on his kind, from our local stream and Janus
   * one as well.
   *
   * @param {MediaType} kind the kind of stream (audio or video)
   */
  private removeTrackByKind = (kind: MediaType, options?: { exceptTrack: MediaStreamTrack }) => {
    this.localStream
      .getTracks()
      .filter((match) => match.kind === kind)
      .forEach((trk) => {
        trk.stop();
        this.localStream.removeTrack(trk);
      });

    const st = this.publisherHandle.webrtcStuff.myStream;
    st.getTracks()
      .filter((t) => t.kind === kind && t !== options?.exceptTrack)
      .forEach((t) => {
        t.stop();
        st.removeTrack(t);
      });
  };

  private removeTrackByTrackId = (trackId: string) => {
    this.localStream
      .getTracks()
      .filter((t) => t.id === trackId)
      .forEach((t) => {
        t.stop();
        this.localStream.removeTrack(t);
      });

    const st = this.publisherHandle.webrtcStuff.myStream;
    st.getTracks()
      .filter((t) => t.id === trackId)
      .forEach((t) => {
        t.stop();
        st.removeTrack(t);
      });
  };

  /**
   * Called when the publisher receives a change to a local A/V track
   *
   * @param {MediaStreamTrack} localTrack The track we're being notified about
   * @param {boolean} on True if the track was added, false if removed
   */
  onLocalTrack(localTrack: MediaStreamTrack, on: boolean) {
    log.debug(`CameraPublisher: Local ${localTrack.kind} track ${on ? 'added' : 'removed'} with id ${localTrack.id}`);
    trackMetric(`SFU Publisher Local ${localTrack.kind} track ${on ? 'added' : 'removed'}`, {
      muted: localTrack.muted,
      enabled: localTrack.enabled,
    });
    if (!localTrack) {
      log.warn('CameraPublisher: onlocaltrack callback with null track');
      return;
    }

    if (!on) {
      const localMid = localTrack?.kind === 'audio' ? this.audioMid : this.videoMid;
      try {
        // localTrack.enabled = false;
        // this.localStream.removeTrack(localTrack);
      } catch (e) {
        log.error(
          `CameraPublisher: Something went wrong removing ${localTrack.kind} local track: ${localTrack.id} localMid: ${localMid}`
        );
      }
      return;
    }

    if (this.localStream.getTrackById(localTrack.id)) {
      log.warn(`CameraPublisher: Already have track with id ${localTrack.id}, dont care...`);
      return;
    }

    this.removeTrackByKind(localTrack.kind as MediaType, { exceptTrack: localTrack });
    this.localStream.addTrack(localTrack);

    log.debug(
      'CameraPublisher: Created/updated local stream with tracks:\n',
      this.localStream
        .getTracks()
        .map((t) => `\t${t.kind} ${t.label} ${t.id}`)
        .join('\n')
    );

    this.delegate.publisherStreamReadyForHTML(this.localStream, this.userId, PublisherStreamSource.Camera);
  }

  setOnCameraUpdate(func: OnCameraUpdateFnPrototype): void {
    this.onCameraUpdate = func;
  }

  async startCamera(options: { isAudioOn: boolean; isVideoOn: boolean }): Promise<void> {
    return this.publishOwnFeed(options.isAudioOn, options.isVideoOn, true, 0);
  }

  // FIXME: take a look where and how its being used. Worth to change to `unsubscribe`?
  stopCamera() {
    return new Promise((resolve) => {
      this.onUnpublish = resolve;
      this.publisherHandle.send({
        message: { request: 'unpublish' },
      });
    });
  }

  restoreICE(forceIceRestart = false) {
    this.publishOwnFeed(this.isAudioOn, this.isVideoOn, true, 0, true, forceIceRestart);
  }

  async onJSEP(jsep: JanusJS.JSEP, msg: JanusJS.Message) {
    log.debug('CameraPublisher: Handling SDP as well, onmessage...');
    try {
      await (this.publisherHandle as VideoroomWrapper).handleRemoteJsep({ jsep });
    } catch (error) {
      this.isConfiguring = false;
      log.error('Room: Publisher JSEP Error:', error);
      trackMetric('SFU Publisher onJSEP Error', { name: error?.name, message: error?.message });
    }

    // Check if any of the media we wanted to publish has
    // been rejected (e.g., wrong or unsupported codec)
    if (this.localStream) {
      const audioTracks = this.localStream.getAudioTracks();
      if (this.isAudioOn && audioTracks && audioTracks.length > 0 && !msg.audio_codec) {
        // Audio has been rejected
        log.warn(
          `CameraPublisher: Our audio stream has been rejected, viewers won't hear us, msg: ${JSON.stringify(msg)}`
        );
        trackMetric('SFU Publisher Audio Stream Rejected', { audioCodec: msg.audio_codec });
      }

      const videoTracks = this.localStream.getVideoTracks();
      if (this.isVideoOn && videoTracks && videoTracks.length > 0 && !msg.video_codec) {
        // Video has been rejected
        log.warn(
          `CameraPublisher: Our video stream has been rejected, viewers won't see us, msg: ${JSON.stringify(msg)}`
        );
        // TODO Hide the webcam video
        trackMetric('SFU Publisher Video Stream Rejected', { videoCodec: msg.video_codec });
      }
    }
  }

  // FIXME:
  // We probably don't want to notify presence through Firebase?
  // At this point we have data channel established, may be use both with a signature token
  // in case one of them does not arrive?
  updateAndNotifyCameraStatus() {
    this.isAudioOn = this.getLocalAudio() ? !this.publisherHandle.isAudioMuted() : false;
    this.isVideoOn = this.getLocalVideo() ? !this.publisherHandle.isVideoMuted() : false;
    if (this.onCameraUpdate) {
      this.onCameraUpdate({
        isAudioOn: this.isAudioOn,
        isVideoOn: this.isVideoOn,
      });
    }
    if (!this.isVideoOn) {
      this.resetRates();
    }
  }

  async unmuteLocal(type: MediaType) {
    log.debug(
      `CameraPublisher: Attempt to unmute local ${type}, ICE: ${this.iceState} PC conn state: ${this.publisherHandle?.webrtcStuff?.pc?.connectionState}`
    );

    trackMetric(`SFU Publisher Unmute ${type}`);

    if (type === MediaType.Audio) await this.enableAudio();
    if (type === MediaType.Video) await this.enableVideo();

    this.updateAndNotifyCameraStatus();
  }

  async muteLocal(type: MediaType) {
    log.debug(
      `CameraPublisher: Attempt to mute local ${type}, ICE: ${this.iceState} PC conn state: ${this.publisherHandle?.webrtcStuff?.pc?.connectionState}`
    );

    trackMetric(`SFU Publisher Mute ${type}`);

    if (type === MediaType.Audio) await this.disableAudio();
    if (type === MediaType.Video) await this.disableVideo();

    this.updateAndNotifyCameraStatus();
  }

  private shouldDeferConfigure(offerTrack: TrackOption, attempts = 0): DeferStatus {
    const pc = this.publisherHandle?.webrtcStuff?.pc;

    const deferStatus: DeferStatus = { command: DeferCommand.Continue };

    if (!pc) {
      deferStatus.reason = DeferReason.NotPeerConnection;
    }

    if (this.isConfiguring) {
      deferStatus.reason = DeferReason.Configuring;
    }

    if (this.status !== ConnectionStatus.Connected || pc?.connectionState !== 'connected') {
      deferStatus.reason = DeferReason.NotConnected;
    }

    if (this.peerConnection?.signalingState !== 'stable') {
      deferStatus.reason = DeferReason.SignalNotStable;
    }

    if (
      pc?.signalingState === undefined &&
      pc?.connectionState === undefined &&
      this.status === ConnectionStatus.Connected &&
      attempts > 5
    ) {
      deferStatus.reason = DeferReason.GiveUp;
      deferStatus.command = DeferCommand.Abort;
      this.localStream?.getTracks().forEach((t) => {
        if (t.kind === offerTrack.type) this.localStream.removeTrack(t);
      });
      trackMetric('SFU Camera Publisher Defer Give Up');
      log.warn('CameraPublisher: reconfigureMedia will Give Up');
      return deferStatus;
    }

    if (deferStatus.reason) {
      deferStatus.command = pc?.connectionState === 'failed' ? DeferCommand.Republish : DeferCommand.Defer;
      deferStatus.attempts = attempts + 1;
      log.warn(
        `CameraPublisher: reconfigureMedia will ${deferStatus.command.toUpperCase()} ${
          deferStatus.reason.toUpperCase() || ''
        }, attempts: ${attempts + 1}, signalingState: ${pc?.signalingState}, status: ${this.status}, pc status: ${
          pc?.connectionState
        }, offer track: ${JSON.stringify(offerTrack)}`
      );
      return deferStatus;
    }

    log.debug(`CameraPublisher: ${JSON.stringify(offerTrack)} operation will not be defered`);
    return deferStatus;
  }

  muteLocalAudio = async () => {
    await this.muteLocal(MediaType.Audio);
  };

  muteLocalVideo = async () => {
    await this.muteLocal(MediaType.Video);
  };

  unmuteLocalAudio = async () => {
    try {
      await this.unmuteLocal(MediaType.Audio);
    } catch (err) {
      this.filterAndShowDeviceError(err, ['audio']);
    }
  };

  unmuteLocalVideo = async () => {
    try {
      await this.unmuteLocal(MediaType.Video);
    } catch (err) {
      this.filterAndShowDeviceError(err, ['video']);
    }
  };

  async changeAudioDevice(trackConstraints: MediaTrackConstraints, reconfigure = true) {
    await this.changeDevice(MediaType.Audio, trackConstraints, reconfigure);
  }

  async changeVideoDevice(trackConstraints: MediaTrackConstraints, reconfigure = true) {
    await this.changeDevice(MediaType.Video, trackConstraints, reconfigure);
  }

  async restartVideoDevice() {
    await this.changeDevice(MediaType.Video, this.videoDeviceConstraints, true);
  }

  private async changeDevice(media: MediaType, trackConstraints: MediaTrackConstraints, reconfigure = true) {
    let track: MediaStreamTrack;
    const currentTrack = media === MediaType.Audio ? this.getLocalAudio() : this.getLocalVideo();
    const currentTrackSettings = currentTrack ? currentTrack.getSettings() : {};
    const currentTrackConstraints = currentTrack ? currentTrack.getConstraints() : {};

    if (media === MediaType.Audio) {
      const currentAudioTrackProvider = getCurrentAudioTrackProvider();
      if (
        currentAudioTrackProvider.type === this.audioTrackProvider.type &&
        isEqual(this.audioDeviceConstraints, trackConstraints)
      )
        return;
      this.audioDeviceConstraints = trackConstraints;
      track = await currentAudioTrackProvider.getTrack(this.audioDeviceConstraints);
      this.audioTrackProvider = currentAudioTrackProvider;
    } else if (media === MediaType.Video) {
      const currentVideoTrackProvider = getCurrentVideoTrackProvider();
      if (
        currentVideoTrackProvider.type === this.videoTrackProvider.type &&
        isEqual(this.videoDeviceConstraints, trackConstraints)
      )
        return;
      this.videoDeviceConstraints = trackConstraints;
      track = await currentVideoTrackProvider.getTrack(this.videoDeviceConstraints);
      this.videoTrackProvider = currentVideoTrackProvider;
    }

    if (!reconfigure) {
      log.log(
        `CameraPublisher: Will skip change ${media} device, but set constraints to ${JSON.stringify(trackConstraints)}`
      );
      return;
    }

    log.debug(
      `CameraPublisher: Changing ${media} track
        %cconstraints:%c ${JSON.stringify(currentTrackConstraints)}
        %csettings:%c ${JSON.stringify(currentTrackSettings)},
        with
        %cconstraints:%c ${JSON.stringify(track.getConstraints())}
        %csettings:%c ${JSON.stringify(track.getSettings())}`,
      'color: red',
      'color: system',
      'color: red',
      'color: system',
      'color: red',
      'color: system',
      'color: red',
      'color: system'
    );

    if (this.status !== ConnectionStatus.Connected || this.peerConnectionStatus !== 'connected') {
      log.warn(
        `CameraPublisher: Will not change ${media} device, status: ${this.status}, PC status: ${this.peerConnectionStatus}`
      );
      return;
    }

    log.log(`CameraPublisher: Will change ${media} device with constraints ${JSON.stringify(trackConstraints)}`);

    let mid = media === MediaType.Audio ? this.audioMid : null;
    mid = media === MediaType.Video ? this.videoMid : mid;

    const newTrackSettings = currentTrack?.getSettings();

    trackMetric('SFU Publisher Change Device Attempt', {
      media,
      previousConstraints: currentTrackConstraints,
      trackConstraints,
      currentTrackSettings,
      newTrackSettings,
    });

    try {
      await this.reconfigureMedia({
        type: media,
        replace: true,
        mid,
        capture: this.sanitizeCapture(track),
      });
    } catch (e) {
      log.error(`CameraPublisher: Change Device Error ${e}`);
      trackMetric('SFU Publisher Change Device Error', {
        media,
        previousConstraints: currentTrackConstraints,
        trackConstraints,
        currentTrackSettings,
        newTrackSettings,
      });
    }

    log.debug(`CameraPublisher: Changed ${media} track, new settings: ${JSON.stringify(newTrackSettings)}`);

    trackMetric('SFU Publisher Change Device Success', {
      media,
      previousConstraints: currentTrackConstraints,
      trackConstraints,
      currentTrackSettings,
      newTrackSettings,
    });
  }

  private getLocalAudio = (): MediaStreamTrack =>
    (this.localStream?.getAudioTracks() || []).filter((t) => t.readyState === 'live')[0];

  private getLocalVideo = (): MediaStreamTrack =>
    (this.localStream?.getVideoTracks() || []).filter((t) => t.readyState === 'live')[0];

  private async enableVideo() {
    log.debug('CameraPublisher: Enable Video');

    if (this.toggleMediaStrategy === ToggleMediaStrategy.EnabledProperty && this.getLocalVideo()) {
      if (!this.publisherHandle.unmuteVideo()) {
        log.debug('CameraPublisher: Unable to unmute video');
        trackMetric('SFU Publisher Unable To Unmute Video');
      } else {
        trackMetric('SFU Publisher Enable Video', { strategy: this.toggleMediaStrategy });
        return;
      }
    }

    this.videoTrackProvider = getCurrentVideoTrackProvider();
    const track = await this.videoTrackProvider.getTrack(this.videoDeviceConstraints);
    await this.reconfigureMedia({
      type: 'video',
      add: true,
      mid: this.videoMid,
      capture: this.sanitizeCapture(track),
    });

    trackMetric('SFU Publisher Enable Video', { strategy: this.toggleMediaStrategy });
  }

  private async disableVideo() {
    log.log('CameraPublisher: disable video');

    if (this.toggleMediaStrategy === ToggleMediaStrategy.EnabledProperty && this.getLocalVideo()) {
      this.publisherHandle.muteVideo();
    } else if (this.toggleMediaStrategy === ToggleMediaStrategy.Configure) {
      await this.reconfigureMedia({ type: 'video', remove: true, mid: this.videoMid });
      this.videoTrackProvider?.suspend();
    }

    trackMetric('SFU Publisher Disable Video', { strategy: this.toggleMediaStrategy });
  }

  private async enableAudio() {
    log.debug('CameraPublisher: Enable Audio');

    if (this.toggleMediaStrategy === ToggleMediaStrategy.EnabledProperty && this.getLocalAudio()) {
      if (!this.publisherHandle.unmuteAudio()) {
        log.debug('CameraPublisher: Unable to unmute audio');
        trackMetric('SFU Publisher Unable To Unmute Audio');
      } else {
        trackMetric('SFU Publisher Enable Audio', { strategy: this.toggleMediaStrategy });
        return;
      }
    }

    this.audioTrackProvider = getCurrentAudioTrackProvider();
    const track = await this.audioTrackProvider.getTrack(this.audioDeviceConstraints);
    await this.reconfigureMedia({
      type: 'audio',
      add: true,
      mid: this.audioMid,
      capture: this.sanitizeCapture(track),
    });
    trackMetric('SFU Publisher Enable Audio', { strategy: this.toggleMediaStrategy });
  }

  private async disableAudio() {
    log.log('CameraPublisher: disable audio');

    if (this.toggleMediaStrategy === ToggleMediaStrategy.EnabledProperty && this.getLocalAudio()) {
      if (this.publisherHandle.isAudioMuted()) {
        trackMetric('SFU Muting Already Muted Track');
      }
      this.publisherHandle.muteAudio();
    } else if (this.toggleMediaStrategy === ToggleMediaStrategy.Configure) {
      await this.reconfigureMedia({ type: 'audio', remove: true, mid: this.audioMid });
      this.audioTrackProvider?.suspend();
    }
    trackMetric('SFU Publisher Disable Audio', { strategy: this.toggleMediaStrategy });
  }

  async reconfigureMedia(offerTrack: TrackOption, attempts = 0) {
    trackMetric(`SFU Publisher Reconfigure Media ${offerTrack.type} Attempt`, { offerTrack });

    if (offerTrack?.capture instanceof MediaStreamTrack) {
      offerTrack.capture.enabled = true;
    }

    this.deferStatus = this.shouldDeferConfigure(offerTrack, attempts);

    if (this.deferStatus.command === DeferCommand.Abort) {
      return;
    }

    if (this.deferStatus.command === DeferCommand.Continue) {
      this.isConfiguring = true;
      log.log('CameraPublisher: reconfigureMedia offerTrack:', JSON.stringify(offerTrack));
    } else if (
      this.deferStatus.command === DeferCommand.Republish ||
      this.deferStatus.attempts > RECONFIGURE_ATTEMPTS_LIMIT
    ) {
      this.isConfiguring = false;
      log.warn(
        `CameraPublisher: Configure Attempts Limit Reached, attempts: ${this.deferStatus.attempts}, reason: ${this.deferStatus.reason}... re-publishing!`
      );
      trackMetric('SFU Publisher Configure Attempts Limit Reached', { attempts, reason: this.deferStatus.reason });
      const audioOffer = offerTrack.type === MediaType.Audio && offerTrack.add;
      const videoOffer = offerTrack.type === MediaType.Video && offerTrack.add;
      delay(this.publishOwnFeed.bind(this), 2000, this.isAudioOn || audioOffer, this.isVideoOn || videoOffer, true, 0);
      return;
    } else {
      delay(this.reconfigureMedia.bind(this), 1000, offerTrack, this.deferStatus.attempts);
      return;
    }

    try {
      const jsep = await this.publisherHandle.createOffer({ tracks: [offerTrack] });
      if (offerTrack.remove) {
        await this.publisherHandle.send({ message: { request: this.configureRequestName, iceRestart: true }, jsep });
      } else {
        await this.publisherHandle.send({ message: { request: this.configureRequestName }, jsep });
      }
    } catch (error) {
      this.isConfiguring = false;
      log.error(`CameraPublisher: reconfigureMedia WebRTC error: ${error}`);
      trackMetric(`SFU Publisher Reconfigure Media ${offerTrack.type} Failed`, {
        name: error.name,
        message: error,
        remove: offerTrack.remove,
      });
      this.filterAndShowDeviceError(error, [offerTrack.type]);
    }
  }

  onConfigureFailed() {
    this.isConfiguring = false;
    this.shouldMuteAfterConfigure = false;
  }

  onConfigureSuccess(configuredTracks: ConfiguredTrack[]) {
    if (!configuredTracks) {
      log.warn('CameraPublisher: Received configured without tracks');
      configuredTracks = [];
    }

    const disabledTracks = configuredTracks.filter((t) => t.disabled);
    const enabledTracks = configuredTracks.filter((t) => !t.disabled);

    const enabledVideoTracksLength = enabledTracks.filter((t) => t.type === 'video').length;
    if (enabledVideoTracksLength > 1) {
      trackMetric('SFU Publisher Multiple Configured Video Tracks', { quantity: enabledVideoTracksLength });
    }

    [disabledTracks, enabledTracks].forEach((tracks) => {
      tracks.forEach((conf) => {
        const localAudio = this.getLocalAudio();
        if (conf.type === MediaType.Audio && this.audioMid === conf.mid) {
          if (conf.disabled) this.isAudioOn = false;
          if (!conf.disabled && localAudio?.enabled) this.isAudioOn = true;
          if (!conf.disabled && !localAudio)
            trackMetric('SFU Publisher Configure Missing Local Track', { type: MediaType.Audio });
        } else if (conf.type === MediaType.Audio && !conf.disabled) {
          this.audioMid = conf.mid;
          if (this.shouldMuteAfterConfigure) {
            this.shouldMuteAfterConfigure = false;
            if (localAudio) {
              localAudio.enabled = false;
            }
            this.isAudioOn = false;
          } else if (localAudio && localAudio.enabled) {
            this.isAudioOn = true;
          } else if (!localAudio || !localAudio.enabled) {
            this.isAudioOn = false;
          }
        }

        if (conf.type === MediaType.Video && this.videoMid === conf.mid) {
          if (conf.disabled) this.isVideoOn = false;
          if (!conf.disabled && this.getLocalVideo()?.enabled) this.isVideoOn = true;
          if (!conf.disabled && !this.getLocalVideo())
            trackMetric('SFU Publisher Configure Missing Local Track', { type: MediaType.Video });
        } else if (conf.type === MediaType.Video && !conf.disabled) {
          this.videoMid = conf.mid;
          this.isVideoOn = true;
        }

        if (conf.type === 'data') {
          this.dataMid = conf.mid;
        }

        if (conf.disabled) {
          if (conf.type !== 'data') {
            this.removeTrackByTrackId(`janus${conf.mid}`);
          }
        }
      });
    });

    this.onCameraUpdate({
      isAudioOn: this.isAudioOn,
      isVideoOn: this.isVideoOn,
    });

    this.isConfiguring = false;
  }

  onMediaState(medium: MediaType, on: boolean, mid: number) {
    super.onMediaState(medium, on, mid);
    /*
    if (medium === MediaType.Audio) this.isAudioOn = on;
    if (medium === MediaType.Video) this.isVideoOn = on;
    this.onCameraUpdate({ isAudioOn: this.isAudioOn, isVideoOn: this.isVideoOn });
    */
  }

  setTargetBitrate(target: number) {
    if (!this.publisherHandle) {
      log.warn('PublisherBase: Cannot set Target Bitrate without a publisher handler');
    }

    if (this.peerConnectionStatus !== 'connected') {
      log.warn('PublisherBase: Cannot set Target Bitrate without a connected Peer Connection');
    }

    this.publisherHandle.send({ message: { request: 'configure', bitrate: target } });
  }

  customizeSdp(jsep: { sdp: string; type: 'offer' | 'answer' }) {
    if (jsep.type === 'offer' && jsep.sdp.match(/useinbandfec=1/)) {
      // jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1; stereo=0; maxaveragebitrate=48000');
    }
  }

  async sendPublishRequest(offer: OfferParams) {
    this.isConfiguring = true;
    const jsep = await this.publisherHandle.createOffer(offer, { customizeSdp: this.customizeSdp });
    const caps = await Devices.getInstance().testCapabilities();

    let audioState = this.isAudioOn;
    if (this.isAudioOn) {
      this.shouldMuteAfterConfigure = false;
    } else if (this.shouldMuteAfterConfigure && !this.isAudioOn && caps.isAudioCapable) {
      audioState = true;
      this.isAudioOn = false;
    }

    const publish: PublishParams = {
      request: 'configure',
      bitrate: this.bitrateConstraint,
      data: true,
      audio: audioState,
      video: this.isVideoOn,
    };

    if (this.isVideoOn) {
      if (jsep.sdp.match(/h264/i)) {
        publish.videocodec = 'h264';
      } else if (!publish.videocodec && jsep.sdp.match(/vp8/i)) {
        publish.videocodec = 'vp8';
      }
    }

    log.debug(
      `${this.publisherType}: Send Publish Request Offer: ${JSON.stringify(offer, null, 2)}`
      /*
      `payload: ${JSON.stringify(publish, null, 2)}`,
      `JSEP: ${JSON.stringify(jsep, null, 2)}\n`
      */
    );

    let tracksForMetric;
    try {
      tracksForMetric = offer.tracks?.reduce((a: { [key: string]: boolean }, t) => {
        a[t.type] = t.add;
        return a;
      }, {});
    } catch (e) {
      log.error(`${this.publisherType}: Has been an error tracking publish offer metric!`);
    } finally {
      trackMetric('Send Publish Offer', Object.assign(tracksForMetric || {}, { publisherType: this.publisherType }));
    }

    await this.publisherHandle.send({ message: publish, jsep });
  }
}
