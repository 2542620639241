export default class BoardElement {
  get domElement() {
    return document.getElementById(`element-${this.elementId}`);
  }

  constructor(elementId) {
    this.elementId = elementId;
  }

  setPinnedElements(pinnedElements = []) {
    this.pinnedElements = pinnedElements;
  }
}
