import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import LottieAnimation from '../../../../animations/LottieAnimation';
import { getWidgetGif } from '../../../../../util/room-streak-util';

import ServerDate from '../../../../../util/server-date';

const EXPIRING_SOON_IN_MS = 1000 * 60 * 60 * 4; // 4 hours

const RoomStreak = ({ streak, streakExpiresAt }) => {
  const [streakExpiringSoon, setStreakExpiringSoon] = useState(false);
  const gif = getWidgetGif(streak);

  useEffect(() => {
    if (streakExpiresAt) {
      (async () => {
        const now = await ServerDate.getTime();
        const expiresAt = new Date(streakExpiresAt);

        if (expiresAt && expiresAt - now <= EXPIRING_SOON_IN_MS) {
          setStreakExpiringSoon(true);
        }
      })();
    }
  }, [streakExpiresAt]);

  return (
    <TooltipProvider>
      <TooltipRoot>
        <TooltipTrigger asChild>
          <RoomStreakAndExpiryContainer>
            <RoomStreakContainer>
              <AnimationContainer>
                <LottieAnimation animationData={gif} loop />
              </AnimationContainer>
              <RoomStreakNumber>{streak}</RoomStreakNumber>
            </RoomStreakContainer>
            {streakExpiringSoon && <HourglassContainer>⏳</HourglassContainer>}
          </RoomStreakAndExpiryContainer>
        </TooltipTrigger>
        <TooltipStyledContent side="top" sideOffset={5}>
          Someone has been here <br />
          {`for ${streak} days in a row`}
        </TooltipStyledContent>
      </TooltipRoot>
    </TooltipProvider>
  );
};

export default RoomStreak;

RoomStreak.propTypes = {
  streak: PropTypes.number,
  streakExpiresAt: PropTypes.string,
};

RoomStreak.defaultProps = {
  streak: 0,
  streakExpiresAt: null,
};

const TooltipProvider = TooltipPrimitive.Provider;
const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipStyledContent = styled(TooltipPrimitive.Content)`
  padding: 5px 15px;
  min-width: 100px;
  max-width: 250px;
  border-radius: 8px;
  background-color: #ffffff;

  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;

const AnimationContainer = styled.div`
  height: 65px;
  position: absolute;
  margin-top: -45px;
`;

export const RoomStreakAndExpiryContainer = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const RoomStreakContainer = styled.div`
  position: relative;
  width: 40px;

  margin-right: -10px;
`;

const RoomStreakNumber = styled.div`
  position: absolute;
  width: 100%;
  font-weight: bold;
  text-align: center;
  left: -3px;
  top: -6px;
  font-size: 13px;

  color: #12002d;
`;

const HourglassContainer = styled.div`
  margin-left: 8px;
`;
