import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ConditionalWrapper } from './ConditionalWrapper';
import UserAvatar from './UserAvatar';
import OfflineCircle from './presence-indicators/OfflineCircle';
import OnlineCircle from './presence-indicators/OnlineCircle';
import ChatTooltip from '../chats/ChatIconTooltip';
import UserMoodTooltip from '../sidebar/UserMoodTooltip.tsx';

const UserAvatarWithStatus = ({
  userProfile,
  status,
  statusBorderColor,
  showNameTooltip,
  showOnlineStatusTooltip,
  isHereOfficialAccount,
  customAvatarBorderColor,
  size,
  isLoading,
  borderWidth,
  borderRadius,
}) => (
  <ConditionalWrapper
    condition={showNameTooltip}
    wrapper={(children) =>
      userProfile?.mood ? (
        <UserMoodTooltip title={userProfile?.displayName} mood={userProfile?.mood}>
          {children}
        </UserMoodTooltip>
      ) : (
        <ChatTooltip title={userProfile?.displayName} subtitle={`@${userProfile?.username}`}>
          {children}
        </ChatTooltip>
      )
    }
  >
    <AvatarWithStatusContainer size={size}>
      <AvatarContainer size={size}>
        <UserAvatar
          userProfile={userProfile}
          isHereOfficialAccount={isHereOfficialAccount}
          customBorderColor={customAvatarBorderColor}
          isLoading={isLoading}
          borderWidth={borderWidth}
          borderRadius={borderRadius}
        />
      </AvatarContainer>
      {status === 'online' && !isHereOfficialAccount && (
        <StatusContainer avatarBorderWidth={borderWidth}>
          <OnlineCircle borderColor={statusBorderColor} showOnlineStatusTooltip={showOnlineStatusTooltip} />
        </StatusContainer>
      )}
      {status === 'offline' && !isHereOfficialAccount && (
        <StatusContainer avatarBorderWidth={borderWidth}>
          <OfflineCircle
            borderColor={statusBorderColor}
            backgroundColor={statusBorderColor}
            showOnlineStatusTooltip={showOnlineStatusTooltip}
          />
        </StatusContainer>
      )}
    </AvatarWithStatusContainer>
  </ConditionalWrapper>
);

export default UserAvatarWithStatus;

UserAvatarWithStatus.propTypes = {
  userProfile: PropTypes.shape({
    displayName: PropTypes.string,
    avatar: PropTypes.shape({
      thumbnail: PropTypes.string,
      original: PropTypes.string,
    }),
    username: PropTypes.string,
    mood: PropTypes.shape({
      text: PropTypes.string,
      theme: PropTypes.shape({
        colors: PropTypes.shape({
          primaryForeground: PropTypes.string,
          primaryBackground: PropTypes.string,
        }),
        primaryFont: PropTypes.string,
      }),
    }),
  }),
  status: PropTypes.string,
  statusBorderColor: PropTypes.string,
  showNameTooltip: PropTypes.bool,
  showOnlineStatusTooltip: PropTypes.bool,
  isHereOfficialAccount: PropTypes.bool,
  customAvatarBorderColor: PropTypes.string,
  size: PropTypes.number,
  isLoading: PropTypes.bool,
  borderRadius: PropTypes.string,
  borderWidth: PropTypes.number,
};

UserAvatarWithStatus.defaultProps = {
  userProfile: null,
  status: '',
  statusBorderColor: '#ffffff',
  showNameTooltip: false,
  showOnlineStatusTooltip: false,
  isHereOfficialAccount: false,
  customAvatarBorderColor: null,
  size: 42,
  isLoading: false,
  borderRadius: '50%',
  borderWidth: 0,
};

const AvatarWithStatusContainer = styled.div`
  position: relative;
  height: ${({ size }) => (size === null ? '100%' : `${size}px`)};
  width: ${({ size }) => (size === null ? '100%' : `${size}px`)};
`;

export const AvatarContainer = styled.div`
  width: ${({ size }) => (size === null ? '100%' : `${size}px`)};
  height: ${({ size }) => (size === null ? '100%' : `${size}px`)};
`;

export const StatusContainer = styled.div`
  position: absolute;
  right: ${({ theme, avatarBorderWidth }) =>
    (theme?.statusBorderWidth ? -theme.statusBorderWidth : 0) + avatarBorderWidth}px;
  bottom: ${({ theme, avatarBorderWidth }) =>
    (theme?.statusBorderWidth ? -theme.statusBorderWidth : 0) + avatarBorderWidth}px;
  height: ${({ theme }) => theme?.statusSize || 14}px;
  width: ${({ theme }) => theme?.statusSize || 14}px;
  box-sizing: border-box;
`;
