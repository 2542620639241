import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { track } from '../../util/analytics-util';
import { nop } from '../../util/js-util';

import FadeIn from '../components/animate/FadeIn';
import { moreMenuItemMixin, moreMenuMixin } from '../mixins';

import { approveFriendRequest, ignoreFriendRequest } from '../store/friends/api';

import { CheckIcon } from '../icons';
import VerticalMoreIcon from '../../../assets/icons/vertical-more.svg';
import { fetchFriends } from '../store/friends/store';
import { ACCEPT_FRIEND, FRIEND_REQUEST_SOURCES, IGNORE_FRIEND } from '../../constants/analytics-events/friend-events';
import { EventSourceContext } from '../common/contexts.ts';

const FriendRequestButtons = ({ requesterId, onAccept, onIgnore, onDismissAlert, onError }) => {
  const dispatch = useDispatch();
  const [uiConfirmation, setUIConfirmation] = useState('');
  const source = useContext(EventSourceContext);

  const onAcceptClick = async () => {
    onAccept();
    setUIConfirmation('accepted');

    // TODO: The api call shouldn't be in the component
    const response = await approveFriendRequest(requesterId);

    if (response && response.success) {
      // TODO: Handle approve request in saga and put new friend to store instead
      // of redundantly call api again.
      dispatch(fetchFriends());
      track(ACCEPT_FRIEND, { source, requesterId });
    } else {
      setUIConfirmation('');
      onError();
    }
  };

  const onIgnoreClick = async () => {
    onIgnore();
    setUIConfirmation('ignored');

    const response = await ignoreFriendRequest(requesterId);

    if (response && response.success) {
      track(IGNORE_FRIEND, { source });
    } else {
      setUIConfirmation('');
      onError();
    }
  };

  return (
    <ButtonsContainer>
      {uiConfirmation === 'accepted' && (
        <FadeIn>
          <AcceptedConfirmation>
            <CheckIcon />
            <span style={{ marginLeft: '10px' }}>Accepted</span>
          </AcceptedConfirmation>
        </FadeIn>
      )}
      {uiConfirmation === 'ignored' && (
        <FadeIn>
          <IgnoredConfirmation>Ignored</IgnoredConfirmation>
        </FadeIn>
      )}
      {!uiConfirmation && (
        <>
          <AcceptButton onClick={onAcceptClick}>Accept</AcceptButton>
          <IgnoreButton onClick={onIgnoreClick}>Ignore</IgnoreButton>
          <MobileMoreButton>
            <VerticalMoreIcon />
          </MobileMoreButton>
          <div data-uk-dropdown="mode: click; pos: bottom-right" style={{ padding: '0px' }}>
            <MobileMoreMenuContainer>
              <MobileMoreMenuItem onClick={onAcceptClick}>Accept</MobileMoreMenuItem>
              <MobileMoreMenuItem onClick={onIgnoreClick}>Ignore</MobileMoreMenuItem>
              {source === FRIEND_REQUEST_SOURCES.FRIEND_REQUEST_ALERT && (
                <MobileMoreMenuItem onClick={onDismissAlert}>Dismiss</MobileMoreMenuItem>
              )}
            </MobileMoreMenuContainer>
          </div>
        </>
      )}
    </ButtonsContainer>
  );
};

export default FriendRequestButtons;

FriendRequestButtons.propTypes = {
  requesterId: PropTypes.string.isRequired,
  onAccept: PropTypes.func,
  onIgnore: PropTypes.func,
  onDismissAlert: PropTypes.func,
  onError: PropTypes.func,
};

FriendRequestButtons.defaultProps = {
  onAccept: nop,
  onIgnore: nop,
  onDismissAlert: nop,
  onError: nop,
};

const ButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 615px) {
    padding-right: 0;
  }
`;

const Button = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme?.buttonsFontWeight || 'normal'};
  line-height: 24px;
  padding-top: 11px;
  padding-bottom: 11px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AcceptButton = styled(Button)`
  width: 76px;
  background: ${({ theme }) => theme?.buttonsBackground || '#6b1be3'};
  color: ${({ theme }) => theme?.buttonsFontColor || 'white'};
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

const IgnoreButton = styled(Button)`
  width: 65px;
  background: ${({ theme }) => theme?.ghostButtonBackground || 'rgba(0, 0, 0, 0.05)'};
  color: ${({ theme }) => theme?.ghostButtonFontColor || '#12002d'};
  opacity: 0.5;

  margin-left: 8px;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 615px) {
    display: none;
  }
`;

const MobileMoreButton = styled.div`
  display: none;

  @media (max-width: 615px) {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 13px 18px;
    cursor: pointer;
  }
`;

const AcceptedConfirmation = styled(Button)`
  cursor: auto;
  background: ${({ theme }) => theme?.buttonsBackground || '#6b1be3'};
  color: ${({ theme }) => theme?.buttonsFontColor || 'white'};
  width: 147px;
`;

const IgnoredConfirmation = styled(Button)`
  cursor: auto;
  width: 147px;
  background: #e6e6e6;
`;

const MobileMoreMenuContainer = styled.div`
  ${moreMenuMixin()}
`;

const MobileMoreMenuItem = styled.div`
  ${moreMenuItemMixin({ themed: false })}
`;
