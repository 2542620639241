/* eslint-disable no-underscore-dangle */
import $ from 'jquery';

import Janus from '../lib/janus';
import { htmlToElement } from '../util';
import log from '../log';
import { getCameraDeviceId, getMicDeviceId, setCameraDeviceId, setMicDeviceId } from '../util/device-util';
import { ConnectionStatus } from './definitions/index.definitions';
import { showDeviceError } from '../util/device-error-util';
import { JanusPluginWrapperType } from './janus/videoroom';
import { track } from '../util/analytics-util';

export interface PublisherDeviceProtocol {
  status: ConnectionStatus;
  publisherHandle: JanusPluginWrapperType;
  changeAudioDevice(trackConstraints: MediaTrackConstraints, reconfigure?: boolean): Promise<undefined>;
  changeVideoDevice(trackConstraints: MediaTrackConstraints, reconfigure?: boolean): Promise<undefined>;
}
export interface Capability {
  isAudioCapable: boolean;
  audioNotCapableError: Error;
  isVideoCapable: boolean;
  videoNotCapableError: Error;
}

export class Devices {
  private static instance: Devices;

  audioDeviceId: string;

  videoDeviceId: string;

  audioConstraints: MediaTrackConstraints;

  videoConstraints: MediaTrackConstraints;

  audioDeviceLabel: string;

  videoDeviceLabel: string;

  isAudioCapable: null | boolean = null;

  audioNotCapableError: Error;

  isVideoCapable: null | boolean = null;

  videoNotCapableError: Error;

  // FIXME: Define a Protocol for Devices and access through a Delegate instead.
  publisher: PublisherDeviceProtocol;

  public static getInstance(): Devices {
    if (!Devices.instance) {
      Devices.instance = new Devices();
    }
    return Devices.instance;
  }

  get publisherHandle() {
    return this.publisher.publisherHandle;
  }

  // Helper method to prepare a UI selection of the available devices
  // FIXME: we shouldn't be added properties to MediaDeviceInfo
  private populateDeviceMenu(devices: (MediaDeviceInfo & { selected: boolean })[]) {
    $('#audio-input, #video-input, #audio-output, #preview-audio-input, #preview-video-input').empty();
    let hasVideoDevices = false;

    const audioSelect = $('#audio-input');
    const previewAudioSelect = $('#preview-audio-input');
    const videoSelect = $('#video-input');
    const previewVideoSelect = $('#preview-video-input');

    devices.forEach((device) => {
      let { label } = device;
      if (label === null || label === undefined || label === '') {
        label = device.deviceId;
      }
      const option = htmlToElement(
        `<option value="${device.deviceId}" ${device.selected ? 'selected' : ''}>${label}</option>`
      );
      if (device.kind === 'audioinput') {
        if (audioSelect) audioSelect.append(option.cloneNode(true) as HTMLElement);
        if (previewAudioSelect) previewAudioSelect.append(option.cloneNode(true) as HTMLElement);
      } else if (device.kind === 'videoinput') {
        if (videoSelect) videoSelect.append(option.cloneNode(true) as HTMLElement);
        if (previewVideoSelect) previewVideoSelect.append(option.cloneNode(true) as HTMLElement);
        hasVideoDevices = true;
      } else if (device.kind === 'audiooutput') {
        // Definitely missing in Safari at the moment: https://bugs.webkit.org/show_bug.cgi?id=179415
        $('#audio-output').append(option as HTMLElement);
      }
    });

    if (hasVideoDevices) {
      document.getElementById('video-input-container').style.display = 'block';
      (document.getElementsByClassName('preview-video')[0] as HTMLElement).style.display = 'block';
    } else {
      document.getElementById('video-input-container').style.display = 'none';
      (document.getElementsByClassName('preview-video')[0] as HTMLElement).style.display = 'none';
    }
  }

  updateAudioOutput() {
    interface HTMLMediaElementWithSink {
      setSinkId: (deviceId: string) => Promise<undefined>;
    }
    const outputDevice = $('#audio-output').val();
    log.log(`Devices: Trying to set device ${outputDevice} as sink for the output`);
    $('video').each((_idx: number, el: HTMLElement) => {
      const fn = async () => {
        try {
          await (el as unknown as HTMLMediaElementWithSink).setSinkId(outputDevice.toString());
        } catch (ex) {
          log.warn('Devices: Unable to set audio output device for video', ex);
        }
      };
      fn();
    });
  }

  async restartCapture() {
    if (!this.publisher || this.publisher.status !== ConnectionStatus.Connected) {
      log.error(`Devices: Cannot restartCapture without a connected publisher, status: ${this.publisher?.status}`);
      return;
    }

    const audioInput = document.getElementById('audio-input') as HTMLSelectElement;
    if (audioInput.options[audioInput.selectedIndex]) {
      this.audioDeviceId = audioInput.value;
      this.audioDeviceLabel = audioInput.options[audioInput.selectedIndex].text;
      setMicDeviceId(this.audioDeviceId);
      if (window.rtc.isAudioOn) {
        const constraints: MediaTrackConstraints = this.audioConstraints || {};
        constraints.deviceId = { exact: this.audioDeviceId };
        await this.publisher.changeAudioDevice(constraints);
      }
    }

    const videoInput = document.getElementById('video-input') as HTMLSelectElement;
    if (videoInput.options[videoInput.selectedIndex]) {
      this.videoDeviceId = videoInput.value;
      this.videoDeviceLabel = videoInput.options[videoInput.selectedIndex].text;
      setCameraDeviceId(this.videoDeviceId);
      if (window.rtc.isVideoOn) {
        const constraints: MediaTrackConstraints = this.videoConstraints || {};
        constraints.deviceId = { exact: this.videoDeviceId };
        await this.publisher.changeVideoDevice(constraints);
      }
    }
    window.onAudioDeviceChanged();
  }

  async getDeviceList() {
    if (!Janus.isGetUserMediaAvailable()) {
      log.warn('Devices: navigator.mediaDevices unavailable');
      return [];
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    const deviceList = [...devices];

    deviceList.forEach((device: MediaDeviceInfo & { selected: boolean }) => {
      if (device.deviceId === this.audioDeviceId || device.deviceId === this.videoDeviceId) {
        device.selected = true;
      }
    });

    return deviceList;
  }

  async updateDevices() {
    try {
      const devices = await this.getDeviceList();
      this.populateDeviceMenu(devices as (MediaDeviceInfo & { selected: boolean })[]);
    } catch (error) {
      log.error('Device error: ', error);
      showDeviceError(error);
      this.populateDeviceMenu([]);
    }
  }

  async checkDeviceChanges() {
    this.isAudioCapable = null;
    this.isVideoCapable = null;

    if (!this.audioDeviceId && !this.videoDeviceId) {
      // Not set up yet.
      return false;
    }
    let devices: MediaDeviceInfo[];
    try {
      devices = await this.getDeviceList();
    } catch (error) {
      log.error('Error fetching new devices', error);
      devices = [];
    }

    this.populateDeviceMenu(devices as (MediaDeviceInfo & { selected: boolean })[]);

    log.debug('Devices: Populate Devices');
    let restartCapture = false;
    if (this.audioDeviceId) {
      log.debug('Current audio: ', this.audioDeviceId, this.audioDeviceLabel);
      const selectedAudio = devices.find((d) => d.deviceId === this.audioDeviceId);
      if (!selectedAudio) {
        log.warn('Lost our audio device. Finding a new one...');
        const audioInDevices = devices.filter((d) => d.kind === 'audioinput');
        if (audioInDevices && audioInDevices.length > 0) {
          (document.getElementById('audio-input') as HTMLInputElement).value = audioInDevices[0].deviceId;
          this.audioDeviceId = audioInDevices[0].deviceId;
          log.debug(`Devices: Audio Device Automatically Changed: ${this.audioDeviceId}`);
          if (window.rtc?.isAudioOn) restartCapture = true;
          track('Device Changed', { found: true, restartCapture, type: 'audio' });
        } else {
          log.warn('Devices: Audio Device Not Changed, no audio devices found :-/');
          this.audioDeviceId = null;
          track('Device Changed', { found: false, restartCapture: false, type: 'audio' });
        }
      } else if (window.rtc.isAudioOn) {
        restartCapture = true;
        track('Device Changed', { found: false, restartCapture, type: 'audio' });
      }
    }

    if (this.videoDeviceId) {
      const selectedVideo = devices.find((d) => d.deviceId === this.videoDeviceId);
      if (!selectedVideo) {
        log.warn('Lost our video device. Finding a new one...');
        const videoInDevices = devices.filter((d) => d.kind === 'videoinput');
        if (videoInDevices && videoInDevices.length > 0) {
          (document.getElementById('video-input') as HTMLInputElement).value = videoInDevices[0].deviceId;
          this.videoDeviceId = videoInDevices[0].deviceId;
          if (window.rtc?.isVideoOn) restartCapture = true;
          track('Device Changed', { found: true, restartCapture, type: 'video' });
        } else {
          log.warn('Devices: Video Device Not Changed, no video devices found :-/');
          this.videoDeviceId = null;
          track('Device Changed', { found: false, restartCapture: false, type: 'video' });
        }
      }
    }

    return restartCapture;
  }

  async monitorDeviceChanges() {
    this.audioDeviceId = getMicDeviceId();
    this.videoDeviceId = getCameraDeviceId();
    await this.checkDeviceChanges();

    // Monitor for device changes and swap out devices if ours is no longer available.
    navigator.mediaDevices.addEventListener('devicechange', async (__event) => {
      track('Device List Changed');
      const restartCapture = await this.checkDeviceChanges();
      if (restartCapture) {
        this.restartCapture();
      }
    });
  }

  async testCapabilities(force = false): Promise<Capability> {
    let stream = null;
    let skip = false;

    if ((!force && this.isAudioCapable !== null) || this.isVideoCapable !== null) {
      skip = true;
    }

    if (!skip) {
      try {
        track('Get User Media', { audio: true, operation: 'testDeviceCapabilities' });
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.isAudioCapable = true;
      } catch (err) {
        this.audioNotCapableError = err;
        track('Get User Media Error', {
          constraints: { audio: true },
          error: err.name,
          message: err.message,
          action: 'testDeviceCapabilities',
        });
        log.error(`Auth: Unable to retrieve getUserMedia with Audio On: ${err.name}`);
      } finally {
        if (stream) stream.getTracks().forEach((t) => t.stop());
      }

      try {
        track('Get User Media', { video: true, operation: 'testDeviceCapabilities' });
        stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.isVideoCapable = true;
      } catch (err) {
        this.videoNotCapableError = err;
        track('Get User Media Error', {
          constraints: { video: true },
          error: err.name,
          message: err.message,
          action: 'testDeviceCapabilities',
        });
        log.error(`Auth: Unable to retrieve getUserMedia with Video On: ${err.name}`);
      } finally {
        if (stream) stream.getTracks().forEach((t) => t.stop());
      }
    }

    return {
      isAudioCapable: this.isAudioCapable,
      audioNotCapableError: this.audioNotCapableError,
      isVideoCapable: this.isVideoCapable,
      videoNotCapableError: this.videoNotCapableError,
    };
  }
}
