import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';

// components
import { chatButtonMixin } from '../shared-styles';

interface MessageColorButtonProps {
  messageColor: string;
  onColorPick: (color: string) => void;
}

const MessageColorButton = ({ messageColor, onColorPick }: MessageColorButtonProps) => {
  const colorPickerRef = useRef(null);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  useEffect(() => {
    const colorPicker = colorPickerRef.current;
    if (isColorPickerOpen) {
      colorPicker?.addEventListener('colorchange', onColorPick);
    } else {
      colorPicker?.removeEventListener('colorchange', onColorPick);
    }

    return () => {
      colorPicker?.removeEventListener('colorchange', onColorPick);
    };
  }, [isColorPickerOpen, onColorPick]);

  return (
    <Popover onOpenChange={setIsColorPickerOpen}>
      <PopoverTrigger asChild>
        <Button messageColor={messageColor} />
      </PopoverTrigger>

      <PopoverContent side="top" className="prevent-on-click-outside">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <here-color-select ref={colorPickerRef} initialValue={messageColor} />
      </PopoverContent>
    </Popover>
  );
};

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverContent = styled(PopoverPrimitive.Content)`
  padding: 10px 15px;
  background-color: white;
  border-radius: 10px;
  outline: none;
`;

const Button = styled.button<{ messageColor: string }>`
  ${chatButtonMixin()}

  height: 18px;
  width: 18px;
  background-color: ${({ messageColor }) => messageColor};
  border-radius: 20px 20px 0 20px;
`;

export default MessageColorButton;
