import React, { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import Particles from '@tsparticles/react';
import { isEqual } from 'lodash/fp';

import { SkinParticles } from '../../../../store/chat-skinning/init-state';
import { directionToSide } from '../constants';

interface Props {
  uniqueIdPrefix: string;
  particles: SkinParticles;
  shouldPauseOnMouseLeave?: boolean;
}

// Position defines where emitter's center (not top left corner) is. In percents.
const emitterPosition = {
  up: { x: 50, y: 100 },
  down: { x: 50, y: 0 },
  left: { x: 100, y: 50 },
  right: { x: 0, y: 50 },
};

const sizeMagicRatio = 0.05;
const speedMagicRatio = 0.1;
const radToDeg = 180 / Math.PI;

const SkinningParticlesEmitter = ({ uniqueIdPrefix, particles, shouldPauseOnMouseLeave }: Props) => {
  // tsparticles calls it on every render for some reason, so storing it in the ref
  const particlesApiRef = useRef(null);
  const onParticlesLoaded = useCallback(
    async (p) => {
      if (!particlesApiRef.current && shouldPauseOnMouseLeave) {
        setTimeout(() => p.pause(), 500);
      }
      particlesApiRef.current = p;
    },
    [shouldPauseOnMouseLeave]
  );

  const isVertical = particles?.direction === 'up' || particles?.direction === 'down';

  const options = useMemo(
    () => ({
      fullScreen: { enable: false },
      fpsLimit: 60,
      particles: {
        number: {
          value: 0,
        },
        shape: {
          type: 'image',
          options: {
            image: particles?.images.map((url) => ({ name: url })),
          },
        },
        // It looks like with the 'life' option the particles are reused and their lifetime accumulates which is
        // a very stupid way to implement it. Need to address this later to actually take the 'lifetime' property
        // into account. Leaving commented code since their docs are literally horseshit and I don't want any human
        // being to go that path again .·´¯(>▂<)´¯·.
        // life: {
        //   duration: {
        //     sync: false,
        //     value: particles.lifetime,
        //   },
        // },
        move: {
          outModes: {
            default: 'destroy',
          },
          direction: directionToSide[particles?.direction],
          enable: true,
          random: false,
          straight: true,
          speed: {
            // The absolute value of velocity range can be larger than velocity, so to avoid negative and zero speed
            // (which may lead to unpredicted behavior in poorly documented tsparticles) I invented another magic number - 10
            min: Math.max(10, Math.abs(particles?.velocity) - Math.abs(particles?.velocityRange)) * speedMagicRatio,
            max: (Math.abs(particles?.velocity) + Math.abs(particles?.velocityRange)) * speedMagicRatio,
          },
          gravity: {
            enable: true,
            acceleration: (isVertical ? particles?.yAcceleration : particles?.xAcceleration) * speedMagicRatio ** 2,
          },
        },
        rotate: particles?.spin
          ? {
              value: {
                min: 0,
                max: 360,
              },
              animation: {
                enable: true,
                direction: 'random',
                random: true,
                speed: {
                  min: (particles?.spin - particles?.spinRange) * radToDeg,
                  max: (particles?.spin + particles?.spinRange) * radToDeg,
                },
              },
            }
          : null,
        wobble: particles?.wave
          ? {
              enable: true,
              distance: 10,
              speed: {
                min: -Math.abs(isVertical ? particles?.wave.xForce : particles?.wave.yForce),
                max: Math.abs(isVertical ? particles?.wave.xForce : particles?.wave.yForce),
              },
            }
          : null,
        size: {
          value: {
            // My guess is the size is passed in percents. I couldn't find anything about it in the tsparticles
            // documentation (way to go, guys!)
            min: Math.max(0, (particles?.scale - particles?.scaleRange) * 100) * sizeMagicRatio,
            max: (particles?.scale + particles?.scaleRange) * 100 * sizeMagicRatio,
          },
        },
      },
      preload: particles?.images.map((url) => ({ src: url, name: url })),
      emitters: {
        startCount: 0,
        position: emitterPosition[particles?.direction],
        size: {
          width: isVertical ? 100 : 0,
          height: isVertical ? 0 : 100,
          mode: 'percent',
        },
        rate: {
          delay: 1 / particles?.birthRate,
          quantity: 1,
        },
      },
    }),
    [particles, isVertical]
  );

  const uniqueId = `particles-${uniqueIdPrefix}-${particles?.id}`;

  return (
    <>
      <Container
        onMouseEnter={() => shouldPauseOnMouseLeave && particlesApiRef.current?.play()}
        onMouseLeave={() => shouldPauseOnMouseLeave && particlesApiRef.current?.pause()}
        particlesId={uniqueId}
      >
        {particles ? (
          // @ts-ignore
          <Particles id={uniqueId} options={options} particlesLoaded={onParticlesLoaded} />
        ) : null}
      </Container>
    </>
  );
};

export default React.memo(SkinningParticlesEmitter, isEqual);

const Container = styled.div<{ particlesId: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  #${({ particlesId }) => particlesId} {
    height: 100%;
  }
`;
