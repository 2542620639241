import UIkit from 'uikit';

import { db } from '../../../firebase';
import unsplashApi from '../../../unsplash-api';
import BackgroundPicker from './background-picker';
import { htmlToElement, isImage } from '../../../util';
import bus, { boardChanged, roomSettingsUpdated } from '../../../event-bus';

import template from './background.html';

import './background.less';
import { uploadFeedBackground, uploadTextChannelBackground } from '../../../upload';
import { addSystemMessage } from '../../../message-util';
import { offSnapshot, onSnapshot } from '../../../firestore-watcher';
import { track } from '../../../util/analytics-util';

class ChatBackgroundPicker extends BackgroundPicker {
  constructor() {
    super();

    this.modal = null;
  }

  connectedCallback() {
    this.innerHTML = `
      <div class="uk-modal-dialog uk-margin-auto-vertical chat-background-picker-modal">
        ${template.replace(/background-upload-input/g, 'chat-background-upload-input')}
      </div>
    `;

    this.spacesTab = this.querySelector('.spaces-tab');
    this.spacesTab.style.display = 'none';
    this.spacesTab.classList.remove('uk-active');

    this.currentBoardId = window.currentBoardId;

    this.searchResults = this.querySelector('.search-results');
    this.searchResults.addEventListener('scroll', this.onSearchResultsScroll.bind(this));

    this.onBackToProperties = this.onBackToProperties.bind(this);
    this.onChatBackgroundUpload = this.onChatBackgroundUpload.bind(this);
    this.onColorSelect = this.onColorSelect.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
    this.onBoardUpdate = this.onBoardUpdate.bind(this);
    this.onSwitchingBoards = this.onSwitchingBoards.bind(this);

    this.querySelector('.done-button').addEventListener('click', this.onBackToProperties);
    this.querySelector('#chat-background-upload-input').addEventListener('change', this.onChatBackgroundUpload);
    this.querySelector('#background-search-input').addEventListener('keyup', this.onSearchInput);
    this.querySelector('here-color-select').addEventListener('colorchange', this.onColorSelect);

    bus.on(boardChanged, this.onSwitchingBoards);

    onSnapshot(`/boards/${this.currentBoardId}`, this.onBoardUpdate);

    this.loadPopularImages();
  }

  disconnectedCallback() {
    this.querySelector('.done-button').removeEventListener('click', this.onBackToProperties);
    this.querySelector('#chat-background-upload-input').removeEventListener('change', this.onChatBackgroundUpload);
    this.querySelector('#background-search-input').removeEventListener('keyup', this.onSearchInput);

    bus.off(boardChanged, this.onSwitchingBoards);

    offSnapshot(`/boards/${this.currentBoardId}`, this.onBoardUpdate);
  }

  onSwitchingBoards(newBoardId) {
    offSnapshot(`/boards/${this.currentBoardId}`, this.onBoardUpdate);
    this.currentBoardId = newBoardId;
    onSnapshot(`/boards/${this.currentBoardId}`, this.onBoardUpdate);
  }

  onBoardUpdate(boardData) {
    this.currentFeedBackgroundImg = boardData.feedBackgroundImg;
    this.currentFeedBackgroundColor = boardData.feedBackgroundColor;
    this.refreshBackgroundSelection();
  }

  open(textChannelId = null, textChannelBackgroundUrl = null) {
    if (!this.modal) {
      this.modal = UIkit.modal(this, { stack: true });
    }

    this.textChannelId = textChannelId;
    this.textChannelBackgroundUrl = textChannelBackgroundUrl;

    this.modal.show();
    this.querySelector('.done-container').style.display = 'none';
    this.refreshBackgroundSelection();
  }

  onBackToProperties() {
    this.modal.hide();
    this.modal = null;
  }

  refreshBackgroundSelection() {
    const selectedClass = 'selected';
    const previousFeedBackgroundImgEl = this.querySelector(`.image-container.${selectedClass}`);
    const currentFeedBackgroundImgEl = this.textChannelId
      ? this.querySelector(`[data-url="${this.textChannelBackgroundUrl}"]`)
      : this.querySelector(`[data-url="${this.currentFeedBackgroundImg}"]`);

    if (previousFeedBackgroundImgEl === currentFeedBackgroundImgEl) {
      return;
    }

    if (previousFeedBackgroundImgEl) {
      previousFeedBackgroundImgEl.classList.remove(selectedClass);
    }

    if (currentFeedBackgroundImgEl) {
      currentFeedBackgroundImgEl.classList.add(selectedClass);
    }
  }

  async retrieveImages(methodName, ...args) {
    this.isLoading = true;
    const imageLoader = this.querySelector('.images-loader');
    imageLoader.style.opacity = 1;

    const images = await unsplashApi[methodName](...args);
    const elements = images.map((image) => {
      const element = htmlToElement(`
        <div class="image-container" data-url="${image.large}">
        <div class="check-container"><span uk-icon="icon: check"></span></div>
          <img src="${image.small}" alt="${image.description}" />
          <div class="author-info">
            <a href="${image.author.url}" target="_blank">${image.author.name}</a>
          </div>
        </div>
      `);

      element.addEventListener('click', async () => {
        if (image.large === this.currentBackground) {
          return;
        }

        if (this.textChannelId) {
          await db
            .doc(`boards/${this.currentBoardId}/elements/${this.textChannelId}`)
            .update({ textChannelBackgroundImg: image.large, textChannelBackgroundColor: null });

          this.textChannelBackgroundUrl = image.large;

          track('Change Text Channel Background Image');
        } else {
          await db
            .doc(`boards/${this.currentBoardId}`)
            .update({ feedBackgroundImg: image.large, feedBackgroundColor: null });

          track('Change Feed Background Image');
        }

        unsplashApi.sendDownloadRequest(image.download);
        this.refreshBackgroundSelection();
        this.querySelector('.done-container').style.display = 'block';
      });

      return element;
    });

    this.searchResults.append(...elements);
    imageLoader.style.opacity = 0;
    this.refreshBackgroundSelection();
    this.isLoading = false;
  }

  async onChatBackgroundUpload(e) {
    const { files } = e.target;
    if (!files || !files[0]) {
      return;
    }

    if (isImage(files[0].name)) {
      if (this.textChannelId) {
        await uploadTextChannelBackground(files[0], this.textChannelId);
      } else {
        await uploadFeedBackground(files[0]);
        bus.dispatch(roomSettingsUpdated);
      }
    }

    this.querySelector('.done-container').style.display = 'block';
  }

  async onColorSelect(e) {
    const { color, inputComplete } = e.detail;
    if (!inputComplete) {
      return;
    }

    this.querySelector('.done-container').style.display = 'block';

    if (this.textChannelId) {
      addSystemMessage('changed the text channel background color');
      track('Change Text Channel Background Color');
      await db
        .doc(`boards/${this.currentBoardId}/elements/${this.textChannelId}`)
        .update({ textChannelBackgroundColor: color, textChannelBackgroundImg: null });

      this.textChannelBackgroundUrl = null;
    } else {
      await db.doc(`boards/${window.currentBoardId}`).update({ feedBackgroundColor: color, feedBackgroundImg: null });
      addSystemMessage('changed the feed background color');
      track('Change Feed Background Color');
    }

    bus.dispatch(roomSettingsUpdated);
  }
}

window.customElements.define('here-chat-background-picker', ChatBackgroundPicker);

export default new ChatBackgroundPicker();
