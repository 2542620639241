import { ADD_NEW_USER_CARD, DELETE_USER_CARD } from '../../constants/analytics-events/user-page-events';
import { USER_CARD_BOARD_TYPE, USER_CARD_TEMPLATE_BOARD_ID } from '../../constants/board-constants';
import firebase, { db } from '../../firebase';
import log from '../../log';
import { track } from '../../util/analytics-util';
import { deleteLobbyRoom } from '../../util/lobby-util';
import { createRoomRequest } from '../store/room/sagas';

export const markUserPageAsEdited = (userId) => {
  db.doc(`userProfiles/${userId}`).update({
    lastEditedUserPage: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export const addNewUserCard = async ({ userId, currentCardIds }) => {
  try {
    const newCardData = await createRoomRequest({
      name: '',
      templateBoardId: USER_CARD_TEMPLATE_BOARD_ID,
      allowAnonymous: true,
      uid: userId,
      type: USER_CARD_BOARD_TYPE,
    });

    const newCardIds = [...currentCardIds, newCardData.id];
    await db.doc(`userProfiles/${userId}`).update({
      cardIds: newCardIds,
    });

    markUserPageAsEdited(userId);
    track(ADD_NEW_USER_CARD, { cardsCount: currentCardIds.length });
    return newCardData.id;
  } catch (error) {
    log.error('Error creating new user card', error);
    return null;
  }
};

export const removeUserCard = async ({ userId, cardId, currentCardIds }) => {
  try {
    await db.doc(`userProfiles/${userId}`).update({
      cardIds: currentCardIds.filter((id) => id !== cardId),
    });
    await deleteLobbyRoom(cardId);
    track(DELETE_USER_CARD);
  } catch (error) {
    log.error('Error removing user card', error);
  }
};
