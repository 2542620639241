import React from 'react';
import styled, { css } from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { reactionButtonsSharedStyles, reactionLabelSharedStyles } from './shared-styles';

// hooks
import { useUserProfileData } from '../../../hooks/useUserProfileData';
import Tooltip from '../../../components/Tooltip';

const plusIcon = '/images/icons/plus-icon.svg';
const minusIcon = '/images/icons/minus-icon.svg';

interface ChatReactionLabelProps {
  userId: string;
  reaction: string;
  customReaction: string;
  showPlusButton: boolean;
  showMinusButton: boolean;
  onClick: () => void;
}

const ChatReactionLabel = ({
  userId,
  reaction,
  customReaction,
  showPlusButton,
  showMinusButton,
  onClick,
}: ChatReactionLabelProps) => {
  const { userProfile } = useUserProfileData(userId) as { userProfile: { displayName: string }; loading: boolean };

  const TooltipContent = (
    <TooltipStyledContent side="top" sideOffset={showPlusButton || showMinusButton ? 13 : 8}>
      {`${userProfile?.displayName || 'Somebody'} reacted${reaction ? ` with ${reaction}` : ''}`}
      {customReaction && <img height="33px" src={customReaction} alt="reaction" />}
    </TooltipStyledContent>
  );

  return (
    <Container onClick={onClick}>
      <Tooltip content={TooltipContent}>
        <div>
          {customReaction && <img src={customReaction} alt="reaction" />}
          {reaction && reaction}
        </div>
      </Tooltip>
      {showPlusButton ? <ChatAddReactionLabelButton /> : null}
      {showMinusButton ? <ChatRemoveReactionLabelButton /> : null}
    </Container>
  );
};

const reactionLabelButtonStyles = css`
  height: 1px;
  width: 1px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #eeeeee;
  box-shadow: none;
  border: 0.5px solid #dddddd;
  font-size: 1.1em;

  &:hover {
    background-color: white;
  }
`;

const TooltipStyledContent = styled(TooltipPrimitive.Content)`
  padding: 0 10px;
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: #12002d;

  font-size: 13px;
  font-weight: bold;
  text-align: center;

  display: flex;
  align-items: center;

  img {
    margin-left: 3px;
    height: 33px;
    width: auto;
  }
`;

const ChatAddReactionLabelButton = styled.div`
  ${reactionButtonsSharedStyles}
  ${reactionLabelButtonStyles}

  display: none;
  background-image: url('${plusIcon}');
`;

const ChatRemoveReactionLabelButton = styled.div`
  ${reactionButtonsSharedStyles}
  ${reactionLabelButtonStyles}

  display: none;
  background-size: 50%;
  background-image: url('${minusIcon}');
`;

const Container = styled.div`
  ${reactionLabelSharedStyles}

  box-shadow: none;
  height: 33px;
  width: 33px;

  position: relative;
  display: flex;
  font-size: 30px;

  &:first-child {
    margin-left: 4px;
  }

  &:not(:first-child) {
    margin-left: -14px;
  }

  &:hover {
    z-index: 10;

    ${ChatAddReactionLabelButton} {
      left: -3px;
      top: -3px;
      z-index: 5;
      display: flex;

      &:hover {
        z-index: 15;
      }
    }

    ${ChatRemoveReactionLabelButton} {
      left: -3px;
      top: -3px;
      z-index: 5;
      display: flex;

      &:hover {
        z-index: 15;
      }
    }
  }
`;

export default ChatReactionLabel;
