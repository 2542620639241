import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isCurrentUser } from '../../util/user-util';
import { track } from '../../util/analytics-util';
import { useUserProfileData } from '../hooks/useUserProfileData';

import { friendByIdSelector, loadingFriendsSelector } from '../store/friends/selectors';

import DisplayProfile from './DisplayProfile';
import ProfileCTAs from './ProfileCTAs';
import EditProfile from './EditProfile';
import { fetchFriends } from '../store/friends/store';
import { applyTheme } from '../../util/theming-util';
import EditProfileTheme from './EditProfileTheme';
import { PROFILE_CARD_HEIGHT_PX, PROFILE_CARD_WIDTH_PX } from './constants';
import ProfileCarousel from './ProfileCarousel';
import UserMood from './mood/index.tsx';

import '../../../styles/buttons.less';
import UserProfileEffect from '../animations/UserProfileEffect';
import { OPEN_PROFILE, OPEN_PROFILE_SOURCES } from '../../constants/analytics-events/user-events';
import { selectIsInOs } from '../store/room/selectors';

const UserProfile = ({ userId, showCTAs, showUserCards, id, openToUserCards, editUserMood }) => {
  const isSelf = isCurrentUser(userId);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTheme, setIsEditingTheme] = useState(false);

  const profileWrapperElement = useRef(null);
  const displayProfileElement = useRef(null);

  const { userProfile } = useUserProfileData(userId);

  const source = useSelector(selectIsInOs) ? OPEN_PROFILE_SOURCES.LOBBY : null;
  useEffect(() => {
    track(OPEN_PROFILE, { target: isSelf ? 'self' : 'other member', source });
  }, [isSelf, source]);

  const dispatch = useDispatch();
  const friend = useSelector((state) => friendByIdSelector(state, userId));
  const isLoading = useSelector((state) => loadingFriendsSelector(state));

  useEffect(() => {
    dispatch(fetchFriends());
  }, [dispatch]);

  useEffect(() => {
    if (userProfile?.id && profileWrapperElement.current) {
      applyTheme(
        userProfile.profileTheme?.colors,
        userProfile.profileTheme?.primaryFont,
        profileWrapperElement.current
      );
    }
  }, [userProfile?.id, userProfile?.profileTheme]);

  const onEnterEditingTheme = useCallback(() => {
    setIsEditing(false);
    setIsEditingTheme(true);
  }, []);
  const onExitEditingTheme = useCallback(() => {
    setIsEditing(true);
    setIsEditingTheme(false);
  }, []);

  const [isEditingMood, setIsEditingMood] = useState(false);
  const onEnterMoodEdit = useCallback(() => setIsEditingMood(true), []);
  const onExitMoodEdit = useCallback(() => setIsEditingMood(false), []);

  const getCtas = () => (
    <ProfileCTAs
      userId={userId}
      isSelf={isSelf}
      setEditingMode={setIsEditing}
      profileWrapperElement={profileWrapperElement.current}
      isFriends={Boolean(friend)}
      isFetchingFriends={isLoading}
    />
  );

  const areCtasVisible = showCTAs && !isEditingMood;

  const profileView = (
    <>
      <DisplayProfile
        ref={displayProfileElement}
        userId={userId}
        userProfile={userProfile}
        isFriends={Boolean(friend)}
        showCTAs={showCTAs}
        bottomGap={isEditingMood || userProfile?.mood ? 78 : 0}
      />
      {areCtasVisible && getCtas()}
    </>
  );

  const wrappedProfileView = showUserCards ? (
    // Rendering CTAs inside the carousel so they're in the same stacking context with carousel controls.
    <ProfileCarousel
      cardIds={userProfile.cardIds || []}
      ctas={isEditingMood ? null : getCtas()}
      userId={userId}
      initialCardIndex={openToUserCards ? 1 : 0}
      isNavigationDisabled={isEditingMood}
    >
      {profileView}
      {userProfile.effectEmoteUrl && (
        <AnimationContainer>
          <UserProfileEffect userId={userId} />
        </AnimationContainer>
      )}
      <MoodContainer
        areCtasVisible={areCtasVisible}
        isProfileScrollable={
          displayProfileElement.current?.scrollHeight !== displayProfileElement.current?.clientHeight
        }
      >
        <UserMood
          mood={userProfile.mood}
          canEdit={isSelf}
          editUserMood={editUserMood}
          onEnterEditMode={onEnterMoodEdit}
          onExitEditMode={onExitMoodEdit}
        />
      </MoodContainer>
    </ProfileCarousel>
  ) : (
    profileView
  );

  return (
    <ProfileWrapper ref={profileWrapperElement}>
      {isSelf && userProfile?.id && (
        <EditProfileTheme userProfile={userProfile} isOpened={isEditingTheme} onClose={onExitEditingTheme} id={id} />
      )}
      {isSelf && isEditing ? (
        <EditProfile userProfile={userProfile} onEditProfileTheme={onEnterEditingTheme} />
      ) : (
        <div>{wrappedProfileView}</div>
      )}
    </ProfileWrapper>
  );
};

export default UserProfile;

UserProfile.propTypes = {
  userId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  // TODO: instead of passing in noCTAs bool, we should probably pass in CTAs node
  // and simply render here for better flexibility of CTAs on the user profile
  showCTAs: PropTypes.bool,
  editUserMood: PropTypes.bool,
  showUserCards: PropTypes.bool,
  openToUserCards: PropTypes.bool,
};

UserProfile.defaultProps = {
  showCTAs: true,
  showUserCards: true,
  editUserMood: false,
  openToUserCards: false,
};

const ProfileWrapper = styled.div`
  width: ${PROFILE_CARD_WIDTH_PX}px;
  height: ${PROFILE_CARD_HEIGHT_PX}px;
  border-radius: 20px;
  background: var(--primary-background, #12002d);
  position: relative;
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
`;

const MoodContainer = styled.div`
  position: absolute;
  bottom: ${({ areCtasVisible }) => (areCtasVisible ? 54 : 11)}px;
  width: 100%;
  z-index: 1;
  background: transparent;
  ${({ isProfileScrollable, areCtasVisible }) => {
    if (!isProfileScrollable) return '';
    return areCtasVisible
      ? 'background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 50%);'
      : 'box-shadow: 0px -11px 17px -9px rgba(0, 0, 0, 0.4);';
  }}
  padding: ${({ areCtasVisible }) => (areCtasVisible ? '0 0 4px' : '8px 0')};
  padding-bottom: 4px;
  ${({ areCtasVisible }) =>
    !areCtasVisible &&
    `
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  `}
`;
