import firebase, { db } from '../firebase';
import log from '../log';

export const fetchUserProfile = (userId) =>
  db
    .doc(`userProfiles/${userId}`)
    .get()
    .catch((error) => {
      log.error(error);
      return null;
    });

export const setUserProfileTheme = (userId, theme) =>
  db.doc(`userProfiles/${userId}`).update({ profileTheme: theme }).catch(log.error);

export const setCurrentUserAvatar = async (avatarUrl, isDefault = false) => {
  const userId = firebase.auth().currentUser.uid;
  return Promise.all([
    firebase.auth().currentUser.updateProfile({ photoURL: avatarUrl }),
    db.collection('userProfiles').doc(userId).update({
      'avatar.original': avatarUrl,
      'avatar.thumbnail': null,
      'avatar.isDefault': isDefault,
    }),
  ]);
};

export const setCurrentUserBackground = async (backgroundUrl) => {
  const userId = firebase.auth().currentUser.uid;
  await db.doc(`userProfiles/${userId}`).update({
    'backgroundPhoto.original': backgroundUrl,
    'backgroundPhoto.thumbnail': null,
  });
};

export const setHasSeenLinkSchoolModal = (userId, hasSeenLinkSchoolModal) =>
  db.doc(`userProfiles/${userId}`).update({ hasSeenLinkSchoolModal }).catch(log.error);
