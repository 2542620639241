import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { ChatPermissionsContext } from '../../../../common/contexts';
import ChatUserData from '../ChatUserData';
import { unbanMember } from '../../../../store/messaging/actions';

interface Props {
  memberId: string;
  chatId: string;
}

const BannedListItem = ({ memberId, chatId }: Props) => {
  const dispatch = useDispatch();

  const permissions = useContext(ChatPermissionsContext);

  return (
    <Container>
      <ChatUserData userId={memberId} />
      <Button
        className="here-button-regular"
        onClick={() => dispatch(unbanMember({ chatId, memberId }))}
        disabled={!permissions.canRemoveMembers}
      >
        Unban
      </Button>
    </Container>
  );
};

export default BannedListItem;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  padding: 0 10px;
  height: 40px;
  margin: 4px 0;
  background: rgba(255, 255, 255, 0.1);
`;

const Button = styled.button`
  height: 24px;
  flex: 1 0 auto;
  margin-left: 2px;
  border-radius: 12px;
  font-size: 12px;
  padding: 0 12px;
`;
