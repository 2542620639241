import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { CHAT_WINDOW_HEADER_HEIGHT } from '../constants';
import { ellipsisTextMixin, svgColorMixin } from '../../../mixins';
import MembersAvatarsList from '../../../groups-lobby/main-area/MembersAvatarsList';
import VideoIcon from '../../../../../assets/icons/hereos/chat/video.svg';
import SettingsIcon from '../../../../../assets/icons/hereos/chat/settings-unselected.svg';
import ThemeIcon from '../../../../../assets/icons/hereos/chat/theme.svg';
import LottieAnimation from '../../../animations/LottieAnimation';
import animationData from '../../../../../assets/lottie/chat-theme-pulse.json';
import { selectCurrentUserHasCustomizedChat, selectUserById } from '../../../store/users/selectors';
import { selectCurrentScreenId } from '../../../store/signing-in/selectors';
import { CreateOsChatFlowSteps } from '../../../sign-in-flow/constants';
import ShareLinkIcon from '../../../../../assets/icons/hereos/chat/share-link.svg';
import AddFriendsIcon from '../../../../../assets/icons/friends-v3/add-friends.svg';
import { getGroupChatParams, track } from '../../../../util/analytics-util';
import { CHAT_COPY_LINK, CHAT_HEADER_ADD_MEMBERS_CLICK } from '../../analytics';
import { selectGroupIdFromChatId, selectIsAPublicChat } from '../../../store/messaging/selectors';
import UserMood from '../../../user-profile/mood';
import CloseIcon from '../../../../../assets/icons/close-circled.svg';
import { ChatPermissionsContext } from '../../../common/contexts';

const MAX_VISIBLE_AVATARS = 7;

interface Props {
  chatId: string;
  chatName: string;
  isActive: boolean;
  memberIds: string[];
  isDm: boolean;
  onClose: () => void;
  onSettingsClick: () => void;
  onVideoClick: () => void;
  onThemeClick: () => void;
}

const ChatHeader: React.FC<Props> = ({
  chatId,
  chatName,
  isActive,
  memberIds,
  isDm,
  onClose,
  onSettingsClick,
  onVideoClick,
  onThemeClick,
}) => {
  const groupId = useSelector((state) => selectGroupIdFromChatId(state, chatId));
  const dmReceiver = useSelector((state) => selectUserById(state, memberIds[0]));
  const isPublic = useSelector((state) => selectIsAPublicChat(state, chatId));

  const hasCustomizedChat = useSelector(selectCurrentUserHasCustomizedChat);
  const currOnboardingScreen = useSelector(selectCurrentScreenId);
  const isInOnboarding = Object.values(CreateOsChatFlowSteps).includes(currOnboardingScreen as CreateOsChatFlowSteps);

  const onAddMembersClick = () => {
    track(CHAT_HEADER_ADD_MEMBERS_CLICK);
    onSettingsClick();
  };

  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const onCopyLinkClick = () => {
    window.navigator.clipboard.writeText(`${window.location.origin}/join/${groupId}`);

    track(CHAT_COPY_LINK, getGroupChatParams({ chatId, source: 'chat window header' }));

    setIsUrlCopied(true);

    setTimeout(() => {
      setIsUrlCopied(false);
    }, 1000);
  };

  const permissions = useContext(ChatPermissionsContext);

  return (
    <Header>
      <TopRow>
        <HeaderButtonsSideContainer>
          <CloseButton onClick={onClose}>
            <StyledCloseIcon width={18} height={18} />
          </CloseButton>
        </HeaderButtonsSideContainer>
        <ChatTitle>{chatName || dmReceiver?.displayName}</ChatTitle>
        <HeaderButtonsSideContainer>
          {isPublic ? null : (
            <IconButton onClick={onVideoClick}>
              <VideoIcon />
            </IconButton>
          )}
          {permissions.canChangeTheme ? (
            <IconButton onClick={onThemeClick}>
              {!hasCustomizedChat && isActive && !isInOnboarding && (
                <ThemeAnimationContainer>
                  <LottieAnimation animationData={animationData} loop />
                </ThemeAnimationContainer>
              )}
              <ThemeIcon />
            </IconButton>
          ) : null}
          {isDm ? null : (
            <IconButton onClick={onSettingsClick}>
              <SettingsIcon />
            </IconButton>
          )}
        </HeaderButtonsSideContainer>
      </TopRow>
      {!isInOnboarding && (
        <BottomRow>
          {memberIds.length > 0 ? (
            <MembersAvatarsList
              memberIds={memberIds}
              avatarSize={27}
              maxVisibleAvatars={MAX_VISIBLE_AVATARS}
              onOverflowClick={onSettingsClick}
            />
          ) : (
            <Button onClick={onAddMembersClick}>
              <AddFriendsIcon />
              Add Members
            </Button>
          )}
          {isDm ? (
            <MoodContainer>
              <UserMood mood={dmReceiver?.mood} hasMarquee />
            </MoodContainer>
          ) : null}
          {!isDm && permissions.canAddMembers ? (
            <Button onClick={onCopyLinkClick}>
              {isUrlCopied ? (
                'Copied!'
              ) : (
                <>
                  <ShareLinkIcon />
                  Invite Link
                </>
              )}
            </Button>
          ) : null}
        </BottomRow>
      )}
    </Header>
  );
};

export default ChatHeader;

const Header = styled.div`
  position: relative;
  height: ${CHAT_WINDOW_HEADER_HEIGHT}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
`;

const TopRow = styled(Row)`
  align-items: center;
`;

const BottomRow = styled(Row)`
  justify-content: space-between;
`;

const CloseButton = styled.div`
  cursor: pointer;
  margin-right: 8px;
`;

const HeaderButtonsSideContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

const ChatTitle = styled.div`
  color: var(--primary-foreground);
  font-family: var(--primary-font, Inter);
  font-size: 16px;
  font-weight: 600;
  ${ellipsisTextMixin()}
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  padding: 0 10px;
  border-radius: 30px;
  height: 30px;
  min-width: 119px;
  cursor: pointer;
  background-color: var(--secondary-background);
  color: var(--secondary-foreground);
  transition: background-color 0.2s;

  svg {
    margin-right: 3px;
    ${svgColorMixin('var(--secondary-foreground)')}
  }

  &:hover {
    background-color: var(--secondary-background-alpha-50);
    color: var(--primary-foreground);

    ${svgColorMixin('var(--primary-foreground)')}
  }
`;

const MoodContainer = styled.div`
  max-width: 80%;
  transform: scale(0.6);
  transform-origin: top left;
  margin-right: auto;
  height: 0; // A hack to prevent this scaled-down element to increase its parent height before scaling down
`;

const ThemeAnimationContainer = styled.div`
  position: absolute;
  top: -5px;
  left: 1px;
  width: 26px;
  height: 26px;
`;

export const StyledCloseIcon = styled(CloseIcon)<{ disabled?: boolean }>`
  ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : 'cursor: pointer;')}
  ${svgColorMixin('var(--primary-foreground)')};
`;

const IconButton = styled.div<{ disabled?: boolean }>`
  width: 16px;
  box-sizing: content-box;
  padding: 0 6px;
  position: relative;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : 'cursor: pointer;')}

  svg {
    ${svgColorMixin('var(--primary-foreground)')}
  }
`;
