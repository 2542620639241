export function drawCenteredImage(
  src: CanvasImageSource,
  srcWidth: number,
  srcHeight: number,
  dstContext: CanvasRenderingContext2D,
  dstWidth: number,
  dstHeight: number,
  compositeMode: GlobalCompositeOperation,
  dstOriginX: number,
  dstOriginY: number
): void {
  const hRatio = dstWidth / srcWidth;
  const vRatio = dstHeight / srcHeight;
  const ratio = Math.min(hRatio, vRatio);
  const shiftY = (dstWidth - srcWidth * ratio) / 2;
  const shiftX = (dstHeight - srcHeight * ratio) / 2;

  const drawSize = Math.min(srcWidth, srcHeight);

  dstContext.globalCompositeOperation = compositeMode;
  // console.log(srcWidth, srcHeight, dstWidth, dstHeight);
  dstContext.drawImage(src, shiftX, shiftY, drawSize, drawSize, dstOriginX, dstOriginY, dstWidth, dstHeight);
}
