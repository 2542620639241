import React from 'react';
import styled from 'styled-components';

import { HereInput } from '../components/inputs';
import HereErrorLabel from '../components/HereErrorLabel';

interface FlowInputProps {
  label: string;
  hint?: string;
  errorMessage?: string;
  // HereInput props
  id: string;
  type?: string;
  placeholder?: string;
  checkResult?: boolean;
  value: string;
  prefix?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
  testId?: string;
}

const FlowInput = ({
  label,
  hint,
  errorMessage,
  // HereInput props
  id,
  type,
  placeholder,
  checkResult,
  value,
  prefix,
  onChange,
  onKeyDown,
  disabled,
  autoFocus,
  maxLength,
  testId,
}: FlowInputProps) => (
  <>
    <Label htmlFor={id}>
      <LabelTextContainer>
        <LabelText>{label}</LabelText>
        <HintText>{hint}</HintText>
      </LabelTextContainer>

      <HereInput
        id={id}
        type={type}
        value={value}
        placeholder={placeholder}
        checkResult={checkResult}
        onChange={onChange}
        onKeyDown={onKeyDown}
        prefix={prefix}
        disabled={disabled}
        autoFocus={autoFocus}
        maxLength={maxLength}
        controlled
        testId={testId}
      />
    </Label>

    {errorMessage ? <HereErrorLabel errorMessage={errorMessage} /> : null}
  </>
);

const Label = styled.label``;

const LabelTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LabelText = styled.span`
  font-size: 12px;
  line-height: 24px;
`;

const HintText = styled.span`
  color: #6b1be3;
  font-size: 12px;
  font-weight: bolder;
  line-height: 24px;
`;

export default FlowInput;
