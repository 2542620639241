import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';

import firebase from '../../../firebase';
import log from '../../../log';
import { setUserProfileTheme } from '../../../api/user-profiles-api';

// store
import {
  continueToRoom,
  createRoom,
  sendInvites,
  setCreateRoomFlowStep,
  setIsNewRoomCreating,
  updateNewRoomData,
  createRoomAndSendInvites,
  createVibeGroupOnboarding,
  setIsOnboarding,
} from './actions';
import {
  selectNewRoom,
  selectNewRoomId,
  selectNewRoomCustomUrl,
  selectNewRoomHasCustomUrl,
  selectNewRoomTemplate,
  selectNewRoomName,
  selectNewRoomCreator,
  selectNewRoomTemplateBackground,
  selectIsOnboarding,
  selectNewRoomTheme,
  selectNewRoomHasCustomBackground,
} from './selectors';
import { selectCurrentUserFirstName, selectCurrentUserId } from '../users/selectors';
import { NewRoomFlowSteps } from './init-state';
import {
  createRoomForGroupRequest,
  createRoomRequest,
  makeSendInvitesRequest,
  setGroupsOnboardingStep,
  setUserProfileIsOnboarding,
} from './api';
import { createGroup } from '../../../api/groups-api';

// utils
import { checkUrlAlias } from '../../../util/url-alias-validator';
import { isNewUser } from '../../../util/user-util';
import { track } from '../../../util/analytics-util';
import { checkIsElectron } from '../../../util/platform-util';
import { goToRoom } from '../../../util/navigation-util';

// constants
import { homepageConceptLocalStorageItem } from '../../../constants/homepage-constants';
import { CREATE_NEW_USER_ROOM, CREATE_ROOM } from '../../../constants/analytics-events/rooms-events';
import { CREATE_NEW_USER_GROUP } from '../../../constants/analytics-events/onboarding-events';
import { roomOnboardingFlow } from '../../sign-in-flow/flows';
import { HubsOnboardingScreens } from '../../sign-in-flow/constants';
import { BLANK_ROOM_ID } from '../../../constants/board-constants';
import { ERROR_CREATING_GROUP_IN_ONBOARDING } from '../../../constants/analytics-events/error-events';
import eventBus, { roomCreated } from '../../../event-bus';

function* handleContinueToRoom() {
  const newRoomId: ReturnType<typeof selectNewRoomId> = yield select(selectNewRoomId);
  const newRoomCustomUrl: ReturnType<typeof selectNewRoomCustomUrl> = yield select(selectNewRoomCustomUrl);
  const isOnboarding: ReturnType<typeof selectIsOnboarding> = yield select(selectIsOnboarding);

  goToRoom(newRoomId, { customUrl: newRoomCustomUrl, isNewRoom: true, isOnboarding });
}

function* handleCreateRoom({ payload }: { payload: { flowType?: string; isCustomBackground?: boolean } }) {
  yield put(setIsNewRoomCreating({ isNewRoomCreating: true }));

  // validate custom url (if provided)
  let roomUrl = '';
  const hasCustomUrl: ReturnType<typeof selectNewRoomHasCustomUrl> = yield select(selectNewRoomHasCustomUrl);
  if (hasCustomUrl) {
    roomUrl = yield select(selectNewRoomCustomUrl);
    if (roomUrl) {
      roomUrl = roomUrl.trim().toLowerCase();
      if (roomUrl) {
        const urlAliasErrorMessage: ReturnType<typeof checkUrlAlias> = yield call(checkUrlAlias, roomUrl);
        if (urlAliasErrorMessage) {
          yield put(setIsNewRoomCreating({ isNewRoomCreating: false }));
          yield put(updateNewRoomData({ data: { customUrl: '' } }));
          return;
        }
      }
    }
  }

  // create room
  const room: ReturnType<typeof selectNewRoom> = yield select(selectNewRoom);
  const uid: string = yield select(selectCurrentUserId);
  const background: ReturnType<typeof selectNewRoomTemplateBackground> = yield select(selectNewRoomTemplateBackground);
  const isOnboarding: ReturnType<typeof selectIsOnboarding> = yield select(selectIsOnboarding);

  let createRoomResponse: { id: string; boardData: { title: string } };
  const requestBody = {
    ...room,
    background,
    uid,
    ...(payload?.isCustomBackground ? { isCustomBackground: !!payload?.isCustomBackground } : {}),
    joinMode: room.joinModeOpen ? 'waitlist' : 'open',
  };

  yield call([eventBus, 'dispatch'], roomCreated);

  if (room.groupId) {
    createRoomResponse = yield call(createRoomForGroupRequest, {
      groupId: room.groupId,
      ...requestBody,
    });
  } else {
    createRoomResponse = yield call(createRoomRequest, requestBody);
  }

  yield put(setIsNewRoomCreating({ isNewRoomCreating: false }));
  if (!createRoomResponse.id) {
    yield call(log.error, 'Could not create board');
    return;
  }

  // analytics
  const newRoomTemplate: ReturnType<typeof selectNewRoomTemplate> = yield select(selectNewRoomTemplate);
  const user = firebase.auth().currentUser;
  if (user) {
    const firstName = user.displayName ? user.displayName.split(' ')[0] : null;
    const isNew: boolean = yield call(isNewUser, user);
    const isElectron: boolean = yield call(checkIsElectron);
    if (isNew) {
      const homepageConcept = localStorage.getItem(homepageConceptLocalStorageItem);
      yield call(track, CREATE_NEW_USER_ROOM, {
        firstName,
        fullName: user.displayName,
        email: user.email,
        roomId: createRoomResponse.id,
        template: newRoomTemplate.name || 'blank',
        userOrigin: isElectron ? 'electron' : 'web',
        entryPoint: newRoomTemplate.name ? 'homepage' : 'room deep link',
        homepageConcept,
        flowType: payload?.flowType,
        groupId: room.groupId,
      });
    } else {
      track(CREATE_ROOM, {
        title: createRoomResponse.boardData.title,
        roomId: createRoomResponse.id,
        template: newRoomTemplate.name || 'blank',
        userOrigin: isElectron ? 'electron' : 'web',
        flowType: payload?.flowType,
        groupId: room.groupId,
      });
    }
  }

  // save new room id
  yield put(updateNewRoomData({ data: { id: createRoomResponse.id, creator: uid } }));

  if (isOnboarding || room.groupId) {
    yield call(handleContinueToRoom);
  } else {
    yield put(setCreateRoomFlowStep({ step: NewRoomFlowSteps.INVITE_FRIENDS }));
  }
}

const RESPONSE_MIN_TIME_MS = 6000;

function* handleCreateGroupOnboarding({ isCustomBackground }: { isCustomBackground?: boolean }) {
  const currentUserId: string = yield select(selectCurrentUserId);

  try {
    yield put(setIsNewRoomCreating({ isNewRoomCreating: true }));
    yield put(updateNewRoomData({ data: { id: '' } }));

    const room: ReturnType<typeof selectNewRoom> = yield select(selectNewRoom);
    const currentUserFirstName: string = yield select(selectCurrentUserFirstName);

    const requestBody = {
      name: room.name || `${currentUserFirstName}'s Hub`,
      roomOnboardingStepId: roomOnboardingFlow.getFirstScreen()?.id,
      ...(isCustomBackground ? { customVibeBackgroundUrl: room.template.background } : { vibeId: room.template.id }),
    };

    const startTimestamp = Date.now();
    const response: { group: { id: string } } = yield call(createGroup, requestBody);
    const endTimestamp = Date.now();
    const elapsedTime = endTimestamp - startTimestamp;

    // this block is required to ensure that the user sees the loading screen for at least RESPONSE_MIN_TIME_MS
    if (elapsedTime < RESPONSE_MIN_TIME_MS) {
      yield delay(RESPONSE_MIN_TIME_MS - elapsedTime);
    }

    yield call(setGroupsOnboardingStep, currentUserId, HubsOnboardingScreens.WELCOME_TO_HUBS);
    yield call(setUserProfileIsOnboarding, currentUserId, false);
    yield put(setIsNewRoomCreating({ isNewRoomCreating: false }));
    yield put(updateNewRoomData({ data: { id: response.group.id } }));
    yield call(track, CREATE_NEW_USER_GROUP, {
      groupId: response.group.id,
      firstName: currentUserFirstName,
      userOrigin: 'web',
      ...(room.template.id === BLANK_ROOM_ID ? {} : { vibeId: room.template.id }),
    });
  } catch (error) {
    yield call(log.error, 'Could not create group in onboarding', error);
    yield call(track, ERROR_CREATING_GROUP_IN_ONBOARDING, { userId: currentUserId, error });
    yield call(setUserProfileIsOnboarding, currentUserId, false);
  }
}

function* handleSendInvites({ payload }: { payload: { userIds: string[] } }) {
  if (payload.userIds.length) {
    const roomId: ReturnType<typeof selectNewRoomId> = yield select(selectNewRoomId);
    const title: ReturnType<typeof selectNewRoomName> = yield select(selectNewRoomName);
    const creator: ReturnType<typeof selectNewRoomCreator> = yield select(selectNewRoomCreator);
    const background: ReturnType<typeof selectNewRoomTemplateBackground> = yield select(
      selectNewRoomTemplateBackground
    );

    yield call(makeSendInvitesRequest, roomId, payload.userIds, { title, background, creator });
  }

  yield call(handleContinueToRoom);
}

function* handleCreateRoomAndSendInvites({ payload }: { payload: { userIds: string[] } }) {
  yield call(handleCreateRoom, { payload: { flowType: 'create group' } }); // Note: we should update flowType here if we reuse this saga
  yield call(handleSendInvites, { payload: { userIds: payload.userIds } });
}

function* handleCreateVibeGroupOnboarding() {
  // update current user profile theme
  const currentUserId: string = yield select(selectCurrentUserId);
  const theme: ReturnType<typeof selectNewRoomTheme> = yield select(selectNewRoomTheme);
  if (theme) {
    yield call(setUserProfileTheme, currentUserId, theme);
  }

  const isCustomBackground: ReturnType<typeof selectNewRoomHasCustomBackground> = yield select(
    selectNewRoomHasCustomBackground
  );

  // create a room and jump to it
  yield put(setIsNewRoomCreating({ isNewRoomCreating: true }));
  yield put(setIsOnboarding({ isOnboarding: true }));
  yield call(handleCreateGroupOnboarding, { isCustomBackground });
}

export default function* newRoomSaga() {
  yield all([
    takeEvery(createRoom, handleCreateRoom),
    takeEvery(continueToRoom, handleContinueToRoom),
    takeEvery(sendInvites, handleSendInvites),
    takeEvery(createRoomAndSendInvites, handleCreateRoomAndSendInvites),
    takeEvery(createVibeGroupOnboarding, handleCreateVibeGroupOnboarding),
  ]);
}
