import UIkit from 'uikit';

import firebase, { db } from '../../firebase';
import { showWebAppShare, showUploadDefault, handleFiles } from '../../upload';

// elements
import ScreenshareElement from '../../screenshare';
import TextElement from '../../notes';
import WhiteboardElement from '../../whiteboard';
import { getNewUpdatesCount, resetFeedbackMenu } from '../../feedback';
import { isRoomOnboardingInProgress } from '../../onboarding';

// react
import { renderAddImageContainer } from '../../react/image-search/render';
import { renderRoomDecorationMenu } from '../../react/room-decoration-menu/render-room-decoration-menu.tsx';
import reduxAdapter from '../../react/store/redux-adapter';
import { TipsAndTricks } from '../../react/store/tips-and-tricks/init-state.ts';
import { hideTip } from '../../react/store/tips-and-tricks/actions.ts';

// utils
import { htmlToElement, sanitize } from '../../util';
import { checkIsElectron, checkIsMobile } from '../../util/platform-util';
import { hasSeenScreenshareNotice, updateProfile } from '../../util/profile-util';
import { Activities, throttleControl } from '../../util/throttler';
import { browserName } from '../../util/browser-util';

import template from './toolbar.html';

import './toolbar.less';
import { startPhotoCountdown } from '../../photobooth';
import { setPenColor } from '../../drawing';
import WarpElement from '../../warp';
import { setupEmbeddedResourceUrlHandler } from '../../widgets/embedded';
import { gifPickerCallers } from '../../constants/image-constants';
import { track } from '../../util/analytics-util';

function populateWarpList(warpList) {
  db.collection('memberships')
    .doc(firebase.auth().currentUser.uid)
    .collection('boards')
    .orderBy('lastAccess', 'desc')
    .limit(10)
    .get()
    .then((boards) => {
      warpList.innerHTML = '';
      boards.forEach((doc) => {
        const title = sanitize(doc.data().title || '');
        if (doc.id !== window.currentBoardId && !doc.data().ban) {
          const warpItem = htmlToElement(`<li><a>${sanitize(title)}</a></li>`);
          warpList.append(warpItem);
          warpItem.onclick = () => {
            WarpElement.addElement(doc.id, title);
          };
        }
      });
    });
}

let isMediaPLayerAddedDuringOnboarding = false;

// TODO: remove unused variables
class Toolbar extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    // tools
    this.addTextButtons = Array.from(this.querySelectorAll('.add-text'));
    this.addDrawingButtons = Array.from(this.querySelectorAll('.add-drawing-button'));
    this.menuUploadButtons = Array.from(this.querySelectorAll('.menu-upload-button'));
    this.addMediaPlayerButtons = Array.from(this.querySelectorAll('.new-media-player-button'));
    this.openGifsButtons = Array.from(this.querySelectorAll('.open-gifs-button'));
    this.fileUploadSecondaryMenuButton = this.querySelector('#file-upload-secondary-menu-button');
    this.gifsSecondaryMenuButton = this.querySelector('#gifs-secondary-menu-button');
    this.gamesPickerSecondaryMenuButton = this.querySelector('#games-picker-secondary-menu-button');
    this.photoboothButton = this.querySelector('#photobooth-button');
    this.colorSelectSecondaryMenuButton = this.querySelector('#color-select-secondary-menu-button');
    this.buildToolsSecondaryMenuButton = this.querySelector('#build-tools-secondary-menu-button');
    this.toolbarEtcEnabledButton = this.querySelector('#toolbar-etc-enabled-button');
    this.feedbackSecondaryButton = this.querySelector('#feedback-secondary-button');
    this.addWarpButton = this.querySelector('.add-warp');
    this.openRoomDecorationButton = this.querySelector('#open-room-decoration-button');
    this.openRoomDecorationSecondaryButton = this.querySelector('#open-room-decoration-secondary-button');

    // file upload dialog

    document.getElementById('share-web-app').addEventListener('click', showWebAppShare);
    document.getElementById('file-upload-button-cancel').addEventListener('click', showUploadDefault);
    document.getElementById('upload-file').addEventListener('change', (e) => handleFiles(e.target.files));

    // handlers
    window.addEventListener('resize', () => {
      // hide '...'-menu on each resize event
      UIkit.dropdown('#etc-tools-dropdown').hide();
    });

    window.addEventListener('orientationchange', () => {
      // hide '...'-menu on mobile rotate event
      UIkit.dropdown('#etc-tools-dropdown').hide();
    });

    UIkit.util.on('#etc-tools-dropdown', 'hide', () => {
      this.toolbarEtcEnabledButton.classList.remove('active');
    });

    UIkit.util.on('#etc-tools-dropdown', 'show', () => {
      this.toolbarEtcEnabledButton.classList.add('active');
    });

    UIkit.util.on('#build-tools-dropdown', 'show', () => {
      this.querySelector('#add-warp-list').style.display = 'none';
      this.querySelector('.build-tools-list').style.display = 'grid';
    });

    // Closing menus after interaction

    document.getElementById('etc-tools-dropdown').addEventListener('click', () => {
      UIkit.dropdown('#etc-tools-dropdown').hide(0);
    });

    document.getElementById('build-tools-dropdown').addEventListener('click', () => {
      UIkit.dropdown('#build-tools-dropdown').hide(0);
    });

    document.getElementById('game-select-dropdown').addEventListener('click', () => {
      UIkit.dropdown('#game-select-dropdown').hide(0);
    });

    this.addWarpButton.addEventListener('click', (e) => {
      const warpList = this.querySelector('#add-warp-list');
      const buildToolsList = this.querySelector('.build-tools-list');
      const menuStyle = window.getComputedStyle(buildToolsList);
      warpList.style.display = 'block';
      warpList.style.height = menuStyle.height;
      buildToolsList.style.display = 'none';
      this.querySelector('#add-warp-list').style.display = 'block';

      populateWarpList(this.querySelector('#add-warp-items'));
      e.stopPropagation();
    });

    this.fileUploadSecondaryMenuButton.addEventListener('click', (e) => {
      e.stopPropagation();

      UIkit.dropdown('#etc-tools-dropdown').hide();
      UIkit.dropdown('#file-upload-dropdown').show();
    });

    this.gifsSecondaryMenuButton.addEventListener('click', (e) => {
      e.stopPropagation();

      UIkit.dropdown('#etc-tools-dropdown').hide();
      UIkit.dropdown('#sticker-select').show();
    });

    this.gamesPickerSecondaryMenuButton.addEventListener('click', (e) => {
      e.stopPropagation();

      UIkit.dropdown('#etc-tools-dropdown').hide();
      UIkit.dropdown('#game-select-dropdown').show();
    });

    this.colorSelectSecondaryMenuButton.addEventListener('click', (e) => {
      e.stopPropagation();

      UIkit.dropdown('#etc-tools-dropdown').hide();
      UIkit.dropdown('#color-select-dropdown').show();
    });

    this.buildToolsSecondaryMenuButton.addEventListener('click', (e) => {
      e.stopPropagation();

      UIkit.dropdown('#etc-tools-dropdown').hide();
      UIkit.dropdown('#build-tools-dropdown').show();
    });

    this.feedbackSecondaryButton.addEventListener('click', (e) => {
      e.stopPropagation();

      UIkit.dropdown('#etc-tools-dropdown').hide();
      UIkit.dropdown('#feedback-window').show();
    });

    this.addTextButtons.forEach((button) => {
      button.addEventListener('click', () => {
        throttleControl(Activities.AddElement, () => {
          TextElement.addElement({ width: 300, height: 300, backgroundColor: '#12002DF0' });
        });
      });
    });

    this.addDrawingButtons.forEach((button) => {
      button.addEventListener('click', () => {
        throttleControl(Activities.AddElement, () => {
          WhiteboardElement.addElement();
        });
      });
    });

    this.menuUploadButtons.forEach((button) => {
      button.addEventListener('click', showUploadDefault);
    });

    this.addMediaPlayerButtons.forEach((button) => {
      button.addEventListener('click', () => {
        throttleControl(Activities.AddElement, async () => {
          // don't create a media player if
          // 1) onboarding is in progress
          // AND
          // 2) one media player instance was already created
          if (!isRoomOnboardingInProgress() || !isMediaPLayerAddedDuringOnboarding) {
            if (isRoomOnboardingInProgress()) {
              isMediaPLayerAddedDuringOnboarding = true;
            }

            const { default: MediaPlayerElement } = await import('../../media-player/media-player');
            await MediaPlayerElement.addElement();
          }
        });
      });
    });

    this.openGifsButtons.forEach((button) => {
      button.addEventListener('click', () => {
        renderAddImageContainer({
          elementId: 'add-image-container',
          stickerMenuId: 'sticker-select',
          firebaseId: null,
          caller: gifPickerCallers.TOOLBAR,
        });
      });
    });

    this.openRoomDecorationButton.addEventListener('click', () => {
      reduxAdapter.dispatchAction(hideTip({ tip: TipsAndTricks.ADD_ROOM_DECORATION, source: 'open room decoration' }));
      renderRoomDecorationMenu();
    });

    this.openRoomDecorationSecondaryButton.addEventListener('click', (e) => {
      e.stopPropagation();

      UIkit.dropdown('#etc-tools-dropdown').hide();
      UIkit.dropdown('#room-decoration-select').show();
      reduxAdapter.dispatchAction(hideTip({ tip: TipsAndTricks.ADD_ROOM_DECORATION, source: 'open room decoration' }));
      renderRoomDecorationMenu();
    });

    let colorUpdateTimeout;
    const colorPicker = document.getElementById('pen-color-picker');
    if (colorPicker) {
      colorPicker.addEventListener('colorchange', (e) => {
        const penColor = e.detail.color;
        setPenColor(penColor);
        clearTimeout(colorUpdateTimeout);
        colorUpdateTimeout = setTimeout(() => {
          updateProfile({ penColor });
          track('Change Pen Color');
        }, 250);
      });
    }

    Array.from(this.querySelectorAll('.feedback-menu-button')).forEach((feedbackMenuButton) => {
      feedbackMenuButton.addEventListener('click', () => {
        resetFeedbackMenu();

        if (getNewUpdatesCount() > 0) {
          window.openFeedbackTab('feedback-whats-new');
        } else {
          window.openFeedbackTab('feedback-help');
        }

        document.getElementById('feedback-window').classList.remove('uk-dropdown-close');
        feedbackMenuButton.classList.remove('uk-dropdown-close');

        track("Clicked on Feedback/What's New icon");
      });
    });

    // if not mobile device and screenshare is supported
    if (!checkIsMobile()) {
      Array.from(this.querySelectorAll('.pen-color-indicator-button')).forEach((colorIndicator) => {
        colorIndicator.style.display = null;
      });

      if (checkIsElectron() || !!navigator.mediaDevices.getDisplayMedia) {
        UIkit.util.on('#screen-share-notice', 'hide', async () => {
          await ScreenshareElement.toggle();
        });
        Array.from(document.getElementsByClassName('screenshare-on-button')).forEach((item) => {
          item.addEventListener('click', () => {
            const browser = browserName();
            if (!hasSeenScreenshareNotice() && (browser === 'Chrome' || browser === 'Edge')) {
              UIkit.modal('#screen-share-notice').show();
            } else {
              ScreenshareElement.toggle();
            }
          });
        });
      }

      const screenshareNoticeHideButtons = document.querySelectorAll('.screen-share-notice-hide-button');
      screenshareNoticeHideButtons.forEach((element) => {
        element.addEventListener('click', async () => {
          await db
            .collection('userProfiles')
            .doc(firebase.auth().currentUser.uid)
            .set({ seenScreenshareNotice: true }, { merge: true });
          UIkit.modal('#screen-share-notice').hide();
        });
      });

      Array.from(document.getElementsByClassName('screenshare-off-button')).forEach((item) => {
        item.addEventListener('click', ScreenshareElement.toggle.bind(ScreenshareElement));
      });

      this.photoboothButton.addEventListener('click', () => startPhotoCountdown(this.photoboothButton));

      Array.from(document.getElementsByClassName('screenshare-buttons')).forEach((item) => {
        item.style.display = null;
      });
    }

    setupEmbeddedResourceUrlHandler();
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.onWindowResize);
  }
}

window.customElements.define('here-toolbar', Toolbar);

export default new Toolbar();
