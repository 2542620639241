import UIkit from 'uikit';
import log from '../log';
import { track } from './analytics-util';
import { CAMERA_MIC_ERROR } from '../constants/analytics-events/camera-events';

let cameraInitTimer = null;

export function stopCameraInitTimer() {
  clearTimeout(cameraInitTimer);
}

export async function showScreenshareError(error) {
  track('Screenshare Error', { name: error.name, message: error.message });
  log.error(`DeviceError: ${error}`);
  let customMessage = error.message;
  if (error.name === 'NotAllowedError')
    customMessage = `Your browser doesn't have permission to share the screen. Please allow your browser permission to share and try again.`;
  return UIkit.modal.alert(customMessage);
}

export function startCameraInitTimer() {
  clearTimeout(cameraInitTimer);
  cameraInitTimer = setTimeout(() => {
    log.warn('Camera init timeout');
    track('Camera Init Timeout', { videoServer: window.rtc.serverPrefix });
  }, 5000);
}

export function showDeviceError(error, mediaType = null) {
  let deviceName = 'camera';
  // FIXME: we accept !mediatype due to compatiblity with the previous signature.
  if (!mediaType || mediaType?.length === 0 || mediaType.includes('video')) {
    stopCameraInitTimer();
    document.querySelector('.camera-loading').style.display = 'none';
    document.querySelector('#camera-on-button').style.display = 'block';
    document.querySelector('#camera-off-button').style.display = 'none';
  }

  // FIXME: we accept !mediatype due to compatiblity with the previous signature.
  if (!mediaType || mediaType?.length === 0 || mediaType.includes('audio')) {
    if (mediaType.length === 1) {
      deviceName = 'microphone';
    }
    document.querySelector('#mic-on-button').style.display = 'block';
    document.querySelector('#mic-off-button').style.display = 'none';
    document.querySelector('#mic-levels').style.display = 'none';
  }

  if (error.name === 'NotAllowedError') {
    UIkit.modal.alert(`
      Here doesn't have permission to use your ${deviceName}.
      Please enable by clicking the ${deviceName} icon in your browser's location bar and try again.
      <img src='/images/misc/camera-permissions.gif' width='988px' height='372px'></img>
      `);
  } else if (error.name === 'NotFoundError') {
    UIkit.modal.alert(
      `Here can't find any ${deviceName} :( please enable your ${deviceName} if you have one, and reload to try again.`
    );
  } else if (error.name === 'NotReadableError') {
    // Probably in use by someone else
    UIkit.modal.alert(
      `Here can't access your ${deviceName}. If another application is using it, please close that app and reload to try again.`
    );
  } else {
    log.error(JSON.stringify(error));
  }
  track(CAMERA_MIC_ERROR, { kind: mediaType, name: error.name });
}
