import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useUserProfileData } from '../../hooks/useUserProfileData';
import { useBoardData } from '../../hooks/useBoardData';
import { BoardElementEnum } from './BoardElementEnum';
import TaggedUsername from './TaggedUsername';
import { messageTypes } from '../../../constants/chat-constants';
import { scrollToElement } from '../../../viewport';
import { OPEN_TAGGED_ELEMENT_LINK, MOVE_TO_TAGGED_ELEMENT } from '../../../constants/analytics-events/tagging-events';
import { track } from '../../../util/analytics-util';
import getElementTypeText from './getElementTypeText';
import { checkIsElectron } from '../../../util/platform-util';
import { jumpIntoRoom } from '../../../util/room-util';
import { loadRoom } from '../../../electron-support/electron-support';
import { selectRoomId } from '../../store/room/selectors';
import { DEFAULT_TAG_IMAGE_MAP } from './defaultTagImageMap';

type Props = {
  elementType: BoardElementEnum;
  entityId: string;
  imageUrl: string;
  receiverIds: string[];
  senderId: string;
  messageType: string;
  boardId: string;
  roomUrl?: string;
};

function TagMessage({ elementType, entityId, imageUrl, receiverIds, senderId, messageType, roomUrl, boardId }: Props) {
  const { userProfile: senderProfile } = useUserProfileData(senderId) as {
    userProfile: { displayName: string };
    loading: boolean;
  };

  const [board] = useBoardData(boardId) as { title: string }[];
  const { title } = board || {};

  const roomId = useSelector(selectRoomId);

  const onClick = async () => {
    if (messageType === messageTypes.ROOM_ELEMENT_TAG_DM) {
      await track(OPEN_TAGGED_ELEMENT_LINK, { elementType });

      if (roomId) {
        jumpIntoRoom({ roomId: boardId, title });
        return;
      }

      if (checkIsElectron()) {
        loadRoom(boardId, entityId);
        return;
      }

      window.location.href = roomUrl;
      return;
    }

    track(MOVE_TO_TAGGED_ELEMENT, { elementType });

    const element = document.getElementById(`element-${entityId}`);
    if (element) {
      scrollToElement(element);
    }
  };

  const onImageError = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.src = DEFAULT_TAG_IMAGE_MAP[elementType];
  };

  return (
    <TagMessageContainer>
      <TaggedText>
        {messageType === messageTypes.ROOM_ELEMENT_TAG_DM && title && <RoomName>{title}</RoomName>}
        {messageType === messageTypes.ROOM_ELEMENT_TAG_FEED ? `${senderProfile.displayName} tagged ` : 'Tagged you'}
        {messageType === messageTypes.ROOM_ELEMENT_TAG_FEED &&
          receiverIds.map((receiverId) => <TaggedUsername key={receiverId} receiverId={receiverId} />)}
        {getElementTypeText(elementType)}
      </TaggedText>
      {imageUrl && (
        <TaggedElementImage
          crossOrigin="anonymous"
          src={imageUrl}
          alt="tagged-element"
          onClick={onClick}
          onError={onImageError}
        />
      )}
    </TagMessageContainer>
  );
}

const RoomName = styled.div`
  color: #999999;
  font-weight: normal;
`;

const TagMessageContainer = styled.div`
  padding: 10px 0 10px 0;
`;

const TaggedText = styled.div`
  color: white;
  font-size: 0.75rem;
  margin: 5px 0;
  display: block;
  line-height: 17px;
  font-weight: 700;
`;

const TaggedElementImage = styled.img`
  width: 120px;
  border-radius: 10px;
  cursor: pointer;
`;

export default TagMessage;
