let audioContext;
let gainNode;
const oscillators = {};
const playNoteListeners = {};

function initializeAudioContext() {
  audioContext = new (window.AudioContext || window.webkitAudioContext)();

  gainNode = audioContext.createGain();
  gainNode.gain.value = 1 / 12;
  gainNode.connect(audioContext.destination);
}

export function addPlayNoteListener(elementId, frequency, listener) {
  if (!playNoteListeners[elementId]) {
    playNoteListeners[elementId] = {};
  }

  playNoteListeners[elementId][frequency] = listener;
}

export function removePlayNoteListener(elementId, frequency) {
  delete playNoteListeners[elementId][frequency];
}

export function createOscillator(frequency) {
  if (!audioContext) initializeAudioContext();
  const oscillator = audioContext.createOscillator();
  oscillator.connect(gainNode);
  oscillator.frequency.value = frequency;
  return oscillator;
}

export function playNote(elementId, frequency) {
  if (!oscillators[elementId]) {
    oscillators[elementId] = {};
  }

  if (!oscillators[elementId][frequency]) {
    oscillators[elementId][frequency] = createOscillator(frequency);
    oscillators[elementId][frequency].start(0);
    if (playNoteListeners[elementId]) {
      const l = playNoteListeners[elementId][frequency];
      if (l) {
        l('play');
      }
    }
  }
}

export function stopPlayingNote(elementId, frequency) {
  if (oscillators[elementId][frequency]) {
    oscillators[elementId][frequency].stop(0);
    delete oscillators[elementId][frequency];
    if (playNoteListeners[elementId]) {
      const l = playNoteListeners[elementId][frequency];
      if (l) {
        l('stop');
      }
    }
  }
}
