import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { RoomTopMembersData, addRoomTopMembersListener, removeRoomTopMembersListener } from './room-top-members-data';

// store
import { selectCurrentUserId } from '../../../store/users/selectors';
import { selectRoomId } from '../../../store/room/selectors';

// hooks
import { useBoardElementResize } from '../../../hooks/useBoardElementResize';

// components
import RoomTopMemberDataWrapper from './top-members/RoomTopMemberDataWrapper';

// constants
import {
  ROOM_TOP_MEMBERS_WIDGET_MIN_HEIGHT_PX,
  ROOM_TOP_MEMBERS_WIDGET_MIN_WIDTH_PX,
} from '../../../../constants/board-constants';

interface RoomTopMembersProps {
  elementId: string;
}

const RoomTopMembers = ({ elementId }: RoomTopMembersProps) => {
  const roomId = useSelector(selectRoomId);
  const currentUserId = useSelector(selectCurrentUserId);

  const [topMembersListFetched, setTopMembersListFetched] = useState(false);
  const [topMembersList, setTopMembersList] = useState([]);

  const topThreeMembers = topMembersList.slice(0, 3);
  const currentUserPosition = topMembersList.findIndex((member) => member.userId === currentUserId);
  const currentUserData = topMembersList[currentUserPosition];
  const currentUserListedInTopThree = topThreeMembers.some((member) => member.userId === currentUserId);

  const { widthScale } = useBoardElementResize(
    elementId,
    ROOM_TOP_MEMBERS_WIDGET_MIN_HEIGHT_PX,
    ROOM_TOP_MEMBERS_WIDGET_MIN_WIDTH_PX
  );

  useEffect(() => {
    const roomTopMembersDataListener = (data: RoomTopMembersData) => {
      const membersWithTimeInMinutes = data.members.map((member) => ({
        ...member,
        minutes: Math.round(member.time / 60),
      }));

      setTopMembersList(membersWithTimeInMinutes);
      setTopMembersListFetched(true);
    };

    addRoomTopMembersListener(roomId, roomTopMembersDataListener);

    return () => {
      removeRoomTopMembersListener(roomId, roomTopMembersDataListener);
    };
  }, [roomId]);

  return (
    <Container>
      <ZoomingContainer widthScale={widthScale}>
        <Title>Top Members</Title>

        {topMembersList.length ? <Subtitle>{topMembersList.length} total</Subtitle> : null}

        <Content hasPadding={!!topMembersList.length}>
          {topThreeMembers.length ? (
            <MembersListContainer>
              {topThreeMembers.map((member, index) => (
                <RoomTopMemberDataWrapper
                  key={member.userId}
                  userId={member.userId}
                  position={index + 1}
                  activeMinutes={member.minutes}
                />
              ))}

              {/* You */}
              {!currentUserListedInTopThree && currentUserData ? (
                <>
                  <CurrentMemberSeparator />
                  <RoomTopMemberDataWrapper
                    key={currentUserData.userId}
                    userId={currentUserData.userId}
                    position={currentUserPosition + 1}
                    activeMinutes={currentUserData.minutes}
                    isSelf
                  />
                </>
              ) : null}
            </MembersListContainer>
          ) : (
            <NoDataContainer>
              <BoredSkeletonGif src="/gif/bored-skeleton.gif" alt="bored skeleton" />
            </NoDataContainer>
          )}

          {topMembersListFetched && topMembersList.length ? (
            <FooterText>total time spent in last 30 days</FooterText>
          ) : null}

          {topMembersListFetched && !topMembersList.length ? (
            <WaitingForDataText>waiting for data...</WaitingForDataText>
          ) : null}
        </Content>
      </ZoomingContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 6px 5px 6px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary-foreground, white);
  font-family: var(--primary-font, Inter, sans-serif);
  background: var(--primary-background, #12002d);
  border-radius: 15px;
`;

const ZoomingContainer = styled.div<{ widthScale: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  zoom: ${({ widthScale }) => `${widthScale}`};
`;

const Title = styled.h3`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: bolder;
  color: var(--primary-foreground, white);
  font-family: var(--primary-font, Inter, sans-serif);
`;

const Subtitle = styled.h4`
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-foreground, white);
  font-family: var(--primary-font, Inter, sans-serif);
  opacity: 0.5;
`;

const Content = styled.div<{ hasPadding: boolean }>`
  margin-top: 15px;
  padding: ${({ hasPadding }) => (hasPadding ? '15px' : '0')};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-radius: 20px;
  background: var(--secondary-background, #6b1be3);
`;

const MembersListContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
`;

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoredSkeletonGif = styled.img`
  width: 160px;
`;

const CurrentMemberSeparator = styled.div`
  width: 1px;
  border-left: 2px dashed var(--primary-background, white);
  opacity: 0.5;
`;

const FooterText = styled.h5`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--secondary-foreground, white);
  opacity: 0.5;
`;

const WaitingForDataText = styled.h5`
  font-size: 14px;
  font-weight: bold;
  color: var(--secondary-foreground, white);
  text-align: center;
`;

export default RoomTopMembers;
