import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';

import { selectDefaultTheme, selectRoomId } from '../room/selectors';
import eventBus, { hereOsAddElementRequested } from '../../../event-bus';
import { elementClasses } from '../../board-elements/elements';
import { db } from '../../../firebase';
import log from '../../../log';
import { Theme } from '../../../definitions/theme';
import { createGroup } from '../groups/actions';
import { setActiveChatId, setGroupChat, createChatWithoutMessage, createChatSuccess } from '../messaging/reducer';
import { HERE_OS_PATH } from '../../../definitions/groups';
import {
  checkOnboardingList,
  completeOnboardingStep,
  finishOnboardingList,
  createNewChat,
  openChat,
  createDesktopRequested,
  createNewDMChat,
  setIsCreateChatOpened,
} from './actions';
import { selectMaxZIndex, selectOpenChats, selectOsOnboardingList } from './selectors';
import { currentUserProfileUpdateRequest } from '../users/store';
import { UserProfile } from '../../../definitions/user-profile';
import { OsOnboardingListRequiredStepIds } from '../../os/onboarding-list/steps';
import { selectCurrentUser, selectCurrentUserId, selectCurrentUserProfileTheme } from '../users/selectors';
import { selectNewRoom, selectNewRoomHasCustomBackground, selectNewRoomTheme } from '../new-room/selectors';
import { setUserProfileTheme } from '../../../api/user-profiles-api';
import { createRoomRequest } from '../room/sagas';
import { OS_PAGE_BOARD_TYPE } from '../../../constants/board-constants';
import { setIsNewRoomCreating } from '../new-room/actions';
import { selectActiveChatId, selectChatIdByGroupId } from '../messaging/selectors';
import { waitForAction } from '../helpers';
import { getGroupChatParams, track } from '../../../util/analytics-util';
import { CHAT_CREATE, ONBOARDING_STEP_COMPLETED } from '../../os/analytics';
import { goNextScreen } from '../signing-in/actions';
import { DEFAULT_THEME_COLORS, DEFAULT_THEME_FONT } from '../../groups-lobby/style-constants';
import { NEW_DEFAULT_OS_BOARD_BG } from '../../os/constants';
import { selectCreatedGroupId } from '../groups/selectors';

const newChatSources = {
  ONBOARDING: 'onboarding',
  LOBBY: 'lobby',
};

function* handleOpenChat({ payload: { chatId, center } }: { payload: { chatId: string; center?: number[] } }) {
  if (!chatId) {
    return;
  }

  const maxZIndex: number = yield select(selectMaxZIndex);
  const openChats: { [key: string]: { elementId: string } } = yield select(selectOpenChats);
  const boardId: string = yield select(selectRoomId);

  if (openChats[chatId]) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield call([db.doc(`boards/${boardId}/elements/${openChats[chatId].elementId}`), 'update'], {
        zIndex: maxZIndex + 1,
      });
    } catch (error) {
      yield call([log, 'error'], 'Error updating chat element z-index', error);
    }
  } else {
    yield call([eventBus, 'dispatch'], hereOsAddElementRequested, {
      class: elementClasses.CHAT_WINDOW,
      chatId,
      ...(center ? { center } : {}),
    });
  }
}

function* handleCreateNewDMChat({
  payload: { receiverId, theme, bgUrl },
}: {
  payload: { receiverId: string; theme: Theme; bgUrl: string };
}) {
  // make sure no other fields get passed to request, in case:
  const themeForRequest = {
    colors: {
      primaryBackground: theme.colors.primaryBackground,
      primaryForeground: theme.colors.primaryForeground,
      secondaryBackground: theme.colors.secondaryBackground,
      secondaryForeground: theme.colors.secondaryForeground,
    },
    isCustom: theme.isCustom,
    primaryFont: theme.primaryFont,
    skin: {
      ...(bgUrl ? { background: { original: bgUrl } } : {}),
      fonts: {
        title: theme.primaryFont,
      },
    },
  };
  yield put(createChatWithoutMessage({ receiverId, background: bgUrl, theme: themeForRequest }));

  yield all([call(waitForAction, createChatSuccess), call(waitForAction, setActiveChatId)]);

  const chatId: string = yield select(selectActiveChatId);

  yield call([eventBus, 'dispatch'], hereOsAddElementRequested, {
    class: elementClasses.CHAT_WINDOW,
    chatId,
  });

  // memberCount: 2 because DM chat always has two users
  yield call(track, CHAT_CREATE, getGroupChatParams({ chatId, source: newChatSources.LOBBY, memberCount: 2 }));

  yield put(setIsCreateChatOpened({ isOpened: false }));
}

function* handleCreateNewChat({
  payload: { name, userIds, theme, bgUrl, isOnboarding },
}: {
  payload: { name: string; userIds?: string[]; theme: Theme; bgUrl: string; isOnboarding?: boolean };
}) {
  // make sure no other fields get passed to request, in case:
  const themeForRequest = {
    colors: {
      primaryBackground: theme.colors.primaryBackground,
      primaryForeground: theme.colors.primaryForeground,
      secondaryBackground: theme.colors.secondaryBackground,
      secondaryForeground: theme.colors.secondaryForeground,
    },
    isCustom: theme.isCustom,
    primaryFont: theme.primaryFont,
    skin: {
      ...(bgUrl ? { background: { original: bgUrl } } : {}),
      fonts: {
        title: theme.primaryFont,
      },
    },
  };
  yield put(
    createGroup({
      name,
      userIds,
      customVibeBackgroundUrl: bgUrl,
      theme: themeForRequest,
      skipRoomCreation: true,
      isOnboarding,
    })
  );
  yield take(setGroupChat);

  const groupId: string = yield select(selectCreatedGroupId);
  const chatId: string = yield select(selectChatIdByGroupId, groupId);

  if (userIds?.length) {
    const source = isOnboarding ? newChatSources.ONBOARDING : newChatSources.LOBBY;
    yield call(track, CHAT_CREATE, getGroupChatParams({ chatId, source, memberCount: userIds?.length + 1 }));
  }

  if (isOnboarding) {
    yield put(setActiveChatId({ activeChatId: chatId }));
    yield put(goNextScreen({}));
  } else {
    yield call([eventBus, 'dispatch'], hereOsAddElementRequested, {
      class: elementClasses.CHAT_WINDOW,
      chatId,
    });
  }

  yield put(setIsCreateChatOpened({ isOpened: false }));
}

function* handleCheckOnboardingList() {
  const user: UserProfile = yield select(selectCurrentUser);
  // Room data might not be loaded yet, so checking the url instead of using dedicated selector
  const isInOs = window.location.pathname === `/${HERE_OS_PATH}` || window.location.pathname === `/${HERE_OS_PATH}/`;
  if (user.isOsOnboardingListFinished || user.osOnboardingList || !isInOs) {
    return;
  }

  const osOnboardingList: { [key in OsOnboardingListRequiredStepIds]?: boolean } = {};
  Object.values(OsOnboardingListRequiredStepIds).forEach((key: OsOnboardingListRequiredStepIds) => {
    osOnboardingList[key] = false;
  });

  yield put(currentUserProfileUpdateRequest({ patch: { osOnboardingList } }));
}

function* handleCompleteOnboardingStep({
  payload: { stepId },
}: {
  payload: { stepId: OsOnboardingListRequiredStepIds };
}) {
  yield put(currentUserProfileUpdateRequest({ patch: { osOnboardingList: { [stepId]: true } } }));
  // @ts-ignore
  const list = yield select(selectOsOnboardingList);
  const stepsCompleted = Object.keys(list).filter((step) => list[step]);
  yield call(track, ONBOARDING_STEP_COMPLETED, {
    step: stepId,
    stepsCompleted: stepsCompleted.join(', '),
    numCompleted: stepsCompleted.length,
  });
}

function* handleFinishOnboardingList() {
  yield put(currentUserProfileUpdateRequest({ patch: { isOsOnboardingListFinished: true } }));
}

function* handleCreateDesktopRequested() {
  yield put(setIsNewRoomCreating({ isNewRoomCreating: true }));
  const currentUserId: string = yield select(selectCurrentUserId);

  const hasCustomBackground: boolean = yield select(selectNewRoomHasCustomBackground);
  const selectedTheme: ReturnType<typeof selectNewRoomTheme> = yield select(selectNewRoomTheme);
  const profileTheme: ReturnType<typeof selectNewRoomTheme> = yield select(selectCurrentUserProfileTheme);

  let theme: ReturnType<typeof selectNewRoomTheme>;
  let isExistingUser = false; // checks if was existing user (pre- HereOS)
  if (hasCustomBackground) {
    theme = yield select(selectDefaultTheme);
  } else if (selectedTheme) {
    theme = selectedTheme;
  } else if (profileTheme) {
    // There wasn't vibe picker, so using existing profile theme
    theme = {
      primaryFont: profileTheme.primaryFont || DEFAULT_THEME_FONT,
      colors: {
        primaryBackground: profileTheme.colors?.primaryBackground || DEFAULT_THEME_COLORS.primaryBackground,
        primaryForeground: profileTheme.colors?.primaryForeground || DEFAULT_THEME_COLORS.primaryForeground,
        secondaryBackground: profileTheme.colors?.secondaryBackground || DEFAULT_THEME_COLORS.secondaryBackground,
        secondaryForeground: profileTheme.colors?.secondaryForeground || DEFAULT_THEME_COLORS.secondaryForeground,
      },
      // if isCustom field doesn't exist on profileTheme, we should set it to true:
      isCustom: typeof profileTheme.isCustom === 'undefined' ? true : profileTheme.isCustom,
    };
    isExistingUser = true;
  } else {
    // There are still users without a profile theme.
    // Themes might not be loaded from the API yet, so using hardcoded constants.
    theme = {
      primaryFont: DEFAULT_THEME_FONT,
      colors: DEFAULT_THEME_COLORS,
    };
    isExistingUser = true;
  }

  try {
    if (hasCustomBackground || selectedTheme) {
      // User picked a vibe, updating their profile theme with it
      yield call(setUserProfileTheme, currentUserId, theme);
    }

    const room: ReturnType<typeof selectNewRoom> = yield select(selectNewRoom);
    const requestBody = {
      name: 'HereOS page',
      type: OS_PAGE_BOARD_TYPE,
      allowAnonymous: false,
      uid: currentUserId,
      theme,
      background: room?.template?.background || NEW_DEFAULT_OS_BOARD_BG,
    };
    const response: { id: string } = yield call(createRoomRequest, requestBody);

    const urlParams = new URLSearchParams(window.location.search);
    const isSignUpThroughJoinLink = urlParams.get('groupId');

    yield put(
      currentUserProfileUpdateRequest({
        patch: {
          isOnboarding: false,
          osPageId: response.id,
          isOsOnboardingPending: false,
          isOsAnnouncePending: isExistingUser,
          isOsChatOnboardingPending: !isExistingUser && !isSignUpThroughJoinLink, // no chat onboarding if user is already joining a group chat
        },
      })
    );
    yield put(setIsNewRoomCreating({ isNewRoomCreating: false }));
  } catch (error) {
    yield call([log, 'error'], error);
  }
}

export default function* osSagas() {
  yield all([
    takeEvery(openChat, handleOpenChat),
    takeEvery(createNewChat, handleCreateNewChat),
    takeEvery(createNewDMChat, handleCreateNewDMChat),
    takeEvery(checkOnboardingList, handleCheckOnboardingList),
    takeEvery(completeOnboardingStep, handleCompleteOnboardingStep),
    takeEvery(finishOnboardingList, handleFinishOnboardingList),
    takeEvery(createDesktopRequested, handleCreateDesktopRequested),
  ]);
}
