import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import { BoardElementControllerContext } from '../../common/contexts';
import RemixAIMenu from './RemixAIMenu';

const RemixAIButton = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { setIsTemporaryOnTop, setAreControlButtonsAlwaysVisible } = useContext(BoardElementControllerContext);
  useEffect(() => {
    setAreControlButtonsAlwaysVisible(isOpened);
    setIsTemporaryOnTop(isOpened);
  }, [isOpened, setAreControlButtonsAlwaysVisible, setIsTemporaryOnTop]);

  return (
    <Popover.Root open={isOpened} onOpenChange={setIsOpened}>
      <Popover.Trigger asChild>
        <Button>🤖</Button>
      </Popover.Trigger>
      <Content sideOffset={10} side="right" onInteractOutside={useCallback((e) => e.preventDefault(), [])}>
        <RemixAIMenu onClose={useCallback(() => setIsOpened(false), [])} />
      </Content>
    </Popover.Root>
  );
};

export default RemixAIButton;

const Button = styled.div`
  height: 30px;
  border-radius: 15px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #12002d;
  font-size: 12px;
  padding: 4px 8px;
  user-select: none;
`;

const Content = styled(Popover.Content)`
  &:focus-visible {
    outline: none;
  }
`;
