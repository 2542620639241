import { authedApiInstance } from '../firebase';

export const fetchEmotes = () =>
  authedApiInstance
    .get('/emotes')
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const fetchStickerPacks = () =>
  authedApiInstance
    .get('/emotes/stickerPacks')
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const addStickerPackEmote = ({ url, stickerPack }: { url: string; stickerPack: string }) =>
  authedApiInstance
    .post('/emotes', { url, type: 'library', stickerPack })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const addCustomEmote = ({ url, fileName }: { url: string; fileName: string }) =>
  authedApiInstance
    .post('/emotes', { url, type: 'custom', fileName })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const removeEmote = (url: string) =>
  authedApiInstance
    .post('/emotes/remove', { url })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);
