import { eventChannel } from 'redux-saga';
import { all, takeEvery, put, join, select, call } from 'redux-saga/effects';
import { updateUsersFromArray, currentUserSwitched } from '../users/store';
import { selectCurrentUserId } from '../users/selectors';
import {
  getFriendsList,
  removeFriend,
  subscribeToIncomingFriendRequests,
  subscribeToOutgoingFriendRequests,
} from './api';
import { friendsToIds, friendsToUsers } from './normalizers';

import * as store from './store';
import { addAnimation } from '../animations/store';
import { ANIMATION_NAMES } from '../animations/constants';
import { acceptedNotificationsSelector } from './selectors';
import log from '../../../log';
import { setEventsFriendCount, track } from '../../../util/analytics-util';
import { selectDMChatByReceiverId } from '../messaging/selectors';
import { removeChat, setActiveChatId, setActiveReceiverId } from '../messaging/reducer';

async function fetchAsync(apiCall) {
  const response = await apiCall();

  // For fetch friends:
  if (response && response.friends) {
    return response.friends;
  }
  // For unfriend:
  if (response && response.success) {
    return response.success;
  }
  throw new Error('error');
}

function* checkInvalidFriends(friends, validFriends) {
  const invalidFriendsCount = friends.length - validFriends.length;
  const invalidFriends = friends.filter(({ profile }) => !profile.userId);
  if (invalidFriendsCount) {
    const myId = yield select(selectCurrentUserId);
    yield call([log, 'error'], 'invalid friends', invalidFriendsCount);
    yield call(track, 'invalid friends', { userId: myId, count: invalidFriendsCount, invalid: invalidFriends });
  }
}

function* unfriend({ payload: userId }) {
  try {
    yield put(setActiveChatId({ activeChatId: null }));
    yield put(setActiveReceiverId({ activeReceiverId: null }));

    yield call(fetchAsync, () => removeFriend(userId));

    // if there is a chat with user you are unfriending, then remove chat
    const chatsWithYouAndUser = yield select((state) => selectDMChatByReceiverId(state, userId));
    const chatId = chatsWithYouAndUser.length >= 1 ? chatsWithYouAndUser[0].id : null;
    if (chatId) yield put(removeChat({ chatId }));

    yield call(fetchFriends);
  } catch (e) {
    yield put(store.friendsError({ error: 'There was an error. Please try again.' }));
  }
}

function* fetchFriends() {
  try {
    const friends = yield call(fetchAsync, getFriendsList);
    const validFriends = friends.filter(({ profile }) => profile.userId);
    yield call(checkInvalidFriends, friends, validFriends);

    // set friend count for our events tracking
    setEventsFriendCount(validFriends.length);

    yield put(updateUsersFromArray({ users: friendsToUsers(validFriends) }));
    yield put(store.friendsSuccess({ friendsIds: friendsToIds(validFriends) }));
  } catch (e) {
    yield put(store.friendsError({ error: 'There was an error. Please try again.' }));
  }
}

function* watchOutgoingFriendRequests({ payload: { id: currentUserId } }) {
  const channel = eventChannel((emitter) => {
    const listener = (notifications) => notifications && emitter(notifications);
    return subscribeToOutgoingFriendRequests(currentUserId, listener);
  });

  try {
    const task = yield takeEvery(channel, function* onFriendRequestsUpdated(requests) {
      const oldNotification = yield select(acceptedNotificationsSelector);
      yield put(store.outgoingFriendRequestsUpdated({ requests }));
      const newNotifications = yield select(acceptedNotificationsSelector);
      if (newNotifications.length > oldNotification.length) {
        yield put(
          addAnimation({
            name: ANIMATION_NAMES.confettiBurst,
            id: `friendship-accepted-${newNotifications[newNotifications.length - 1]}`,
          })
        );
      }

      yield put(store.fetchFriends());
    });
    yield join(task);
  } finally {
    channel.close();
  }
}

function* watchIncomingFriendRequests({ payload: { id: currentUserId } }) {
  const channel = eventChannel((emitter) => {
    const listener = (notifications) => notifications && emitter(notifications);
    return subscribeToIncomingFriendRequests(currentUserId, listener);
  });

  try {
    const task = yield takeEvery(channel, function* onFriendRequestsUpdated(requests) {
      yield put(store.incomingFriendRequestsUpdated({ requests }));
      yield put(store.fetchFriends());
    });
    yield join(task);
  } finally {
    channel.close();
  }
}

function* handleUserSwitched() {
  yield put(store.fetchFriends());
}

export default function* friendsSaga() {
  yield all([
    takeEvery(store.fetchFriends, fetchFriends),
    takeEvery(store.unfriend, unfriend),
    takeEvery(currentUserSwitched, watchOutgoingFriendRequests),
    takeEvery(currentUserSwitched, watchIncomingFriendRequests),
    takeEvery(currentUserSwitched, handleUserSwitched),
  ]);
}
