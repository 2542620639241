import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DotsNavigation = ({ currentIndex, totalCount, onIndexChange, color }) => (
  <CarouselDotsContainer>
    {[...Array(totalCount)].map((num, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <CarouselDot key={index} isActive={currentIndex === index} onClick={() => onIndexChange(index)} color={color} />
    ))}
  </CarouselDotsContainer>
);

export default DotsNavigation;

DotsNavigation.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onIndexChange: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

const CarouselDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CarouselDot = styled.div`
  cursor: pointer;
  background: ${({ color }) => color};
  border-radius: 20px;
  opacity: 0.3;
  margin: 0 8px;

  width: 9px;
  height: 9px;

  ${({ isActive }) =>
    isActive &&
    `
    height: 20px;
    opacity: 0.9;
  `}

  transition: all 0.15s ease-out;

  &:hover {
    height: 20px;
  }
`;
