import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsNewRoomCreating } from '../../store/new-room/selectors';
import { createDesktopRequested } from '../../store/os/actions';
import { getOsLinkBasedOnGroup } from '../../../util/groups-util';
import SignInBackground from '../SignInBackground';
import { Logo } from '../../vibe-picker/shared-styles';
import { ONBOARDING_STEP_VIEW } from '../../../constants/analytics-events/onboarding-events';
import { track } from '../../../util/analytics-util';
import reduxAdapter from '../../store/redux-adapter';

const animationDurationMs = 3700;

const DesktopCreationScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createDesktopRequested());
  }, [dispatch]);

  // Redirect after animation is played, but not before the desktop is created
  const isNewRoomCreating = useSelector(selectIsNewRoomCreating);
  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false);
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);
  useEffect(() => {
    if (isAnimationLoaded) {
      setTimeout(() => setIsAnimationFinished(true), animationDurationMs);
    }
  }, [isAnimationLoaded]);

  useEffect(() => {
    if (!isNewRoomCreating && isAnimationFinished) {
      window.location.href = getOsLinkBasedOnGroup();
    }
  }, [isAnimationFinished, isNewRoomCreating]);

  useEffect(() => {
    if (reduxAdapter.latestState?.newRoom?.newRoom?.template?.id) {
      track(ONBOARDING_STEP_VIEW, {
        step: 'Vibe Chosen',
        vibeId: reduxAdapter.latestState?.newRoom?.newRoom?.template?.id,
      });
    }
  }, []);

  return (
    <Container>
      <SignInBackground />
      <Logo />
      <Title>Creating your desktop...</Title>
      {isAnimationFinished ? (
        <AnimationEndFrame as="div" isLoaded />
      ) : (
        <Animation
          onLoad={() => setIsAnimationLoaded(true)}
          isLoaded={isAnimationLoaded}
          src="https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/homepage%2FHER_Lappy_creating_960.gif?alt=media&token=ef88eb3c-43c1-4274-8ea4-37ca0c1d9ea4"
          alt="Rotating laptop animated"
        />
      )}
    </Container>
  );
};

export default DesktopCreationScreen;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  background: rgba(255, 255, 255, 0.6);
  color: #6b1be3;
  padding: 10px 40px;
  z-index: 2;
  border-radius: 8px;
  font-weight: bold;
`;

const Animation = styled.img<{ isLoaded: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  ${({ isLoaded }) => (isLoaded ? '' : 'display: none;')}
`;

const AnimationEndFrame = styled(Animation)`
  background: white;
`;
