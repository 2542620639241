import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import lottie from 'lottie-web';

import animationData from '../../../assets/lottie/user-profile-effect.json';
import { useUserProfileData } from '../hooks/useUserProfileData';

const UserProfileEffect = ({ userId }) => {
  const container = useRef(null);

  const { userProfile } = useUserProfileData(userId);

  useEffect(() => {
    const jsonObj = animationData;
    jsonObj.assets[0].p = userProfile.effectEmoteUrl;

    const animation = lottie.loadAnimation({
      animationData: jsonObj,
      container: container.current,
      loop: true,
      autoplay: true,
    });

    return () => animation.destroy();
  }, [userProfile.effectEmoteUrl]);

  return <Container ref={container} />;
};

UserProfileEffect.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserProfileEffect;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
