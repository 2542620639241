import React from 'react';
import styled from 'styled-components';
import { School } from '../../../definitions/schools';

interface Props {
  school: School;
  onSchoolClick: React.Dispatch<React.SetStateAction<School>>;
}

const SchoolListItem: React.FC<Props> = ({ school, onSchoolClick }) => (
  <SchoolItem onClick={() => onSchoolClick(school)}>
    <HoverBackground>
      <SchoolName>{school.name}</SchoolName>
      <Location>{`${school.city}, ${school.state}`}</Location>
    </HoverBackground>
  </SchoolItem>
);
export default SchoolListItem;

const HoverBackground = styled.div`
  border-radius: 7px;
  width: 456px;
  margin-left: -13px;
  padding: 10px 13px;
  cursor: pointer;
`;

const SchoolItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    ${HoverBackground} {
      background: rgba(107, 27, 227, 0.1);
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

const SchoolName = styled.div`
  color: #12002d;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 4px;
`;

const Location = styled.div`
  color: #12002d;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.7;
`;
