import { htmlToElement } from '../../util';
import firebase, { db } from '../../firebase';
import log from '../../log';
import { addSystemMessage } from '../../message-util';
import bus, { roomSettingsUpdated } from '../../event-bus';
import { CHANGE_BACKGROUND_SPACE } from '../../constants/analytics-events/background-event';
import { track } from '../../util/analytics-util';

import template from './spaces-select.html';
import './spaces-select.less';

const storage = firebase.storage();
let imagesCache;
let desktopImagesCache;

export default class SpacesSelect extends HTMLElement {
  async connectedCallback() {
    this.innerHTML = template;

    this.images = this.querySelector('.spaces-gifs');

    await Promise.resolve(); // // Ensures the microtask queue is flushed so that isOsDesktop is safely set

    const isOsDesktop = this.getAttribute('isOsDesktop');
    try {
      let gifs;

      if (isOsDesktop) {
        gifs = await this.retrieveOsGifs();
      } else {
        gifs = await this.retrieveGifs();
      }

      const mappedGifs = gifs.map((gif) => {
        const element = htmlToElement(`
            <div class="image-container">
              <img src=${gif.url} />
            </div>
            `);
        element.addEventListener('click', () => {
          this.onSpacesSelect(gif);
        });
        return element;
      });
      this.images.append(...mappedGifs);
    } catch (error) {
      log.error('there was an error!', error);
    }

    const customTracking = this.getAttribute('customTracking');
    if (customTracking) {
      this.customTracking = JSON.parse(customTracking);
    }
  }

  async getImagesFromStorage(storageRef) {
    try {
      const imagesRef = await storageRef.listAll();
      return await Promise.all(
        imagesRef.items.map(async (item) => ({
          id: item.name,
          url: await item.getDownloadURL(),
        }))
      );
    } catch (err) {
      log.error(err);
      return [];
    }
  }

  async retrieveGifs() {
    if (imagesCache) return imagesCache;

    const storageRef = storage.ref(`furniture/background-spaces`);
    const images = await this.getImagesFromStorage(storageRef);
    imagesCache = images;
    return images || null;
  }

  async retrieveOsGifs() {
    if (desktopImagesCache) return desktopImagesCache;

    const storageRef = storage.ref('furniture/os-desktop-background-spaces');
    const images = await this.getImagesFromStorage(storageRef);
    desktopImagesCache = images;
    return images || null;
  }

  async onSpacesSelect(gif) {
    addSystemMessage('changed the background');
    if (this.customTracking?.spaces) {
      const [event, params] = this.customTracking.spaces;
      track(event, { ...params, background: gif.url });
    } else {
      track(CHANGE_BACKGROUND_SPACE);
    }
    db.collection('boards')
      .doc(this.getAttribute('boardId') || window.currentBoardId)
      .update({ background: gif.url, backgroundColor: null });
    bus.dispatch(roomSettingsUpdated);
  }
}

window.customElements.define('here-spaces-select', SpacesSelect);
