import UIkit from 'uikit';
import log from '../../log';

import template from './camera-lite-mode.html';

import './camera-lite-mode.less';

class CameraLiteMode extends HTMLElement {
  showingElement = null;

  connectedCallback() {
    this.innerHTML = template;
  }

  disconnectedCallback() {}

  askToLowerQuality() {
    return this.showDialog(document.querySelector('#camera-lite-mode-ask-lower-bandwidth')).promise;
  }

  askToTestHigherQuality() {
    const { actionButton, promise } = this.showDialog(document.querySelector('#camera-lite-mode-ask-turn-on-video'));
    if (actionButton) {
      actionButton.innerHTML = 'Back to Normal';
    }
    return promise;
  }

  askToShutdownVideo() {
    return this.showDialog(document.querySelector('#camera-lite-mode-ask-turn-off-video')).promise;
  }

  askTurnVideoBackOn() {
    const { actionButton, promise } = this.showDialog(document.querySelector('#camera-lite-mode-ask-turn-on-video'));
    if (actionButton) {
      actionButton.innerHTML = 'Enable Video';
    }
    return promise;
  }

  toggleDetails() {
    const body = this.showingElement.querySelector('.uk-modal-body');
    body.style.display = body.style.display === 'none' ? 'block' : 'none';

    if (body.style.display === 'block') {
      this.showingElement.classList.add('details');
    } else {
      this.showingElement.classList.remove('details');
    }
  }

  showDialog(element) {
    if (this.showingElement) {
      const promise = new Promise((resolve, reject) => reject);
      return { modal: null, actionButton: null, promise };
    }

    this.showingElement = element;

    const modal = UIkit.modal(element);
    const actionButton = element.querySelector('.uk-button-primary');
    const closeButton = element.querySelector('.uk-close');
    const questionButton = element.querySelector('img.question');

    const promise = new Promise((resolve) => {
      const actionFunction = () => {
        resolve(true);
        modal.hide();
      };
      const closeFunction = () => {
        resolve(false);
        modal.hide();
      };

      actionButton.addEventListener('click', actionFunction);
      closeButton.addEventListener('click', closeFunction);
      questionButton.addEventListener('click', this.toggleDetails.bind(this));

      UIkit.util.on(element, 'hide', () => {
        this.showingElement = null;
        actionButton.removeEventListener('click', actionFunction);
        closeButton.removeEventListener('click', closeFunction);
        questionButton.removeEventListener('click', this.toggleDetails.bind(this));
        log.log('Dialog Hidden');
      });

      const modalBody = element.querySelector('.uk-modal-body');
      modalBody.innerHTML = document.getElementById('camera-lite-mode-how-to-improve-things').innerHTML;

      modal.show();
    });

    return { modal, actionButton, promise };
  }
}

window.customElements.define('here-camera-lite-mode', CameraLiteMode);
