import firebase, { db } from './firebase';
import log from './log';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import TextElement from './notes';
import AppElement from './app-element';
import { htmlToElement, sanitize } from './util';
import BoardElement from './board-element';

import '../styles/app-admin.less';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

const sampleCode = `
// Here's some sample code to get started
// Type "/add codedocs" to add documentation to the room
// Or check out http://tiny.cc/p48btz - Happy hacking!

// Callback after the room is loaded for a user
function onLoad() {
  addElements([button('Press Me', onPressed), pressCountText()]);
}

// Callback upon data being changed
function onDataUpdated(newData) {
  replaceElements([pressCountText()]);
}

function pressCountText() {
  return text({content: \`
    Press count: \${appData.pressCount || '0'},
    most recent by \${appData.pressUser || 'nobody'}\`,
              id: 'pressCounter'});
}

function onPressed() {
  const currentCount = appData.pressCount || 0;
  updateData({pressCount: currentCount + 1,
              pressUser: context.currentUser.name});
}
`;

export default class AppAdminElement extends BoardElement {
  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    this.updateData(elementDoc.data);
    return true;
  }

  async updateData(data) {
    this.appId = data.appId;
    this.editorId = data.editorId;

    const element = document.getElementById(`element-${this.elementId}`);
    if (data.appId) {
      const appDoc = await db.collection('hereApps').doc(data.appId).get();
      if (appDoc.exists) {
        const appData = appDoc.data();
        element.querySelector('.app-title').value = sanitize(appData.title);
        element.querySelector('.publish-info').innerHTML = appData.updatedAt;
      } else {
        log.error(`Unknown app with id ${data.appId}`);
      }
    }
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  async setup(elementId, elementDoc) {
    this.updateData(elementDoc.data());
  }

  // Required method
  getElement(elementDoc) {
    const container = htmlToElement(`
      <div class="app-admin-contents">
        <input class="app-title" placeholder="App Name"></input>
        <div class="publish-info">New app</div>
        <button class="admin-button start-editing">Start Editing</button>
        <button class="admin-button publish">Publish</button>
        <div class="message"></div>
      </div>
    `);

    container.querySelector('.publish').addEventListener('click', this.publishApp.bind(this));
    container.querySelector('.start-editing').addEventListener('click', this.editApp.bind(this));

    return wrapElement(container, elementDoc, {
      classes: ['app-admin-element'],
    });
  }

  async editApp() {
    let appCode = null;
    // Either use the published app source or start with a skeleton
    if (this.appId) {
      const app = await db.collection('hereApps').doc(this.appId).get();
      appCode = app.data().code;
    }
    const codeEditorRef = await TextElement.addCodeElement(appCode || sampleCode);
    this.editorId = codeEditorRef.id;
    await AppElement.addElement({ editorId: codeEditorRef.id });
    this.editorId = codeEditorRef.id;
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .doc(this.elementId)
      .update({ editorId: this.editorId });
  }

  async publishApp() {
    log.debug('Publishing...');
    const element = document.getElementById(`element-${this.elementId}`);

    if (!this.editorId) {
      const message = element.querySelector('.message');
      message.innerHTML = "Can't publish, edit the app first";
      return;
    }
    const editorHandler = window.elementHandlers[this.editorId];
    if (!editorHandler) {
      log.error(`No code handler for note ${this.editorId}`);
      return;
    }

    const title = element.querySelector('.app-title').value.trim();
    if (!title || title.length === 0) {
      const message = element.querySelector('.message');
      message.innerHTML = 'Please name your beautiful creation before publishing!';
      return;
    }

    const code = editorHandler.rawContent;
    const appData = {
      title,
      code,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    if (this.appId) {
      await db.collection('hereApps').doc(this.appId).update(appData);
      log.debug(`Updated existing app ${this.appId}`);
    } else {
      appData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      appData.createdBy = firebase.auth().currentUser.uid;
      const appRef = await db.collection('hereApps').add(appData);
      db.collection('boards')
        .doc(window.currentBoardId)
        .collection('elements')
        .doc(this.elementId)
        .update({ appId: appRef.id });
      this.appId = appRef.id;
      log.debug(`Created new app ${this.appId}`);
    }

    const message = element.querySelector('.message');
    message.innerHTML = `Published! App ID is ${this.appId}`;
  }

  // Statics

  // @param noteRef the reference to the note containing code
  static async addElement(appId) {
    const ref = await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'AppAdminElement',
        center: screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2 - 200),
        creator: firebase.auth().currentUser.uid,
        size: [300, 170],
        appId: appId || null,
        zIndex: window.getFrontZIndex(),
      });
    track(ADD_ELEMENT, { element: ELEMENT_TYPES.APP_ADMIN, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });

    return ref;
  }
}

AppAdminElement.elementType = 'AppAdminElement';
