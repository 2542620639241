import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../store/Wrapper';
import AppAlerts from '.';

export function renderAppAlerts() {
  ReactDOM.render(
    <WithRoomStore>
      <AppAlerts />
    </WithRoomStore>,
    document.getElementById('app-alerts')
  );
}
