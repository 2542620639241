import { all, call, debounce, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects';

import eventBus, { openVibePickerRequested, unmountSignInModalRequested } from '../../../event-bus';
import firebase, { db } from '../../../firebase';
import log from '../../../log';
import { track } from '../../../util/analytics-util';
import { createUniqueUsername } from '../../../util/profile-util';
import { getFirstName, isUsernameValid } from '../../../util/user-util';
import {
  AuthTypes,
  EmailPasswordAuthResults,
  FetchSignInMethodsForEmailResults,
  FLOW_FINISHED,
  flows,
  Screens,
} from '../../sign-in-flow/constants.ts';
import { flowsMap } from '../../sign-in-flow/flows-map';
import { getSuggestedFriendsV2 } from '../friends/api';
import { FRIENDS_SUGGESTED } from '../../../constants/analytics-events/friend-events';

import {
  // navigation
  startFlow,
  setScreenId,
  setLastInteractedScreenId,
  goNextScreen,
  goPrevScreen,

  // profile
  setProfileId,
  setEmail,
  setDisplayName,
  setGeneratedUsername,
  setUsernameWithValidation,
  setPhoneNumber,
  setVerificationId,

  // screens
  startProviderAuth,
  submitInputEmailScreen,
  sendCode,
  verifyConfirmationCode,
  setInputEmailScreenError,
  setSignInWithEmailAndPasswordError,
  trySignInWithEmailAndPassword,
  tryCreateAccountWithEmail,
  setCreateAccountEmailError,
  setCreateAccountPasswordError,
  setIsGeneratingUsername,
  setUsernameValidationResult,
  submitUsernameScreen,
  submitUsernameAndDisplayNameScreen,
  setFriendSuggestions,
  submitFriendSuggestions,
  setResetPasswordError,
  resetPassword,
  resetErrors,
  setFlowId,
  schoolSelectionComplete,
} from './actions.ts';
import {
  selectProfileId,
  selectUsername,
  selectDisplayName,
  selectCurrentScreenId,
  selectLastInteractedScreenId,
  selectFlowId,
} from './selectors.ts';
import { getRoomId } from '../../../util';
import { checkIsGroupJoinLink, checkIsGroupLink, getGroupIdFromUrl } from '../../../util/groups-util';
import { setIsOnboarding } from '../new-room/actions.ts';
import { NEW_USER_JOIN_GROUP, NEW_USER_JOIN_ROOM } from '../../../constants/analytics-events/onboarding-events.ts';
import { selectCurrentRoomId, selectRoomGroupId } from '../room/selectors';
import { roomDataUpdated } from '../room/store';
import { checkIfLooksLikeRoomAlias, checkIfLooksLikeRoomId } from '../../../util/room-util';
import { fetchSchools } from '../../../api/schools-api.ts';
import { ResultTypes } from '../../../definitions/schools.ts';

const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: 'select_account' });

const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');
appleAuthProvider.addScope('email');

let currentFlow = null;
let currentFlowScreen = null;

const getFlowFromMap = (flowId) => flowsMap[flowId];

const setFlow = (flow) => {
  currentFlow = flow;
};

const getNextScreen = (screenData) => currentFlow[currentFlowScreen.getNextScreen(screenData)];

const getPrevScreen = (screenData) => currentFlow[currentFlowScreen.getPrevScreen(screenData)];

const setFlowScreen = (screen) => {
  currentFlowScreen = screen;
};

// handling navigation between screens

function* handleStartFlow({ payload: { flowId, screenId } }) {
  const flow = yield call(getFlowFromMap, flowId);
  yield call(setFlow, flow);
  yield put(setFlowId({ flowId }));

  const flowFirstScreen = yield call(flow.getFirstScreen.bind(flow, screenId));
  yield call(setFlowScreen, flowFirstScreen);
  yield put(setScreenId({ screenId: flowFirstScreen.id }));
}

function* handleGoNextScreen({ payload: { screenData } = {} }) {
  const currentScreenId = yield select(selectCurrentScreenId);
  const lastInteractedScreenId = yield select(selectLastInteractedScreenId);
  if (currentScreenId === lastInteractedScreenId) {
    return;
  }

  yield put(setLastInteractedScreenId({ screenId: currentScreenId }));

  const nextScreen = yield call(getNextScreen, screenData);
  yield call(setFlowScreen, nextScreen);
  yield put(setScreenId({ screenId: nextScreen.id }));
}

function* handleGoPrevScreen({ payload: { screenData } = {} }) {
  const currentScreenId = yield select(selectCurrentScreenId);
  const lastInteractedScreenId = yield select(selectLastInteractedScreenId);
  if (currentScreenId === lastInteractedScreenId) {
    return;
  }

  yield put(setLastInteractedScreenId({ screenId: currentScreenId }));

  const prevScreen = yield call(getPrevScreen, screenData);
  yield call(setFlowScreen, prevScreen);
  yield put(setScreenId({ screenId: prevScreen.id }));
}

/**
 *
 * Show onboarding after signing in only if:
 * 1) User is not a member of any room
 * 2) User tries to sign in from the home page (not deep-linking)
 *
 * @param {string} userId
 * @returns {boolean}
 */
const isOnboardingRequired = async (userId) => {
  try {
    const isRoomDeepLink = getRoomId();
    const isGroupJoinLink = checkIsGroupJoinLink();
    const isGroupLink = checkIsGroupLink();

    const userProfileRef = await db.doc(`userProfiles/${userId}`).get();
    const userProfileData = userProfileRef.data();

    return userProfileData.isOnboarding === true && !isRoomDeepLink && !isGroupJoinLink && !isGroupLink;
  } catch (err) {
    log.error(err);
    return false;
  }
};

const startOnboarding = () => {
  eventBus.dispatch(openVibePickerRequested);
};

// handling screens logic
function* handleSetScreenId({ payload: { screenId } }) {
  yield put(resetErrors());

  switch (screenId) {
    case Screens.CREATE_USERNAME_AND_NAME:
    case Screens.CREATE_USERNAME: {
      const displayName = yield select(selectDisplayName);
      const username = yield call(createUniqueUsername, displayName);
      yield put(setIsGeneratingUsername({ isGenerating: true }));
      yield put(setGeneratedUsername({ username }));
      break;
    }

    case FLOW_FINISHED: {
      const profileId = yield select(selectProfileId);

      // TODO: originally I expected the code to got here only when user is signing up -- if so profileId is always defined in previous screens
      // but after we've added the room onboarding flow, profileId is an empty string as we don't set it up -> we make a 'memberships//boards' query which is invalid
      // we need to either revise this code or make sure that profileId is always defined
      if (profileId) {
        const needShowOnboarding = yield call(isOnboardingRequired, profileId);
        if (needShowOnboarding) {
          yield put(setIsOnboarding({ isOnboarding: true }));
          yield call(startOnboarding);
          return;
        }
      }

      yield call(eventBus.dispatch.bind(eventBus), unmountSignInModalRequested);

      break;
    }

    default: {
      // do nothing by default
    }
  }
}

const runProviderAuth = async (authProvider) => {
  const result = await auth.signInWithPopup(authProvider);
  const oldUserProfile = await db.doc(`userProfiles/${result.user.uid}`).get();
  const oldUserProfileData = { ...oldUserProfile.data(), id: oldUserProfile.id };

  const updateData = {};

  if (!oldUserProfileData.displayName) {
    updateData.displayName = result.user.displayName;
    oldUserProfileData.displayName = result.user.displayName;
  }

  if (result.additionalUserInfo.isNewUser && oldUserProfileData.isOnboarding !== false) {
    updateData.isOnboarding = result.additionalUserInfo.isNewUser;
  }

  if (Object.keys(updateData).length > 0) {
    await db.doc(`userProfiles/${result.user.uid}`).set(updateData, { merge: true });
  }

  return { result, oldUserProfileData };
};

function* handleStartProviderAuth({ payload: { authType } }) {
  try {
    let authResult = null;
    let profileData = null;
    if (authType === AuthTypes.GMAIL) {
      const { result, oldUserProfileData } = yield call(runProviderAuth, googleAuthProvider);
      authResult = result;
      profileData = oldUserProfileData;
    } else if (authType === AuthTypes.APPLE) {
      const { result, oldUserProfileData } = yield call(runProviderAuth, appleAuthProvider);
      authResult = result;
      profileData = oldUserProfileData;
    } else {
      throw new Error('Unknown auth type');
    }

    yield put(setProfileId({ id: authResult.user.uid }));
    yield put(setEmail({ email: authResult.user.email }));
    yield put(setDisplayName({ displayName: authResult.user.displayName }));
    yield put(goNextScreen({ screenData: { authType, hasUsername: !!profileData.username } }));
    if (!profileData.username) {
      yield call(sendFirstTimeUserAnalytics, getFirstName(authResult.user.displayName));
    }
  } catch (err) {
    yield call(log.error, err);
  }
}

const checkInputEmailScreen = (email) =>
  auth.fetchSignInMethodsForEmail(email).then((loginMethods) => {
    switch (true) {
      case loginMethods.includes('google.com'): {
        return FetchSignInMethodsForEmailResults.ALREADY_HAS_GOOGLE_ACCOUNT;
      }

      case loginMethods.includes('password'): {
        return FetchSignInMethodsForEmailResults.ALREADY_HAS_EMAIL_ACCOUNT;
      }

      case loginMethods.length === 0: {
        return FetchSignInMethodsForEmailResults.NO_ACCOUNT;
      }

      default: {
        throw new Error(`Unknown login methods case, ${loginMethods}`);
      }
    }
  });

function* handleSubmitInputEmailScreen({ payload: { email } }) {
  try {
    const result = yield call(checkInputEmailScreen, email);
    switch (result) {
      case FetchSignInMethodsForEmailResults.ALREADY_HAS_GOOGLE_ACCOUNT: {
        yield put(setInputEmailScreenError({ error: 'You already have an account. Sign in with Google to continue.' }));
        break;
      }

      case FetchSignInMethodsForEmailResults.ALREADY_HAS_EMAIL_ACCOUNT: {
        yield put(setEmail({ email }));
        yield put(goNextScreen({ screenData: { profileAlreadyExists: true } }));
        break;
      }

      case FetchSignInMethodsForEmailResults.NO_ACCOUNT: {
        yield put(setEmail({ email }));
        yield put(goNextScreen({ screenData: { profileAlreadyExists: false } }));
        break;
      }

      default: {
        throw new Error('Unknown login methods case', result);
      }
    }
  } catch (err) {
    yield call(log.error, err);
    if (err.code === 'auth/invalid-email') {
      yield put(setInputEmailScreenError({ error: 'The email address is badly formatted' }));
    } else {
      yield put(setInputEmailScreenError({ error: 'Something went wrong. Please try again' }));
    }
  }
}

const signInWithEmailAndPassword = (email, password) =>
  auth
    .signInWithEmailAndPassword(email, password)
    .then((result) => ({ result, status: EmailPasswordAuthResults.SUCCESS }))
    // TODO: handle other errors (e.g. user not found)
    .catch(() => EmailPasswordAuthResults.WRONG_PASSWORD);

const fetchUserProfile = async (userId) => {
  try {
    const userProfile = await db.doc(`userProfiles/${userId}`).get();
    return { id: userProfile.id, ...userProfile.data() };
  } catch (err) {
    log.error(err);
    return null;
  }
};

function* handleTrySignInWithEmailAndPassword({ payload: { email, password } }) {
  const { result, status } = yield call(signInWithEmailAndPassword, email, password);

  if (status === EmailPasswordAuthResults.SUCCESS) {
    let hasUsername = false;
    if (result) {
      const userProfile = yield call(fetchUserProfile, result.user.uid);
      hasUsername = userProfile ? !!userProfile.username : false;

      yield put(setProfileId({ id: result.user.uid }));
      yield put(setEmail({ email: result.user.email }));
      yield put(setDisplayName({ displayName: result.user.displayName }));
    }

    yield put(goNextScreen({ screenData: { hasUsername } }));
  } else {
    yield put(
      setSignInWithEmailAndPasswordError({ error: 'The password is invalid or the user does not have a password' })
    );
  }
}

const CREATE_ACCOUNT_INVALID_FIELD_ERROR = 'CREATE_ACCOUNT_INVALID_FIELD_ERROR';
const createUserWithEmail = async (email, name, password) => {
  const trimmedEmail = email.trim();
  const trimmedName = name.trim();
  const trimmedPassword = password.trim();
  if (trimmedEmail && trimmedName && trimmedPassword) {
    const result = await auth.createUserWithEmailAndPassword(trimmedEmail, trimmedPassword);
    const updateData = { displayName: trimmedName };
    await result.user.updateProfile(updateData);

    if (result.additionalUserInfo.isNewUser) {
      const userProfile = await db.doc(`userProfiles/${result.user.uid}`).get();
      const userProfileData = { ...userProfile.data(), id: userProfile.id };
      if (userProfileData.isOnboarding !== false) {
        updateData.isOnboarding = result.additionalUserInfo.isNewUser;
      }
    }

    if (Object.keys(updateData).length > 0) {
      await db.doc(`userProfiles/${result.user.uid}`).set(updateData, { merge: true });
    }

    return result;
  }

  const error = new Error('Invalid email, name or password');
  error.code = CREATE_ACCOUNT_INVALID_FIELD_ERROR;
  throw error;
};

function* handleTryCreateAccountWithEmail({ payload: { email, name, password } }) {
  try {
    const result = yield call(createUserWithEmail, email, name, password);
    yield put(setProfileId({ id: result.user.uid }));
    yield put(setDisplayName({ displayName: name }));
    yield put(goNextScreen());
    yield call(sendFirstTimeUserAnalytics, getFirstName(name));
  } catch (err) {
    yield call(log.error, err);
    if (err.code === 'auth/email-already-in-use') {
      yield put(setCreateAccountEmailError({ error: 'Email is already in use!' }));
    } else if (err.code === 'auth/weak-password') {
      yield put(setCreateAccountPasswordError({ error: 'Password should be at least 6 characters' }));
    } else if (err.code === 'auth/invalid-email') {
      yield put(setCreateAccountEmailError({ error: 'The email address is badly formatted' }));
    } else if (err.code === CREATE_ACCOUNT_INVALID_FIELD_ERROR) {
      yield put(setCreateAccountPasswordError({ error: 'Invalid email, name or password' }));
    } else {
      yield put(setCreateAccountPasswordError({ error: 'Something went wrong. Please try again' }));
    }
  }
}

function* validateUsername({ payload: { username } }) {
  const validationResult = yield call(isUsernameValid, username);
  yield put(setUsernameValidationResult({ message: validationResult.message, isValid: validationResult.result }));
}

function* getFriendsSuggestionsData() {
  const { data } = yield call(getSuggestedFriendsV2);
  const hasFriendsSuggestions = data.success === true && data.suggestedFriends.length > 0;
  const nextPage = data.metadata.next ? data.metadata.next : null;
  return { suggestions: data.success ? data.suggestedFriends : [], hasFriendsSuggestions, nextPage };
}

function* handleSubmitUsernameScreen() {
  try {
    const username = yield select(selectUsername);
    const profileId = yield select(selectProfileId);
    yield call([db.doc(`userProfiles/${profileId}`), 'update'], { username: username.toLowerCase() });

    // check is in school supported location
    const resp = yield call(fetchSchools);
    const inSchoolSupportedLocation = resp?.resultType === ResultTypes.LOCATION_SUPPORTED;

    // check friend suggestions
    let hasFriendsSuggestionsCheck = false;
    if (!inSchoolSupportedLocation) {
      const { suggestions, hasFriendsSuggestions, nextPage } = yield call(getFriendsSuggestionsData);
      yield put(setFriendSuggestions({ suggestions, nextPage }));
      if (hasFriendsSuggestions) {
        hasFriendsSuggestionsCheck = hasFriendsSuggestions;
        yield call(track, FRIENDS_SUGGESTED, { source: 'account creation flow' });
      }
    }

    yield put(
      goNextScreen({ screenData: { hasFriendsSuggestions: hasFriendsSuggestionsCheck, inSchoolSupportedLocation } })
    );
  } catch (err) {
    log.error(err);
    yield put(goNextScreen({ screenData: { hasFriendsSuggestions: false } }));
  }
}

function* handleSchoolSelectionComplete() {
  try {
    const { suggestions, hasFriendsSuggestions, nextPage } = yield call(getFriendsSuggestionsData);
    yield put(setFriendSuggestions({ suggestions, nextPage }));
    if (hasFriendsSuggestions) {
      yield call(track, FRIENDS_SUGGESTED, { source: 'account creation flow' });
    }

    yield put(goNextScreen({ screenData: { hasFriendsSuggestions } }));
  } catch (err) {
    log.error(err);
    yield put(goNextScreen({ screenData: { hasFriendsSuggestions: false } }));
  }
}

function* handleSendCode({ payload: { domesticPhoneNumber, countryCode, verificationId } }) {
  yield put(setVerificationId({ verificationId }));
  yield put(setPhoneNumber({ domesticPhoneNumber, countryCode }));
  yield put(goNextScreen());
}

const setIsOnboardingOnProfile = async (userId) => {
  db.doc(`userProfiles/${userId}`).set({ isOnboarding: true }, { merge: true });
};

function* handleVerifyCode({ payload: { uid, isNewUser } }) {
  if (isNewUser) {
    yield call(setIsOnboardingOnProfile, uid);
  }
  yield put(setProfileId({ id: uid }));
  yield put(goNextScreen({ screenData: { isNewUser } }));
}

const setDisplayNameAndUsername = async (displayName, username) => {
  const trimmedName = displayName.trim();
  const updateData = { displayName: trimmedName, username };
  const { currentUser } = firebase.auth();

  if (currentUser) {
    await currentUser.updateProfile(updateData);
    await db.doc(`userProfiles/${currentUser.uid}`).set(updateData, { merge: true });
  }
};

function* handleSubmitUsernameAndDisplayNameScreen({ payload: { displayName } }) {
  try {
    const username = yield select(selectUsername);

    // check is in school supported location
    const resp = yield call(fetchSchools);
    const inSchoolSupportedLocation = resp?.resultType === ResultTypes.LOCATION_SUPPORTED;

    // check friend suggestions
    let hasFriendsSuggestionsCheck = false;
    if (!inSchoolSupportedLocation) {
      const { suggestions, hasFriendsSuggestions, nextPage } = yield call(getFriendsSuggestionsData);
      yield put(setFriendSuggestions({ suggestions, nextPage }));
      if (hasFriendsSuggestions) {
        hasFriendsSuggestionsCheck = hasFriendsSuggestions;
        yield call(track, FRIENDS_SUGGESTED, { source: 'account creation flow' });
      }
    }

    yield call(setDisplayNameAndUsername, displayName, username);
    yield put(
      goNextScreen({ screenData: { hasFriendsSuggestions: hasFriendsSuggestionsCheck, inSchoolSupportedLocation } })
    );
    yield call(sendFirstTimeUserAnalytics, getFirstName(displayName));
  } catch (err) {
    yield call(log.error, err);
    yield put(goNextScreen({ screenData: { hasFriendsSuggestions: false } }));
  }
}

function* handleSubmitFriendsSuggestions() {
  yield put(goNextScreen({ screenData: {} }));
}

function* handleResetPassword({ payload: { email } }) {
  try {
    yield call(() => auth.sendPasswordResetEmail(email.trim()));
    yield put(goNextScreen({ screenData: {} }));
  } catch (err) {
    yield call(log.error, err);
    if (err.code === 'auth/invalid-email') {
      yield put(setResetPasswordError({ error: 'The email address is badly formatted' }));
    }

    if (err.code === 'auth/user-not-found') {
      yield put(
        setResetPasswordError({
          error: 'There is no user record corresponding to this email',
        })
      );
    }
  }
}

function* sendFirstTimeUserAnalytics(firstName) {
  const flowId = yield select(selectFlowId);

  // flows.DEEP_LINK_SIGN_IN is used for both room- and hub- deep-linking
  if (flowId === flows.DEEP_LINK_SIGN_IN) {
    const isGroupJoinLink = checkIsGroupJoinLink();
    const isGroupLink = checkIsGroupLink();
    if (isGroupJoinLink || isGroupLink) {
      const groupId = getGroupIdFromUrl();
      yield call(track, NEW_USER_JOIN_GROUP, { groupId, firstName, userOrigin: 'web' });
      return;
    }

    const roomIdFromUrl = yield call(getRoomId);
    if (checkIfLooksLikeRoomId(roomIdFromUrl) || checkIfLooksLikeRoomAlias(roomIdFromUrl)) {
      yield take(roomDataUpdated);
      const roomId = yield select(selectCurrentRoomId);
      const roomGroupId = yield select(selectRoomGroupId);
      yield call(track, NEW_USER_JOIN_ROOM, { roomId, groupId: roomGroupId, firstName, userOrigin: 'web' });
    }
  }
}

export default function* signingInSaga() {
  yield all([
    // navigation between screens
    takeEvery(startFlow, handleStartFlow),
    takeLeading(goNextScreen, handleGoNextScreen),
    takeLeading(goPrevScreen, handleGoPrevScreen),
    // screens logic
    takeEvery(startProviderAuth, handleStartProviderAuth),
    debounce(350, setUsernameWithValidation, validateUsername),
    takeEvery(setScreenId, handleSetScreenId),
    takeLeading(submitInputEmailScreen, handleSubmitInputEmailScreen),
    takeLeading(trySignInWithEmailAndPassword, handleTrySignInWithEmailAndPassword),
    takeLeading(tryCreateAccountWithEmail, handleTryCreateAccountWithEmail),
    takeLeading(sendCode, handleSendCode),
    takeLeading(submitUsernameScreen, handleSubmitUsernameScreen),
    takeLeading(schoolSelectionComplete, handleSchoolSelectionComplete),
    takeLeading(submitUsernameAndDisplayNameScreen, handleSubmitUsernameAndDisplayNameScreen),
    takeLeading(verifyConfirmationCode, handleVerifyCode),
    takeLeading(submitFriendSuggestions, handleSubmitFriendsSuggestions),
    takeLeading(resetPassword, handleResetPassword),
  ]);
}
