import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

// store
import { hideTip } from '../store/tips-and-tricks/actions';
import { TipsAndTricks } from '../store/tips-and-tricks/init-state';

// hooks
import { useDomElement } from '../hooks/useDomElement';

// components
import Portal from '../components/Portal';

const element = document.createElement('div');

const AddFurnitureTip = () => {
  const dispatch = useDispatch();

  const furnitureTipRoot = useDomElement({ parentSelector: '#room-decoration-tip-container', element });

  useEffect(() => {
    const roomDecorationButton = document.querySelector('#open-room-decoration-button');
    roomDecorationButton.classList.add('highlight-round-pulse');
    roomDecorationButton.classList.add('highlight-round-pulse-front');

    return () => {
      roomDecorationButton.classList.remove('highlight-round-pulse');
      roomDecorationButton.classList.remove('highlight-round-pulse-front');
    };
  }, []);

  const onClose = () => {
    dispatch(hideTip({ tip: TipsAndTricks.ADD_ROOM_DECORATION, source: 'dismiss' }));
  };

  if (!furnitureTipRoot) {
    return null;
  }

  return (
    <>
      <Portal root={furnitureTipRoot}>
        <Container>
          <TipTitle>TIP #82</TipTitle>
          <TipBody>
            <TipText>Add Room Decor Here</TipText>
          </TipBody>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Container>
      </Portal>

      <Portal root={furnitureTipRoot}>
        <Image height={43} width={43} src="images/icons/tip-arrow.gif" alt="tip arrow" />
      </Portal>
    </>
  );
};

const CloseButton = styled.button`
  position: absolute;
  right: -8px;
  top: 8px;
  z-index: 10;
  font-size: 16px;
  font-weight: bolder;
  border: none;
  border-radius: 100%;
  background: white;
  cursor: pointer;
`;

const Container = styled.div`
  position: absolute;
  bottom: 140%;
  margin-left: -33%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${CloseButton} {
    display: none;
  }

  &:hover {
    ${CloseButton} {
      display: block;
    }
  }

  // hide when the room furniture button is hidden
  @media (max-width: 615px) {
    display: none;
  }
`;

const TipTitle = styled.h6`
  color: white;
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
`;

const TipBody = styled.div`
  padding: 6px 15px;
  background: linear-gradient(89.93deg, rgba(181, 1, 176, 0.7) 20%, rgba(42, 7, 244, 0.5) 100%);
  border-radius: 10px;
`;

const TipText = styled.p`
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const Image = styled.img`
  position: absolute;
  margin-left: 2%;
  bottom: 140%;
  z-index: 10;
  transform: rotate(19deg);

  // hide when the toolbar is too narrow
  @media (max-width: 828px) {
    display: none;
  }
`;

export default AddFurnitureTip;
