// Helper function to turn janus methods with success/error params into promises.

import log from '../../log';

// 'successHandler' is needed to do something extra with the result (used for plugins wrappers).
export default function promisify(method, options, successHandler) {
  if (options && (options.success || options.error)) {
    throw new Error('You were supposed to fight callbacks, not join them! This wrapper works on promises');
  }

  return new Promise((resolve, reject) => {
    let isResolved = false;
    const resolveMethod = successHandler ? successHandler.bind(null, resolve) : resolve;
    method({
      ...options,
      success(arg) {
        isResolved = true;
        resolveMethod(arg);
      },
      error(err) {
        if (isResolved) {
          if (options.onerror) {
            options.onerror(err);
          }
          log.error(err);
        } else {
          isResolved = true;
          reject(err);
        }
      },
    });
  });
}
