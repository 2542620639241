import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

// components
import { createSlideDownAnimation } from '../../components/animate/keyframes';
import { hintContainerSlideDownMs } from './shared-styles';

interface HintContentProps {
  children: React.ReactNode;
}

// timeouts
let outerTimeoutId: ReturnType<typeof setTimeout> | null = null;
let innerTimeoutId: ReturnType<typeof setTimeout> | null = null;

const confettiGifDurationMs = 1750;

const Hint = ({ children }: HintContentProps) => {
  const [isConfettiGifVisible, setIsConfettiGifVisible] = useState(false);

  useEffect(() => {
    outerTimeoutId = setTimeout(() => {
      setIsConfettiGifVisible(true);

      innerTimeoutId = setTimeout(() => {
        setIsConfettiGifVisible(false);
      }, confettiGifDurationMs);
    }, hintContainerSlideDownMs);

    return () => {
      clearTimeout(outerTimeoutId);
      clearTimeout(innerTimeoutId);
    };
  }, []);

  return (
    <OuterContainer>
      <InnerContainer>{children}</InnerContainer>

      {isConfettiGifVisible ? <ConfettiGif src="gif/room-onboarding-confetti.gif" /> : null}
    </OuterContainer>
  );
};

export const OuterContainer = styled.div`
  position: relative;
  padding: 15px;
  max-width: 550px;
  overflow: hidden;
  background: linear-gradient(89.89deg, rgba(181, 1, 176, 0.7) 0.04%, rgba(42, 7, 244, 0) 91.82%);
  border-radius: 30px;

  animation: ${() => css`
    ${hintContainerSlideDownMs}ms ${createSlideDownAnimation(-100)} ease-in-out both
  `};
`;

export const InnerContainer = styled.div`
  padding: 30px 45px;
  background: linear-gradient(89.88deg, rgba(181, 1, 176, 0.7) 0.05%, rgba(42, 7, 244, 0) 99.91%);
  border-radius: 30px;
  backdrop-filter: blur(10px);
`;

const ConfettiGif = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

export default Hint;
