import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectUserById } from '../../../../../store/users/selectors';
import { friendByIdSelector } from '../../../../../store/friends/selectors';
import { unfriend } from '../../../../../store/friends/store';
import ReportUserPreview from '../../ReportUserPreview';
import {
  ButtonsContainer,
  StepContainer,
  Description,
  ReportButton,
  Title,
  UserPreviewContainer,
} from '../../shared-styles';
import { track } from '../../../../../../util/analytics-util';
import { UNFRIEND } from '../../../../../../constants/analytics-events/friend-events';

interface PostReportStepProps {
  userId: string;
  close: () => void;
}

const PostReportStep = ({ userId, close }: PostReportStepProps) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => selectUserById(state, userId));
  const friend = useSelector((state) => friendByIdSelector(state, userId));

  const unfriendReportedUser = () => {
    // @ts-ignore
    dispatch(unfriend(userId));
    track(UNFRIEND);
    close();
  };

  return (
    <StepContainer>
      <Title>{user.displayName} has been reported!</Title>

      <UserPreviewContainer>
        {user ? (
          <ReportUserPreview userId={userId} displayName={user.displayName} username={user.username} showFlag />
        ) : null}
      </UserPreviewContainer>

      <Description>
        Thank you for reporting this user and keeping Here safe for <br />
        all other members. We'll review your report and take action.
      </Description>

      {friend ? (
        <>
          <Subtitle>In the meantime, consider also doing the following:</Subtitle>

          <ButtonsContainer>
            <ReportButton onClick={unfriendReportedUser}>✕ Unfriend User</ReportButton>
          </ButtonsContainer>

          <SkipStepsContainer>
            <SkipStepsButton onClick={close}>Skip these Steps</SkipStepsButton>
          </SkipStepsContainer>
        </>
      ) : null}
    </StepContainer>
  );
};

const Subtitle = styled.h3`
  margin-top: 25px;
  font-size: 12px;
  text-align: center;
  font-weight: bolder;
`;

const SkipStepsContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SkipStepsButton = styled.button`
  color: #00000099;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default PostReportStep;
