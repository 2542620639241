import React from 'react';
import PropTypes from 'prop-types';

const CameraIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="3" width="19" height="13" rx="3" stroke={color} strokeWidth="2" />
    <path d="M6 2C6 0.895431 6.89543 0 8 0H13C14.1046 0 15 0.895431 15 2V3H6V2Z" fill={color} />
    <circle cx="10.5" cy="9.5" r="3.5" fill={color} />
  </svg>
);

export default CameraIcon;

CameraIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

CameraIcon.defaultProps = {
  width: '21',
  height: '17',
  color: 'white',
};
