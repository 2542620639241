import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './init-state';
import { addTip, removeTip } from './actions';

export const tipsAndTricksReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(addTip, (state, action) => {
      if (!state.tipsAndTricks.includes(action.payload.tip)) {
        state.tipsAndTricks.push(action.payload.tip);
      }
    })
    .addCase(removeTip, (state, action) => {
      state.tipsAndTricks = state.tipsAndTricks.filter((tip) => tip !== action.payload.tip);
    })
);
