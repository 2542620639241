import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { requestGroupUpdate } from '../../../../store/groups/actions';
import { getGroupChatParams, track } from '../../../../../util/analytics-util';
import FontSelect from '../../../../room/settings/theme/FontSelect';
import { CHAT_EDIT_SKIN, colorPropertyToCategory, fontPropertyToCategory } from '../../../analytics';
import { selectChatByChatId } from '../../../../store/messaging/selectors';
import CustomThemeColorSelect, { Label } from '../../../../room/settings/theme/CustomThemeColorSelect';
import { Theme } from '../../../../../definitions/theme';

const colorButtonHeight = 50;

interface Props {
  chatId: string;
}

const SkinningThemePicker: React.FC<Props> = ({ chatId }) => {
  const dispatch = useDispatch();

  const chat = useSelector((state) => selectChatByChatId(state, chatId));
  const theme: Theme = useMemo(() => chat?.theme || {}, [chat?.theme]);
  const skin = useMemo(() => theme?.skin || {}, [theme?.skin]);

  const onColorUpdate = useCallback(
    (
      color: string,
      property: 'primaryBackground' | 'primaryForeground' | 'secondaryBackground' | 'secondaryForeground'
    ) => {
      dispatch(
        requestGroupUpdate({
          id: chat.groupId,
          updates: {
            theme: {
              ...theme,
              skin: {
                ...skin,
                name: 'custom',
              },
              isCustom: true,
              colors: {
                ...theme.colors,
                [property]: color,
              },
            },
          },
        })
      );
      track(CHAT_EDIT_SKIN, getGroupChatParams({ chatId, category: colorPropertyToCategory[property] }, chatId));
    },
    [chat.groupId, chatId, dispatch, skin, theme]
  );

  const onFontChange = useCallback(
    ({ family }: { family: string }, property: 'title' | 'section' | 'system') => {
      dispatch(
        requestGroupUpdate({
          id: chat.groupId,
          updates: {
            theme: {
              ...theme,
              skin: {
                ...skin,
                name: 'custom',
                fonts: {
                  ...(skin.fonts || {}),
                  [property]: family,
                },
              },
            },
          },
        })
      );
      track(
        CHAT_EDIT_SKIN,
        getGroupChatParams(
          {
            chatId,
            category: fontPropertyToCategory[property],
            name: family,
            oldName: (skin?.fonts && skin.fonts[property]) || '',
          },
          chatId
        )
      );
    },
    [dispatch, chat.groupId, theme, skin, chatId]
  );

  return (
    <>
      <ColorsContainer>
        <CustomThemeColorSelect
          label="Main Color"
          value={theme?.colors?.primaryBackground}
          onChange={useCallback((color) => onColorUpdate(color, 'primaryBackground'), [onColorUpdate])}
          buttonHeight={colorButtonHeight}
        />
        <CustomThemeColorSelect
          label="Main Text"
          previewText="Tt"
          value={theme?.colors?.primaryForeground}
          onChange={useCallback((color) => onColorUpdate(color, 'primaryForeground'), [onColorUpdate])}
          buttonHeight={colorButtonHeight}
        />
        <CustomThemeColorSelect
          isSecondary
          label="Accent Color"
          value={theme?.colors?.secondaryBackground}
          onChange={useCallback((color) => onColorUpdate(color, 'secondaryBackground'), [onColorUpdate])}
          buttonHeight={colorButtonHeight}
        />
        <CustomThemeColorSelect
          isSecondary
          label="Accent Text"
          previewText="♥"
          value={theme?.colors?.secondaryForeground}
          onChange={useCallback((color) => onColorUpdate(color, 'secondaryForeground'), [onColorUpdate])}
          buttonHeight={colorButtonHeight}
        />
      </ColorsContainer>
      <FontContainer>
        <FontSelect
          label="Chat name"
          id="chatNameFontPicker"
          currentFont={skin?.fonts?.title}
          onFontChange={useCallback((font) => onFontChange(font, 'title'), [onFontChange])}
          dropdownDirection="up"
        />
        <FontSelect
          label="Names & Time Stamps"
          id="chatTimestampsFontPicker"
          currentFont={skin?.fonts?.section}
          onFontChange={useCallback((font) => onFontChange(font, 'section'), [onFontChange])}
          dropdownDirection="up"
        />
        <FontSelect
          label="System Messages"
          id="chatSystemMessagesFontPicker"
          currentFont={skin?.fonts?.system}
          onFontChange={useCallback((font) => onFontChange(font, 'system'), [onFontChange])}
          dropdownDirection="up"
        />
      </FontContainer>
    </>
  );
};

export default SkinningThemePicker;

const FontContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

const ColorsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 40px;

  ${Label} {
    font-size: 12px;
    opacity: 1;
  }
`;
