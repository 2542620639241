import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

export interface TabData {
  name: string | ReactNode;
  node: ReactNode;
}

interface Props {
  tabs: TabData[];
}

const FolderTabs = ({ tabs }: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <Container>
      <TabsContainer>
        {tabs.map((t, index) => (
          <TabNameOuterContainer
            onClick={() => setActiveTabIndex(index)}
            key={typeof t.name === 'string' ? t.name : index}
          >
            {activeTabIndex === index ? null : <InactiveOverlay />}
            <TabNameInnerContainer>{t.name}</TabNameInnerContainer>
          </TabNameOuterContainer>
        ))}
      </TabsContainer>
      <Content>{tabs[activeTabIndex].node}</Content>
    </Container>
  );
};

export default FolderTabs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TabsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const InactiveOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
`;

const TabNameOuterContainer = styled.div`
  padding: 8px;
  border-radius: 16px 16px 0 0;
  background: var(--primary-background, #12002d);
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:hover ${InactiveOverlay} {
    opacity: 0.5;
  }
`;

const TabNameInnerContainer = styled.div`
  background: var(--secondary-background, #6b1be3);
  color: var(--secondary-foreground, white);
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: bold;
`;

const Content = styled.div`
  flex: 1 1 auto;
  height: calc(100% - 38px);
`;
