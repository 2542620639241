import { createReducer } from '@reduxjs/toolkit';

import {
  setActiveTab,
  setActiveModal,
  addVisibleRoomId,
  deleteVisibleRoomId,
  setVisibleRoomIds,
  setActiveGroupId,
  setGroupSettingsTab,
  setShouldShowRooms,
} from './actions';
import { initialState } from './init-state';

export const webLobbyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveTab, (state, action) => {
      state.prevActiveTab = state.activeTab;
      state.activeTab = action.payload.activeTab;

      if (state.activeTab !== null) {
        state.activeGroupId = null;
      }
    })
    .addCase(setActiveModal, (state, action) => {
      state.activeModal = action.payload.activeModal;
    })
    .addCase(addVisibleRoomId, (state, action) => {
      state.visibleRoomIds[action.payload.roomId] = true;
    })
    .addCase(deleteVisibleRoomId, (state, action) => {
      delete state.visibleRoomIds[action.payload.roomId];
    })
    .addCase(setVisibleRoomIds, (state, action) => {
      state.visibleRoomIds = action.payload.visibleRoomIds;
    })
    .addCase(setShouldShowRooms, (state, action) => {
      state.shouldShowRooms = action.payload.shouldShowRooms;
    })
    .addCase(setActiveGroupId, (state, action) => {
      state.activeGroupId = action.payload.activeGroupId;
      if (state.activeGroupId !== null) {
        state.activeTab = null;
      }
    })
    .addCase(setGroupSettingsTab, (state, action) => {
      state.groupSettings.currentTab = action.payload.tab;
    });
});
