export enum Screens {
  // signing in steps
  AUTH_TYPE_SELECTION = 'AUTH_TYPE_SELECTION',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  CREATE_USERNAME = 'CREATE_USERNAME',
  EMAIL_INPUT = 'EMAIL_INPUT',
  EMAIL_SIGN_IN = 'EMAIL_SIGN_IN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  PASSWORD_WAS_RESET = 'PASSWORD_WAS_RESET',
  PHONE_INPUT = 'PHONE_INPUT',
  PHONE_VERIFY_CODE = 'PHONE_VERIFY_CODE',
  CREATE_USERNAME_AND_NAME = 'CREATE_USERNAME_AND_NAME',
  FRIENDS_SUGGESTIONS = 'FRIENDS_SUGGESTIONS',
  SCHOOL_SELECTION = 'SCHOOL_SELECTION',

  // room onboarding steps
  REACTIONS_HINT = 'REACTIONS_HINT',
  REACTIONS_ONBOARDING = 'REACTIONS_ONBOARDING',

  MEDIA_PLAYER_ONBOARDING_HIGHLIGHT_BUTTON = 'MEDIA_PLAYER_ONBOARDING_HIGHLIGHT_BUTTON',
  MEDIA_PLAYER_ONBOARDING_PLAYER_HINT = 'MEDIA_PLAYER_ONBOARDING_PLAYER_HINT',
  MEDIA_PLAYER_ONBOARDING_INVITE_FRIENDS = 'MEDIA_PLAYER_ONBOARDING_INVITE_FRIENDS',

  ROOM_ONBOARDING_FINISHED = 'ROOM_ONBOARDING_FINISHED',
}

export enum HubsOnboardingScreens {
  WELCOME_TO_HUBS = 'WELCOME_TO_HUBS',
  PROFILE_EDIT_ONBOARDING = 'PROFILE_EDIT_ONBOARDING',
  MURALS_INTRO = 'MURALS_INTRO',
  CHAT_INTRO = 'CHAT_INTRO',
  ROOMS_INTRO = 'ROOMS_INTRO',
}

export enum CreateFirstHubFlowSteps {
  VIBE_PICKER = 'VIBE_PICKER',
  HUB_NAME = 'HUB_NAME',
  HUB_CREATION = 'HUB_CREATION',
}

export enum CreateOsDesktopFlowSteps {
  VIBE_PICKER = 'VIBE_PICKER',
  DESKTOP_CREATION = 'DESKTOP_CREATION',
}

export enum CreateOsChatFlowSteps {
  CREATE_CHAT = 'CREATE_CHAT',
  CUSTOMIZE_CHAT = 'CUSTOMIZE_CHAT',
  CHAT_INSTRUCTIONS = 'CHAT_INSTRUCTIONS',
  CHAT_COMPLETION = 'CHAT_COMPLETION',
}

export const FLOW_FINISHED = 'FLOW_FINISHED';

export enum AuthTypes {
  GMAIL = 'GMAIL',
  EMAIL = 'EMAIL',
  APPLE = 'APPLE',
  PHONE = 'PHONE',
}

export const flows = {
  STANDARD_ONBOARDING: 'FLOWS@STANDARD_ONBOARDING',
  DEEP_LINK_SIGN_IN: 'FLOWS@DEEP_LINK_SIGN_IN',
  CREATE_USERNAME: 'FLOWS@CREATE_USERNAME',
  CREATE_USERNAME_AND_DISPLAY_NAME: 'FLOWS@CREATE_USERNAME_AND_DISPLAY_NAME',
  ROOM_ONBOARDING: 'FLOWS@ROOM_ONBOARDING',
  CREATE_OS_DESKTOP: 'FLOWS@CREATE_OS_DESKTOP',
  CREATE_OS_CHAT: 'FLOWS@CREATE_OS_CHAT',
};

export const FetchSignInMethodsForEmailResults = {
  ALREADY_HAS_GOOGLE_ACCOUNT: 'ALREADY_HAS_GOOGLE_ACCOUNT',
  ALREADY_HAS_EMAIL_ACCOUNT: 'ALREADY_HAS_EMAIL_ACCOUNT',
  NO_ACCOUNT: 'NO_ACCOUNT',
};

export const EmailPasswordAuthResults = {
  SUCCESS: 'SUCCESS',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
};

export const PASSWORD_MIN_LENGTH = 6;
export const USERNAME_MIN_LENGTH = 4;
export const DISPLAY_NAME_MAX_LENGTH = 130;
