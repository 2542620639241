import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getBannedMembers, getOfflineMembers, getOnlineMembers } from '../../../store/room-members/selectors';

import RoomMembersList from './RoomMembersList';

const RoomMembersContainerView = ({ onlineUsers, offlineUsers, bannedUsers }) => (
  <div>
    <RoomMembersList type="online" label="Here" users={onlineUsers} />
    <RoomMembersList type="banned" label="Banned" users={bannedUsers} />
    <RoomMembersList type="offline" label="Not here" users={offlineUsers} />
  </div>
);

RoomMembersContainerView.propTypes = {
  onlineUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  offlineUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  bannedUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default connect((state) => ({
  onlineUsers: getOnlineMembers(state),
  offlineUsers: getOfflineMembers(state),
  bannedUsers: getBannedMembers(state),
}))(RoomMembersContainerView);
