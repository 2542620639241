import { isNumber } from 'lodash';
import Subscriber from '../subscriber';
import { RTCInboundRtpStreamStatsComplete } from './definitions';
import { DomStatsBase } from './dom-stats-base';

export class DomSubscriberStats extends DomStatsBase {
  private subscriber: Subscriber;

  constructor(userId: string, username: string, subscriber: Subscriber) {
    super(userId, username);
    this.subscriber = subscriber;
  }

  getFeedTrackForStat(stat: RTCInboundRtpStreamStatsComplete) {
    return this.subscriber?.feedCollection?.feedTracks.find((ft) => ft.track?.id === stat.trackIdentifier);
  }

  audioTemplate(stat: RTCInboundRtpStreamStatsComplete) {
    const codec = this.subscriber?.getAudioCodecMime(this.getFeedTrackForStat(stat));
    return `
      ${this.row('Codec', `${codec}`)}
      <div><strong>${stat.kind.toUpperCase()} / ${stat.trackIdentifier} / ${stat.ssrc}</strong></div>
      ${this.jitterAndBitrateRow(stat)}
      ${this.lostAndDropsRow(stat)}
    `;
  }

  videoTemplate(stat: RTCInboundRtpStreamStatsComplete) {
    const ft = this.getFeedTrackForStat(stat);
    let codecProfile = this.subscriber?.getVideoCodecProfile(ft);
    codecProfile = codecProfile ? ` / ${codecProfile}` : '';

    const tmpl = `
      <div><strong>${stat.kind.toUpperCase()} / ${stat.trackIdentifier} / ${stat.ssrc}</strong></div>
      ${this.row('Resolution', `${stat.frameWidth} x ${stat.frameHeight} at ${stat.framesPerSecond}`)}
      ${this.jitterAndBitrateRow(stat)}
      ${this.lostAndDropsRow(stat)}
      ${this.nacksAndPlis(stat)}
      ${this.row('Codec/Profile', `${this.subscriber?.getVideoCodecMime(ft)}${codecProfile}`)}
    `;

    return tmpl;
  }

  private nacksAndPlis(stat: RTCInboundRtpStreamStatsComplete) {
    return [
      this.row('Nack / PLI / FIR', `${stat.nackCount} / ${stat.pliCount} / ${stat.firCount}`),
      this.row('Nack %', `${this.nacksPercentage(stat)}%`),
      this.row('PLI %', `${this.plisPercentage(stat)}%`),
    ].join('');
  }

  private lostAndDropsRow(stat: RTCInboundRtpStreamStatsComplete) {
    return [
      stat.framesDropped ? this.row('F Drop', `${stat.framesDropped} / ${this.frameDropPercentage(stat)}%`) : '',
      stat.packetsLost ? this.row('P Lost', `${stat.packetsLost} / ${this.packetLostPercentage(stat)}%`) : '',
    ].join('');
  }

  //
  // TODO: Move the following methods to FeedTrack when need to be used in more than one place.
  //
  frameDropPercentage(stat: RTCInboundRtpStreamStatsComplete) {
    return this.calculatePercentage(stat.framesDropped, stat.framesReceived);
  }

  packetLostPercentage(stat: RTCInboundRtpStreamStatsComplete) {
    return this.calculatePercentage(stat.packetsLost, stat.packetsReceived);
  }

  nacksPercentage(stat: RTCInboundRtpStreamStatsComplete) {
    return this.calculatePercentage(stat.nackCount, stat.packetsReceived);
  }

  plisPercentage(stat: RTCInboundRtpStreamStatsComplete) {
    return this.calculatePercentage(stat.pliCount, stat.framesReceived);
  }

  calculatePercentage(value: number, basedinValue: number) {
    if (!isNumber(value) || !isNumber(basedinValue)) return undefined;
    return this.roundValue((value * 100) / basedinValue);
  }
}
