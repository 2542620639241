import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import HereModal from '../components/HereModal';
import PhoneNumberInputForm from './screens/PhoneNumberInputForm';
import PhoneVerifyCodeForm from './screens/VerifyCodeForm';
import Success from './screens/Success';
import firebase from '../../firebase';
import { updatePhone } from '../user-profile/api';
import { currentUserPhoneNumberUpdated } from '../store/users/store';
import { selectCurrentUserId } from '../store/users/selectors';
import { useVerifyPhone } from './useVerifyPhone';

const STEPS = {
  INPUT_PHONE_NUMBER: 'INPUT_PHONE_NUMBER',
  VERIFY_CONFIRMATION_CODE: 'VERIFY_CONFIRMATION_CODE',
  SUCCESS: 'SUCCESS',
};

const PhoneModal = ({ closePhoneModal }: { closePhoneModal: () => void }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentUserId);

  const [formData, setFormData] = useState({
    phoneNumber: null,
    verificationId: null,
    stage: STEPS.INPUT_PHONE_NUMBER,
  });

  const { sendVerificationCode } = useVerifyPhone();

  const onSendCode = async (number: string) => {
    const verificationId = await sendVerificationCode(number);
    setFormData({ phoneNumber: number, verificationId, stage: STEPS.VERIFY_CONFIRMATION_CODE });
  };

  const onBack = useCallback(() => {
    setFormData((state) => ({ ...state, stage: STEPS.INPUT_PHONE_NUMBER }));
  }, []);

  const onVerify = useCallback(() => {
    setFormData({ phoneNumber: null, verificationId: null, stage: STEPS.SUCCESS });
  }, []);

  const onValidate = useCallback(
    async (verificationId: string, confirmationCode: string) => {
      const user = firebase.auth().currentUser;
      // Check confirmation code
      const phoneCredentials = firebase.auth.PhoneAuthProvider.credential(verificationId, confirmationCode);
      // Update phone number on user's account
      await user.updatePhoneNumber(phoneCredentials);
      await updatePhone();
      // Update phone number in store to update user settings
      dispatch(currentUserPhoneNumberUpdated({ id: userId, phoneNumber: formData.phoneNumber }));
    },
    [dispatch, formData.phoneNumber, userId]
  );

  const handleSendVerificationCode = useCallback(
    () => sendVerificationCode(formData.phoneNumber),
    [formData.phoneNumber, sendVerificationCode]
  );

  const renderStage = () => {
    switch (formData.stage) {
      case STEPS.INPUT_PHONE_NUMBER:
        return <PhoneNumberInputForm onSendCode={onSendCode} closePhoneModal={closePhoneModal} />;
      case STEPS.VERIFY_CONFIRMATION_CODE:
        return (
          <PhoneVerifyCodeForm
            onBack={onBack}
            onVerify={onVerify}
            initialVerificationId={formData.verificationId}
            onValidate={onValidate}
            handleResendVerificationCode={handleSendVerificationCode}
          />
        );
      case STEPS.SUCCESS:
        return <Success closePhoneModal={closePhoneModal} />;
      default:
        return null;
    }
  };

  return (
    <HereModal modalWidth={formData.stage === STEPS.SUCCESS ? '360px' : '500px'}>
      <Container height={formData.stage === STEPS.SUCCESS ? '280px' : '450px'}>{renderStage()}</Container>
    </HereModal>
  );
};

const Container = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => height};
`;

export default PhoneModal;
