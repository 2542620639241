import { onSnapshot, offSnapshot } from '../../firestore-watcher';
import { sanitize } from '../../util';

export default class UserName extends HTMLElement {
  constructor() {
    super();
    this.onUserChange = this.onUserChange.bind(this);
  }

  onUserChange(user) {
    // We don't have user profiles for old users, so just in case we have this attribute.
    const name = user ? user.displayName : this.getAttribute('fallbackName');
    this.innerHTML = sanitize(name);
  }

  connectedCallback() {
    const userId = this.getAttribute('userId');
    onSnapshot(`/userProfiles/${userId}`, this.onUserChange);
  }

  disconnectedCallback() {
    const userId = this.getAttribute('userId');
    offSnapshot(`/userProfiles/${userId}`, this.onUserChange);
  }
}
window.customElements.define('here-user-name', UserName);
