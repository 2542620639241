import React from 'react';
import PropTypes from 'prop-types';

function Hexagon(props) {
  const { color } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 0L9.33013 2.5V7.5L5 10L0.669873 7.5V2.5L5 0Z" fill={color} />
    </svg>
  );
}

Hexagon.propTypes = {
  color: PropTypes.string,
};

Hexagon.defaultProps = {
  color: '#FEB445',
};

export default Hexagon;
