import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { currentUserProfileUpdateRequest } from '../../store/users/store';
import { selectCurrentUserAvatarUrl } from '../../store/users/selectors';
import { track } from '../../../util/analytics-util';
import { EDIT_PROFILE_PHOTO } from '../../../constants/analytics-events/user-events';

const AddAvatarConfirmation = ({ newAvatarUrl, onClose }) => {
  const dispatch = useDispatch();
  const currentAvatarUrl = useSelector(selectCurrentUserAvatarUrl);
  const onConfirm = useCallback(() => {
    track(EDIT_PROFILE_PHOTO, { photo: 'avatar', source: 'Gifs menu' });
    dispatch(currentUserProfileUpdateRequest({ patch: { avatar: { original: newAvatarUrl } } }));
    onClose();
  }, [dispatch, newAvatarUrl, onClose]);

  return (
    <Container>
      {currentAvatarUrl ? (
        <UnfunnyJoke>
          &mdash; Mom, can we have <InlineImage src={newAvatarUrl} /> ?
          <br />
          &mdash; No. There is <InlineImage src={newAvatarUrl} /> at home.
          <br />
          <InlineImage src={newAvatarUrl} /> at home: <InlineImage src={currentAvatarUrl} />
        </UnfunnyJoke>
      ) : (
        <Text>
          Do you want to set <InlineImage src={newAvatarUrl} /> as your new avatar?
        </Text>
      )}

      <ButtonsContainer>
        <Button className="here-button-regular" onClick={onConfirm}>
          Please make <InlineImage src={newAvatarUrl} /> my new avatar
        </Button>
        <Button className="here-button-ghost" onClick={onClose}>
          {currentAvatarUrl ? (
            <>
              I want to keep <InlineImage src={currentAvatarUrl} />
            </>
          ) : (
            'No thanks'
          )}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default AddAvatarConfirmation;

AddAvatarConfirmation.propTypes = {
  newAvatarUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Container = styled.div`
  background: var(--primary-background, white);
  color: var(--primary-foreground, black);
  border-radius: 16px;
  padding: 20px;
`;

const Text = styled.div`
  width: max-content;
  border-radius: 8px;
  padding: 8px;
  margin: 0 auto 16px;
`;

const UnfunnyJoke = styled(Text)`
  border: 2px dotted black;
`;

const InlineImage = styled.img`
  height: 24px;
  margin: 0 4px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  margin: 0 16px;
  padding: 4px 16px;
`;
