import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import SelectSchoolScreen from './screens/SelectSchoolScreen';
import SelectGradeScreen from './screens/SelectGradeScreen';
import { School } from '../../definitions/schools';
import { updateProfile } from '../user-profile/api';
import { selectCurrentUserId } from '../store/users/selectors';
import { track } from '../../util/analytics-util';
import { SKIP_SCHOOL_LINKING } from '../../constants/analytics-events/user-events';

export const SELECT_SCHOOL_STATE = 'select_school';
export const SELECT_GRADE_STATE = 'select_grade';

interface Props {
  onComplete: () => void;
}

const SchoolSelectionFlow: React.FC<Props> = ({ onComplete }) => {
  const [currentState, setCurrentState] = useState(SELECT_SCHOOL_STATE);
  const [selectedSchool, setSelectedSchool] = useState(null);

  const currentUserId = useSelector(selectCurrentUserId);

  const onSkip = () => {
    track(SKIP_SCHOOL_LINKING);
    onComplete();
  };

  return (
    <Container>
      {currentState === SELECT_SCHOOL_STATE && (
        <SelectSchoolScreen
          goToNext={(s: School) => {
            if (s.isPostSecondary || s.isMiddleSchool) {
              onComplete();
            } else {
              setCurrentState(SELECT_GRADE_STATE);
              setSelectedSchool(s);
            }
          }}
          onSkip={onSkip}
        />
      )}
      {currentState === SELECT_GRADE_STATE && (
        <SelectGradeScreen
          school={selectedSchool}
          goBack={() => {
            setCurrentState(SELECT_SCHOOL_STATE);
            updateProfile(currentUserId, { schoolId: null });
          }}
          goToNext={onComplete}
        />
      )}
    </Container>
  );
};

export default SchoolSelectionFlow;

const Container = styled.div`
  width: 520px;
`;
