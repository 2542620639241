import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { doc, getDoc } from 'firebase/firestore';

import { db } from '../../../firebase';
import { selectIsFriendsByIdCurried } from '../../store/friends/selectors';
import MiniProfile from '../../user-profile/MiniProfile';
import AddFriendButton from '../../friends/AddFriendButton';
import { selectDMChatByReceiverId } from '../../store/messaging/selectors';
import { addMessage } from '../../store/messaging/reducer';
import { messageTypes } from '../../../constants/chat-constants';
import CallIcon from '../../../../assets/icons/call.svg';
import { svgColorMixin } from '../../mixins';
import { EventSourceContext } from '../../common/contexts';
import { FRIEND_REQUEST_SOURCES } from '../../../constants/analytics-events/friend-events';
import { CALL_FROM_RECENT_VISITOR_INVITE_MODAL } from '../../../constants/analytics-events/dm-events';

const CTA_BUTTON_WIDTH = '135px';
const CTA_BUTTON_HEIGHT = '35px';

interface Props {
  userId: string
}

const InviteRecentVisitorTile: React.FC<Props> = ({ userId }) => {
  const dispatch = useDispatch();
  const isFriends = useSelector((state) => selectIsFriendsByIdCurried(userId)(state));
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    (async () => {
      const docRef = doc(db, 'userProfiles', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserProfile({ userId: docSnap.id, ...docSnap.data() });
      }
    })();
  }, [userId]);

  const [inviteCallSent, setInviteCallSent] = useState(false);
  const chatsWithYouAndReceiver = useSelector((state) => selectDMChatByReceiverId(state, userId));
  const chatId = chatsWithYouAndReceiver.length >= 1 ? chatsWithYouAndReceiver[0].id : null;

  const onCallClick = () => {
    const roomId = window.currentBoardId;

    if (!roomId || inviteCallSent) return;
    
    setInviteCallSent(true);

    if (chatId) {
      dispatch(addMessage({ type: messageTypes.INVITE, chatId, roomId, inviteMessageSource: CALL_FROM_RECENT_VISITOR_INVITE_MODAL }));
    } else {
      dispatch(addMessage({ type: messageTypes.INVITE, receiverId: userId, roomId, createChat: true, inviteMessageSource: CALL_FROM_RECENT_VISITOR_INVITE_MODAL }));
    }
  };

  return (
    <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.INVITE_RECENT_VISITOR_MODAL}>
      {userProfile && (
        <Container>
          <MiniProfile profile={userProfile} status="" withDMButton={false} />
          <ButtonContainer>
            {isFriends ? (
              <CallButton disabled={inviteCallSent} onClick={inviteCallSent ? () => {} : onCallClick}>
                <CallIcon height="16" />
                <CallButtonText>{inviteCallSent ? 'Call Sent' : 'Call to Room'}</CallButtonText>
              </CallButton>
            ) : (
              <ThemeProvider theme={{ buttonWidth: CTA_BUTTON_WIDTH, buttonHeight: CTA_BUTTON_HEIGHT }}>
                <AddFriendButton userId={userId} />
              </ThemeProvider>
            )}
          </ButtonContainer>
        </Container>
      )}
    </EventSourceContext.Provider>
  );
};

export default InviteRecentVisitorTile;

const Container = styled.div`
  width: 160px;
`;

type CallButtonProps = {
  disabled: boolean;
};

const CallButton = styled.div<CallButtonProps>`
  width: ${CTA_BUTTON_WIDTH};
  height: ${CTA_BUTTON_HEIGHT};
  border-radius: 10px;

  background-color: #39C360;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${svgColorMixin('white')}

  transition: opacity 0.2s;

  ${({ disabled }) => disabled && `
    opacity: 0.7;
  `}

  &:hover {
    opacity: 0.85;
  }
`;

const CallButtonText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;

  margin-left: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;
