import { Timestamp } from 'firebase/firestore';

type MessageTimestamp = Timestamp | Date | number;

interface MessageTimestampData {
  currMsgDate: MessageTimestamp;
  prevMsgDate: MessageTimestamp;
  creator: string;
  previousCreator?: string;
}

const isNewDayFirstMsg = (currMsgDate: Date, prevMsgDate: Date) => {
  const midnight = new Date(currMsgDate).setHours(0, 0, 0, 0);
  return prevMsgDate ? midnight < currMsgDate.getTime() && midnight > prevMsgDate.getTime() : false;
};

const getFormattedMessageTimestamp = (currMsgDate: Date) => {
  const currDate = new Date();
  const msInOneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const yesterday = new Date(currDate.getTime() - msInOneDay);

  const sixDaysAgo = new Date(currDate.getTime() - 6 * 24 * 60 * 60 * 1000);
  sixDaysAgo.setHours(0, 0, 0, 0);
  const startOfCurrDate = new Date(currMsgDate);
  startOfCurrDate.setHours(0, 0, 0, 0);
  const diffInDays = (startOfCurrDate.getTime() - sixDaysAgo.getTime()) / msInOneDay;

  const isToday = currMsgDate.toDateString() === currDate.toDateString();
  const isYesterday = currMsgDate.toDateString() === yesterday.toDateString();
  const isWithinLastSixDays = diffInDays <= 6 && diffInDays >= 0;

  const time = new Intl.DateTimeFormat('en', {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  }).format(currMsgDate);

  const day = new Intl.DateTimeFormat('en', {
    weekday: 'short',
  }).format(currMsgDate);

  if (isToday) {
    return `Today at ${time}`;
  }
  if (isYesterday) {
    return `Yesterday at ${time}`;
  }
  if (isWithinLastSixDays) {
    return `${day} at ${time}`;
  }
  const date = new Intl.DateTimeFormat('en', {
    hour12: true,
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: '2-digit',
  }).format(currMsgDate);
  return date;
};

const getLocalDate = (date: MessageTimestamp) => {
  let localDate = null;
  if (date) {
    if (date instanceof Date) {
      // createdAt is Date
      localDate = date;
    } else if (typeof date === 'number') {
      // createdAt is timestamp
      localDate = new Date(date);
    } else if (date instanceof Timestamp) {
      // createdAt in some firebase data format
      localDate = date.toDate();
    }
  }
  return localDate;
};

export const getMessageTimestampData = ({
  currMsgDate,
  prevMsgDate,
  creator,
  previousCreator,
}: MessageTimestampData) => {
  let currDate = getLocalDate(currMsgDate);
  let prevDate = getLocalDate(prevMsgDate);

  // prevDate could be null in the case where there is no previous message
  if (!prevDate) prevDate = new Date(0);
  if (!currDate) currDate = new Date(0);

  const diffMs = currDate.getTime() - prevDate?.getTime();
  const diffMins = diffMs / (1000 * 60); // minutes

  const showTimestamp = diffMins > 59 || previousCreator !== creator;
  const msgTimestamp = getFormattedMessageTimestamp(currDate);

  return {
    showTimestamp,
    msgTimestamp,
  };
};

export const getDividerData = (currMsgDate: MessageTimestamp, prevMsgDate: MessageTimestamp) => {
  const currDate = getLocalDate(currMsgDate);
  const prevDate = getLocalDate(prevMsgDate);

  const showNewDayDivider = isNewDayFirstMsg(currDate, prevDate);
  const dividerTimestamp = new Intl.DateTimeFormat('en', {
    hour12: true,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(currDate);

  return {
    showNewDayDivider,
    dividerTimestamp,
  };
};
