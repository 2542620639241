import React, { useState } from 'react';
import styled from 'styled-components';

import log from '../../log';
import { uploadVibeImage } from '../../upload';

// components
import {
  Image,
  LoadingStub,
  UploadedImageContainer,
  clickableVibePickerOptionMixin,
  uploadedImageContainerMixin,
  vibePickerOptionMixin,
} from './shared-styles';
import { svgColorMixin } from '../mixins';

// utils
import { deleteUploadedFile, getFilesTotalSize } from '../../util/file-util';

// constants
import { fileUploadErrorTypes } from '../../constants/chat-constants';

// icons
import UploadIcon from '../../../assets/icons/upload.svg';
import WarningIcon from '../../../assets/icons/warning.svg';

const MAX_VIBE_IMAGE_UPLOAD_BYTES = 10034800; // 10mb

const VIBE_IMAGE_IS_TOO_LARGE_ERROR_MESSAGE = 'Max file size is 10mb.';
const VIBE_IMAGE_WRONG_TYPE_ERROR_MESSAGE = 'Wrong file type. Please try again.';
const VIBE_IMAGE_UPLOAD_ERROR_MESSAGE = 'Something went wrong. Please try again.';

interface VibePickerCustomOptionProps {
  isActive?: boolean;
  bgUrl: string | null;
  setBgUrl: (url: string) => void;
  selectCustomOption: () => void;
}

const VibePickerCustomOption = ({ isActive, bgUrl, setBgUrl, selectCustomOption }: VibePickerCustomOptionProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [uploadingErrorMessage, setUploadingErrorMessage] = useState(null);

  const onLoad = () => {
    setImageLoaded(true);
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setUploadingErrorMessage(null);

    const { files } = e.target;
    const filesArray = Array.from(files);
    const filesToUploadTotalSize = getFilesTotalSize(filesArray);
    if (filesToUploadTotalSize > MAX_VIBE_IMAGE_UPLOAD_BYTES) {
      log.error('VibePickerCustomOption', 'handleFileUpload', 'File size is too large');
      setUploadingErrorMessage(VIBE_IMAGE_IS_TOO_LARGE_ERROR_MESSAGE);
      return;
    }

    const [lastFile] = filesArray.slice(-1);

    try {
      const { fileURL } = await uploadVibeImage({ file: lastFile });
      setBgUrl(fileURL);
      selectCustomOption(); // autoselect the option on upload success
    } catch (err) {
      log.error('VibePickerCustomOption', 'handleFileUpload', err);
      if (err.cause === fileUploadErrorTypes.WRONG_TYPE) {
        setUploadingErrorMessage(VIBE_IMAGE_WRONG_TYPE_ERROR_MESSAGE);
      } else {
        setUploadingErrorMessage(VIBE_IMAGE_UPLOAD_ERROR_MESSAGE);
      }
    }
  };

  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // preventing selection of the option in the vibe picker
      e.preventDefault();
      e.stopPropagation();

      const prevUploadedUrl = bgUrl;
      await handleFileUpload(e);
      await deleteUploadedFile(prevUploadedUrl);
    } catch (err) {
      log.error('VibePickerCustomOption', 'handleChangeImage', err);
    }
  };

  return (
    <>
      {/* success state -- the image was successfully uploaded, new room can be created */}
      {bgUrl && !uploadingErrorMessage ? (
        <UploadedImageContainer isActive={isActive}>
          <Image
            src={bgUrl}
            isActive={isActive}
            imageLoaded={imageLoaded}
            onClick={selectCustomOption}
            onLoad={onLoad}
          />

          {!imageLoaded ? <LoadingStub imageLoaded={false} /> : null}

          <ChangeImageLabel>
            Change Image
            <UploadInput type="file" accept="image/*" onChange={handleChangeImage} />
          </ChangeImageLabel>
        </UploadedImageContainer>
      ) : null}

      {/* error state -- issue during the image uploading */}
      {uploadingErrorMessage ? (
        <ImageUploadErrorContainer>
          <WarningIcon />

          <UploadFailedText>Upload Failed...</UploadFailedText>

          <UploadErrorText>{uploadingErrorMessage}</UploadErrorText>

          <ErrorStateChangeImageLabel>
            Change Image
            <UploadInput type="file" accept="image/*" onChange={handleChangeImage} />
          </ErrorStateChangeImageLabel>
        </ImageUploadErrorContainer>
      ) : null}

      {/* init state -- a button for uploading your  */}
      {!bgUrl && !imageLoaded && !uploadingErrorMessage ? (
        <UploadVibeLabel isActive={isActive}>
          <UploadIcon />
          <UploadVibeText>Upload Custom</UploadVibeText>
          <UploadInput type="file" accept="image/*" onChange={handleFileUpload} />
        </UploadVibeLabel>
      ) : null}
    </>
  );
};

const ImageUploadErrorContainer = styled.div`
  ${vibePickerOptionMixin()}

  position: relative;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6335d;

  svg {
    width: 38px;
  }
`;

const UploadFailedText = styled.p`
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 0;
`;

const UploadErrorText = styled.p`
  color: white;
  font-size: 14px;
  font-weight: normal;
  line-height: 5px;
`;

const ChangeImageLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 25px;
  margin: auto;
  width: fit-content;
  height: fit-content;
  color: #6b1be3;
  font-size: 14px;
  font-weight: bolder;
  background-color: white;
  border-radius: 40px;
  box-shadow: 0 0 3px 2px #0000004a;
  cursor: pointer;
`;

const ErrorStateChangeImageLabel = styled(ChangeImageLabel)`
  position: relative;
  margin: 0;
`;

const UploadVibeLabel = styled.label<{ isActive: boolean }>`
  ${({ isActive }) => uploadedImageContainerMixin(isActive)}

  ${({ isActive }) => clickableVibePickerOptionMixin({ isActive })}

  ${svgColorMixin('var(--primary-foreground, #6b1be2)')}

  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff33;

  svg {
    width: 52px;
  }
`;

const UploadVibeText = styled.p`
  color: var(--primary-foreground, #6b1be2);
  font-size: 22px;
  font-weight: bold;
`;

const UploadInput = styled.input`
  display: none;
`;

export default VibePickerCustomOption;
