import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';

import { useKeyPress } from '../../hooks/useKeyPress';

// modals
import MobileUpsellModal from './MobileUpsellModal';
import { Overlay } from './shared-styles.ts';

const hideModal = () => {
  const mobileUpsellModalContainer = document.getElementById('mobile-upsell-modal-container');
  ReactDOM.unmountComponentAtNode(mobileUpsellModalContainer);
};

const MobileUpsellRoomModal = () => {
  const overlayRef = useRef();

  useKeyPress('Escape', hideModal);

  const handleOverlayClick = useCallback((e) => {
    // check if event wasn't triggered by bubbling
    if (overlayRef.current === e.target) {
      hideModal();
    }
  }, []);

  return (
    <Overlay ref={overlayRef} onEscPress={hideModal} onClick={handleOverlayClick}>
      <MobileUpsellModal />
    </Overlay>
  );
};

export default MobileUpsellRoomModal;
