import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useFriendingButtons } from '../../hooks/feed/useFriendingButtons';
import AddFriendIcon from '../../../../assets/icons/add-friend.svg';
import AddFriendPendingIcon from '../../../../assets/icons/add-friend-pending.svg';
import AcceptFriendIcon from '../../../../assets/icons/add-friend-accept.svg';

import { getHubTrackParams, track } from '../../../util/analytics-util';
import { ACCEPT_FRIEND, REQUEST_FRIEND } from '../../../constants/analytics-events/friend-events';
import { EventSourceContext } from '../../common/contexts.ts';

function FriendingSystemMessage({ creator }) {
  const source = useContext(EventSourceContext);
  const [friendingError, setFriendingError] = useState('');
  const { showAddFriend, showAcceptFriend, showPendingIcon, addFriendHandler, acceptFriendHandler } =
    useFriendingButtons(creator);

  const onAcceptFriendRequestClick = async () => {
    try {
      const response = await acceptFriendHandler();
      if (response && response.success) {
        track(ACCEPT_FRIEND, {
          source,
          requesterId: creator,
        });
      }
    } catch (e) {
      setFriendingError('Error Accepting Friend');
    }
  };

  const onAddFriendClick = async () => {
    try {
      const response = await addFriendHandler();
      if (response && response.success) {
        track(
          REQUEST_FRIEND,
          getHubTrackParams({
            source,
            receiverId: creator,
          })
        );
      }
    } catch (e) {
      setFriendingError('Error Adding Friend');
    }
  };

  return (
    <SystemMessageContainer>
      {showAcceptFriend && (
        <AddFriendButton
          disabled={friendingError}
          onClick={onAcceptFriendRequestClick}
          className="here-button-regular friending-button"
        >
          <AcceptFriendIcon width="18" /> Accept Friend
        </AddFriendButton>
      )}
      {showPendingIcon && (
        <AddFriendButton disabled className="here-button-regular friending-button">
          <AddFriendPendingIcon width="20" /> Request Sent
        </AddFriendButton>
      )}
      {showAddFriend && (
        <AddFriendButton
          disabled={friendingError}
          onClick={onAddFriendClick}
          className="here-button-regular friending-button"
        >
          <AddFriendIcon width="20" /> Add Friend
        </AddFriendButton>
      )}
      {friendingError && <FriendingError>{friendingError}</FriendingError>}
    </SystemMessageContainer>
  );
}

FriendingSystemMessage.propTypes = {
  creator: PropTypes.string.isRequired,
};

export default React.memo(FriendingSystemMessage);

const AddFriendButton = styled.button`
  margin-top: 5px;
  margin-bottom: 7px;
  svg {
    margin-bottom: 2px;
  }
  &:disabled {
    svg {
      margin-bottom: 0px;
    }
  }
`;

const FriendingError = styled.p`
  color: red;
  font-size: 0.7rem;
  font-weight: 700;
  margin-top: -7px;
`;

const SystemMessageContainer = styled.div``;
