import { useCallback, useEffect, useState } from 'react';

import log from '../../log';
import { DefaultImageOption, getDefaultAvatars } from '../../api/default-images-api';

let avatarsCache: DefaultImageOption[] = [];

export const useAvatarsData = () => {
  const [avatars, setAvatars] = useState<DefaultImageOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const fetchAvatars = useCallback(async () => {
    try {
      const response = await getDefaultAvatars();
      setAvatars(response.defaultAvatars);
      avatarsCache = response.defaultAvatars;
      setError('');
    } catch (err) {
      log.error(err);
      setError('Failed to load avatars');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (avatarsCache.length) {
        setAvatars(avatarsCache);
        setLoading(false);
        setError('');
        return;
      }

      await fetchAvatars();
    })();
  }, [fetchAvatars]);

  return {
    avatars,
    loading,
    error,
    fetchAvatars,
  };
};
