import log from '../log';
import { track } from './analytics-util';

export function trackUserActivationFor(action) {
  const { userActivation } = navigator;
  if (
    typeof userActivation?.isActive === 'boolean' &&
    !userActivation?.isActive &&
    typeof userActivation?.hasBeenActive === 'boolean' &&
    !userActivation?.hasBeenActive
  ) {
    track('User Activation Before Play', {
      isActive: userActivation?.isActive,
      hasBeenActive: userActivation?.hasBeenActive,
      action,
    });
    log.warn(
      `User Activation isActive:${userActivation.isActive} hasBeenActive: ${userActivation.hasBeenActive} when ${action}`
    );
  }
}
