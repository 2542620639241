import { addAudioTrack } from '../audio-mixer';
import { shouldUseCustomAudioMixer } from '../electron-support/electron-support';
import log from '../log';
import Janus from '../sfu/janus/wrapper';
import { setVideoVolume } from '../spatial-audio';
import { track } from '../util/analytics-util';
import { trackUserActivationFor } from '../util/user-activation';
import { CameraElementHandler } from './camera-element';

export abstract class VideoRenderer {
  static attachStream: (
    camera: CameraElementHandler,
    streamId: string,
    stream: MediaStream,
    onPaused: (e: Event) => void
  ) => void;

  static setupForCamera(camera: CameraElementHandler): boolean {
    const cameraDom = camera.domElement;
    const videoContainer = cameraDom.querySelector('.video-container');

    if (!videoContainer) {
      log.error('Video Renderer: No video-container found');
      return false;
    }

    const existing = videoContainer.querySelector('video');
    if (existing && existing.parentNode) {
      log.debug(`Video Renderer: setupForCamera: Attaching to existing element for ${camera.userId}`);
      existing.style.display = 'none';
      return false;
    }

    // Due to a Safari 14.x bug, we don't create video tags explicitly here.
    // See related bug report: https://bugs.webkit.org/show_bug.cgi?id=222657
    // Some discussion without a solution: https://stackoverflow.com/questions/58240755/html-5-video-doesnt-show-up-in-safari-when-rendered-via-domparser-rendering-vi
    const video = document.getElementById('video-template').cloneNode() as HTMLVideoElement;
    video.style.display = 'none';
    video.id = `video-${camera.userId}`;
    video.classList.add(`camera-${camera.clipShape}`);
    video.classList.add('mirror');
    video.setAttribute('autoplay', 'true');
    video.setAttribute('playsinline', 'true');

    video.addEventListener('loadeddata', (__e) => {
      try {
        trackUserActivationFor('setupForCamera');
        video.play();
      } catch (error) {
        log.error(`Unable to play stream for ${camera.userId}: `, error);
      }
    });

    if (camera.isOwnCamera()) {
      video.volume = 0;
      try {
        video.setAttributeNode(document.createAttribute('muted'));
      } catch (e) {
        video.setAttribute('muted', 'true');
      }
    }

    log.debug(
      `Video Renderer: Created media <${video.tagName} id=${video.id}> to <${videoContainer.tagName} id=${videoContainer.id}>`
    );
    videoContainer.append(video);
    return true;
  }

  static teardownForCamera(camera: CameraElementHandler) {
    const el = camera.domElement;
    const videoContainer = el.querySelector(`#video-container-${camera.userId}`);
    const video = videoContainer.querySelector('video');
    if (video) {
      videoContainer.removeChild(video);
    }
  }

  static showVideo: (camera: CameraElementHandler) => void;

  static hideVideo: (camera: CameraElementHandler) => void;
}

/** Utility functions */

export async function attachStreams(
  handler: CameraElementHandler,
  videoElement: HTMLVideoElement,
  streamId: string,
  stream: MediaStream
) {
  const audioTracks = stream.getAudioTracks();
  const videoTracks = stream.getVideoTracks();

  if ((await shouldUseCustomAudioMixer()) && !handler.isOwnCamera()) {
    if (audioTracks?.length > 0) {
      try {
        addAudioTrack(audioTracks[0], streamId);
        audioTracks[0].enabled = false;
      } catch (err) {
        log.error('Failed to add audio track to mixer', err);
      }
    }
  }
  Janus.attachMediaStream(videoElement, stream);
  if (!streamId.startsWith('screen-')) {
    handler.setupAudioLevelsWithDefaults();
  }

  const videoContainer = document.getElementById(`video-container-${streamId}`);
  const element = videoContainer.closest('.videoElement');
  setVideoVolume(element);

  const { userActivation } = navigator as Navigator & {
    userActivation: { isActive: boolean; hasBeenActive: boolean };
  };

  if (!handler.isOwnCamera() && handler.isAudioOn) {
    window.setTimeout(() => {
      track('SFU Video Element Status', {
        elementMuted: videoElement.muted,
        elementVolume: videoElement.volume,
        elementReadyState: videoElement.readyState,
        elementPaused: videoElement.paused,
        elementEnded: videoElement.ended,
        audioTracksEnabled: audioTracks.map((t) => t.enabled),
        audioTracksMuted: audioTracks.map((t) => t.muted),
        audioTracksReadyState: audioTracks.map((t) => t.readyState),
        videoTracksEnabled: videoTracks.map((t) => t.enabled),
        videoTracksMuted: videoTracks.map((t) => t.muted),
        videoTracksReadyState: videoTracks.map((t) => t.readyState),
        isAudioOn: handler.isAudioOn,
        isVideoOn: handler.isVideoOn,
        isActive: userActivation?.isActive,
        hasBeenActive: userActivation?.hasBeenActive,
      });
    }, 3000);
  }
}
