import { debounce, delay, isNumber } from 'lodash';
import log from '../log';
import { showDeviceError } from '../util/device-error-util';
import {
  ConnectionStatus,
  MetricRateKeys,
  MetricRate,
  PublisherStreamSource,
  PublisherType,
} from './definitions/index.definitions';
import JanusJS, {
  ConfiguredTrack,
  IceState,
  MediaType,
  OfferParams,
  OfferType,
  PeerConnectionStatus,
} from './janus/janus.definitions';
import { trackInForeground as trackMetric } from '../util/analytics-util';
import { JanusPluginWrapperType } from './janus/videoroom';
import { JanusWrapperType } from './janus/wrapper';
import { IceRestartStatsType, RTCOutboundRtpStreamStatsComplete } from './stats/definitions';
import { TrackProvider } from './track-provider';
import { getCurrentAudioTrackProvider, getCurrentVideoTrackProvider } from '../camera/track-provider-util';

export interface PublisherDelegateProtocol {
  janus: JanusWrapperType;
  dispatchVideoRoomEvent: (publihser: BasePublisher, event: string, msg: JanusJS.Message) => void;
  publisherStreamReadyForHTML: (stream: MediaStream, username: string, type: PublisherStreamSource) => void;
  iceRestartCancelled: (state: RTCIceConnectionState) => void;
  noICE(publisher: BasePublisher): void;
}

export interface VideoRoomPluginDelegate {
  onPeerConnectionStatusChange: (publisher: BasePublisher, isConnected: boolean) => void;
  dispose: (publisher: BasePublisher) => void;
}

export enum ToggleMediaStrategy {
  EnabledProperty = 'property',
  Configure = 'configure',
}

export enum DeferCommand {
  Continue = 'continue',
  Defer = 'defer',
  Republish = 'republish',
  Abort = 'abort',
}

export enum DeferReason {
  Configuring = 'configuring',
  NotConnected = 'not_connected',
  NotPeerConnection = 'not_peer_connection',
  SignalNotStable = 'signal_not_stable',
  GiveUp = 'give_up',
}

export interface DeferStatus {
  attempts?: number;
  reason?: DeferReason;
  command: DeferCommand;
}
export interface PublishParams {
  request: 'configure';
  bitrate: number;
  data: boolean;
  audio: boolean;
  video: boolean;
  videocodec?: 'h264' | 'vp8';
}

export interface AttachPluginOptions {
  opaqueId: string;
  delegate: VideoRoomPluginDelegate;
}

const PUBLISH_OWN_FEED_MAX_ATTEMPTS = 10;

const ICE_RESTART_DELAY_MS = 3000;

export class BasePublisher {
  _videoOutboundRTPStats: RTCOutboundRtpStreamStatsComplete;

  audioOutboundRTPStats: RTCOutboundRtpStreamStatsComplete;

  /** Rate of a metric in seconds */
  rateInSeconds: MetricRate = {
    [MetricRateKeys.NackCount]: 0,
    [MetricRateKeys.PliCount]: 0,
    [MetricRateKeys.FirCount]: 0,
    [MetricRateKeys.QualityBandwidth]: 0,
    [MetricRateKeys.QualityCpu]: 0,
  };

  /** An instance of Janus VideoRoom handler this Publisher is using */
  publisherHandle: JanusPluginWrapperType;

  /** Connection status of this Publisher */
  status: ConnectionStatus = ConnectionStatus.Disconnected;

  /** An instance of the local stream this publish has access */
  localStream: MediaStream;

  /** Audio device constraints to be used when publishing */
  audioDeviceConstraints: MediaTrackConstraints;

  /** Video device constraints to be used when publishing */
  videoDeviceConstraints: MediaTrackConstraints;

  configureRequestName: 'configure';

  _toggleMediaStrategy: ToggleMediaStrategy;

  /** Last reconfigure media DeferStatus */
  deferStatus: DeferStatus;

  hangedUp = false;

  _audioTrackProvider: TrackProvider;

  _videoTrackProvider: TrackProvider;

  onUpdatedRates: (rateInSeconds: MetricRate) => void;

  previousIceState: IceState;

  iceRestartStats: IceRestartStatsType = {};

  /**
   * Indicate if Audio should be muted inmediately after getting a configure event.
   */
  shouldMuteAfterConfigure = false;

  encodingPriority: 'very-low' | 'low' | 'medium' | 'high' = null;

  private _isVideoOn = false;

  private _isAudioOn = false;

  private _videoMid: string;

  private _linkQuality: number;

  protected audioMid: string;

  protected dataMid: string;

  protected delegate: PublisherDelegateProtocol;

  protected isConfiguring = false;

  protected bitrateConstraint = 500000;

  get linkQuality(): number | undefined {
    try {
      const lq = this.getLinkQuality();
      if (lq !== this._linkQuality) {
        trackMetric('SFU Publisher Link Quality Change', { from: this._linkQuality, to: lq });
      }
      this._linkQuality = lq;
      return lq;
    } catch (error) {
      log.error('SFU Publisher Get Link Quality Error');
      return undefined;
    }
  }

  get videoMid() {
    return this._videoMid;
  }

  set videoMid(mid) {
    this._videoMid = mid;
  }

  /** Indicate if this publisher is accessing to the Video local stream. */
  get isVideoOn() {
    return this._isVideoOn;
  }

  protected set isVideoOn(on) {
    this._isVideoOn = on;
  }

  /** Indicate if this publisher is accessing to the Audio local stream. */
  get isAudioOn() {
    return this._isAudioOn;
  }

  protected set isAudioOn(on) {
    this._isAudioOn = on;
  }

  get publisherType(): PublisherType {
    throw new Error('publisherType must override in subclasses');
  }

  get peerConnection() {
    return this.publisherHandle?.webrtcStuff?.pc;
  }

  get peerConnectionStatus() {
    return this.peerConnection?.connectionState;
  }

  get iceState() {
    return this.publisherHandle?.webrtcStuff?.pc?.iceConnectionState;
  }

  get videoSender() {
    return this.peerConnection?.getSenders().find((s) => s.track.kind === 'video');
  }

  get audioSender() {
    return this.peerConnection?.getSenders().find((s) => s.track.kind === 'audio');
  }

  get videoCodecMime() {
    const codecs = this.videoSender?.getParameters()?.codecs;
    return codecs?.find((c) => this.videoOutboundRTPStats?.codecId.match(c.payloadType.toString()))?.mimeType;
  }

  get audioCodecMime() {
    const codecs = this.audioSender?.getParameters()?.codecs;
    const codecId = this.audioOutboundRTPStats?.codecId;
    if (!codecId) {
      this.trackMissingAudioCodec();
      return null;
    }
    return codecs?.find((c) => codecId.match(c.payloadType.toString()))?.mimeType;
  }

  get videoOutboundRTPStats() {
    return this._videoOutboundRTPStats;
  }

  set videoOutboundRTPStats(stat: RTCOutboundRtpStreamStatsComplete) {
    if (this._videoOutboundRTPStats && isNumber(stat.nackCount)) {
      this.calculateRateInSeconds(stat, MetricRateKeys.NackCount);
    }
    if (this._videoOutboundRTPStats && isNumber(stat.pliCount)) {
      this.calculateRateInSeconds(stat, MetricRateKeys.PliCount);

      if (
        isNumber(this.rateInSeconds.pliCount) &&
        isNumber(this.rateInSeconds.nackCount) &&
        this.rateInSeconds.pliCount > this.rateInSeconds.nackCount
      ) {
        // trackMetric('SFU BasePublisher PLIs over Nacks', {
        // pliCount: this.rateInSeconds.pliCount,
        //          nackCount: this.rateInSeconds.nackCount,
        //      });
        // log.warn('BasePublisher: PLIs over Nacks');
      }
    }
    if (this._videoOutboundRTPStats && isNumber(stat.firCount)) {
      this.calculateRateInSeconds(stat, MetricRateKeys.FirCount);
    }
    if (this._videoOutboundRTPStats && stat.qualityLimitationDurations?.bandwidth) {
      this.calculateRateInSeconds(stat, MetricRateKeys.QualityBandwidth);
    }
    if (this._videoOutboundRTPStats && stat.qualityLimitationDurations?.cpu) {
      this.calculateRateInSeconds(stat, MetricRateKeys.QualityCpu);
    }
    this._videoOutboundRTPStats = stat;

    if (this.onUpdatedRates) {
      this.onUpdatedRates(this.rateInSeconds);
    }
  }

  get nackRatePerSecond() {
    return this.rateInSeconds.nackCount || 0;
  }

  get firRatePerSecond() {
    return this.rateInSeconds.firCount || 0;
  }

  get pliRatePerSecond() {
    return this.rateInSeconds.pliCount || 0;
  }

  get audioTrackProvider() {
    return this._audioTrackProvider;
  }

  set audioTrackProvider(provider) {
    this._audioTrackProvider = provider;
  }

  get videoTrackProvider() {
    return this._videoTrackProvider;
  }

  set videoTrackProvider(provider) {
    this._videoTrackProvider = provider;
  }

  getLinkQuality() {
    let currentLink = 4;

    if ((!this.audioOutboundRTPStats && !this.videoOutboundRTPStats) || !isNumber(this.nackRatePerSecond)) {
      return currentLink;
    }

    if (
      this.videoOutboundRTPStats?.qualityLimitationReason === 'bandwidth' ||
      this.videoOutboundRTPStats?.targetBitrate < 200000 ||
      this.videoOutboundRTPStats?.framesPerSecond < 10 ||
      this.nackRatePerSecond > 0
    ) {
      currentLink -= 1;
    }

    if (this.audioOutboundRTPStats?.targetBitrate < 30000) {
      currentLink = 2;
    }

    if (this.nackRatePerSecond >= 1.0) currentLink = 2;
    if (this.nackRatePerSecond >= 3.0) currentLink = 1;

    return currentLink;
  }

  private calculateRateInSeconds(stat: RTCOutboundRtpStreamStatsComplete, field: keyof typeof this.rateInSeconds) {
    if (!this.videoOutboundRTPStats) return;
    try {
      const path = field.split('.');
      let existentSample: { [key in keyof Partial<RTCOutboundRtpStreamStatsComplete>]: unknown } =
        this.videoOutboundRTPStats;
      let newSample: { [key in keyof Partial<RTCOutboundRtpStreamStatsComplete>]: unknown } = stat;
      let i = 0;
      while (i < path.length) {
        existentSample = existentSample[path[i] as keyof typeof this.videoOutboundRTPStats];
        newSample = newSample[path[i] as keyof typeof this.videoOutboundRTPStats];
        i += 1;
      }
      const value = (newSample as number) - (existentSample as number) || 0;
      const seconds = (stat.timestamp - this.videoOutboundRTPStats.timestamp) / 1000;
      this.rateInSeconds[field] = value / seconds;
    } catch (e) {
      log.error(`${this.publisherType}: calculateRate failed with error: ${e}`);
    }
  }

  trackMissingAudioCodec = debounce(
    () => {
      trackMetric('SFU Missing Audio Codec');
    },
    600000,
    { leading: true }
  );

  async attachPlugin(options: AttachPluginOptions) {
    log.debug(`SFU Attaching ${this.publisherType} plugin`);
    const pluginOptions: JanusJS.PluginOptions = {
      plugin: 'janus.plugin.videoroom',
      opaqueId: options.opaqueId,
      consentDialog(on) {
        log.debug(`${this.publisherType}: Consent dialog should be ${on ? 'on' : 'off'} now`);
      },
      mediaState: this.onMediaState.bind(this),
      webrtcState: (isConnected: boolean) => {
        options.delegate.onPeerConnectionStatusChange.call(options.delegate, this, isConnected);
      },
      iceState: this.onIceStateChange.bind(this),
      onmessage: this.onMessage.bind(this),
      ondataopen: () => {},
      onlocaltrack: this.onLocalTrack.bind(this),
      onremotetrack: (track, mid, on) => {
        log.error('BasePublisher: Unexpected remote track in a publisher connection: ', track, mid, on);
      },
      slowLink(__uplink, __lost) {
        // TODO: Handle slow link
      },
      oncleanup: () => {}, // delay(options.delegate.dispose.bind(options.delegate), 5000),
      ondetached(err: unknown) {
        log.error(`${this.publisherType}: ondetached`, err);
        this.status = ConnectionStatus.Disconnected;
      },
    };

    return this.delegate.janus.attach(pluginOptions);
  }

  async publishOwnFeed(
    isAudioOn: boolean,
    isVideoOn: boolean,
    useData: boolean,
    attempts: number,
    iceRestart = false,
    forceRestart = false
  ) {
    if (
      this.iceState &&
      !iceRestart &&
      ![IceState.Disconnected, IceState.Failed, IceState.Checking, IceState.Closed].includes(this.iceState as IceState)
    ) {
      if (attempts > PUBLISH_OWN_FEED_MAX_ATTEMPTS) {
        log.error(
          `${this.publisherType}: Will not publish too many attempts: ${attempts}, status is: ${this.iceState}`
        );
        trackMetric('SFU Publisher Own Feed Timeout', { iceState: this.iceState, attempts, iceRestart });
      } else {
        trackMetric('SFU Publisher Will Not Publish', { iceState: this.iceState, attempts, iceRestart });
        log.warn(`${this.publisherType}: Will not publish own feed because ice status is: ${this.iceState}... defer`);
        attempts = attempts || 0;
        delay(this.publishOwnFeed.bind(this), 2000, isAudioOn, isVideoOn, useData, attempts + 1, iceRestart);
      }
      return;
    }

    log.debug(
      `${this.publisherType}: Publishing Own Feed with audio: ${isAudioOn} video: ${isVideoOn} iceRestart: ${iceRestart}`
    );

    if (this.isConfiguring) {
      [log.warn, trackMetric].map((f) => f(`SFU ${this.publisherType} Republish Attempt`));
      log.warn(`${this.publisherType}: is already publishing a feed, cancel...`);
      return;
    }

    if (
      !iceRestart &&
      this.publisherHandle?.webrtcStuff?.pc?.connectionState === PeerConnectionStatus.Connected &&
      isVideoOn === this.isVideoOn &&
      isAudioOn === this.isAudioOn
    ) {
      log.debug(`${this.publisherType}: Already connected with audio and video, not republishing`);

      return;
    }

    this.isVideoOn = isVideoOn;
    this.isAudioOn = isAudioOn;

    if (this.status !== ConnectionStatus.Connected) {
      log.error(`${this.publisherType}: Trying to start streaming with PC Status: ${this.status}`);
      return;
    }

    // At this point we should have data, so enable video and audio
    let offer: OfferParams = { tracks: [] };

    if (useData) {
      offer.tracks.push({
        type: 'data',
        add: true,
        mid: this.dataMid,
      });
    }

    if (isVideoOn) {
      this.videoTrackProvider = getCurrentVideoTrackProvider();
      const videoTrack = await this.videoTrackProvider.getTrack(this.videoDeviceConstraints);
      offer.tracks.push({
        type: 'video',
        mid: this.videoMid,
        add: true,
        capture: this.sanitizeCapture(videoTrack),
      });
    }

    // FIXME: What does happen when people do not have Mic or allowed access to it???
    try {
      if (isAudioOn || this.shouldMuteAfterConfigure) {
        this.audioTrackProvider = getCurrentAudioTrackProvider();
        const audioTrack = await this.audioTrackProvider.getTrack(this.audioDeviceConstraints);
        offer.tracks.push({
          type: 'audio',
          mid: this.audioMid,
          add: true,
          capture: this.sanitizeCapture(audioTrack),
        });
      }
    } catch (e) {
      log.warn(`${this.publisherType}: Couldn't access audio to publish early, error: ${e}`);
    }

    if (iceRestart) {
      if (this.iceState === IceState.Connected && !forceRestart) {
        log.warn(`${this.publisherType}: ICE Restart cancelled, ice status: ${this.iceState}`);
        this.iceRestartStats.cancelled = (this.iceRestartStats.cancelled || 0) + 1;
        this.delegate.iceRestartCancelled(this.iceState);
        return;
      }
      this.iceRestartStats[this.iceState] = (this.iceRestartStats[this.iceState] || 0) + 1;
      offer = { iceRestart };
      log.warn(`${this.publisherType}: ICE Restart with status: ${this.iceState}`);

      if (!this.iceState) {
        try {
          trackMetric('SFU Publisher Republish Cant Restart', {
            isAudioOn: this.isAudioOn,
            isVideoOn: this.isVideoOn,
            ice: this.iceState,
          });
          this.publishOwnFeed(this.isAudioOn, this.isVideoOn, false, 0);
        } catch (e) {
          trackMetric('SFU Publisher Republish Cant Republish', {
            isAudioOn: this.isAudioOn,
            isVideoOn: this.isVideoOn,
            ice: this.iceState,
            errorName: e.name,
            error: e.message,
          });
          this.delegate.noICE(this);
        }
        return;
      }

      trackMetric('SFU Publisher ICE Restart Attempt', {
        publisherType: this.publisherType,
        iceState: this.iceState,
        forceRestart,
      });
    }

    try {
      await this.sendPublishRequest(offer);
    } catch (error) {
      trackMetric('SFU Send Publisher Request Error', {
        publisherType: this.publisherType,
        errorName: error.name,
        errorMessage: error.message,
        error: error.message,
      });
      this.isConfiguring = false;

      log.error(`${this.publisherType} WebRTC error: ${JSON.stringify(error)}`);

      this.filterAndShowDeviceError(error, offer.tracks?.map((t) => t.type) || []);
    }
  }

  protected filterAndShowDeviceError(error: Error, mediaType?: OfferType[]) {
    if (
      error &&
      [
        'NotFoundError',
        'NotReadableError',
        'OverconstrainedError',
        'SecurityError',
        'NotAllowedError',
        'AbortError',
      ].includes(error.name)
    ) {
      showDeviceError(error, mediaType);
    }
  }

  customizeSdp(_jsep: { sdp: string; type: 'offer' | 'answer' }) {
    throw new Error('Implement in sub classes');
  }

  onMediaState(medium: MediaType, on: boolean, mid: number) {
    log.debug(`${this.publisherType}: Janus ${on ? 'started' : 'stopped'} receiving our ${medium}, mid: ${mid}`);
    trackMetric(`SFU Janus ${on ? 'started' : 'stopped'} receiving our ${medium}`, { type: this.publisherType });
    if (medium === 'video') {
      // this.tuneVideoEncodingProperties();
    } else if (medium === 'audio') {
      // this.tuneAudioEncodingProperties();
    }
  }

  restoreICE(_forceIceRestart = false) {
    throw new Error('Should implement in sub classes');
  }

  private onIceStateChange(state: IceState) {
    log.warn(`${this.publisherType}: ICE connection state ${this.previousIceState} -> ${state}`);
    if (
      this.publisherHandle &&
      !this.publisherHandle.detached &&
      this.status === ConnectionStatus.Connected &&
      (state === IceState.Failed || state === IceState.Disconnected)
    ) {
      log.warn(`Room: Ice State went wrong: ${this.iceState} -> ${state}`);
      trackMetric('SFU Publisher ICE State Failed', { previous_ice: this.previousIceState, current_ice: state });
    }

    if (state === IceState.Failed) {
      // Let Watchdog grab this condition.
    } else if (state === IceState.Disconnected) {
      delay(this.restoreICE.bind(this), ICE_RESTART_DELAY_MS);
    }

    this.previousIceState = state;
  }

  onMessage(msg: JanusJS.Message, jsep: JanusJS.JSEP) {
    const event = msg.videoroom;
    if (event) {
      if (jsep) {
        let bundlesStr = '';
        try {
          [bundlesStr] = jsep.sdp.match(/bundle.*/i);
        } catch (e) {
          bundlesStr = '';
        }

        log.debug(`${this.publisherType}: Got VideoRoom event with jsep ${bundlesStr}`);
      } else {
        log.debug(`${this.publisherType}: Got VideoRoom event`);
      }
      this.dispatchVideoRoomEvent(event, msg);
    }
    if (jsep) {
      this.onJSEP(jsep, msg);
    }
  }

  dispatchVideoRoomEvent(event: string, msg: JanusJS.Message) {
    this.dispatchRoomEventMessage(msg);
    this.delegate.dispatchVideoRoomEvent(this, event, msg);
  }

  dispatchRoomEventMessage(msg: JanusJS.Message) {
    if (msg.error !== undefined && msg.error !== null) {
      log.error(`Room: VideoRoom publisherHandle error - ${msg.error}`);
      // FIXME: We should handle this errors based on Janus error code table.
      this.onConfigureFailed();
    } else if (msg.configured) {
      trackMetric('SFU Publisher Configured OK');
      log.debug(`Room: VideoRoom configured: ${JSON.stringify(msg.streams, null, 2)}`);
      this.onConfigureSuccess(msg.streams);
    } else if (msg.streams) {
      log.error('Room: Unexpected message: ', msg);
    }
  }

  onLocalTrack(_localTrack: MediaStreamTrack, _on: boolean) {
    throw new Error('onLocalTrack must override in sub classes!');
  }

  onJSEP(_jsep: JanusJS.JSEP, _msg: JanusJS.Message) {
    throw new Error('onJSEP must override in sub classes!');
  }

  onConfigureFailed() {
    throw new Error('onConfigureFailed must override in sub classes!');
  }

  onConfigureSuccess(_configuredTracks: ConfiguredTrack[]) {
    throw new Error('onConfigureSuccess override in sub classes!');
  }

  async sendPublishRequest(_offer: OfferParams) {
    throw new Error('sendPublishRequest override in sub classes!');
  }

  resetRates() {
    this.rateInSeconds.firCount = 0;
    this.rateInSeconds.pliCount = 0;
    this.rateInSeconds['qualityLimitationDurations.bandwidth'] = 0;
    this.rateInSeconds['qualityLimitationDurations.cpu'] = 0;
  }

  sanitizeCapture(track: MediaStreamTrack & { dontStop?: boolean }): MediaStreamTrack | null {
    if (track) {
      trackMetric('SFU Sanitize Track', { enabled: track.enabled });
      if (!track.enabled) {
        track.enabled = true;
      }
      track.dontStop = true; // Look at janus.js
      log.debug('BasePublisher: Forcing non stop track');
    }
    return track;
  }

  async tuneVideoEncodingProperties() {
    let senderParams;
    try {
      trackMetric('SFU Publiser Tune Video Encoding Params', { publisherType: this.publisherType });
      senderParams = this.videoSender?.getParameters();
      if (!senderParams) {
        return;
      }
      if (!senderParams.encodings) {
        senderParams.encodings = [{}];
      }
      if (this.encodingPriority) {
        senderParams.encodings[0].priority = this.encodingPriority;
      }
      await this.videoSender.setParameters(senderParams);
    } catch (e) {
      trackMetric('SFU Publiser Tune Video Encoding Params Error', {
        publisherType: this.publisherType,
        errorName: e.name,
        errorMessage: e.message,
        senderParams: JSON.stringify(senderParams || {}),
      });
    }
  }

  async tuneAudioEncodingProperties() {
    const senderParams = this.audioSender?.getParameters();
    if (!senderParams) {
      return;
    }
    if (!senderParams.encodings) {
      senderParams.encodings = [{}];
    }
    senderParams.encodings[0].priority = 'high';
    // senderParams.encodings[0].maxBitrate = 48000;
    await this.audioSender.setParameters(senderParams);
  }
}
