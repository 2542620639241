import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { AnotherNextNavButton } from '../shared-styled';
import { goNextScreen } from '../../store/signing-in/actions';
import ReactionsPicker from '../../user-profile/ReactionsPicker';
import LottieAnimation from '../../animations/LottieAnimation';

import animationData from '../../../../assets/lottie/keyboard-with-emojis.json';

const ReactionsScreen = () => {
  const dispatch = useDispatch();

  return (
    <Container>
      <Title>Customize your Reactions</Title>
      <Subtitle>
        You can always change these later when <br /> editing your profile.
      </Subtitle>
      <ReactionsContainer>
        <ReactionsPicker />
        <SubtleHint>
          <AnimationContainer>
            <LottieAnimation animationData={animationData} loop />
          </AnimationContainer>
          React while watching and <br />
          playing with your friends!
        </SubtleHint>
      </ReactionsContainer>
      <div>
        <AnotherNextNavButton data-testid="next-button" onClick={() => dispatch(goNextScreen({}))}>
          Next
        </AnotherNextNavButton>
      </div>
    </Container>
  );
};

export default ReactionsScreen;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: white;
  font-family: Nunito, Inter, sans-serif;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-height: 690px) {
    margin-bottom: 8px;
  }
`;

const Subtitle = styled.h2`
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  text-align: center;
`;

const ReactionsContainer = styled.div`
  position: relative;
  margin: 50px 0;

  @media (max-height: 690px) {
    margin: 8px 0;
  }
`;

const SubtleHint = styled.div`
  position: absolute;
  left: -308px;
  top: 94px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  background: linear-gradient(90deg, #12002d 0%, rgba(18, 0, 45, 0) 100%);
  border-radius: 20px;
  padding: 40px 60px 40px 40px;
  z-index: -1;
  font-weight: bold;
`;

const AnimationContainer = styled.div`
  width: 120px;
  margin-left: -7px;
  margin-top: -60px;
`;
