import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ellipsisTextMixin } from '../../../mixins';
import { DM_WINDOW_TOPBAR_HEIGHT_PX } from '../../chats-shared-styles';
import ChatPresence from '../ChatPresence';

function ChatWindowTopBar({ chatTitle, username, controls, memberIds }) {
  const containerRef = useRef(null);

  return (
    <ChatWindowTopbarContainer ref={containerRef}>
      <ChatWindowTopbarTitle>
        <ChatWindowTopbarTitleName className="themed-font">{chatTitle}</ChatWindowTopbarTitleName>
        {username && <ChatWindowTopbarTitleUsername>{`@${username}`}</ChatWindowTopbarTitleUsername>}
        {memberIds.length ? <ChatPresence memberIds={memberIds} /> : null}
      </ChatWindowTopbarTitle>

      {controls}
    </ChatWindowTopbarContainer>
  );
}

export default ChatWindowTopBar;

ChatWindowTopBar.propTypes = {
  chatTitle: PropTypes.string,
  username: PropTypes.string,
  controls: PropTypes.node,
  theme: PropTypes.shape({
    colors: PropTypes.shape({}),
    primaryFont: PropTypes.string,
  }),
  memberIds: PropTypes.arrayOf(PropTypes.string),
};

ChatWindowTopBar.defaultProps = {
  chatTitle: '',
  username: null,
  controls: null,
  theme: null,
  memberIds: [],
};

export const ChatWindowTopbarContainer = styled.div`
  padding: 10px 22px;
  height: ${DM_WINDOW_TOPBAR_HEIGHT_PX}px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
  position: relative;
`;

const ChatWindowTopbarTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
`;

const ChatWindowTopbarTitleName = styled.h4`
  margin: 0;
  color: var(--primary-foreground, white);
  font-size: 16px;
  font-weight: 600;

  ${ellipsisTextMixin()}
`;

const ChatWindowTopbarTitleUsername = styled.h5`
  margin: 0;
  color: #ffffff88;
  font-size: 14px;
  font-weight: 400;

  ${ellipsisTextMixin()}
`;
