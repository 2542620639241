/* eslint-disable @typescript-eslint/lines-between-class-members */
import { ConnectionStatus } from './definitions/index.definitions';
import { JanusWrapperType } from './janus/wrapper';
import { fetchAndWaitForVideoServer } from '../util/video-server-util';
import Janus from './janus';
import log from '../log';
import { asyncThrottle } from './util';
import { trackInForeground as _trackMetric } from '../util/analytics-util';

export interface SessionDelegateProtocol {
  beforeSessionCreate: () => Promise<void>;
  afterSessionConnected: (janus: JanusWrapperType) => void;
  afterSessionDestroyed: () => void;
  beforeSessionReconnect: () => void;
  afterSessionReconnect: () => void;
  afterSessionReconnectFailed: () => void;
}

const DEFAULT_SERVER_PREFIX = 'v';
const RECONNECT_INTERVAL = 10000;

const ICE_SERVER_URLS: RTCIceServer[] = [
  { urls: 'stun:turn3.here.fm:3478' },
  {
    urls: ['turn:turn3.here.fm:3478?transport=udp', 'turns:turns.here.fm:443?transport=tcp'],
    username: 'officeparty',
    credential: 'officeparty',
  },
];

export default class Session {
  status: ConnectionStatus;
  server: string = null;

  private janus: JanusWrapperType = null;
  private delegate: SessionDelegateProtocol;
  private isReconnecting = false;
  private disposedSilently = false;

  constructor(delegate: SessionDelegateProtocol) {
    this.delegate = delegate;
  }

  private trackMetric(name: string, props: { [key: string]: unknown } = {}) {
    _trackMetric(name, {
      sessionStatus: this.status,
      isReconnecting: this.isReconnecting,
      ...props,
    });
  }

  sessionSuccess() {
    log.log('Session: Connected');
    this.trackMetric('SFU Session Connected');
    this.status = ConnectionStatus.Connected;
    this.delegate.afterSessionConnected(this.janus);
    if (this.isReconnecting) {
      this.isReconnecting = false;
      log.log('Session: Successfully re-connected');
      this.trackMetric('SFU Session Reconnect Success');
      this.delegate.afterSessionReconnect();
    }
  }

  sessionError(errorStr: string) {
    this.trackMetric('SFU Session Error', { errorMessage: errorStr });
    log.error(`Session: session error: ${errorStr}`);
    if (!this.janus?.isConnected()) {
      this.status = ConnectionStatus.Disconnected;
    }
    if (!this.janus?.isConnected()) {
      this.reconnect();
    }
  }

  sessionDestroyed() {
    this.status = ConnectionStatus.Disconnected;
    log.warn("Session: I've been destroyed");
    this.trackMetric('SFU Session Destroyed');
    if (!this.disposedSilently) this.delegate.afterSessionDestroyed();
  }

  async createSession(server: string): Promise<boolean> {
    this.trackMetric('SFU Create Session');
    if (this.status === ConnectionStatus.Connected) {
      throw new Error('Attempt to create over an already Connected session.');
    }

    this.status = ConnectionStatus.Connecting;

    if (!server) {
      server = DEFAULT_SERVER_PREFIX;
    }
    this.server = server;

    this.delegate.beforeSessionCreate();

    this.janus = new Janus({
      server: `wss://${server}.here.fm:443/janus`,
      iceServers: ICE_SERVER_URLS,
      success: this.sessionSuccess.bind(this),
      error: this.sessionError.bind(this),
      destroyed: this.sessionDestroyed.bind(this),
    });

    return this.janus.isConnected();
  }

  async dispose(disposedSilently = false) {
    this.disposedSilently = disposedSilently;
    this.status = ConnectionStatus.Disconnected;
    log.debug('Session: Destroying session ', this.janus?.getSessionId());

    if (!this.janus) return;

    try {
      await this.janus.destroy({ unload: true });
    } catch (error) {
      log.error('Session: Error destroying janus session', error);
    }
    this.janus = null;
  }

  reconnect = asyncThrottle(
    async (force = false) => {
      this.trackMetric('SFU Session Reconnect Attempt', { force, status: this.status });

      if (this.status !== ConnectionStatus.Disconnected) {
        if (force) {
          log.warn(`Session: Forcing reconnect, status: ${this.status}`);
        } else {
          log.warn('Session: status is ', this.status, ' bailing on reconnect');
          return;
        }
      }
      this.dispose();

      this.isReconnecting = true;

      this.delegate.beforeSessionReconnect();

      try {
        this.status = ConnectionStatus.Connecting;
        const server = await fetchAndWaitForVideoServer(window.currentBoardId, true);
        log.warn('Session: Forcing Reconnect to ', server);
        await this.createSession(server);
      } catch (error) {
        log.error('Session: Reconnect Failed:', error);
        this.trackMetric('SFU Session Reconnect Error', { errorMessage: error.message });
        this.delegate.afterSessionReconnectFailed();
      }
    },
    RECONNECT_INTERVAL,
    { trailing: false }
  );
}
