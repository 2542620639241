import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// redux
import { friendsAsArraySelector } from '../store/friends/selectors';

// components
import FriendsListItem from './FriendsSearchableListItem.tsx';
import { buttonMixin, svgColorMixin } from '../mixins';
import { renderFriendsModal } from './render';

// constants
import { FRIENDS_MODAL_TABS } from '../../constants/friends-modal-constants.ts';

// icons
import PaperplaneIcon from '../../../assets/icons/paperplane-icon.svg';
import SearchIcon from '../../../assets/icons/search.svg';

function FriendsSearchableList({ onFriendClick, setPopoverOpen, canSearchForFriends }) {
  const [friendsFilter, setFriendsFilter] = React.useState('');
  const [friendsFilterFocused, setFriendsFilterFocused] = React.useState(false);

  const friendsList = useSelector(friendsAsArraySelector);
  const hasFriends = useMemo(() => friendsList.length > 0, [friendsList]);

  const filteredFriendsList = React.useMemo(
    () =>
      friendsList.filter((friendData) => {
        let nameMatch = false;
        if (friendData.displayName) {
          nameMatch = friendData.displayName.toLowerCase().includes(friendsFilter.toLowerCase());
        }

        let usernameMatch = false;
        if (friendData.username) {
          usernameMatch = friendData.username.toLowerCase().includes(friendsFilter.toLowerCase());
        }

        return nameMatch || usernameMatch;
      }),
    [friendsList, friendsFilter]
  );

  const onFriendsFilterChange = (event) => {
    setFriendsFilter(event.target.value);
  };

  const onFriendClickLocal = (activeReceiverId, chatId) => {
    // clear filter
    setFriendsFilter('');

    onFriendClick(activeReceiverId, chatId);
  };

  const openAddFriendsModal = () => {
    setPopoverOpen(false);
    renderFriendsModal(FRIENDS_MODAL_TABS.add);
  };

  return (
    <FriendsSearchableListContainer>
      <PopoverTitle>New Message</PopoverTitle>
      <FriendsFilterContainer friendsFilterFocused={friendsFilterFocused}>
        <FriendsFilterLabel aria-disabled={!hasFriends}>
          <PaperplaneIcon />

          <FriendsFilterLabelText>To: </FriendsFilterLabelText>
        </FriendsFilterLabel>
        <FriendsFilter
          value={friendsFilter}
          onChange={onFriendsFilterChange}
          onFocus={() => setFriendsFilterFocused(true)}
          onBlur={() => setFriendsFilterFocused(false)}
          disabled={!hasFriends}
        />
      </FriendsFilterContainer>
      <FriendsListTitle>All Friends</FriendsListTitle>
      <FriendsListContainer>
        {hasFriends &&
          filteredFriendsList.map((friendData) => (
            <FriendsListItem
              key={friendData.userId}
              userId={friendData.userId}
              onlineStatus={friendData.onlineStatus}
              onFriendClick={onFriendClickLocal}
            />
          ))}

        {/* no friends at all :'( */}
        {!hasFriends && (
          // TODO: can be moved to the component to share 'no friends'-state with app\src\react\friends\FriendsList.jsx
          <NoFriendsContainer>
            <img width="227" src="/images/misc/no-friends-smiley-dudes.svg" alt="No friends" />
            <NoFriendsTitle>Your friends list is empty</NoFriendsTitle>
            {canSearchForFriends ? (
              <NoFriendsSubtitle>
                You can only message friends.{' '}
                <SearchForFriends onClick={openAddFriendsModal}>Search for friends</SearchForFriends> or invite them to
                Here to start chatting.
              </NoFriendsSubtitle>
            ) : (
              <NoFriendsSubtitle>
                You can only message friends. Invite your friends to join or find them in search!
              </NoFriendsSubtitle>
            )}
          </NoFriendsContainer>
        )}

        {/* user has friends but current filter doesn't match one */}
        {hasFriends && !filteredFriendsList.length && (
          <NoFriendsContainer>
            <img src="images/misc/no-friend-requests-tear.svg" alt="smiley" />
            <NoFriendsTitle>No Matches</NoFriendsTitle>
            <FindFriendsButton onClick={openAddFriendsModal}>
              <SearchIconStyled width={12} height={11} />
              Find Friends
            </FindFriendsButton>
          </NoFriendsContainer>
        )}
      </FriendsListContainer>
    </FriendsSearchableListContainer>
  );
}

FriendsSearchableList.propTypes = {
  onFriendClick: PropTypes.func.isRequired,
  setPopoverOpen: PropTypes.func,
  canSearchForFriends: PropTypes.bool,
};

FriendsSearchableList.defaultProps = {
  setPopoverOpen: () => {},
  canSearchForFriends: false,
};

export const PopoverTitle = styled.h3`
  margin: 0;
  padding: 0;
  align-self: flex-start;
  font-size: 14px;
  font-weight: bolder;
  font-family: Inter;
  text-align: left;
`;

export const FriendsFilterContainer = styled.div`
  margin-top: 7px;
  height: 45px;
  width: 100%;
  display: flex;
  border-radius: 10px;

  outline: ${({ friendsFilterFocused }) => (friendsFilterFocused ? '2px solid #6b1be3' : 'none')};
`;

export const FriendsFilterLabel = styled.label`
  padding-left: 15px;
  height: 100%;
  width: 70px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #12002d19;
  border-radius: 10px 0 0 10px;

  &[aria-disabled='true'] {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const FriendsFilterLabelText = styled.span`
  font-size: 14px;
  font-weight: bolder;
  font-family: Inter;
  color: #12002d;
`;

export const FriendsFilter = styled.input`
  padding: 5px 10px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  color: #12002d;
  font-size: 14px;
  border: none;
  border-radius: 0 10px 10px 0;
  background-color: #12002d19;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const FriendsListTitle = styled.h4`
  margin: 15px 0 0 0;
  align-self: flex-start;
  font-size: 13px;
  font-weight: bolder;
  font-family: Inter;
  text-align: left;
`;

export const FriendsListContainer = styled.div`
  margin-top: 7px;
  width: 100%;
`;

export const NoFriendsContainer = styled.div`
  position: relative;
  padding: 55px 0;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 10px;
`;

export const NoFriendsTitle = styled.h4`
  margin: 10px 0 0 0;
  font-size: 16px;
  font-weight: 800;
`;

export const NoFriendsSubtitle = styled.h5`
  margin: 10px 0 0 0;
  width: 200px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`;

export const SearchForFriends = styled.button`
  cursor: pointer;
  font-weight: bold;
  display: inline;
  color: #6b1be3ef;
  text-decoration: underline;
  border: none;
  padding: 0;
`;

const FriendsSearchableListContainer = styled.div`
  width: 100%;
`;

const FindFriendsButton = styled.div`
  ${buttonMixin()}
  display: flex;
  background-color: #6b1be3;
  flex-direction: row;
  padding: 12px 30px;
  width: fit-content;
  margin-top: 18px;
  gap: 8px;
  font-weight: bold;
  color: white;
  &:hover {
    background-color: rgba(97, 44, 218, 0.8);
  }
`;

const SearchIconStyled = styled(SearchIcon)`
  ${svgColorMixin('white')};
`;

export default FriendsSearchableList;
