import { elementClasses } from './elements';

export const getEmbedElementProperties = (linkSummary: { tiktokVideoId?: string; youtubeVideoId: string }) => {
  let elementClass;
  let videoId;
  if (linkSummary.tiktokVideoId) {
    elementClass = elementClasses.TIKTOK_EMBED;
    videoId = linkSummary.tiktokVideoId;
  } else if (linkSummary.youtubeVideoId) {
    elementClass = elementClasses.YOUTUBE_EMBED;
    videoId = linkSummary.youtubeVideoId;
  } else {
    return null;
  }
  return { class: elementClass, videoId };
};
