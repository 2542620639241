import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import StarburstIcon from '../../../assets/icons/starburst.svg';
import { StickerPack, StickerPacks as Packs } from '../../definitions/emotes';
import { addFromEmotePackRequest, fetchStickerPacksRequest, removeEmoteRequest } from '../store/emotes/actions';
import { noScrollbarMixin, svgColorMixin } from '../mixins';
import EmoteButton from './EmoteButton';
import { selectStickerPacks } from '../store/emotes/selectors';
import { useStickerPacksNav } from './useStickerPacksNav';
import CloseIcon from '../../../assets/icons/dismiss.svg';

const defaultStarburstColor = 'rgba(136,136,136,0.5)';
const getColorByPack = (key: Packs | string) => {
  if (key === Packs.HERE) {
    return '#6B1BE3';
  }
  if (key === Packs.SANRIO) {
    return '#FF4FD8';
  }
  if (key === Packs.MEME) {
    return '#588C3D';
  }
  // default case
  return defaultStarburstColor;
};

interface Props {
  onClose: () => void;
}

const StickerPacks: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const packs = useSelector(selectStickerPacks);

  const { current, setPackRef, scrollToSection } = useStickerPacksNav();

  useEffect(() => {
    dispatch(fetchStickerPacksRequest());
  }, [dispatch]);

  const addEmote = (url: string, pack: string) => {
    dispatch(addFromEmotePackRequest({ url, pack }));
  };

  const removeEmote = (url: string) => {
    dispatch(removeEmoteRequest({ url }));
  };

  return (
    <Container>
      <Nav>
        <NavTitle>Packs</NavTitle>
        {packs.map((pack: StickerPack, index: number) => (
          <NavItem key={pack.id} isCurrent={pack.id === current} onClick={() => scrollToSection(index)}>
            {pack.label}
          </NavItem>
        ))}
      </Nav>
      <AllEmotesContainer>
        <Title>All Emotes</Title>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {packs.map((pack: StickerPack, index: number) => (
          <EmotePack key={pack.id} id={pack.id} ref={(el) => setPackRef(el, index)}>
            <EmotePackHeader starburstColor={getColorByPack(pack.id)}>
              <StyledStarburstIcon />
              {pack.label}
            </EmotePackHeader>
            <EmotesContainer>
              {pack.urls.map((url: string) => (
                <EmoteButton
                  key={url}
                  url={url}
                  onAddClick={() => addEmote(url, pack.id)}
                  onRemoveClick={() => removeEmote(url)}
                />
              ))}
            </EmotesContainer>
          </EmotePack>
        ))}
      </AllEmotesContainer>
    </Container>
  );
};

export default StickerPacks;

const Container = styled.div`
  display: flex;
`;

const Nav = styled.div`
  background: #e9e9e9;

  width: 218px;
  padding: 30px;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  box-sizing: border-box;
`;

const NavTitle = styled.div`
  color: black;
  font-size: 16px;
  font-weight: 700;
`;

const NavItem = styled.div<{ isCurrent: boolean }>`
  color: rgb(83, 81, 81);
  font-size: 14px;
  font-weight: 500;

  margin-top: 10px;
  padding: 4px 12px;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: rgb(223, 223, 223);
  }

  ${({ isCurrent }) =>
    isCurrent &&
    `
    background-color: rgb(107, 27, 227);
    color: white;
    font-weight: 600;

    &:hover {
      background-color: rgb(107, 27, 227);
    }
  `}
`;

const CloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;

  cursor: pointer;
  margin-top: -5px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const AllEmotesContainer = styled.div`
  padding: 30px 60px;
  height: 570px;
  overflow: scroll;

  ${noScrollbarMixin()}
`;

const Title = styled.div`
  color: #12002d;
  font-size: 16px;
  font-weight: 700;

  margin-bottom: 55px;
`;

const EmotePack = styled.div`
  margin-bottom: 55px;
`;

const StyledStarburstIcon = styled(StarburstIcon)`
  margin-right: 10px;
  margin-top: -2px;
`;

const EmotePackHeader = styled.div<{ starburstColor: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  color: black;
  font-size: 17px;
  font-weight: 700;

  ${({ starburstColor }) => svgColorMixin(starburstColor || '6B1BE3')}
`;

const EmotesContainer = styled.div`
  background: rgba(136, 136, 136, 0.1);
  border-radius: 8px;
  padding: 20px 40px;

  display: grid;

  grid-template-columns: repeat(6, 1fr);
  gap: 14px;
`;
