import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const OfflineCircle = ({ backgroundColor, borderColor }) => (
  <OfflineCircleContainer>
    <BackgroundCircle borderColor={borderColor} />
    <MiddleCircle color={backgroundColor} hasBorder={Boolean(borderColor)} />
  </OfflineCircleContainer>
);

export default OfflineCircle;

OfflineCircle.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
};

OfflineCircle.defaultProps = {
  borderColor: '',
};

const OfflineCircleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BackgroundCircle = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #888096;
  border-radius: 50%;

  ${({ borderColor, theme }) => borderColor && `border: ${theme?.statusBorderWidth || 2}px solid ${borderColor};`}
`;

const MiddleCircle = styled.div`
  width: ${({ theme }) => theme?.offlineStatusMiddleCircleSize || 4}px;
  height: ${({ theme }) => theme?.offlineStatusMiddleCircleSize || 4}px;
  border-radius: 50%;
  background: ${({ color }) => color};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
