export const CREATE_GROUP = 'Create Group'; //  vibe: customVibeBackgroundUrl ? 'Custom' : vibeId
export const ADD_GROUP_MEMBERS = 'Add Group Members';
export const REMOVE_GROUP_MEMBER = 'Remove Group Member';
export const VIEW_GROUP = 'View Group'; // memberCount
export const JOIN_GROUP = 'Join Group';
export const LEAVE_GROUP = 'Leave Group';
export const DELETE_GROUP = 'Delete Group';
export const RENAME_GROUP = 'Rename Group';
export const CHANGE_GROUP_THEME = 'Change Group Theme'; // theme: string; groupId: string; oldTheme: string
export const CHANGE_GROUP_CUSTOM_THEME_COLOR = 'Change Group Custom Theme Color'; // property: string; groupId: string
export const CHANGE_GROUP_FONT = 'Change Group Font'; // font: font-family
export const CHANGE_GROUP_BACKGROUND = 'Change Group Background'; // custom: boolean
export const GROUP_NOTIFICATIONS_ON = 'Enable Group Notifications';
export const GROUP_NOTIFICATIONS_OFF = 'Disable Group Notifications';
export const SEND_GROUPS_FEEDBACK = 'Send Groups Feedback';
export const NEED_HELP_WATCHING_LINK_CLICKED = 'Need Help Watching Link Clicked';
export const NEED_HELP_VOICE_CALLING_LINK_CLICKED = 'Need Help Voice Calling Link Clicked';
export const ATTACH_EXISTING_ROOM_TO_GROUP = 'Attached Existing Room to Group'; // groupId, roomId
export const UPLOAD_GROUP_BACKGROUND_IMAGE = 'Upload Group Background Image';
export const COPY_HUB_LINK = 'Copy Hub Link'; // groupId, memberCount, source (CopyHubLinkSourceTypes)
export const SHARE_MOOD_TO_HUBS_MURAL = 'Share Mood to Hub Mural'; // hubCount

export enum CopyHubLinkSourceTypes {
  NEW_HUB_INVITE_MODAL = 'New Hub Invite Modal',
  ADD_MEMBERS_MODAL = 'Add Members Modal',
}

export const DELETE_ROOM_FROM_GROUP = 'Delete Room from Group'; // groupId, roomId, source: 'rooms tab' or 'hub page'
export enum DeleteRoomFromGroupSources {
  ROOMS_TAB = 'rooms tab',
  HUB_PAGE = 'hub page',
}

export const FORMAT_GROUP_ERROR = 'Error formatting group'; // error: string
export enum FormatGroupErrorTypes {
  WEBSOCKET_TYPE = 'websocket',
  FETCH_GROUP_TYPE = 'fetch group',
  CREATE_GROUP_TYPE = 'create group',
  JOIN_GROUP_TYPE = 'join group',
}

// For analytics tracking only
// There is no vibe ID when choosing the custom vibe on web, but we are passing this vibe ID
// which mobile uses because they must create a group first before uploading a custom background
export const CUSTOM_VIBE_ID = 'MjV3SUH97P9tt4cc55mt';
