import React from 'react';

// components
import {
  ActiveMinutes,
  Avatar,
  AvatarContainer,
  Container,
  DisplayName,
  InitalIcon,
  PositionLabel,
} from './shared-styles';

interface RoomTopMemberProps {
  position: number;
  avatarUrl: string | null;
  displayName: string | null;
  activeMinutes: number;
  isSelf?: boolean;
}

const RoomTopMember = ({ position, avatarUrl, displayName, activeMinutes, isSelf = false }: RoomTopMemberProps) => (
  <Container>
    <AvatarContainer>
      <PositionLabel isSelf={isSelf}>#{position}</PositionLabel>
      {avatarUrl ? <Avatar src={avatarUrl} /> : <InitalIcon>{(displayName || '')[0]}</InitalIcon>}
    </AvatarContainer>
    <DisplayName>{displayName || ''}</DisplayName>
    <ActiveMinutes>{activeMinutes} min</ActiveMinutes>
  </Container>
);

export default RoomTopMember;
