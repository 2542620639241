export const ANIMATION_NAMES = {
  confettiBurst: 'confetti-burst',
  avatarsSmash: 'avatars-smash',
};

// Presets for complex animations that are consist of base ones.
export const COMPLEX_ANIMATIONS = {
  confettiWithAvatars: [
    {
      name: ANIMATION_NAMES.avatarsSmash,
      additionalData: {
        duration: 600,
      },
    },
    {
      name: ANIMATION_NAMES.confettiBurst,
      delay: 200,
    },
  ],
};
