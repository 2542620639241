import { isRoomManagementAllowed } from '../../roles-management';
import { disableViewersMode } from '../../room';

import template from './on-air.html';
import './on-air.less';

export default class OnAir extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;
    this.querySelector('.on-air').addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.querySelector('.on-air').addEventListener('mouseleave', this.onMouseLeave.bind(this));
    this.querySelector('.off-button').addEventListener('click', disableViewersMode);
    if (this.getAttribute('dark')) {
      this.querySelector('.on-air-caption').style.color = 'black';
    }
    this.isInteractive = !!this.getAttribute('interactive');
  }

  onMouseEnter() {
    if (this.isInteractive && isRoomManagementAllowed()) {
      this.querySelector('.off-button-container').style.display = null;
      this.querySelector('.on-air-caption').style.display = 'none';
    }
  }

  onMouseLeave() {
    this.querySelector('.off-button-container').style.display = 'none';
    this.querySelector('.on-air-caption').style.display = null;
  }
}
window.customElements.define('here-on-air', OnAir);
