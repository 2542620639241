import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function RotateFadeIn({ children, time }) {
  return <RotateFadeInAnimationWrapper time={time}>{children}</RotateFadeInAnimationWrapper>;
}

RotateFadeIn.propTypes = {
  children: PropTypes.node.isRequired,
  time: PropTypes.number,
};

RotateFadeIn.defaultProps = {
  time: 0.4,
};

const rotateFadeInAnimation = css`
  @keyframes rotate-fade-in-animation {
    0% {
      opacity: 0;
      transform: rotate(90deg);
    }

    60% {
      opacity: 1;
      transform: rotate(0deg);
    }

    65% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
`;

const RotateFadeInAnimationWrapper = styled.div`
  ${rotateFadeInAnimation}

  transform-origin: top left;
  animation: ${({ time }) => `rotate-fade-in-animation ${time}s ease-in-out`};
`;

export default RotateFadeIn;
