import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../../../store/Wrapper';
import MoodChatHead from './MoodChatHead';

export function renderChatHeadMood(element: ReactDOM.Container, userId: string) {
  ReactDOM.render(
    <WithRoomStore>
      <MoodChatHead userId={userId} />
    </WithRoomStore>,
    element
  );
}
