import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserCardsIcon from '../../../assets/icons/user-cards/cards-fan-small.svg';
import { selectCurrentUserId } from '../store/users/selectors';
import { db } from '../../firebase';
import { track } from '../../util/analytics-util';
import { USER_CARD_ICON_CLICK } from '../../constants/analytics-events/user-page-events';

const UserCardIconWithBadge = ({ userId, onClick }) => {
  const currentUserId = useSelector(selectCurrentUserId);

  const [showBadge, setShowBadge] = useState(false);
  const [lastEditedUserPage, setLastEditedUserPage] = useState(null);
  const [lastViewedUserPage, setLastViewedUserPage] = useState(null);

  useEffect(() => {
    (async () => {
      const userProfileDoc = await db.doc(`/userProfiles/${userId}`).get();

      if (userProfileDoc.exists) {
        const data = userProfileDoc.data();
        setLastEditedUserPage(data.lastEditedUserPage);
      }
    })();
  }, [userId]);

  useEffect(() => {
    (async () => {
      const userPageViewDoc = await db.doc(`/userPageViews/${currentUserId}/views/${userId}`).get();

      if (userPageViewDoc.exists) {
        const data = userPageViewDoc.data();
        setLastViewedUserPage(data.lastViewed);
      }
    })();
  }, [currentUserId, userId]);

  // set showBadge
  useEffect(() => {
    if (lastEditedUserPage && lastViewedUserPage && lastEditedUserPage > lastViewedUserPage) {
      setShowBadge(true);
    }
  }, [lastEditedUserPage, lastViewedUserPage]);

  const onUserCardIconClick = () => {
    setShowBadge(false);
    onClick();

    track(USER_CARD_ICON_CLICK, { isSelf: currentUserId === userId, hasUnreadBadge: showBadge });
  };

  return (
    <Container onClick={onUserCardIconClick}>
      <StyledUserCardsIcon />
      {showBadge && <Badge />}
    </Container>
  );
};

export default UserCardIconWithBadge;

UserCardIconWithBadge.propTypes = {
  userId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
`;

const StyledUserCardsIcon = styled(UserCardsIcon)`
  position: absolute;
  top: 0;
  right: 0;

  width: 30px;
  height: 30px;
`;

const Badge = styled.div`
  position: absolute;
  top: 1px;
  right: 0;

  border: 5px solid #f6335d;
  background-color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
`;
