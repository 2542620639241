import React from 'react';
import styled from 'styled-components';

// components
import { moreMenuItemMixin } from '../../../mixins';

type MessageContextButtonProps = {
  children: React.ReactNode;
  onClick: (event: object) => void;
};

const MessageContextButton = ({ children, onClick }: MessageContextButtonProps) => (
  <MenuItem onClick={onClick}>{children}</MenuItem>
);

export default MessageContextButton;

const MenuItem = styled.div`
  ${moreMenuItemMixin({ themed: false })}
`;
