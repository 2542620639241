import React from 'react';
import styled from 'styled-components';

interface OpenedInDesktopProps {
  title?: string;
  close: () => void;
  showJoinRoomModal: () => void;
}

const OpenedInDesktop = ({ title, close, showJoinRoomModal }: OpenedInDesktopProps) => {
  const messageTitle = title ? `"${title.trim()}"` : 'This room';

  const continueOnWeb = () => {
    close();
    showJoinRoomModal();
  };

  return (
    <Overlay>
      <Container>
        <Image height={100} width={100} src="/images/icons/here-starburst-logo-white.svg" alt="Here logo" />
        <Message>
          {messageTitle} <br /> opened in Here App
        </Message>
        <JoinOnWebButton onClick={continueOnWeb}>Join on Web instead</JoinOnWebButton>
      </Container>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(44deg, #2a07f4 0%, #b501b0 100%);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  margin-left: 10px;
`;

const Message = styled.h5`
  margin-top: 35px;
  color: white;
  font-size: 22px;
  font-weight: bolder;
  text-align: center;
`;

const JoinOnWebButton = styled.button`
  margin-top: 18px;
  color: white;
  font-size: 16px;
  border: none;
  background: none;
  opacity: 0.7;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 1;
  }
`;

export default OpenedInDesktop;
