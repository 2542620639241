import firebase, { db } from '../firebase';
import log from '../log';
import { _getCurrentRole } from '../roles-management';
import { checkIsElectron } from '../util/platform-util';

export const boardUsers = {};
export const nameTags = {};

const colors = ['green', 'magenta', 'yellow', 'orange', 'lime', 'seafoam', 'blue', 'violet'];

let presenceUpdateTimer = null;
let currentIsIdle = null;

// We might want to create a generic solution if realtime database will be used for something else.
const userListChangedCallbacks = {};
/**
 *
 * @param {string} boardId The board to monitor
 * @param {function} userListUpdated Callback when the user list has been changed
 * @param {*} onChangeCallback ??? TODO remove this parameter?
 * @returns
 */
export function onUserListChanged(boardId, userListUpdated, onChangeCallback) {
  function shouldNotify(oldUserList, newUserList) {
    if (!newUserList) {
      // Should always notify when the last user left or when we query an empty room.
      return true;
    }

    const newUserKeys = Object.keys(newUserList);
    if ((!oldUserList && newUserList) || Object.keys(oldUserList).length !== newUserKeys.length) {
      return true;
    }
    for (let i = 0; i < newUserKeys.length; i += 1) {
      const user = newUserKeys[i];
      const newUser = newUserList[user];
      const oldUser = oldUserList[user];
      if (!oldUser) return true;
      if (
        oldUser.color !== newUser.color ||
        oldUser.name !== newUser.name ||
        oldUser.role !== newUser.role ||
        oldUser.queueForRemoval !== newUser.queueForRemoval
      )
        return true;
    }
    return false;
  }

  if (userListChangedCallbacks[boardId]) {
    userListChangedCallbacks[boardId].listeners.push(userListUpdated);
    if (userListChangedCallbacks[boardId].lastValue) {
      // Making its behavior async so it's consistent.
      setTimeout(() => userListUpdated(userListChangedCallbacks[boardId].lastValue, null, boardId), 0);
    }

    return;
  }

  userListChangedCallbacks[boardId] = { listeners: [userListUpdated] };

  firebase
    .database()
    .ref(`/status/${boardId}`)
    .on('value', (snapshot) => {
      const value = snapshot.val();
      boardUsers[boardId] = value;
      if (!shouldNotify(userListChangedCallbacks[boardId].lastValue, value)) {
        userListChangedCallbacks[boardId].lastValue = value;
        return;
      }
      for (let i = 0; i < boardUsers.length; i += 1) {
        onChangeCallback(boardUsers[0]);
      }
      userListChangedCallbacks[boardId].listeners.forEach((f) =>
        f(value, userListChangedCallbacks[boardId].lastValue, boardId)
      );
      userListChangedCallbacks[boardId].lastValue = value;
    });
}

export function offUserListChanged(boardId, userListUpdated) {
  if (userListChangedCallbacks[boardId]) {
    const index = userListChangedCallbacks[boardId].listeners.findIndex((f) => f === userListUpdated);
    userListChangedCallbacks[boardId].listeners.splice(index, 1);
  }
}

let boardWaitlist = {};
export function sendWaitlistMessage(boardId, user, message) {
  const waitlistRef = firebase.database().ref(`/waitlist/${boardId}/${user}`);
  waitlistRef.update({ message });
}

export function onWaitlistChanged(boardId, { updated, added, removed }) {
  const waitlistRef = firebase.database().ref(`/waitlist/${boardId}`);
  waitlistRef.on('value', (snapshot) => {
    const values = snapshot.val();
    updated(boardId, values);

    if (values) {
      // If it's new, call waitlistAdded as well
      Object.keys(values).forEach((key) => {
        if (!boardWaitlist || !boardWaitlist[key]) {
          const newUserData = { ...values[key] };
          newUserData.id = key;
          added(boardId, newUserData, Object.keys(values).length);
        }
      });
    }

    if (boardWaitlist) {
      Object.keys(boardWaitlist).forEach((key) => {
        if (!values || !values[key]) {
          removed(boardId, key);
        }
      });
    }

    boardWaitlist = values;
  });
}

export async function leaveBoard(boardId) {
  const { uid } = firebase.auth().currentUser;
  await firebase.database().ref(`/status/${boardId}/${uid}`).onDisconnect().cancel();
  await firebase.database().ref(`/status/${boardId}/${uid}`).remove();
  await firebase.database().ref(`/waitlist/${boardId}/${uid}`).remove();
  clearInterval(presenceUpdateTimer);
}

let stopUserProfileSnapshot = null;
let previousSessionId = null;

export async function enterBoard(boardId) {
  const { uid } = firebase.auth().currentUser;
  const userStatusDatabaseRef = firebase.database().ref(`/status/${boardId}/${uid}`);
  await userStatusDatabaseRef.onDisconnect().update({ queueForRemoval: true });
  // The promise returned from .onDisconnect().set() will
  // resolve as soon as the server acknowledges the onDisconnect()
  // request, NOT once we've actually disconnected:
  // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

  if (stopUserProfileSnapshot) {
    stopUserProfileSnapshot();
    stopUserProfileSnapshot = null;
  }

  stopUserProfileSnapshot = db
    .collection('userProfiles')
    .doc(uid)
    .onSnapshot(async (snapshot) => {
      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      const user = snapshot.data();
      let name = user.displayName;
      if (nameTags[boardId]) {
        name += ` (${nameTags[boardId]})`;
      }

      if (boardUsers[boardId] === null || boardUsers[boardId] === undefined) {
        const boardUsersRef = firebase.database().ref(`/status/${boardId}`);
        await boardUsersRef.once('value', (_snapshot) => {
          const value = _snapshot.val();
          boardUsers[boardId] = value;
        });
      }
      const currentBoardUsers = boardUsers[boardId];
      const currentColors = new Array(colors.length).fill(0);
      if (currentBoardUsers) {
        for (let i = 0; i < colors.length; i += 1) {
          Object.keys(currentBoardUsers).forEach((obj) => {
            if (obj !== uid && currentBoardUsers[obj].color === colors[i]) {
              currentColors[i] += 1;
            }
          });
        }
      }
      const indexOfLeast = indexOfSmallest(currentColors);
      const myColor = colors[indexOfLeast];

      const presenceObj = {
        name,
        state: 'online',
        role: _getCurrentRole() || null,
        lastChanged: firebase.database.ServerValue.TIMESTAMP,
        color: myColor,
        platform: checkIsElectron() ? 'desktop' : 'web',
      };

      if (previousSessionId) {
        presenceObj.janusSessionId = previousSessionId;
      }

      if (currentIsIdle !== null) {
        presenceObj.isIdle = currentIsIdle;
      }

      await userStatusDatabaseRef.set(presenceObj);
      log.debug('Done adding self to presence');

      let waitCount = 0;
      const TIME_TO_WAIT = 100;

      const waitForJanusSessionIdAndUpdate = () => {
        // Wait a max of 20 seconds
        if (waitCount === 20000) {
          window.analytics.track('Timeout Waiting For Janus Session');
          return;
        }

        if (window.rtc.sessionId) {
          const janusSessionId = window.rtc.sessionId;

          if (!janusSessionId || (previousSessionId && previousSessionId === janusSessionId)) {
            waitCount += TIME_TO_WAIT;
            setTimeout(waitForJanusSessionIdAndUpdate, TIME_TO_WAIT);
          } else {
            userStatusDatabaseRef.update({ janusSessionId, lastChanged: firebase.database.ServerValue.TIMESTAMP });
            previousSessionId = janusSessionId;

            waitCount = 0;
          }
        } else {
          waitCount += TIME_TO_WAIT;
          setTimeout(waitForJanusSessionIdAndUpdate, TIME_TO_WAIT);
        }
      };

      if (!window.rtc.sessionId) {
        waitForJanusSessionIdAndUpdate();
      } else {
        // We're trying to prevent going into a 20s loop if the previous session id is equal ot the current one
        const janusSessionId = window.rtc.sessionId;
        if (!janusSessionId || previousSessionId !== janusSessionId) {
          waitForJanusSessionIdAndUpdate();
        }
      }

      const lastChanged = userStatusDatabaseRef.child('lastChanged');
      clearInterval(presenceUpdateTimer);
      presenceUpdateTimer = setInterval(() => {
        lastChanged.set(firebase.database.ServerValue.TIMESTAMP);
      }, 60 * 1000);
    });

  // TODO: The code is similar to waitlist
}

function indexOfSmallest(a) {
  let lowest = 0;
  for (let i = 1; i < a.length; i += 1) {
    if (a[i] < a[lowest]) lowest = i;
  }
  return lowest;
}

export function joinWaitlist(boardId, onAllowedEntry, onMessage) {
  const { uid } = firebase.auth().currentUser;
  const userWaitlistDatabaseRef = firebase.database().ref(`/waitlist/${boardId}/${uid}`);
  userWaitlistDatabaseRef
    .onDisconnect()
    .remove()
    .then(() => {
      // The promise returned from .onDisconnect().set() will
      // resolve as soon as the server acknowledges the onDisconnect()
      // request, NOT once we've actually disconnected:
      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      let name = firebase.auth().currentUser.displayName;
      if (nameTags[boardId]) {
        name += ` (${nameTags[boardId]})`;
      }
      log.debug('Adding to waitlist');
      userWaitlistDatabaseRef.set({
        state: 'waiting',
        name,
        lastChanged: firebase.database.ServerValue.TIMESTAMP,
      });
    });

  userWaitlistDatabaseRef.on('value', (snapshot) => {
    const data = snapshot.val();
    log.debug(`Value change for waitlist: ${JSON.stringify(data)}`);
    if (data && data.state === 'allowed') {
      onAllowedEntry();
    } else if (data && data.message) {
      onMessage(data.message);
    }
  });
}

export async function approveWaitlistUser(userId) {
  const userWaitlistDatabaseRef = firebase.database().ref(`/waitlist/${window.currentBoardId}/${userId}`);
  const data = await userWaitlistDatabaseRef.once('value');
  if (data.val()) {
    await userWaitlistDatabaseRef.update({ state: 'allowed' });
  } else {
    log.warn(`User not found in waiting list: ${userId}`);
  }
}

export function leaveWaitlist(boardId) {
  const { uid } = firebase.auth().currentUser;
  firebase.database().ref(`/waitlist/${boardId}/${uid}`).remove();
}

export async function trackIdleChange(boardId, isIdle) {
  const { uid } = firebase.auth().currentUser;

  currentIsIdle = isIdle;
  const userStatusDatabaseRef = firebase.database().ref(`/status/${boardId}/${uid}`);
  await userStatusDatabaseRef.update({
    isIdle,
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  });
}
