import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import { boardElementForEvent, downloadURI, htmlToElement } from './util';
import BoardElement from './board-element';
import log from './log';
import showChangeAvatarConfirmation from './react/user-profile/avatar-confirmation/show-change-avatar-confirmation';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES } from './constants/analytics-events/element-events';
import { USER_CARD_BOARD_TYPE } from './constants/board-constants';

const labels = {
  sticker: 'a sticker',
  image: 'an image',
  gif: 'a GIF',
  giftext: 'text',
};

export default class ImageElement extends BoardElement {
  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    const image = element.querySelector('img');
    const { imageURL, useImageProxy } = elementDoc.data();
    if (image && image.src !== imageURL) {
      const url = useImageProxy ? `${process.env.IMAGE_PROXY_URL}/${imageURL}` : imageURL;
      image.src = url;
    }

    // If we just received the imageURL back from a photo booth, save it.
    if (
      this.elementData.captureSource === 'photobooth' &&
      !this.elementData.imageURL &&
      imageURL &&
      this.elementData.creator === firebase.auth().currentUser?.uid
    ) {
      downloadURI(imageURL);
    }

    this.elementData = elementDoc.data();

    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(__elementId, elementDoc) {
    this.elementData = elementDoc.data();
  }

  pictureMarkup(data) {
    const picture = htmlToElement('<picture></picture>');
    if (data.webp) {
      const source = htmlToElement(`<source type="image/webp" srcset="${data.webp}" />`);
      picture.appendChild(source);
    }

    const imageURL = data.useImageProxy ? `${process.env.IMAGE_PROXY_URL}/${data.imageURL}` : data.imageURL;

    const img = htmlToElement(`<img class="rotatable-container" src="${imageURL}" crossOrigin="Anonymous" />`);
    img.addEventListener('load', this.verifyDimensions);

    picture.appendChild(img);
    return picture;
  }

  videoMarkup(data) {
    const video = htmlToElement(`
    <video class="rotatable-container image-embedded-video" autoplay loop>
      <source src="${data.webm}" type="video/webm" />
    </video>`);

    return video;
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();

    const picture = data.webm ? this.videoMarkup(data) : this.pictureMarkup(data);

    const downloadUrl = data.original || data.webp || data.imageURL || data.webm;

    const downloadButton = htmlToElement(`<a href="${downloadUrl}" target="__blank" download>Download Original</a>`);
    const avatarButton = htmlToElement('<button class="options-menu-option">Set as my avatar</button>');
    avatarButton.addEventListener('click', () => showChangeAvatarConfirmation(downloadUrl));

    return wrapElement(picture, elementDoc, {
      classes: ['imageCard'],
      preserveAspectRatio: true,
      additionalOptions: [downloadButton, avatarButton],
    });
  }

  // Statics
  static async addElement({ imageURL, ...additionalData }, type, source) {
    const width = additionalData.width || 350;
    const height = additionalData.height || 350;
    delete additionalData.width;
    delete additionalData.height;

    const props = {
      class: 'ImageElement',
      creator: firebase.auth().currentUser.uid,
      size: [width, height],
      zIndex: window.getFrontZIndex(),
      imageURL,
      ...additionalData,
    };

    if (!props.center) {
      props.center = screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2);
    }

    const boardRef = db.collection('boards').doc(window.currentBoardId);
    const boardData = (await boardRef.get()).data();
    await boardRef.collection('elements').add(props);

    addSystemMessage(`added ${labels[type]}`);
    track(ADD_ELEMENT, {
      element: type,
      source,
      destination:
        boardData.type === USER_CARD_BOARD_TYPE
          ? ADD_ELEMENT_DESTINATION_TYPES.CARD
          : ADD_ELEMENT_DESTINATION_TYPES.ROOM,
    });
  }

  verifyDimensions(e) {
    if (!firebase.auth().currentUser) {
      return;
    }

    const imgRatio = e.target.naturalWidth / e.target.naturalHeight;
    const boardElement = boardElementForEvent(e);
    const width = boardElement.style.width.replace('px', '');
    const height = boardElement.style.height.replace('px', '');
    const elementRatio = width / height;

    // TODO this check doesn't quite work, lots of images fall into this condition
    if (elementRatio !== imgRatio) {
      const newWidth = width;
      const newHeight = Math.round(width / imgRatio);

      // May run into a rounding issue here, don't want to thrash and continually rewrite
      if (newHeight !== height) {
        try {
          db.doc(boardElement.getAttribute('docPath')).update({
            size: [newWidth, newHeight],
          });
        } catch (err) {
          log.warn('Unable to update image dimensions', err);
        }
      }
    }
  }

  // Used for photobooth since it doesn't support <picture> tag.
  showSingleImage() {
    const element = document.getElementById(`element-${this.elementId}`);
    this.singleImage = element.querySelector('.rotatable-container').cloneNode();
    element.querySelector('.element-container').prepend(this.singleImage);
    const pic = element.querySelector('picture');
    if (pic) {
      pic.style.display = 'none';
    }
  }

  hideSingleImage() {
    if (this.singleImage) {
      this.singleImage.remove();
      this.singleImage = null;
    }

    const pic = document.querySelector(`#element-${this.elementId} picture`);
    if (pic) {
      pic.style.display = null;
    }
  }
}

ImageElement.elementType = 'ImageElement';
