import styled from 'styled-components';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;

  svg {
    width: 19px;
  }
`;

export const UserPreviewContainer = styled.div`
  margin-top: 25px;
  height: 50px;
`;

export const Description = styled.p`
  margin-top: 25px;
  font-size: 12px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 20px;
`;

export const Button = styled.button`
  padding: 15px;
  width: 200px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  background: none;
  cursor: pointer;
`;

export const CancelButton = styled(Button)`
  color: black;
  background-color: #e5e5e5;
`;

export const ReportButton = styled(Button)`
  color: white;
  font-weight: bold;
  background-color: #f6335d;
`;
