import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

// utils
import { getRandomNumberInRange } from '../../../util';

export function SpinningWrapper({ children }) {
  return <Spinning>{children}</Spinning>;
}
SpinningWrapper.propTypes = { children: PropTypes.node.isRequired };

export function VerticalJumpingWrapper({ children }) {
  return <VerticalJumping>{children}</VerticalJumping>;
}
VerticalJumpingWrapper.propTypes = { children: PropTypes.node.isRequired };

export function HorizontalJumpingWrapper({ children }) {
  return <HorizontalJumping>{children}</HorizontalJumping>;
}
HorizontalJumpingWrapper.propTypes = { children: PropTypes.node.isRequired };

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const verticalJump = keyframes`
  0% {
   transform: translateY(0);
  }

  50% {
   transform: translateY(-10px);
  }

  100% {
   transform: translateY(0);
  }
`;

const horizontalJump = keyframes`
  0% {
   transform: translateX(0);
  }

  50% {
   transform: translateX(15px);
  }

  100% {
   transform: translateX(0);
  }
`;

const TypingIndicatorItemAnimation = styled.div`
  display: flex; // hack fix for a weird visual issue
`;

const Spinning = styled(TypingIndicatorItemAnimation)`
  animation: ${rotate} 0.8s ease infinite ${getRandomNumberInRange(0, 1)}s;
`;

const VerticalJumping = styled(TypingIndicatorItemAnimation)`
  animation: ${verticalJump} 0.5s ease-in-out infinite;
`;

const HorizontalJumping = styled(TypingIndicatorItemAnimation)`
  animation: ${horizontalJump} 0.5s ease-in-out infinite ${getRandomNumberInRange(0, 1)}s;
`;
