import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import UIkit from 'uikit';

import Offcanvas from '../../components/Offcanvas';
import RoomSettingsHeader from './Header';
import { closeRoomSettings, setRoomSettingsCurrentTab } from '../../store/room/store';
import { selectAreSettingsOpened, selectCurrentSettingsTabName } from '../../store/room/selectors';
import RoomSettingsMenu from './Menu';
import { svgColorMixin } from '../../mixins';
import RoomSettingsFooter from './Footer';

const RoomSettings = () => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => dispatch(closeRoomSettings()), [dispatch]);
  const onBack = useCallback(() => dispatch(setRoomSettingsCurrentTab({ tabName: null })), [dispatch]);

  const isOpened = useSelector(selectAreSettingsOpened);
  const currentTabName = useSelector(selectCurrentSettingsTabName);

  useEffect(() => {
    if (isOpened) {
      // The tooltip of opening button remains visible on mobile, so hiding it manually.
      // Need to remove this once the top panel is rewritten in React (and radix instead of uikit).
      ['.board-title-settings-button', '.room-settings-button'].forEach((el) => UIkit.tooltip(el).hide());
    }
  }, [isOpened]);

  return (
    <Offcanvas isOpened={isOpened} width={368} fullscreenBreakpoint={380} onClose={onClose}>
      <Container>
        <RoomSettingsHeader
          label={currentTabName || 'Room Settings'}
          hasBackButton={!!currentTabName}
          onClose={onClose}
          onBack={onBack}
        />

        <Content>
          <RoomSettingsMenu />

          {!currentTabName ? <RoomSettingsFooter /> : null}
        </Content>
      </Container>
    </Offcanvas>
  );
};

export default RoomSettings;

const scrollInFromLeft = keyframes`
  from {
    transform: translateX(-110%);
  }
  to {
    transform: translateX(0);
  }
`;

const scrollInFromRight = keyframes`
  from {
    transform: translateX(110%);
  }
  to {
    transform: translateX(0);
  }
`;

const scrollOutToLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-110%);
  }
`;

const scrollOutToRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(110%);
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  background: var(--primary-background-alpha-90, rgba(255, 255, 255, 0.9));
  backdrop-filter: blur(20px);
  font-family: 'Inter';
  box-sizing: border-box;
  overflow: auto;

  // Here go common styles needed for web components.
  // PLEASE DO NOT USE ANY OF THESE CLASSNAMES WHEN REWRITING THEM TO REACT.
  // These styles will be removed once all tabs are in react.

  .scroll-in-from-right {
    animation-name: ${scrollInFromRight};
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .scroll-in-from-left {
    animation-name: ${scrollInFromLeft};
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .scroll-out-to-right {
    animation-name: ${scrollOutToRight};
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .scroll-out-to-left {
    animation-name: ${scrollOutToLeft};
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .background-chooser-container {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
  }

  .option-section {
    border-radius: 5px;
    padding: 14px;
  }

  .tab-content-title {
    display: none;
  }

  li {
    position: relative;
  }

  .heading {
    font-size: 16px;
    color: var(--primary-foreground, black);
    margin-bottom: 4px;
  }

  .settings-section {
    margin: 20px 0;

    &:first-child,
    &.first-section {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .heading {
      border-bottom: none;
      padding-bottom: 10px;
      font-weight: 600;
    }

    .gray-section {
      background: rgba(196, 196, 196, 0.15);

      body.themed & {
        background: var(--primary-background);
        border: 1px solid var(--primary-foreground-alpha-20);

        &:hover {
          border: 1px solid var(--primary-foreground-alpha-80);
        }
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-top: 4px;

      h2 {
        position: relative;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2rem;
        color: var(--primary-foreground, black);
      }

      .make-private svg {
        ${svgColorMixin('var(--primary-foreground, black)')}
        position: absolute;
        top: 8%;
        margin-left: 10px;
        height: 15px;
        width: 11px;
        display: inline-block;
      }
    }

    .subtitle {
      margin: 6px 0 0 0;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.4rem;
      color: var(--primary-foreground, #868686);
    }

    .voice-controls-section {
      span {
        color: var(--primary-foreground, #6b1be3);
      }
    }
  }
`;
