const roomsCache = {};
const urlAliasCache = {};

export const cacheRoom = (room) => {
  roomsCache[room.id] = room;
};

export const cacheCustomUrl = (urlAlias, roomId) => {
  urlAliasCache[urlAlias] = roomId;
};

export const getCachedRoom = (roomId) => roomsCache[roomId];

export const getUrlAliasCachedRoom = (urlAlias) => urlAliasCache[urlAlias];
