import { toggleInterfaceVisible } from './util';
import CameraElement from './camera';
import { zoomOut, zoomIn } from './viewport';

export function handleKey(keyEvent) {
  // Ignore if not in a room yet
  if (!window.currentBoardId) {
    return;
  }

  if (keyEvent.altKey) {
    if (keyEvent.code === 'KeyI') {
      // Toggle interface visibility
      toggleInterfaceVisible();
    } else if (keyEvent.code === 'KeyM') {
      // Toggle mic
      if (CameraElement.ownCamera.isAudioOn) {
        CameraElement.disableOwnAudio();
      } else {
        CameraElement.enableOwnAudio();
      }
    } else if (keyEvent.code === 'KeyC') {
      // Toggle camera
      if (CameraElement.ownCamera.isVideoOn) {
        CameraElement.disableOwnCamera();
      } else {
        CameraElement.enableOwnCamera();
      }
    } else if (keyEvent.code === 'Minus') {
      zoomOut();
    } else if (keyEvent.code === 'Equal') {
      zoomIn();
    }
  }
}
