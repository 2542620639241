import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserListItem from '../../user-profile/UserListItem';
import { ListItemContainer } from '../../user-profile/UserListItemLayout.tsx';

import { CheckIcon } from '../../icons';

const UsersSelectListItem = ({ userId, isActive, isDisabled, onlineStatus, onClick }) => (
  <Container onClick={() => !isDisabled && onClick(userId)} isDisabled={isDisabled}>
    <CheckboxLabel>
      <CheckboxInput type="checkbox" checked={isActive} readOnly onClick={(e) => e.stopPropagation()} />
      <Checkbox>
        <CheckIcon size="16" color="white" />
      </Checkbox>
    </CheckboxLabel>

    <UserListItemContainer>
      <UserListItem userId={userId} onlineStatus={onlineStatus} />
    </UserListItemContainer>
  </Container>
);

export default UsersSelectListItem;

UsersSelectListItem.propTypes = {
  userId: PropTypes.string.isRequired,
  onlineStatus: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

UsersSelectListItem.defaultProps = {
  isDisabled: false,
};

const Container = styled.div`
  padding-left: 17px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}

  ${ListItemContainer} {
    background: none;
    border-radius: 0;

    &:hover {
      background: none;
    }
  }

  & + & {
    margin-top: 5px;
  }

  &:hover {
    ${({ isDisabled }) => !isDisabled && 'background: rgba(0, 0, 0, 0.1);'}
  }
`;

const UserListItemContainer = styled.div`
  flex-grow: 1;
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
`;

const Checkbox = styled.span`
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  svg {
    display: none;
  }
`;

const CheckboxInput = styled.input`
  display: none;

  &:not(:checked) ~ ${Checkbox} {
    display: flex;
    background-color: #d9d9d9;
  }

  &:checked ~ ${Checkbox} {
    display: flex;
    background-color: #6b1be3;

    svg {
      display: block;
    }
  }
`;
