export const MAX_MESSAGES_IN_PAGE = 50; // # of messages in page of fetch chat response
export const MAX_MESSAGES_IN_GROUP_RESPONSE = 20; // # of messages in chat in fetch group response
export const MAX_CHATS_IN_PAGE = 50;
export const FEED_BOTTOM_INIT_BOTTOM = 45;
export const FEED_BOTTOM_MAX_BOTTOM = 120;
export const MAX_VISIBLE_REACTIONS = 8;
export const MAX_PRESENCE_SHOWN_IN_ROOM_CHATS = 8;

// popup messages
export const DELAY_BEFORE_FADEOUT_MS = 4000;
export const FADEOUT_DURATION_MS = 500;
export const INVITE_MESSAGE_DELAY_MS = 6400;

// emoji messages
export const EMOJI_COUNT_THRESHOLD = 4;

// typing indicator
//
export const MAX_VISIBLE_TYPING_USERS = 6;
export const TYPING_USER_IN_LIST_VISIBILITY_MS = 1500;

export const typingStatuses = {
  ACTIVE: 'a',
  FINISHED: 'f',
};

export const typingTargets = {
  FEED: 'f',
};

export const typingIndicatorShapes = {
  STAR: 'TYPING_INDICATOR_SHAPES@STAR',
  RECT: 'TYPING_INDICATOR_SHAPES@RECT',
  HEXAGON: 'TYPING_INDICATOR_SHAPES@HEXAGON',
  TRIANGLE: 'TYPING_INDICATOR_SHAPES@TRIANGLE',
};

export const typingIndicatorAnimations = {
  HORIZONTAL_JUMPS: 'TYPING_INDICATOR_ANIMATIONS@HORIZONTAL_JUMPS',
  VERTICAL_JUMPS: 'TYPING_INDICATOR_ANIMATIONS@VERTICAL_JUMPS',
  SPINNING: 'TYPING_INDICATOR_ANIMATIONS@SPINNING',
};

export const typingIndicatorAppearingAnimations = {
  SCALE: 'TYPING_APPEARING_ANIMATIONS@SCALE',
  TRANSLATE: 'TYPING_APPEARING_ANIMATIONS@TRANSLATE',
};

export const messageTypes = {
  SYSTEM: 'system',
  TEXT: 'text',
  IMAGE: 'image',
  STICKER: 'sticker',
  GIF: 'gif',
  UPLOAD_ERROR: 'upload-error',
  INVITE: 'invite',
  OUTSIDE_REACTION: 'outside-reaction',
  ROOM_ELEMENT_TAG_FEED: 'room element tag feed',
  ROOM_ELEMENT_TAG_DM: 'room element tag dm',
  AUDIO: 'audio',
  VIDEO: 'video',
};

export const fileUploadErrorTypes = {
  SIZE_EXCEEDED: 'FILE_UPLOAD_ERROR_TYPES@SIZE_EXCEEDED',
  WRONG_TYPE: 'FILE_UPLOAD_ERROR_TYPES@WRONG_TYPE',
  DEFAULT: 'FILE_UPLOAD_ERROR_TYPES@DEFAULT',
};

export const fileUploadErrorMessagesMap = {
  [fileUploadErrorTypes.SIZE_EXCEEDED]: 'Please select a file smaller than 10mb',
  [fileUploadErrorTypes.WRONG_TYPE]: 'Please choose a gif, jpeg, or png file',
  [fileUploadErrorTypes.DEFAULT]: 'Uploading error',
};

export const chatTypes = {
  FEED: 'Feed',
  TEXT_CHANNEL: 'Chat',
  DM: 'DM',
};

export const chatCallState = {
  IS_CALLING: 'is-calling',
  DISABLE: 'disable',
  ENABLE: 'enable',
};

export const embedMessageTypes = {
  TIKTOK: 'TikTok',
  YOUTUBE: 'YouTube',
  YOUTUBE_SHORTS: 'YouTube shorts',
};
