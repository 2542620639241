import { createAction } from '@reduxjs/toolkit';

import { GroupSettingsTabs, WebLobbyTabs } from '../../../definitions/web-lobby';

export const setActiveTab = createAction<{ activeTab: WebLobbyTabs }>('webLobby/setActiveTab');
export const setActiveModal = createAction<{ activeModal: string }>('webLobby/setActiveModal');
export const addVisibleRoomId = createAction<{ roomId: string }>('webLobby/addVisibleRoomId');
export const deleteVisibleRoomId = createAction<{ roomId: string }>('webLobby/deleteVisibleRoomId');
export const setVisibleRoomIds = createAction<{ visibleRoomIds: { [key: string]: boolean } }>(
  'webLobby/setVisibleRoomIds'
);
export const setShouldShowRooms = createAction<{ shouldShowRooms: boolean }>('webLobby/setShouldShowRooms');
export const setActiveGroupId = createAction<{ activeGroupId: string | null }>('webLobby/setActiveGroupId');
export const setGroupSettingsTab = createAction<{ tab: GroupSettingsTabs | null }>('webLobby/setGroupSettingsTab');
