import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useThrottledCallback } from 'use-debounce';
import { CSSTransition } from 'react-transition-group';

import { requestGroupUpdate } from '../../store/groups/actions';
import { getGroupChatParams, track } from '../../../util/analytics-util';
import { RENAME_GROUP } from '../../../constants/analytics-events/groups-events';
import ErrorIcon from '../../../../assets/icons/error.svg';
import { CHAT_EDIT_NAME } from '../../os/analytics';
import { selectChatByChatId } from '../../store/messaging/selectors';
import { validateGroupName } from '../../../util/groups-util';

const CSS_TRANSITION_NAME = 'error-transition';
const CSS_TRANSITION_TIMEOUT_IN_MS = 300;

interface Props {
  chatId?: string;
}

const GroupNameField: React.FC<Props> = ({ chatId }) => {
  const dispatch = useDispatch();

  const hasTrackedEvent = useRef(false);
  const isInitialRender = useRef(true);

  const chat = useSelector((state) => selectChatByChatId(state, chatId));

  const [groupName, setGroupName] = useState(() => chat?.groupName || '');
  const [error, setError] = useState('');

  const onGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };

  const updateGroupName = (name: string) => {
    dispatch(
      requestGroupUpdate({
        id: chat.groupId,
        updates: {
          name,
        },
      })
    );

    // we only want to track it once:
    if (!hasTrackedEvent.current) {
      if (chatId) {
        track(CHAT_EDIT_NAME, getGroupChatParams({ chatId }, chatId));
      } else {
        track(RENAME_GROUP, { groupId: chat.groupId });
      }

      hasTrackedEvent.current = true;
    }
  };

  const throttledUpdateGroupName = useThrottledCallback((name) => {
    updateGroupName(name);
  }, 1000);

  const validateAndUpdateGroupName = (name: string) => {
    setError('');

    const nameError = validateGroupName(name);
    if (nameError) {
      setError(nameError);
      return;
    }

    throttledUpdateGroupName.callback(name);
  };

  // update group name on enter
  const onGroupNameKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      validateAndUpdateGroupName(groupName);
    }
  };

  // also update group name as user types (debounced)
  useEffect(() => {
    // we need this initial render check to avoid sending group name
    // when groupName is initially set:
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    validateAndUpdateGroupName(groupName);
    // no exhaustive deps because we only wanna call this when debouncedGroupName changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  return (
    <Container>
      <TextInput value={groupName} onChange={onGroupNameChange} onKeyDown={onGroupNameKeydown} hasError={!!error} />
      <CSSTransition in={!!error} classNames={CSS_TRANSITION_NAME} timeout={CSS_TRANSITION_TIMEOUT_IN_MS} unmountOnExit>
        <Error>
          <div>{error}</div>
          <ErrorIcon />
        </Error>
      </CSSTransition>
    </Container>
  );
};

export default GroupNameField;

const Container = styled.div`
  .${CSS_TRANSITION_NAME}-enter {
    opacity: 0;
    transform: translateY(-30%);
  }
  .${CSS_TRANSITION_NAME}-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 300ms, transform 300ms;
  }
  .${CSS_TRANSITION_NAME}-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  .${CSS_TRANSITION_NAME}-exit-active {
    opacity: 0;
    transform: translateY(-30%);
    transition: opacity 300ms, transform 300ms;
  }
`;

const TextInput = styled.input<{ hasError: boolean }>`
  padding: 5px 0;
  width: 100%;
  font-size: 14px;
  font-weight: bolder;
  box-sizing: border-box;
  color: var(--primary-foreground);
  background: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 5px;
  cursor: text;

  &:focus {
    outline: none;
  }

  &&& {
    &,
    &:focus {
      ${({ hasError }) => hasError && 'border-color: #f6335d;'}
    }
  }
`;

const Error = styled.div`
  background-color: #f6335d;
  color: white;
  margin-top: 3px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-radius: 10px;
  padding: 0 10px 0 15px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(18, 0, 45, 0.2);
`;
