import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.60869 1L1 6.60869" stroke={color} strokeLinejoin="round" />
    <path d="M6.60869 6.60869L1 1" stroke={color} strokeLinejoin="round" />
  </svg>

);

export default CloseIcon;

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

CloseIcon.defaultProps = {
  width: 12,
  height: 12,
  color: '#bababa',
};
