import { isNumber } from 'lodash';
import { RTCInboundRtpStreamStatsComplete, RTCOutboundRtpStreamStatsComplete } from './definitions';

// FIXME move this to a generic place
export abstract class DomStatsBase {
  userId: string;

  username: string;

  element: HTMLElement;

  previousInboundRTPMeasure: {
    [key: string]: {
      bytesReceived: number;
      lastTimestamp: number;
    };
  } = {};

  abstract audioTemplate(stat: RTCInboundRtpStreamStatsComplete | RTCOutboundRtpStreamStatsComplete): string;

  abstract videoTemplate(stat: RTCInboundRtpStreamStatsComplete | RTCOutboundRtpStreamStatsComplete): string;

  constructor(userId: string, username: string) {
    this.userId = userId;
    this.username = username;
    this.initializeElement();
  }

  get statsElementId() {
    return `camera-${this.userId}-stats`;
  }

  get titleElementId() {
    return `video-stats-title-${this.userId}`;
  }

  get audioStatsElementId() {
    return `audio-stats-${this.userId}`;
  }

  get videoStatsElementId() {
    return `video-stats-${this.userId}`;
  }

  get candidatePairElementId() {
    return `candidate-pair-stats-${this.userId}`;
  }

  get titleStatsElement() {
    return document.getElementById(this.titleElementId);
  }

  get audioStatsElement() {
    return document.getElementById(this.audioStatsElementId);
  }

  get candidatePairElement() {
    return document.getElementById(this.candidatePairElementId);
  }

  get videoStatsElement() {
    return document.getElementById(this.videoStatsElementId);
  }

  get cameraElement() {
    const camera = document.getElementsByClassName(`camera-${this.userId}`)[0];
    const screenshare = document.getElementById(`video-container-${this.userId}`);
    return camera || screenshare;
  }

  get cssTemplate() {
    return `
      background: #111;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      opacity: 0.7;
      color: white;
      padding: 10px;
      font-size: 4vh;
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;
    `;
  }

  initializeElement() {
    if (this.cameraElement) {
      const html = `
        <div style='margin-bottom: 10px'>${this.username} / ${this.userId}</div>
        <div id='${this.titleElementId}' style='margin-bottom: 10px'><strong>Getting stats...</strong></div>
        <div id='${this.audioStatsElementId}' style='margin-bottom: 10px'></div> 
        <div id='${this.videoStatsElementId}' style='margin-bottom: 10px'></div> 
        <div id='${this.candidatePairElementId}' style='margin-bottom: 10px'></div>
      `;

      this.element = document.createElement('div');
      this.element.id = this.statsElementId;
      this.element.className = 'camera-stats';
      this.element.innerHTML = html;
      this.element.style.cssText = this.cssTemplate;

      this.cameraElement.appendChild(this.element);
    }
  }

  remove() {
    this.element?.parentElement?.removeChild(this.element);
  }

  updateStats(stats: RTCStats[]) {
    stats?.forEach((stat: RTCStats & { kind?: 'audio' | 'video' }) => {
      if (stat.type === 'inbound-rtp' && this.titleStatsElement) this.titleStatsElement.innerHTML = 'Subscribing';
      if (stat.type === 'outbound-rtp' && this.titleStatsElement) this.titleStatsElement.innerHTML = 'Publishing';

      if (stat.kind === 'audio' && this.audioStatsElement) {
        this.audioStatsElement.innerHTML = this.audioTemplate(stat as RTCInboundRtpStreamStatsComplete) || '';
      }
      if (stat.kind === 'video' && this.videoStatsElement) {
        this.videoStatsElement.innerHTML = this.videoTemplate(stat as RTCInboundRtpStreamStatsComplete) || '';
      }
      if (stat.type === 'candidate-pair' && this.candidatePairElement) {
        this.candidatePairElement.innerHTML =
          this.candidatePairTemplate(stat as unknown as RTCIceCandidatePairStats) || '';
      }

      if (stat.type === 'inbound-rtp' && (stat.kind === 'audio' || stat.kind === 'video')) {
        this.previousInboundRTPMeasure[stat.id] = {
          bytesReceived: (stat as RTCInboundRtpStreamStatsComplete).bytesReceived,
          lastTimestamp: stat.timestamp,
        };
      }
    });
  }

  candidatePairTemplate(stat: RTCIceCandidatePairStats): string {
    return `
      ${this.row('Candidate Id Local / Remote', `${stat.localCandidateId} / ${stat.remoteCandidateId}`)}
      ${this.row('Available In / Out Bitrate', `${stat.availableIncomingBitrate} / ${stat.availableOutgoingBitrate}`)}
      ${this.row('Current Roundtrip Time', `${stat.currentRoundTripTime}`)}
      ${this.row('State', `${stat.state}`)}
      `;
  }

  row(key: string, value: unknown) {
    return `
      <div style='display: flex; flex-direction: row;'>
        <div class='key' style='width: 40%; font-size: 4vh'>${key}</div>
        <div class='value' style='width: 60%; text-align: right'>${value}</div>
      </div>
    `;
  }

  jitterAndBitrateRow(stat: RTCInboundRtpStreamStatsComplete) {
    const bytesInInterval = stat.bytesReceived - this.previousInboundRTPMeasure[stat.id]?.bytesReceived || 0;
    const bitrate =
      (bytesInInterval * 8) / (stat.timestamp - this.previousInboundRTPMeasure[stat.id]?.lastTimestamp || Date.now());
    return this.row('Jitter / Bitrate', `${stat.jitter} / ${Math.round(bitrate)} Kbps`);
  }

  roundValue(value: number) {
    if (!isNumber(value)) return undefined;
    return Math.round(value + Number.EPSILON * 100) / 100;
  }
}
