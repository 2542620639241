import UIkit from 'uikit';
import { db } from '../firebase';
import { applyBackgroundStyle, sanitize } from '../util';

import '../../styles/group-not-allowed-dialog.less';
import { goToLobby } from './navigation-util';
import { GROUP_JOIN_PATH, GROUP_PATH, HERE_OS_PATH } from '../definitions/groups.ts';

// TODO: Currently showing the "Room at max capacity"
export async function showUnjoinedGroupDialog(boardId) {
  UIkit.modal('#group-not-allowed-dialog').show();

  const roomCapacityDialogImage = document.getElementById('group-not-allowed-image');
  roomCapacityDialogImage.src = roomCapacityDialogImage.dataset.src;

  const roomInfoSnapshot = await db.doc(`boards/${boardId}`).get();
  const roomData = roomInfoSnapshot.data();
  applyBackgroundStyle(document.querySelector('.board-background'), roomData);
  document.getElementById('group-lobby-button').addEventListener('click', () => {
    goToLobby();
  });

  document.querySelector('#group-not-allowed-dialog .room-name').innerHTML = sanitize(roomData.title);
}

export const checkIsGroupJoinLink = () => window.location.pathname.split('/')[1] === GROUP_JOIN_PATH;

export const checkIsGroupLink = () => window.location.pathname.split('/')[1] === GROUP_PATH;

export const getGroupIdFromUrl = () => window.location.pathname.split('/')[2];

export const getOsLinkBasedOnGroup = () => {
  const groupId = checkIsGroupLink() || checkIsGroupJoinLink() ? getGroupIdFromUrl() : null;
  return `/${HERE_OS_PATH}${groupId ? `?groupId=${groupId}` : ''}`;
};

export const validateGroupName = (name) => {
  if (name.length === 0) {
    return 'Name cannot be empty';
  }
  if (name.length > 50) {
    return 'Name too long!';
  }
  return '';
};
