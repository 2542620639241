import styled from 'styled-components';

const SectionSeparator = styled.hr`
  border: none;
  background: var(--primary-foreground, #e5e5e5);
  height: 1px;
  margin: 24px 0;
`;

export default SectionSeparator;
