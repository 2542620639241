import React, { MouseEvent, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

// store
import { selectUserById } from '../store/users/selectors';

// components
import HereModal from '../components/HereModal';
import UserAvatar from '../components/UserAvatar';
import AddFriendButton from './AddFriendButton';
import { noScrollbarMixin } from '../mixins';
import UserListItem from '../user-profile/UserListItem';

// hooks
import { useUserFriendsData } from './useUserFriendsData';

// utils
import { applyTheme } from '../../util/theming-util';
import { openUserProfile } from '../user-profile/utils';
import { EventSourceContext } from '../common/contexts';

// constants
import { FRIEND_REQUEST_SOURCES } from '../../constants/analytics-events/friend-events';

interface UserFriendsListModalProps {
  userId: string;
}

const UserFriendsListModal = ({ userId }: UserFriendsListModalProps) => {
  const friendUserProfile = useSelector((state) => selectUserById(state, userId));

  const { friends } = useUserFriendsData(userId);

  const containerRef = useRef(null);
  useEffect(() => {
    if (friendUserProfile && containerRef.current) {
      applyTheme(
        friendUserProfile.profileTheme?.colors,
        friendUserProfile.profileTheme?.primaryFont,
        containerRef.current
      );
    }
  }, [friendUserProfile]);

  const viewProfileClick = (e: MouseEvent, uid: string) => {
    const target = e.target as HTMLElement;
    const itemRefCoordinates = target.getBoundingClientRect();
    const topPosition = itemRefCoordinates.y - 10;
    const leftPosition = itemRefCoordinates.x + target.offsetWidth / 2;
    openUserProfile({ userId: uid, topPosition, leftPosition });
  };

  const closeModal = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('friends-modal'));
  };

  return (
    <HereModal onOverlayClick={closeModal}>
      <Container ref={containerRef}>
        <Header memberColor="default" profileBackground={friendUserProfile.backgroundPhoto?.original}>
          <AvatarContainer>
            <UserAvatar userProfile={friendUserProfile} borderRadius="64px" borderWidth={0} />
          </AvatarContainer>
          <Subtitle className="themed-font">{friendUserProfile?.displayName}'s Friends</Subtitle>
        </Header>

        <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.USER_FRIENDS_MODAL}>
          <FriendsListContainer>
            {friends.map((friend) => (
              <UserItemContainer key={friend.userId} onClick={(e) => viewProfileClick(e, friend.userId)}>
                <UserListItem
                  userId={friend.userId}
                  userCTAsSection={
                    <CtaContainer>
                      <BackgroundPhotoContainer
                        backgroundUrl={friend.backgroundPhoto && friend.backgroundPhoto.original}
                        backgroundColor="default"
                      />

                      <AddFriendButton userId={friend.userId} />
                    </CtaContainer>
                  }
                  ctaSectionHoverChange
                  onlineStatus={friend.onlineStatus && friend.onlineStatus.status}
                />
              </UserItemContainer>
            ))}
          </FriendsListContainer>
        </EventSourceContext.Provider>
      </Container>
    </HereModal>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 5px solid var(--primary-background, white);
  border-radius: 20px;
`;

const Header = styled.header<{ memberColor: string; profileBackground?: string }>`
  position: relative;
  padding: 10px 10px 10px 20px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  background: ${({ profileBackground, memberColor }) =>
    profileBackground ? `url(${profileBackground})` : `url(/images/profile-backgrounds/${memberColor}.svg)`};
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

const AvatarContainer = styled.div`
  height: 64px;
  width: 64px;
  z-index: 10;
`;

const Subtitle = styled.p`
  z-index: 10;
  font-size: 28px;
  font-weight: bolder;
  color: white;
`;

const FriendsListContainer = styled.div`
  padding: 21px 29px;
  height: 600px;
  overflow: auto;
  background-color: var(--primary-background, white);

  ${noScrollbarMixin()}
`;

const UserItemContainer = styled.div`
  cursor: pointer;

  & + & {
    margin-top: 10px;
  }
`;

const BackgroundPhotoContainer = styled.div<{
  backgroundUrl: string;
  backgroundColor: string;
}>`
  position: absolute;
  top: 0;
  right: 0;

  background: ${({ backgroundUrl, backgroundColor }) =>
    backgroundUrl ? `url(${backgroundUrl})` : `url(images/profile-backgrounds/${backgroundColor}.svg)`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 154px;
  height: 69px;

  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (max-width: 615px) {
    width: 85px;
  }
`;

const CtaContainer = styled.div``;

export default UserFriendsListModal;
