import React from 'react';
import styled, { css } from 'styled-components';

import { loadingPulsationKeyframes } from '../chats-shared-styles';

const DMsLoadingWidget = () => (
  <DMsLoadingWidgetContainer>
    <img src="/images/icons/dms-loading.svg" alt="dms-loading" />
  </DMsLoadingWidgetContainer>
);

const DMsLoadingWidgetContainer = styled.div`
  width: 100%;
  animation: ${() => css`1s ${loadingPulsationKeyframes} infinite`};
`;

export default DMsLoadingWidget;
