import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useOnClickOutside } from '../hooks/useOnClickOutside';

const ActivatableElement = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const containerRef = useRef(null);
  useOnClickOutside(
    containerRef,
    useCallback(() => setIsActive(false), [])
  );

  return (
    <Container ref={containerRef} onClick={() => setIsActive(true)}>
      <InnerContainer isActive={isActive}>{children}</InnerContainer>
    </Container>
  );
};

export default ActivatableElement;

ActivatableElement.propTypes = {
  children: PropTypes.node.isRequired,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  ${({ isActive }) =>
    isActive ? 'outline: 2px solid var(--primary-background-alpha-80, rgba(0, 0, 0, 0.8))' : 'pointer-events: none;'}
`;
