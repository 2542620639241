import React, { useContext } from 'react';
import styled from 'styled-components';
import { EmojiData } from 'emoji-mart';

import { Theme } from '../../../definitions/theme';

// components
import EmojiPickerButton from '../chat-input/buttons/EmojiPickerButton';
import KaomojiPickerButton from '../chat-input/buttons/KaomojiPickerButton';
import GifPickerButton from '../chat-input/buttons/GifPickerButton';
import UploadButton from '../chat-input/buttons/UploadButton';
import { svgColorMixin } from '../../mixins';
import { RoomChatInputContext } from '../../common/contexts';

// constants
import { DEFAULT_PRIMARY_FOREGROUND } from '../../groups-lobby/style-constants';

type RoomChatInputActiveProps = {
  theme: Theme;
  onClick: () => void;
  onEmojiSelected: (emoji: EmojiData) => void;
  onKaomojiSelected: (kaomoji: string, category: string) => void;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RoomChatInputInactive = ({
  theme,
  onClick,
  onEmojiSelected,
  onKaomojiSelected,
  handleFileUpload,
}: RoomChatInputActiveProps) => {
  const { elementId, caller } = useContext(RoomChatInputContext);

  return (
    <Container onClick={onClick}>
      <ButtonsContainer
        iconsColor={theme?.colors?.primaryForeground || DEFAULT_PRIMARY_FOREGROUND}
        onClick={(e) => e.stopPropagation()}
      >
        <KaomojiPickerButton onSelectKaomoji={onKaomojiSelected} />
        <EmojiPickerButton onEmojiSelected={onEmojiSelected} />
        <GifPickerButton elementId={elementId} caller={caller} />
        <UploadButton elementId={elementId} handleFileUpload={handleFileUpload} />
      </ButtonsContainer>

      <Text>Say something...</Text>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 6px 6px 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--primary-foreground-alpha-20);
  border-radius: 20px;
`;

const BUTTONS_CONTAINER_WIDTH_PX = 107;

const Text = styled.p`
  margin-left: 10px;
  width: calc(100% - ${BUTTONS_CONTAINER_WIDTH_PX}px);
  overflow: hidden;
  color: var(--primary-foreground, white);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.5;
`;

const ButtonsContainer = styled.div<{ iconsColor: string }>`
  width: ${BUTTONS_CONTAINER_WIDTH_PX}px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;

  ${({ iconsColor }) => svgColorMixin(iconsColor)};
`;

export default RoomChatInputInactive;
