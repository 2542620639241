import CameraElement from './camera';
import { canvasToScreenCoords } from './util/canvas-util';
import { getElementPosition } from './element-transform';
import log from './log';

let visibilityThreshold = 14;

function distanceFromCenter(el) {
  const [x, y] = getElementPosition(el);
  const cameraCoords = canvasToScreenCoords(x, y);

  const distanceX = cameraCoords[0] + (parseFloat(el.style.width) * window.canvasScale) / 2 - window.innerWidth / 2;
  const distanceY = cameraCoords[1] + (parseFloat(el.style.width) * window.canvasScale) / 2 - window.innerHeight / 2;

  return Math.sqrt(distanceX * distanceX + distanceY * distanceY);
}

export function updateVideoVisibilityThreshold(newThreshold) {
  visibilityThreshold = newThreshold;
  updateVideoVisibility();
  log.debug(`Video visibility updated to nearest ${newThreshold} cameras.`);
}

export function updateVideoVisibility() {
  const cameras = Object.values(window.elementHandlers).filter((el) => el.constructor.elementType === 'CameraElement');
  let sortedCameras;
  // Sort the cameras if we want to potentially select some out by nearest X
  if (cameras.length > visibilityThreshold) {
    // Sort cameras by distance from center of the screen
    sortedCameras = cameras.sort((a, b) => {
      const da = distanceFromCenter(document.getElementById(`element-${a.elementId}`));
      const db = distanceFromCenter(document.getElementById(`element-${b.elementId}`));
      return da - db;
    });
  } else {
    sortedCameras = cameras;
  }

  // Make sure all cameras that are *on* are marked 'visible' up to a certain
  // amount, then visibility off for all others.
  const overflow = window.innerWidth * 0.4; // Disable cameras that are 10% outside of view
  let i = 0;
  sortedCameras.forEach((camera) => {
    if (camera === CameraElement.ownCamera) {
      return;
    }

    if (!camera.isOnScreen(overflow) && camera.isVideoOn) {
      camera.setVisible(false);
    } else if (i < visibilityThreshold && camera.isVideoOn) {
      camera.setVisible(true);
      i += 1;
    } else if (i >= visibilityThreshold && camera.isVideoOn) {
      camera.setVisible(false);
      i += 1;
    }
  });
}
