import React from 'react';
import { EmojiContainer } from '../shared-styles';
import { EmoteType } from '../../../definitions/emotes';

const HERE_PACK_ID = 'herePack';

interface Props {
  emoteUrl: string;
  onSelect: (emoteUrl: string, emoteType: EmoteType, pack?: string) => void;
}

const EmoteButton: React.FC<Props> = ({ emoteUrl, onSelect }) => (
  // we only have here pack emotes rn, but eventually emoteType and pack here should be pulled from emote redux store
  <EmojiContainer onClick={() => onSelect(emoteUrl, EmoteType.LIBRARY, HERE_PACK_ID)}>
    <img src={emoteUrl} height={22} width={22} alt="emote" />
  </EmojiContainer>
);

export default EmoteButton;
