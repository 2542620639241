import styled from 'styled-components';

import { svgColorMixin } from '../../mixins';
import PenIcon from '../../../../assets/icons/pen.svg';

export const MoodContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
  margin: 0 12px;
  max-width: 100%;
`;

export const EditIcon = styled(PenIcon)`
  background: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px 1px;
  position: absolute;
  top: -4px;
  right: -4px;
  display: none;
  align-items: center;
  justify-content: center;
  ${svgColorMixin('#12002d')}
`;

export const MoodContent = styled.div<{ html?: string; canEdit?: boolean }>`
  background: var(--primary-background);
  color: var(--primary-foreground);
  padding: 8px;
  border-radius: 20px;
  width: max-content;
  max-width: 234px;
  box-sizing: border-box;
  margin: 4px 0;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: center;
  word-break: break-word;
  position: relative;

  ${({ canEdit }) =>
    canEdit &&
    `
  cursor: pointer;

  &:hover ${EditIcon} {
    display: flex;
  }

  `}

  ${({ html }) =>
    typeof html === 'string' &&
    !html.length &&
    `
      text-align: left;

      &::after {
        content: 'How are you feeling?';
        color: var(--primary-foreground-alpha-30);
      }
  `}
`;
