import UIkit from 'uikit';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

// hooks
import { useOnScreen } from '../../hooks/useOnScreen';
import { useBoardMembersList } from '../../hooks/useBoardMembersList';

// utils
import { clamp, sanitize } from '../../../util';
import { copyLobbyRoomLink, deleteLobbyRoomOLD, leaveLobbyRoomOLD } from '../../../util/lobby-util';
import { nop } from '../../../util/js-util';
import { selectCurrentUserId } from '../../store/users/selectors';

// constants
import { VISIBLE_MEMBERS_COUNT } from '../../../constants/board-constants';

// icons
import RoomMemberIcon from '../../../../assets/icons/room-member.svg';

const publicRoomGlobeIcon = 'images/misc/planet.gif';

const DUPLICATE_ROOM_OPTION = {
  id: 'duplicate-room',
  icon: '/images/icons/electron-lobby/room-options/duplicate-room.svg',
  title: 'Duplicate Room',
  onClick: (room) => {
    const hereDuplicateDialog = document.getElementById('here-room-duplicate-dialog');
    hereDuplicateDialog.show(room, true);
  },
};

const COPY_ROOM_LINK_OPTION = {
  id: 'copy-link',
  icon: '/images/icons/electron-lobby/room-options/copy-room-link.svg',
  title: 'Copy Link',
  onClick: copyLobbyRoomLink,
};

const DELETE_ROOM_OPTION = {
  id: 'delete-room',
  icon: '/images/icons/electron-lobby/room-options/delete-room.svg',
  title: 'Delete Room',
  onClick: async ({ id, title }) => {
    try {
      await UIkit.modal.confirm(`Are you sure you want to delete the room "${sanitize(title)}"?`);
    } catch (err) {
      return;
    }

    deleteLobbyRoomOLD(id);
  },
};

const LEAVE_ROOM_OPTION = {
  id: 'leave-room',
  icon: '/images/icons/electron-lobby/room-options/leave-room.svg',
  title: 'Leave Room',
  onClick: async (roomData) => {
    try {
      await UIkit.modal.confirm(`Are you sure you want to leave the room "${sanitize(roomData.title)}"?`);
    } catch (err) {
      return;
    }

    leaveLobbyRoomOLD(roomData.id, roomData.userId);
  },
};

function isRedOption(optionId) {
  return optionId === DELETE_ROOM_OPTION.id;
}

function RoomItem({
  id,
  title,
  background,
  backgroundColor,
  creator,
  urlAlias,
  isPublic,
  publicShareMessage,
  showMenu,
  onRoomContainerClick,
  showRoomMembersIcon,
}) {
  const roomItemContainerRef = React.useRef(null);

  const [roomOptions, setRoomOptions] = React.useState([]);

  const currentUserId = useSelector(selectCurrentUserId);

  const { isIntersecting } = useOnScreen(roomItemContainerRef, 0.25);

  const { members } = useBoardMembersList({ boardId: id, active: isIntersecting });

  const { visibleMembers, hiddenMembersCount } = React.useMemo(
    () => ({
      visibleMembers: members.slice(0, VISIBLE_MEMBERS_COUNT),
      hiddenMembersCount: clamp(members.length - VISIBLE_MEMBERS_COUNT, 0, members.length),
    }),
    [members]
  );

  const backgroundUrl = React.useMemo(
    () => (Number.isInteger(background) ? `images/bg/${background}.jpg` : background),
    [background]
  );

  const onRoomOptionButtonClick = React.useCallback(
    (e) => {
      e.stopPropagation();

      const options = [COPY_ROOM_LINK_OPTION];
      if (
        currentUserId === creator ||
        members.find((member) => member.id === currentUserId && (member.role === 'owner' || member.role === 'host'))
      ) {
        options.push(DUPLICATE_ROOM_OPTION);

        if (currentUserId === creator) {
          options.push(DELETE_ROOM_OPTION);
        }
      } else {
        options.push(LEAVE_ROOM_OPTION);
      }

      setRoomOptions(options);
    },
    [members, currentUserId, creator]
  );

  const onRoomOptionClick = (event, cb) => {
    event.stopPropagation();
    cb();
  };

  return (
    <RoomItemWrapper>
      <RoomItemContainer ref={roomItemContainerRef} backgroundColor={backgroundColor} onClick={onRoomContainerClick}>
        {isPublic ? (
          <PublicRoomOverlay>
            <PublicRoomGlobeIconContainer>
              <img width="40px" src={publicRoomGlobeIcon} alt="public room globe" />
            </PublicRoomGlobeIconContainer>

            <RoomInviteMessageContainer>
              <RoomInviteMessage>{publicShareMessage}</RoomInviteMessage>
            </RoomInviteMessageContainer>

            <PublicRoomOverlayCover />
          </PublicRoomOverlay>
        ) : null}

        {backgroundUrl ? <RoomItemImage backgroundImage={backgroundUrl} alt="room image" /> : null}

        <RoomItemFooter>
          <RoomMembersList>
            {visibleMembers.map((member) => (
              <RoomMembersListItem key={member.id}>
                <here-avatar class="color-ring" userId={member.id} roomId={id} />
              </RoomMembersListItem>
            ))}
            {hiddenMembersCount ? <HiddenRoomMembersCount>+{hiddenMembersCount}</HiddenRoomMembersCount> : null}
          </RoomMembersList>

          <RoomItemTitleWrapper>
            <RoomItemTitle>{title}</RoomItemTitle>
            {showMenu ? <RoomOptionButton id={`room-option-button-${id}`} onClick={onRoomOptionButtonClick} /> : null}
          </RoomItemTitleWrapper>
        </RoomItemFooter>

        {showRoomMembersIcon ? (
          <RoomMembersIconWrapper>
            <RoomMemberIcon width="20px" />
            <RoomMembersIconText>{members.length}</RoomMembersIconText>
          </RoomMembersIconWrapper>
        ) : null}
      </RoomItemContainer>

      {showMenu ? (
        <div
          uk-dropdown={`mode: click; toggle: #room-option-button-${id}; pos: bottom-right`}
          className="uk-dropdown room-options-dropdown"
        >
          <ul className="uk-nav uk-dropdown-nav">
            {roomOptions.map((roomOption) => (
              <li key={roomOption.id}>
                <RoomOptionItemButton
                  isRed={isRedOption(roomOption.id)}
                  className="uk-dropdown-close"
                  type="button"
                  onClick={
                    (e) =>
                      onRoomOptionClick(e, () => roomOption.onClick({ id, title, urlAlias, userId: currentUserId }))
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  <RoomOptionItemButtonIcon src={roomOption.icon} />
                  <RoomOptionItemButtonText>{roomOption.title}</RoomOptionItemButtonText>
                </RoomOptionItemButton>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </RoomItemWrapper>
  );
}

RoomItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  creator: PropTypes.string,
  urlAlias: PropTypes.string,
  isPublic: PropTypes.bool,
  showMenu: PropTypes.bool,
  publicShareMessage: PropTypes.string,
  onRoomContainerClick: PropTypes.func,
  showRoomMembersIcon: PropTypes.bool,
};

RoomItem.defaultProps = {
  background: '',
  backgroundColor: '',
  creator: '',
  urlAlias: '',
  isPublic: false,
  showMenu: false,
  publicShareMessage: 'Public rooms are a great place to meet new friends, have fun and join a new community.',
  onRoomContainerClick: nop,
  showRoomMembersIcon: false,
};

export const RoomItemWrapper = styled.div`
  position: relative;
  padding: 2px;
  background-color: transparent;
  border-radius: 10px;

  & + & {
    margin-top: 13px;
  }

  &:hover {
    background-color: #ffffff;
  }

  .room-options-dropdown {
    padding: 4px 8px;
    border-radius: 16px;
  }
`;

const PublicRoomOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PublicRoomOverlayCover = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(90deg, rgba(27, 1, 170, 0.8) 0%, rgba(85, 1, 127, 0.8) 100%);
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
`;

const RoomInviteMessageContainer = styled.div`
  padding: 2.5px 5px 0 5px;
  max-height: 27px;
  z-index: 2;
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
`;

const RoomInviteMessage = styled.p`
  margin: 0;
  color: #ffffff;
  font-size: 10px;
  line-height: 12px;
  text-align: justify;
  text-overflow: ellipsis;
`;

const PublicRoomGlobeIconContainer = styled.div`
  padding: 0 5px;
  z-index: 3;
  width: fit-content;
  border-bottom-right-radius: 8px;
  background-color: #12002d;
`;

const RoomOptionButton = styled.button`
  width: 30px;
  height: 30px;
  visibility: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 15px;
  background-image: url(/images/icons/more.svg);
  filter: invert(1);
  background-color: inherit;
  border: none;
  clip-path: circle(40%);
  cursor: pointer;

  &:hover {
    filter: invert(0);
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const RoomItemContainer = styled.div`
  position: relative;
  height: 125px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    ${PublicRoomOverlayCover}, ${RoomInviteMessageContainer} {
      opacity: 1;
    }

    ${RoomOptionButton} {
      visibility: visible;
    }
  }
`;

const RoomItemImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage});`};
`;

const RoomMembersList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RoomMembersListItem = styled.li`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-align: center;
  clip-path: circle(50%);

  & + & {
    margin-left: 2px;
  }
`;

const HiddenRoomMembersCount = styled(RoomMembersListItem)`
  margin-left: 2px;
  color: #ffffff;
  background-color: inherit;
  border: 1px solid #ffffff;
  border-radius: 100%;
`;

export const RoomItemFooter = styled.div`
  padding: 6px 10px;
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.23) 85%, rgba(0, 0, 0, 0) 100%);
`;

const RoomItemTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RoomItemTitle = styled.span`
  color: #ffffff;
  font-size: 14px;
`;

const RoomOptionItemButtonIcon = styled.img`
  width: 18px;
`;

const RoomOptionItemButtonText = styled.span`
  margin-left: 5px;
  display: inline-block;
  font-weight: bolder;
`;

const RoomOptionItemButton = styled.button`
  padding: 6px 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: inherit;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: ${({ isRed }) => (isRed ? '#f6335d33' : '#efefef')};
  }

  ${RoomOptionItemButtonText} {
    color: ${({ isRed }) => (isRed ? '#f6335d' : '#000000')};
  }
`;

const RoomMembersIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  display: flex;
  gap: 3px;
`;

const RoomMembersIconText = styled.span`
  color: #ffffff;
  font-family: Inter;
  font-size: 12px;
  font-weight: bolder;
`;

export default RoomItem;
