import React from 'react';
import styled from 'styled-components';

import { Overlay } from '../../shared-styles';
import { useMultistep } from '../../../../hooks/useMultistep';
import BanStep from './steps/BanStep';
import BannedStep from './steps/BannedStep';

interface BanUserModalProps {
  userId: string;
  chatId: string;
  close: () => void;
}

const BanUserModal = ({ userId, chatId, close }: BanUserModalProps) => {
  const { step } = useMultistep([
    (next: () => void) => <BanStep userId={userId} chatId={chatId} next={next} close={close} />,
    () => <BannedStep userId={userId} />,
  ]);

  return (
    <Overlay onClick={close}>
      <Container onClick={(e) => e.stopPropagation()}>{step}</Container>
    </Overlay>
  );
};

const Container = styled.div`
  padding: 40px 60px;
  background-color: white;
  border-radius: 20px;
`;

export default BanUserModal;
