import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { USER_CARD_HEIGHT_PX, USER_CARD_WIDTH_PX } from '../../constants/user-card-constants';
import DotsNavigation from '../components/DotsNavigation';
import { svgColorMixin } from '../mixins';
import { trackUserPageVisit } from '../../util/user-util';
import UserCardIconWithBadge from '../user-card/UserCardIconWithBadge';
import CardView from '../user-card/CardView';
import { BoardControllerContext } from '../common/contexts.ts';

import PrevArrowIcon from '../../../assets/icons/arrows/rounded-chevron-left.svg';
import NextArrowIcon from '../../../assets/icons/arrows/rounded-chevron-right.svg';

const ProfileCarousel = ({ children, cardIds, ctas, userId, initialCardIndex, isNavigationDisabled }) => {
  const [currentIndex, setCurrentIndex] = useState(initialCardIndex || 0);

  const slidesCount = cardIds.length + 1;
  useEffect(() => {
    if (!initialCardIndex && currentIndex >= slidesCount) {
      setCurrentIndex(slidesCount - 1);
    }
  }, [slidesCount, currentIndex, initialCardIndex]);

  const [hasTracked, setHasTracked] = useState(false);
  useEffect(() => {
    if (!hasTracked && currentIndex > 0) {
      trackUserPageVisit(userId, 'User Profile');
      setHasTracked(true);
    }
  }, [currentIndex, hasTracked, userId]);

  const onPrevClick = useCallback(() => setCurrentIndex(currentIndex - 1), [currentIndex]);
  const onNextClick = useCallback(() => setCurrentIndex(currentIndex + 1), [currentIndex]);

  const dummyBoardController = useMemo(
    () => ({
      addElement: () => {},
    }),
    []
  );

  return (
    <>
      <Container className={currentIndex > 0 ? 'unthemed' : ''}>
        <InnerContainer total={slidesCount} current={currentIndex}>
          <CarouselItem key="user-profile">{children}</CarouselItem>
          <BoardControllerContext.Provider value={dummyBoardController}>
            {cardIds.map((cardId) => (
              <CarouselItem key={cardId}>
                <CardView cardId={cardId} isEditMode={false} />
              </CarouselItem>
            ))}
          </BoardControllerContext.Provider>
        </InnerContainer>
        <PrevButton onClick={onPrevClick} disabled={isNavigationDisabled || currentIndex === 0}>
          <PrevArrowIcon />
        </PrevButton>
        <NextButton onClick={onNextClick} disabled={isNavigationDisabled || currentIndex === slidesCount - 1}>
          <NextArrowIcon />
        </NextButton>
        {currentIndex === 0 ? (
          ctas
        ) : (
          <DotsContainer isHiddenDisplay={currentIndex === 0}>
            <DotsNavigation
              color="var(--primary-foreground, white)"
              totalCount={slidesCount}
              currentIndex={currentIndex}
              onIndexChange={setCurrentIndex}
            />
          </DotsContainer>
        )}
      </Container>
      {cardIds?.length > 0 && currentIndex === 0 ? (
        <UserCardsIconContainer>
          <UserCardIconWithBadge userId={userId} onClick={onNextClick} />
        </UserCardsIconContainer>
      ) : null}
    </>
  );
};

export default ProfileCarousel;

ProfileCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  cardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  userId: PropTypes.string.isRequired,
  ctas: PropTypes.node,
  initialCardIndex: PropTypes.number,
  isNavigationDisabled: PropTypes.bool,
};

ProfileCarousel.defaultProps = {
  ctas: null,
  initialCardIndex: 0,
  isNavigationDisabled: false,
};

const SideButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  background: none;
  border: none;
  transition: opacity 0.2s ease-out;
  width: 40px;
  z-index: 1;
  ${svgColorMixin('var(--primary-foreground, white)')}

  &:disabled {
    display: none;
  }
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;

  &:hover ${SideButton} {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
`;

const InnerContainer = styled.div`
  width: ${({ total }) => total * USER_CARD_WIDTH_PX}px;
  transform: translate3d(${({ current }) => -current * USER_CARD_WIDTH_PX}px, 0, 0);
  transition: transform 0.3s ease-out;
  display: flex;
`;

const CarouselItem = styled.div`
  width: ${USER_CARD_WIDTH_PX}px;
  height: ${USER_CARD_HEIGHT_PX}px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
`;

const DotsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.2);
  width: fit-content;
  padding: 6px 8px;
  border-radius: 16px;
`;

const PrevButton = styled(SideButton)`
  left: 0;
  background: linear-gradient(-90deg, rgba(19, 1, 46, 0) 0%, rgba(19, 2, 46, 0.4) 100%);
`;

const NextButton = styled(SideButton)`
  right: 0;
  background: linear-gradient(90deg, rgba(19, 1, 46, 0) 0%, rgba(19, 2, 46, 0.4) 100%);
`;

const UserCardsIconContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;

  z-index: 1;

  width: 30px;
  height: 30px;

  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: scale(1.3);
  }
`;
