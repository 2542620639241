import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import { Blurred } from '../../components/shared-styles';
import { TypingIndicatorInnerGlow, TypingIndicatorOuterGlow } from './typing-indicator-shared-styles';

// utils
import { getTypingAnimationIcon, wrapWithAnimations } from './utils';

function TypingIndicatorItem(props) {
  const { color, shape, animations, addGlow } = props;

  const AnimatedTypingIndicatorItemIcon = React.useMemo(() => {
    const TypingIndicatorItemIcon = getTypingAnimationIcon(shape);
    return wrapWithAnimations(() => <TypingIndicatorItemIcon color={color} />, animations);
  }, [shape, color, animations]);

  return (
    <TypingIndicatorItemContainer>
      <GlowContainer glowVisible={addGlow} blurity="7px">
        <TypingIndicatorInnerGlow />
        <TypingIndicatorOuterGlow />
      </GlowContainer>

      <AnimatedTypingIndicatorItemIcon />
    </TypingIndicatorItemContainer>
  );
}

TypingIndicatorItem.propTypes = {
  color: PropTypes.string,
  addGlow: PropTypes.bool,
  shape: PropTypes.string.isRequired,
  animations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TypingIndicatorItem.defaultProps = {
  color: undefined,
  addGlow: false,
};

const TypingIndicatorItemContainer = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
`;

const GlowContainer = styled(Blurred)`
  transform: translateY(10px);
  opacity: ${({ glowVisible }) => (glowVisible ? '1' : '0')};
  transition: opacity 0.25s ease-in-out;
`;

export default TypingIndicatorItem;
