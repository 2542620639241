import { eventChannel } from 'redux-saga';
import UIkit from 'uikit';

import { takeEvery } from 'redux-saga/effects';
import eventBus, { userUpdated } from '../../event-bus';

export function* watchAddImagePopupOpened(saga) {
  const channel = eventChannel((emitter) => {
    const callback = () => emitter(true);
    UIkit.util.on('#sticker-select', 'toggle', callback);

    return () => UIkit.util.off('#sticker-select', 'show', callback);
  });

  yield takeEvery(channel, saga);
}

export function* watchAddRoomKitPopupOpened(saga) {
  const channel = eventChannel((emitter) => {
    const callback = () => emitter(true);
    UIkit.util.on('#room-kit-select', 'toggle', callback);

    return () => UIkit.util.off('#room-kit-select', 'show', callback);
  });

  yield takeEvery(channel, saga);
}

export function* watchCameraContextMenuItemClick(saga) {
  const channel = eventChannel((emitter) => {
    const callback = (payload) => {
      emitter(payload);
    };

    eventBus.on(userUpdated, callback);

    return () => eventBus.off(userUpdated, callback);
  });

  yield takeEvery(channel, saga);
}
