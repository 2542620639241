// To pass in as game param:
export const Games = {
  CHESS: 'Chess',
  CODENAMES: 'Codenames',
  POKER: 'Poker',
  TICTACTOE: 'Tic Tac Toe',
  SKRIBBLIO: 'Skribbl.io',
};

export const STARTED_GAME = 'Started Playing Game'; // params: game
