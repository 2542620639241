import { useEffect, useState } from 'react';

export const useBoardElementResize = (containerId: string, containerInitHeight: number, containerInitWidth: number) => {
  const [heightScale, setHeightScale] = useState(1);
  const [widthScale, setWidthScale] = useState(1);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setHeightScale(entries[0].contentRect.height / containerInitHeight);
      setWidthScale(entries[0].contentRect.width / containerInitWidth);
    });

    const container = document.getElementById(`element-${containerId}`);
    if (container) {
      observer.observe(container);
    }

    return () => {
      observer.disconnect();
    };
  }, [containerId, containerInitHeight, containerInitWidth]);

  return { heightScale, widthScale };
};
