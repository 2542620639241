import firebase, { db } from './firebase';
import log from './log';
import { track } from './util/analytics-util';

let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();

  messaging.onMessage((__payload) => {
    // TODO we can use this for foreground messages if we want to
  });
}

// Get Instance ID token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
export async function getPushToken() {
  try {
    const currentToken = await messaging.getToken({ vapidKey: process.env.MESSAGING_VAPID_KEY });
    if (currentToken) {
      await savePushToken(currentToken);
      track('Notification Token Received');
    } else {
      // Show permission request.
      log.debug('No Instance ID token available. Request permission to generate one.');
      track('Notification Token Empty');
      // Show permission UI.
      // updateUIForPushPermissionRequired();
      // setTokenSentToServer(false);
    }
  } catch (error) {
    log.debug('An error occurred while retrieving token. ', error);
    track('Notification Token Denied');
  }
}

export async function savePushToken(token) {
  await db.collection('userProfiles').doc(firebase.auth().currentUser.uid).set(
    {
      pushToken: token,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  );

  track('Push Notification Token Updated');

  log.debug('Token saved successfully');

  return true;
}
