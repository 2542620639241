import { fetchThemes } from './api/themes-api';
import { Theme } from './definitions/theme';

import themes from '../assets/data/themes.json';

let themesData: Theme[] = [];

export const getThemes = async () => {
  if (!themesData.length) {
    const response = await fetchThemes();
    themesData = [...response.data.themes, ...themes];
  }

  return themesData;
};
