import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';

export const itemTypes = {
  templates: 'templates',
  kits: 'kits',
};

const DEFAULT_CATEGORY = itemTypes.templates;

const initialState = {
  isBlankRoomSelected: false,
  isBasicsRoomSelected: false,
  isBackgroundClicked: false,
  isLoading: false,
  chosenCategory: DEFAULT_CATEGORY,
  type: DEFAULT_CATEGORY,
  categories: [],
  isFirstTimeUser: false,
  kits: [],
  templates: [],
  nextPage: 1,
  hasMore: true,
  items: {
    all: [],
    chosenItem: null,
    current: [],
    perPage: 100,
    gradients: [
      ['#2b3b8f', '#c1265e'],
      ['#36398b', '#41bbff'],
      ['#ffd25e', '#dd21a8'],
      ['#bedb6c', '#30d3dd'],
    ],
  },
};

export const setHasMore = createAction('template/setHasMore');
export const setHasMoreKits = createAction('template/setHasMoreKits');
export const setCategories = createAction('template/setCategories');
export const fetchCategories = createAction('template/fetchCategories');
export const fetchTemplates = createAction('template/fetchTemplates');
export const fetchTemplatesPage = createAction('template/fetchTemplatesPage');
export const setTemplates = createAction('template/setTemplates');
export const categoryChosen = createAction('template/categoryChosen');
export const itemChosen = createAction('template/itemChosen');
export const backgroundClicked = createAction('template/backgroundClicked');
export const resetVisibility = createAction('template/resetVisibility');
export const setForKits = createAction('template/setForKits');
export const blankRoomSelected = createAction('template/blankRoomSelected');
export const basicsRoomSelected = createAction('template/basicsRoomSelected');
export const fetchKits = createAction('template/fetchKits');
export const kitChosen = createAction('template/kitChosen');
export const setChosenKit = createAction('template/setChosenKit');
export const setKits = createAction('template/setKits');
export const setNextPage = createAction('template/setNextPage');
export const setIsLoading = createAction('template/setIsLoading');
export const setType = createAction('template/setType');

export const getHasMore = (state) => state.template.hasMore;
export const getType = (state) => state.template.type;
export const getKits = (state) => state.template.kits;
export const getTemplates = (state) => state.template.templates;
export const getCategories = (state) => state.template.categories;
export const getItems = (state) => state.template.items.current;
export const getChosenItem = (state) => state.template.items.chosenItem;
export const getChosenCategory = (state) => state.template.chosenCategory;
export const getIsBlankRoomSelected = (state) => state.template.isBlankRoomSelected;
export const getIsBasicsRoomSelected = (state) => state.template.isBasicsRoomSelected;
export const getIsBackgroundClicked = (state) => state.template.isBackgroundClicked;
export const getNextPage = (state) => state.template.nextPage;
export const getIsLoading = (state) => state.template.isLoading;
export const getItemByIndex = createSelector(
  getItems,
  (state, { index }) => index,
  (items, index) => items[index]
);

export const templateReducer = createReducer(initialState, {
  [setType]: (state, { payload }) => {
    const { type } = payload;
    state.type = type;
  },
  [setHasMore]: (state, { payload }) => {
    const { hasMore } = payload;
    state.hasMore = hasMore;
  },
  [setHasMoreKits]: (state, { payload }) => {
    const { hasMoreKits } = payload;
    state.items.hasMoreKits = hasMoreKits;
  },
  [setCategories]: (state, { payload }) => {
    const { categories } = payload;
    state.categories = categories.filter(
      (category) => category !== 'new' && category !== 'popular' && category !== 'tutorials'
    );
  },
  [setTemplates]: (state, { payload }) => {
    const { templates } = payload;
    state.templates = templates;
  },
  [categoryChosen]: (state, { payload }) => {
    const { category } = payload;
    state.chosenCategory = category;
  },
  [itemChosen]: (state, { payload }) => {
    const { item } = payload;
    state.items.chosenItem = item;
    state.isBlankRoomSelected = false;
    state.isBasicsRoomSelected = false;
    if (state.type === itemTypes.kits) state.isBackgroundClicked = true;
  },
  [backgroundClicked]: (state) => {
    state.nextPage = 1;
    state.hasMore = true;
    state.isBackgroundClicked = true;
  },
  [resetVisibility]: (state) => {
    state.isBackgroundClicked = false;
  },
  [setForKits]: (state) => {
    state.type = itemTypes.kits;
    state.chosenCategory = itemTypes.kits;
  },
  [blankRoomSelected]: (state) => {
    state.isBlankRoomSelected = true;
  },
  [basicsRoomSelected]: (state) => {
    state.isBasicsRoomSelected = true;
  },
  [setKits]: (state, { payload }) => {
    const { kits } = payload;
    state.kits = kits;
    state.items.all = kits;
    state.items.current = kits;
  },
  [setNextPage]: (state, { payload }) => {
    const { page } = payload;
    state.nextPage = page;
  },
  [setIsLoading]: (state, { payload }) => {
    const { isLoading } = payload;
    state.isLoading = isLoading;
  },
  [setChosenKit]: (state, { payload }) => {
    const { kit } = payload;
    state.selectedKit = kit;
    state.isBackgroundClicked = true;
  },
});
