// Activities are defined as:

import { track } from './analytics-util';

// You can perform [key] [maxCount] times within [timeWindow] ms.
export const Activities = {
  SendMessage: {
    key: 'sendMessage',
    maxCount: 5,
    timeWindow: 8 * 1000,
  },
  AddElement: {
    key: 'addElement',
    maxCount: 5,
    timeWindow: 10 * 1000,
  },
  AddRoomKit: {
    key: 'addRoomKit',
    maxCount: 1,
    timeWindow: 2 * 1000,
  },
};

const activityLog = {};

/**
 *
 * @param {Activities} activity
 * @param {function} action
 * @param {function} onThrottled
 * @returns
 */
export function throttleControl(activity, action, onThrottled) {
  if (!activityLog[activity.key]) {
    activityLog[activity.key] = [new Date()];
    action();
    return;
  }

  const dates = activityLog[activity.key];
  while (dates.length > activity.maxCount) {
    dates.shift();
  }

  if (dates.length < activity.maxCount) {
    dates.push(new Date());
    action();
    return;
  }

  const now = new Date();
  if (now - dates[0] > activity.timeWindow) {
    action();
    dates.push(now);
  } else {
    track('Activity Throttled', { activity: activity.key });
    if (onThrottled) {
      onThrottled();
    }
  }
}
