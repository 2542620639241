import { db } from '../../../firebase';

// constants
import { SEND_FEED_MESSAGE_SOURCES } from '../../../constants/analytics-events/chat-events.ts';
import { gifPickerCallers } from '../../../constants/image-constants';

const downArrowButtonPath = 'images/icons/down-black.svg';

export const formatFeedItemFromDocChanges = (feedItemRaw) => ({
  id: feedItemRaw.doc.id,
  ...feedItemRaw.doc.data(),
  createdAt: feedItemRaw.doc.data().createdAt, // createdAt is a getter so I need to extract its value manually
  ts: feedItemRaw.doc.data().createdAt ? feedItemRaw.doc.data().createdAt.toDate() : new Date(),
});

export const formatFeedItemFromDocs = (feedItemRaw) => ({
  id: feedItemRaw.id,
  ...feedItemRaw.data(),
  createdAt: feedItemRaw.data().createdAt, // createdAt is a getter so I need to extract its value manually
  ts: feedItemRaw.data().createdAt ? feedItemRaw.data().createdAt.toDate() : new Date(),
});

/**
 *
 * @param {string} topMessageId
 * @param {string} messagesCollectionPath
 * @param {number} pageSize
 * @returns
 */
export async function loadMessagesPage(topMessageId, messagesCollectionPath, pageSize) {
  let topMessageDocCursor = null;
  if (topMessageId) {
    topMessageDocCursor = await db.doc(`${messagesCollectionPath}/${topMessageId}`).get();
  }

  if (topMessageDocCursor) {
    return db
      .collection(messagesCollectionPath)
      .orderBy('createdAt', 'desc')
      .startAfter(topMessageDocCursor)
      .limit(pageSize)
      .get();
  }

  return db.collection(messagesCollectionPath).orderBy('createdAt', 'desc').limit(pageSize).get();
}

export function updateMessageColorUI(newColor) {
  const messageColorButtonEls = document.querySelectorAll('.message-color-icon');
  messageColorButtonEls.forEach((el) => {
    el.style.backgroundColor = newColor;
  });
  const feedTextareaEls = document.querySelectorAll('.input-sizer');
  feedTextareaEls.forEach((el) => {
    el.style.backgroundColor = newColor;
  });
}

export function updateMessageFontColorUI(newColor) {
  const messageColorButtonEls = document.querySelectorAll('.font-color-indicator');
  messageColorButtonEls.forEach((el) => {
    el.style.backgroundColor = newColor;
  });
  const feedTextareaEls = document.querySelectorAll('.input-text');
  feedTextareaEls.forEach((el) => {
    el.style.color = newColor;
  });
}

export function updateMessageFontSizeUI(newSize) {
  const messageFontSizeEls = document.querySelectorAll('.select-font-size');
  messageFontSizeEls.forEach((el) => {
    el.innerHTML = `${newSize} <img src=${downArrowButtonPath} alt="" />d`;
  });
  const messageFontSizeButtons = document.querySelectorAll('.select-font-size-button');
  messageFontSizeButtons.forEach((el) => {
    el.innerHTML = `${newSize} <img src=${downArrowButtonPath} alt="" />`;
  });
  const feedTextareaContainerEls = document.querySelectorAll('.input-sizer');
  feedTextareaContainerEls.forEach((el) => {
    el.style.fontSize = newSize;
    el.style.lineHeight = newSize;
  });
  const feedTextareaEls = document.querySelectorAll('.input-text');
  feedTextareaEls.forEach((el) => {
    el.style.fontSize = newSize;
    el.style.lineHeight = newSize;
  });
}

export function updateMessageFontFamilyUI(newFontFamily) {
  const feedTextareaContainerEls = document.querySelectorAll('.input-sizer');
  feedTextareaContainerEls.forEach((el) => {
    el.style.fontFamily = newFontFamily;
  });
  const fontSelectorEls = document.querySelectorAll('.dropdown-font-family');
  fontSelectorEls.forEach((el) => {
    // Why the hell we updated ALL font pickers??? Anyway, disabling it at list for react font select:
    // it can update itself.
    if (el.closest('.font-select-react')) return;
    el.innerHTML = newFontFamily;
    el.style.fontFamily = newFontFamily;
  });
  const feedTextareaEls = document.querySelectorAll('.input-text');
  feedTextareaEls.forEach((el) => {
    el.style.fontFamily = newFontFamily;
  });
}

export const getSourceFromCaller = (caller) => {
  switch (caller) {
    case gifPickerCallers.FEED: {
      return null;
    }

    case gifPickerCallers.ROOM_CHAT: {
      return SEND_FEED_MESSAGE_SOURCES.MESSAGES_SIDEBAR;
    }

    case gifPickerCallers.WEB_LOBBY_ROOM_CHAT: {
      return SEND_FEED_MESSAGE_SOURCES.WEB_LOBBY;
    }

    default: {
      return null;
    }
  }
};
