import styled from 'styled-components';

import { ellipsisTextMixin } from '../../mixins';

export const HintPositionContainer = styled.div`
  position: fixed;
`;

export const HintTitle = styled.h3`
  color: white;
  font-size: 34px;
  font-family: Nunito, Inter, sans-serif;
  font-weight: bolder;
  line-height: initial;

  ${ellipsisTextMixin()}
`;

export const HintDescription = styled.p`
  margin-top: 15px;
  color: white;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  opacity: 0.8;
`;

export const hintContainerSlideDownMs = 250;
