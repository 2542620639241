import ReactDOM from 'react-dom';
import UIkit from 'uikit';
import eventBus, { renderUserProfileRequested } from '../../event-bus';

import { htmlToElement } from '../../util';
import { PROFILE_CARD_HEIGHT_PX, PROFILE_CARD_WIDTH_PX } from './constants';

let lastClickedElement;
document.addEventListener('mousedown', (e) => {
  lastClickedElement = e.target;
});

export function openUserProfile({
  userId,
  topPosition,
  leftPosition,
  editUserMood = false,
  source = null,
  openToUserCards = false,
  isCentered = false,
  hasOverlay = false,
  customZIndex = null,
  preventOnClickOutside = false,
}) {
  let userProfileModalAnchor;
  let userProfileModal;
  let userProfileOverlay;

  if (!document.getElementById('user-profile')) {
    userProfileModalAnchor = htmlToElement('<div id="user-profile-ghost-anchor"></div>');
    userProfileModal = htmlToElement(
      `<div id="user-profile" uk-dropdown="mode: click; pos: bottom-left;" ${
        preventOnClickOutside ? 'class="prevent-on-click-outside"' : ''
      }></div>`
    );
    userProfileOverlay = htmlToElement('<div id="user-profile-overlay"></div>');
    document.body.append(userProfileOverlay, userProfileModalAnchor, userProfileModal);
  } else {
    userProfileModalAnchor = document.getElementById('user-profile-ghost-anchor');
    userProfileModal = document.getElementById('user-profile');
    userProfileOverlay = document.getElementById('user-profile-overlay');
    userProfileModal.style.display = 'block';
  }

  if (customZIndex) {
    userProfileModal.style.zIndex = customZIndex;
  }

  userProfileOverlay.style.display = hasOverlay ? 'block' : 'none';

  userProfileModalAnchor.style.top = isCentered
    ? `calc(50% - ${PROFILE_CARD_HEIGHT_PX / 2}px)`
    : `${getSafeTopPosition(topPosition)}px`;
  userProfileModalAnchor.style.left = isCentered
    ? `calc(50% - ${PROFILE_CARD_WIDTH_PX / 2}px)`
    : `${getSafeLeftPosition(leftPosition)}px`;

  eventBus.dispatch(renderUserProfileRequested, { userId, openToUserCards, editUserMood, source });

  UIkit.dropdown(userProfileModal).show();

  // It works the same way as prevent-on-click-outside class and used in various places
  // where the content is portalled for any reason. However it's better to keep those classes
  // separate just in case
  userProfileModal.addEventListener('beforehide', (e) => {
    if (lastClickedElement?.closest('.prevent-profile-closing')) {
      e.preventDefault();
    }
  });

  // unmount component after user profile is closed
  userProfileModal.addEventListener('hidden', () => {
    ReactDOM.unmountComponentAtNode(userProfileModal);
    userProfileOverlay.style.display = 'none';
  });
}

/**
 *
 * Closes user profile modal if it's open
 *
 */
export function closeUserProfile() {
  const userProfileModal = document.getElementById('user-profile');
  userProfileModal.style.display = 'none';

  if (userProfileModal) UIkit.dropdown(userProfileModal).hide();
}

/**
 *
 * Returns a safe top position if original position is too high/low
 * If not too high/low, returns original position
 *
 * @param {number} originalPosition
 * @returns {number}
 */
export function getSafeTopPosition(originalPosition) {
  const userProfileMaxHeight = PROFILE_CARD_HEIGHT_PX;
  const buffer = 25;

  const roomMenuContainer = document.getElementById('room-menu-container');
  const roomMenuContainerHeight = roomMenuContainer ? roomMenuContainer.offsetHeight : 0;

  if (originalPosition < buffer) {
    return buffer;
  }

  if (originalPosition + userProfileMaxHeight + roomMenuContainerHeight + buffer > window.innerHeight) {
    return window.innerHeight - userProfileMaxHeight - roomMenuContainerHeight - buffer;
  }

  return originalPosition;
}

/**
 *
 * Returns a safe left position if original position is too much to the right of the screen.
 * If not too far right, returns original position
 *
 * @param {number} originalPosition
 * @returns {number}
 */
export function getSafeLeftPosition(originalPosition) {
  const userProfileWidth = PROFILE_CARD_WIDTH_PX;
  const buffer = 10;
  const viewportWidth = window.visualViewport.width;

  if (originalPosition + userProfileWidth > viewportWidth) {
    return viewportWidth - userProfileWidth - buffer;
  }

  return originalPosition;
}
