import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Loader = ({ children }) => (
  <Container>
    <Dot background="#1030a0" delay={0.2} />
    <Dot background="#3040a0" delay={0.4} />
    <Dot background="#5040a0" delay={0.6} />
    <span>
      &nbsp;
      {children}
      &nbsp;
    </span>
    <Dot background="#7040a0" delay={0.8} />
    <Dot background="#9040a0" delay={1} />
    <Dot background="#a040a0" delay={1.2} />
  </Container>
);

Loader.propTypes = {
  children: PropTypes.node,
};

Loader.defaultProps = {
  children: null,
};

export default Loader;

const Container = styled.div`
  text-align: center;
`;

const wave = keyframes`
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
`;

const Dot = styled.div`
  display: inline-block;
  position: relative;
  width: 0.5em;
  height: 0.5em;
  margin: 0.4em 0.2em;
  border-radius: 50%;
  background: ${({ background }) => background};

  &::before {
    position: absolute;
    content: '';
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    animation: ${wave} 2s ease-out infinite;
    animation-delay: ${({ delay }) => delay}s;
  }
`;
