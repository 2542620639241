import { boardUsers } from '../presence';
import { htmlToElement } from '../util';
import { findCameraHandler } from '../util/camera-util';
import firebase from '../firebase';

let Chart = null;

const MaxDataPoints = 20;
const allStats = {};

const TimeChartOptions = {
  responsive: false,
  legend: {
    display: false,
  },
  animation: {
    duration: 300,
  },
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  hover: {
    mode: 'nearest',
    intersect: true,
  },
  scales: {
    x: {
      display: false,
      /*
      FIXME: Commenting until agree to install a date adapter to make it work

      type: 'time',
      time: {
        unit: 'second',
      },
      */
    },
    y: {
      display: true,
    },
  },
};

const driftChartConfig = () => ({
  type: 'line',
  data: {
    labels: [],
    datasets: [
      {
        label: 'A/V Drift',
        data: [],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        pointBackgroundColor: 'rgba(49, 117, 223, 1)',
        borderColor: 'rgba(49, 117, 223, 1)',
        fill: false,
      },
    ],
  },

  options: { ...TimeChartOptions },
});

const jitterChartConfig = () => ({
  type: 'line',
  data: {
    labels: [],
    datasets: [
      {
        label: '🎙 Jitter',
        data: [],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        pointBackgroundColor: 'rgba(150, 41, 126, 1)',
        borderColor: 'rgba(150, 41, 126, 1)',
        fill: false,
      },
      {
        label: '🎥 Jitter',
        data: [],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        pointBackgroundColor: 'rgba(69, 145, 108, 1)',
        borderColor: 'rgba(69, 145, 108, 1)',
        fill: false,
      },
      {
        label: '🔁 Roundtrip',
        data: [],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        pointBackgroundColor: 'rgba(67, 145, 168, 1)',
        borderColor: 'rgba(67, 145, 168, 1)',
        fill: false,
      },
    ],
  },
  options: { ...TimeChartOptions },
});

const pictureStatusConfig = () => ({
  type: 'line',
  data: {
    labels: [],
    datasets: [
      {
        label: 'FIRs',
        data: [],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        pointBackgroundColor: 'rgba(190, 0, 223, 1)',
        borderColor: 'rgba(190, 0, 223, 1)',
        fill: false,
      },
      {
        label: 'PLIs',
        data: [],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        pointBackgroundColor: 'rgba(0, 190, 223, 1)',
        borderColor: 'rgba(0, 190, 223, 1)',
        fill: false,
      },
      {
        label: 'NACKs',
        data: [],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        pointBackgroundColor: 'rgba(0, 190, 223, 1)',
        borderColor: 'rgba(0, 223, 190, 1)',
        fill: false,
      },
    ],
  },
  options: { ...TimeChartOptions },
});

/**
 * Checks all known Janus feeds against what we see in firestore
 * Highlights any differences between the two.
 *
 * @param { [key: string]: MediaStream; } activeStreams
 */
export function addTrackCheckData(activeStreams) {
  const users = boardUsers[window.currentBoardId];
  const trackData = [];
  const selfId = firebase.auth().currentUser.uid;
  Object.keys(users).forEach((user) => {
    if (user === selfId) {
      return;
    }

    trackData.push(htmlToElement(`<span class="username">${users[user].name}: </span>`));
    const stream = activeStreams[user];
    const camera = findCameraHandler(user);
    if (!camera) {
      trackData.push(htmlToElement('<span>[❌ No Chathead]</span>'));
    } else if (!stream) {
      if (!camera.isAudioOn && !camera.isVideoOn) {
        trackData.push(htmlToElement('<span>[Not Publishing, No Stream] </span>'));
      } else {
        trackData.push(htmlToElement('<span>[❌ Publishing, No Stream]</span>'));
      }
    } else {
      const audio = stream.getAudioTracks();
      const video = stream.getVideoTracks();

      if (!camera.isAudioOn) {
        trackData.push(htmlToElement('<span>[🎙: Off] </span>'));
      } else if (audio.length === 1) {
        trackData.push(htmlToElement('<span>[🎙: 🆗]</span>'));
      } else {
        trackData.push(htmlToElement(`<span>[🎙: ⚠️${audio.length} tracks⚠️]</span>`));
      }

      if (!camera.isVideoOn || !camera.isVisible) {
        trackData.push(htmlToElement('<span>[🎥: Off]</span>'));
      } else if (video.length === 1) {
        trackData.push(htmlToElement('<span>[🎥: 🆗]</span>'));
      } else {
        trackData.push(htmlToElement(`<span>[🎥: ⚠️${video.length} tracks⚠️]</span>`));
      }
    }
    trackData.push(htmlToElement('<br />'));
  });

  const statsBox = document.getElementById('webrtc-stats-box');
  statsBox.innerHTML = '';
  trackData.forEach((el) => {
    statsBox.appendChild(el);
  });
}

export function updateChartData({
  user,
  roundTripTime,
  audioJitter,
  videoJitter,
  trackSync,
  firCount,
  pliCount,
  nackCount,
}) {
  const userStats = allStats[user] || {};
  if (!userStats.jitterConfig) {
    userStats.jitterConfig = jitterChartConfig();
  }
  if (!userStats.driftConfig) {
    userStats.driftConfig = driftChartConfig();
  }
  if (!userStats.pictureStatusConfig) {
    userStats.pictureStatusConfig = pictureStatusConfig();
  }

  userStats.jitterConfig.data.labels.push(new Date());
  if (userStats.jitterConfig.data.labels.length > 20) {
    userStats.jitterConfig.data.labels.shift();
  }

  userStats.driftConfig.data.labels.push(new Date());
  if (userStats.driftConfig.data.labels.length > 20) {
    userStats.driftConfig.data.labels.shift();
  }

  userStats.pictureStatusConfig.data.labels.push(new Date());
  if (userStats.pictureStatusConfig.data.labels.length > 20) {
    userStats.pictureStatusConfig.data.labels.shift();
  }

  if (trackSync) {
    userStats.driftConfig.data.datasets[0].data.push(trackSync);
    if (userStats.driftConfig.data.datasets[0].data.length > MaxDataPoints) {
      userStats.driftConfig.data.datasets[0].data.shift();
    }
  }

  if (roundTripTime) {
    userStats.roundTripTime = roundTripTime;
    userStats.jitterConfig.data.datasets[2].data.push(roundTripTime);
    if (userStats.jitterConfig.data.datasets[2].data.length > MaxDataPoints) {
      userStats.jitterConfig.data.datasets[2].data.shift();
    }
  }

  if (audioJitter) {
    userStats.jitterConfig.data.datasets[0].data.push(audioJitter);
    if (userStats.jitterConfig.data.datasets[0].data.length > MaxDataPoints) {
      userStats.jitterConfig.data.datasets[0].data.shift();
    }
  }
  if (videoJitter) {
    userStats.jitterConfig.data.datasets[1].data.push(videoJitter);
    if (userStats.jitterConfig.data.datasets[1].data.length > MaxDataPoints) {
      userStats.jitterConfig.data.datasets[1].data.shift();
    }
  }
  [firCount, pliCount, nackCount].forEach((value, index) => {
    if (!value && value !== 0) return;
    userStats.pictureStatusConfig.data.datasets[index].data.push(value);
    if (userStats.pictureStatusConfig.data.datasets[index].data.length > MaxDataPoints) {
      userStats.pictureStatusConfig.data.datasets[index].data.shift();
    }
  });

  allStats[user] = userStats;
}

export async function refreshCharts() {
  if (!Chart) {
    Chart = (await import('chart.js/auto')).default;
  }
  const statsEl = document.getElementById('webrtc-stats-box');
  Object.keys(allStats).forEach((k) => {
    const v = allStats[k];
    let container = document.getElementById(`webrtc-stats-${k}`);
    if (!container) {
      container = htmlToElement(`<div class="webrtc-stat" id="webrtc-stats-${k}"></div>`);
      const userAvatar = htmlToElement(
        `<div style="position: absolute; width: 35px;height: 35px;border-radius: 50%;overflow: hidden;"><here-avatar userId="${k}" /></div>`
      );
      container.appendChild(userAvatar);

      const jitterCanvas = htmlToElement('<canvas width="240" height="100" style="margin-left: 40px"></canvas>');
      // const jitterChartContainer = htmlToElement('<div class="chart-container" style="margin-left:50px;position:relative;height:100px;width:300px;"></div>')
      // jitterChartContainer.appendChild(jitterCanvas);
      container.appendChild(jitterCanvas);

      const driftCanvas = htmlToElement(
        '<canvas width="240" height="100" style="margin-left: 40px; margin-top: 10px;"></canvas>'
      );
      // const driftChartContainer = htmlToElement('<div class="chart-container" style="margin-left:50px;position:relative;height:100px;width:300px;"></div>')
      // driftChartContainer.appendChild(driftCanvas);
      container.appendChild(driftCanvas);

      const pictureStatusCanvas = htmlToElement(
        '<canvas width="240" height="100" style="margin-left: 40px; margin-top: 10px;"></canvas>'
      );
      container.appendChild(pictureStatusCanvas);

      statsEl.appendChild(container);

      v.jitterChart = new Chart(jitterCanvas.getContext('2d'), v.jitterConfig);
      v.driftChart = new Chart(driftCanvas.getContext('2d'), v.driftConfig);
      v.pictureStatusChart = new Chart(pictureStatusCanvas.getContext('2d'), v.pictureStatusConfig);
    }

    v.jitterChart.update();
    v.driftChart.update();
    v.pictureStatusChart.update();
  });
}
