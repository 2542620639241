import React from 'react';
import ReactDOM from 'react-dom';

import { RoomStreaks } from './RoomStreaks';
import { WithRoomStore } from '../../store/Wrapper';

export function renderRoomStreaksBoardElement(root, { elementId }) {
  ReactDOM.render(
    <WithRoomStore>
      <RoomStreaks elementId={elementId} />
    </WithRoomStore>,
    root
  );
}
