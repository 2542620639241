import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const FadeIn = ({ time, children }) => <FadeInDiv time={time}>{children}</FadeInDiv>;

export default FadeIn;

FadeIn.propTypes = {
  time: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FadeIn.defaultProps = {
  time: '0.2s',
};

const fadeInAnimation = css`
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const FadeInDiv = styled.div`
  ${fadeInAnimation}

  animation: ${({ time }) => `fade-in ${time}`};
`;
