import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { hideableMixin } from '../mixins';

// A panel that opens from a side of the screen. Right now it's hardcoded to be the left side,
// but it's pretty easy to add a support for other sides.
const Offcanvas = ({
  isOpened,
  children,
  width,
  fullscreenBreakpoint,
  animationDurationMs,
  hasOverlay,
  onClose,
  customZIndex,
}) => {
  const [wasOpened, setWasOpened] = useState(false);
  useEffect(() => {
    if (isOpened) setWasOpened(true);
  }, [isOpened]);

  return (
    <>
      <Container
        isHiddenDisplay={!wasOpened}
        isOpened={isOpened}
        width={width}
        fullscreenBreakpoint={fullscreenBreakpoint}
        animationDurationMs={animationDurationMs}
        zIndex={customZIndex}
      >
        {children}
      </Container>
      {isOpened && hasOverlay ? <Overlay contentWidth={width} onClick={onClose} zIndex={customZIndex} /> : null}
    </>
  );
};

Offcanvas.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  fullscreenBreakpoint: PropTypes.number.isRequired,
  animationDurationMs: PropTypes.number,
  hasOverlay: PropTypes.bool,
  onClose: PropTypes.func,
  customZIndex: PropTypes.number,
};

Offcanvas.defaultProps = {
  animationDurationMs: 400,
  hasOverlay: true,
  onClose: () => {},
  customZIndex: 99999,
};

export default Offcanvas;

const slideInAnimation = () => keyframes`
  0% {
    transform: translateX(-100%);
    visibility: hidden;
  }

  100% {
    transform: translateX(0);
    visibility: visible;
  }
`;

const slideOutAnimation = () => keyframes`
  0% {
    transform: translateX(0);
    visibility: visible;
  }

  100% {
    transform: translateX(-100%);
    visibility: hidden;
  }
`;

const animationMixin = (width) => css`
  animation-name: ${({ isOpened }) => (isOpened ? slideInAnimation() : slideOutAnimation())};
  width: ${width};
`;
const Container = styled.div`
  ${hideableMixin()}
  ${({ width }) => animationMixin(`${width}px`)}

  z-index: ${({ zIndex }) => zIndex};
  position: fixed;
  top: 0;
  left: 0;
  animation-duration: ${({ animationDurationMs }) => animationDurationMs}ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  overflow: hidden;
  box-shadow: ${({ isOpened }) => (isOpened ? '12px 0px 20px 8px rgba(0, 0, 0, 0.15)' : 'none')};
  height: 100vh;
  max-width: 100vw;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: ${({ contentWidth }) => contentWidth}px;
  right: 0;
  height: 100vh;
  z-index: ${({ zIndex }) => zIndex};
`;
