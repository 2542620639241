import React from 'react';
import { connect } from 'react-redux';

import GifSearchResult from './GifSearchResult';
import SearchableContent from '../components/SearchableContent';
import * as store from '../store/gif-search/store';

export default connect(
  (state, props) => ({
    entityName: props.type,
    searchTerm: store.getSearchTerm(state, props),
    isLoading: store.getIsLoading(state, props),
    results: store
      .getResults(state, props)
      .map((gif) => (
        <GifSearchResult
          gif={gif}
          key={gif.id}
          type={props.type}
          isChat={props.isChat}
          elementId={props.elementId}
          elementMenuId={props.elementMenuId}
          firebaseId={props.firebaseId}
          chatId={props.chatId}
          caller={props.caller}
          onResultClick={props.onResultClick}
        />
      )),
    attribution: <img src="/images/misc/giphy_logo.png" alt="Giphy attribution" />,
  }),
  (dispatch, props) => {
    const { type, caller } = props;
    return {
      onSearchTermChanged: (searchTerm) => dispatch(store.searchTermChanged({ searchTerm, type })),
      onResultsRequest: () => dispatch(store.resultsRequested({ type, caller })),
      onLoadNext: () => dispatch(store.moreResultsRequested({ type })),
    };
  }
)(SearchableContent);
