import {
  createUsernameFlow,
  deepLinkFlow,
  onboardingFlow,
  createUsernameAndDisplayNameFlow,
  roomOnboardingFlow,
  createOsDesktopFlow,
  createOsChatFlow,
} from './flows.ts';

import { flows } from './constants.ts';

export const flowsMap = {
  [flows.STANDARD_ONBOARDING]: onboardingFlow,
  [flows.DEEP_LINK_SIGN_IN]: deepLinkFlow,
  [flows.CREATE_USERNAME]: createUsernameFlow,
  [flows.CREATE_USERNAME_AND_DISPLAY_NAME]: createUsernameAndDisplayNameFlow,
  [flows.ROOM_ONBOARDING]: roomOnboardingFlow,
  [flows.CREATE_OS_DESKTOP]: createOsDesktopFlow,
  [flows.CREATE_OS_CHAT]: createOsChatFlow,
};
