import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import ControlButton from './ControlButton';
import { BoardControllerContext, BoardElementControllerContext } from '../../common/contexts.ts';

import MoreIcon from '../../../../assets/icons/horizontal-more.svg';
import { svgColorMixin } from '../../mixins';

const ContextMenuButton = ({ onMenuOpened, onMenuClosed }) => {
  const boardController = useContext(BoardControllerContext);
  const elementController = useContext(BoardElementControllerContext);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  // Note: this callback is called only when the radix lib is changing the state (e.g. on click outside).
  // It is not called when we change the state manually (by calling setIsMenuOpened).
  const onOpenChange = useCallback(
    (isOpened) => {
      setIsMenuOpened(isOpened);
      if (isOpened) {
        onMenuOpened();
      } else {
        onMenuClosed();
      }
    },
    [onMenuOpened, onMenuClosed]
  );

  const onMoveToFront = useCallback(() => {
    elementController.patchDbData({ zIndex: boardController.maxZIndex + 1 });
    onOpenChange(false);
  }, [boardController.maxZIndex, elementController, onOpenChange]);
  const onMoveToBack = useCallback(() => {
    elementController.patchDbData({ zIndex: boardController.minZIndex - 1 });
    onOpenChange(false);
  }, [boardController.minZIndex, elementController, onOpenChange]);
  const onMirror = useCallback(() => {
    elementController.patchDbData({ isMirrored: !elementController.elementData.isMirrored });
    onOpenChange(false);
  }, [elementController, onOpenChange]);

  return (
    <Popover.Root open={isMenuOpened} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>
        <ControlButton>
          <MoreIconStyled />
        </ControlButton>
      </Popover.Trigger>
      <Popover.Content asChild align="start" sideOffset={10}>
        <Menu>
          <MenuItem onClick={onMoveToFront}>Move to Front</MenuItem>
          <MenuItem onClick={onMoveToBack}>Move to Back</MenuItem>
          <MenuItem onClick={onMirror}>Mirror</MenuItem>
        </Menu>
      </Popover.Content>
    </Popover.Root>
  );
};

export default ContextMenuButton;

ContextMenuButton.propTypes = {
  onMenuOpened: PropTypes.func,
  onMenuClosed: PropTypes.func,
};

ContextMenuButton.defaultProps = {
  onMenuOpened: () => {},
  onMenuClosed: () => {},
};

const Menu = styled.div`
  padding: 8px 12px;
  border-radius: 10px;
  background: white;

  &:focus-visible {
    outline: none;
  }
`;

const MenuItem = styled.div`
  color: #999;
  font-size: 14px;
  cursor: pointer;
  margin: 4px 0;

  &:hover {
    color: #666;
  }
`;

const MoreIconStyled = styled(MoreIcon)`
  width: 14px;
  ${svgColorMixin('black')}
`;
