import firebase, { db, typesenseClient } from '../firebase';
import { getRandomNumberInRange } from '../util';

const storage = firebase.storage();

// room kits
export async function fetchRoomKits() {
  const data = await db.collection('roomTemplates').where('isRoomKit', '==', true).get();
  return data.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

// room templates
export async function fetchAllTemplates() {
  const data = (await db.collection('roomTemplates').get()).docs;

  return data
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    .sort((a, b) => b.priority - a.priority);
}

export async function fetchArchivedTemplates() {
  const data = (await db.collection('roomTemplates').where('isArchived', '==', true).get()).docs;

  return data
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    .sort((a, b) => b.priority - a.priority);
}

export async function fetchUnArchivedTemplates() {
  const data = (await db.collection('roomTemplates').where('isArchived', '==', false).get()).docs;

  return data
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    .sort((a, b) => b.priority - a.priority);
}

export async function fetchRoomTemplates() {
  const data = await db.collection('roomTemplates').where('isTemplate', '==', true).get();
  return data.docs
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    .sort((a, b) => b.priority - a.priority);
}

export async function fetchRoomTemplatesPage(pageSize, searchQuery, page, category, type) {
  const templatesPageData = await typesenseClient
    .collections(process.env.TYPESENSE_TEMPLATES_COLLECTION)
    .documents()
    .search({
      q: searchQuery,
      query_by: 'name,keywords',
      per_page: pageSize,
      num_typos: '1,0',
      filter_by: `${type === 'templates' ? 'isTemplate:=true' : 'isRoomKit:=true'} ${
        category ? `&& categories:[${category}]` : ''
      } && isArchived:=false`,

      page,

      ...(searchQuery ? {} : { sort_by: 'priority:desc' }),
    });
  const templatesPage = templatesPageData.hits.map(({ document }) => document);
  return { templatesPage, found: templatesPageData.found };
}

export async function fetchRandomTemplate() {
  const templatesCollection = db.collection('roomTemplates');
  const randomKey = templatesCollection.doc().id;
  return new Promise((res) => {
    templatesCollection
      .where('isTemplate', '==', true)
      .where(firebase.firestore.FieldPath.documentId(), '>=', randomKey)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          const filteredDocs = snapshot.docs.filter((doc) => !doc.data().isArchived);
          const randomDoc = filteredDocs[Math.round(getRandomNumberInRange(0, filteredDocs.length - 1))];
          res(randomDoc.data());
        } else {
          templatesCollection
            .where(firebase.firestore.FieldPath.documentId(), '<', randomKey)
            .get()
            .then((innerSnapshot) => {
              const filteredDocs = innerSnapshot.docs.filter((doc) => !doc.data().isArchived);
              const randomDoc = filteredDocs[Math.round(getRandomNumberInRange(0, filteredDocs.length - 1))];
              res(randomDoc.data());
            });
        }
      });
  });
}

export async function fetchRoomTemplate(roomTemplateId) {
  const response = await db.doc(`roomTemplates/${roomTemplateId}`).get();
  return { ...response.data(), id: response.id };
}

export async function checkIfRoomTemplateUnique(roomTemplate) {
  const fetchResults = await Promise.all([
    db.collection('roomTemplates').where('name', '==', roomTemplate.name).get(),
    db.collection('roomTemplates').where('board', '==', roomTemplate.board).get(),
  ]);

  return fetchResults.every((result) => result.empty);
}

export async function createRoomTemplate(newRoomTemplate) {
  const createResponse = await db.collection('roomTemplates').add(newRoomTemplate);
  return createResponse.id;
}

export async function updateRoomTemplate(roomTemplateId, roomTemplateUpdates) {
  return db.doc(`roomTemplates/${roomTemplateId}`).update(roomTemplateUpdates);
}

export function deleteRoomTemplate(roomTemplateId) {
  return db.doc(`roomTemplates/${roomTemplateId}`).delete();
}

export function archiveRoomTemplate(roomTemplateId) {
  return db.doc(`roomTemplates/${roomTemplateId}`).update({ isArchived: true });
}

export function unArchiveRoomTemplate(roomTemplateId) {
  return db.doc(`roomTemplates/${roomTemplateId}`).update({ isArchived: false });
}

// template categories
export async function fetchCategories(type) {
  const categoriesRef = await db.collection('templateCategories');
  let categoriesData = [];
  if (type === 'templates') {
    categoriesData = await categoriesRef.where('templatesRelated', '==', true).get();
  } else if (type === 'kits') {
    categoriesData = await categoriesRef.where('kitsRelated', '==', true).get();
  } else if (type === 'all') {
    categoriesData = await categoriesRef.get();
  } else {
    throw new Error('wrong categories type');
  }

  return categoriesData.docs
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    .sort((a, b) => a.order - b.order);
}

export async function fetchTemplateCategory(templateCategoryId) {
  const response = await db.doc(`templateCategories/${templateCategoryId}`).get();
  return { ...response.data(), id: response.id };
}

export async function checkIfCategoryUnique(category) {
  const fetchedCategory = await db.collection('templateCategories').where('name', '==', category).get();
  return fetchedCategory.empty;
}

export async function createTemplateCategory(newCategory) {
  const createResponse = await db.collection('templateCategories').add(newCategory);
  const createdCategory = await db.doc(createResponse.path).get();
  return { ...createdCategory.data(), id: createdCategory.id };
}

export function updateTemplateCategory(templateCategoryId, templateCategoryUpdates) {
  return db.doc(`templateCategories/${templateCategoryId}`).update(templateCategoryUpdates);
}

export function deleteTemplateCategory(templateCategoryId) {
  return db.doc(`templateCategories/${templateCategoryId}`).delete();
}

// template images
export async function fetchTemplateImages() {
  const templateImagesList = await storage.ref('admin/template-images').listAll();
  return Promise.all(
    templateImagesList.items.map(async (templateImage) => ({
      url: await templateImage.getDownloadURL(),
      name: templateImage.name,
    }))
  );
}

// security
export function setAllowBoardAnonymous(boardId, allowAnonymous) {
  const setAllowBoardAnonymousFunc = firebase.functions().httpsCallable('setAllowBoardAnonymous');
  return setAllowBoardAnonymousFunc({ boardId, allowAnonymous });
}
