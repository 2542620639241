// utils
import { getOS } from '../../util/device-util';

// constants
import { OS_TYPES } from '../../constants/device-constants';

import template from './electron-upsell.html';

import './electron-upsell.less';

const SHOW_DOWNLOAD_ON_HOMEPAGE = false;

class ElectronUpsell extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    if (!SHOW_DOWNLOAD_ON_HOMEPAGE) {
      return;
    }

    // binds
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    // elements
    this.electronUpsellElement = this.querySelector('#electron-upsell');
    this.electronUpsellDownloadLinkElement = this.querySelector('#electron-upsell-download-link');

    if (window.location.pathname === '/') {
      if (getOS() === OS_TYPES.win) {
        this.querySelector('.windows-icon').classList.remove('hidden');
        this.querySelector('.download-cta').innerHTML = 'Download for Windows';
      } else if (getOS() === OS_TYPES.mac) {
        this.querySelector('.mac-icon').classList.remove('hidden');
        this.querySelector('.download-cta').innerHTML = 'Download for Mac';
      }

      this.show();
    }
  }

  show() {
    this.electronUpsellElement.classList.remove('hidden');
  }

  hide() {
    this.electronUpsellElement.classList.add('hidden');
  }
}

window.customElements.define('here-electron-upsell', ElectronUpsell);
