// Triggered whenever user changes shape of their camera in a room
// params: oldShape, newShape
export const CHANGE_CAMERA_SHAPE = 'Change Camera Shape';

export const CAMERA_MIC_ERROR = 'Camera/Mic Error';

// params: name
export const SET_FACE_FILTER = 'Set Face Filter';
export const REMOVE_FACE_FILTER = 'Remove Face Filter';

export const CANNOT_FIND_OWN_CAMERA = 'Cannot Find Own Camera';
