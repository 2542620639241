export const elementClasses = {
  IMAGE: 'ImageElement',
  WHITEBOARD: 'WhiteboardElement',
  TEXT: 'TextElement',
  MOOD: 'MoodElement',
  AUDIO_NOTE: 'AudioNote',
  YOUTUBE_EMBED: 'YoutubeEmbedElement',
  TIKTOK_EMBED: 'TiktokEmbedElement',
  VIDEO: 'VideoElement',
  ROOM_SHORTCUT: 'RoomShortcut',
  CHAT_WINDOW: 'ChatWindow',
};

export type ElementClass = typeof elementClasses[keyof typeof elementClasses];
