export function checkIsElectron() {
  return window.bridge !== undefined;
}

export function checkIsElectronBrowser() {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
}

export function isElectronFocused() {
  if (window.bridge && window.bridge.isFocused) {
    return window.bridge.isFocused();
  }
  return false;
}

const mobileRegex = /Android|webOS|iPhone|iPod|BlackBerry|BB|IEMobile|Windows Phone/i;
const tabletRegex = /iPad|Kindle|Silk|Android|android 3.0|xoom|sch-i800|playbook|tablet|nexus/i;

let isMobile = null;
export function checkIsMobile() {
  if (isMobile === null) {
    const ua = navigator.userAgent;
    isMobile = mobileRegex.test(ua) || mobileRegex.test(navigator.platform);
  }
  return isMobile;
}

export function isMobileOrTablet() {
  const ua = navigator.userAgent;
  return mobileRegex.test(ua) || tabletRegex.test(ua);
}

export function isiOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isAndroid() {
  return /Android/.test(navigator.userAgent);
}
