import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const OnlineCircle = ({ borderColor }) => <Circle borderColor={borderColor} />;

export default OnlineCircle;

OnlineCircle.propTypes = {
  borderColor: PropTypes.string,
};

OnlineCircle.defaultProps = {
  borderColor: '',
};

const Circle = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #39c360;
  border-radius: 50%;

  ${({ borderColor, theme }) => borderColor && `border: ${theme?.statusBorderWidth || 2}px solid ${borderColor};`}
`;
