/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { OfferParams } from './janus.definitions';
import JanusJS from './janus.definitions.original';
import promisify from './promisify';

interface CreateOfferCallbacks {
  customizeSdp?: (jsep: { sdp: string; type: 'offer' | 'answer' }) => void;
}

export type JanusPluginWrapperType = Partial<Omit<JanusJS.PluginHandle, 'createOffer' | 'send' | 'detach'>> & {
  createOffer: (options: OfferParams, callbacks?: CreateOfferCallbacks) => Promise<any>;
  send: (options: JanusJS.PluginMessage) => Promise<any>;
  detach: (options?: JanusJS.DetachOptions) => Promise<any>;
};

export default class VideoroomWrapper implements JanusPluginWrapperType {
  handler: JanusJS.PluginHandle;

  constructor(originalHandler: JanusJS.PluginHandle) {
    this.handler = originalHandler;

    return new Proxy(this, {
      get: (obj, prop) =>
        prop in this ? this[prop as keyof typeof this] : this.handler[prop as keyof typeof this.handler],
    });
  }

  createOffer(params: OfferParams, callbacks: CreateOfferCallbacks = {}) {
    return promisify(
      (...args: any[]) => this.handler.createOffer.apply(this.handler, args),
      Object.assign(params, callbacks)
    );
  }

  send(message: JanusJS.PluginMessage) {
    return promisify((...args: any[]) => this.handler.send.apply(this.handler, args), message);
  }

  detach(params: JanusJS.DetachOptions) {
    return promisify((...args: any[]) => this.handler.detach.apply(this.handler, args), params);
  }

  handleRemoteJsep(params: { jsep: JanusJS.JSEP }) {
    return promisify((...args: any[]) => this.handler.handleRemoteJsep.apply(this.handler, args), params);
  }
}
