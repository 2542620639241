const devKey = 'GgSdGbD1Sfc8PilmfKk1L-nw9SvSCS67fbeWX-QdMhQ';
const appName = 'here';
const baseUrl = 'https://api.unsplash.com';

const photosPerPage = 30;

class UnsplashApi {
  constructor() {
    this.cache = {}; // Amount of requests per hour is limited, so saving as much as possible.
  }

  async getPopularImages(page = 1) {
    const queryParams = {
      page,
      per_page: photosPerPage,
      order_by: 'popular',
    };

    const photos = await this.getRequest(`${baseUrl}/photos`, { queryParams });
    return photos.map(this.fromResponseImage.bind(this));
  }

  async searchImages(query, page = 1) {
    const queryParams = {
      query,
      page,
      per_page: photosPerPage,
    };

    const photos = await this.getRequest(`${baseUrl}/search/photos`, { queryParams });
    return photos.results.map(this.fromResponseImage.bind(this));
  }

  async sendDownloadRequest(url) {
    await this.getRequest(url, { useCache: false });
  }

  async getRequest(absoluteUrl, { queryParams = {}, useCache = true }) {
    const urlObject = new URL(absoluteUrl);

    const searchParams = new URLSearchParams(queryParams);
    searchParams.sort();
    urlObject.search = searchParams.toString();

    const url = urlObject.toString();
    if (useCache && this.cache[url]) {
      return this.cache[url];
    }

    const response = await fetch(urlObject, {
      headers: { Authorization: `Client-ID ${devKey}` },
    });
    if (!response.ok) {
      throw new Error('Error sending Unsplash API request');
    }

    const result = await response.json();
    this.cache[url] = result;
    return result;
  }

  fromResponseImage(data) {
    let fullName = data.user.first_name;
    if (data.user.last_name) {
      fullName += ` ${data.user.last_name}`;
    }

    return {
      id: data.id,
      small: data.urls.thumb,
      large: data.urls.regular,
      download: data.links.download_location,
      description: data.alt_description,
      author: {
        name: fullName,
        url: this.addUtm(data.user.links.html),
      },
    };
  }

  addUtm(link) {
    return `${link}?utm_source=${appName}&utm_medium=referral`;
  }
}

export default new UnsplashApi();
