import { useCallback, useContext } from 'react';

import { BoardControllerContext } from '../common/contexts.ts';
import { getLinkSummary } from '../../api/links-api';
import { getEmbedElementProperties } from '../board-elements/util.ts';
import { ADD_ELEMENT_SOURCE_TYPES } from '../../constants/analytics-events/element-events';

export const useBoardPaste = () => {
  const { addElement, uploadImage, areEmbeddedElementsSupported } = useContext(BoardControllerContext);

  const onPaste = useCallback(
    async (e) => {
      const file = e.clipboardData.files && e.clipboardData.files[0];
      if (file?.type.startsWith('image')) {
        await uploadImage(file, null, ADD_ELEMENT_SOURCE_TYPES.PASTED);
        return;
      }

      // TODO: More flexible way to determine what can be added to a board
      if (!areEmbeddedElementsSupported) return;

      const url = e.clipboardData.getData('text');
      if (!url.startsWith('https')) return;

      const summary = await getLinkSummary(url);
      if (!summary.embed) return;

      const elementData = getEmbedElementProperties(summary);
      if (elementData) {
        addElement(elementData);
      }
    },
    [addElement, areEmbeddedElementsSupported, uploadImage]
  );

  const listeners = { onPaste };
  return { listeners };
};
