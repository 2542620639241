import { useCallback, useContext, useRef, useState } from 'react';

import { BoardControllerContext, ViewportControllerContext } from '../common/contexts.ts';
import { elementClasses } from '../board-elements/elements.ts';
import { ADD_ELEMENT_SOURCE_TYPES } from '../../constants/analytics-events/element-events';

export const useBoardDnd = () => {
  const viewportController = useContext(ViewportControllerContext);
  const boardController = useContext(BoardControllerContext);

  const [isDropAreaVisible, setIsDropAreaVisible] = useState(false);
  // enter/leave events are triggered on every container child, so we need to keep
  // depth level count to actually know if we're inside the container.
  const depthLevelRef = useRef(0);
  const onDragEnter = useCallback((e) => {
    e.preventDefault();
    if (e.dataTransfer.items.length && e.dataTransfer.items[0].kind === 'file') {
      setIsDropAreaVisible(true);
    }
    depthLevelRef.current += 1;
  }, []);
  const onDragLeave = useCallback((e) => {
    depthLevelRef.current -= 1;
    e.preventDefault();
    e.stopPropagation();
    if (!depthLevelRef.current) {
      setIsDropAreaVisible(false);
    }
  }, []);

  const onDrop = useCallback(
    (e) => {
      depthLevelRef.current = 0;
      e.preventDefault();
      setIsDropAreaVisible(false);

      const viewport = viewportController.getCoordinates();
      const viewportRect = viewportController.getViewportRect();

      if (e.dataTransfer.files.length) {
        const [file] = e.dataTransfer.files;
        if (file.type.match(/image.*/)) {
          boardController.uploadImage(
            file,
            [
              viewport.x + (e.clientX - viewportRect.x) / viewport.scale,
              viewport.y + (e.clientY - viewportRect.y) / viewport.scale,
            ],
            ADD_ELEMENT_SOURCE_TYPES.DRAGGED_FROM_COMPUTER
          );
        }
      } else {
        const dataString = e.dataTransfer.getData('application/json');
        if (!dataString) return;

        const data = JSON.parse(dataString);
        const center = [
          viewport.x + (e.clientX - data.dragX - viewportRect.x) / viewport.scale,
          viewport.y + (e.clientY - data.dragY - viewportRect.y) / viewport.scale,
        ];
        const size = [data.width / viewport.scale, data.height / viewport.scale];
        if (data.type === 'image') {
          boardController.addElement(
            {
              class: elementClasses.IMAGE,
              imageURL: data.url,
              mp4: data.mp4 || null,
              webp: data.web || null,
              center,
              size,
              useImageProxy: data.useImageProxy || data.gifType === 'text',
              imageType: data.imageType,
            },
            {
              moveViewportToElement: false,
              analyticsParams: { source: data.source || ADD_ELEMENT_SOURCE_TYPES.SEARCH },
            }
          );
        } else if (data.elementToAdd) {
          boardController.addElement(
            {
              center,
              size,
              ...data.elementToAdd,
            },
            { moveViewportToElement: false }
          );
        }
      }
    },
    [boardController, viewportController]
  );

  const listeners = { onDragEnter, onDragLeave, onDrop };
  const preventDefaultCallback = useCallback((e) => e.preventDefault(), []);
  ['onDragStart', 'onDragEnd', 'onDragOver', 'onDrag'].forEach((eventName) => {
    listeners[eventName] = preventDefaultCallback;
  });

  return { listeners, isDropAreaVisible };
};
