import UIkit from 'uikit';

import bus, { boardChanged, capacityInfoUpdated } from '../../event-bus';
import { offSnapshot, onSnapshot } from '../../firestore-watcher';

import template from './users-count.html';
import './users-count.less';

export default class UsersCount extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    this.onCapacityInfoUpdated = this.onCapacityInfoUpdated.bind(this);
    bus.on(capacityInfoUpdated, this.onCapacityInfoUpdated);

    this.onBoardChanged = this.onBoardChanged.bind(this);
    bus.on(boardChanged, this.onBoardChanged.bind(this));

    this.onBoardUpdated = this.onBoardUpdated.bind(this);

    this.container = this.querySelector('.users-count-container');
    this.count = this.querySelector('.users-count');

    this.currentBoardId = window.currentBoardId;
    this.addListeners();
  }

  onBoardChanged(boardId) {
    this.removeListeners();
    this.currentBoardId = boardId;
    this.addListeners();
  }

  addListeners() {
    onSnapshot(`boards/${this.currentBoardId}`, this.onBoardUpdated);
  }

  removeListeners() {
    offSnapshot(`boards/${this.currentBoardId}`, this.onBoardUpdated);
  }

  onBoardUpdated(board) {
    if (!board) return;

    if (board.isViewersModeOn) {
      this.enableTooltip();
    } else {
      this.disableTooltip();
    }

    this.isViewersModeOn = board.isViewersModeOn;
  }

  onCapacityInfoUpdated(capacityInfo) {
    this.capacityInfo = capacityInfo;
    if (this.isViewersModeOn) {
      this.enableTooltip();
    }

    this.count.innerHTML = capacityInfo.totalCount;
    const method = capacityInfo.isCapacityReached ? 'add' : 'remove';
    this.count.classList[method]('capacity-reached');
  }

  enableTooltip() {
    UIkit.tooltip(this.container, {
      title: `
        <img src="images/icons/eye.svg" alt="Eye" /> ${this.capacityInfo.viewersCount} &nbsp; <img src="images/icons/bold-user.svg" alt="User" /> ${this.capacityInfo.membersCount}
      `,
      cls: 'uk-active users-count-tooltip',
      pos: 'bottom-right',
    });
  }

  disableTooltip() {
    UIkit.tooltip(this.container).$destroy();
  }

  disconnectedCallback() {
    bus.off(boardChanged);
    bus.off(capacityInfoUpdated, this.onCapacityInfoUpdated);
  }
}
window.customElements.define('here-users-count', UsersCount);
