import { useState, useEffect } from 'react';

import { offSnapshot, onSnapshot } from '../../firestore-watcher';

export function useUserProfileData(userId) {
  const [userProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function onUserProfileUpdate(data) {
      setUserProfile(data);
      setLoading(false);
    }

    if (userId) {
      onSnapshot(`userProfiles/${userId}`, onUserProfileUpdate);
    }

    return () => {
      if (userId) {
        offSnapshot(`userProfiles/${userId}`, onUserProfileUpdate);
      }
      setLoading(true);
    };
  }, [userId]);

  return { userProfile, loading };
}
