export const chatFontSizeOptions = [`12px`, `14px`, `16px`, `18px`, `24px`, `28px`, `30px`, `32px`];

export const chatFontFamilies = [
  'Allison',
  'Anton',
  'Bangers',
  'Barlow Condensed',
  'Black Ops One',
  'Boogaloo',
  'Bungee Shade',
  'Cabin Sketch',
  'Codystar',
  'Creepster',
  'Dancing Script',
  'Eater',
  'Emilys Candy',
  'Finger Paint',
  'Fredericka the Great',
  'Frijole',
  'Grenze Gotisch',
  'Hanalei',
  'Indie Flower',
  'Inter',
  'Italianno',
  'Kaushan Script',
  'Kranky',
  'Lato',
  'Lobster',
  'Love Ya Like A Sister',
  'Luckiest Guy',
  'Merienda',
  'Merriweather',
  'Monoton',
  'Open Sans Condensed',
  'Open Sans',
  'Orbitron',
  'Permanent Marker',
  'Rampart One',
  'Roboto',
  'Rye',
  'Source Code Pro',
  'Space Grotesk',
  'Special Elite',
  'Teko',
  'Tourney',
  'UnifrakturMaguntia',
  'Urbanist',
  'Wallpoet',
  'Yesteryear',
];
