export const calculateAbsoluteTopValue = (scale) => {
  const scaleRanges = [0.4, 0.5, 0.75, 1, 1.25, 1.5, 2, 4];
  const topValues = ['-2.5rem', '-3rem', '-3.5rem', '-4rem', '-4.5rem', '-5rem', '-6rem', '-7rem', '-9rem'];

  for (let i = 0; i < scaleRanges.length; i += 1) {
    if (scale < scaleRanges[i]) {
      return topValues[i];
    }
  }

  return '-9rem';
};
