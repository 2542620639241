import React from 'react';
import PropTypes from 'prop-types';

const PencilIcon = ({ size, color, strokeWidth }) => (
  <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.57804 16.2561L16.8151 5.01904C17.0337 4.80043 17.2071 4.54089 17.3254 4.25525C17.4437 3.96962 17.5046 3.66347 17.5046 3.3543C17.5046 3.04513 17.4437 2.73899 17.3254 2.45335C17.2071 2.16771 17.0337 1.90818 16.8151 1.68956C16.5964 1.47094 16.3369 1.29753 16.0513 1.17921C15.7656 1.0609 15.4595 1 15.1503 1C14.8411 1 14.535 1.0609 14.2494 1.17921C13.9637 1.29753 13.7042 1.47094 13.4856 1.68956L2.24856 12.9266M5.57804 16.2561L1 17.5046L2.24856 12.9266M5.57804 16.2561L2.24856 12.9266" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PencilIcon;

PencilIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  strokeWidth: PropTypes.string,
};

PencilIcon.defaultProps = {
  size: '19',
  color: 'white',
  strokeWidth: '2',
};
