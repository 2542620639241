import { GroupSettingsTabs, WebLobbyTabs } from '../../../definitions/web-lobby';

export interface WebLobbyState {
  activeTab: WebLobbyTabs;
  prevActiveTab: WebLobbyTabs | null;
  activeModal: string;
  visibleRoomIds: { [key: string]: boolean };
  allTabs: WebLobbyTabs[];

  // groups zone :skull_crossbones:
  shouldShowRooms: boolean;
  activeGroupId: string | null;
  groupSettings: {
    currentTab: string | GroupSettingsTabs;
  };
}

export const initialState: WebLobbyState = {
  activeTab: WebLobbyTabs.Rooms,
  prevActiveTab: null,
  activeModal: '',
  visibleRoomIds: {},
  allTabs: [WebLobbyTabs.Rooms, WebLobbyTabs.DMs, WebLobbyTabs.Friends, WebLobbyTabs.UserPage],

  // groups zone :skull_crossbones:
  shouldShowRooms: false,
  activeGroupId: null,
  groupSettings: {
    currentTab: null,
  },
};
