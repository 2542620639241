import { trackUserActivationFor } from './user-activation';
import log from '../log';

// utils
import { nop } from './js-util';
import { isSafari } from './browser-util';
import { getSoundEffectsEnabled } from './profile-util';

export const soundTypes = {
  DROP: 'audio/fx/drop.ogg',
  JOIN: 'audio/fx/room-join.ogg',
  LEAVE: 'audio/fx/room-leave.ogg',
  MESSAGE: 'audio/fx/message.ogg',
  WAITLIST: 'audio/fx/waitlist.ogg',
  WARP: 'audio/fx/warp.ogg',
  TRASH: 'audio/fx/trash.wav',
  PHOTOCOUNTDOWN: 'audio/fx/photo-countdown.ogg',
  PHOTOCAPTURE: 'audio/fx/photo-capture.ogg',
  ALARM: 'audio/fx/alarm.ogg',
  POP: 'audio/fx/pop.wav',
  DIRECTMESSAGE: 'audio/fx/direct-message.ogg',
  INVITECALL: 'audio/fx/invite-direct-message.ogg',
};

/**
 *
 * @param {string} sound
 * @param {bool} loop - repeat the sound
 * @returns {Promise<Function>} - a function to stop the sound
 */
export async function playSoundEffect(sound, loop = false) {
  if (!getSoundEffectsEnabled() || isSafari()) {
    return nop;
  }

  const audio = new Audio(sound);
  audio.volume = 0.15;
  audio.loop = loop;
  try {
    // Right now, this outputDevice only exists in rooms (not lobby)
    const outputDevice = document.getElementById('audio-output');
    if (audio.setSinkId && outputDevice) {
      await audio.setSinkId(outputDevice.value);
    }

    trackUserActivationFor('playSoundEffect');
    audio.play();
  } catch (ex) {
    log.warn(ex);
  }

  return () => audio.pause();
}
