import { isEqual } from 'lodash';
import { TrackProvider } from '../sfu/track-provider';

class DefaultAudioTrackProvider implements TrackProvider {
  type = 'defaultAudio';

  currentStream?: MediaStream = null;

  currentMediaConfig?: object = null;

  async getTrack(audioMediaConfig: object) {
    if (
      !this.currentStream?.active ||
      this.currentStream?.getAudioTracks()?.length === 0 ||
      !isEqual(audioMediaConfig, this.currentMediaConfig)
    ) {
      if (this.currentStream) {
        this.currentStream.getTracks().forEach((t) => t.stop());
      }
      this.currentStream = await navigator.mediaDevices.getUserMedia({ audio: audioMediaConfig || true });
      this.currentMediaConfig = audioMediaConfig;
    }
    return this.currentStream.getAudioTracks()[0];
  }

  async suspend() {}
}

export default new DefaultAudioTrackProvider();
