import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Tooltip from '@radix-ui/react-tooltip';

// components
import UserAvatar from '../../../../components/UserAvatar';

// utils
import { clamp } from '../../../../../util';

// constants
import { VISIBLE_MEMBERS_COUNT } from '../../../../../constants/board-constants';

const RoomItemMembersList = ({ members, visibleMembersCount }) => {
  const { visibleMembers, hiddenMembersCount } = useMemo(
    () => ({
      visibleMembers: members.slice(0, visibleMembersCount),
      hiddenMembersCount: clamp(members.length - visibleMembersCount, 0, members.length),
    }),
    [members, visibleMembersCount]
  );

  return (
    <Tooltip.Provider delayDuration={50}>
      <MembersList>
        {visibleMembers.map((member) => (
          <Tooltip.Root key={member.id}>
            <Tooltip.Trigger asChild>
              <MembersListItem>
                <UserAvatar userId={member.id} />
              </MembersListItem>
            </Tooltip.Trigger>

            <MemberTooltipStyledContent side="bottom">
              <MemberTooltipText>{member.name}</MemberTooltipText>
            </MemberTooltipStyledContent>
          </Tooltip.Root>
        ))}

        {hiddenMembersCount ? <HiddenRoomMembersCount>+{hiddenMembersCount}</HiddenRoomMembersCount> : null}
      </MembersList>
    </Tooltip.Provider>
  );
};

RoomItemMembersList.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ).isRequired,
  visibleMembersCount: PropTypes.number,
};

RoomItemMembersList.defaultProps = {
  visibleMembersCount: VISIBLE_MEMBERS_COUNT,
};

const MembersList = styled.ul`
  display: flex;
  gap: 7px;
  list-style: none;
`;

const membersListItem = 47;
export const MembersListItem = styled.li`
  height: ${membersListItem}px;
  width: ${membersListItem}px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 6px black;
  border-radius: 100%;
`;

const HiddenRoomMembersCount = styled(MembersListItem)`
  margin-left: 2px;
  color: white;
  background-color: inherit;
  border: 1px solid white;
  border-radius: 100%;
`;

const MemberTooltipStyledContent = styled(Tooltip.Content)`
  margin-top: 10px;
  padding: 10px 16px;
  max-width: 210px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  background-color: #12002d;
`;

const MemberTooltipText = styled.p`
  overflow: hidden;
  color: white;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default RoomItemMembersList;
