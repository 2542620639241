import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

// components
import { fadeOut } from '../../components/animate/keyframes';

// constants
import { DELAY_BEFORE_FADEOUT_MS } from '../../../constants/chat-constants';

interface MessagePreviewProps {
  messageNode: React.ReactNode;
  delayBeforeFadeoutMs?: number;
  imageNode?: React.ReactNode;
  titleNode?: React.ReactNode;
  onFadeOut?: () => void;
}

const MessagePreview = ({
  messageNode,
  delayBeforeFadeoutMs = DELAY_BEFORE_FADEOUT_MS,
  imageNode = null,
  titleNode = null,
  onFadeOut = () => {},
}: MessagePreviewProps) => {
  const [isFadingOut, setIsFadingOut] = useState(false);

  const startFadingOut = useCallback(() => {
    setIsFadingOut(true);
    onFadeOut();
  }, [onFadeOut]);

  useEffect(() => {
    const timerId = setTimeout(startFadingOut, delayBeforeFadeoutMs);
    return () => {
      clearTimeout(timerId);
    };
  }, [startFadingOut, delayBeforeFadeoutMs]);

  return (
    <Container isFadingOut={isFadingOut}>
      {imageNode}
      <div>
        {titleNode}
        {messageNode}
      </div>
    </Container>
  );
};

const Container = styled.div<{ isFadingOut: boolean }>`
  margin-bottom: 20px;
  display: flex;
  align-items: end;
  gap: 9px;
  animation: ${fadeOut} 0.5s ease-in-out;
  animation-play-state: ${({ isFadingOut }) => (isFadingOut ? 'running' : 'paused')};
`;

export default MessagePreview;
