import { eventChannel } from 'redux-saga';
import { select, call, all, takeEvery, put, takeLatest, join } from 'redux-saga/effects';
import omit from 'lodash/fp/omit';
import isEqual from 'lodash/fp/isEqual';

import { api, db } from '../../../firebase';
import bus, { preBoardChanged } from '../../../event-bus';
import { onSnapshot, offSnapshot } from '../../../firestore-watcher';

import * as store from './store';
import * as selectors from './selectors';
import { handleThemeChanged, handleThemeSelected } from './theming';
import { track } from '../../../util/analytics-util';
import { ROOM_DATA_IGNORED_FIELDS } from './constants';
import { setRoomSounds } from './store';
import { getResultChatSoundsList } from '../../../util/room-util';
import { setChatSoundEnabled } from '../../../api/boards-api';
import log from '../../../log';
import { TOGGLE_CHAT_SOUND } from '../../../constants/analytics-events/chat-sound-events.ts';
import { getDefaultChatSounds } from '../../../api/chat-sounds-api.ts';
import { selectCurrentUserId } from '../users/selectors';
import { ROOM_BOARD_TYPE } from '../../../constants/board-constants';
import { getThemes } from '../../../themes-data.ts';
import { currentUserSwitched } from '../users/store';

const omitRoomDataFields = omit(ROOM_DATA_IGNORED_FIELDS);

export function createRoomRequest(data) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accepts', 'application/json');

  return fetch(`${api}/room/create`, {
    headers,
    method: 'POST',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

function* watchRoomSwitched() {
  const channel = eventChannel((emitter) => {
    const listener = (roomId) => emitter(roomId);

    bus.on(preBoardChanged, listener);
    return () => bus.off(preBoardChanged, listener);
  });

  yield takeLatest(channel, function* onRoomSwitched(roomId) {
    yield put(store.roomSwitched({ roomId }));
  });
}

function* handleRoomSwitched({ payload: { roomId } }) {
  const channel = eventChannel((emitter) => {
    const listener = (room) => room && emitter(room);

    onSnapshot(`/boards/${roomId}`, listener);
    return () => offSnapshot(`/boards/${roomId}`, listener);
  });

  try {
    const task = yield takeEvery(channel, function* onRoomUpdated(room) {
      const previousRoomData = yield select(selectors.selectRoomData);
      const currentRoomData = omitRoomDataFields(room);
      if (!isEqual(previousRoomData, currentRoomData)) {
        yield put(store.roomDataUpdated({ data: currentRoomData }));
      }

      const previousTheme = yield select(selectors.selectCurrentTheme);
      if (!isEqual(previousTheme, room.theme)) {
        yield put(store.roomThemeUpdated({ theme: room.theme }));
      }

      if (room.type && room.type !== ROOM_BOARD_TYPE) {
        return;
      }

      if (!previousRoomData) {
        // Just entered the room (or camera preview modal)
        const themeName = yield select(selectors.selectSelectedThemeName);
        yield call(track, 'Pre-Enter Room', { themeName });
      }

      const currentUser = yield select(selectCurrentUserId);
      if (currentUser) {
        let defaultSounds = [];
        const defaultSoundsResp = yield call(getDefaultChatSounds);
        if (defaultSoundsResp.success) {
          defaultSounds = defaultSoundsResp.defaultChatSounds;
        }

        // TODO: this will re-run every time the room data changes, which is not ideal
        // prob there is a way to do it only on chatSounds change
        const { defaultChatSoundsCustomizations = {}, customChatSounds = {} } = room;
        const mergingResult = getResultChatSoundsList(defaultSounds, defaultChatSoundsCustomizations, customChatSounds);

        yield put(setRoomSounds({ sounds: mergingResult }));
      }
    });

    yield join(task);
  } finally {
    channel.close();
  }
}

function* handleRoomDataUpdateRequested({ payload: { patch, disableMerge } }) {
  const roomId = yield select(selectors.selectRoomId);
  try {
    yield call([db.doc(`boards/${roomId}`), disableMerge ? 'update' : 'set'], patch, { merge: !disableMerge });
  } catch (error) {
    log.error('Error updating room data', error);
  }
}

function* handleToggleDisableSound({ payload: { soundId, currentState } }) {
  const roomId = yield select(selectors.selectRoomId);
  const currentSounds = yield select(selectors.selectRoomChatSounds);

  const soundToUpdate = currentSounds.find((sound) => sound.id === soundId);
  if (!soundToUpdate) {
    log.error('Could not find sound to update', { soundId, currentState });
    return;
  }

  const newSounds = currentSounds.map((sound) =>
    sound.id === soundId ? { ...sound, isEnabled: !currentState } : sound
  );

  // optimistic update
  yield put(setRoomSounds({ sounds: newSounds }));

  // make an API call to update the backend

  try {
    yield call(setChatSoundEnabled, roomId, soundToUpdate.keyInCollection, {
      isEnabled: !currentState,
      isDefault: soundToUpdate.isDefault,
    });

    yield call(track, TOGGLE_CHAT_SOUND, {
      isEnabled: !currentState,
      isDefault: soundToUpdate.isDefault,
    });
  } catch (error) {
    log.error('Error updating sound state', error);

    // Revert optimistic update if backend update fails
    yield put(setRoomSounds({ sounds: currentSounds }));
  }
}

function* fetchThemes() {
  try {
    const fetchedThemes = yield call(getThemes);
    yield put(store.setThemes({ themes: fetchedThemes }));
  } catch (error) {
    log.error('Error fetching themes', error);
  }
}

export default function* roomSaga() {
  yield all([
    takeLatest(store.roomSwitched, handleRoomSwitched),
    takeEvery(store.roomThemeUpdated, handleThemeChanged),
    takeEvery(store.roomDataUpdateRequested, handleRoomDataUpdateRequested),
    takeEvery(store.themeSelected, handleThemeSelected),
    takeEvery(store.toggleDisableSound, handleToggleDisableSound),
    takeEvery(currentUserSwitched, fetchThemes),
    watchRoomSwitched(),
  ]);
}
