import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import UserAvatar from '../components/UserAvatar';
import { useUserProfileData } from '../hooks/useUserProfileData';

const AvatarsSmash = ({ data: { firstUserId, secondUserId, duration }, onComplete }) => {
  const { userProfile: leftProfile } = useUserProfileData(firstUserId);
  const { userProfile: rightProfile } = useUserProfileData(secondUserId);

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (leftProfile.username && rightProfile.username && !isLoaded) {
      setIsLoaded(true);
      setTimeout(onComplete, duration);
    }
  }, [leftProfile, rightProfile, isLoaded, duration, onComplete]);

  return isLoaded ? (
    <Container>
      <LeftContainer durationMs={duration}>
        <UserAvatar userProfile={leftProfile} />
      </LeftContainer>
      <RightContainer durationMs={duration}>
        <UserAvatar userProfile={rightProfile} />
      </RightContainer>
    </Container>
  ) : null;
};

AvatarsSmash.propTypes = {
  data: PropTypes.shape({
    firstUserId: PropTypes.string.isRequired,
    secondUserId: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
  onComplete: PropTypes.func,
};

AvatarsSmash.defaultProps = {
  onComplete: () => {},
};

export default AvatarsSmash;

const avatarSize = '25vh';
const Container = styled.div`
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100vw;
  height: ${avatarSize};
  display: flex;
  justify-content: center;
`;

const Subcontainer = styled.div`
  display: inline-block;
  width: ${avatarSize};
  height: ${avatarSize};
  font-size: calc(${avatarSize} / 2);
`;

const afterBounceMargin = 30;
const animation = (sign) => keyframes`
  0% {
    transform: translateX(${sign}50vw);
    opacity: 1;
  }
  20% {
    transform: translateX(0);
    opacity: 1;
  }
  30% {
    transform: translateX(${sign}${afterBounceMargin}px);
    opacity: 1;
  }
  60% {
    transform: translateX(${sign}${afterBounceMargin}px);
    opacity: 1;
  }
  90% {
    transform: translateX(${sign}${afterBounceMargin}px);
    opacity: 0;
  }
  100% {
    transform: translateX(${sign}${afterBounceMargin}px);
    opacity: 0;
  }
`;
const LeftContainer = styled(Subcontainer)`
  animation: ${animation('-')} ${(props) => props.durationMs}ms linear;
`;
const RightContainer = styled(Subcontainer)`
  animation: ${animation('+')} ${(props) => props.durationMs}ms linear;
`;
