/* eslint-disable @typescript-eslint/lines-between-class-members */

import { StreamType, SubscribeStatus, UserId } from './definitions/index.definitions';
import { FeedId, MidId, PublisherFeed, StreamEventParams } from './janus/janus.definitions';
import { Feed, FeedDelegateProtocol } from './feed';
import { FeedTrack } from './feed-track';
import log from '../log';
import { shouldExclude } from './util';
import { trackInForeground as trackMetric } from '../util/analytics-util';

export type FeedCollectionDelegateProtocol = {
  streamTrackRemoved: (track: MediaStreamTrack, stream: MediaStream, userId: string) => void;
} & Omit<FeedDelegateProtocol, 'getFeedTracksByFeedId'>;

export class FeedCollection {
  feeds: Feed[] = [];
  feedTracks: FeedTrack[] = [];
  feedIdDisplayIdDictionary: { [key: FeedId]: UserId } = {};
  private delegate: FeedCollectionDelegateProtocol;
  private excludeIds: string[];

  constructor(delegate: FeedCollectionDelegateProtocol, excludeIds: string[]) {
    this.delegate = delegate;
    this.excludeIds = excludeIds;

    this.feeds.push = (feed: Feed) => {
      this.feedIdDisplayIdDictionary[feed.id] = feed.display;
      return Array.prototype.push.call(this.feeds, feed);
    };

    this.feedTracks.push = (feedTrack: FeedTrack) => {
      const existentFT = this.feedTracks.find(
        (ft) => ft.feedId === feedTrack.feedId && ft.feedMidId === feedTrack.feedMidId
      );

      if (existentFT) {
        if (existentFT.status !== SubscribeStatus.Off && feedTrack.disabled) {
          existentFT.status = SubscribeStatus.Idle;
        }
        existentFT.uniqueMidId = feedTrack.uniqueMidId || existentFT.uniqueMidId;
        existentFT.disabled = feedTrack.disabled;
        existentFT.codec = feedTrack.codec;
        return this.feedTracks.length;
      }

      return Array.prototype.push.call(this.feedTracks, feedTrack);
    };
  }

  get activeStreams() {
    const streams: { [key: UserId]: MediaStream } = {};
    this.feeds.forEach((f) => {
      streams[f.display] = f.stream;
    });
    return streams;
  }

  getFeedById(id: string): Feed | undefined | null {
    return this.feeds.find((f) => f.id === id);
  }

  getFeedByUserId(userId: UserId): Feed | undefined | null {
    return this.feeds.find((f) => f.display === userId);
  }

  getFeedbyUniqueMid(mid: MidId): Feed {
    return this.feeds.find((f) => f.feedTracks.find((s) => s.uniqueMidId === mid));
  }

  getFeedTrackbyUniqueMid(mid: MidId): FeedTrack {
    return this.feedTracks.find((ft) => ft.uniqueMidId === mid);
  }

  getFeedsByIds(ids: string[]) {
    return this.feeds.filter((f) => ids.includes(f.id));
  }

  getFeedTrackByFeedIdAndFeedMidId(type: StreamType, feedId: string, feedMidId: MidId): FeedTrack {
    return this.getFeedById(feedId)?.feedTracks.find((s) => s.type === type && s.feedMidId === feedMidId);
  }

  getVideoFeedTrackForUserId(userId: UserId): FeedTrack {
    return this.feeds.find((f) => f.userId === userId)?.feedTracks.find((ft) => ft.type === StreamType.Video);
  }

  getFeedTracksByFeedId(feedId: FeedId): FeedTrack[] {
    return this.feedTracks.filter((ft) => ft.feedId === feedId);
  }

  updateWithPublisherFeedParams(params: PublisherFeed) {
    if (shouldExclude(params, this.excludeIds)) return;
    const { id, streams } = params;
    const existentFeed = this.getFeedById(id);
    const pub = new Feed({ getFeedTracksByFeedId: this.getFeedTracksByFeedId.bind(this) }, params);
    if (existentFeed) {
      existentFeed.audio_codec = pub.audio_codec;
      existentFeed.video_codec = pub.video_codec;
    } else {
      this.feeds.push(pub);
    }

    (streams || []).forEach((streamParams) => {
      const existentFeedTrack = this.getFeedTrackByFeedIdAndFeedMidId(streamParams.type, params.id, streamParams.mid);
      if (existentFeedTrack) {
        existentFeedTrack.disabled = streamParams.disabled;
        existentFeedTrack.codec = streamParams.codec;
        existentFeedTrack.simulcast = streamParams.simulcast;
        existentFeedTrack.svc = streamParams.svc;
      } else {
        const feedTrack = new FeedTrack(streamParams.type, params.id, streamParams.mid, streamParams);
        this.feedTracks.push(feedTrack);
      }
    });
  }

  updateWithStreamParams(params: StreamEventParams): Feed {
    log.debug(
      `FeedCollection: Updating with StreamEventParams: ${params.type} ${params.active ? 'active' : 'inactive'} mid: ${
        params.mid
      } feed_mid: ${params.feed_mid} feed: ${params.feed_id}`
    );

    if (!params.active && params.feed_id && params.feed_mid && (!params.mid || params.mid === '')) {
      trackMetric('SFU Track Became Inactive Without MID');
      const feedTrack = this.getFeedTrackByFeedIdAndFeedMidId(params.type, params.feed_id, params.feed_mid);
      log.debug(
        `FeedCollection: Cleaning inactive ${feedTrack?.type} track mid: ${params.mid} feed: ${feedTrack?.feedId} mid: ${feedTrack?.feedMidId}`
      );
      if (feedTrack) {
        if (feedTrack.status !== SubscribeStatus.Off) feedTrack.status = SubscribeStatus.Idle;

        const feed = this.getFeedById(feedTrack.feedId);
        if (feed && feedTrack.track) {
          const present = feed.stream.getTracks().find((t) => t.id === feedTrack.track.id);
          trackMetric('SFU Track Became Inactive', {
            includeActiveProp: Object.keys(params).includes('active'),
            presentInStream: !!present,
            enabled: feedTrack.track.enabled,
            muted: feedTrack.track.muted,
            readyState: feedTrack.track.readyState,
          });
          this.delegate.streamTrackRemoved(feedTrack.track, feed.stream, feed.userId);
        }

        feedTrack.track = null;
        feedTrack.uniqueMidId = null;
      }
      return;
    }

    if (shouldExclude(params, this.excludeIds)) return;

    let feedTrack: FeedTrack = null;
    let feedIds = [params.feed_id];

    // FIXME: Should we care of this?
    if (params.type === StreamType.Data) {
      if (params.source_ids) feedIds = params.source_ids;
      else feedIds = this.feedTracks.filter((ft) => ft.type === StreamType.Data).map((ft) => ft.feedId);
      feedTrack = this.feedTracks.find((ft) => ft.type === StreamType.Data);
    }

    feedIds.forEach((feedId: FeedId) => {
      const feed = this.getFeedById(feedId);

      if (feed) {
        feed.status = SubscribeStatus.Subscribed;
        feed.display = params.feed_display || feed.display;
      }

      if (!feedTrack) feedTrack = this.getFeedTrackByFeedIdAndFeedMidId(params.type, params.feed_id, params.feed_mid);
      if (!feedTrack) feedTrack = this.getFeedTrackbyUniqueMid(params.mid);

      if (feedTrack) {
        if (params.mid) {
          this.feedTracks
            .filter((ft) => ft.uniqueMidId === params.mid)
            .forEach((ft) => {
              ft.uniqueMidId = null;
            });
        }
        feedTrack.active = params.active;
        feedTrack.send = params.send;
        feedTrack.ready = params.ready;
        if (params.type !== StreamType.Data) {
          feedTrack.feedId = params.feed_id || feedTrack.feedId;
          feedTrack.feedMidId = params.feed_mid || feedTrack.feedMidId;
          feedTrack.uniqueMidId = params.mid || feedTrack.uniqueMidId;
        }
      } else if (params.active) {
        log.warn(
          `FeedCollection: updateWithStreamParams FeedTrack is missing! creating one with stream params: ${JSON.stringify(
            params
          )}`
        );
        const ft = new FeedTrack(params.type, params.feed_id, params.feed_mid);
        ft.active = params.active;
        ft.codec = params.codec;
        ft.ready = params.ready;
        ft.send = params.send;
        ft.uniqueMidId = params.mid;
        this.feedTracks.push(ft);
      }
    });
  }

  deleteFeedId(id: string): void {
    this.deleteFeedTracksByFeedId(id);
    const index = this.feeds.findIndex((f) => f.id === id);
    if (index >= 0) {
      this.feeds.splice(index, 1);
    }
  }

  private deleteFeedTracksByFeedId(feedId: FeedId) {
    let found = true;
    while (found) {
      const feedTrackIndex = this.feedTracks.findIndex((ft) => ft.feedId === feedId);
      if (feedTrackIndex >= 0) {
        const feedTrack = this.feedTracks[feedTrackIndex];
        log.debug(
          `FeedCollection: Deleting FeedTrack ${feedTrack.type} mid: ${feedTrack.uniqueMidId} feed_mid: ${feedTrack.feedMidId} feed: ${feedTrack.feedId}`
        );
        this.feedTracks.splice(feedTrackIndex, 1);
      } else {
        found = false;
      }
    }
  }

  deleteFeedTrackByFeedMid(feedId: FeedId, midId: MidId) {
    const feedTrackIndex = this.feedTracks.findIndex((ft) => ft.feedId === feedId && ft.feedMidId === midId);
    if (feedTrackIndex >= 0) {
      const feedTrack = this.feedTracks[feedTrackIndex];
      log.debug(
        `FeedCollection: Deleting FeedTrack ${feedTrack.type} unique mid: ${feedTrack.uniqueMidId} feed_mid: ${feedTrack.feedMidId} feed: ${feedTrack.feedId}`
      );
      const feed = this.getFeedById(feedId);
      if (feed && feedTrack.track) feed.stream.removeTrack(feedTrack.track);
      this.feedTracks.splice(feedTrackIndex, 1);
    }
  }

  teardownFeedTrack(feedId: FeedId, feedMidId: MidId) {
    const feed = this.getFeedById(feedId);
    const feedTrack = feed?.feedTracks.find((ft) => ft.feedMidId === feedMidId);
    if (feed?.stream && feedTrack) {
      const track = feed.stream.getTracks().find((t) => t.id === `janus${feedTrack.uniqueMidId}`);
      if (track) feed.stream.removeTrack(track);
    }
    if (feedTrack) {
      if (feedTrack.status !== SubscribeStatus.Off) feedTrack.status = SubscribeStatus.Idle;
      feedTrack.uniqueMidId = undefined;
    }
    this.deleteFeedTrackByFeedMid(feedId, feedMidId);
  }
}
