import { htmlToElement } from '../../util';
import EmbeddedResource from './embedded-resource';

export default class PdfElement extends EmbeddedResource {
  static getEmbedUrl(url) {
    const arr = url.split('.');
    const isPdf = arr[arr.length - 1] === 'pdf';
    return isPdf ? url : null;
  }

  getUnderlayContent({ url }) {
    const arr = url.split('/');
    const pdfTitle = arr[arr.length - 1];
    this.setTooltip(pdfTitle);
    return htmlToElement(`
      <div class="underlay-content">
        <div class="underlay-background pdf-background"></div>
        <img class="icon" alt="Pdf icon" src="images/icons/pdf.svg" />
        <span class="title">${pdfTitle}</span>
      </div>
    `);
  }
}

PdfElement.elementType = 'PdfElement';
