import { WebsocketEvents, WebsocketGroupEventTypes } from '../constants/websocket-events';
import { GroupInResponse, GroupMember, GroupPermissions } from './groups';
import { ChatMessage, Chat, ChatMember, ChatMemberRoles } from './message';
import { Theme } from './theme';
import { OnlineStatus } from './user';

export const DMType = ['messageSend', 'messageSend:v2', 'messageDelete', 'createChat:v2', 'messageUpdate'] as const;
export type DMMessageType = typeof DMType[number];

export interface SocketDMMessage {
  type: DMMessageType;
  message?: ChatMessage;
  messageId?: string;
  chatId?: string;
  unreadCount?: number;
  lastReadMessageId?: string;
  chat?: Chat;
  members?: ChatMember[];
}

export interface SocketOnlineStatusChange {
  type: 'onlineStatusChange';
  userId?: string;
  onlineStatus?: {
    status: OnlineStatus;
  };
}

export interface SocketTypingInChat {
  type: WebsocketEvents.TYPING_IN_CHAT;
  chatId: string;
  userId: string;
  typingStatus: TypingStatus;
}

export enum TypingStatus {
  ACTIVE = 'a',
  FINISHED = 'f',
}

// Group events:

export interface SocketGroupSettingsChange {
  type: WebsocketGroupEventTypes.GROUP_SETTINGS_CHANGE;
  data: {
    groupId: string;
    name?: string;
    theme?: Theme;
    background?: {
      original: string;
    };
  };
}

export interface SocketNewGroupMembers {
  type: WebsocketGroupEventTypes.NEW_MEMBER_IN_GROUP;
  data: {
    groupId: string;
    newMembers: GroupMember[];
  };
}

export interface SocketRemovedGroupMember {
  type: WebsocketGroupEventTypes.MEMBER_REMOVED_FROM_GROUP;
  data: {
    userId: string;
    groupId: string;
  };
}

export interface SocketNewGroupAdded {
  type: WebsocketGroupEventTypes.NEW_GROUP_ADDED;
  data: GroupInResponse;
}

export interface SocketGroupDeleted {
  type: WebsocketGroupEventTypes.GROUP_DELETED;
  data: {
    groupId: string;
  };
}

export interface SocketMemberRoleChanged {
  type: WebsocketGroupEventTypes.MEMBER_ROLE_CHANGED;
  data: {
    groupId: string;
    userId: string;
    role: ChatMemberRoles;
    permissions: GroupPermissions;
  };
}

export type SocketMessage =
  | SocketDMMessage
  | SocketOnlineStatusChange
  | SocketNewGroupMembers
  | SocketRemovedGroupMember
  | SocketNewGroupAdded
  | SocketGroupSettingsChange
  | SocketGroupDeleted
  | SocketTypingInChat
  | SocketMemberRoleChanged;
