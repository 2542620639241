import { call, put, select } from 'redux-saga/effects';

import { selectAvailableThemes, selectCurrentThemeColors, selectSelectedThemeName } from './selectors';
import { roomDataUpdateRequested } from './store';

import { track } from '../../../util/analytics-util';
import { applyTheme } from '../../../util/theming-util';

export function* handleThemeSelected({ payload: { theme, oldThemeName } }) {
  const currentThemeName = yield select(selectSelectedThemeName);
  if (currentThemeName === theme.name) return;

  let colors = null;
  if (theme.isCustom) {
    const availableThemes = yield select(selectAvailableThemes);
    const defaultTheme = availableThemes.find((t) => t.isDefault);
    colors = currentThemeName === defaultTheme.name ? defaultTheme.colors : yield select(selectCurrentThemeColors);
  } else if (!theme.isDefault) {
    colors = theme.colors;
  }

  const patch = { theme: { colors, isCustom: !!theme.isCustom } };
  yield put(roomDataUpdateRequested({ patch }));
  yield call(track, 'Theme Changed', { name: theme.name, oldTheme: oldThemeName });
}

export function* handleThemeChanged({ payload: { theme } }) {
  yield call(applyTheme, theme?.colors, theme?.primaryFont);
}
