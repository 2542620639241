import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { EmojiData } from 'emoji-mart';
import { useSelector } from 'react-redux';

// components
import RoomChatInputActive from './RoomChatInputActive';
import RoomChatInputInactive from './RoomChatInputInactive';
import { EventSourceContext } from '../../common/contexts';

// hooks
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { selectCurrentTheme } from '../../store/room/selectors';
import { track } from '../../../util/analytics-util';
import { SEND_KAOMOJI } from '../../store/kaomoji/analytics';

type RoomChatInputProps = {
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendTextMessage: (message: string) => Promise<void>;
  sendTyping: () => void;
  onActiveChange?: (isActive: boolean) => void;
};

const RoomChatInput = ({ handleFileUpload, sendTextMessage, sendTyping, onActiveChange }: RoomChatInputProps) => {
  const ref = useRef(null);
  const inputRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState('');

  const theme = useSelector(selectCurrentTheme);

  const source = useContext(EventSourceContext);

  useOnClickOutside(ref, () => {
    if (!message) {
      setIsActive(false);
    }
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('emoji-mart/css/emoji-mart.css');
  }, []);

  useEffect(() => {
    if (onActiveChange) {
      onActiveChange(isActive);
    }
  }, [isActive, onActiveChange]);

  const onInactiveClick = () => {
    setIsActive(true);
  };

  const onMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
    sendTyping();
  };

  const onEmojiSelected = (emoji: EmojiData) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setMessage((prevMessage) => `${prevMessage}${emoji.native}`);
    sendTyping();
    setIsActive(true);
  };

  const onKaomojiSelected = (kaomoji: string, category: string) => {
    setMessage((prevMessage) => `${prevMessage}${kaomoji}`);
    sendTyping();
    setIsActive(true);
    inputRef.current?.focus();
    track(SEND_KAOMOJI, { kaomoji, category, source });
  };

  return (
    <Container ref={ref}>
      {isActive ? (
        <RoomChatInputActive
          message={message}
          theme={theme}
          setMessage={setMessage}
          onMessageChange={onMessageChange}
          sendTextMessage={sendTextMessage}
          onEmojiSelected={onEmojiSelected}
          onKaomojiSelected={onKaomojiSelected}
          handleFileUpload={handleFileUpload}
          inputRef={inputRef}
        />
      ) : (
        <RoomChatInputInactive
          theme={theme}
          onClick={onInactiveClick}
          onKaomojiSelected={onKaomojiSelected}
          onEmojiSelected={onEmojiSelected}
          handleFileUpload={handleFileUpload}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default RoomChatInput;
