export const SEND_DM = 'Send DM'; // params: messageType, source
export const SEND_GROUP_MESSAGE = 'Send Group Message'; // params: messageType, source, groupId
export const CREATE_CHAT = 'Create Chat'; // params: messageType, groupId (optional)
export const OPEN_DM_WINDOW = 'Open DM Window'; // params: source
export const DELETE_DM = 'Delete DM';
export const RECEIVE_CHAT = 'Receive New Chat'; // params: chatId, messageType
export const RECEIVE_DM = 'Receive DM'; // params: chatId, messageType
export const ADD_REACTION = 'Add Reaction'; // params: chatId, reaction, groupId, chatType (CHAT_TYPE)
export const EMBED_MESSAGE_CREATED = 'Embed Message Created'; // params: type, source

// 'source' param values for invite type messages
export const CALL_FROM_DM_WINDOW = 'DM Window';
export const CALL_FROM_INVITE_MODAL = 'Invite Modal';
export const CALL_FROM_RECENT_VISITOR_INVITE_MODAL = 'Recent Visitor Invite Modal';

export const CHAT_TYPE = {
  HUB: 'Hub',
  DM: 'DM',
  ROOM_FEED: 'Room Feed',
  CHAT_WIDGET: 'Chat Widget',
};

export const AUDIO_MESSAGE_PLAYED = 'Audio Message Played';
export const AUDIO_MESSAGE_PAUSED = 'Audio Message Paused';
