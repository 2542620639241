import { GroupPermissions } from '../../definitions/groups';

// Fallback value when permissions are not available for any reason
export const defaultChatPermissions: GroupPermissions = {
  canAddMessages: true,
  canDeleteOwnMessage: true,
  canAddMembers: true,
  canRemoveMembers: false,
  canChangeMemberRole: false,
  canAddRooms: false,
  canRemoveRooms: false,
  canChangeTheme: true,
  canChangeGroupSettings: true,
  canDeleteAnyMessage: false,
  canDeleteGroup: false,
};

// Permissions where everything's allowed
export const superChatPermissions: GroupPermissions = {
  canAddMessages: true,
  canDeleteOwnMessage: true,
  canAddMembers: true,
  canRemoveMembers: true,
  canChangeMemberRole: true,
  canAddRooms: true,
  canRemoveRooms: true,
  canChangeTheme: true,
  canChangeGroupSettings: true,
  canDeleteAnyMessage: true,
  canDeleteGroup: true,
};
