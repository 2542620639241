import { htmlToElement } from '../../util';
import EmbeddedResource from './embedded-resource';

// The regex is taken from here: https://www.figma.com/developers/embed
// and slightly modified to capture figma file name if present.
const regex = /https:\/\/([\w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})\/?([^?]*)?.*?$/i;

export default class FigmaElement extends EmbeddedResource {
  static getEmbedUrl(url) {
    const isFigmaUrl = regex.test(url);
    return isFigmaUrl ? `https://www.figma.com/embed?embed_host=here&url=${url}` : null;
  }

  getUnderlayContent({ url }) {
    // Embeddable title is just 'Figma' for Figma files, so parsing it from the url.
    const titleMatch = url.match(regex);
    const capturedTitle = titleMatch && titleMatch[4];
    const title = capturedTitle || 'Figma';
    const elTitle = title || 'Figma';
    this.setTooltip(elTitle);
    return htmlToElement(`
      <div class="underlay-content">
        <div class="underlay-background figma-background"></div>
        <img class="icon" alt="Figma icon" src="images/icons/figma.svg" />
        <span class="title">${elTitle}</span>
      </div>
    `);
  }
}

FigmaElement.elementType = 'FigmaElement';
