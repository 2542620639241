import { io, Socket } from 'socket.io-client';
import firebase from 'firebase/compat/app';
import { api } from '../firebase';
import log from '../log';

// eslint-disable-next-line import/no-cycle
import store from '../react/store/room-root-store';

import {
  receiveMessage,
  receiveNewChat,
  socketReconnect as messageSocketReconnect,
  deleteMessageSuccess,
  receiveUpdatedMessage,
  removeChat,
} from '../react/store/messaging/reducer';
import { selectRoomId } from '../react/store/room/selectors';
import {
  SocketDMMessage,
  SocketGroupDeleted,
  SocketGroupSettingsChange,
  SocketMemberRoleChanged,
  SocketMessage,
  SocketNewGroupMembers,
  SocketOnlineStatusChange,
  SocketRemovedGroupMember,
  SocketTypingInChat,
} from '../definitions/websocket';
import { checkIsElectron } from '../util/platform-util';
import { emitSocketEvent, startWebSocket } from '../electron-support/electron-support';
import { WebsocketEvents, EventsMap, WebsocketGroupEventTypes } from '../constants/websocket-events';
import { setUserOnlineStatus } from '../react/store/users/store';
import { setNewGroupMembers, socketReconnect as groupSocketReconnect } from '../react/store/groups/actions';
import eventBus, { socketConnected, userTyping } from '../event-bus';
import { selectActiveGroupId } from '../react/store/web-lobby/selectors';
import { RootState } from '../definitions/store';
import { selectChatIdByGroupId } from '../react/store/messaging/selectors';
import {
  receiveGroupSettingsUpdated,
  receiveMemberRoleChanged,
  receiveSelfRoleChanged,
  removeMemberSocketEvent,
} from '../react/store/messaging/actions';
import { isHereEmployeeCheck } from '../util/user-util';
import { selectCurrentUserId } from '../react/store/users/selectors';

// This is what socket.io-client defines as EventsMap for emitting events.
//   It does not publicly export this type, so we have to redefine it here.

interface ElectronSocketMessage {
  event: WebsocketEvents;
  data: SocketDMMessage;
}

const useLocalServer = process.env.LOCAL_SERVER || false;

class WebsocketWrapper {
  socket: Socket;

  socketConnectBound = false;

  hasReceivedElectronNotification = false;

  isInitialConnect = true;

  onSocketConnect() {
    log.debug('socket connected');

    const state = store.getState();
    const boardId = selectRoomId(state);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const groupId = selectActiveGroupId(state);

    if (boardId) {
      this.emit(WebsocketEvents.JOIN_ROOM, { boardId });
    }

    store.dispatch(messageSocketReconnect());

    // Don't need to fetch groups on initial connect
    if (this.isInitialConnect) {
      this.isInitialConnect = false;
      return;
    }

    if (!boardId) {
      // If boardId is not set, then we're in the lobby and we should refetch groups list
      store.dispatch(groupSocketReconnect({ groupId }));
    }
  }

  async socketConnect() {
    const hadSocketBefore = !!this.socket;
    const { currentUser } = firebase.auth();

    if (currentUser) {
      this.socket = io(useLocalServer ? 'ws://localhost:8080' : api.replace('http', 'ws'), {
        transports: ['websocket'],
        auth: async (cb) => {
          const token = await currentUser.getIdToken();
          const isElectron = checkIsElectron();
          let isVisible = false;

          if (!isElectron) {
            isVisible = this.checkIsVisible();
          }

          cb({ token, platform: isElectron ? 'desktop' : 'web', backgrounded: !isVisible });
        },
      });
    }

    if (!hadSocketBefore) {
      document.addEventListener('visibilitychange', this.visibilityChange.bind(this));

      window.addEventListener(
        'focus',
        () => {
          this.visibilityChange(false);
        },
        false
      );

      window.addEventListener(
        'blur',
        () => {
          this.visibilityChange(true);
        },
        false
      );
    }
  }

  async connect() {
    if (!this.socket) {
      if (!checkIsElectron() || (await isHereEmployeeCheck())) {
        this.socketConnect();
        if (!this.socket.connected) {
          this.socket.connect();
        }

        this.socket.on('connect', this.onSocketConnect.bind(this));

        this.socket.on('disconnect', (reason) => {
          log.debug('socket disconnected');

          if (reason === 'io server disconnect') {
            // the disconnection was initiated by the server, you need to reconnect manually
            // this was likely due to an expired auth token.
            this.socket.connect();
          }
        });

        this.socket.onAny(this.processMessage.bind(this));
      } else {
        log.debug('starting electron socket');
        startWebSocket((electronEvent: object, eventObj: ElectronSocketMessage) => {
          this.processMessage(eventObj.event, eventObj.data);
        });
        if (!this.socketConnectBound) {
          this.socketConnectBound = true;
          eventBus.on(socketConnected, this.onSocketConnect.bind(this));
        }
      }
    }
  }

  processMessage(event: WebsocketEvents, messageData: SocketMessage, callback?: (resp: { status: string }) => void) {
    if (event === WebsocketEvents.DM) {
      if (callback) {
        callback({ status: 'ok' });
      }
      this.dmCallback(messageData as SocketDMMessage);
    }

    if (event === WebsocketEvents.ONLINE_STATUS_CHANGE) {
      store.dispatch(setUserOnlineStatus(messageData as SocketOnlineStatusChange));
    }

    if (event === WebsocketEvents.GROUP) {
      this.groupsCallback(messageData);
    }

    if (event === WebsocketEvents.TYPING_IN_CHAT) {
      const { userId, typingStatus, chatId } = messageData as SocketTypingInChat;
      eventBus.dispatch(userTyping, {
        userId,
        typingStatus,
        target: chatId,
      });
    }
  }

  dmCallback(messageData: SocketDMMessage) {
    const { type, chat, message: dmMessage, members, unreadCount, lastReadMessageId } = messageData;

    if (type === 'messageSend:v2') {
      store.dispatch(
        receiveMessage({
          message: dmMessage,
          chat,
          members,
          unreadCount,
          lastReadMessageId,
        })
      );
    }

    if (type === 'createChat:v2') {
      store.dispatch(
        receiveNewChat({
          message: dmMessage,
          chat,
          members,
          unreadCount,
          lastReadMessageId,
        })
      );
    }

    if (type === 'messageDelete') {
      store.dispatch(
        deleteMessageSuccess({
          messageId: messageData.messageId,
          chatId: messageData.chatId,
          unreadCount,
        })
      );
    }

    if (type === 'messageUpdate') {
      store.dispatch(receiveUpdatedMessage(messageData));
    }
  }

  groupsCallback(messageData: SocketMessage) {
    if (messageData.type === WebsocketGroupEventTypes.GROUP_SETTINGS_CHANGE) {
      const groupSettingsChangeData = messageData as SocketGroupSettingsChange;

      store.dispatch(
        receiveGroupSettingsUpdated({
          socketMessage: { groupId: groupSettingsChangeData.data.groupId, ...groupSettingsChangeData.data },
        })
      );
    }

    if (messageData.type === WebsocketGroupEventTypes.NEW_MEMBER_IN_GROUP) {
      const newMemberEventData = messageData as SocketNewGroupMembers;
      store.dispatch(
        setNewGroupMembers({
          groupId: newMemberEventData.data.groupId,
          newMembers: newMemberEventData.data.newMembers,
        })
      );
    }

    if (messageData.type === WebsocketGroupEventTypes.MEMBER_REMOVED_FROM_GROUP) {
      const removedMemberEventData = messageData as SocketRemovedGroupMember;
      const chatId = selectChatIdByGroupId(store.getState() as RootState, removedMemberEventData.data.groupId);
      store.dispatch(
        removeMemberSocketEvent({
          userId: removedMemberEventData.data.userId,
          chatId,
        })
      );
    }

    if (messageData.type === WebsocketGroupEventTypes.GROUP_DELETED) {
      const groupDeletedEventData = messageData as SocketGroupDeleted;
      const chatId = selectChatIdByGroupId(store.getState() as RootState, groupDeletedEventData.data.groupId);

      store.dispatch(removeChat({ chatId }));
    }

    if (messageData.type === WebsocketGroupEventTypes.MEMBER_ROLE_CHANGED) {
      const eventData = messageData as SocketMemberRoleChanged;
      const isSelf = selectCurrentUserId(store.getState() as RootState) === eventData.data.userId;
      const chatId = selectChatIdByGroupId(store.getState() as RootState, eventData.data.groupId);

      const payload = {
        chatId,
        role: eventData.data.role,
      };

      if (isSelf) {
        store.dispatch(
          receiveSelfRoleChanged({
            ...payload,
            permissions: eventData.data.permissions,
          })
        );
      } else {
        store.dispatch(
          receiveMemberRoleChanged({
            ...payload,
            memberId: eventData.data.userId,
          })
        );
      }
    }
  }

  async disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  async visibilityChange(backgrounded: boolean) {
    if (!checkIsElectron() || (await isHereEmployeeCheck())) {
      const isVisible = this.checkIsVisible();

      this.emit(WebsocketEvents.VISIBILITY_CHANGE, { backgrounded: !isVisible });
    } else {
      this.emit(WebsocketEvents.VISIBILITY_CHANGE, { backgrounded });
    }
  }

  checkIsVisible() {
    return document.visibilityState === 'visible' && document.hasFocus();
  }

  emit(eventName: WebsocketEvents, data: EventsMap) {
    if (!checkIsElectron() && this.socket) {
      return this.socket.emit(eventName, data);
    }

    return emitSocketEvent(eventName, data);
  }
}

const websocketClient = new WebsocketWrapper();

(() => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      websocketClient.connect();
    } else {
      websocketClient.disconnect();
    }
  });
})();

export default websocketClient;
