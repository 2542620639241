import axios from 'axios';

import firebase, { db } from '../../../firebase';

import template from './notifications-tab.html';
import './notifications-tab.less';
import { track } from '../../../util/analytics-util';

class Notifications extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    this.currentBoardId = window.currentBoardId;

    this.notificationCheckboxEl = this.querySelector('#notifications-checkbox');
    this.notificationCheckboxEl.onclick = () => {
      const newState = this.notificationCheckboxEl.checked;
      this.setNotificationsEnabled(newState);
    };
  }

  async onBoardUpdate() {
    const membershipSnapshot = await db
      .doc(`boards/${this.currentBoardId}/members/${firebase.auth().currentUser.uid}`)
      .get();

    const membership = membershipSnapshot.data();
    if (membership && membership.push && membership.push.userJoined === false) {
      this.notificationCheckboxEl.checked = false;
    }
  }

  onSwitchingBoards(boardId) {
    this.currentBoardId = boardId;
  }

  setNotificationsEnabled(newState) {
    if (newState) {
      track('Enable Room Notifications');
      axios.post('/api/room/notifications/subscribe', {
        boardId: window.currentBoardId,
        uid: firebase.auth().currentUser.uid,
      });
    } else {
      track('Disable Room Notifications');
      axios.post('/api/room/notifications/unsubscribe', {
        boardId: window.currentBoardId,
        uid: firebase.auth().currentUser.uid,
      });
    }
  }
}

window.customElements.define('here-notifications-tab', Notifications);
