export const USER_SEARCH = 'User Profile Search'; // pass source, searchQuery as param
export const EDIT_PROFILE_PHOTO = 'Edit User Profile Photo';

export const EDIT_MOOD_TEXT = 'Edit Mood Text';
export const EDIT_MOOD_THEME = 'Edit Mood Theme';
export const REMOVE_MOOD = 'Remove Mood';

export const EDIT_PROFILE_EFFECT = 'Edit Profile Effect'; // effectUrl
export const REMOVE_PROFILE_EFFECT = 'Remove Profile Effect';

export const LINK_SCHOOL = 'Link School'; // source, schoolId, isPostSecondary
export const ADD_GRADE = 'Add Grade'; // source, grade
export const SKIP_SCHOOL_LINKING = 'Skip School Linking During Signup';
export const LINK_SCHOOL_PROFILE_CLICK = 'Click Link School on Edit Profile';

export const MOBILE_UPSELL_CTA_CLICKED = 'Mobile Upsell CTA Clicked';
export const MOBILE_UPSELL_CTA_DISMISSED = 'Mobile Upsell CTA Dismissed';

export const LINK_SCHOOL_SOURCES = {
  SIGNUP: 'signup',
  USER_PROFILE: 'user profile',
  PROMPT: 'prompt',
};

export const OPEN_PROFILE = 'Open User Profile';
export const OPEN_PROFILE_SOURCES = {
  LOBBY: 'lobby',
};
