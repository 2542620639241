import { useEffect, useState } from 'react';

import { offUserListChanged, onUserListChanged } from '../../presence';

export function useBoardMembersList({ boardId, active = false }) {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    function onRoomMembersChange(users) {
      const usersArr = Object.keys(users || {}).map((userId) => ({ ...users[userId], id: userId }));
      setMembers(usersArr);
    }

    if (active) {
      onUserListChanged(boardId, onRoomMembersChange);
    } else {
      offUserListChanged(boardId, onRoomMembersChange);
    }

    return () => {
      offUserListChanged(boardId, onRoomMembersChange);
    };
  }, [boardId, active]);

  return { members };
}
