import React from 'react';
import styled from 'styled-components';
import { MAX_NUM_CARDS, USER_CARD_HEIGHT_PX, USER_CARD_WIDTH_PX } from '../../constants/user-card-constants';

import CardsFanIcon from '../../../assets/icons/user-cards/cards-fan.svg';

interface Props {
  scale?: number;
}

const CardsLimitMessage = ({ scale = 1 }: Props) => (
  <MaxCardsAddedContainer scale={scale}>
    <MaxCardsAdded>
      <StyledCardsFanIcon />
      <MaxCardsNumberLabel scale={scale}>{`${MAX_NUM_CARDS}/${MAX_NUM_CARDS}`}</MaxCardsNumberLabel>
      <MaxCardsTitle scale={scale}>Max Cards Added</MaxCardsTitle>
      <MaxCardsSubtitle scale={scale}>Delete existing cards to create more</MaxCardsSubtitle>
    </MaxCardsAdded>
  </MaxCardsAddedContainer>
);

export default CardsLimitMessage;

const MaxCardsAddedContainer = styled.div<{ scale: number }>`
  width: ${({ scale }) => USER_CARD_WIDTH_PX * scale}px;
  height: ${({ scale }) => USER_CARD_HEIGHT_PX * scale}px;
  display: flex;
  align-items: center;
`;

const MaxCardsAdded = styled.div`
  width: 100%;
  height: 70%;
  background: rgba(18, 0, 45, 0.3);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledCardsFanIcon = styled(CardsFanIcon)`
  display: block;
  margin: 15px auto;
`;

const MaxCardsNumberLabel = styled.div<{ scale: number }>`
  color: white;
  opacity: 0.7;
  font-size: ${({ scale }) => Math.max(14 * scale, 10)}px;
  text-align: center;
  margin-bottom: 8px;
`;

const MaxCardsTitle = styled.div<{ scale: number }>`
  font-weight: 800;
  color: white;
  font-size: ${({ scale }) => Math.max(20 * scale, 14)}px;
  text-align: center;
  margin-bottom: 6px;
`;

const MaxCardsSubtitle = styled.div<{ scale: number }>`
  color: white;
  opacity: 0.7;
  font-size: ${({ scale }) => Math.max(14 * scale, 10)}px;
  text-align: center;
  width: 140px;
  margin: 0 auto;
`;
