import { getQueryStringValue } from '../util';

export const MediaTypes = {
  YOUTUBE: 'YouTube',
  TWITCH: 'Twitch',
};

export const PlayerStates = {
  ENDED: 'ENDED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  CUED: 'CUED',
  UNSTARTED: 'UNSTARTED',
  BUFFERING: 'BUFFERING',
};

export function checkIsYouTubeURL(url) {
  const regex1 = /youtu\.be\/([-_A-Za-z0-9]+)/;
  const regex2 = /youtube.com\/watch\?.*v=([-_A-Za-z0-9]+)/;
  const regex3 = /youtube.com\/shorts\/([-_A-Za-z0-9]+)$/;
  return url.match(regex1) || url.match(regex2) || url.match(regex3);
}

export function checkIsTwitchURL(url) {
  const regex1 = /twitch.tv\/([-_A-Za-z0-9]+)/;
  let matches1 = url.match(regex1);
  /*
  // TODO: Add support for Twitch videos-on-demand (VOD)
  const regex2 = /twitch.tv\/videos\/([-_A-Za-z0-9]+)/;
  const matches2 = url.match(regex2);
  return matches2 ? matches2 : matches1;
  */
  if (matches1 !== null && matches1.length === 2 && matches1[1] === 'videos') matches1 = null;
  return matches1;
}

export const getStandardizedYouTubeUrl = (videoId) => `https://www.youtube.com/watch?v=${videoId}`;

export function getVideoDataFromYouTubeURL(url) {
  const videoData = {};
  let videoId = getQueryStringValue('v', url);
  if (!videoId) {
    let regex = /youtu\.be\/([-_A-Za-z0-9]+)/;
    let matches = url.match(regex);
    if (matches && matches.length === 2) {
      [, videoId] = matches;
    } else {
      regex = /youtube.com\/shorts\/([-_A-Za-z0-9]+)/;
      matches = url.match(regex);
      if (matches && matches.length === 2) {
        [, videoId] = matches;
      }
    }
  }
  videoData.id = videoId;
  videoData.isClip = true;
  return videoData;
}

export function getVideoDataFromTwitchURL(url) {
  const videoData = {};
  const regex1 = /twitch.tv\/([-_A-Za-z0-9]+)/;
  const matches1 = url.match(regex1);

  if (matches1 && matches1.length === 2 && matches1[1] !== 'videos') {
    [, videoData.id] = matches1;
    videoData.isClip = false;
  }
  /*
  // TODO: Add support for Twitch videos-on-demand (VOD)
  const regex2 = /twitch.tv\/videos\/([-_A-Za-z0-9]+)/;
  const matches2 = url.match(regex2);
  if (matches2 && matches2.length === 2) {
    [, videoData.id] = matches2;
    videoData.isClip = true;
  }
  */
  return videoData;
}

export function getVideoInfoFromURL(url) {
  if (checkIsYouTubeURL(url)) {
    const { id, isClip } = getVideoDataFromYouTubeURL(url);
    return { id, isClip, mediaType: MediaTypes.YOUTUBE };
  }
  if (checkIsTwitchURL(url)) {
    const { id, isClip } = getVideoDataFromTwitchURL(url);
    return { id, isClip, mediaType: MediaTypes.TWITCH };
  }

  return null;
}
