import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { confirmAlert } from 'react-confirm-alert';
import { parsePhoneNumber, formatPhoneNumber } from 'react-phone-number-input';

import { signout } from '../../util/user-util';
import { checkIsElectron } from '../../util/platform-util';
import { track } from '../../util/analytics-util';

import { HereInput, HereSwitch } from '../components/inputs';

import { currentUserProfileUpdateRequest } from '../store/users/store';
import { selectCurrentUserEmail, selectCurrentUserPhoneNumber } from '../store/users/selectors';
import { ELECTRON_CANDYBAR_LINK } from '../../constants/electron-constants';
import { getSoundEffectsEnabled } from '../../util/profile-util';
import PhoneNumber from '../phone/PhoneNumber.tsx';
import { closeUserProfile } from './utils';
import { ADD_PHONE_NUMBER_CLICKED } from '../../constants/analytics-events/phone-events.ts';
import PhonePortal from '../phone/PhonePortal.tsx';
import ProfileSchoolSettings from './ProfileSchoolSettings.tsx';
import { supportsNewLobby } from '../../electron-support/electron-support';

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector(selectCurrentUserEmail);
  const currentUserPhoneNumber = useSelector(selectCurrentUserPhoneNumber);

  // we only want to show Edit Theme button if user is not on older electron version with the old candybar
  // defaulting to true so there's no lag in showing button for web/latest electron versions
  const [showAddPhoneButton, setShowAddPhoneButton] = useState(true);
  useEffect(() => {
    (async () => {
      if (checkIsElectron()) {
        setShowAddPhoneButton(await supportsNewLobby());
      }
    })();
  }, []);

  const localPhoneNumber = currentUserPhoneNumber.length > 0 ? formatPhoneNumber(currentUserPhoneNumber) : '';
  const parsedPhoneNumber = currentUserPhoneNumber.length > 0 ? parsePhoneNumber(currentUserPhoneNumber) : undefined;
  const countryCode = parsedPhoneNumber ? parsedPhoneNumber.country : 'US';

  const [soundEffectsSwitchOn, setSoundEffectsSwitchOn] = useState(getSoundEffectsEnabled());
  const [showPhoneModal, setShowPhoneModal] = useState(false);

  const onSoundEffectSwitchChange = useCallback(
    (value) => {
      setSoundEffectsSwitchOn(value);
      dispatch(currentUserProfileUpdateRequest({ patch: { soundEnabled: value } }));

      track(`Turn Sound Effects ${value ? 'On' : 'Off'}`);
    },
    [dispatch]
  );

  const onLogoutClick = () => {
    confirmAlert({
      /* eslint-disable react/prop-types */
      customUI: ({ onClose }) => (
        /* eslint-enable react/prop-types */
        <div className="react-confirm-alert-body">
          Are you sure you want to sign out?
          <div className="react-confirm-alert-button-group">
            <button
              type="button"
              data-testid="confirm-logout-button"
              onClick={
                checkIsElectron()
                  ? () => signout({ needLeave: false, newLocationUrl: ELECTRON_CANDYBAR_LINK })
                  : signout
              }
            >
              Logout
            </button>
            <button type="button" onClick={onClose} data-testid="nevermind-logout-button">
              Nevermind
            </button>
          </div>
        </div>
      ),
    });
  };

  const onClickAddPhoneNumber = () => {
    track(ADD_PHONE_NUMBER_CLICKED);
    closeUserProfile();
    setShowPhoneModal(true);
  };

  const preventUserProfileClose = (e) => {
    e.preventDefault();
  };

  const onPortalMount = () => {
    document.getElementById('user-profile').addEventListener('beforehide', preventUserProfileClose);
  };

  const onPortalUnmount = () => {
    document.getElementById('user-profile').removeEventListener('beforehide', preventUserProfileClose);
  };

  const closePhoneModal = () => {
    setShowPhoneModal(false);
  };

  return (
    <ProfileSettingsWrapper>
      <FlexContainer>
        <Content>
          <ProfileSchoolSettings />
          {currentUserEmail && (
            <>
              <EmailLabel>Email</EmailLabel>
              <HereInput controlled smallStyle disabled value={currentUserEmail} />
            </>
          )}
          {currentUserPhoneNumber ? (
            <PhoneNumber isDisabled phoneNumber={localPhoneNumber} countryCode={countryCode} flexBasis="40%" />
          ) : (
            !checkIsElectron() ||
            (showAddPhoneButton && (
              <AddPhoneNumberButton onClick={onClickAddPhoneNumber}>+Add Phone Number</AddPhoneNumberButton>
            ))
          )}
          <SoundEffectsContainer className="unthemed">
            <div>
              <SectionLabel>Sound Effects</SectionLabel>
              <SoundEffectsDesc>Notifications, chat, new member, etc.</SoundEffectsDesc>
            </div>
            <HereSwitch isOn={soundEffectsSwitchOn} onChange={onSoundEffectSwitchChange} />
          </SoundEffectsContainer>
          <LogoutButton onClick={onLogoutClick} data-testid="profile-logout-button">
            Logout
          </LogoutButton>
        </Content>
      </FlexContainer>
      {showPhoneModal && (
        <PhonePortal
          onPortalMount={onPortalMount}
          onPortalUnmount={onPortalUnmount}
          closePhoneModal={closePhoneModal}
        />
      )}
    </ProfileSettingsWrapper>
  );
};

export default ProfileSettings;

export const ProfileSettingsWrapper = styled.div`
  background: #ffffff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 15px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 380px;
  position: relative;
`;

const EmailLabel = styled.div`
  font-size: 12px;
  color: rgba(1, 1, 1, 0.5);
  margin-bottom: 5px;
`;

const AddPhoneNumberButton = styled.button`
  margin-top: 25px;
  font-weight: bold;
  color: #6b1be3;
  background: none;
  border: none;
  cursor: pointer;
`;

const SoundEffectsContainer = styled.div`
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
`;

const SectionLabel = styled.div`
  font-size: 14px;
  color: black;
  font-family: 'Inter';
`;

const SoundEffectsDesc = styled.div`
  font-size: 12px;
  color: #1a1a1a;
  font-family: 'Inter';
  opacity: 0.5;
`;

const LogoutButton = styled.button`
  cursor: pointer;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 12px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  border: none;
  font-family: 'Inter';

  background: #f6335d;
  color: #ffffff;
  width: 100%;

  transition: all 0.4s ease;

  &:hover {
    opacity: 0.7;
  }
`;
