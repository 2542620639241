import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useThrottledCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';

import { updateFeedLastVisibleTime } from '../../../api/members-api';
import { startDroppingReactionAnimation, startScalingInReactionAnimation } from '../../../reactions';
import { handleSystemCommand } from '../../../feed-system-commands';

// components
import FeedPopupMessage from '../messages/FeedPopupMessage';
import TypingIndicator, { TypingIndicatorWrapper } from '../typing-indicator/TypingIndicator';
import { BackToPresentButton } from '../chats-shared-styles';
import Message from '../messages/Message';
import { svgColorMixin } from '../../mixins';
import RoomChatInput from '../room-chat-input/index.tsx';

import { MessageReactionsContextProvider } from '../messages/reactions/MessageReactionsContext';

// hooks
import { useUserCanAddContent } from '../../hooks/useUserCanAddContent';
import { useMessagesList } from '../../hooks/useMessagesList';
import { useBoardData } from '../../hooks/useBoardData';
import { useTypingUsersList } from '../../hooks/feed/useTypingUsersList';
import { useLocalMessagesList } from '../../hooks/useLocalMessagesList';

import { deleteMessage, sendImageMessage, sendMessage } from '../../../api/feed-api';
import { setMinimapFeedState } from '../../../minimap';

// utils
import { track } from '../../../util/analytics-util';
import { ENTER_ROOM_SYS_MSG, handleMessageSoundFx, handleURLSummary } from '../../../message-util';
import { createUploadHandler } from '../chat-input/upload-to-chat';

// store
import { selectCurrentUserId } from '../../store/users/selectors';
import { selectRoomChatSounds } from '../../store/room/selectors';
import { setOutsideReactionFriendSuggestion } from '../../store/friends/store';
import { openRoomSettings } from '../../store/room/store';

// constants
import {
  MAX_MESSAGES_IN_PAGE,
  FEED_BOTTOM_INIT_BOTTOM,
  typingTargets,
  typingIndicatorAppearingAnimations,
  typingStatuses,
  messageTypes,
} from '../../../constants/chat-constants';
import {
  CHANGE_MESSAGE_FONT_STYLE_SOURCES,
  SEND_FEED_MESSAGE,
} from '../../../constants/analytics-events/chat-events.ts';
import { ROOM_SETTINGS_TABS } from '../../store/room/constants';

// icons
import ChatUpIcon from '../../../../assets/icons/chat-up.svg';
import ChatDownIcon from '../../../../assets/icons/chat-down.svg';
import GearIcon from '../../../../assets/icons/gear.svg';

import '../../../../styles/feed.less';

import { EventSourceContext, RoomChatInputContext } from '../../common/contexts.ts';
import { FRIEND_REQUEST_SOURCES } from '../../../constants/analytics-events/friend-events';
import { gifPickerCallers } from '../../../constants/image-constants';

const playedReactionsCache = {};
const playedChatSounds = {};

function Feed({ currentBoardId }) {
  const dispatch = useDispatch();

  const feedRootRef = React.useRef(null);
  const feedContainerRef = React.useRef(null);
  const textInputRef = React.useRef(null);

  const userId = useSelector(selectCurrentUserId);
  const roomChatSounds = useSelector(selectRoomChatSounds);

  // feed input
  const [isFeedEntryOpen, setIsFeedEntryOpen] = React.useState(false);

  // feed container
  const [isFeedVisible, setIsFeedVisible] = React.useState(false);

  const [scrolledUpAway, setScrolledUpAway] = React.useState(false);

  const [messageWithOpenedChatReactionsPickerPrevReactions, setMessageWithOpenedChatReactionsPickerPrevReactions] =
    React.useState([]);

  const [userCanAddContent] = useUserCanAddContent();

  const feedListRef = React.useRef(null);
  const bottomOfTheListRef = React.useRef(null);

  const [{ feedBackgroundImg, feedBackgroundColor, chatSoundFx }] = useBoardData(currentBoardId);

  // TODO: move to the util to reuse with text channel
  const feedBackground = React.useMemo(() => {
    if (feedBackgroundImg) {
      return `linear-gradient(0deg, #00000099, #00000099), url("${feedBackgroundImg}") center/cover`;
    }

    if (feedBackgroundColor) {
      return feedBackgroundColor;
    }

    return '#12002d'; // @menuBackground
  }, [feedBackgroundImg, feedBackgroundColor]);

  const messagesCollectionPath = React.useMemo(() => `boards/${currentBoardId}/feedItems`, [currentBoardId]);
  const imagesStorageFolder = React.useMemo(() => `boards/${currentBoardId}/feedImages`, [currentBoardId]);

  const shouldPlayFeedSound = React.useCallback(() => textInputRef.current !== document.activeElement, []);

  const {
    messagesList,
    popupMessages,
    setMessagesList,
    setPopupMessages,
    setHasMore,
    deletePopupMessage,
    loadMoreMessages,
    hasMore,
  } = useMessagesList(messagesCollectionPath, shouldPlayFeedSound);

  const { resultMessagesList, addMessageLocally, deleteLocalMessage, deleteAllLocalMessages } = useLocalMessagesList({
    messagesList,
  });

  const deleteTextMessage = React.useCallback(
    (messageId, storagePath) => {
      track(`Deleted Message`);
      deleteMessage(messageId, messagesCollectionPath).then(() => {
        deleteLocalMessage(messageId, storagePath);
      });
    },
    [deleteLocalMessage, messagesCollectionPath]
  );

  React.useEffect(() => {
    // hide feed on board change
    setIsFeedVisible(false);
  }, [messagesCollectionPath]);

  useEffect(() => {
    const latestMessage = messagesList[messagesList.length - 1];
    if (latestMessage?.type === messageTypes.OUTSIDE_REACTION && !playedReactionsCache[latestMessage.id]) {
      playedReactionsCache[latestMessage.id] = true;
      startDroppingReactionAnimation({ reaction: latestMessage.text });
      startScalingInReactionAnimation({ reaction: latestMessage.text });
      dispatch(
        setOutsideReactionFriendSuggestion({
          suggestion: {
            senderId: latestMessage.creator,
            emoji: latestMessage.text,
          },
        })
      );
    }
  }, [dispatch, messagesList]);

  useEffect(() => {
    const latestMessage = messagesList[messagesList.length - 1];
    if (
      latestMessage?.type === messageTypes.TEXT && // trigger only on text messages
      chatSoundFx !== false && // only if sound effects are not disabled
      !playedChatSounds[latestMessage.id] // only if this message hasn't been triggered a sound effect yet
    ) {
      playedChatSounds[latestMessage.id] = true;
      handleMessageSoundFx(roomChatSounds, latestMessage.text);
    }
  }, [dispatch, messagesList, chatSoundFx, roomChatSounds]);

  React.useEffect(() => {
    // TODO: should be fixed during refatoring of the InputEntry
    // reset feed container bottom we set in the InputEntry to keep keep the feed container "attached" to the InputEntry
    if (!isFeedVisible) {
      feedContainerRef.current.style.bottom = null;
    }
  }, [isFeedVisible]);

  React.useEffect(() => {
    if (!scrolledUpAway) scrollToChatBottom({ behavior: 'smooth' });
  }, [resultMessagesList, scrolledUpAway]);

  React.useEffect(() => {
    if (isFeedVisible) {
      // if chat is visible reset popup messages list on new messages
      setPopupMessages([]);

      deleteAllLocalMessages();

      if (!scrolledUpAway) {
        // if chat is not scrolled *too much* scroll it down on new messages
        scrollToChatBottom({ behavior: 'smooth' });
      }
    }
  }, [isFeedVisible, scrolledUpAway, messagesList, setPopupMessages, deleteAllLocalMessages]);

  React.useEffect(() => {
    if (!isFeedVisible) {
      // if chat is not visible render only <= MAX_MESSAGES_IN_PAGE items
      if (messagesList.length > MAX_MESSAGES_IN_PAGE) {
        setMessagesList(messagesList.slice(-MAX_MESSAGES_IN_PAGE));
      }
    }
  }, [messagesList, setMessagesList, isFeedVisible]);

  const { typingUsersList } = useTypingUsersList(typingTargets.FEED);

  function toggleFeedVisibility() {
    if (isFeedVisible) {
      // on close chat delete all messages from the local state except last MAX_MESSAGES_IN_PAGE
      const messagesLastPage = messagesList.slice(-MAX_MESSAGES_IN_PAGE);
      setMessagesList(messagesLastPage);
      setScrolledUpAway(false);
      setHasMore(messagesLastPage.length === MAX_MESSAGES_IN_PAGE);
    }

    if (!isFeedVisible) {
      // scroll to list bottom before open the chat
      scrollToChatBottom();
    }

    if (isFeedVisible) {
      track('Close Feed');
    } else {
      updateFeedLastVisibleTime(userId, currentBoardId);
      track('Open Feed');
    }

    setMinimapFeedState(!isFeedVisible);
    setIsFeedVisible(!isFeedVisible);
  }

  function scrollToChatBottom(options) {
    bottomOfTheListRef.current.scrollIntoView(options);
  }

  function onChatScroll() {
    if (feedListRef.current.scrollTop < -400 && isFeedVisible) {
      setScrolledUpAway(true);
    } else {
      setScrolledUpAway(false);
    }
  }

  const renderFeedItem = useCallback(
    (message, idx, array) => {
      const arrayLength = array.length - 1;
      // only want to display friending buttons for the last system message of each unique user
      let displaySystemFriendButton = false;
      if (message.type === messageTypes.SYSTEM && message.text === ENTER_ROOM_SYS_MSG) {
        displaySystemFriendButton = !array
          .slice(idx + 1, array.length)
          .find(
            (msg) =>
              msg.creator === message.creator && msg.type === messageTypes.SYSTEM && msg.text === ENTER_ROOM_SYS_MSG
          );
      }

      const nextCreator =
        idx < arrayLength && array[idx + 1].type !== messageTypes.SYSTEM ? array[idx + 1].creator : null;
      const nextMsgCreatedAt =
        idx < arrayLength && array[idx + 1].type !== messageTypes.SYSTEM ? array[idx + 1].createdAt : null;

      const previousCreator = idx > 0 && array[idx - 1].type !== messageTypes.SYSTEM ? array[idx - 1].creator : null;
      const previousMsgCreatedAt = idx > 0 ? array[idx - 1].createdAt : null;

      return (
        <Message
          key={message.id}
          message={message}
          nextCreator={nextCreator}
          nextMsgCreatedAt={nextMsgCreatedAt}
          previousCreator={previousCreator}
          previousMsgCreatedAt={previousMsgCreatedAt}
          userCanAddContent={userCanAddContent}
          deleteMessage={deleteTextMessage}
          isLastMessage={arrayLength === idx}
          displaySystemFriendButton={displaySystemFriendButton}
          isFeedMsg
        />
      );
    },
    [deleteTextMessage, userCanAddContent]
  );

  const renderedMessages = React.useMemo(
    () => resultMessagesList.map(renderFeedItem),
    [resultMessagesList, renderFeedItem]
  );

  const chatItemMenuContextValue = React.useMemo(
    () => ({
      messagesCollectionPath,

      messageWithOpenedChatReactionsPickerPrevReactions,
      setMessageWithOpenedChatReactionsPickerPrevReactions,
    }),
    [
      messagesCollectionPath,

      messageWithOpenedChatReactionsPickerPrevReactions,
      setMessageWithOpenedChatReactionsPickerPrevReactions,
    ]
  );

  const roomChatInputContextValue = React.useMemo(
    () => ({
      elementId: 'feed',
      caller: gifPickerCallers.FEED,
      fontStyleSource: CHANGE_MESSAGE_FONT_STYLE_SOURCES.ROOM_FEED,
    }),
    []
  );

  const sendTypingEventThrottled = useThrottledCallback((params) => {
    window.rtc.sendTyping(params);
  }, 800);

  const typingParams = React.useMemo(
    () => ({
      typingStatus: typingStatuses.ACTIVE,
      target: typingTargets.FEED,
    }),
    []
  );

  const send = async (messageToSendText) => {
    if (messageToSendText.startsWith('/') && (await handleSystemCommand(messageToSendText))) {
      return;
    }

    const ref = await sendMessage(messageToSendText, messagesCollectionPath);
    if (ref) {
      handleURLSummary(messageToSendText, ref);

      track(SEND_FEED_MESSAGE);

      sendTypingEventThrottled.cancel();
      window.rtc.sendTyping({ typingParams, typingStatus: typingStatuses.FINISHED });
    }
  };

  const onMessageUploadSuccess = React.useCallback(
    async ({ imageUrl, storagePath }) => {
      await sendImageMessage(imageUrl, storagePath, messagesCollectionPath);
    },
    [messagesCollectionPath]
  );

  const onMessageUploadFail = React.useCallback(
    (errorData) => {
      const id = `feed-upload-failed-${Math.random()}`;
      addMessageLocally({
        id,
        type: messageTypes.UPLOAD_ERROR,
        errorType: errorData.type,
        creator: userId,
        text: errorData.message,
        createdAt: new Date(),
        deleteSelf: () => deleteLocalMessage(id),
      });
    },
    [addMessageLocally, deleteLocalMessage, userId]
  );

  const handleFileUpload = useMemo(
    () => createUploadHandler(imagesStorageFolder, onMessageUploadSuccess, onMessageUploadFail),
    [imagesStorageFolder, onMessageUploadSuccess, onMessageUploadFail]
  );

  const onChatSettingsButtonClick = () => {
    dispatch(openRoomSettings({ initialTab: ROOM_SETTINGS_TABS.chat }));
  };

  return (
    <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.ROOM_CHAT_FEED}>
      <FeedRoot ref={feedRootRef}>
        {!isFeedVisible ? (
          <TypingIndicator
            typingUsers={typingUsersList}
            appearingAnimationType={typingIndicatorAppearingAnimations.SCALE}
            addGlow
          />
        ) : null}

        {/* we should keep .feed-bar because of the 'Ctrl + I'  */}
        <FeedBar feedOpen={isFeedVisible} feedEntryOpen={isFeedEntryOpen} className="feed-bar">
          <RoomChatInputContext.Provider value={roomChatInputContextValue}>
            <RoomChatInputContainer>
              <RoomChatInput
                handleFileUpload={handleFileUpload}
                sendTextMessage={send}
                onActiveChange={(isActive) => setIsFeedEntryOpen(isActive)}
                sendTyping={() => {
                  sendTypingEventThrottled.callback({
                    typingStatus: typingStatuses.ACTIVE,
                    target: typingTargets.FEED,
                  });
                }}
              />
            </RoomChatInputContainer>
          </RoomChatInputContext.Provider>
          <FeedRevealButton type="button" onClick={toggleFeedVisibility} className="prevent-on-click-outside">
            {isFeedVisible ? <ChatUpIcon /> : <ChatDownIcon />}
          </FeedRevealButton>
        </FeedBar>

        <MessageReactionsContextProvider value={chatItemMenuContextValue}>
          {/* popups list */}
          {!isFeedVisible ? (
            <PopupMessagesContainer feedEntryOpen={isFeedEntryOpen} typingIndicatorVisible={!!typingUsersList.length}>
              <PopupMessagesList>
                {popupMessages.map((popup) => (
                  <li key={popup.id}>
                    <FeedPopupMessage
                      feedItem={popup}
                      deleteItem={deletePopupMessage}
                      deleteTextMessage={deleteTextMessage}
                      userCanAddContent={userCanAddContent}
                    />
                  </li>
                ))}
              </PopupMessagesList>
            </PopupMessagesContainer>
          ) : null}

          {/* we should keep .feed-container for some InputEntry stuff */}
          <FeedContainer
            ref={feedContainerRef}
            feedEntryOpen={isFeedEntryOpen}
            feedOpen={isFeedVisible}
            className="feed-container"
          >
            <ChatSettingsButton onClick={onChatSettingsButtonClick}>
              <GearIcon height={15} width={15} />
            </ChatSettingsButton>

            <FeedList feedBackground={feedBackground}>
              {/* we should keep #feed-list-scroll-wrapper for InfiniteScroll's scrollableTarget */}
              <FeedListScrollWrapper id="feed-list-scroll-wrapper" ref={feedListRef} onScroll={onChatScroll}>
                {scrolledUpAway ? (
                  <BackToPresentButton type="button" onClick={() => scrollToChatBottom({ behavior: 'smooth' })} />
                ) : null}

                {isFeedVisible && typingUsersList.length ? (
                  <TypingIndicator
                    typingUsers={typingUsersList}
                    appearingAnimationType={typingIndicatorAppearingAnimations.TRANSLATE}
                  />
                ) : null}

                <InfiniteScroll
                  className={`messages-list ${typingUsersList.length ? 'typing-indicator-visible' : ''}`}
                  dataLength={resultMessagesList.length}
                  next={loadMoreMessages}
                  loader={null}
                  scrollableTarget="feed-list-scroll-wrapper"
                  hasMore={hasMore}
                  inverse
                >
                  {renderedMessages}
                  <div ref={bottomOfTheListRef} />
                </InfiniteScroll>
              </FeedListScrollWrapper>
            </FeedList>
          </FeedContainer>
        </MessageReactionsContextProvider>
      </FeedRoot>
    </EventSourceContext.Provider>
  );
}

Feed.propTypes = {
  currentBoardId: PropTypes.string.isRequired,
};

const FEED_REVEAL_BUTTON_WIDTH = 34;

const FeedRoot = styled.div`
  position: relative;

  .typing-indicator {
    z-index: -1;
  }

  .feed {
    .typing-indicator {
      z-index: 1;
    }
  }

  ${TypingIndicatorWrapper} {
    position: absolute;
  }
`;

const FeedBar = styled.div`
  position: absolute;
  right: 5px;
  bottom: -44px; // height of the room chat input
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  z-index: 10001;
  line-height: 35px;
  text-align: left;
  background: var(--primary-background, #12002d);
  box-shadow: 0 0 6px #ffffff1a;
  display: flex;
  border-radius: ${({ feedOpen, feedEntryOpen }) => {
    if (feedOpen) {
      return '0px 0px 15px 15px';
    }

    if (!feedOpen && feedEntryOpen) {
      return '15px';
    }

    return '30px';
  }};

  // only transition border radius on feed close
  ${({ feedOpen }) => !feedOpen && `transition: border-radius 0.6s ease;`}
`;

const RoomChatInputContainer = styled.div`
  width: calc(100% - ${FEED_REVEAL_BUTTON_WIDTH}px);
`;

const FeedRevealButton = styled.button`
  margin: 2px 2px 0px 7px;
  height: 30px;
  width: 34px;
  text-align: right;
  float: left; // TODO: can't delete it now without significant InputEntry refactoring
  background: none;
  vertical-align: middle;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  position: relative;
  right: 3px;

  &:focus {
    outline: none;
  }

  svg {
    ${svgColorMixin('var(--primary-foreground, white)')}
    width: 26px;
    max-width: none;
  }
`;

const PopupMessagesContainer = styled.div`
  position: absolute;
  bottom: ${({ feedEntryOpen }) => (feedEntryOpen ? '35px' : '15px')};
  z-index: 9998;
  padding: 10px 1px;
  width: 100%;
  font-size: 0.9em;
  line-height: 1em;
  box-sizing: border-box;
  pointer-events: none;
  transition: transform 0.2s linear;
  transform: ${({ typingIndicatorVisible }) => (typingIndicatorVisible ? 'translateY(-40px)' : '')};

  .feed-text-message {
    pointer-events: all;

    &:hover {
      background-color: inherit;
    }
  }
`;

const PopupMessagesList = styled.ul`
  padding: 0;
  list-style: none;
`;

const FeedContainer = styled.div`
  position: absolute;
  right: 5px;
  bottom: ${({ feedEntryOpen, feedOpen }) => {
    if (feedEntryOpen && feedOpen) {
      return '30px';
    }

    if (feedOpen) {
      return '0px';
    }

    return '22.5px';
  }};

  height: ${({ feedOpen, feedEntryOpen }) => {
    if (feedOpen && feedEntryOpen) {
      return 'calc(100vh - 148px)';
    }

    if (feedOpen) {
      return 'calc(100vh - 115px)';
    }

    return '0';
  }};

  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  z-index: -1;
  background-color: #12002d;
  border-radius: 15px 15px 0 0;
  border-bottom: none;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
  transition: height 0.2s ease, bottom 0.01s linear;

  ${({ feedOpen }) => !feedOpen && `transition: border 0.7s ease;`}
`;

const FeedList = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: ${FEED_BOTTOM_INIT_BOTTOM}px;
  margin-left: -60px;
  padding-left: 3px;
  height: 100%;
  width: 100%;
  z-index: 9998;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  font-size: 0.9em;
  line-height: 1em;
  background: ${({ feedBackground }) => feedBackground};
  animation-timing-function: ease;
  transition: 0.3s;
  background-size: cover;

  .feed-item-image-content {
    width: 180px;
  }
`;

const FeedListScrollWrapper = styled.div`
  position: relative;
  padding: 0 5px 15px 0;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden auto;

  ${TypingIndicatorWrapper} {
    position: fixed;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #00000087;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eeeeee87;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #eeeeeeaa;
    }
  }

  .messages-list {
    transition: transform 0.2s linear;

    &.typing-indicator-visible {
      transform: translateY(-35px);
    }
  }
`;

const chatSettingsButtonPositionPx = 15;
const ChatSettingsButton = styled.button`
  position: absolute;
  top: ${chatSettingsButtonPositionPx}px;
  right: ${chatSettingsButtonPositionPx}px;
  padding: 12px;
  z-index: 10000;
  border: none;
  border-radius: 100%;
  background-color: #12002d;
  opacity: 0.75;
  cursor: pointer;

  ${svgColorMixin('white')}

  &:hover {
    opacity: 1;
  }
`;

export default Feed;
