import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ToastManagerContext = React.createContext();

let toastsCount = 0;

export function ToastProvider({ children, toastsPosition }) {
  const [toasts, setToasts] = React.useState([]);

  const removeToast = (id) => {
    const newToasts = toasts.filter((t) => t.id !== id);
    setToasts(newToasts);
  };

  const addToast = (content, background, hideAfter = 5000) => {
    toastsCount += 1;

    const id = toastsCount;
    const toast = { content, id, background };
    setToasts([...toasts, toast]);

    if (hideAfter) {
      setTimeout(() => {
        removeToast(id);
      }, hideAfter);
    }
  };

  // avoid creating a new fn on every render
  const onDismiss = (id) => () => removeToast(id);

  return (
    <ToastManagerContext.Provider value={{ addToast, removeToast }}>
      {children}

      <ToastContainer toastsPosition={toastsPosition}>
        {toasts.map(({ content, id, background }) => (
          <Toast key={id} onDismiss={onDismiss(id)} background={background}>
            {content}
          </Toast>
        ))}
      </ToastContainer>
    </ToastManagerContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
  toastsPosition: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }).isRequired,
};

export const useToasts = () => React.useContext(ToastManagerContext);

const ToastContainer = styled.div`
  position: fixed;
  ${({ toastsPosition }) => toastsPosition};
  z-index: 2000;
`;

const ToastBody = styled.div`
  margin: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: ${({ background }) => background};
  border-radius: 10px;
`;

const CloseToastButton = styled.button`
  margin-right: 10px;
  font-weight: bolder;
  cursor: pointer;
  background: inherit;
  border: none;
`;

const Toast = ({ children, onDismiss, background }) => (
  <ToastBody background={background}>
    <CloseToastButton type="button" onClick={onDismiss}>
      ✕
    </CloseToastButton>

    {children}
  </ToastBody>
);

Toast.propTypes = {
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func.isRequired,
  background: PropTypes.string.isRequired,
};
