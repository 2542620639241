import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// store
import {
  goPrevScreen,
  tryCreateAccountWithEmail,
  setCreateAccountPasswordError,
} from '../../store/signing-in/actions.ts';
import {
  selectEmail,
  selectTryCreateAccountEmailError,
  selectTryCreateAccountPasswordError,
} from '../../store/signing-in/selectors.ts';
import { PASSWORD_MIN_LENGTH, DISPLAY_NAME_MAX_LENGTH } from '../constants.ts';

// components
import FlowInput from '../FlowInput.tsx';
import FlowInputsContainer from '../FlowInputsContainer.tsx';
import {
  ContainerDefault,
  InputContainer,
  NavButtonsContainer,
  NextNavButton,
  PrevNavButton,
  ScreenTitle,
  TitleContainer,
} from '../shared-styled.ts';
import { applyTheme } from '../../../util/theming-util';

const CreateAccountScreen = () => {
  const dispatch = useDispatch();

  const savedEmail = useSelector(selectEmail);
  const createAccountEmailError = useSelector(selectTryCreateAccountEmailError);
  const createAccountPasswordError = useSelector(selectTryCreateAccountPasswordError);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const passwordInputHint =
    password.length < PASSWORD_MIN_LENGTH ? `Password must be at least ${PASSWORD_MIN_LENGTH} characters` : '';

  const displayNameInputHint =
    name.length > DISPLAY_NAME_MAX_LENGTH ? `Name cannot be over ${DISPLAY_NAME_MAX_LENGTH} characters` : '';

  useEffect(() => {
    // preventing weird autocomplete
    setEmail(savedEmail);
    setIsEmailValid(true);
    setName('');
    setPassword('');
  }, [savedEmail]);

  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef?.current) {
      // clearing any previous theme set in a parent div that might affect the inputs
      applyTheme({}, 'Inter', containerRef.current);
    }
  }, []);

  const handleNameChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const handlePasswordChange = useCallback(
    (e) => {
      setPassword(e.target.value);

      if (createAccountPasswordError) {
        dispatch(setCreateAccountPasswordError({ error: '' }));
      }
    },
    [dispatch, createAccountPasswordError]
  );

  const onPrevClick = () => {
    dispatch(goPrevScreen({ screenData: {} }));
  };

  const onNextClick = () => {
    dispatch(tryCreateAccountWithEmail({ email, name, password }));
  };

  const isValid =
    isEmailValid &&
    name &&
    name.length <= DISPLAY_NAME_MAX_LENGTH &&
    password &&
    password.length >= PASSWORD_MIN_LENGTH;

  return (
    <ContainerDefault ref={containerRef}>
      <TitleContainer>
        <ScreenTitle>Create Account</ScreenTitle>
      </TitleContainer>

      <FlowInputsContainer isValid={isValid} onSubmit={onNextClick}>
        <InputContainer>
          <FlowInput
            label="Email"
            errorMessage={createAccountEmailError}
            id="create-account-email-input"
            type="email"
            placeholder="example@email.com"
            value={email}
            disabled
            testId="create-account-email-input-field"
          />
        </InputContainer>

        <InputContainer>
          <FlowInput
            label="Name"
            id="create-account-name-input"
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={handleNameChange}
            hint={displayNameInputHint}
            autoFocus
            testId="create-account-display-name-input-field"
          />
        </InputContainer>

        <InputContainer>
          <FlowInput
            label="Password"
            errorMessage={createAccountPasswordError}
            hint={passwordInputHint}
            id="create-account-password-input"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            testId="create-account-password-input-field"
          />
        </InputContainer>
      </FlowInputsContainer>

      <NavButtonsContainer>
        <PrevNavButton onClick={onPrevClick}>Cancel</PrevNavButton>
        <NextNavButton onClick={onNextClick} disabled={!isValid} data-testid="next-button">
          Next
        </NextNavButton>
      </NavButtonsContainer>
    </ContainerDefault>
  );
};

export default CreateAccountScreen;
