import React, {
  ChangeEvent,
  ClipboardEvent,
  DragEvent,
  KeyboardEvent,
  FocusEvent,
  useEffect,
  useRef,
  forwardRef,
  MutableRefObject,
  useCallback,
  useState,
} from 'react';
import styled from 'styled-components';

interface MessageInputProps {
  inputId?: string;

  // input props
  autoFocus?: boolean;
  disabled?: boolean;

  // message
  message: string;
  maxMessageLength: number;

  // message settings
  messageColor: string;
  fontColor: string;
  fontSize: string;
  fontFamily: string;

  // event handlers
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onPaste: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
  onDrop: (e: DragEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: FocusEvent<HTMLTextAreaElement>) => void;

  //
  onUnmount?: (inputValue: string) => void;
}

// input constants
const MIN_WIDTH = 35;
const PADDING = 10;

const MessageInput = (
  {
    inputId,

    // input props
    autoFocus,
    disabled,

    // message
    message,
    maxMessageLength,

    // message settings
    messageColor,
    fontColor,
    fontSize,
    fontFamily,

    // event handlers
    onChange,
    onPaste,
    onDrop,
    onKeyDown,
    onBlur,

    //
    onUnmount,
  }: MessageInputProps,
  ref: MutableRefObject<HTMLElement>
) => {
  const localTextareaRef = useRef(null);
  const textareaRef = ref || localTextareaRef;
  const inputSizerRef = useRef(null);

  const [maxWidth, setMaxWidth] = useState(0);
  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      entries.forEach((entry) => {
        setMaxWidth(entry.contentRect.width + PADDING * 2); // entry.contentRect.width doesn't include horizontal padding
      });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (textareaRef.current) {
      resizeObserver.observe(textareaRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [textareaRef]);

  useEffect(() => {
    const input = textareaRef.current;
    return () => {
      if (onUnmount) {
        onUnmount(input.value);
      }
    };
  }, [onUnmount, textareaRef]);

  useEffect(() => {
    // https://medium.com/@oherterich/creating-a-textarea-with-dynamic-height-using-react-and-typescript-5ed2d78d9848

    textareaRef.current.style.height = '0px';
    const { scrollHeight } = textareaRef.current;
    textareaRef.current.style.height = `${scrollHeight}px`;
  }, [message, fontSize, fontFamily, textareaRef]);

  const onFocus = useCallback(
    (e) => {
      if (autoFocus) {
        // Autofocusing on the end of the input
        const { value } = e.target;
        e.target.value = '';
        e.target.value = value;
      }
    },
    [autoFocus]
  );

  const [textAlign, setTextAlign] = useState('right');
  useEffect(() => {
    const widthNum = parseFloat(inputSizerRef?.current?.style?.width?.match(/[\d.]+/) || '0');

    if (message.length === 0) {
      setTextAlign('right');
      return;
    }

    if (widthNum >= maxWidth - 10) {
      // 10 is a buffer, helps make the text align to the left when it's close to the edge
      setTextAlign('left');
    } else {
      setTextAlign('right');
    }
  }, [inputSizerRef, message, maxWidth]);

  useEffect(() => {
    if (!textareaRef.current) return;
    const context = document.createElement('canvas').getContext('2d');
    const style = window.getComputedStyle(textareaRef.current);

    // @ts-ignore
    context.font = `${style['font-size']} ${style['font-family']}`;

    const metrics = context.measureText(message);
    const newWidth = Math.max(MIN_WIDTH, Math.min(maxWidth, metrics.width + PADDING * 2)); // metrics.width doesn't include horizontal padding

    inputSizerRef.current.style.width = `${newWidth}px`;
  }, [message, textareaRef, fontSize, fontFamily, maxWidth]);

  return (
    <MessageInputContainer
      className="dont-drag-me"
      messageColor={messageColor}
      fontColor={fontColor}
      fontSize={fontSize}
      fontFamily={fontFamily}
    >
      <MessageInputSizer ref={inputSizerRef}>
        <MessageInputSizerText>{message || ' '}</MessageInputSizerText>
      </MessageInputSizer>
      <MessageInputField
        textAlign={textAlign}
        id={inputId}
        ref={textareaRef}
        cols={1}
        value={message}
        maxLength={maxMessageLength}
        onChange={onChange}
        onPaste={onPaste}
        onDrop={onDrop}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </MessageInputContainer>
  );
};

export default forwardRef(MessageInput);

const MessageInputField = styled.textarea<{ textAlign: string }>`
  position: relative;
  padding: 7px ${PADDING}px;
  max-height: 50px;
  box-sizing: border-box;
  z-index: 1;
  overflow: hidden;
  background-color: transparent;
  border: none;
  resize: none;
  cursor: text;
  width: 100%;
  text-align: ${({ textAlign }) => textAlign};

  &:focus {
    outline: none;
  }
`;

// TODO: make sizer and text input share styles
const MessageInputSizer = styled.div`
  position: absolute;
  padding: 7px ${PADDING}px;
  height: 100%;
  z-index: 0;
  box-sizing: border-box;
  border-radius: 10px 10px 0 10px;
  min-width: ${MIN_WIDTH}px;
`;

const MessageInputSizerText = styled.p`
  padding: 5px;
  visibility: hidden;
`;

const MessageInputContainer = styled.div<{
  messageColor: string;
  fontColor: string;
  fontSize: string;
  fontFamily: string;
}>`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  ${MessageInputSizer} {
    background-color: ${({ messageColor }) => messageColor};
  }
  ${MessageInputSizerText}, ${MessageInputField} {
    color: ${({ fontColor }) => fontColor};
    font-size: ${({ fontSize }) => fontSize};
    font-family: ${({ fontFamily }) => fontFamily};
    line-height: ${({ fontSize }) => fontSize};
    white-space: pre-wrap;
  }
`;
