import { take } from 'redux-saga/effects';

export async function requestAsync(apiCall) {
  const response = await apiCall();

  if (response && response.success) {
    return response;
  }
  throw new Error('error');
}

export function* waitForAction(action) {
  yield take(action);
}
