import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './init-state';
import {
  setIsAppearanceMenuOpened,
  setIsFeedbackMenuOpened,
  setIsAvatarProfileOpened,
  setIsCreateChatOpened,
  setIsFriendsOnlineOpened,
  setIsFriendsSidebarOpened,
  setIsMyStuffMenuOpened,
  setIsRoomsMenuOpened,
  setIsWhatsNewOpened,
  setMaxZIndex,
  setOpenChats,
  setIsDiscoverModalOpened,
} from './actions';

export const osReducer = createReducer(initialState, (builder) => {
  builder.addCase(setIsFriendsSidebarOpened, (state, action) => {
    state.isFriendsSidebarOpened = action.payload.defaultTab;
  });
  builder.addCase(setIsFriendsOnlineOpened, (state, action) => {
    state.isFriendsOnlineOpened = action.payload.isOpened;
  });
  builder.addCase(setIsAppearanceMenuOpened, (state, action) => {
    state.isAppearanceMenuOpened = action.payload.isOpened;
  });
  builder.addCase(setIsFeedbackMenuOpened, (state, action) => {
    state.isFeedbackMenuOpened = action.payload.isOpened;
  });
  builder.addCase(setIsRoomsMenuOpened, (state, action) => {
    state.isRoomsMenuOpened = action.payload.isOpened;
  });
  builder.addCase(setIsAvatarProfileOpened, (state, action) => {
    state.isAvatarProfileOpened = action.payload.isOpened;
  });
  builder.addCase(setIsMyStuffMenuOpened, (state, action) => {
    state.isMyStuffMenuOpened = action.payload.isOpened;
  });
  builder.addCase(setIsCreateChatOpened, (state, action) => {
    state.isCreateChatOpened = action.payload.isOpened;
  });
  builder.addCase(setIsWhatsNewOpened, (state, action) => {
    state.isWhatsNewOpened = action.payload.isOpened;
  });
  builder.addCase(setIsDiscoverModalOpened, (state, action) => {
    state.isDiscoverModalOpened = action.payload.isOpened;
  });
  builder.addCase(setOpenChats, (state, action) => {
    state.openChats = action.payload.openChats;
  });
  builder.addCase(setMaxZIndex, (state, action) => {
    state.maxZIndex = action.payload.value;
  });
});
