import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import lottie from 'lottie-web';

const LottieAnimation = ({ loop, onComplete, animationData }) => {
  const container = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      animationData,
      container: container.current,
      loop,
      autoplay: true,
    });
    animation.onComplete = onComplete;

    return () => animation.destroy();

    // We don't want to initiate it more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container ref={container} />;
};

LottieAnimation.propTypes = {
  animationData: PropTypes.shape({}).isRequired,
  // set loop prop to true for infinite amount of loops, or pass a number for a specific amount of loops:
  loop: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onComplete: PropTypes.func,
};

LottieAnimation.defaultProps = {
  loop: 0, // Means it'll be played only once
  onComplete: () => {},
};

export default LottieAnimation;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
