import UIkit from 'uikit';
import Hammer from 'hammerjs';

import firebase from './firebase';

import { loadUpdates } from './feedback';
import SpawnElement from './spawn';
import CameraElement from './camera';
import BarrierElement from './barriers';
import ChatElement from './chat';
import AppElement, { Apps, INTERNAL_APP_NAMES } from './app-element';
import TictactoeElement from './tictactoe';
import StopwatchElement from './stopwatch';
import PokerElement from './poker';
import CodenamesElement from './codenames';
import SkribblioElement from './skribblio';
import ChessElement from './chess';
import log from './log';
import { addReactionForKey } from './reactions';
import { startMonitoringOwnAudioDevicesChange } from './mic-monitor';
import { zoomIn, zoomOut, mouseMoveDraw, updateCanvasPosition, moveToDefaultViewport } from './viewport';
import { handleKey } from './keyboard-shortcuts';
import { startElectronBridge } from './electron-support/electron-support';
import { checkIsElectron } from './util/platform-util';
import EmbeddedElement from './embedded';
import TimerElement from './timer';
import RoomTopMembersWidget from './widgets/room-top-members-widget';
import RoomActiveTimeWidget from './widgets/room-active-time-widget';
import RoomStreaksElement from './room-streaks';
import { checkNuked } from './util/nuke-util';

import store from './react/store/room-root-store';
import { openRoomSettings } from './react/store/room/store';
import { renderRoomSettings } from './react/room/render';

// utils
import { track } from './util/analytics-util';
import { isHereEmployee, signout } from './util/user-util';
import { getQueryStringValue } from './util';
import { renderLensPicker } from './react/lenses/render.tsx';
import eventBus, { lensSelected } from './event-bus';
import { ELEMENT_TYPES } from './constants/analytics-events/element-events';

function setupModalSlider({ joinRoomContentHammer, joinRoomContentSteps, joinRoomContentMobileStepDots }) {
  let sliderInterval = 0;

  // mobile carousel should slide automatically
  UIkit.util.on(document, 'show', '#join-room-dialog', () => {
    let intervalIndex = 1;
    const stepClasses = ['first-step', 'second-step', 'third-step'];

    sliderInterval = setInterval(() => {
      if (intervalIndex > 2) {
        clearInterval(sliderInterval);
        return;
      }

      joinRoomContentSteps.classList.remove(stepClasses[intervalIndex - 1]);
      joinRoomContentSteps.classList.add(stepClasses[intervalIndex]);
      joinRoomContentMobileStepDots.classList.remove(stepClasses[intervalIndex - 1]);
      joinRoomContentMobileStepDots.classList.add(stepClasses[intervalIndex]);

      intervalIndex += 1;
    }, 1500);
  });

  joinRoomContentHammer.on('swipeleft', () => {
    clearInterval(sliderInterval); // if user swiped, stop auto carousel

    if (joinRoomContentSteps.classList.contains('second-step')) {
      joinRoomContentSteps.classList.remove('second-step');
      joinRoomContentSteps.classList.add('third-step');
      joinRoomContentMobileStepDots.classList.remove('second-step');
      joinRoomContentMobileStepDots.classList.add('third-step');
    } else if (joinRoomContentSteps.classList.contains('first-step')) {
      joinRoomContentSteps.classList.remove('first-step');
      joinRoomContentSteps.classList.add('second-step');
      joinRoomContentMobileStepDots.classList.remove('first-step');
      joinRoomContentMobileStepDots.classList.add('second-step');
    }
  });

  joinRoomContentHammer.on('swiperight', () => {
    clearInterval(sliderInterval); // if user swiped, stop auto carousel

    if (joinRoomContentSteps.classList.contains('second-step')) {
      joinRoomContentSteps.classList.remove('second-step');
      joinRoomContentSteps.classList.add('first-step');
      joinRoomContentMobileStepDots.classList.remove('second-step');
      joinRoomContentMobileStepDots.classList.add('first-step');
    } else if (joinRoomContentSteps.classList.contains('third-step')) {
      joinRoomContentSteps.classList.remove('third-step');
      joinRoomContentSteps.classList.add('second-step');
      joinRoomContentMobileStepDots.classList.remove('third-step');
      joinRoomContentMobileStepDots.classList.add('second-step');
    }
  });
}

export default async function bootstrap() {
  if (checkNuked()) {
    signout();
    return;
  }

  // Room data isn't loaded yet, so relying on the query string
  if (getQueryStringValue('is-user-page')) return;

  await window.rtc.init();

  if (window.location.pathname !== '/') {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        unsubscribe();
      }
    });
  }

  startMonitoringOwnAudioDevicesChange();
  // TODO: Do we need to do it on every menu open?
  UIkit.util.on(document, 'show', '#device-settings', window.rtc.updateDevices.bind(window.rtc));

  if (checkIsElectron()) {
    document.getElementById('electron-toolbar').style.display = 'block';
  }

  document.addEventListener('keydown', (e) => {
    if (!['INPUT', 'TEXTAREA'].includes(document.activeElement.nodeName)) {
      addReactionForKey(e.key);
    }
    handleKey(e);
  });

  document
    .querySelectorAll('.board-title-settings-button, .room-settings-button, .room-settings-link')
    .forEach((el) => {
      el.addEventListener('click', () => store.dispatch(openRoomSettings()));
    });
  renderRoomSettings();

  const onIdClick = (id, e) => {
    document.getElementById(id).addEventListener('click', e);
  };

  onIdClick('mic-on-button', () => {
    log.debug('Bootstrap: Attempt to enable own audio');
    CameraElement.enableOwnAudio();
  });

  onIdClick('mic-off-button', () => {
    log.debug('Bootstrap: Attempt to disable own audio');
    CameraElement.disableOwnAudio();
  });

  onIdClick('camera-mic-problems-help-link', () => {
    track('Devices Modal Need Help');
  });

  onIdClick('camera-on-button', () => {
    log.debug('Bootstrap: Attempt to enable own camera');
    CameraElement.enableOwnCamera();
  });
  onIdClick('camera-off-button', () => {
    log.debug('Bootstrap: Attempt to disable own camera');
    CameraElement.disableOwnCamera();
  });
  onIdClick('newTictactoeButton', () => TictactoeElement.addElement());
  onIdClick('newFourRowButton', () =>
    EmbeddedElement.addElement('/embedded/connect-four.html', {
      name: ELEMENT_TYPES.FOUR_IN_A_ROW,
      size: [700, 440],
      minSize: [510, 325],
      preserveAspectRatio: true,
    })
  );
  onIdClick('newTriviaButton', () =>
    EmbeddedElement.addElement('/embedded/trivia.html', {
      name: ELEMENT_TYPES.TRIVIA,
      size: [880, 580],
      minSize: [880, 580],
      preserveAspectRatio: true,
    })
  );
  onIdClick('newStopwatchButton', () => StopwatchElement.addElement());
  onIdClick('newTimerButton', () => TimerElement.addElement());
  onIdClick('roomStreaksButton', () => RoomStreaksElement.addElement());
  onIdClick('newPokerButton', () => PokerElement.addElement());
  onIdClick('newCodenamesButton', () => CodenamesElement.addElement());
  onIdClick('newSkribblioButton', () => SkribblioElement.addElement());
  onIdClick('newChessButton', () => ChessElement.addElement());

  onIdClick('newDiceRoller', () =>
    AppElement.addElement({ appId: Apps.Dice, name: INTERNAL_APP_NAMES.DICE, systemMessage: 'added a dice roller' })
  );
  onIdClick('newToDoList', () =>
    AppElement.addElement({ appId: Apps.ToDo, name: INTERNAL_APP_NAMES.TO_DO, systemMessage: 'added a to-do list' })
  );
  onIdClick('newPoll', () =>
    AppElement.addElement({ appId: Apps.Poll, name: INTERNAL_APP_NAMES.POLL, systemMessage: 'added a poll' })
  );

  onIdClick('zoom-in', zoomIn);
  onIdClick('zoom-out', zoomOut);
  onIdClick('reset-position-button', moveToDefaultViewport);

  document.querySelector('.add-camera-spawn-point').onclick = () => SpawnElement.addElement(['CameraElement']);
  document.querySelector('.add-barrier').onclick = () => BarrierElement.addElement();
  document.querySelector('.add-chat').onclick = () => ChatElement.addElement();
  document.querySelector('.active-room-time').onclick = () => RoomActiveTimeWidget.addElement();
  document.querySelector('.top-members').onclick = () => RoomTopMembersWidget.addElement();

  document.getElementById('main').onmousemove = mouseMoveDraw;

  onIdClick('waitlist-button', (e) => {
    const messages = document.getElementById('room-messages');
    if (messages.style.display !== 'block') {
      e.currentTarget.classList.add('active-waitlist');
    } else {
      e.currentTarget.classList.remove('active-waitlist');
    }
  });

  window.addEventListener(
    'resize',
    () => {
      updateCanvasPosition(window.canvasOffsetX, window.canvasOffsetY, window.canvasScale);
    },
    false
  );

  const lensButton = document.getElementById('lens-menu-button');
  eventBus.on(lensSelected, ({ lens }) => lensButton.classList[lens ? 'add' : 'remove']('lens-enabled'));

  const viewerJoinRoomContent = document.getElementById('viewer-join-room-content');
  const viewerJoinRoomContentMobileStepDots = document.getElementById('viewer-join-room-content-mobile-step-dots');
  const viewerJoinRoomContentHammer = new Hammer(viewerJoinRoomContent, {});
  const viewerJoinRoomContentSteps = viewerJoinRoomContent.querySelector('.steps');
  setupModalSlider({
    joinRoomContentHammer: viewerJoinRoomContentHammer,
    joinRoomContentSteps: viewerJoinRoomContentSteps,
    joinRoomContentMobileStepDots: viewerJoinRoomContentMobileStepDots,
  });

  const publicJoinRoomContent = document.getElementById('public-join-room-content');
  const publicJoinRoomContentMobileStepDots = document.getElementById('public-join-room-content-mobile-step-dots');
  const publicJoinRoomContentHammer = new Hammer(publicJoinRoomContent, {});
  const publicJoinRoomContentSteps = publicJoinRoomContent.querySelector('.steps');
  setupModalSlider({
    joinRoomContentHammer: publicJoinRoomContentHammer,
    joinRoomContentSteps: publicJoinRoomContentSteps,
    joinRoomContentMobileStepDots: publicJoinRoomContentMobileStepDots,
  });

  let isLensPickerInitialized = false;
  document.querySelector('.lenses-menu-container').addEventListener('show', (e) => {
    if (!isLensPickerInitialized) {
      renderLensPicker(e.target);
      isLensPickerInitialized = true;
    }
  });
  document.querySelector('.lenses-popover-link').addEventListener('click', () => {
    UIkit.dropdown('.lenses-menu-container').show();
  });

  // WebRTC connectivity

  window.rtc.onReconnecting = () => {
    log.warn('On Reconnecting callback');
    const errorEl = document.getElementById('error-message');
    errorEl.style.display = 'block';

    errorEl.innerHTML = '<here-loader>Reconnecting</here-loader>';
  };

  loadUpdates(false);
  startElectronBridge();

  // Testing h.264 support - temporary, remove me!
  const testEl = document.createElement('video');
  if (testEl.canPlayType) {
    // Check for h264 support
    let typeStr =
      testEl.canPlayType('video/mp4; codecs="avc1.42E01E"') ||
      testEl.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"');
    const h264 = typeStr !== '';
    // Check for Webm / vp8 support
    typeStr = testEl.canPlayType('video/webm; codecs="vp8, vorbis"');
    const vp8 = typeStr !== '';
    // Check for AV1 support
    typeStr = testEl.canPlayType('video/webm; codecs="av01.0.05M.08"');
    const av1 = typeStr !== '';

    track('Video Codec Support', { h264, vp8, av1 });
    log.debug('Video Codec Support', { h264, vp8, av1 });
  }
}
