import React, { useRef } from 'react';

import { useSettingsTabsAdapter } from '../useSettingsTabsAdapter';

const RoomDetailsSettings = () => {
  const webComponentRef = useRef();
  useSettingsTabsAdapter(webComponentRef);
  return <here-room-admin ref={webComponentRef} />;
};

export default RoomDetailsSettings;
