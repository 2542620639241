import { createAction } from '@reduxjs/toolkit';

import { CreateGroupRequest, Group, GroupMember } from '../../../definitions/groups';
import { Board } from '../../../definitions/boards';
import { SkinData } from '../chat-skinning/init-state';

export const joinGroup = createAction<{ groupId: string }>('groups/joinGroup');
export const joinGroupSuccess = createAction<{ group: Group }>('groups/joinGroupSuccess');
export const joinGroupError = createAction<{ error: string }>('groups/joinGroupError');

export const createGroup = createAction<CreateGroupRequest>('groups/createGroup');
export const setCreateGroupError = createAction<{ error: string }>('groups/setCreateGroupError');
export const setCreateGroupSuccess = createAction('groups/setCreateGroupSuccess');

export const requestGroupUpdate = createAction<{
  id: string;
  updates: {
    name?: string;
    background?: string;
    theme?: {
      isCustom?: boolean;
      primaryFont?: string;
      colors?: {
        primaryBackground?: string;
        primaryForeground?: string;
        secondaryBackground?: string;
        secondaryForeground?: string;
      };
      skin?: SkinData;
    };
  };
}>('groups/requestGroupUpdate');
export const setCreatedGroupId = createAction<{ groupId: string }>('groups/setCreatedGroupId');
export const setNewGroupMembers = createAction<{ groupId: string; newMembers: GroupMember[] }>(
  'groups/setNewGroupMembers'
);

export const addMembersToGroup = createAction<{ groupId: string; userIds: string[] }>('groups/addMembers');

export const updateNotificationsSetting = createAction<{ groupId: string; userId: string; isNotificationsOn: boolean }>(
  'groups/updateNotifications'
);
export const leaveGroup = createAction<{ groupId: string }>('groups/leaveGroup');

// sends delete group request and handles success/response:
export const deleteGroup = createAction<{ groupId: string }>('groups/deleteGroup');
export const deleteGroupSuccess = createAction('groups/deleteGroupSuccess');
export const deleteGroupError = createAction<{ error: string }>('groups/deleteGroupError');

// transfer room to group
export const transferRoomToGroupRequest = createAction<{ groupId: string; roomId: string }>(
  'groups/transferRoomToGroupRequest'
);
export const transferRoomToGroupSuccess = createAction<{ chatId: string; boards: Board[] }>(
  'groups/transferRoomToGroupSuccess'
);
export const transferRoomToGroupError = createAction<{ error: string }>('groups/transferRoomToGroupError');

// delete room from group
export const deleteRoomFromGroupRequest = createAction<{ groupId: string; roomId: string }>(
  'groups/deleteRoomFromGroupRequest'
);
export const deleteRoomFromGroupSuccess = createAction<{ chatId: string; roomId: string }>(
  'groups/deleteRoomFromGroupSuccess'
);
export const deleteRoomFromGroupError = createAction<{ error: string }>('groups/deleteRoomFromGroupError');

export const socketReconnect = createAction<{ groupId?: string }>('groups/socketReconnect');
