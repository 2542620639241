export enum WebsocketEvents {
  JOIN_ROOM = 'joinRoom',
  VISIBILITY_CHANGE = 'visibilityChange',
  DM = 'dm',
  ONLINE_STATUS_CHANGE = 'onlineStatusUpdate',
  GROUP = 'group',
  USER_TYPING = 'userTyping',
  TYPING_IN_CHAT = 'typingInChat',
  UPDATE_SUPER_PRESENCE = 'updateSuperPresence',
}

export enum WebsocketGroupEventTypes {
  GROUP_SETTINGS_CHANGE = 'groupSettingsChange',
  NEW_MEMBER_IN_GROUP = 'newMembersAddedToGroup',
  MEMBER_REMOVED_FROM_GROUP = 'memberRemovedFromGroup',
  NEW_GROUP_ADDED = 'newGroupAdded',
  GROUP_DELETED = 'groupDeleted',
  MEMBER_ROLE_CHANGED = 'roleChangeForMemberOfGroup',
}

export interface EventsMap {
  [key: string]: unknown;
}
