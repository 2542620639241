export const ROOM_SEARCH = 'Room Search';
export const ENTER_ROOM = 'Enter Room'; // optional param: type ('user page')

export const RoomSearchActions = {
  SEARCH: 'Search',
  ENTER: 'Enter',
  FETCHED: 'Fetched',
};

export const RoomSearchFetchTypes = {
  ID: 'id',
  URL_ALIAS: 'urlAlias',
  NO_RESULTS: 'noResults',
};

// Event triggered when first room for a user is created:
export const CREATE_NEW_USER_ROOM = 'Create New User Room';
// params:
// firstName
// fullName
// email
// roomId
// template
// userOrigin ('electron' or 'web')
// entryPoint ('homepage' or 'room deep link')
// homepageConcept
// flowType (optional 'create group')
// groupId

// Event triggered when room is created (unless it's the user's first room, then trigger CREATE_NEW_USER_ROOM)
export const CREATE_ROOM = 'Create Room';
// params: title, roomId, template, userOrigin ('electron' or 'web'), flowType (optional 'create group'), groupId

export const COPY_INVITE_LINK = 'Copy Share Link';
// params: source

export const CopyInviteLinkContexts = {
  INVITE_MODAL: 'Invite Modal',
  CREATE_ROOM: 'Create Room',
  ROOM_SETTINGS: 'Room Settings',
  INVITE_RECENT_VISITOR_MODAL: 'Invite Recent Visitor Modal',
};

export const SAVE_DEFAULT_VIEWPORT = 'Save Default Viewport';
// params: size
export const SAVE_ROOM_BORDERS = 'Save Room Borders';

export const INVITE_RECENT_VISITORS_MODAL_DISPLAYED = 'Invite Recent Visitors Modal Displayed';
export const INVITE_RECENT_VISTORS_MODAL_DISMISSED = 'Invite Recent Visitors Modal Dismissed';

// params: destination
export const UPLOAD_BACKGROUND_IMAGE = 'Upload Background Image';
