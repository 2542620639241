import React from 'react';
import PropTypes from 'prop-types';

const BanIcon = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="6.25" stroke={color} strokeWidth="1.5" />
    <path d="M3 2.5L11.5 11" stroke={color} strokeWidth="1.5" />
  </svg>
);

export default BanIcon;

BanIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

BanIcon.defaultProps = {
  size: '14',
  color: 'black',
};
