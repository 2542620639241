import CameraElement from './camera';
import log from './log';
import { htmlToElement, getRandomNumberInRange, performActionWithInterval } from './util';
import { getElementPosition } from './element-transform';
import '../styles/reactions.less';
import { track } from './util/analytics-util';
import eventBus, { keyEmojisActivated } from './event-bus';
import reduxAdapter from './react/store/redux-adapter';
import { selectCurrentUserReactions } from './react/store/users/selectors';
import { screenToCanvasCoords } from './util/canvas-util';
import { setCssPixelsVariable, setCssVariable } from './util/styles-util';

let userReactions = {};
reduxAdapter.subscribe((state) => {
  userReactions = selectCurrentUserReactions(state);
});

let mouseCoordinates = {};
let shouldSplashReactions = false;
window.addEventListener('mousemove', (e) => {
  mouseCoordinates = { x: e.clientX, y: e.clientY };
  shouldSplashReactions = !!e.target.closest('.camera-container, .youtube-element');
});

// TODO: What if user presses multiple keys?
let isKeyPressed = false;
let isKeyPressedForLong = false;
let lastKeyDownTime = null;
window.addEventListener('keydown', () => {
  if (!isKeyPressed) {
    lastKeyDownTime = new Date().getTime();
    isKeyPressed = true;
  }
  isKeyPressedForLong = new Date().getTime() - lastKeyDownTime > 1500;
});
window.addEventListener('keyup', () => {
  isKeyPressed = false;
  isKeyPressedForLong = false;
});

let lastChatReaction = null;
let lastChatReactionTime = null;
const CHAT_TRACKING_INTERVAL = 2000;
const SPLASH_RANGE = 200;
const DIRECTED_REACTION_INTERVAL_MS = 200;

export function addReactionForKey(key) {
  const reaction = userReactions[key];
  if (reaction) {
    addReaction(reaction);
  }
}

function getMouseCoordinates() {
  return screenToCanvasCoords(mouseCoordinates.x, mouseCoordinates.y);
}

function getRandomizedMouseCoordinates(randomizationRange = 10) {
  return screenToCanvasCoords(
    mouseCoordinates.x + getRandomNumberInRange(-randomizationRange, randomizationRange),
    mouseCoordinates.y + getRandomNumberInRange(-randomizationRange, randomizationRange)
  );
}

function addReaction(reaction) {
  if (!CameraElement.ownCamera) {
    return;
  }

  const now = new Date().getTime();
  // Don't send too much directed reactions (maybe we should do it for usual as well?)
  if (isKeyPressedForLong && now - lastChatReactionTime < DIRECTED_REACTION_INTERVAL_MS) {
    return;
  }
  // Track when either a new reaction is sent, or there's been a delay or CHAT_TRACKING_INTERVAL
  // since the last one.
  if (lastChatReaction !== reaction || now - lastChatReactionTime > CHAT_TRACKING_INTERVAL) {
    track(isKeyPressedForLong ? 'Blaster' : 'Chat Head Reaction', { reaction });
    lastChatReaction = reaction;
  }
  lastChatReactionTime = now;

  const camera = document.getElementById(`element-${CameraElement.ownCamera.elementId}`);
  if (camera) {
    const style = window.getComputedStyle(camera);
    const [x, y] = getElementPosition(camera);
    const w = parseFloat(style.getPropertyValue('width')) || 0;
    const h = parseFloat(style.getPropertyValue('height')) || 0;

    if (isKeyPressedForLong) {
      // If it hits the target, coordinates will be randomized later on the splash stage.
      const [xDest, yDest] = shouldSplashReactions ? getMouseCoordinates() : getRandomizedMouseCoordinates();
      const xCenter = x + w / 2;
      const yCenter = y + h / 2;
      const additionalData = { x: xCenter, y: yCenter, xDest, yDest, doesHit: shouldSplashReactions };
      startDirectedReaction(reaction, additionalData);
      window.rtc.sendReactionDirected(reaction, additionalData);
    } else {
      const xPos = x + getRandomNumberInRange(0, w);
      const yPos = y + getRandomNumberInRange(0, h);
      startReaction(reaction, xPos, yPos);
      window.rtc.sendReaction(reaction, xPos, yPos);
    }
    eventBus.dispatch(keyEmojisActivated);
  } else {
    log.warn('No camera found for reaction');
  }
}

export function startReaction(reaction, x, y) {
  const el = htmlToElement(
    `<div class="reaction" style="transform: translate3d(${x - 15}px, ${y}px, 0)">
    <div class="reaction-up"><div class="reaction-wave">${reaction}</div></div></div>`
  );
  document.getElementById('elements').appendChild(el);
  setTimeout(() => {
    el.parentNode.removeChild(el);
  }, 4000);
}

export function startReactionInNode(reaction, node, x, y) {
  const el = htmlToElement(
    `<div class="reaction" style="transform: translate3d(${x - 15}px, ${y}px, 0)">
    <div class="reaction-up"><div class="reaction-wave">${reaction}</div></div></div>`
  );
  node.appendChild(el);
  setTimeout(() => {
    el.parentNode.removeChild(el);
  }, 4000);
}

const bodyStyle = window.getComputedStyle(document.body);
const bodyWidth = parseFloat(bodyStyle.getPropertyValue('width')) || 0;

function dropReactionFromTop(reaction, x, y, parent = document.getElementById('main')) {
  const el = htmlToElement(
    `<div class="reaction" style="top: ${y}px; left: ${x}px;">
      <div class="reaction-down">
        <div class="reaction-wave">${reaction}</div>
      </div>
    </div>`
  );

  parent.appendChild(el);

  setTimeout(() => {
    el.parentNode.removeChild(el);
  }, 3000);
}

function scaleInReaction(reaction, parent = document.getElementById('main')) {
  const el = htmlToElement(
    `<div class="reaction" style="top: 50%; left: 50%;">
      <div class="reaction-scale-in">
        ${reaction}
      </div>
    </div>`
  );

  parent.appendChild(el);

  setTimeout(() => {
    el.parentNode.removeChild(el);
  }, 500);
}

const REACTIONS_NUMBER_DEFAULT = 200;
const REACTIONS_INTERVAL_DEFAULT = 30;
const SCALE_IN_REACTION_NUMBER_DEFAULT = 3;
const SCALE_IN_REACTION_INTERVAL_DEFAULT = 400;

export function startDroppingReactionAnimation({
  reaction = '💖',
  reactionsNumber = REACTIONS_NUMBER_DEFAULT,
  reactionsInterval = REACTIONS_INTERVAL_DEFAULT,
  fallingReactionsParent,
}) {
  performActionWithInterval(reactionsNumber, reactionsInterval, () => {
    const xPos = getRandomNumberInRange(0, bodyWidth);
    const yPos = getRandomNumberInRange(-100, 150);
    dropReactionFromTop(reaction, xPos, yPos, fallingReactionsParent);
  });
}

export function startScalingInReactionAnimation({
  reaction = '💖',
  scaleInReactionNumber = SCALE_IN_REACTION_NUMBER_DEFAULT,
  scaleInReactionInterval = SCALE_IN_REACTION_INTERVAL_DEFAULT,
  scalingInReactionsParent,
}) {
  performActionWithInterval(scaleInReactionNumber, scaleInReactionInterval, () => {
    scaleInReaction(reaction, scalingInReactionsParent);
  });
}

export function startDirectedReaction(
  reaction,
  // The speed is just empirical value so it looks pretty fast.
  { x, y, xDest, yDest, doesHit, container, speed = 0.7 }
) {
  xDest = xDest || mouseCoordinates.x;
  yDest = yDest || mouseCoordinates.y;

  const el = htmlToElement(`<div class="reaction reaction-directed">${reaction}</div>`);
  setCssPixelsVariable('xStart', x, el);
  setCssPixelsVariable('yStart', y, el);

  setCssPixelsVariable('xHalf', (xDest + x) / 2, el);
  setCssPixelsVariable('yHalf', (yDest + y) / 2, el);

  setCssPixelsVariable('xEnd', xDest, el);
  setCssPixelsVariable('yEnd', yDest, el);

  const xSplash = doesHit ? getRandomNumberInRange(-SPLASH_RANGE, SPLASH_RANGE) : 0;
  const ySplash = doesHit ? getRandomNumberInRange(-SPLASH_RANGE, SPLASH_RANGE) : 0;
  setCssPixelsVariable('xSplashEnd', xDest + xSplash, el);
  setCssPixelsVariable('ySplashEnd', yDest + ySplash, el);

  const initialRotation = `${Math.atan2(yDest - y, xDest - x) + Math.PI / 2}rad`;
  setCssVariable('initialRotation', initialRotation, el);
  setCssVariable('finalRotation', doesHit ? `${getRandomNumberInRange(500, 800)}deg` : initialRotation, el);
  setCssVariable('finalScale', doesHit ? 10 : 1, el);

  // Making it run with the same speed on any distance
  const distance = Math.hypot(x - xDest, y - yDest);
  const durationMs = distance / speed;
  setCssVariable('duration', `${durationMs}ms`, el);

  (container || document.getElementById('elements')).appendChild(el);
  setTimeout(() => {
    el.parentNode.removeChild(el);
  }, durationMs);
}
