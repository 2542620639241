import React from 'react';
import styled from 'styled-components';

const SignInBackground = () => (
  <Container>
    <VideoBackground playsInline autoPlay muted loop>
      <source
        src="https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/homepage%2FDesktopBG_slower.mp4?alt=media&token=44223295-d919-4bf3-9304-b6ceabddbf63"
        type="video/mp4"
      />
    </VideoBackground>
    <ImageBackground />
  </Container>
);

export default SignInBackground;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ImageBackground = styled(Container)`
  background: url(/images/homepage-bottom-gradient.png) no-repeat bottom;
  background-size: cover;
`;

const VideoBackground = styled.video`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;
