import { all, takeEvery, call, put, select } from 'redux-saga/effects';

import {
  createGroup,
  setCreatedGroupId,
  requestGroupUpdate,
  joinGroup,
  addMembersToGroup,
  setNewGroupMembers,
  updateNotificationsSetting,
  leaveGroup,
  joinGroupSuccess,
  joinGroupError,
  deleteGroup,
  deleteGroupSuccess,
  deleteGroupError,
  transferRoomToGroupRequest,
  transferRoomToGroupSuccess,
  transferRoomToGroupError,
  deleteRoomFromGroupRequest,
  deleteRoomFromGroupSuccess,
  deleteRoomFromGroupError,
  setCreateGroupError,
  setCreateGroupSuccess,
} from './actions';
import {
  patchGroup,
  createGroup as createGroupRequest,
  joinGroup as joinGroupRequest,
  addMembersToGroup as addMembersToGroupRequest,
  patchGroupNotifications,
  removeMemberFromGroup,
  deleteGroup as deleteGroupRequest,
  transferRoomToGroup as transferRoomToGroupApiRequest,
  deleteRoomFromGroup as deleteRoomFromGroupApiRequest,
} from '../../../api/groups-api';
import { requestAsync } from '../helpers';
import {
  CreateGroupRequest,
  Group,
  GroupChatInStore,
  GroupChatMember,
  GroupMember,
  GroupResponse,
  JoinGroupResultType,
} from '../../../definitions/groups';
import { formatGroupChatFromGroupResponse, getGroupFromGroupResponse } from './normalizers';
import log from '../../../log';
import { ThemeColors } from '../../../definitions/theme-colors';
import { getDefinedFieldsObject } from '../../../util';
import { updateUsersFromArray } from '../users/store';
import { selectCurrentUserId } from '../users/selectors';

import { setGroupChat, removeChat } from '../messaging/reducer';
import { keyMessagesById } from '../messaging/normalizers';
import {
  ADD_GROUP_MEMBERS,
  FormatGroupErrorTypes,
  CREATE_GROUP,
} from '../../../constants/analytics-events/groups-events';
import { getGroupChatParams, getHubTrackParams, track } from '../../../util/analytics-util';
import { openChat, setOpenChats } from '../os/actions';
import { selectCurrentOsDesktopId } from '../room/selectors';
import { db } from '../../../firebase';
import { elementClasses } from '../../board-elements/elements';
import { selectChatByGroupId, selectChatIdByGroupId, selectMembersByGroupId } from '../messaging/selectors';
import { Chat } from '../../../definitions/message';
import { CHAT_JOIN } from '../../os/analytics';
import { SkinData } from '../chat-skinning/init-state';

function* handleCreateGroup({
  payload: { vibeId, customVibeBackgroundUrl, name, userIds, theme, skipRoomCreation, isOnboarding },
}: {
  payload: CreateGroupRequest;
}) {
  try {
    const response: GroupResponse = yield call(requestAsync, () =>
      createGroupRequest({
        name,
        ...(vibeId && { vibeId }),
        ...(customVibeBackgroundUrl && { customVibeBackgroundUrl }),
        ...(userIds && { userIds }),
        ...(theme && { theme }),
        ...(skipRoomCreation && { skipRoomCreation }),
        ...(isOnboarding && { isWebOnboarding: isOnboarding }),
      })
    );

    if (response.success) {
      yield put(setCreateGroupSuccess());

      // We need to set this so that the UI knows once it's created,
      // do this first to make sure that user is sent to next screen right away once group is created:
      yield put(setCreatedGroupId({ groupId: response.group.id }));

      // don't track groupd creation when handleCreateGroup triggered by os chat creation
      if (!skipRoomCreation) {
        track(CREATE_GROUP, { vibeId: customVibeBackgroundUrl ? 'Custom' : vibeId, groupId: response.group.id });
      }

      // Format and set group chat:
      const currentUserId: string = yield select(selectCurrentUserId);
      const formattedChat: GroupChatInStore = {
        ...formatGroupChatFromGroupResponse(response.group, currentUserId),
        hasMore: false,
        messages: keyMessagesById(response.group.chat.messages),
      };
      yield put(setGroupChat({ groupChat: formattedChat }));

      // Format and set users:
      if (response.group?.members?.length) {
        yield put(updateUsersFromArray({ users: response.group.members }));
      }
    }
  } catch (e) {
    log.error(e);
    yield put(setCreateGroupError({ error: 'Error creating group' }));
  }
}

function* handleUpdateGroup({
  payload: { id, updates },
}: {
  payload: {
    id: string;
    updates: {
      name?: string;
      background?: string;
      theme?: {
        isCustom?: boolean;
        primaryFont?: string;
        colors?: ThemeColors;
        skin?: SkinData;
      };
    };
  };
}) {
  try {
    const chatToUpdate: Chat = yield select(selectChatByGroupId, id);
    let chatResult = {};
    if (updates.name) {
      chatResult = { ...chatToUpdate, groupName: updates.name };
    } else if (updates.background) {
      chatResult = {
        ...chatToUpdate,
        background: { original: updates.background, thumbnail: updates.background, isProcessing: false },
      };
    } else if (updates.theme) {
      chatResult = {
        ...chatToUpdate,
        theme: {
          ...chatToUpdate.theme,
          ...getDefinedFieldsObject(updates.theme),
        },
      };
    }

    // optimistic update
    yield put(setGroupChat({ groupChat: chatResult as GroupChatInStore }));
    yield call(patchGroup, { groupId: id, updates });
  } catch (e) {
    log.error(e);
  }
}

function* handleJoinGroup({ payload: { groupId } }: { payload: { groupId: string } }) {
  try {
    const response: GroupResponse = yield call(requestAsync, () => joinGroupRequest({ groupId }));

    if (response.success) {
      const currentUserId: string = yield select(selectCurrentUserId);
      const formattedGroup = getGroupFromGroupResponse(response, currentUserId, FormatGroupErrorTypes.JOIN_GROUP_TYPE);

      // Format and set group chat:
      const formattedChat: GroupChatInStore = {
        ...formatGroupChatFromGroupResponse(response.group, currentUserId),
        hasMore: false,
        messages: keyMessagesById(response.group.chat.messages),
      };

      // Format and set users:
      if (response.group?.members?.length) {
        yield put(updateUsersFromArray({ users: response.group.members }));
      }

      yield put(setGroupChat({ groupChat: formattedChat }));
      yield put(joinGroupSuccess({ group: formattedGroup }));

      if (response.resultType === JoinGroupResultType.INVITED_USER_ADDED) {
        track(CHAT_JOIN, getGroupChatParams({ chatId: formattedGroup.chatId }));
      }
    }
  } catch (e) {
    log.error(e);
    yield put(joinGroupError({ error: 'Error joining group' }));
  }
}

const fetchElements = async (boardId: string) => {
  const elementsRef = db.collection(`boards/${boardId}/elements`);
  const snapshot = await elementsRef.get();
  const elements: Record<string, unknown>[] = [];
  snapshot.forEach((doc) => {
    elements.push({ id: doc.id, ...doc.data() });
  });
  return elements;
};

function* handleJoinGroupSuccess({ payload: { group } }: { payload: { group: Group } }) {
  try {
    const osDesktopId: string = yield select(selectCurrentOsDesktopId);

    // make sure that open chats is set in redux store before trying to open chat
    // this is to prevent opening a chat window that may already be open
    if (osDesktopId) {
      const elements: { id: string; class: string; chatId?: string; zIndex: string }[] = yield call(
        fetchElements,
        osDesktopId
      );

      const openChats: { [key: string]: { elementId: string } } = {};
      elements.forEach((element) => {
        if (element.class === elementClasses.CHAT_WINDOW && element.chatId) {
          openChats[element.chatId] = { elementId: element.id };
        }
      });

      yield put(setOpenChats({ openChats }));
    }

    yield put(openChat({ chatId: group.chatId }));
  } catch (e) {
    log.error(e);
  }
}

function* handleSetNewGroupMembers({
  payload: { groupId, newMembers },
}: {
  payload: { groupId: string; newMembers: GroupMember[] };
}) {
  // set new members to users store
  yield put(updateUsersFromArray({ users: newMembers }));

  const chat: GroupChatInStore = yield select(selectChatByGroupId, groupId);
  const currentChatMemberIds: { [key: string]: GroupChatMember } = yield select(selectMembersByGroupId, groupId);
  const newGroupMemberIds: { [key: string]: GroupChatMember } = newMembers.reduce(
    (memberIds, member) => ({
      ...memberIds,
      [member.userId]: {
        role: member.role,
      },
    }),
    currentChatMemberIds
  );

  yield put(
    setGroupChat({
      groupChat: {
        ...chat,
        chatMembers: newGroupMemberIds,
      },
    })
  );
}

function* handleAddMembersToGroup({
  payload: { groupId, userIds },
}: {
  payload: { groupId: string; userIds: string[] };
}) {
  try {
    const response: { success: boolean; members: GroupMember[] } = yield call(requestAsync, () =>
      addMembersToGroupRequest({ groupId, userIds })
    );

    if (response.success && response.members?.length) {
      track(ADD_GROUP_MEMBERS, getHubTrackParams({ count: response.members.length }));
      // Note: we don't need to set new group members to groups store here because the socket event will handle it
      yield put(updateUsersFromArray({ users: response.members }));
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleUpdateNotificationsSetting({
  payload: { groupId, userId, isNotificationsOn },
}: {
  payload: { groupId: string; userId: string; isNotificationsOn: boolean };
}) {
  try {
    const response: { success: boolean } = yield call(requestAsync, () =>
      patchGroupNotifications({ groupId, userId, isNotificationsOn })
    );

    if (response.success) {
      const chat: GroupChatInStore = yield select(selectChatByGroupId, groupId);
      yield put(setGroupChat({ groupChat: { ...chat, isNotificationsOn } }));
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleLeaveGroup({ payload: { groupId } }: { payload: { groupId: string } }) {
  try {
    const currentUserId: string = yield select(selectCurrentUserId);
    const chatId: string = yield select((state) => selectChatIdByGroupId(state, groupId));
    const response: { success: boolean } = yield call(requestAsync, () =>
      removeMemberFromGroup({ groupId, userId: currentUserId })
    );

    if (response.success) {
      if (chatId) {
        // remove chat from list
        yield put(removeChat({ chatId }));
      }
    }
  } catch (e) {
    log.error(e);
  }
}

function* handleDeleteGroup({ payload: { groupId } }: { payload: { groupId: string } }) {
  try {
    const chatId: string = yield select((state) => selectChatIdByGroupId(state, groupId));

    if (chatId) {
      // optimistically remove chat from list
      yield put(removeChat({ chatId }));
    }

    const response: { success: boolean } = yield call(requestAsync, () => deleteGroupRequest({ groupId }));

    if (response.success) {
      yield put(deleteGroupSuccess());
    }
  } catch (e) {
    log.error(e);
    yield put(deleteGroupError({ error: 'Error deleting group' }));
  }
}

function* handleTransferRoomToGroupRequest({
  payload: { groupId, roomId },
}: {
  payload: { groupId: string; roomId: string };
}) {
  try {
    const response: GroupResponse = yield call(requestAsync, () => transferRoomToGroupApiRequest({ groupId, roomId }));

    if (response.success) {
      yield put(transferRoomToGroupSuccess({ chatId: response.group.chat.chatId, boards: response.group.boards }));
    }
  } catch (e) {
    log.error(e);
    yield put(transferRoomToGroupError({ error: 'There was an error adding room to group' }));
  }
}

function* handleDeleteRoomFromGroupRequest({
  payload: { groupId, roomId },
}: {
  payload: { groupId: string; roomId: string };
}) {
  try {
    const response: GroupResponse = yield call(requestAsync, () => deleteRoomFromGroupApiRequest({ groupId, roomId }));

    if (response.success) {
      yield put(deleteRoomFromGroupSuccess({ chatId: response.group.chat.chatId, roomId }));
    }
  } catch (e) {
    log.error(e);
    yield put(deleteRoomFromGroupError({ error: 'There was an error deleting room from group' }));
  }
}

export default function* groupsSaga() {
  yield all([
    takeEvery(createGroup, handleCreateGroup),
    // @ts-ignore
    takeEvery(requestGroupUpdate, handleUpdateGroup),
    takeEvery(joinGroup, handleJoinGroup),
    takeEvery(joinGroupSuccess, handleJoinGroupSuccess),
    takeEvery(addMembersToGroup, handleAddMembersToGroup),
    takeEvery(setNewGroupMembers, handleSetNewGroupMembers),
    takeEvery(updateNotificationsSetting, handleUpdateNotificationsSetting),
    takeEvery(leaveGroup, handleLeaveGroup),
    takeEvery(deleteGroup, handleDeleteGroup),
    takeEvery(transferRoomToGroupRequest, handleTransferRoomToGroupRequest),
    takeEvery(deleteRoomFromGroupRequest, handleDeleteRoomFromGroupRequest),
  ]);
}
