import { createAction } from '@reduxjs/toolkit';
import { GroupPermissions, SocketUpdateGroupSettingsMessage } from '../../../definitions/groups';
import { Theme } from '../../../definitions/theme';
import { PublicChat, ChatMemberRoles } from '../../../definitions/message';
import { StoreChat, StoreChatMessage, StoreChatPopupMessage } from '../../../definitions/messaging-store';

export const setActiveReceiverId = createAction<{ activeReceiverId: string | null }>('messages/setActiveReceiverId');
export const setActiveGroupChatId = createAction<{ activeGroupChatId: string | null }>('messages/setActiveGroupChatId');
export const setActiveChatId = createAction<{ activeChatId: string | null }>('messages/setActiveChatId');
export const setMessagesSidebarOpen = createAction<{ messagesSidebarOpen: boolean }>('messages/setMessagesSidebarOpen');

export const openExistingDM = createAction<{ chatId: string }>('messages/openExistingDM');
export const saveDraftMessage = createAction<{ chatId: string; draft: string }>('messages/saveDraftMessage');

export const fetchChatsList = createAction<{ isPagination?: boolean }>('messages/fetchChatList');
export const fetchChatsListSuccess = createAction<{ chats: { [key: string]: StoreChat }; hasMoreChats: boolean }>(
  'messages/fetchChatListSuccess'
);
export const fetchChat = createAction('messages/fetchChat');
export const fetchChatSuccess = createAction<{ chat: StoreChat }>('messages/fetchChatSuccess');

export const createChat = createAction('messages/createChat');
export const createChatWithoutMessage = createAction('messages/createChatWithoutMessage');
export const createChatSuccess = createAction<{ chat: StoreChat }>('messages/createChatSuccess');
export const setGroupChat = createAction<{ groupChat: StoreChat }>('messages/setGroupChat');
export const removeChat = createAction<{ chatId: string }>('messages/removeChat');

export const addPopupMessage = createAction<{ message: StoreChatPopupMessage }>('messages/addPopupMessage');
export const removePopupMessage = createAction<{ messageId: string }>('messages/removePopupMessage');
export const deletePopupMessages = createAction('messages/deletePopupMessages');
export const addActiveInviteCall = createAction<{ messageId: string; chatId: string }>('messages/addActiveInviteCall');
export const removeActiveInviteCall = createAction<{ messageId: string }>('messages/removeActiveInviteCall');
export const setInviteCallSoundActive = createAction('messages/setInviteCallSoundActive');

export const addLocalChat = createAction<{ chat: StoreChat }>('messages/addLocalChat');
export const removeLocalChat = createAction<{ chatId: string }>('messages/removeLocalChat');

export const addMessage = createAction('messages/addMessage');
export const putMessageInQueue = createAction('messages/putMessageInQueue');
export const queuedMessageSent = createAction('messages/queuedMessageSent');
export const queuedMessageError = createAction('messages/queuedMessageError');
export const setSendingQueuedMessage = createAction<{ sendingQueuedMessage: boolean }>(
  'messages/setSendingQueuedMessage'
);
export const addFailedMessage = createAction('messages/addFailedMessage');
export const resendFailedMessage = createAction<{ messageId: string }>('messages/resendFailedMessage');
export const removeFailedMessage = createAction('messages/removeFailedMessage');

export const addDMEmojiReaction = createAction('messages/addDMEmojiReaction');
export const addDMEmoteReaction = createAction('messages/addDMEmoteReaction');
export const addDMReactionSuccess = createAction<{ chatId: string; messageId: string; reactions: object[] }>(
  'messages/addDMReactionSuccess'
);

export const sendMessage = createAction('messages/sendMessage');
export const sendMessageSuccess = createAction<{ chat: StoreChat; message: StoreChatMessage }>(
  'messages/sendMessageSuccess'
);
export const deleteMessage = createAction('messages/deleteMessage');
export const deleteMessageSuccess = createAction<{ chatId: string; messageId: string; unreadCount: number }>(
  'messages/deleteMessageSuccess'
);
export const receiveNewChat = createAction('messages/receiveNewChat');
export const receiveNewChatSuccess = createAction<{ chat: StoreChat }>('messages/receiveNewChatSuccess');
export const receiveMessage = createAction('messages/receiveMessage');
export const receiveMessageSuccess = createAction<{
  chat: StoreChat;
  message: StoreChatMessage;
  unreadCount: number;
  lastReadMessageId: string;
}>('messages/receiveMessageSuccess');
export const receiveUpdatedMessage = createAction<{ chatId: string; messageId: string; message: StoreChatMessage }>(
  'messages/receiveUpdatedMessage'
);
export const resetChatUnreads = createAction<{ chatId: string }>('messages/resetChatUnreads');
export const resetChatUnreadsSuccess = createAction<{ chatId: string; unreadCount: number }>(
  'messages/resetChatUnreadsSuccess'
);
export const resetChatUnreadsError = createAction<{ error: string }>('messages/resetChatUnreadsError');
export const messagesError = createAction<{ error: string }>('messages/messagesError');

export const joinRoom = createAction('messages/joinRoom');
export const socketReconnect = createAction('messages/socketReconnect');

export const removeMember = createAction<{ chatId: string; userId: string }>('messages/removeMember');
export const removeMemberSocketEvent = createAction<{ chatId: string; userId: string }>(
  'messages/removeMemberSocketEvent'
);
export const setRemovedMember = createAction<{ chatId: string; userId: string }>('messages/setRemovedMember');

export const receiveGroupSettingsUpdated = createAction<{ socketMessage: SocketUpdateGroupSettingsMessage }>(
  'messages/groupSettingsUpdated'
);
export const setGroupSettings = createAction<{
  chatId: string;
  updates: { groupName?: string; background?: { original: string; thumbnail: string }; theme?: Theme };
}>('messages/setGroupSettings');

// public chats:

export const fetchPublicChats = createAction('messages/fetchPublicChats');
export const fetchPublicChatsSuccess = createAction<{
  publicChatIds: string[];
  publicChats: { [key: string]: PublicChat };
}>('messages/fetchPublicChatsSuccess');

export const fetchPublicChat = createAction<{ chatId: string }>('messages/fetchPublicChat');
export const fetchPublicChatSuccess = createAction<{ publicChat: PublicChat }>('messages/fetchPublicChatSuccess');

export const fetchPublicChatsError = createAction<{ error: string }>('messages/fetchPublicChatsError');

export const joinPublicChat = createAction<{ publicChat: PublicChat }>('messages/joinPublicChat');
export const setMemberRole = createAction<{ chatId: string; memberId: string; role: ChatMemberRoles }>(
  'messages/setMemberRole'
);
export const receiveMemberRoleChanged = createAction<{
  chatId: string;
  memberId: string;
  role: ChatMemberRoles;
}>('messages/receiveMemberRoleChange');
export const receiveSelfRoleChanged = createAction<{
  chatId: string;
  role: ChatMemberRoles;
  permissions: GroupPermissions;
}>('messages/receiveSelfRoleChange');

export const setChatMemberBanned = createAction<{ chatId: string; memberId: string; isBanned: boolean }>(
  'messages/setChatMemberBanned'
);

export const banMember = createAction<{ chatId: string; memberId: string }>('messages/banMember');
export const unbanMember = createAction<{ chatId: string; memberId: string }>('messages/unbanMember');
