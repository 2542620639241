import StreamConstraints from '../stream-constraints';

/* eslint-disable @typescript-eslint/lines-between-class-members */
export type UserId = string;

export enum ConnectionStatus {
  Disconnected = 'disconnected',
  Connecting = 'connecting',
  Connected = 'connected',
}

export enum SubscribeStatus {
  Off = 'voluntary_off',
  Idle = 'idle',
  Subscribing = 'subscribing',
  Subscribed = 'subscribed',
}

export enum PublisherStreamSource {
  Camera = 'camera',
  ScreenShare = 'screenshare',
}

export enum StreamType {
  Audio = 'audio',
  Video = 'video',
  Data = 'data',
}

export enum ScreenShareQuality {
  Gaming = 'Gaming',
  Presentation = 'Presentation',
  Cinema = 'Cinema',
  Standard = 'Standard',
}
export interface Stream {
  mid: string;
  mindex: number;
  type: StreamType;

  id: string;
  // FIXME: is this display property correct in this structure?
  display: string;
  feed: string;
  codec?: string;
  h264_profile?: string;
  disabled?: boolean; // FIXME: confirm
}

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX;

export enum ParticleShape {
  Star = 'star',
  RoundSquare = 'roundedSquare',
  Circle = 'circle',
  Rectangle = 'rectangle',
  Square = 'square',
}
export interface ParticleData {
  rect: [x: number, y: number, width: number, height: number];
  shapes?: ParticleShape[];
  count?: [rangeLow: number, rangeHigh: number];
  color?: Color[];
  gravity?: boolean;
}

export type OnCameraUpdateFnPrototype = (options: { isAudioOn: boolean; isVideoOn: boolean }) => void;

export enum PlayerState {
  ENDED = 'ENDED',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  CUED = 'CUED',
  UNSTARTED = 'UNSTARTED',
  BUFFERING = 'BUFFERING',
}

export interface ElementWithPlayerState {
  playerState: PlayerState;
}
export type DirectedAnimationCoords = { x: number; y: number; xDest: number; yDest: number; doesHit: boolean };
export interface RTCRtpEncodingParametersPlusFramerate extends RTCRtpEncodingParameters {
  maxFramerate: number;
}

export interface ScreensharingState {
  stream: MediaStream;
  ready: () => void;
  restart: (stream?: MediaStream) => void;
}

export enum OnlineStatus {
  Unknown = 'unknown',
  Online = 'online',
  Offline = 'offline',
}

export enum MetricRateKeys {
  NackCount = 'nackCount',
  PliCount = 'pliCount',
  FirCount = 'firCount',
  QualityBandwidth = 'qualityLimitationDurations.bandwidth',
  QualityCpu = 'qualityLimitationDurations.cpu',
}

export interface MetricRate {
  [MetricRateKeys.NackCount]: number;
  [MetricRateKeys.PliCount]: number;
  [MetricRateKeys.FirCount]: number;
  [MetricRateKeys.QualityBandwidth]: number;
  [MetricRateKeys.QualityCpu]: number;
}

export enum PublisherType {
  Camera = 'CameraPublisher',
  Screenshare = 'ScreensharePublisher',
}
export abstract class SFU {
  /**
   * Create a new instance of SFU class or return one that already exists.
   * @return A singleton instance of this class.
   */
  static getInstance: () => SFU;

  /**
   * Initialize the SFU, that means to make it ready to establish a session
   * and join the room to publish and subscribe. This method only need to be
   * called once, and following calls will be ignored.
   */
  init: () => Promise<void>;

  /**
   * README: Below are the Core properties of the SFU, that involves Session, Room,
   * Publisher, ScreenPublisher, Subscriber, Devices etc. The components help to establish
   * and maintain the Session to the WebRTC server alive. Following them you will
   * find the Plugins section, those are the components that somehow interact with
   * the Core components, mostly and ideally through the SFU, not directly. Among
   * those components there different kind of them, for example there are ones that
   * use PeerConnection handlers from Publisher and Subscribe for example and others
   * tha use features such as sending data through DataChannel. Currently the APIs for
   * one and the other are not yet specified or exemplified in 100% for one the
   * components, and because of that reason just keep this note in mind if you change,
   * add or refactor this piece of code and their relatives.
   */

  /**
   * Get the unique Session Id for this SFU instance.
   */
  readonly sessionId: string | undefined;

  /**
   * Get the stream that is being used by Publisher to send audio/video/data.
   */
  readonly localStream: MediaStream;

  /**
   * Get the stream that has been provided to ScreenPublisher to send video.
   *
   * @notice Concept of original stream for screnshare needs to be revisited.
   */
  readonly screenshareOriginalStream: MediaStream;

  /**
   * Get the servers that is being used by WebRTC Session.
   */
  readonly serverPrefix: string;

  /**
   * Get all MediaStream objects in the room.That involves Publishing mic/camera,
   * Publishing Screen, Subscribed streams.
   * @returns A list of MediaStreams
   */
  readonly activeStreams: { [key: UserId]: MediaStream };

  /**
   * Last know online status.
   */
  online: OnlineStatus;

  /**
   * Set indicates to the SFU that Audio is being captured.
   *
   * As a getter when the SFU is publishing this property will indicate that media is
   * being captured and sent, if the SFU is not publishing this property will return
   * the last know value that has been set.
   */
  isAudioOn: boolean;

  /**
   * Set indicates to the SFU that Video is being captured.
   *
   * As a getter when the SFU is publishing this property will indicate that media is
   * being captured and sent, if the SFU is not publishing this property will return
   * the last know value that has been set.
   */
  isVideoOn: boolean;

  /**
   * A list subscribed A/V MediaStreamTrack tracks.
   */
  readonly remoteTracks: MediaStreamTrack[];

  /**
   * An instance of StreamConstraints class to manipulate Local Stream constraints.
   */
  readonly constraints: StreamConstraints;

  /**
   * Indicate weather or not the user is screensharing.
   */
  readonly isScreenVideoOn: boolean;

  /**
   * Indicate the SFU is ready to start publishing, this property is set from "outside" and
   * should be the "green light" to start publishing, SFU will defer publication until this
   * condition is met.
   */
  cameraIsReadyToPublish: boolean;

  /**
   * Ideal framerate for screen sharing. This is suggested to `getDisplayMedia` and Chrome
   * will attempt to serve this rate if possible.
   */
  screenshareFramerate: number = null;

  /**
   * Forces a reconnection with the server.
   */
  reconnect: () => void;

  /**
   * A setter/getter callback when SFU is reconnecting.
   */
  onReconnecting: () => void;

  /**
   * An event called when an external media player has changed state.
   * This method is useful to adapt publishing bitrate.
   */
  onExternalMediaPlayerStateChange: (element: ElementWithPlayerState) => void;

  /**
   * Return signal strength in the form of 1 or 2 or 3 or 4 number.
   *
   * @param userId The user id you want to retrive signal strength.
   */
  getUserIdLinkQuality: (userId: UserId) => number | undefined;

  /**
   * Room, Publisher, Subscriber etc. sub APIs exposed.
   */
  startCamera: (options: { isAudioOn: boolean; isVideoOn: boolean }) => void;
  stopCamera: () => void;
  startScreenshare: (ready: () => void, restart: () => void, stream: MediaStream) => void;
  updateScreensharingQuality: (quality: ScreenShareQuality) => void;
  screensharingQuality: ScreenShareQuality;
  screensharingConstraints: { video: MediaTrackConstraints; audio?: boolean | MediaTrackConstraints };
  hangupScreenshare: () => void;
  onCameraElementReady: (streamId: string) => void;
  onCameraUpdate: OnCameraUpdateFnPrototype;
  updateLocalStreamConstraints: () => void;
  unmuteLocalAudio: () => void;
  unmuteLocalVideo: () => void;
  muteLocalAudio: () => void;
  muteLocalVideo: () => void;
  subscribeToVideo: (streamId: string) => Promise<void>;
  unsubscribeFromVideo: (streamId: string) => Promise<void>;
  onReconnectComplete: () => void;
  joinRTCRoom: (boardId: string, videoServer: string, isAudioOn: boolean, isVideoOn: boolean) => Promise<void>;
  // FIXME:
  // In practice when this method is called the SFU already released the stream,
  // it might not be required. Current implementation just logs the action.
  removeStream: (streamId: string) => void;

  /**
   * Events Plugin implementation
   */
  sendTyping: (params: { typingStatus: string; target: string }) => void;
  sendCameraHand: (x: number, y: number) => void;
  sendCursorLocation: (x: number, y: number) => void;
  sendElementMoved: (elementId: string, x: number, y: number) => void;
  sendDrawPoint: (elementId: string, lineId: string, color: Color, x: number, y: number, drawState: string) => void;
  sendElementResized: (elementId: string, x: number, y: number, w: number, h: number, time: unknown) => void;
  sendElementRotated: (elementId: string, angle: number) => void;
  sendParticleEffect: (particleData: ParticleData) => void;
  sendReaction: (reaction: unknown, x: number, y: number) => void;
  sendReactionDirected: (reaction: unknown, coords: DirectedAnimationCoords) => void;
  sendMarkupDiff: (elementId: string, diff: string) => void;
  playSynthesizerSound: (elementId: string, soundFrequency: number, play: boolean) => void;
  // FIXME: Types here please
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendPhotoBoothCountdown: (message: any, duration: number) => void;
  handleDataMessage: (params: { sender: UserId; data: { [key: string]: unknown } }) => void;
  sendVoiceControl: (voiceControlFunc: string) => void;

  /**
   * Devices Plugin implementation
   */
  readonly audioDeviceLabel: string;
  readonly videoDeviceLabel: string;
  readonly audioDeviceId: string;
  readonly videoDeviceId: string;
  getDeviceList: () => Promise<MediaDeviceInfo[]>;
  updateAudioOutput: () => void;
  restartCapture: () => void;
  updateDevices: () => Promise<void>;

  /**
   * Snapshot implementation
   */
  saveSnapshot: (userId: string) => void;
  hideSnapshot: (userId: string) => void;
  replaceWithSnapshot: (userId: string) => void;

  /**
   * Stats implementation
   */
  setMonitorInterval: (value: number) => void;
  toggleUserStats: (userId: string, elementClass: 'CameraElement' | 'ScreenshareElement') => void;
}
