import { track } from './analytics-util';
import { db } from '../firebase';
import firebaseRules from '../../../firebase.json';

export const maxRoomNameLength = 100;
const vanityUrlLengthMin = 2;
const vanityUrlLengthMax = 50;

/**
 * Checks the validity of the URL Alias
 * - allowed chars: A-z, 0-9, ~, - and _
 * - checks if dupe room url alias
 * - checks if forbidden (reserved) url alias
 *
 * @param {string} roomUrlAlias - A candidate URL alias to check
 * @returns {string} errorMessage if error, null otherwise
 */

export const ROOM_URL_ALIAS_VALID_MESSAGE = 'Looking good! This URL is available 🙂';

export async function checkUrlAlias(roomUrlAlias) {
  if (roomUrlAlias === '') {
    return 'Room URL cannot be blank.';
  }

  const errorMessage = getErrorIfUrlAliasInvalid(roomUrlAlias);
  if (errorMessage) {
    track('Room URL update failed', { reason: errorMessage, roomUrlAlias });
    return errorMessage;
  }
  if (isHereReservedRoute(roomUrlAlias) || (await isDupeRoomUrlAlias(roomUrlAlias))) {
    return 'This room URL is unavailable. Please enter a new one.';
  }
  return null;
}

function getErrorIfUrlAliasInvalid(roomUrlAlias) {
  const VALID_UNRESERVED_URL_CHARS = /^[a-zA-Z0-9~_-]+$/;
  if (roomUrlAlias.length < vanityUrlLengthMin) {
    return `Room URLs must be at least ${vanityUrlLengthMin} characters.`;
  }
  if (roomUrlAlias.length > vanityUrlLengthMax) {
    return `Room URLs cannot contain more than ${vanityUrlLengthMax} characters.`;
  }
  if (VALID_UNRESERVED_URL_CHARS.test(roomUrlAlias) !== true) {
    return 'Special characters are not allowed in room URLs.';
  }
  return null;
}

async function isDupeRoomUrlAlias(roomUrlAlias) {
  const existingRoom = await db.collection('boards').where('urlAlias', '==', roomUrlAlias).get();
  return existingRoom.size > 0;
}

export function isHereReservedRoute(roomUrlAlias) {
  const reservedRoutes = [
    'about',
    'app',
    'careers',
    'help',
    'here',
    'lobby',
    'splash-mobile',
    'support',
    'test',
    'yambo',
    'desktop',
  ];
  if ('hosting' in firebaseRules && 'redirects' in firebaseRules.hosting) {
    firebaseRules.hosting.redirects.forEach((i) => reservedRoutes.push(i.source.replace(/^\//, '')));
  }
  if ('hosting' in firebaseRules && 'rewrites' in firebaseRules.hosting) {
    firebaseRules.hosting.rewrites.forEach((i) =>
      reservedRoutes.push(i.source.replace(/^\//, '').replace(/\/[^/]*$/, ''))
    );
  }
  return reservedRoutes.includes(roomUrlAlias);
}
