import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { createFriendRequest } from '../../store/friends/api';

// components
import UserListItemLayout from '../../user-profile/UserListItemLayout.tsx';

// utils
import { track } from '../../../util/analytics-util';

// constants
import {
  FRIEND_REQUEST_SOURCES,
  FRIEND_SUGGESTION_REASONS,
  REQUEST_FRIEND,
} from '../../../constants/analytics-events/friend-events';

// icons
import AddFriendIcon from '../../../../assets/icons/add-friend.svg';
import AddFriendPending from '../../../../assets/icons/add-friend-pending.svg';
import { selectCanAddToFriend } from '../../store/friends/selectors';
import { openUserProfile } from '../../user-profile/utils';
import { EventSourceContext } from '../../common/contexts.ts';
import { nop } from '../../../util/js-util';

const suggestionReasonsToLabel = {
  CONTACT_BOOK: 'From Your Contact Book',
  DOMAIN_SUGGESTION: 'Suggested Friend',
  MUTUAL_FRIEND: 'Mutual Friend',
  SAME_SCHOOL: 'Goes to Your School',
};

const SuggestedFriendsListItem = React.forwardRef(({ suggestedFriend, disableProfileOpenClick }, ref) => {
  const {
    profile: { userId, displayName, username, avatar, backgroundPhoto, onlineStatus },
    suggestionReasons,
  } = suggestedFriend;
  const [clicked, setClicked] = useState(false);

  const canAddToFriend = useSelector((state) => selectCanAddToFriend(state, userId));
  const customSource = useContext(EventSourceContext);

  const isDisabled = !canAddToFriend || clicked;

  const addFriend = useCallback(
    async (e) => {
      e.stopPropagation();
      setClicked(true);

      const response = await createFriendRequest(userId);
      if (response && response.success) {
        track(REQUEST_FRIEND, {
          source: customSource || FRIEND_REQUEST_SOURCES.FRIEND_SUGGESTION,
          suggestionReason: suggestionReasons.map((reason) => FRIEND_SUGGESTION_REASONS[reason]),
          receiverId: userId,
        });
      }
    },
    [userId, suggestionReasons, customSource]
  );

  const userCTAsSection = useMemo(
    () => (
      <div>
        <BackgroundPhotoContainer backgroundUrl={backgroundPhoto && backgroundPhoto.thumbnail} />
        <AddFriendButton onClick={addFriend} className="here-button-regular" disabled={isDisabled}>
          {isDisabled ? <AddFriendPending width="20" /> : <AddFriendIcon width="18" />}
          {isDisabled ? 'Request Sent' : 'Add Friend'}
        </AddFriendButton>
      </div>
    ),
    [backgroundPhoto, addFriend, isDisabled]
  );

  const onClick = useCallback(() => {
    openUserProfile({ userId, isCentered: true, hasOverlay: true });
  }, [userId]);

  return (
    <Container ref={ref} onClick={disableProfileOpenClick ? nop : onClick} disableClick={disableProfileOpenClick}>
      {suggestionReasons.length && suggestionReasonsToLabel[suggestionReasons[0]] && (
        <SuggestionReason>{suggestionReasonsToLabel[suggestionReasons[0]]}</SuggestionReason>
      )}
      <UserListItemLayout
        displayName={displayName}
        username={username}
        onlineStatus={onlineStatus.status}
        avatar={avatar}
        userCTAsSection={userCTAsSection}
      />
    </Container>
  );
});

SuggestedFriendsListItem.propTypes = {
  suggestedFriend: PropTypes.shape({
    profile: PropTypes.shape({
      userId: PropTypes.string,
      username: PropTypes.string,
      avatar: PropTypes.shape({
        thumbnail: PropTypes.string,
      }),
      displayName: PropTypes.string,
      onlineStatus: PropTypes.shape({
        status: PropTypes.string,
      }),
      backgroundPhoto: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }).isRequired,
    suggestionReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
    contactBookData: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  disableProfileOpenClick: PropTypes.bool,
};

SuggestedFriendsListItem.defaultProps = {
  disableProfileOpenClick: false,
};

const Container = styled.div`
  margin-top: 2px;

  ${({ disableClick }) =>
    !disableClick &&
    `
  cursor: pointer;
  `}
`;

const SuggestionReason = styled.div`
  color: var(--secondary-foreground, white);
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
  background: var(--secondary-background, #6b1be3);
  position: absolute;
  padding: 0 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
`;

const AddFriendButton = styled.button`
  margin-right: 13px;
  width: 150px;
  height: ${({ theme }) => theme?.addFriendButtonHeight || 37}px;
  column-gap: 6px;
  opacity: 1;
  border: none;
  cursor: pointer;
`;

const BackgroundPhotoContainer = styled.div`
  opacity: 0.7;
  background: ${({ backgroundUrl }) => `url(${backgroundUrl || `images/profile-backgrounds/green.svg`})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: absolute;
  top: 0;
  right: 0;

  width: 154px;
  height: ${({ theme }) => theme?.userItemHeight || 69}px;
  z-index: -1;

  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export default SuggestedFriendsListItem;
