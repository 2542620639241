import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FontPicker from 'font-picker-react';

import Field from '../../../components/inputs/Field';
import { chatFontFamilies } from '../../../chats/input-entry/fonts';
import { ROOM_DEFAULT_FONT } from '../../../store/room/constants';

import '../../../chats/input-entry/input-entry-font-picker.less';
import { ConditionalWrapper } from '../../../components/ConditionalWrapper';

const FontSelect = ({ label, id, currentFont, defaultFont, onFontChange, dropdownDirection }) => {
  const selectedFont = useMemo(
    () => (chatFontFamilies.includes(currentFont) ? currentFont : defaultFont),
    [currentFont, defaultFont]
  );

  const onFontChangeDedupe = useCallback(
    (font) => {
      if (font?.family !== currentFont) onFontChange(font);
    },
    [currentFont, onFontChange]
  );

  return (
    <ConditionalWrapper condition={label} wrapper={(children) => <Field label={label}>{children}</Field>}>
      <FontPickerContainer
        selectedFont={selectedFont}
        defaultFont={defaultFont}
        pickerId={id}
        dropdownDirection={dropdownDirection}
        className="font-select-react"
      >
        <FontPicker
          apiKey={process.env.GOOGLE_WEB_FONTS_API_KEY}
          activeFontFamily={selectedFont}
          onChange={onFontChangeDedupe}
          families={chatFontFamilies}
          pickerId={id}
        />
      </FontPickerContainer>
    </ConditionalWrapper>
  );
};

export default FontSelect;

FontSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  onFontChange: PropTypes.func.isRequired,
  dropdownDirection: PropTypes.oneOf(['up', 'down']).isRequired,
  currentFont: PropTypes.string,
  defaultFont: PropTypes.string,
};

FontSelect.defaultProps = {
  label: '',
  currentFont: null,
  defaultFont: ROOM_DEFAULT_FONT,
};

const FontPickerContainer = styled.div`
  #font-picker-${({ pickerId }) => pickerId} {
    width: 100%;
    border-radius: 10px;
    background: var(--primary-background, white);

    .dropdown-font-family {
      font-family: '${({ selectedFont }) => selectedFont}', sans-serif !important;

      &::after {
        content: '${({ selectedFont, defaultFont }) => (selectedFont === defaultFont ? ' (Default)' : '')}';
      }
    }

    ul {
      ${({ dropdownDirection }) => {
        if (dropdownDirection === 'up') {
          return `
              bottom: 36px;
            `;
        }

        if (dropdownDirection === 'down') {
          return `
              bottom: -200px;
              top: 36px;
            `;
        }

        return '';
      }}
    }

    [id^='font-button-${({ defaultFont }) => defaultFont.toLowerCase()}'] {
      &::after {
        content: ' (Default)';
        margin-left: 4px; // Space at 'content' is getting trimmed for some reason.
      }
    }
  }
`;
