import { createAction, createReducer } from '@reduxjs/toolkit';

export const gifTypes = {
  gifs: 'gifs',
  stickers: 'stickers',
  text: 'text',
  remix: 'remix',
};

const initialState = {};
Object.values(gifTypes).forEach((type) => {
  initialState[type] = {
    isLoading: false,
    searchTerm: '',
    perPage: 10,
    results: [],
  };
});

export const getSearchTerm = (state, { type }) => state.gifSearch[type].searchTerm;
export const getIsLoading = (state, { type }) => state.gifSearch[type].isLoading;
export const getPerPage = (state, { type }) => state.gifSearch[type].perPage;
export const getResults = (state, { type }) => state.gifSearch[type].results;
export const getResultsLength = (state, { type }) => state.gifSearch[type].results.length;

export const openChatImagePopup = createAction('gifs/openChatImagePopup');
export const openToolbarImagePopup = createAction('gifs/openToolbarImagePopup');
export const searchTermChanged = createAction('gifs/searchTermChanged');
export const resultsRequested = createAction('gifs/resultsRequested');
export const moreResultsRequested = createAction('gifs/moreResultsRequested');
export const gotResults = createAction('gifs/gotResults');
export const gotMoreResults = createAction('gifs/gotMoreResults');
export const addGifRequested = createAction('gifs/addRequested');

export const gifSearchReducer = createReducer(initialState, {
  [searchTermChanged]: (state, { payload: { type, searchTerm } }) => {
    state[type].searchTerm = searchTerm;
  },
  [resultsRequested]: (state, { payload: { type } }) => {
    state[type].results = [];
    state[type].isLoading = true;
  },
  [moreResultsRequested]: (state, { payload: { type } }) => {
    state[type].isLoading = true;
  },
  [gotResults]: (state, { payload: { type, results } }) => {
    state[type].results = results;
    state[type].isLoading = false;
  },
  [gotMoreResults]: (state, { payload: { type, results } }) => {
    state[type].results = state[type].results.concat(results);
    state[type].isLoading = false;
  },
});
