export const PROFILE_CARD_HEIGHT_PX = 512;
export const PROFILE_CARD_WIDTH_PX = 300;

export const PRONOUNS = {
  he: 'he, him, his',
  she: 'she, her, hers',
  they: 'they, them, theirs',
  any: 'any',
  preferNotToSay: 'prefer not to say',
};

export const FRIEND_REQUEST_STATUS = {
  pending: 'pending',
  accepted: 'approved',
  ignored: 'ignored',
};

export const DEFAULT_REACTIONS = {
  1: '😊',
  2: '💖',
  3: '🎆',
  4: '👏',
  5: '👍',
  6: '🌈',
  7: '🍉',
  8: '👌',
  9: '🦄',
  0: '😅',
};

// adding these here for now until we move to configcat + API route
export const DEFAULT_PROFILE_EFFECTS = [
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F0D9159C6-0598-4067-8032-3A48B8DDE34C.png?alt=media&token=48189168-c996-4e73-be99-ee4583509ba1',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F0F44C120-5383-4F11-A529-CB8FD09B3319.png?alt=media&token=be9a8e64-4162-4bfe-8ead-e3d125450dbf',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F1111996975605366904.gif?alt=media&token=0ef177d4-78da-4eb9-bc5b-e3be895cc84f',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F29DC184B-314E-4C64-A4E2-0852CE5DBE59.png?alt=media&token=3e7ca41e-f3d8-4fce-9a99-0bf99fec36ce',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F3E8971CE-2EFD-4DDC-8157-C1045D85C3E0.png?alt=media&token=26508a73-c26c-448f-9aa9-b602a64c2bf3',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F491A556B-E8EF-4DB4-85AB-0A2DFC0388B7.png?alt=media&token=1c1cdaaa-b255-42a2-b9a0-0c34aec141ec',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F622FB89E-5351-41B3-877E-E5E0D3C34075.png?alt=media&token=df81e09c-0cd8-4de3-9306-9fe63b606a78',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F7A917696-DFDA-43E7-BA6B-CA77A8FCF3BA.png?alt=media&token=e5d5ecfb-f44c-4311-a265-a01b49ac1f13',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F927061782193917952.gif?alt=media&token=43c37d18-62f0-480b-a6a8-0700dd744478',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F931452307944640543.png?alt=media&token=5074eb64-9d58-45a8-b287-4578c0532960',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2F9675-pixel-teddy.png?alt=media&token=13b02c70-2d40-4e6e-a30d-d1fd0f6ff2cf',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FBE1E4CA1-DC9A-42E3-AE41-6E0D6AA0036E.png?alt=media&token=a83fee22-2acb-43fd-9ade-60277de5244e',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_13112.png?alt=media&token=16c44eb0-643f-4da3-98b1-7130ebb3db43',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_3112.png?alt=media&token=376d7819-b3cb-4359-8d25-525f3ad95382',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_67112.png?alt=media&token=f12c6ac5-d02f-4dbe-8fa8-021b58ce4e60',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_8112.png?alt=media&token=cb48fedf-bb84-4f4f-9afe-af6c5fe96eb8',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Fcorgi18.png?alt=media&token=f7ce9f55-abed-4686-9228-c3c368393d3b',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Femoji41273.png?alt=media&token=2502dabe-9bb8-444c-a72f-57d291272a2a',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Ffg_milk.png?alt=media&token=0eb9ac06-c2c6-46d1-852a-e5df1117f5cb',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Fmcteddyheart74.png?alt=media&token=906ab1fd-714d-4b7d-87b6-32c43bc3f80b',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Fpopukofukme.png?alt=media&token=417199d4-a130-428e-aeec-a7023b86e2fe',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Fqqmelohearts.png?alt=media&token=d50b6327-b2cc-45b6-b1a0-5ff0c90128b4',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Freaction_roles_954.png?alt=media&token=105f5ca9-9d1f-48c4-9fcf-28ffaae82c29',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Fspompompurincry.png?alt=media&token=b08cd914-9218-4667-a564-4673d6f6e671',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2Ftsukasablank89.png?alt=media&token=ac08382a-425a-4c9d-ba50-92ecfac64d31',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_33112.png?alt=media&token=5004b765-fd3d-4951-bcf1-b91c0a661749',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_11112.png?alt=media&token=1b756950-cb20-477b-82f9-4412d5d74e5f',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_91112.png?alt=media&token=fa5035d8-65cb-4e0f-b10d-58274aa3a771',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_93112.png?alt=media&token=51c1de80-9b16-4a20-bd66-06ef2661e294',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_43112.png?alt=media&token=1425b570-b96f-4d35-8d56-f583db5c56c7',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_34112.png?alt=media&token=6e30e951-cff2-4323-87bf-20e9cb1140e0',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_39112.png?alt=media&token=a4e6b8fb-efd5-473e-b036-9d10e5d70638',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_24112.png?alt=media&token=c39f8a3d-3e4e-4437-bc41-054f2e85efbe',
  'https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/emotes%2FprofileEffectPresets%2FMeme_97112.png?alt=media&token=3dbd3c39-b4a7-4ae8-b539-a8cb893d4305',
];
