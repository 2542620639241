// TODO consider switching to a strategy like this for rotated rects? http://jsfiddle.net/365eX/5/

import { rectIntersects } from '../util';

function rectIntersectsBarriers(barriers, rect) {
  // eslint-disable-next-line no-restricted-syntax
  for (const barrier of barriers) {
    if (rectIntersects(rect, barrier)) {
      return true;
    }
  }
  return false;
}

// Align the X coordinate right up against any barriers
function getAdjustedX(barriers, rect, movingRight) {
  let xPos = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const barrier of barriers) {
    if (rectIntersects(rect, barrier)) {
      const barrierW = parseFloat(barrier.w);
      if (movingRight && rect.x < barrier.x && rect.x + rect.w >= barrier.x) {
        if (xPos === null) {
          xPos = barrier.x - rect.w - 1;
        } else {
          xPos = Math.min(xPos, barrier.x - rect.w) - 1;
        }
      } else if (!movingRight && rect.x <= barrier.x + barrierW) {
        if (xPos === null) {
          xPos = barrier.x + barrierW + 1;
        } else {
          xPos = Math.max(xPos, barrier.x + barrierW) + 1;
        }
      }
    }
  }
  return xPos;
}

// Align the Y coordinate right up against any barriers
function getAdjustedY(barriers, rect, movingDown) {
  let yPos = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const barrier of barriers) {
    if (rectIntersects(rect, barrier)) {
      const barrierH = parseFloat(barrier.h);
      if (movingDown && rect.y < barrier.y && rect.y + rect.h >= barrier.y) {
        if (yPos === null) {
          yPos = barrier.y - rect.h - 1;
        } else {
          yPos = Math.min(yPos, barrier.y - rect.h) - 1;
        }
      } else if (!movingDown && rect.y <= barrier.y + barrierH) {
        if (yPos === null) {
          yPos = barrier.y + barrierH + 1;
        } else {
          yPos = Math.max(yPos, barrier.y + barrierH) + 1;
        }
      }
    }
  }
  return yPos;
}

function lineIntersectsRect(x1, y1, x2, y2, barrier) {
  const minX = parseFloat(barrier.x);
  const minY = parseFloat(barrier.y);
  const maxX = minX + parseFloat(barrier.w);
  const maxY = minY + parseFloat(barrier.h);
  // Completely outside.
  if (
    (x1 <= minX && x2 <= minX) ||
    (y1 <= minY && y2 <= minY) ||
    (x1 >= maxX && x2 >= maxX) ||
    (y1 >= maxY && y2 >= maxY)
  )
    return false;

  const m = (y2 - y1) / (x2 - x1);

  let y = m * (minX - x1) + y1;
  if (y > minY && y < maxY) return true;

  y = m * (maxX - x1) + y1;
  if (y > minY && y < maxY) return true;

  let x = (minY - y1) / m + x1;
  if (x > minX && x < maxX) return true;

  x = (maxY - y1) / m + x1;
  if (x > minX && x < maxX) return true;

  return false;
}

export function checkBarriers(oldPos, newPos) {
  const barriers = Object.values(window.elementHandlers).filter((h) => h.constructor.elementType === 'BarrierElement');

  // First, check basic intersection
  if (!rectIntersectsBarriers(barriers, newPos)) {
    // If the destination is farther than the size of the object away,
    // Test for intersection along the path
    const distance = Math.sqrt(
      (oldPos.x - newPos.x) * (oldPos.x - newPos.x) + (oldPos.y - newPos.y) * (oldPos.y - newPos.y)
    );

    if (distance > newPos.w) {
      // eslint-disable-next-line no-restricted-syntax
      for (const barrier of barriers) {
        // Cast lines from each corner of the old and new rect and make sure they don't pass through
        // any barriers
        if (
          lineIntersectsRect(oldPos.x, oldPos.y, newPos.x, newPos.y, barrier) ||
          lineIntersectsRect(oldPos.x + oldPos.w, oldPos.y, newPos.x + newPos.w, newPos.y, barrier) ||
          lineIntersectsRect(
            oldPos.x + oldPos.w,
            oldPos.y + oldPos.h,
            newPos.x + newPos.w,
            newPos.y + newPos.h,
            barrier
          ) ||
          lineIntersectsRect(oldPos.x, oldPos.y + newPos.h, newPos.x, newPos.y + newPos.h, barrier)
        )
          return oldPos;
      }
    }
    return newPos;
  }

  // Try and isolate the axis on which the user is blocked so they can "slide"
  if (newPos.x !== oldPos.x) {
    const pos = { x: oldPos.x, y: newPos.y, w: newPos.w, h: newPos.h };
    if (!rectIntersectsBarriers(barriers, pos)) {
      if (Math.abs(oldPos.x - newPos.x) < newPos.w) {
        const updatedX = getAdjustedX(barriers, newPos, newPos.x > oldPos.x);
        if (updatedX !== null) {
          pos.x = updatedX;
        }
      }
      return pos;
    }
  }

  if (newPos.y !== oldPos.y) {
    const pos = { x: newPos.x, y: oldPos.y, w: newPos.w, h: newPos.h };
    if (!rectIntersectsBarriers(barriers, pos)) {
      if (Math.abs(oldPos.y - newPos.y) < newPos.h) {
        const updatedY = getAdjustedY(barriers, newPos, newPos.y > oldPos.y);
        if (updatedY !== null) {
          pos.y = updatedY;
        }
      }
      return pos;
    }
  }

  // You're totally blocked. But before we give up, check to see if you're stuck inside a barrier, and if so, just let you out
  if (rectIntersectsBarriers(barriers, oldPos)) {
    return newPos;
  }
  return oldPos;
}
