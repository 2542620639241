import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { goNextScreen } from '../../store/signing-in/actions';

// components
import { ContainerDefault, NavButtonsContainer, NextNavButton, ScreenTitle, TitleContainer } from '../shared-styled';

const PasswordWasResetScreen = () => {
  const dispatch = useDispatch();

  const onNextClick = () => {
    dispatch(goNextScreen({ screenData: {} }));
  };

  return (
    <ContainerDefault>
      <TitleContainer>
        <ScreenTitle>Recovery Email Sent</ScreenTitle>
      </TitleContainer>

      <LogoContainer>
        <LogoImg className="recovery-email-image" src="/images/misc/spinning-email.gif" alt="logo" />
      </LogoContainer>

      <DescriptionText>
        Please check your email and follow the <br /> instructions to reset your password.
      </DescriptionText>

      <PasswordWasResetScreenNavButtonsContainer>
        <PasswordWasResetScreenNextNavButton type="submit" onClick={onNextClick}>
          OK
        </PasswordWasResetScreenNextNavButton>
      </PasswordWasResetScreenNavButtonsContainer>
    </ContainerDefault>
  );
};

const LogoContainer = styled.div`
  margin-top: 37px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LogoImg = styled.img`
  height: 169px;
  display: inline-block;
`;

const DescriptionText = styled.p`
  margin-top: 29px;
  color: #12002daf;
  font-size: 16px;
  text-align: center;
`;

const PasswordWasResetScreenNavButtonsContainer = styled(NavButtonsContainer)`
  margin-top: 34px;
  display: flex;
  justify-content: center;
`;

const PasswordWasResetScreenNextNavButton = styled(NextNavButton)`
  width: 220px;
  flex-grow: 0;
`;

export default PasswordWasResetScreen;
