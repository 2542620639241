import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import { useUserProfileData } from '../../hooks/useUserProfileData';
import { CloseIcon } from '../../icons';
import UserAvatar from '../../components/UserAvatar';
import { ellipsisTextMixin, svgColorMixin } from '../../mixins';
import AcceptFriendIcon from '../../../../assets/icons/add-friend-accept.svg';

const notificationColor = '#6a2cdb';

const OutgoingFriendRequestAcceptedPopover = ({ userId, onClose }) => {
  const { userProfile } = useUserProfileData(userId);

  return userProfile && userProfile.displayName ? (
    <Popover.Root open>
      <Anchor />
      <Content>
        <Avatar>
          <UserAvatar userProfile={userProfile} />
        </Avatar>
        <Icon>
          <AcceptFriendIcon width="20" />
        </Icon>
        <UserName>{userProfile.displayName} accepted!</UserName>
        <Arrow offset={16} />
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </Content>
    </Popover.Root>
  ) : null;
};

OutgoingFriendRequestAcceptedPopover.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

OutgoingFriendRequestAcceptedPopover.defaultProps = {
  onClose: () => {},
};

export default OutgoingFriendRequestAcceptedPopover;

const background = 'white';
const height = 40;

const Anchor = styled(Popover.Anchor)`
  position: fixed;
  top: 57px;
  right: 14px;
`;

const scaleInAnimation = keyframes({
  '0%': { opacity: 0, transform: 'scale(0)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
});
const Content = styled(Popover.Content)`
  max-width: 300px;
  display: flex;
  align-items: center;
  background: ${background};
  height: ${height}px;
  border-radius: ${height / 2}px;
  padding: 0 12px;
  transform-origin: var(--radix-popover-content-transform-origin);
  animation: ${scaleInAnimation} 0.3s ease-out forwards;
`;

const Arrow = styled(Popover.Arrow)`
  fill: ${background};
`;

const Avatar = styled.div`
  display: inline-block;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
`;

const UserName = styled.div`
  color: ${notificationColor};
  ${ellipsisTextMixin({ fullWidth: false })}
`;

const Icon = styled.div`
  margin: 0 8px 4px;
  flex-shrink: 0;

  ${svgColorMixin(notificationColor)}
`;

const CloseButton = styled.button`
  border: none;
  background: ${background};
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  margin-left: 4px;
  outline: none;
`;
