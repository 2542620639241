import styled from 'styled-components';

export default styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: 0 2px;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: white;
  }
`;
