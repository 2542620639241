// utils
import { getFileType } from './file-util';

// constants
import {
  ALLOWED_FILE_TYPES_STRING,
  CUSTOM_SOUND_MAX_SIZE_BYTES,
  CUSTOM_SOUND_MAX_SIZE_MB,
  customSoundAllowedFileTypes,
} from '../constants/custom-sounds-constants';

export const checkIfAllowedCustomSoundFile = (fileType: string) => customSoundAllowedFileTypes.includes(fileType);

export const validateCustomSoundFile = (fileToUpload: File, onError: (message: string) => void) => {
  if (!fileToUpload) {
    onError('No file selected');
    return null;
  }

  const fileType = getFileType(fileToUpload);
  if (!fileType) {
    onError('Invalid file type');
    return null;
  }

  if (!checkIfAllowedCustomSoundFile(fileType.toLowerCase())) {
    onError(`Invalid file type. Only ${ALLOWED_FILE_TYPES_STRING}, etc are allowed`);
    return null;
  }

  if (fileToUpload.size > CUSTOM_SOUND_MAX_SIZE_BYTES) {
    onError(`File size is too big. Max size is ${CUSTOM_SOUND_MAX_SIZE_MB}MB`);
    return null;
  }

  return fileToUpload;
};
