// TODO: handling element position should be done from one place. Maybe having a base class
// of screen element and storing list of these classes will help with this and other issues.
import { clamp, htmlToElement } from './util';
import { getElementPosition } from './element-transform';
import { updateCanvasPosition } from './viewport';
import log from './log';
import '../styles/wayfinders.less';
import { PlayerStates } from './util/media-util';
import { canvasToScreenCoords } from './util/canvas-util';

let wayfinders = {};

export function updateWayfinders() {
  Object.keys(wayfinders).forEach((wayfinderId) => {
    if (!document.getElementById(wayfinderId)) {
      removeWayfinder(wayfinderId);
    }
  });

  const elHandlers = [];
  const cameraHandlers = Object.values(window.elementHandlers).filter(
    (h) => h.constructor.elementType === 'CameraElement'
  );

  cameraHandlers.forEach((el) => {
    elHandlers.push(el);
  });

  const mediaPlayerElHandlers = Object.values(window.elementHandlers).filter(
    (mp) => mp.constructor.elementType === 'MediaPlayerElement'
  );

  const timerElHandlers = Object.values(window.elementHandlers).filter(
    (t) => t.constructor.elementType === 'TimerElement'
  );

  // For legacy YT Players
  const youtubePlayerElHandlers = Object.values(window.elementHandlers).filter(
    (yt) => yt.constructor.elementType === 'YoutubeElement'
  );

  youtubePlayerElHandlers.forEach((handler) => {
    mediaPlayerElHandlers.push(handler);
  });

  timerElHandlers.forEach((el) => {
    if (el.timerDone) {
      elHandlers.push(el);
    } else {
      const timerId = `element-${el.elementId}`;
      removeWayfinder(timerId);
    }
  });

  mediaPlayerElHandlers.forEach((el) => {
    const id = el.elementId;
    const { player } = el;
    const wfId = `element-${id}`;
    if (player && el.playerState === PlayerStates.PLAYING) {
      if (wayfinders[wfId]) {
        const title = wayfinders[wfId].getAttribute('waytitle');
        if (el.title !== title) removeWayfinder(wfId);
      }
      elHandlers.push(el);
    } else {
      removeWayfinder(wfId);
    }
  });

  elHandlers.forEach((handler) => {
    const element = document.getElementById(`element-${handler.elementId}`);
    if (!element) {
      log.warn('No element for handler', handler.elementId);
      return;
    }

    if (element.classList.contains('screenshareElement')) {
      return;
    }

    const [x, y] = getElementPosition(element);
    const ow = parseFloat(element.style.width);
    const oh = parseFloat(element.style.height);
    const w = ow * window.canvasScale;
    const h = oh * window.canvasScale;

    const sxy = canvasToScreenCoords(x, y);

    // Look for any out-of-bounds cameras
    if (sxy[0] + w >= 0 && sxy[1] + h >= 0 && sxy[0] <= window.innerWidth && sxy[1] <= window.innerHeight) {
      if (wayfinders[element.id]) {
        wayfinders[element.id].parentNode.removeChild(wayfinders[element.id]);
        delete wayfinders[element.id];
      }

      return;
    }

    const elX = sxy[0] + w / 2;
    const elY = sxy[1] + h / 2;
    const screenX = window.innerWidth / 2;
    const screenY = window.innerHeight / 2;
    const distX = Math.abs(elX - screenX);
    const distY = Math.abs(elY - screenY);

    let wayX;
    let wayY;

    const angleRad = Math.atan2(elY - screenY, elX - screenX) + Math.PI / 2;
    const angle = (angleRad * 180) / Math.PI;

    // Figure out wayfinder position on screen by interpolating along the line
    // between center of screen and center of camera
    if (elX < 0) {
      const proportion = (distX - screenX) / distX;
      wayY = elY + proportion * (distY / distX);
      wayY = clamp(wayY, 10, window.innerHeight - 10 - 90);
      wayX = 20;
    } else if (elX > window.innerWidth) {
      const proportion = (distX - screenX) / distX;
      wayY = elY + proportion * (distY / distX);
      wayY = clamp(wayY, 10, window.innerHeight - 10 - 90);
      wayX = window.innerWidth - 10 - 90;
    } else if (elY < 0 || elY > window.innerHeight) {
      const proportion = (distY - screenY) / distY;
      wayX = elX + proportion * (distX / distY);
      wayX = clamp(wayX, 10, window.innerWidth - 10 - 90);
      wayY = elY < 0 ? 10 : window.innerHeight - 10 - 90;
    }

    let style = '';
    let wayfinderIcon = '';
    if (handler.constructor.elementType === 'MediaPlayerElement') {
      style = 'style="background-color: #6B1BE3;"';
      wayfinderIcon = `
      <div class='bars'>
        <div class='bar'></div>
        <div class='bar'></div>
        <div class='bar'></div>
      </div>
    `;
    }
    if (handler.constructor.elementType === 'TimerElement') {
      style = 'style="background-color: #F6335D; display: flex; justify-content: center;"';
      wayfinderIcon = `<img style="width: 23px" src="images/icons/timer.svg" />`;
    }
    if (!wayfinders[element.id]) {
      const wayfinder = htmlToElement(`
        <div
          class="wayfinder"
          ${handler.userId !== null && handler.userId !== undefined ? `` : `waytitle="${handler.title}"`}
          uk-tooltip="title: ${
            handler.userId !== null && handler.userId !== undefined
              ? `<here-user-name userId='${handler.userId}' />`
              : handler.title
          }">
          <svg id="wf-${element.id}">
            <g fill="#333333">
              <path d="M40 3 Q43 13.6 60 36 A25.6 25.6 0 1 1 20 36 Q37 13.6 40 3z" />
            </g>
          </svg>
          <div class="presence-icon wayfinder-icon" ${style}>
          ${
            handler.userId !== null && handler.userId !== undefined
              ? `<here-avatar userId="${handler.userId}"></here-avatar>`
              : wayfinderIcon
          }
          </div>
        </div>
      `);
      wayfinder.addEventListener('click', () => {
        const canvasX = (window.innerWidth - ow) / 2 - x;
        const canvasY = (window.innerHeight - oh) / 2 - y;
        updateCanvasPosition(canvasX, canvasY, window.canvasScale, { isAnimated: true });
      });
      document.getElementById('main').appendChild(wayfinder);
      wayfinders[element.id] = wayfinder;
    }

    wayfinders[element.id].style.transform = `translate(${wayX}px, ${wayY}px)`;
    wayfinders[element.id].querySelector('svg').style.transform = `rotate(${angle}deg)`;
  });
}

function removeWayfinder(id) {
  if (wayfinders[id] !== null && wayfinders[id] !== undefined) {
    wayfinders[id].parentNode.removeChild(wayfinders[id]);
    delete wayfinders[id];
  }
}

// Called when warping / switching rooms
export function clearAllWayfinders() {
  wayfinders = {};
}
