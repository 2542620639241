import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Board from '../board/Board';
import { ViewportControllerContext } from '../common/contexts.ts';
import { useViewportController } from '../hooks/useViewportController';
import { USER_CARD_HEIGHT_PX, USER_CARD_WIDTH_PX } from '../../constants/user-card-constants';

const cardsAnimationWaitingTimeMs = 500;

const CardView = ({ cardId, isEditMode, scale }) => {
  const containerRef = useRef(null);
  const viewportController = useViewportController({ containerRef, allowedAxis: '' });

  useEffect(() => {
    // TODO: setTimeout won't be needed once we remove the hubs lobby
    // Waiting for animation to end, then update viewport rect
    setTimeout(async () => {
      await viewportController.initializedPromise;
      viewportController.recalculateViewportSize();
      viewportController.requestViewportChange({
        coordinates: { scale, x: -USER_CARD_WIDTH_PX / 2, y: -USER_CARD_HEIGHT_PX / 2, forceRecalculate: true },
      });
    }, cardsAnimationWaitingTimeMs);
  }, [isEditMode, scale, viewportController]);

  return (
    <Container ref={containerRef}>
      <ViewportControllerContext.Provider value={viewportController}>
        <Board boardId={cardId} isEditable={isEditMode} />
      </ViewportControllerContext.Provider>
    </Container>
  );
};

export default CardView;

CardView.propTypes = {
  cardId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  scale: PropTypes.number,
};

CardView.defaultProps = {
  scale: 1,
};

const Container = styled.div`
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  z-index: 1;
`;
