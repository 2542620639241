import { authedApiInstance } from '../firebase';

export const fetchSchools = (query?: string) =>
  authedApiInstance
    .get('/schools', { params: { q: query || '' } })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const fetchSchool = (schoolId: string) =>
  authedApiInstance
    .get(`/schools/${schoolId}`)
    .then((response) => response?.data)
    .catch((error) => error?.response?.data);
