import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  fetchPublicChatSuccess,
  fetchPublicChats,
  fetchPublicChatsError,
  fetchPublicChatsSuccess,
  receiveMemberRoleChanged,
  receiveSelfRoleChanged,
  setChatMemberBanned,
  setGroupSettings,
  setRemovedMember,
} from './actions.ts';
import { deleteRoomFromGroupSuccess, transferRoomToGroupSuccess } from '../groups/actions.ts';

const initialState = {
  activeReceiverId: null,
  activeGroupChatId: null,
  activeChatId: null,
  messagesSidebarOpen: window.location.pathname === '/l',
  chats: {},
  loadingChats: false,
  localChats: {},
  popupMessages: {},
  activeInviteCalls: {},
  loadingMessages: false,
  messagesError: '',
  loadingUnreads: false,
  unreadsError: '',
  messageQueue: [],
  failedMessages: [],
  sendingQueuedMessage: false,
  hasMoreChats: false,
  // public chats related:
  publicChatIds: [],
  publicChats: {},
  loadingPublicChats: false,
  publicChatsError: '',
};

// TODO: for now, we're storing these separately but we should probably
// just store a single activeChatId for both
// For DMs:
export const setActiveReceiverId = createAction('messages/setActiveReceiverId'); //
// For Group Chats:
export const setActiveGroupChatId = createAction('messages/setActiveGroupChatId'); //

export const setActiveChatId = createAction('messages/setActiveChatId'); //

export const setMessagesSidebarOpen = createAction('messages/setMessagesSidebarOpen'); //

export const fetchChatsList = createAction('messages/fetchChatList'); //
export const fetchChatsListSuccess = createAction('messages/fetchChatListSuccess'); //

export const openExistingDM = createAction('messages/openExistingDM'); //

export const fetchChat = createAction('messages/fetchChat'); //
export const fetchChatSuccess = createAction('messages/fetchChatSuccess'); //

export const createChat = createAction('messages/createChat'); //
export const createChatWithoutMessage = createAction('messages/createChatWithoutMessage'); //
export const createChatSuccess = createAction('messages/createChatSuccess'); //

export const addLocalChat = createAction('messages/addLocalChat'); //
export const removeLocalChat = createAction('messages/removeLocalChat'); //

export const addMessage = createAction('messages/addMessage'); //
export const putMessageInQueue = createAction('messages/putMessageInQueue'); //
export const queuedMessageSent = createAction('messages/queuedMessageSent'); //
export const queuedMessageError = createAction('messages/queuedMessageError'); //
export const setSendingQueuedMessage = createAction('messages/setSendingQueuedMessage'); //

export const addFailedMessage = createAction('messages/addFailedMessage'); //
export const resendFailedMessage = createAction('messages/resendFailedMessage'); //
export const removeFailedMessage = createAction('messages/removeFailedMessage'); //

export const addDMEmojiReaction = createAction('messages/addDMEmojiReaction'); //
export const addDMEmoteReaction = createAction('messages/addDMEmoteReaction'); //
export const addDMReactionSuccess = createAction('messages/addDMReactionSuccess'); //

export const saveDraftMessage = createAction('messages/saveDraftMessage'); //

export const sendMessage = createAction('messages/sendMessage'); //
export const sendMessageSuccess = createAction('messages/sendMessageSuccess'); //

export const deleteMessage = createAction('messages/deleteMessage'); //
export const deleteMessageSuccess = createAction('messages/deleteMessageSuccess', (payload) => ({ payload })); //

export const receiveNewChat = createAction('messages/receiveNewChat', (payload) => ({ payload })); //
export const receiveNewChatSuccess = createAction('messages/receiveNewChatSuccess'); //

export const receiveMessage = createAction('messages/receiveMessage', (payload) => ({ payload })); //
export const receiveMessageSuccess = createAction('messages/receiveMessageSuccess'); //

export const receiveUpdatedMessage = createAction('messages/receiveUpdatedMessage', (payload) => ({ payload })); //

export const messagesError = createAction('messages/messagesError'); //

export const resetChatUnreads = createAction('messages/resetChatUnreads'); //
export const resetChatUnreadsSuccess = createAction('messages/resetChatUnreadsSuccess'); //
export const resetChatUnreadsError = createAction('messages/resetChatUnreadsError'); //

export const addPopupMessage = createAction('messages/addPopupMessage'); //
export const removePopupMessage = createAction('messages/removePopupMessage'); //
export const deletePopupMessages = createAction('messages/deletePopupMessages'); //

export const joinRoom = createAction('messages/joinRoom'); //

export const socketReconnect = createAction('messages/socketReconnect'); //

export const addActiveInviteCall = createAction('messages/addActiveInviteCall'); //
export const removeActiveInviteCall = createAction('messages/removeActiveInviteCall'); //
export const setInviteCallSoundActive = createAction('messages/setInviteCallSoundActive'); //

export const setGroupChat = createAction('messages/setGroupChat'); //

export const removeChat = createAction('messages/removeChat'); //

export const messagesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setActiveChatId, (state, { payload: { activeChatId } }) => {
    state.activeChatId = activeChatId;
  });
  builder.addCase(setActiveReceiverId, (state, { payload: { activeReceiverId } }) => {
    state.activeGroupChatId = null;
    state.activeReceiverId = activeReceiverId;
  });
  builder.addCase(setActiveGroupChatId, (state, { payload: { activeGroupChatId } }) => {
    state.activeReceiverId = null;
    state.activeGroupChatId = activeGroupChatId;
  });
  builder.addCase(setMessagesSidebarOpen, (state, { payload: { messagesSidebarOpen } }) => {
    state.messagesSidebarOpen = messagesSidebarOpen;
  });
  builder.addCase(fetchChatsList, (state) => {
    state.loadingMessages = true;
    state.loadingChats = true;
    state.messagesError = '';
  });
  builder.addCase(fetchChatsListSuccess, (state, { payload: { chats, hasMoreChats } }) => {
    state.loadingMessages = false;
    state.loadingChats = false;
    // Merge each chat individually to ensure no data is lost
    // We need to do this because the API does not return role in the fetchChatsList request
    // We can remove this loop once the API returns role in the fetchChatsList request
    Object.keys(chats).forEach((chatId) => {
      if (state.chats[chatId]) {
        // Merge existing chat with new chat details
        state.chats[chatId] = {
          ...state.chats[chatId],
          ...chats[chatId],
        };
      } else {
        // Add new chat if it doesn't exist
        state.chats[chatId] = chats[chatId];
      }
    });
    state.hasMoreChats = hasMoreChats;
  });
  builder.addCase(fetchChat, (state) => {
    state.loadingMessages = true;
    state.messagesError = '';
  });
  builder.addCase(fetchChatSuccess, (state, { payload: { chat } }) => {
    state.loadingMessages = false;
    state.chats = {
      ...state.chats,
      [chat.id]: chat,
    };
  });
  builder.addCase(createChat, (state) => {
    state.loadingMessages = true;
    state.messagesError = '';
  });
  builder.addCase(createChatWithoutMessage, (state) => {
    state.loadingMessages = true;
    state.messagesError = '';
  });
  builder.addCase(createChatSuccess, (state, { payload: { chat } }) => {
    state.loadingMessages = false;
    state.chats = {
      ...state.chats,
      [chat.id]: chat,
    };
  });
  builder.addCase(addLocalChat, (state, { payload: { chat } }) => {
    state.localChats[chat.id] = chat;
  });
  builder.addCase(removeLocalChat, (state, { payload: { chatId } }) => {
    delete state.localChats[chatId];
  });
  builder.addCase(sendMessage, (state) => {
    state.loadingMessages = true;
    state.messagesError = '';
  });
  builder.addCase(saveDraftMessage, (state, { payload: { chatId, draft } }) => {
    if (state.chats[chatId]) {
      state.chats[chatId].draft = draft;
    }
  });
  builder.addCase(sendMessageSuccess, (state, { payload: { chat, message } }) => {
    state.loadingMessages = false;
    state.chats = {
      ...state.chats,
      [chat.id]: {
        ...state.chats[chat.id],
        ...chat,
        chatMembers: { ...state.chats[chat.id]?.chatMembers },
        draft: '',
        messages: {
          ...(state.chats[chat.id] && state.chats[chat.id].messages),
          [message.id]: message,
        },
        permissions: state.chats[chat.id].permissions,
      },
    };

    state.sendingQueuedMessage = false;
    // Remove the oldest message from queue
    state.messageQueue.shift();
  });
  builder.addCase(deleteMessage, (state) => {
    state.loadingMessages = true;
    state.messagesError = '';
  });
  builder.addCase(deleteMessageSuccess, (state, { payload: { chatId, messageId, unreadCount } }) => {
    state.loadingMessages = false;
    delete state.chats[chatId].messages[messageId];
    state.chats[chatId].unreadCount = unreadCount;
  });
  builder.addCase(removeChat, (state, { payload: { chatId } }) => {
    delete state.chats[chatId];
  });
  builder.addCase(receiveNewChatSuccess, (state, { payload: { chat } }) => {
    state.chats = {
      ...state.chats,
      [chat.id]: chat,
    };
  });
  builder.addCase(receiveMessageSuccess, (state, { payload: { chat, message, unreadCount, lastReadMessageId } }) => {
    state.chats = {
      ...state.chats,
      [chat.id]: {
        ...state.chats[chat.id],
        ...chat,
        chatMembers: { ...state.chats[chat.id]?.chatMembers },
        messages: {
          ...(state.chats[chat.id] && state.chats[chat.id].messages),
          [message.id]: message,
        },
        unreadCount,
        lastReadMessageId,
      },
    };
  });
  builder.addCase(receiveMemberRoleChanged, (state, { payload: { chatId, memberId, role } }) => {
    state.chats[chatId].chatMembers[memberId] = { role, isBanned: state.chats[chatId].chatMembers[memberId]?.isBanned };
  });
  builder.addCase(receiveSelfRoleChanged, (state, { payload: { chatId, role, permissions } }) => {
    state.chats[chatId] = {
      ...state.chats[chatId],
      role,
      permissions,
    };
  });
  builder.addCase(setGroupChat, (state, { payload: { groupChat } }) => {
    state.chats = {
      ...state.chats,
      [groupChat.id]: {
        ...state.chats[groupChat.id],
        ...groupChat,
        messages: {
          ...(state.chats[groupChat.id] && state.chats[groupChat.id].messages),
          ...groupChat.messages,
        },
      },
    };
  });
  builder.addCase(receiveUpdatedMessage, (state, { payload: { chatId, messageId, message } }) => {
    state.chats[chatId].messages[messageId] = {
      ...state.chats[chatId].messages[messageId],
      ...message,
    };
  });
  builder.addCase(messagesError, (state, { payload: { error } }) => {
    state.loadingMessages = false;
    state.messagesError = error;
  });
  builder.addCase(resetChatUnreads, (state) => {
    state.loadingUnreads = true;
    state.resetChatUnreadsError = '';
  });
  builder.addCase(resetChatUnreadsSuccess, (state, { payload: { chatId, unreadCount } }) => {
    state.loadingUnreads = false;
    state.chats = {
      ...state.chats,
      [chatId]: {
        ...state.chats[chatId],
        unreadCount,
      },
    };
  });
  builder.addCase(addDMReactionSuccess, (state, { payload: { chatId, messageId, reactions } }) => {
    if (state.chats[chatId] && state.chats[chatId].messages[messageId]) {
      state.chats[chatId].messages[messageId].reactions = reactions;
    }
  });
  builder.addCase(resetChatUnreadsError, (state, { payload: { error } }) => {
    state.loadingUnreads = false;
    state.unreadsError = error;
  });
  builder.addCase(addPopupMessage, (state, { payload: { message } }) => {
    const { messageId, chatId } = message;

    state.popupMessages = {
      ...state.popupMessages,
      [messageId]: { messageId, chatId },
    };
  });
  builder.addCase(removePopupMessage, (state, { payload: { messageId } }) => {
    delete state.popupMessages[messageId];
  });
  builder.addCase(deletePopupMessages, (state) => {
    state.popupMessages = {};
  });
  builder.addCase(addActiveInviteCall, (state, { payload: { chatId, messageId } }) => {
    state.activeInviteCalls[messageId] = { chatId, messageId };
  });
  builder.addCase(removeActiveInviteCall, (state, { payload: { messageId } }) => {
    delete state.activeInviteCalls[messageId];
  });
  builder.addCase(putMessageInQueue, (state, { payload }) => {
    state.messageQueue.push(payload);
  });
  builder.addCase(setSendingQueuedMessage, (state, { payload: { sendingQueuedMessage } }) => {
    state.sendingQueuedMessage = sendingQueuedMessage;
  });
  builder.addCase(queuedMessageSent, (state) => {
    state.sendingQueuedMessage = false;

    // Remove the oldest message from queue
    state.messageQueue.shift();
  });
  builder.addCase(queuedMessageError, (state) => {
    state.sendingQueuedMessage = false;

    // put oldest message from the queue to the errors array
    state.failedMessages.push(state.messageQueue.shift());
  });
  builder.addCase(resendFailedMessage, (state, { payload: { messageId } }) => {
    const failedMessage = state.failedMessages.find((message) => message.id === messageId);
    if (failedMessage) {
      state.messageQueue.push(failedMessage);
      state.failedMessages = state.failedMessages.filter((message) => message.id !== messageId);
    }
  });
  builder.addCase(setRemovedMember, (state, action) => {
    if (state.chats[action.payload.chatId]) {
      delete state.chats[action.payload.chatId].chatMembers[action.payload.userId];
    }
  });
  builder.addCase(setGroupSettings, (state, action) => {
    if (!state.chats[action.payload.chatId]) {
      return;
    }
    state.chats[action.payload.chatId] = {
      ...state.chats[action.payload.chatId],
      ...action.payload.updates,
    };
  });
  builder.addCase(transferRoomToGroupSuccess, (state, action) => {
    if (state.chats[action.payload.chatId]) {
      state.chats[action.payload.chatId].boards = action.payload.boards;
    }
  });
  builder.addCase(deleteRoomFromGroupSuccess, (state, { payload }) => {
    if (state.chats[payload.chatId]) {
      const updatedBoards = state.chats[payload.chatId].boards.filter((board) => board.id !== payload.roomId);
      state.chats[payload.chatId].boards = updatedBoards;
    }
  });
  builder.addCase(fetchPublicChats, (state) => {
    state.loadingPublicChats = true;
    state.publicChatsError = '';
  });
  builder.addCase(fetchPublicChatsSuccess, (state, { payload }) => {
    state.loadingPublicChats = false;
    state.publicChatIds = payload.publicChatIds;
    state.publicChats = payload.publicChats;
  });
  builder.addCase(fetchPublicChatSuccess, (state, { payload }) => {
    state.loadingPublicChats = false;
    state.publicChats = {
      ...state.publicChats,
      [payload.publicChat.chatId]: payload.publicChat,
    };
  });
  builder.addCase(fetchPublicChatsError, (state, { payload }) => {
    state.loadingPublicChats = false;
    state.publicChatsError = payload.error;
  });
  builder.addCase(setChatMemberBanned, (state, { payload }) => {
    if (state.chats[payload.chatId] && state.chats[payload.chatId].chatMembers[payload.memberId]) {
      state.chats[payload.chatId].chatMembers[payload.memberId].isBanned = payload.isBanned;
    }
  });
});
