import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectTimeSpentByRoomId } from '../../../store/visited-rooms/selectors';
import { RootState } from '../../../../definitions/store';

// components
import RoomItemWithOnScreen from './RoomItemWithOnScreen';
import RoomItemWithMessagePreview from './RoomItemWithMessagePreview';
import {
  RoomItemStats,
  RoomListItemIconsContainer,
  RoomListItemPrimaryTitle,
  RoomListItemSecondaryTitle,
  TownSquareLabel,
} from '../../shared-styles';
import RoomStreak from './components/RoomStreak';
import { ellipsisTextMixin } from '../../../mixins';
import RoomTimeSpent from './components/RoomTimeSpent';
import RoomItemContextMenu from './RoomItemContextMenu';

// utils
import { getShowLobbyRoomStreak } from '../../../../util/room-streak-util';
import { getDisplayMinutesFromSecs } from '../../../../util/time-util';

interface MainRoomItemProps {
  type: 'primary' | 'secondary';

  roomData: {
    id: string;
    title: string;
    creator: string;
    background: string;
    backgroundColor: string;
    urlAlias?: string;
    members: object[];
    isHereClub?: boolean;
    roomStreak?: number;
    streakExpiresAt?: string;
  };
}

const MainRoomItem = ({ type, roomData }: MainRoomItemProps) => {
  const timeSpent = useSelector((state: RootState) => selectTimeSpentByRoomId(state, roomData.id));
  const timeSpentInMins = timeSpent ? getDisplayMinutesFromSecs(timeSpent) : '0';

  const title = useMemo(
    () => (
      <>
        <TitleContainer>
          {type === 'primary' ? (
            // the 'title'-attr is here for a native HTML tooltip (to let users see the full title if it's cut off)
            <RoomListItemPrimaryTitle title={roomData.title}>{roomData.title}</RoomListItemPrimaryTitle>
          ) : (
            <RoomListItemSecondaryTitle title={roomData.title}>{roomData.title}</RoomListItemSecondaryTitle>
          )}

          {roomData.isHereClub ? <TownSquareLabel>Official</TownSquareLabel> : null}
        </TitleContainer>

        <RoomListItemIconsContainer>
          <RoomItemContextMenu roomData={roomData} />
        </RoomListItemIconsContainer>
      </>
    ),
    [type, roomData]
  );

  const roomStreak = useMemo(
    () =>
      getShowLobbyRoomStreak(roomData.roomStreak) ? (
        <RoomStreak streak={roomData.roomStreak} streakExpiresAt={roomData.streakExpiresAt} />
      ) : null,
    [roomData.roomStreak, roomData.streakExpiresAt]
  );

  const roomTimespent = useMemo(
    () => (timeSpentInMins > '0' ? <RoomTimeSpent timeSpentInMins={timeSpentInMins} /> : null),
    [timeSpentInMins]
  );

  return (
    <RoomItemWithOnScreen roomId={roomData.id}>
      <RoomItemStats>
        {roomTimespent}
        {roomStreak}
      </RoomItemStats>
      <RoomItemWithMessagePreview roomData={roomData} title={title} />
    </RoomItemWithOnScreen>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  ${ellipsisTextMixin({ fullWidth: false })}
`;

export default MainRoomItem;
