import React from 'react';
import styled from 'styled-components';

import FadeIn from './animate/FadeIn';
import { nop } from '../../util/js-util';

interface Props {
  children: React.ReactNode;
  onOverlayClick?: () => void;
  modalWidth?: string;
}

const HereModal: React.FC<Props> = ({ children, onOverlayClick, modalWidth }) => (
  <>
    <Overlay onClick={onOverlayClick || nop} />
    <FadeIn>
      <Modal width={modalWidth}>{children}</Modal>
    </FadeIn>
  </>
);

export default HereModal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.3);
`;

const Modal = styled.div<{ width?: string }>`
  z-index: 100001;
  border-radius: 20px;
  background-color: #ffffff;
  justify-content: center;
  position: fixed;
  width: ${({ width }) => width || '500px'};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;
