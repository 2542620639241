import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// TODO: Add validation errors showing.
const Field = ({ label, children }) => (
  <Container>
    <LabelText>{label}</LabelText>
    <FieldContainer>{children}</FieldContainer>
  </Container>
);

export default Field;

Field.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Container = styled.label`
  display: block;
`;

const LabelText = styled.div`
  font-size: 12px;
  margin-bottom: 2px;
  color: var(--primary-foreground, black);
`;

const FieldContainer = styled.div`
  width: 100%;
`;
