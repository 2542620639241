import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { disableEverything, enableElements, enableEverything } from '../../../onboarding';

// store
import { goNextScreen } from '../../store/signing-in/actions';

// components
import Portal from '../../components/Portal';
import Hint from '../hints/Hint';
import { HintDescription, HintTitle } from '../hints/shared-styles';

const HighlightMediaPlayerButtonScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    disableEverything();
    enableElements('.new-media-player-button');
    enableElements('.menu-button.etc-button'); // endble the '+' button in the toolbar on narrow screens
    document.querySelector('.menu-button.etc-button').classList.add('highlight-round-pulse'); // add pulsation to the '+' button in the toolbar on narrow screens
    document.querySelector('.etc-tools-grid .new-media-player-button').classList.add('highlight-round-pulse'); // add pulsation to the media player button in the etc popups in the toolbar on narrow screenss
    document.querySelector('#enabled-menu-buttons .new-media-player-button').classList.add('highlight-round-pulse');

    const onMediaPlayerButtonClick = () => {
      dispatch(goNextScreen({}));
    };

    const mediaPlayerButtons = document.querySelectorAll('.new-media-player-button');
    mediaPlayerButtons.forEach((button) => {
      button.addEventListener('click', onMediaPlayerButtonClick);
    });

    return () => {
      enableEverything();
      document.querySelector('.menu-button.etc-button').classList.remove('highlight-round-pulse');
      document.querySelector('.etc-tools-grid .new-media-player-button').classList.remove('highlight-round-pulse');
      document
        .querySelector('#enabled-menu-buttons .new-media-player-button')
        .classList.remove('highlight-round-pulse');

      mediaPlayerButtons.forEach((button) => {
        button.removeEventListener('click', onMediaPlayerButtonClick);
      });
    };
  }, [dispatch]);

  return (
    <>
      <Portal root={document.getElementById('media-player-onboarding-hint-container')}>
        <MediaPlayerHintPositionContainer>
          <Hint>
            <HintTitle>Let’s add a video!</HintTitle>
            <HintDescription>
              Select the player from the toolbar. <br /> You can explore the other tools later.
            </HintDescription>
          </Hint>
        </MediaPlayerHintPositionContainer>
      </Portal>

      <Portal root={document.getElementById('media-player-onboarding-hint-container')}>
        <MediaPlayerArrowPositionContainer>
          <ArrowImage src="gif/onboarding-arrow.gif" />
        </MediaPlayerArrowPositionContainer>
      </Portal>
    </>
  );
};

const arrowImageWidthPx = 125;

const MediaPlayerHintPositionContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 140px;
  left: 0;
  margin: auto;
  width: fit-content;
`;

const MediaPlayerArrowPositionContainer = styled.div`
  position: absolute;
  bottom: 25px;
  margin-left: -140px;
`;

const ArrowImage = styled.img`
  width: ${arrowImageWidthPx}px;
  grid-row: 2;
  transform: rotate(180deg);
`;

export default HighlightMediaPlayerButtonScreen;
