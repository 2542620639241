import eventBus, { socketConnected } from '../event-bus';
import firebase, { api, authedApiInstance } from '../firebase';
import log from '../log';
import { checkIsElectron } from '../util/platform-util';
import { isHereEmployee } from '../util/user-util';

let authCodeRef = null;

export async function startAuth() {
  authCodeRef = await firebase.database().ref('/desktopAuthCodes').push();
  await authCodeRef.set({
    createdAt: firebase.database.ServerValue.TIMESTAMP,
  });

  authCodeRef.on('value', async (data) => {
    if (data.val().authToken) {
      await firebase.auth().signInWithCustomToken(data.val().authToken);
    } else {
      log.warn(
        `Received update for desktupAuthCode without an auth token ${authCodeRef.id}: ${JSON.stringify(data.val())}`
      );
    }
  });

  window.open(`/desktop-auth?authCode=${authCodeRef.key}`, '_blank');
}

export function shouldUseCustomAudioMixer() {
  // We only enable AudioMixer for Electron/Mac because enabling it for Electron/Win will
  // cause the screenshare audio (only supported in Win so far) to produce echo from
  // incomming audio streams. TODO: revisit upcomming weeks.
  return false;
  // return checkIsElectron() && isMacOS();
}

async function getAuthToken() {
  if (window.bridge) {
    const { currentUser } = firebase.auth();

    if (currentUser) {
      window.bridge.send({ event: 'authToken', token: await currentUser.getIdToken() });
    } else {
      window.bridge.send({ event: 'authToken', token: null });
    }
  }
}

export async function startWebSocket(onMessageCallback) {
  if (window.bridge) {
    const response = await authedApiInstance.get('/user/customToken');

    if (response && response.data) {
      if (window.bridge.startWebSocket) {
        const firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG);
        const onConnectCallback = () => {
          eventBus.dispatch(socketConnected);
        };
        window.bridge.startWebSocket(onMessageCallback, onConnectCallback, firebaseConfig, response.data.token, api);
        /* TODO: checkForUpdates throwing exceptions, put this back when fixed */
        // } else {
        // window.bridge.checkForUpdates();
      }
    }
  }
}

export function emitSocketEvent(event, data) {
  if (window.bridge) {
    window.bridge.emitSocketEvent(event, data);
  }
}

export async function getRoomWindowMediaSource() {
  return window.bridge.getRoomWindowMediaSource();
}

export function loadRoom(boardId, boardElementId) {
  if (window.bridge) {
    window.bridge.send({ event: 'loadRoom', boardId, boardElementId });
  }
}

export function showElectronTemplatePicker() {
  if (window.bridge) {
    window.bridge.send({ event: 'showTemplatePicker' });
  }
}

export function hideElectronTemplatePicker() {
  if (window.bridge) {
    window.bridge.send({ event: 'hideTemplatePicker' });
  }
}

export function exitApp() {
  if (window.bridge) {
    window.bridge.send({ event: 'exitApp' });
  }
}

export function closeFocusedWindow() {
  if (window.bridge) {
    window.bridge.send({ event: 'closeFocusedWindow' });
  }
}

export function resizeFocusedWindow() {
  if (window.bridge) {
    window.bridge.send({ event: 'resizeFocusedWindow' });
  }
}

export function hideFocusedWindow() {
  if (window.bridge) {
    window.bridge.send({ event: 'hideFocusedWindow' });
  }
}

export function openLobby(activityId, groupId) {
  if (window.bridge) {
    window.bridge.send({ event: 'openCandybar', activityId, groupId });
  }
}

export function setBadge(badge) {
  if (window.bridge) {
    window.bridge.send({ event: 'setBadge', badge });
  }
}

// TODO: delete ?
export function closeRoom() {
  if (window.bridge) {
    window.bridge.send({ event: 'closeRoom' });
  }
}

export async function startElectronBridge() {
  if (window.bridge) {
    window.bridge.onMessage((message) => {
      switch (message.event) {
        case 'get_auth_token': {
          getAuthToken();
          break;
        }
        case 'socket_connect': {
          eventBus.dispatch(socketConnected);
          break;
        }
        default: {
          log.warn(`Unknown Electron event ${message.event}`);
        }
      }
    });
  }
}

export async function getAppVersion() {
  if (window.bridge) {
    return window.bridge.getAppVersion();
  }

  log.error('Cannot get App Version from Electron, not in the electron app');
  return null;
}

export function getIsProduction() {
  if (window.bridge) {
    if (window.bridge.getIsProduction) {
      return window.bridge.getIsProduction();
    }
    // return true if getIsProduction does not yet exist on bridge for older builds
    return true;
  }

  log.error('Cannot get isProduction from Electron, not in the electron app');
  return null;
}

export function getNavigationSetting() {
  if (window.bridge) {
    if (window.bridge.getNavigationSetting) {
      return window.bridge.getNavigationSetting();
    }
    // return multi window if function getNavigationSetting does not yet exist on birdge for older builds
    return 'multi window';
  }

  log.error('Cannot get navigation setting from Electron, not in the electron app');
  return null;
}

export function hideWindowButtons() {
  if (window.bridge?.hideWindowButtons) {
    window.bridge.hideWindowButtons();
  }

  log.error('Cannot call hideWindowButtons from Electron, not in the electron app');
}

export function checkForUpdates() {
  if (window.bridge) {
    window.bridge.checkForUpdates();
  }

  log.error('Cannot check for version updates, not in the electron app');
}

function getOSType() {
  if (window.bridge) {
    return window.bridge.getOSType();
  }

  log.error('Cannot get OS type from Electron, not in the electron app');
  return null;
}

export function isWindowsOS() {
  const osType = getOSType();
  if (osType) {
    return osType.toLowerCase().startsWith('win');
  }

  return false;
}

export function isMacOS() {
  const osType = getOSType();
  if (osType) {
    return osType.toLowerCase() === 'darwin';
  }

  return false;
}

async function checkVersionGreaterThan(major, minor, micro) {
  const version = await getAppVersion();
  const parts = version.split('.');
  if (parts.length > 2 && (parts[2].includes('alpha') || parts[2].includes('beta'))) {
    return true;
  }
  parts[0] = parts[0].replace('v', '');
  return parseInt(parts[0], 10) > major || parseInt(parts[1], 10) > minor || parseInt(parts[2], 10) > micro;
}

export async function supportsElectronScreenshot() {
  return checkVersionGreaterThan(1, 0, 6);
}

export async function supportsNewLobby() {
  const isProd = await getIsProduction();

  // eslint-disable-next-line no-return-await
  return !isProd || (await checkVersionGreaterThan(1, 0, 8));
}

export function isLatestScreenshareAPIAvailable() {
  return !!window.bridge?.setScreenshareSourceHandler;
}

export function startScreenshare() {
  window.bridge.startScreenshare();
}

export async function getScreenshareSources() {
  return window.bridge.getScreenshareSources();
}

export async function setScreenshareSourceHandler(source) {
  return window.bridge.setScreenshareSourceHandler(source);
}

export function onScreenshareSourcesReady(fn) {
  window.bridge.onScreenshareSourcesReady(fn);
}

export async function checkScreenAccessPermission() {
  return window.bridge.checkScreenAccessPermission();
}

export const ELECTRON_WINDOWS = {
  LOBBY: 'lobby',
  TEMPLATE_PICKER: 'template picker',
  ROOM: 'room',
};

const HREF_TO_WINDOW = {
  '/electron-candybar': ELECTRON_WINDOWS.LOBBY,
  '/electron-template-picker': ELECTRON_WINDOWS.TEMPLATE_PICKER,
};

export function getCurrentElectronWindow() {
  const { pathname } = window.location;

  if (HREF_TO_WINDOW[pathname]) {
    return HREF_TO_WINDOW[pathname];
  }
  return ELECTRON_WINDOWS.ROOM;
}

firebase.auth().onAuthStateChanged(async (user) => {
  if (window.bridge) {
    window.bridge.send({
      event: 'authChanged',
      isLoggedIn: !!user,
      isHereEmployee: await isHereEmployee(user),
      token: user ? await user.getIdToken() : null,
    });
  }
});
