import { useCallback, useMemo, useState } from 'react';

// TODO: this should probably be moved to redux store, stored with each chat itself
export function useLocalMessagesList({ messagesList = [] }) {
  const [localMessagesList, setLocalMessagesList] = useState([]);

  const addMessageLocally = useCallback((message) => setLocalMessagesList((messages) => [...messages, message]), []);

  const deleteLocalMessage = useCallback(
    (messageId) => setLocalMessagesList((messages) => messages.filter((message) => message.id !== messageId)),
    []
  );

  const deleteAllLocalMessages = useCallback(() => setLocalMessagesList([]), []);

  const resultMessagesList = useMemo(() => [...messagesList, ...localMessagesList], [messagesList, localMessagesList]);

  return { resultMessagesList, addMessageLocally, deleteLocalMessage, deleteAllLocalMessages };
}
