import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import OnlineCircle from '../components/presence-indicators/OnlineCircle';
import OfflineCircle from '../components/presence-indicators/OfflineCircle';

import ProfileHeader from './ProfileHeader';
import { PRONOUNS } from './constants';
import { getOnlineStatus } from './api';
import { isOfficialHereUser } from '../../util/user-util';
import { ellipsisTwoLinesTextMixin } from '../mixins';
import { EventSourceContext } from '../common/contexts.ts';
import { selectCurrentUserId } from '../store/users/selectors';
import UserMood from './mood/index.tsx';

const DisplayProfile = forwardRef(({ userId, userProfile, isFriends, showCTAs }, ref) => {
  const { username, displayName, bio, pronouns } = userProfile;

  const [onlineStatus, setOnlineStatus] = useState('');

  const currentUserId = useSelector(selectCurrentUserId);

  const getIsOnline = useCallback(async () => {
    const response = await getOnlineStatus(userId);

    if (response && response.success && response.result && response.result[userId]) {
      setOnlineStatus(response.result[userId].status);
    }
  }, [userId]);

  useEffect(() => {
    // only get online status if user is logged in (it's an authed endpoint)
    if (currentUserId) {
      getIsOnline();
    }
  }, [currentUserId, getIsOnline]);

  return (
    <EventSourceContext.Provider value="Display profile">
      <Container ref={ref}>
        <ProfileHeader userId={userId} userProfile={userProfile} isOfficialHereUser={isOfficialHereUser(userProfile)} />
        <ProfileContent showCTAs={showCTAs}>
          {isFriends && (
            <FriendIconContainer>
              <img src="/images/icons/friend.svg" uk-tooltip="title: Friends; pos: top-right" alt="Friends" />
            </FriendIconContainer>
          )}
          {displayName && (
            <>
              <DisplayName>
                {/* we don't want to show online/offline status if it's a here admin account */}
                {onlineStatus === 'online' && !isOfficialHereUser(userProfile) && (
                  <OnlineCircleContainer>
                    <OnlineCircle />
                  </OnlineCircleContainer>
                )}
                {onlineStatus === 'offline' && !isOfficialHereUser(userProfile) && (
                  <OnlineCircleContainer>
                    <OfflineCircle backgroundColor="#12002D" />
                  </OnlineCircleContainer>
                )}
                <DisplayNameText data-testid="display-name-text">{displayName}</DisplayNameText>
              </DisplayName>
            </>
          )}
          {username && <Username data-testid="display-username-text">{`@${username}`}</Username>}
          {bio && <Bio>{bio}</Bio>}
          {pronouns && pronouns !== PRONOUNS.preferNotToSay && (
            <Pronouns data-testid="display-pronouns-text">{pronouns}</Pronouns>
          )}
          <DummyMoodContainer showCTAs={showCTAs}>
            <UserMood mood={userProfile.mood} canEdit={currentUserId === userId} />
          </DummyMoodContainer>
        </ProfileContent>
      </Container>
    </EventSourceContext.Provider>
  );
});

export default DisplayProfile;

DisplayProfile.propTypes = {
  userId: PropTypes.string.isRequired,
  userProfile: PropTypes.shape({
    username: PropTypes.string,
    displayName: PropTypes.string,
    bio: PropTypes.string,
    pronouns: PropTypes.string,
    avatar: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    backgroundPhoto: PropTypes.shape({
      original: PropTypes.string,
    }),
    mood: PropTypes.shape({}),
  }).isRequired,
  isFriends: PropTypes.bool,
  showCTAs: PropTypes.bool,
};

DisplayProfile.defaultProps = {
  isFriends: false,
  showCTAs: true,
};

const Container = styled.div`
  height: 456px;
  overflow: auto;
`;

const ProfileContent = styled.div`
  position: relative;
  top: 80px;
  text-align: center;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: start;

  ${({ showCTAs }) =>
    !showCTAs &&
    `
    padding-bottom: 20px;
    border-radius: 10px;
  `}
`;

const FriendIconContainer = styled.div`
  text-align: auto;
  position: absolute;
  right: 6px;
  top: 0;
`;

const OnlineCircleContainer = styled.span`
  margin-right: 7px;
`;

const DisplayName = styled.div`
  font-weight: 600;
  color: var(--primary-foreground, white);
  font-size: 30px;
  line-height: 35px;
  margin-top: 10px;
  word-wrap: break-word;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const DisplayNameText = styled.p`
  font-family: var(--primary-font, Inter, sans-serif);
  ${ellipsisTwoLinesTextMixin()}
`;

const Username = styled.div`
  color: var(--primary-foreground, white);
  opacity: 50%;
  font-size: 14px;
  line-height: 24px;
`;

const Bio = styled.div`
  color: var(--primary-foreground, white);
  margin-top: 10px;
  font-size: 14px;
  overflow-wrap: break-word;
`;

const Pronouns = styled.div`
  color: var(--primary-foreground, white);
  opacity: 50%;
  margin-top: 10px;
  font-size: 14px;
`;

const DummyMoodContainer = styled.div`
  padding-bottom: 4px;
  visibility: hidden;
  pointer-events: none;
  ${({ showCTAs }) => !showCTAs && `margin-top: -55px;`}
`;
