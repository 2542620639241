import React from 'react';
import styled from 'styled-components';

interface HereErrorLabelProps {
  errorMessage: string;
}

const HereErrorLabel = ({ errorMessage }: HereErrorLabelProps) => (
  <Container>
    <ErrorText>{errorMessage}</ErrorText>
  </Container>
);

const Container = styled.div`
  margin-top: 5px;
  padding: 8px 15px;
  background-color: #f6335d;
  border-radius: 10px;
`;

const ErrorText = styled.p`
  color: white;
  font-size: 12px;
  font-weight: bolder;
  line-height: 24px;
`;

export default HereErrorLabel;
