/**
 * Orders array of objects by the date field you specify
 *
 * @param {Array} array
 * @param {String} fieldName
 * @return {Array}
 */
export const orderObjectsByDateField = (array, fieldName) => {
  const sortedArray = [...array];
  sortedArray.sort((a, b) => new Date(b[fieldName]) - new Date(a[fieldName]));

  return sortedArray;
};
