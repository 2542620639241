import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { db } from '../../../firebase';
import { updateWayfinders } from '../../../wayfinders';

import ServerDate from '../../../util/server-date';
import { playSoundEffect, soundTypes } from '../../../util/sound-fx-util';

import CircleProgress from '../CircleProgress';
import PauseButton from './PauseButton';
import ResetButton from './ResetButton';
import PlayButton from './PlayButton';
import { getDisplayTimeFromSecs, getDisplayTimeInSecs } from '../../../util/time-util';
import { timerStates } from './constants';

const ActiveTimer = ({
  currentBoardId,
  elementId,
  originalStartTime,
  timerState,
  timerDone,
  currTimeLeft,
  timeStarted,
  updateDocument,
}) => {
  const [displayTime, setDisplayTime] = useState({});
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [timesUp, setTimesUp] = useState(false);

  const setCircleProgressPercentage = (timeCompleted, startTimeSeconds) => {
    setPercentageCompleted((timeCompleted / startTimeSeconds) * 100);
  };

  const getSecondsLeft = (timeNow) => {
    // --> seconds (number)
    const timeInputted = getDisplayTimeInSecs(currTimeLeft);
    const timeNowInSecs = Math.round(timeNow / 1000);

    const timeElapsed = timeNowInSecs - Math.round(timeStarted);
    const secondsLeft = timeInputted - timeElapsed;

    return secondsLeft;
  };

  const setActiveTimer = (secondsLeft) => {
    const startTimeSeconds = getDisplayTimeInSecs(originalStartTime);
    const timeCompleted = startTimeSeconds - secondsLeft;
    setCircleProgressPercentage(timeCompleted, startTimeSeconds);
    setDisplayTime(getDisplayTimeFromSecs(secondsLeft));
  };

  const setPausedTimer = () => {
    const startTimeSeconds = getDisplayTimeInSecs(originalStartTime);
    const currTimeLeftInSeconds = getDisplayTimeInSecs(currTimeLeft);
    const timeCompleted = startTimeSeconds - currTimeLeftInSeconds;
    setCircleProgressPercentage(timeCompleted, startTimeSeconds);
    setDisplayTime(currTimeLeft);
  };

  const setFinishedTimer = (secondsLeft) => {
    // play sound if seconds left is 0:
    if (secondsLeft === 0) {
      playSoundEffect(soundTypes.ALARM);
    }

    setTimesUp(true);
    setDisplayTime({ hour: '00', min: '00', sec: '00' });
  };

  useEffect(() => {
    updateWayfinders();

    const timer = setTimeout(async () => {
      // Update timer if paused state
      if (timerState === timerStates.paused) {
        setPausedTimer();
        return;
      }

      const timeNow = await ServerDate.getTime();
      const secondsLeft = getSecondsLeft(timeNow);

      // Update timer if finished state
      if (secondsLeft <= 0) {
        setFinishedTimer(secondsLeft);
        return;
      }

      // Update timer if in active counting down state
      setActiveTimer(secondsLeft);
    }, 1000);

    if (timesUp) {
      clearTimeout(timer);
    }

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (timesUp) {
      setPercentageCompleted(100);
    }

    // If timer's up and has not been already set to done in db:
    if (timesUp && !timerDone) {
      db.collection('boards').doc(currentBoardId).collection('elements').doc(elementId).update({ timerDone: true });
    }
  }, [timesUp, timerDone, currentBoardId, elementId]);

  const onResetClick = useCallback(() => {
    updateDocument({
      timerState: timerStates.inactive,
      currTimeLeft: null,
      timeStarted: -1,
      originalStartTime: { hour: '00', min: '00', sec: '00' },
      timerDone: false,
    });
  }, [updateDocument]);

  const onPauseClick = useCallback(
    (currDisplayTime) => {
      updateDocument({
        timerState: timerStates.paused,
        currTimeLeft: currDisplayTime,
      });
    },
    [updateDocument]
  );

  const onPlayClick = useCallback(async () => {
    const timeNow = await ServerDate.getTimeInSecs();
    updateDocument({
      timerState: timerStates.active,
      timeStarted: timeNow,
    });
  }, [updateDocument]);

  return (
    <CircleProgress percentage={percentageCompleted} color="var(--primary-foreground, #6b1be3)">
      <FlexContainer>
        <TimeTextContainer>
          <TimeText>{displayTime.hour}</TimeText>
          <TimeLabel>HR</TimeLabel>
        </TimeTextContainer>
        <TimeDivider>:</TimeDivider>
        <TimeTextContainer>
          <TimeText>{displayTime.min}</TimeText>
          <TimeLabel>MIN</TimeLabel>
        </TimeTextContainer>
        <TimeDivider>:</TimeDivider>
        <TimeTextContainer>
          <TimeText>{displayTime.sec}</TimeText>
          <TimeLabel>SEC</TimeLabel>
        </TimeTextContainer>
      </FlexContainer>
      <FlexContainer>
        {timerState === timerStates.active && !timesUp ? (
          <ButtonContainer>
            <PauseButton onClick={() => onPauseClick(displayTime)} />
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <PlayButton disabled={timesUp} onClick={() => onPlayClick()} />
          </ButtonContainer>
        )}
        <ButtonContainer>
          <ResetButton onClick={() => onResetClick()} />
        </ButtonContainer>
      </FlexContainer>
    </CircleProgress>
  );
};

export default ActiveTimer;

ActiveTimer.propTypes = {
  currentBoardId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  originalStartTime: PropTypes.shape({
    hour: PropTypes.string.isRequired,
    min: PropTypes.string.isRequired,
    sec: PropTypes.string.isRequired,
  }).isRequired,
  timerState: PropTypes.string.isRequired,
  currTimeLeft: PropTypes.shape({
    hour: PropTypes.string.isRequired,
    min: PropTypes.string.isRequired,
    sec: PropTypes.string.isRequired,
  }).isRequired,
  timeStarted: PropTypes.number.isRequired,
  updateDocument: PropTypes.func.isRequired,
  timerDone: PropTypes.bool.isRequired,
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeTextContainer = styled.div`
  background: var(--primary-background, #12002d);
  border-radius: 9px;
  padding: 6px 2px;
  width: 36px;
`;

const TimeText = styled.div`
  font-family: 'Inter';
  letter-spacing: 1.5px;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-foreground, white);
`;

const TimeLabel = styled.div`
  font-family: 'Inter';
  color: var(--primary-foreground, #ffffff63);
  font-size: 10.5px;
`;

const TimeDivider = styled.div`
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-foreground, white);
  padding: 2px;
`;

const ButtonContainer = styled.div`
  margin: 8px;
`;
