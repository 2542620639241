import $ from 'jquery';

import { getElementPosition } from './element-transform';
import { isPageMuted } from './util';
import { canvasToScreenCoords } from './util/canvas-util';
import log from './log';

const getDistance = (el) => {
  const [x, y] = getElementPosition(el);
  const elementCoords = canvasToScreenCoords(x, y);
  const distanceX = elementCoords[0] + (parseFloat(el.style.width) * window.canvasScale) / 2 - window.innerWidth / 2;
  const distanceY = elementCoords[1] + (parseFloat(el.style.width) * window.canvasScale) / 2 - window.innerHeight / 2;
  const distance = {};
  distance.x = distanceX;
  distance.y = distanceY;
  distance.diag = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
  return distance;
};

const getVolumeForDistance = (distance) => {
  const dropoffRate = 120; // higher = slower dropoff
  const fullVolume = Math.max(window.innerWidth, window.innerHeight) / 2;
  const vol = distance.diag <= fullVolume ? 1.0 : 1 / (1 + (distance.diag - fullVolume) / dropoffRate);
  return vol;
};

export const setVideoVolume = (el) => {
  const video = $(el).find('video').get(0);
  if (video !== undefined && !video.hasAttribute('muted')) {
    let vol = 0;
    if (!isPageMuted()) vol = getVolumeForDistance(getDistance(el));
    if (!Number.isNaN(vol)) {
      video.volume = vol;
    } else {
      log.error(`Error setting video volume to ${vol}`);
    }
  }
};

export const setMediaPlayerVolume = (mp) => {
  const id = mp.elementId;
  const el = document.getElementById(`element-${id}`);

  const distanceMultiplier = isPageMuted() ? 0 : getVolumeForDistance(getDistance(el));
  if (!Number.isNaN(distanceMultiplier)) {
    mp.setDistanceMultiplier(distanceMultiplier);
  } else {
    log.error('Error setting video volume');
  }
};

export const updateSpatialAudio = () => {
  const cameraHandlers = Object.values(window.elementHandlers).filter(
    (h) => h.constructor.elementType === 'CameraElement'
  );
  cameraHandlers.forEach((handler) => {
    setVideoVolume(document.getElementById(`element-${handler.elementId}`));
  });

  const mediaPlayerHandlers = Object.values(window.elementHandlers).filter(
    (h) => h.constructor.elementType === 'MediaPlayerElement'
  );
  mediaPlayerHandlers.forEach((handler) => {
    setMediaPlayerVolume(handler);
  });
};
