import styled from 'styled-components';

export const ChatMessageContainer = styled.div<{ hasReactions?: boolean }>`
  margin: 3px 0;
  ${({ hasReactions }) => (hasReactions ? 'margin-bottom: 16px;' : 'margin-bottom: 3px;')}
  padding: 3px 5px;
  border-radius: 7px;

  &:nth-last-child(2) {
    margin: 3px 0 10px 0;
  }

  .chat-item-context-buttons {
    display: none;
  }

  &:hover {
    background-color: var(--secondary-background-alpha-30, #ffffff10);

    .chat-item-context-buttons {
      display: flex;
    }

    .delete-message-button {
      display: flex;
    }

    .toggle-emoji-picker-button {
      opacity: 1;
    }
  }
`;

export const AvatarContainer = styled.div`
  width: 36px;
  height: 36px;

  border-radius: 50%;

  cursor: pointer;
`;

export const ChatMessageMain = styled.div`
  display: flex;
`;

export const ChatAvatarStub = styled.div`
  min-width: 36px;
`;

export const MessageGutter = styled(ChatAvatarStub)`
  display: flex;
  align-items: end;
  min-width: 36px;

  here-avatar {
    width: 37px;
    height: 37px;
    border-radius: 18.5px;
    overflow: hidden;
  }
`;

export const MessageContainer = styled.div<{ isSelf: boolean; isLocal: boolean; isFailed: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ isSelf }) => (isSelf ? 'flex-end' : 'flex-start')};
  opacity: ${({ isLocal, isFailed }) => (isLocal || isFailed ? '0.5' : '1')};
`;

export const MessageContainerHeader = styled.div`
  margin: 0 5px;
  height: 22px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: text;
  cursor: text;
`;

export const MessageCreator = styled.div`
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-size: 0.7em;
  color: white;
  font-family: var(--chat-section-font, Inter, sans-serif);
`;

export const MessageDate = styled.div`
  color: #999999;
  font-family: var(--chat-section-font, Inter, sans-serif);
`;

export const MessageContent = styled.div<{ isFailed?: boolean }>`
  margin: 0 5px;
  max-width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.1rem;
  outline: ${({ isFailed }) => (isFailed ? '2px solid #F6335D' : 'none')};
  border-radius: 10px;
`;
