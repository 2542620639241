import React from 'react';
import styled from 'styled-components';

import { TimerContainerCellContent, TimerContainerGrid } from '../shared-styles';

const ErrorState = () => (
  <Container>
    <TimerContainerGrid>
      <TimerContainerCellContent>-</TimerContainerCellContent>
      <TimerContainerCellContent>:</TimerContainerCellContent>
      <TimerContainerCellContent>-</TimerContainerCellContent>
      <TimerContainerCellContent>:</TimerContainerCellContent>
      <TimerContainerCellContent>--</TimerContainerCellContent>
    </TimerContainerGrid>

    <ErrorText>An error has occurred...</ErrorText>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorText = styled.h4`
  color: var(--primary-foreground, white);
  font-size: 16px;
  font-weight: bolder;
  font-family: var(--primary-font, Inter, sans-serif);
  text-align: center;
`;

export default ErrorState;
