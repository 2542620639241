import styled from 'styled-components';

const cellSizeWidePx = 176;

export const RoomDecorationTabContainer = styled.div`
  padding-top: 35px;
  height: 40vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: ${cellSizeWidePx}px;
  justify-content: center;
  gap: 25px;

  @media (max-width: 590px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
