import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Marquee from 'react-fast-marquee';

import { Mood } from '../../../definitions/user-profile';
import { EditIcon, MoodContent } from './shared-styles';
import { applyTheme } from '../../../util/theming-util';

interface Props {
  mood: Mood;
  canEdit?: boolean;
}

const MarqueeMood: React.FC<Props> = ({ mood, canEdit = false }) => {
  const containerRef = useRef(null);
  const textElementRef = useRef(null);

  useEffect(() => {
    if (mood?.theme && containerRef.current) {
      applyTheme(mood.theme.colors, mood.theme.primaryFont, containerRef.current);
    }
  }, [mood?.theme, containerRef]);

  const [playMarquee, setPlayMarquee] = useState(false);

  useEffect(() => {
    const textRefCurrent = textElementRef.current;
    const containerRefCurrent = containerRef.current;

    const updateMarqueePlayState = () => {
      const shouldPlay = textElementRef.current?.clientWidth > containerRef.current?.clientWidth;
      setPlayMarquee(shouldPlay);
    };

    const resizeObserver = new ResizeObserver(updateMarqueePlayState);

    if (textRefCurrent) {
      resizeObserver.observe(textRefCurrent);
    }

    if (containerRefCurrent) {
      resizeObserver.observe(containerRefCurrent);
    }

    updateMarqueePlayState();

    return () => {
      if (textRefCurrent) {
        resizeObserver.unobserve(textRefCurrent);
      }
      if (containerRefCurrent) {
        resizeObserver.unobserve(containerRefCurrent);
      }
    };
  }, [textElementRef, containerRef, mood]);

  return (
    <StyledMoodContent ref={containerRef} canEdit={canEdit} playMarquee={playMarquee}>
      {canEdit ? <EditIcon /> : null}
      <Marquee speed={40} play={playMarquee}>
        <MarqueeText className="themed-font" ref={textElementRef}>
          {mood.text}
        </MarqueeText>
        {playMarquee ? <MarqueeDot>·</MarqueeDot> : null}
      </Marquee>
    </StyledMoodContent>
  );
};

export default MarqueeMood;

const StyledMoodContent = styled(MoodContent)<{ playMarquee: boolean }>`
  ${({ playMarquee }) =>
    !playMarquee &&
    `
      // Need to do these to overwrite the styling coming from the 
      // container from the marquee lib when marquee is not playing

      .rfm-marquee-container > :nth-child(1) {
        display: none;
        animation: none;
      }
      .rfm-marquee-container > :nth-child(2) {
        animation: none;
      }
  `}
  max-width: 100%;
`;

const MarqueeText = styled.div`
  white-space: nowrap;
`;

const MarqueeDot = styled.p`
  padding: 0 10px;
  color: inherit;
  font-weight: bolder;
`;
