import { createAction } from '@reduxjs/toolkit';
import { Theme } from '../../../definitions/theme';
import { OsOnboardingListRequiredStepIds } from '../../os/onboarding-list/steps';
import { FriendsSubTabs } from '../../web-lobby/tabs/friends-tab/Navigation';

// Chat related
export const setOpenChats = createAction<{ openChats: { [key: string]: { elementId: string } } }>('os/setOpenChats');
export const openChat = createAction<{ chatId: string; center?: number[] }>('os/openChat');
export const createNewChat = createAction<{
  name: string;
  userIds?: string[];
  theme: Theme;
  bgUrl: string;
  isOnboarding?: boolean;
}>('os/createNewChat');
export const createNewDMChat = createAction<{ receiverId: string; theme: Theme; bgUrl: string }>('os/createNewDMChat');

// Sidebar & menu related
export const setIsFriendsSidebarOpened = createAction<{ defaultTab: FriendsSubTabs }>('os/setIsFriendsSidebarOpened');
export const setIsFriendsOnlineOpened = createAction<{ isOpened: boolean }>('os/setIsFriendsOnlineOpened');
export const setIsAppearanceMenuOpened = createAction<{ isOpened: boolean }>('os/setIsAppearanceMenuOpened');
export const setIsFeedbackMenuOpened = createAction<{ isOpened: boolean }>('os/setIsFeedbackMenuOpened');
export const setIsRoomsMenuOpened = createAction<{ isOpened: boolean }>('os/setIsRoomsMenuOpened');
export const setIsAvatarProfileOpened = createAction<{ isOpened: boolean }>('os/setIsAvatarProfileOpened');
export const setIsMyStuffMenuOpened = createAction<{ isOpened: boolean }>('os/setIsMyStuffMenuOpened');
export const setIsCreateChatOpened = createAction<{ isOpened: boolean }>('os/setIsCreateChatOpened');
export const setIsWhatsNewOpened = createAction<{ isOpened: boolean }>('os/setIsWhatsNewOpened');
export const setIsDiscoverModalOpened = createAction<{ isOpened: boolean }>('os/setIsDiscoverModalOpened');

// This should be updated only with board's element max z-index (already doing it in OsBoard component)
export const setMaxZIndex = createAction<{ value: number }>('os/setMaxZIndex');

// Onboarding related
export const checkOnboardingList = createAction('os/checkOnboardingList');
export const completeOnboardingStep = createAction<{ stepId: OsOnboardingListRequiredStepIds }>(
  'os/completeOnboardingStep'
);
export const finishOnboardingList = createAction('os/finishOnboardingList');
export const createDesktopRequested = createAction('os/createDesktopRequested');
