import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  searchTerm: '',
  perPage: 20,
  results: [],
  defaultSearchTerms: [
    'clouds',
    'cute kittens',
    'planet',
    'tropical',
    'flames',
    'fruit',
    'mountains',
    'trees',
    'tropical fish',
  ],
};

export const searchTermChanged = createAction('images/searchTermChanged');
export const resultsRequested = createAction('images/resultsRequested');
export const moreResultsRequested = createAction('images/moreResultsRequested');
export const gotResults = createAction('images/gotResults');
export const gotMoreResults = createAction('images/gotMoreResults');
export const addImageRequested = createAction('images/addRequested');

export const getSearchTerm = (state) => state.imageSearch.searchTerm;
export const getIsLoading = (state) => state.imageSearch.isLoading;
export const getPerPage = (state) => state.imageSearch.perPage;
export const getDefaultSearchTerms = (state) => state.imageSearch.defaultSearchTerms;
export const getResults = (state) => state.imageSearch.results;
export const getResultsLength = (state) => state.imageSearch.results.length;

export const imageSearchReducer = createReducer(initialState, {
  [searchTermChanged]: (state, { payload: { searchTerm } }) => {
    state.searchTerm = searchTerm;
  },
  [resultsRequested]: (state) => {
    state.results = [];
    state.isLoading = true;
  },
  [moreResultsRequested]: (state) => {
    state.isLoading = true;
  },
  [gotResults]: (state, { payload: { results } }) => {
    state.results = results;
    state.isLoading = false;
  },
  [gotMoreResults]: (state, { payload: { results } }) => {
    state.results = state.results.concat(results);
    state.isLoading = false;
  },
});
