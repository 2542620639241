import React from 'react';
import styled from 'styled-components';

import MessageContextButton from './context-menu/MessageContextButton';

// utils
import { downloadFile } from '../../../util/file-util';
import log from '../../../log';
import MessageContextMenu from './context-menu/MessageContextMenu';

interface VideoMessageProps {
  id: string;
  chatId: string;
  url: string;
  storagePath: string;
  creator: string;
  deleteMessage: (id: string, storagePath: string) => Promise<void>;
}

const allowedVideoTypes = ['mp4', 'webm', 'ogg'];

const VideoMessage = ({ id, chatId, storagePath, url, creator, deleteMessage }: VideoMessageProps) => {
  const downloadVideo = async () => {
    try {
      const parts = storagePath.split('.');
      let fileType = 'mp4';
      if (parts.length > 2 && allowedVideoTypes.includes(parts[parts.length - 1])) {
        fileType = parts[parts.length - 1];
      }

      await downloadFile(url, `video-message-${id}.${fileType}`);
    } catch (error) {
      log.error(error);
    }
  };

  return (
    <Container>
      <VideoContainer>
        <MessageContextMenu
          chatId={chatId}
          text={url}
          creator={creator}
          options={[<MessageContextButton onClick={downloadVideo}>Download</MessageContextButton>]}
          deleteSelf={() => deleteMessage(id, storagePath)}
        />

        <Video src={url} loop controls disablePictureInPicture controlsList="nodownload noplaybackrate" />
      </VideoContainer>
    </Container>
  );
};

const Container = styled.div``;

const VideoContainer = styled.div`
  position: relative;
  max-width: fit-content;
`;

const Video = styled.video`
  max-width: 350px;
  max-height: 375px;
`;

export default VideoMessage;
