import UIkit from 'uikit';
import firebase, { db } from './firebase';
import { drawLines, DrawStates, EraserCursorStyle, DrawCursorStyle, currentColor, setPenColor } from './drawing';
import { screenToCanvasCoords } from './util/canvas-util';
import { addSystemMessage } from './message-util';
import { isElementsInteractionAllowed, isLockAllowed } from './roles-management';
import { elementMoved } from './drag';
import { isPointInsideElement, htmlToElement } from './util';
import log from './log';
import wrapElement from './element-wrapper';
import BoardElement from './board-element';

import '../styles/whiteboard.less';
import { updateProfile } from './util/profile-util';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';
import { USER_CARD_BOARD_TYPE } from './constants/board-constants';

const MaxUndoSteps = 15;

let colorUpdateTimeout;

export default class WhiteboardElement extends BoardElement {
  constructor(elementId) {
    super(elementId);

    this.lines = {};
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    const data = elementDoc.data();

    this.addRasterImage(data);

    const el = document.getElementById(`element-${this.elementId}`);
    const whiteboard = el.getElementsByClassName('whiteboard')[0];
    whiteboard.style.backgroundColor = data.backgroundColor;

    const canvas = this.getCanvas();
    canvas.width = parseFloat(data.size[0]) * 2;
    canvas.height = parseFloat(data.size[1]) * 2;

    this.lines = elementDoc.data().lines || {};
    drawLines(this.lines, this.getContext(), canvas);
    return true;
  }

  getCursorStyle() {
    if (this.drawState === DrawStates.Erase) {
      return EraserCursorStyle;
    }

    return DrawCursorStyle;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(elementId, elementDoc) {
    const canvas = this.getCanvas();
    canvas.width = parseFloat(elementDoc.data().size[0]) * 2;
    canvas.height = parseFloat(elementDoc.data().size[1]) * 2;
    canvas.style.cursor = DrawCursorStyle;

    this.lines = elementDoc.data().lines || {};
    drawLines(this.lines, this.getContext(), canvas);
    document.getElementById(`draw-${elementDoc.id}`).onclick = () => {
      this.drawState = DrawStates.Draw;
      document.querySelector(`#draw-state-${elementDoc.id} here-inline-svg`).src = 'images/icons/pen.svg';
      canvas.style.cursor = DrawCursorStyle;

      UIkit.dropdown(`#draw-state-menu-${elementDoc.id}`).hide();
      UIkit.dropdown(`#fg-color-menu-${elementDoc.id}`).hide();
      UIkit.dropdown(`#bg-color-menu-${elementDoc.id}`).hide();
    };
    document.getElementById(`erase-${elementDoc.id}`).onclick = () => {
      this.drawState = DrawStates.Erase;
      document.querySelector(`#draw-state-${elementDoc.id} here-inline-svg`).src = 'images/icons/eraser.svg';
      canvas.style.cursor = EraserCursorStyle;
    };
    document.getElementById(`undo-${elementDoc.id}`).onclick = () => {
      this.undo();
    };

    // Color picker
    document.getElementById(`pen-color-${elementDoc.id}`).addEventListener('colorchange', (e) => {
      const penColor = e.detail.color;
      setPenColor(penColor);
      clearTimeout(colorUpdateTimeout);
      colorUpdateTimeout = setTimeout(() => {
        updateProfile({ penColor });
        track('Change Pen Color');
      }, 250);
    });

    // Background Picker
    document.getElementById(`background-color-${elementDoc.id}`).addEventListener('colorchange', (e) => {
      if (e.detail.inputComplete) {
        db.collection('boards')
          .doc(window.currentBoardId)
          .collection('elements')
          .doc(this.elementId)
          .update({ backgroundColor: e.detail.color });
      }

      const el = document.getElementById(`element-${this.elementId}`);
      const whiteboard = el.getElementsByClassName('whiteboard')[0];
      whiteboard.style.backgroundColor = e.detail.color;
    });

    // Update the color picker
    const el = document.getElementById(`element-${this.elementId}`);
    const color = el.getElementsByClassName('color-indicator')[0];
    color.style.backgroundColor = currentColor;

    const overlay = el.querySelector('.whiteboard-overlay');
    if (overlay) {
      overlay.addEventListener('click', () => {
        if (elementMoved() || !el.classList.contains('can-move')) {
          return;
        }

        overlay.style.display = 'none';
        if (!this.onMouseUpEvent) {
          this.onMouseUpEvent = this.onMouseUp.bind(this);
        }
        document.addEventListener('mouseup', this.onMouseUpEvent);
        track('Focus', { element: this.elementType });
      });
    }

    this.addRasterImage(elementDoc.data());

    const overlayMessage = el.querySelector('.whiteboard-overlay-message');
    this.overlayMessageVisible = Object.keys(this.lines).length === 0 && !elementDoc.data().image;
    if (this.overlayMessageVisible) {
      overlayMessage.style.display = 'block';
    } else {
      overlayMessage.style.display = 'none';
    }
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();
    const background = data.backgroundColor;
    const whiteboard = htmlToElement(`
      <div style="height: 100%">
        <div class="whiteboard" style="background-color: ${background}">
          <canvas class="whiteboard-canvas dont-drag-me"></canvas>
        </div>
        <div class="whiteboard-overlay"><div class="whiteboard-overlay-message">Click to Start Drawing</div></div>
        <div class="element-menu-bar ${
          !isElementsInteractionAllowed() || (data.lockedInteraction && !isLockAllowed()) ? 'hidden' : ''
        }">
          <button
            class="menu-button"
            id="draw-state-${elementDoc.id}"
            uk-tooltip="title: Draw">
            <here-inline-svg src="images/icons/pen.svg"></here-inline-svg>
          </button>
          <div id="draw-state-menu-${
            elementDoc.id
          }" uk-dropdown="mode: click; pos: top-justify" class="element-submenu">
            <ul class="uk-nav uk-dropdown-nav">
              <li id="draw-${elementDoc.id}">
                <a>
                  <img src="/images/icons/pen.png" height="36" width="36" /> Draw
                </a>
              </li>
              <li id="erase-${elementDoc.id}">
                <a>
                  <img src="/images/icons/eraser.png" height="36" width="36" /> Erase
                </a>
              </li>
            </ul>
          </div>
          <button class="menu-button" id="fg-color-${elementDoc.id}" uk-tooltip="title: Pen Color">
            <div class="color-indicator pen-color-indicator"></div>
          </button>
          <div id="fg-color-menu-${
            elementDoc.id
          }" uk-dropdown="mode: click; pos: top-justify" class="element-submenu color-dropdown">
            <div class="uk-nav-header" style="margin-top: 0">Pen</div>
            <here-color-select id="pen-color-${elementDoc.id}" />
          </div>
          <button
            class="menu-button"
            id="bg-color-${elementDoc.id}"
            uk-tooltip="title: Background Color">
            <here-inline-svg src="images/icons/fill.svg"></here-inline-svg>
          </button>
          <div id="bg-color-menu-${
            elementDoc.id
          }" uk-dropdown="mode: click; pos: top-justify" class="element-submenu color-dropdown">
            <div class="uk-nav-header" style="margin-top: 0">Background</div>
            <here-color-select alpha="true" id="background-color-${this.elementId}" />
          </div>
          <button
            class="menu-button"
            id="undo-${elementDoc.id}"
            uk-tooltip="title: Undo">
            <here-inline-svg src="images/icons/undo.svg"></here-inline-svg>
          </button>
        </div>
      </div>
    `);

    return wrapElement(whiteboard, elementDoc);
  }

  onSizeChange(w, h) {
    const canvas = this.getCanvas();
    if (canvas.width !== w || canvas.height !== h) {
      canvas.width = w * 2;
      canvas.height = h * 2;
      drawLines(this.lines, this.getContext(), canvas);
    }
  }

  elementDescription() {
    return 'drawing';
  }

  icon() {
    return 'drawing';
  }

  onMouseUp(e) {
    const el = document.getElementById(`element-${this.elementId}`);
    // if the target of the click isn't the container nor a descendant of the container
    if (!el || el === e.target || el.contains(e.target)) {
      return;
    }

    if (isPointInsideElement(el, e.clientX, e.clientY)) {
      return;
    }

    document.removeEventListener('mouseup', this.onMouseUpEvent);
    const overlay = el.querySelector('.whiteboard-overlay');
    overlay.style.display = 'block';
  }

  // Statics

  static async addElement({ center, height, width } = {}) {
    try {
      const boardRef = db.collection('boards').doc(window.currentBoardId);
      const boardData = (await boardRef.get()).data();
      await boardRef.collection('elements').add({
        class: 'WhiteboardElement',
        center:
          center ||
          screenToCanvasCoords(
            Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
            Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
          ),
        creator: firebase.auth().currentUser.uid,
        size: [width || 400, height || 300],
        backgroundColor: '#ffffff',
        zIndex: window.getFrontZIndex(),
      });

      addSystemMessage('added a drawing');
      track(ADD_ELEMENT, {
        element: ELEMENT_TYPES.DRAWING,
        destination:
          boardData.type === USER_CARD_BOARD_TYPE
            ? ADD_ELEMENT_DESTINATION_TYPES.CARD
            : ADD_ELEMENT_DESTINATION_TYPES.ROOM,
      });
    } catch (error) {
      log.error(`Something went wrong creating WhiteboardElement: ${error}`);
    }
  }

  // Handler-specific:

  addRasterImage(data) {
    if (data.image) {
      const backgroundImage = this.getRasterImage();
      backgroundImage.src = data.image;
      backgroundImage.style.display = 'block';
    }
  }

  async saveSnapshot(imageData) {
    const storage = firebase.storage();

    const storagePath = `boards/${window.currentBoardId}/whiteboards/${this.elementId}`;
    const uploadTask = storage.ref(storagePath).put(imageData);

    await new Promise((resolve, reject) => {
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded
          // and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          log.debug(`Upload is ${progress}% done`);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              log.debug('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              log.debug('Upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          log.error('Error uploading file', error);
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              reject();
              break;

            case 'storage/canceled':
              // User canceled the upload
              reject();
              break;

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              reject();
              break;

            default:
              break;
          }
        },
        () => {
          resolve();
        }
      );
    });

    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
    return downloadURL;
  }

  getContext() {
    if (!this.context) {
      const canvas = this.getCanvas();
      this.context = canvas.getContext('2d');
    }
    return this.context;
  }

  getCanvas() {
    const el = document.getElementById(`element-${this.elementId}`);
    return el.getElementsByClassName('whiteboard-canvas')[0];
  }

  getRasterImage() {
    const el = document.getElementById(`element-${this.elementId}`);
    const rasterEls = el.getElementsByClassName('whiteboard-rasterized-image');
    if (!rasterEls || rasterEls.length === 0) {
      const rasterEl = htmlToElement(
        '<img crossorigin="anonymous" class="whiteboard-rasterized-image" style="display: none" />'
      );
      const canvas = el.getElementsByClassName('whiteboard-canvas')[0];
      canvas.parentNode.insertBefore(rasterEl, canvas);
      return rasterEl;
    }
    return rasterEls[0];
  }

  hideOverlayMessage() {
    if (this.overlayMessageVisible) {
      const el = document.getElementById(`element-${this.elementId}`);
      const overlayMessage = el.querySelector('.whiteboard-overlay-message');
      overlayMessage.style.display = 'none';
      this.overlayMessageVisible = false;
    }
  }

  didStartDrawing() {
    UIkit.dropdown(`#draw-state-menu-${this.elementId}`).hide();
    UIkit.dropdown(`#fg-color-menu-${this.elementId}`).hide();
    UIkit.dropdown(`#bg-color-menu-${this.elementId}`).hide();
  }

  async addLine(lineId, color, points) {
    this.hideOverlayMessage();
    this.lines[lineId] = { color, points };
    if (this.drawState) {
      this.lines[lineId].drawState = this.drawState;
    }
    const elementRef = db.collection('boards').doc(window.currentBoardId).collection('elements').doc(this.elementId);
    elementRef.update({ lines: this.lines });

    const numLines = Object.keys(this.lines).length;
    // Every MaxUndoSteps lines, write everything into an image and delete old lines
    if (numLines > MaxUndoSteps && numLines % MaxUndoSteps === 0) {
      const currentCanvas = this.getCanvas();
      const fileCanvas = new OffscreenCanvas(currentCanvas.width, currentCanvas.height);
      const fileContext = fileCanvas.getContext('2d');
      const image = this.getRasterImage();
      fileContext.drawImage(
        image,
        (fileCanvas.width - image.clientWidth) / 2,
        (fileCanvas.height - image.clientHeight) / 2
      );

      const linesToRasterize = {};
      const lineKeys = Object.keys(this.lines).sort(numericKeySort);
      for (let i = 0; i < lineKeys.length - MaxUndoSteps; i += 1) {
        linesToRasterize[lineKeys[i]] = this.lines[lineKeys[i]];
      }
      drawLines(linesToRasterize, fileContext, fileCanvas);
      const blob = await fileCanvas.convertToBlob({ type: 'image/png' });
      const imagePath = await this.saveSnapshot(blob);
      Object.keys(linesToRasterize).forEach((rasterKey) => {
        delete this.lines[rasterKey];
      });
      elementRef.update({ lines: this.lines, image: imagePath });
    }
  }

  undo() {
    const lineKeys = Object.keys(this.lines).sort(numericKeySort);
    delete this.lines[lineKeys[lineKeys.length - 1]];
    drawLines(this.lines, this.getContext(), this.getCanvas());

    db.doc(`boards/${window.currentBoardId}/elements/${this.elementId}`).update({ lines: this.lines });
  }
}

function numericKeySort(a, b) {
  const na = parseInt(a, 10);
  const nb = parseInt(b, 10);
  return na - nb;
}

WhiteboardElement.elementType = 'WhiteboardElement';

// Make globally available
window.WhiteboardElement = WhiteboardElement;
