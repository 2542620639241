import { isEqual } from 'lodash/fp';
import eventBus, { roomVisibilityChanged } from '../../../../../event-bus';
import store from '../../../../store/room-root-store';
import { setVisibleRoomIds } from '../../../../store/web-lobby/actions.ts';
import { selectVisibleRoomIds } from '../../../../store/web-lobby/selectors.ts';

const localVisibleRoomIds = {};

setInterval(() => {
  const currentValue = selectVisibleRoomIds(store.getState());
  if (!isEqual(currentValue, localVisibleRoomIds)) {
    store.dispatch(setVisibleRoomIds({ visibleRoomIds: { ...localVisibleRoomIds } }));
  }
}, 5000);

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      const roomId = entry.target.dataset.roomid;

      if (localVisibleRoomIds !== entry.isIntersecting) {
        eventBus.dispatch(roomVisibilityChanged, roomId, entry.isIntersecting);
      }
      localVisibleRoomIds[roomId] = entry.isIntersecting;
    });
  },
  { root: null }
);

export const observeRoom = (id, el) => {
  if (!localVisibleRoomIds[id]) {
    observer.observe(el);
  }
};

export const unobserveRoom = (id, el) => {
  observer.unobserve(el);
  localVisibleRoomIds[id] = false;
};
