import React from 'react';
import ReactDOM from 'react-dom';

import Feed from '.';
import { WithRoomStore } from '../../store/Wrapper';

/**
 * @param {string} currentBoardId
 * @returns {void}
 */
export function renderFeed(currentBoardId) {
  const elementFeed = document.getElementById('element-feed');

  // TODO: disconnect Feed from the redux
  //
  // we don't need a redux in the Feed, but we call the `useDispatch`-hook in the ImageMessage (and in the TextMessage) to reset unreads (https://github.com/herefm/here-web/commit/55b2b1706dcc25a0df3fe39514a7e6c82efdbad2)
  // as we use ImageMessage (and the TextMessage) in the Feed as well it's required to connect Feed to the redux store to let ImageMessage and TextMessage call `useDispatch`-hook
  // in my opinion more generic solution is to:
  // 1) put resetting unreads to the new function resetUnreads (in the DMWindow because it's anyway connected to the redux)
  // 2) put this resetUnreads-function to the React context (to avoid drilling it through ChatWindow -> ImageMessage)
  // 3) put nop in this React context for the TextChannel and Feed (which don't need resetting unreads) to keep the same interface of the React context
  // 4) in the ImageMessage and TextMessage get resetUnreads-function from the React context and call it
  //
  // but IMO this refactoring should be done in the scope of the separate PR to not hold DMs MVP and to not cause new potential bugs
  ReactDOM.render(
    <WithRoomStore>
      <Feed currentBoardId={currentBoardId} />
    </WithRoomStore>,
    elementFeed
  );
}
