import React from 'react';
import PropTypes from 'prop-types';
import '../../../components/here-switch/here-switch.less';

// NOTE: There are two different ways to use this switch:

// 1. In a controlled React form
// Required props -- isOn, onChange(checked)

// 2. In an uncontrolled React form
// Required props -- toggleClass, sliderClass, onChange(checked)

// Where possible, #1 is preferred.
// See here: https://reactjs.org/docs/forms.html#controlled-components
// and here: https://reactjs.org/docs/uncontrolled-components.html

const HereSwitch = ({ toggleClass, sliderClass, onChange, isOn }) => {
  // `isOn`/`sliderClass`/`toggleClass` in input needs null check
  // to be passed in conditionally as there are some places where we're using this
  // component in an uncontrolled way. However, this may break if your initial value is null.
  // If your switch is controlled, make sure that your isOn prop is a boolean
  // TODO: move towards only using this component in a controlled way.
  const conditionalInputProps = {
    ...(isOn !== null && { checked: isOn }),
    ...(sliderClass !== null && { className: `url-toggle-switch ${sliderClass}` }),
    ...(toggleClass !== null && { 'uk-toggle': `target: .${toggleClass}` }),
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        // Need props spread to pass in props conditionally
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...conditionalInputProps}
      />
      <span className="slider round" />
    </label>
  );
};


HereSwitch.propTypes = {
  toggleClass: PropTypes.string,
  sliderClass: PropTypes.string,
  onChange: PropTypes.func,
  isOn: PropTypes.bool,
};

HereSwitch.defaultProps = {
  toggleClass: null,
  sliderClass: null,
  onChange: () => {},
  isOn: null,
};

export default HereSwitch;
