import React, { useCallback, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Country } from 'react-phone-number-input';
import { goPrevScreen, sendCode } from '../../store/signing-in/actions';
import HereErrorLabel from '../../components/HereErrorLabel';

import {
  NavButtonsContainer,
  PrevNavButton,
  NextNavButton,
  TitleContainer,
  ScreenTitle,
  ScreenSubTitle,
  ContainerDefault,
  FormContainer,
} from '../shared-styled';
import PhoneNumber from '../../phone/PhoneNumber';
import { useVerifyPhone, Values } from '../../phone/useVerifyPhone';
import { handleErrorCode, formatPhone } from '../../phone/phone-util';

const PhoneInputScreen = ({
  handleSendVerificationCode,
}: {
  handleSendVerificationCode: (phoneNumber: string) => Promise<string>;
}) => {
  const dispatch = useDispatch();

  const onPrevClick = () => {
    dispatch(goPrevScreen({ screenData: {} }));
  };

  const { validatePhoneNumber } = useVerifyPhone();

  const initialValues = useMemo(() => ({ phoneNumber: '', countryCode: 'US' }), []);

  const onSubmit = useCallback(
    async ({ countryCode, phoneNumber }: Values, { setSubmitting, setErrors }) => {
      try {
        const verificationId = await handleSendVerificationCode(formatPhone(countryCode, phoneNumber));
        dispatch(sendCode({ domesticPhoneNumber: phoneNumber, countryCode, verificationId }));
      } catch (e) {
        setErrors({ phoneNumber: handleErrorCode(e.code) });
      }

      setSubmitting(false);
    },
    [dispatch, handleSendVerificationCode]
  );

  return (
    <ContainerDefault>
      <TitleContainer>
        <ScreenTitle>Sign In with Phone</ScreenTitle>
        <ScreenSubTitle>Enter your phone number</ScreenSubTitle>
      </TitleContainer>
      <FormContainer>
        <Formik
          initialValues={initialValues as Values}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
          validate={validatePhoneNumber}
        >
          {({ values, isSubmitting, errors, setFieldValue, setErrors }) => {
            if (values.phoneNumber.length === 0 && Object.keys(errors).length > 0) {
              setErrors({});
            }

            return (
              <Form className="form-container">
                <PhoneNumber
                  flexBasis="25%"
                  phoneNumber={values.phoneNumber}
                  countryCode={values.countryCode}
                  setFieldValue={setFieldValue}
                  handleCountrySelect={(val: Country) => setFieldValue('countryCode', val)}
                  hasError={values.phoneNumber.length > 0 && errors.phoneNumber && errors.phoneNumber.length > 0}
                  autoFocus
                />
                {values.phoneNumber.length > 0 && errors.phoneNumber && errors.phoneNumber.length > 0 && (
                  <HereErrorLabel errorMessage={errors.phoneNumber} />
                )}
                <NavButtonsContainer>
                  <PrevNavButton type="button" onClick={onPrevClick}>
                    Back
                  </PrevNavButton>
                  <NextNavButton
                    type="submit"
                    className="here-button-regular"
                    disabled={isSubmitting || values.phoneNumber.length === 0}
                    data-testid="sign-in-send-code-button"
                  >
                    Send Code
                  </NextNavButton>
                </NavButtonsContainer>
              </Form>
            );
          }}
        </Formik>
      </FormContainer>
    </ContainerDefault>
  );
};

export default PhoneInputScreen;
