import $ from 'jquery';

import { photoTimerShouldStart } from '../photobooth';
import { updateWayfinders } from '../wayfinders';
import { showCursor, didReceiveLinePoint } from '../drawing';
import { startDirectedReaction, startReaction } from '../reactions';
import minimap from '../minimap';
import { updateViewableRectangle } from '../viewport';
import { applyElementTransformFromPartialData } from '../element-transform';
import { handleMessageReceived } from './voice-control';
import { cameraHandMove, DefaultHandType } from '../camera-hand';
import { setVideoVolume } from '../spatial-audio';
import { emitParticles } from '../particles';
import log from '../log';
import { playNote, stopPlayingNote } from '../util/synthesizer-util';
import eventBus, { userTyping } from '../event-bus';
import { updateCaptionText } from '../util/camera-util';

const MessageTypes = {
  MoveElement: 1,
  Reaction: 2,
  CameraHand: 3,
  ResizeElement: 4,
  RotateElement: 5,
  DrawPoint: 6,
  MoveCursor: 7,
  EmitParticles: 8,
  SetBitrate: 9,
  TextDiff: 10,
  Typing: 11,
  RoomEffect: 12,
  PhotoBoothCountdown: 13,
  PlayNote: 14,
  Caption: 15,
  ReactionDirected: 17,
};

export default class Events {
  constructor(publisherHandle, myusername) {
    if (!publisherHandle) throw new Error('Cannot initialize Events without a publisher handle.');
    this.publisherHandle = publisherHandle;
    this.myusername = myusername;
    this.previousMoveEvent = {};
    this.previousResizeEvent = {};
    this.previousRotateEvent = {};
    this.previousCX = 0;
    this.previousCY = 0;
    this.outbuf = [];
  }

  // previousMoveEvent, sendData
  sendElementMoved(elementId, x, y) {
    if (this.previousMoveEvent.e === elementId && this.previousMoveEvent.x === x && this.previousMoveEvent.y === y) {
      return;
    }
    const data = { e: elementId, x, y };
    this.sendData(MessageTypes.MoveElement, data);
    this.previousMoveEvent = data;
  }

  sendCameraHand(x, y, handType) {
    const type = handType || DefaultHandType;
    this.sendData(MessageTypes.CameraHand, { handX: x, handY: y, handType: type });
  }

  sendVoiceControl(voiceControlFunc) {
    this.sendData(MessageTypes.RoomEffect, { voiceControlFunc });
  }

  sendPhotoBoothCountdown(message, duration) {
    this.sendData(MessageTypes.PhotoBoothCountdown, {
      photo: {
        m: message,
        t: duration,
        x: window.canvasOffsetX,
        y: window.canvasOffsetY,
        w: window.innerWidth,
        h: window.innerHeight,
      },
    });
  }

  sendReaction(reaction, x, y) {
    this.sendData(MessageTypes.Reaction, { r: reaction, x, y });
  }

  sendReactionDirected(reaction, { x, y, xDest, yDest, doesHit }) {
    this.sendData(MessageTypes.ReactionDirected, { r: reaction, x, y, xDest, yDest, doesHit });
  }

  // previousResizeEvent, sendData
  sendElementResized(elementId, x, y, w, h, time = null) {
    if (
      this.previousResizeEvent.e === elementId &&
      this.previousResizeEvent.x === x &&
      this.previousResizeEvent.y === y &&
      this.previousResizeEvent.w === w &&
      this.previousResizeEvent.h === h
    ) {
      return;
    }
    const data = {
      e: elementId,
      x,
      y,
      w,
      h,
    };
    if (time) {
      data.t = time;
    }
    this.sendData(MessageTypes.ResizeElement, data);
    this.previousResizeEvent = data;
  }

  sendElementRotated(elementId, angle) {
    if (this.previousRotateEvent.e === elementId && this.previousRotateEvent.a === angle) {
      return;
    }
    const data = {
      e: elementId,
      a: angle,
    };

    this.sendData(MessageTypes.RotateElement, data);
    this.previousRotateEvent = data;
  }

  // sendData
  sendDrawPoint(elementId, lineId, color, x, y, drawState) {
    this.sendData(MessageTypes.DrawPoint, {
      e: elementId,
      l: lineId,
      c: color,
      ds: drawState,
      x,
      y,
    });
  }

  // previousCX, previousCY, sendData
  sendCursorLocation(x, y) {
    x = Math.round(x);
    y = Math.round(y);
    if (x !== this.previousCX || y !== this.previousCY) {
      this.sendData(MessageTypes.MoveCursor, { cx: x, cy: y });
      this.previousCX = x;
      this.previousCY = y;
    }
  }

  /*
Data:
- shapes: ["star", "roundedSquare", "circle", "rectangle", "square"]
- rect: [x, y, width, height]
- count: [rangeLow, rangeHigh]
- color: [color1, color2, color3...]
- gravity: true/false
*/
  sendParticleEffect(particles) {
    this.sendData(MessageTypes.EmitParticles, { particles });
  }

  // sendData
  sendMarkupDiff(elementId, diff) {
    this.sendData(MessageTypes.TextDiff, { e: elementId, md: diff });
  }

  sendCaption(userId, text) {
    this.sendData(MessageTypes.Caption, { captionData: { userId, text } });
  }

  handleDataMessage(sender, data) {
    if (data.length) {
      data.forEach((datum) => {
        this.handleIndividualDataMessage(sender, datum);
      });
    } else {
      this.handleIndividualDataMessage(sender, data);
    }
  }

  handleIndividualDataMessage(sender, data) {
    const elementId = data.e;

    if (data.particles) {
      emitParticles(data.particles);
      return;
    }

    if (data.voiceControlFunc) {
      handleMessageReceived(data.voiceControlFunc);
      return;
    }

    if (data.fq) {
      if (data.p) {
        playNote(data.e, data.fq);
      } else {
        stopPlayingNote(data.e, data.fq);
      }

      return;
    }

    // Feed/TextChannel typing indicator event
    if (data.ts) {
      eventBus.dispatch(userTyping, { userId: sender, typingStatus: data.ts, target: data.t });
      return;
    }

    if (data.handX) {
      cameraHandMove(sender, data.handX, data.handY, data.handType);
      return;
    }

    if (data.captionData) {
      updateCaptionText(data.captionData.userId, data.captionData.text);
      return;
    }

    // Element movement
    if (data.l) {
      // line drawing
      didReceiveLinePoint(elementId, data.l, data.c, data.x, data.y, data.ds);
    } else if (elementId) {
      // other element-based transformations
      if (data.md) {
        // Markup diff
        window.elementHandlers[elementId]?.applyMarkupDiff(data.md);
        return;
      }

      const element = $(`#${elementId}`);
      if (element.length > 0) {
        if (data.t) {
          element[0].style.transition = `all ${data.t}s ease`;
        } else {
          element[0].style.transition = null;
        }
        const { x, y, a } = data;
        if ((x && y) || a) {
          const transformData = {};
          if (x && y) {
            transformData.center = [x, y];
          }
          if (a) {
            transformData.rotationAngle = a;
          }
          applyElementTransformFromPartialData(element[0], transformData);
        }
        const { w, h } = data;
        if (w && h) {
          element[0].style.width = `${w}px`;
          element[0].style.height = `${h}px`;
          // TODO Why are we prefixing elementId here with element- ?
          const handler = window.elementHandlers[elementId.replace('element-', '')];
          if (handler && handler.onSizeChange) {
            handler.onSizeChange(w, h);
          }
        }

        if (x || y || w || h || a) {
          if (element.hasClass('videoElement')) {
            setVideoVolume(element[0]);
            updateWayfinders();
          }

          minimap.setNeedsUpdate();
        } else {
          // Delegate to the individual handler, if exists
          const handler = window.elementHandlers[elementId.replace('element-', '')];
          if (handler && handler.dataReceived) {
            handler.dataReceived(data);
          } else {
            log.debug(`Data received for ${elementId} that I don't know what to do with`);
          }
        }
      } else {
        log.warn(`No element found with id ${elementId} but I'm receiving data for it`);
      }
    } else if (data.photo) {
      photoTimerShouldStart(data.photo.t, data.photo.m);
    } else if (data.cx !== undefined && data.cy !== undefined) {
      showCursor(sender, data.cx, data.cy);
    } else if (data.mt === MessageTypes.Reaction) {
      // Reaction
      startReaction(data.r, data.x, data.y);
    } else if (data.mt === MessageTypes.ReactionDirected) {
      startDirectedReaction(data.r, data);
    } else if (data.summon) {
      log.debug('Received Summon');
      updateViewableRectangle(data.summon.x, data.summon.y, data.summon.w, data.summon.h);
    } else {
      log.warn('Unknown data: ', JSON.stringify(data));
    }
  }

  // synthesizer
  playSynthesizerSound(elementId, soundFrequency, play) {
    const data = {
      e: elementId,
      fq: soundFrequency,
      p: play,
    };

    this.sendData(MessageTypes.PlayNote, data);
  }

  /**
   *
   * @param {object} typingProps
   * @param {string} typingProps.typingStatus - can be TYPING_ACTIVE (user is still typing) or TYPING_FINISHED (after user sent message)
   * @param {string} typingProps.target - can be TYPING_TARGET_FEED (and TYPING_TARGET_DM in future). id of the TextChannel instead
   */
  sendTyping({ typingStatus, target }) {
    const data = {
      ts: typingStatus,
      t: target,
    };

    this.sendData(MessageTypes.Typing, data);
  }

  /* Internal stuff */

  // videoroom, myusername
  /**
   *
   * @param {number} messageType see MessageTypes
   * @param {object} data
   * @returns
   */
  sendData(messageType, data) {
    if (!this.publisherHandle || !this.publisherHandle.webrtcStuff.pc) {
      return;
    }

    this.outbuf.push({ mt: messageType, ...data });

    if (!this.dataOutTimer) {
      this.dataOutTimer = setTimeout(() => {
        this.dataOutTimer = null;
        const json = JSON.stringify({
          sender: this.myusername,
          data: this.outbuf.length > 1 ? this.outbuf : this.outbuf[0],
        });
        this.publisherHandle.data({ data: json });
        this.outbuf = [];
      }, 5);
    }
  }
}
