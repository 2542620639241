import firebase, { api, authedApiInstance, db } from './firebase';

// utils
import { isGoogleDocURL, getGoogleDocType } from './util/gsuite-util';
import { playSoundEffect } from './util/sound-fx-util';
import { track } from './util/analytics-util';

// constants
import { PLAY_SOUND_FX } from './constants/analytics-events/sound-fx-events';

// styles
import '../styles/message.less';
import { compareStringsIgnoringSpacesAndCase } from './util/string-util';
import { CHAT_TYPE, EMBED_MESSAGE_CREATED } from './constants/analytics-events/dm-events';

export const JOIN_HUB_SYS_MSG = 'has joined the hub';
export const ENTER_ROOM_SYS_MSG = 'is here!';

export async function handleURLSummary(text, docRef) {
  const urlRegex = /(https?:\/\/[^ ]*)/;
  const url = text.match(urlRegex);
  const { currentUser } = firebase.auth();

  if (url) {
    if (isGoogleDocURL(url[1])) {
      const docType = getGoogleDocType(url[1]);
      docRef.update({
        creator: currentUser.uid,
        type: 'text',
        text,
        linkSummary: { description: `Add this Google ${docType} to your room.` },
        url: url[1],
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      return;
    }

    const { data } = await authedApiInstance.get(`${api}/linkSummary`, { params: { q: url[1] } });
    await docRef.update({
      creator: currentUser.uid,
      type: 'text',
      text,
      linkSummary: Object.keys(data).length > 0 ? data : null,
      url: url[1],
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    if (data?.embed) {
      track(EMBED_MESSAGE_CREATED, {
        type: data.embed.type,
        title: data.title,
        creator: data.author,
        source: CHAT_TYPE.ROOM_FEED,
      });
    }
  }
}

export function handleMessageSoundFx(roomChatSounds, message) {
  const candidates = roomChatSounds.filter((sound) => compareStringsIgnoringSpacesAndCase(sound.trigger, message));
  const randomSound = candidates[Math.floor(Math.random() * candidates.length)];
  if (randomSound && randomSound.isEnabled !== false) {
    playSoundEffect(randomSound.soundUrl);
    track(PLAY_SOUND_FX, { soundType: randomSound.isDefault ? 'default' : 'custom', trigger: 'room chat message' });
  }
}

export function addSystemMessage(message, deletedElementId = null) {
  const { currentUser } = firebase.auth();

  db.collection('boards')
    .doc(window.currentBoardId)
    .collection('feedItems')
    .add({
      creator: currentUser.uid,
      type: 'system',
      text: `${message}`,
      deletedElementId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
}
