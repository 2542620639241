import styled, { css } from 'styled-components';

// components
import { whiteShimmerAnimationMixin } from '../mixins';

export const vibePickerOptionMixin = () => css`
  height: 100%;
  width: 100%;
  aspect-ratio: 2 / 1;
  border-radius: 20px;
`;

const outlineWeightPx = 4;
export const clickableVibePickerOptionMixin = ({ isActive }: { isActive: boolean }) => css`
  ${vibePickerOptionMixin()}

  outline: ${isActive ? `${outlineWeightPx}px solid white` : 'none'};
  outline-offset: -${outlineWeightPx}px;
`;

export const uploadedImageContainerMixin = (isActive?: boolean) => css`
  position: relative;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  box-shadow: 0 0 0 0 white;
  border-radius: 20px;
  outline-offset: -${outlineWeightPx}px;
  cursor: pointer;

  ${isActive === false && 'opacity: 0.6;'}

  &:hover {
    outline: ${outlineWeightPx}px solid white;
    box-shadow: 0 0 20px 0 white;
    transform: scale(1.05);
    opacity: 1;
  }
`;

export const UploadedImageContainer = styled.div<{ isActive: boolean }>`
  ${({ isActive }) => uploadedImageContainerMixin(isActive)}
`;

export const Image = styled.img<{ isActive: boolean; imageLoaded: boolean }>`
  ${({ isActive }) => clickableVibePickerOptionMixin({ isActive })}

  display: ${({ imageLoaded }) => (imageLoaded ? 'block' : 'none')};
  object-fit: cover;
`;

export const LoadingStub = styled.div<{ imageLoaded: boolean }>`
  display: ${({ imageLoaded }) => (imageLoaded ? 'none' : 'block')};

  ${vibePickerOptionMixin()}
  ${whiteShimmerAnimationMixin()}
`;

// Pulled out of VibePicker in order to share with GroupsVibePicker

export const ContainerBackgroundGradient = styled.div<{
  isActive: boolean;
  primaryBackground?: string;
  secondaryBackground?: string;
  isCustomVibe: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: ${({ isActive, isCustomVibe }) => (isActive || isCustomVibe ? 1 : 0)};
  transition: opacity 0.25s ease-in-out;
  background: ${({ primaryBackground, isCustomVibe }) => {
    if (isCustomVibe) {
      return 'linear-gradient(44.34deg, #B501B080 18.12%, #2A07F480 85.9%)';
    }

    if (primaryBackground) {
      return 'var(--primary-background)';
    }

    return '';
  }};

  &:not(:first-child) {
    ${({ isCustomVibe }) => isCustomVibe && 'display: none;'}
  }
`;

export const CreateFirstHubScreenContainer = styled.div<{
  primaryBackground?: string;
  secondaryBackground?: string;
  isCustomVibe?: boolean;
}>`
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ primaryBackground, secondaryBackground, isCustomVibe }) => {
    if (isCustomVibe) {
      return 'linear-gradient(44.34deg, #B501B0 18.12%, #2A07F4 85.9%)';
    }

    if (primaryBackground && secondaryBackground) {
      return `linear-gradient(44.34deg, ${primaryBackground} 18.12%, ${secondaryBackground} 85.9%)`;
    }

    return 'linear-gradient(44deg, #3c328f 18.12%, #eb4288 85.9%)';
  }};
`;

export const VibePickerContent = styled.div`
  position: relative;
  width: 100%;
  /* z-index should be higher that z-index of ContainerBackgroundGradient elements  */
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1<{ font?: string }>`
  color: var(--primary-foreground, #6b1be2);
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
`;

export const Subtitle = styled.h2<{ font?: string }>`
  margin-top: 13px;
  color: var(--primary-foreground, #101010b2);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
`;

export const VibeRoomsContainer = styled.div`
  margin-top: 38px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  gap: 35px;

  @media (max-width: 1040px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
`;

export const Logo = styled.img.attrs({
  src: '/images/misc/here-logo-animated.gif',
  alt: 'here.fm logo',
})`
  position: fixed;
  top: 20px;
  left: 40px;
  height: 10vh;
`;
