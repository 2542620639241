export enum TipsAndTricks {
  ADD_ROOM_DECORATION = 'room decoration',
}

export interface TipsAndTricksState {
  tipsAndTricks: TipsAndTricks[];
}

export const initialState: TipsAndTricksState = {
  tipsAndTricks: [],
};
