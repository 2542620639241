import party from 'party-js';
import { canvasToScreenCoords } from './util/canvas-util';

/**
 * Particle data (all optional):
 * color: Array of hex color options
 * count: 2-element array of low / high count for particles
 * speed: 2-element array of low / high for speed
 * lifetime: 2-element array of low/high for lifetime
 * shapes: array of shape options:
 *   square, rectangle, circle, roundedSquare, roundedRectangle, star
 * gravity: boolean
 */

export function emitParticles(particles) {
  const [x, y] = canvasToScreenCoords(particles.rect[0], particles.rect[1]);
  const rect = new party.Rect(x, y, particles.rect[2] * window.canvasScale, particles.rect[3] * window.canvasScale);
  const color = particles.color ? particles.color.map((c) => party.Color.fromHex(c)) : null;
  const count = particles.count ? party.variation.range(particles.count[0], particles.count[1]) : null;
  const speed = particles.speed ? party.variation.range(particles.speed[0], particles.speed[1]) : null;
  const lifetime = particles.lifetime ? party.variation.range(particles.lifetime[0], particles.lifetime[1]) : null;

  const opts = {};
  if (color) opts.color = color;
  if (count) opts.count = count;
  if (speed) opts.speed = speed;
  if (lifetime) opts.lifetime = lifetime;
  if (particles.shapes) opts.shapes = particles.shapes;

  if (particles.gravity) {
    party.confetti(rect, opts);
  } else {
    party.sparkles(rect, opts);
  }
}

(() => {
  party.resolvableShapes.heart = '<span>♥</span>️';
})();
