import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../store/Wrapper';
import TipsAndTricksManager from './TipsAndTricksManager';

export const renderTipsAndTricks = () => {
  let root = document.getElementById('tips-and-tricks-root');
  if (!root) {
    root = document.createElement('div');
    root.id = 'tips-and-tricks-root';
    document.body.appendChild(root);
  }

  ReactDOM.render(
    <WithRoomStore>
      <TipsAndTricksManager />
    </WithRoomStore>,
    root
  );
};
