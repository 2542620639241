import { createSelector } from '@reduxjs/toolkit';

// types
import { RootState } from '../../../definitions/store';
import { WebLobbyTabs } from '../../../definitions/web-lobby';

//
import { selectCurrentUser } from '../users/selectors';
import { selectCurrentScreenId } from '../signing-in/selectors';
import { HubsOnboardingScreens } from '../../sign-in-flow/constants';

export const selectAllTabs = (state: RootState) => state.webLobby.allTabs;

export const selectTabsList = createSelector(selectCurrentUser, selectAllTabs, (currentUser, allTabs) =>
  currentUser.username ? allTabs : allTabs.filter((t) => t !== WebLobbyTabs.UserPage)
);

export const selectActiveTab = (state: RootState) => state.webLobby.activeTab;
export const selectActiveTabIndex = createSelector(selectTabsList, selectActiveTab, (tabsList, activeTab) =>
  tabsList.findIndex((t) => t === activeTab)
);

export const selectPrevActiveTab = (state: RootState) => state.webLobby.prevActiveTab;
export const selectPrevActiveTabIndex = createSelector(selectTabsList, selectPrevActiveTab, (tabsList, prevActiveTab) =>
  tabsList.findIndex((t) => t === prevActiveTab)
);

export const selectActiveModal = (state: RootState) => state.webLobby.activeModal;

export const selectVisibleRoomIds = (state: RootState) => state.webLobby.visibleRoomIds;
export const selectVisibleRoomIdsArray = createSelector([selectVisibleRoomIds], (roomIds) =>
  Object.keys(roomIds).filter((id) => roomIds[id])
);

export const selectIsRoomVisible = (state: RootState, roomId: string) => state.webLobby.visibleRoomIds[roomId];

export const selectShouldShowRooms = (state: RootState) => state.webLobby.shouldShowRooms;

export const selectActiveGroupId = (state: RootState) => state?.webLobby?.activeGroupId;

export const selectGroupSettingsCurrentTab = (state: RootState) => state.webLobby.groupSettings.currentTab;

export const selectIsHubsOnboarding = createSelector(selectCurrentScreenId, (screenId) =>
  Object.values(HubsOnboardingScreens).includes(screenId as HubsOnboardingScreens)
);
