import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// store
import {
  selectActiveReceiverId,
  selectPopupMessages,
  selectChatsWithMembers,
  selectHasMoreChats,
  selectAllUnreads,
  selectActiveGroupChatId,
} from '../../store/messaging/selectors';
import {
  deletePopupMessages,
  fetchChatsList,
  setActiveReceiverId,
  setMessagesSidebarOpen,
  setActiveGroupChatId,
  setActiveChatId,
} from '../../store/messaging/reducer';
import { selectUserById } from '../../store/users/selectors';
import { fetchEmotesRequest } from '../../store/emotes/actions.ts';

// components
import NewMessagePopover from './NewMessagePopover';
import UnreadsBadge, { UnreadsBadgeContainer } from './UnreadsBadge';
import MessagesPreviewPopover from './MessagesPreviewPopover';
import MessageSidebarAvatar from './MessageSidebarAvatar';
import ChatWindow from './chat-window/ChatWindowForMessageSidebar';
import SlideIn from '../../components/animate/SlideIn';
import PopIn from '../../components/animate/PopIn';

// util
import { orderObjectsByDateField } from '../../../util/date-util';
import { track } from '../../../util/analytics-util';
import { playSoundEffect, soundTypes } from '../../../util/sound-fx-util';

// constants
import { MAX_CHATS_IN_PAGE } from '../../../constants/chat-constants';

// icons
import PlaneIcon from '../../../../assets/icons/plane.svg';
import ChevronIcon from '../../../../assets/icons/arrows/triangle-arrow-right.svg';
import MessageSidebarGroupIcon from './MessageSidebarGroupIcon';

const MessagesSidebar = ({ messagesOpen }) => {
  const dispatch = useDispatch();
  const percentAwayFromBottom = 4;

  useEffect(() => {
    if (messagesOpen) {
      dispatch(fetchChatsList({ isPagination: true }));
      track('Open DMs Sidebar');
    } else {
      track('Close DMs Sidebar');
    }
  }, [messagesOpen, dispatch]);

  useEffect(() => {
    dispatch(fetchEmotesRequest());
  }, [dispatch]);

  const activeReceiverId = useSelector(selectActiveReceiverId);
  const dmChatsToDisplay = useSelector(selectChatsWithMembers);

  const sortedChats = orderObjectsByDateField(dmChatsToDisplay, 'updatedAt');

  const popoverMessagesList = useSelector(selectPopupMessages);

  const activeChatMember = useSelector((state) => selectUserById(state, activeReceiverId));
  const activeGroupChatId = useSelector(selectActiveGroupChatId);

  const allUnreadMessages = useSelector(selectAllUnreads);
  const hasMoreChats = useSelector(selectHasMoreChats);

  const loadMoreChats = useCallback(() => {
    dispatch(fetchChatsList({ isPagination: true }));
  }, [dispatch]);

  const onAvatarClick = (chat) => {
    const receiverId = chat.member.userId;
    if (activeReceiverId === receiverId) {
      dispatch(setActiveReceiverId({ activeReceiverId: null }));
      dispatch(setActiveChatId({ activeChatId: null }));
    } else {
      dispatch(setActiveReceiverId({ activeReceiverId: receiverId, source: 'MessagesSidebar' }));
      dispatch(deletePopupMessages());
    }
  };

  const onGroupChatClick = (chatId) => {
    dispatch(setActiveGroupChatId({ activeGroupChatId: chatId }));
    dispatch(setActiveChatId({ activeChatId: chatId }));
    dispatch(deletePopupMessages());
  };

  const onSelectedGroupChatClick = () => {
    dispatch(setActiveGroupChatId({ activeGroupChatId: null }));
    dispatch(setActiveChatId({ activeChatId: null }));
  };

  const onSelectedChatClick = () => {
    dispatch(setActiveReceiverId({ activeReceiverId: null }));
    dispatch(setActiveChatId({ activeChatId: null }));
  };

  const onMessagesToggleClick = () => {
    dispatch(setMessagesSidebarOpen({ messagesSidebarOpen: !messagesOpen }));

    if (activeReceiverId) {
      dispatch(setActiveReceiverId({ activeReceiverId: null }));
      dispatch(setActiveChatId({ activeChatId: null }));
    }
  };
  const dmsSidebarRef = useRef();
  const onScroll = () => {
    if (dmsSidebarRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = dmsSidebarRef.current;
      // start fetching for more chats once you have scrolled down 96% to the bottom
      const scrollLeft = (Math.abs(scrollHeight - scrollTop - clientHeight) / scrollHeight) * 100; // percentage
      if (scrollLeft < percentAwayFromBottom && hasMoreChats) {
        loadMoreChats();
      }
    }
  };

  const getAvatars = (
    <AvatarsContainer hasActiveChat={Boolean(activeReceiverId)} ref={dmsSidebarRef} onScroll={onScroll}>
      {sortedChats.map((c, i) =>
        c.isDm ? (
          <SlideIn time={`${((i % MAX_CHATS_IN_PAGE) + 1) * 0.1}s`} key={c.id}>
            <MessageSidebarAvatar user={c.member} unreadCount={c.unreadCount} onClick={() => onAvatarClick(c)} />
          </SlideIn>
        ) : (
          <SlideIn time={`${((i % MAX_CHATS_IN_PAGE) + 1) * 0.1}s`} key={c.id}>
            <MessageSidebarGroupIcon chatId={c.id} isActive={false} onClick={() => onGroupChatClick(c.id)} />
          </SlideIn>
        )
      )}
    </AvatarsContainer>
  );

  const MessagesButtonIcon = (
    <IconsContainer>
      <PlaneIconContainer sidebarOpen={messagesOpen}>
        <PlaneIcon />
      </PlaneIconContainer>
      <ChevronIconContainer sidebarOpen={messagesOpen}>
        <ChevronIcon />
      </ChevronIconContainer>
    </IconsContainer>
  );

  return (
    <>
      <div uk-tooltip="title: Messages; pos: right; offset: 20" style={{ margin: '10px' }}>
        <MessagesPreviewPopover displayMessages messagesList={popoverMessagesList}>
          <MessagesButton sidebarOpen={messagesOpen} onClick={onMessagesToggleClick}>
            {!messagesOpen && allUnreadMessages > 0 && <UnreadsBadge unreadsCount={allUnreadMessages} />}
            {MessagesButtonIcon}
          </MessagesButton>
        </MessagesPreviewPopover>
      </div>

      {messagesOpen && (
        <MessagesOpenContainer id="messages-open-container">
          <SlideIn time="0.2s">
            <div uk-tooltip="title: New Message; pos: right; offset: 10">
              <NewMessagePopover isMessagesSidebar />
            </div>
          </SlideIn>
          {activeGroupChatId && !activeReceiverId && (
            <ChatWindow
              triggerContent={
                <ActiveChatContainer>
                  <PopIn time="0.3s" onPopInTrigger={activeGroupChatId} onPopIn={() => playSoundEffect(soundTypes.POP)}>
                    <MessageSidebarGroupIcon
                      chatId={activeGroupChatId}
                      key={activeGroupChatId}
                      isActive
                      onClick={onSelectedGroupChatClick}
                    />
                  </PopIn>
                  <Divider />
                </ActiveChatContainer>
              }
            />
          )}
          {activeReceiverId && !activeGroupChatId && (
            <ChatWindow
              triggerContent={
                activeChatMember ? (
                  <ActiveChatContainer onClick={onSelectedChatClick}>
                    <PopIn
                      time="0.3s"
                      onPopInTrigger={activeReceiverId}
                      onPopIn={() => playSoundEffect(soundTypes.POP)}
                    >
                      <MessageSidebarAvatar user={activeChatMember} isActiveChat />
                    </PopIn>
                    <Divider />
                  </ActiveChatContainer>
                ) : null
              }
            />
          )}
          {getAvatars}
        </MessagesOpenContainer>
      )}
    </>
  );
};

export default MessagesSidebar;

MessagesSidebar.propTypes = {
  messagesOpen: PropTypes.bool.isRequired,
};

const MessagesButton = styled.button`
  width: 46px;
  height: 46px;
  display: block;

  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;

  background-color: ${({ sidebarOpen }) => (sidebarOpen ? '#12002d7e' : '#ffffff')};
  transition: all 0.1s ease-in;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }

  ${UnreadsBadgeContainer} {
    position: absolute;
    margin-left: 27px;
    margin-top: -7px;
  }
`;

const IconsContainer = styled.div`
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlaneIconContainer = styled.div`
  svg {
    path {
      fill: ${({ sidebarOpen }) => (sidebarOpen ? '#ffffff' : '#12002D')};
    }
  }
`;

const ChevronIconContainer = styled.div`
  svg {
    transform: ${({ sidebarOpen }) => (sidebarOpen ? 'rotate(180deg)' : '')};
    transition: transform 0.5s ease;

    path {
      fill: ${({ sidebarOpen }) => (sidebarOpen ? '#ffffff' : '#12002D')};
      stroke: ${({ sidebarOpen }) => (sidebarOpen ? '#ffffff' : '#12002D')};
      transition: fill 0.5s ease, stroke 0.5s ease;
    }
  }
`;

const AvatarsContainer = styled.div`
  padding: 10px 7px 100px 5px;
  width: 55px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: visible;
  overflow-y: auto;
  opacity: ${({ hasActiveChat }) => (hasActiveChat ? '0.7' : '1')};

  // no scrollbar:
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const MessagesOpenContainer = styled.div`
  height: fit-content;
  max-height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
`;

const ActiveChatContainer = styled.div`
  padding-left: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Divider = styled.div`
  border: 1px solid #ffffff;
  opacity: 0.5;
`;
