import BoardElement from './board-element';
import wrapElement from './element-wrapper';
import firebase, { db } from './firebase';
import { handleWheelEvent } from './viewport';
import { screenToCanvasCoords } from './util/canvas-util';
import log from './log';
import { htmlToElement } from './util';
import { elementMoved } from './drag';

import '../styles/embedded-element.less';
import { offUserListChanged, onUserListChanged } from './presence';
import { onSnapshot, offSnapshot } from './firestore-watcher';
import { emitParticles } from './particles';
import { getElementPosition } from './element-transform';
import { addSystemMessage } from './message-util';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';
import { USER_CARD_BOARD_TYPE } from './constants/board-constants';
/**
 * Messages:
 *
 * To the webview:
 * - init
 * - dataUpdated
 * - activeUsersUpdated
 *
 * From the webview:
 * - updateData
 * - emitParticles
 *
 */

export default class EmbeddedElement extends BoardElement {
  constructor(elementId) {
    super(elementId);
    this.onUserChange = this.onUserChange.bind(this);
    this.activeUsers = {};
    this.messageQueue = [];
  }

  handleUpdate(element, elementDoc) {
    this.elementData = elementDoc.data();
    const data = elementDoc.data();
    this.sendMessage({ message: 'dataUpdated', newData: data.class === 'EmbeddedElement' ? data.appData : data });
    return true;
  }

  teardownObservers() {
    Object.keys(this.activeUsers).forEach((uid) => {
      offSnapshot(`/userProfiles/${uid}`, this.onUserChange);
    });
    offUserListChanged(window.currentBoardId, this.updateUserList);
  }

  sendMessage(message) {
    message.creator = this.creator;
    message.creatorName = this.creatorName;
    if (this.isIFrameLoaded) {
      const container = document.getElementById(`element-${this.elementId}`);
      if (container) {
        const iframe = container.querySelector('iframe');
        iframe.contentWindow.postMessage(message);
      } else {
        // The element has been destroyed. Do teardown stuff.
        this.teardownObservers();
      }
    } else {
      this.messageQueue.push(message);
    }
  }

  onUserChange(userInfo) {
    if (this.activeUsers[userInfo.id]) {
      const userData = { ...this.activeUsers[userInfo.id] };
      userData.avatar = userInfo.avatar;
      this.activeUsers[userInfo.id] = userData;
      if (userInfo.displayName) {
        this.activeUsers[userInfo.id].name = userInfo.displayName;
      }
    }
    this.sendMessage({
      message: 'activeUsersUpdated',
      activeUsers: this.activeUsers,
    });
  }

  updateUserList(users) {
    if (this.activeUsers) {
      Object.keys(this.activeUsers).forEach((uid) => {
        offSnapshot(`/userProfiles/${uid}`, this.onUserChange);
      });
    }

    const newActiveUsers = {};
    if (users) {
      Object.keys(users).forEach((uid) => {
        onSnapshot(`/userProfiles/${uid}`, this.onUserChange);

        // Update new user list with any known avatars
        newActiveUsers[uid] = users[uid];
        if (this.activeUsers[uid] && this.activeUsers[uid].avatar) {
          newActiveUsers[uid].avatar = this.activeUsers[uid].avatar;
        }
      });

      this.activeUsers = newActiveUsers;

      this.sendMessage({
        message: 'activeUsersUpdated',
        activeUsers: this.activeUsers,
      });
    }
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(__elementId, elementDoc, boardId) {
    this.elementData = elementDoc.data();
    onUserListChanged(boardId, this.updateUserList.bind(this));
  }

  setupOverlay(element) {
    const overlay = element.querySelector('.embedded-overlay');
    overlay.addEventListener('click', () => {
      if (elementMoved()) {
        return;
      }

      window.analytics.track('Focus', { element: this.elementType });
      const iframe = element.querySelector('iframe');
      if (iframe) {
        iframe.focus();
      }

      element.querySelector('.underlay').classList.add('embedded-focused');
      const iframeBody = element
        .querySelector('.embedded-iframe')
        .contentWindow.document.querySelector('.embedded-body');
      iframeBody.classList.add('embedded-body-focused');
      overlay.style.display = 'none';
      document.addEventListener('mouseup', this.onMouseUp.bind(this));
    });
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();
    this.url = data.url;
    this.preserveAspectRatio = data.preserveAspectRatio;
    this.elementName = data.elementName;
    this.minimumSize = data.minSize;
    this.creator = data.creator;
    this.creatorName = data.creatorName;

    const container = htmlToElement(`
      <div class="embedded-container">
        <div class="underlay"></div>
        <iframe class="embedded-iframe"></iframe>
        <div class="embedded-overlay"></div>
      </div>
    `);

    const iframe = container.querySelector('iframe');
    iframe.addEventListener('load', () => {
      try {
        this.isIFrameLoaded = true;

        iframe.contentWindow.addEventListener('wheel', handleWheelEvent, { passive: false });
        window.addEventListener('message', this.handleMessage.bind(this));

        this.sendMessage(
          {
            message: 'init',
            elementId: this.elementId,
            newData: data.class === 'EmbeddedElement' ? data.appData : data,
            currentUser: firebase.auth().currentUser.uid,
          },
          '*'
        ); // TODO can we do better with targetOrigin security?

        // Flush any messages in queue
        if (this.messageQueue.length > 0) {
          this.messageQueue.forEach((message) => {
            this.sendMessage(message);
          });
          this.messageQueue = [];
        }
      } catch (e) {
        log.error("Failed to capture wheel event, iframe probably doesn't support cross domain events", e);
      }
    });
    iframe.src = this.url;

    const element = wrapElement(container, elementDoc, {
      preserveAspectRatio: this.preserveAspectRatio,
    });
    this.setupOverlay(element);

    return element;
  }

  elementDescription() {
    return this.elementName;
  }

  minSize() {
    return this.minimumSize;
  }

  static async addElement(url, additionalProperties = {}) {
    const { name } = additionalProperties;
    delete additionalProperties.name;

    const center =
      additionalProperties.center || screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2 - 200);
    delete additionalProperties.center;
    const size = additionalProperties.size || [350, 500];
    const { currentUser } = firebase.auth();
    const boardRef = db.collection('boards').doc(window.currentBoardId);
    const boardData = (await boardRef.get()).data();
    await boardRef.collection('elements').add({
      class: 'EmbeddedElement',
      center,
      creator: currentUser.uid,
      creatorName: currentUser.displayName,
      size,
      elementName: name,
      url,
      zIndex: window.getFrontZIndex(),
      ...additionalProperties,
    });

    const destination =
      boardData.type === USER_CARD_BOARD_TYPE ? ADD_ELEMENT_DESTINATION_TYPES.CARD : ADD_ELEMENT_DESTINATION_TYPES.ROOM;
    if (name) {
      addSystemMessage(`added ${name}`);
      track(ADD_ELEMENT, { element: name, url, destination });
    } else {
      track(ADD_ELEMENT, {
        element: ELEMENT_TYPES.EMBEDDED,
        url,
        destination,
      });
    }
  }

  // Handler-specific

  // Convert x,y,width,height params and return the converted rect.
  iFrameToCanvasCoords(rect) {
    const container = document.getElementById(`element-${this.elementId}`);
    const [x1, y1] = getElementPosition(container);
    return [x1 + rect.x, y1 + rect.y, rect.width, rect.height];
  }

  handleMessage(message) {
    const { data } = message;
    if (data.elementId !== this.elementId) {
      return;
    }

    switch (data.event) {
      case 'updateData': {
        if (this.elementData.class === 'EmbeddedElement') {
          db.doc(`/boards/${window.currentBoardId}/elements/${this.elementId}`).update({ appData: data.data });
        } else {
          db.doc(`/boards/${window.currentBoardId}/elements/${this.elementId}`).update(data.data);
        }
        break;
      }
      case 'emitParticles': {
        const particleData = { ...data.particles };
        particleData.rect = this.iFrameToCanvasCoords(particleData.rect);
        emitParticles(particleData);
        window.rtc.sendParticleEffect(particleData);
        break;
      }
      default: {
        log.warn('Unrecognized event from child window');
      }
    }
  }

  onMouseUp(e) {
    const el = document.getElementById(`element-${this.elementId}`);

    // if the target of the click isn't the container nor a descendant of the container
    if (!el || el === e.target || el.contains(e.target)) {
      return;
    }

    document.removeEventListener('mouseup', this.onMouseUp);
    el.querySelector('.underlay').classList.remove('embedded-focused');
    const iframeBody = el.querySelector('.embedded-iframe').contentWindow.document.querySelector('.embedded-body');
    iframeBody.classList.remove('embedded-body-focused');
    const overlay = el.querySelector('.embedded-overlay');
    overlay.style.display = 'block';
  }
}

EmbeddedElement.elementType = 'EmbeddedElement';
