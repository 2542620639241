import $ from 'jquery';
import UIkit from 'uikit';

import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import log from './log';
import ImageElement from './image';
import getAddElementFunctionForUrl from './widgets/embedded';
import FileElement from './file';
import PdfElement from './widgets/embedded/pdf';
import { addUserCanAddContentSubscriber, getUserCanAddContent, isElementsInteractionAllowed } from './roles-management';
import { createNewFilename, getFileTypeByExtension, htmlToElement, isImage } from './util';
import { copyElementsRequest } from './react/store/template/api';
import { fileUploadErrorTypes } from './constants/chat-constants';

import '../styles/upload-notification.less';
import { getCurrentBoardIsUserCard } from './util/room-util';
import { DEFAULT_WIDGET_PARAMS } from './constants/user-card-constants';
import { track } from './util/analytics-util';
import { UPLOAD_GROUP_BACKGROUND_IMAGE } from './constants/analytics-events/groups-events.ts';
import { ADD_ELEMENT_SOURCE_TYPES } from './constants/analytics-events/element-events';

const uploadIconPath = 'images/icons/upload-white.svg';
const disableAddContentLockIcon = 'images/icons/disable-add-content-lock.svg';

// File upload stuff
const storage = firebase.storage();
const fileUploadLimitBytes = 50000000;
const fileUploadLimitMegabytes = fileUploadLimitBytes / 1000000;

const errorIconPath = '/images/icons/alert.svg';
const videoIconPath = '/images/icons/video.svg';
const audioIconPath = '/images/icons/audio.svg';
const textIconPath = '/images/icons/document.svg';
const presentationIconPath = '/images/icons/presentation.svg';
const spreadsheetIconPath = '/images/icons/spreadsheet.svg';
const imageIconPath = '/images/icons/image.svg';
const unknownIconPath = '/images/icons/unknown.svg';
const pdfIconPath = '/images/icons/pdf.svg';

// TODO: Split room-specific logic and reusable functions so we don't need to mock it
const dropArea = document.getElementById('main') || { addEventListener: () => {} };

['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
  dropArea.addEventListener(eventName, preventDefaults, false);
});

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}

['dragenter', 'dragover'].forEach((eventName) => {
  dropArea.addEventListener(eventName, highlight, false);
});
['dragleave', 'drop'].forEach((eventName) => {
  dropArea.addEventListener(eventName, unhighlight, false);
});

dropArea.addEventListener('dragenter', () => UIkit.dropdown('#sticker-select').hide(0));
if (document.getElementById('sticker-select-say-something')) {
  dropArea.addEventListener('dragenter', () => UIkit.dropdown('#sticker-select-say-something').hide(0));
}
dropArea.addEventListener('dragover', (e) => {
  e.dataTransfer.dropEffect = 'copy';
});

const fileUploadStatus = document.getElementById('file-upload-status');
let fileUploadStatusHtml = `<img src=${uploadIconPath} />  Drop File(s) Anywhere to Upload`;
addUserCanAddContentSubscriber((userCanAddContent) => {
  if (userCanAddContent) {
    fileUploadStatusHtml = `<img src=${uploadIconPath} />  Drop File(s) Anywhere to Upload`;
  } else {
    fileUploadStatusHtml = `<img src="${disableAddContentLockIcon}"> Locked by Admin!`;
  }
});

function highlight() {
  if (!isElementsInteractionAllowed()) return;

  dropArea.classList.add('highlight');
  fileUploadStatus.style.display = 'flex';
  fileUploadStatus.innerHTML = fileUploadStatusHtml;
}

function unhighlight() {
  dropArea.classList.remove('highlight');
  fileUploadStatus.style.display = 'none';
}

dropArea.addEventListener('drop', handleDrop, false);

export function showWebAppShare() {
  const elFileUploadDefault = document.querySelector('.upload-default');
  const elFileUploadShare = document.querySelector('.upload-share-web-app');
  elFileUploadShare.style.display = 'block';
  elFileUploadDefault.style.display = 'none';
  const elInput = document.getElementById('embedded-resource-url');
  elInput.value = '';
}

export function showUploadDefault() {
  const elFileUploadDefault = document.querySelector('.upload-default');
  const elFileUploadShare = document.querySelector('.upload-share-web-app');
  elFileUploadShare.style.display = 'none';
  elFileUploadDefault.style.display = 'block';
}

async function handleDrop(e) {
  if (!isElementsInteractionAllowed()) return;

  const dt = e.dataTransfer;
  const { files } = dt;

  if (files.length) {
    handleFiles(files, { x: e.clientX, y: e.clientY });
  } else {
    const dataString = e.dataTransfer.getData('application/json');
    if (!dataString) {
      return;
    }

    const data = JSON.parse(dataString);
    if (data.type === 'image') {
      const objToAdd = {
        imageURL: data.url,
        center: screenToCanvasCoords(e.clientX - data.dragX, e.clientY - data.dragY),
        size: [data.width / window.canvasScale, data.height / window.canvasScale],
        useImageProxy: data.useImageProxy || data.gifType === 'text',
      };

      if (data.mp4) {
        objToAdd.mp4 = `${data.gifType === 'text' ? `${process.env.IMAGE_PROXY_URL}/` : ''}${data.mp4}`;
      }

      if (data.webp) {
        objToAdd.webp = `${data.gifType === 'text' ? `${process.env.IMAGE_PROXY_URL}/` : ''}${data.webp}`;
      }

      ImageElement.addElement(objToAdd, 'image', ADD_ELEMENT_SOURCE_TYPES.UPLOAD_FROM_COMPUTER);
    } else if (data.type === 'roomKit') {
      const kitBoardRef = await db.collection('boards').doc(data.id).get();
      const kitBoardData = kitBoardRef.data();
      const { defaultViewport } = kitBoardData;
      const copyElementsData = {
        sourceBoardId: data.id,
        destinationBoardId: window.currentBoardId,
        createdBy: firebase.auth().currentUser.uid,
        options: {
          center: screenToCanvasCoords(e.clientX, e.clientY),
          defaultViewport,
        },
      };
      copyElementsRequest(copyElementsData);
      track('Add Room Kit', { kit: kitBoardData.title });
    }
  }
}

export function handleFiles(files, center) {
  const positionDifference = 110;
  for (let i = 0; i < files.length; i += 1) {
    const fileCenter = center
      ? {
          x: center.x + positionDifference * i,
          y: center.y + positionDifference * i,
        }
      : null;
    uploadFile(files[i], fileCenter);
  }
}

export function uploadToStorage(file, storagePath) {
  let extension;
  if (file instanceof File) {
    extension = file.name.split('.').pop();
  } else if (file instanceof Blob) {
    extension = file.type.split('/').pop();
  } else {
    log.warn('unknown file object type');
    extension = 'png'; // I'm feeling lucky
  }

  const type = getFileTypeByExtension(extension);

  let promiseResolve;
  const promise = new Promise((resolve) => {
    promiseResolve = resolve;
  });
  const uploadTask = storage.ref(storagePath).put(file);
  const notificationId = showUploadNotification(type, file.name);
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      $(`#js-progressbar-${notificationId}`)[0].value = progress;
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          $('#file-upload-status').html('Uploading Paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break;
        default:
          log.debug(`non-handled state: ${snapshot.state}`);
      }
    },
    (error) => {
      $('#file-upload-status').hide();
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      log.error('Error uploading file', error);
      throw error; // throw an error out to prevent stuff we do after a successful update
    },
    () => {
      $('#file-upload-status').hide();
      promiseResolve(uploadTask);
      setTimeout(() => {
        $(`#upload-notification-${notificationId}`).remove();
      }, 500);
    }
  );

  return promise;
}

export async function uploadBackground(
  file,
  boardId = window.currentBoardId,
  { destination, shouldAddSystemMessage, disableTracking } = { shouldAddSystemMessage: true }
) {
  const storagePath = `boards/${boardId}/backgroundImages/background`;
  const uploadTask = await uploadToStorage(file, storagePath);
  // Upload completed successfully, now we can get the download URL

  const downloadUrl = await uploadTask.ref.getDownloadURL();
  log.debug('File available at', downloadUrl);
  await db.collection('boards').doc(boardId).update({
    background: downloadUrl,
    backgroundColor: null,
  });

  if (shouldAddSystemMessage) {
    addSystemMessage('uploaded a background');
  }
  if (!disableTracking) {
    track('Upload Background Image', { destination });
  }
}

export async function uploadGroupBackground(groupId, file) {
  const storagePath = `groups/${groupId}/backgroundImages/background`;
  const uploadTask = await uploadToStorage(file, storagePath);
  const downloadUrl = await uploadTask.ref.getDownloadURL();
  log.debug('File available at', downloadUrl);
  track(UPLOAD_GROUP_BACKGROUND_IMAGE, { groupId });
  return { url: downloadUrl };
}

export async function uploadFeedBackground(file) {
  const storagePath = `boards/${window.currentBoardId}/backgroundImages/feed`;
  const uploadTask = await uploadToStorage(file, storagePath);
  const downloadUrl = await uploadTask.ref.getDownloadURL();
  log.debug('File available at', downloadUrl);
  await db.doc(`boards/${window.currentBoardId}`).update({
    feedBackgroundImg: downloadUrl,
    feedBackgroundColor: null,
  });

  addSystemMessage('uploaded a feed background');
  track('Upload Feed Background Image');
}

export async function uploadTextChannelBackground(file, textChannelId) {
  const storagePath = `boards/${window.currentBoardId}/backgroundImages/textChannels/textChannel-${textChannelId}`;
  const uploadTask = await uploadToStorage(file, storagePath);
  const downloadUrl = await uploadTask.ref.getDownloadURL();
  log.debug('File available at', downloadUrl);
  await db.doc(`boards/${window.currentBoardId}/elements/${textChannelId}`).update({
    textChannelBackgroundImg: downloadUrl,
    textChannelBackgroundColor: null,
  });

  addSystemMessage('uploaded a text channel background');
  track('Upload Text Channel Background Image');
}

function getIconData(type) {
  let notificationIconClass = '';
  let notificationIcon;
  switch (type) {
    case 'error':
      notificationIconClass = 'upload-notification-error';
      notificationIcon = errorIconPath;
      break;
    case 'image':
      notificationIconClass = 'upload-notification-image';
      notificationIcon = imageIconPath;
      break;
    case 'video':
      notificationIconClass = 'upload-notification-video';
      notificationIcon = videoIconPath;
      break;
    case 'audio':
      notificationIconClass = 'upload-notification-audio';
      notificationIcon = audioIconPath;
      break;
    case 'text':
      notificationIconClass = 'upload-notification-text';
      notificationIcon = textIconPath;
      break;
    case 'spreadsheet':
      notificationIconClass = 'upload-notification-spreadsheet';
      notificationIcon = spreadsheetIconPath;
      break;
    case 'presentation':
      notificationIconClass = 'upload-notification-presentation';
      notificationIcon = presentationIconPath;
      break;
    case 'pdf':
      notificationIconClass = 'upload-notification-pdf';
      notificationIcon = pdfIconPath;
      break;
    default:
      notificationIconClass = 'upload-notification-unknown';
      notificationIcon = unknownIconPath;
  }
  return { class: notificationIconClass, icon: notificationIcon };
}

function showUploadNotification(type, msg) {
  const iconData = getIconData(type);
  const notificationIconClass = iconData.class;
  const notificationIcon = iconData.icon;
  const notificationIdNumber = Math.random().toString(36).substring(7);
  if (typeof msg === 'undefined' || !msg) {
    msg = 'Uploading...';
  }

  document.querySelector('.notifications-container-bottom').prepend(
    htmlToElement(
      `<div class="upload-notification-container" id="upload-notification-${notificationIdNumber}">
          <div class="upload-notification-section icon-section ${notificationIconClass}">
            <img class="upload-notification-icon" src=${notificationIcon} />
          </div>
          <div class="upload-notification-section" style="width: 300px;">
              <h5 class="${
                type === 'error' ? 'message-section-error' : 'message-section'
              }" uk-tooltip="title: ${msg}">${msg}</h5>
          </div>
            ${
              type === 'error'
                ? `<div class="action-button-section"><button class="action-button uk-close-large" type="button" onclick="document.getElementById('upload-notification-${notificationIdNumber}').remove()" uk-close>
                </button>`
                : `<div class="upload-bar-section"><progress id="js-progressbar-${notificationIdNumber}" class="uk-progress upload-bar ${notificationIconClass}" value="10" max="100"></progress>`
            }
          </div>
        </div>`
    )
  );
  return notificationIdNumber;
}

let fileUploading = false;
export async function uploadFile(file, center, elementOptions = {}) {
  if (fileUploading) {
    return;
  }

  if (!getUserCanAddContent()) {
    return;
  }

  if (file.size > fileUploadLimitBytes) {
    log.error('Upload Failed: file is too big');
    const notificationId = showUploadNotification(
      'error',
      `Upload Failed: ${file.name} exceeds ${fileUploadLimitMegabytes}mb limit`
    );
    log.debug(`created upload notification: ${notificationId}`);
    track('Upload File Error', {
      reason: `File exceeds upload quota (${fileUploadLimitMegabytes}mb)`,
    });
    return;
  }

  let type;
  if (file instanceof File) {
    type = file.name.split('.').pop();
  } else if (file instanceof Blob) {
    type = file.type.split('/').pop();
  } else {
    log.warn('unknown file object type');
    type = 'png'; // I'm feeling lucky
  }

  const fileType = getFileTypeByExtension(type);
  let category;
  // TODO Move these string consts to enums
  if (fileType === 'image' || type === 'webm') {
    category = 'images';
  } else if (fileType === 'pdf') {
    category = 'pdf';
  } else {
    category = 'files';
  }

  try {
    fileUploading = true;

    let fileName = file.name;
    let availableFilename = false;
    let storagePath;
    while (!availableFilename) {
      storagePath = `boards/${window.currentBoardId}/${category}/${fileName}`;
      const ref = storage.ref(storagePath);
      let urlTest;
      try {
        urlTest = await ref.getDownloadURL();
      } catch (err) {
        if (err.code !== 'storage/object-not-found') {
          log.error(err);
        }
      }
      if (urlTest !== undefined) {
        fileName = createNewFilename(fileName, type);
      } else {
        availableFilename = true;
      }
    }

    const uploadTask = await uploadToStorage(file, storagePath);

    // Upload completed successfully, now we can get the download URL
    const fileURL = await uploadTask.ref.getDownloadURL();
    log.debug('File available at', fileURL);
    track('Upload File', { fileType: type });

    const canvasCenter = center ? screenToCanvasCoords(center.x, center.y) : null;
    switch (category) {
      case 'images':
        elementOptions.center = canvasCenter;
        if (getCurrentBoardIsUserCard()) {
          Object.assign(elementOptions, DEFAULT_WIDGET_PARAMS);
        }
        if (type === 'webm') {
          await ImageElement.addElement(
            { storagePath, imageURL: null, webm: fileURL, ...elementOptions },
            'image',
            ADD_ELEMENT_SOURCE_TYPES.UPLOAD_FROM_COMPUTER
          );
        } else {
          await ImageElement.addElement(
            { storagePath, imageURL: fileURL, ...elementOptions },
            'image',
            ADD_ELEMENT_SOURCE_TYPES.UPLOAD_FROM_COMPUTER
          );
        }
        break;
      case 'files':
        await FileElement.addElement({ storagePath, fileURL, center: canvasCenter, type, fileName });
        break;
      case 'pdf':
        await PdfElement.addElement(fileURL, { originalUrl: fileName, center: canvasCenter });
        break;
      default:
    }
    return fileURL;
  } catch (e) {
    log.error(e);
  } finally {
    fileUploading = false;
  }
}

export async function uploadAvatar(file) {
  const userId = firebase.auth().currentUser.uid;
  const extension = file.name.split('.').pop();
  const storagePath = `users/${userId}/avatars/original.${extension}`;
  const uploadTask = await uploadToStorage(file, storagePath);

  const photoURL = await uploadTask.ref.getDownloadURL();
  log.debug('File available at', photoURL);
  firebase.auth().currentUser.updateProfile({ photoURL });

  await db.collection('userProfiles').doc(userId).update({
    'avatar.original': photoURL,
    'avatar.thumbnail': null,
    'avatar.isDefault': false,
  });
}

export async function uploadUserBackgroundPhoto(file) {
  const userId = firebase.auth().currentUser.uid;
  const extension = file.name.split('.').pop();
  const storagePath = `users/${userId}/backgroundPhoto/original.${extension}`;
  const uploadTask = await uploadToStorage(file, storagePath);

  const photoURL = await uploadTask.ref.getDownloadURL();
  log.debug('File available at', photoURL);

  await db.collection('userProfiles').doc(userId).update({
    'backgroundPhoto.original': photoURL,
    'backgroundPhoto.thumbnail': null,
  });
}

export async function uploadImageToChat({ file, storageFolder }) {
  const fileExtension = file.name.split('.').pop();
  const fileName = createNewFilename(file.name, fileExtension);
  if (!isImage(fileName)) {
    throw new Error('Wrong file type!', { cause: fileUploadErrorTypes.WRONG_TYPE });
  }

  const storagePath = `${storageFolder}/${fileName}`;

  const uploadTask = await uploadToStorage(file, storagePath);
  const fileURL = await uploadTask.ref.getDownloadURL();

  return { fileURL, storagePath };
}

export async function uploadVibeImage({ file }) {
  const userId = firebase.auth().currentUser.uid;
  const fileName = createNewFilename(file.name);
  if (!isImage(fileName)) {
    throw new Error('Wrong file type!', { cause: fileUploadErrorTypes.WRONG_TYPE });
  }

  const storagePath = `users/${userId}/background-images/${fileName}`;

  const uploadTask = await uploadToStorage(file, storagePath);
  const fileURL = await uploadTask.ref.getDownloadURL();

  return { fileURL, storagePath };
}

export async function uploadChatSound({ file, storagePath }) {
  const fileName = createNewFilename(file.name);
  const filePath = `${storagePath}/${fileName}`;
  const uploadTask = await uploadToStorage(file, filePath);
  const fileURL = await uploadTask.ref.getDownloadURL();
  return { fileURL, filePath, ref: uploadTask.ref };
}

window.addEventListener('paste', (event) => {
  if (!isElementsInteractionAllowed()) return;
  const pasteText = event.clipboardData.getData('text');
  if (pasteText && !['textarea', 'input'].includes(event.target.nodeName.toLowerCase())) {
    const addElement = getAddElementFunctionForUrl(pasteText);
    if (addElement) addElement();
    return;
  }

  const { items } = event.clipboardData || event.originalEvent.clipboardData;
  // find pasted image among pasted items
  let blob = null;
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].type.indexOf('image') === 0) {
      blob = items[i].getAsFile();
      break;
    }
  }
  // load image if there is a pasted image

  if (blob !== null) {
    uploadFile(blob);
  }
});
