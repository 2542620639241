import { all, takeEvery, put, fork, delay } from 'redux-saga/effects';

import * as store from './store';

function* handleComplexAnimation({ payload: { animations, id, data } }) {
  for (let i = 0; i < animations.length; i += 1) {
    const { name, additionalData, delay: animationDelay } = animations[i];
    const animationPayload = {
      name,
      id: `${id}-${name}`,
      data: {
        ...additionalData,
        ...data,
      },
    };

    if (animationDelay) {
      yield fork(function* animationWithDelay() {
        yield delay(animationDelay);
        yield put(store.addAnimation(animationPayload));
      });
    } else {
      yield put(store.addAnimation(animationPayload));
    }
  }
}

export default function* friendsSaga() {
  yield all([takeEvery(store.addComplexAnimation, handleComplexAnimation)]);
}
