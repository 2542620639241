import log from './log';

// The current board has been changed: either the initial load or switching it.
export const boardChanged = 'boardChanged';

// The same as the previous event, but fired before the welcome modal is opened.
export const preBoardChanged = 'preBoardChanged';

// Room settings (name, bg, user permissions) were updated.
export const roomSettingsUpdated = 'roomSettingsUpdated';

// Capacity info (number of members/viewers, is cap reached) was updated.
export const capacityInfoUpdated = 'capacityInfoUpdated';

// Canvas was zoomed in or out
export const canvasScaleUpdated = 'canvasScaleUpdated';

// Host made somebody host / made somebody member / kicked somebody / banned somebody
export const userUpdated = 'userUpdated';

// Somebody started typing / finished typing in the feed
export const userTyping = 'userTyping';

// Face filter lens is selected
export const lensSelected = 'lensSelected';

// Requesting adding elements to the mural from the outside
// arguments: muralId, elementData, additionalParams
export const muralAddElementRequested = 'muralAddElementRequested';

// Requesting adding elements to HereOS board from the outside
// arguments: elementData
export const hereOsAddElementRequested = 'hereOsAddElementRequested';
// Requesting file upload
// arguments: file, center
export const hereOsImageUploadRequested = 'hereOsImageUploadRequested';

// Onboarding events
export const keyEmojisActivated = 'keyEmojisActivated';
export const headHandsActivated = 'headHandsActivated';
export const temporaryDrawingActivated = 'temporaryDrawingActivated';
export const roomCreated = 'roomCreated';
export const userCardCreated = 'userCardCreated';
export const profileEdited = 'profileEdited';
export const roomThemeChanged = 'roomThemeChanged';
export const friendInviteSent = 'friendInviteSent';

// Running room visibility events through the bus for better performance
export const roomVisibilityChanged = 'roomVisibilityChanged'; // arguments: roomId, isVisible

export const loadBoardRequested = 'loadBoardRequested';

export const renderUserProfileRequested = 'renderUserProfileRequested';
export const renderOnboardingTemplateSelectionScreenRequested = 'renderOnboardingTemplateSelectionScreenRequested';
export const openVibePickerRequested = 'openVibePickerRequested';

export const networkInformationUpdated = 'networkInformationUpdated';

export const unmountSignInModalRequested = 'unmountSignInModalRequested';

export const taggingWindowRequested = 'taggingWindowRequested';

export const socketConnected = 'socketConnected';

export class EventBus {
  constructor() {
    this.listeners = {};
  }

  on(eventName, callback) {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }

    const listeners = this.listeners[eventName];
    if (listeners.includes(callback)) {
      log.warn(`Adding the same listener twice for ${eventName}`);
      return;
    }

    listeners.push(callback);
  }

  off(eventName, callback) {
    const listeners = this.listeners[eventName];
    if (!listeners) {
      log.warn(`Trying to remove non-existent event: ${eventName}`);
      return;
    }

    const index = listeners.indexOf(callback);
    if (index === -1) {
      log.warn(`Trying to remove not added event for ${eventName}`);
      return;
    }

    listeners.splice(index, 1);
  }

  dispatch(eventName, ...args) {
    const listeners = this.listeners[eventName];
    if (!listeners) {
      return;
    }

    listeners.forEach((f) => f(...args));
  }
}

export default new EventBus();
