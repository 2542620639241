import styled, { css } from 'styled-components';

import { ellipsisTextMixin } from '../../../../mixins';

export const Container = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

interface PositionLabelProps {
  isSelf?: boolean;
}

const positionLabelSizePx = 42;
export const PositionLabel = styled.div<PositionLabelProps>`
  position: absolute;
  top: ${-positionLabelSizePx / 4}px;
  left: ${-positionLabelSizePx / 4}px;
  height: ${positionLabelSizePx}px;
  width: ${positionLabelSizePx}px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: var(--secondary-foreground, white);
  font-size: 20px;
  font-weight: bolder;
  border-radius: 100%;
  background: var(--secondary-background, #6b1be3);

  // different styles for own position if not in top 3
  ${({ isSelf }) =>
    isSelf &&
    `
    font-size: 18px;
    color: var(--secondary-foreground-alpha-50, rgba(255, 255, 255, 0.5));
  `}
`;

const avatarSizePx = 78;
const avatarMixin = () => css`
  height: ${avatarSizePx}px;
  width: ${avatarSizePx}px;
  border-radius: 100%;
  box-shadow: 0 0 1px 0px black;
`;

export const Avatar = styled.img`
  ${avatarMixin()}
`;

export const InitalIcon = styled.div`
  ${avatarMixin()}

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 27px;
  font-weight: bolder;
  background-color: #6b1be3;
`;

export const DisplayName = styled.p`
  color: var(--secondary-foreground, white);
  text-align: center;
  font-size: 14px;
  font-weight: bold;

  ${ellipsisTextMixin()}
`;

export const ActiveMinutes = styled.p`
  color: var(--secondary-foreground, white);
  font-size: 14px;
`;
