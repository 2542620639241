import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import { BoardControllerContext, BoardElementControllerContext } from '../../common/contexts';
import { editImage } from './api';
import Loader from '../../components/Loader';
import { HereSwitch } from '../../components/inputs';

interface Props {
  onClose: () => void;
}

const inputMaxLength = 200;

const RemixAIMenu = ({ onClose }: Props) => {
  const { elementData, patchDbData } = useContext(BoardElementControllerContext);
  const { boardId } = useContext(BoardControllerContext);

  const [inputValue, setInputValue] = useState('');

  const onSubmit = () => {
    setInputValue('');
    editImage({ boardId, elementId: elementData.id, prompt: inputValue });
  };

  const onToggleOriginalSwitch = useCallback(
    (value: boolean) => {
      if (value) {
        patchDbData({
          remixedImageURL: elementData.imageURL,
          webp: elementData.remixOriginalUrl.includes('.webp') ? elementData.remixOriginalUrl : null,
          imageURL: elementData.remixOriginalUrl,
        });
      } else {
        patchDbData({
          imageURL: elementData.remixedImageURL,
          webp: null,
        });
      }
    },
    [elementData.imageURL, elementData.remixOriginalUrl, elementData.remixedImageURL, patchDbData]
  );

  return (
    <Container>
      {elementData.isAIRemixingInProgress ? (
        <ProcessingOverlay>
          <Loader />
        </ProcessingOverlay>
      ) : null}
      <CloseButton className="here-button-minimal" onClick={onClose}>
        ✕
      </CloseButton>
      <div>
        {inputValue.length} / {inputMaxLength}
      </div>
      <Input
        className="themed-input"
        value={inputValue}
        maxLength={inputMaxLength}
        rows={3}
        onChange={useCallback((e) => setInputValue(e.target.value), [])}
      />
      <SubmitButton className="here-button-regular" onClick={onSubmit} disabled={!inputValue.length}>
        Submit
      </SubmitButton>
      {elementData.remixOriginalUrl ? (
        <SwitchContainer>
          <HereSwitch isOn={elementData.remixOriginalUrl === elementData.imageURL} onChange={onToggleOriginalSwitch} />
          <span>Show Original</span>
        </SwitchContainer>
      ) : null}
    </Container>
  );
};

export default RemixAIMenu;

const Container = styled.div`
  position: relative;
  width: fit-content;
  background: var(--primary-background);
  color: var(--primary-foreground);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px;
  border-radius: 10px;
`;

const Input = styled.textarea`
  padding: 4px;
  margin: 4px 0;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  padding: 4px 8px;
  width: 50%;
`;

const SwitchContainer = styled.div`
  font-size: 12px;
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

const ProcessingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;
