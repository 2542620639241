import { RoomTopMember, getRoomTopMembers } from '../../../../api/stats-api';

const SERVER_REFRESH_INTERVAL_MS = 1000 * 60 * 5;

export interface RoomTopMembersData {
  members: RoomTopMember[];
  serverRefreshFailed: boolean;
  serverRefreshInterval: ReturnType<typeof setInterval>;
  listeners: ((data: RoomTopMembersData) => void)[];
}

const roomsTopMembersData: {
  [key: string]: RoomTopMembersData;
} = {};

export const addRoomTopMembersListener = (roomId: string, listener: (data: RoomTopMembersData) => void) => {
  if (roomsTopMembersData[roomId]) {
    listener(roomsTopMembersData[roomId]);
    roomsTopMembersData[roomId].listeners.push(listener);
    return;
  }

  const refreshRoomTopMembers = async () => {
    try {
      const { data } = await getRoomTopMembers(roomId);
      roomsTopMembersData[roomId].members = data.members;
      roomsTopMembersData[roomId].serverRefreshFailed = false;
      roomsTopMembersData[roomId].listeners.forEach((l) => l(roomsTopMembersData[roomId]));
    } catch (error) {
      roomsTopMembersData[roomId].serverRefreshFailed = true;
      roomsTopMembersData[roomId].listeners.forEach((l) => l(roomsTopMembersData[roomId]));
    }
  };

  roomsTopMembersData[roomId] = {
    members: [],
    serverRefreshFailed: false,
    serverRefreshInterval: null,
    listeners: [listener],
  };

  refreshRoomTopMembers();

  roomsTopMembersData[roomId].serverRefreshInterval = setInterval(refreshRoomTopMembers, SERVER_REFRESH_INTERVAL_MS);
};

export const removeRoomTopMembersListener = (roomId: string, listener: (data: RoomTopMembersData) => void) => {
  if (!roomsTopMembersData[roomId]) {
    return;
  }

  roomsTopMembersData[roomId].listeners = roomsTopMembersData[roomId].listeners.filter((l) => l !== listener);
  if (roomsTopMembersData[roomId].listeners.length === 0) {
    clearInterval(roomsTopMembersData[roomId].serverRefreshInterval);
    delete roomsTopMembersData[roomId];
  }
};
