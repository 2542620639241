import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { BoardControllerContext, BoardElementControllerContext } from '../../common/contexts.ts';
import { svgColorMixin } from '../../mixins';
import UserAvatar from '../../components/UserAvatar';
import { openUserProfile } from '../../user-profile/utils';
import { track } from '../../../util/analytics-util';
import { AUDIO_NOTE_PAUSED, AUDIO_NOTE_PLAYED } from './analytics';
import AudioItem from '../../components/AudioItem.tsx';

const AudioNote = () => {
  const { elementData } = useContext(BoardElementControllerContext);
  // For now it's used only in murals. Later we should also check `type`
  const { groupId } = useContext(BoardControllerContext);

  const [audio, setAudio] = useState(null);
  useEffect(() => {
    const newAudio = new Audio(elementData.audioNote?.url);
    newAudio.preload = 'metadata';
    setAudio(newAudio);
  }, [elementData.audioNote?.url]);

  const onTogglePlayback = ({ isPlaying, isStarted }) => {
    if (isPlaying) {
      track(AUDIO_NOTE_PAUSED, { groupId });
    } else if (!isStarted) {
      track(AUDIO_NOTE_PLAYED, { groupId });
    }
  };

  const theme = useMemo(
    () => ({
      playIcon: {
        size: Math.floor(elementData.size[1] / 3),
      },

      pauseIcon: {
        size: Math.floor(elementData.size[1] / 3),
      },

      time: {
        size: Math.floor(elementData.size[1] / 5),
      },
    }),
    [elementData.size]
  );

  return (
    <Container
      background={elementData?.audioNote?.backgroundColor}
      color={elementData?.audioNote?.foregroundColor}
      borderRadius={elementData.size[1] / 2}
    >
      <AvatarContainer onClick={() => openUserProfile({ userId: elementData.creator })}>
        <UserAvatar userId={elementData.creator} />
      </AvatarContainer>

      <ThemeProvider theme={theme}>
        <AudioItem audio={audio} onTogglePlayback={onTogglePlayback} />
      </ThemeProvider>
    </Container>
  );
};

export default AudioNote;

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 0 8%;

  ${({ color }) => svgColorMixin(color)}
`;

const AvatarContainer = styled.div`
  height: 50%;
  aspect-ratio: 1;
  margin-right: 4%;
  cursor: pointer;
`;
