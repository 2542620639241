import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { starburstClipPathMixin } from '../mixins';
import { useUserProfileData } from '../hooks/useUserProfileData';
import { getUserAvatarColors } from '../../util/user-util';

const UserAvatar = ({
  userId,
  userProfile: preloadedUserProfile,
  isHereOfficialAccount,
  customBorderColor,
  borderRadius,
  borderWidth,
  isLoading,
}) => {
  // We should remove this snapshot once the members store is merged into users store:
  const loadedUserProfile = useUserProfileData(userId).userProfile;
  const userProfile = preloadedUserProfile || loadedUserProfile;

  const avatar = userProfile?.avatar?.thumbnail || userProfile?.avatar?.original;

  const displayName = userProfile?.displayName;
  const { background, foreground } = getUserAvatarColors(userProfile);

  const [initial] = displayName || ''; // This is the only way to handle emojis.
  const initialFont = userProfile?.profileTheme?.primaryFont;

  const [fontSize, setFontSize] = useState(16);
  const ref = useRef(null);

  useEffect(() => {
    const { current } = ref;
    const resizeObserver = new ResizeObserver((entries) => {
      const newSize = entries[0].contentRect.width;
      const newFontSize = newSize / 1.5;

      setFontSize(newFontSize);
    });

    if (current) {
      resizeObserver.observe(current);
    }

    return () => {
      if (current) {
        resizeObserver.unobserve(current);
      }
    };
  }, []);

  return (
    <Container
      ref={ref}
      background={background}
      foreground={foreground}
      isHereOfficialAccount={isHereOfficialAccount}
      borderColor={customBorderColor || background}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      isLoading={isLoading}
    >
      {avatar ? (
        <Image src={avatar} alt="avatar" isHereOfficialAccount={isHereOfficialAccount} loading="lazy" />
      ) : (
        <Initial font={initialFont} fontSize={fontSize}>
          {initial}
        </Initial>
      )}
    </Container>
  );
};

UserAvatar.propTypes = {
  userId: PropTypes.string,
  userProfile: PropTypes.shape({
    displayName: PropTypes.string,
    avatar: PropTypes.shape({
      thumbnail: PropTypes.string,
      original: PropTypes.string,
    }),
    profileTheme: PropTypes.shape({
      primaryFont: PropTypes.string,
    }),
  }),
  isHereOfficialAccount: PropTypes.bool,
  customBorderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  borderWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

UserAvatar.defaultProps = {
  userId: null,
  userProfile: null,
  isHereOfficialAccount: false,
  customBorderColor: null,
  borderRadius: '50%',
  borderWidth: 0,
  isLoading: false,
};

export default UserAvatar;

const Container = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  color: ${({ foreground }) => foreground};
  background: ${({ background }) => background};
  background-origin: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: hidden;
  ${({ isLoading }) => isLoading && 'background: rgba(255,255,255,0.1);'}
  border: ${({ borderWidth }) => `${borderWidth}px solid transparent`};
  box-sizing: border-box;

  // TODO: Make a separate component for the official account avatar
  ${({ isHereOfficialAccount }) =>
    isHereOfficialAccount &&
    `
      background-color: #f6a730;
      border: none;

    ${starburstClipPathMixin()}
  `}

  ${({ borderColor }) => borderColor && `border-color: ${borderColor}`}
`;

const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;

  ${({ isHereOfficialAccount }) =>
    isHereOfficialAccount &&
    `

    width: calc(100% - 4px);
    height: calc(100% - 4px);

    ${starburstClipPathMixin()}
  `}
`;

const Initial = styled.span`
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${({ font }) => font && `${font}, `}Inter, sans-serif;
`;
