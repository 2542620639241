import { createSelector } from '@reduxjs/toolkit';

import { Roles } from '../../../definitions/groups';
import { RootState } from '../../../definitions/store';
import { RoomServiceResponse } from '../../../definitions/room';

const selectVisitedRoomsIndex = (state: RootState) => state.visitedRooms.visitedRoomsIndex;
export const selectVisitedRooms = (state: RootState) => state.visitedRooms.visitedRooms;
export const selectVisitedRoomById = createSelector(
  [selectVisitedRooms, selectVisitedRoomsIndex, (state: RootState, id: string) => id],
  (visitedRooms, index, id) => visitedRooms[index[id]]
);

export const selectVisitedRoomsLoading = (state: RootState) => state.visitedRooms.visitedRoomsLoading;

export const selectVisitedRoomsLimit = (state: RootState) => state.visitedRooms.limit;

export const selectTimeSpentByRoomId = createSelector(
  [selectVisitedRoomById],
  (room: RoomServiceResponse) => room?.timeSpent
);

export const selectRoomsToAddToGroup = createSelector([selectVisitedRooms], (rooms) =>
  rooms.filter((room) => !room.groupId && (room.role === Roles.Owner || room.role === Roles.Host))
);
