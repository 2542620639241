import {
  ROOM_ID_LENGTH,
  ROOM_URL_ALIAS_MIN_LENGTH,
  ROOM_URL_ALIAS_MAX_LENGTH,
  USER_CARD_BOARD_TYPE,
} from '../constants/board-constants';
import eventBus, { loadBoardRequested } from '../event-bus';
import { db } from '../firebase';
import log from '../log';
import { addSystemMessage } from '../message-util';
import { isWaitlistAllowedForBoardId } from '../roles-management';
import { getCapacityInfoForBoardId } from '../room-capacity';
import { playSoundEffect, soundTypes } from './sound-fx-util';
import { cacheCustomUrl, getCachedRoom, getUrlAliasCachedRoom } from '../rooms-cache';
import { track } from './analytics-util';
import { isHereReservedRoute } from './url-alias-validator';

const ROOM_ID_REGEX = new RegExp(`^[a-zA-Z0-9]{${ROOM_ID_LENGTH}}$`);
const ROOM_URL_ALIAS_REGEX = new RegExp(`^[a-zA-Z0-9~_-]{${ROOM_URL_ALIAS_MIN_LENGTH},${ROOM_URL_ALIAS_MAX_LENGTH}}$`);

/**
 *
 * @param {string} roomId
 * @returns {boolean}
 */
export const checkIfLooksLikeRoomId = (roomId) => typeof roomId === 'string' && ROOM_ID_REGEX.test(roomId);

/**
 *
 * @param {string} roomUrlAlias
 * @returns {boolean}
 */
export const checkIfLooksLikeRoomAlias = (roomUrlAlias) =>
  typeof roomUrlAlias === 'string' && ROOM_URL_ALIAS_REGEX.test(roomUrlAlias);

/**
 *
 * @param {string} roomLink
 * @returns {string|null}
 */
export const getRoomIdOrUrlAliasFromRoomLink = (roomLink) => {
  if (typeof roomLink !== 'string') {
    return null;
  }

  const [, roomIdOrUrlAlias] = roomLink.split('https://here.fm/');
  return roomIdOrUrlAlias;
};

/**
 *
 * @param {string} searchValue
 * @returns {boolean}
 */
export const checkIfLooksLikeRoomLink = (searchValue) => {
  if (typeof searchValue !== 'string') {
    return false;
  }

  if (searchValue.startsWith('https://here.fm/')) {
    const [, roomIdOrUrlAlias] = searchValue.split('https://here.fm/');
    return checkIfLooksLikeRoomId(roomIdOrUrlAlias) || checkIfLooksLikeRoomAlias(roomIdOrUrlAlias);
  }

  return false;
};

// Copied over from prev rooms-util:
export function lockEveryElement() {
  db.runTransaction(async (transaction) => {
    Object.keys(window.elementHandlers).forEach((handlerId) => {
      const { elementType } = window.elementHandlers[handlerId].constructor;
      if (elementType !== 'CameraElement' && elementType !== 'ScreenshareElement') {
        transaction.update(db.doc(`boards/${window.currentBoardId}/elements/${handlerId}`), { locked: true });
      }
    });
  })
    .then(() => {
      addSystemMessage('locked everything in the room');
      track('Lock All');
    })
    .catch((e) => {
      log.error('Locking all failed', e);
    });
}

/**
 *
 * @returns {boolean}
 *
 */
export function getCurrentBoardIsUserCard() {
  return window.currentBoardData?.type === USER_CARD_BOARD_TYPE;
}

export async function jumpIntoRoom({ roomId, title }) {
  playSoundEffect(soundTypes.WARP);

  const { isCapacityReached } = await getCapacityInfoForBoardId(roomId);
  if (isCapacityReached) {
    window.location.pathname = `/${roomId}`;
    return;
  }

  const isAllowedToBypassWaitlist = await isWaitlistAllowedForBoardId(roomId);
  const board = await db.doc(`/boards/${roomId}`).get();
  if (!board.exists) {
    log.error(`Board ${roomId} doesn't exist`);
    return;
  }

  const { joinMode } = board.data();
  if (joinMode === 'waitlist' && !isAllowedToBypassWaitlist) {
    window.location.pathname = `/${roomId}`;
    return;
  }

  const { isAudioOn, isVideoOn } = window.rtc;
  window.history.pushState(null, document.title, `${roomId}`);
  eventBus.dispatch(loadBoardRequested, {
    roomId,
    title,
    isVideoOn,
    isAudioOn,
    isPreloaded: false,
    joinSound: false,
  });
}

export async function checkValidHereRoom(roomId) {
  const room = await db.doc(`boards/${roomId}`).get();
  return room.exists;
}

export async function getBoardIdFromURLAlias(urlAlias) {
  const existingName = await db.collection('boards').where('urlAlias', '==', urlAlias).get();
  if (existingName.size > 0) {
    return existingName.docs[0].id;
  }

  return null;
}

export const getResultChatSoundsList = (defaultSounds, defaultSoundsCustomizationsMap, customSoundsMap) => {
  const resultList = [];

  // add default sounds
  defaultSounds.forEach((sound) => {
    const defaultSoundCustomization = defaultSoundsCustomizationsMap[sound.id];
    if (defaultSoundCustomization) {
      resultList.push({
        ...sound,
        ...defaultSoundCustomization,
        id: `default-sound-${sound.id}`,
        keyInCollection: sound.id,
        isDefault: true,
        isCustomizedDefault: true,
      });
    } else {
      resultList.push({
        ...sound,
        id: `default-sound-${sound.id}`,
        keyInCollection: sound.id,
        isDefault: true,
      });
    }
  });

  // add custom sounds
  Object.entries(customSoundsMap).forEach(([key, value]) => {
    resultList.push({
      ...value,
      id: `custom-sound-${key}`,
      keyInCollection: key,
      isDefault: false,
    });
  });

  return resultList;
};

function extractHereRoomUrl(text) {
  const hereUrlRegex = `(?:https?://)?[^ ]*${window.location.host}/(.*)`;

  const hereUrl = text.match(hereUrlRegex);
  if (hereUrl && hereUrl[1].split(/[/ ]/)[0].length > 1 && hereUrl[1].split(/[/ ]/).length === 1) {
    const roomIdOrUrlAlias = hereUrl[1].split(/[/ ]/)[0];
    const messageIsUrl = hereUrl[0] === text && hereUrl[0].split(' ').length === 1;
    return { roomIdOrUrlAlias, messageIsUrl };
  }
  return null;
}

export async function isHereRoomURL(text) {
  const { roomIdOrUrlAlias = false, messageIsUrl = false } = extractHereRoomUrl(text) || {};

  if (isHereReservedRoute(roomIdOrUrlAlias)) return null;

  if (roomIdOrUrlAlias) {
    if (checkIfLooksLikeRoomId(roomIdOrUrlAlias)) {
      const cachedRoom = getCachedRoom(roomIdOrUrlAlias);
      if (cachedRoom) {
        return { roomId: roomIdOrUrlAlias, messageIsUrl };
      }
      if (await checkValidHereRoom(roomIdOrUrlAlias)) {
        return { roomId: roomIdOrUrlAlias, messageIsUrl };
      }
    } else {
      const cachedCustomUrlRoomId = getUrlAliasCachedRoom(roomIdOrUrlAlias);
      if (cachedCustomUrlRoomId) {
        return { roomId: cachedCustomUrlRoomId, messageIsUrl };
      }
      if (checkIfLooksLikeRoomAlias(roomIdOrUrlAlias)) {
        const roomIdFromCustomUrl = await getBoardIdFromURLAlias(roomIdOrUrlAlias);
        cacheCustomUrl(roomIdOrUrlAlias, roomIdFromCustomUrl);
        return roomIdFromCustomUrl ? { roomId: roomIdFromCustomUrl, messageIsUrl } : null;
      }
    }
  }
  return null;
}
