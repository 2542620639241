import firebase, { db } from './firebase';
import { htmlToElement, getBackgroundImageUrl } from './util';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import BoardElement from './board-element';

import { renderTextChannelBoardElement } from './react/board-elements/render';
import chatBackgroundPicker from './components/room-settings/background/chat-background-picker';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';
import { track } from './util/analytics-util';

export default class ChatElement extends BoardElement {
  elementDescription() {
    return 'chat';
  }

  icon() {
    return 'slingshot';
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, __elementDoc) {
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(__elementId, __elementDoc) {}

  // Required method
  getElement(elementDoc) {
    const chat = htmlToElement(`
      <div style="height: 100%">
      </div>
    `);

    renderTextChannelBoardElement(chat, { elementId: this.elementId });

    return wrapElement(chat, elementDoc, {
      classes: ['chat-element'],
      additionalOptions: [...this.getAdditionalOptions()],
    });
  }

  getAdditionalOptions() {
    const changeTextChannelBackgroundOption = htmlToElement(
      `<button class="options-menu-option interaction-option separated-option">Change background</button>`
    );
    changeTextChannelBackgroundOption.addEventListener('click', () => {
      const textChannelElement = document
        .getElementById(`element-${this.elementId}`)
        .querySelector('.feed-list-scroll-wrapper');

      const { background } = window.getComputedStyle(textChannelElement);
      chatBackgroundPicker.open(this.elementId, getBackgroundImageUrl(background));
    });
    return [changeTextChannelBackgroundOption];
  }

  // Statics

  static async addElement() {
    db.collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'ChatElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 100) + window.innerHeight / 2
        ),
        creator: firebase.auth().currentUser.uid,
        size: [320, 450],
        backgroundColor: '#ffffff',
        zIndex: window.getFrontZIndex(),
      })
      .then((__docRef) => {
        addSystemMessage('added chat');
        track(ADD_ELEMENT, { element: ELEMENT_TYPES.CHAT, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
      });
  }
}
