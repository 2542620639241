import { createAction } from '@reduxjs/toolkit';

// types
import { RoomServiceResponse } from '../../../definitions/room';

export const fetchVisitedRooms = createAction<{ params: object }>('visitedRooms/fetchVisitedRooms');
export const startVisitedRoomsPolling = createAction('visitedRooms/startVisitedRoomsPolling');

// setters
export const setVisitedRooms = createAction<{ visitedRooms: RoomServiceResponse[] }>('visitedRooms/setVisitedRooms');
export const setVisitedRoomsLoading = createAction<{ loading: boolean }>('visitedRooms/setVisitedRoomsLoading');
export const setVisitedRoomsLimit = createAction<{ limit: number }>('visitedRooms/setVisitedRoomsLimit');
