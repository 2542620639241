import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { openUserProfile } from '../../user-profile/utils';
import { useUserProfileData } from '../../hooks/useUserProfileData';

type Props = {
  receiverId: string;
};

const TaggedUsername = ({ receiverId }: Props) => {
  const { userProfile: receiverProfile } = useUserProfileData(receiverId) as {
    userProfile: { username: string };
    loading: boolean;
  };

  const onClick = (e: MouseEvent) => {
    openUserProfile({ userId: receiverId, topPosition: e.clientY, leftPosition: e.clientX });
  };

  return <TaggedUsernameText onClick={onClick}>{`@${receiverProfile.username}`}</TaggedUsernameText>;
};

const TaggedUsernameText = styled.span`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
`;

export default TaggedUsername;
