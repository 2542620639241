import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { removeCustomKaomoji } from '../store/kaomoji/actions';

interface Props {
  onSelect: (content: string) => void;
  content: string;
  isCustom?: boolean;
}

const KaomojiItem = ({ onSelect, content, isCustom }: Props) => {
  const dispatch = useDispatch();

  const [isRemoved, setIsRemoved] = useState(false);
  const onRemove = (e: React.MouseEvent) => {
    if (isRemoved) return; // Prevent double clicks

    e.stopPropagation();
    setIsRemoved(true);
    dispatch(removeCustomKaomoji({ content }));
  };

  return (
    <Container onClick={() => onSelect(content)}>
      <span>{content}</span>
      {isCustom ? <RemoveButton onClick={onRemove}>×</RemoveButton> : null}
    </Container>
  );
};

export default KaomojiItem;

const Container = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--primary-foreground);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin: 16px 0;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  body:not(.themed) & {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const RemoveButton = styled.div`
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  bottom: 1px;
  right: 4px;
  line-height: 1;
  opacity: 0.5;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;
