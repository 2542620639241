import React, { useContext } from 'react';
import styled from 'styled-components';

import CheckIcon from '../../../../../../assets/icons/check-angular.svg';
import { svgColorMixin } from '../../../../mixins';
import { ChatPermissionsContext } from '../../../../common/contexts';
import ChatUserData from '../ChatUserData';

interface Props {
  friendId: string;
  isSelected: boolean;
  onSelect: () => void;
}

const FriendItem: React.FC<Props> = ({ friendId, isSelected, onSelect }) => {
  const permissions = useContext(ChatPermissionsContext);
  const canPerformAction = (isSelected && permissions.canRemoveMembers) || (!isSelected && permissions.canAddMembers);

  return (
    <Container
      isSelected={isSelected}
      canPerformAction={canPerformAction}
      onClick={canPerformAction ? onSelect : () => {}}
    >
      <ChatUserData userId={friendId} isSelected />
      {isSelected ? (
        <CheckIconContainer>
          <StyledCheckIcon />
        </CheckIconContainer>
      ) : null}
      {!isSelected && permissions.canAddMembers ? <AddToChatButton>Add to Chat</AddToChatButton> : null}
    </Container>
  );
};

export default FriendItem;

const Container = styled.div<{ isSelected: boolean; canPerformAction: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;

  border-radius: 20px;
  padding: 0 10px;
  height: 40px;

  cursor: ${({ canPerformAction }) => (canPerformAction ? 'pointer' : 'default')};

  background: ${({ isSelected }) => (isSelected ? 'var(--secondary-background)' : 'rgba(255, 255, 255, 0.1)')};
`;

const CheckIconContainer = styled.div`
  width: 25px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  ${svgColorMixin('var(--secondary-foreground)')};
  width: 12px;
`;

const AddToChatButton = styled.div`
  flex: 1 0 auto;
  background: var(--secondary-background);
  color: var(--secondary-foreground);

  transition: background-color 0.2s;
  &:hover {
    background-color: var(--secondary-background-alpha-50);
    color: var(--primary-foreground);
  }

  font-size: 9px;
  font-weight: bold;

  border-radius: 30px;
  width: 65px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
