import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import ConfettiBurst from './ConfettiBurst';
import AvatarsSmash from './AvatarsSmash';

import { currentAnimationsSelector } from '../store/animations/selectors';
import { animationCompleted } from '../store/animations/store';
import { ANIMATION_NAMES } from '../store/animations/constants';

const componentsMap = {
  [ANIMATION_NAMES.confettiBurst]: ConfettiBurst,
  [ANIMATION_NAMES.avatarsSmash]: AvatarsSmash,
};

// Right now it's meant to be used for fullscreen animations, but there's
// a chance we'll want to use them inside some container. Need more generic
// solution in that case (or maybe just import the components directly and put
// them into a container?).
const FullscreenAnimations = () => {
  const dispatch = useDispatch();

  const currentAnimations = useSelector(currentAnimationsSelector);
  const animationElements = currentAnimations.map(({ id, name, data }) => {
    const Component = componentsMap[name];
    if (!Component) {
      throw new Error('Unknown animation name!');
    }
    return <Component key={id} data={data} onComplete={() => dispatch(animationCompleted({ id }))} />;
  });

  return <Container>{animationElements}</Container>;
};

export default FullscreenAnimations;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  pointer-events: none;
`;
