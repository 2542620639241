import React from 'react';
import styled from 'styled-components';

import LockIcon from '../../../../../assets/icons/lock.svg';

const AdminOverlay = () => (
  <>
    <Overlay />
    <OverlayTitle>
      <LockIcon />
      <OverlayText>Admin access only</OverlayText>
    </OverlayTitle>
  </>
);

export default AdminOverlay;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #ffffff80;
  backdrop-filter: blur(2px);
`;

const OverlayTitle = styled.div`
  position: relative;
  padding: 1.2rem;
  z-index: 15;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #333;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const OverlayText = styled.span`
  color: white;
  margin-left: 20px;
`;
