import React from 'react';

import Tabs from './Tabs';
import GifSearchContainer from '../gif-search/GifSearchContainer';
import { gifTypes } from '../store/gif-search/store';
import ImageSearchContainer from '../image-search/ImageSearchContainer';
import RoomKitContainer from './RoomKitContainer';

const height = '40vh';

const AddImageContainer = (data) => {
  let tabs = [
    {
      name: 'Sticker',
      node: (
        <GifSearchContainer
          height={height}
          type={gifTypes.stickers}
          isChat={data.isChat}
          elementId={data.elementId}
          elementMenuId={data.elementMenuId}
          firebaseId={data.firebaseId}
          chatId={data.chatId}
          caller={data.caller}
          onResultClick={data.onResultClick}
        />
      ),
    },
  ];

  if (!data.isChat) {
    tabs.push({
      name: 'Image',
      node: (
        <ImageSearchContainer
          height={height}
          isChat={data.isChat}
          elementId={data.elementId}
          elementMenuId={data.elementMenuId}
          firebaseId={data.firebaseId}
          onResultClick={data.onResultClick}
          caller={data.caller}
        />
      ),
    });
  }

  tabs.push({
    name: 'Gif',
    node: (
      <GifSearchContainer
        height={height}
        type={gifTypes.gifs}
        isChat={data.isChat}
        elementId={data.elementId}
        elementMenuId={data.elementMenuId}
        firebaseId={data.firebaseId}
        chatId={data.chatId}
        caller={data.caller}
        onResultClick={data.onResultClick}
      />
    ),
  });

  if (!data.isChat && !data.isUserCard) {
    tabs.push({
      name: 'Text',
      node: (
        <GifSearchContainer
          height={height}
          type={gifTypes.text}
          elementId={data.elementId}
          elementMenuId={data.elementMenuId}
          firebaseId={data.firebaseId}
          caller={data.caller}
          onResultClick={data.onResultClick}
        />
      ),
    });
  }

  if (!data.isChat && !data.isGifsOnly) {
    tabs.push({
      name: 'Room Kits',
      node: <RoomKitContainer height={height} />,
    });
  }

  if (data.allowedTabIds) {
    tabs = tabs.filter((tab) => data.allowedTabIds.includes(tab.name));
  }

  return (
    <Tabs uniqueId={data.elementId} tabs={tabs} onTabChange={data.onTabChange} activeTabName={data.activeTabName} />
  );
};

export default AddImageContainer;
