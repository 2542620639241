import React from 'react';

import { Hexagon, Rect, Star, Triangle } from './icons';
import { HorizontalJumpingWrapper, SpinningWrapper, VerticalJumpingWrapper } from './TypingIndicatorAnimations';
import {
  typingIndicatorAnimations,
  typingIndicatorAppearingAnimations,
  typingIndicatorShapes,
} from '../../../constants/chat-constants';

export function getTypingAnimationIcon(shape) {
  switch (shape) {
    case typingIndicatorShapes.HEXAGON: {
      return Hexagon;
    }

    case typingIndicatorShapes.RECT: {
      return Rect;
    }

    case typingIndicatorShapes.STAR: {
      return Star;
    }

    case typingIndicatorShapes.TRIANGLE: {
      return Triangle;
    }

    default: {
      throw new Error('Wrong shape');
    }
  }
}

export function getAnimationWrapper(animation) {
  switch (animation) {
    case typingIndicatorAnimations.SPINNING: {
      return SpinningWrapper;
    }

    case typingIndicatorAnimations.VERTICAL_JUMPS: {
      return VerticalJumpingWrapper;
    }

    case typingIndicatorAnimations.HORIZONTAL_JUMPS: {
      return HorizontalJumpingWrapper;
    }

    default: {
      throw new Error('Wrong animation');
    }
  }
}

export function getAppearingAnimation({ appearingAnimationType, usersAreTyping }) {
  switch (appearingAnimationType) {
    case typingIndicatorAppearingAnimations.SCALE: {
      return usersAreTyping ? 'scale(1) translateY(-28px)' : 'scale(0) translateY(-28px)';
    }

    case typingIndicatorAppearingAnimations.TRANSLATE: {
      if (usersAreTyping) {
        return 'translateY(30px)';
      }

      return 'translateY(64px)';
    }

    default: {
      throw new Error('Wrong appearing animation');
    }
  }
}

export function wrapWithAnimations(Target, animationsToWrapWith) {
  return animationsToWrapWith.reduce((Result, animation) => {
    const AnotherWrapper = getAnimationWrapper(animation);
    return () => (
      <AnotherWrapper>
        <Result />
      </AnotherWrapper>
    );
  }, Target);
}
