import firebase from '../firebase';
import { track } from './analytics-util';

export function getSlackRedirectUrl(boardId) {
  return encodeURIComponent(`https://here.fm/slack-auth.html?boardId=${boardId}`);
}

export function getSlackIntegrationLink(slackRedirectUrl, userId) {
  const slackRedirectUrlToUse = slackRedirectUrl || getSlackRedirectUrl(window.currentBoardId);
  const userIdToUse = userId || firebase.auth().currentUser.uid;
  return `https://slack.com/oauth/v2/authorize?scope=incoming-webhook,channels:read,groups:read,im:read,mpim:read,chat:write,commands&redirect_uri=${slackRedirectUrlToUse}&state=${userIdToUse}&client_id=1169989381937.1157816709731`;
}

export function onDiscordIntegrationLinkClick(boardId, trackData = {}) {
  const { event, params } = trackData;
  if (event) {
    track(event, params);
  }
  localStorage.setItem('here-discordAuth-boardId', boardId || window.currentBoardId);
  localStorage.setItem('here-discordAuth-userId', firebase.auth().currentUser.uid);
  window.open(
    'https://discord.com/api/oauth2/authorize?client_id=785660817713528884&permissions=0&redirect_uri=https%3A%2F%2Fhere.fm%2Fdiscord-auth.html&response_type=code&scope=bot%20messages.read%20identify',
    '_blank'
  );
}
