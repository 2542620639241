import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import OutgoingFriendRequestAcceptedPopover from './OutgoingFriendRequestAcceptedPopover';
import { acceptedNotificationsSelector } from '../../store/friends/selectors';
import { outgoingFriendRequestsNotificationClosed } from '../../store/friends/store';

const OutgoingFriendRequestNotifications = () => {
  const dispatch = useDispatch();

  const acceptedNotifications = useSelector(acceptedNotificationsSelector);

  return (
    <Container>
      {acceptedNotifications.map((n) => (
        <OutgoingFriendRequestAcceptedPopover
          userId={n}
          key={n}
          onClose={() => dispatch(outgoingFriendRequestsNotificationClosed({userId: n}))}
        />
      ))}
    </Container>
  );
};

export default OutgoingFriendRequestNotifications;

const Container = styled.div``;
