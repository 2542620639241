import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectUserById } from '../../../store/users/selectors';
import { ellipsisTextMixin } from '../../../mixins';
import UserAvatar from '../../../components/UserAvatar';

interface Props {
  userId: string;
  isSelected?: boolean;
}

const ChatUserData = ({ userId, isSelected }: Props) => {
  const user = useSelector((state) => selectUserById(state, userId));

  return (
    <>
      <AvatarContainer>
        <UserAvatar userId={userId} />
      </AvatarContainer>
      <NamesContainer isSelected={isSelected}>
        <DisplayName isSelected={isSelected}>{user?.displayName}</DisplayName>
        <Username isSelected={isSelected}>@{user?.username}</Username>
      </NamesContainer>
    </>
  );
};

export default ChatUserData;

const AvatarContainer = styled.div`
  width: 25px;
  height: 25px;
  flex: 1 0 auto;
`;

const NamesContainer = styled.div<{ isSelected: boolean }>`
  padding: 0 6px;
  flex: 0 1 auto;

  ${ellipsisTextMixin()}
`;

const Username = styled.div<{ isSelected: boolean }>`
  font-size: 10.5px;

  color: ${({ isSelected }) =>
    isSelected ? 'var(--secondary-foreground-alpha-60)' : 'var(--primary-foreground-alpha-60)'};
`;

const DisplayName = styled.div<{ isSelected: boolean }>`
  font-size: 14px;
  font-weight: 700;

  color: ${({ isSelected }) => (isSelected ? 'var(--secondary-foreground)' : 'var(--primary-foreground)')};
`;
