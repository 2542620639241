import React from 'react';
import styled from 'styled-components';

import ReactionsPicker from '../../ReactionsPicker';
import LottieAnimation from '../../../animations/LottieAnimation';
import animationData from '../../../../../assets/lottie/keyboard-with-emojis.json';

const RoomReactionsSettings: React.FC = () => (
  <>
    <ReactionsPicker />
    <div>
      <Subtitle>Use keyboard to react</Subtitle>
      <AnimationContainer>
        <LottieAnimation animationData={animationData} loop />
      </AnimationContainer>
    </div>
  </>
);

export default RoomReactionsSettings;

const Subtitle = styled.div`
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
  text-align: center;
`;

const AnimationContainer = styled.div`
  height: 120px;
  margin-top: -60px;
`;
