import { api } from '../firebase';
import CameraElement from '../camera';
import log from '../log';
import { browserName } from './browser-util';

const axios = require('axios');

export default async function spawnCamera(user, isAudioOn, isVideoOn) {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  try {
    const resp = await axios.post(
      `${api}/spawn/camera`,
      {
        currentBoardId: window.currentBoardId,
        uid: user.uid,
        isVideoOn,
        isAudioOn,
        platform: navigator.platform,
        client: browserName(),
        audioDeviceName: window.rtc.audioDeviceLabel,
        videoDeviceName: window.rtc.videoDeviceLabel,
      },
      headers
    );
    if (resp.status === 200) {
      const data = await resp.data;
      if (data.shouldSpawnCamera) {
        // We want to start loading of camera/audio hardware as soon as possible but we don't
        //   want initOwnCamera to create a cameraElement as that was already done by this
        //   api request.  So we set ownCamera with a camera element using the document id
        //   of the camera element created on the server (only if firebase hasn't finsihed
        //   loading the elements yet), so that initOwnCamera won't spawn a new camera.
        if (!CameraElement.ownCamera) {
          CameraElement.ownCamera = new CameraElement(data.cameraDocId);
        }

        CameraElement.initOwnCamera({ isVideoOn, isAudioOn });
        CameraElement.updateCameraPosition(data.cameraElement, data.cameraDocId);
      } else {
        // if `shouldSpawnCamera` is false, it's a possibility that this is due to the camera
        // already existing in the room due to lack of cleanup or being open in another browser.
        CameraElement.initOwnCamera({ isVideoOn, isAudioOn });
      }
    } else {
      log.error('Error spawning camera');
    }
  } catch (err) {
    log.error(err);
  }
}
