import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Item = ({ gradient, icon, isRandom, name, onClick, className }) => {
  const shownName = isRandom ? 'Surprise Me' : name;
  return (
    <Container className={className} gradient={gradient} onClick={onClick}>
      <ImageContainer>
        <Icon alt={`${shownName} icon`} src={isRandom ? '/images/icons/Itememoji/surpriseme.png' : icon} />
        <Title>{shownName}</Title>
      </ImageContainer>
    </Container>
  );
};

Item.propTypes = {
  gradient: PropTypes.arrayOf(PropTypes.string),
  isRandom: PropTypes.bool,
  name: PropTypes.string.isRequired,

  icon: PropTypes.string,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  isRandom: false,
  onClick: () => {},
  icon: null,
  gradient: ['#2b3b8f', '#c1265e'],
};

export default Item;

const Container = styled.div`
  position: relative;
  border-radius: 8px;
  background: linear-gradient(to right, ${({ gradient }) => gradient.join(', ')});
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5%;
`;

const Icon = styled.img`
  position: absolute;
  top: 25%;
  width: 60%;
  margin: auto;
  height: 55%;
`;

const Title = styled.div`
  position: absolute;
  bottom: 10%;
  margin: 0 auto;
  color: white;
  font-size: 16px;
  word-spacing: 100vw;
  text-align: center;
`;
