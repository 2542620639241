// Event triggered when first room for a user is created:
export const SUBMIT_FEEDBACK = 'Submit Feedback';
// params: rating, feedbackTags, videoServer, isPublic

export const OPEN_WEB_LOBBY_TAB = 'Open Web Lobby Tab';
// params: tab ('Rooms'/'DMs'/'User Page')

export const ONE_TIME_MOBILE_UPSELL_MODAL_SEEN = 'One-time Mobile Upsell Lobby Modal Seen';
export const ONE_TIME_MOBILE_UPSELL_MODAL_CLOSED = 'One-time Mobile Upsell Lobby Modal Closed';

export const CLICK_FIND_FRIENDS_LOBBY_TILE_CTA = 'Clicked Find Friends Lobby Tile CTA';
export const CLOSE_FIND_FRIENDS_LOBBY_TILE_CTA = 'Closed Find Friends Lobby Tile CTA';

export const CLICK_MOBILE_UPSELLS_ROOM_TILE_CTA = 'Mobile Upsells Room Tile CTA Clicked';
export const CLOSE_MOBILE_UPSELLS_ROOM_TILE_CTA = 'Close Mobile Upsells Room Tile CTA';

export const DUPLICATE_ROOM = 'Duplicate Room'; // params: id
