import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  RoomActiveTimeData,
  addRoomActiveTimeDataListener,
  removeRoomActiveTimeDataListener,
} from './rooms-active-time-data';

// components
import SuccessState from './states/SuccessState';
import ErrorState from './states/ErrorState';
import EmptyState from './states/EmptyState';

// utils
import { getRoomActiveDisplayTimeFromSecs } from '../../../../util/time-util';

interface RoomActiveTimeWidgetProps {
  roomId: string;
}

const RoomActiveTimeWidget = ({ roomId }: RoomActiveTimeWidgetProps) => {
  const [roomActiveTimeInitialized, setRoomActiveTimeInitialized] = useState(false);
  const [roomActiveTimeSeconds, setRoomActiveTimeSeconds] = useState(0);
  const [serverRefreshFailed, setServerRefreshFailed] = useState(false);

  useEffect(() => {
    const roomActiveTimeDataListener = (data: RoomActiveTimeData) => {
      setRoomActiveTimeInitialized(!!data);
      setRoomActiveTimeSeconds(data.totalSeconds);
      setServerRefreshFailed(data.serverRefreshFailed);
    };

    addRoomActiveTimeDataListener(roomId, roomActiveTimeDataListener);

    return () => {
      removeRoomActiveTimeDataListener(roomId, roomActiveTimeDataListener);
    };
  }, [roomId]);

  const { day, hour, min } = getRoomActiveDisplayTimeFromSecs(roomActiveTimeSeconds);

  return (
    <Container className="themed-font">
      <div>
        <TimerTitle className="themed-font">Active Room Time</TimerTitle>

        {roomActiveTimeInitialized && !serverRefreshFailed ? (
          <SuccessState day={`${day}`} hour={`${hour}`} min={`${min}`} />
        ) : null}

        {roomActiveTimeInitialized && serverRefreshFailed ? <ErrorState /> : null}

        {!roomActiveTimeInitialized && !serverRefreshFailed ? <EmptyState /> : null}
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-foreground, white);
  background: var(--primary-background, #12002d);
  border-radius: 14px;
`;

const TimerTitle = styled.h3`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: bolder;
  color: var(--primary-foreground, white);
`;

export default RoomActiveTimeWidget;
