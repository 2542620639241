import { clamp } from '../../util';

import template from './here-audio-control.html';

import './here-audio-control.less';

const VOLUME_ON_ICON = 'images/icons/volume-on.svg';
const VOLUME_OFF_ICON = 'images/icons/volume-off.svg';

export default class HereAudioControl extends HTMLElement {
  constructor() {
    super();

    this.drag = false;
    this.muted = false;
    this.volume = 0;
    this.volumeBeforeMuted = null;
  }

  connectedCallback() {
    this.innerHTML = template;

    // binds
    this.onAudioButtonClick = this.onAudioButtonClick.bind(this);

    // elements
    this.audioButtonEl = this.querySelector('.here-audio-icon');

    // handlers
    this.audioButtonEl.addEventListener('click', this.onAudioButtonClick);

    const volumeSliderWrapper = this.querySelector('.here-audio-slider-wrapper');

    const onMouseMove = (event) => {
      if (this.drag) {
        this.setVolume(this.convertSliderValueToVolume(volumeSliderWrapper, event));
      }
    };

    const onMouseUp = () => {
      if (this.drag) {
        this.drag = false;
        this.dispatchEvent(
          new CustomEvent('audiochangecommit', { bubbles: true, composed: true, detail: { volume: this.volume } })
        );
      }

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    volumeSliderWrapper.addEventListener('mousedown', (event) => {
      this.drag = true;
      this.setVolume(this.convertSliderValueToVolume(volumeSliderWrapper, event));

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    });

    volumeSliderWrapper.addEventListener('mouseleave', () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    });
  }

  setVolume(volume, commitRequired = false) {
    if (Number.isNaN(volume) || volume === undefined) {
      return;
    }

    this.muted = volume === 0;
    const eInner = this.querySelector('.here-audio-slider');
    const percentage = clamp(volume, 0, 1);
    eInner.style.height = `${percentage * 100}%`;
    this.volume = percentage;
    this.dispatchEvent(
      new CustomEvent('audiochange', { bubbles: true, composed: true, detail: { volume: this.volume } })
    );

    if (commitRequired) {
      this.dispatchEvent(
        new CustomEvent('audiochangecommit', { bubbles: true, composed: true, detail: { volume: this.volume } })
      );
    }

    this.updateVolumeIcon(volume);
  }

  convertSliderValueToVolume(sliderEl, event) {
    const relDistance = sliderEl.getBoundingClientRect().bottom - event.clientY;
    const relDistancePercentage = relDistance / sliderEl.getBoundingClientRect().height;
    return relDistancePercentage;
  }

  updateVolumeIcon(newVolume = 0) {
    switch (true) {
      case newVolume <= 0: {
        this.audioButtonEl.src = VOLUME_OFF_ICON;
        break;
      }

      case newVolume > 0: {
        this.audioButtonEl.src = VOLUME_ON_ICON;
        break;
      }

      default: {
        throw new Error();
      }
    }
  }

  onAudioButtonClick() {
    this.muted = !this.muted;
    if (this.muted) {
      this.volumeBeforeMuted = this.volume;
      this.setVolume(0, true);
    } else {
      this.setVolume(this.volumeBeforeMuted || 0.5, true);
    }
  }
}

window.customElements.define('here-audio-control', HereAudioControl);
