import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';

import { selectCurrentUserGrade, selectCurrentUserSchoolId } from '../store/users/selectors';
import { fetchSchool, fetchSchools } from '../../api/schools-api';
import HereModalWithPortal from '../components/HereModalWithPortal';
import SchoolSelectionFlow from '../school-selection';
import { ellipsisTextMixin, noScrollbarMixin } from '../mixins';
import { ResultTypes } from '../../definitions/schools';
import { getSuggestedFriendsV2 } from '../store/friends/api';
import SuggestedFriendsList from '../friends/suggested-friends/SuggestedFriendsList';
import { EventSourceContext } from '../common/contexts';
import { LINK_SCHOOL_PROFILE_CLICK, LINK_SCHOOL_SOURCES } from '../../constants/analytics-events/user-events';
import { track } from '../../util/analytics-util';
import { SuggestionReasons } from '../hooks/useFriendsSuggestions';

const PROFILE_SCHOOL_SELECTION_MODAL = 'profile-school-selection-modal';

const ProfileSchoolSettings: React.FC = () => {
  const currentSchoolId = useSelector(selectCurrentUserSchoolId);
  const currentGrade = useSelector(selectCurrentUserGrade);

  const [isLocationSupported, setIsLocationSupported] = useState(false);
  const [schoolSelectionOpen, setSchoolSelectionOpen] = useState(false);
  const [school, setSchool] = useState(null);

  useEffect(() => {
    (async () => {
      if (currentSchoolId) {
        const resp = await fetchSchool(currentSchoolId);

        setSchool(resp?.school);
      } else {
        const resp = await fetchSchools();

        setIsLocationSupported(resp?.resultType === ResultTypes.LOCATION_SUPPORTED);
      }
    })();
  }, [currentSchoolId]);

  const linkSchoolButtonClick = () => {
    track(LINK_SCHOOL_PROFILE_CLICK);
    setSchoolSelectionOpen(true);
  };

  const [suggestedFriends, setSuggestedFriends] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSchoolSelectionCompletion = async () => {
    setIsLoading(true);

    const { data }: { data: { success: boolean; suggestedFriends: [] } } = await getSuggestedFriendsV2();

    if (data?.success && data?.suggestedFriends.length) {
      const friendsFromSchool = data.suggestedFriends.filter((friend: { suggestionReasons: string[] }) =>
        friend.suggestionReasons.some((reason: string) => reason === SuggestionReasons.SAME_SCHOOL)
      );
      if (friendsFromSchool?.length) {
        setSuggestedFriends(friendsFromSchool);
      } else {
        setSchoolSelectionOpen(false);
      }
    } else {
      setSchoolSelectionOpen(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      {currentSchoolId && school && (
        <SchoolSection>
          <SchoolNameSection>
            <SchoolLabel>School</SchoolLabel>
            <SchoolName>{school.name}</SchoolName>
          </SchoolNameSection>
          {currentGrade && (
            <GradeSection>
              <SchoolLabel>Grade</SchoolLabel>
              <SchoolName>{`${currentGrade}th`}</SchoolName>
            </GradeSection>
          )}
        </SchoolSection>
      )}
      {!currentSchoolId && isLocationSupported && (
        <LinkSchoolButton onClick={linkSchoolButtonClick}>
          <SchoolButtonIcon>🏫</SchoolButtonIcon>
          <SchoolButtonText>
            <SchoolButtonTitle>Link Your School!</SchoolButtonTitle>
            <SchoolButtonSubtitle>We’ll help you find classmates</SchoolButtonSubtitle>
          </SchoolButtonText>
        </LinkSchoolButton>
      )}
      <div className="prevent-profile-closing prevent-on-click-outside">
        {schoolSelectionOpen && (
          <HereModalWithPortal modalWidth="520px" modalRootId={PROFILE_SCHOOL_SELECTION_MODAL}>
            {isLoading && (
              <LoadingContainer>
                <div>
                  <LoadingTitle>Loading...</LoadingTitle>
                  <img height={95} width={115} src="/gif/pencil-man.gif" alt="schools" />
                </div>
              </LoadingContainer>
            )}
            {!suggestedFriends?.length && !isLoading && (
              <div className="prevent-profile-closing prevent-on-click-outside">
                <EventSourceContext.Provider value={LINK_SCHOOL_SOURCES.USER_PROFILE}>
                  <SchoolSelectionFlow onComplete={onSchoolSelectionCompletion} />
                </EventSourceContext.Provider>
              </div>
            )}
            {suggestedFriends?.length > 0 && (
              <div className="prevent-profile-closing prevent-on-click-outside">
                <ThemeProvider theme={{ userItemHeight: 86 }}>
                  <SuggestedFriendsListContainer>
                    <SuggestedFriendsTitle>People from your school!</SuggestedFriendsTitle>
                    <SuggestedFriendsSubtitle>
                      These people all said they go to the same school. <br /> Add them as a friend!
                    </SuggestedFriendsSubtitle>
                    <SuggestedFriendsList initialSuggestedFriends={suggestedFriends} disableProfileOpenClick />
                  </SuggestedFriendsListContainer>
                  <SuggestedFriendsFooter>
                    <SuggestedFriendsDoneButton onClick={() => setSchoolSelectionOpen(false)}>
                      Done
                    </SuggestedFriendsDoneButton>
                  </SuggestedFriendsFooter>
                </ThemeProvider>
              </div>
            )}
          </HereModalWithPortal>
        )}
      </div>
    </>
  );
};

export default ProfileSchoolSettings;

const SchoolSection = styled.div`
  border-radius: 10px;
  background: rgba(18, 0, 45, 0.05);
  padding: 14px 10px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
`;

const SchoolLabel = styled.div`
  font-size: 12px;
  color: rgba(1, 1, 1, 0.5);
  margin-bottom: 5px;
`;

const SchoolName = styled.div`
  color: #12002d;
  font-size: 16px;
  font-weight: 700;
  ${ellipsisTextMixin()}
`;

const SchoolNameSection = styled.div`
  width: 215px;
  margin-right: 10px;
`;

const GradeSection = styled.div`
  width: 35px;
`;

const LinkSchoolButton = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #6b1be3;
  height: 68px;
  padding-left: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const SchoolButtonIcon = styled.div`
  font-size: 40px;
`;

const SchoolButtonText = styled.div`
  margin-left: 10px;
`;

const SchoolButtonTitle = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 700;
`;

const SchoolButtonSubtitle = styled.div`
  color: white;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
`;

const LoadingContainer = styled.div`
  height: 545px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingTitle = styled.div`
  color: #6b1be3;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  margin-bottom: 30px;
`;

const SuggestedFriendsTitle = styled.div`
  color: #6b1be3;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  margin-bottom: 10px;
`;

const SuggestedFriendsSubtitle = styled.div`
  color: #12002d;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.7;
  margin-bottom: 16px;
`;

const SuggestedFriendsFooter = styled.div`
  padding: 17px 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
`;

const SuggestedFriendsDoneButton = styled.div`
  border-radius: 12px;
  background: #6b1be3;
  height: 50px;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SuggestedFriendsListContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  padding: 37px 30px;

  height: 460px;
  ${noScrollbarMixin()}
`;
