import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Gif } from '@giphy/react-components';
import UIkit from 'uikit';
import * as Popover from '@radix-ui/react-popover';

import Draggable from '../components/Draggable.tsx';
import showChangeAvatarConfirmation from '../user-profile/avatar-confirmation/show-change-avatar-confirmation';
import { addGifRequested, gifTypes } from '../store/gif-search/store';
import { moreMenuItemMixin, moreMenuMixin, svgColorMixin } from '../mixins';

import OptionsIcon from '../../../assets/icons/options-dots.svg';

const GifSearchResultView = ({ gif, onGifClick, type, width, isChat }) => {
  const dataObj = {
    url: type === 'text' ? gif.images.fixed_width_downsampled.url : gif.images.downsized.url,
    webp: gif.images.original.webp,
    type: 'image',
    imageType: type,
    gifType: type,
  };

  if (type !== gifTypes.stickers) {
    dataObj.mp4 = gif.images.original.mp4;
  }

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const onMenuButtonClick = useCallback((e) => {
    e.stopPropagation();
    setIsMenuOpened(true);
  }, []);
  const onAddAvatarClick = useCallback(() => {
    setIsMenuOpened(false);
    showChangeAvatarConfirmation(gif.images.fixed_width.url);
  }, [gif]);
  const onSelectClick = useCallback(() => {
    onGifClick(gif);
    setIsMenuOpened(false);
  }, [gif, onGifClick]);

  return (
    <GifContainer>
      <Draggable contentSelector="img" data={dataObj}>
        <Gif gif={gif} width={width} noLink backgroundColor="transparent" onGifClick={onGifClick} isChat={isChat} />
      </Draggable>
      <Popover.Root open={isMenuOpened}>
        <Popover.Trigger asChild>
          <MenuButton onClick={onMenuButtonClick} isMenuOpened={isMenuOpened}>
            <OptionsIcon />
          </MenuButton>
        </Popover.Trigger>
        <Popover.Content
          asChild
          align="center"
          side="right"
          sideOffset={10}
          onPointerDownOutside={useCallback(() => setIsMenuOpened(false), [])}
        >
          <PopoverMenu>
            <MenuItem onClick={onSelectClick}>Select</MenuItem>
            <MenuItem onClick={onAddAvatarClick}>Set as my avatar</MenuItem>
          </PopoverMenu>
        </Popover.Content>
      </Popover.Root>
    </GifContainer>
  );
};

GifSearchResultView.propTypes = {
  gif: PropTypes.shape().isRequired,
  onGifClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
  isChat: PropTypes.bool,
};

GifSearchResultView.defaultProps = {
  width: 180,
  isChat: false,
};

// TODO: Move props-related stuff (AND UIKIT CALL) into the component.
export default connect(null, (dispatch, props) => ({
  onGifClick: (gif) => {
    let obj;
    if (gif.type === 'text') {
      obj = {
        url: `https://ip.here.fm/${gif.images.fixed_width_downsampled.url}`,
      };
    } else {
      obj = {
        url: gif.images.downsized.url,
      };
      // some gifs dont have a webp field (only add if it exists)
      if (gif.images.original.webp) {
        obj.webp = gif.images.original.webp;
      }
    }

    if (props.type === gifTypes.gifs) {
      obj.mp4 = gif.images.original.mp4;
    }

    obj.firebaseId = props.firebaseId;
    obj.elementId = props.elementId;
    obj.isChat = props.isChat;
    obj.type = gif.type === 'text' ? 'sticker' : gif.type;
    obj.imageType = props.type;
    obj.chatId = props.chatId;
    obj.caller = props.caller;
    obj.width = parseInt(gif.images?.original?.width, 10);
    obj.height = parseInt(gif.images?.original?.height, 10);
    if (document.querySelector(`#${props.elementMenuId}`)) {
      UIkit.dropdown(`#${props.elementMenuId}`).hide(0);
    }
    if (props.onResultClick) {
      props.onResultClick(obj);
    }
    return dispatch(addGifRequested(obj));
  },
}))(GifSearchResultView);

const MenuButton = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  height: 30px;
  width: 30px;
  justify-content: center;
  border-radius: 50px;
  z-index: 1;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  border: 2px solid var(--primary-foreground, black);
  display: ${({ isMenuOpened }) => (isMenuOpened ? 'flex' : 'none')};

  ${svgColorMixin('var(--primary-foreground, black)')}

  &:hover {
    transform: scale(1.1);
  }
`;

const GifContainer = styled.div`
  margin: 20px 0;
  position: relative;

  .giphy-gif-img {
    pointer-events: none;
  }

  .giphy-attribution {
    cursor: pointer;
  }

  &:hover ${MenuButton} {
    display: flex;
  }
`;

const MenuItem = styled.div`
  ${moreMenuItemMixin({ themed: true })}
`;

const PopoverMenu = styled.div`
  ${moreMenuMixin()}
  background: var(--primary-background, white);
  box-shadow: 8px 8px 24px -8px var(--primary-foreground, black);
`;
