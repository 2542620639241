import './inline-svg.less';

export default class InlineSvg extends HTMLElement {
  async connectedCallback() {
    const src = this.getAttribute('src');
    await this.setImage(src, [...this.attributes]);
  }

  async setImage(src, attributes) {
    const result = await fetch(`${window.location.origin}/${src}`);
    this.innerHTML = await result.text();

    attributes.forEach((attr) => {
      if (attr.nodeName === 'src') return;
      this.firstChild.setAttribute(attr.nodeName, attr.nodeValue);
      this.removeAttribute(attr.nodeName);
    });
  }

  set src(val) {
    this.srcValue = val;
    const previousAttributes = this.firstChild ? [...this.firstChild.attributes] : [...this.attributes];
    this.setImage(val, previousAttributes);
  }

  get src() {
    return this.srcValue;
  }
}
window.customElements.define('here-inline-svg', InlineSvg);
