import { convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertFromHTML } from 'draft-convert';

export const textNotesFormat = {
  FIREPAD: 'firepad', // old
  DRAFTJS: 'draft-js', // new (cool)
};

// Converts content state to html string
export const convertToHtml = (contentState) => draftToHtml(convertToRaw(contentState));

const firepadParser = convertFromHTML({
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (node.style?.fontSize) {
      let pixelFontSize;
      if (node.style.fontSize.endsWith('em')) {
        pixelFontSize = parseInt(node.style.fontSize, 10) * 16;
      } else if (node.style.fontSize.endsWith('px')) {
        pixelFontSize = parseInt(node.style.fontSize, 10);
      }
      if (pixelFontSize) {
        currentStyle = currentStyle.add(`fontsize-${pixelFontSize}`);
      }
    }

    if (node.style?.fontFamily) {
      currentStyle = currentStyle.add(`fontfamily-${node.style.fontFamily.replace(/['"]/g, '')}`);
    }

    currentStyle = currentStyle.add(`color-${node.style?.color || 'white'}`);

    return currentStyle;
  },
});
// Parses html produced by our old editor and converts it to draft-js content state
const parseFirepadHtml = (html) => {
  const contentState = firepadParser(html);
  return contentState;
};

// Parses html that was produced by convertToHtml in this very file
const parseDraftjsHtml = (html) => {
  const { contentBlocks, entityMap } = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return contentState;
};

// Converts html string to content state using correct parser based on format
export const parseHtml = (html, format) => {
  const parser = format === textNotesFormat.DRAFTJS ? parseDraftjsHtml : parseFirepadHtml;
  return parser(html);
};
