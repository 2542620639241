import React, { useContext } from 'react';
import styled from 'styled-components';

import { BoardElementControllerContext } from '../../common/contexts.ts';

const ImageElement = () => {
  const { elementData } = useContext(BoardElementControllerContext);
  return elementData.webm ? (
    <Video autoPlay loop>
      <source src={elementData.webm} type="video/webm" />
    </Video>
  ) : (
    <picture>
      {elementData.webp ? <source type="image/webp" srcSet={elementData.webp} /> : null}
      <Image
        alt=""
        crossOrigin="Anonymous"
        loading="lazy"
        src={
          elementData.useImageProxy ? `${process.env.IMAGE_PROXY_URL}/${elementData.imageURL}` : elementData.imageURL
        }
      />
    </picture>
  );
};
export default ImageElement;

const Image = styled.img`
  width: 100%;
  height: 100%;
  user-select: none;
`;

const Video = Image.withComponent('video');
