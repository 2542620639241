export function friendsToIds(friends) {
  const ids = {};

  friends.forEach((friend) => {
    ids[friend.profile.userId] = true;
  });

  return ids;
}

export function friendsToUsers(friends) {
  return friends.map((friend) => friend.profile);
}

export const friendRequestNormalizer = (r) => ({
  receiver: r.receiver,
  requester: r.requester,
  status: r.status,
  updatedAt: r.updatedAt,
});
