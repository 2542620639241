import React from 'react';
import PropTypes from 'prop-types';

const KeyIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3998 5.37499C10.3998 5.916 10.3139 6.44554 10.148 6.94763L14.5998 11.4568L14.5998 14.5833L10.8157 14.5833L9.69982 13.4858L9.69982 12.4583L8.29982 12.4583L8.29982 11.0417L7.30357 11.0417L6.52119 10.2253C6.18797 10.2969 5.84612 10.3333 5.49982 10.3333C2.79363 10.3333 0.599823 8.1134 0.599823 5.37499C0.599823 2.63658 2.79363 0.416656 5.49982 0.416656C8.20602 0.416656 10.3998 2.63658 10.3998 5.37499ZM13.1998 12.0432L8.49313 7.27583L8.69198 6.82994C8.89399 6.37697 8.99982 5.8842 8.99982 5.37499C8.99982 3.41898 7.43282 1.83332 5.49982 1.83332C3.56683 1.83332 1.99982 3.41898 1.99982 5.37499C1.99982 7.331 3.56683 8.91666 5.49982 8.91666C5.85496 8.91666 6.20221 8.86335 6.53389 8.75974L6.94404 8.63161L7.89608 9.62499L9.69982 9.62499L9.69982 11.0417L11.0998 11.0417L11.0998 12.8872L11.384 13.1667L13.1998 13.1667L13.1998 12.0432ZM3.39982 4.31249C3.39982 3.72569 3.86992 3.24999 4.44982 3.24999C5.02972 3.24999 5.49982 3.72569 5.49982 4.31249C5.49982 4.89929 5.02972 5.37499 4.44982 5.37499C3.86992 5.37499 3.39982 4.89929 3.39982 4.31249Z" fill={color} />
  </svg>
);

export default KeyIcon;

KeyIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

KeyIcon.defaultProps = {
  color: 'black',
  size: '15',
};
