import $ from 'jquery';
import { getElementPosition } from './element-transform';
import { updateCanvasPosition } from './viewport';
import { onIdle } from './util';
import { boardUsers } from './presence';

import '../styles/minimap.less';

const MiniMap = {};
export default MiniMap;

MiniMap.canvas = document.getElementById('mini-map');
MiniMap.scale = 0.05;
MiniMap.needsUpdate = false;
MiniMap.idleCallback = null;

function colorForElement(item) {
  const handler = window.elementHandlers[item.id.replace('element-', '')];
  let color = '#4064ff99';
  if (handler) {
    const presenceData =
      window.currentBoardId && boardUsers[window.currentBoardId]
        ? boardUsers[window.currentBoardId][handler.userId]
        : null;
    if (presenceData) {
      color = presenceData.color;
    }
  }
  return color;
}

MiniMap.checkUpdate = () => {
  if (MiniMap.needsUpdate) {
    MiniMap.needsUpdate = false;
    MiniMap.update();
  }
  MiniMap.idleCallback = null;
};

MiniMap.setNeedsUpdate = () => {
  MiniMap.needsUpdate = true;
  if (!MiniMap.idleCallback) {
    MiniMap.idleCallback = onIdle(MiniMap.checkUpdate, { timeout: 500 });
  }
};

MiniMap.update = () => {
  if (!MiniMap.canvas) {
    return;
  }

  const themeForeground = window.currentBoardData?.theme?.colors?.primaryForeground;

  MiniMap.scale =
    window.innerWidth > window.innerHeight
      ? MiniMap.canvas.width / window.innerWidth / 3
      : MiniMap.canvas.height / window.innerHeight / 3;

  MiniMap.scale *= window.canvasScale;

  const ctx = MiniMap.canvas.getContext('2d');
  ctx.clearRect(0, 0, MiniMap.canvas.width, MiniMap.canvas.height);

  $('#elements')
    .find('.boardElement')
    .sort((a, b) => (a.style.zIndex > b.style.zIndex ? 1 : -1))
    .each((idx, item) => {
      const [itemX, itemY] = getElementPosition(item);
      const x =
        MiniMap.canvas.width / 2 +
        (parseFloat(itemX) + window.canvasOffsetX - window.innerWidth / 2) * MiniMap.scale * 2;
      const y =
        MiniMap.canvas.height / 2 +
        (parseFloat(itemY) + window.canvasOffsetY - window.innerHeight / 2) * MiniMap.scale * 2;
      const width = item.style.width.replace('px', '') * MiniMap.scale;
      const height = item.style.height.replace('px', '') * MiniMap.scale;
      ctx.beginPath();
      ctx.lineWidth = 1;

      // Pick shape based on element type
      if (item.classList.contains('screenshareElement')) {
        ctx.fillStyle = colorForElement(item);
        ctx.rect(x, y, width * 2, height * 2);
      } else if (item.classList.contains('videoElement')) {
        ctx.fillStyle = colorForElement(item);
        ctx.arc(x + width, y + height, width, 0, Math.PI * 2);
      } else {
        ctx.fillStyle = themeForeground || '#55779988';
        ctx.rect(x, y, width * 2, height * 2);
      }
      ctx.fill();
    });

  if (window.currentBoardData.borders) {
    const [borderX, borderY, borderWidth, borderHeight] = window.currentBoardData.borders;
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.setLineDash([5, 5]);
    ctx.strokeStyle = themeForeground || '#dddddd';
    ctx.rect(
      MiniMap.canvas.width / 2 + (borderX + window.canvasOffsetX - window.innerWidth / 2) * MiniMap.scale * 2,
      MiniMap.canvas.height / 2 + (borderY + window.canvasOffsetY - window.innerHeight / 2) * MiniMap.scale * 2,
      borderWidth * 2 * MiniMap.scale,
      borderHeight * 2 * MiniMap.scale
    );
    ctx.stroke();
    ctx.setLineDash([]);
  }

  const winWidth = window.innerWidth * MiniMap.scale * 2;
  const winHeight = window.innerHeight * MiniMap.scale * 2;

  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.strokeStyle = themeForeground || '#dddddd';
  ctx.rect(
    (MiniMap.canvas.width - winWidth / window.canvasScale) / 2,
    (MiniMap.canvas.height - winHeight / window.canvasScale) / 2,
    winWidth / window.canvasScale,
    winHeight / window.canvasScale
  );
  ctx.stroke();
};

const minimapElement = document.getElementById('mini-map');
if (minimapElement) {
  minimapElement.addEventListener('mousedown', (e) => {
    minimapElement.addEventListener('mousemove', moveMap);
    minimapElement.addEventListener('mouseup', doneMovingMap);
    minimapElement.addEventListener('mouseout', doneMovingMap);

    e = e || window.event;

    const startCursorX = e.clientX;
    const startCursorY = e.clientY;
    const startOffsetX = window.canvasOffsetX;
    const startOffsetY = window.canvasOffsetY;

    function moveMap(moveMapEvent) {
      moveMapEvent = moveMapEvent || window.event;
      moveMapEvent.preventDefault();
      moveMapEvent.stopPropagation();
      // set the element's new position
      const xDiff = moveMapEvent.clientX - startCursorX;
      const yDiff = moveMapEvent.clientY - startCursorY;
      updateCanvasPosition(
        startOffsetX + xDiff / MiniMap.scale,
        startOffsetY + yDiff / MiniMap.scale,
        window.canvasScale
      );
    }

    function doneMovingMap() {
      document.getElementById('mini-map').removeEventListener('mousemove', moveMap);
      window.impetus.setValues(window.canvasOffsetX, window.canvasOffsetY);
    }
  });
}

export function setMinimapFeedState(isFeedVisible) {
  if (isFeedVisible) {
    document.getElementById('mini-map').classList.remove('minimap-feed-closed');
    document.getElementById('mini-map').classList.add('minimap-feed-open');
    document.getElementById('zoom').classList.remove('map-controls-feed-closed');
    document.getElementById('zoom').classList.add('map-controls-feed-open');
    document.getElementById('reset-position-button').classList.remove('map-controls-feed-closed');
    document.getElementById('reset-position-button').classList.add('map-controls-feed-open');
  } else {
    document.getElementById('mini-map').classList.add('minimap-feed-closed');
    document.getElementById('mini-map').classList.remove('minimap-feed-open');
    document.getElementById('zoom').classList.add('map-controls-feed-closed');
    document.getElementById('zoom').classList.remove('map-controls-feed-open');
    document.getElementById('reset-position-button').classList.add('map-controls-feed-closed');
    document.getElementById('reset-position-button').classList.remove('map-controls-feed-open');
  }
}

window.addEventListener('resize', MiniMap.update, false);
