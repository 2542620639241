import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import MyStuffIcon from '../../../../assets/icons/my-stuff.svg';

import UserAvatar from '../../components/UserAvatar';
import { selectCurrentUserId } from '../../store/users/selectors';
// eslint-disable-next-line import/no-cycle
import MyStuffMenu from './MyStuffMenu';

const MyStuffSettings: React.FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef(null);

  const currentUserId = useSelector(selectCurrentUserId);

  return (
    <>
      <Button type="button" onClick={() => setIsOpened(true)} className="here-button-regular">
        <MyStuffIcon />
        My Stuff
      </Button>
      {isOpened && (
        <BackgroundOverlay
          onClick={(e) => e.target === containerRef.current && setIsOpened(false)}
          ref={containerRef}
          className="my-stuff-menu"
        >
          <Title>
            <AvatarContainer>
              <UserAvatar userId={currentUserId} customBorderColor="white" borderWidth={2} />
            </AvatarContainer>
            My Stuff
          </Title>
          <MyStuffMenu />
        </BackgroundOverlay>
      )}
    </>
  );
};

export default MyStuffSettings;

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
  font-weight: bold;
`;

const Button = styled.button`
  padding: 11px;
  font-size: 13px;
  line-height: 17px;

  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }

  .my-stuff-menu & {
    display: none;
  }
`;

const AvatarContainer = styled.div`
  width: 30px;
  height: 30px;

  margin-right: 10px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 900;
  color: white;
  margin-bottom: 22px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 14px;
    width: 32px;
    height: 32px;
  }
`;
