export const ONBOARDING_PROFILE_EDIT_HINT_CLICKED = 'Onboarding: Profile Edit Hint Clicked';
export const ONBOARDING_PROFILE_EDIT_STEP_COMPLETED = 'Onboarding: Profile Edit Step Completed';
export const ONBOARDING_REACTIONS_HINT_STEP_COMPLETED = 'Onboarding: Reactions Hint Step Completed';
export const ONBOARDING_REACTIONS_STEP_COMPLETED = 'Onboarding: Reactions Step Completed';
export const ONBOARDING_MEDIA_PLAYER_BUTTON_CLICKED = 'Onboarding: Media Player Button Clicked';
export const ONBOARDING_MEDIA_PLAYER_VIDEO_SELECTED = 'Onboarding: Media Player Video Selected';
export const ONBOARDING_INVITE_COPY_ROOM_LINK_CLICKED = 'Onboarding: Invite Copy Room Link Clicked';
export const ONBOARDING_COMPLETED = 'Onboarding: Completed';

export const CREATE_NEW_USER_GROUP = 'Create New User Group';
export const NEW_USER_JOIN_GROUP = 'New User Join Group';
export const NEW_USER_JOIN_ROOM = 'New User Join Room';

export const ONBOARDING_STEP_VIEW = 'Onboarding - Step View'; // params: step

export const groupsOnboardingEvents = {
  WELCOME_TO_HUBS_COMPLETED: 'Groups Onboarding: Welcome to Hubs Completed',
  PROFILE_EDIT_COMPLETED: 'Groups Onboarding: Profile Edit Completed',
  MURALS_INTRO_COMPLETED: 'Groups Onboarding: Murals Intro Completed',
  CHAT_INTRO_COMPLETED: 'Groups Onboarding: Chat Intro Completed',
  ROOMS_INTRO_COMPLETED: 'Groups Onboarding: Rooms Intro Completed',
};

export const createFirstHubOnboardingEvents = {
  VIBE_PICKER_COMPLETED: 'Create First Hub Onboarding: Vibe Picker Completed',
  HUB_NAMING_COMPLETED: 'Create First Hub Onboarding: Hub Naming Completed',
  HUB_CREATION_COMPLETED: 'Create First Hub Onboarding: Hub Creation Completed',
};
