import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import RoomShortcutView from './RoomShortcutView';
import { BoardElementControllerContext } from '../../common/contexts';
import { selectVisitedRoomById } from '../../store/visited-rooms/selectors';
import { offUserListChanged, onUserListChanged } from '../../../presence';

const RoomShortcut = () => {
  const { elementData } = useContext(BoardElementControllerContext);
  const { boardId } = elementData;
  const roomData = useSelector((state) => selectVisitedRoomById(state, boardId));
  const [members, setMembers] = useState([]);
  useEffect(() => {
    const listener = (newMembers: object[]) =>
      setMembers(Object.entries(newMembers || {}).map(([memberId, member]) => ({ id: memberId, ...member })));
    // @ts-ignore
    onUserListChanged(boardId, listener);
    return () => offUserListChanged(boardId, listener);
  });
  const roomDataWithMembers = useMemo(() => ({ ...roomData, members }), [roomData, members]);

  return <RoomShortcutView roomDataWithMembers={roomDataWithMembers} />;
};

export default RoomShortcut;
