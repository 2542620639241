import React from 'react';
import ReactDOM from 'react-dom';

import { WithRoomStore } from '../store/Wrapper';
import OpenedInDesktop from './OpenedInDesktop';

export const renderOpenedInDesktop = (title: string, showJoinRoomModal: () => void) => {
  const root = document.createElement('div');
  root.id = 'opened-in-desktop-root';
  root.style.position = 'fixed';
  root.style.zIndex = '100000'; // should be higher than the join room modal
  document.body.appendChild(root);

  const close = () => {
    ReactDOM.unmountComponentAtNode(root);
    document.body.removeChild(root);
  };

  ReactDOM.render(
    <WithRoomStore>
      <OpenedInDesktop title={title} close={close} showJoinRoomModal={showJoinRoomModal} />
    </WithRoomStore>,
    root
  );
};
