import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './init-state';
import { setVisitedRooms, setVisitedRoomsLoading, setVisitedRoomsLimit } from './actions';

export const visitedRoomsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setVisitedRoomsLoading, (state, action) => {
      state.visitedRoomsLoading = action.payload.loading;
    })
    .addCase(setVisitedRooms, (state, action) => {
      state.visitedRooms = action.payload.visitedRooms;
      state.visitedRoomsIndex = {};
      state.visitedRooms.forEach((room, i) => {
        state.visitedRoomsIndex[room.id] = i;
      });
    })
    .addCase(setVisitedRoomsLimit, (state, action) => {
      state.limit = action.payload.limit;
    });
});
