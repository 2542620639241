import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Message from './Message';

// components
import { fadeOut } from '../../components/animate/keyframes';

// constants
import { DELAY_BEFORE_FADEOUT_MS, FADEOUT_DURATION_MS } from '../../../constants/chat-constants';

function FeedPopupMessage({ feedItem, deleteItem, deleteTextMessage, userCanAddContent }) {
  const [isFadingOut, setIsFadingOut] = React.useState(false);

  const startFadingOut = useCallback(() => {
    setIsFadingOut(true);
    setTimeout(() => {
      deleteItem(feedItem.id);
    }, FADEOUT_DURATION_MS);
  }, [deleteItem, feedItem.id]);

  useEffect(() => {
    const timerId = setTimeout(startFadingOut, DELAY_BEFORE_FADEOUT_MS);
    return () => {
      clearTimeout(timerId);
    };
  }, [startFadingOut]);

  const renderPopupMessage = React.useCallback(
    (message) => (
      <Message message={message} userCanAddContent={userCanAddContent} deleteMessage={deleteTextMessage} isFeedMsg />
    ),
    [userCanAddContent, deleteTextMessage]
  );

  return <PopupMessage isFadingOut={isFadingOut}>{renderPopupMessage(feedItem)}</PopupMessage>;
}

FeedPopupMessage.propTypes = {
  feedItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    createdAt: PropTypes.shape({}),
    type: PropTypes.string,
    imageUrl: PropTypes.string,
    storagePath: PropTypes.string,
    style: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    deletedElementId: PropTypes.string,
    url: PropTypes.string,
    emojiReactions: PropTypes.arrayOf(PropTypes.shape({})),
    linkSummary: PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
  deleteItem: PropTypes.func.isRequired,
  deleteTextMessage: PropTypes.func.isRequired,
  userCanAddContent: PropTypes.bool.isRequired,
};

const PopupMessage = styled.div`
  animation: ${({ isFadingOut }) =>
    isFadingOut
      ? () =>
          css`
            ${fadeOut} 0.5s linear forwards
          `
      : ''};
`;

const propsComparer = (prevProps, nextProps) =>
  prevProps.feedItem.id === nextProps.feedItem.id &&
  prevProps.feedItem.linkSummary === nextProps.feedItem.linkSummary &&
  prevProps.feedItem.deletedElementId === nextProps.feedItem.deletedElementId &&
  prevProps.feedItem.emojiReactions === nextProps.feedItem.emojiReactions;

export default React.memo(FeedPopupMessage, propsComparer);
