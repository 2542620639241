import { EmoteFromResponse, StickerPack } from '../../../definitions/emotes';

interface EmotesState {
  hereEmotePack: EmoteFromResponse[];
  emotes: EmoteFromResponse[];
  stickerPacks: StickerPack[];
  // fetch emotes:
  isLoadingFetchEmotes: boolean;
  fetchEmotesError: string;
  // fetch sticker packs:
  isLoadingStickerPacks: boolean;
  fetchStickerPacksError: string;
  // adding emote:
  isLoadingAddEmote: boolean;
  addEmoteError: string;
  // removing emote:
  isLoadingRemoveEmote: boolean;
  removeEmoteError: string;
}

export const initialState: EmotesState = {
  emotes: [],
  hereEmotePack: [],
  // fetch emotes:
  isLoadingFetchEmotes: false,
  fetchEmotesError: '',
  // fetch sticker packs:
  isLoadingStickerPacks: false,
  fetchStickerPacksError: '',
  stickerPacks: [],
  // adding emote:
  isLoadingAddEmote: false,
  addEmoteError: '',
  // removing emote:
  isLoadingRemoveEmote: false,
  removeEmoteError: '',
};
