import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserListItem from '../user-profile/UserListItem';
import { getOnlineStatus } from '../user-profile/api';
import FriendRequestButtons from '../friends/FriendRequestButtons';

import DismissIcon from '../../../assets/icons/dismiss.svg';
import { errorMessageMixin } from '../mixins';
import { addComplexAnimation } from '../store/animations/store';
import { COMPLEX_ANIMATIONS } from '../store/animations/constants';
import { EventSourceContext } from '../common/contexts.ts';
import { FRIEND_REQUEST_SOURCES } from '../../constants/analytics-events/friend-events';

const FriendRequestAlert = ({ alert, dismissAlert }) => {
  const dispatch = useDispatch();

  const userId = alert.requester;
  const [errorMessage, setErrorMessage] = useState('');
  const [onlineStatus, setOnlineStatus] = useState('');

  const getIsOnline = useCallback(async () => {
    const response = await getOnlineStatus(userId);

    if (response && response.success && response.result && response.result[userId]) {
      setOnlineStatus(response.result[userId].status);
    }
  }, [userId]);

  useEffect(getIsOnline, [getIsOnline]);

  const onAcceptIgnore = useCallback(() => {
    setTimeout(() => {
      dismissAlert();
    }, 1000);
  }, [dismissAlert]);

  const onAccept = useCallback(() => {
    dispatch(
      addComplexAnimation({
        animations: COMPLEX_ANIMATIONS.confettiWithAvatars,
        id: `${alert.requester}-friendship`,
        data: {
          firstUserId: alert.requester,
          secondUserId: alert.receiver,
        },
      })
    );

    onAcceptIgnore();
  }, [alert, onAcceptIgnore, dispatch]);

  const friendRequestCTAs = (
    <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.FRIEND_REQUEST_ALERT}>
      <ButtonContainer>
        <FriendRequestButtons
          requesterId={alert.requester}
          onAccept={onAccept}
          onIgnore={onAcceptIgnore}
          onDismissAlert={dismissAlert}
          onError={() => setErrorMessage('There was an error. Please try again.')}
        />
        <DismissButton onClick={dismissAlert}>
          <DismissIcon />
        </DismissButton>
      </ButtonContainer>
    </EventSourceContext.Provider>
  );

  return (
    <FriendRequestAlertContainer>
      <UserListItem userId={alert.requester} onlineStatus={onlineStatus} userCTAsSection={friendRequestCTAs} />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </FriendRequestAlertContainer>
  );
};

export default FriendRequestAlert;

FriendRequestAlert.propTypes = {
  alert: PropTypes.shape({
    requester: PropTypes.string.isRequired,
    receiver: PropTypes.string.isRequired,
  }).isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

const ButtonContainer = styled.div`
  display: flex;
`;

const DismissButton = styled.div`
  padding: 13px;
  cursor: pointer;

  svg {
    g {
      opacity: 0.3;
    }
  }

  &:hover {
    svg {
      g {
        opacity: 0.6;
      }
    }
  }

  @media (max-width: 615px) {
    display: none;
  }
`;

const FriendRequestAlertContainer = styled.div`
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const ErrorMessage = styled.div`
  ${errorMessageMixin()}
`;
