import React from 'react';

import PhoneModal from './PhoneModal';
import Portal from '../components/Portal';

const PhonePortal = ({
  onPortalMount = () => {},
  onPortalUnmount = () => {},
  closePhoneModal,
}: {
  onPortalMount?: () => void;
  onPortalUnmount?: () => void;
  closePhoneModal: () => void;
}) => (
  <>
    <div id="recaptcha-container" />
    <Portal root={document.body} id="phone-modal" onPortalMount={onPortalMount} onPortalUnmount={onPortalUnmount}>
      <PhoneModal closePhoneModal={closePhoneModal} />
    </Portal>
  </>
);

export default PhonePortal;
