import React, { useCallback } from 'react';
import { KaomojiCategory } from '../store/kaomoji/init-state';
import KaomojiItem from './KaomojiItem';

interface Props {
  onSelect: (params: { content: string; category: string }) => void;
  category: KaomojiCategory;
}

const KaomojiCategoryTab = ({ onSelect, category }: Props) => {
  const onSelectKaomoji = useCallback(
    (content) => onSelect({ content, category: category.name }),
    [category.name, onSelect]
  );

  return (
    <>
      {category.kaomojis.map((k) => (
        <KaomojiItem key={k.content} content={k.content} onSelect={onSelectKaomoji} />
      ))}
    </>
  );
};

export default KaomojiCategoryTab;
