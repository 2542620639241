import UIkit from 'uikit';
import $ from 'jquery';

import firebase from './firebase';
import log from './log';
import { boardUsers } from './presence';
import { htmlToElement, sanitize } from './util';
import { getElementPosition } from './element-transform';
import colorValues from './components/avatar/colors';
import { screenToCanvasCoords, canvasToScreenCoords } from './util/canvas-util';
import { isLockAllowed } from './roles-management';
import eventBus, { temporaryDrawingActivated } from './event-bus';
import { track } from './util/analytics-util';

// Bind canvas to listeners

export const DrawStates = {
  Draw: 'draw',
  Erase: 'erase',
};

export const EraserCursorStyle = 'url(images/icons/eraser-cursor.svg) 11 27, crosshair';
export const DrawCursorStyle = 'url(images/icons/pen-cursor.svg) 13 24, crosshair';

const kLineLifetime = 10000; // how long a line lives, in ms

export let screenDrawingCanvas = document.getElementById('pen-canvas');
export let screenDrawingContext;

export let currentColor = '#bd14ff';
let screenLines = {};
let currentPointList = [];

const cursorTimers = {};

let ctx; // The current context
let canvas; // The current canvas
let canvasHandler; // If we're drawing into a non-fullscreen canvas
let started = false;
let currentLineId = 0;

// we have a placeholder for each potential simultaneous guideline
export let xGuideline = [null, null];
export let yGuideline = [null, null];

const getScreenLinesWithPoints = (lines) =>
  Object.keys(lines).reduce((acc, key) => {
    const line = lines[key];
    if (line.points.length) {
      acc[key] = line;
      return acc;
    }

    return acc;
  }, {});

// Resize the canvas when the window resizes
(() => {
  if (!screenDrawingCanvas) return;

  screenDrawingContext = screenDrawingCanvas.getContext('2d');

  // resize the canvas to fill browser window dynamically
  window.addEventListener('resize', resizeCanvas, false);

  function resizeCanvas() {
    screenDrawingCanvas.width = window.innerWidth;
    screenDrawingCanvas.height = window.innerHeight;

    /**
     * Your drawings need to be inside this function otherwise they will be reset when
     * you resize the browser window and the canvas goes will be cleared.
     */
    if (ctx) {
      drawScreenLines();
    }
  }
  resizeCanvas();
})();

export function setPenColor(colorHex) {
  currentColor = colorHex;
  $('.color-indicator').css('background-color', currentColor);
}

UIkit.util.on(document, 'show', '#color-select', () => {
  track('Open Color Picker');
});

export function setupCanvas() {
  screenDrawingCanvas.width = window.innerWidth;
  screenDrawingCanvas.height = window.innerHeight;
  screenDrawingCanvas.addEventListener('mouseup', penUp, false);
}

function getMaxLineId() {
  let maxLineId = 0;
  Object.values(window.elementHandlers).forEach((handler) => {
    if (handler.constructor.elementType === 'WhiteboardElement') {
      Object.keys(handler.lines).forEach((key) => {
        key = parseInt(key, 10);
        if (key > maxLineId) {
          maxLineId = key;
        }
      });
    }
  });

  Object.keys(screenLines).forEach((key) => {
    if (key > maxLineId) {
      maxLineId = key;
    }
  });

  return maxLineId;
}

export function startDrawing(e) {
  if (ctx === undefined) {
    setupCanvas();
  }

  currentPointList = [];
  currentLineId = parseInt(getMaxLineId(), 10) + 1;

  let drawHandler = null;
  let zIndex = -1;
  const mxy = screenToCanvasCoords(e.clientX, e.clientY);
  document
    .getElementById('elements')
    .querySelectorAll('.boardElement')
    .forEach((element) => {
      const xy = getElementPosition(element);
      const style = getComputedStyle(element);
      const w = parseFloat(style.width);
      const h = parseFloat(style.height);
      const z = parseInt(style.zIndex, 10);
      if (
        mxy[0] >= xy[0] &&
        mxy[1] >= xy[1] &&
        mxy[0] <= xy[0] + w &&
        mxy[1] <= xy[1] + h &&
        z > zIndex &&
        element.classList.contains('can-move')
      ) {
        if (!isLockAllowed() && element.classList.contains('lockedInteraction')) {
          return;
        }
        const elementId = element.id.replace('element-', '');
        const handler = window.elementHandlers[elementId];
        // Whiteboard must be the top-most element that we find
        if (handler && handler.constructor.elementType === 'WhiteboardElement') {
          drawHandler = handler;
        } else {
          drawHandler = null;
        }
        zIndex = z;
      }
    });

  const m = getMouse(e);
  let x;
  let y;

  track('Draw Line', { state: drawHandler ? 'temporary' : 'permanent' });

  if (drawHandler) {
    ctx = drawHandler.getContext();
    canvas = drawHandler.getCanvas();
    canvasHandler = drawHandler;
    if (drawHandler.didStartDrawing) {
      drawHandler.didStartDrawing();
    }

    const canvasXY = screenToCanvasCoords(m.x, m.y);
    const element = document.getElementById(`element-${canvasHandler.elementId}`);
    const xy = getElementPosition(element);
    x = (canvasXY[0] - xy[0]) * 2 - canvas.width / 2;
    y = (canvasXY[1] - xy[1]) * 2 - canvas.height / 2;
  } else {
    // We're drawing on the screen, unless we find a whiteboard
    ctx = screenDrawingContext;
    canvas = screenDrawingCanvas;
    canvasHandler = null;
    x =
      (m.x - screenDrawingCanvas.width / 2) / window.canvasScale - window.canvasOffsetX + screenDrawingCanvas.width / 2;
    y =
      (m.y - screenDrawingCanvas.height / 2) / window.canvasScale -
      window.canvasOffsetY +
      screenDrawingCanvas.height / 2;
  }

  // Add the initial point in the line
  addPoint(x, y);

  started = true;

  ctx.lineWidth = 3;
  ctx.lineJoin = 'round';
  ctx.lineCap = 'round';

  return drawHandler;
}

function addPoint(x, y) {
  x = Math.round(x * 100) / 100;
  y = Math.round(y * 100) / 100;
  if (
    currentPointList.length === 0 ||
    currentPointList[currentPointList.length - 1].x !== x ||
    currentPointList[currentPointList.length - 1].y !== y
  ) {
    currentPointList.push({ x, y });
    window.rtc.sendDrawPoint(
      canvasHandler ? canvasHandler.elementId : null,
      currentLineId,
      currentColor,
      x,
      y,
      canvasHandler ? canvasHandler.drawState : null
    );
  }
}

export function didReceiveLinePoint(elementId, lineId, color, x, y, drawState) {
  let lineList = screenLines;
  let handler = null;

  if (elementId) {
    handler = window.elementHandlers[elementId];
    if (handler) {
      lineList = handler.lines;
      handler.hideOverlayMessage();
      ctx = handler.getContext();
    } else {
      log.error('Element not found for line', elementId);
    }
  } else {
    ctx = screenDrawingContext;
  }

  if (lineList[lineId]) {
    lineList[lineId].points.push({ x, y });
  } else {
    lineList[lineId] = {
      color,
      points: [{ x, y }],
    };
  }

  // If we're drawing on a whiteboard and whiteboard drawState is not set, set it
  if (elementId && !lineList[lineId].drawState && drawState) {
    lineList[lineId].drawState = drawState;
  }

  if (!elementId) {
    setLineDeletionTimer(lineId);
  }

  // TODO is this necessary?
  if (!ctx) {
    screenDrawingCanvas = document.getElementById('pen-canvas');
    screenDrawingCanvas.width = window.innerWidth;
    screenDrawingCanvas.height = window.innerHeight;
    ctx = screenDrawingCanvas.getContext('2d');
  }

  ctx.lineWidth = 3;
  ctx.lineJoin = 'round';
  ctx.lineCap = 'round';

  ctx.clearRect(0, 0, screenDrawingCanvas.width, screenDrawingCanvas.height);
  if (!handler) {
    drawScreenLines();
  } else {
    handler.hideOverlayMessage();
    drawLines(handler.lines, handler.getContext(), handler.getCanvas());
  }
}

export function removeLineTimers() {
  Object.values(screenLines).forEach((line) => {
    clearTimeout(line.timer);
  });
  screenLines = {};
}

// clear the canvas!
export function clearCanvas() {
  if (ctx && canvas) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }
}

function setLineDeletionTimer(lineId) {
  if (!screenLines[lineId]) {
    screenLines[currentLineId] = { color: currentColor, points: currentPointList };
  }

  if (screenLines[lineId].timer) {
    clearTimeout(screenLines[lineId].timer);
  }

  const lineToDelete = lineId;
  screenLines[lineId].timer = setTimeout(() => {
    const lineDeletionTimer = window.setInterval(() => {
      screenDrawingContext.clearRect(0, 0, screenDrawingCanvas.width, screenDrawingCanvas.height);
      drawScreenLines();
      if (!screenLines[lineToDelete] || screenLines[lineToDelete].points.length < 1) {
        delete screenLines[lineToDelete];
        screenDrawingContext.clearRect(0, 0, screenDrawingCanvas.width, screenDrawingCanvas.height);
        drawScreenLines();
        window.clearInterval(lineDeletionTimer);
      } else {
        screenLines[lineToDelete].points.shift();
      }
    }, 10);
  }, kLineLifetime);
}

export function penMove(e) {
  if (started) {
    setLineDeletionTimer(currentLineId);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const m = getMouse(e);
    let x;
    let y;
    if (ctx === screenDrawingContext) {
      x =
        (m.x - screenDrawingCanvas.width / 2) / window.canvasScale -
        window.canvasOffsetX +
        screenDrawingCanvas.width / 2;
      y =
        (m.y - screenDrawingCanvas.height / 2) / window.canvasScale -
        window.canvasOffsetY +
        screenDrawingCanvas.height / 2;
      eventBus.dispatch(temporaryDrawingActivated);
    } else if (canvasHandler) {
      const canvasXY = screenToCanvasCoords(m.x, m.y);
      const element = document.getElementById(`element-${canvasHandler.elementId}`);
      const xy = getElementPosition(element);
      x = (canvasXY[0] - xy[0]) * 2 - canvas.width / 2;
      y = (canvasXY[1] - xy[1]) * 2 - canvas.height / 2;
    } else {
      log.warn('Misconfigured context, not handling pen move');
    }

    addPoint(x, y);
    drawLines(canvasHandler ? canvasHandler.lines : getScreenLinesWithPoints(screenLines), ctx, canvas);
  }
}

export function penUp() {
  if (started) {
    started = false;
    if (canvasHandler) {
      // Uses the canvasHandler's current draw state
      canvasHandler.addLine(currentLineId, currentColor, currentPointList);
    } else {
      setLineDeletionTimer(currentLineId);
    }

    currentPointList = [];
  }
}

export function clearAlignmentGuidelines() {
  if (xGuideline[0] || yGuideline[0] || xGuideline[1] || yGuideline[1]) {
    xGuideline = [null, null];
    yGuideline = [null, null];
    if (!screenDrawingContext) {
      setupCanvas();
    }
    screenDrawingContext.clearRect(0, 0, screenDrawingCanvas.width, screenDrawingCanvas.height);
    drawScreenLines();
  }
}

function setContextForState(context, drawState) {
  if (drawState === DrawStates.Erase) {
    context.globalCompositeOperation = 'destination-out';
    context.lineWidth = 24;
  } else {
    context.globalCompositeOperation = 'source-over';
    context.lineWidth = context === screenDrawingContext ? 3 : 6;
  }
}

export function drawLines(lines, context, destCanvas) {
  context.lineJoin = 'round';
  context.lineCap = 'round';
  let scale = 1;
  let ofsX = 0;
  let ofsY = 0;
  if (context === screenDrawingContext) {
    scale = window.canvasScale;
    ofsX = window.canvasOffsetX;
    ofsY = window.canvasOffsetY;
    context.setLineDash([12, 8]);
  } else {
    context.setLineDash([]);
    ofsX = destCanvas.width / 2;
    ofsY = destCanvas.height / 2;
  }

  Object.keys(lines)
    .sort((a, b) => {
      const na = parseInt(a, 10);
      const nb = parseInt(b, 10);
      if (na < nb) return -1;
      if (na > nb) return 1;
      return 0;
    })
    .forEach((key) => {
      const pointData = lines[key];
      const { points } = pointData;

      setContextForState(context, pointData.drawState);
      context.strokeStyle = pointData.color;
      context.fillStyle = pointData.color;
      drawPoints(points, context, destCanvas, scale, ofsX, ofsY);
    });
  context.strokeStyle = currentColor;
  if (currentPointList.length > 0) {
    setContextForState(context, canvasHandler ? canvasHandler.drawState : null);
    drawPoints(currentPointList, context, destCanvas, scale, ofsX, ofsY);
  }
}

function drawAlignmentGuides() {
  screenDrawingContext.lineWidth = 1;
  screenDrawingContext.setLineDash([5, 5]);
  xGuideline.forEach((guide) => {
    if (guide) {
      screenDrawingContext.strokeStyle = '#ffff88';
      const xCoords = canvasToScreenCoords(guide, 0);

      screenDrawingContext.beginPath();
      screenDrawingContext.moveTo(xCoords[0], 0);
      screenDrawingContext.lineTo(xCoords[0], screenDrawingCanvas.height);
      screenDrawingContext.stroke();
    }
  });
  yGuideline.forEach((guide) => {
    if (guide) {
      screenDrawingContext.strokeStyle = '#ffff88';
      const yCoords = canvasToScreenCoords(0, guide);

      screenDrawingContext.beginPath();
      screenDrawingContext.moveTo(0, yCoords[1]);
      screenDrawingContext.lineTo(screenDrawingCanvas.width, yCoords[1]);
      screenDrawingContext.stroke();
    }
  });
}

export function drawScreenLines() {
  drawLines(getScreenLinesWithPoints(screenLines), screenDrawingContext, screenDrawingCanvas);
  drawAlignmentGuides();
}

function drawPoints(points, context, destCanvas, scale, ofsX, ofsY) {
  // draw a basic circle instead
  const hw = destCanvas.width / 2;
  const hh = destCanvas.height / 2;
  if (points.length < 6) {
    const b = points[0];
    const x = (b.x + ofsX - hw) * scale + hw;
    const y = (b.y + ofsY - hh) * scale + hh;
    context.beginPath();
    context.arc(x, y, context.lineWidth / 2, 0, Math.PI * 2, true);
    context.closePath();
    context.fill();
    return;
  }

  const startX = (points[0].x + ofsX - hw) * scale + hw;
  const startY = (points[0].y + ofsY - hh) * scale + hh;
  context.beginPath();
  context.moveTo(startX, startY);
  // draw a bunch of quadratics, using the average of two points as the control point
  let i = 1;
  for (; i < points.length - 2; i += 1) {
    const c = ((points[i].x + points[i + 1].x) / 2 + ofsX - hw) * scale + hw;
    const d = ((points[i].y + points[i + 1].y) / 2 + ofsY - hh) * scale + hh;
    const x = (points[i].x + ofsX - hw) * scale + hw;
    const y = (points[i].y + ofsY - hh) * scale + hh;
    context.quadraticCurveTo(x, y, c, d);
  }

  const x = (points[i].x + ofsX - hw) * scale + hw;
  const y = (points[i].y + ofsY - hh) * scale + hh;
  const finalX = (points[i + 1].x + ofsX - hw) * scale + hw;
  const finalY = (points[i + 1].y + ofsY - hh) * scale + hh;
  context.quadraticCurveTo(x, y, finalX, finalY);
  context.stroke();
}

// Creates an object with x and y defined,
// set to the mouse position relative to the state's canvas
// If you wanna be super-correct this can be tricky,
// we have to worry about padding and borders
// takes an event and a reference to the canvas
function getMouse(e) {
  let element = screenDrawingCanvas;
  let offsetX = 0;
  let offsetY = 0;

  // Compute the total offset. It's possible to cache this if you want
  if (element.offsetParent !== undefined) {
    do {
      offsetX += element.offsetLeft;
      offsetY += element.offsetTop;
      element = element.offsetParent;
    } while (element);
  }

  // We return a simple javascript object with x and y defined
  return { x: e.pageX - offsetX, y: e.pageY - offsetY };
}

export function showCursor(uid, x, y) {
  if (uid === firebase.auth().currentUser.uid) {
    return; // ignore our own cursor
  }

  let cursor = document.getElementById(`c-${uid}`);
  if (!cursor) {
    const boardUser = boardUsers[window.currentBoardId] ? boardUsers[window.currentBoardId][uid] : null;
    if (!boardUser) {
      log.debug('Cursor event for unknown user', uid);
      return;
    }

    const { name } = boardUser;

    const firstName = name ? name.split(' ')[0] : '';

    const cursorColorName = boardUsers[window.currentBoardId][uid].color;
    let cursorColorHEX = colorValues[cursorColorName];
    if (!cursorColorHEX) {
      cursorColorHEX = colorValues.default;
    }

    cursor = htmlToElement(`
    <div id="c-${uid}" class="user-cursor">
      <div class="maintain-size-in-viewport" style="transform: scale3d(${1 / window.canvasScale}, ${
      1 / window.canvasScale
    }, 1)">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6706 13.7317C22.1101 13.5559 22.082 12.9246 21.6286 12.7886L9.88941 9.26682C9.50801 9.1524 9.1524 9.50801 9.26682 9.88941L12.7886 21.6286C12.9246 22.082 13.5559 22.1101 13.7317 21.6706L15.9204 16.199C15.9712 16.0719 16.0719 15.9712 16.199 15.9204L21.6706 13.7317Z" fill="${cursorColorHEX}"/>
          <path d="M21.8563 14.196C22.7353 13.8444 22.679 12.5817 21.7723 12.3097L10.0331 8.78791C9.27028 8.55907 8.55907 9.27028 8.78791 10.0331L12.3097 21.7723C12.5817 22.679 13.8444 22.7353 14.196 21.8563L16.3847 16.3847L21.8563 14.196Z" stroke="white"/>
          </g>
          <defs>
          <filter id="filter0_d" x="0.244141" y="0.243683" width="30.7413" height="30.7413" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
        </svg>
        <span class='user-cursor-name'>${sanitize(firstName)}</span>
      </div>
    </div>`);
    document.getElementById('elements').appendChild(cursor);
  }

  cursor.style.transform = `translate3d(${x}px, ${y}px, 0)`;
  if (cursorTimers[uid]) {
    clearTimeout(cursorTimers[uid]);
  }
  cursorTimers[uid] = setTimeout(() => {
    cursor.classList.add('fadeout');
    cursor.addEventListener('transitionend', () => {
      cursor.remove();
    });
  }, 3000);
}

export function updatePenColorUI(newPenColor) {
  const penColorIndicatorEls = document.querySelectorAll('.pen-color-indicator');
  setPenColor(newPenColor);
  penColorIndicatorEls.forEach((el) => {
    el.style.backgroundColor = newPenColor;
  });
}

document.addEventListener('keyup', (e) => {
  // on shift
  if (e.key === 'Shift') {
    const penCanvas = document.getElementById('pen-canvas');
    if (penCanvas) {
      penCanvas.style.cursor = 'default';
    }
  }
});
