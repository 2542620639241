import firebase, { db } from './firebase';
import log from './log';
import { screenToCanvasCoords } from './util/canvas-util';
import { boardUsers } from './presence';
import SdkWorker from './sdk/sdk-worker-interface';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import { addSystemMessage } from './message-util';
import BoardElement from './board-element';
import { emitParticles } from './particles';

import '../styles/sdk/ui.less';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

export const INTERNAL_APP_NAMES = {
  DICE: 'Dice',
  TO_DO: 'ToDo',
  POLL: 'Poll',
};

// Published Internal Apps
export const Apps = {
  Dice: 'vo1VAf1tdfYevgLjYHr7',
  ToDo: 'HyeNLuM1DFkAixuLl2he',
  Poll: '3duiE8RsQPqI0YhSv8vJ',
};

export const CodeState = {
  WorkInProgress: 'wip',
  Published: 'published',
};

export default class AppElement extends BoardElement {
  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(element, elementDoc) {
    this.elementData = elementDoc.data();

    // Quick and dirty check if data has changed
    const newAppData = this.elementData.appData;
    const newJSONData = JSON.stringify(newAppData);
    const oldJSONData = JSON.stringify(this.appData);
    if (newJSONData !== oldJSONData) {
      this.appData = newAppData || {};
      this.worker.onDataUpdated(newAppData);
    }
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  async setup(elementId, elementDoc) {
    const data = elementDoc.data();
    this.appData = data.appData || {};
    if (this.state === CodeState.Published) {
      this.loadPublishedCode();
    } else {
      this.monitorEditorChanges();
    }
  }

  // Required method
  getElement(elementDoc) {
    const data = elementDoc.data();
    this.elementData = data;
    this.state = data.appId ? CodeState.Published : CodeState.WorkInProgress;
    this.appId = data.appId;
    this.editorId = data.editorId || data.codeId;

    const container = htmlToElement(`
      <div style="height: 100%" class="rotatable-container">
        <div class="app-container"></div>
        <div class="errors"></div>
      </div>
    `);

    return wrapElement(container, elementDoc, {
      classes: ['sdk-element'],
    });
  }

  async loadPublishedCode() {
    const ref = await db.collection('hereApps').doc(this.appId).get();
    if (!ref.exists) {
      log.error('App ID not found', this.appId);
      return;
    }
    this.reloadCode(ref.data().code, this.appData);
  }

  monitorEditorChanges() {
    // Put this onto the runloop in case elementHandler for code hasn't
    // been added yet.
    setTimeout(() => {
      const handler = window.elementHandlers[this.editorId];
      if (!handler) {
        log.error(`No code handler for note ${this.editorId}`);
        return;
      }

      handler.onTextChanged = (text) => {
        if (this.codeChangeDebouncer) {
          clearTimeout(this.codeChangeDebouncer);
        }
        this.codeChangeDebouncer = setTimeout(() => {
          this.reloadCode(text, this.appData);
        }, 500);
      };

      this.reloadCode(handler.rawContent, this.appData);
    }, 0);
  }

  async reloadCode(newCode, initialData) {
    if (this.worker) {
      this.worker.terminate();
    }
    const el = document.getElementById(`element-${this.elementId}`);
    const errorEl = el.querySelector('.errors');
    errorEl.style.display = 'none';

    const context = {
      currentUser: {
        id: firebase.auth().currentUser.uid,
        name: firebase.auth().currentUser.displayName,
      },
      users: {
        ...boardUsers[window.currentBoardId],
      },
    };
    this.worker = new SdkWorker(newCode, initialData, context, el.querySelector('.app-container'), this.elementId, {
      // Something went horribly wrong
      onError: (message) => {
        log.error('Error: ', message);
        errorEl.innerHTML = message;
        errorEl.style.display = 'block';
      },
      // Successfully loaded/reloaded the app
      onLoaded: () => {},
      // Incoming data updates from the app
      handleDataUpdate: (data) => {
        Object.keys(data).forEach((key) => {
          if (data[key] !== null && data[key] !== undefined) {
            this.appData[key] = data[key];
          } else {
            delete this.appData[key];
          }
        });
        db.collection('boards')
          .doc(window.currentBoardId)
          .collection('elements')
          .doc(this.elementId)
          .update({ appData: this.appData });
      },
      emitParticles: (particleData) => {
        if (!particleData) {
          particleData = {};
        }
        if (!particleData.rect) {
          particleData.rect = [
            this.elementData.center[0],
            this.elementData.center[1],
            this.elementData.size[0],
            this.elementData.size[1],
          ];
        }
        emitParticles(particleData);
        window.rtc.sendParticleEffect(particleData);
      },
    });
  }

  elementDescription() {
    return this.elementData.elementName?.toLowerCase() || 'object';
  }

  // Statics

  static async addElement({ editorId, appId, name, systemMessage }) {
    const ref = await db
      .collection('boards')
      .doc(window.currentBoardId)
      .collection('elements')
      .add({
        class: 'AppElement',
        center: screenToCanvasCoords(window.innerWidth / 2, window.innerHeight / 2 - 200),
        creator: firebase.auth().currentUser.uid,
        size: [400, 300],
        editorId: editorId || null,
        appId: appId || null,
        elementName: name || null,
        zIndex: window.getFrontZIndex(),
      });
    track(ADD_ELEMENT, {
      element: ELEMENT_TYPES[name] || ELEMENT_TYPES.SDK_APP,
      destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM,
    });
    if (systemMessage) {
      addSystemMessage(systemMessage);
    }

    return ref;
  }
}

AppElement.elementType = 'AppElement';
