import styled from 'styled-components';

export const TimerContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  grid-template-rows: repeat(2, auto);
`;

export const TimerContainerCellContent = styled.div`
  font-size: 49px;
  font-weight: bolder;
  font-family: var(--primary-font, Inter, sans-serif);
  text-align: center;
`;

export const TimerContainerCellLegend = styled.div`
  font-size: 16px;
  font-weight: bolder;
  font-family: var(--primary-font, Inter, sans-serif);
  text-align: center;
`;
