export enum WebLobbyTabs {
  Rooms = 'Rooms',
  DMs = 'DMs',
  UserPage = 'User Page',
  Friends = 'Friends',
  Settings = 'Settings',
}

export const tabsToTitle: { [key in WebLobbyTabs]?: string } = {
  [WebLobbyTabs.Rooms]: '✨ Lobby | Here ✨',
  [WebLobbyTabs.DMs]: '✨ Messages | Here ✨',
  [WebLobbyTabs.UserPage]: '✨ Your Profile Page | Here ✨',
  [WebLobbyTabs.Friends]: '✨ Your Friends | Here ✨',
};

export const tabsToPath: { [key in WebLobbyTabs]?: string } = {
  [WebLobbyTabs.Rooms]: '/r',
  [WebLobbyTabs.DMs]: '/m',
  [WebLobbyTabs.UserPage]: '/u',
  [WebLobbyTabs.Friends]: '/f',
};

export enum GroupSettingsTabs {
  Background = 'Background',
  Theme = 'Theme',
}
