import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './init-state';
import { chatSkinningDataFetched, setIsChatSkinningDataLoading } from './actions';

export const chatSkinningReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setIsChatSkinningDataLoading, (state, action) => {
      state.isLoading = action.payload.isLoading;
    })
    .addCase(chatSkinningDataFetched, (state, action) => {
      state.defaultData = action.payload.data;
    })
);
