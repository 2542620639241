import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UIkit from 'uikit';

import Draggable from '../components/Draggable.tsx';
import { addImageRequested } from '../store/image-search/store';

const ImageSearchResultView = ({ title, url, thumbnail, onImageClick }) => (
  <ImageContainer>
    <Draggable contentSelector="img" data={{ url, type: 'image', imageType: 'image', useImageProxy: true }}>
      <img alt={title} src={thumbnail} onClick={useCallback(() => onImageClick({ url }), [onImageClick, url])} />
    </Draggable>
  </ImageContainer>
);

ImageSearchResultView.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default connect(null, (dispatch, props) => ({
  // TODO: Move props-related stuff (AND UIKIT CALL) into the component.
  onImageClick: () => {
    const payload = {};
    payload.isChat = props.isChat;
    payload.firebaseId = props.firebaseId;
    payload.elementId = props.elementId;
    payload.url = props.url;
    payload.thumbnail = props.thumbnail;
    payload.width = props.size?.width;
    payload.height = props.size?.height;
    payload.caller = props.caller;
    payload.imageType = 'image';
    payload.useImageProxy = true;
    if (document.querySelector(`#${props.elementMenuId}`)) {
      UIkit.dropdown(`#${props.elementMenuId}`).hide(0);
    }
    if (props.onResultClick) {
      props.onResultClick(payload);
    }
    return dispatch(addImageRequested(payload));
  },
}))(ImageSearchResultView);

const ImageContainer = styled.div`
  margin: 20px 0;
`;
