import React, { useState } from 'react';
import styled from 'styled-components';

// components
import { Image, LoadingStub, UploadedImageContainer } from './shared-styles';

interface VibePickerOptionProps {
  id: string;
  title: string;
  isActive?: boolean;
  bgUrl: string | null;
  onClick: () => void;
  font?: string;
}

const VibePickerOption = ({ id, title, isActive, bgUrl, onClick, font }: VibePickerOptionProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const onLoad = () => {
    setImageLoaded(true);
  };

  return (
    <UploadedImageContainer isActive={isActive}>
      <Image
        src={bgUrl}
        isActive={isActive}
        imageLoaded={imageLoaded}
        onClick={onClick}
        onLoad={onLoad}
        data-testid={`vibe-picker-option-${id}`}
      />
      <Title imageLoaded={imageLoaded} onClick={onClick} font={font}>
        {title}
      </Title>
      <LoadingStub imageLoaded={imageLoaded} />
    </UploadedImageContainer>
  );
};

const Title = styled.p<{ imageLoaded: boolean; font: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  width: fit-content;
  z-index: 10;
  display: ${({ imageLoaded }) => (imageLoaded ? 'block' : 'none')};
  color: white;
  font-size: 24px;
  font-family: ${({ font }) => font || 'inherit'};
  font-weight: bolder;
  box-shadow: 0 0 20px 16px #0000002f;
  background-color: #0000002f;
`;

export default VibePickerOption;
