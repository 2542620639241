import log from '../log';
import { htmlToElement } from '../util';

const MaxUserDisplay = 5;

export function getPresenceElements(users, boardId, classes = 'presence-icon presence-bar-item bar-item') {
  const elements = [];
  const keys = Object.keys(users);
  keys.slice(0, MaxUserDisplay).forEach((uid) => {
    const userData = users[uid];
    if (userData && userData.name) {
      elements.push(
        htmlToElement(`
        <div class="settings-button-container" userId="${uid}">
          <div id="settings-button-${uid}" class="${classes}" uk-tooltip="title: ${userData.name}; pos: bottom">
            <here-avatar class="color-ring" userId="${uid}" roomId="${boardId}"/>
          </div>
        </div>
      `)
      );
    } else {
      log.debug(`User data for user ${uid} incomplete`, JSON.stringify(userData));
    }
  });

  if (keys.length > MaxUserDisplay) {
    const viewersCount = keys.filter((uid) => users[uid].role === 'viewer').length;

    elements.push(
      htmlToElement(`
      <span class="more-icon">
        <span class="user-count">+${keys.length - MaxUserDisplay}</span>
        <div class="user-count-tooltip">
          <div class="user-count-wrapper"><here-inline-svg class="user-count-icon" src="images/icons/total-user-count.svg"></here-inline-svg>${
            keys.length
          }</div>
          ${
            viewersCount > 0
              ? `<div class="user-count-wrapper" style="margin-left: 15px"><img class="user-count-icon" src="images/icons/viewers-count.svg">${viewersCount}</div>`
              : ''
          }
        </div>
      </span>
    `)
    );
  }

  return elements;
}
