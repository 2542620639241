import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'lodash/fp/isEqual';

import { db } from '../../../../firebase';

// store
import { selectIsRoomVisible } from '../../../store/web-lobby/selectors.ts';

// components
import RoomItem from './RoomItem';
import RoomItemMessagePreviewWithAnimation from './components/RoomItemMessagePreviewWithAnimation';
import RoomItemMembersList from './components/RoomItemMembersList';
import OutsideReaction from './OutsideReaction.tsx';

const sentLessThanFiveSecondsAgo = (message) => {
  const FIVE_SECONDS_IN_MS = 5 * 1000;
  const timestampDate = message.createdAt ? message.createdAt.toDate() : new Date();
  const now = new Date();

  const timeDifference = now.getTime() - timestampDate.getTime();
  return timeDifference < FIVE_SECONDS_IN_MS;
};

const RoomItemWithMessagePreview = ({ roomData, title }) => {
  const isOnScreen = useSelector((state) => selectIsRoomVisible(state, roomData.id));

  const [messagePreview, setMessagePreview] = useState(null);

  useEffect(() => {
    let unsubscribe;

    // If is on screen, subscribe to room messages
    if (isOnScreen) {
      unsubscribe = db
        .collection(`/boards/${roomData.id}/feedItems`)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            const docData = change.doc.data();
            if (sentLessThanFiveSecondsAgo(docData)) {
              // only setting messages that were less than one minute ago
              // we don't want to see a message unless it was recent
              setMessagePreview({ ...docData, id: change.doc.id });
            }
          });
        });
    }

    // If is not on screen, and snapshot exists, unsubscribe
    if (!isOnScreen && unsubscribe) {
      unsubscribe();
    }

    // If snapshot exists, unsubscribe on unmount of component
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [isOnScreen, roomData.title, roomData.id]);

  const unmountMessage = useCallback(() => {
    setMessagePreview(null);
  }, []);

  const membersListComponent = useMemo(() => <RoomItemMembersList members={roomData.members} />, [roomData.members]);
  const contextMenuComponent = null;
  const outsideReactionComponent = useMemo(
    () => <OutsideReaction roomId={roomData.id} type="primary" />,
    [roomData.id]
  );

  return (
    <Container>
      <RoomItem
        title={title}
        roomData={roomData}
        contextMenuComponent={contextMenuComponent}
        outsideReactionComponent={outsideReactionComponent}
        membersListComponent={membersListComponent}
      />
      {messagePreview && (
        <RoomItemMessagePreviewWithAnimation
          key={messagePreview.id}
          message={messagePreview}
          unmount={unmountMessage}
        />
      )}
    </Container>
  );
};

export default React.memo(RoomItemWithMessagePreview, (prevProps, nextProps) => isEqual(prevProps, nextProps));

RoomItemWithMessagePreview.propTypes = {
  title: PropTypes.node.isRequired,
  roomData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundColor: PropTypes.string,
    urlAlias: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
    isHereClub: PropTypes.bool,
  }).isRequired,
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;
