import { getRoomActiveTime } from '../../../../api/stats-api';
import eventBus, { boardChanged } from '../../../../event-bus';
import log from '../../../../log';

const SERVER_REFRESH_INTERVAL_MS = 1000 * 60 * 5;
const LOCAL_REFRESH_INTERVAL_MS = 1000;

export interface RoomActiveTimeData {
  totalSeconds: number;
  serverRefreshFailed: boolean;
  serverRefreshInterval: ReturnType<typeof setInterval>;
  localRefreshInterval: ReturnType<typeof setInterval>;
  listeners: ((data: RoomActiveTimeData) => void)[];
}

const roomsActiveTimeData: {
  [key: string]: RoomActiveTimeData;
} = {};

eventBus.on(boardChanged, () => {
  Object.keys(roomsActiveTimeData).forEach((roomId) => {
    clearInterval(roomsActiveTimeData[roomId].serverRefreshInterval);
    clearInterval(roomsActiveTimeData[roomId].localRefreshInterval);
    delete roomsActiveTimeData[roomId];
  });
});

export const addRoomActiveTimeDataListener = async (roomId: string, listener: (data: RoomActiveTimeData) => void) => {
  if (!roomId) {
    return;
  }

  if (roomsActiveTimeData[roomId]) {
    listener(roomsActiveTimeData[roomId]);
    roomsActiveTimeData[roomId].listeners.push(listener);
    return;
  }

  const refreshRoomActiveTime = async () => {
    try {
      const activeTime = await getRoomActiveTime(roomId);
      roomsActiveTimeData[roomId].totalSeconds = activeTime.data.total;
      roomsActiveTimeData[roomId].serverRefreshFailed = false;
      roomsActiveTimeData[roomId].listeners.forEach((l) => l(roomsActiveTimeData[roomId]));
    } catch (error) {
      log.error(error);
      roomsActiveTimeData[roomId].serverRefreshFailed = true;
      roomsActiveTimeData[roomId].listeners.forEach((l) => l(roomsActiveTimeData[roomId]));
    }
  };

  roomsActiveTimeData[roomId] = {
    totalSeconds: 0,
    serverRefreshFailed: false,
    serverRefreshInterval: null,
    localRefreshInterval: null,
    listeners: [listener],
  };

  await refreshRoomActiveTime();

  roomsActiveTimeData[roomId].serverRefreshInterval = setInterval(refreshRoomActiveTime, SERVER_REFRESH_INTERVAL_MS);
  roomsActiveTimeData[roomId].localRefreshInterval = setInterval(() => {
    roomsActiveTimeData[roomId].totalSeconds += 1;
    roomsActiveTimeData[roomId].listeners.forEach((l) => l(roomsActiveTimeData[roomId]));
  }, LOCAL_REFRESH_INTERVAL_MS);
};

export const removeRoomActiveTimeDataListener = (roomId: string, listener: (data: RoomActiveTimeData) => void) => {
  if (!roomsActiveTimeData[roomId]) {
    return;
  }

  roomsActiveTimeData[roomId].listeners = roomsActiveTimeData[roomId].listeners.filter((l) => l !== listener);
  if (roomsActiveTimeData[roomId].listeners.length === 0) {
    clearInterval(roomsActiveTimeData[roomId].serverRefreshInterval);
    clearInterval(roomsActiveTimeData[roomId].localRefreshInterval);
    delete roomsActiveTimeData[roomId];
  }
};
