import BoardElement from './board-element';
import firebase, { db } from './firebase';
import { addSystemMessage } from './message-util';
import { screenToCanvasCoords } from './util/canvas-util';
import wrapElement from './element-wrapper';
import { htmlToElement } from './util';
import log from './log';
import { renderRoomStreaksBoardElement } from './react/board-elements/streak/renderStreaks';

import '../styles/timer.less';
import { track } from './util/analytics-util';
import { ADD_ELEMENT, ADD_ELEMENT_DESTINATION_TYPES, ELEMENT_TYPES } from './constants/analytics-events/element-events';

export default class RoomStreaksElement extends BoardElement {
  constructor(elementId) {
    super(elementId);

    this.title = 'Room Streaks';
  }

  elementDescription() {
    return 'Room Streaks';
  }

  minSize() {
    return [190, 240];
  }

  // Required method
  // Returns: True if update has been handled, false if it should be reloaded
  handleUpdate(__element, __elementDoc) {
    return true;
  }

  // Required method
  // Called after the html for the element has been laid out in the DOM
  setup(__elementId, __elementDoc) {}

  // Required method
  getElement(elementDoc) {
    const roomStreak = htmlToElement(`
      <div style="height: 100%;">
      </div>
    `);
    renderRoomStreaksBoardElement(roomStreak, { elementId: this.elementId });
    return wrapElement(roomStreak, elementDoc, { preserveAspectRatio: true });
  }

  static async addElement() {
    try {
      await db.collection(`boards/${window.currentBoardId}/elements`).add({
        class: 'RoomStreaksElement',
        center: screenToCanvasCoords(
          Math.floor(Math.random() * 200 - 100) + window.innerWidth / 2,
          Math.floor(Math.random() * 200 - 110) + window.innerHeight / 2
        ),
        size: [190, 240],
        zIndex: window.getFrontZIndex(),
        creator: firebase.auth().currentUser.uid,
      });
      addSystemMessage('added a room streaks widget');
      track(ADD_ELEMENT, { element: ELEMENT_TYPES.ROOM_STREAKS, destination: ADD_ELEMENT_DESTINATION_TYPES.ROOM });
    } catch (error) {
      log.error(`Something bad happened when adding room streaks widget: ${error}`);
    }
  }
}

RoomStreaksElement.elementType = 'RoomStreaksElement';
