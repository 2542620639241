import eventBus, { friendInviteSent } from '../../../event-bus';
import { authedApiInstance, db } from '../../../firebase';

// create friend request api call:
export const createFriendRequest = (receiver) => {
  eventBus.dispatch(friendInviteSent);

  return authedApiInstance
    .post('/friends/request', { receiver })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);
};

// approve friend request api call:
export const approveFriendRequest = (requester) =>
  authedApiInstance
    .post('/friends/request/approve', { requester })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

// ignore friend request api call:
export const ignoreFriendRequest = (requester) =>
  authedApiInstance
    .post('/friends/request/ignore', { requester })
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

// query firestore db for friend request:
export const getFriendRequest = async (requester, receiver) => {
  const queryResult = await db
    .collection('friendRequests')
    .where('requester', '==', requester)
    .where('receiver', '==', receiver)
    .get();

  if (queryResult.size > 0) {
    // there should only be one:
    return queryResult.docs[0].data();
  }
  return null;
};

export const subscribeToOutgoingFriendRequests = (userId, onChanged) =>
  db
    .collection('friendRequests')
    .where('requester', '==', userId)
    .onSnapshot((snapshot) => {
      onChanged(snapshot.docs.map((d) => ({ id: d.id, ...d.data() })));
    });

export const subscribeToIncomingFriendRequests = (userId, onChanged) =>
  db
    .collection('friendRequests')
    .where('receiver', '==', userId)
    .onSnapshot((snapshot) => {
      onChanged(snapshot.docs.map((d) => ({ id: d.id, ...d.data() })));
    });

// get all friends api call:
export const getFriendsList = () =>
  authedApiInstance
    .get('/friends')
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

// remove friends api call:
export const removeFriend = (userId) =>
  authedApiInstance
    .delete(`/friends/${userId}`)
    .then((response) => response.data)
    .catch((error) => error?.response?.data);

export const getSuggestedFriends = async () => {
  const suggestedFriends = await authedApiInstance.get('/friends/suggest');
  return suggestedFriends;
};

export const getSuggestedFriendsV2 = async () => {
  const suggestedFriends = await authedApiInstance.get('/v2/friends/suggest');
  return suggestedFriends;
};
