import { track, trackInForeground } from './util/analytics-util';

export const collectPageLoadData = () => {
  if (!window.PerformanceObserver) {
    return Promise.reject(new Error('PerformanceObserver is not supported'));
  }

  const navigationObserverPromise = new Promise((resolve) => {
    const observer = new PerformanceObserver((list) => {
      const [entry] = list.getEntries();
      if (entry && entry.entryType === 'navigation') {
        observer.disconnect();
        resolve(entry);
      }
    });

    observer.observe({ type: 'navigation', buffered: true });
  });

  const firstPaintObserverPromise = new Promise((resolve) => {
    const observer = new PerformanceObserver((list) => {
      const [entry] = list.getEntries();
      if (entry && entry.name === 'first-paint') {
        observer.disconnect();
        resolve(entry);
      }
    });

    observer.observe({ type: 'paint', buffered: true });
  });

  const firstContentfulPaintObserverPromise = new Promise((resolve) => {
    const observer = new PerformanceObserver((list) => {
      const [entry] = list.getEntries();
      if (entry && entry.name === 'first-contentful-paint') {
        observer.disconnect();
        resolve(entry);
      }
    });

    observer.observe({ type: 'paint', buffered: true });
  });

  // TODO: Find out how to collect and aggregate  all 'largest-contentful-paint'-events

  // const largestContentfulPaintObserverPromise = new Promise((resolve) => {
  //   const observer = new PerformanceObserver((list) => {
  //     const [entry] = list.getEntries();
  //     if (entry && entry.entryType === 'largest-contentful-paint') {
  //       observer.disconnect();
  //       resolve(entry);
  //     }
  //   });

  //   observer.observe({ type: 'largest-contentful-paint', buffered: true });
  // });

  return Promise.all([
    firstPaintObserverPromise,
    navigationObserverPromise,
    firstContentfulPaintObserverPromise,
    // largestContentfulPaintObserverPromise,
  ]);
};

export const sendAnalyticsCallback = (
  event,
  [firstPaintObserver, navigationObserver, firstContentfulPaintObserver /* , largestContentfulPaintObserverPromise */]
) => {
  const dataToTrack = {};

  if (firstPaintObserver) {
    dataToTrack.firstPaintStartTime = firstPaintObserver.startTime;
  }

  if (navigationObserver) {
    dataToTrack.navigationDuration = navigationObserver.duration;
  }

  if (firstContentfulPaintObserver) {
    dataToTrack.firstContentfulPaintStartTime = firstContentfulPaintObserver.startTime;
  }

  // if (largestContentfulPaintObserverPromise) {
  //   dataToTrack.largestContentfulPaintStartTime = largestContentfulPaintObserverPromise.startTime;
  // }

  const roundedDataToTrack = Object.keys(dataToTrack).reduce(
    (acc, key) => ({
      ...acc,
      [key]: Math.round(dataToTrack[key]),
    }),
    {}
  );

  trackInForeground(event, roundedDataToTrack);
};
