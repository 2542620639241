import UIKit from 'uikit';
import React from 'react';
import ReactDOM from 'react-dom';

import SignInModal from './SignInModal';
import { WithRoomStore } from '../store/Wrapper';
import eventBus, { unmountSignInModalRequested } from '../../event-bus';
import { CreateOsDesktopFlowSteps, flows } from './constants.ts';
import RoomOnboarding from './RoomOnboarding.tsx';
import store from '../store/room-root-store';
import { startFlow } from '../store/signing-in/actions.ts';
import CreateOsDesktop from './create-os-desktop/CreateOsDesktop.tsx';

export default function renderSignInFlow({ flowId = flows.STANDARD_ONBOARDING, onClose } = {}, { profileId } = {}) {
  let signInModalContainer = document.getElementById('sign-in-modal-container');
  if (!signInModalContainer) {
    signInModalContainer = document.createElement('div');
    signInModalContainer.setAttribute('id', 'sign-in-modal-container');
    signInModalContainer.setAttribute('uk-modal', '');
    signInModalContainer.setAttribute('esc-close', flowId === flows.STANDARD_ONBOARDING ? 'true' : 'false');
    signInModalContainer.style.padding = '0';
    document.body.appendChild(signInModalContainer);
  }

  UIKit.modal('#sign-in-modal-container').show();

  const onSignInModalHide = () => {
    ReactDOM.unmountComponentAtNode(signInModalContainer);
  };

  UIKit.util.on('#sign-in-modal-container', 'hide', onSignInModalHide);

  ReactDOM.render(
    <WithRoomStore>
      <SignInModal flowId={flowId} profileId={profileId} onClose={onClose} />
    </WithRoomStore>,
    signInModalContainer
  );
}

export function unmountSignInFlow() {
  UIKit.modal('#sign-in-modal-container')?.hide();
  const signInModalContainer = document.getElementById('sign-in-modal-container');
  if (signInModalContainer) {
    ReactDOM.unmountComponentAtNode(signInModalContainer);
  }
}

// Dependency cycle without the event bus :'(
eventBus.on(unmountSignInModalRequested, unmountSignInFlow);

export function renderRoomOnboardingFlow(roomOnboardingStepId) {
  ReactDOM.render(
    <WithRoomStore>
      <RoomOnboarding />
    </WithRoomStore>,
    document.getElementById('room-onboarding')
  );

  store.dispatch(startFlow({ flowId: flows.ROOM_ONBOARDING, screenId: roomOnboardingStepId }));
}

export function renderCreateOsDesktopFlow(container) {
  ReactDOM.render(
    <WithRoomStore>
      <CreateOsDesktop />
    </WithRoomStore>,
    container
  );

  store.dispatch(startFlow({ flowId: flows.CREATE_OS_DESKTOP, screenId: CreateOsDesktopFlowSteps.VIBE_PICKER }));
}
