import { onSnapshot } from '../firestore-watcher';
import firebase from '../firebase';
import { signout } from './user-util';

/**
 * Nuked users cannot use the app. They can't use their accounts, or create new
 * ones that work.
 *
 */

export function checkNuked() {
  return localStorage.nukedAt;
}

(() => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      onSnapshot(`/userProfiles/${user.uid}`, (profile) => {
        if (profile?.nukedAt) {
          localStorage.nukedAt = profile.nukedAt;
          signout();
        }
      });
    }
  });
})();
