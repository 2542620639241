import { useCallback, useEffect, useRef, useState } from 'react';
import { Categories, categories } from '../constants';

export const useCurrentCategory = () => {
  const [currentCategory, setCurrentCategory] = useState<string>(Categories.FREQ_USED);

  const [refsReady, setRefsReady] = useState(false);
  const categoryRefs = useRef<HTMLDivElement[]>([]);
  const observer = useRef(null);

  const setCategoryRef = (element: HTMLDivElement | null, index: number) => {
    if (element) {
      categoryRefs.current[index] = element;
      if (categoryRefs.current?.length === categories.length) {
        setRefsReady(true); // Update state when all refs are set
      }
    }
  };

  // When refs are all set, set observers
  useEffect(() => {
    if (refsReady) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setCurrentCategory(entry.target.id);
            }
          });
        },
        { threshold: 0.1 }
      );

      categoryRefs.current.forEach((div) => {
        if (observer.current) {
          observer.current?.observe(div);
        }
      });

      return () => {
        observer.current?.disconnect();
      };
    }
    return () => {};
  }, [refsReady]);

  // Scrolling to section functionality for nav item clicks:

  const scrollToSection = useCallback((index: number) => {
    const ref = categoryRefs.current[index];
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return { currentCategory, setCategoryRef, scrollToSection };
};
