import { authedApiInstance } from '../firebase';
import { KaomojiCategory } from '../react/store/kaomoji/init-state';

interface KaomojiResponse {
  defaultKaomojis: KaomojiCategory[];
}

export const getDefaultKaomojis: () => Promise<KaomojiCategory[]> = () =>
  authedApiInstance
    .get<KaomojiResponse>('/kaomoji/default')
    .then((response) => response.data.defaultKaomojis)
    .catch((error) => error?.response?.data);
export const addCustomKaomoji = (content: string) =>
  authedApiInstance.post('/kaomoji', { content }).catch((error) => error?.response?.data);
export const removeCustomKaomoji = (content: string) =>
  authedApiInstance.delete('/kaomoji', { data: { content } }).catch((error) => error?.response?.data);
