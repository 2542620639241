import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { confirmAlert } from 'react-confirm-alert';

import RoomMemberMenu from '../room/settings/members/RoomMemberMenu';
import { getMemberForMenuCurried } from '../store/room-members/selectors';

import UnfriendConfirmationModal from '../friends/UnfriendConfirmationModal';
import { friendByIdSelector } from '../store/friends/selectors';

import UnfriendIcon from '../../../assets/icons/unfriend.svg';
import { moreMenuItemMixin, moreMenuMixin } from '../mixins';

import { WithRoomStore } from '../store/Wrapper';
import { useUserProfileData } from '../hooks/useUserProfileData';
import ViewUserPageMenuItem from '../user-page/ViewUserPageMenuItem';
import { EventSourceContext } from '../common/contexts.ts';
import { FRIEND_REQUEST_SOURCES } from '../../constants/analytics-events/friend-events';

const UserMenu = ({ userId, fromUserProfile, fromUserProfileShowAddFriend, reload }) => {
  const roomMember = useSelector(getMemberForMenuCurried(userId));

  // Note: right now, we're only using this menu off of the FriendsList so it's safe to
  // assume we don't need to dispatch a fetchFriends here. Once we re-use it in other
  // places we should add the fetch call.
  const friend = useSelector((state) => friendByIdSelector(state, userId));

  const { userProfile } = useUserProfileData(userId);
  const { username } = userProfile;

  const getConfirmationModal = (onClose) => (
    <WithRoomStore>
      <UnfriendConfirmationModal onClose={onClose} userId={userId} />
    </WithRoomStore>
  );

  const onUnfriendClick = () => {
    confirmAlert({
      customUI: ({ onClose }) => getConfirmationModal(onClose),
    });
  };

  if (roomMember) {
    return (
      <RoomMemberMenu
        member={roomMember}
        fromUserProfile={fromUserProfile}
        reload={reload}
        fromUserProfileShowAddFriend={fromUserProfileShowAddFriend}
        memberUsername={username}
      />
    );
  }
  return (
    <MoreMenuContainer>
      <EventSourceContext.Provider value={FRIEND_REQUEST_SOURCES.MINI_PROFILE_FROM_FRIEND_LIST}>
        <ViewUserPageMenuItem username={username} />
        {friend && (
          <MoreMenuItem onClick={onUnfriendClick}>
            <UnfriendIcon />
            Unfriend
          </MoreMenuItem>
        )}
      </EventSourceContext.Provider>
    </MoreMenuContainer>
  );
};

export default UserMenu;

UserMenu.propTypes = {
  userId: PropTypes.string.isRequired,
  fromUserProfile: PropTypes.bool,
  fromUserProfileShowAddFriend: PropTypes.bool,
  reload: PropTypes.bool,
};

UserMenu.defaultProps = {
  fromUserProfile: false,
  fromUserProfileShowAddFriend: false,
  reload: false,
};

const MoreMenuContainer = styled.div`
  ${moreMenuMixin()}
`;

const MoreMenuItem = styled.div`
  ${moreMenuItemMixin({ themed: false })}

  svg {
    margin-right: 10px;
    display: flex;
  }
`;
