import { htmlToElement } from '../../util';
import EmbeddedResource from './embedded-resource';

import { typesMap, regex } from '../../util/gsuite-util';

export default class GSuiteElement extends EmbeddedResource {
  static getEmbedUrl(url) {
    return regex.test(url) ? url : null;
  }

  getUnderlayContent({ url, title }) {
    const matches = url.match(regex);
    const typeInfo = typesMap[matches[1]];
    const elTitle = title || (typeInfo.type ? `Google ${typeInfo.type}` : 'GSuite');
    this.setTooltip(elTitle);
    return htmlToElement(`
      <div class="underlay-content">
        <div class="underlay-background ${typeInfo.backgroundClass}"></div>
        <img class="icon" alt="GSuite icon" src="images/icons/${typeInfo.icon}" />
        <span class="title">${elTitle}</span>
      </div>
    `);
  }
}

GSuiteElement.elementType = 'GSuiteElement';
