import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../definitions/store';
import { selectCurrentUser } from '../users/selectors';
import { OsOnboardingListRequiredStepIds } from '../../os/onboarding-list/steps';

export const selectIsFriendsSidebarOpened = (state: RootState) => state.os.isFriendsSidebarOpened;
export const selectIsFriendsOnlineOpened = (state: RootState) => state.os.isFriendsOnlineOpened;
export const selectIsAppearanceMenuOpened = (state: RootState) => state.os.isAppearanceMenuOpened;
export const selectIsFeedbackMenuOpened = (state: RootState) => state.os.isFeedbackMenuOpened;
export const selectIsRoomsMenuOpened = (state: RootState) => state.os.isRoomsMenuOpened;
export const selectIsCreateChatOpened = (state: RootState) => state.os.isCreateChatOpened;
export const selectIsMyStuffMenuOpened = (state: RootState) => state.os.isMyStuffMenuOpened;
export const selectIsAvatarProfileOpened = (state: RootState) => state.os.isAvatarProfileOpened;
export const selectIsWhatsNewOpened = (state: RootState) => state.os.isWhatsNewOpened;
export const selectIsDiscoverModalOpened = (state: RootState) => state.os.isDiscoverModalOpened;

export const selectOpenChats = (state: RootState) => state.os.openChats;
export const selectOpenChatElementIds = createSelector(selectOpenChats, (openChats) =>
  Object.values(openChats).map((chat) => chat.elementId)
);
export const selectOpenChatIds = createSelector(selectOpenChats, (openChats) => Object.keys(openChats) || []);
export const selectMaxZIndex = (state: RootState) => state.os.maxZIndex;

export const selectOsOnboardingList = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.osOnboardingList || {}
);
export const selectOsOnboardingListProgress = createSelector(
  [selectOsOnboardingList],
  (list) => (Object.values(list).filter((v) => v).length / Object.values(list).length) * 100 || 0
);
// Completed - all list items are checked
export const selectOsOnboardingListCompleted = createSelector(
  [selectOsOnboardingList],
  (list) =>
    // Check if all required steps are present and all of them are true
    Object.values(OsOnboardingListRequiredStepIds).every((key) => Object.prototype.hasOwnProperty.call(list, key)) &&
    Object.values(list).every((v) => v)
);
// Finished - the Finish button is pressed after the list was completed
export const selectIsOsOnboardingListFinished = createSelector(
  [selectCurrentUser],
  // If user is not loaded completely assume that onboarding is complete to avoid flickering
  // for users who actually already finished it.
  (currentUser) => !currentUser.displayName || currentUser.isOsOnboardingListFinished
);
export const selectIsOsOnboardingPending = createSelector([selectCurrentUser], (user) => user.isOsOnboardingPending);
export const selectIsOsAnnouncementPending = createSelector([selectCurrentUser], (user) => user.isOsAnnouncePending);
export const selectIsOsChatOnboardingPending = createSelector(
  [selectCurrentUser],
  (user) => user.isOsChatOnboardingPending
);
