import axios from 'axios';
import { GrowthBook } from '@growthbook/growthbook';

import firebase, { unAuthedApiInstance } from '../firebase';
import log from '../log';
import { identifyAnalytics } from '../util/analytics-util';

let GROWTHBOOK_INIT_USER_TIMEOUT = null;

const GROWTHBOOK_FEATURES = {
  basics_trial: 'basics_trial',
  silly_actions: 'silly_actions',
  homepage: 'homepage',
};

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    log.debug({
      experimentId: experiment.key,
      variationId: result.variationId,
    });
    const user = firebase.auth().currentUser;
    if (user) {
      identifyAnalytics(user.uid, { [result.featureId]: result.variationId });
    } else if (window.analytics && window.analytics.user) {
      identifyAnalytics({ [result.featureId]: result.variationId });
    }
  },
});

const FEATURES_ENDPOINT = process.env.GROWTHBOOK_URL;

export const growthBookRequestPromise = axios
  .get(FEATURES_ENDPOINT)
  .then((json) => {
    growthbook.setFeatures(json.data.features);
  })
  .catch(() => {
    log.error('Failed to fetch feature definitions from GrowthBook');
  });

async function handleABEvent(featureID, eventType, { metricBool, metricInt, metricFloat } = {}) {
  const result = growthbook.evalFeature(featureID);
  if (!result.experimentResult) {
    log.warn('No experiment found: ', featureID);
    return;
  }
  try {
    const response = await unAuthedApiInstance.post('/events', {
      user_id: result.experimentResult.hashValue,
      experiment_id: result.experiment.key,
      variation_id: result.experimentResult.variationId,
      event_type: eventType,
      metric_bool: metricBool,
      metric_int: metricInt,
      metric_float: metricFloat,
    });
    log.debug('response:', response);
  } catch (err) {
    log.error(err);
  }
}

(() => {
  firebase.auth().onAuthStateChanged((user) => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      id: user?.uid,
    });
  });
})();

function initGrowthBookUser() {
  if (GROWTHBOOK_INIT_USER_TIMEOUT) {
    window.clearTimeout(GROWTHBOOK_INIT_USER_TIMEOUT);
    GROWTHBOOK_INIT_USER_TIMEOUT = null;
  }
  if (window.analytics && window.analytics.user) {
    const userAttributes = growthbook.getAttributes();
    userAttributes.anonymous_id = window.analytics.user().anonymousId();
    growthbook.setAttributes(userAttributes);
  } else {
    GROWTHBOOK_INIT_USER_TIMEOUT = window.setTimeout(initGrowthBookUser, 50);
  }
}

export { growthbook, initGrowthBookUser, handleABEvent, GROWTHBOOK_FEATURES };
