import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './init-state';
import { fetchDefaultKaomojis, defaultKaomojisFetched } from './actions';

export const kaomojiReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchDefaultKaomojis, (state) => {
      state.isLoading = true;
    })
    .addCase(defaultKaomojisFetched, (state, action) => {
      state.defaultKaomojis = action.payload.categories;
      state.isLoading = false;
    })
);
