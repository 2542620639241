import React from 'react';
import styled from 'styled-components';

// utils
import { validateCustomSoundFile } from '../../../../../util/custom-sounds-util';

// constants
import { ALLOWED_FILE_TYPES_STRING } from '../../../../../constants/custom-sounds-constants';

interface UploadSoundLabelProps {
  text: string;
  onUpload: (file: File) => void;
  onError: (message: string) => void;
}

const UploadSoundLabel = ({ text, onUpload, onError }: UploadSoundLabelProps) => {
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const [fileToUpload] = Array.from(e.target.files || []);
    const validFile = validateCustomSoundFile(fileToUpload, onError);
    if (validFile) {
      onUpload(fileToUpload);
    }
  };

  return (
    <>
      <Label htmlFor="upload-sound">{text}</Label>
      <Input id="upload-sound" type="file" accept={`${ALLOWED_FILE_TYPES_STRING}, audio/*`} onChange={onFileChange} />
    </>
  );
};

export const Label = styled.label`
  color: #6b1be3;
  font-size: 14px;
  font-weight: bolder;
  text-decoration-line: underline;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const Input = styled.input`
  display: none;
`;

export default UploadSoundLabel;
