import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const FontSizePicker = ({ currentState, config, onChange }) => {
  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef(null);
  useOnClickOutside(
    containerRef,
    useCallback(() => setIsOpened(false), [])
  );

  return (
    <Container ref={containerRef}>
      <SelectBox onClick={() => setIsOpened(!isOpened)}>
        {currentState.fontSize || 16}
        <Chevron isOpened={isOpened} />
      </SelectBox>
      <OptionsContainer isOpened={isOpened}>
        {config.options.map((size) => (
          <Option
            key={size}
            onClick={() => {
              onChange(size);
              setIsOpened(false);
            }}
          >
            {size}
          </Option>
        ))}
      </OptionsContainer>
    </Container>
  );
};

export default FontSizePicker;

FontSizePicker.propTypes = {
  currentState: PropTypes.shape({
    fontSize: PropTypes.number,
  }).isRequired,
  config: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

const Container = styled.div`
  position: relative;
  top: 2px;
`;

const SelectBox = styled.div`
  cursor: pointer;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 0 4px;
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 34px;
  left: 4px;
  width: 50px;
  background: white;
  overflow: auto;
  max-height: ${({ isOpened }) => (isOpened ? '200px' : '0')};
  transition: 0.3s; // TODO: Animate in more optimized way
`;

const Option = styled.div`
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background: #ddd;
  }
`;

const Chevron = styled.div`
  transform: rotate(${({ isOpened }) => (isOpened ? 180 : 0)}deg);
  transition: transform 0.3s;
  margin-left: 3px;

  &::after {
    display: block;
    width: 0;
    height: 0;
    margin: 0 2px;
    border-top: 6px solid #000000;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    transition: transform 0.3s;
    content: '';
  }
`;
