import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash/fp';

import FeedMessage from './FeedMessage';
import ChatMessage from './ChatMessage';
import TextMessage from './TextMessage';
import ImageMessage from './ImageMessage';
import UploadErrorMessage from './UploadErrorMessage';
import SystemMessage from './system-messages/SystemMessage';
import RoomCardMessage from './RoomCardMessage';
import TagMessage from './TagMessage.tsx';
import AudioMessage from './AudioMessage.tsx';
import VideoMessage from './VideoMessage.tsx';
import { messageTypes } from '../../../constants/chat-constants';

const Message = ({
  message,
  nextCreator,
  nextMsgCreatedAt,
  previousCreator,
  previousMsgCreatedAt,
  userCanAddContent,
  deleteMessage,
  isLastMessage,
  displaySystemFriendButton,
  isFeedMsg,
  decorator,
}) => {
  const style = message?.emojiReactions?.length > 0 ? { marginBottom: isLastMessage ? '5px' : '16px' } : null;

  const chatMessage = (messageContent) =>
    isFeedMsg ? (
      <FeedMessage
        key={message.id}
        messageId={message.id}
        creator={message.creator}
        nextCreator={nextCreator}
        nextMsgCreatedAt={nextMsgCreatedAt}
        previousCreator={previousCreator}
        previousMsgCreatedAt={previousMsgCreatedAt}
        createdAt={message.createdAt || message.ts}
        messageContent={messageContent}
        // dms loading/failed states
        queue={message.queue}
        sendingFailed={message.sendingFailed}
      />
    ) : (
      <ChatMessage
        key={message.id}
        messageId={message.id}
        chatId={message.chatId}
        creator={message.creator}
        nextCreator={nextCreator}
        nextMsgCreatedAt={nextMsgCreatedAt}
        previousCreator={previousCreator}
        previousMsgCreatedAt={previousMsgCreatedAt}
        createdAt={message.createdAt || message.ts}
        messageContent={messageContent}
        emojiReactions={message.reactions}
        // dms loading/failed states
        queue={message.queue}
        sendingFailed={message.sendingFailed}
      />
    );

  switch (message.type) {
    case messageTypes.OUTSIDE_REACTION:
    case messageTypes.SYSTEM: {
      const systemMessageText =
        message.type === messageTypes.OUTSIDE_REACTION ? `has sent ${message.text} to this room!` : message.text;

      return (
        <SystemMessage
          key={message.id}
          id={message.id}
          chatId={message.chatId}
          creator={message.creator}
          icon={message.icon}
          text={systemMessageText}
          deletedElementId={message.deletedElementId}
          userCanAddContent={userCanAddContent}
          displayFriendButton={displaySystemFriendButton}
          createdAt={message.createdAt || message.ts}
          previousMsgCreatedAt={previousMsgCreatedAt}
          roomChatMessageOutsideOfRoom={message.roomChatMessageOutsideOfRoom}
          sysMsgType={message.metadata?.systemMsgType}
          isFeedMsg={isFeedMsg}
        />
      );
    }

    case messageTypes.ROOM_ELEMENT_TAG_FEED:
    case messageTypes.ROOM_ELEMENT_TAG_DM: {
      return chatMessage(
        <TagMessage
          key={message.id}
          entityId={message.tagMetadata.entityId}
          elementType={message.tagMetadata.elementType}
          imageUrl={message.tagMetadata.imageUrl}
          receiverIds={message.tagMetadata.receiverIds}
          senderId={message.tagMetadata.senderId}
          roomUrl={message.tagMetadata.roomUrl}
          boardId={message.tagMetadata.boardId}
          messageType={message.type}
        />
      );
    }

    case messageTypes.TEXT: {
      return chatMessage(
        <TextMessage
          id={message.id}
          creator={message.creator}
          nextCreator={nextCreator}
          url={message.url}
          text={message.text || ''}
          linkSummary={message.linkSummary}
          deleteMessage={deleteMessage}
          userCanAddContent={userCanAddContent}
          messageColor={message.messageColor}
          fontColor={message.fontColor}
          fontSize={message.fontSize}
          fontFamily={message.fontFamily}
          emojiReactions={message.emojiReactions}
          roomChatMessageOutsideOfRoom={message.roomChatMessageOutsideOfRoom}
          dmChatId={message.chatId}
          style={style}
          decorator={decorator}
        />
      );
    }

    case messageTypes.IMAGE: {
      return chatMessage(
        <ImageMessage
          id={message.id}
          imageUrl={message.imageUrl}
          storagePath={message.storagePath}
          creator={message.creator}
          nextCreator={nextCreator}
          deleteMessage={deleteMessage}
          emojiReactions={message.emojiReactions}
          dmChatId={message.chatId}
          style={style}
        />
      );
    }

    case messageTypes.STICKER:
    case messageTypes.GIF: {
      return chatMessage(
        <ImageMessage
          id={message.id}
          url={message.url}
          storagePath={message.storagePath}
          creator={message.creator}
          nextCreator={nextCreator}
          deleteMessage={deleteMessage}
          emojiReactions={message.emojiReactions}
          dmChatId={message.chatId}
          style={style}
        />
      );
    }

    case messageTypes.INVITE: {
      return chatMessage(
        <RoomCardMessage
          messageId={message.id}
          dmChatId={message.chatId}
          creator={message.creator}
          roomId={message.roomId}
          isInvitorBlockVisible={false}
          deleteMessage={deleteMessage}
        />
      );
    }

    case messageTypes.UPLOAD_ERROR: {
      return chatMessage(<UploadErrorMessage text={message.text} />);
    }

    case messageTypes.AUDIO: {
      return chatMessage(
        <AudioMessage
          id={message.id}
          chatId={message.chatId}
          url={message.url}
          storagePath={message.storagePath}
          creator={message.creator}
          deleteMessage={deleteMessage}
        />
      );
    }

    case messageTypes.VIDEO: {
      return chatMessage(
        <VideoMessage
          id={message.id}
          chatId={message.chatId}
          url={message.url}
          storagePath={message.storagePath}
          creator={message.creator}
          deleteMessage={deleteMessage}
        />
      );
    }

    default: {
      return null;
    }
  }
};

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    creator: PropTypes.string,
    createdAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.shape({
        createdAt: PropTypes.func,
      }),
      PropTypes.number,
    ]),
    sendingFailed: PropTypes.bool,
    deletedElementId: PropTypes.string,
  }).isRequired,
  nextCreator: PropTypes.string,
  previousCreator: PropTypes.string,
  userCanAddContent: PropTypes.bool.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  isLastMessage: PropTypes.bool,
  nextMsgCreatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      nextMsgCreatedAt: PropTypes.func,
    }),
    PropTypes.number,
  ]),
  previousMsgCreatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      previousMsgCreatedAt: PropTypes.func,
    }),
    PropTypes.number,
  ]),
  displaySystemFriendButton: PropTypes.bool,
  isFeedMsg: PropTypes.bool.isRequired,
  decorator: PropTypes.shape({}),
};

Message.defaultProps = {
  nextCreator: null,
  previousCreator: null,
  isLastMessage: false,
  nextMsgCreatedAt: null,
  previousMsgCreatedAt: null,
  displaySystemFriendButton: false,
};

export default memo(Message, (prevProps, nextProps) => isEqual(prevProps, nextProps));
