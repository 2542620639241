import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ROOM_SETTINGS_TABS } from '../../store/room/constants';
import { setRoomSettingsCurrentTab } from '../../store/room/store';
import { selectCurrentSettingsTabName } from '../../store/room/selectors';
import { svgColorMixin } from '../../mixins';
import { curriedGetIsHostGranted } from '../../store/room-members/selectors';
import { selectCurrentUserId } from '../../store/users/selectors';
import AdminOverlay from './common/AdminOverlay';

import RoomDetailsSettings from './details';
import GearIcon from '../../../../assets/icons/gear.svg';

import BackgroundSettings from './background';
import BackgroundIcon from '../../../../assets/icons/background.svg';

import ThemeSettings from './theme';
import ThemeIcon from '../../../../assets/icons/theme.svg';

import RoomMembers from './members';
import MembersIcon from '../../../../assets/icons/members.svg';

import ChatSettings from './chat';
import ChatIcon from '../../../../assets/icons/chat.svg';

import ChatSounds from './chat-sounds/index.tsx';

import NotificationSettings from './notifications';
import NotificationsIcon from '../../../../assets/icons/notifications.svg';

import HelpSettings from './help';
import HelpIcon from '../../../../assets/icons/help.svg';

const tabs = [
  {
    name: ROOM_SETTINGS_TABS.details,
    icon: <GearIcon />,
    component: <RoomDetailsSettings />,
    isAdminOnly: true,
  },
  {
    name: ROOM_SETTINGS_TABS.background,
    icon: <BackgroundIcon />,
    component: <BackgroundSettings />,
    isAdminOnly: true,
  },
  {
    name: ROOM_SETTINGS_TABS.theme,
    icon: <ThemeIcon />,
    component: <ThemeSettings />,
    isAdminOnly: true,
  },
  {
    name: ROOM_SETTINGS_TABS.members,
    icon: <MembersIcon />,
    component: <RoomMembers />,
  },
  {
    name: ROOM_SETTINGS_TABS.chat,
    icon: <ChatIcon />,
    component: <ChatSettings />,
    isAdminOnly: true,
  },
  {
    name: ROOM_SETTINGS_TABS.notifications,
    icon: <NotificationsIcon />,
    component: <NotificationSettings />,
  },
  {
    name: ROOM_SETTINGS_TABS.help,
    icon: <HelpIcon />,
    component: <HelpSettings />,
  },
];

// inner tabs are tabs that are not shown in the menu, but you can reach them from other tabs
const innerTabs = [
  {
    name: ROOM_SETTINGS_TABS.chatSounds,
    icon: <ChatIcon />,
    component: <ChatSounds />,
    isAdminOnly: true,
  },
];

const RoomSettingsMenu = () => {
  const dispatch = useDispatch();

  const tabName = useSelector(selectCurrentSettingsTabName);
  const tab = [...tabs, ...innerTabs].find((t) => t.name === tabName);

  const currentUserId = useSelector(selectCurrentUserId);
  const isHostGranted = useSelector(curriedGetIsHostGranted(currentUserId));
  const adminOverlay = tab?.isAdminOnly && !isHostGranted ? <AdminOverlay /> : null;

  return (
    <Container>
      {tab ? (
        <>
          {adminOverlay}
          {tab.component}
        </>
      ) : (
        tabs.map((t) => (
          <MenuItem key={t.name} onClick={() => dispatch(setRoomSettingsCurrentTab({ tabName: t.name }))}>
            <MenuIcon>{t.icon}</MenuIcon>
            <MenuName>{t.name}</MenuName>
          </MenuItem>
        ))
      )}
    </Container>
  );
};

export default RoomSettingsMenu;

export const MENU_HEIGHT_PX = 60;

const Container = styled.div`
  position: relative;
  padding: 24px 16px;
`;

const MenuItem = styled.div`
  width: 100%;
  height: ${MENU_HEIGHT_PX}px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 8px;
  cursor: pointer;
  color: var(--primary-foreground, black);

  svg {
    ${svgColorMixin('var(--primary-foreground-alpha-40, rgba(0, 0, 0, 0.4))')}
  }

  &:hover {
    background: var(--secondary-background, #6b1be3);
    color: var(--secondary-foreground, white);

    svg {
      ${svgColorMixin('var(--secondary-foreground, white)')}
    }
  }
`;

const MenuIcon = styled.div`
  margin: 0 22px;
  width: 20px;
`;

const MenuName = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
