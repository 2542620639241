import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Lens } from '@snap/camera-kit';
import * as Tooltip from '@radix-ui/react-tooltip';

import snapchatVideoProvider from '../../camera/snapchat-video-provider';
import Loader from '../components/Loader';
import eventBus, { lensSelected } from '../../event-bus';
import { ellipsisTextMixin, svgColorMixin } from '../mixins';

import SimpleFace from '../../../assets/icons/simple-face.svg';
import MagicIcon from '../../../assets/icons/magic.svg';
import SnapchatIcon from '../../../assets/icons/external/powered-by-snapchat.svg';
import { track } from '../../util/analytics-util';
import { REMOVE_FACE_FILTER, SET_FACE_FILTER } from '../../constants/analytics-events/camera-events';

const LensPicker = () => {
  const [lenses, setLenses] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await snapchatVideoProvider.getLenses();
      setLenses(response);
    })();
  }, []);

  const [selectedLensId, setSelectedLensId] = useState(null);

  const onLensClick = (lens: Lens) => {
    if (lens?.id === selectedLensId) return;

    setSelectedLensId(lens?.id);
    if (lens) {
      track(SET_FACE_FILTER, { name: lens.name });
      snapchatVideoProvider.applyLens(lens);
    } else {
      track(REMOVE_FACE_FILTER);
      snapchatVideoProvider.removeLens();
    }
    eventBus.dispatch(lensSelected, { lens });
  };

  if (!lenses.length) {
    return <Loader />;
  }

  return (
    <Container>
      <Title>
        <MagicIcon />
        &nbsp; Lens Effects
      </Title>
      <Attribution />
      <LensesContainer>
        <LensItem key="no-lens" onClick={() => onLensClick(null)}>
          <NoLensContainer key="no-lens" isSelected={!selectedLensId}>
            <SimpleFace />
          </NoLensContainer>
          <LensTitle>No Filter</LensTitle>
        </LensItem>
        {lenses.map((lens) => (
          <LensItem key={lens.id} onClick={() => onLensClick(lens)}>
            <LensPreviewContainer isSelected={lens.id === selectedLensId}>
              <LensPreviewImage
                alt={`${lens.name} preview`}
                src={`https://firebasestorage.googleapis.com/v0/b/officeparty-1cc24.appspot.com/o/face-filters%2F${lens.id}.gif?alt=media`}
              />
            </LensPreviewContainer>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <LensTitle>{lens.name}</LensTitle>
              </Tooltip.Trigger>
              <TooltipContent>{lens.name}</TooltipContent>
            </Tooltip.Root>
          </LensItem>
        ))}
      </LensesContainer>
    </Container>
  );
};

export default LensPicker;

const Container = styled.div`
  overflow: auto;
  height: 350px;
  padding: 0 12px 8px;
  position: relative;
`;

const Title = styled.div`
  color: #12002d;
  text-align: left;
  font-weight: bold;
  margin-bottom: 20px;

  ${svgColorMixin('#12002d')}
`;

const Attribution = styled(SnapchatIcon)`
  position: absolute;
  top: 16px;
  right: 20px;
`;

const LensesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 10px;
  justify-content: space-between;
`;

const LensItem = styled.div`
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
`;

const LensPreviewContainer = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  ${({ isSelected }) => isSelected && 'outline: 7px solid #6b1be3;'}
`;

const LensPreviewImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const LensTitle = styled.div`
  font-size: 14px;
  text-align: center;
  ${ellipsisTextMixin()}
`;

const NoLensContainer = styled(LensPreviewContainer)<{ isSelected: boolean }>`
  background: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TooltipContent = styled(Tooltip.Content)`
  background: white;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.5);
  font-size: 12px;
`;
