import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import { removeButtonStylingMixin } from '../../mixins';
import MessagesPreview from './previews/MessagesPreview';

// Messages preview with popover wrapper where children is popover anchor
const MessagesPreviewPopover = ({ children: anchor, displayMessages, messagesList }) => (
  <MessagesPreviewWrapper open={displayMessages}>
    <MessagesPreviewAnchor asChild>{anchor}</MessagesPreviewAnchor>
    <MessagesPreviewContent
      align="start"
      alignOffset={-5}
      side="right"
      onOpenAutoFocus={useCallback((e) => e.preventDefault(), [])}
      avoidCollisions={false}
    >
      <MessagesPreview messagesList={messagesList} />
    </MessagesPreviewContent>
  </MessagesPreviewWrapper>
);

MessagesPreviewPopover.propTypes = {
  children: PropTypes.node.isRequired,
  displayMessages: PropTypes.bool,
  messagesList: PropTypes.arrayOf(PropTypes.shape({})),
};

MessagesPreviewPopover.defaultProps = {
  displayMessages: true,
  messagesList: [],
};

const MessagesPreviewWrapper = Popover.Root;
const MessagesPreviewAnchor = styled(Popover.Trigger)`
  ${removeButtonStylingMixin()}
`;

const MessagesPreviewContent = styled(Popover.Content)`
  padding-left: 17px;

  @media (max-width: 764px) {
    display: none;
  }
`;

export default MessagesPreviewPopover;
