import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import ThemePreview from './ThemePreview';
import ClockIcon from '../../../../../assets/icons/clock.svg';
import Tooltip from '../../../components/Tooltip';

const PREVIEW_WIDTH = 105;
const PREVIEW_HEIGHT = 40;

const ThemeItem = ({ theme, isSelected, onThemeClick }) => {
  const themeWrapper = (
    <ThemeWrapper isSelected={isSelected}>
      {theme.isCustom ? (
        <PlusSignWrapper>
          <PlusSign>+</PlusSign>
        </PlusSignWrapper>
      ) : (
        <>
          {theme.isLimited ? (
            <LimitedBadge>
              <ClockIconContainer width={16} height={16} />
            </LimitedBadge>
          ) : null}
          <ThemePreview colors={theme.colors} width={PREVIEW_WIDTH} height={PREVIEW_HEIGHT} />
        </>
      )}
    </ThemeWrapper>
  );

  return (
    <ThemeItemContainer onClick={onThemeClick}>
      {theme.isLimited ? (
        <Tooltip
          content={
            <TooltipStyledContent side="top" sideOffset={8}>
              <h6>Limited time only!</h6>
              <TooltipArrow offset={8} />
            </TooltipStyledContent>
          }
        >
          {themeWrapper}
        </Tooltip>
      ) : (
        themeWrapper
      )}

      <Title>{theme.name}</Title>
    </ThemeItemContainer>
  );
};

export default ThemeItem;

ThemeItem.propTypes = {
  theme: PropTypes.shape({
    name: PropTypes.string.isRequired,
    colors: PropTypes.shape({}),
    isCustom: PropTypes.bool,
    isLimited: PropTypes.bool,
  }).isRequired,
  onThemeClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

ThemeItem.defaultProps = {
  isSelected: false,
};

const TooltipStyledContent = styled(TooltipPrimitive.Content)`
  padding: 5px 15px;
  min-width: 80px;
  border-radius: 8px;
  background-color: white;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  filter: drop-shadow(0 0 0.25rem grey);
`;

const TooltipArrow = styled(TooltipPrimitive.Arrow)`
  height: 10px;
  width: 10px;
  fill: white;
`;
const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: var(--primary-foreground, black);
  margin: 8px 0;
  text-align: center;
`;

const ThemeItemContainer = styled.div`
  ${({ theme }) =>
    theme?.themePreview?.width ? `max-width: ${theme?.themePreview?.width * 2}px` : 'max-width: 155px'};
  position: relative;
  cursor: pointer;
`;

export const ThemeWrapper = styled.div`
  padding: 24px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  background: white;
  outline: ${({ isSelected }) =>
    isSelected
      ? '4px solid var(--secondary-background, #6b1be3)'
      : '1px solid var(--primary-foreground-alpha-20, rgba(0, 0, 0, 0.2))'};

  &:hover {
    outline: ${({ isSelected }) =>
      isSelected
        ? '4px solid var(--secondary-background, #6b1be3)'
        : '1px solid var(--primary-foreground-alpha-40, rgba(0, 0, 0, 0.4))'};
  }
`;

const PlusSignWrapper = styled.div`
  width: ${({ theme }) => (theme?.themePreview?.width ? theme?.themePreview?.width : PREVIEW_WIDTH)}px;
  height: ${({ theme }) => (theme?.themePreview?.height ? theme?.themePreview?.height : PREVIEW_HEIGHT)}px;
`;

const PlusSign = styled.div`
  width: ${({ theme }) =>
    theme?.themePreview?.height ? theme?.themePreview?.height : PREVIEW_HEIGHT}px; // Not a typo, should be a circle
  height: ${({ theme }) => (theme?.themePreview?.height ? theme?.themePreview?.height : PREVIEW_HEIGHT)}px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  color: black;
  font-size: 36px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 4px; // 'align-items: center' looks not like a center for the plus sign.
`;

const LimitedBadge = styled.div`
  width: 100px;
  height: 50px;
  background-image: linear-gradient(to top left, transparent 50%, #6b1be3 0);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: left, right;
  border-top-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
`;

const ClockIconContainer = styled(ClockIcon)`
  margin: 7px;
`;
