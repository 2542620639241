import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createFriendRequest } from '../store/friends/api';

// store
import { getMemberIsOnline, getMemberProfile } from '../store/room-members/selectors';
import { selectFirstTimeMemberFriendSuggestion } from '../store/friends/selectors';
import { setFirstTimeMemberFriendSuggestion } from '../store/friends/store';

// components
import FriendSuggestionPopover from './FriendSuggestionPopover.tsx';

// utils
import { track } from '../../util/analytics-util';

// constants
import { FRIEND_REQUEST_SOURCES, REQUEST_FRIEND } from '../../constants/analytics-events/friend-events';

const NewMemberFriendSuggestion = () => {
  const dispatch = useDispatch();

  const memberId = useSelector(selectFirstTimeMemberFriendSuggestion)?.memberId;
  const member = useSelector((state) => (memberId ? getMemberProfile(state, { id: memberId }) : null));
  const isOnline = useSelector((state) => (memberId ? getMemberIsOnline(state, { id: memberId }) : false));

  const close = useCallback(() => dispatch(setFirstTimeMemberFriendSuggestion({ suggestion: null })), [dispatch]);
  const addFriend = useCallback(async () => {
    close();
    const response = await createFriendRequest(memberId);
    if (response && response.success) {
      track(REQUEST_FRIEND, {
        source: FRIEND_REQUEST_SOURCES.NEW_MEMBER_SUGGESTION,
        receiverId: memberId,
      });
    }
  }, [close, memberId]);

  return member?.name ? (
    <FriendSuggestionPopover
      isOnline={isOnline}
      text={`${member.name} joined for the first time!`}
      showAddFriendButton
      member={member}
      onClose={close}
      onAddFriend={addFriend}
    />
  ) : null;
};

export default NewMemberFriendSuggestion;
