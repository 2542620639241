import bus, { boardChanged } from '../../event-bus';
import firebase from '../../firebase';

import template from './broadcast-camera-invite.html';
import './broadcast-camera-invite.less';

export default class BroadcastCameraInvite extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    this.onSwitchingBoards = this.onSwitchingBoards.bind(this);
    this.onPromotionUpdate = this.onPromotionUpdate.bind(this);
    this.onIgnore = this.onIgnore.bind(this);
    this.onAccept = this.onAccept.bind(this);

    this.currentBoardId = window.currentBoardId;
    bus.on(boardChanged, this.onSwitchingBoards);

    this.notification = this.querySelector('.broadcast-camera-invite');
    this.querySelector('.ignore-button').addEventListener('click', this.onIgnore);
    this.querySelector('.accept-button').addEventListener('click', this.onAccept);
  }

  disconnectedCallback() {
    bus.off(boardChanged, this.onSwitchingBoards);
    this.removeMemberListener();
  }

  getRef() {
    return firebase.database().ref(`/memberPromotions/${this.currentBoardId}/${firebase.auth().currentUser.uid}`);
  }

  addMemberListener() {
    this.getRef().on('value', this.onPromotionUpdate);
  }

  removeMemberListener() {
    this.getRef().off('value', this.onPromotionUpdate);
  }

  onSwitchingBoards(newBoardId) {
    if (firebase.auth().currentUser) this.removeMemberListener();
    this.currentBoardId = newBoardId;
    if (firebase.auth().currentUser) this.addMemberListener();
  }

  onPromotionUpdate(promotionSnapshot) {
    const promotion = promotionSnapshot.val();
    if (!promotion || promotion.status !== 'requested') {
      this.notification.style.display = 'none';
      return;
    }
    if (!this.notification.style.display) {
      return;
    }

    this.notification.style.display = null;
    this.querySelector('.host-name').innerHTML = `<here-user-name userId="${promotion.by}"></here-user-name>`;
  }

  onAccept() {
    this.getRef().update({ status: 'accepted' });
  }

  onIgnore() {
    this.getRef().remove();
  }
}
window.customElements.define('here-broadcast-camera-invite', BroadcastCameraInvite);
