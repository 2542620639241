import styled from 'styled-components';
import { CHAT_WINDOW_HEADER_HEIGHT } from './constants';

// default chat window theme colors
export const DEFAULT_PRIMARY_BACKGROUND = '#12002d';
export const DEFAULT_PRIMARY_FOREGROUND = '#ffffff';
export const DEFAULT_SECONDARY_BACKGROUND = '#6b1be3';
export const DEFAULT_SECONDARY_FOREGROUND = '#ffffff';
export const DEFAULT_THEME_COLORS = {
  primaryBackground: DEFAULT_PRIMARY_BACKGROUND,
  primaryForeground: DEFAULT_PRIMARY_FOREGROUND,
  secondaryBackground: DEFAULT_SECONDARY_BACKGROUND,
  secondaryForeground: DEFAULT_SECONDARY_FOREGROUND,
};
export const DEFAULT_CHAT_THEME = { colors: DEFAULT_THEME_COLORS, primaryFont: 'Inter', isCustom: true };

export const chatWindowTheme = {
  chatWindowContainer: {
    background: 'transparent',
  },
  chatListScrollWrapper: {
    borderRadius: '4px',
  },
};

export const chatInputTheme = {
  container: {
    background: 'transparent',
    padding: '0',
  },
};

export const Container = styled.div<{ backgroundImg?: string }>`
  height: 100%;
  border-radius: 11px;

  ${({ backgroundImg }) =>
    backgroundImg &&
    `
    background: url('${backgroundImg}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}

  position: relative;
  padding: 14px;

  &.isActive {
    outline: 3px solid var(--primary-foreground);
  }
`;

export const Background = styled.div<{ background: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 11px;
  background: ${({ background }) => background};
`;

export const ChatContainer = styled.div`
  height: calc(100% - ${CHAT_WINDOW_HEADER_HEIGHT}px);
`;
