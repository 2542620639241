import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import UserListItem from '../user-profile/UserListItem';
import { selectDMChatByReceiverId } from '../store/messaging/selectors';

const FriendsListItem = ({
  userId,
  onlineStatus,
  onFriendClick,
}: {
  userId: string;
  onlineStatus?: { status: string };
  onFriendClick: (userId: string, chatId?: string) => void;
}) => {
  const DMWithYouAndReceiver = useSelector((state) => selectDMChatByReceiverId(state, userId));

  const onClick = () => {
    onFriendClick(userId, DMWithYouAndReceiver[0]?.id);
  };

  return (
    <UserItemWrapper key={userId} onClick={onClick}>
      <UserListItem userId={userId} onlineStatus={onlineStatus?.status} />
    </UserItemWrapper>
  );
};

const UserItemWrapper = styled.div`
  cursor: pointer;

  & + & {
    margin-top: 2px;
  }
`;

export default FriendsListItem;
