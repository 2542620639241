import iro from '@jaames/iro';

import { htmlToElement } from '../../util';
import log from '../../log';

import template from './color-select.html';
import './color-select.less';

const ColorPrefixes = [
  '#f5b752',
  '#6021de',
  '#000000',
  '#f2f2f2',
  '#959595',
  '#e86f5f',
  '#96297e',
  '#45916c',
  '#4391a8',
  '#3175df',
];

export default class ColorSelect extends HTMLElement {
  connectedCallback() {
    this.innerHTML = template;

    this.observer = new IntersectionObserver((e) => {
      if (e[0].isIntersecting) {
        this.initialize();
        this.observer.unobserve(this);
      }
    });
    this.observer.observe(this);
    this.hasAlpha = this.getAttribute('alpha') === 'true';
  }

  initialize() {
    if (this.colorPicker) {
      log.warn('Color picker was initialized more than one time, weird!');
      return;
    }

    const layout = [{ component: iro.ui.Wheel }, { component: iro.ui.Slider }];
    if (this.hasAlpha) {
      this.querySelector('.color-select-container').classList.add('color-select-with-alpha');
      layout.push({
        component: iro.ui.Slider,
        options: {
          sliderType: 'alpha',
        },
      });
    }

    this.colorPicker = new iro.ColorPicker(this.querySelector('.color-picker'), {
      width: 150,
      layout,
      color: this.getAttribute('initialValue') || '#fff',
    });

    const prefixes = this.querySelector('.color-prefixes');
    prefixes.innerHTML = '';

    ColorPrefixes.forEach((color) => {
      const colorElement = htmlToElement(`<div class="color-prefix"
      style="background-color: ${color}"></div>`);
      prefixes.appendChild(colorElement);
      colorElement.addEventListener('click', () => {
        this.colorSelected(color, true);
        this.colorPicker.color.hexString = color;
      });
    });

    this.colorPicker.on('input:move', (color) => {
      this.colorSelected(this.hasAlpha ? color.hex8String : color.hexString, false);
    });
    this.colorPicker.on('input:end', (color) => {
      this.colorSelected(this.hasAlpha ? color.hex8String : color.hexString, true);
    });

    if (this.colorValue) {
      this.colorPicker.color.set(this.colorValue);
    }
  }

  colorSelected(color, inputComplete) {
    this.setAttribute('selectedcolor', color);
    const event = new CustomEvent('colorchange', { detail: { color, inputComplete } });
    this.dispatchEvent(event);
  }

  setColor(color) {
    if (this.colorPicker) {
      this.colorPicker.color.set(color);
    } else {
      this.colorValue = color;
    }
  }
}
window.customElements.define('here-color-select', ColorSelect);
