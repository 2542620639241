import { BasePublisher } from '../base_publisher';
import { RTCOutboundRtpStreamStatsComplete } from './definitions';
import { DomStatsBase } from './dom-stats-base';

export class DomPublisherStats extends DomStatsBase {
  private publisher: BasePublisher;

  constructor(userId: string, username: string, publisher: BasePublisher) {
    super(userId, username);
    this.publisher = publisher;
  }

  getEncodeFrameTime(stat: RTCOutboundRtpStreamStatsComplete) {
    return Number((stat.totalEncodeTime / stat.framesEncoded).toFixed(6));
  }

  getTargetBitrate(stat: RTCOutboundRtpStreamStatsComplete) {
    return Number((stat.targetBitrate / 1000).toFixed(0));
  }

  audioTemplate(stat: RTCOutboundRtpStreamStatsComplete) {
    return `
      <div><strong>${stat.kind.toUpperCase()} / ${stat.mid} / ${stat.ssrc}</strong></div>
      ${this.row('Codec', `${this.publisher?.audioCodecMime}`)}
      ${this.row('Target Bitrate', `${this.getTargetBitrate(stat)}Kbps`)}
      ${this.row('Nack', `${stat.nackCount}`)}
      ${this.row('RTX Packets / Bytes', `${stat.retransmittedPacketsSent} / ${stat.retransmittedBytesSent}`)}
    `;
  }

  videoTemplate(stat: RTCOutboundRtpStreamStatsComplete) {
    return `
      <div><strong>${stat.kind.toUpperCase()} / ${stat.mid} / ${stat.ssrc}</strong></div>
      ${this.row('Codec', `${this.publisher?.videoCodecMime}`)}
      ${this.row('Target Bitrate', `${this.getTargetBitrate(stat)}Kbps`)}
      ${this.row('FPS', `${stat.framesPerSecond}`)}
      ${this.row('Frame Size', `${stat.frameWidth} x ${stat.frameHeight}`)}
      ${this.row('Nack / PLI / FIR', `${stat.nackCount} / ${stat.pliCount} / ${stat.firCount}`)}
      ${this.row('Nack/s', `${this.publisher?.nackRatePerSecond}`)}
      ${this.row('PLI/s', `${this.publisher?.pliRatePerSecond}`)}
      ${this.row('FIR/s', `${this.publisher?.firRatePerSecond}`)}
      ${this.row('Quality Limit Reason', `${stat.qualityLimitationReason}`)}
      ${this.row('RTX Packets / Bytes', `${stat.retransmittedPacketsSent} / ${stat.retransmittedBytesSent}`)}
      ${this.row('Encode Frame Time', `${this.getEncodeFrameTime(stat)}`)}
    `;
  }
}
