import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// store
import { goPrevScreen, submitInputEmailScreen, setInputEmailScreenError } from '../../store/signing-in/actions.ts';
import { selectInputEmailScreenError } from '../../store/signing-in/selectors.ts';

// components
import FlowInput from '../FlowInput.tsx';
import FlowInputsContainer from '../FlowInputsContainer.tsx';
import {
  InputContainer,
  NavButtonsContainer,
  NextNavButton,
  PrevNavButton,
  TitleContainer,
  ScreenTitle,
  ContainerDefault,
} from '../shared-styled.ts';

// utils
import { isEmail } from '../../../util/string-util';

const EmailInputScreen = () => {
  const dispatch = useDispatch();

  const emailScreenError = useSelector(selectInputEmailScreenError);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const emailValid = isEmail(e.target.value);
    setIsEmailValid(emailValid);
    setEmail(e.target.value);

    if (emailScreenError && emailValid) {
      dispatch(setInputEmailScreenError({ error: '' }));
    }
  };

  const onPrevClick = () => {
    dispatch(goPrevScreen({ screenData: {} }));
  };

  const onNextClick = () => {
    dispatch(submitInputEmailScreen({ email }));
  };

  return (
    <ContainerDefault>
      <TitleContainer>
        <ScreenTitle>Sign In With Email</ScreenTitle>
      </TitleContainer>

      <FlowInputsContainer isValid={isEmailValid} onSubmit={onNextClick}>
        <InputContainer>
          <FlowInput
            label="Email"
            errorMessage={emailScreenError}
            id="sign-in-first-email-input"
            type="email"
            placeholder="example@email.com"
            checkResult={email && emailScreenError ? isEmailValid && !emailScreenError : null}
            value={email}
            autoFocus
            onChange={handleEmailChange}
            testId="sign-in-email-input-field"
          />
        </InputContainer>
      </FlowInputsContainer>

      <NavButtonsContainer>
        <PrevNavButton onClick={onPrevClick}>Cancel</PrevNavButton>
        <NextNavButton type="submit" disabled={!email || !isEmailValid} onClick={onNextClick} data-testid="next-button">
          Next
        </NextNavButton>
      </NavButtonsContainer>
    </ContainerDefault>
  );
};

export default EmailInputScreen;
