/* Audio signal monitoring and rendering */

import log from './log';
import { clamp } from './util';

const audioMonitors = {};
const nodesByElement = {};

export function drawAudioBar(canvas, cxt, rms) {
  const barHeight = Math.min(canvas.height, rms * canvas.height);

  cxt.fillStyle = 'rgb(75, 150, 25)';
  cxt.fillRect(0, 0, canvas.width, canvas.height);
  cxt.fillStyle = 'rgb(100, 200, 50)';
  cxt.fillRect(0, canvas.height - barHeight, canvas.width, barHeight);
}

export function drawAudioMultiBar(canvas, cxt, rms) {
  const tallBarHeight = clamp((rms * canvas.height) / 2, 2, canvas.height);
  const shortBarHeight = clamp((rms * canvas.height) / 4, 2, canvas.height);

  const cx = canvas.width / 2;
  const cy = canvas.height / 2;
  cxt.clearRect(cx - 35, 0, 70, canvas.height);
  cxt.strokeStyle = 'rgb(100, 200, 50)';
  cxt.lineCap = 'round';
  cxt.lineWidth = 12;
  cxt.beginPath();
  cxt.moveTo(cx, cy - tallBarHeight);
  cxt.lineTo(cx, cy + tallBarHeight);
  cxt.moveTo(cx - 25, cy - shortBarHeight);
  cxt.lineTo(cx - 25, cy + shortBarHeight);
  cxt.moveTo(cx + 25, cy - shortBarHeight);
  cxt.lineTo(cx + 25, cy + shortBarHeight);
  cxt.stroke();
}

async function monitorAudioWithDeprecatedAPI(stream, canvasElement, renderer = drawAudioBar) {
  if (!stream || !(stream instanceof MediaStream)) {
    log.warn(`Invalid stream type for monitoring audio: ${stream}`);
    return null;
  }

  const audioContext = new AudioContext();
  if (!audioContext) {
    log.debug('AudioContext not supported');
    return null;
  }
  const mediaStreamSource = audioContext.createMediaStreamSource(stream);
  const processor = audioContext.createScriptProcessor(4096, 1, 1);

  mediaStreamSource.connect(processor);
  processor.connect(audioContext.destination);

  const canvas = document.getElementById(canvasElement);
  const cxt = canvas.getContext('2d');

  processor.onaudioprocess = (e) => {
    const inputData = e.inputBuffer.getChannelData(0);
    const total = inputData.reduce((sum, x) => sum + Math.abs(x), 0);
    const rms = Math.sqrt(total / inputData.length);
    renderer(canvas, cxt, rms);
  };

  processor.onended = () => {
    log.debug('Source Ended');
    mediaStreamSource.disconnect(processor);
    processor.disconnect(audioContext.destination);
  };
  return processor;
}

export function stopMonitoringAudio(node) {
  if (typeof AudioWorkletNode === 'undefined') {
    // Handles itself when the stream ends?
    return;
  }

  node.port.postMessage({ release: true });
  node.port.onmessage = null;
  node.port.close();
  if (node.context.state !== 'closed') {
    node.context.close();
  }
  node.disconnect();
  let deleted = false;
  Object.keys(nodesByElement).forEach((key) => {
    if (nodesByElement[key] === node) {
      delete nodesByElement[key];
      deleted = true;
    }
  });
  if (!deleted) {
    log.warn("Couldn't find audio node in monitors when deleting", node);
  }
}

export async function monitorAudio(stream, canvasElement, renderer = drawAudioBar) {
  if (!stream || !(stream instanceof MediaStream)) {
    log.warn(`Invalid stream type for monitoring audio: ${stream}`);
    return null;
  }

  if (stream.getAudioTracks().length < 1) {
    log.debug('Stream has no audio tracks. Not monitoring audio.');
    return null;
  }

  if (nodesByElement[canvasElement]) {
    log.debug(`Duplicate audio monitor for ${canvasElement}, replacing it`);
    stopMonitoringAudio(nodesByElement[canvasElement]);
    delete nodesByElement[canvasElement];
  }

  if (typeof AudioWorkletNode === 'undefined') {
    return monitorAudioWithDeprecatedAPI(stream, canvasElement, renderer);
  }

  /* Note that this only currently works in Chrome and Edge
   * But the alternative (AudioContext) was deprecated in 2014!
   * Saved the alternative in monitorAudioWithDeprecatedAPI for use when
   * we need browser compatibility.
   *
   * The web is a terrible place.
   */
  const audioContext = new AudioContext();
  const mediaStreamSource = audioContext.createMediaStreamSource(stream);

  await audioContext.audioWorklet.addModule('vumeter-processor.js');
  const node = new AudioWorkletNode(audioContext, 'vumeter');

  // Listing any message from AudioWorkletProcessor in its
  // process method here where you can know
  // the volume level
  const canvas = document.getElementById(canvasElement);
  if (!canvas) {
    log.error(`Can't find audio canvas ${canvasElement}`);
    return null;
  }
  const cxt = canvas.getContext('2d');
  node.port.onmessage = (event) => {
    if (!nodesByElement[canvasElement]) {
      log.debug('Stopping audio monitor from within event callback');
      stopMonitoringAudio(node);
      return;
    }

    const volume = event.data.volume ? event.data.volume : 0;
    const sensibility = 50;
    const rms = (volume * 100) / sensibility;
    if (volume > 0) {
      renderer(canvas, cxt, rms);
    }
  };

  mediaStreamSource.connect(node).connect(audioContext.destination);

  nodesByElement[canvasElement] = node;
  return node;
}

const monitorUserAudioInternal = async (streamId, stream) => {
  try {
    log.debug('Monitoring audio for stream', streamId, stream.getAudioTracks());
    stopMonitoringUserAudio(streamId);
    audioMonitors[streamId] = await monitorAudio(stream, `audio-level-${streamId}`, drawAudioMultiBar);
  } catch (err) {
    log.error('Error monitoring audio for stream', err);
  }
};

export function stopMonitoringUserAudio(streamId) {
  if (audioMonitors[streamId]) {
    log.debug(`Found audio monitor for ${streamId}, removing it`);
    stopMonitoringAudio(audioMonitors[streamId]);
    delete audioMonitors[streamId];
  }
}

export async function monitorUserAudio(streamId, stream) {
  if (!stream) {
    log.warn('Audio: monitorUserAudio: Empty stream to monitor');
    return;
  }
  await monitorUserAudioInternal(streamId, stream);
}
