import { useState, useEffect } from 'react';

import { offSnapshot, onSnapshot } from '../../firestore-watcher';

export function useBoardElementsData(currentBoardId) {
  const [currentElementsData, setCurrentElementsData] = useState([]);

  useEffect(() => {
    function handleElementsUpdate(boardData) {
      setCurrentElementsData(boardData);
    }

    onSnapshot(`boards/${currentBoardId}/elements`, handleElementsUpdate, 'collection');

    return () => {
      offSnapshot(`boards/${currentBoardId}/elements`, handleElementsUpdate);
    };
  }, [currentBoardId]);

  return [currentElementsData];
}
